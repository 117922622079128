import { type TrialAndRepeatPurchaseActivityRequestDto } from "../models/TrialAndRepeatPurchaseActivityRequestDto";
import { type TrialAndRepeatPurchaseActivityResponseDto } from "../models/TrialAndRepeatPurchaseActivityResponseDto";
import { getSlugFromBreakdown } from "../utils/getSlugFromBreakdown";
import { trialAndRepeatApiSlice } from "./trial-and-repeat-api-slice";

export const trialAndRepeatPurchaseActivityApiSlice =
  trialAndRepeatApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getPurchaseActivity: builder.query<
        TrialAndRepeatPurchaseActivityResponseDto,
        {
          breakdownType: string;
          division: string;
          payload: TrialAndRepeatPurchaseActivityRequestDto;
          reportId: string | undefined;
        }
      >({
        query: ({ division, reportId, breakdownType, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetPurchaseActivity/${getSlugFromBreakdown(
            breakdownType
          )}/${division}/${reportId ?? ""}`,
        }),
      }),
    }),
  });

export const { useLazyGetPurchaseActivityQuery } =
  trialAndRepeatPurchaseActivityApiSlice;
