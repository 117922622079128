import {
  type LocalIndexedMetricSelection,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import {
  isMaximumSelected,
  isSelected,
} from "components-ui/src/local-filters/multiCheckboxFilter/utils";
import {
  type PanelOption,
  type Panel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { SidePanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isSingleSelectionParameter,
  isTimePeriodParameter,
  isPlainTextContentParameter,
  isSegmentationGroupParameter,
  isLocationSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildSingleSelectPanel,
  buildIndexedMetricPanel,
  buildTimePeriodPanel,
  buildPlainTextContentPanel,
  buildMultiSegmentationCheckboxPanel,
  buildLocationHierarchyPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";
import {
  onChannelChange,
  onIndexedAgainstChange,
  onLocationChange,
  onMetricChange,
  onPromotionChange,
  onSegmentationChange,
  selectChannel,
  selectFocalItems,
  selectIndexedAgainst,
  selectLocation,
  selectMetric,
  selectPromotion,
  selectSegmentation,
} from "../services/customer-profiling-slice";
import { getFilterIndexAgainst } from "../utils/getFilterIndexAgainst";
import { convertPanelOptionToString } from "./TopDrawerProductTable/utils";

export const CustomerProfilingSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  const dispatch = useDispatch();

  const channel = useSelector(selectChannel);
  const indexedAgainst = useSelector(selectIndexedAgainst);
  const location = useSelector(selectLocation);
  const metric = useSelector(selectMetric);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const focalItems = useSelector(selectFocalItems);

  const { localParameters, tableResponse } = useSelector(
    (state: RootState) => ({
      localParameters: state.customerProfiling.localParameters,
      tableResponse: state.customerProfiling.tableResponse,
    })
  );

  const generatedPanels = useCallback((): Panel[] => {
    const panels: Panel[] = [];
    for (const localParameter of localParameters) {
      if (
        localParameter.id === LocalParameters.Channel &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onChannelSelection = (value: PanelOption) => {
          dispatch(onChannelChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onChannelSelection,
            channel.value as string
          )
        );
      } else if (
        localParameter.id === LocalParameters.LocationHierarchy &&
        isLocationSelectionParameter(localParameter)
      ) {
        const onLocationSelection = (
          selection: LocalHierarchyNodeSelection
        ) => {
          dispatch(onLocationChange(selection));
        };

        panels.push(
          buildLocationHierarchyPanel(
            localParameter,
            onLocationSelection,
            location
          )
        );
      } else if (
        localParameter.id === LocalParameters.Metrics &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onMetricSelection = (
          metricSelection: PanelOption,
          indexedAgainstSelection: PanelOption
        ) => {
          dispatch(onMetricChange(metricSelection));
          dispatch(onIndexedAgainstChange(indexedAgainstSelection));
        };

        const tableRows =
          tableResponse.metricValues.find(
            (value) => value.metricName === (metric.value as string)
          )?.items ?? [];

        const indexAgainstOptions = localParameter.selections
          .filter((x: LocalIndexedMetricSelection) => x.indexedAgainst)
          .map(
            (x: LocalIndexedMetricSelection) => x.indexedAgainst?.value ?? ""
          );

        panels.push(
          buildIndexedMetricPanel(
            localParameter,
            onMetricSelection,
            indexedAgainst.value as string,
            metric,
            focalItems,
            getFilterIndexAgainst(focalItems, tableRows, indexAgainstOptions)
          )
        );
      } else if (
        localParameter.id === LocalParameters.Promotion &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onPromotionSelection = (value: PanelOption) => {
          dispatch(onPromotionChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onPromotionSelection,
            promotion.value as string
          )
        );

        // SEGMENTATION
      } else if (localParameter.id === LocalParameters.Segmentation) {
        if (isSegmentationGroupParameter(localParameter)) {
          const onSegmentationSelection = (value: PanelOption) => {
            dispatch(onSegmentationChange(value));
          };

          panels.push(
            buildMultiSegmentationCheckboxPanel(
              (value: string) =>
                isSelected(convertPanelOptionToString(segmentation), value) ||
                !isMaximumSelected(
                  convertPanelOptionToString(segmentation),
                  localParameter.maxSelections ?? 1
                ),
              localParameter,
              onSegmentationSelection,
              convertPanelOptionToString(segmentation),
              `${localParameter.maxSelections ?? 1} selections max.`,
              localParameter.selections
                .filter((selection) =>
                  isSelected(
                    convertPanelOptionToString(segmentation),
                    selection.id
                  )
                )
                .map((selection) => selection.name)
                .join(", ")
            )
          );
        } else if (isPlainTextContentParameter(localParameter)) {
          panels.push(buildPlainTextContentPanel(localParameter));
        }

        // TIME
      } else if (isTimePeriodParameter(localParameter)) {
        panels.push(buildTimePeriodPanel(localParameter));
      }
    }

    return panels;
  }, [
    dispatch,
    focalItems,
    localParameters,
    channel,
    location,
    metric,
    indexedAgainst,
    promotion,
    segmentation,
    tableResponse,
  ]);

  return (
    <SidePanel
      eventTrackingService={eventTrackingService}
      panels={generatedPanels()}
    />
  );
};
