import { FormInputHeight } from "@quantium-enterprise/qds-react";
import { InformationTooltip } from "components-ui/src/information-tooltip/InformationTooltip";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFocalItemDropdownOptions } from "../../common/utils/focal-item-labels-utils";
import { type RootState } from "../../store";
import {
  onFocalItemChange,
  onFocalItemHeatmapSelectionChange,
  onSegmentationOptionSelectionChange,
} from "../services/time-of-day-day-of-week-slice";
import {
  selectFocalItemParents,
  selectFocalItems,
} from "../services/time-of-day-day-of-week-slice-selectors";
import styles from "./TimeOfDayDayOfWeekHeatmapReportlet.module.css";

export const TimeOfDayDayOfWeekHeatmapFilters = () => {
  const dispatch = useDispatch();

  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);

  const {
    activeFocalItem,
    activeSegmentOption,
    focalItemOptions,
    segmentOptions,
  } = useSelector((state: RootState) => ({
    activeFocalItem:
      state.timeOfDayDayOfWeek.heatmapFilters.activeFocalItemOption,
    activeSegmentOption:
      state.timeOfDayDayOfWeek.heatmapFilters.activeSegmentIndex,
    focalItemOptions:
      state.timeOfDayDayOfWeek.heatmapFilters.focalItemDropdownOptions,
    segmentOptions:
      state.timeOfDayDayOfWeek.heatmapFilters.segmentsDropdownOptions,
  }));

  const focalItemDropdownOptions = useMemo(
    () => getFocalItemDropdownOptions(focalItemOptions, focalItemParents),
    [focalItemOptions, focalItemParents]
  );

  const segmentsDropdownOptions = useMemo(
    () =>
      segmentOptions.map((item, index) => ({
        label: item,
        value: index.toString(),
      })),
    [segmentOptions]
  );

  const focalItemDropdownSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onFocalItemHeatmapSelectionChange(Number(event.value)));
    },
    [dispatch]
  );

  const segmentationDropdownSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onSegmentationOptionSelectionChange(Number(event.value)));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(onFocalItemChange(focalItems));
  }, [dispatch, focalItems]);

  return (
    <div className={styles.heatmapReportletFilterContainer}>
      <div className={styles.heatmapReportletFilter}>
        <div className={styles.heatmapHeaderWithIcon}>
          <div className={styles.heatmapReportletHeadings}>Focal item</div>
        </div>
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          isDisabled={focalItemOptions.length === 1}
          onSelection={focalItemDropdownSelectionHandler}
          selectOptions={focalItemDropdownOptions}
          selectedValue={activeFocalItem?.nodeNumber.toString()}
        />
      </div>
      {segmentOptions.length > 1 ? (
        <div className={styles.heatmapReportletFilter}>
          <div className={styles.heatmapHeaderWithIcon}>
            <div className={styles.heatmapReportletHeadings}>
              Customer segment
            </div>
            <InformationTooltip displayText="Understand when a customer segment is shopping." />
          </div>
          <SingleSelectDropdown
            height={FormInputHeight.XSmall}
            isDisabled={segmentOptions.length === 1}
            onSelection={segmentationDropdownSelectionHandler}
            selectOptions={segmentsDropdownOptions}
            selectedValue={activeSegmentOption.toString()}
          />
        </div>
      ) : null}
    </div>
  );
};
