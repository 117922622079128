import { AppContext, type Flags } from "@quantium-enterprise/common-ui";
import { useContext } from "react";
import { useDivision } from "./use-division";

export const useFlags = (): Flags => {
  const user = useContext(AppContext).userState.currentUser;
  const division = useDivision();

  return user?.flags[division.name] ?? {};
};
