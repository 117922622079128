import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { EMPTY_HIERARCHY_SLICE_NODE } from "../../common/constants";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getReportLocalParameters } from "../../common/utils/local-parameters/getReportLocalParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import {
  type CrossShopDataTableResponseDto,
  type CrossShopTableItem,
} from "../models/CrossShopDataTableResponseDto";
import { type CrossShopParameterSelections } from "../models/CrossShopParameterSelections";
import { type CustomerCrossShopLocalParametersResponseDto } from "../models/CustomerCrossShopLocalParametersResponseDto";
import { getLocalSelections } from "../utils/getLocalSelections";

export type CustomerCrossShopState = {
  isGetTableRowsQueryLoading: boolean;
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  persistedSelections: CustomerCrossShopPersistedSelections;
  persistedSelectionsLoaded: boolean;
  reportError: boolean;
  reportId: string;
  reportName: string;
  rowSelection: RowSelectionState;
  showSegmentationChartDataLabels: boolean;
  topDrawerTableData: CrossShopDataTableResponseDto;
};

export type CustomerCrossShopPersistedSelections = {
  focalItems: CrossShopTableItem[];
  localSelections: CrossShopParameterSelections;
};

export const initialState: CustomerCrossShopState = {
  localParameters: [],
  localParametersInitialised: false,
  persistedSelections: {
    focalItems: [],
    localSelections: {
      channel: {
        label: "",
        value: "",
      },
      locationHierarchy: EMPTY_HIERARCHY_SLICE_NODE,
      productInteraction: {
        label: "",
        value: "",
      },
      segmentation: [],
      time: "",
      timePeriodLength: "",
    },
  },
  persistedSelectionsLoaded: false,
  reportName: "Customer Cross Shop",
  reportId: "",
  reportError: false,
  rowSelection: {} as RowSelectionState,
  showSegmentationChartDataLabels: false,
  topDrawerTableData: {
    metrics: [],
    tableRows: [],
  },
  isGetTableRowsQueryLoading: true,
};

const getPersistenceKey = (reportId: string) =>
  `customer-cross-shop-${reportId}`;

export const customerCrossShopSlice = createSlice({
  initialState,
  name: "customerCrossShop",
  reducers: {
    onReportError: (state: CustomerCrossShopState) => {
      state.reportError = true;
    },
    onChannelChange: (
      state: CustomerCrossShopState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.channel = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: CustomerCrossShopState,
      action: PayloadAction<PanelOption[]>
    ) => {
      state.persistedSelections.localSelections.segmentation = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: CustomerCrossShopState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localSelections.locationHierarchy =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onProductInteractionChange: (
      state: CustomerCrossShopState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.productInteraction =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onReportSuccess: (
      state: CustomerCrossShopState,
      action: PayloadAction<CustomerCrossShopLocalParametersResponseDto>
    ) => {
      state.reportName = action.payload.reportName;

      state.localParameters = getReportLocalParameters(
        action.payload.localParameters
      );

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localSelections = getLocalSelections(
          state.localParameters
        );
        state.persistedSelections.focalItems = action.payload.defaultFocalItems;
      }

      for (const item of state.persistedSelections.focalItems) {
        state.rowSelection[item.itemCode] = true;
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onTopDrawerTableResponseReceived: (
      state: CustomerCrossShopState,
      action: PayloadAction<CrossShopDataTableResponseDto>
    ) => {
      if (action.payload.tableRows.length > 0) {
        if (
          !state.persistedSelectionsLoaded &&
          state.persistedSelections.focalItems.length < 1
        ) {
          state.persistedSelections.focalItems = action.payload.tableRows
            .slice(0, 3)
            .map((item) => item.item);

          for (const item of state.persistedSelections.focalItems) {
            state.rowSelection = {} as RowSelectionState;
            state.rowSelection[item.itemCode] = true;
          }

          persistSelections(
            getPersistenceKey(state.reportId),
            state.persistedSelections
          );
        }

        state.topDrawerTableData = action.payload;
      } else {
        state.topDrawerTableData = {
          metrics: [],
          tableRows: [],
        };
      }

      state.isGetTableRowsQueryLoading = false;
    },
    setFocalItems: (
      state: CustomerCrossShopState,
      action: PayloadAction<{
        isSelected: boolean;
        selectedItem: CrossShopTableItem;
      }>
    ) => {
      if (action.payload.isSelected) {
        state.persistedSelections.focalItems =
          state.persistedSelections.focalItems.filter(
            (value) => value.itemCode !== action.payload.selectedItem.itemCode
          );
        state.rowSelection[action.payload.selectedItem.itemCode] = false;
      } else {
        state.persistedSelections.focalItems = [
          ...state.persistedSelections.focalItems,
          action.payload.selectedItem,
        ];
        state.rowSelection[action.payload.selectedItem.itemCode] = true;
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onGetTableQueryStarted: (state: CustomerCrossShopState) => {
      state.isGetTableRowsQueryLoading = true;
    },
    toggleSegmentationChartDataLabels: (state: CustomerCrossShopState) => {
      state.showSegmentationChartDataLabels =
        !state.showSegmentationChartDataLabels;
    },
    onReportOpen: (
      state: CustomerCrossShopState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: CustomerCrossShopPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
  },
});

// SELECTORS
// These are for getting data out of the store. They are memoized, so they only recalculate if the state they depend on has changed.
export const selectReportId = createSelector(
  (state: RootState) => state.customerCrossShop.reportId,
  (reportId) => reportId
);

export const selectFocalItems = createSelector(
  (state: RootState) => state.customerCrossShop.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.customerCrossShop.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectChannel = createSelector(
  (state: RootState) =>
    state.customerCrossShop.persistedSelections.localSelections.channel,
  (channel) => channel
);

export const selectLocation = createSelector(
  (state: RootState) =>
    state.customerCrossShop.persistedSelections.localSelections
      .locationHierarchy,
  (locationHierarchy) => locationHierarchy
);

export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.customerCrossShop.persistedSelections.localSelections.segmentation,
  (segmentation) => segmentation
);

export const selectProductInteraction = createSelector(
  (state: RootState) =>
    state.customerCrossShop.persistedSelections.localSelections
      .productInteraction,
  (productInteraction) => productInteraction
);

export const selectTime = createSelector(
  (state: RootState) =>
    state.customerCrossShop.persistedSelections.localSelections.time,
  (time) => time
);

export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.customerCrossShop.persistedSelections.localSelections
      .timePeriodLength,
  (timePeriodLength) => timePeriodLength
);

export const selectLocalSelections = createSelector(
  [
    selectChannel,
    selectLocation,
    selectProductInteraction,
    selectSegmentation,
    selectTime,
    selectTimePeriodLength,
  ],
  (
    channel,
    locationHierarchy,
    productInteraction,
    segmentation,
    time,
    timePeriodLength
  ): CrossShopParameterSelections => ({
    channel,
    locationHierarchy,
    productInteraction,
    segmentation,
    time,
    timePeriodLength,
  })
);

export const {
  onReportError,
  onChannelChange,
  onSegmentationChange,
  onProductInteractionChange,
  onReportSuccess,
  onTopDrawerTableResponseReceived,
  setFocalItems,
  onGetTableQueryStarted,
  onLocationChange,
  toggleSegmentationChartDataLabels,
  onReportOpen,
} = customerCrossShopSlice.actions;

export default customerCrossShopSlice.reducer;
