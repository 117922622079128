import {
  type SelectionDto,
  isListSelectionDto,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import defaultChartImgIcon from "../../assets/tab-icons/bar-chart-black.svg";
import selectedChartImgIcon from "../../assets/tab-icons/bar-chart-white.svg";
import defaultTableImgIcon from "../../assets/tab-icons/table-black.svg";
import selectedTableImgIcon from "../../assets/tab-icons/table-white.svg";
import { ReportButtonTabs } from "../../common/components/ReportTabComponents/ReportButtonTabs";
import { ReportTabItem } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { RepertoireFeatureFlags } from "../constants/repertoire-feature-flags";
import {
  type RepertoireReportletDataRequest,
  useLazyGetRepertoireReportletDataQuery,
} from "../services/repertoire-reportlet-api-slice";
import {
  focalItemTableInitialised,
  selectFocalItems,
  selectLocalParameters,
  selectLocalParameterViewAs,
  selectMetaData,
  selectRepertoireItems,
  selectReportletResponseDto,
  selectTableResponseDto,
  selectedLocalParameterMetric,
  selectedTableSorting,
} from "../services/repertoire-slice";
import { VIEW_AS_SHARE } from "../utils/constants";
import { getLocalParameterSummary } from "../utils/export-utils";
import { getUserSelections } from "../utils/local-parameter-utils";
import styles from "./RepertoireReportlet.module.css";
import { RepertoireReportletChart } from "./reportlet-chart/RepertoireReportletChart";
import { RepertoireReportletTable } from "./reportlet-table/RepertoireReportletTable";
import { csvTransformation } from "./reportlet-table/csvTransformation";

const enum REPERTOIRE_REPORTLET_TABS {
  Chart = "Chart",
  Table = "Table",
}

export const RepertoireReportlet = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const [activeTab, setActiveTab] = useState<string>(
    REPERTOIRE_REPORTLET_TABS.Chart
  );

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[RepertoireFeatureFlags.ReportExport] ?? false;

  const { name: activeDivisionName, locale, currency } = useDivision();
  const { id } = useParams();

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const focalItems = useSelector(selectFocalItems);
  const repertoireItems = useSelector(selectRepertoireItems);
  const { localParametersInitialised, localParameterSelections } = useSelector(
    selectLocalParameters
  );
  const focalItemTableReady = useSelector(focalItemTableInitialised);
  const tableSortingValue = useSelector(selectedTableSorting);
  const reportletDto = useSelector(selectReportletResponseDto);
  const selectedMetric = useSelector(selectedLocalParameterMetric);
  const viewAs = useSelector(selectLocalParameterViewAs);
  const focalItemTableRows = useSelector(selectTableResponseDto);
  const { reportId } = useSelector(selectMetaData);

  const metricDisplayLabel = useMemo(
    () =>
      `${selectedMetric.label}${
        (viewAs.value as string) === VIEW_AS_SHARE ? " - % share" : ""
      }`,
    [selectedMetric, viewAs]
  );

  const levelOfAnalysisName = useMemo(() => {
    const selection =
      reportParameters?.parameterGroupSelections
        .flatMap((item) => item.parameterSelections)
        .find((item) => item.name === "Level of analysis")?.selections[0] ??
      ({} as SelectionDto);

    return isListSelectionDto(selection) ? selection.label : "";
  }, [reportParameters]);

  const dependenciesLoading =
    !activeDivisionName ||
    !id ||
    !localParametersInitialised ||
    !focalItemTableReady;

  const [
    getReportletDataQuery,
    { isFetching: isReportletFetching, isLoading: isReportletLoading },
  ] = useLazyGetRepertoireReportletDataQuery();

  const fetchReportletDataQuery = useCallback(
    async (
      division: string,
      requestPayload: RepertoireReportletDataRequest
    ) => {
      await getReportletDataQuery({ divisionName: division, requestPayload });
    },
    [getReportletDataQuery]
  );

  useEffect(() => {
    if (!dependenciesLoading && focalItems.length && reportId === id) {
      void fetchReportletDataQuery(activeDivisionName, {
        localSelectedValues: getUserSelections(localParameterSelections),
        reportId: id,
        focalItems,
        repertoireItems,
      });
    }
  }, [
    activeDivisionName,
    dependenciesLoading,
    fetchReportletDataQuery,
    focalItems,
    id,
    localParameterSelections,
    repertoireItems,
    reportId,
  ]);

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Repertoire_Table_${localParameterSelections.TimePeriod}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const parameterSummary = useMemo(
    () =>
      getLocalParameterSummary(
        localParameterSelections,
        repertoireItems,
        levelOfAnalysisName
      ),
    [levelOfAnalysisName, localParameterSelections, repertoireItems]
  );

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        reportletDto,
        metricDisplayLabel,
        currencySymbol,
        tableSortingValue,
        focalItemTableRows.focalItemData.map((item) => item.id)
      ),
    [
      reportletDto,
      focalItemTableRows,
      tableSortingValue,
      metricDisplayLabel,
      currencySymbol,
    ]
  );

  return (
    <ReportletAccordion
      subtitle="Understand how your customers are shopping across the selected universe."
      title="Repertoire"
    >
      {activeTab === "Table" && (
        <DataTableOptions
          filename={exportFilename}
          invokeCSVDownload={chartCsvTransformationCallback}
          isFeatureEnabled={isExportEnabled}
          localParameters={parameterSummary}
          reportParameters={reportParameters}
        />
      )}
      <div className={`${activeTab === "Table" && styles.container}`}>
        <ReportButtonTabs
          activeTab={activeTab}
          onClickButtonTabItem={setActiveTab}
        >
          <ReportTabItem
            defaultImgIcon={defaultChartImgIcon}
            label={REPERTOIRE_REPORTLET_TABS.Chart}
            selectedImgIcon={selectedChartImgIcon}
            value={REPERTOIRE_REPORTLET_TABS.Chart}
          >
            <ErrorBoundary>
              <RepertoireReportletChart
                isLoading={
                  isReportletFetching ||
                  isReportletLoading ||
                  dependenciesLoading
                }
                noData={!focalItems.length}
                reportParameters={reportParameters}
              />
            </ErrorBoundary>
          </ReportTabItem>
          <ReportTabItem
            defaultImgIcon={defaultTableImgIcon}
            label={REPERTOIRE_REPORTLET_TABS.Table}
            selectedImgIcon={selectedTableImgIcon}
            value={REPERTOIRE_REPORTLET_TABS.Table}
          >
            <ErrorBoundary>
              <RepertoireReportletTable
                isLoading={
                  isReportletFetching ||
                  isReportletLoading ||
                  dependenciesLoading
                }
                noData={!focalItems.length}
              />
            </ErrorBoundary>
          </ReportTabItem>
        </ReportButtonTabs>
      </div>
    </ReportletAccordion>
  );
};
