import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import {
  type ExportFocalItemRequest,
  type TableRowsRequestDto,
} from "../models/ProductSubstitutabilityTableRowsRequestDto";
import { type TableRowsResponseDto } from "../models/ProductSubstitutabilityTableRowsResponseDto";
import { productSubstitutabilityApiSlice } from "./product-substitutability-api-slice";

export const productSubstitutabilityDataTableApiSlice =
  productSubstitutabilityApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTableRows: builder.query<
        TableRowsResponseDto,
        { division: string; payload: TableRowsRequestDto }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetTableRows/${division}`,
        }),
      }),
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportFocalItemRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `product substitutability focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const { useLazyGetTableRowsQuery, useLazyDownloadTableQuery } =
  productSubstitutabilityDataTableApiSlice;
