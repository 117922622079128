import { formatMetricValue } from "../../common/utils/export-parameter-summary-utils";
import {
  type ContributionBreakdownResponseDto,
  type ContributionMeasuresResponseDto,
} from "../models/ContributionChartDriverDto";
import { getExportColumnHeader } from "../utils/csvTransformUtils";

export const csvMeasuresTransformation = (
  response: ContributionMeasuresResponseDto,
  currencySymbol: string
) => {
  const columnHeaders = ["Contribution breakdown"];
  const csvData = [columnHeaders];
  if (response.data) {
    const header = response.data.label[0];
    columnHeaders.push(
      response.categories[0] === "Volume"
        ? header
        : `${header} (${currencySymbol})`
    );
    for (let index = 0; index < response.categories.length; ++index) {
      const row = [
        response.categories[index],
        formatMetricValue(response.data.values[index][0]),
      ];
      csvData.push(row);
    }
  }

  return csvData;
};

export const csvBreakdownTransformation = (
  response: ContributionBreakdownResponseDto,
  currencySymbol: string,
  selectedTab: string
) => {
  const columnHeaders = [selectedTab];
  const csvData = [columnHeaders];
  if (response.chartData) {
    columnHeaders.push(
      ...response.chartData.map(getExportColumnHeader(currencySymbol, true))
    );
    columnHeaders.push(
      ...response.tableData.map(getExportColumnHeader(currencySymbol, false))
    );
    for (let index = 0; index < response.categories.length; ++index) {
      const row = [
        response.categories[index],
        ...response.chartData.map((data) =>
          formatMetricValue(data.values[index])
        ),
        ...response.tableData.map((data) =>
          formatMetricValue(data.values[index])
        ),
      ];
      csvData.push(row);
    }
  }

  return csvData;
};
