import {
  type TrackingComponent,
  type TrackingEvent,
  FeatureFlag,
  GenericTrackingProperties,
  ReportType,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { type RootState } from "../store";
import styles from "./KeyDriversOverTimeReport.module.css";
import { KeyDriversOverTimeReportlet } from "./components/KeyDriversOverTimeReportlet";
import KeyDriversOverTimeSidePanel from "./components/KeyDriversOverTimeSidePanel";
import KeyDriversOverTimeTopDrawer from "./components/top-drawer/KeyDriversOverTimeTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/key-drivers-over-time-local-parameters-api-slice";
import {
  onMetadataSuccess,
  onReportOpen,
  reset,
  selectLocalParametersInitialised,
} from "./services/key-drivers-over-time-slice";

export const KeyDriversOverTimeReport = () => {
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  // Store
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const { reportName, reportId } = useSelector((state: RootState) => ({
    reportName: state.keyDriversOverTime.metaData.reportName,
    reportId: state.keyDriversOverTime.metaData.reportId,
  }));

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
  }, [id, isTabsEnabled, dispatch]);

  // info panel
  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: id === undefined || !activeDivisionName }
    );

  // Local Parameters Query
  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, activeReportId: string) => {
      await getLocalParameters({
        divisionName: division,
        reportId: activeReportId,
      });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  // Save metadata to the state
  useEffect(() => {
    if (infoPanelSummary) {
      dispatch(
        onMetadataSuccess({
          reportId: infoPanelSummary.id,
          reportName: infoPanelSummary.reportName,
        })
      );
    }
  }, [dispatch, infoPanelSummary]);

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Key drivers over time",
        reportId,
        ...properties,
      })
    );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <KeyDriversOverTimeTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportType={ReportType.KeyDriversOverTime}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <KeyDriversOverTimeSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <div className={styles.reportlet}>
            <KeyDriversOverTimeReportlet infoPanelSummary={infoPanelSummary} />
          </div>
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <KeyDriversOverTimeReport />,
  path: "key-drivers-over-time/:id",
};

export default KeyDriversOverTimeReport;
