import { type GainsAndLossOverviewResponseDto } from "../models/GainsAndLossOverviewReportletDto";
import { type GainsAndLossOverviewReportletRequestDto } from "../models/GainsAndLossOverviewRequestDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossOverviewReportletSlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getGainsAndLossOverviewReportlet: builder.query<
        GainsAndLossOverviewResponseDto,
        {
          division: string;
          payload: GainsAndLossOverviewReportletRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetOverviewReportletData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetGainsAndLossOverviewReportletQuery } =
  gainsAndLossOverviewReportletSlice;
