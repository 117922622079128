import { type TooltipFormatterContextObject } from "highcharts";
import { type Series, type Metric } from "../../models";
import {
  getColorFromDataPoints,
  getLegendNameFromDataPoints,
  getValueFromDataPoints,
} from "../PerformanceTrendChart";
import {
  GroupedItemsTooltip,
  SeriesTypes,
} from "../tooltip/GroupedItemsTooltip";

const getTooltipData = (
  data: TooltipFormatterContextObject,
  series: Series[],
  metric?: Metric
) => {
  const filteredSeries = series.filter(
    (point) => point.metric.name === metric?.name
  );

  const filteredPoints = data.points?.filter(
    (point) => point.series.options.yAxis === metric?.name
  );

  return filteredSeries
    .map((item, index: number) => ({
      color: String(getColorFromDataPoints(index, filteredPoints)),
      name: getLegendNameFromDataPoints(index, filteredPoints),
      value: item.metric.formatter(
        getValueFromDataPoints(index, filteredPoints)
      ),
    }))
    .filter((tooltipItem) => tooltipItem.color !== "null");
};

export const getPerformanceTrendChartFormatter = (
  data: TooltipFormatterContextObject,
  series: Series[],
  primaryMetric?: Metric,
  secondaryMetric?: Metric,
  tooltipPrimaryHeader?: string,
  tooltipSecondaryHeader?: string
) => (
  <GroupedItemsTooltip
    groupedItems={[
      {
        items: getTooltipData(data, series, primaryMetric),
        primaryHeader: primaryMetric?.label.toUpperCase(),
        secondaryHeader: tooltipPrimaryHeader,
        seriesType: SeriesTypes.Line,
      },
      {
        items: getTooltipData(data, series, secondaryMetric),
        primaryHeader: secondaryMetric?.label.toUpperCase(),
        secondaryHeader: tooltipSecondaryHeader,
        seriesType: SeriesTypes.Dash,
      },
    ]}
    topAuxiliaryHeader={data.x?.toString().toUpperCase()}
  />
);
