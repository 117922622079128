import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { type SeriesOptionsType } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  formatDayId,
  formatHourId,
} from "../../../../reports/src/time-of-day-day-of-week/utils/chart-utils";
import { HighchartsCustomTooltip } from "../../highcharts-custom-tooltip/HighchartsCustomTooltip";
import {
  defaultOptions,
  HighchartsReact,
  type HighchartsReactProps,
} from "../highcharts-react/HighchartsReact";
import styles from "./TrendsChart.module.css";

export type ChartDataSeries = {
  colour?: string;
  data: number[];
  name: string;
};

type TrendsChartProps = {
  benchmark?: number;
  categories: number[] | string[];
  chartData: ChartDataSeries[];
  chartDataFormatter: FormatterFunction;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  percentFormatter: FormatterFunction;
  showChartDataLabels?: boolean;
  yAxisLabel: string;
};

export const TrendsChart = ({
  benchmark,
  categories,
  chartData,
  percentFormatter,
  chartDataFormatter,
  onOptionsChanged,
  showChartDataLabels,
  yAxisLabel,
}: TrendsChartProps) => {
  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const convertedCategories = useMemo(
    () =>
      categories.map((item) => {
        if (typeof item === "number") {
          return formatHourId(item);
        } else {
          return formatDayId[item.toUpperCase()];
        }
      }),
    [categories]
  );
  const optionsState: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        borderWidth: 0,
        marginRight: benchmark === undefined ? 10 : 90,
        plotBorderWidth: 0,
        spacingLeft: 0,
        style: {
          fontFamily: `var(--qbit-font-family)`,
        },
      },
      legend: {
        align: "left",
        enabled: true,
        itemStyle: {
          fontWeight: "medium",
        },
        x: 16,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
            symbol: "circle",
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        series: {
          dataLabels: {
            enabled: showChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return chartDataFormatter(this.y);
            },
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
      series: chartData.map(
        (series: ChartDataSeries, index): SeriesOptionsType => ({
          data: series.data.slice(),
          name: series.name,
          color:
            benchmark === index ? "var(--qbit-colour-shade-15)" : series.colour,
          dashStyle: benchmark === index ? "ShortDashDot" : "Solid",
          lineWidth: benchmark === index ? 2 : 3,
          visible: !hiddenSeries.includes(index),
          type: "line",
          yAxis: benchmark === index ? 1 : 0,
          // a second x axis is needed for the double crosshairs and it needs to have at least one series allocated to it
          xAxis: index === 1 ? 1 : 0,
        })
      ),
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsCustomTooltip({
            benchmark,
            chartDataFormatter,
            hasSentiment: false,
            percentFormatter,
            ttData,
            yAxisTitle: yAxisLabel,
          }),
        shared: true,
      },
      xAxis: [
        {
          categories: convertedCategories,
          crosshair: {
            className: "trends-chart-crosshairs",
            color: "var(--qbit-colour-shade-2)",
            zIndex: 0,
          },
          labels: {
            style: {
              fontSize: "1em",
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          title: {
            style: {
              color: "var(--qbit-colour-text-primary)",
            },
          },
          tickLength: 5,
          tickWidth: 1,
          visible: true,
        },
        {
          categories: convertedCategories,
          crosshair: {
            dashStyle: "Dash",
            color: "var(--qbit-colour-shade-4)",
            width: 2,
            zIndex: 1,
          },
          linkedTo: 0,
          visible: false,
        },
      ],
      yAxis: [
        {
          ...defaultOptions.yAxis,
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return chartDataFormatter(this.value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          title: {
            style: {
              fontWeight: "bold",
              color: "var(--qbit-colour-text-primary)",
            },
            text: yAxisLabel,
          },
        },
        {
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return chartDataFormatter(this.value, false, "", true);
            },
          },
          opposite: true,
          title: {
            style: { fontWeight: "bold" },
            text: "Benchmark",
          },
        },
      ],
    }),
    [
      benchmark,
      chartData,
      chartDataFormatter,
      convertedCategories,
      hiddenSeries,
      legendItemClick,
      percentFormatter,
      showChartDataLabels,
      yAxisLabel,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(optionsState);
    }
  }, [onOptionsChanged, optionsState]);

  return (
    <div className={styles.parentContainer}>
      <HighchartsReact options={optionsState} />
    </div>
  );
};
