import { datadogLogs } from "@datadog/browser-logs";

/* eslint-disable no-console */
export const ddLog = (
  message: string,
  context?: object,
  status: "debug" | "error" | "info" | "warn" = "info",
  error?: Error
) => {
  // Log to datadog if in production, otherwise use regular console functions.
  if (import.meta.env.PROD) {
    datadogLogs.logger.log(message, context, status, error);
  } else if (status === "info") {
    console.info(message);
  } else if (status === "debug") {
    console.debug(message);
  } else if (status === "warn") {
    console.warn(message);
  } else {
    console.error(message);
  }
};
/* eslint-enable no-console */
