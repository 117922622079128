import {
  type FolderOrGroupDto,
  type CustomerGroupDtoWithDisplayName,
} from "@quantium-enterprise/common-ui";
import { type PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import { GroupType } from "../../enums/group-type";
import {
  isCustomerGroupWithSharingDto,
  isFolderOrHierarchyGroup,
  isSegmentRow,
} from "../../utilities/util";
import { type SegmentRow } from "../segment-library-table/SegmentLibraryTableCells";
import { CustomerGroupInfoPanel } from "./CustomerGroupInfoPanel";
import { HierarchyGroupInfoPanel } from "./HierarchyGroupInfoPanel";
import { SegmentLibraryInfoPanel } from "./SegmentLibraryInfoPanel";

export type GroupInfoPanelProps = {
  customerGroupTab: CustomerGroupTab;
  focalGroup:
    | CustomerGroupDtoWithDisplayName
    | FolderOrGroupDto
    | SegmentRow
    | undefined;
  onClose: () => void;
  onMove: () => void;
  showPanel: boolean;
};

export const GroupInfoPanel = ({
  children,
  onClose,
  onMove,
  showPanel,
  focalGroup,
  customerGroupTab,
}: PropsWithChildren<GroupInfoPanelProps>) => {
  const { groupType } = useParams();

  switch (groupType) {
    case GroupType.Product:
    case GroupType.Location:
      return (
        <HierarchyGroupInfoPanel
          focalGroup={
            isFolderOrHierarchyGroup(focalGroup) ? focalGroup : undefined
          }
          onClose={onClose}
          onMove={onMove}
          showPanel={showPanel}
        >
          {children}
        </HierarchyGroupInfoPanel>
      );
    case GroupType.Customer:
      if (customerGroupTab === CustomerGroupTab.CustomerGroups) {
        return (
          <CustomerGroupInfoPanel
            focalGroup={
              isCustomerGroupWithSharingDto(focalGroup) ? focalGroup : undefined
            }
            onClose={onClose}
            showPanel={showPanel}
          >
            {children}
          </CustomerGroupInfoPanel>
        );
      } else {
        return (
          <SegmentLibraryInfoPanel
            focalGroup={isSegmentRow(focalGroup) ? focalGroup : undefined}
            onClose={onClose}
            showPanel={showPanel}
          >
            {children}
          </SegmentLibraryInfoPanel>
        );
      }

    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};
