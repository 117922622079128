import {
  type CustomerGroupTypeDto,
  type CustomerGroupWithSharingDto,
} from "@quantium-enterprise/common-ui";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CustomerGroupDetailsModal } from "./CustomerGroupDetailsModal";

type CgDetailsModalTriggerProps = {
  customerGroupId: string;
  customerGroupTypes: CustomerGroupTypeDto[];
  customerGroups: CustomerGroupWithSharingDto[];
};

export const CgDetailsModalTrigger = ({
  customerGroups,
  customerGroupTypes,
  customerGroupId,
}: CgDetailsModalTriggerProps) => {
  const [customerGroupInfo, setCustomerGroupInfo] = useState<
    CustomerGroupWithSharingDto | undefined
  >(undefined);
  const [cgModalVisibility, setCgModalVisibility] = useState<boolean>(false);

  const updateCgModalVisibilityState = useCallback(
    (isVisible: boolean) => {
      setCgModalVisibility(isVisible);
      setCustomerGroupInfo(undefined);
    },
    [setCgModalVisibility, setCustomerGroupInfo]
  );

  const cgModalVisibilityState = useMemo(
    () => ({
      isShown: cgModalVisibility,
      setIsShown: updateCgModalVisibilityState,
    }),
    [cgModalVisibility, updateCgModalVisibilityState]
  );

  return (
    <>
      <Link
        onClick={(event) => {
          event.preventDefault();
          const info = customerGroups.find(
            (group) => group.id === customerGroupId
          );
          if (info) {
            setCustomerGroupInfo(info);
            setCgModalVisibility(true);
          }
        }}
        to=""
      >
        Details
      </Link>
      {cgModalVisibility && customerGroupInfo && (
        <CustomerGroupDetailsModal
          customerGroupInfo={customerGroupInfo}
          customerGroupTypes={customerGroupTypes}
          modalVisbilityState={cgModalVisibilityState}
        />
      )}
    </>
  );
};
