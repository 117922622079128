import { FormInputWidth, QSearchInput } from "@quantium-enterprise/qds-react";
import React from "react";
import {
  openSearchModal,
  selectSearchQuery,
} from "../focal-item-search/fastReportingSearchSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import styles from "./HeaderSearchBox.module.css";

type HeaderSearchBoxProps = {
  placeholder: string;
};

export const HeaderSearchBox = ({ placeholder }: HeaderSearchBoxProps) => {
  const dispatch = useAppDispatch();

  const onFocus = () => dispatch(openSearchModal());
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== "Tab") {
      dispatch(openSearchModal());
    }
  };

  const savedSearchQuery = useAppSelector(selectSearchQuery);

  return (
    // We are hooking onto the events from the child QSearchInput
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={styles.searchBox} onClick={onFocus} onKeyDown={onKeyDown}>
      <QSearchInput
        id="fastReportingHeaderSearchBox"
        onChange={() => {}}
        placeholder={placeholder}
        value={savedSearchQuery}
        width={FormInputWidth.Fill}
      />
    </div>
  );
};

export default HeaderSearchBox;
