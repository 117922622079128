import { type PropsWithChildren } from "react";
import styles from "./CompareMetricsReportlet.module.css";

export type CompareMetricsReportletFilterProps = {
  header?: string;
};

export const CompareMetricsReportletFilter = ({
  children,
  header,
}: PropsWithChildren<CompareMetricsReportletFilterProps>) => (
  <div className={styles.reportletFilter}>
    <div className={styles.headerWithIcon}>
      <div className={styles.reportletHeadings}>{header}</div>
    </div>
    {children}
  </div>
);

export default CompareMetricsReportletFilter;
