import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { useEffect, useMemo } from "react";
import { PURCHASE_ACTIVITY_DATA_TYPE_TABS } from "../models/PurchaseActivityTabs";
import { type TrialAndRepeatDistributionPricingResponseDto } from "../models/TrialAndRepeatDistributionPricingResponseDto";
import { type TrialAndRepeatPurchaseActivityResponseDto } from "../models/TrialAndRepeatPurchaseActivityResponseDto";
import { type PurchaseActivityTableData } from "../utils/generateDataTableColumns";
import { generateDataTableColumns } from "../utils/generateDataTableColumns";
import styles from "./PurchaseActivityDataTable.module.css";

type PurchaseActivityDataTableProps = {
  dataType: string;
  distributionPricingResponse: TrialAndRepeatDistributionPricingResponseDto;
  getTableData: (data: PurchaseActivityTableData[]) => void;
  purchaseActivityResponse: TrialAndRepeatPurchaseActivityResponseDto;
  summaryType: string;
};

export const PurchaseActivityDataTable = ({
  purchaseActivityResponse,
  distributionPricingResponse,
  summaryType,
  dataType,
  getTableData,
}: PurchaseActivityDataTableProps) => {
  const formatter = useFormatter();
  const dates = useMemo(
    () =>
      Array.from(
        new Set([
          ...purchaseActivityResponse.dates,
          ...distributionPricingResponse.dates,
        ])
      ).sort((a: string, b: string) => a.localeCompare(b)),
    [purchaseActivityResponse, distributionPricingResponse]
  );

  // exclude Total row for Share
  const purchaseActivityLegends = useMemo(
    () =>
      dataType === PURCHASE_ACTIVITY_DATA_TYPE_TABS.Share
        ? purchaseActivityResponse.legends.slice(0, -1)
        : purchaseActivityResponse.legends,
    [purchaseActivityResponse, dataType]
  );

  const tableData: PurchaseActivityTableData[] = useMemo(
    () => [
      ...purchaseActivityLegends.map((legend, index) => ({
        header: legend === "All" ? "Total" : legend,
        data: dates.map((date) => {
          const dateIndex = purchaseActivityResponse.dates.indexOf(date);
          if (dateIndex !== -1) {
            return purchaseActivityResponse.data[index][dataType.toLowerCase()]
              .values[summaryType.toLowerCase()][dateIndex];
          }

          return null;
        }),
        format:
          purchaseActivityResponse.data[index][dataType.toLowerCase()].format,
        isSeparatorRow:
          dataType === PURCHASE_ACTIVITY_DATA_TYPE_TABS.Share
            ? index === purchaseActivityLegends.length - 1
            : index === purchaseActivityLegends.length - 2,
      })),
      ...distributionPricingResponse.legends.map((legend, index) => ({
        header: legend,
        data: dates.map((date) => {
          const dateIndex = distributionPricingResponse.dates.indexOf(date);
          if (dateIndex !== -1) {
            return distributionPricingResponse.values[index][dateIndex];
          }

          return null;
        }),
        format: legend.includes("Store")
          ? MetricTypes.Integer
          : MetricTypes.Currency,
      })),
    ],
    [
      purchaseActivityResponse,
      distributionPricingResponse,
      purchaseActivityLegends,
      dataType,
      summaryType,
      dates,
    ]
  );

  useEffect(() => {
    getTableData(tableData);
  }, [tableData, getTableData]);

  return (
    <div className={styles.summaryTable}>
      <BasicTable
        columns={generateDataTableColumns(dates, formatter)}
        data={tableData}
        pinFirstColumn
      />
    </div>
  );
};
