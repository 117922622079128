import { type SelectionDto } from "./selection-dto";

export type ListSelectionDto = {
  label: string;
  value: string;
};

export const isListSelectionDto = (
  selectionObject: SelectionDto
): selectionObject is ListSelectionDto => "value" in selectionObject;
