import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { BufferSelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getBufferParameterState } from "../../parameters/buffer/BufferParameterState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelBufferProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelBuffer = ({
  parameterDto,
}: SummaryPanelBufferProps) => {
  const buffer = useAppSelector(
    getBufferParameterState(parameterDto.id)
  ).buffer;

  return <BufferSelectionSummary buffer={buffer} />;
};
