import { partition } from "@quantium-enterprise/common-ui";
import { isGroupParameterState } from "../parameters/groups/GroupParameterState";
import { isHierarchyState } from "../parameters/hierarchy/HierarchyState";
import { type GroupState } from "./GroupState";
import { type ParameterState } from "./ParameterState";

export const getGroupIsValid = (groupState: GroupState): boolean =>
  typeof groupState.isValid === "boolean"
    ? groupState.isValid
    : groupState.isValid.every((parameter) => parameter.isValid);

export const validateGroupSelectionCount = (
  parameterStates: ParameterState[],
  minSelections?: number,
  maxSelections?: number
): {
  isMaxGroupSelectionsReached: boolean;
  isValidGroupParameter: boolean;
} => {
  let selectedCount = 0;
  const minValue = minSelections ?? 0;
  const maxValue = maxSelections ?? Number.MAX_SAFE_INTEGER;

  for (const parameterState of parameterStates) {
    if (isHierarchyState(parameterState)) {
      selectedCount += parameterState.selectedRows.length;
    }

    if (isGroupParameterState(parameterState)) {
      selectedCount += parameterState.confirmedSelections.length;
    }
  }

  return {
    isValidGroupParameter:
      selectedCount >= minValue && selectedCount <= maxValue,
    isMaxGroupSelectionsReached: selectedCount >= maxValue,
  };
};

export const validateExcludedParameters = (
  parameterStates: ParameterState[],
  parameterIdToStates: Record<string, ParameterState>
) => {
  const validOptions: boolean[] = [];
  for (const parameterState of parameterStates) {
    let isValid = parameterState.isValid;

    const dependency = parameterState.parameterConfig.attributes.dependency;

    if (dependency) {
      const dependencyParameterState =
        parameterIdToStates[dependency.parameterId];

      const isDependencyValid = dependencyParameterState.isValid;

      if (
        !isDependencyValid &&
        parameterState.parameterConfig.attributes
          .validateOnlyIfDependencyIsValid
      ) {
        isValid = true;
      }
    }

    validOptions.push(isValid);
  }

  return validOptions.every(Boolean);
};

export const validateParameterGroup = (
  groupState: GroupState,
  parameterStates: ParameterState[],
  parameterIdToStates: Record<string, ParameterState>
) => {
  // For now assume if there are global min and max selections set then we do not check each param within group is valid
  const hasParameterGroupOptionsSet =
    groupState.minSelections !== undefined ||
    groupState.maxSelections !== undefined;

  if (hasParameterGroupOptionsSet) {
    const [excludedParameters, groupParameters] = partition<ParameterState>(
      parameterStates,
      (parameterState) =>
        parameterState.parameterConfig.attributes
          .excludeFromParameterGroupValidation
    );

    if (groupParameters.length > 0) {
      const { isValidGroupParameter, isMaxGroupSelectionsReached } =
        validateGroupSelectionCount(
          groupParameters,
          groupState.minSelections,
          groupState.maxSelections
        );

      const isValidParameters = validateExcludedParameters(
        excludedParameters,
        parameterIdToStates
      );

      for (const groupParameter of groupParameters) {
        groupParameter.maxGroupSelectionsReached = isMaxGroupSelectionsReached;
      }

      return isValidGroupParameter && isValidParameters;
    }
  }

  return validateExcludedParameters(parameterStates, parameterIdToStates);
};

export const validateGroupState = (
  groupState: GroupState,
  parameterIdToStates: Record<string, ParameterState>
): {
  isGroupValid: boolean;
  isValid: Array<{ isValid: boolean; parameters: string[] }> | boolean;
} => {
  const parameterStates = groupState.parameters.map(
    (element) => parameterIdToStates[element]
  );

  if (typeof groupState.isValid === "boolean") {
    const isValid = validateParameterGroup(
      groupState,
      parameterStates,
      parameterIdToStates
    );

    return { isValid, isGroupValid: isValid };
  }

  const groupValidState = groupState.isValid;

  for (const subGroup of groupValidState) {
    const subGroupParameterStates = parameterStates.filter((state) =>
      subGroup.parameters.includes(state.parameterConfig.id)
    );

    subGroup.isValid = validateParameterGroup(
      groupState,
      subGroupParameterStates,
      parameterIdToStates
    );
  }

  return {
    isValid: groupValidState,
    isGroupValid: groupValidState.every((group) => group.isValid),
  };
};
