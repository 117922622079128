import {
  ParameterId,
  type ParameterDto,
  isTimePeriodParameterOption,
} from "@quantium-enterprise/common-ui";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  leadPeriodSelected,
  startDateSelected,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { getTimePeriodState } from "./TimePeriodState";
import { TimePeriod } from "./components/TimePeriod";
import { TimePeriodOptions } from "./components/TimePeriodConstants";
import {
  convertParametersToCustomPeriodOptions,
  dateToIsoString,
  timestampToDate,
} from "./utilities";

type LeadPeriodParameterProps = {
  parameterDto: ParameterDto;
};

export const LeadPeriodParameter = ({
  parameterDto,
}: LeadPeriodParameterProps) => {
  const dispatch = useDispatch();

  const focusPeriodState = useAppSelector(
    getTimePeriodState(ParameterId.FocusPeriod)
  );
  const leadPeriodState = useAppSelector(getTimePeriodState(parameterDto.id));
  const { wizard } = useSelector((state: RootState) => ({
    wizard: state.wizard,
  }));

  const focusPeriodStartDate = focusPeriodState
    ? timestampToDate(focusPeriodState.startDate)
    : undefined;
  const leadPeriodStartDate = leadPeriodState
    ? timestampToDate(leadPeriodState.startDate)
    : undefined;
  const leadPeriodEndDate = leadPeriodState
    ? timestampToDate(leadPeriodState.endDate)
    : undefined;

  const maxPriorWeeks = useMemo(() => {
    if (!focusPeriodState) return 0;
    const options = focusPeriodState.parameterConfig.options[0].options ?? [];

    if (!focusPeriodStartDate) return options.length;

    return options.filter((op) =>
      isTimePeriodParameterOption(op)
        ? op.startDate < focusPeriodState.startDate
        : op
    ).length;
  }, [focusPeriodStartDate, focusPeriodState]);

  const timePeriodOptions = useMemo(() => {
    if (!leadPeriodState) return [];
    const options = leadPeriodState.options;

    if (maxPriorWeeks === 0)
      return options.map((op) => {
        if (op.value !== "PriorPeriod-0") return { ...op, disabled: true };

        return op;
      });

    return options.map((op) => {
      if (op.value.includes("PriorPeriod")) {
        const numberOfWeeks = Number(op.value.split("-")[1]);

        if (numberOfWeeks > maxPriorWeeks) return { ...op, disabled: true };

        return op;
      } else {
        return op;
      }
    });
  }, [leadPeriodState, maxPriorWeeks]);

  const customTimePeriodOptions = useMemo(() => {
    const options = convertParametersToCustomPeriodOptions(
      parameterDto.options
    );

    if (!focusPeriodStartDate) return options;

    return options.filter((op) => op.weekStartDate < focusPeriodStartDate);
  }, [parameterDto.options, focusPeriodStartDate]);

  if (!focusPeriodState || !leadPeriodState) {
    return null;
  }

  const isTabVisited = wizard.visitedTabs[leadPeriodState.parameterGroup];

  const customTimePeriodOption = leadPeriodState.options.find(
    (lp) => lp.value === TimePeriodOptions.CUSTOM_PERIOD
  );

  const timePeriodOnChangeHandler = (newTimePeriod: string) => {
    dispatch(
      leadPeriodSelected({
        parameter: parameterDto.id,
        leadPeriodValue: newTimePeriod,
      })
    );
  };

  const startDateHandler = (newStartDate: string) => {
    dispatch(
      startDateSelected({
        parameter: parameterDto.id,
        startDate: dateToIsoString(timestampToDate(newStartDate)),
      })
    );
  };

  return (
    <TimePeriod
      customTimePeriodOption={customTimePeriodOption}
      customTimePeriodOptions={customTimePeriodOptions}
      endDate={leadPeriodEndDate}
      endDateHandler={() => {}}
      errors={leadPeriodState.errors}
      infoIcon={parameterDto.description}
      isDisabled={focusPeriodStartDate === undefined}
      isEndDateSelectionVisible={false}
      isNumberOfWeeksTagVisible={false}
      isVisited={isTabVisited}
      label={parameterDto.name}
      maxEndDate={undefined}
      maxStartDate={leadPeriodEndDate}
      minEndDate={leadPeriodStartDate}
      minStartDate={undefined}
      onChange={timePeriodOnChangeHandler}
      options={timePeriodOptions}
      startDate={leadPeriodStartDate}
      startDateHandler={startDateHandler}
      timePeriod={leadPeriodState.value}
      weeks={leadPeriodState.weeks}
    />
  );
};
