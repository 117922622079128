import {
  ddLog,
  HierarchyType,
  type NodeExpansionRequestDto,
  type HierarchyItem,
  useLazyExpandQuery,
  getHierarchyItemTransactionSources,
  getPreferredTransactionSource,
  LocationHierarchy,
  ParameterId,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Checkbox,
  Tag,
  TagVariant,
  Toggle,
  ToggleSize,
} from "@quantium-enterprise/qds-react";
import {
  type Updater,
  type RowSelectionState,
  type Row,
  type HeaderContext,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import classNames from "classnames";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHierarchyNodeExpanding,
  hierarchyNodeExpanded,
  onHierarchyChildNodesReceived,
  selectHierarchyItems,
  selectHierarchyExpandedRows,
  selectHierarchySelectedRows,
  selectHierarchyDisabledLevelShortNames,
  onHierarchyNodeSelection,
  selectIsDataEntitlementsShown,
  selectTransactionSources,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./LocationHierarchyParameter.module.scss";

const PAGE_SIZE = 500;
const hierarchyType = HierarchyType.Location;
const parameterType = ParameterId.LocationHierarchy;

export type LocationHierarchyParameterItem = HierarchyItem & {
  isExpanding?: boolean;
  isMoreRow?: boolean;
  loadMoreParentRow?: LocationHierarchyParameterItem;
  parentRow?: LocationHierarchyParameterItem;
  subRows?: LocationHierarchyParameterItem[];
};

export type LocationHierarchyParameterTableProps = {
  isSuccess: boolean;
};

export const LocationHierarchyParameterTable = ({
  isSuccess,
}: LocationHierarchyParameterTableProps) => {
  const dispatch = useDispatch();
  const { name: division, transactionSources: availableTransactionSources } =
    useDivision();
  const [rowsPagination, setRowsPagination] = useState<Record<string, number>>(
    {}
  );

  const featureFlags = useFlags();

  const [isSelectedItemsShown, setIsSelectedItemsShown] = useState(false);

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(parameterType, state)
  );

  const selectedRows = useSelector((state: RootState) =>
    selectHierarchySelectedRows(parameterType, state)
  );

  const selectedItems = useMemo(
    () =>
      selectedRows
        .map((row) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { parent, subRows, parentRow, ...newRow } = row;
          return { ...newRow, isLeaf: true };
        })
        .sort((a, b) => Number(a.code) - Number(b.code))
        .sort((a, b) => a.depth - b.depth),
    // We only want deselected items to update when toggle is clicked
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSelectedItemsShown]
  );

  const expandedRows = useSelector((state: RootState) =>
    selectHierarchyExpandedRows(parameterType, state)
  );

  const disabledLevelShortNames = useSelector((state: RootState) =>
    selectHierarchyDisabledLevelShortNames(parameterType, state)
  );

  const isDataEntitlementsShown = useSelector((state: RootState) =>
    selectIsDataEntitlementsShown(state)
  );

  const reportTransactionSources = useSelector((state: RootState) =>
    selectTransactionSources(state)
  );

  const getNodeId = useMemo(
    () => (shortName: string, code: string) => `${shortName}.${code}`,
    []
  );

  const someSelectedItemsMandatory = useMemo(
    () =>
      selectedItems.some((item) =>
        disabledLevelShortNames.includes(item.shortName)
      ),
    [disabledLevelShortNames, selectedItems]
  );

  const createRowId = useMemo(
    () => (row: LocationHierarchyParameterItem) =>
      row.loadMoreParentRow?.parent
        ? `${getNodeId(
            row.loadMoreParentRow.parent.shortName,
            row.loadMoreParentRow.parent.code
          )}-load-more`
        : getNodeId(row.shortName, row.code),
    [getNodeId]
  );

  const updatePaginationState = useCallback(
    (shortName: string, code: string) => {
      setRowsPagination((pageState) => {
        const rowId = getNodeId(shortName, code);
        const newPageState = { ...pageState };
        newPageState[rowId] = newPageState[rowId] ? newPageState[rowId] + 1 : 1;

        return newPageState;
      });
    },
    [getNodeId]
  );

  const [triggerExpandQuery] = useLazyExpandQuery();

  const fetchChildNodesData = useCallback(
    async (payload: NodeExpansionRequestDto) => {
      try {
        const result = await triggerExpandQuery({
          division,
          hierarchyType,
          payload,
        }).unwrap();

        if (result.count > 0) {
          dispatch(
            onHierarchyChildNodesReceived({
              childNodes: result,
              parameterType,
            })
          );

          const parentNodeShortName = result.results[0].parent.shortName;
          const parentNodeCode = result.results[0].parent.code;

          updatePaginationState(parentNodeShortName, parentNodeCode);

          dispatch(
            setHierarchyNodeExpanding({
              nodeCode: parentNodeCode,
              nodeShortName: parentNodeShortName,
              parameterType,
            })
          );
        }
      } catch (error) {
        ddLog("Error retrieving children nodes", {}, "error", error as Error);
      }
    },
    [dispatch, division, triggerExpandQuery, updatePaginationState]
  );

  const expandAndFetchChildNodes = useCallback(
    (nodeCode: string, nodeShortName: string) => {
      dispatch(
        setHierarchyNodeExpanding({
          nodeCode,
          nodeShortName,
          parameterType,
        })
      );

      fetchChildNodesData({
        page: 0,
        pageSize: PAGE_SIZE,
        parent: {
          code: nodeCode,
          shortName: nodeShortName,
        },
      }).catch((error) => {
        ddLog("Error retrieving children nodes", {}, "error", error);
      });
    },
    [dispatch, fetchChildNodesData]
  );

  useEffect(() => {
    if (isSuccess && items.length === 1 && items[0].subRows === undefined) {
      expandAndFetchChildNodes(items[0].code, items[0].shortName);
    }
    // configure dependency array to trigger auto expansion only once on the top node
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, items.length, items[0]?.subRows, expandAndFetchChildNodes]);

  const getNewExpandedRows = useMemo(
    () => (row: Row<LocationHierarchyParameterItem>) => {
      const expandedRowIndex = expandedRows.findIndex(
        (expandedRow) =>
          expandedRow.code === row.original.code &&
          expandedRow.shortName === row.original.shortName
      );

      let newExpandedRows = [...expandedRows];

      if (expandedRowIndex === -1) {
        newExpandedRows = [...expandedRows, row.original];
      } else {
        newExpandedRows = expandedRows.filter(
          (expandedRow) =>
            getNodeId(expandedRow.shortName, expandedRow.code) !==
            getNodeId(row.original.shortName, row.original.code)
        );
      }

      return newExpandedRows;
    },
    [expandedRows, getNodeId]
  );

  const onToggleExpansionHandler = useCallback(
    (row: Row<LocationHierarchyParameterItem>) => {
      row.toggleExpanded();

      const newExpandedRows = getNewExpandedRows(row);

      dispatch(
        hierarchyNodeExpanded({
          expandedRows: newExpandedRows,
          parameter: parameterType,
        })
      );

      if (row.subRows.length === 0) {
        expandAndFetchChildNodes(row.original.code, row.original.shortName);
      }
    },
    [dispatch, expandAndFetchChildNodes, getNewExpandedRows]
  );

  const getNewSelectedRows = useMemo(
    () => (row: Row<LocationHierarchyParameterItem>) => {
      // row.getIsSelected provides the opposite state for checkbox
      const isChecked = !row.getIsSelected();

      // Filter out the current row add it back in if checked later
      let newSelectedRows: HierarchyItem[] = selectedRows.filter(
        (selectedRow) =>
          getNodeId(selectedRow.shortName, selectedRow.code) !==
          getNodeId(row.original.shortName, row.original.code)
      );

      if (isChecked) {
        newSelectedRows = [...newSelectedRows, row.original];
      }

      return newSelectedRows;
    },
    [getNodeId, selectedRows]
  );

  const onToggleSelectionHandler = useCallback(
    (row: Row<LocationHierarchyParameterItem>) => {
      row.getToggleSelectedHandler();

      const newSelectedRows = getNewSelectedRows(row);

      dispatch(
        onHierarchyNodeSelection({
          nodeSelection: newSelectedRows,
          parameterType,
        })
      );
    },
    [dispatch, getNewSelectedRows]
  );

  const onMoreClickedHandler = async (
    row: Row<LocationHierarchyParameterItem>
  ) => {
    if (row.original.loadMoreParentRow?.parent) {
      const parentNodeCode = row.original.loadMoreParentRow.parent.code;
      const parentNodeShortName =
        row.original.loadMoreParentRow.parent.shortName;
      const nextPage =
        rowsPagination[getNodeId(parentNodeShortName, parentNodeCode)];

      await fetchChildNodesData({
        page: nextPage,
        pageSize: PAGE_SIZE,
        parent: {
          code: parentNodeCode,
          shortName: parentNodeShortName,
        },
      });
    }
  };

  const hierarchyItemHeader = useCallback(
    ({ table }: HeaderContext<LocationHierarchyParameterItem, unknown>) => (
      <div className={styles.locationHierarchyParameterTableHeader}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isSelectedItemsShown && (
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              label=""
              name="allItemsSelectedCheckbox"
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          )}
          <div
            className={classNames(styles.headerText, {
              [styles.spacer]: !isSelectedItemsShown,
            })}
          >
            Location
          </div>
        </div>
        {Boolean(
          featureFlags[FeatureFlag.LocationHierarchySelectedItemsToggle]
        ) &&
          !someSelectedItemsMandatory && (
            <div className={styles.selectedItemsToggle}>
              <Toggle
                checked={isSelectedItemsShown}
                disabled={selectedRows.length === 0 && !isSelectedItemsShown}
                label="Show selected item(s)"
                onClick={() => setIsSelectedItemsShown((previous) => !previous)}
                size={ToggleSize.XSmall}
              />
              <Tag
                className={classNames(styles.selectedItemsCount, {
                  [styles.selectedItemsCountDisabled]:
                    selectedRows.length === 0,
                  [styles.selectedItemsCountEnabled]: selectedRows.length,
                })}
                text={selectedRows.length.toString()}
                variant={TagVariant.Lozenge}
              />
            </div>
          )}
      </div>
    ),
    [
      isSelectedItemsShown,
      setIsSelectedItemsShown,
      selectedRows,
      featureFlags,
      someSelectedItemsMandatory,
    ]
  );

  const hierarchyItemCell = useCallback(
    ({
      row,
      getValue,
    }: CellContext<LocationHierarchyParameterItem, unknown>) => {
      const itemTransactionSources = getHierarchyItemTransactionSources(
        row.original
      );
      const displayedTransactionSource = getPreferredTransactionSource(
        reportTransactionSources,
        itemTransactionSources
      );
      // prepend code for stores only
      const displayValue =
        row.original.shortName === LocationHierarchy.Store
          ? `${row.original.code} - ${String(getValue())}`
          : String(getValue());

      return (
        <ExpandableNameCell
          canExpand={row.subRows.length >= 0 && !row.original.isLeaf}
          depth={row.depth}
          handleToggleExpanded={() => onToggleExpansionHandler(row)}
          handleToggleSelected={() => onToggleSelectionHandler(row)}
          hideTooltip={
            disabledLevelShortNames.includes(row.original.shortName) &&
            row.getIsSelected()
          }
          isCheckboxDisabled={
            displayedTransactionSource === null ||
            disabledLevelShortNames.includes(row.original.shortName)
          }
          isCompact
          isExpanded={row.subRows.length > 0 && row.getIsExpanded()}
          isExpanding={row.original.isExpanding}
          isSelected={row.getIsSelected()}
          name={row.original.name}
          shortName={row.original.shortName}
          type={row.original.type}
          value={displayValue}
        />
      );
    },
    [
      disabledLevelShortNames,
      onToggleExpansionHandler,
      onToggleSelectionHandler,
      reportTransactionSources,
    ]
  );

  const transactionSourceHeader = useCallback(() => <span>Dataset</span>, []);

  const transactionSourceCell = useCallback(
    ({ row }: CellContext<LocationHierarchyParameterItem, unknown>) => {
      const itemTransactionSources = getHierarchyItemTransactionSources(
        row.original
      );
      const displayedTransactionSource = getPreferredTransactionSource(
        reportTransactionSources,
        itemTransactionSources
      )?.[0];

      return (
        <span className={styles.transactionSourceCell}>
          <TransactionSourceIcon
            availableTransactionSources={availableTransactionSources}
            greyedOut={!row.getIsSelected()}
            transactionSource={displayedTransactionSource}
          />
        </span>
      );
    },
    [availableTransactionSources, reportTransactionSources]
  );

  const columns = useMemo<Array<ColumnDef<LocationHierarchyParameterItem>>>(
    () =>
      isDataEntitlementsShown
        ? [
            {
              accessorKey: "name",
              cell: hierarchyItemCell,
              header: hierarchyItemHeader,
            },
            {
              accessorKey: "transactionSource",
              cell: transactionSourceCell,
              header: transactionSourceHeader,
            },
          ]
        : [
            {
              accessorKey: "name",
              cell: hierarchyItemCell,
              header: hierarchyItemHeader,
            },
          ],
    [
      transactionSourceCell,
      transactionSourceHeader,
      hierarchyItemCell,
      hierarchyItemHeader,
      isDataEntitlementsShown,
    ]
  );

  const handleRowSelectionsChange = (
    newRowSelections: Updater<RowSelectionState>
  ) => {
    const selectionIds = Object.keys(newRowSelections);

    const newSelectedRows = items.filter((item) =>
      selectionIds.includes(item.code)
    );

    dispatch(
      onHierarchyNodeSelection({
        nodeSelection: newSelectedRows,
        parameterType,
      })
    );
  };

  const rowSelectionState = useMemo(() => {
    const newRowSelectionState: Record<string, boolean> = {};

    for (const selectedRow of selectedRows) {
      newRowSelectionState[getNodeId(selectedRow.shortName, selectedRow.code)] =
        true;
    }

    return newRowSelectionState;
  }, [getNodeId, selectedRows]);

  const rowExpansionState = useMemo(() => {
    const newRowExpansionState: Record<string, boolean> = {};

    for (const expandedRow of expandedRows) {
      newRowExpansionState[getNodeId(expandedRow.shortName, expandedRow.code)] =
        true;
    }

    return newRowExpansionState;
  }, [expandedRows, getNodeId]);

  return (
    <div className={styles.locationHierarchyParameter}>
      <ReportHierarchyTableWrapper isSuccess={isSuccess}>
        <ReportHierarchyTable
          // FIXME: should actually be optional and should not allow resizing for single column
          // https://jira.quantium.com.au/browse/CO3-1855
          className={styles.table}
          columnResizeMode="onChange"
          columns={columns}
          compactRows
          data={isSelectedItemsShown ? selectedItems : items}
          depthPadding={50}
          disableSorting
          getRowId={(row: LocationHierarchyParameterItem) => createRowId(row)}
          getSubRows={(row) => row.subRows}
          // FIXME: should actually be optional and should not be passed in if not needed
          // https://jira.quantium.com.au/browse/CO3-1856
          handleMoreClicked={async (row: Row<LocationHierarchyParameterItem>) =>
            await onMoreClickedHandler(row)
          }
          moreText="Load More..."
          rowExpandedState={rowExpansionState}
          rowSelectionState={rowSelectionState}
          setRowSelection={handleRowSelectionsChange}
        />
      </ReportHierarchyTableWrapper>
    </div>
  );
};
