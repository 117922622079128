import {
  type CustomerGroupWithSharingDto,
  type FolderOrGroupDto,
} from "@quantium-enterprise/common-ui";
import { type SegmentRow } from "../components/segment-library-table/SegmentLibraryTableCells";

export const isWithSharingDto = (
  object:
    | CustomerGroupWithSharingDto
    | FolderOrGroupDto
    | SegmentRow
    | undefined
): object is CustomerGroupWithSharingDto | FolderOrGroupDto =>
  object !== undefined && Object.hasOwn(object, "sharedWithUserIds");

export const isCustomerGroupWithSharingDto = (
  object:
    | CustomerGroupWithSharingDto
    | FolderOrGroupDto
    | SegmentRow
    | undefined
): object is CustomerGroupWithSharingDto =>
  object !== undefined && Object.hasOwn(object, "segments");

export const isFolderOrHierarchyGroup = (
  object:
    | CustomerGroupWithSharingDto
    | FolderOrGroupDto
    | SegmentRow
    | undefined
): object is FolderOrGroupDto =>
  object !== undefined && Object.hasOwn(object, "parentId");

export const isSegmentRow = (
  object:
    | CustomerGroupWithSharingDto
    | FolderOrGroupDto
    | SegmentRow
    | undefined
): object is SegmentRow =>
  object !== undefined && Object.hasOwn(object, "customerGroupId");
