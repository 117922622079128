import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { ListParameter } from "../list/ListParameter";
import { ListParameterSelectionType } from "../list/ListParameterSelectionType";

type ChannelParameterProps = {
  parameterDto: ParameterDto;
};

export const ChannelParameter = ({ parameterDto }: ChannelParameterProps) => {
  const listSelectionType =
    parameterDto.attributes.minSelections === 1 &&
    parameterDto.attributes.maxSelections === 1
      ? ListParameterSelectionType.RadioButton
      : ListParameterSelectionType.CheckboxWithDefault;

  return (
    <ListParameter
      parameterDto={parameterDto}
      selectionType={listSelectionType}
    />
  );
};
