import {
  type DragEndEvent,
  type DragStartEvent,
  type DragMoveEvent,
  type DragOverEvent,
  type DragCancelEvent,
  DndContext,
  DragOverlay,
  closestCorners,
} from "@dnd-kit/core";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import classnames from "classnames";
import styles from "./StructureDragAndDrop.module.css";
import {
  DragAndDropContainer,
  DragAndDropContainerType,
} from "./container/DragAndDropContainer";
import { DroppableZone } from "./droppable-zone/DroppableZone";
import { HierarchyItem } from "./item/HierarchyItem";
import { ItemList } from "./item/ItemList";
import { type Item } from "./models/Item";
import { type Zone } from "./models/Zone";

export type StructureDragAndDropProps = {
  activeItem: Item | undefined;
  className?: string;
  description: string;
  items: Item[];
  maxDisplayItems?: number;
  onDragCancel?: (event: DragCancelEvent) => void;
  onDragEnd?: (event: DragEndEvent) => void;
  onDragMove?: (event: DragMoveEvent) => void;
  onDragOver?: (event: DragOverEvent) => void;
  onDragStart?: (event: DragStartEvent) => void;
  onRemove: (item: Item, zone: Zone) => void;
  title: string;
  zones: Zone[];
};

export const StructureDragAndDrop = ({
  title,
  description,
  activeItem,
  items,
  zones,
  onRemove,
  onDragStart,
  onDragEnd,
  onDragMove,
  onDragOver,
  onDragCancel,
  maxDisplayItems,
  className,
}: StructureDragAndDropProps) => (
  <div className={classnames(styles.dndContainer, className)}>
    <DndContext
      collisionDetection={closestCorners}
      onDragCancel={onDragCancel}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
    >
      <DragAndDropContainer type={DragAndDropContainerType.Left}>
        <ItemList
          heading="Hierarchy"
          items={items.filter(
            (item) => !item.type.includes(HierarchyItemType.Attribute)
          )}
          max={maxDisplayItems}
          tooltipText="Available hierarchy levels"
        />
        <ItemList
          heading="Attributes"
          items={items.filter((item) =>
            item.type.includes(HierarchyItemType.Attribute)
          )}
          max={maxDisplayItems}
          tooltipText="Available attributes"
        />
        <DragOverlay>
          {activeItem ? (
            <HierarchyItem {...{ ...activeItem, dragActive: true }} />
          ) : null}
        </DragOverlay>
      </DragAndDropContainer>
      <DragAndDropContainer
        description={description}
        title={title}
        type={DragAndDropContainerType.Right}
      >
        {zones.map((zone, index) => {
          const canDrop = activeItem?.type
            ? zone.accepts.includes(activeItem.type) && !zone.item
            : false;

          // FIXME: this is grabbing the token from Qbit in string format of "16px", removing the pixel unit and doing multiplcation with it
          const medium = Number.parseInt(
            tokens.space.medium.split("px")[0],
            10
          );
          const xxsmall = Number.parseInt(
            tokens.space.xxsmall.split("px")[0],
            10
          );

          const style = {
            marginLeft: `${Math.max(index, 1) * medium + xxsmall * index}px`,
          } as React.CSSProperties;

          return (
            <DroppableZone
              key={zone.id}
              newEntry={index !== 0}
              onRemove={onRemove}
              style={style}
              validDropLocation={canDrop}
              zone={zone}
            />
          );
        })}
      </DragAndDropContainer>
    </DndContext>
  </div>
);
