import {
  type HierarchicalTableRow,
  type TableRow,
} from "../models/DataTableDto";

// recursive breadth first search
export const findNode = (
  data: HierarchicalTableRow[],
  nodeNumber: number
): HierarchicalTableRow | undefined => {
  if (data.length === 0) {
    return undefined;
  }

  const subRows: HierarchicalTableRow[] = [];
  for (const node of data) {
    if (node.hierarchyItem.nodeNumber === nodeNumber) {
      return node;
    } else {
      subRows.push(...node.subRows);
    }
  }

  return findNode(subRows, nodeNumber);
};

// build new array for table data using new data
export const rebuildNestedRows = (
  data: HierarchicalTableRow[],
  newData: TableRow[]
): HierarchicalTableRow[] => {
  const newNodeDataByNodeNumber: {
    [nodeNumber: number]: TableRow;
  } = {};
  for (const node of newData) {
    newNodeDataByNodeNumber[node.nodeNumber] = node;
  }

  const recursiveRebuild = (
    dataToUpdate: HierarchicalTableRow[]
  ): HierarchicalTableRow[] =>
    dataToUpdate.map((node) => {
      const newNodeData =
        newNodeDataByNodeNumber[node.hierarchyItem.nodeNumber];
      const newSubRows = recursiveRebuild(node.subRows);
      return {
        ...node,
        promoWeeks: newNodeData.promoWeeks,
        subRows: newSubRows,
      };
    });

  return recursiveRebuild(data);
};

export const getAllNodeNumbers = (data: HierarchicalTableRow[]): number[] => {
  const getAllNodeNumbersRecursive = (
    dataToProcess: HierarchicalTableRow[],
    nodeNumbers: number[]
  ): number[] => {
    const subRows: HierarchicalTableRow[] = [];
    for (const node of dataToProcess) {
      subRows.push(...node.subRows);
      nodeNumbers.push(node.hierarchyItem.nodeNumber);
    }

    return subRows.length === 0
      ? nodeNumbers
      : getAllNodeNumbersRecursive(subRows, nodeNumbers);
  };

  return getAllNodeNumbersRecursive(data, []);
};
