import {
  type HeaderContext,
  type ColumnDef,
  type CellContext,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useMemo } from "react";
import { type MiniFocalTableItem } from "../../models/MiniFocalTableItem";
import { type MiniFocalTableMetric } from "../../models/MiniFocalTableMetric";
import { type MiniFocalTableRow } from "../../models/MiniFocalTableRow";
import styles from "./CrossShopInteractionsReportletMiniFocalItemTable.module.css";
import { ItemTable, ItemTableVariant } from "./ItemTable";
import { ItemTableCellFormatter } from "./ItemTableCellFormatter";
import { MiniFocalItemTableItemDescriptionCellFormatter } from "./MiniFocalItemTableItemDescriptionCellFormatter";

const ItemDescriptionHeader = () => <span>Item description</span>;

const MetricHeader = ({ displayName }: { displayName: string }) => (
  <span className={styles.metricHeader}>{displayName}</span>
);

export type CrossShopInteractionsReportletMiniFocalItemTableProps = {
  className?: string;
  hideMetricColumns?: boolean;
  metrics: MiniFocalTableMetric[];
  tableRows: MiniFocalTableRow[];
};

export const CrossShopInteractionsReportletMiniFocalItemTable = ({
  metrics,
  tableRows,
  className,
  hideMetricColumns,
}: CrossShopInteractionsReportletMiniFocalItemTableProps) => {
  const columns: Array<ColumnDef<MiniFocalTableRow>> = useMemo(() => {
    let columnDefinitions = [
      {
        accessorFn: (row: MiniFocalTableRow): MiniFocalTableItem => row.item,
        cell: ({
          getValue,
        }: CellContext<MiniFocalTableRow, MiniFocalTableItem>) =>
          MiniFocalItemTableItemDescriptionCellFormatter({ item: getValue() }),
        header: (
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          _properties: HeaderContext<MiniFocalTableRow, MiniFocalTableItem>
        ) => ItemDescriptionHeader(),
        id: `item-description`,
      },
    ];

    if (!hideMetricColumns) {
      const metricsColumnDefinition = metrics.map((metric, index) => ({
        accessorFn: (row: MiniFocalTableRow): number => row.metricValues[index],
        cell: ({ getValue }: CellContext<MiniFocalTableRow, number>) =>
          ItemTableCellFormatter({
            measureFormat: metric.format,
            value: getValue(),
          }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        header: (_properties: HeaderContext<MiniFocalTableRow, number>) =>
          MetricHeader({ displayName: metric.displayName }),
        id: `${metric.displayName}`,
      }));

      // @ts-expect-error this doesn't like the different header types: Item and number for some reason
      columnDefinitions = [...columnDefinitions, ...metricsColumnDefinition];
    }

    return columnDefinitions;
  }, [hideMetricColumns, metrics]);

  const table = useReactTable({
    data: tableRows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <ItemTable
      className={classNames(
        styles.crossShopInteractionsReportletMiniFocalItemTableWrapper,
        className
      )}
      heading="Focal items"
      pinFirstColumn
      table={table}
      variant={ItemTableVariant.Grow}
    />
  );
};
