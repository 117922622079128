export enum SegmentationSlice {
  Interaction = "interaction",
  Segment = "segment",
}

export enum ProductInteraction {
  CustomersL = "CustomersL",
  SalesL = "SalesL",
}

export enum SegmentationCut {
  Actual = "actual",
  Index = "index",
}

export type SegmentationInteractionDataCut = {
  [key in SegmentationCut]: number[];
};

export type SegmentationInteractionData = {
  [key in ProductInteraction]: SegmentationInteractionDataCut;
};

export type SegmentationCategoryData = {
  data: SegmentationInteractionData;
  name: string;
};

export type SegmentationCategory = {
  categories: string[];
  data: SegmentationCategoryData[];
};

export type SegmentationCategorySlice = {
  [key in SegmentationSlice]: SegmentationCategory;
};

export type SegmentationCategoryDataFormat = {
  [key in ProductInteraction]: string;
};

export type CrossShopSegmentationResponseDto = SegmentationCategorySlice & {
  dataFormat: SegmentationCategoryDataFormat;
};
