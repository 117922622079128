import {
  FeatureFlag,
  ddLog,
  useLazyGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { BasketQuantitiesOverTimeReportlet } from "./components/BasketQuantitiesOverTimeReportlet/BasketQuantitiesOverTimeReportlet";
import BasketQuantitiesSidePanel from "./components/BasketQuantitiesSidePanel";
import { BasketQuantitiesSummaryReportlet } from "./components/BasketQuantitiesSummaryReportlet/BasketQuantitiesSummaryReportlet";
import BasketQuantitiesTopDrawer from "./components/BasketQuantitiesTopDrawer";
import { SummaryDisplayType } from "./constants";
import { useLazyGetLocalParametersQuery } from "./services/basket-quantities-local-parameters-api-slice";
import {
  onReportOpen,
  selectLocalParametersInitialised,
} from "./services/basket-quantities-slice";

export const BasketQuantitiesReport = () => {
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
  }, [dispatch, id, isTabsEnabled]);

  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, reportId: string) => {
      await getLocalParameters({ divisionName: division, reportId });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  const [
    getReportParametersQuery,
    { data: reportParameters, isLoading: isInfoPanelSummaryLoading },
  ] = useLazyGetReportParametersQuery();

  const fetchReport = useCallback(
    async (division: string, reportId: string) => {
      await getReportParametersQuery({ divisionName: division, reportId });
    },
    [getReportParametersQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      fetchReport(activeDivisionName, id).catch((error) => {
        ddLog(
          "Error retrieving basket quantities local parameters",
          {},
          "error",
          error
        );
      });
    }
  }, [
    activeDivisionName,
    dispatch,
    fetchReport,
    id,
    localParametersInitialised,
  ]);

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <BasketQuantitiesTopDrawer renameReport={handleRenameReport} />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <BasketQuantitiesSidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <BasketQuantitiesSummaryReportlet
            defaultChart={SummaryDisplayType.CHART}
            reportParameters={reportParameters}
          />
          <BasketQuantitiesOverTimeReportlet
            reportParameters={reportParameters}
          />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={reportParameters}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <BasketQuantitiesReport />,
  path: "basket-quantities/:id",
};

export default BasketQuantitiesReport;
