import { type DragAndMultiDropContainerType } from "../container/DragAndMultiDropContainer";
import { type SimpleItem } from "./Item";

export enum ContainerIdType {
  METRIC = "Metric",
  RANKED = "Ranked",
  UNRANKED = "Unranked",
}

export type Container = {
  containerType: DragAndMultiDropContainerType;
  id: ContainerIdType;
  title: string;
};

export type SimpleZone = {
  containerId: ContainerIdType;
  hasItem: boolean;
  id: string;
  isPlaceholder: boolean;
  item?: SimpleItem;
  type: string;
};
