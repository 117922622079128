import {
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  type CellContext,
  type ColumnDef,
  type ColumnResizeMode,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  type TrialAndRepeatProductData,
  type TrialAndRepeatProductLoadMoreRow,
  type TrialAndRepeatProductRow,
} from "../models/TrialAndRepeatProductRow";
import { type TrialAndRepeatTableRequestDto } from "../models/TrialAndRepeatTableRequestDto";
import { useLazyGetReportDataTableQuery } from "../services/trial-and-repeat-data-table-api-slice";
import {
  onLoadMoreSuccess,
  onSearchChange,
  selectActiveTab,
  selectLocalSelections,
  selectPagination,
  selectPurchaseActivityActiveTab,
  selectRowSelection,
  selectSearchQuery,
  selectTopDrawerTableRows,
  selectTransactionNumber,
  setFocalItem,
} from "../services/trial-and-repeat-slice";
import { getTableRequestDto } from "../utils/getTableRequestDto";
import styles from "./TrialAndRepeatTopDrawerProductTable.module.css";

const columnResizeMode: ColumnResizeMode = "onChange";

export type TrialAndRepeatTopDrawerProductTableProps = {
  eventTrackingService?: Function;
  isQuerySuccess: boolean;
};

const isLoadMore = (
  panel: TrialAndRepeatProductRow
): panel is TrialAndRepeatProductLoadMoreRow =>
  Object.prototype.hasOwnProperty.call(panel, "loadMoreParentRow");

const getRowId = (row: TrialAndRepeatProductRow) => {
  if (isLoadMore(row)) {
    return `${row.loadMoreParentRow.hierarchyItem.itemCode}-load-more`;
  }

  return row.hierarchyItem.itemCode;
};

export const TrialAndRepeatTopDrawerProductTable = ({
  isQuerySuccess,
  eventTrackingService,
}: TrialAndRepeatTopDrawerProductTableProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();

  const activeTab = useSelector(selectActiveTab);
  const localSelections = useSelector(selectLocalSelections);
  const pagination = useSelector(selectPagination);
  const breakdownType = useSelector(selectPurchaseActivityActiveTab);
  const tableRows = useSelector(selectTopDrawerTableRows);
  const rowSelection = useSelector(selectRowSelection);
  const searchQuery = useSelector(selectSearchQuery);
  const transactionNumber = useSelector(selectTransactionNumber);

  const [getTableRows, { isSuccess }] = useLazyGetReportDataTableQuery();

  const fetchData = useCallback(
    async (division: string, payload: TrialAndRepeatTableRequestDto) => {
      const response = await getTableRows({
        division,
        reportId,
        breakdownType,
        payload,
      });
      if (response.data) {
        dispatch(onLoadMoreSuccess(response.data));
      }
    },
    [getTableRows, breakdownType, reportId, dispatch]
  );

  const onMoreClickedHandler = useCallback(async () => {
    const payload = getTableRequestDto(
      activeTab ?? "",
      localSelections,
      pagination.pageIndex + 1,
      searchQuery,
      transactionNumber
    );

    await fetchData(divisionName, payload);
  }, [
    activeTab,
    localSelections,
    pagination.pageIndex,
    searchQuery,
    fetchData,
    divisionName,
    transactionNumber,
  ]);

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return pagination.totalCount;
  }, [pagination.totalCount, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchBox}>
        <SearchBox
          enableDebounce
          onChange={(value) => dispatch(onSearchChange(value))}
          placeholder="Type to search"
          resultCount={matchesFound}
          searchQuery={searchQuery}
        />
      </div>
    ),
    [matchesFound, searchQuery, dispatch]
  );

  const displayEmptySearch: boolean =
    isSuccess && searchQuery.length > 0 && tableRows.length === 0;

  const createNameCell = useCallback(
    ({ row }: CellContext<TrialAndRepeatProductData, unknown>) => (
      <ExpandableNameCell
        canExpand={false}
        depth={0}
        handleToggleExpanded={row.getToggleExpandedHandler()}
        handleToggleSelected={row.getToggleSelectedHandler()}
        isCheckboxHidden
        isExpanded={false}
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        onClick={() => {
          if (!row.getIsSelected()) {
            dispatch(
              setFocalItem({
                itemCode: row.original.hierarchyItem.itemCode,
                name: row.original.hierarchyItem.name,
                shortName: row.original.hierarchyItem.shortName,
              })
            );
            eventTrackingService?.(
              [
                TrackingComponent.MyReports,
                TrackingComponent.Report,
                TrackingComponent.FocalItemTableRow,
              ],
              TrackingEvent.Selected,
              {
                selection: `${row.original.hierarchyItem.shortName}:${row.original.hierarchyItem.name}`,
              }
            );
          }
        }}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={row.original.hierarchyItem.name}
      />
    ),
    [dispatch, eventTrackingService]
  );

  // @ts-expect-error typecheck issue with memoizedSearchBox as header
  const columnDefs: Array<ColumnDef<TrialAndRepeatProductData>> = useMemo(
    () => [
      {
        accessorKey: "name",
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        header: memoizedSearchBox,
      },
    ],
    [createNameCell, memoizedSearchBox]
  );

  return (
    <ReportHierarchyTableWrapper isSuccess={isQuerySuccess}>
      <VirtuosoTableComponent
        columnResizeMode={columnResizeMode}
        columns={columnDefs}
        data={tableRows}
        enableSorting={false}
        getRowId={getRowId}
        handleMoreClicked={onMoreClickedHandler}
        moreText="Load more..."
        pinFirstColumn
        rowExpandedState={{ "0": true }}
        rowSelectionState={rowSelection}
      />
      {displayEmptySearch && (
        <div className={styles.emptySearch}>
          <EmptySearch />
        </div>
      )}
    </ReportHierarchyTableWrapper>
  );
};
