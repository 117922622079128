type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};

export const DynamicIcon = ({ className, coloured = true, text }: Props) => {
  const colour = coloured
    ? "var(--dynamic-colour)"
    : "var(--group-colour-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Dynamic"}</title>
      <path
        d="M17.6444 6.35439C16.1944 4.90439 14.2044 4.00439 11.9944 4.00439C7.57439 4.00439 4.00439 7.58439 4.00439 12.0044C4.00439 16.4244 7.57439 20.0044 11.9944 20.0044C15.7244 20.0044 18.8344 17.4544 19.7244 14.0044H17.6444C16.8244 16.3344 14.6044 18.0044 11.9944 18.0044C8.68439 18.0044 5.99439 15.3144 5.99439 12.0044C5.99439 8.69439 8.68439 6.00439 11.9944 6.00439C13.6544 6.00439 15.1344 6.69439 16.2144 7.78439L12.9944 11.0044H19.9944V4.00439L17.6444 6.35439Z"
        fill={colour}
      />
    </svg>
  );
};
