import { type ProductSubstitutabilityHierarchyProductData } from "../models/ProductSubstitutabilityHierarchyProductData";
import { type FocalProduct } from "../models/SubstitutabilityResponseDto";
import { type SubstitutabilityTableRow } from "../models/SubstitutabilityTableData";

export const getSubstitutabilityTableData = (
  focalItemsData: ProductSubstitutabilityHierarchyProductData[],
  substitutabilityData: FocalProduct[],
  maxSubs: number
): SubstitutabilityTableRow[] => {
  const substitutababilityTableData: SubstitutabilityTableRow[] = [];

  for (const focalProduct of substitutabilityData) {
    const focalProductData = focalItemsData.find(
      (data) => data.item.itemCode === focalProduct.item.itemCode
    );

    if (focalProductData) {
      substitutababilityTableData.push({
        item: focalProduct.item,
        attributeValues: focalProductData.attributeValues,
        metricValues: ([null, null] as Array<number | null>).concat(
          focalProductData.metricValues.slice(1)
        ),
        subRows: focalProduct.substituteProducts
          .filter(
            (substituteProduct) =>
              substituteProduct.metricValues[0] &&
              substituteProduct.metricValues[0] <= maxSubs
          )
          .map((substituteProduct) => ({
            ...substituteProduct,
            parentItem: focalProduct.item,
            subRows: [] as SubstitutabilityTableRow[],
          })),
      });
    }
  }

  return substitutababilityTableData;
};
