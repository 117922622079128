import { type CustomerLoyaltyLocalParametersResponseDto } from "../models/CustomerLoyaltyLocalParametersResponseDto";
import { customerLoyaltyApiSlice } from "./customer-loyalty-api-slice";

export const customerLoyaltyLocalParametersApiSlice =
  customerLoyaltyApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        CustomerLoyaltyLocalParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useGetLocalParametersQuery, useLazyGetLocalParametersQuery } =
  customerLoyaltyLocalParametersApiSlice;
