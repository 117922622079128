import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { BandsSelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getBandsParameterState } from "../../parameters/bands/BandsParameterState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelBandsProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelBands = ({ parameterDto }: SummaryPanelBandsProps) => {
  const bands = useAppSelector(getBandsParameterState(parameterDto.id)).bands;

  return <BandsSelectionSummary bands={bands} />;
};
