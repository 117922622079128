import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const trialAndRepeatApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/trial-and-repeat`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "trialAndRepeatApi",
  tagTypes: ["trialAndRepeat"],
});
