import {
  HierarchyItemType,
  hierarchyServiceApi,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import { type AvailableHierarchyLevel } from "../../hierarchy-level-selector/AvailableHierarchyLevels";
import { store } from "../../store";
import {
  type SelectableAttributeItem,
  type SelectableAttributeLevelItem,
} from "../SelectableItem";
import { SearchConfigurationApi } from "../services/SearchConfigurationApi";

export const getAttributeSubRows = async (
  item: SelectableAttributeItem,
  division: string
) => {
  const pageSize = 500;
  const hierarchyType = HierarchyType.Product;

  const { data: searchConfigData } = await store.dispatch(
    SearchConfigurationApi.endpoints.SearchConfiguration.initiate({
      division,
    })
  );

  const { data: hierarchyMetadata } = await store.dispatch(
    hierarchyServiceApi.endpoints.hierarchyMetadata.initiate({
      division,
      hierarchyType,
    })
  );

  let hierarchyLevelShortNames: string[] = [];

  if (hierarchyMetadata && searchConfigData) {
    const orderedProductHierarchyMetadata = hierarchyMetadata
      .filter((hm) => hm.structureName === HierarchyType.Product)
      .sort((a, b) => a.ordinal - b.ordinal);
    const skipHierarchyLevelsIndex = orderedProductHierarchyMetadata.findIndex(
      (hm) => hm.shortName === searchConfigData.attributeHierarchyStartLevel
    );

    hierarchyLevelShortNames = orderedProductHierarchyMetadata
      .slice(skipHierarchyLevelsIndex)
      .filter((hm) => hm.isLeaf === false)
      .map((hm) => hm.shortName);
  }

  const { data: hierarchyResponse } = await store.dispatch(
    hierarchyServiceApi.endpoints.locateAttribute.initiate({
      division,
      hierarchyType,
      payload: {
        attribute: {
          code: item.code,
          shortName: item.shortName,
        },
        levelShortNames: hierarchyLevelShortNames,
        page: 0,
        pageSize,
      },
    })
  );

  let availableHierarchyLevels: AvailableHierarchyLevel[] | undefined;

  if (hierarchyResponse && hierarchyMetadata) {
    availableHierarchyLevels = hierarchyResponse.results
      .filter(
        (result) => result.transactionSourceAccess?.[result.shortName]?.length
      )
      .map((result) => {
        const hierarchyLevel = hierarchyMetadata.find(
          (hm) => hm.shortName === result.shortName
        );
        return {
          hierarchyItems: [
            {
              code: result.code,
              name: result.name,
              entitlements:
                result.transactionSourceAccess?.[result.shortName] ?? [],
            },
          ],
          levelName: hierarchyLevel?.name ?? "Unknown",
          shortName: result.shortName,
        } as AvailableHierarchyLevel;
      })
      .sort((a, b) => {
        const ordinalA =
          hierarchyMetadata.find((hm) => hm.shortName === a.shortName)
            ?.ordinal ?? 0;
        const ordinalB =
          hierarchyMetadata.find((hm) => hm.shortName === b.shortName)
            ?.ordinal ?? 0;
        if (ordinalA !== ordinalB) {
          return ordinalA - ordinalB;
        }

        return a.hierarchyItems[0].name.localeCompare(b.hierarchyItems[0].name);
      });
  }

  if (availableHierarchyLevels) {
    return availableHierarchyLevels.flatMap((level) =>
      level.hierarchyItems.map(
        (hierarchyItem) =>
          ({
            code: hierarchyItem.code,
            entitlements: hierarchyItem.entitlements,
            name: hierarchyItem.name,
            parent: item,
            shortName: level.shortName,
            type: HierarchyItemType.Attribute,
          } as SelectableAttributeLevelItem)
      )
    );
  }

  return undefined;
};
