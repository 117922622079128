import { type GainsAndLossBreakdownSegmentationResponseDto } from "../models/GainsAndLossBreakdownSegmentationResponseDto";
import { type GainsAndLossMiniChartRequestDto } from "../models/GainsAndLossMiniChartRequestDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossBreakdownSegmentationApiSlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getBreakdownSegmentation: builder.query<
        GainsAndLossBreakdownSegmentationResponseDto[],
        {
          division: string;
          payload: GainsAndLossMiniChartRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `SegmentationMiniData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetBreakdownSegmentationQuery } =
  gainsAndLossBreakdownSegmentationApiSlice;
