import {
  type LocalMetricWithFormatSelection,
  type LocalSegmentationSelection,
} from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isMetricWithFormatParameter,
  isSegmentationParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";

export const getMetricList = (
  localParameters: SidePanelParameter[]
): LocalMetricWithFormatSelection[] => {
  const metricsParameter = localParameters.find(
    (parameter) => parameter.id === LocalParameters.Metrics
  );

  return metricsParameter && isMetricWithFormatParameter(metricsParameter)
    ? metricsParameter.selections
    : [];
};

export const getSegmentList = (
  localParameters: SidePanelParameter[]
): LocalSegmentationSelection[] => {
  const segmentationParameter = localParameters.find(
    (parameter) => parameter.id === LocalParameters.Segmentation
  );

  return segmentationParameter && isSegmentationParameter(segmentationParameter)
    ? segmentationParameter.selections
    : [];
};
