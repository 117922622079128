import {
  ParameterId,
  useGetValidTransactionSourcesQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { groupValidated } from "../../states/report-wizard-slice";
import { getGroupParameterState } from "./GroupParameterState";

const useGroupValidator = (parameterId: ParameterId) => {
  const dispatch = useDispatch();

  const { name: divisionName } = useDivision();
  const groupState = useAppSelector(getGroupParameterState(parameterId));

  const groupIdToBeValidated = useMemo(
    () => groupState?.pendingSelections[0]?.id,
    [groupState]
  );

  const {
    currentData: validTransactionSources,
    isLoading: isLoadingValidTransactionSources,
  } = useGetValidTransactionSourcesQuery(
    {
      divisionName,
      groupId: groupIdToBeValidated ?? "",
    },
    { skip: !divisionName || !groupIdToBeValidated }
  );

  useEffect(() => {
    if (
      groupIdToBeValidated &&
      validTransactionSources &&
      !isLoadingValidTransactionSources
    ) {
      dispatch(
        groupValidated({
          parameterId,
          groupId: groupIdToBeValidated,
          transactionSources: validTransactionSources,
        })
      );
    }
  }, [
    validTransactionSources,
    isLoadingValidTransactionSources,
    groupIdToBeValidated,
    parameterId,
    dispatch,
  ]);
};

export const useGroupsValidator = () => {
  useGroupValidator(ParameterId.ProductGroups);
  useGroupValidator(ParameterId.LocationGroups);
};
