import {
  getBaseQuery,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type StoreByStoreMetricMetadataDto } from "../../fast-report/api/reportConfigurationDto";
import { type GlobalParameterValues } from "../../fast-report/globalParameterValues";
import { type FocalItemDto } from "../focalItemDto";
import { REPORT_DATA_CACHE_LENGTH_SECONDS } from "../reportUtilities";

export enum StoreByStoreResultType {
  Cumulative = "cumulative",
  Location = "location",
  Metric = "metric",
  Other = "other",
}

export type StoreByStoreMetricResult = {
  displayName: string;
  formatType: string;
  isGrowth: boolean;
  rank: number | null;
  resultType: StoreByStoreResultType;
  value: string;
};
export type StoreByStoreRow = { results: StoreByStoreMetricResult[] };
export type StoreByStoreReportResponse = {
  rows: StoreByStoreRow[];
  transactionSource: TransactionSource;
};

export type StoreByStoreRequest = GlobalParameterValues & {
  item: FocalItemDto;
  metrics: StoreByStoreMetricMetadataDto[];
  shareOfShortname: string;
};

export const storeByStoreApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    storeByStoreReport: builder.query<
      StoreByStoreReportResponse,
      StoreByStoreRequest & { division: string }
    >({
      keepUnusedDataFor: REPORT_DATA_CACHE_LENGTH_SECONDS,
      providesTags: ["StoreByStoreReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/store-by-store-report`,
      }),
    }),
  }),
  reducerPath: "storeByStoreApi",
  tagTypes: ["StoreByStoreReport"],
});

export const { useStoreByStoreReportQuery } = storeByStoreApi;
