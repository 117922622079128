import styles from "./DriverTreeNode.module.css";

type DriverTreeNodeProps = {
  barColour: string;
  content?: JSX.Element;
  nodeHeight?: number;
  nodeWidth?: number;
};

const defaultNodeWidth = 216;
const defaultNodeHeight = 107;

const DriverTreeNode = ({
  barColour,
  nodeWidth = defaultNodeWidth,
  nodeHeight = defaultNodeHeight,
  content,
}: DriverTreeNodeProps) => (
  <div
    className={styles.driverTreeNode}
    style={{
      height: nodeHeight,
      width: nodeWidth,
    }}
  >
    <svg height="100%" width="12">
      <rect fill={barColour} height={nodeHeight - 10} rx="3" width="10" />
    </svg>
    {content}
  </div>
);

const LegendNode = ({
  barColour,
  nodeWidth = 158,
  nodeHeight = 64,
  content,
}: DriverTreeNodeProps) => (
  <div
    className={styles.driverTreeLegendNode}
    style={{
      height: nodeHeight,
      width: nodeWidth,
    }}
  >
    <svg height="100%" width="8">
      <rect fill={barColour} height={nodeHeight - 10} rx="3" width="6" />
    </svg>
    {content}
  </div>
);

export type HiddenDriverTreeNodeProps = {
  key: string;
};

const HiddenDriverTreeNode = ({ key }: HiddenDriverTreeNodeProps) => (
  <div
    className={styles.hiddenDriverTreeNode}
    key={key}
    style={{
      height: defaultNodeHeight,
      width: defaultNodeWidth / 2,
    }}
  />
);

DriverTreeNode.LegendNode = LegendNode;
DriverTreeNode.HiddenNode = HiddenDriverTreeNode;

export { DriverTreeNode };
