import {
  type HierarchyValueDto,
  type HierarchySliceNodeDto,
  formatStringDate,
} from "@quantium-enterprise/common-ui";
import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { EXPORT_LOCATION_SUFFIX } from "../constants";
import {
  type SidePanelParameter,
  type SidePanelTimePeriodParameter,
} from "../models/local-parameters/SidePanelParameters";
import {
  focalItemsHasDuplicateNames,
  getFocalItemLabels,
} from "./focal-item-labels-utils";
import {
  isGroupParameter,
  isHierarchySelectionParameter,
} from "./local-parameters/LocalParametersUtils";

export const getFocalItemSummary = (
  items: HierarchySliceNodeDto[] | HierarchyValueDto[]
): string => {
  if (!items.length) {
    return "";
  }

  return items.length < 6
    ? items
        .map(
          (item) =>
            `(${hierarchyLevelDisplayLabel(item.shortName)}) ${item.name}`
        )
        .join(", ")
    : `${items.length} items`;
};

export const getFocalItemSummaryWithParents = (
  items: HierarchySliceNodeDto[],
  parents: HierarchySliceNodeDto[]
): string => {
  if (!items.length) {
    return "";
  }

  if (items.length >= 6) {
    return `${items.length} items`;
  }

  if (!focalItemsHasDuplicateNames(items)) {
    return getFocalItemSummary(items);
  }

  const labels = getFocalItemLabels(items, parents);
  return items
    .map(
      (item) =>
        `(${hierarchyLevelDisplayLabel(item.shortName)}) ${
          labels[item.nodeNumber] ?? item.name
        }`
    )
    .join(", ");
};

export const getTimeDefaultSelection = (
  parameter: SidePanelTimePeriodParameter
) => {
  const focusPeriod = parameter.focusPeriod.label;
  const focusStartDateFormatted = formatStringDate(
    parameter.focusPeriod.startDate
  );
  const focusEndDateFormatted = formatStringDate(parameter.focusPeriod.endDate);
  let time = `${focusPeriod} (focus period ${focusStartDateFormatted} - ${focusEndDateFormatted})`;
  let timePeriodLength = focusPeriod;

  if (parameter.comparisonPeriod) {
    const comparisonPeriod = parameter.comparisonPeriod.label;
    const comparisonStartDateFormatted = formatStringDate(
      parameter.comparisonPeriod.startDate
    );
    const comparisonEndDateFormatted = formatStringDate(
      parameter.comparisonPeriod.endDate
    );
    time = `${focusPeriod} (focus period ${focusStartDateFormatted} - ${focusEndDateFormatted}), ${comparisonPeriod} (comparison period ${comparisonStartDateFormatted} - ${comparisonEndDateFormatted})`;
    timePeriodLength = focusPeriod;

    return { time, timePeriodLength };
  } else if (parameter.rollingPeriod) {
    const rollingPeriod = parameter.rollingPeriod.label;
    time = `${focusPeriod} (focus period ${focusStartDateFormatted} - ${focusEndDateFormatted}), (rolling period ${rollingPeriod})`;
    timePeriodLength = focusPeriod;

    return { time, timePeriodLength };
  } else if (parameter.leadPeriod) {
    const leadPeriod = parameter.leadPeriod.label;
    timePeriodLength = focusPeriod;
    time = `${focusPeriod} (focus period ${focusStartDateFormatted} - ${focusEndDateFormatted}), (lead in period ${leadPeriod})`;
  }

  return { time, timePeriodLength };
};

export const getExportParameterSummary = (
  parameter: string,
  suffix: string
) => {
  const selectionCount = parameter.split(",").length;

  return selectionCount > 6 ? `${selectionCount} ${suffix}` : parameter;
};

export const getExportFixedLocationSelection = (
  parameter: SidePanelParameter,
  location: string
) => {
  let summary: string = location;
  if (isHierarchySelectionParameter(parameter)) {
    const selectionItems = parameter.selections.map(
      (data) => data.hierarchyItem
    );
    summary = selectionItems
      .map(
        ({ name, shortName }) =>
          `(${hierarchyLevelDisplayLabel(shortName)}) ${name}`
      )
      .join(", ");
  } else if (isGroupParameter(parameter)) {
    const selectionItems = parameter.selections
      .map((grp) => `(GRP) ${grp.groupName}`)
      .join(", ");
    summary += (location && selectionItems ? ", " : "") + selectionItems;
  }

  return getExportParameterSummary(summary, EXPORT_LOCATION_SUFFIX);
};

// will replace non-numeric values with "-"
export const formatMetricValue = (
  metricValue: number | null | undefined
): string => {
  if (
    metricValue === null ||
    metricValue === undefined ||
    !Number.isFinite(metricValue) ||
    Number.isNaN(metricValue)
  )
    return "-";

  return metricValue.toString();
};

export const getCsvColumnHeader = (
  currencyFormat: string,
  currencySymbol: string,
  data: string,
  metrics?: string
) => {
  if (metrics) {
    if (currencyFormat === MetricTypes.Currency) {
      return `${metrics} (${currencySymbol}) - ${data}`;
    }

    return `${metrics} - ${data}`;
  } else {
    if (currencyFormat === MetricTypes.Currency) {
      return `${data}(${currencySymbol})`;
    }

    return `${data}`;
  }
};

export const getSingleFocalItemSummary = (
  item: HierarchySliceNodeDto | HierarchyValueDto
): string => `(${hierarchyLevelDisplayLabel(item.shortName)}) ${item.name}`;

export const sortByIndex = (
  csvData: string[][],
  index: number,
  desc: boolean
) => {
  const sortedCsvData = [...csvData.slice(1)].sort((a, b) => {
    if (a[index] === "-" && b[index] === "-") return 0;
    if (a[index] === "-") return desc ? 1 : -1;
    if (b[index] === "-") return desc ? -1 : 1;

    const valueA = Number.parseFloat(a[index]);
    const valueB = Number.parseFloat(b[index]);

    return desc ? valueB - valueA : valueA - valueB;
  });
  return [csvData[0], ...sortedCsvData];
};
