import { type ProductUniquenessReportletResponseDto } from "../models/ProductUniquessReportletResponseDto";
import { productSubstitutabilityApiSlice } from "./product-substitutability-api-slice";

export const productUniquenessReportletApiSlice =
  productSubstitutabilityApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      GetProductUniquenessReportlet: builder.query<
        ProductUniquenessReportletResponseDto,
        {
          division: string;
          reportId: string;
        }
      >({
        query: ({ division, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          url: `/GetProductUniqueness/${division}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetProductUniquenessReportletQuery } =
  productUniquenessReportletApiSlice;
