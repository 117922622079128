import { TrackingProperties } from "./TrackingProperties";

export enum ReportTabTrackingProperty {
  ReportType = "Report type",
}

export enum ReportTabTrackingPropertyValue {
  AdvancedReporting = "Checkout",
  FastReporting = "COQI",
}

export class ReportTabTrackingProperties extends TrackingProperties {
  public constructor(
    property: ReportTabTrackingProperty,
    value: ReportTabTrackingPropertyValue
  ) {
    super();

    this.Properties = {};
    this.Properties[property] = value;
  }

  public static reportType(
    value: ReportTabTrackingPropertyValue
  ): ReportTabTrackingProperties {
    return new this(ReportTabTrackingProperty.ReportType, value);
  }
}
