import { ddLog } from "@quantium-enterprise/common-ui";
import { type PricingLaddersDataTableRequestDto } from "../models/PricingLaddersDataTableRequestDto";
import { type PricingLaddersDataTableResponseDto } from "../models/PricingLaddersDataTableResponseDto";
import { pricingLaddersApiSlice } from "./pricing-ladders-api-slice";

export const pricingLaddersItemTableApiSlice =
  pricingLaddersApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTable: builder.query<
        PricingLaddersDataTableResponseDto,
        { divisionName: string; payload: PricingLaddersDataTableRequestDto }
      >({
        async onQueryStarted(_argument, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error retrieving focal item data for pricing ladder report",
              undefined,
              "error",
              error
            );
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetReportTableData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useLazyGetTableQuery } = pricingLaddersItemTableApiSlice;
