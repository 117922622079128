import {
  ddLog,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui/src/models/hierarchy-slice-dto";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import {
  type KeyDriversOverTimeMetaData,
  type KeyDriversOverTimeTableRow,
} from "../models/key-drivers-over-time-table-models";
import { keyDriversOverTimeApiSlice } from "./key-drivers-over-time-api-slice";
import {
  onInitialTableDataReceived,
  onTableChildrenDataReceived,
  onTableDataReceived,
  onSearchResultsReceived,
} from "./key-drivers-over-time-slice";
// Initial table type declarations
export type InitialTableRequest = {
  defaultFocalItem: HierarchySliceNodeDto;
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
};
export type ExportCsvTableRequest = {
  fileName: string;
  localSelectedLabels: Array<{ name: string; value: string }>;
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
  selectedMeasure: string;
};
export type InitialTableResponse = {
  dropdownOptions: KeyDriversOverTimeMeasureOption[];
  hierarchySliceHypercubeId: string;
  tableMetaData: KeyDriversOverTimeMetaData;
  tableRows: KeyDriversOverTimeTableRow[];
};
export type KeyDriversOverTimeMeasureOption = {
  baseMeasure: string;
  keyDriver: number;
};
// Refetch table type declarations
export type RefetchTableRequest = {
  hypercubeId: string;
  localSelectedValues: LocalSelectedValueDto[];
  parentNodes: number[];
  reportId: string;
  selectedMeasure?: string;
};
export type RefetchTableResponse = {
  tableMetaData: KeyDriversOverTimeMetaData;
  tableRows: KeyDriversOverTimeTableRow[];
};
// Refetch table type declarations
export type SearchTableRequest = {
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
  searchText: string;
  selectedMeasure?: string;
};
// Fetch children type declarations
export type FetchChildrenTableRequest = {
  localSelectedValues: LocalSelectedValueDto[];
  parentNode: number;
  reportId: string;
  selectedMeasure: string;
};
export type FetchChildrenTableResponse = {
  parentNode: number;
  tableRows: KeyDriversOverTimeTableRow[];
};
export const keyDriversOverTimeTableDataApiSlice =
  keyDriversOverTimeApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getChildrenTableData: builder.query<
        FetchChildrenTableResponse,
        { divisionName: string; payload: FetchChildrenTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableChildrenDataReceived(data));
          } catch (error) {
            ddLog(
              "Error retrieving children data",
              {},
              "error",
              error as Error
            );
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTableChildren/${divisionName}`,
        }),
      }),
      getInitialTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: InitialTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onInitialTableDataReceived(data));
          } catch (error) {
            ddLog("Error retrieving table data", {}, "error", error as Error);
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetInitialTable/${divisionName}`,
        }),
      }),
      getTableData: builder.query<
        RefetchTableResponse,
        { divisionName: string; payload: RefetchTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataReceived(data));
          } catch (error) {
            ddLog("Error retrieving table data", {}, "error", error as Error);
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportTableData/${divisionName}`,
        }),
      }),
      searchTableData: builder.query<
        RefetchTableResponse,
        { divisionName: string; payload: SearchTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onSearchResultsReceived(data));
          } catch (error) {
            ddLog("Error retrieving table data", {}, "error", error as Error);
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/SearchReportTableData/${divisionName}`,
        }),
      }),
      getExportData: builder.query<
        Blob,
        { divisionName: string; payload: ExportCsvTableRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportFocalItemToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `Key Drivers Over Time focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });
export const {
  useLazyGetChildrenTableDataQuery,
  useLazyGetInitialTableQuery,
  useLazyGetTableDataQuery,
  useLazySearchTableDataQuery,
  useLazyGetExportDataQuery,
} = keyDriversOverTimeTableDataApiSlice;
