import {
  FeatureFlag,
  useLazyGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { BasketLimitsReportlet } from "./components/BasketLimitsReportlet";
import { BasketLimitsSidePanel } from "./components/BasketLimitsSidePanel";
import BasketLimitsTopDrawer from "./components/BasketLimitsTopDrawer";
import { StoreBreakdownReportlet } from "./components/StoreBreakdownReportlet";
import { useLazyGetReportQuery } from "./services/basket-limits-api-slice";
import {
  onReportOpen,
  selectLocalParametersInitialised,
} from "./services/basket-limits-slice";

export const BasketLimitsReport = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
  }, [dispatch, id, isTabsEnabled]);

  const [
    getReportParametersQuery,
    { data: reportParameters, isLoading: isInfoPanelSummaryLoading },
  ] = useLazyGetReportParametersQuery();

  const fetchReportParametersQuery = useCallback(
    async (division: string, reportId: string) => {
      await getReportParametersQuery({ divisionName: division, reportId });
    },
    [getReportParametersQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchReportParametersQuery(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchReportParametersQuery,
    localParametersInitialised,
  ]);

  const [getReportQuery] = useLazyGetReportQuery();

  const fetchReportQuery = useCallback(
    async (division: string, reportId: string) => {
      await getReportQuery({ divisionName: division, reportId });
    },
    [getReportQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchReportQuery(activeDivisionName, id);
    }
  }, [activeDivisionName, id, localParametersInitialised, fetchReportQuery]);

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <BasketLimitsTopDrawer
            renameReport={handleRenameReport}
            reportParameters={reportParameters}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <BasketLimitsSidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <BasketLimitsReportlet reportParameters={reportParameters} />
          <StoreBreakdownReportlet reportParameters={reportParameters} />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={reportParameters}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <BasketLimitsReport />,
  path: "basket-limits/:id",
};

export default BasketLimitsReport;
