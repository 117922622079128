import {
  FeatureFlag,
  ReportType,
  ddLog,
  useGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { type RootState } from "../store";
import { CrossShopInteractionsReportlet } from "./components/CrossShopInteractionsReportlet/CrossShopInteractionsReportlet";
import { CrossShopSegmentationReportlet } from "./components/CrossShopSegmentationReportlet/CrossShopSegmentationReportlet";
import { CustomerCrossShopSidePanel } from "./components/CustomerCrossShopSidePanel";
import CustomerCrossShopTopDrawer from "./components/CustomerCrossShopTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/customer-cross-shop-local-parameters-api-slice";
import {
  onReportError,
  onReportOpen,
  onReportSuccess,
  selectLocalParametersInitialised,
  selectSegmentation,
} from "./services/customer-cross-shop-slice";

const CustomerCrossShopReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
  }, [id, isTabsEnabled, dispatch]);

  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: !activeDivisionName || !id }
    );

  const segmentation = useSelector(selectSegmentation);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.customerCrossShop.reportName,
  }));

  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, reportId: string) => {
      await getLocalParameters({ divisionName: division, reportId })
        .unwrap()
        .then((response) => {
          dispatch(onReportSuccess(response));
        })
        .catch((error) => {
          ddLog(
            "Error retrieving customer cross shop local parameters",
            {},
            "error",
            error
          );
          dispatch(onReportError());
        });
    },
    [dispatch, getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <CustomerCrossShopTopDrawer
            infoPanelSummary={infoPanelSummary}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportType={ReportType.CustomerCrossShop}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <CustomerCrossShopSidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <CrossShopInteractionsReportlet />
          {segmentation.length > 1 && (
            <CrossShopSegmentationReportlet
              infoPanelSummary={infoPanelSummary}
            />
          )}
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <CustomerCrossShopReport />,
  path: "customer-cross-shop/:id",
};

export default CustomerCrossShopReport;
