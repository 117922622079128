import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { useNumberFormat } from "@quantium-enterprise/hooks-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelSliderParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isLocationSelectionParameter,
  isMinMaxRangeParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildLocationHierarchyPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildSingleSelectPanel,
  buildSliderPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import {
  onBasketsWithBothThresholdChange,
  onChannelChange,
  onLocationChange,
  onSegmentationChange,
  onUpliftChange,
  selectBasketsWithBothRange,
  selectBasketsWithThreshold,
  selectChannel,
  selectLocalParameters,
  selectLocation,
  selectSegment,
  selectSegmentation,
  selectUpliftRange,
  selectUpliftThreshold,
} from "../services/basket-affinities-slice";
import {
  getDisplayedRange,
  getDisplayedThreshold,
  getEditability,
  getSummaryText,
} from "../utils/sidePanelUtil";

export const BasketAffinitiesSidePanel = () => {
  const dispatch = useDispatch();

  const { decimalFormatter, integerFormatter } = useNumberFormat();

  const localParametersConfig = useSelector(selectLocalParameters);

  const channel = useSelector(selectChannel);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const upliftThreshold = useSelector(selectUpliftThreshold);
  const basketsWithBothThreshold = useSelector(selectBasketsWithThreshold);
  const location = useSelector(selectLocation);

  const upliftRange = useSelector(selectUpliftRange);
  const basketsWithBothRange = useSelector(selectBasketsWithBothRange);

  const upliftEditability = useMemo(
    () => getEditability(upliftRange, upliftThreshold),
    [upliftRange, upliftThreshold]
  );

  const displayedUpliftRange = useMemo(
    () => getDisplayedRange(upliftEditability, upliftRange),
    [upliftEditability, upliftRange]
  );

  const displayedUpliftThreshold = useMemo(
    () =>
      getDisplayedThreshold(
        upliftEditability,
        upliftRange.maximum,
        upliftThreshold
      ),
    [upliftEditability, upliftRange.maximum, upliftThreshold]
  );

  const upliftSummary = useMemo(
    () =>
      getSummaryText(upliftEditability, decimalFormatter, upliftRange.maximum),
    [decimalFormatter, upliftEditability, upliftRange.maximum]
  );

  const basketsWithThresholdEditability = useMemo(
    () => getEditability(basketsWithBothRange, basketsWithBothThreshold),
    [basketsWithBothRange, basketsWithBothThreshold]
  );

  const displayedBasketsWithBothRange = useMemo(
    () =>
      getDisplayedRange(basketsWithThresholdEditability, basketsWithBothRange),
    [basketsWithBothRange, basketsWithThresholdEditability]
  );

  const displayedBasketsWithBothThreshold = useMemo(
    () =>
      getDisplayedThreshold(
        basketsWithThresholdEditability,
        basketsWithBothRange.maximum,
        basketsWithBothThreshold
      ),
    [
      basketsWithBothRange.maximum,
      basketsWithBothThreshold,
      basketsWithThresholdEditability,
    ]
  );

  const basketsWithBothSummary = useMemo(
    () =>
      getSummaryText(
        basketsWithThresholdEditability,
        integerFormatter,
        basketsWithBothRange.maximum
      ),
    [
      basketsWithBothRange.maximum,
      basketsWithThresholdEditability,
      integerFormatter,
    ]
  );

  const generatePanels = useCallback(() => {
    const panels: Panel[] = [];

    for (const parameter of localParametersConfig) {
      switch (parameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(parameter))
            panels.push(buildTimePeriodPanel(parameter));

          break;

        case LocalParameters.Channel:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onChannelChange(value));
                },
                channel.value as string
              )
            );
          }

          break;

        case LocalParameters.Segmentation:
          if (isSegmentationParameter(parameter)) {
            panels.push(
              buildSegmentationPanel(
                parameter,
                (value: SegmentOption) => dispatch(onSegmentationChange(value)),
                [segmentation.value as string, segment.value as string]
              )
            );
          } else if (isPlainTextContentParameter(parameter)) {
            panels.push(buildPlainTextContentPanel(parameter));
          }

          break;

        case LocalParameters.UpliftThreshold:
          if (isMinMaxRangeParameter(parameter)) {
            panels.push(
              buildSliderPanel(
                {
                  ...parameter,
                  maximumPossibleValue: displayedUpliftRange.maximum,
                  minimumPossibleValue: displayedUpliftRange.minimum,
                } as SidePanelSliderParameter,
                (value: number) => dispatch(onUpliftChange(value)),
                upliftEditability,
                displayedUpliftThreshold,
                upliftSummary,
                0.01,
                decimalFormatter,
                { maximum: 90, minimum: 10 }
              )
            );
          }

          break;

        case LocalParameters.BasketsWithBothThreshold:
          if (isMinMaxRangeParameter(parameter)) {
            panels.push(
              buildSliderPanel(
                {
                  ...parameter,
                  maximumPossibleValue: displayedBasketsWithBothRange.maximum,
                  minimumPossibleValue: displayedBasketsWithBothRange.minimum,
                } as SidePanelSliderParameter,
                (value: number) =>
                  dispatch(onBasketsWithBothThresholdChange(value)),
                basketsWithThresholdEditability,
                displayedBasketsWithBothThreshold,
                basketsWithBothSummary
              )
            );
          }

          break;

        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(parameter)) {
            panels.push(
              buildLocationHierarchyPanel(
                parameter,
                (panel: LocalHierarchyNodeSelection) => {
                  dispatch(onLocationChange(panel));
                },
                location
              )
            );
          }

          break;

        default:
          break;
      }
    }

    return panels;
  }, [
    basketsWithBothSummary,
    basketsWithThresholdEditability,
    channel.value,
    decimalFormatter,
    dispatch,
    displayedBasketsWithBothRange.maximum,
    displayedBasketsWithBothRange.minimum,
    displayedBasketsWithBothThreshold,
    displayedUpliftRange.maximum,
    displayedUpliftRange.minimum,
    displayedUpliftThreshold,
    localParametersConfig,
    location,
    segment.value,
    segmentation.value,
    upliftEditability,
    upliftSummary,
  ]);

  return <SidePanel panels={generatePanels()} />;
};

export default BasketAffinitiesSidePanel;
