import {
  HierarchyShortName,
  ParameterId,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import CheckboxList from "components-ui/src/checkbox-list/CheckboxList";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  levelOfAnalysisOptionSelected,
  levelOfAnalysisOptionsUpdated,
} from "../../states/report-wizard-slice";
import { getGroupParameterState } from "../groups/GroupParameterState";
import { getHierarchyState } from "./HierarchyState";
import {
  getLevelOfAnalysisState,
  type LevelOfAnalysisSelection,
} from "./LevelOfAnalysisState";
import { getDisabledValuesWithReason } from "./LevelOfAnalysisUtilities";

type LevelOfAnalysisParameterProps = {
  attributesCounts?: { [key: string]: number };
  childrenCount?: { [key: string]: number };
  isAttributesCountSuccess: boolean;
  isChildrenCountSuccess: boolean;
  isLoading: boolean | undefined;
  parameterDto: ParameterDto;
};

export const LevelOfAnalysisParameter = ({
  attributesCounts,
  childrenCount,
  isAttributesCountSuccess,
  isChildrenCountSuccess,
  isLoading,
  parameterDto,
}: LevelOfAnalysisParameterProps) => {
  const loaState = useAppSelector(getLevelOfAnalysisState(parameterDto.id));

  const hierarchyState = useAppSelector(
    getHierarchyState(ParameterId.ProductHierarchy)
  );

  const groupsState = useAppSelector(
    getGroupParameterState(ParameterId.ProductGroups)
  );

  const dispatch = useDispatch();
  const onChange = (value: string) => {
    dispatch(
      levelOfAnalysisOptionSelected({
        parameter: parameterDto.id,
        selectedValue: value,
      })
    );
  };

  const disabledValuesWithReason = useMemo(() => {
    const countForShortNames: Record<string, number> = {};

    if (hierarchyState?.isValid || groupsState?.isValid) {
      if (isAttributesCountSuccess && attributesCounts) {
        for (const shortName of Object.keys(attributesCounts)) {
          countForShortNames[shortName] = attributesCounts[shortName];
        }
      }

      if (isChildrenCountSuccess && childrenCount) {
        for (const shortName of Object.keys(childrenCount)) {
          countForShortNames[shortName] = childrenCount[shortName];
        }
      }

      countForShortNames[HierarchyShortName.ProductGroup] =
        groupsState?.confirmedSelections.length ?? 0;
    }

    return getDisabledValuesWithReason(
      (hierarchyState?.isValid ?? false) || (groupsState?.isValid ?? false),
      hierarchyState?.selectedRows ?? [],
      parameterDto.options,
      countForShortNames
    );
  }, [
    hierarchyState,
    groupsState,
    attributesCounts,
    isAttributesCountSuccess,
    childrenCount,
    isChildrenCountSuccess,
    parameterDto.options,
  ]);

  useEffect(() => {
    const showWarning =
      !isLoading &&
      Object.keys(disabledValuesWithReason).length ===
        parameterDto.options.length;

    dispatch(levelOfAnalysisOptionsUpdated(showWarning));
  }, [dispatch, disabledValuesWithReason, isLoading, parameterDto.options]);

  if (!loaState) {
    return null;
  }

  return (
    <CheckboxList
      disabledValuesWithReason={disabledValuesWithReason}
      mainTooltipMessage={`A maximum of ${
        parameterDto.attributes.maxSelections
      } level${
        parameterDto.attributes.maxSelections === 1 ? "" : "s"
      } of analysis may be selected`}
      onChange={onChange}
      parameter={parameterDto}
      selectedValues={loaState.selections.map(
        (selection: LevelOfAnalysisSelection) => selection.value
      )}
    />
  );
};
