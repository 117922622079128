import {
  ddLog,
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  getNumberFormat,
  useDivision,
  useNumberFormat,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { VennChart } from "components-ui/src/charts/venn-chart/VennChart";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import {
  type TooltipFormatterContextObject,
  type PointOptionsObject,
  type Point,
} from "highcharts";
import { useMemo, type CSSProperties } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import ReportExpandableLeftContentLayout from "../../../common/components/ReportExpandableLeftContentLayout/ReportExpandableLeftContentLayout";
import { isfeatureEnable } from "../../../common/utils/feature-flag-utils";
import { type RootState } from "../../../store";
import { CROSS_SHOP_BASE_COLOUR_MAP } from "../../constants/cross-shop-base-colour-map";
import { CrossShopFeatureFlags } from "../../constants/cross-shop-feature-flags";
import { INTERACTIONS_VENN_CHART_COLOUR_MAP } from "../../constants/interactions-venn-chart-colour-map";
import { INTERACTIONS_VENN_TABLE_COLOUR_MAP } from "../../constants/interactions-venn-table-colour-map";
import { type InteractionsVennMetric } from "../../models/InteractionsVennMetric";
import { type InteractionsVennMetricColumnHeaderKey as ColumnHeaderKey } from "../../models/InteractionsVennMetricColumnHeaderKey";
import { InteractionsVennMetricColumnHeaderKey } from "../../models/InteractionsVennMetricColumnHeaderKey";
import { type MiniFocalTableRow } from "../../models/MiniFocalTableRow";
import { useLazyGetInteractionsVennChartDataQuery } from "../../services/customer-cross-shop-interactions-venn-api-slice";
import {
  selectChannel,
  selectFocalItems,
  selectLocalParametersInitialised,
  selectLocalSelections,
  selectLocation,
  selectProductInteraction,
  selectReportId,
  selectSegmentation,
  selectTimePeriodLength,
} from "../../services/customer-cross-shop-slice";
import { getCssStyledString } from "../../utils/getCssStyledString";
import getLocalParametersSummary from "../../utils/getLocalParametersSummary";
import { getVennChartSelectedSeries } from "../../utils/getVennChartSelectedSeries";
import {
  PRODUCT_INTERACTION_DISPLAY_NAME_MAP,
  getVennChartSeries,
} from "../../utils/getVennChartSeries";
import { CrossShopInteractionsReportletMiniFocalItemTable } from "./CrossShopInteractionsReportletMiniFocalItemTable";
import styles from "./CrossShopInteractionsVenn.module.css";
import {
  CrossShopInteractionsVennFocalItemCsvTransformation,
  CrossShopInteractionsVennItemCombinationCsvTransformation,
} from "./CrossShopInteractionsVennCsvTransformation";
import { CrossShopInteractionsVennCustomTooltip } from "./CrossShopInteractionsVennCustomTooltip";
import { ItemCombinationTable } from "./ItemCombinationTable";

export type CrossShopInteractionsVennProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

type ItemTableSelectedColumnState = {
  selectedColumnColour: string | undefined;
  selectedColumns: string[] | undefined;
};

const EMPTY_ITEM_TABLE_SELECTED_COLUMN_STATE: ItemTableSelectedColumnState = {
  selectedColumnColour: undefined,
  selectedColumns: undefined,
};

export const CrossShopInteractionsVenn = ({
  infoPanelSummary,
}: CrossShopInteractionsVennProps) => {
  const { name: divisionName } = useDivision();
  const { id } = useParams();

  const { metricFormatter } = useNumberFormat();

  const chartRef = useRef();

  const focalItems = useSelector(selectFocalItems);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const productInteraction = useSelector(selectProductInteraction);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const segmentation = useSelector(selectSegmentation);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const localSelections = useSelector(selectLocalSelections);
  const reportId = useSelector(selectReportId);

  const {
    topDrawerTableMetrics,
    topDrawerTableRows,
    isGetTableRowsQueryLoading,
    reportName,
  } = useSelector((state: RootState) => ({
    topDrawerTableMetrics: state.customerCrossShop.topDrawerTableData.metrics,
    topDrawerTableRows: state.customerCrossShop.topDrawerTableData.tableRows,
    isGetTableRowsQueryLoading:
      state.customerCrossShop.isGetTableRowsQueryLoading,
    reportName: state.customerCrossShop.reportName,
  }));

  const isfeatureEnabled = isfeatureEnable(CrossShopFeatureFlags.ReportExport);
  const [expanded, setExpanded] = useState(true);

  // Venn states
  const [selectedZone, setSelectedZone] = useState<string | undefined>();
  const [vennChartData, setVennChartData] = useState<PointOptionsObject[]>();

  // Item table states
  const [selectedColumnState, setSelectedColumnState] =
    useState<ItemTableSelectedColumnState>(
      EMPTY_ITEM_TABLE_SELECTED_COLUMN_STATE
    );

  // TODO this is a workaround until we come up with a better way to resize chart
  // https://quantium.atlassian.net/browse/CO3-3232
  const handleExpandableLeftContentLayoutChange = (isExpanded: boolean) => {
    setExpanded(isExpanded);

    // @ts-expect-error @ts-ignore
    chartRef.current?.resize("90%");
    setTimeout(() => {
      // @ts-expect-error @ts-ignore
      chartRef.current?.resize("100%");
    }, 200);
  };

  // Venn Chart Query
  const [
    triggerVennChartDataQuery,
    { data, isSuccess, isFetching, isLoading },
  ] = useLazyGetInteractionsVennChartDataQuery();

  const resetVennChartAndSelectedColumn = useCallback(() => {
    setVennChartData(
      getVennChartSeries(
        focalItems,
        productInteraction.value as string,
        data?.metrics
      )
    );

    setSelectedColumnState(EMPTY_ITEM_TABLE_SELECTED_COLUMN_STATE);
    setSelectedZone(undefined);
  }, [data?.metrics, focalItems, productInteraction]);

  const fetchData = useCallback(async () => {
    await triggerVennChartDataQuery({
      divisionName,
      payload: {
        focalItems,
        parameterSelections: {
          channel: channel.value as string,
          segmentation: segmentation.map((item) => item.value as string),
          locationNodeNumber: location.nodeNumber,
        },
        reportId: id ? id : "",
      },
    });
  }, [
    channel.value,
    divisionName,
    focalItems,
    id,
    location.nodeNumber,
    segmentation,
    triggerVennChartDataQuery,
  ]);

  useEffect(() => {
    if (
      divisionName &&
      focalItems.length >= 0 &&
      localParametersInitialised &&
      reportId === id
    ) {
      fetchData()
        // make sure to catch any error
        .catch((error) => {
          // FIXME throw this somewhere
          ddLog("ERROR", {}, "error", error);
        });
    }
  }, [
    divisionName,
    fetchData,
    focalItems,
    localParametersInitialised,
    reportId,
    id,
  ]);

  useEffect(() => {
    if (data) {
      resetVennChartAndSelectedColumn();
    }
  }, [data, resetVennChartAndSelectedColumn]);

  const getCustomLabel = useCallback(
    (point: Point) => {
      if (point.options.custom?.displayValue === 0) {
        return "";
      }

      // Styles for the custom label
      const pointValueWrapperStyling: CSSProperties = {
        minWidth: "5rem",
        display: "inline-block",
      };

      const pointValueStyling: CSSProperties = {
        padding: "0.05rem 0.25rem",
        color: "var(--qbit-colour-text-primary)",
        borderRadius: "0.1rem",
        backgroundColor: "var(--qbit-colour-white)",
      };

      const pointNameStyling: CSSProperties = {
        display: "block",
        marginTop: "0.5rem",
        minWidth: "5rem",
        textTransform: "uppercase",
      };

      const productInteractionsDisplayName =
        PRODUCT_INTERACTION_DISPLAY_NAME_MAP[
          `${productInteraction.value}Percentage` as keyof typeof PRODUCT_INTERACTION_DISPLAY_NAME_MAP
        ];

      const format = data?.metrics.find(
        (metric) => metric.displayName === productInteractionsDisplayName
      )?.format;

      const pointValueStringArray = [];

      let selectedSetValue;
      const labelArray = [];

      // When user has selected on a particular zone
      if (selectedZone) {
        // Provide prefix text for single series, e.g. A:
        const selectedSetName =
          point.options.sets &&
          point.options.sets.length === 1 &&
          point.options.sets[0] === selectedZone
            ? `${point.options.sets[0]}`
            : "";
        pointValueStringArray.push(selectedSetName);

        if (point.options.custom?.value) {
          selectedSetValue = metricFormatter(
            format ? format : MetricTypes.None,
            point.options.custom.value
          );

          pointValueStringArray.push(selectedSetValue);
        }
      } else {
        // When user has NOT selected a particular zone

        // Provide prefix text for single series, e.g. A:
        const selectedSetName =
          point.options.sets && point.options.sets.length === 1
            ? `${point.options.sets[0]}`
            : "";
        pointValueStringArray.push(selectedSetName);

        // Provide point value in percentage, e.g. 56%
        selectedSetValue = metricFormatter(
          format ? format : MetricTypes.None,
          point.options.custom?.displayValue
        );

        pointValueStringArray.push(selectedSetValue);
      }

      const pointValueString = pointValueStringArray
        .filter((string_) => string_ !== "")
        .join(": ");

      if (pointValueString !== "") {
        labelArray.push(
          `<span style="${getCssStyledString(
            pointValueWrapperStyling
          )}"><b style="${getCssStyledString(
            pointValueStyling
          )}">${pointValueString}</b></span>`
        );
      }

      if (
        !selectedZone ||
        (point.options.sets &&
          point.options.sets.length === 1 &&
          point.options.sets[0] === selectedZone)
      ) {
        labelArray.push(
          `<span style="${getCssStyledString(pointNameStyling)}">${
            point.name
          }</span>`
        );
      }

      return labelArray.join("");
    },
    [data?.metrics, metricFormatter, productInteraction, selectedZone]
  );

  const handleVennChartAreaClick = useCallback(
    (selectedSets: string[]) => {
      // Reset:
      // Venn diagram to default and
      // ItemTable column background colour to none
      // when A+B, A+C or A+B+C areas are selected
      if (selectedSets.length !== 1) {
        resetVennChartAndSelectedColumn();
        return;
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention, canonical/id-match
      const selectedZone_ = selectedSets[0];
      setSelectedZone(selectedZone_);

      // Update venn diagram series colours
      if (vennChartData) {
        const newVennChartData = getVennChartSelectedSeries(
          vennChartData,
          productInteraction.value as string,
          selectedZone_,
          data?.metrics
        );

        setVennChartData(newVennChartData);
      }

      // Update ItemTable column background colour selections continues
      if (selectedColumnState.selectedColumns?.includes(selectedZone_)) {
        // Toggle: reset to default state when selected zone is reclicked
        resetVennChartAndSelectedColumn();
      } else {
        // Update selected zones with correct colours
        const keys = Object.keys(InteractionsVennMetricColumnHeaderKey).filter(
          (key) => key.includes(selectedZone_)
        );

        const colour =
          INTERACTIONS_VENN_CHART_COLOUR_MAP[
            selectedZone_ as keyof typeof InteractionsVennMetricColumnHeaderKey
          ];

        setSelectedColumnState({
          selectedColumnColour: colour,
          selectedColumns: keys,
        });
      }
    },
    [
      data?.metrics,
      productInteraction,
      resetVennChartAndSelectedColumn,
      selectedColumnState.selectedColumns,
      vennChartData,
    ]
  );

  const getMiniFocalItemTableRows = (): MiniFocalTableRow[] =>
    focalItems.flatMap((focalItem, index) => {
      const selectedRow = topDrawerTableRows.find(
        (row) =>
          row.item.itemCode === focalItem.itemCode &&
          row.item.shortName === focalItem.shortName
      );

      // Convert 0, 1, 2 to 'A', 'B', 'C'
      const key = String.fromCharCode(65 + index);

      const colour =
        CROSS_SHOP_BASE_COLOUR_MAP[
          key as keyof typeof CROSS_SHOP_BASE_COLOUR_MAP
        ];

      return selectedRow && colour
        ? {
            ...selectedRow,
            item: {
              ...selectedRow.item,
              key,
              color: colour,
            },
          }
        : [];
    });

  const getCustomTooltip = useCallback(
    ({ point }: TooltipFormatterContextObject) => {
      let matched: boolean | undefined = false;
      if (selectedZone) {
        matched = point.options.sets?.includes(selectedZone);
      }

      if (
        !point.options.custom ||
        !("tooltipData" in point.options.custom) ||
        !point.options.sets
      ) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
      }

      const tooltipData: InteractionsVennMetric[] =
        point.options.custom.tooltipData;
      const metricValueKey = point.options.sets.join("");

      const metrics = tooltipData.map((td: InteractionsVennMetric) => {
        const mDisplayName = td.displayName;
        const value = td.metricValues.find(
          (mv) => mv.key === metricValueKey
        )?.value;

        return {
          displayName: mDisplayName,
          value: metricFormatter(td.format, value),
        };
      });

      const sets = point.options.sets;
      const colours = sets.flatMap(
        (set: string) =>
          INTERACTIONS_VENN_TABLE_COLOUR_MAP[
            set as keyof typeof ColumnHeaderKey
          ]
      );

      const names: string[] = sets
        .map((set: string) => {
          const matchedData = vennChartData?.find(
            (cData) =>
              cData.sets && cData.sets.length === 1 && cData.sets.includes(set)
          );

          if (matchedData) return matchedData.name;

          return undefined;
        })
        .filter(Boolean) as string[];

      const displayNameKey = sets.join("");
      const displayName =
        data?.columns.find((col) => col.key === displayNameKey)?.displayName ??
        "";

      // NOTE: do not try and do calculations within the CrossShopInteractionsVennCustomTooltip component
      // It will cause some very unhappy errors to appear. Pass already calculated values in instead
      return !selectedZone || matched ? (
        CrossShopInteractionsVennCustomTooltip({
          colours,
          displayName,
          metrics,
          names,
        })
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      );
    },
    [data?.columns, metricFormatter, selectedZone, vennChartData]
  );

  const chartContainerRef = useRef<HTMLElement>();
  const parameterSummary = useMemo(
    () => getLocalParametersSummary(localSelections, focalItems),
    [localSelections, focalItems]
  );

  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const tableRows = getMiniFocalItemTableRows();
  const focalItemCsvTransformationCallback = useCallback(
    () =>
      CrossShopInteractionsVennFocalItemCsvTransformation(
        topDrawerTableMetrics,
        currencySymbol,
        tableRows
      ),
    [topDrawerTableMetrics, currencySymbol, tableRows]
  );

  const itemCombinationCsvTransformationCallback = useCallback(
    () =>
      CrossShopInteractionsVennItemCombinationCsvTransformation(
        data,
        currencySymbol
      ),
    [data, currencySymbol]
  );

  const exportChartFilename = useMemo(
    () =>
      cleanFilename(
        `Cross_Shop_Interactions_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const exportTableFilename = useMemo(
    () =>
      cleanFilename(
        `Cross_Shop_Interactions_Table_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  return (
    <ReportExpandableLeftContentLayout
      className={styles.crossShopInteractionsVennContentLayout}
      expanded={expanded}
      onExpandedChange={handleExpandableLeftContentLayoutChange}
    >
      <ReportExpandableLeftContentLayout.LeftExpandableContainer>
        <ReportExpandableLeftContentLayout.LeftTopContent>
          <div className={styles.crossShopInteractionsVennLeftTopContent}>
            <ErrorBoundary>
              <NoDataChartWrapper
                isLoading={
                  isFetching ||
                  isLoading ||
                  !localParametersInitialised ||
                  !vennChartData
                }
                noData={!data || data.metrics.length === 0 || !vennChartData}
              >
                {vennChartData ? (
                  <ChartFooterWrapper
                    height="465px"
                    parameters={parameterSummary}
                    ref={chartContainerRef}
                  >
                    <VennChart
                      CustomTooltip={getCustomTooltip}
                      data={vennChartData}
                      getCustomLabel={getCustomLabel}
                      onAreaClicked={handleVennChartAreaClick}
                      onOptionsChanged={setCurrentOptions}
                      ref={chartRef}
                    />
                  </ChartFooterWrapper>
                ) : null}
              </NoDataChartWrapper>
            </ErrorBoundary>
          </div>
        </ReportExpandableLeftContentLayout.LeftTopContent>
        <ReportExpandableLeftContentLayout.LeftBottomContent>
          <ErrorBoundary>
            <NoDataChartWrapper
              isLoading={isGetTableRowsQueryLoading}
              noData={
                !isGetTableRowsQueryLoading &&
                (topDrawerTableRows.length === 0 ||
                  topDrawerTableMetrics.length === 0)
              }
            >
              <div className={styles.focalItemContainer}>
                <CrossShopInteractionsReportletMiniFocalItemTable
                  className={styles.crossShopInteractionsVennLeftBottomContent}
                  hideMetricColumns={!expanded}
                  metrics={topDrawerTableMetrics}
                  tableRows={getMiniFocalItemTableRows()}
                />
                <div className={styles.chartOptionsContainer}>
                  <ChartOptions
                    downloadWizardOptions={
                      currentOptions
                        ? {
                            chartOptions: currentOptions,
                            reportIcon: (
                              <ReportIcon type={ReportType.CustomerCrossShop} />
                            ),
                            chartTitle: `Cross shop interactions - ${reportName}`,
                            reportTitle: reportName,
                          }
                        : undefined
                    }
                    filename={exportChartFilename}
                    getCSVData={focalItemCsvTransformationCallback}
                    getElementToExport={() => chartContainerRef.current}
                    isFeatureEnabled={isfeatureEnabled}
                    localParameters={parameterSummary}
                    reportParameters={infoPanelSummary}
                  />
                </div>
              </div>
            </NoDataChartWrapper>
          </ErrorBoundary>
        </ReportExpandableLeftContentLayout.LeftBottomContent>
      </ReportExpandableLeftContentLayout.LeftExpandableContainer>
      <ReportExpandableLeftContentLayout.RightResizableContainer>
        <div className={styles.crossShopInteractionsVennRightContent}>
          <ErrorBoundary>
            <NoDataChartWrapper
              isLoading={!(isSuccess && localParametersInitialised)}
              noData={!data || data.metrics.length === 0}
            >
              <div className={styles.focalItemContainer}>
                <ItemCombinationTable
                  colourMap={INTERACTIONS_VENN_TABLE_COLOUR_MAP}
                  columns={data ? data.columns : []}
                  metrics={data ? data.metrics : []}
                  selectedColumnColour={
                    selectedColumnState.selectedColumnColour
                  }
                  selectedColumns={selectedColumnState.selectedColumns}
                />
                <div className={styles.chartOptionsContainer}>
                  <DataTableOptions
                    filename={exportTableFilename}
                    invokeCSVDownload={itemCombinationCsvTransformationCallback}
                    isFeatureEnabled={isfeatureEnabled}
                    localParameters={parameterSummary}
                    reportParameters={infoPanelSummary}
                  />
                </div>
              </div>
            </NoDataChartWrapper>
          </ErrorBoundary>
        </div>
      </ReportExpandableLeftContentLayout.RightResizableContainer>
    </ReportExpandableLeftContentLayout>
  );
};
