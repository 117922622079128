import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  Icon,
  IconGlyph,
} from "@quantium-enterprise/qds-react";
import { useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../../store";
import { onModalSubmit } from "../../services/product-substitutability-slice";
import ProductSubstitutabilityAttributesContent from "./ProductSubstitutabilityAttributesContent";
import styles from "./ProductSubstitutabilityModal.module.css";

export const ProductSubstitutabilityModal = () => {
  const triggerRef = useRef();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { attributes, columnVisibility } = useSelector((state: RootState) => ({
    attributes: state.productSubstitutability.attributes,
    columnVisibility: state.productSubstitutability.columnVisibility,
  }));

  const modalDataLoaded: boolean = useMemo(
    () => attributes.length > 0,
    [attributes]
  );

  const trigger = useMemo(
    () => (
      <Button
        disabled={!modalDataLoaded}
        height={ButtonHeight.XSmall}
        onClick={() => {
          setShowModal(true);
        }}
        ref={triggerRef}
        variant={ButtonVariant.Stealth}
      >
        <svg
          className={styles.triggerButtonIcon}
          fill={
            modalDataLoaded
              ? "var(--qbit-colour-brand-700)"
              : "var(--qbit-colour-shade-8)"
          }
          viewBox="0 -960 960 960"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M120-280v-400q0-33 23.5-56.5T200-760h559q33 0 56.5 23.5T839-680v400q0 33-23.5 56.5T759-200H200q-33 0-56.5-23.5T120-280Zm80 0h133v-400H200v400Zm213 0h133v-400H413v400Zm213 0h133v-400H626v400Z" />
        </svg>
        <span>Attributes</span>
      </Button>
    ),
    [modalDataLoaded]
  );

  return (
    <>
      {/* React portal used to have modal appear over the entire page and grey out everything */}
      {createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div aria-hidden="true" onClick={(event) => event.stopPropagation()}>
          <Dialog
            className={styles.modal}
            header={
              <div>
                <h2 className={styles.modalHeader}>Show attributes</h2>
                <Button
                  className={styles.closeDialogButton}
                  height={ButtonHeight.Large}
                  onClick={() => setShowModal(false)}
                  variant={ButtonVariant.Stealth}
                >
                  <Icon
                    glyph={IconGlyph.DeleteAndCloseClose}
                    text="Close selection"
                  />
                </Button>
              </div>
            }
            onClose={() => setShowModal(false)}
            show={showModal}
            titleId="attributes-modal"
            triggeredBy={triggerRef}
          >
            <div className={styles.modalContent}>
              <ProductSubstitutabilityAttributesContent
                attributes={attributes}
                handleOnToggle={(value: string) => {
                  dispatch(onModalSubmit(value));
                }}
                selectedAttributes={Object.keys(
                  Object.fromEntries(
                    Object.entries(columnVisibility).filter(
                      ([, value]) => value
                    )
                  )
                )}
              />
            </div>
          </Dialog>
        </div>,
        document.body
      )}
      {trigger}
    </>
  );
};

export default ProductSubstitutabilityModal;
