import { type ReportParametersDto } from "@quantium-enterprise/common-ui";
import {
  type DeferredFormatFunction,
  useFormatter,
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { type GroupColumnDef, type ColumnDef } from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getFocalItemSummaryWithParents } from "../../common/utils/export-parameter-summary-utils";
import { CompareMetricsFeatureFlags } from "../constants/compare-metrics-feature-flags";
import {
  selectButtonSelections,
  selectChartContentSelection,
  selectContributionMetrics,
  selectFocalItemParents,
  selectFocalItems,
  selectLocalParametersConfig,
  selectLocalParametersSelections,
  selectMetricSelectionSelection,
  selectReportletData,
  selectXAxisSelection,
} from "../services/compare-metrics-slice-selectors";
import {
  getChartHeader,
  type CompareMetricsTableData,
} from "../utils/reportlet-table-utils";
import { getMetaDataMapping } from "../utils/reportlet-utils";
import styles from "./CompareMetricsReportlet.module.css";
import { csvTransformationTable } from "./csvTransformation";

const metricKey = "Metric";
export const createColumn = (
  chartContent: string,
  rows: CompareMetricsTableData[],
  index: number,
  hasSentiment: boolean,
  formatter: DeferredFormatFunction
): ColumnDef<CompareMetricsTableData> => {
  const column: ColumnDef<CompareMetricsTableData> = {
    accessorFn: (row) => row.values[index],
    cell: ({ getValue }) =>
      ValueCell({
        hasSentiment,
        formatter: formatter(rows[0].format),
        value: getValue() as number,
      }),
    footer: (properties) => properties.column.id,
    header: () => <span className={styles.alignRight}>{chartContent}</span>,
    id: uniqueId(),
  };

  return column;
};

export const createColumnGroup = (
  chartContentMetadata: string[],
  rows: CompareMetricsTableData[],
  metric: string,
  hasSentiment: boolean,
  formatter: DeferredFormatFunction
):
  | ColumnDef<CompareMetricsTableData>
  | GroupColumnDef<CompareMetricsTableData> => {
  const columnGroup:
    | ColumnDef<CompareMetricsTableData>
    | GroupColumnDef<CompareMetricsTableData> = {
    columns: chartContentMetadata.map((chartContent, index) =>
      createColumn(chartContent, rows, index, hasSentiment, formatter)
    ),
    footer: (properties) => properties.column.id,
    header: () => <span className={styles.alignCenter}>{metric}</span>,
    id: uniqueId(),
  };

  return columnGroup;
};

export const generateDataTableColumns = (
  rows: CompareMetricsTableData[],
  chartContentMetadata: string[],
  cornerHeader: string,
  isContributionMetric: boolean,
  xAxisSelection: string,
  chartContentSelection: string,
  metricVariant: string,
  formatter: DeferredFormatFunction
): Array<
  ColumnDef<CompareMetricsTableData> | GroupColumnDef<CompareMetricsTableData>
> => {
  const columns: Array<
    ColumnDef<CompareMetricsTableData> | GroupColumnDef<CompareMetricsTableData>
  > = [
    {
      accessorFn: (row) => row.header,
      footer: (properties) => properties.column.id,
      header: () => <span>{cornerHeader}</span>,
      id: uniqueId(),
    },
    isContributionMetric ||
    (xAxisSelection !== metricKey && chartContentSelection !== metricKey) ||
    xAxisSelection === metricKey
      ? createColumnGroup(
          chartContentMetadata,
          rows,
          metricVariant,
          isContributionMetric || metricVariant.includes("%"),
          formatter
        )
      : createColumn(
          metricVariant,
          rows,
          0,
          metricVariant.includes("%"),
          formatter
        ),
  ];

  return columns;
};

// export const CompareMetricsReportletTable = () => {

export type CompareMetricReportletProps = {
  reportParameter?: ReportParametersDto;
};
export const CompareMetricsReportletTable = ({
  reportParameter,
}: CompareMetricReportletProps) => {
  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const xAxisSelection = useSelector(selectXAxisSelection);
  const chartContentSelection = useSelector(selectChartContentSelection);
  const metricSelection = useSelector(selectMetricSelectionSelection);
  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );
  const localParametersConfig = useSelector(selectLocalParametersConfig);
  const contributionMetrics = useSelector(selectContributionMetrics);
  const metricTypeSelection = useSelector(
    selectButtonSelections
  ).metricTypeSelection;
  const reportletData = useSelector(selectReportletData);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CompareMetricsFeatureFlags.ReportExport] ?? false;

  const dataIndex = useMemo(
    () =>
      reportletData.itemData.findIndex(
        (tab) => tab.type === metricTypeSelection
      ),
    [metricTypeSelection, reportletData.itemData]
  );

  const metricVariant = useMemo(
    () => reportletData.itemData[dataIndex].metric,
    [reportletData, dataIndex]
  );

  const isContributionMetric = useMemo(
    () => contributionMetrics.some((x) => x.groupName === metricSelection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metricSelection]
  );
  const formatter = useFormatter();

  const tableRows = useMemo(
    () =>
      getMetaDataMapping(
        xAxisSelection,
        reportletData.xAxisMetaData,
        focalItems,
        focalItemParents,
        localParametersConfig
      ).map(
        (metaData, index) =>
          ({
            format: reportletData.itemData[dataIndex].format,
            header: metaData,
            type: reportletData.itemData[dataIndex].type,
            values: reportletData.itemData[dataIndex].values.map(
              (value: number[]) => value[index]
            ),
          } as CompareMetricsTableData)
      ),
    [
      xAxisSelection,
      reportletData.xAxisMetaData,
      reportletData.itemData,
      focalItems,
      focalItemParents,
      localParametersConfig,
      dataIndex,
    ]
  );

  const mappedChartContentMetadata = useMemo(
    () =>
      getMetaDataMapping(
        chartContentSelection,
        reportletData.chartContentMetaData,
        focalItems,
        focalItemParents,
        localParametersConfig
      ),
    [
      chartContentSelection,
      reportletData.chartContentMetaData,
      focalItems,
      focalItemParents,
      localParametersConfig,
    ]
  );

  const cornerHeader = getChartHeader(xAxisSelection);

  const tableColumns = useMemo(
    () =>
      generateDataTableColumns(
        tableRows,
        mappedChartContentMetadata,
        cornerHeader,
        isContributionMetric,
        xAxisSelection,
        chartContentSelection,
        metricVariant,
        formatter
      ),
    [
      tableRows,
      mappedChartContentMetadata,
      cornerHeader,
      isContributionMetric,
      xAxisSelection,
      chartContentSelection,
      metricVariant,
      formatter,
    ]
  );
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Compare_Metrics_Table_${localParametersSelections.Time[0].value}_${localParametersSelections.LocationHierarchy[0].label}`
      ),
    [localParametersSelections]
  );
  const parameterSummary = useMemo(
    () => [
      {
        name: "Focal Item",
        value: getFocalItemSummaryWithParents(focalItems, focalItemParents),
      },
      {
        name: "Time",
        value: `${localParametersSelections.Time[0].value}`,
      },
      {
        name: "Metric",
        value: metricSelection,
      },
      {
        name: "Channel",
        value: Object.values(localParametersSelections.Channel)
          .map((value) => value.label + " ")
          .toString(),
      },
      {
        name: "Promotion",
        value: Object.values(localParametersSelections.Promotion)
          .map((value) => value.label + " ")
          .toString(),
      },
      {
        name: "Segmentation",
        value: localParametersSelections.Segmentation[0].label,
      },
      {
        name: "Customer Segments",
        value: Object.values(localParametersSelections.Segmentation)
          .map((value) => value.label + " ")
          .slice(1)
          .toString(),
      },
      {
        name: "Location",
        value: Object.values(localParametersSelections.LocationHierarchy)
          .map((value) => value.label + " ")
          .toString(),
      },
    ],
    [
      focalItems,
      focalItemParents,
      localParametersSelections.Time,
      localParametersSelections.Channel,
      localParametersSelections.Promotion,
      localParametersSelections.Segmentation,
      localParametersSelections.LocationHierarchy,
      metricSelection,
    ]
  );
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformationTable(
        tableRows.map((row) => row.header),
        tableRows.map((row) => row.values),
        metricVariant,
        mappedChartContentMetadata,
        currencySymbol,
        xAxisSelection,
        reportletData.itemData[dataIndex].format
      ),
    [
      metricVariant,
      tableRows,
      mappedChartContentMetadata,
      xAxisSelection,
      currencySymbol,
      dataIndex,
      reportletData,
    ]
  );

  return (
    <div className={styles.reportletChartContainer}>
      <div className={styles.chartOptionsContainer}>
        <div className={styles.container}>
          <DataTableOptions
            filename={exportFilename}
            invokeCSVDownload={chartCsvTransformationCallback}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={reportParameter}
          />
        </div>
      </div>
      <div className={styles.compareMetricsDatatable}>
        <BasicTable
          className={styles.reportletTable}
          columns={tableColumns}
          data={tableRows}
          pinFirstColumn
        />
      </div>
    </div>
  );
};

export default CompareMetricsReportletTable;
