import { MetricTypes } from "@quantium-enterprise/hooks-ui";

/**
 * Standard metric definition type to be used with below declaration
 */
export type MetricDefinitions = Array<{
  groupName: string;
  metrics: Array<{
    format: string;
    label: string;
    name: string;
  }>;
}>;

/**
 *  Returns default metric definitions and groupings as specified
 *  in the doc here: https://quantium.atlassian.net/wiki/spaces/RP/pages/298058642/Metrics+Mapping
 */
export const getDefaultMetrics = () => {
  const metricDefinitions = [
    {
      groupName: "Cumulative Metrics",
      metrics: [
        {
          label: "Sales (Cumulative)",
          name: "SalesCumulative",
          format: MetricTypes.Currency,
        },
        {
          label: "Units",
          name: "UnitsCumulative",
          format: MetricTypes.Integer,
        },
        {
          label: "Volume",
          name: "VolumeCumulative",
          format: MetricTypes.Decimal,
        },
        {
          label: "Total customers",
          name: "CustomersCumulative",
          format: MetricTypes.Integer,
        },
        {
          label: "Repeat rate",
          name: "RepeatRateCumulative",
          format: MetricTypes.Percentage,
        },
      ],
    },
    {
      groupName: "Weekly Metrics",
      metrics: [
        { label: "Sales", name: "Sales", format: MetricTypes.Currency },
        { label: "Units", name: "Units", format: MetricTypes.Integer },
        { label: "Volume", name: "Volume", format: MetricTypes.Decimal },
        {
          label: "Total customers",
          name: "Customers",
          format: MetricTypes.Integer,
        },
        {
          label: "Average unit price",
          name: "Average Price",
          format: MetricTypes.Currency,
        },
        {
          label: "Sales per store per week",
          name: "UnitsPerStorePerWeek",
          format: MetricTypes.Currency,
        },
        {
          label: "Volume Per store per week",
          name: "VolumePerStorePerWeek",
          format: MetricTypes.Decimal,
        },
        {
          label: "Stores ranged",
          name: "StoresRanged",
          format: MetricTypes.Integer,
        },
        {
          label: "Stores sold",
          name: "StoresSold",
          format: MetricTypes.Integer,
        },
        {
          label: "Weighted ranged distribution",
          name: "StoreDistributionWeightedRanged",
          format: MetricTypes.Decimal,
        },
        {
          label: "Weighted sold distribution",
          name: "StoreDistributionWeightedSold",
          format: MetricTypes.Decimal,
        },
      ],
    },
  ] as MetricDefinitions;

  return metricDefinitions;
};

/**
 * Helper function to extract the correct formatter from the provivided metrics definitons
 */
export const getFormatterFromMetricDefinitons = (
  metricDefinitions: MetricDefinitions,
  selectedMetric: string,
  defaultformat: string = MetricTypes.Integer
) =>
  metricDefinitions
    .flatMap((definition) => definition.metrics)
    .find((metric) => metric.name === selectedMetric)?.format ?? defaultformat;

/**
 * Helper function allowing the toggle store of a provided unique value to a given series.
 * If the provided unique value already exists within the target series it will be removed,
 * otherwise it will be added.
 *
 * @param {number} uniqueValue the unique value which will be searched for then removed or added.
 * @param {number[]} targetSeries the series through which the search will be conducted
 * @param {Function} setter A callback function called with the new series.
 */
export const toggleAddToSeries = (
  uniqueValue: number,
  targetSeries: number[],
  setter: (series: number[]) => void
) => {
  const newSeries = [...targetSeries];
  const index = targetSeries.indexOf(uniqueValue);
  if (index === -1) {
    newSeries.push(uniqueValue);
  } else {
    newSeries.splice(index, 1);
  }

  setter(newSeries);
};

/**
 * Preset colour scheme for benchmarks.
 */
export const benchmarkColours = [
  "var(--qbit-colour-shade-15)",
  "var(--qbit-colour-shade-12)",
  "var(--qbit-colour-shade-9)",
  "var(--qbit-colour-shade-6)",
  "var(--qbit-colour-shade-4)",
];

/**
 * Preset chart colour scheme for active series
 */
export const chartColours = [
  "#E88030",
  "#3EAFBF",
  "#C96478",
  "#5885FF",
  "#DBAC49",
  "#B10200",
  "#7256A7",
  "#219653",
  "#1B3B84",
  "#736E68",
];

/**
 * Expected data type for chart input
 */
export type NpbChartDataItem = {
  label: string;
  values: number[];
};

/**
 * Reshapes chart data by adding colour and other metadata needed for highcharts
 *
 * @param chartData data which is to be enriched by adding colour and metadata
 * @param colours a list of colours which will be assigned in order
 * @param isBenchmark adds an "isBenchmark" flag to the return object
 * @returns {{colour: string; data: number[]; name: string; isBenchmark?: boolean}[]} reshaped data for highcharts input
 */
export const reshapeNpbChartData = (
  chartData: NpbChartDataItem[],
  colours: string[],
  isBenchmark?: boolean
) => {
  if (colours.length > 0) {
    return chartData.map(({ label, values }, index) => ({
      colour: colours[index % colours.length],
      data: values,
      name: label,
      isBenchmark,
    }));
  } else {
    throw new Error("Array of colours must contain at least one element");
  }
};
