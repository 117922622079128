import {
  type DeferredFormatFunction,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { type ColumnDef } from "@tanstack/react-table";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { DEFAULT_COLUMN_WIDTH } from "components-ui/src/tables/common/constants";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { type Series } from "../../models/basket-quantities-common-models";
import styles from "./BasketQuantitiesSummaryTable.module.css";

export type BasketQuantitiesSummaryTableProps = {
  categories: string[];
  series: Series[];
};

type TableDataSeries = {
  data: Array<number | null | undefined>;
  quantityPerBasket: string;
};

const generateDataTableColumns = (
  series: Series[],
  formatter: DeferredFormatFunction
): Array<ColumnDef<TableDataSeries>> => {
  const columns: Array<ColumnDef<TableDataSeries>> = [
    {
      accessorFn: (row) => row.quantityPerBasket,
      cell: ({ getValue }) => (
        <div className={styles.firstColumn}>{getValue() as string}</div>
      ),
      footer: (properties) => properties.column.id,
      header: () => (
        <span className={styles.colHeaders}>Quantity per basket</span>
      ),
      id: "Quantity per basket",
    },
    ...series.map(
      (metricSeries, index) =>
        ({
          accessorFn: (row) => row.data[index],
          cell: ({ getValue }) => (
            <ValueCell
              formatter={formatter(metricSeries.format)}
              value={getValue() as number}
            />
          ),
          header: () => (
            <span className={styles.colHeaders}>
              {metricSeries.metricLabel}
            </span>
          ),
          id: metricSeries.metricLabel,
        } as ColumnDef<TableDataSeries>)
    ),
  ];
  return columns;
};

const pivotChartDataToTableData = (
  chartSeries: Series[],
  chartCategories: string[]
): TableDataSeries[] => {
  const tableData = chartCategories.map(
    (category) =>
      ({
        quantityPerBasket: category,
        data: [],
      } as TableDataSeries)
  );
  const tableCategories: string[] = [];
  for (const series of chartSeries) {
    tableCategories.push(series.metricLabel);
    for (const [index, dataPoint] of series.data.entries()) {
      tableData[index].data.push(dataPoint.metricValue);
    }
  }

  return tableData;
};

export const BasketQuantitiesSummaryTable = ({
  series,
  categories,
}: BasketQuantitiesSummaryTableProps) => {
  const formatter = useFormatter();
  return (
    <div className={styles.summaryTable}>
      <BasicTable
        columns={generateDataTableColumns(series, formatter)}
        data={pivotChartDataToTableData(series, categories)}
        pinFirstColumn
        pinnedColumnStyles={{ minWidth: DEFAULT_COLUMN_WIDTH }}
      />
    </div>
  );
};
