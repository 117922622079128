import {
  IconColour,
  IconSize,
  InlineIcon,
  InlineIconGlyph,
  Item,
  Text,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { type PropsWithChildren } from "react";
import styles from "./InfoBanner.module.css";

export type InfoBannerProps = {
  text: string;
};

// Minimalistic, to be used in ReportWizard where vertical height is limited.
export const InfoBanner = ({ text }: InfoBannerProps) => (
  <Item className={styles.infoBannerContainer}>
    <InlineIcon
      colour={IconColour.Notification}
      glyph={InlineIconGlyph.AlertsAndNotificationsInformFilled}
      text={text}
    />
    <Text>{text}</Text>
  </Item>
);

export type StandAloneInfoBannerProps = {
  className?: string;
  iconTooltip?: string;
};

// Allows more flexibility and intended to be used where there is more space. Has more padding a bit more styling, like a border.
export const StandAloneInfoBanner = ({
  className,
  iconTooltip,
  children,
}: PropsWithChildren<StandAloneInfoBannerProps>) => (
  <Item className={classNames(styles.standAloneInfoBannerContainer, className)}>
    <InlineIcon
      colour={IconColour.Notification}
      glyph={InlineIconGlyph.AlertsAndNotificationsInformFilled}
      size={IconSize.Small}
      text={iconTooltip ?? ""}
    />
    <Text>
      <>{children}</>
    </Text>
  </Item>
);

export default InfoBanner;
