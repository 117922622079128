import { type CrossShopDataTableRequestDto } from "../models/CrossShopDataTableRequestDto";
import { type CrossShopDataTableResponseDto } from "../models/CrossShopDataTableResponseDto";
import { customerCrossShopApiSlice } from "./customer-cross-shop-api-slice";

export const customerCrossShopDataTableApiSlice =
  customerCrossShopApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTableRows: builder.query<
        CrossShopDataTableResponseDto,
        { division: string; payload: CrossShopDataTableRequestDto }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetTableData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetTableRowsQuery } = customerCrossShopDataTableApiSlice;
