import { type UniqueIdentifier } from "@dnd-kit/core";
import { type OrderedSelectionDto } from "@quantium-enterprise/common-ui";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { type Item } from "../models/Item";
import { type Zone } from "../models/Zone";
import {
  getProductHierarchyLevelOrdinal,
  WowProductHierarchyLevel,
} from "./hierarchyOrdinalMapper";

const placeholderSuffix = "_ph";

/**
 * Generate a droppable zone for the current active item
 */
export const defaultDropZone = (activeItem: Item) =>
  ({
    accepts: [activeItem.type],
    id: uniqueId(),
  } as Zone);

/**
 * Inserts a new zone into the list of droppable zones
 */
export const insertTemporaryZone = (
  droppableZones: Zone[],
  activeItem: Item
) => {
  droppableZones.push(defaultDropZone(activeItem));
};

/**
 * Callback to sort hierarchy items based on ordinal
 * Attribute items with the same ordinals are sorted alphabetically
 */
export const sortHierarchyItems = (itemA: Item, itemB: Item) => {
  if (itemA.ordinal === itemB.ordinal) {
    return itemA.name.toLowerCase() <= itemB.name.toLowerCase() ? -1 : 1;
  } else {
    return itemA.ordinal - itemB.ordinal;
  }
};

/**
 * Callback to sort structure parameter droppable zones based on pre-filled selections position for report rerun
 */
export const sortDroppableZones = (
  zones: Zone[],
  prefilledSelections: OrderedSelectionDto[]
) => {
  const positionMap = Object.fromEntries(
    prefilledSelections.map((item) => [item.shortName, item.position])
  );
  return zones.sort(
    (a, b) =>
      positionMap[a.item?.shortName ?? Number.MAX_SAFE_INTEGER] -
      positionMap[b.item?.shortName ?? Number.MAX_SAFE_INTEGER]
  );
};

/**
 * This function is called when an item is being dragged within the existing structure
 */
export const reorderExistingStructure = (
  activeItem: Item,
  droppableZones: Zone[],
  setDroppableZones: (zone: Zone[]) => void,
  canDrop: (activeItem: Item, zone: Zone) => boolean
) => {
  const dropZoneIndex = droppableZones.findIndex(
    (zone) => zone.item?.id === activeItem.id
  );

  if (dropZoneIndex >= 0) {
    const copy = Array.from(droppableZones);
    copy.splice(dropZoneIndex, 1);
    const newDroppableZones: Zone[] = [];

    for (const zone of copy) {
      newDroppableZones.push(zone);

      // Only attribute items is draggable from this area
      // If a hierarchy item somehow gets dragged, it'll be moved back to the lhs list
      if (
        activeItem.type === HierarchyItemType.Attribute &&
        canDrop(activeItem, zone)
      ) {
        insertTemporaryZone(newDroppableZones, activeItem);
      }
    }

    setDroppableZones([...newDroppableZones]);
  }
};

/**
 * Finds a valid index in the existing structure for the current active item &
 * if a valid index is found, add a new droppable zone at this index for the item
 */
export const addNewDropZone = (
  activeItem: Item,
  droppableZones: Zone[],
  setDroppableZones: (zone: Zone[]) => void,
  injectionIndex: number
) => {
  if (injectionIndex >= 0) {
    const newDroppableZones = Array.from(droppableZones);
    newDroppableZones.splice(injectionIndex, 0, defaultDropZone(activeItem));
    setDroppableZones([...newDroppableZones]);
  }
};

/**
 * Adds highlighted placeholder on LHS at index where activeItem was
 */
export const addPlaceholderItemAtIndex = (
  items: Item[],
  setItems: (items: Item[]) => void,
  index: number
) => {
  const newItems = Array.from(items);
  newItems[index] = {
    ...newItems[index],
    isPlaceholder: true,
    id: newItems[index].id + placeholderSuffix,
  };
  setItems(newItems);
};

/**
 * Removes any placeholder containers on LHS
 * Called on drag end event
 */
export const removePlaceholders = (
  items: Item[],
  setItems: (items: Item[]) => void
) => {
  setItems(Array.from(items).filter((item) => !item.isPlaceholder));
};

/**
 * Insert the active item into the existing structure
 */
export const insertItemIntoStructure = (
  overId: UniqueIdentifier,
  activeItem: Item,
  setActiveItem: (item: Item | undefined) => void,
  droppableZones: Zone[],
  setDroppableZones: (zone: Zone[]) => void,
  items: Item[],
  setItems: (items: Item[]) => void
) => {
  const foundZone = droppableZones.find((zone) => zone.id === overId);

  if (foundZone) {
    const foundZoneIndex = droppableZones.indexOf(foundZone);

    const newDropZone = {
      ...foundZone,
      item: activeItem,
    };

    // Inject new zone
    const newDroppableZones = Array.from(droppableZones);
    newDroppableZones.splice(foundZoneIndex, 0, newDropZone);

    // Update droppable zones and filter out any empty zones
    setDroppableZones([...newDroppableZones.filter((zone) => zone.item)]);

    setActiveItem(undefined);
  }

  removePlaceholders(items, setItems);
};

/**
 * Insert the active item back into the list
 */
export const moveItemBackToList = (
  activeItem: Item | undefined,
  setActiveItem: (item: Item | undefined) => void,
  items: Item[],
  setItems: (items: Item[]) => void
) => {
  // Insert active item back into list
  if (activeItem) {
    const newItems = Array.from(items);
    newItems.push(activeItem);
    newItems.sort(sortHierarchyItems);
    setItems(newItems);
    setActiveItem(undefined);
    removePlaceholders(newItems, setItems);
  }
};

/**
 * Remove all temporary drop zones that do not contain an item
 */
export const removeEmptyDropZones = (
  droppableZones: Zone[],
  setDroppableZones: (zone: Zone[]) => void
) => {
  const newDroppableZones = droppableZones.filter((zone) => zone.item);
  setDroppableZones(newDroppableZones);
};

/**
 * Default callback to determine whether an attribute item can be dropped below a given zone
 */
export const defaultCanDropAttribute = (
  activeItem: Item,
  droppableZone: Zone
) => {
  if (
    activeItem.type === HierarchyItemType.Attribute &&
    (droppableZone.item?.type === HierarchyItemType.Attribute ||
      (droppableZone.item?.type === HierarchyItemType.Hierarchy &&
        droppableZone.item.ordinal >=
          getProductHierarchyLevelOrdinal(WowProductHierarchyLevel.Category)))
  ) {
    return true;
  }

  return false;
};

/**
 * Default callback to determine whether an attribute item can be dropped below a given zone for location hierarchies
 */
export const defaultCanDropLocationAttribute = (
  // Keeping these props to maintain the same signature as defaultCanDropAttribute, but as per current spec
  // all droppable zones should be available for a location attribute.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  activeItem: Item,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  droppableZone: Zone
) => droppableZone.item?.type !== HierarchyItemType.Leaf;

/**
 * Default callback to find a valid drop index in the structure for a hierarchy item
 */
export const defaultFindValidDropIndex = (
  activeItem: Item,
  droppableZones: Zone[]
) => {
  // Find index of first valid child
  const firstValidChildIndex = droppableZones.findIndex(
    (zone) => zone.item && zone.item.ordinal > activeItem.ordinal
  );

  // If there isn't a valid child
  if (firstValidChildIndex < 0) {
    // Find last index of valid parent
    const reversedDroppableZones = Array.from(droppableZones);
    reversedDroppableZones.reverse();
    const lastValidParentIndex = reversedDroppableZones.findIndex(
      (zone) =>
        zone.item &&
        zone.item.type === HierarchyItemType.Hierarchy &&
        zone.item.ordinal < activeItem.ordinal
    );

    // If there isn't a valid parent, do nothing
    // This case will never happen as long as all hierarchy items have unique ordinals
    if (lastValidParentIndex < 0) {
      return -1;
    }

    // Inject new drop zone after the last valid parent
    const injectionIndex = droppableZones.length - lastValidParentIndex;

    // Leaf items cannot be inserted anywhere except the bottom of the structure
    if (
      injectionIndex < droppableZones.length &&
      activeItem.type === HierarchyItemType.Leaf
    ) {
      return droppableZones.length;
    }

    return injectionIndex;
  } else {
    return firstValidChildIndex;
  }
};
