import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { PerformanceTrendChart } from "components-ui/src/charts/performance-trend/PerformanceTrendChart";
import { mapToPerformanceTrendSeries } from "components-ui/src/charts/performance-trend/utils/mapToPerformanceTrendSeries";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { MeasureGroups } from "../../../common/models/MeasureGroups";
import { type RootState } from "../../../store";
import { ChartSeriesLabels } from "../../models/CustomerProfilingSegmentsOverTimeReportlet";
import {
  selectMetric,
  selectSegmentation,
  selectShowSegmentsOverTimeChartDataLabels,
} from "../../services/customer-profiling-slice";
import {
  getChartMetricFormatAndDisplayName,
  getChartSeriesData,
  getChartXAxisDates,
} from "../../utils/getSegmentsOverTimeSeries";

export const CustomerProfilingTrendedPerformanceChart = ({
  onOptionsChanged,
}: {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
}) => {
  const formatter = useFormatter();

  const metric = useSelector(selectMetric);
  const segmentation = useSelector(selectSegmentation);
  const showSegmentsOverTimeChartDataLabels = useSelector(
    selectShowSegmentsOverTimeChartDataLabels
  );

  const {
    chartSeriesActiveButtonIndex,
    segmentationActiveButtonIndex,
    focalItemFilter,
    segmentsFilter,
    reportData,
  } = useSelector((state: RootState) => ({
    chartSeriesActiveButtonIndex:
      state.customerProfiling.segmentsOverTimeFilters
        .chartSeriesActiveButtonIndex,
    segmentationActiveButtonIndex:
      state.customerProfiling.segmentsOverTimeFilters
        .segmentationActiveButtonIndex,
    focalItemFilter:
      state.customerProfiling.segmentsOverTimeFilters.focalItemFilter,
    segmentsFilter:
      state.customerProfiling.segmentsOverTimeFilters.segmentsFilter,
    reportData: state.customerProfiling.segmentsOverTimeData,
  }));

  const chartConfig = useMemo(() => {
    const selectedChartSeries: ChartSeriesLabels =
      Object.values(ChartSeriesLabels)[chartSeriesActiveButtonIndex];

    const selectedSegmentation = segmentation[segmentationActiveButtonIndex]
      .value as string;

    const { metricDisplayName, metricFormat } =
      getChartMetricFormatAndDisplayName(
        reportData,
        metric.value as string,
        selectedSegmentation
      );

    const dates = getChartXAxisDates(
      selectedChartSeries,
      focalItemFilter.options,
      reportData,
      focalItemFilter.activeOption,
      selectedSegmentation,
      segmentsFilter.activeOption
    );

    let focalItems = [];
    if (selectedChartSeries === ChartSeriesLabels.CustomerSegments) {
      focalItems = focalItemFilter.activeOption
        ? [focalItemFilter.activeOption]
        : [];
    } else {
      focalItems = focalItemFilter.options;
    }

    const metricItem = {
      format: metricFormat,
      formatter: formatter(metricFormat),
      label: metric.value as string,
      name: "",
      unit:
        metric.value.toString().toLowerCase() ===
        MeasureGroups.Sales.toLowerCase()
          ? "$"
          : undefined,
    };

    const chartSeries = getChartSeriesData(
      selectedChartSeries,
      focalItems,
      metric.value as string,
      metricItem,
      reportData,
      showSegmentsOverTimeChartDataLabels,
      focalItemFilter.activeOption,
      selectedSegmentation,
      segmentsFilter.activeOption
    );

    const chartSeriesOptions = mapToPerformanceTrendSeries(chartSeries);

    const { primaryTooltipHeader, secondaryTooltipHeader } =
      focalItemFilter.options.length > 1
        ? selectedChartSeries === ChartSeriesLabels.CustomerSegments
          ? {
              primaryTooltipHeader: focalItemFilter.activeOption?.name,
              secondaryTooltipHeader: metricDisplayName,
            }
          : {
              primaryTooltipHeader: segmentsFilter.activeOption?.segment,
              secondaryTooltipHeader: metricDisplayName,
            }
        : {
            primaryTooltipHeader: metricDisplayName,
            secondaryTooltipHeader: undefined,
          };

    return {
      dates,
      metricDisplayName,
      metricFormat,
      onOptionsChanged,
      series: chartSeries,
      seriesWithOptions: chartSeriesOptions,
      primaryMetric: metricItem,
      primaryMetricUIName: metricDisplayName,
      tooltipPrimaryHeader: primaryTooltipHeader,
      tooltipSecondaryHeader: secondaryTooltipHeader,
      legendEnabled: true,
    };
  }, [
    chartSeriesActiveButtonIndex,
    segmentation,
    segmentationActiveButtonIndex,
    reportData,
    metric,
    onOptionsChanged,
    focalItemFilter.options,
    focalItemFilter.activeOption,
    segmentsFilter.activeOption,
    showSegmentsOverTimeChartDataLabels,
    formatter,
  ]);

  return <PerformanceTrendChart {...chartConfig} />;
};
