type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};
export const ProductGroupIcon = ({
  className,
  coloured = true,
  text,
}: Props) => {
  const colour = coloured
    ? "var(--marker-boxer-colour)"
    : "var(--icon-colour-two-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 36 36"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Product group"}</title>
      <path
        d="M6.03794 11.1853C6.03794 11.2008 6.02539 11.2162 6.02539 11.2473C6.01085 11.308 6.00226 11.3705 6.00005 11.4336V24.5663C5.99653 24.8993 6.17077 25.196 6.42937 25.2959L17.7599 29.9379L17.7978 29.9533C17.928 30.0156 18.072 30.0156 18.2022 29.9533C18.2161 29.9555 18.2297 29.9498 18.2399 29.9379L29.5704 25.2959C29.829 25.196 30.0035 24.8993 29.9999 24.5663V11.4336C29.9986 11.3602 29.99 11.2871 29.9746 11.2162C29.9552 11.1225 29.916 11.037 29.861 10.9679C29.8409 10.9 29.8002 10.8442 29.7473 10.8126C29.7473 10.8042 29.7345 10.7971 29.7345 10.7971L29.6587 10.7505C29.6336 10.7194 29.5957 10.7194 29.5704 10.704L29.5072 10.6729L18.278 6.0777L18.2022 6.04657C18.1421 6.01597 18.0777 6.00027 18.0128 6H17.9874C17.9224 6.00027 17.8579 6.0157 17.7978 6.0463L17.722 6.07743L6.50537 10.6728H6.49281L6.42959 10.704C6.41197 10.7032 6.39413 10.7086 6.37893 10.7194C6.36637 10.7194 6.3536 10.7351 6.34104 10.7505C6.30778 10.7616 6.27739 10.7833 6.25271 10.8128C6.24016 10.8128 6.24016 10.8128 6.24016 10.8282C6.19082 10.8583 6.15095 10.9073 6.1265 10.9679C6.1265 10.9679 6.11372 10.9747 6.11372 10.9834C6.08596 11.044 6.06063 11.106 6.03794 11.1696V11.1853Z"
        fill={colour}
      />
      <path
        d="M9 13L18 16.5M18 16.5V27M18 16.5L27 13"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};
