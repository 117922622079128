import { MetricTypes } from "@quantium-enterprise/hooks-ui";

// modify column header to have currencysymbol for format type as "Currency"
export const getExportColumnHeader =
  (currencySymbol: string, metricFormat: string, selectedMetric: string) =>
  (header: string) => {
    let columnHeader = `${selectedMetric} - ${header}`;
    if (metricFormat === MetricTypes.Currency) {
      columnHeader = `${selectedMetric} (${currencySymbol}) - ${header}`;
    }

    return columnHeader;
  };
