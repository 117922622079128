import { ReportType } from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { type RootState } from "../../store";
import { BasketAffinitiesFeatureFlags } from "../constants/basket-affinities-feature-flags";
import {
  selectAssociatedItems,
  selectFocalItem,
  selectIsDataLoading,
  selectIsEmptyReport,
  selectLocalParametersSelection,
  selectReportParameters,
  selectShowAssociatedItemsChartDataLabels,
  toggleAssociatedItemsChartDataLabels,
} from "../services/basket-affinities-slice";
import { getExportParameterSummary } from "../utils/getExportParameterSummary";
import { BasketAffinitiesAssociatedItemsChart } from "./BasketAffinitiesAssociatedItemsChart";
import styles from "./BasketAffinitiesAssociatedItemsReportlet.module.css";
import { csvTransformation } from "./csvTransformation";

export const BasketAffinitiesAssociatedItemsReportlet = () => {
  const { id: reportId } = useParams();
  const dispatch = useDispatch();
  const isEmptyReport = useSelector(selectIsEmptyReport);
  const reportParameters = useSelector(selectReportParameters);
  const focalItem = useSelector(selectFocalItem);
  const localParametersSelection = useSelector(selectLocalParametersSelection);
  const showAssociatedItemsChartDataLabels = useSelector(
    selectShowAssociatedItemsChartDataLabels
  );

  const isDataLoading = useSelector(selectIsDataLoading);
  const associatedItems = useSelector(selectAssociatedItems);
  // currently hardcoded, but these should eventually come from DivisionConfiguration
  const associatedLevel = "Subcategory";
  const associatedLevelPlural = "subcategories";
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketAffinitiesFeatureFlags.ReportExport] ?? false;

  const chartContainerRef = useRef<HTMLElement>();

  const isReportLoaded =
    isEmptyReport || (Boolean(reportId) && Boolean(focalItem?.itemCode));
  const noData = isEmptyReport || associatedItems.length === 0;

  const chartCsvTransformationCallback = useCallback(
    () => csvTransformation(associatedLevel, associatedItems),
    [associatedLevel, associatedItems]
  );
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Number_Of_Associated_Items_By_Subcategory_Chart_${localParametersSelection.timePeriodLength}_${localParametersSelection.location.name}`
      ),
    [localParametersSelection]
  );
  const localParameters = useMemo(
    () => getExportParameterSummary(localParametersSelection, focalItem),
    [localParametersSelection, focalItem]
  );

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  // Memoize the component for performance and to avoid freezing when changing
  // the basket affinities slider filter local parameter.
  const basketAffinitiesChart = useMemo(
    () => (
      <BasketAffinitiesAssociatedItemsChart
        onOptionsChanged={setCurrentOptions}
      />
    ),
    []
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showAssociatedItemsChartDataLabels,
      value: "",
    },
  ];

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.basketAffinities.reportName,
  }));

  return (
    <ReportletAccordion
      subtitle={`See the associated ${associatedLevelPlural} that have been purchased with the focal item.`}
      title={`Number of associated items by ${associatedLevel.toLocaleLowerCase()}`}
    >
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={!isReportLoaded || isDataLoading}
          noData={!isDataLoading && noData}
        >
          <div className={styles.chartOptionsContainer}>
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.BasketAffinities} />
                      ),
                      chartTitle: `Number of associated items by ${associatedLevel.toLocaleLowerCase()} - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={localParameters}
              reportParameters={reportParameters}
              toggleDataLabels={() => {
                dispatch(toggleAssociatedItemsChartDataLabels());
              }}
            />
          </div>
          <ChartFooterWrapper
            height="400px"
            parameters={localParameters}
            ref={chartContainerRef}
          >
            {basketAffinitiesChart}
          </ChartFooterWrapper>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
