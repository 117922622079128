import {
  ButtonToggleGroup,
  type ToggleButton,
} from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { useDispatch, useSelector } from "react-redux";
import ChartIcon from "../../assets/tab-icons/bar-chart-black.svg";
import ChartIconSelected from "../../assets/tab-icons/bar-chart-white.svg";
import TableIcon from "../../assets/tab-icons/table-black.svg";
import TableIconSelected from "../../assets/tab-icons/table-white.svg";
import {
  onTableChartChange,
  onMetricTypeChange,
} from "../services/compare-metrics-slice";
import {
  selectButtonSelections,
  selectMetricDataTypes,
} from "../services/compare-metrics-slice-selectors";
import styles from "./CompareMetricsReportlet.module.css";
import { metricType } from "./CompareMetricsReportletChart";

const chartTableButtons: Array<ToggleButton<string>> = [
  {
    displayText: "Chart",
    icon: {
      iconDefault: ChartIcon,
      iconSelected: ChartIconSelected,
    },
    id: "Chart",
  },
  {
    displayText: "Table",
    icon: {
      iconDefault: TableIcon,
      iconSelected: TableIconSelected,
    },
    id: "Table",
  },
];

export const CompareMetricsReportletButtons = () => {
  const dispatch = useDispatch();

  const buttonSelections = useSelector(selectButtonSelections);
  const metricDataTypes = useSelector(selectMetricDataTypes);

  const chartTableButtonGroup = (
    <span className={styles.selectorButtons}>
      <ButtonToggleGroup
        buttonSelected={buttonSelections.chartTableSelection}
        buttons={chartTableButtons}
        setButtonSelected={(id: string) => dispatch(onTableChartChange(id))}
      />
    </span>
  );

  const metricTypeButtons: Array<ToggleButton<string>> = metricType.map(
    ({ value, label }) => ({
      displayText: label,
      id: value,
    })
  );

  const metricTypeButtonGroup = (
    <span className={styles.selectorButtons}>
      <ButtonToggleGroup
        buttonSelected={buttonSelections.metricTypeSelection}
        buttons={metricTypeButtons}
        getIsDisabled={(id: string) => !metricDataTypes.includes(id)}
        setButtonSelected={(id: string) => dispatch(onMetricTypeChange(id))}
      />
    </span>
  );

  return (
    <div className={styles.chartSelectorsRow}>
      <span>{chartTableButtonGroup}</span>
      <span>{metricTypeButtonGroup}</span>
    </div>
  );
};
