import { type Header, flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { useCallback } from "react";
import { SortIcon } from "../SortIcon";
import ColumnResizer from "../column-resizer/ColumnResizer";
import styles from "./Cell.module.css";

type HeaderProps<T> = {
  columnResizeMode?: "onChange" | "onEnd";
  deltaOffset: number | null;
  depth: number;
  disableSorting?: boolean;
  header: Header<T, unknown>;
  pinFirstColumn?: boolean;
};

export const HeaderCell = <T,>({
  header,
  depth,
  columnResizeMode,
  deltaOffset,
  disableSorting,
  pinFirstColumn,
}: HeaderProps<T>) => {
  const showColumnResizer = useCallback(
    (headerValue: Header<T, unknown>): boolean => {
      if (pinFirstColumn && header.index === 0) {
        return false;
      }

      if (headerValue.column.getCanResize()) {
        return true;
      }

      return false;
    },
    [header.index, pinFirstColumn]
  );

  const defaultHeaderStyles = {
    [styles.cellHeading]: depth === 0 || header.index === 0,
    [styles.cellSubheading]: depth > 0 && header.index > 0,
    [styles.alignLeft]: header.index === 0,
    [styles.alignCenter]: depth === 0 && header.index > 0,
  };

  return (
    <>
      {disableSorting ? (
        <div
          className={classNames(
            styles.cellText,
            styles.cellButton,
            styles.paddingLeft,
            {
              ...defaultHeaderStyles,
            }
          )}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {header.column.getIsSorted() && (
            <span className={styles.sortIcon}>
              <SortIcon sortedType={header.column.getIsSorted()} />
            </span>
          )}
        </div>
      ) : (
        <button
          className={classNames(
            styles.cellText,
            styles.cellButton,
            { [styles.pointer]: header.column.getCanSort() },
            { ...defaultHeaderStyles }
          )}
          onClick={header.column.getToggleSortingHandler()}
          onKeyPress={header.column.getToggleSortingHandler()}
          onTouchEnd={header.column.getToggleSortingHandler()}
          type="button"
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {header.column.getIsSorted() && (
            <span className={styles.sortIcon}>
              <SortIcon sortedType={header.column.getIsSorted()} />
            </span>
          )}
        </button>
      )}

      {showColumnResizer(header) ? (
        <ColumnResizer
          columnResizeMode={columnResizeMode}
          deltaOffset={deltaOffset}
          header={header}
        />
      ) : null}
    </>
  );
};
