import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";

export const isSelected = (selected: string[], option: string): boolean =>
  selected.includes(option);

export const isMaximumSelected = (
  selected: string[],
  maximumSelections: number
): boolean => selected.length >= maximumSelections;

export const removeSelection = (
  selected: PanelOption[],
  option: PanelOption
): PanelOption[] =>
  selected.filter(
    (selection) => selection.value.toString() !== option.value.toString()
  );

export const addSelection = (
  selected: PanelOption[],
  option: PanelOption
): PanelOption[] => {
  if (!selected.some((selection) => selection.value === option.value)) {
    return [...selected, option];
  }

  return selected;
};
