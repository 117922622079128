import {
  FeatureFlag,
  ReportType,
  ddLog,
  useGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { type RootState } from "../store";
import { CustomerLoyaltyReportlet } from "./components/CustomerLoyaltyReportlet/CustomerLoyaltyReportlet";
import { CustomerLoyaltySidePanel } from "./components/CustomerLoyaltySidePanel";
import { CustomerLoyaltyTopDrawer } from "./components/CustomerLoyaltyTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/customer-loyalty-local-parameters-api-slice";
import {
  onReportOpen,
  onReportError,
  onReportSuccess,
  reset,
} from "./services/customer-loyalty-slice";

export const CustomerLoyaltyReport = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: !activeDivisionName || !id }
    );

  const { reportName, localParametersInitialised, levelOfAnalysis } =
    useSelector((state: RootState) => ({
      reportName: state.customerLoyalty.reportName,
      localParametersInitialised:
        state.customerLoyalty.localParametersInitialised,
      levelOfAnalysis:
        state.customerLoyalty.persistedSelections.levelOfAnalysis,
    }));

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
  }, [dispatch, id, isTabsEnabled]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [getLocalParametersQuery] = useLazyGetLocalParametersQuery();

  const fetchReport = useCallback(
    async (division: string, reportId: string) => {
      await getLocalParametersQuery({ divisionName: division, reportId })
        .unwrap()
        .then((response) => {
          dispatch(onReportSuccess(response));
        })
        .catch((error) => {
          dispatch(onReportError());

          ddLog(
            "Error retrieving customer loyalty local parameters",
            {},
            "error",
            error
          );
        });
    },
    [dispatch, getLocalParametersQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchReport(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    dispatch,
    fetchReport,
    id,
    localParametersInitialised,
  ]);

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <CustomerLoyaltyTopDrawer
            renameReport={handleRenameReport}
            reportName={reportName}
            reportTabItems={levelOfAnalysis.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            reportType={ReportType.CustomerLoyalty}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <CustomerLoyaltySidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <CustomerLoyaltyReportlet reportParameters={infoPanelSummary} />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <CustomerLoyaltyReport />,
  path: "customer-loyalty/:id",
};

export default CustomerLoyaltyReport;
