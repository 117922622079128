import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import {
  HighchartsReact,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { HighchartsCustomTooltip } from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import { type TooltipFormatterContextObject } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { type Series } from "../../models/basket-quantities-common-models";
import { selectShowBasketQuantitiesSummaryChartDataLabels } from "../../services/basket-quantities-slice";

export type BasketQuantitiesSummaryChartProps = {
  additionalMetricKey?: string;
  categories: string[];
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  primaryMetricKey: string;
  series: Series[];
};

const Tooltip = ({
  basketSeries,
  formatter,
  ttData,
}: {
  basketSeries?: Series;
  formatter: DeferredFormatFunction;
  ttData: TooltipFormatterContextObject;
}) => (
  <HighchartsCustomTooltip.Layout>
    <HighchartsCustomTooltip.Title title={basketSeries?.metricLabel ?? ""} />
    <HighchartsCustomTooltip.Series
      pointFormatter={(pt) =>
        formatter(pt.series.userOptions.custom?.metricFormat)
      }
      ttData={ttData}
      useSeriesNameForLabels
    />
  </HighchartsCustomTooltip.Layout>
);

export const BasketQuantitiesSummaryChart = ({
  additionalMetricKey,
  primaryMetricKey,
  categories,
  series,
  onOptionsChanged,
}: BasketQuantitiesSummaryChartProps) => {
  const formatter = useFormatter();
  const showBasketQuantitiesSummaryChartDataLabels = useSelector(
    selectShowBasketQuantitiesSummaryChartDataLabels
  );

  const basketSeries = series.find(
    (metricSeries) =>
      metricSeries.breakdown === "Total" &&
      metricSeries.metricKey === primaryMetricKey
  );

  let colourIndex = 0;

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const seriesOptions: Highcharts.SeriesOptionsType[] = useMemo(() => {
    const seriesData: Highcharts.SeriesOptionsType[] =
      basketSeries?.data
        // remove last item as it is the total
        .slice(0, categories.length)
        .map((dataPoint, index) => {
          const data = Array.from(
            { length: categories.length - 1 },
            () => null as number | null
          );
          data[index] =
            dataPoint.metricValue === undefined ? null : dataPoint.metricValue;
          const chartSeries: Highcharts.SeriesOptionsType = {
            data,
            color: getColourByIndex(colourIndex++),
            custom: {
              metricFormat: basketSeries.format,
            },
            name: `${categories[index]} per basket`,
            type: "column",
          };
          return chartSeries;
        }) ?? [];

    if (additionalMetricKey) {
      const filteredAdditionalSeries = series.find(
        (metricSeries) =>
          metricSeries.breakdown === "Total" &&
          metricSeries.metricKey === additionalMetricKey
      );
      if (filteredAdditionalSeries) {
        seriesData.push({
          data: filteredAdditionalSeries.data
            // remove last item as it is the total
            .slice(0, categories.length)
            .map((metric) => metric.metricValue ?? null),
          // static colour for secondary line series
          color: "var(--qbit-colour-text-primary)",
          custom: {
            metricFormat: filteredAdditionalSeries.format,
          },
          name: filteredAdditionalSeries.metricLabel,
          type: "line",
          yAxis: 1,
        });
      }
    }

    for (const [index, seriesDataItem] of seriesData.entries()) {
      seriesDataItem.visible = !hiddenSeries.includes(index);
    }

    return seriesData;
  }, [
    additionalMetricKey,
    basketSeries?.data,
    basketSeries?.format,
    categories,
    colourIndex,
    hiddenSeries,
    series,
  ]);

  const yAxis: Highcharts.YAxisOptions[] = useMemo(() => {
    const axisData: Highcharts.YAxisOptions[] = [
      {
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            const metricFormat =
              self.axis.series[0].userOptions.custom?.metricFormat;
            return formatter(metricFormat)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: basketSeries?.metricLabel,
        },
        reversedStacks: false,
        visible: true,
      },
    ];

    if (additionalMetricKey) {
      const filteredAdditionalSeries = series.find(
        (metricSeries) =>
          metricSeries.breakdown === "Total" &&
          metricSeries.metricKey === additionalMetricKey
      );
      if (filteredAdditionalSeries) {
        axisData.push({
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.value;
              const metricFormat =
                self.axis.series[0].userOptions.custom?.metricFormat;
              return formatter(metricFormat)(value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          title: {
            style: {
              fontWeight: "bold",
              color: "var(--qbit-colour-text-primary)",
            },
            text: filteredAdditionalSeries.metricLabel,
          },
          visible: true,
          reversedStacks: false,
          opposite: true,
        });
      }
    }

    return axisData;
  }, [additionalMetricKey, basketSeries?.metricLabel, formatter, series]);

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      legend: {
        ...defaultOptions.legend,
        // square symbols
        symbolRadius: 0,
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        visible: true,
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          Tooltip({
            formatter,
            ttData,
            basketSeries,
          }),
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: showBasketQuantitiesSummaryChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              const metricFormat = self.series.userOptions.custom?.metricFormat;
              return formatter(metricFormat)(value);
            },
          },
          // sets width of each column by changing padding between them
          pointPadding: 0,
          stacking: "normal",
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        line: {
          dataLabels: {
            enabled: showBasketQuantitiesSummaryChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              const metricFormat = self.series.userOptions.custom?.metricFormat;
              return formatter(metricFormat)(value);
            },
          },
          marker: {
            enabled: false,
          },
          shadow: {
            color: "white",
            offsetX: 0,
            offsetY: 0,
            opacity: 100,
            width: 3,
          },
          zIndex: 1,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: seriesOptions as any,
    }),
    [
      basketSeries,
      categories,
      formatter,
      legendItemClick,
      seriesOptions,
      showBasketQuantitiesSummaryChartDataLabels,
      yAxis,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
