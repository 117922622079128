/* eslint-disable react/no-array-index-key */
import { type HierarchyItemType } from "@quantium-enterprise/common-ui";
import * as React from "react";
import { ChildArrow } from "../child-arrow/ChildArrow";
import { HierarchyLevelIcon } from "../hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./StructurePreview.module.scss";

export type StructurePreviewItemProps = {
  count?: number;
  label: string;
  shortName: string;
  type: HierarchyItemType;
};

export type StructurePreviewProps = {
  displayTitle?: boolean;
  itemCount?: number;
  items: StructurePreviewItemProps[];
  maxDepth: number;
  moreItemsText: string;
};

/*
 * This component renders a list of Structure folders, each level being indented from the previous one.
 *
 * It is not a nested treeview, and there is no way to add multiple children to the same level.
 *
 * There is a max level defined that prevents the list rendering too deep so it blows the template.
 */
export const StructurePreview = ({
  items,
  maxDepth = 5,
  moreItemsText,
  displayTitle,
  itemCount,
}: StructurePreviewProps) => {
  const count = itemCount ?? items.length;
  // memoize the children creation for performance
  const children = React.useMemo(
    () =>
      items.map((element, index) => {
        const row = [] as React.ReactElement[];

        // '+ x more' is only shown when the number of items in the list is above the max.
        if (index === maxDepth) {
          return (
            <div className={styles.more} key={`more-${index}`}>
              + {count - maxDepth}
              {moreItemsText}
            </div>
          );
        }

        // don't render any items after the max.
        if (index > maxDepth - 1) {
          return null;
        }

        // Add a child arrow on every row but the first, then add a new padder span for each level down.
        // eslint-disable-next-line id-length, unicorn/prevent-abbreviations -- i is the standard looping var
        let i = index - 1;
        while (i > 0) {
          row.push(<span className={styles.spacer} />);
          i--;
        }

        if (index > 0) {
          row.push(
            <div className={styles.arrow}>
              <ChildArrow />
            </div>
          );
        }

        row.push(
          <HierarchyLevelIcon
            shortName={element.shortName}
            type={element.type}
          />
        );

        row.push(
          <span aria-label={element.label} className={styles.title}>
            {element.label}
          </span>
        );

        if (element.count) {
          row.push(
            <span className={styles.count}>
              ({Math.max(element.count, 0).toString()})
            </span>
          );
        }

        return React.createElement(
          "div",
          {
            className: styles.itemRow,
            key: `structure-preview-${index}`,
          },
          React.Children.toArray(row)
        );
      }),
    [items, maxDepth, moreItemsText, count]
  );

  return (
    <div className={styles.structurePreview}>
      {displayTitle && <p>Structure:</p>}
      {children}
    </div>
  );
};
