import { ddLog } from "@quantium-enterprise/common-ui";
import {
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
} from "@quantium-enterprise/qds-react";
import { useCallback, useState } from "react";

type RenameItemProps = {
  itemType: string;
  renameItem?: (newItemName: string, itemId: string) => Promise<unknown>;
  triggerLazyReload?: () => Promise<void>;
};

export const RenameItem = ({
  itemType,
  triggerLazyReload,
  renameItem,
}: RenameItemProps) => {
  const EMIT_TOAST_DURATION = 3_000;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleRename = useCallback(
    async (newItemName: string, itemId?: string) => {
      if (!itemId || !renameItem) {
        QbitEmitToast(
          <QbitToastMessage
            content={
              <>
                <p>Something went wrong. Please try again later</p>
                <p> Contact support if error persists. </p>
              </>
            }
            heading={<h5>An error occurred during rename operation</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );

        ddLog(
          `${itemType} rename error: no ${itemType.toLocaleLowerCase()} to rename available`
        );
        throw new Error(
          `${itemType} rename error: no ${itemType.toLocaleLowerCase()} to rename available`
        );
      }

      setIsProcessing(true);
      try {
        await renameItem(newItemName, itemId);

        const message = `${itemType} has been renamed`;
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<h5>{message}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const heading =
          error.status === 409
            ? `${itemType} naming failed`
            : `${itemType} wasn't renamed`;

        const content =
          error.status === 409 ? (
            <p>
              A {itemType.toLocaleLowerCase()} with the same name already
              exists.
            </p>
          ) : (
            <>
              <p>Something went wrong. Please try again later</p>
              <p>Contact support if error persists.</p>
            </>
          );

        QbitEmitToast(
          <QbitToastMessage
            content={content}
            heading={heading}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
        throw new Error("An error occured during rename operation");
      }

      if (triggerLazyReload) {
        try {
          await triggerLazyReload();
        } catch {
          QbitEmitToast(
            <QbitToastMessage
              content={<p>Please manually reload the page.</p>}
              heading={<h5>An error occurred during reload</h5>}
              showCloseButton
              showIcon
              variant={MessageVariant.Danger}
            />,
            {
              autoClose: EMIT_TOAST_DURATION,
            }
          );
        } finally {
          setIsProcessing(false);
        }
      }
    },
    [itemType, triggerLazyReload, renameItem]
  );

  return { handleRename, isProcessing };
};
