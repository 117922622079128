import { type SeriesColumnOptions, type SeriesOptionsType } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import {
  defaultOptions,
  HighchartsReact,
  TooltipHTML,
} from "../highcharts-react/HighchartsReact";
import { type GroupColumnChartProps } from "../models";

export const GroupColumnChart = ({
  data,
  categories,
  dataLabelFormatter,
  showChartDataLabels,
  yAxisTickFormatter,
  yAxisLabel = "",
  tooltipSecondaryHeader,
  threshold = 0,
  onOptionsChanged,
}: GroupColumnChartProps) => {
  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        style: {
          fontFamily: `var(--qbit-font-family)`,
        },
        type: "column",
      },
      legend: {
        align: "left",
        enabled: true,
        itemStyle: {
          color: "var(--qbit-colour-text-secondary)",
          fontSize: "0.75rem",
          fontWeight: "var(--qbit-font-weight-regular)",
        },
        symbolRadius: 0,
        verticalAlign: "bottom",
      },
      plotOptions: {
        column: {
          dataLabels: {
            crop: false,
            enabled: showChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return dataLabelFormatter(this.y);
            },
            inside: false,
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontFamily: "var(--qbit-font-family)",
              fontSize: "0.75rem",
              fontWeight: "var(--qbit-font-weight-medium)",
              textOutline: "white",
            },
          },
          threshold,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
      series: data.map(
        (
          seriesOption: SeriesColumnOptions,
          index: number
        ): SeriesOptionsType => ({
          ...seriesOption,
          visible: !hiddenSeries.includes(index),
        })
      ),
      tooltip: {
        ...defaultOptions.tooltip,
        positioner(labelWidth, _, point) {
          return {
            x:
              point.plotX - labelWidth > 0
                ? point.plotX - labelWidth
                : point.plotX + 1.2 * labelWidth,
            y: 100,
          };
        },
        ReactFormatter: (ttData) => {
          const tooltipData = ttData.points?.map((pt) => ({
            color: String(pt.color),
            name: pt.series.name,
            value: dataLabelFormatter(pt.y),
          }));
          return TooltipHTML(tooltipData, ttData.x, tooltipSecondaryHeader);
        },
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
      yAxis: {
        ...defaultOptions.yAxis,
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return yAxisTickFormatter(this.value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          text: yAxisLabel,
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
    }),
    [
      showChartDataLabels,
      threshold,
      data,
      categories,
      yAxisLabel,
      dataLabelFormatter,
      legendItemClick,
      hiddenSeries,
      tooltipSecondaryHeader,
      yAxisTickFormatter,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
