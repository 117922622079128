export const Location = ({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 3.16665C9.28665 3.16665 11.3333 4.09998 11.3333 6.59998C11.3333 8.03998 10.1866 9.71331 7.99998 11.48C5.81331 9.71331 4.66665 8.03331 4.66665 6.59998C4.66665 4.09998 6.71331 3.16665 7.99998 3.16665ZM7.99998 1.83331C5.81998 1.83331 3.33331 3.47331 3.33331 6.59998C3.33331 8.67998 4.88665 10.8733 7.99998 13.1666C11.1133 10.8733 12.6666 8.67998 12.6666 6.59998C12.6666 3.47331 10.18 1.83331 7.99998 1.83331Z"
      fill="#B5B5B5"
    />
    <path
      d="M7.99998 5.16665C7.26665 5.16665 6.66665 5.76665 6.66665 6.49998C6.66665 7.23331 7.26665 7.83331 7.99998 7.83331C8.3536 7.83331 8.69274 7.69284 8.94279 7.44279C9.19284 7.19274 9.33331 6.8536 9.33331 6.49998C9.33331 6.14636 9.19284 5.80722 8.94279 5.55717C8.69274 5.30712 8.3536 5.16665 7.99998 5.16665ZM3.33331 13.8333H12.6666V15.1666H3.33331V13.8333Z"
      fill="#B5B5B5"
    />
  </svg>
);
