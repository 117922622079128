import {
  HierarchyItemType,
  type HierarchyValue,
} from "@quantium-enterprise/common-ui";
import { HierarchyLevelIcon } from "../../../hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./HierarchyValueCell.module.css";

export type HierarchyValueCellProps = {
  item: HierarchyValue;
};

export const HierarchyValueCell = ({ item }: HierarchyValueCellProps) => (
  <div className={styles.hierarchyValueCell}>
    <HierarchyLevelIcon
      shortName={item.shortName}
      type={HierarchyItemType.Leaf}
    />
    <span className={styles.item}>{item.name}</span>
  </div>
);
