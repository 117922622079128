import {
  IconSize,
  InlineIcon,
  InlineIconGlyph,
  Label,
  Text,
} from "@quantium-enterprise/qds-react";
import styles from "./ParameterLabel.module.css";

export type ParameterLabelProps = {
  description?: string;
  heading: string;
  htmlFor: string;
};

export const ParameterLabel = ({
  htmlFor,
  heading,
  description,
}: ParameterLabelProps) => (
  <Label
    className={styles.parameterLabel}
    htmlFor={htmlFor}
    required
    text={
      <span className={styles.parameterLabelText}>
        <Text>{heading}</Text>
        {description && (
          <InlineIcon
            data-testid="info-icon"
            glyph={InlineIconGlyph.AlertsAndNotificationsInform}
            size={IconSize.Small}
            text={description}
          />
        )}
      </span>
    }
  />
);
