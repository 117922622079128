export enum LocalParameterDisplayTypes {
  Groups = "Groups",
  Hierarchy = "Hierarchy",
  MinMaxRange = "MinMaxRange",
  MultiCheckbox = "MultiCheckbox",
  MultiSelect = "MultiSelect",
  MultiType = "MultiType",
  PlainTextContent = "PlainTextContent",
  Segmentation = "Segmentation",
  TimePeriod = "TimePeriod",
}
