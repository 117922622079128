import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type AvailableReportsDto } from "../models";
import { type BusinessQuestion } from "../models/business-question";

export const availableReportsApi = createApi({
  baseQuery: getBaseQuery(`/api/available-reports`),
  endpoints: (builder) => ({
    getAvailableReports: builder.query<AvailableReportsDto, string>({
      providesTags: ["available-reports"],
      query: (divisionName) => `/AvailableReports/Get/${divisionName}`,
      transformResponse: (response: AvailableReportsDto) => ({
        availableReports: response.availableReports.sort(
          (reportOne, reportTwo) =>
            reportOne.displayName.localeCompare(reportTwo.displayName)
        ),
      }),
    }),
    getBusinessQuestions: builder.query<
      BusinessQuestion[],
      { divisionName: string }
    >({
      providesTags: ["available-reports"],
      query: ({ divisionName }) => ({
        method: "GET",
        url: `/AvailableReports/GetBusinessQuestions/${divisionName}`,
      }),
    }),
  }),
  reducerPath: "availableReportsApi",
  tagTypes: ["available-reports"],
});

export const { useGetAvailableReportsQuery, useGetBusinessQuestionsQuery } =
  availableReportsApi;
