type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};
export const NewLapsedContinuousIcon = ({
  className,
  coloured,
  text,
}: Props) => {
  const colour1 = coloured
    ? "var(--cg-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--cg-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--cg-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";
  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "New / lapsed / continuous customer group"}</title>
      <path
        clipRule="evenodd"
        d="M13.75 7.25C13.75 9.87335 11.6234 12 9 12C6.37665 12 4.25 9.87335 4.25 7.25C4.25 4.62665 6.37665 2.5 9 2.5C11.6234 2.5 13.75 4.62665 13.75 7.25ZM7.72357 6.12708V8.04379C7.72357 8.15048 7.81006 8.23696 7.91675 8.23696C8.02344 8.23696 8.10992 8.32345 8.10992 8.43014V10.2656C8.10992 10.4037 8.22185 10.5156 8.35992 10.5156H9.36591C9.50398 10.5156 9.61591 10.4037 9.61591 10.2656V8.4262C9.61591 8.32169 9.70063 8.23696 9.80514 8.23696C9.90965 8.23696 9.99437 8.15224 9.99437 8.04773V6.11721C9.99437 6.07502 9.98457 6.03298 9.95998 5.9987C9.8532 5.84986 9.56841 5.59558 8.87474 5.59558C8.17547 5.59558 7.89584 5.82504 7.76635 5.99246C7.73674 6.03076 7.72357 6.07867 7.72357 6.12708ZM8.851 5.21714C9.2734 5.21714 9.61582 4.87472 9.61582 4.45232C9.61582 4.02992 9.2734 3.6875 8.851 3.6875C8.4286 3.6875 8.08618 4.02992 8.08618 4.45232C8.08618 4.87472 8.4286 5.21714 8.851 5.21714Z"
        fill={colour2}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.8221 14C11.7774 13.9227 11.7774 13.8274 11.8221 13.75L14.2402 9.56165C14.2849 9.4843 14.3674 9.43665 14.4567 9.43665L19.2931 9.43665C19.3824 9.43665 19.4649 9.48429 19.5096 9.56165L21.9277 13.75C21.9724 13.8274 21.9724 13.9227 21.9277 14L19.5096 18.1884C19.4649 18.2658 19.3824 18.3134 19.2931 18.3134H14.4567C14.3674 18.3134 14.2849 18.2658 14.2402 18.1884L11.8221 14ZM15.7897 12.8843V14.8318C15.7897 14.9402 15.8775 15.028 15.9858 15.028C16.0942 15.028 16.182 15.1158 16.182 15.2242V17.0919C16.182 17.23 16.2939 17.3419 16.432 17.3419H17.4613C17.5994 17.3419 17.7113 17.23 17.7113 17.0919V15.2201C17.7113 15.114 17.7973 15.028 17.9035 15.028C18.0096 15.028 18.0956 14.942 18.0956 14.8358V12.8744C18.0956 12.8322 18.0858 12.7902 18.0613 12.7559C17.9533 12.6049 17.6644 12.3457 16.9587 12.3457C16.2473 12.3457 15.9636 12.5796 15.8324 12.7497C15.8028 12.788 15.7897 12.8359 15.7897 12.8843ZM16.9345 11.9614C17.3634 11.9614 17.7112 11.6137 17.7112 11.1848C17.7112 10.7558 17.3634 10.4081 16.9345 10.4081C16.5056 10.4081 16.1579 10.7558 16.1579 11.1848C16.1579 11.6137 16.5056 11.9614 16.9345 11.9614Z"
        fill={colour3}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.5 17.25C11.5 19.8734 9.37335 22 6.75 22C4.12665 22 2 19.8734 2 17.25C2 14.6266 4.12665 12.5 6.75 12.5C9.37335 12.5 11.5 14.6266 11.5 17.25ZM5.47357 16.1271V18.0438C5.47357 18.1505 5.56006 18.237 5.66675 18.237C5.77344 18.237 5.85992 18.3235 5.85992 18.4301V20.2656C5.85992 20.4037 5.97185 20.5156 6.10992 20.5156H7.11591C7.25398 20.5156 7.36591 20.4037 7.36591 20.2656V18.4262C7.36591 18.3217 7.45063 18.237 7.55514 18.237C7.65965 18.237 7.74437 18.1522 7.74437 18.0477V16.1172C7.74437 16.075 7.73457 16.033 7.70998 15.9987C7.6032 15.8499 7.31841 15.5956 6.62474 15.5956C5.92547 15.5956 5.64584 15.825 5.51635 15.9925C5.48674 16.0308 5.47357 16.0787 5.47357 16.1271ZM6.601 15.2171C7.0234 15.2171 7.36582 14.8747 7.36582 14.4523C7.36582 14.0299 7.0234 13.6875 6.601 13.6875C6.1786 13.6875 5.83618 14.0299 5.83618 14.4523C5.83618 14.8747 6.1786 15.2171 6.601 15.2171Z"
        fill={colour1}
        fillRule="evenodd"
      />
    </svg>
  );
};
