import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { ChartColoursList } from "components-ui/src/charts/ChartColours";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { type DataLabelsOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type ChartDataSeries } from "components-ui/src/charts/column-and-line-chart/BasicColumnAndLineChart";
import { CompareMetricsChart } from "components-ui/src/charts/compare-metrics-chart/CompareMetricsChart";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFocalItemSummaryWithParents } from "../../common/utils/export-parameter-summary-utils";
import { CompareMetricsFeatureFlags } from "../constants/compare-metrics-feature-flags";
import { toggleChartDataLabels } from "../services/compare-metrics-slice";
import {
  selectButtonSelections,
  selectChartContentSelection,
  selectContributionMetrics,
  selectFocalItemParents,
  selectFocalItems,
  selectLocalParametersConfig,
  selectLocalParametersSelections,
  selectMetadata,
  selectMetricSelectionSelection,
  selectReportletData,
  selectShowChartDataLabels,
  selectXAxisSelection,
} from "../services/compare-metrics-slice-selectors";
import { getLocationForFileName } from "../utils/getExportfunctions";
import { getMetaDataMapping } from "../utils/reportlet-utils";
import styles from "./CompareMetricsReportlet.module.css";
import { csvTransformation } from "./csvTransformation";

export const metricType = [
  {
    value: "Actual",
    threshold: 0,
    label: "Actual",
  },
  {
    value: "% change",
    threshold: 0,
    label: "% change",
  },
  {
    value: "Index",
    threshold: 100,
    label: "Index",
  },
];
export type CompareMetricReportletProps = {
  reportParameter?: ReportParametersDto;
};
export const CompareMetricsReportletChart = ({
  reportParameter,
}: CompareMetricReportletProps) => {
  const focalItems = useSelector(selectFocalItems);
  const xAxisSelection = useSelector(selectXAxisSelection);
  const chartContentSelection = useSelector(selectChartContentSelection);
  const metricSelection = useSelector(selectMetricSelectionSelection);
  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );
  const localParametersConfig = useSelector(selectLocalParametersConfig);
  const showChartDataLabels = useSelector(selectShowChartDataLabels);
  const contributionMetrics = useSelector(selectContributionMetrics);
  const metricTypeSelection = useSelector(
    selectButtonSelections
  ).metricTypeSelection;
  const reportletData = useSelector(selectReportletData);
  const { reportName } = useSelector(selectMetadata);
  const focalItemParents = useSelector(selectFocalItemParents);

  const dispatch = useDispatch();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CompareMetricsFeatureFlags.ReportExport] ?? false;
  const formatter = useFormatter();
  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Compare_Metrics_Chart_${
          localParametersSelections.Time[0].value
        }_${getLocationForFileName(
          localParametersSelections.LocationHierarchy
        )}`
      ),
    [localParametersSelections]
  );

  const dataIndex = useMemo(
    () =>
      reportletData.itemData.findIndex(
        (tab) => tab.type === metricTypeSelection
      ),
    [metricTypeSelection, reportletData.itemData]
  );

  const mappedXAxisMetadata = useMemo(
    () =>
      getMetaDataMapping(
        xAxisSelection,
        reportletData.xAxisMetaData,
        focalItems,
        focalItemParents,
        localParametersConfig
      ),
    [
      focalItemParents,
      focalItems,
      localParametersConfig,
      reportletData.xAxisMetaData,
      xAxisSelection,
    ]
  );

  const mappedChartContentMetadata = useMemo(
    () =>
      getMetaDataMapping(
        chartContentSelection,
        reportletData.chartContentMetaData,
        focalItems,
        focalItemParents,
        localParametersConfig
      ),
    [
      chartContentSelection,
      focalItemParents,
      focalItems,
      localParametersConfig,
      reportletData.chartContentMetaData,
    ]
  );

  const parameterSummary = useMemo(
    () => [
      {
        name: "Focal Item",
        value: getFocalItemSummaryWithParents(focalItems, focalItemParents),
      },
      {
        name: "Time",
        value: `${localParametersSelections.Time[0].label}`,
      },
      {
        name: "Metric",
        value: metricSelection,
      },
      {
        name: "Channel",
        value: Object.values(localParametersSelections.Channel)
          .map((value) => value.label + " ")
          .toString(),
      },
      {
        name: "Promotion",
        value: Object.values(localParametersSelections.Promotion)
          .map((value) => value.label + " ")
          .toString(),
      },
      {
        name: "Segmentation",
        value: localParametersSelections.Segmentation[0].label,
      },
      {
        name: "Customer Segments",
        value: Object.values(localParametersSelections.Segmentation)
          .map((value) => value.label + " ")
          .slice(1)
          .toString(),
      },
      {
        name: "Location",
        value: Object.values(localParametersSelections.LocationHierarchy)
          .map((value) => value.label)
          .join(", "),
      },
    ],
    [
      focalItems,
      focalItemParents,
      localParametersSelections.Time,
      localParametersSelections.Channel,
      localParametersSelections.Promotion,
      localParametersSelections.Segmentation,
      localParametersSelections.LocationHierarchy,
      metricSelection,
    ]
  );

  const isStackedColumns =
    chartContentSelection === "Metric" &&
    contributionMetrics.findIndex(
      (metric) => metric.groupName === metricSelection
    ) > -1;

  const exportData = mappedChartContentMetadata.map((_, index) =>
    reportletData.xAxisMetaData.map(
      (_xAxisItem, index2) =>
        reportletData.itemData[dataIndex].values[index][index2]
    )
  );

  // Chart data converts "NaN" to 0 for the purpose of HighCharts
  const chartData = useMemo(
    () =>
      mappedChartContentMetadata.map((chartContentItem, index) => ({
        color: ChartColoursList[index],
        data: reportletData.xAxisMetaData.map((_xAxisItem, index2) => {
          const value = reportletData.itemData[dataIndex].values[index][index2];
          if (typeof value === "string" && value === "NaN") {
            return 0;
          }

          return value;
        }),
        name: chartContentItem,
        type: index === 0 && isStackedColumns ? "line" : "column",
      })) as ChartDataSeries[],
    [
      dataIndex,
      isStackedColumns,
      mappedChartContentMetadata,
      reportletData.itemData,
      reportletData.xAxisMetaData,
    ]
  );

  const metricTypeIndex = metricType.findIndex(
    (mt) => mt.value === reportletData.itemData[dataIndex].type
  );

  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        mappedXAxisMetadata,
        exportData,
        reportletData.itemData[dataIndex].metric,
        mappedChartContentMetadata,
        currencySymbol,
        xAxisSelection,
        reportletData.itemData[dataIndex].format
      ),
    [
      mappedXAxisMetadata,
      exportData,
      reportletData.itemData,
      mappedChartContentMetadata,
      dataIndex,
      currencySymbol,
      xAxisSelection,
    ]
  );

  const dataLabelOptions: DataLabelsOptions = {
    isSelected: showChartDataLabels,
    value: "",
  };

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  return (
    <div className={styles.reportletChartContainer}>
      <div className={styles.chartOptionsContainer}>
        <div className={styles.container}>
          <ChartOptions
            dataLabelsOptions={[dataLabelOptions]}
            downloadWizardOptions={
              currentOptions
                ? {
                    chartOptions: currentOptions,
                    reportIcon: <ReportIcon type={ReportType.CompareMetrics} />,
                    chartTitle: `Compare metrics - ${reportName}`,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={exportFilename}
            getCSVData={chartCsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={reportParameter}
            toggleDataLabels={() => {
              dispatch(toggleChartDataLabels());
            }}
          />
        </div>
      </div>
      <ChartFooterWrapper
        height="450px"
        parameters={parameterSummary}
        ref={chartContainerRef}
      >
        <CompareMetricsChart
          data={chartData}
          dataFormatter={formatter(reportletData.itemData[dataIndex].format)}
          isStackedColumns={isStackedColumns}
          onOptionsChanged={setCurrentOptions}
          showChartDataLabels={showChartDataLabels}
          threshold={metricType[metricTypeIndex].threshold}
          tooltipSentiment={Boolean(metricTypeSelection.includes("%"))}
          xAxisCategories={mappedXAxisMetadata}
          yAxisLabel={reportletData.itemData[dataIndex].metric}
        />
      </ChartFooterWrapper>
    </div>
  );
};

export default CompareMetricsReportletChart;
