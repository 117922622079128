import { type PropsWithChildren } from "react";

export type ReportTabItemType = {
  defaultImgIcon?: string;
  icon?: string;
  label: string;
  selectedImgIcon?: string;
  value: string;
};

/**
 * Wrapper component to wrap the given item with HTMLDivElement
 * because HTMLDivElement does not have label,value as attributes
 * this component is to abstract away the conversion from label,value
 * to id,title which is a much lesser known attribute set
 *
 * @returns HTMLDivElement wrapper
 */
export const ReportTabItem = ({
  defaultImgIcon,
  icon,
  label,
  value,
  selectedImgIcon,
  children,
}: PropsWithChildren<ReportTabItemType>) => (
  <div
    data-default-img-icon={defaultImgIcon}
    data-icon={icon}
    data-selected-img-icon={selectedImgIcon}
    id={value}
    title={label}
  >
    {children}
  </div>
);
