import { type CrossShopSegmentationRequestDto } from "../models/CrossShopSegmentationRequestDto";
import { type CrossShopSegmentationResponseDto } from "../models/CrossShopSegmentationResponseDto";
import { customerCrossShopApiSlice } from "./customer-cross-shop-api-slice";

export const customerCrossShopSegmentationApiSlice =
  customerCrossShopApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getSegmentationChartData: builder.query<
        CrossShopSegmentationResponseDto,
        { divisionName: string; payload: CrossShopSegmentationRequestDto }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetSegmentationChartData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useLazyGetSegmentationChartDataQuery } =
  customerCrossShopSegmentationApiSlice;
