import {
  ddLog,
  type HierarchyType,
  useLazyGetRootNodesQuery,
  useHierarchyStructureQuery,
  HierarchyStructureName,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHierarchyItems,
  onHierarchyRootNodeReceived,
  reset,
} from "../../states/group-hierarchy-source-slice";
import { type RootState } from "../../store";
import { GroupHierarchyTable } from "./GroupHierarchyTable";

type GroupHierarchyTableWrapperProps = {
  hierarchyType: HierarchyType;
};

// This is the equivalent of the ProductHierarchyParameterQuery in report-parameters (for the report-wizard)
export const GroupHierarchyTableWrapper = ({
  hierarchyType,
}: GroupHierarchyTableWrapperProps) => {
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();
  const [leafNodeShortName, setLeafNodeShortName] = useState<string>("");

  const items = useSelector((state: RootState) => selectHierarchyItems(state));

  const stateHierarchyType = useSelector(
    (state: RootState) => state.groupHierarchySource.type
  );

  // Grab root nodes
  const [triggerLazyGetRootNodesQuery, { data, isSuccess }] =
    useLazyGetRootNodesQuery();

  const fetchRootNode = useCallback(async () => {
    if (!activeDivisionName) {
      return;
    }

    await triggerLazyGetRootNodesQuery({
      division: activeDivisionName,
      hierarchyType: hierarchyType.toString() as HierarchyType,
      payload: {
        page: 0,
        pageSize: 100,
      },
    });
  }, [activeDivisionName, hierarchyType, triggerLazyGetRootNodesQuery]);

  // reset state if the hierarchy type selection changed
  useEffect(() => {
    if (hierarchyType !== stateHierarchyType) {
      dispatch(reset({ hierarchyType }));
    }
  }, [dispatch, hierarchyType, stateHierarchyType]);

  useEffect(() => {
    if (items.length === 0) {
      fetchRootNode().catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [items.length, fetchRootNode]);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(onHierarchyRootNodeReceived(data));
    }
  }, [data, dispatch, isSuccess]);

  const { data: hierarchyStructure, isSuccess: isStructureQuerySuccess } =
    useHierarchyStructureQuery({
      division: activeDivisionName,
      hierarchyType: hierarchyType.toString() as HierarchyType,
    });

  useEffect(() => {
    if (isStructureQuerySuccess && hierarchyStructure.length > 0) {
      const hierarchyLeafNode = hierarchyStructure
        .filter((level) => {
          const structureName = level.structureName.toLowerCase();
          return (
            structureName === HierarchyStructureName.Product.toLowerCase() ||
            structureName === HierarchyStructureName.Location.toLowerCase()
          );
        })
        .sort((a, b) => b.ordinal - a.ordinal)[0];
      setLeafNodeShortName(hierarchyLeafNode.shortName);
    }
  }, [hierarchyStructure, isStructureQuerySuccess]);

  return (
    <GroupHierarchyTable
      hierarchyType={hierarchyType.toString() as HierarchyType}
      isSuccess={items.length !== 0}
      leafNodeShortName={leafNodeShortName}
    />
  );
};
