import { formatNumberDate } from "@quantium-enterprise/common-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  HighchartsReact,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import HighchartsCustomTooltip from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import {
  type SeriesOptionsType,
  type TooltipFormatterContextObject,
} from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type TrialAndRepeatDistributionPricingResponseDto } from "../models/TrialAndRepeatDistributionPricingResponseDto";
import {
  sharedMouseMove,
  sharedMouseOut,
} from "../utils/synchronisedChartUtils";

export type DistributionPricingChartProps = {
  data: TrialAndRepeatDistributionPricingResponseDto;
  hoveredPoints: React.MutableRefObject<TooltipFormatterContextObject[]>;
  onHoveredPointsChange: (points: TooltipFormatterContextObject[]) => void;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  showDataLabels?: boolean;
};

const metricMetadata = [
  {
    id: "store",
    format: "Integer",
    title: "Stores",
  },
  {
    id: "price",
    format: "Currency",
    title: "Avg. price",
  },
];

const getMetadataFromMetricName = (metric: string) =>
  metric.includes("Stores") ? metricMetadata[0] : metricMetadata[1];

export const DistributionPricingChart = ({
  data,
  hoveredPoints,
  onHoveredPointsChange,
  onOptionsChanged,
  showDataLabels,
}: DistributionPricingChartProps) => {
  const formatter = useFormatter();

  const categories = useMemo(
    () => data.dates.map((pw) => formatNumberDate(Number.parseInt(pw, 10))),
    [data.dates]
  );

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const highchartsSeries: SeriesOptionsType[] = useMemo(
    () =>
      data.legends.map((metric, index) => ({
        dashStyle: "Solid",
        data: data.values[index],
        marker: { enabled: false, symbol: "circle" },
        name: metric,
        custom: {
          metricFormat: getMetadataFromMetricName(metric).format,
        },
        type: "line",
        yAxis: getMetadataFromMetricName(metric).id,
        dataLabels: {
          enabled: showDataLabels,
          formatter() {
            return formatter(getMetadataFromMetricName(metric).format)(
              // eslint-disable-next-line react/no-this-in-sfc
              this.point.y,
              false,
              "",
              true
            );
          },
        },
        visible: !hiddenSeries.includes(index),
      })),
    [data.legends, data.values, formatter, hiddenSeries, showDataLabels]
  );

  const getYAxis = useMemo(
    () =>
      (id: string, formatType: string, title: string, opposite = false) => ({
        id,
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter({ value }: { value: number | string }) {
            return formatter(formatType)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
            fontSize: "0.75rem",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
            fontWeight: "var(--qbit-font-weight-medium)",
          },
          text: title,
        },
        allowDecimals: !(
          formatType === MetricTypes.Integer ||
          formatType === MetricTypes.SmallInteger
        ),
        opposite,
      }),
    [formatter]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      series: highchartsSeries,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsCustomTooltip({
            pointFormatter: (pt: TooltipFormatterContextObject) =>
              formatter(pt.series.userOptions.custom?.metricFormat),
            ttData: { ...ttData, points: hoveredPoints.current },
            yAxisTitle: "",
          }),
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-secondary)",
          dashStyle: "Dash",
          width: 1,
          zIndex: 1,
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        min: 0,
      },
      yAxis: metricMetadata.map((metric) =>
        getYAxis(metric.id, metric.format, metric.title, metric.id === "price")
      ),
      legend: {
        align: "left",
        enabled: true,
        itemStyle: {
          color: "var(--qbit-colour-text-secondary)",
          fontSize: "0.75rem",
          fontWeight: "var(--qbit-font-weight-regular)",
        },
        verticalAlign: "bottom",
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: sharedMouseMove(onHoveredPointsChange),
              mouseOut: sharedMouseOut,
            },
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
    }),
    [
      categories,
      formatter,
      getYAxis,
      highchartsSeries,
      hoveredPoints,
      legendItemClick,
      onHoveredPointsChange,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
