import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import { ParameterId, ddLog } from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { FormInputHeight } from "@quantium-enterprise/qds-react";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { type DataLabelsOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type TooltipFormatterContextObject } from "highcharts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import defaultChartImgIcon from "../../assets/tab-icons/bar-chart-black.svg";
import selectedChartImgIcon from "../../assets/tab-icons/bar-chart-white.svg";
import defaultTableImgIcon from "../../assets/tab-icons/table-black.svg";
import selectedTableImgIcon from "../../assets/tab-icons/table-white.svg";
import { ChartDataWrapper } from "../../common/components/ChartDataWrapper";
import { ReportButtonTabs } from "../../common/components/ReportTabComponents/ReportButtonTabs";
import { ReportTabItem } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabs } from "../../common/components/ReportTabComponents/ReportTabs";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { ReportletFilterWrapper } from "../../common/components/ReportletFilterWrapper";
import { isSingleSelectionParameter } from "../../common/utils/local-parameters/LocalParametersUtils";
import { TrialAndRepeatFeatureFlags } from "../constants/trial-and-repeat-feature-flags";
import {
  PURCHASE_ACTIVITY_DATA_TYPE_TABS,
  PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS,
  PURCHASE_ACTIVITY_TABS,
  PURCHASE_ACTIVITY_VIEW_TYPE_TABS,
} from "../models/PurchaseActivityTabs";
import { useLazyGetDistributionDataQuery } from "../services/trial-and-repeat-distribution-pricing-api-slice";
import { useLazyGetPurchaseActivityQuery } from "../services/trial-and-repeat-purchase-activity-api-slice";
import {
  onPurchaseActivityActiveTabChange,
  onPurchaseActivityDataTypeChange,
  onPurchaseActivitySummaryTypeChange,
  onPurchaseActivityViewTypeChange,
  onPurchaseStageChange,
  selectChannel,
  selectDataType,
  selectFocalItem,
  selectLocalParameters,
  selectLocalParametersInitialised,
  selectLocalSelections,
  selectMetric,
  selectPromotion,
  selectPurchaseActivityActiveTab,
  selectReportId,
  selectReportName,
  selectSegmentation,
  selectShowDistributionDataLabels,
  selectShowPurchaseDataLabels,
  selectSummaryType,
  selectTransactionNumber,
  selectViewType,
  toggleDistributionDataLabel,
  togglePurchaseDataLabel,
} from "../services/trial-and-repeat-slice";
import { type PurchaseActivityTableData } from "../utils/generateDataTableColumns";
import { DistributionPricingChart } from "./DistributionPricingChart";
import { PurchaseActivityChart } from "./PurchaseActivityChart";
import { PurchaseActivityDataTable } from "./PurchaseActivityDataTable";
import styles from "./PurchaseActivityReportlet.module.css";
import {
  dapChartCsvTransformation,
  paChartCsvTransformation,
  paTableCsvTransformation,
} from "./csv-transformations/csvTransformations";

export const purchaseStageOptions = [
  { label: "Trial", value: "1" },
  { label: "Repeat 1", value: "2" },
  { label: "Repeat 2", value: "3" },
  { label: "Repeat 3", value: "4" },
  { label: "Repeat 4", value: "5" },
  { label: "Repeat 5", value: "6" },
  { label: "Repeat 6+", value: "7+" },
];

const tabNames = [
  PURCHASE_ACTIVITY_TABS.TrialAndRepeat,
  PURCHASE_ACTIVITY_TABS.Segmentation,
  PURCHASE_ACTIVITY_TABS.Promotion,
  PURCHASE_ACTIVITY_TABS.Channel,
];

type PurchaseActivityReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const PurchaseActivityReportlet = ({
  infoPanelSummary,
}: PurchaseActivityReportletProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName, locale, currency } = useDivision();
  const purchaseActivityChartContainerRef = useRef<HTMLElement>();
  const distributionAndPricingChartContainerRef = useRef<HTMLElement>();
  const [paTableData, setPaTableData] = useState<PurchaseActivityTableData[]>();

  const featureFlags = useFlags();

  const hoveredPoints = useRef<TooltipFormatterContextObject[]>([]);

  const handleHoveredPointsUpdate = useCallback(
    (points: TooltipFormatterContextObject[]) => {
      hoveredPoints.current = points;
    },
    [hoveredPoints]
  );

  const activeTab = useSelector(selectPurchaseActivityActiveTab);
  const focalItem = useSelector(selectFocalItem);
  const breakdownType = useSelector(selectPurchaseActivityActiveTab);
  const localSelections = useSelector(selectLocalSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const transactionNumber = useSelector(selectTransactionNumber);
  const localParameters = useSelector(selectLocalParameters);
  const viewType = useSelector(selectViewType);
  const summaryType = useSelector(selectSummaryType);
  const metric = useSelector(selectMetric);
  const segmentation = useSelector(selectSegmentation);
  const promotion = useSelector(selectPromotion);
  const channel = useSelector(selectChannel);
  const showDistributionDataLabels = useSelector(
    selectShowDistributionDataLabels
  );
  const showPurchaseDataLabels = useSelector(selectShowPurchaseDataLabels);
  const dataType = useSelector(selectDataType);
  const id = useSelector(selectReportId);
  const reportName = useSelector(selectReportName);

  const isExportEnabled =
    (featureFlags[TrialAndRepeatFeatureFlags.ReportExport] &&
      localParametersInitialised) ??
    false;

  const getMetricLabel = () => {
    const metricParameter = localParameters.find(
      (x) => x.id === ParameterId.Metric
    );
    if (metricParameter && isSingleSelectionParameter(metricParameter)) {
      const metricLabel = metricParameter.selections.find(
        (x) => x.value === metric.value
      )?.label;

      return metricLabel ?? metric.value.toString();
    }

    return metric.value.toString();
  };

  const breakdownTabNames =
    localSelections.segmentation.length === 0
      ? tabNames.filter((x) => x !== PURCHASE_ACTIVITY_TABS.Segmentation)
      : tabNames;

  const [
    getPurchaseActivityData,
    {
      data: purchaseActivityData,
      isFetching: isPurchaseActivityFetching,
      isError: isPurchaseActivityError,
    },
  ] = useLazyGetPurchaseActivityQuery();

  const [
    getDistributionPricingData,
    {
      data: pricingData,
      isFetching: isDistributionFetching,
      isError: isDistributionError,
    },
  ] = useLazyGetDistributionDataQuery();

  const fetchData = useCallback(
    async (division: string) => {
      if (focalItem) {
        await getPurchaseActivityData({
          division,
          payload: {
            focalItem,
            segmentation: segmentation.map((index) => index.value as string),
            channel: channel.value as string,
            promotion: promotion.value as string,
            measure: metric.value as string,
            transactionNumber:
              transactionNumber === "" ? null : transactionNumber,
            locationNode:
              localSelections.LocationHierarchy.nodeNumber.toString(),
          },
          breakdownType,
          reportId,
        }).catch((error) => {
          ddLog(
            "Error retrieving trial and repeat purchase activity chart data",
            {},
            "error",
            error
          );
        });
      }
    },
    [
      reportId,
      focalItem,
      transactionNumber,
      breakdownType,
      getPurchaseActivityData,
      segmentation,
      channel,
      promotion,
      metric,
      localSelections.LocationHierarchy.nodeNumber,
    ]
  );

  const fetchDistributionData = useCallback(
    async (division: string) => {
      if (focalItem) {
        await getDistributionPricingData({
          division,
          payload: {
            focalItem,
            channel: channel.value as string,
            promotion: promotion.value as string,
            locationNode:
              localSelections.LocationHierarchy.nodeNumber.toString(),
          },
          reportId,
        }).catch((error) => {
          ddLog(
            "Error retrieving trial and repeat distribution pricing chart data",
            {},
            "error",
            error
          );
        });
      }
    },
    [
      reportId,
      focalItem,
      getDistributionPricingData,
      channel,
      promotion,
      localSelections.LocationHierarchy.nodeNumber,
    ]
  );

  useEffect(() => {
    if (
      divisionName &&
      reportId &&
      reportId === id &&
      localParametersInitialised
    ) {
      void fetchData(divisionName);
    }
  }, [divisionName, fetchData, reportId, localParametersInitialised, id]);

  useEffect(() => {
    if (
      divisionName &&
      reportId &&
      reportId === id &&
      localParametersInitialised
    ) {
      void fetchDistributionData(divisionName);
    }
  }, [
    divisionName,
    fetchDistributionData,
    reportId,
    localParametersInitialised,
    id,
  ]);

  const purchaseActivityResponse = useMemo(() => {
    if (!purchaseActivityData) {
      return {
        legends: [],
        dates: [],
        data: [],
      };
    }

    if (breakdownType === PURCHASE_ACTIVITY_TABS.TrialAndRepeat) {
      return {
        legends: purchaseActivityData.legends.map(
          (value) =>
            purchaseStageOptions.find((option) => option.value === value)
              ?.label ?? value
        ),
        dates: purchaseActivityData.dates,
        data: purchaseActivityData.data,
      };
    }

    return purchaseActivityData;
  }, [purchaseActivityData, breakdownType]);

  const distributionPricingResponse = useMemo(() => {
    if (!pricingData) {
      return {
        legends: [],
        dates: [],
        values: [],
      };
    }

    return pricingData;
  }, [pricingData]);

  const daplocalParametersSummary = useMemo(
    () => [
      {
        name: "Focal Item",
        value: `(${hierarchyLevelDisplayLabel(focalItem?.shortName ?? "")}) ${
          focalItem?.name
        }`,
      },
      { name: "Time", value: localSelections.time as string },
      { name: "Metric", value: localSelections.metric.label },
      {
        name: "Segmentation",
        value: localSelections.segmentation[0]?.label,
      },
      {
        name: "Customer Segment",
        value: localSelections.segmentation[1]?.label,
      },
      { name: "Promotion", value: localSelections.promotion.label },
      { name: "Channel", value: localSelections.channel.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localSelections.LocationHierarchy.shortName
        )}) ${localSelections.LocationHierarchy.name}`,
      },
    ],
    [localSelections, focalItem]
  );

  const palocalParametersSummary = useMemo(() => {
    const parameterSummary = [
      ...daplocalParametersSummary,
      { name: "Summary type", value: summaryType },
      { name: "Data type", value: dataType },
    ];

    if (transactionNumber !== null) {
      parameterSummary.push({
        name: "Purchase stage",
        value:
          purchaseStageOptions[Number.parseInt(transactionNumber, 10) - 1]
            .label,
      });
    }

    return parameterSummary;
  }, [daplocalParametersSummary, summaryType, dataType, transactionNumber]);

  const paChartExportFilename = useMemo(
    () =>
      cleanFilename(
        `Purchase_Activity_Chart_${localSelections.timePeriodLength}_${localSelections.LocationHierarchy.name}`
      ),
    [localSelections]
  );

  const paTableExportFilename = useMemo(
    () =>
      cleanFilename(
        `Purchase_Activity_Table_${localSelections.timePeriodLength}_${localSelections.LocationHierarchy.name}`
      ),
    [localSelections]
  );

  const dpChartExportFilename = useMemo(
    () =>
      cleanFilename(
        `Distribution_Pricing_Chart_${localSelections.timePeriodLength}_${localSelections.LocationHierarchy.name}`
      ),
    [localSelections]
  );

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const paChartCsvTransformationCallback = useCallback(
    () =>
      paChartCsvTransformation(
        purchaseActivityResponse,
        summaryType,
        dataType,
        localSelections.metric.label,
        currencySymbol
      ),
    [
      purchaseActivityResponse,
      summaryType,
      dataType,
      localSelections,
      currencySymbol,
    ]
  );

  const dapChartCsvTransformationCallback = useCallback(
    () =>
      dapChartCsvTransformation(distributionPricingResponse, currencySymbol),
    [distributionPricingResponse, currencySymbol]
  );

  const getPaTableData = useCallback((data: PurchaseActivityTableData[]) => {
    setPaTableData(data);
  }, []);

  const paTableCsvTransformationCallback = useCallback(
    () =>
      paTableCsvTransformation(
        paTableData,
        purchaseActivityResponse,
        distributionPricingResponse,
        currencySymbol
      ),
    [
      paTableData,
      purchaseActivityResponse,
      distributionPricingResponse,
      currencySymbol,
    ]
  );

  const purchaseDataLabelsOptions: DataLabelsOptions = {
    isSelected: showPurchaseDataLabels,
    value: "",
  };

  const distributionDataLabelsOptions: DataLabelsOptions = {
    isSelected: showDistributionDataLabels,
    value: "",
  };

  const distributionPricingChartData = useMemo(
    () => structuredClone(distributionPricingResponse),
    [distributionPricingResponse]
  );

  const [purchaseActivityOptions, setPurchaseActivityOptions] =
    useState<HighchartsReactProps>();
  const [distributionAndPricingOptions, setDistributionAndPricingOptions] =
    useState<HighchartsReactProps>();

  return (
    <ReportletAccordion
      subtitle="Analyse shopper purchasing behaviour for your focal item by: trialists and repeat buys, segmentation, promotion, and channel."
      title="Purchase activity"
    >
      <div className={styles.chartOptionsContainer}>
        {viewType === PURCHASE_ACTIVITY_VIEW_TYPE_TABS.Chart ? (
          <ChartOptions
            dataLabelsOptions={[purchaseDataLabelsOptions]}
            downloadWizardOptions={
              purchaseActivityOptions
                ? {
                    chartOptions: purchaseActivityOptions,
                    reportIcon: <ReportIcon type={ReportType.TrialAndRepeat} />,
                    chartTitle: `Purchase activity - ${reportName}`,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={paChartExportFilename}
            getCSVData={paChartCsvTransformationCallback}
            getElementToExport={() => purchaseActivityChartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={palocalParametersSummary}
            reportParameters={infoPanelSummary}
            toggleDataLabels={() => {
              dispatch(togglePurchaseDataLabel());
            }}
          />
        ) : (
          <DataTableOptions
            filename={paTableExportFilename}
            invokeCSVDownload={paTableCsvTransformationCallback}
            isFeatureEnabled={isExportEnabled}
            localParameters={palocalParametersSummary}
            reportParameters={infoPanelSummary}
          />
        )}
      </div>
      <ReportTabs
        activeTab={activeTab}
        onClickTabItem={(tab) =>
          dispatch(onPurchaseActivityActiveTabChange(tab))
        }
      >
        {breakdownTabNames.map((tab) => (
          <ReportTabItem key={tab} label={tab} value={tab}>
            <div className={styles.purchaseActivityToggles}>
              <div className={styles.buttonToggles}>
                <ReportButtonTabs
                  activeTab={viewType}
                  onClickButtonTabItem={(value) =>
                    dispatch(onPurchaseActivityViewTypeChange(value))
                  }
                >
                  <ReportTabItem
                    defaultImgIcon={defaultChartImgIcon}
                    label={PURCHASE_ACTIVITY_VIEW_TYPE_TABS.Chart}
                    selectedImgIcon={selectedChartImgIcon}
                    value={PURCHASE_ACTIVITY_VIEW_TYPE_TABS.Chart}
                  />
                  <ReportTabItem
                    defaultImgIcon={defaultTableImgIcon}
                    label={PURCHASE_ACTIVITY_VIEW_TYPE_TABS.Table}
                    selectedImgIcon={selectedTableImgIcon}
                    value={PURCHASE_ACTIVITY_VIEW_TYPE_TABS.Table}
                  />
                </ReportButtonTabs>
                <ReportButtonTabs
                  activeTab={summaryType}
                  onClickButtonTabItem={(value) =>
                    dispatch(onPurchaseActivitySummaryTypeChange(value))
                  }
                >
                  <ReportTabItem
                    label={PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS.Cumulative}
                    value={PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS.Cumulative}
                  />
                  <ReportTabItem
                    label={PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS.Weekly}
                    value={PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS.Weekly}
                  />
                </ReportButtonTabs>
                <ReportButtonTabs
                  activeTab={dataType}
                  onClickButtonTabItem={(value) =>
                    dispatch(onPurchaseActivityDataTypeChange(value))
                  }
                >
                  <ReportTabItem
                    label={PURCHASE_ACTIVITY_DATA_TYPE_TABS.Actual}
                    value={PURCHASE_ACTIVITY_DATA_TYPE_TABS.Actual}
                  />
                  <ReportTabItem
                    label={PURCHASE_ACTIVITY_DATA_TYPE_TABS.Share}
                    value={PURCHASE_ACTIVITY_DATA_TYPE_TABS.Share}
                  />
                </ReportButtonTabs>
              </div>
              {activeTab !== PURCHASE_ACTIVITY_TABS.TrialAndRepeat && (
                <ReportletFilterWrapper label="Purchase stage">
                  <SingleSelectDropdown
                    height={FormInputHeight.XSmall}
                    onSelection={(value) =>
                      dispatch(onPurchaseStageChange(value.value as string))
                    }
                    selectOptions={purchaseStageOptions}
                    selectedValue={transactionNumber ?? ""}
                    title="Purchase Stage"
                  />
                </ReportletFilterWrapper>
              )}
            </div>
            {viewType === PURCHASE_ACTIVITY_VIEW_TYPE_TABS.Chart ? (
              <>
                <ChartFooterWrapper
                  height="400px"
                  parameters={palocalParametersSummary}
                  ref={purchaseActivityChartContainerRef}
                >
                  <ErrorBoundary>
                    <ChartDataWrapper
                      isError={isPurchaseActivityError}
                      isSuccess={
                        !isPurchaseActivityFetching &&
                        localParametersInitialised
                      }
                      minHeight="25rem"
                      retry={async () => await fetchData(divisionName)}
                      showNoDataAvailable={
                        purchaseActivityResponse.data.length === 0
                      }
                    >
                      <PurchaseActivityChart
                        data={purchaseActivityResponse}
                        dataType={dataType}
                        hoveredPoints={hoveredPoints}
                        onHoveredPointsChange={handleHoveredPointsUpdate}
                        onOptionsChanged={setPurchaseActivityOptions}
                        primaryMetricLabel={getMetricLabel()}
                        showDataLabels={showPurchaseDataLabels}
                        summaryType={summaryType}
                      />
                    </ChartDataWrapper>
                  </ErrorBoundary>
                </ChartFooterWrapper>
                <div className={styles.chartOptionsContainerTwo}>
                  <h6 className={styles.chartHeader}>
                    Distribution and pricing
                  </h6>
                  <ChartOptions
                    dataLabelsOptions={[distributionDataLabelsOptions]}
                    downloadWizardOptions={
                      distributionAndPricingOptions
                        ? {
                            chartOptions: distributionAndPricingOptions,
                            reportIcon: (
                              <ReportIcon type={ReportType.TrialAndRepeat} />
                            ),
                            chartTitle: `Distribution and pricing - ${reportName}`,
                            reportTitle: reportName,
                          }
                        : undefined
                    }
                    filename={dpChartExportFilename}
                    getCSVData={dapChartCsvTransformationCallback}
                    getElementToExport={() =>
                      distributionAndPricingChartContainerRef.current
                    }
                    isFeatureEnabled={isExportEnabled}
                    localParameters={daplocalParametersSummary}
                    reportParameters={infoPanelSummary}
                    toggleDataLabels={() => {
                      dispatch(toggleDistributionDataLabel());
                    }}
                  />
                </div>
                <ChartFooterWrapper
                  height="400px"
                  parameters={daplocalParametersSummary}
                  ref={distributionAndPricingChartContainerRef}
                >
                  <ErrorBoundary>
                    <ChartDataWrapper
                      isError={isDistributionError}
                      isSuccess={
                        !isDistributionFetching && localParametersInitialised
                      }
                      minHeight="25rem"
                      retry={async () =>
                        await fetchDistributionData(divisionName)
                      }
                      showNoDataAvailable={
                        distributionPricingResponse.values.length === 0
                      }
                    >
                      <DistributionPricingChart
                        data={distributionPricingChartData}
                        hoveredPoints={hoveredPoints}
                        onHoveredPointsChange={handleHoveredPointsUpdate}
                        onOptionsChanged={setDistributionAndPricingOptions}
                        showDataLabels={showDistributionDataLabels}
                      />
                    </ChartDataWrapper>
                  </ErrorBoundary>
                </ChartFooterWrapper>
              </>
            ) : (
              <ErrorBoundary>
                <ChartDataWrapper
                  isError={isDistributionError || isPurchaseActivityError}
                  isSuccess={
                    !isPurchaseActivityFetching &&
                    !isDistributionFetching &&
                    localParametersInitialised
                  }
                  retry={() => {
                    void fetchData(divisionName);
                    void fetchDistributionData(divisionName);
                  }}
                  showNoDataAvailable={
                    purchaseActivityResponse.data.length === 0 &&
                    distributionPricingResponse.values.length === 0
                  }
                >
                  <PurchaseActivityDataTable
                    dataType={dataType}
                    distributionPricingResponse={distributionPricingResponse}
                    getTableData={getPaTableData}
                    purchaseActivityResponse={purchaseActivityResponse}
                    summaryType={summaryType}
                  />
                </ChartDataWrapper>
              </ErrorBoundary>
            )}
          </ReportTabItem>
        ))}
      </ReportTabs>
    </ReportletAccordion>
  );
};
