type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};
export const ProductSubstitutabilityIcon = ({
  className,
  coloured,
  text,
}: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Product substitutability report"}</title>
      <path
        d="M8.49378 9.38661C7.73802 10.8421 5.94544 11.4094 4.48994 10.6536C3.03444 9.89784 2.46719 8.10526 3.22295 6.64976C3.97871 5.19426 5.77129 4.62701 7.22679 5.38277C8.68229 6.13853 9.24954 7.93111 8.49378 9.38661Z"
        fill={colour1}
      />
      <path
        d="M21.2678 18.408C19.9772 20.9058 16.9062 21.8844 14.4084 20.5938C11.9107 19.3033 10.9321 16.2322 12.2226 13.7345C13.5132 11.2367 16.5842 10.2581 19.082 11.5487C21.5797 12.8392 22.5583 15.9103 21.2678 18.408Z"
        fill={colour3}
      />
      <path
        d="M4.33983 11.5414C4.24452 11.4013 4.04138 11.3919 3.93355 11.5226L2.05851 13.7959C1.92522 13.9575 2.03362 14.2019 2.24287 14.2115L2.8962 14.2417C3.05606 14.8282 3.27604 15.3987 3.55382 15.9438C4.16888 17.1508 5.05111 18.2016 6.1334 19.0163C7.21568 19.831 8.46952 20.3882 9.79954 20.6455C10.8556 20.8497 11.9376 20.8606 12.9934 20.68C12.3213 20.1335 11.7911 19.4569 11.4211 18.7069C11.0119 18.7027 10.6026 18.6616 10.1984 18.5834C9.1819 18.3868 8.22366 17.961 7.39653 17.3384C6.5694 16.7157 5.89516 15.9126 5.4251 14.9902C5.31793 14.7799 5.22201 14.5646 5.13758 14.3454L5.77521 14.3748C5.98445 14.3845 6.11494 14.1512 5.99713 13.978L4.33983 11.5414Z"
        fill={colour2}
      />
      <path
        d="M6.81299 4.33853C7.09292 4.40133 7.36943 4.50092 7.63603 4.63936C8.11982 4.89056 8.51774 5.24199 8.81757 5.65502C9.52305 5.35134 10.2772 5.16822 11.0485 5.11588C12.1664 5.04 13.2857 5.24121 14.3072 5.70163C15.2628 6.13238 16.1052 6.77757 16.7695 7.58594L16.3306 7.63612C16.1225 7.65991 16.0308 7.91109 16.1747 8.0633L18.1984 10.2039C18.3148 10.327 18.5168 10.3039 18.6025 10.1577L19.6687 8.33656L19.6704 8.33626C19.6702 8.33576 19.67 8.33526 19.6698 8.33476L20.0908 7.61562C20.1966 7.43486 20.0507 7.21085 19.8426 7.23464L19.1422 7.3147C18.9676 7.02458 18.7761 6.74387 18.5684 6.4741C17.6741 5.31238 16.5068 4.38936 15.1702 3.7869C13.8336 3.18444 12.369 2.92117 10.9062 3.02045C9.4545 3.11898 8.04916 3.5716 6.81299 4.33853Z"
        fill={colour2}
      />
    </svg>
  );
};
