import { ScaleLegendColors } from "components-ui/src/tables/common/legend/ScaleLegend";

export const calculatePercentile = (value: number) => {
  const percentile = Math.min(
    Math.ceil(value * ScaleLegendColors.length) - 1,
    ScaleLegendColors.length - 1
  );

  return percentile < 0 ? 0 : percentile;
};
