import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const gainsAndLossApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/gains-and-loss`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "gainsAndLossApi",
  tagTypes: ["gains-and-loss"],
});
