export const Time = ({ className }: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99331 1.83331C4.31331 1.83331 1.33331 4.81998 1.33331 8.49998C1.33331 12.18 4.31331 15.1666 7.99331 15.1666C11.68 15.1666 14.6666 12.18 14.6666 8.49998C14.6666 4.81998 11.68 1.83331 7.99331 1.83331ZM7.99998 13.8333C5.05331 13.8333 2.66665 11.4466 2.66665 8.49998C2.66665 5.55331 5.05331 3.16665 7.99998 3.16665C10.9466 3.16665 13.3333 5.55331 13.3333 8.49998C13.3333 11.4466 10.9466 13.8333 7.99998 13.8333ZM8.33331 5.16665H7.33331V9.16665L10.8333 11.2666L11.3333 10.4466L8.33331 8.66665V5.16665Z"
      fill="#B5B5B5"
    />
  </svg>
);
