import {
  type Series,
  type TooltipFormatterContextObject,
  type Point,
  type PointerEventObject,
  charts,
} from "highcharts";

const compareChartType = (
  a: TooltipFormatterContextObject,
  b: TooltipFormatterContextObject
) => {
  const chartTypeOrder: Record<string, number> = {
    column: -1,
    line: 1,
  };

  return chartTypeOrder[a.series.type] - chartTypeOrder[b.series.type];
};

export const sharedMouseMove = (
  setHoveredPoints: (points: TooltipFormatterContextObject[]) => void
) =>
  function (this: Point, event: Event) {
    // eslint-disable-next-line @babel/no-invalid-this
    const currentX = this.category;

    const newHoveredPoints: TooltipFormatterContextObject[] = charts
      .flatMap((chart) =>
        chart?.series
          .filter((series) => series.visible)
          .flatMap((series) => series.points)
      )
      .filter((point) => point?.category === currentX)
      .flatMap((point) => ({
        color: point?.color,
        colorIndex: point?.colorIndex ?? 0,
        key: currentX.toString(),
        percentage: point?.percentage ?? 0,
        point: point ?? ({} as Point),
        series: point?.series ?? ({} as Series),
        total: point?.total,
        x: currentX,
        y: point?.y,
      }))
      .sort(
        (a: TooltipFormatterContextObject, b: TooltipFormatterContextObject) =>
          compareChartType(a, b) || a.colorIndex - b.colorIndex
      );

    setHoveredPoints(newHoveredPoints);

    for (const chart of charts) {
      if (!chart) continue;
      const pointerEvent = chart.pointer.normalize(event as PointerEventObject);
      for (const series of chart.series.filter((item) => item.visible)) {
        for (const point of series.points) {
          if (point.category === currentX) {
            // need to trigger crosshair and point for desired highlighting
            chart.xAxis[0].drawCrosshair(pointerEvent, point);
            point.setState("hover");
          }
        }
      }
    }
  };

export const sharedMouseOut = () => {
  for (const chart of charts) {
    if (!chart) continue;
    chart.xAxis[0].hideCrosshair();
    for (const series of chart.series.filter((item) => item.visible))
      for (const point of series.points) point.setState("");
  }
};
