import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isSegmentationParameter,
  isMetricWithFormatParameter,
  isTimePeriodParameter,
  isLocationSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getMetricWithFormatSelectDefault,
  getSegmentationSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import {
  initialState,
  type CustomerLoyaltyLocalSelections,
} from "../services/customer-loyalty-slice";

export const getLocalSelections = (
  localParameters: SidePanelParameter[]
): CustomerLoyaltyLocalSelections => {
  const localSelections: CustomerLoyaltyLocalSelections = {
    ...initialState.persistedSelections.localSelections,
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const selection = getTimeDefaultSelection(parameter);
          localSelections.time = selection.time;
          localSelections.timePeriod = selection.timePeriodLength;
        }

        break;
      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.segmentation = {
              label: selection.label,
              value: selection.value,
            };
            localSelections.segment = {
              label: selection.segmentLabel,
              value: selection.segmentValue,
            };
          }
        }

        break;
      case LocalParameters.Metrics:
        if (isMetricWithFormatParameter(parameter))
          localSelections.metric = getMetricWithFormatSelectDefault(parameter);
        break;

      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          localSelections.locationHierarchy =
            getLocationSelectDefault(parameter);
        }

        break;
      default:
        break;
    }
  }

  return localSelections;
};
