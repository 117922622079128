import { useEffect, useState } from "react";

/*
This hook will set whether the element passed in by ref is visible in the viewport. 
This uses the native IntersectionObserver and should be useful for trigging lazy loading.

** Usage **

function MyComponent() {
  const ref = useRef(null);
  const isOnScreen = useScrollIntoViewPort(ref, { threshold: 0.25 });

  return (
    <div ref={ref}>
      {isOnScreen ? 'Visible' : 'Not visible'}
    </div>
  );
}

*/
export const useScrollIntoViewport = (
  ref: React.MutableRefObject<HTMLElement | null>,
  options: IntersectionObserverInit
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return isIntersecting;
};
