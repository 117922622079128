import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type SearchConfigurationDto } from "./dtos/SearchConfigurationDto";

export const SearchConfigurationApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    SearchConfiguration: builder.query<
      SearchConfigurationDto,
      { division: string }
    >({
      providesTags: ["SearchConfiguration"],
      query: ({ division }) => ({
        method: "GET",
        url: `${division}/search-configuration`,
      }),
    }),
  }),
  reducerPath: "fastReportingSearchConfigurationApi",
  tagTypes: ["SearchConfiguration"],
});

export const { useSearchConfigurationQuery } = SearchConfigurationApi;
