import {
  type MetricMetadata,
  type TableRow,
} from "../models/basket-affinities-data-table-models";

export const csvTransformation = (
  tableRows: TableRow[],
  metricMetadata: MetricMetadata[]
) => {
  const columnHeaders = ["Focal item", "Focal item code"];
  const csvData = [columnHeaders];
  for (const metadata of metricMetadata) {
    columnHeaders.push(metadata.metricLabel);
  }

  for (const row of tableRows) {
    const rowData = [row.product.name, row.product.itemCode];
    for (const metric of row.metrics) {
      rowData.push(metric.metricValue?.toString() ?? "");
    }

    csvData.push(rowData);
  }

  return csvData;
};
