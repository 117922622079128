import {
  Button,
  ButtonVariant,
  EmptyState,
  Icon,
  IconGlyph,
  IllustrationSize,
  SpaceAround,
  SpotColour,
  StateType,
  Text,
} from "@quantium-enterprise/qds-react";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import styles from "./ErrorMessageWithRetry.module.css";

type ErrorMessageWithRetryProps = {
  errorText: string;
  retry?: () => void;
};

const ErrorMessageWithRetry = ({
  errorText,
  retry,
  ...properties
}: ErrorMessageWithRetryProps) => (
  <div {...properties} className={styles.container}>
    <EmptyState
      description=""
      heading=""
      size={IllustrationSize.Large}
      spaceOutside={SpaceAround.XXXSmall}
      spotColour={SpotColour.Theme}
      stateType={StateType.Insights}
    />
    <div className={styles.errorTitle}>
      <Icon
        colour={tokens.colour["status-bad-text"]}
        glyph={IconGlyph.AlertsAndNotificationsWarning}
        text="Error"
      />
      <Text>
        <b>{errorText}</b>
      </Text>
    </div>
    <Text className={styles.errorDescription}>
      <p>
        {retry ? "Please try again." : "An error has occurred."} If the error
        persists, please contact{" "}
        <a href="mailto:ProductSupport@quantium.com">user support</a>.
      </p>
    </Text>
    {retry && (
      <Button
        onClick={() => {
          retry();
        }}
        text="Retry"
        variant={ButtonVariant.Primary}
      />
    )}
  </div>
);

export default ErrorMessageWithRetry;
