import { HierarchyItemType, ddLog } from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { type ReportTabItemType } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabItem } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabs } from "../../common/components/ReportTabComponents/ReportTabs";
import { TrialAndRepeatFeatureFlags } from "../constants/trial-and-repeat-feature-flags";
import { type TrialAndRepeatTableRequestDto } from "../models/TrialAndRepeatTableRequestDto";
import { useLazyGetReportDataTableQuery } from "../services/trial-and-repeat-data-table-api-slice";
import { useLazyDownloadTableQuery } from "../services/trial-and-repeat-data-table-export-api-slice";
import {
  onTopDrawerTableResponseReceived,
  selectActiveTab,
  selectFocalItem,
  selectLocalParameters,
  selectLocalParametersInitialised,
  selectLocalSelections,
  selectPurchaseActivityActiveTab,
  selectReportId,
  selectSearchQuery,
  selectTransactionNumber,
  setTopDrawerActiveTab,
} from "../services/trial-and-repeat-slice";
import { getUserSelectionsForExport } from "../utils/getLocalSelections";
import { getTableRequestDto } from "../utils/getTableRequestDto";
import { TrialAndRepeatTopDrawerProductTable } from "./TrialAndRepeatTopDrawerProductTable";

export type TrialAndRepeatTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportTabItems: ReportTabItemType[];
  reportType: string;
};

export const TrialAndRepeatTopDrawer = ({
  eventTrackingService,
  renameReport,
  reportType,
  reportName,
  reportTabItems,
}: TrialAndRepeatTopDrawerProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[TrialAndRepeatFeatureFlags.ReportExport] ?? false;

  const activeTab = useSelector(selectActiveTab);
  const focalItem = useSelector(selectFocalItem);
  const breakdownType = useSelector(selectPurchaseActivityActiveTab);
  const localSelections = useSelector(selectLocalSelections);
  const searchQuery = useSelector(selectSearchQuery);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const transactionNumber = useSelector(selectTransactionNumber);
  const localParameters = useSelector(selectLocalParameters);
  const id = useSelector(selectReportId);

  const [getTableRows, { isFetching }] = useLazyGetReportDataTableQuery();

  const fetchData = useCallback(
    async (division: string, payload: TrialAndRepeatTableRequestDto) => {
      await getTableRows({
        division,
        reportId,
        breakdownType,
        payload,
      })
        .unwrap()
        .then((response) => {
          dispatch(onTopDrawerTableResponseReceived(response));
        })
        .catch((error) => {
          ddLog(
            "Error retrieving trial and repeat table data",
            {},
            "error",
            error
          );
          dispatch(
            onTopDrawerTableResponseReceived({
              pagination: {
                hasNextPage: false,
                hasPreviousPage: false,
                pageIndex: 0,
                pageSize: 0,
                totalCount: 0,
                totalPages: 0,
              },
              data: [],
              levelOfAnalysis: "",
            })
          );
        });
    },
    [dispatch, reportId, breakdownType, getTableRows]
  );

  useEffect(() => {
    if (
      reportId &&
      reportId === id &&
      divisionName &&
      localParametersInitialised
    ) {
      const payload = getTableRequestDto(
        activeTab ?? "",
        localSelections,
        0,
        searchQuery,
        transactionNumber
      );

      fetchData(divisionName, payload).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [
    reportId,
    activeTab,
    breakdownType,
    divisionName,
    searchQuery,
    localSelections,
    fetchData,
    localParametersInitialised,
    transactionNumber,
    id,
  ]);

  const handleTabClick = useCallback(
    (value: string) => {
      dispatch(setTopDrawerActiveTab(value));
    },
    [dispatch]
  );

  const [downloadTableTrigger] = useLazyDownloadTableQuery();
  const localParameterSelections = useMemo(
    () => getUserSelectionsForExport(localSelections, localParameters),
    [localSelections, localParameters]
  );

  const fileName = useMemo(
    () =>
      cleanFilename(
        `Trial_and_repeat_${localSelections.timePeriodLength}_${localSelections.LocationHierarchy.name}`
      ),
    [localSelections]
  );

  const exportToCsvCallback = useCallback(async () => {
    const tableRequest = getTableRequestDto(
      activeTab ?? "",
      localSelections,
      0,
      searchQuery,
      transactionNumber
    );

    await downloadTableTrigger({
      division: divisionName,
      payload: {
        localParametersSummary: tableRequest.localSelectedValues,
        localParameterSelections,
        fileName,
        reportId: reportId ?? "",
        loaShortName: activeTab ?? "",
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    fileName,
    reportId,
    localSelections,
    activeTab,
    localParameterSelections,
    searchQuery,
    transactionNumber,
  ]);

  return (
    <ReportTopDrawer
      controls={[
        <DataTableOptions
          disabled={false}
          filename={fileName}
          invokeCSVDownload={exportToCsvCallback}
          isFeatureEnabled={isExportEnabled}
          key={reportId}
        />,
      ]}
      items={
        focalItem
          ? [
              {
                code: focalItem.shortName,
                displayName: focalItem.name,
                type: HierarchyItemType.Hierarchy,
              },
            ]
          : []
      }
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportTabs activeTab={activeTab ?? ""} onClickTabItem={handleTabClick}>
          {reportTabItems.map((item) => (
            <ReportTabItem
              key={item.value}
              label={item.label}
              value={item.value}
            >
              <TrialAndRepeatTopDrawerProductTable
                eventTrackingService={eventTrackingService}
                isQuerySuccess={!isFetching}
              />
            </ReportTabItem>
          ))}
        </ReportTabs>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default TrialAndRepeatTopDrawer;
