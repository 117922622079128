import { type Row } from "@tanstack/react-table";

export const SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID = "name";
export const DEFAULT_SELECTED_ITEMS_ONLY_FILTER_VALUE = true;

export const selectedItemsOnlyFilter = <T>(
  row: Row<T>,
  columnId: string,
  filterValue: boolean
): boolean => {
  if (columnId === SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID) {
    return row.getIsSelected() === filterValue;
  }

  return false;
};
