import { type DragEndEvent, type DragStartEvent } from "@dnd-kit/core";
import { StructureDragAndDrop } from "../StructureDragAndDrop";
import { handleDragEnd, handleDragStart, handleRemove } from "../eventHandlers";
import { type Item } from "../models/Item";
import { type Zone } from "../models/Zone";
import { type DndWrapperProps } from "./DefaultDndWrapper";

type HierarchyStructureWrapperProps = DndWrapperProps & {
  activeItem?: Item;
  droppableZones: Zone[];
  items: Item[];
  setActiveItem: (newActiveItem?: Item) => void;
  setDroppableZones: (newDroppableZones: Zone[]) => void;
  setItems: (newItems: Item[]) => void;
};

export const HierarchyStructureWrapper = ({
  canDropAttribute,
  title,
  description,
  maxDisplayItems,
  findValidDropIndex,
  items,
  droppableZones,
  activeItem,
  setItems,
  setDroppableZones,
  setActiveItem,
}: HierarchyStructureWrapperProps) => (
  <StructureDragAndDrop
    activeItem={activeItem}
    description={description}
    items={items}
    maxDisplayItems={maxDisplayItems}
    onDragEnd={(event: DragEndEvent) => {
      handleDragEnd(
        event.over?.data.current,
        event.over?.id,
        activeItem,
        setActiveItem,
        droppableZones,
        setDroppableZones,
        items,
        setItems
      );
    }}
    onDragStart={(event: DragStartEvent) => {
      handleDragStart(
        event.active.data.current,
        items,
        setItems,
        setActiveItem,
        droppableZones,
        setDroppableZones,
        canDropAttribute,
        findValidDropIndex
      );
    }}
    onRemove={(item: Item, zone: Zone) => {
      handleRemove(
        item,
        zone,
        droppableZones,
        setDroppableZones,
        items,
        setItems
      );
    }}
    title={title}
    zones={droppableZones}
  />
);
