import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const customerCrossShopApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/customer-cross-shop`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "customerCrossShopApi",
  tagTypes: ["customerCrossShop"],
});
