import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { ListSelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getSegmentationParameterState } from "../../parameters/segmentation/SegmentationParameterState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelSegmentationProps = {
  defaultText?: string;
  parameterDto: ParameterDto;
};

export const SummaryPanelSegmentation = ({
  defaultText,
  parameterDto,
}: SummaryPanelSegmentationProps) => {
  let selectedLabels = useAppSelector(
    getSegmentationParameterState(parameterDto.id)
  )
    .selections.slice()
    .map((selection) => selection.name);

  if (selectedLabels.length === 0 && defaultText) {
    selectedLabels = [defaultText];
  }

  return (
    <ListSelectionSummary
      label={parameterDto.name}
      selections={selectedLabels}
    />
  );
};
