import {
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type ColumnDef,
  type CellContext,
  type ColumnResizeMode,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EMPTY_NODE_NUMBER } from "../../common/constants";
import { type RootState } from "../../store";
import {
  type GainsAndLossHierarchyProductData,
  type GainsAndLossHierarchyProductLoadMoreRow,
} from "../models/GainsAndLossHierarchyProductData";
import { type GainsAndLossReportTableRequestDto } from "../models/GainsAndLossReportTableRequestDto";
import { useLazyGetReportDataTableQuery } from "../services/gains-and-loss-data-table-api-slice";
import {
  setFocalItem,
  onLoadMoreSuccess,
  onSearchQueryChange,
  selectRowSelection,
  selectLocalSelections,
  selectTopDrawerActiveTab,
} from "../services/gains-and-loss-slice";
import { getTableRequestDto } from "../utils/getTableData";
import styles from "./GainsAndLossTopDrawerProductTable.module.css";

const columnResizeMode: ColumnResizeMode = "onChange";

const isLoadMore = (
  panel: GainsAndLossHierarchyProductRow
): panel is GainsAndLossHierarchyProductLoadMoreRow =>
  Object.prototype.hasOwnProperty.call(panel, "loadMoreParentRow");

const getRowId = (row: GainsAndLossHierarchyProductRow) => {
  if (isLoadMore(row)) {
    return `${row.loadMoreParentRow.hierarchyItem.itemCode}-load-more`;
  }

  return row.hierarchyItem.itemCode;
};

export type GainsAndLossTopDrawerProductTableProps = {
  eventTrackingService?: Function;
  isQuerySuccess: boolean;
};

type GainsAndLossHierarchyProductRow =
  | GainsAndLossHierarchyProductData
  | GainsAndLossHierarchyProductLoadMoreRow;

export const GainsAndLossTopDrawerProductTable = ({
  isQuerySuccess,
  eventTrackingService,
}: GainsAndLossTopDrawerProductTableProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();
  const formatter = useFormatter();

  const activeTab = useSelector(selectTopDrawerActiveTab);
  const rowSelection = useSelector(selectRowSelection);
  const localSelections = useSelector(selectLocalSelections);

  const { data, measures, page, searchQuery, topDrawerRowCount } = useSelector(
    (state: RootState) => ({
      data: state.gainsAndLoss.topDrawerProductTableData.data,
      measures: state.gainsAndLoss.topDrawerProductTableData.measures,
      page: state.gainsAndLoss.tablePage,
      searchQuery: state.gainsAndLoss.searchQuery,
      topDrawerRowCount: state.gainsAndLoss.topDrawerRowCount,
    })
  );

  const [getReportDataTable] = useLazyGetReportDataTableQuery();

  const fetchData = useCallback(
    async (division: string, payload: GainsAndLossReportTableRequestDto) => {
      const response = await getReportDataTable({ division, payload });
      if (response.data) {
        dispatch(onLoadMoreSuccess(response.data));
      }
    },
    [getReportDataTable, dispatch]
  );

  const onMoreClickedHandler = useCallback(async () => {
    if (localSelections.location.nodeNumber === EMPTY_NODE_NUMBER) {
      return;
    }

    const payload = getTableRequestDto(
      activeTab ?? "",
      localSelections,
      page,
      reportId ?? "",
      searchQuery
    );

    await fetchData(divisionName, payload);
  }, [
    activeTab,
    localSelections,
    page,
    reportId,
    searchQuery,
    fetchData,
    divisionName,
  ]);

  const onSearchChange = useCallback(
    (value: string) => dispatch(onSearchQueryChange(value)),
    [dispatch]
  );

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return topDrawerRowCount;
  }, [topDrawerRowCount, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchbox}>
        <SearchBox
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={matchesFound}
          searchQuery={searchQuery}
        />
      </div>
    ),
    [onSearchChange, matchesFound, searchQuery]
  );

  const displayEmptySearch: boolean =
    isQuerySuccess && searchQuery.length > 0 && data.length === 0;

  const createNameCell = useCallback(
    ({ row }: CellContext<GainsAndLossHierarchyProductData, unknown>) => (
      <ExpandableNameCell
        canExpand={false}
        depth={0}
        handleToggleExpanded={row.getToggleExpandedHandler()}
        handleToggleSelected={row.getToggleSelectedHandler()}
        isCheckboxHidden
        isExpanded={false}
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        onClick={() => {
          if (!row.getIsSelected()) {
            dispatch(
              setFocalItem({
                itemCode: row.original.hierarchyItem.itemCode,
                name: row.original.hierarchyItem.name,
                shortName: row.original.hierarchyItem.shortName,
              })
            );
            eventTrackingService?.(
              [
                TrackingComponent.MyReports,
                TrackingComponent.Report,
                TrackingComponent.FocalItemTableRow,
              ],
              TrackingEvent.Selected,
              {
                selection: `${row.original.hierarchyItem.shortName}:${row.original.hierarchyItem.name}`,
              }
            );
          }
        }}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={row.original.hierarchyItem.name}
      />
    ),
    [dispatch, eventTrackingService]
  );

  const measureColumnsDefs = useMemo(
    (): Array<ColumnDef<GainsAndLossHierarchyProductData>> =>
      measures.map((measure, index) => ({
        accessorKey: measure.label,
        cell: ({ row }) =>
          ValueCell({
            formatter: formatter(measure.format),
            value: row.original.measureValues[index],
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        header: measure.label,
        id: index + measure.label,
      })),
    [formatter, measures]
  );

  // @ts-expect-error typecheck issue with memoizedSearchBox as header
  const columnDefs: Array<ColumnDef<GainsAndLossHierarchyProductData>> =
    useMemo(
      () => [
        {
          accessorKey: "name",
          cell: createNameCell,
          enableHiding: false,
          enableResizing: true,
          enableSorting: false,
          header: memoizedSearchBox,
        },
        ...measureColumnsDefs,
      ],
      [createNameCell, memoizedSearchBox, measureColumnsDefs]
    );

  return (
    <ReportHierarchyTableWrapper
      isSuccess={isQuerySuccess}
      showNoDataAvailable={
        localSelections.location.nodeNumber === EMPTY_NODE_NUMBER
      }
    >
      <VirtuosoTableComponent
        columnResizeMode={columnResizeMode}
        columns={columnDefs}
        data={data}
        enableSorting={false}
        getRowId={getRowId}
        handleMoreClicked={onMoreClickedHandler}
        moreText="Load more..."
        pinFirstColumn
        rowExpandedState={{ "0": true }}
        rowSelectionState={rowSelection}
      />
      {displayEmptySearch && (
        <div className={styles.emptySearch}>
          <EmptySearch />
        </div>
      )}
    </ReportHierarchyTableWrapper>
  );
};
