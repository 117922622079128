import classNames from "classnames";
import { InformationModal } from "../InformationModal";
import { SegmentationInformation } from "./SegmentationInformation";
import styles from "./SegmentationModal.module.css";

export type SegmentationModalProps = {
  className?: string;
  learnMore?: string;
  modalVisbilityState?: {
    isShown: boolean;
    setIsShown: (shown: boolean) => void;
  };
  segmentations: string[];
};

export const SegmentationModal = ({
  className,
  learnMore,
  modalVisbilityState,
  segmentations,
}: SegmentationModalProps) => {
  const filteredSegmentations = SegmentationInformation.expanderContent.filter(
    (accordion) => segmentations.includes(accordion.name)
  );

  const learnMoreLink =
    "https://portal.quantium.com/s/article/Q-Checkout-Customer-Segmentations";

  return (
    <InformationModal
      className={classNames(className, styles.segmentationModalIcon)}
      descriptionWithExpanders={filteredSegmentations}
      detailedDescription={SegmentationInformation.description}
      header="Standard customer segmentation"
      label="Standard customer segmentation"
      learnMore={learnMore ?? learnMoreLink}
      modalVisbilityState={modalVisbilityState}
    />
  );
};
