import {
  type ReportParametersDto,
  ParameterId,
  ddLog,
  ReportType,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  useDivision,
  useFlags,
  useLabelFormatter,
} from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { ChartDataWrapper } from "../../../common/components/ChartDataWrapper";
import { EMPTY_NODE_NUMBER } from "../../../common/constants";
import { type RootState } from "../../../store";
import styles from "../../GainsAndLossReport.module.css";
import { GainAndLossFeatureFlags } from "../../constants/gain-and-loss-feature-flags";
import { type GainsAndLossUniverseSummaryRequestDto } from "../../models/GainsAndLossUniverseSummaryRequestDto";
import { type GainsAndLossUniverseSummary } from "../../models/GainsAndLossUniverseSummaryResponseDto";
import {
  onUniverseSummaryRequest,
  onUniverseSummaryResponseReceived,
  selectChannel,
  selectFocalItem,
  selectLocation,
  selectMetricSet,
  selectReportId,
  selectSegment,
  selectSegmentation,
  selectShowUniverseSummaryChartDataLabels,
  selectTime,
  selectTimePeriodLength,
  toggleUniverseSummaryChartDataLabels,
} from "../../services/gains-and-loss-slice";
import { useLazyGetGainsAndLossUniverseSummaryQuery } from "../../services/gains-and-loss-universe-summary-api-slice";
import { getParameterSummaryValueStrings } from "../../utils/getParameterSummaryValueStrings";
import { UniverseSummary } from "./UniverseSummary";
import { csvMeasuresTransformation } from "./csvTransformation";

export const GainsAndLossUniverseSummaryReportlet = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();
  const { id } = useParams();
  const chartContainerRef = useRef<HTMLElement>();

  const reportId = useSelector(selectReportId);
  const focalItem = useSelector(selectFocalItem);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const metricSet = useSelector(selectMetricSet);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const showUniverseSummaryChartDataLabels = useSelector(
    selectShowUniverseSummaryChartDataLabels
  );

  const { isDataLoading, localParameters, universeSummaryData } = useSelector(
    (state: RootState) => ({
      isDataLoading: state.gainsAndLoss.isUniverseSummaryLoading,
      localParameters: state.gainsAndLoss.localParameters,
      universeSummaryData: state.gainsAndLoss.universeSummaryData,
    })
  );

  const flags = useFlags();
  const isExportEnabled = flags[GainAndLossFeatureFlags.ReportExport] ?? false;

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Summary_Of_Report_Universe_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const parameterSummary = useMemo(
    () =>
      getParameterSummaryValueStrings(
        channel,
        location,
        metricSet,
        segment,
        segmentation,
        time,
        focalItem
      ),
    [channel, location, segment, segmentation, time, focalItem, metricSet]
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [getReportletData, { isError, isFetching, isSuccess, data }] =
    useLazyGetGainsAndLossUniverseSummaryQuery();

  const fetchData = useCallback(
    async (
      division: string,
      payload: GainsAndLossUniverseSummaryRequestDto
    ) => {
      await getReportletData({ division, payload });
    },
    [getReportletData]
  );

  const attemptFetch = useCallback(() => {
    if (
      id &&
      reportId === id &&
      localParameters.length > 0 &&
      location.nodeNumber !== EMPTY_NODE_NUMBER
    ) {
      dispatch(onUniverseSummaryRequest(true));

      const payload = {
        localSelectedValues: [
          { id: ParameterId.Channel, values: [channel.value as string] },
          {
            id: ParameterId.LocationHierarchy,
            values: [location.nodeNumber.toString()],
          },
          {
            id: ParameterId.Segmentation,
            values: [segmentation.value as string, segment.value as string],
          },
        ],
        reportId: id,
      };

      fetchData(divisionName, payload).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [
    channel.value,
    dispatch,
    divisionName,
    fetchData,
    id,
    localParameters,
    location,
    reportId,
    segmentation,
    segment,
  ]);

  useEffect(() => {
    attemptFetch();
  }, [attemptFetch]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(onUniverseSummaryResponseReceived({ data, metricSet }));
    } else if (isError) {
      dispatch(
        onUniverseSummaryResponseReceived({ data: undefined, metricSet })
      );
    }
  }, [data, dispatch, isError, isFetching, isSuccess, metricSet]);

  const metricHeader = useLabelFormatter(
    universeSummaryData.data.measureName,
    MetricTypes[universeSummaryData.data.format as keyof typeof MetricTypes]
  );
  const chartCsvTransformationCallback = useCallback(
    () =>
      csvMeasuresTransformation(
        universeSummaryData as GainsAndLossUniverseSummary,
        metricHeader
      ),
    [universeSummaryData, metricHeader]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showUniverseSummaryChartDataLabels,
      value: "",
    },
  ];
  const [options, setOptions] = useState<HighchartsReactProps>();
  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.gainsAndLoss.reportName,
  }));

  return (
    <Accordion
      accordionState={{ isAccordionOpen: isOpen, toggleOpen: setIsOpen }}
      className={styles.reportlet}
      subtitle="View an overall summary of customer movement across your report universe."
      title="Summary of report universe"
    >
      <ErrorBoundary>
        <ChartDataWrapper
          isError={isError}
          isSuccess={isSuccess && !isDataLoading}
          retry={attemptFetch}
          showNoDataAvailable={
            !data || location.nodeNumber === EMPTY_NODE_NUMBER
          }
        >
          <div className={styles.chartOptionsContainer}>
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                options
                  ? {
                      chartOptions: options,
                      chartTitle: `Summary of report universe - ${reportName}`,
                      reportIcon: <ReportIcon type={ReportType.GainsAndLoss} />,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
              toggleDataLabels={() => {
                dispatch(toggleUniverseSummaryChartDataLabels());
              }}
            />
          </div>
          <ChartFooterWrapper
            height="400px"
            parameters={parameterSummary}
            ref={chartContainerRef}
          >
            <UniverseSummary onOptionsChanged={setOptions} />
          </ChartFooterWrapper>
        </ChartDataWrapper>
      </ErrorBoundary>
    </Accordion>
  );
};
