export enum TestId {
  Checkbox = "checkbox",
  ColumnGroup = "column-group",
  ExpandIcon = "expand-icon",
  FolderIcon = "folder-icon",
  IconGroup = "icon-group",
  ItemName = "item-name",
  ItemSuffix = "item-suffix",
  ResizeIcon = "separator",
  SelectedHighlight = "selected-highlight",
  SortIcon = "sort-icon",
}
