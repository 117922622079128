import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { formatMetricValue } from "../../../common/utils/export-parameter-summary-utils";
import {
  type RepertoireReportletItem,
  type TransformedRepertoireReportletResponse,
} from "../../services/repertoire-reportlet-api-slice";
import { VIEW_AS_SHARE } from "../../utils/constants";
import { getExportColumnHeader } from "../../utils/export-utils";

export const csvTransformation = (
  repertoireItems: string[],
  response: TransformedRepertoireReportletResponse,
  metricLabel: string,
  viewAs: string,
  currencySymbol: string
) => {
  const columnHeaders = ["Focal item(s)"];
  const csvData = [columnHeaders];

  const headers = response.focalItemColumns.map(
    (item) => response.crossProductData[item]?.name ?? item
  );
  columnHeaders.push(
    ...headers.map(
      getExportColumnHeader(currencySymbol, response.metricFormat, metricLabel)
    )
  );

  for (const item of repertoireItems) {
    const data =
      response.crossProductData[item] ?? ({} as RepertoireReportletItem);
    const row = [
      data.name,
      ...data.values.map((metric) => formatMetricValue(metric)),
    ];
    csvData.push(row);
  }

  if (response.otherValues) {
    const row = [
      "Others",
      ...response.otherValues.data.map((metric) => formatMetricValue(metric)),
    ];
    csvData.push(row);
  }

  if (viewAs === VIEW_AS_SHARE) {
    const row = [
      "Total",
      ...response.totalValues.data.map((metric) => {
        if (response.totalValues.format === MetricTypes.Currency) {
          return `${currencySymbol}${formatMetricValue(metric)}`;
        }

        return formatMetricValue(metric);
      }),
    ];
    csvData.push(row);
  }

  return csvData;
};
