import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type Panel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { SidePanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isTimePeriodParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isLocationSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildTimePeriodPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildLocationHierarchyPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import {
  onLocationChange,
  onSegmentationChange,
  selectLocalParameters,
  selectLocalSelections,
} from "../services/customer-loyalty-slice";

export const CustomerLoyaltySidePanel = () => {
  const dispatch = useDispatch();

  const localSelections = useSelector(selectLocalSelections);
  const localParameters = useSelector(selectLocalParameters);

  const generatedPanels = useCallback((): Panel[] => {
    const panels: Panel[] = [];

    for (const localParameter of localParameters) {
      if (isTimePeriodParameter(localParameter)) {
        panels.push(buildTimePeriodPanel(localParameter));
      } else if (
        localParameter.id === LocalParameters.Segmentation &&
        isSegmentationParameter(localParameter)
      ) {
        const onSegmentSelection = (value: SegmentOption) => {
          dispatch(onSegmentationChange(value));
        };

        panels.push(
          buildSegmentationPanel(localParameter, onSegmentSelection, [
            localSelections.segmentation.value as string,
            localSelections.segment.value as string,
          ])
        );
      } else if (
        localParameter.id === LocalParameters.Segmentation &&
        isPlainTextContentParameter(localParameter)
      ) {
        panels.push(buildPlainTextContentPanel(localParameter));
      } else if (
        localParameter.id === LocalParameters.LocationHierarchy &&
        isLocationSelectionParameter(localParameter)
      ) {
        panels.push(
          buildLocationHierarchyPanel(
            localParameter,
            (selection: LocalHierarchyNodeSelection) => {
              dispatch(onLocationChange(selection));
            },
            localSelections.locationHierarchy
          )
        );
      }
    }

    return panels;
  }, [dispatch, localParameters, localSelections]);

  return <SidePanel panels={generatedPanels()} />;
};
