export type SegmentationSelection = {
  segment: string;
  segmentation: string;
};

export type LocalHierarchySelection = {
  // e.g. SM
  itemCode: string;
  // e.g. 1
  shortName: string;
};

export enum LoadingState {
  Loaded = "Loaded",
  Loading = "Loading",
  Uninitialised = "Uninitialised",
}
