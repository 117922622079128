type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const TrialAndRepeatIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Trial and repeat weekly key measures report"}</title>
      <path
        d="M16.6675 2.25C16.6675 2.11193 16.7794 2 16.9175 2H21.75C21.8881 2 22 2.11193 22 2.25V22H16.6675V2.25Z"
        fill={colour1}
      />
      <path d="M22 12.5H16.6675L16.6675 7L22 7V12.5Z" fill={colour2} />
      <path d="M16.6675 12.5H22L22 22H16.6675L16.6675 12.5Z" fill={colour3} />
      <path
        d="M9.33252 9.75C9.33252 9.61193 9.44445 9.5 9.58252 9.5H14.4175C14.5556 9.5 14.6675 9.61193 14.6675 9.75V22H9.33252V9.75Z"
        fill={colour1}
      />
      <path
        d="M14.6675 16.25H9.33249L9.33249 12.5H14.6675L14.6675 16.25Z"
        fill={colour2}
      />
      <path
        d="M9.33249 16.25H14.6675L14.6675 22H9.33252L9.33249 16.25Z"
        fill={colour3}
      />
      <path
        d="M2 16C2 15.8619 2.11193 15.75 2.25 15.75H7.0825C7.22057 15.75 7.3325 15.8619 7.3325 16V22H2V16Z"
        fill={colour1}
      />
      <path d="M7.3325 19.25H2L2 17H7.3325L7.3325 19.25Z" fill={colour2} />
      <path d="M2 19.25H7.3325V22H2L2 19.25Z" fill={colour3} />
    </svg>
  );
};
