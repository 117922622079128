import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { HighchartsCustomTooltip } from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import { type TooltipFormatterContextObject } from "highcharts";

export type HighchartsPlTooltipProps = {
  benchmark?: number;
  chartDataFormatter?: FormatterFunction;
  hasSentiment?: boolean;
  percentFormatter?: FormatterFunction;
  // will be used over chartDataFormatter if provided
  // provides more flexibility to choose which formatter is used per point
  pointFormatter?: (pt: TooltipFormatterContextObject) => FormatterFunction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  seriesInfo: any;
  ttData: TooltipFormatterContextObject;
  yAxisTitle: string;
};

export const HighchartsPlTooltip = ({
  ttData,
  yAxisTitle,
  pointFormatter,
  chartDataFormatter,
  percentFormatter,
  hasSentiment,
  benchmark,
  seriesInfo,
}: HighchartsPlTooltipProps) => {
  const multipleSeries = ttData.points && ttData.points.length > 1;
  const unplottedSeries = [];
  // replacing the y value with the high value for max avg column stack (CO3-4827)
  if (
    ttData.points !== undefined &&
    ttData.points.length > 0 &&
    ttData.points[0].point.high !== undefined
  ) {
    ttData.points[0].y = ttData.points[0].point.high;
  }

  for (const series of seriesInfo) {
    if (!ttData.points?.find((point) => point.series.name === series.name)) {
      unplottedSeries.push(series);
    }
  }

  return (
    <HighchartsCustomTooltip.Layout>
      <HighchartsCustomTooltip.Title title={yAxisTitle} />
      <HighchartsCustomTooltip.Series
        benchmark={benchmark}
        chartDataFormatter={chartDataFormatter}
        hasSentiment={hasSentiment}
        percentFormatter={percentFormatter}
        pointFormatter={pointFormatter}
        ttData={ttData}
        useHighchartsDefaultIcon={multipleSeries}
        useSeriesNameForLabels
      />
      {unplottedSeries.length > 0 && (
        <HighchartsCustomTooltip.UnPlottedSeries
          unplottedSeries={unplottedSeries}
        />
      )}
    </HighchartsCustomTooltip.Layout>
  );
};
