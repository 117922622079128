import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Group,
  GroupGutters,
  GroupRowspace,
  Item,
  Button,
  ButtonVariant,
  Dropdown,
  MenuItemButton,
  Text,
  ItemValign,
  ItemHalign,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { toPng } from "html-to-image";
import {
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { createPortal } from "react-dom";
import { CameraIcon } from "../../assets/icons/CameraIcon";
import { DataLabelsIcon } from "../../assets/icons/DataLabelsIcon";
import { DownloadIcon } from "../../assets/icons/DownloadIcon";
import { downloadFile, toCSV } from "../../export/export-functions";
import { HighchartsDownloadWizard } from "../highcharts-download/HighchartsDownloadWizard";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import styles from "./ChartOptions.module.scss";

export type DataLabelsOptions = {
  isSelected: boolean;
  label?: string;
  value: string;
};

export type ChartOptionsProps = {
  dataLabelsOptions?: DataLabelsOptions[];
  disabled?: boolean;
  downloadWizardOptions?: {
    chartOptions: HighchartsReactProps;
    chartTitle: string;
    customLegend?: ReactNode;
    reportIcon: ReactNode;
    reportTitle: string;
  };
  filename: string;
  getAllCSVData?: (() => string[][]) | (() => void);
  getCSVData?: (() => string[][]) | (() => void);
  getElementToExport: () => HTMLElement | undefined;
  isDownloadPngEnabled?: boolean;
  isFeatureEnabled?: boolean;
  isScreenShotEnabled?: boolean;
  localParameters?: Array<{
    name: string;
    value: string;
  }>;
  reportParameters?: ReportParametersDto;
  toggleDataLabels?: (value: string) => void;
};

type HighchartsDownloadWizardPortalProps = PropsWithChildren & {
  destination?: HTMLElement;
};
const HighchartsDownloadWizardPortal = ({
  children,
  destination,
}: HighchartsDownloadWizardPortalProps) => {
  if (destination) {
    return createPortal(children, destination);
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment -- needed
  return <></>;
};

const defaultDataLabelsOptions: DataLabelsOptions[] = [];
const defaultNoop = () => {};
export const ChartOptions = ({
  dataLabelsOptions = defaultDataLabelsOptions,
  filename,
  getAllCSVData,
  getCSVData,
  getElementToExport,
  toggleDataLabels = defaultNoop,
  disabled,
  localParameters,
  reportParameters,
  isDownloadPngEnabled = true,
  isFeatureEnabled,
  isScreenShotEnabled,
  downloadWizardOptions,
}: ChartOptionsProps) => {
  const copyToClipboardCallback = useCallback(() => {
    // Untested example code:
    // const elementToExport = getElementToExport();
    // toBlob(elementToExport, { cacheBust: true, })
    //   .then((blob) => {
    //       navigator.clipboard.write([
    //         new ClipboardItem({
    //             'image/png': blob
    //         })
    //     ]);
    //   })
    //   .catch((err) => {
    //     ddLog("ERROR", {}, "error", err)
    //   });
  }, []);
  const downloadAsPngCallback = useCallback(async () => {
    const elementToExport = getElementToExport();
    if (elementToExport) {
      try {
        // `margin: 0` to remove the margin allocated in ChartFooterWrapper.
        const dataUrl = await toPng(elementToExport, {
          cacheBust: true,
          style: { margin: "0" },
        });
        downloadFile(dataUrl, `${filename}.png`);
      } catch (error) {
        ddLog("ERROR", {}, "error", error as Error);
      }
    } else {
      ddLog("elementToExport not set correctly", {}, "error", undefined);
    }
  }, [filename, getElementToExport]);
  const downloadAsCsvCallback = useCallback(() => {
    try {
      if (getCSVData) {
        const data = getCSVData();
        if (Array.isArray(data)) {
          const csvContent = toCSV(reportParameters, localParameters, data);
          const encodedUri = encodeURI(csvContent);
          downloadFile(encodedUri, `${filename}.csv`);
        }
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  }, [reportParameters, localParameters, filename, getCSVData]);
  const downloadAllAsCsvCallback = useCallback(() => {
    try {
      if (getAllCSVData) {
        const data = getAllCSVData();
        if (Array.isArray(data)) {
          const csvContent = toCSV(reportParameters, localParameters, data);
          const encodedUri = encodeURI(csvContent);
          downloadFile(encodedUri, `${filename}.csv`);
        }
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  }, [reportParameters, localParameters, filename, getAllCSVData]);

  // Download wizard
  const flags = useFlags();
  const downloadWizardEnabled =
    flags["CO3-5871-Chart-Download-Wizard"] && downloadWizardOptions;
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const buttonId = useMemo(() => crypto.randomUUID(), []);
  const [portalDestination, setPortalDestination] = useState<HTMLElement>();

  useEffect(() => {
    const container = document.createElement("div");
    container.id = "highcharts-download-wizard-" + buttonId;
    document.body.appendChild(container);
    setPortalDestination(container);

    return () => {
      document
        .querySelector("highcharts-download-wizard-" + buttonId)
        ?.remove();
    };
  }, [buttonId]);

  return (
    <>
      {isFeatureEnabled && (
        <Group
          gutters={GroupGutters.None}
          rowspace={GroupRowspace.None}
          wrap={false}
        >
          {dataLabelsOptions.length > 1 && (
            <Item halign={ItemHalign.Right} valign={ItemValign.Bottom}>
              <Dropdown
                trigger={
                  <Button
                    className={styles.iconButton}
                    disabled={disabled}
                    variant={ButtonVariant.Stealth}
                  >
                    <span
                      className={classNames(
                        styles.buttonContent,
                        styles.centerAlignFix
                      )}
                      title="Toggle data labels"
                    >
                      <DataLabelsIcon />
                    </span>
                  </Button>
                }
              >
                {dataLabelsOptions.map((option) => (
                  <MenuItemButton
                    disabled={disabled}
                    key={option.label}
                    onClick={() => toggleDataLabels(option.value)}
                    selected={option.isSelected}
                    text={<Text>{option.label ?? ""}</Text>}
                  />
                ))}
              </Dropdown>
            </Item>
          )}
          {dataLabelsOptions.length <= 1 &&
            toggleDataLabels !== defaultNoop && (
              <Item halign={ItemHalign.Right} valign={ItemValign.Bottom}>
                <Button
                  className={styles.iconButton}
                  disabled={disabled}
                  onClick={() => toggleDataLabels(dataLabelsOptions[0]?.value)}
                  variant={ButtonVariant.Stealth}
                >
                  <span
                    className={styles.buttonContent}
                    title="Toggle data labels"
                  >
                    <DataLabelsIcon />
                  </span>
                </Button>
              </Item>
            )}
          {isScreenShotEnabled && (
            <Item halign={ItemHalign.Right} valign={ItemValign.Bottom}>
              <Button
                className={styles.iconButton}
                disabled={disabled}
                onClick={copyToClipboardCallback}
                variant={ButtonVariant.Stealth}
              >
                <span
                  className={styles.buttonContent}
                  title="Copy chart to clipboard"
                >
                  <CameraIcon />
                </span>
              </Button>
            </Item>
          )}
          {/* This check de-renders the button when the wizard is open.
              This is due to a bug where the button below never releases focus preventing <input>s from working*/}
          {!isWizardOpen && (
            <Item halign={ItemHalign.Right} valign={ItemValign.Bottom}>
              <Dropdown
                trigger={
                  <Button
                    className={styles.iconButton}
                    disabled={disabled}
                    variant={ButtonVariant.Stealth}
                  >
                    <span
                      className={classNames(
                        styles.buttonContent,
                        styles.centerAlignFix
                      )}
                      title="Download"
                    >
                      <DownloadIcon />
                    </span>
                  </Button>
                }
              >
                <>
                  {isDownloadPngEnabled && (
                    <MenuItemButton
                      disabled={disabled}
                      onClick={
                        downloadWizardEnabled
                          ? () => setIsWizardOpen(true)
                          : downloadAsPngCallback
                      }
                      text={<Text>Download as PNG</Text>}
                    />
                  )}
                </>
                <>
                  {getCSVData && (
                    <MenuItemButton
                      disabled={disabled}
                      onClick={downloadAsCsvCallback}
                      text={<Text>Download as CSV</Text>}
                    />
                  )}
                </>
                <>
                  {getAllCSVData && (
                    <MenuItemButton
                      disabled={disabled}
                      onClick={downloadAllAsCsvCallback}
                      text={<Text>Download all as CSV</Text>}
                    />
                  )}
                </>
              </Dropdown>
            </Item>
          )}
        </Group>
      )}
      {isWizardOpen && downloadWizardOptions && (
        <HighchartsDownloadWizardPortal destination={portalDestination}>
          <div className={styles.highchartsDownloadWizardContainer}>
            <HighchartsDownloadWizard
              chartOptions={downloadWizardOptions.chartOptions}
              chartTitle={downloadWizardOptions.chartTitle}
              customLegend={downloadWizardOptions.customLegend}
              fileName={filename}
              onCloseRequested={() => setIsWizardOpen(false)}
              parameters={localParameters}
              reportIcon={downloadWizardOptions.reportIcon}
              reportTitle={downloadWizardOptions.reportTitle}
            />
          </div>
        </HighchartsDownloadWizardPortal>
      )}
    </>
  );
};
