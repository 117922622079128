import { useState, useEffect } from "react";
import { type TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { store, type AppDispatch, type RootState } from "./store";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Shamelessly stolen from https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
const observeStore = <T>(
  select: (state: RootState) => T,
  onChange: (newValue: T) => void
) => {
  let currentState: T;

  const handleChange = () => {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  };

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};

export const useFastReportingSelector =
  /**
   * This is a helper hook, that acts like useSelector(), but forces the store used to be the one for this package.
   * This should only be used to help create other custom hooks that can be consumed from other packages
   *
   * @template T
   * @param {(state: RootState) => T} selector
   * @returns {T | undefined}
   */
  <T>(selector: (state: RootState) => T) => {
    const [selection, setSelection] = useState<T | undefined>();

    useEffect(() => {
      const stopObserving = observeStore(selector, (value) => {
        setSelection(value);
      });
      return stopObserving;
    }, []);

    return selection;
  };
