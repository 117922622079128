import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  GenericTrackingProperties,
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useFlags,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import {
  type CellContext,
  type ColumnResizeMode,
  createColumnHelper,
  type ColumnDef,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { type AttributeItem } from "components-ui/src/focal-item/FocalItem";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { ColumnType } from "components-ui/src/tables/virtuoso-table/VirtuosoTableHeaderElement";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BasketAffinitiesFeatureFlags } from "../constants/basket-affinities-feature-flags";
import {
  type TableMetadata,
  type TableRow,
} from "../models/basket-affinities-data-table-models";
import { useGetTableQuery } from "../services/basket-affinities-data-table-api-slice";
import {
  onFocalItemChange,
  onSearchQueryChange,
  selectFocalItem,
  selectLocation,
  selectChannel,
  selectSegmentation,
  selectLocalParametersInitialised,
  selectReportName,
  selectSegment,
  selectFocalItemTableMetadata,
  selectFocalItemTableRows,
  selectFocalItemTableRowsSearch,
  selectFocalItemRowTableRowSelectionState,
  selectTimePeriodLength,
  selectSearchQuery,
  selectIsEmptyReport,
  selectLocalParametersSelection,
} from "../services/basket-affinities-slice";
import styles from "./BasketAffinitiesTopDrawer.module.css";
import { csvTransformation } from "./topDrawerCsvTransformation";

export const BasketAffinitiesTopDrawer = ({
  renameReport,
  reportParameters,
}: {
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportParameters?: ReportParametersDto;
}) => {
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketAffinitiesFeatureFlags.ReportExport] ?? false;

  const reportName = useSelector(selectReportName);
  const isEmptyReport = useSelector(selectIsEmptyReport);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const localParametersSelection = useSelector(selectLocalParametersSelection);
  const focalItem = useSelector(selectFocalItem);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const searchQuery = useSelector(selectSearchQuery);
  const focalItemTableRowSelectionState = useSelector(
    selectFocalItemRowTableRowSelectionState
  );
  const tableMetadata = useSelector(selectFocalItemTableMetadata);
  const tableRows = useSelector(selectFocalItemTableRows);
  const tableRowsSearch = useSelector(selectFocalItemTableRowsSearch);
  const timePeriodLength = useSelector(selectTimePeriodLength);

  const searchBoxRef = useRef<HTMLDivElement>(null);

  const eventTrackingService = useEventTrackingServiceContext();

  const columnResizeMode: ColumnResizeMode = "onChange";

  const columnHelper = createColumnHelper<TableRow>();

  // These are the extra columns in the table
  const measuresColumnDefs = useCallback(
    (metadata?: TableMetadata): Array<ColumnDef<TableRow, number | string>> => {
      if (!metadata) {
        return [];
      }

      return metadata.metricMetadata.map((x, index) =>
        columnHelper.accessor(
          (row: TableRow) => row.metrics[index].metricValue ?? String(""),
          {
            cell: ({ getValue }: CellContext<TableRow, number | string>) =>
              ValueCell({
                formatter: formatter(x.format),
                value: getValue(),
              }),
            // virtuoso table headers work best with plain text.
            // ellipsis wrapper will break if text is wrapped in a separate div.
            header: x.metricLabel,
            id: x.metricLabel,
            meta: {
              columnType: ColumnType.Numeric,
            },
          }
        )
      );
    },
    [columnHelper, formatter]
  );

  const focalItemSummary: AttributeItem[] = useMemo(
    () =>
      focalItem
        ? [
            {
              code: focalItem.shortName,
              displayName: focalItem.name,
              type: HierarchyItemType.Attribute,
            },
          ]
        : [],
    [focalItem]
  );

  const onSearchChange = useCallback(
    (value: string) => {
      dispatch(onSearchQueryChange(value));
      // this breaks the site report top drawer loading. Undoing for now.
      const box = searchBoxRef.current?.querySelector(
        "input[type=search]"
      ) as HTMLInputElement | null;
      if (box && value.length > 0) {
        box.focus();
      }
    },
    [dispatch]
  );

  // Trigger the api call when local parameters are initialised.
  // This will load the focalItemTableMetadata and focalItemTableRows into the slice
  const { isFetching: isTableFetching, isSuccess } = useGetTableQuery(
    {
      divisionName,
      payload: {
        parameterSelections: {
          channel: String(channel.value),
          location: location.nodeNumber,
          segmentation: {
            segmentation: String(segmentation.value),
            segment: String(segment.value),
          },
        },
        reportId: reportId ?? "",
      },
    },
    { skip: !localParametersInitialised || isEmptyReport }
  );

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return tableRowsSearch.length;
  }, [tableRowsSearch, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchBox} ref={searchBoxRef}>
        <SearchBox
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={matchesFound}
          searchQuery={searchQuery}
        />
      </div>
    ),
    [onSearchChange, matchesFound, searchQuery]
  );

  const displayEmptySearch: boolean =
    isSuccess && searchQuery.length > 0 && tableRowsSearch.length === 0;

  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<TableRow, number | string>) => (
      <ExpandableNameCell
        canExpand={false}
        cssClassName={styles.nameCell}
        depth={0}
        handleToggleExpanded={row.getToggleExpandedHandler()}
        handleToggleSelected={row.getToggleSelectedHandler()}
        isCheckboxHidden
        isExpanded={false}
        isSelected={row.getIsSelected()}
        name={row.original.product.name}
        onClick={() => {
          if (!row.getIsSelected()) {
            eventTrackingService.trackEvent(
              [
                TrackingComponent.MyReports,
                TrackingComponent.Report,
                TrackingComponent.FocalItemTableRow,
              ],
              TrackingEvent.Selected,
              new GenericTrackingProperties({
                reportType: "Basket affinities",
                reportId,
                selection: row.original.product,
              })
            );
            dispatch(onFocalItemChange(row.original.product));
          }
        }}
        shortName={row.original.product.shortName}
        // TODO: temp until the backend can return this
        type={HierarchyItemType.Attribute}
        value={String(getValue())}
      />
    ),
    [dispatch, eventTrackingService, reportId]
  );
  // `any` can be `string | number`. single type to make types work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: Array<ColumnDef<TableRow, any>> = useMemo(
    () => [
      {
        accessorFn: (row: TableRow) => row.product.name as string,
        header: memoizedSearchBox,
        id: "FocalItemTableHeader",
        enableSorting: false,
        enableResizing: false,
        enableHiding: false,
        cell: createNameCell,
      } as unknown as ColumnDef<TableRow, string>,
      // subsequent columns in the table
      ...measuresColumnDefs(tableMetadata),
    ],
    [createNameCell, measuresColumnDefs, memoizedSearchBox, tableMetadata]
  );

  const parameterSummary = [
    {
      name: "Focal Item",
      value: focalItem
        ? `(${hierarchyLevelDisplayLabel(focalItem.shortName)}) ${
            focalItem.name
          }`
        : "NULL",
    },
    { name: "Time", value: localParametersSelection.time },
    {
      name: "Time Period Length",
      value: localParametersSelection.timePeriodLength,
    },
    {
      name: "Channel",
      value: localParametersSelection.channel.label,
    },
    {
      name: "Segmentation",
      value: localParametersSelection.segmentation.label,
    },
    {
      name: "Segment",
      value: localParametersSelection.segment.label,
    },
    {
      name: "Location",
      value: `(${hierarchyLevelDisplayLabel(
        localParametersSelection.location.shortName
      )}) ${localParametersSelection.location.name}`,
    },
    {
      name: "Uplift Threshold",
      value: localParametersSelection.upliftThreshold.toLocaleString(),
    },
    {
      name: "Baskets with Both Threshold",
      value: localParametersSelection.basketsWithBothThreshold.toLocaleString(),
    },
  ];

  const filename = useMemo(
    () =>
      cleanFilename(`Basket_Affinities_${timePeriodLength}_${location.name}`),
    [timePeriodLength, location]
  );
  // Use client side data since the focal item data table will have a
  // finite number of rows that will always be present in the frontend store slice.
  // This avoids backend call to fetch data we already have.
  const csvTransformationCallback = useCallback(
    () => csvTransformation(tableRows, tableMetadata.metricMetadata),
    [tableRows, tableMetadata]
  );

  return (
    <ReportTopDrawer
      controls={[
        <DataTableOptions
          filename={filename}
          invokeCSVDownload={csvTransformationCallback}
          isFeatureEnabled={isExportEnabled}
          key={reportId}
          localParameters={parameterSummary}
          reportParameters={reportParameters}
        />,
      ]}
      items={focalItemSummary}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={ReportType.BasketAffinities}
    >
      <ReportHierarchyTableWrapper
        isSuccess={isEmptyReport || (!isTableFetching && isSuccess)}
        showNoDataAvailable={isEmptyReport}
      >
        <VirtuosoTableComponent
          columnResizeMode={columnResizeMode}
          columns={columns}
          data={tableRowsSearch}
          getRowId={(row) => row.product.name}
          pinFirstColumn
          rowExpandedState={{ "0": true }}
          rowSelectionState={focalItemTableRowSelectionState}
        />
        {displayEmptySearch && (
          <div className={styles.emptySearch}>
            <EmptySearch />
          </div>
        )}
      </ReportHierarchyTableWrapper>
    </ReportTopDrawer>
  );
};

export default BasketAffinitiesTopDrawer;
