export const GroupDescriptions = {
  ProductGroups: {
    Static: "**Static**: a fixed group of products that does not change",
    Dynamic:
      "**Dynamic**: a group of products defined by specific rules that updates with changes to those rules (e.g. change in brand or product attribute)",
  },
  LocationGroups: {
    Static: "**Static**: a fixed group of stores that does not change",
    Dynamic:
      "**Dynamic**: a group of stores defined by specific rules that updates with new data ",
  },
};

export const learnMoreLinks = {
  ProductAndLocationGroups:
    "https://portal.quantium.com/s/article/Groups-Q-Checkout",
  CustomerGroups:
    "https://portal.quantium.com/s/article/Custom-buyer-groups-Q-Checkout",
};
