import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { GroupColumnIndividualTooltipChart } from "components-ui/src/charts/group-column-individual-tooltip-chart/GroupColumnIndividualTooltipChart";
import {
  type HighchartsReactProps,
  TooltipHTML,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type SeriesColumnOptions } from "highcharts";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { SALES_INDEXED_THRESHOLD } from "../../../common/constants";
import { LocalParameters } from "../../../common/models/LocalParameterId";
import { isSingleSelectionParameter } from "../../../common/utils/local-parameters/LocalParametersUtils";
import { type RootState } from "../../../store";
import { type CrossShopTableItem } from "../../models/CrossShopDataTableResponseDto";
import {
  selectFocalItems,
  selectProductInteraction,
} from "../../services/customer-cross-shop-slice";

type CrossShopSegmentationChartProps = {
  categories: string[];
  data: SeriesColumnOptions[];
  format: string;
  indexMetric: boolean;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
};
const renderCustomChartTooltip = (
  metrics?: Array<{
    color?: string | null;
    name?: number | string | null;
    value?: string | null;
  }>,
  category?: number | string,
  productInteraction?: number | string
) => TooltipHTML(metrics, productInteraction ?? "", category);

const getStringLegend = (focalItems: CrossShopTableItem[]) => {
  const vennSegments = ["A", "B", "C"];

  // HACK: highcharts tries to trim whitespace in its title so we have to trick it not doing that
  // eslint-disable-next-line unicorn/no-array-reduce
  return focalItems.reduce(
    (previous, current, index) =>
      `${previous} ${vennSegments[index]}: (${hierarchyLevelDisplayLabel(
        current.shortName
      )}) ${current.name}  `, // eslint-disable-line no-irregular-whitespace
    ""
  );
};

export const CrossShopSegmentationChart = ({
  categories,
  data,
  format,
  indexMetric,
  onOptionsChanged,
}: CrossShopSegmentationChartProps) => {
  const focalItems = useSelector(selectFocalItems);
  const productInteraction = useSelector(selectProductInteraction);

  const { localParametersConfig, showSegmentationChartDataLabels } =
    useSelector((state: RootState) => ({
      localParametersConfig: state.customerCrossShop.localParameters,
      showSegmentationChartDataLabels:
        state.customerCrossShop.showSegmentationChartDataLabels,
    }));

  const formatter = useFormatter()(format);

  const getProductInteractionDisplayName = useCallback(
    (productInteractionValue: string) => {
      const productInteractionParameter = localParametersConfig.find(
        (x) => x.id === LocalParameters.ProductInteraction
      );
      if (
        productInteractionParameter &&
        isSingleSelectionParameter(productInteractionParameter)
      ) {
        const selections = productInteractionParameter.selections;
        return selections.find((x) => x.value === productInteractionValue)
          ?.label;
      }

      return productInteractionValue;
    },
    [localParametersConfig]
  );

  return (
    <GroupColumnIndividualTooltipChart
      categories={categories}
      data={data}
      dataLabelFormatter={formatter}
      onOptionsChanged={onOptionsChanged}
      showChartDataLabels={showSegmentationChartDataLabels}
      textLegend={getStringLegend(focalItems)}
      threshold={indexMetric ? SALES_INDEXED_THRESHOLD : 0}
      tooltipFormatter={formatter}
      tooltipOverride={renderCustomChartTooltip}
      tooltipSecondaryHeader={getProductInteractionDisplayName(
        productInteraction.value as string
      )}
      yAxisLabel={getProductInteractionDisplayName(
        productInteraction.value as string
      )}
      yAxisTickFormatter={formatter}
    />
  );
};
