import {
  HierarchyGroupRuleOperator,
  HierarchyType,
  HierarchyStructureName,
  type HierarchyGroupRuleWithIdAndName,
  ParameterId,
} from "@quantium-enterprise/common-ui";
import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
  Spinner,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NULL_SHORT_NAME } from "../../../../groups/src/components/constants";
import {
  onFilterRulesUpdate,
  selectFilterRules,
  selectHierarchyMetadata,
  selectLeafShortName,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./AdvancedSearchEditor.module.css";
import { type RuleNameOption } from "./DynamicGroupRule";
import { DynamicGroupRule } from "./DynamicGroupRule";

const parameterType = ParameterId.ProductHierarchy;
const hierarchyType = HierarchyType.Product;

export type AdvancedSearchEditorProps = {
  enableAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AdvancedSearchEditor = ({
  enableAdvancedSearch,
}: AdvancedSearchEditorProps) => {
  const dispatch = useDispatch();

  const leafShortName = useSelector((state: RootState) =>
    selectLeafShortName(parameterType, state)
  );

  const hierarchyAttributes = useSelector((state: RootState) =>
    selectHierarchyMetadata(parameterType, state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(parameterType, state)
  );

  const [readOnlyRules, setReadOnlyRules] = useState(filterRules.slice(0, -1));
  const [editableRule, setEditableRule] = useState(filterRules.at(-1));

  useEffect(() => {
    const newFilterRules = editableRule
      ? readOnlyRules.concat([editableRule])
      : readOnlyRules;

    dispatch(
      onFilterRulesUpdate({
        parameterType: ParameterId.ProductHierarchy,
        filterRules: newFilterRules,
      })
    );
  }, [editableRule, readOnlyRules, dispatch]);

  // do not allow the user to add more rules or click apply filter if the currently edited rule is not complete
  const areAnyFiltersEmpty = useMemo(() => {
    if (filterRules.length === 0) return false;

    return (
      filterRules[filterRules.length - 1].shortName === NULL_SHORT_NAME ||
      filterRules[filterRules.length - 1].values.length === 0
    );
  }, [filterRules]);

  const ruleNameOptions = useMemo(
    () => [
      {
        name: "Filter by",
        shortName: NULL_SHORT_NAME,
        disabled: true,
        selected: true,
      } as RuleNameOption,
      ...hierarchyAttributes,
    ],
    [hierarchyAttributes]
  );

  const isFilterByOptionsAvailable = useMemo(
    () => hierarchyAttributes.length,
    [hierarchyAttributes]
  );

  const getLastHierarchicalRuleInFilters = useCallback(
    (structureName: HierarchyStructureName) => {
      const hierarchyNames = hierarchyAttributes
        .filter((x) => x.structureName === structureName)
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((x) => x.shortName);

      return readOnlyRules
        .filter((x) => hierarchyNames.includes(x.shortName))
        .at(-1);
    },
    [hierarchyAttributes, readOnlyRules]
  );

  // cannot be the final item in the hierarchy, or be in the rules already, or be higher than the last rule if its a hierarchical item
  const canAttributeBeUsedWithRule = useCallback(
    (rule: HierarchyGroupRuleWithIdAndName, shortName: string) => {
      // assume its an attribute, or its the first rule added
      let isRuleLaterOrAttribute = true;

      if (editableRule) {
        const wantedShortName = hierarchyAttributes.find(
          (x) => x.shortName === shortName
        );

        // only check if its a hierarchical attribute
        if (
          wantedShortName?.structureName === HierarchyStructureName.Product ||
          wantedShortName?.structureName === HierarchyStructureName.Location
        ) {
          const lastRuleOfSameHierarchyName = getLastHierarchicalRuleInFilters(
            wantedShortName.structureName
          )?.shortName;
          const lastShortName = hierarchyAttributes.find(
            (x) => x.shortName === lastRuleOfSameHierarchyName
          );

          isRuleLaterOrAttribute =
            wantedShortName.ordinal >= (lastShortName?.ordinal ?? 0);
        }
      }

      const usedRules = readOnlyRules.map((x) => x.shortName);

      return (
        shortName === NULL_SHORT_NAME ||
        (shortName !== leafShortName &&
          !usedRules.includes(shortName) &&
          isRuleLaterOrAttribute)
      );
    },
    [
      getLastHierarchicalRuleInFilters,
      editableRule,
      hierarchyAttributes,
      leafShortName,
      readOnlyRules,
    ]
  );

  const getAllowedRuleNameOptions = useCallback(
    (rule: HierarchyGroupRuleWithIdAndName) =>
      ruleNameOptions.filter((attribute) =>
        canAttributeBeUsedWithRule(rule, attribute.shortName)
      ),
    [canAttributeBeUsedWithRule, ruleNameOptions]
  );

  const handleAddRule = () => {
    setReadOnlyRules(filterRules);
    setEditableRule({
      id: uniqueId("rule-"),
      operator: HierarchyGroupRuleOperator.Is,
      shortName: NULL_SHORT_NAME,
      fullShortName: undefined,
      values: [],
    } as HierarchyGroupRuleWithIdAndName);
  };

  const handleReadonlyRuleRemoved = (ruleId: string) => {
    setReadOnlyRules((oldRules) =>
      oldRules.filter((rule) => ruleId !== rule.id)
    );
  };

  const handleEditableRuleRemoved = () => {
    if (filterRules.length === 1) {
      enableAdvancedSearch(false);
    } else {
      setEditableRule(readOnlyRules.at(-1));
      setReadOnlyRules((oldRules) => oldRules.slice(0, -1));
    }
  };

  const handleRuleChange = (updatedRule: HierarchyGroupRuleWithIdAndName) => {
    // Reset the selected values here so that we don't get into an infinite loop inside DynamicGroupRule
    if (editableRule?.shortName !== updatedRule.shortName) {
      updatedRule.values = [];
    }

    setEditableRule(updatedRule);
  };

  const handleRemoveAllFilters = () => {
    enableAdvancedSearch(false);
  };

  return (
    <div className={styles.advancedSearchEditorContainer}>
      {!isFilterByOptionsAvailable && (
        <div className={styles.loadingLeafs}>
          <Spinner />
        </div>
      )}
      {isFilterByOptionsAvailable &&
        readOnlyRules.map((rule) => (
          <DynamicGroupRule
            hierarchyType={hierarchyType}
            key={rule.id}
            onRuleChange={(x) => handleRuleChange(x)}
            onRuleRemoved={() => handleReadonlyRuleRemoved(rule.id)}
            previousGroupRules={readOnlyRules}
            readonly
            rule={rule}
            ruleNameOptions={getAllowedRuleNameOptions(rule)}
          />
        ))}
      {isFilterByOptionsAvailable && editableRule && (
        <DynamicGroupRule
          hierarchyType={hierarchyType}
          key={editableRule.id}
          onRuleChange={(x) => handleRuleChange(x)}
          onRuleRemoved={() => handleEditableRuleRemoved()}
          previousGroupRules={readOnlyRules}
          readonly={false}
          rule={editableRule}
          ruleNameOptions={getAllowedRuleNameOptions(editableRule)}
        />
      )}
      <div className={styles.advancedSearchFilterController}>
        <Button
          disabled={areAnyFiltersEmpty}
          onClick={handleAddRule}
          variant={ButtonVariant.Stealth}
        >
          <Icon glyph={IconGlyph.AddAndPlusAddPlus} text="Add filter" />
          <span>Add filter</span>
        </Button>
        <Button
          onClick={handleRemoveAllFilters}
          text="Remove all filters"
          variant={ButtonVariant.Stealth}
        />
      </div>
    </div>
  );
};
