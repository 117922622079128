type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const RepertoireIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Repertoire report"}</title>
      <path d="M9.33252 16H14.665V22H9.33252V16Z" fill={colour3} />
      <path
        d="M9.33252 2.25C9.33252 2.11193 9.44445 2 9.58252 2H14.415C14.5531 2 14.665 2.11193 14.665 2.25V5.75H9.33252V2.25Z"
        fill={colour1}
      />
      <path d="M9.33252 5.75H14.665V16H9.33252V5.75Z" fill={colour2} />
      <path d="M2 7.25H7.3325V11.5H2V7.25Z" fill={colour2} />
      <path d="M2 11.5H7.3325V22H2V11.5Z" fill={colour3} />
      <path
        d="M2 2.25C2 2.11193 2.11193 2 2.25 2H7.0825C7.22057 2 7.3325 2.11193 7.3325 2.25V7.25H2V2.25Z"
        fill={colour1}
      />
      <path
        clipRule="evenodd"
        d="M19.3325 7.335C20.8057 7.335 22 6.14073 22 4.6675C22 3.19428 20.8057 2 19.3325 2C17.8593 2 16.665 3.19428 16.665 4.6675C16.665 6.14073 17.8593 7.335 19.3325 7.335ZM19.4158 2.75885C19.3872 2.68144 19.2778 2.68144 19.2491 2.75885L18.7703 4.05279C18.7613 4.07712 18.742 4.09631 18.7177 4.10532L17.4239 4.58412C17.3464 4.61275 17.3464 4.72225 17.4239 4.75088L18.7177 5.22968C18.742 5.23869 18.7613 5.25788 18.7703 5.28221L19.2491 6.57615C19.2778 6.65356 19.3872 6.65356 19.4158 6.57615L19.8946 5.28221C19.9037 5.25788 19.9229 5.23869 19.9473 5.22968L21.2411 4.75088C21.3185 4.72225 21.3185 4.61275 21.2411 4.58412L19.9473 4.10532C19.9229 4.09631 19.9037 4.07712 19.8946 4.05279L19.4158 2.75885Z"
        fill={colour1}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M19.3325 14.6675C20.8057 14.6675 22 13.4732 22 12C22 10.5268 20.8057 9.33252 19.3325 9.33252C17.8593 9.33252 16.665 10.5268 16.665 12C16.665 13.4732 17.8593 14.6675 19.3325 14.6675ZM19.7916 11.2791L19.4171 10.1263C19.3905 10.0444 19.2745 10.0444 19.2479 10.1263L18.8733 11.2791C18.8615 11.3158 18.8273 11.3406 18.7887 11.3406H17.5765C17.4904 11.3406 17.4546 11.4508 17.5243 11.5014L18.505 12.2139C18.5362 12.2366 18.5492 12.2767 18.5373 12.3133L18.1627 13.4662C18.1361 13.5481 18.2299 13.6162 18.2995 13.5656L19.2802 12.8531C19.3115 12.8305 19.3535 12.8305 19.3848 12.8531L20.3654 13.5656C20.4066 13.5956 20.4564 13.5839 20.4846 13.5525C20.5039 13.5307 20.5131 13.4996 20.5023 13.4662L20.1277 12.3133C20.1157 12.2767 20.1287 12.2366 20.16 12.2139L21.1407 11.5014C21.2103 11.4508 21.1745 11.3406 21.0884 11.3406H19.8762C19.8377 11.3406 19.8035 11.3158 19.7916 11.2791Z"
        fill={colour2}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M19.3325 22C20.8057 22 22 20.8057 22 19.3325C22 17.8593 20.8057 16.665 19.3325 16.665C17.8593 16.665 16.665 17.8593 16.665 19.3325C16.665 20.8057 17.8593 22 19.3325 22ZM19.7165 18.5438L19.4179 17.4981C19.3934 17.4121 19.2715 17.4121 19.247 17.4981L18.9485 18.5438C18.9353 18.5899 18.8879 18.6173 18.8414 18.6056L17.7865 18.3412C17.6998 18.3195 17.6389 18.4251 17.7011 18.4893L18.4576 19.2706C18.4909 19.3051 18.4909 19.3599 18.4576 19.3943L17.7011 20.1756C17.6389 20.2399 17.6998 20.3455 17.7865 20.3237L18.8414 20.0593C18.8879 20.0477 18.9353 20.075 18.9485 20.1212L19.247 21.1669C19.2715 21.2529 19.3934 21.2529 19.4179 21.1669L19.7165 20.1212C19.7297 20.075 19.7771 20.0477 19.8236 20.0593L20.8783 20.3237C20.9651 20.3455 21.0261 20.2399 20.9639 20.1756L20.2074 19.3943C20.1741 19.3599 20.1741 19.3051 20.2074 19.2706L20.9639 18.4893C21.0261 18.4251 20.9651 18.3195 20.8783 18.3412L19.8236 18.6056C19.7771 18.6173 19.7297 18.5899 19.7165 18.5438Z"
        fill={colour3}
        fillRule="evenodd"
      />
    </svg>
  );
};
