import {
  type ContributionResponseDto,
  type KdtContributionChartRequestDto,
} from "../models/ContributionChartDriverDto";
import { keyDriverTreeApiSlice } from "./key-driver-tree-api-slice";
import { onContributionChartDataReceived } from "./key-driver-tree-slice";

export const keyDriverTreeContributionDriversChartSlice =
  keyDriverTreeApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getKeyDriverTreeContributionDriversChartData: builder.query<
        ContributionResponseDto,
        { divisionName: string; requestPayload: KdtContributionChartRequestDto }
      >({
        async onQueryStarted(_argument, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(onContributionChartDataReceived(data));
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error fetching KDT contribution chart data", Error);
          }
        },
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetContributionChartData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetKeyDriverTreeContributionDriversChartDataQuery } =
  keyDriverTreeContributionDriversChartSlice;
