import { Checkbox } from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import styles from "./HierarchySelectGrid.module.css";

type HeaderProps = {
  handleToggleAllRowsSelected: (event: unknown) => void;
  hideRowCheckbox?: boolean;
  id?: string;
  isChecked: boolean;
  showCheckbox: boolean;
  title: string;
};

export const Header = ({
  handleToggleAllRowsSelected,
  isChecked,
  hideRowCheckbox,
  showCheckbox,
  title,
  id = "",
}: HeaderProps) => (
  <div
    className={classNames(styles.flex, {
      [styles.marginLeft]: hideRowCheckbox,
    })}
  >
    {showCheckbox && (
      <Checkbox
        assistiveLabel
        checked={isChecked}
        className={styles.headerCheckbox}
        data-testid="header-checkbox"
        id={id ? `${id}-checkbox` : undefined}
        label="Toggle selecting all rows"
        name="selectallrows"
        onChange={handleToggleAllRowsSelected}
      />
    )}
    <span
      className={styles.headerText}
      style={{
        marginLeft: "var(--qbit-space-xlarge)",
      }}
    >
      {title}
    </span>
  </div>
);

type DataSourceHeaderProps = {
  title: string;
};

export const DataSourceHeader = ({ title }: DataSourceHeaderProps) => (
  <div className={styles.headerRight}>
    <span className={styles.headerText}>{title}</span>
  </div>
);
