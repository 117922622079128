import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { EXPORT_ITEMS_SUFFIX } from "../../common/constants";
import { getExportParameterSummary } from "../../common/utils/export-parameter-summary-utils";
import { type RepertoireSidePanelSelections } from "../services/repertoire-slice";

export const getExportColumnHeader =
  (currencySymbol: string, metricFormat: string, metricLabel: string) =>
  (header: string) => {
    let columnHeader = `${metricLabel} - ${header}`;
    if (metricFormat === MetricTypes.Currency) {
      columnHeader = `${metricLabel} (${currencySymbol}) - ${header}`;
    }

    return columnHeader;
  };

export const getLocalParameterSummary = (
  localSelections: RepertoireSidePanelSelections,
  repertoireItems: string[],
  levelOfAnalysis?: string
) => {
  const parameterSummary = [
    {
      name: "Focal item(s)",
      value: getExportParameterSummary(
        repertoireItems.join(", "),
        EXPORT_ITEMS_SUFFIX
      ),
    },
    { name: "Level of analysis", value: levelOfAnalysis ?? "" },
    { name: "Time", value: localSelections.Time },
    { name: "Metric", value: localSelections.Metric.label },
    { name: "View as", value: localSelections.ViewAs.label },
    { name: "Channel", value: localSelections.Channel.label },
    {
      name: "Segmentation",
      value: localSelections.Segmentation[0]?.label ?? "",
    },
    {
      name: "Customer segment",
      value: localSelections.Segmentation[1]?.label ?? "",
    },
    {
      name: "Location",
      value: `(${hierarchyLevelDisplayLabel(
        localSelections.LocationHierarchy.shortName
      )}) ${localSelections.LocationHierarchy.name}`,
    },
  ];

  return parameterSummary;
};
