import {
  IconSize,
  InlineIcon,
  InlineIconGlyph,
} from "@quantium-enterprise/qds-react";
import styles from "./KeyDriverTreeLegendContent.module.css";

type KeyDriverTreeLegendContentProps = {
  disableBenchmark?: boolean;
  primaryMeasure: string;
  visible?: boolean;
};

export const KeyDriverTreeLegendContent = ({
  primaryMeasure,
  visible = false,
  disableBenchmark = false,
}: KeyDriverTreeLegendContentProps) => (
  <div className={styles.keyDriverTreeLegendContent}>
    {visible ? (
      <InlineIcon
        className={styles.helpIcon}
        glyph={InlineIconGlyph.AlertsAndNotificationsHelpCircleOutline}
        size={IconSize.Small}
        text="Key Driver Tree Legend Help"
      />
    ) : null}

    <div className={styles.measure}>Measure</div>
    <div className={styles.value}>Value</div>

    {disableBenchmark ? (
      <div className={styles.change}>% change</div>
    ) : (
      <div className={styles.changeAndBenchmark}>
        <div className={styles.change}>% change</div>
        <div className={styles.benchmark}>(benchmark)</div>
      </div>
    )}

    <div className={styles.contribution}>
      {primaryMeasure} growth contribution
    </div>
  </div>
);

export default KeyDriverTreeLegendContent;
