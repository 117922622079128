import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type PricingLaddersDataTableRequestDto } from "../models/PricingLaddersDataTableRequestDto";

export const getTableRequestDto = (
  activeTab: string,
  Channel: PanelOption,
  LocationHierarchy: LocalHierarchyNodeSelection,
  // [0] = Segmentation; [1] = segment;
  Segmentation: PanelOption[],
  reportId: string
) => {
  const payload: PricingLaddersDataTableRequestDto = {
    loaShortName: activeTab,
    localSelectedValues: [
      {
        id: LocalParameters.Channel,
        values: [Channel.value.toString()],
      },
      {
        id: LocalParameters.LocationHierarchy,
        values: [LocationHierarchy.nodeNumber.toString()],
      },
      {
        id: LocalParameters.Segmentation,
        values: Segmentation.map((segment) => segment.value.toString()),
      },
    ],
    reportId,
  };
  return payload;
};
