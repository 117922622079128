import {
  Number,
  NumberUnitPosition,
  NumberSentiment,
  NumberVariant,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import styles from "./Cell.module.css";
import { getSentiment } from "./CellHelper";

export type CellProps = {
  className?: string;
  formatter: Function;
  hasSentiment?: boolean;
  style?: React.CSSProperties;
  value?: number | string | null;
};

export const ValueCell = ({
  value,
  formatter,
  hasSentiment,
  className,
  style,
}: CellProps) => {
  const cellContent = () => {
    if (typeof value === "string") {
      if (
        value.toLowerCase() === "infinity" ||
        value.toLowerCase() === "-infinity"
      ) {
        return <Number>-</Number>;
      }

      return <Number>{formatter(value)}</Number>;
    }

    return (
      <Number
        sentiment={
          hasSentiment && value !== null
            ? getSentiment(value)
            : NumberSentiment.None
        }
        unitPosition={NumberUnitPosition.After}
        variant={NumberVariant.Featured}
      >
        {(value && value > 0 && hasSentiment ? "+" : "") + formatter(value)}
      </Number>
    );
  };

  return (
    <div
      className={classNames([styles.rowValue, styles.cell, className])}
      style={style}
    >
      <div
        className={classNames(styles.cellText, {
          // [styles.alignLeft]:
          //   !(value === undefined || value === null)
          //   && typeof value === "string",
          [styles.alignRight]: !(value === undefined || value === null),
          [styles.alignCenter]: value === undefined || value === null,
        })}
      >
        {cellContent()}
      </div>
    </div>
  );
};
