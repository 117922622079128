import {
  type RangePerformanceTableRow,
  type RangePerformanceTableTree,
} from "../models/rangePerformanceDriverTableTypes";

export const updateTreeWithNewData = (
  tree: RangePerformanceTableTree[],
  newRows: RangePerformanceTableRow[]
): RangePerformanceTableTree[] =>
  // This is O(n^2) can potentially update to make the search O(n) by making a hashmap on the rows with key as ShortName+Code...would need to benchmark
  tree.map((node) => {
    // Find a matching row in the new data
    const matchingRow = newRows.find(
      (row) =>
        row.item.code === node.row.item.code &&
        row.item.shortName === node.row.item.shortName
    );

    return {
      // If a matching row is found, update the node's row, otherwise keep the existing row
      row: matchingRow ?? node.row,
      // Recursively update children
      subRows: updateTreeWithNewData(node.subRows, newRows),
    };
  });
