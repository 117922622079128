import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { EXPORT_LOCATION_SUFFIX } from "../../common/constants";
import { getExportParameterSummary } from "../../common/utils/export-parameter-summary-utils";

export const getExportColumnHeader = (
  currencySymbol: string,
  chartContent: string,
  format: string,
  metric: string
) => {
  let columnHeader;
  const metricValue = metric;
  if (chartContent !== metricValue) {
    if (format === MetricTypes.Currency) {
      columnHeader = `${metricValue} (${currencySymbol}) - ${chartContent}`;
    } else {
      columnHeader = `${metricValue} - ${chartContent}`;
    }
  } else if (format === MetricTypes.Currency) {
    columnHeader = `${metricValue} (${currencySymbol})`;
  } else {
    columnHeader = `${metricValue}`;
  }

  return columnHeader;
};

export const displayFocalItems = (focalitems: HierarchySliceNodeDto[]) => {
  if (focalitems.length > 5) {
    return `${focalitems.length}  items`;
  } else if (focalitems.length < 5 && focalitems.length > 1) {
    let items = "";
    for (const item of focalitems) items += `${item.name}`;
    return items.split("").join(",");
  } else {
    return `${focalitems[0]?.name}`;
  }
};

export const getLocationForFileName = (LocationHierarchy: PanelOption[]) =>
  getExportParameterSummary(
    LocationHierarchy.map((location) => location.label.split(" ")[1]).join(","),
    EXPORT_LOCATION_SUFFIX
  );
