import { useMemo } from "react";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import { type SegmentOption } from "../segmentFilter/SegmentFilter";

export type MultiSelectSegmentFilterSummaryProps = {
  segmentOptions: SegmentOption[];
  selectedSegmentation: PanelOption;
  selectedSegments: string[];
};

export const MultiSelectSegmentFilterSummary = ({
  segmentOptions,
  selectedSegmentation,
  selectedSegments,
}: MultiSelectSegmentFilterSummaryProps) => {
  const summaryTextContent = useMemo(() => {
    const selectedValueLabels = segmentOptions
      .filter(
        (option) =>
          option.segmentationValue === selectedSegmentation.value &&
          selectedSegments.includes(option.segmentValue)
      )
      .map((option) => option.segmentLabel);

    const selectedSegmentationLabel = selectedSegmentation.label;

    return `${selectedSegmentationLabel} - ${selectedValueLabels.join(", ")}`;
  }, [
    segmentOptions,
    selectedSegmentation.label,
    selectedSegmentation.value,
    selectedSegments,
  ]);

  return <div>{summaryTextContent}</div>;
};
