import {
  type TransactionSource,
  getAttributeLevelTransactionSources,
  getPreferredTransactionSource,
  type ParameterDto,
  type HierarchyAttributeParameterOption,
  ParameterId,
  HierarchyItemType,
  HierarchyType,
  type AttributesFilterConfig,
} from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { type Item } from "components-ui/src/drag-and-drop/models/Item";
import { type Zone } from "components-ui/src/drag-and-drop/models/Zone";
import {
  defaultCanDropAttribute,
  defaultCanDropLocationAttribute,
  defaultFindValidDropIndex,
} from "components-ui/src/drag-and-drop/utilities/utilities";
import { HierarchyStructureWrapper } from "components-ui/src/drag-and-drop/wrapper/HierarchyStructureWrapper";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  structureItemSelected,
  structureItemsSelected,
  droppableZonesSelected,
  selectAttributesFilterConfigs,
  selectHierarchySelectedRows,
  selectIsDataEntitlementsShown,
  selectLeafShortName,
  selectTransactionSources,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./StructureParameter.module.css";
import { getStructureState } from "./StructureState";

type StructureParameterProps = {
  parameterDto: ParameterDto;
};

const hierarchyTypeToHierarchyParameterId: Record<string, ParameterId> = {
  [HierarchyType.Product]: ParameterId.ProductHierarchy,
  [HierarchyType.Location]: ParameterId.LocationHierarchy,
  [ParameterId.ProductStructure]: ParameterId.ProductStructure,
  [ParameterId.LocationStructure]: ParameterId.LocationStructure,
};

const getDisabledItems = (
  attributesFilterConfigs: AttributesFilterConfig[],
  droppableZones: Zone[],
  items: Item[]
): Item[] =>
  items.map((item) => {
    const filterConfigs = attributesFilterConfigs.find(
      (filterConfig) => filterConfig.filters[item.shortName]
    );

    if (!filterConfigs) {
      return item;
    }

    if (
      droppableZones.some((zone) =>
        filterConfigs.filters[item.shortName]?.some(
          (filter) => filter === zone.item?.shortName
        )
      )
    ) {
      return {
        ...item,
        isDisabled: true,
        disabledToolTip: filterConfigs.message,
      };
    }

    return {
      ...item,
      isDisabled: false,
    };
  });

export const StructureParameter = ({
  parameterDto,
}: StructureParameterProps) => {
  const structureState = useAppSelector(getStructureState(parameterDto.id));

  const dispatch = useDispatch();
  const boundClassnames = classNames.bind(styles);

  const isDataEntitlementsShown = useSelector((state: RootState) =>
    selectIsDataEntitlementsShown(state)
  );

  const reportTransactionSources = useSelector((state: RootState) =>
    selectTransactionSources(state)
  );

  const leafShortName = useSelector((state: RootState) =>
    selectLeafShortName(
      hierarchyTypeToHierarchyParameterId[parameterDto.hierarchyType],
      state
    )
  );

  const selectedHierarchyItems = useSelector((state: RootState) =>
    selectHierarchySelectedRows(
      hierarchyTypeToHierarchyParameterId[parameterDto.hierarchyType],
      state
    )
  );

  const attributesFilterConfigs = useSelector((state: RootState) =>
    selectAttributesFilterConfigs(
      hierarchyTypeToHierarchyParameterId[parameterDto.id],
      state
    )
  );

  const setItems = (newItems: Item[]) =>
    dispatch(
      structureItemsSelected({
        parameter: parameterDto.id,
        selectedItems: newItems,
      })
    );

  const setDroppableZones = (newDroppableZones: Zone[]) =>
    dispatch(
      droppableZonesSelected({
        parameter: parameterDto.id,
        zones: newDroppableZones,
      })
    );

  const setActiveItem = (newActiveItem: Item | undefined) =>
    dispatch(
      structureItemSelected({
        activeItem: newActiveItem,
        parameter: parameterDto.id,
      })
    );

  const options = parameterDto.options as HierarchyAttributeParameterOption[];
  const nonHierarchicalAttributes = useMemo(
    () => options.filter((index) => !index.isHierarchical),
    [options]
  );
  const hierarchicalItems = useMemo(
    () =>
      structureState?.items.filter(
        (item) =>
          item.type === HierarchyItemType.Hierarchy ||
          item.type === HierarchyItemType.Leaf
      ),
    [structureState?.items]
  );

  let displayedTransactionSource: TransactionSource | null | undefined;
  if (isDataEntitlementsShown) {
    const levelTransactionSources = getAttributeLevelTransactionSources(
      structureState?.parameterConfig.hierarchyType as HierarchyType,
      leafShortName,
      selectedHierarchyItems
    );

    displayedTransactionSource = getPreferredTransactionSource(
      reportTransactionSources,
      levelTransactionSources
    )?.[0];
  }

  const items = nonHierarchicalAttributes
    .map((attribute, attributeIndex) => ({
      dataSource: displayedTransactionSource,
      id: attribute.value,
      name: attribute.label,
      ordinal: attributeIndex,
      shortName: attribute.value,
      type: HierarchyItemType.Attribute,
    }))
    .filter(
      (item) =>
        !structureState?.droppableZones
          .map((zone) => zone.item?.id)
          .includes(item.id)
    );

  useEffect(() => {
    if (!structureState) return;
    if (
      // every filtered non-hierarchical item, there is a corresponding non-hierarchical item in state.items
      items.every((item) =>
        structureState.items.some((stateItem) => item.id === stateItem.id)
      ) &&
      // every hierarchy item is not in state droppable zone
      hierarchicalItems?.every((item) =>
        structureState.droppableZones.every(
          (droppableZone) => droppableZone.id !== item.id
        )
      ) &&
      // every hierarchy item has a corresponding selection within selectedHierarchyItems
      hierarchicalItems.every((item) =>
        selectedHierarchyItems.some(
          (selectedHierarchyItem) =>
            selectedHierarchyItem.shortName === item.shortName
        )
      )
    ) {
      setItems(structureState.items);
    } else {
      setItems(items);
    }
    // Including items fires the effect in an endless loop
    // Including setItems fires the effect on every refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredItems = useMemo(() => {
    if (!structureState) return [];

    return getDisabledItems(
      attributesFilterConfigs ?? [],
      structureState.droppableZones,
      structureState.items
    );
  }, [attributesFilterConfigs, structureState]);

  if (!structureState) {
    return null;
  }

  const looseHierarchyItemsCount = structureState.items.filter(
    (item) => !item.type.includes(HierarchyItemType.Attribute)
  ).length;

  const hierarchyItemsInStructureCount = structureState.droppableZones.filter(
    (zone) => zone.item && !zone.item.type.includes(HierarchyItemType.Attribute)
  ).length;

  const hierarchyItemsCount =
    hierarchyItemsInStructureCount + looseHierarchyItemsCount;

  const title =
    parameterDto.hierarchyType === HierarchyType.Product
      ? "Product Structure"
      : "";

  const description =
    parameterDto.hierarchyType === HierarchyType.Product
      ? "Add or remove product attributes and hierarchy levels to create your product structure"
      : "Drag and drop attributes to create your location structure";

  return (
    <div
      className={boundClassnames(styles.structureParameterContainer, {
        [styles.disabled]: !structureState.isValid && hierarchyItemsCount === 0,
      })}
    >
      <HierarchyStructureWrapper
        activeItem={structureState.activeItem}
        canDropAttribute={
          parameterDto.hierarchyType === HierarchyType.Product
            ? defaultCanDropAttribute
            : defaultCanDropLocationAttribute
        }
        description={description}
        droppableZones={structureState.droppableZones}
        findValidDropIndex={defaultFindValidDropIndex}
        initialItems={[]}
        initialStructure={[]}
        items={filteredItems}
        maxDisplayItems={10}
        setActiveItem={setActiveItem}
        setDroppableZones={setDroppableZones}
        setItems={setItems}
        title={title}
      />
      <div className={styles.disableMask} />
    </div>
  );
};
