import {
  formatNumberDate,
  type HierarchySliceNodeDto,
} from "@quantium-enterprise/common-ui";
import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import { type Metric, type Series } from "components-ui/src/charts/models";
import { ChartSeriesLabels } from "../models/CustomerProfilingSegmentsOverTimeReportlet";
import {
  type SegmentsOverTimeSegmentItem,
  type SegmentsOverTimeFocalItem,
  type SegmentsOverTimeSegmentationItem,
} from "../models/CustomerProfilingSegmentsOverTimeResponseDto";

export const padWithNulls = (
  data: Array<number | null>,
  dataDates: string[],
  segmentDates: string[]
): Array<number | null> => {
  // data array covers all dates
  if (dataDates.length === segmentDates.length) {
    return data;
  }

  // data array only covers some of the dates
  const paddedData = segmentDates.map((segmentDate) => {
    if (dataDates.includes(segmentDate)) {
      return data[dataDates.indexOf(segmentDate)] ?? null;
    }

    return null;
  });

  return paddedData;
};

export const compareDates = (a: string, b: string): number => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const getEnumValueByString = <T extends Record<keyof T, string>>(
  enumObject: T,
  value: string
): string => {
  for (const key in enumObject) {
    if (enumObject[key] === value) {
      return enumObject[key];
    }
  }

  return "";
};

export const getChartMetricFormatAndDisplayName = (
  reportData: SegmentsOverTimeSegmentationItem[],
  metric?: string,
  segmentation?: string
) => {
  const metricData = reportData
    .find((item) => item.segmentation === segmentation)
    ?.focalItems.at(0)
    ?.segments.at(0)
    ?.metrics.find((item) => item.metricName === metric);

  return metricData
    ? {
        metricDisplayName: metricData.displayName,
        metricFormat: getEnumValueByString(MetricTypes, metricData.format),
      }
    : { metricDisplayName: "", metricFormat: MetricTypes.None };
};

export const getSegmentsDatesByFocalItem = (
  focalItems: SegmentsOverTimeFocalItem[],
  activeFocalItem?: HierarchySliceNodeDto
) =>
  [
    ...new Set(
      focalItems
        .filter(
          (item) => item.focalItem.nodeNumber === activeFocalItem?.nodeNumber
        )
        .flatMap((focalItem) =>
          focalItem.segments.flatMap((segment) => segment.dates)
        )
    ),
  ].sort(compareDates);

export const getCustomerChartSeriesData = (
  focalItemsData: SegmentsOverTimeFocalItem[],
  selectedMetric: string,
  metricItem: Metric,
  showDataLabels: boolean,
  activeFocalItem?: HierarchySliceNodeDto,
  segmentation?: string
) => {
  if (!segmentation) return [];

  const dates = getSegmentsDatesByFocalItem(focalItemsData, activeFocalItem);

  const segmentsMetricData = focalItemsData
    .filter((item) => item.focalItem.nodeNumber === activeFocalItem?.nodeNumber)
    .flatMap((item) =>
      item.segments.flatMap((segment) => ({
        name: segment.segment,
        data:
          segment.metrics.find((metric) => metric.metricName === selectedMetric)
            ?.data ?? [],
        dataDate: segment.dates,
      }))
    );

  return segmentsMetricData.map((metric, index) => ({
    color: getColourByIndex(index),
    data: padWithNulls(metric.data, metric.dataDate, dates),
    metric: metricItem,
    name: metric.name,
    showDataLabels,
  }));
};

export const getDatesInFocalItemsBySegment = (
  focalItems: SegmentsOverTimeFocalItem[],
  segment?: string
) =>
  [
    ...new Set(
      focalItems.flatMap((focalItem) =>
        focalItem.segments
          .filter((item) => item.segment === segment)
          .flatMap((item) => item.dates)
      )
    ),
  ].sort(compareDates);

export const getFocalItemChartSeriesData = (
  focalItemsData: SegmentsOverTimeFocalItem[],
  selectedMetric: string,
  metricItem: Metric,
  showDataLabels: boolean,
  selectedSegment?: SegmentsOverTimeSegmentItem
) => {
  const dates = getDatesInFocalItemsBySegment(
    focalItemsData,
    selectedSegment?.segment
  );

  const metricData = focalItemsData.flatMap((item) =>
    item.segments
      .filter((segment) => segment.segment === selectedSegment?.segment)
      .map((segment) => ({
        focalItemName: item.focalItem.name,
        data:
          segment.metrics.find((metric) => metric.metricName === selectedMetric)
            ?.data ?? [],
        dataDate: segment.dates,
      }))
  );

  const series: Series[] = metricData.map((metric) => ({
    data: padWithNulls(metric.data, metric.dataDate, dates),
    metric: metricItem,
    name: metric.focalItemName,
    showDataLabels,
  }));

  return series;
};

export const getFocalItemsSegmentsData = (
  reportData: SegmentsOverTimeSegmentationItem[],
  focalItems: HierarchySliceNodeDto[],
  segmentation?: string
) => {
  const segmentationData = reportData.filter(
    (data) => data.segmentation === segmentation
  );

  const focalItemNodes = focalItems.map((item) => item.nodeNumber);

  const focalItemsData = segmentationData.flatMap((item) =>
    item.focalItems.filter((focalItem) =>
      focalItemNodes.includes(focalItem.focalItem.nodeNumber)
    )
  );

  return focalItemsData.map((item) => ({
    ...item,
    focalItem:
      focalItems.find(
        (focalItem) => focalItem.nodeNumber === item.focalItem.nodeNumber
      ) ?? item.focalItem,
  }));
};

export const getChartSeriesData = (
  chartSeries: string,
  focalItems: HierarchySliceNodeDto[],
  metric: string,
  metricItem: Metric,
  reportData: SegmentsOverTimeSegmentationItem[],
  showDataLabels: boolean,
  activeFocalItem?: HierarchySliceNodeDto,
  segmentation?: string,
  segment?: SegmentsOverTimeSegmentItem
) => {
  const focalItemsData = getFocalItemsSegmentsData(
    reportData,
    focalItems,
    segmentation
  );

  if (chartSeries === ChartSeriesLabels.CustomerSegments) {
    return getCustomerChartSeriesData(
      focalItemsData,
      metric,
      metricItem,
      showDataLabels,
      activeFocalItem,
      segmentation
    );
  }

  if (chartSeries === ChartSeriesLabels.FocalItem) {
    return getFocalItemChartSeriesData(
      focalItemsData,
      metric,
      metricItem,
      showDataLabels,
      segment
    );
  }

  return [];
};

export const getChartXAxisDates = (
  selectedChartSeries: string,
  focalItems: HierarchySliceNodeDto[],
  reportData: SegmentsOverTimeSegmentationItem[],
  activeFocalItem?: HierarchySliceNodeDto,
  segmentation?: string,
  selectedSegment?: SegmentsOverTimeSegmentItem
) => {
  const focalItemsData = getFocalItemsSegmentsData(
    reportData,
    focalItems,
    segmentation
  );

  let dates: string[] = [];

  if (selectedChartSeries === ChartSeriesLabels.CustomerSegments) {
    dates = getSegmentsDatesByFocalItem(focalItemsData, activeFocalItem);
  }

  if (selectedChartSeries === ChartSeriesLabels.FocalItem) {
    dates = getDatesInFocalItemsBySegment(
      focalItemsData,
      selectedSegment?.segment
    );
  }

  return dates.map((date) => formatNumberDate(Number.parseInt(date, 10)));
};
