type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const CustomerLoyaltyIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Customer loyalty report"}</title>
      <path
        d="M21.6628 5.87123L16.4229 4.62626L17.9664 9.79383L18.7442 8.96836C19.095 9.75653 19.3078 10.6057 19.3671 11.4789C19.4808 13.155 19.0217 14.8199 18.0652 16.2C17.1088 17.5801 15.7119 18.593 14.1043 19.0724C12.4967 19.5517 10.7741 19.4689 9.2198 18.8374L8.23663 21.2654C10.3413 22.1204 12.6739 22.2325 14.8507 21.5835C17.0275 20.9344 18.919 19.5628 20.2141 17.694C21.5093 15.8253 22.1309 13.5708 21.977 11.3012C21.8722 9.75795 21.4131 8.27016 20.6471 6.94902L21.6628 5.87123Z"
        fill={colour1}
      />
      <path
        d="M5.43621 15.4082C4.9445 14.4523 4.66342 13.3939 4.6214 12.3053C4.55654 10.6248 5.06514 8.97236 6.06341 7.62016C7.06168 6.26796 8.49006 5.29669 10.1132 4.86637C11.7364 4.43606 13.4574 4.57237 14.9929 5.25285L16.0514 2.85689C13.9722 1.93546 11.6417 1.75088 9.44386 2.33356C7.24597 2.91625 5.31182 4.23144 3.96008 6.06243C2.60833 7.89343 1.91964 10.131 2.00747 12.4065C2.07514 14.1597 2.60071 15.8549 3.51941 17.3279L2.56084 18.2879L7.76259 19.6842L6.36904 14.474L5.43621 15.4082Z"
        fill={colour2}
      />
      <path
        d="M7.75 11.1523C7.75 9.6464 8.74908 8.25 10.1172 8.25C11.0666 8.25 11.771 8.87652 12.2059 9.76527C12.6407 8.87657 13.3452 8.25 14.2946 8.25C15.6628 8.25 16.6618 9.64665 16.6618 11.1523C16.6618 14.3755 12.2059 16.6667 12.2059 16.6667C12.2059 16.6667 7.75 14.5141 7.75 11.1523Z"
        fill={colour3}
      />
    </svg>
  );
};
