import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { ChildArrow } from "components-ui/src/child-arrow/ChildArrow";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./SubstitutabilityStructureHeader.module.scss";

export const SubstitutabilityStructureHeader = () => (
  <div className={styles.structurePreview}>
    <div className={styles.itemRow}>
      <HierarchyLevelIcon shortName="PD" type={HierarchyItemType.Leaf} />
      <span aria-label="Focal product" className={styles.title}>
        Focal product
      </span>
    </div>
    <div className={styles.itemRow}>
      <div className={styles.arrow}>
        <ChildArrow />
      </div>
      <HierarchyLevelIcon shortName="PD" type={HierarchyItemType.Leaf} />
      <span aria-label="Substitute product" className={styles.title}>
        Substitute product
      </span>
    </div>
  </div>
);
