import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { rollingPeriodSelected } from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { getRollingPeriodState } from "./RollingPeriodState";
import { getTimePeriodState } from "./TimePeriodState";
import { RollingPeriod } from "./components/RollingPeriod";

type RollingPeriodParameterProps = {
  parameterDto: ParameterDto;
};

export const RollingPeriodParameter = ({
  parameterDto,
}: RollingPeriodParameterProps) => {
  // Redux Store Hooks
  const dispatch = useDispatch();
  const focusPeriod = useAppSelector(
    getTimePeriodState(ParameterId.FocusPeriod)
  );

  const { wizard } = useSelector((state: RootState) => ({
    wizard: state.wizard,
  }));

  const rollingPeriod = useAppSelector(getRollingPeriodState(parameterDto.id));

  if (!rollingPeriod || !focusPeriod) {
    return null;
  }

  const isDisabled = !focusPeriod.startDate || !focusPeriod.endDate;
  const isTabVisited = wizard.visitedTabs[rollingPeriod.parameterGroup];

  const rollingPeriodSelect = (newRollingPeriod: string) => {
    dispatch(
      rollingPeriodSelected({
        parameter: parameterDto.id,
        rollingPeriodValue: newRollingPeriod,
      })
    );
  };

  return (
    <RollingPeriod
      disabled={isDisabled}
      errors={rollingPeriod.errors}
      isVisited={isTabVisited}
      onChange={rollingPeriodSelect}
      options={rollingPeriod.options}
      rollingPeriod={rollingPeriod.value}
    />
  );
};
