import { format, parse } from "date-fns";
import { BreakdownByParameterId } from "../../models/basket-quantities-common-models";
import { type OverTimeChartResponse } from "../../models/basket-quantities-over-time-response-dto";
import { getExportColumnHeader } from "../../utils/getExportColumnHeader";

export const csvTransformation = (
  response: OverTimeChartResponse | undefined,
  breakdown: string,
  basketSize: string,
  metricKeys: string[],
  currencySymbol: string
) => {
  const columnHeaders = ["Quantity per basket", "Time"];
  const csvData = [columnHeaders];
  if (response) {
    let first = true;
    for (const basketSeries of response.basketSeries.filter((basketSeries2) =>
      breakdown === BreakdownByParameterId.Total
        ? basketSeries2.basketSize !== "Total"
        : basketSeries2.basketSize === basketSize
    )) {
      const series = basketSeries.series
        .filter(
          (metricSeries) =>
            (breakdown === BreakdownByParameterId.Total
              ? metricSeries.breakdown === "Total"
              : metricSeries.breakdown !== "Total") &&
            metricKeys.includes(metricSeries.metricKey)
        )
        .sort(
          (metricSeries1, metricSeries2) =>
            metricKeys.indexOf(metricSeries1.metricKey) -
            metricKeys.indexOf(metricSeries2.metricKey)
        );
      if (first) {
        first = false;
        columnHeaders.push(
          ...series.map(getExportColumnHeader(currencySymbol, breakdown))
        );
      }

      for (let index = 0; index < response.dates.length; ++index) {
        const date = response.dates[index];
        const row = [
          basketSeries.basketSize,
          format(parse(date.toString(), "yyyyMMdd", new Date()), "yyyy-MM-dd"),
          ...series.map((metricSeries) =>
            (metricSeries.data[index].metricValue ?? 0).toString()
          ),
        ];
        csvData.push(row);
      }
    }
  }

  return csvData;
};
