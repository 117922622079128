import {
  type RouteWithLabel,
  GROUPS_PATH,
} from "@quantium-enterprise/common-ui";
import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { groupsRoutes } from "./Groups";
import { store } from "./store";

// eslint-disable-next-line @typescript-eslint/promise-function-async -- this is as per React docs
const Groups = lazy(() => import("./Groups"));

export default (): RouteWithLabel => ({
  children: groupsRoutes,
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <Groups />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Groups",
  path: GROUPS_PATH,
});
