import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type SharedUserDto,
  type UserDto,
  type UsersByIdRequestDto,
} from "../models";

export const userApi = createApi({
  baseQuery: getBaseQuery(`/api/UserService`),
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getUser: builder.query<UserDto, void>({
      providesTags: ["User"],
      query: () => "/GetUserDetails",
    }),
    getOrganizationUsers: builder.query<
      SharedUserDto[],
      { divisionName: string }
    >({
      providesTags: ["OrganisationUsers"],
      query: ({ divisionName }) => `/GetOrganizationUsers/${divisionName}`,
    }),
    getUsersById: builder.query<
      SharedUserDto[],
      { payload: UsersByIdRequestDto }
    >({
      providesTags: ["UsersById"],
      query: ({ payload }) => ({
        body: payload,
        method: "POST",
        url: "/UserDetailsByIds",
      }),
    }),
  }),
  keepUnusedDataFor: 600,
  reducerPath: "userApi",
  tagTypes: ["User", "OrganisationUsers", "UsersById"],
});

export const {
  useGetUserQuery,
  useGetOrganizationUsersQuery,
  useGetUsersByIdQuery,
} = userApi;
export type userApiType = typeof userApi;
