type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};
export const LocationGroupIcon = ({
  className,
  coloured = true,
  text,
}: Props) => {
  const colour = coloured
    ? "var(--marker-boxer-colour)"
    : "var(--icon-colour-two-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 36 36"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Location group"}</title>
      <path
        d="M18 6C12.9857 6 9 9.72 9 14.4C9 21.6 18 30 18 30C18 30 27 21.48 27 14.4C27 9.72 23.0143 6 18 6ZM18 17.4C16.2 17.4 14.7857 16.08 14.7857 14.4C14.7857 12.72 16.2 11.4 18 11.4C19.8 11.4 21.2143 12.72 21.2143 14.4C21.2143 16.08 19.8 17.4 18 17.4Z"
        fill={colour}
      />
    </svg>
  );
};
