type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};

export const StaticIcon = ({ className, coloured = true, text }: Props) => {
  const colour = coloured
    ? "var(--static-colour)"
    : "var(--group-colour-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Static"}</title>
      <path
        d="M14.4956 10H2.49561V12H14.4956V10ZM14.4956 6H2.49561V8H14.4956V6ZM2.49561 16H10.4956V14H2.49561V16ZM21.9956 11.5L23.4956 13L16.5006 20L11.9956 15.5L13.4956 14L16.5006 17L21.9956 11.5Z"
        fill={colour}
      />
    </svg>
  );
};
