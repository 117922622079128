import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type InProgressReportResponseDto } from "../models/InProgressReportResponseDto";

export const adminApi = createApi({
  baseQuery: getBaseQuery(`/api/admin-service`),
  endpoints: (builder) => ({
    getInProgressReportsByElapsedTime: builder.query<
      InProgressReportResponseDto,
      { elapsedMinutes: number; page: number; pageSize: number }
    >({
      providesTags: ["admin"],
      query: ({ elapsedMinutes, page, pageSize }) => ({
        url: "/ReportManagement/GetInProgressReportsByElapsedTime/",
        params: { elapsedMinutes, page, pageSize },
      }),
    }),
    cancelReport: builder.mutation<unknown, { reportId: string }>({
      query: ({ reportId }) => ({
        method: "POST",
        headers: {
          "Content-type": "application/json-patch+json; charset=utf-8",
        },
        body: `"${reportId}"`,
        url: "/ReportManagement/CancelReport/",
      }),
      async onQueryStarted(
        { reportId },
        { dispatch, queryFulfilled, getState }
      ) {
        const postsCache = adminApi.util.selectInvalidatedBy(getState(), [
          { type: "admin" },
        ]);

        try {
          // optimistic update removing report from cache
          for (const { originalArgs } of postsCache.filter(
            ({ endpointName }) =>
              endpointName === "getInProgressReportsByElapsedTime"
          )) {
            dispatch(
              adminApi.util.updateQueryData(
                "getInProgressReportsByElapsedTime",
                originalArgs,
                (draft) => {
                  draft.data = draft.data.filter(
                    (report) => report.reportId !== reportId
                  );
                }
              )
            );
          }

          await queryFulfilled;
        } catch {
          dispatch(adminApi.util.invalidateTags(["admin"]));
        }
      },
    }),
  }),
  reducerPath: "adminApi",
  tagTypes: ["admin"],
});

export const {
  useGetInProgressReportsByElapsedTimeQuery,
  useLazyGetInProgressReportsByElapsedTimeQuery,
  useCancelReportMutation,
} = adminApi;
