import {
  type ReportParametersDto,
  type FocalItem,
} from "@quantium-enterprise/common-ui";
import { store } from "../store";
import {
  addFastReportingTabs,
  addAdvancedReportingTab,
  navigatingToDashboard,
  removeTab,
  replaceActiveTab,
  setActiveTab,
  setActiveTabPosition,
  loadTabsFromSessionStorage,
} from "./report-tabs-slice";

export const dispatchAddFastReportingTabs = (
  items: Array<{
    focalItem: FocalItem;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is type safe when used with useReportTabState with the Zod schema
    initialState?: { [key: string]: any };
  }>
) => {
  store.dispatch(addFastReportingTabs(items));
};

export const dispatchActivateTab = (id: string) => {
  store.dispatch(setActiveTab(id));
};

export const dispatchDeactivateTab = () => {
  store.dispatch(setActiveTab(undefined));
};

export const dispatchRemoveTab = (id: string) => {
  store.dispatch(removeTab(id));
};

export const dispatchNavigatingToDashboard = () => {
  store.dispatch(navigatingToDashboard());
};

export const dispatchReplaceActiveTab = (item: FocalItem) => {
  store.dispatch(replaceActiveTab(item));
};

export const dispatchSetActiveTabPosition = (index: number) => {
  store.dispatch(setActiveTabPosition(index));
};

export const dispatchLoadTabsFromSessionStorage = (divisionName: string) => {
  store.dispatch(loadTabsFromSessionStorage(divisionName));
};

export const dispatchAddAdvancedReportingTab = (
  path: string,
  report: ReportParametersDto
) => store.dispatch(addAdvancedReportingTab({ path, report }));
