type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const MeasureThresholdIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--cg-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--cg-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--cg-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Measure threshold customer group"}</title>
      <path
        d="M2 9.22517V14.7598C2 15.0723 2.25333 15.3257 2.56582 15.3257C2.87831 15.3257 3.13164 15.579 3.13164 15.8915V21C3.13164 21.5523 3.57935 22 4.13164 22H6.54272C7.09501 22 7.54272 21.5523 7.54272 21V15.8799C7.54272 15.5738 7.79088 15.3257 8.09699 15.3257C8.40311 15.3257 8.65127 15.0775 8.65127 14.7714V9.1857C8.65127 9.01694 8.61266 8.84759 8.50916 8.71428C8.16699 8.2736 7.31988 7.58893 5.37182 7.58893C3.40888 7.58893 2.5749 8.20625 2.17502 8.68929C2.05153 8.83847 2 9.03152 2 9.22517Z"
        fill={colour2}
      />
      <circle cx="5.30268" cy="4.24018" fill={colour2} r="2.24018" />
      <path
        d="M15.3394 11.5305C15.3392 11.5303 15.3389 11.5303 15.3387 11.5305L14.7021 12.2066C14.5128 12.4076 14.1964 12.4171 13.9953 12.2278L10.864 9.2793C10.663 9.09 10.6535 8.77356 10.8428 8.57252L11.5818 7.7877C11.7711 7.58666 12.0875 7.57715 12.2885 7.76645L13.9055 9.28902C14.1069 9.47868 14.4241 9.46873 14.6132 9.26682L20.1416 3.36491C20.3304 3.16338 20.6468 3.15304 20.8483 3.34182L21.6351 4.07876C21.8366 4.26754 21.8469 4.58396 21.6582 4.78549L15.7836 11.057C15.7835 11.0571 15.7835 11.0573 15.7836 11.0575C15.7838 11.0576 15.7838 11.0578 15.7836 11.058L15.5908 11.2628L15.34 11.5305C15.3399 11.5307 15.3396 11.5307 15.3394 11.5305Z"
        fill={colour1}
      />
      <path
        d="M15.3394 19.9605C15.3393 19.9604 15.339 19.9604 15.3389 19.9605L14.7021 20.6368C14.5128 20.8378 14.1963 20.8474 13.9953 20.6581L10.864 17.7096C10.663 17.5203 10.6535 17.2038 10.8428 17.0028L11.5818 16.218C11.7711 16.0169 12.0875 16.0074 12.2885 16.1967L13.9053 17.7192C14.1068 17.9088 14.4239 17.8989 14.613 17.697L20.1415 11.7949C20.3303 11.5934 20.6467 11.583 20.8483 11.7718L21.635 12.5087C21.8365 12.6975 21.8469 13.0139 21.6581 13.2155L15.7835 19.487C15.7833 19.4872 15.7833 19.4875 15.7835 19.4877C15.7837 19.4878 15.7837 19.4881 15.7835 19.4883L15.5368 19.7503L15.34 19.9605C15.3398 19.9606 15.3396 19.9606 15.3394 19.9605Z"
        fill={colour3}
      />
    </svg>
  );
};
