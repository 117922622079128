import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type CustomerGroupDto,
  type GeneratedReportDto,
  parseCustomerGroupDto,
  parseGeneratedReportDto,
} from "../models";

let eventSource: EventSource | null;
const defaultUrl = "/sse/server-sent-events";

export const isGeneratedReportDto = (
  data: CustomerGroupDto | GeneratedReportDto
) => "reportStatus" in data;

export const isCustomerGroupDto = (
  data: CustomerGroupDto | GeneratedReportDto
) => "segments" in data;

export type SseMessageQueryArguments = {
  url?: string;
};

// connect to the back end server
const connectToEventSource = async (url: string) =>
  await new Promise<void>((resolve: Function) => {
    eventSource = new EventSource(url);
    eventSource.onopen = () => {
      resolve(eventSource);
    };
  });

export const sseApi = createApi({
  baseQuery: async () => ({ data: [] }),
  endpoints: (builder) => ({
    getMessages: builder.query<
      Array<CustomerGroupDto | GeneratedReportDto>,
      SseMessageQueryArguments
    >({
      async onCacheEntryAdded(
        argument,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        try {
          // need to wait for queryFn to complete to add an initial value to the cache
          await cacheDataLoaded;
          if (eventSource) {
            eventSource.onmessage = (message) => {
              updateCachedData((currentCachedData) => {
                // store the new updated data in the cache
                const jsonData: CustomerGroupDto | GeneratedReportDto =
                  JSON.parse(message.data);
                if (isGeneratedReportDto(jsonData)) {
                  currentCachedData.push(parseGeneratedReportDto(jsonData));
                } else if (isCustomerGroupDto(jsonData)) {
                  // as SelectionDto's are not parsed (see parameter-selection-dto),
                  // we parse to validate the rest of the data, but use the original JSON data
                  parseCustomerGroupDto(jsonData);
                  currentCachedData.push(jsonData);
                } else {
                  throw new Error("Unhandled SSE message type.");
                }
              });
            };

            eventSource.onerror = () => {
              // eslint-disable-next-line no-console -- needs error handling
              console.log("Error in sse event service");
            };
          } else {
            throw new Error("Event source is not defined.");
          }
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;

        if (eventSource) {
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          eventSource.close();
        }
      },
      providesTags: ["sse"],
      // need to run an initial query to get cachedataloaded to fire
      queryFn: async ({ url }: SseMessageQueryArguments) => {
        await connectToEventSource(url ?? defaultUrl);
        return { data: [] };
      },
    }),
  }),
  reducerPath: "sseApi",
  tagTypes: ["sse"],
});

export const { useGetMessagesQuery } = sseApi;
export type sseApiType = typeof sseApi;
