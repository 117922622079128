import {
  type ReportParametersDto,
  createRowNameMatchesSearchPredicate,
  type HierarchyValueDto,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { type PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { type Updater, type SortingState } from "@tanstack/react-table";
import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { type BasketAffinitiesReportParametersResponseDto } from "../models";
import {
  type PurchasedWithProduct,
  type ReportletsDto,
  type AssociatedItem,
  type AssociatedAncestor,
} from "../models/basket-affinities-chart-models";
import {
  type TableMetadata,
  type TableRow,
  type TableResponse,
} from "../models/basket-affinities-data-table-models";
import { associatedItemsAggregation } from "../utils/associatedItemsAggregation";
import { getDefaultSelections } from "../utils/getDefaultSelections";
import { getMaxUplift } from "./basket-affinities-slice-utils";

const getPersistenceKey = (reportId: string) => `basket-affinities-${reportId}`;

export type BasketAffinitiesLocalSelections = {
  basketsWithBothRange: {
    maximum?: number;
    minimum?: number;
  };
  // to be changed when double sided slider is available
  basketsWithBothThreshold: number;
  channel: PanelOption;
  location: LocalHierarchyNodeSelection;
  segment: PanelOption;
  segmentation: PanelOption;
  time: string;
  timePeriodLength: string;
  upliftRange: {
    maximum?: number;
    minimum?: number;
  };
  // to be changed when double sided slider is available
  upliftThreshold: number;
};

export type BasketAffinitiesPersistedSelections = {
  focalItem?: HierarchyValueDto;
  // an object representing which rows are selected in the focal item table
  focalItemRowTableRowSelectionState: Record<string, boolean>;
  localParametersSelection: BasketAffinitiesLocalSelections;
};

export type BasketAffinitiesState = {
  // the selected associated ancestor
  associatedAncestor: AssociatedItem;
  // the colours associated with each associatedItem
  associatedItemColours: Map<AssociatedAncestor, string>;
  associatedItems: AssociatedItem[];
  // whether the data is loaded in the focal item table
  focalItemTableInitialised: boolean;
  // the table column title and formatting info for the focal item table, eg Sales, Customer, Baskets
  focalItemTableMetadata: TableMetadata;
  // the actual values for the columns defined above. These should be the same length and order as the columns in the metadata
  focalItemTableRows: TableRow[];
  // whether the report has no data, so cannot be displayed.
  isEmptyReport: boolean;
  // local parameters
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  persistedSelections: BasketAffinitiesPersistedSelections;
  persistedSelectionsLoaded: boolean;
  // the reportlet data for the associated items chart and table
  purchasedWithProducts: PurchasedWithProduct[];
  // whether the purchased with products have been initialised
  purchasedWithProductsInitialised: boolean;
  purchasedWithProductsSortingState: SortingState;
  reportId: string;
  reportName: string;
  reportParameters?: ReportParametersDto;
  // the table reportlet search string
  reportletSearchQuery: string;
  // the report top drawer search string
  searchQuery: string;

  showAssociatedItemsChartDataLabels: boolean;
};

// this is the initial state of the store
export const initialState: BasketAffinitiesState = {
  isEmptyReport: false,
  localParameters: [],
  localParametersInitialised: false,
  persistedSelections: {
    focalItem: undefined,
    focalItemRowTableRowSelectionState: {},
    localParametersSelection: {
      time: "",
      timePeriodLength: "",
      channel: {
        label: "",
        value: "",
      },
      segment: {
        label: "",
        value: "",
      },
      segmentation: {
        label: "",
        value: "",
      },
      location: {
        code: "",
        depth: 0,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: 0,
        shortName: "",
      },
      upliftThreshold: 0,
      upliftRange: {
        maximum: undefined,
        minimum: undefined,
      },
      basketsWithBothThreshold: 0,
      basketsWithBothRange: {
        maximum: undefined,
        minimum: undefined,
      },
    },
  },
  persistedSelectionsLoaded: false,
  reportId: "",
  reportName: "",
  reportParameters: undefined,
  reportletSearchQuery: "",
  searchQuery: "",
  purchasedWithProducts: [],
  purchasedWithProductsInitialised: false,
  purchasedWithProductsSortingState: [{ id: "Uplift", desc: true }],
  focalItemTableMetadata: {
    metricMetadata: [],
  },
  focalItemTableInitialised: false,
  focalItemTableRows: [],
  associatedItemColours: new Map<AssociatedAncestor, string>(),
  associatedAncestor: {
    associatedAncestor: {
      itemCode: "",
      name: "",
    },
    associatedItemsCount: 0,
  },
  associatedItems: [],
  showAssociatedItemsChartDataLabels: false,
};

export const basketAffinitiesSlice = createSlice({
  initialState,
  name: "basketAffinities",
  reducers: {
    onReportOpen: (
      state: BasketAffinitiesState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: BasketAffinitiesPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
    onLocalParametersReceived: (
      state: BasketAffinitiesState,
      action: PayloadAction<BasketAffinitiesReportParametersResponseDto>
    ) => {
      state.reportName = action.payload.reportName;

      if (action.payload.isEmptyReport) {
        state.isEmptyReport = true;
        state.purchasedWithProducts = [];
        state.purchasedWithProductsInitialised = true;
        return;
      }

      state.localParameters = action.payload.localParameters;
      state.localParametersInitialised = true;

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParametersSelection =
          getDefaultSelections(action.payload.localParameters);
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onReportParametersReceived: (
      state: BasketAffinitiesState,
      action: PayloadAction<ReportParametersDto>
    ) => {
      state.reportParameters = action.payload;
    },
    onChannelChange: (
      state: BasketAffinitiesState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelection.channel = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: BasketAffinitiesState,
      action: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localParametersSelection.segmentation = {
        value: action.payload.segmentationValue,
        label: action.payload.segmentationLabel,
      };
      state.persistedSelections.localParametersSelection.segment = {
        value: action.payload.segmentValue,
        label: action.payload.segmentLabel,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onUpliftChange: (
      state: BasketAffinitiesState,
      action: PayloadAction<number>
    ) => {
      state.persistedSelections.localParametersSelection.upliftThreshold =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onBasketsWithBothThresholdChange: (
      state: BasketAffinitiesState,
      action: PayloadAction<number>
    ) => {
      state.persistedSelections.localParametersSelection.basketsWithBothThreshold =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onFocalItemChange: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<HierarchyValueDto>
    ) => {
      // triggered when a focal item changes. Set to the new focal item and highlight the table row
      state.persistedSelections.focalItem = payload;
      state.persistedSelections.focalItemRowTableRowSelectionState = {
        [payload.name]: true,
      };

      const tableRow = state.focalItemTableRows.find(
        (row) =>
          row.product.shortName ===
            state.persistedSelections.focalItem?.shortName &&
          row.product.itemCode === state.persistedSelections.focalItem.itemCode
      );

      const uplift = tableRow?.uplift;

      state.persistedSelections.localParametersSelection.upliftRange = {
        maximum: uplift?.maximum,
        minimum: uplift?.minimum,
      };
      state.persistedSelections.localParametersSelection.upliftThreshold =
        uplift?.default ?? 0;

      const basketsWithBoth = tableRow?.basketsWithBoth;

      state.persistedSelections.localParametersSelection.basketsWithBothRange =
        {
          maximum: basketsWithBoth?.maximum,
          minimum: basketsWithBoth?.minimum,
        };
      state.persistedSelections.localParametersSelection.basketsWithBothThreshold =
        basketsWithBoth?.default ?? 0;
      state.purchasedWithProductsInitialised = false;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onReportletSearchQueryChange: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<string>
    ) => {
      state.reportletSearchQuery = payload;
    },
    onSearchQueryChange: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<string>
    ) => {
      state.searchQuery = payload;
    },
    onTableDataReceived: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<TableResponse>
    ) => {
      state.focalItemTableMetadata = payload.tableMetadata;
      state.focalItemTableRows = payload.tableRows;
      const tableRow = payload.tableRows[0];
      // select the first product as the default focal item if there was no previous focal item
      if (!state.persistedSelections.focalItem) {
        state.persistedSelections.focalItem = tableRow.product;
        state.persistedSelections.focalItemRowTableRowSelectionState = {
          [tableRow.product.name]: true,
        };

        const upliftRange = tableRow.uplift;

        state.persistedSelections.localParametersSelection.upliftRange = {
          maximum: upliftRange.maximum,
          minimum: upliftRange.minimum,
        };
        state.persistedSelections.localParametersSelection.upliftThreshold =
          upliftRange.default;

        const basketsWithBothRange = tableRow.basketsWithBoth;
        state.persistedSelections.localParametersSelection.basketsWithBothRange =
          {
            maximum: basketsWithBothRange.maximum,
            minimum: basketsWithBothRange.minimum,
          };

        state.persistedSelections.localParametersSelection.basketsWithBothThreshold =
          basketsWithBothRange.default;
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setFocalItemTableInitialised: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.focalItemTableInitialised = payload;
    },
    setReportletIsInitialised: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.purchasedWithProductsInitialised = payload;
    },
    onReportletDataReceived: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<ReportletsDto>
    ) => {
      state.purchasedWithProducts = payload.purchasedWithProducts;
      // assign colours to the products
      const map = new Map<AssociatedAncestor, string>();
      for (let index = 0; index < state.purchasedWithProducts.length; index++) {
        map.set(
          state.purchasedWithProducts[index].associatedAncestor,
          getColourByIndex(index)
        );
      }

      state.associatedItemColours = map;
    },
    onReportletTableSort: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<Updater<SortingState>>
    ) => {
      if (typeof payload === "function") {
        state.purchasedWithProductsSortingState = payload(
          state.purchasedWithProductsSortingState
        );
      } else {
        state.purchasedWithProductsSortingState = payload;
      }
    },
    setAssociatedAncestor: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<AssociatedItem>
    ) => {
      state.associatedAncestor = payload;
    },
    resetAssociatedAncestor: (state: BasketAffinitiesState) => {
      state.associatedAncestor = initialState.associatedAncestor;
    },
    onLocationChange: (
      state: BasketAffinitiesState,
      { payload }: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localParametersSelection.location = payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    toggleAssociatedItemsChartDataLabels: (state: BasketAffinitiesState) => {
      state.showAssociatedItemsChartDataLabels =
        !state.showAssociatedItemsChartDataLabels;
    },
  },
});

// Selectors
export const selectFocalItemTableMetadata = createSelector(
  (state: RootState) => state.basketAffinities.focalItemTableMetadata,
  (tableMetadata: TableMetadata) => tableMetadata
);

export const selectFocalItemTableRows = createSelector(
  (state: RootState) => state.basketAffinities.focalItemTableRows,
  (tableRows: TableRow[]) => tableRows
);

export const selectFocalItemTableRowsSearch = createSelector(
  (state: RootState) => state.basketAffinities,
  (basketAffinitiesState: BasketAffinitiesState) => {
    if (basketAffinitiesState.searchQuery) {
      const rowNameMatchesSearch = createRowNameMatchesSearchPredicate(
        basketAffinitiesState.searchQuery.toLowerCase()
      );
      return basketAffinitiesState.focalItemTableRows.filter(
        (value: TableRow) =>
          rowNameMatchesSearch(value.product.name.toLowerCase())
      );
    } else {
      return basketAffinitiesState.focalItemTableRows;
    }
  }
);

export const selectUpliftThreshold = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection
      .upliftThreshold,
  (upliftThreshold: number) => upliftThreshold
);

export const selectBasketsWithThreshold = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection
      .basketsWithBothThreshold,
  (basketsWithBothThreshold: number) => basketsWithBothThreshold
);

export const selectAssociatedItems = createSelector(
  [
    (state: RootState) => state.basketAffinities.purchasedWithProducts,
    selectUpliftThreshold,
    selectBasketsWithThreshold,
  ],
  associatedItemsAggregation
);

export const selectAssociatedAncestor = createSelector(
  (state: RootState) => state.basketAffinities.associatedAncestor,
  (associatedAncestor: AssociatedItem) => associatedAncestor
);

export const selectReportName = createSelector(
  (state: RootState) => state.basketAffinities.reportName,
  (reportName: string) => reportName
);

export const selectFocalItem = createSelector(
  (state: RootState) => state.basketAffinities.persistedSelections.focalItem,
  (focalItem?: HierarchyValueDto) => focalItem
);

// select the data from a single selected Focal Item
export const selectFocalItemData = createSelector(
  [selectFocalItemTableMetadata, selectFocalItemTableRows, selectFocalItem],
  (
    focalItemTableMetadata: TableMetadata,
    focalItemTableRows: TableRow[],
    focalItem?: HierarchyValueDto
  ) => {
    const tableRow = focalItemTableRows.find(
      (row) =>
        row.product.shortName === focalItem?.shortName &&
        row.product.itemCode === focalItem.itemCode
    );

    if (tableRow) {
      return focalItemTableMetadata.metricMetadata.map((metric, index) => ({
        metric: metric.metricLabel,
        value: tableRow.metrics[index].metricValue,
      }));
    }

    return [];
  }
);

export const selectIsEmptyReport = createSelector(
  (state: RootState) => state.basketAffinities.isEmptyReport,
  (isEmptyReport: boolean) => isEmptyReport
);

export const selectLocalParameters = createSelector(
  (state: RootState) => state.basketAffinities.localParameters,
  (localParameters: SidePanelParameter[]) => localParameters
);

export const selectReportParameters = createSelector(
  (state: RootState) => state.basketAffinities.reportParameters,
  (reportParameters?: ReportParametersDto) => reportParameters
);

export const selectLocalParametersSelection = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection,
  (localSelections: BasketAffinitiesLocalSelections) => localSelections
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.basketAffinities.localParametersInitialised,
  (isInitialised: boolean) => isInitialised
);

export const selectFocalItemInitialised = createSelector(
  (state: RootState) => state.basketAffinities.focalItemTableInitialised,
  (isInitialised: boolean) => isInitialised
);

export const selectPurchasedWithProductsInitialised = createSelector(
  (state: RootState) => state.basketAffinities.purchasedWithProductsInitialised,
  (isInitialised: boolean) => isInitialised
);

export const selectIsDataLoading = createSelector(
  [
    selectLocalParametersInitialised,
    selectFocalItemInitialised,
    selectPurchasedWithProductsInitialised,
  ],
  (localParametersLoaded, focalItemLoaded, reportletsLoaded) =>
    !localParametersLoaded || !focalItemLoaded || !reportletsLoaded
);

export const selectChannel = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection.channel,
  (channel: PanelOption) => channel
);

export const selectSegment = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection.segment,
  (segment: PanelOption) => segment
);

export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection
      .segmentation,
  (segmentation: PanelOption) => segmentation
);

export const selectLocation = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection
      .location,
  (location: LocalHierarchyNodeSelection) => location
);

export const selectTime = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection.time,
  (value) => value
);

export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection
      .timePeriodLength,
  (value) => value
);

export const selectFocalItemRowTableRowSelectionState = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections
      .focalItemRowTableRowSelectionState,
  (selectionState: Record<string, boolean>) => selectionState
);

export const selectReportletSearchQuery = createSelector(
  (state: RootState) => state.basketAffinities.reportletSearchQuery,
  (value: string) => value
);

export const selectSearchQuery = createSelector(
  (state: RootState) => state.basketAffinities.searchQuery,
  (value: string) => value
);

export const selectPurchasedWithProducts = createSelector(
  (state: RootState) => state.basketAffinities.purchasedWithProducts,
  (purchasedWithProducts: PurchasedWithProduct[]) => purchasedWithProducts
);

export const selectUpliftRange = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection
      .upliftRange,
  (value) => value
);

export const selectBasketsWithBothRange = createSelector(
  (state: RootState) =>
    state.basketAffinities.persistedSelections.localParametersSelection
      .basketsWithBothRange,
  (value) => value
);

export const selectPurchasedWithProductsHasProducts = createSelector(
  [selectPurchasedWithProducts],
  (purchasedWithProducts: PurchasedWithProduct[]) =>
    Boolean(purchasedWithProducts.length)
);

export const selectPurchasedWithProductsSortingState = createSelector(
  (state: RootState) =>
    state.basketAffinities.purchasedWithProductsSortingState,
  (sortingState: SortingState) => sortingState
);

export const selectMaxUplift = createSelector(
  (state: RootState) => state.basketAffinities.purchasedWithProducts,
  getMaxUplift
);

export const selectAssociatedItemColours = createSelector(
  (state: RootState) => state.basketAffinities.associatedItemColours,
  (associatedItemColours: Map<AssociatedAncestor, string>) =>
    associatedItemColours
);

export const selectAssociatedAncestorAncestorItemCode = createSelector(
  (state: RootState) =>
    state.basketAffinities.associatedAncestor.associatedAncestor.itemCode,
  (code: string) => code
);

// Returns a table row selection state, in the format
// { [itemId]: true, ... }
export const selectReportletRowSelectionState = createSelector(
  [selectAssociatedAncestorAncestorItemCode, selectPurchasedWithProducts],
  (selectedItemCode: string, products: PurchasedWithProduct[]) => {
    const selectionState: Record<string, boolean> = {};
    for (const product of products.filter(
      (purchasedProduct) =>
        purchasedProduct.associatedAncestor.itemCode === selectedItemCode
    )) {
      selectionState[product.product.itemCode] = true;
    }

    return selectionState;
  }
);

// This is the purchased with products that have been filtered by the search query
export const selectFilteredPurchasedWithProducts = createSelector(
  [
    selectPurchasedWithProducts,
    selectReportletSearchQuery,
    selectUpliftThreshold,
    selectBasketsWithThreshold,
  ],
  (
    products: PurchasedWithProduct[],
    searchString: string,
    upliftThreshold: number,
    basketsWithBothThreshold: number
  ) =>
    searchString
      ? products.filter(
          (x) =>
            x.product.name.toLowerCase().includes(searchString.toLowerCase()) &&
            x.uplift >= upliftThreshold &&
            x.basketsWithBoth >= basketsWithBothThreshold
        )
      : products.filter(
          (x) =>
            x.uplift >= upliftThreshold &&
            x.basketsWithBoth >= basketsWithBothThreshold
        )
);

export const selectShowAssociatedItemsChartDataLabels = createSelector(
  (state: RootState) =>
    state.basketAffinities.showAssociatedItemsChartDataLabels,
  (showAssociatedItemsChartDataLabels: boolean) =>
    showAssociatedItemsChartDataLabels
);

export const {
  onBasketsWithBothThresholdChange,
  onChannelChange,
  onFocalItemChange,
  onLocalParametersReceived,
  onReportParametersReceived,
  onLocationChange,
  setFocalItemTableInitialised,
  setReportletIsInitialised,
  onReportletDataReceived,
  onReportletTableSort,
  onReportletSearchQueryChange,
  onReportOpen,
  onSearchQueryChange,
  onSegmentationChange,
  onTableDataReceived,
  onUpliftChange,
  setAssociatedAncestor,
  resetAssociatedAncestor,
  toggleAssociatedItemsChartDataLabels,
} = basketAffinitiesSlice.actions;

export default basketAffinitiesSlice.reducer;
