/* eslint-disable canonical/id-match */
type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};
export const GrowingStableDecliningIcon = ({
  className,
  coloured,
  text,
}: Props) => {
  const colour1 = coloured
    ? "var(--cg-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour2shade1 = coloured
    ? "var(--cg-icon-colour-three-shade-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2shade2 = coloured
    ? "var(--cg-icon-colour-three-shade-two)"
    : "var(--icon-colour-two-disabled)";
  const colour2shade3 = coloured
    ? "var(--cg-icon-colour-one)"
    : "var(--icon-colour-three-disabled)";
  const colour2shade4 = coloured
    ? "var(--cg-icon-colour-one)"
    : "var(--icon-colour-three-disabled)";
  const colour3shade1 = coloured
    ? "var(--cg-icon-colour-one-shade-one)"
    : "var(--icon-colour-two-disabled)";
  const colour3shade2 = coloured
    ? "var(--cg-icon-colour-one-shade-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3shade3 = coloured
    ? "var(--cg-icon-colour-one-shade-three)"
    : "var(--icon-colour-three-disabled)";
  const colour3shade4 = coloured
    ? "var(--cg-icon-colour-three)"
    : "var(--cg-icon-colour-shade-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Growing / stable / declining customer group"}</title>
      <g style={{ mixBlendMode: "multiply" }}>
        <circle cx="12" cy="12" fill={colour1} r="2.5" />
      </g>
      <g clipPath="url(#clip0_1991_122906)">
        <path
          d="M19.5 11C18.8 11 18.17 10.7125 17.715 10.25C17.2725 10.7 17 11.3175 17 12C17 13.38 18.12 14.5 19.5 14.5C20.88 14.5 22 13.38 22 12C22 11.3175 21.7275 10.7 21.285 10.25C20.8325 10.7125 20.2 11 19.5 11Z"
          fill={colour2shade3}
        />
        <path
          d="M17.715 10.25C18.1675 9.7875 18.8 9.5 19.5 9.5C20.2 9.5 20.83 9.7875 21.285 10.25C21.7275 9.8 22 9.1825 22 8.5C22 7.8175 21.7275 7.2 21.285 6.75C20.8325 7.2125 20.2 7.5 19.5 7.5C18.8 7.5 18.17 7.2125 17.715 6.75C17.2725 7.2 17 7.8175 17 8.5C17 9.1825 17.2725 9.8 17.715 10.25Z"
          fill={colour2shade2}
        />
        <path
          d="M19.5002 9.5C18.8002 9.5 18.1702 9.7875 17.7152 10.25C18.1677 10.7125 18.8002 11 19.5002 11C20.2002 11 20.8302 10.7125 21.2852 10.25C20.8327 9.7875 20.2002 9.5 19.5002 9.5Z"
          fill={colour2shade4}
        />
        <path
          d="M19.5 6C20.2 6 20.83 6.2875 21.285 6.75C21.7275 6.3 22 5.6825 22 5C22 3.62 20.88 2.5 19.5 2.5C18.12 2.5 17 3.62 17 5C17 5.6825 17.2725 6.3 17.715 6.75C18.1675 6.2875 18.8 6 19.5 6Z"
          fill={colour2shade1}
        />
        <path
          d="M19.5002 7.5C20.2002 7.5 20.8302 7.2125 21.2852 6.75C20.8327 6.2875 20.2002 6 19.5002 6C18.8002 6 18.1702 6.2875 17.7152 6.75C18.1677 7.2125 18.8002 7.5 19.5002 7.5Z"
          fill={colour2shade3}
        />
      </g>
      <g clipPath="url(#clip1_1991_122906)">
        <path
          d="M4.5 18C3.8 18 3.17 17.7125 2.715 17.25C2.2725 17.7 2 18.3175 2 19C2 20.38 3.12 21.5 4.5 21.5C5.88 21.5 7 20.38 7 19C7 18.3175 6.7275 17.7 6.285 17.25C5.8325 17.7125 5.2 18 4.5 18Z"
          fill={colour3shade1}
        />
        <path
          d="M2.715 17.25C3.1675 16.7875 3.8 16.5 4.5 16.5C5.2 16.5 5.83 16.7875 6.285 17.25C6.7275 16.8 7 16.1825 7 15.5C7 14.8175 6.7275 14.2 6.285 13.75C5.8325 14.2125 5.2 14.5 4.5 14.5C3.8 14.5 3.17 14.2125 2.715 13.75C2.2725 14.2 2 14.8175 2 15.5C2 16.1825 2.2725 16.8 2.715 17.25Z"
          fill={colour3shade2}
        />
        <path
          d="M4.50016 16.5C3.80016 16.5 3.17016 16.7875 2.71516 17.25C3.16766 17.7125 3.80016 18 4.50016 18C5.20016 18 5.83016 17.7125 6.28516 17.25C5.83266 16.7875 5.20016 16.5 4.50016 16.5Z"
          fill={colour3shade3}
        />
        <path
          d="M4.5 13C5.2 13 5.83 13.2875 6.285 13.75C6.7275 13.3 7 12.6825 7 12C7 10.62 5.88 9.5 4.5 9.5C3.12 9.5 2 10.62 2 12C2 12.6825 2.2725 13.3 2.715 13.75C3.1675 13.2875 3.8 13 4.5 13Z"
          fill={colour3shade3}
        />
        <path
          d="M4.50016 14.5C5.20016 14.5 5.83016 14.2125 6.28516 13.75C5.83266 13.2875 5.20016 13 4.50016 13C3.80016 13 3.17016 13.2875 2.71516 13.75C3.16766 14.2125 3.80016 14.5 4.50016 14.5Z"
          fill={colour3shade4}
        />
      </g>
      <defs>
        <clipPath id="clip0_1991_122906">
          <rect
            fill="white"
            height="12"
            transform="translate(22 14.5) rotate(180)"
            width="5"
          />
        </clipPath>
        <clipPath id="clip1_1991_122906">
          <rect
            fill="white"
            height="12"
            transform="translate(7 21.5) rotate(-180)"
            width="5"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
