import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { type SeriesOptionsType } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HighchartsCustomTooltip } from "../../highcharts-custom-tooltip/HighchartsCustomTooltip";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import {
  HighchartsReact,
  defaultOptions,
} from "../highcharts-react/HighchartsReact";
import styles from "./BasicColumnAndLineChart.module.scss";

export type ChartDataSeries = {
  color: string;
  data: Array<number | string>;
  name: string;
  type: "column" | "line";
};

type BasicColumnAndLineChartProps = {
  alwaysDisplaySign?: boolean;
  categories: string[];
  chartData: ChartDataSeries[];
  chartDataFormatter: FormatterFunction;
  height?: string;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  showChartDataLabels?: boolean;
  tooltipLabel: string;
  yAxisLabel: string;
};

export const BasicColumnAndLineChart = ({
  alwaysDisplaySign,
  chartData,
  categories,
  chartDataFormatter,
  onOptionsChanged,
  height,
  tooltipLabel,
  yAxisLabel,
  showChartDataLabels,
}: BasicColumnAndLineChartProps) => {
  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const optionsState: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        height,
        style: {
          fontFamily: `var(--qbit-font-family)`,
        },
      },
      legend: {
        align: "left",
        enabled: true,
        itemDistance: 32,
        itemStyle: {
          color: "var(--qbit-colour-text-secondary)",
          fontFamily: `var(--qbit-font-family)`,
          fontSize: "0.75rem",
          fontWeight: "400",
        },
        symbolRadius: 0,
      },
      plotOptions: {
        column: {
          // sets width of bar chart
          pointPadding: 0.1,
          stacking: "normal",
        },
        line: {
          marker: {
            enabled: true,
          },
          shadow: {
            color: "white",
            offsetX: 0,
            offsetY: 0,
            opacity: 100,
            width: 3,
          },
          zIndex: 1,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        series: {
          lineWidth: 4,
          dataLabels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return chartDataFormatter(this.y);
            },
            enabled: showChartDataLabels,
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },

      series: chartData.map(
        (series: ChartDataSeries, index: number): SeriesOptionsType => ({
          ...series,
          visible: !hiddenSeries.includes(index),
        })
      ),

      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsCustomTooltip({
            alwaysDisplaySign,
            chartDataFormatter,
            hasSentiment: true,
            ttData,
            yAxisTitle: tooltipLabel,
          }),
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        visible: true,
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
      yAxis: {
        ...defaultOptions.yAxis,
        labels: {
          formatter() {
            return chartDataFormatter(
              // eslint-disable-next-line react/no-this-in-sfc
              this.value,
              alwaysDisplaySign ?? true,
              "",
              true
            );
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: yAxisLabel,
        },
        visible: true,
      },
    }),
    [
      height,
      showChartDataLabels,
      chartData,
      categories,
      yAxisLabel,
      legendItemClick,
      chartDataFormatter,
      hiddenSeries,
      alwaysDisplaySign,
      tooltipLabel,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(optionsState);
    }
  }, [onOptionsChanged, optionsState]);

  return (
    <div className={styles.parentContainer}>
      <HighchartsReact options={optionsState} />
    </div>
  );
};
