import {
  type LocalSelectedValueDto,
  ddLog,
} from "@quantium-enterprise/common-ui";
import { repertoireApiSlice } from "./repertoire-api-slice";
import { onTableDataReceived } from "./repertoire-slice";

export type RepertoireFocalItemRow = {
  id: string;
  name: string;
  value?: number;
};

export type RepertoireTableMetric = {
  format: string;
  name: string;
};

export type RepertoireFocalDataResponse = {
  focalItemData: RepertoireFocalItemRow[];
  levelOfAnalysis: string;
  metric: RepertoireTableMetric;
};

export type RepertoireFocalDataRequest = {
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
};

export const repertoireTopDrawerApiSlice = repertoireApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRepertoireFocalItemData: builder.query<
      RepertoireFocalDataResponse,
      { divisionName: string; requestPayload: RepertoireFocalDataRequest }
    >({
      async onQueryStarted(argument, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(onTableDataReceived(data));
        } catch (error_) {
          let error: Error | undefined;
          if (error_ instanceof Error) {
            error = error_;
          }

          ddLog("Error retrieving table data", {}, "error", error);
        }
      },
      query: ({ divisionName, requestPayload }) => ({
        body: requestPayload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `GetFocalTableData/${divisionName}`,
      }),
    }),
  }),
});

export const { useLazyGetRepertoireFocalItemDataQuery } =
  repertoireTopDrawerApiSlice;
