import {
  type HierarchyType,
  ProductHierarchy,
  type HierarchyItem,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";

export const productLevelsNamesMap: Record<string, string> = Object.fromEntries(
  Object.keys(ProductHierarchy).map((key) => [
    ProductHierarchy[key as keyof typeof ProductHierarchy],
    key,
  ])
);

export type HierarchyGridItem = HierarchyItem & {
  subRows?: HierarchyGridItem[];
};

export type Hierarchy = {
  items: HierarchyGridItem[];
  type: HierarchyType;
  validLevelSelections?: Record<string, string[]>;
};

export type LocationHierarchyGridItem = LocalHierarchyNodeSelection & {
  subRows?: LocationHierarchyGridItem[];
};

export type LocationHierarchy = {
  items: LocationHierarchyGridItem[];
  type: HierarchyType;
  validLevelSelections?: Record<string, string[]>;
};
