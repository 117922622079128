import { type CustomerProfilingMultiSegmentationResponseDto } from "../models/CustomerProfilingMultiSegmentationResponseDto";
import { type CustomerProfilingRequestDto } from "../models/CustomerProfilingRequestDto";
import { customerProfilingApiSlice } from "./customer-profiling-api-slice";

export const customerProfilingMultiSegmentationApiSlice =
  customerProfilingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getCustomerProfilingMultiSegmentation: builder.query<
        CustomerProfilingMultiSegmentationResponseDto[],
        {
          division: string;
          payload: CustomerProfilingRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetCustomerProfilingMultiSegmentationData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetCustomerProfilingMultiSegmentationQuery } =
  customerProfilingMultiSegmentationApiSlice;
