import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import {
  type Point,
  type PlotBubbleOptions,
  type SeriesLineOptions,
  type SeriesBubbleOptions,
  type SeriesLegendItemClickCallbackFunction,
} from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TooltipIcon } from "../TooltipIcon";
import {
  defaultOptions,
  HighchartsReact,
  type HighchartsReactProps,
} from "../highcharts-react/HighchartsReact";
import styles from "../highcharts-react/HighchartsReact.module.css";
import { type Metric } from "../models";

type PointWithZValue = Point & {
  z: number;
};

const isPointWithZValue = (point: Point): point is PointWithZValue =>
  Object.prototype.hasOwnProperty.call(point, "z");

// eslint-disable-next-line react/jsx-no-useless-fragment
const EmptyTooltipHTML = () => <></>;

const BubbleChartTooltipHTML = (
  metrics: Array<{
    name: number | string | null;
    value: string | null;
  }>,
  primaryHeader: {
    color: string | undefined;
    name: number | string;
    shape: string | undefined;
  }
) => (
  <div className={styles.chartTooltip}>
    <div className={styles.textContainer}>
      <div className={styles.leftText}>
        <TooltipIcon color={primaryHeader.color} shape={primaryHeader.shape} />
        <div className={styles.primaryHeader}>{primaryHeader.name}</div>
      </div>
    </div>
    {metrics.map((metric, index) => (
      <div
        className={styles.textContainer}
        // eslint-disable-next-line react/no-array-index-key
        key={`${metric.name}_${metric.value}_${index}`}
      >
        <div className={styles.leftText}>{metric.name}</div>
        <div className={styles.spacer} />
        <div className={styles.rightText}>{metric.value}</div>
      </div>
    ))}
  </div>
);

export type BubbleChartProps = {
  legendName: string;
  lineLegendItemClick: SeriesLegendItemClickCallbackFunction;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  plotOptions: PlotBubbleOptions;
  series: Array<SeriesBubbleOptions | SeriesLineOptions>;
  showChartDataLabels: boolean;
  xAxisExtendedConfig?: {
    max: number;
    min: number;
  };
  xAxisMetric: Metric;
  yAxisExtendedConfig?: {
    max: number;
    min: number;
  };
  yAxisMetric: Metric;
  zAxisMetric?: Metric;
};

export const BubbleChart = ({
  legendName,
  lineLegendItemClick,
  series,
  showChartDataLabels,
  xAxisExtendedConfig,
  yAxisExtendedConfig,
  xAxisMetric,
  yAxisMetric,
  zAxisMetric,
  plotOptions,
  onOptionsChanged,
}: BubbleChartProps) => {
  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        type: "bubble",
        plotBorderWidth: 1,
      },
      legend: {
        enabled: true,
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        itemMarginBottom: 4,
      },
      plotOptions: {
        bubble: {
          ...plotOptions,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        line: {
          dataLabels: {
            enabled: showChartDataLabels,
          },
          events: {
            legendItemClick: (event) => {
              legendItemClick(event.target.index);
              return lineLegendItemClick;
            },
          },
        },
      },
      xAxis: [
        {
          startOnTick: false,
          endOnTick: false,
          gridLineWidth: 1,
          title: {
            text: xAxisMetric.label,
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: tokens.font.body.weight.bold.toString(),
            },
          },
          ...xAxisExtendedConfig,
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return xAxisMetric.formatter(this.value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
        },
      ],
      yAxis: [
        {
          startOnTick: false,
          endOnTick: false,
          title: {
            text: yAxisMetric.label,
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: tokens.font.body.weight.bold.toString(),
            },
          },
          ...yAxisExtendedConfig,
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return yAxisMetric.formatter(this.value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
        },
      ],
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) => {
          if (!("point" in ttData)) {
            return EmptyTooltipHTML();
          }

          const tooltipData = [
            {
              name: xAxisMetric.label,
              value: xAxisMetric.formatter(ttData.point.x),
            },
            {
              name: yAxisMetric.label,
              value: yAxisMetric.formatter(ttData.point.y),
            },
          ];

          if (
            zAxisMetric &&
            zAxisMetric.name !== xAxisMetric.name &&
            zAxisMetric.name !== yAxisMetric.name &&
            isPointWithZValue(ttData.point)
          ) {
            tooltipData.push({
              name: zAxisMetric.label,
              value: zAxisMetric.formatter(ttData.point.z),
            });
          }

          tooltipData.push({
            name: legendName,
            value: ttData.series.name,
          });

          return BubbleChartTooltipHTML(tooltipData, {
            name: ttData.point.name ? ttData.point.name : ttData.series.name,
            color: String(ttData.color),
            // @ts-expect-error symbolName doesn't seem to be exposed to external use
            shape: ttData.point.graphic?.symbolName,
          });
        },
      },
      series: series.map((option, index) => ({
        ...option,
        visible: !hiddenSeries.includes(index),
      })),
    }),
    [
      hiddenSeries,
      legendItemClick,
      legendName,
      lineLegendItemClick,
      plotOptions,
      series,
      showChartDataLabels,
      xAxisExtendedConfig,
      xAxisMetric,
      yAxisExtendedConfig,
      yAxisMetric,
      zAxisMetric,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
