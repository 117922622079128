import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  useFormatter,
  getNumberFormat,
  useDivision,
} from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import {
  ButtonToggleGroup,
  type ToggleButton,
} from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { GroupColumnChart } from "components-ui/src/charts/group-column-chart/GroupColumnChart";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { ColorRankType } from "components-ui/src/tables/common/models/ColorRankType";
import { HeatMap } from "components-ui/src/tables/heat-map/HeatMap";
import {
  type PropsWithChildren,
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import ChartIcon from "../../../assets/tab-icons/bar-chart-black.svg";
import ChartIconSelected from "../../../assets/tab-icons/bar-chart-white.svg";
import TableIcon from "../../../assets/tab-icons/table-black.svg";
import TableIconSelected from "../../../assets/tab-icons/table-white.svg";
import {
  SALES_INDEXED,
  SALES_INDEXED_THRESHOLD,
} from "../../../common/constants";
import { getFocalItemLabels } from "../../../common/utils/focal-item-labels-utils";
import { HEAT_MAP_COLOURS } from "../../../common/utils/heat-map-colours";
import { type RootState } from "../../../store";
import {
  selectChannel,
  selectFocalItemParents,
  selectFocalItems,
  selectIndexedAgainst,
  selectLocation,
  selectMetric,
  selectPromotion,
  selectSegmentation,
  selectShowCustomerProfilingChartDataLabels,
  selectTime,
  selectTimePeriodLength,
  toggleCustomerProfilingChartDataLabels,
} from "../../services/customer-profiling-slice";
import styles from "./CustomerProfilingMultiSegmentationChart.module.css";
import { csvMultiSegmentationTransformation } from "./csvTransformation";
import {
  getCustomerProfilingMultiSegmentationGroupChartData,
  getCustomerProfilingMultiSegmentationHeatMapData,
} from "./utils";

const buttons: Array<ToggleButton<string>> = [
  {
    displayText: "Table",
    icon: {
      iconDefault: TableIcon,
      iconSelected: TableIconSelected,
    },
    id: "Table",
  },
  {
    displayText: "Chart",
    icon: {
      iconDefault: ChartIcon,
      iconSelected: ChartIconSelected,
    },
    id: "Chart",
  },
];

export type MultiSegmentationChartWrapperProps = {
  itemName: string;
  metricDisplayName: string;
};

export const MultiSegmentationChartWrapper = ({
  children,
  itemName,
  metricDisplayName,
}: PropsWithChildren<MultiSegmentationChartWrapperProps>) => (
  <div className={styles.chartWrapper}>
    <div className={styles.chartLabel}>
      <span className={styles.focalItemLabel}>
        {itemName}
        {itemName && metricDisplayName ? " - " : ""}
      </span>
      <span className={styles.metricLabel}>{metricDisplayName}</span>
    </div>
    {children}
  </div>
);

export const CustomerProfilingMultiSegmentationChart = ({
  reportParameters,
  isExportEnabled,
}: {
  isExportEnabled: boolean;
  reportParameters?: ReportParametersDto;
}) => {
  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState("Table");
  const { locale, currency } = useDivision();

  const channel = useSelector(selectChannel);
  const indexedAgainst = useSelector(selectIndexedAgainst);
  const location = useSelector(selectLocation);
  const promotion = useSelector(selectPromotion);
  const metric = useSelector(selectMetric);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const showCustomerProfilingChartDataLabels = useSelector(
    selectShowCustomerProfilingChartDataLabels
  );
  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);

  const { chartData: rawChartData } = useSelector((state: RootState) => ({
    chartData: state.customerProfiling.customerProfilingMultiSegmentationData,
  }));

  const chartData = useMemo(() => {
    const focalItemLabels = getFocalItemLabels(focalItems, focalItemParents);
    const data = [];
    for (const item of rawChartData) {
      if (item.nodeNumber) {
        data.push({
          ...item,
          itemName: focalItemLabels[item.nodeNumber] ?? item.itemName,
        });
      } else {
        data.push(item);
      }
    }

    return data;
  }, [focalItemParents, focalItems, rawChartData]);

  const formatter = useFormatter();

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const threshold = metric.value.toString().startsWith(SALES_INDEXED)
    ? SALES_INDEXED_THRESHOLD
    : 0;

  const segmentsB = useMemo(
    () => chartData[0].segmentationBItems.map((item) => item.segmentName),
    [chartData]
  );

  const labelFormatter = useMemo(() => {
    const format =
      chartData[0]?.format === "" ? MetricTypes.None : chartData[0]?.format;
    return formatter(format);
  }, [chartData, formatter]);

  const dataTableCsvTransformationCallback = useCallback(
    (index: number) =>
      csvMultiSegmentationTransformation(chartData[index], currencySymbol),
    [chartData, currencySymbol]
  );

  const chartCsvTransformationCallback = useCallback(
    (index: number) =>
      csvMultiSegmentationTransformation(chartData[index], currencySymbol),
    [chartData, currencySymbol]
  );

  const exportChartFilename = useMemo(
    () =>
      cleanFilename(
        `Customer_Profiling_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const exportTableFilename = useMemo(
    () =>
      cleanFilename(
        `Customer_Profiling_Table_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const getParameterSummary = useMemo(
    () => (focalitem: string) =>
      [
        { name: "Focal item", value: focalitem },
        { name: "Time", value: time },
        { name: "Metric", value: metric.label },
        { name: "Index against", value: indexedAgainst.label },
        { name: "Channel", value: channel.label },
        { name: "Promotion", value: promotion.label },
        {
          name: "Segmentation",
          value: segmentation.map((seg) => seg.label).join(", "),
        },
        {
          name: "Location",
          value: `(${location.shortName}) ${location.name}`,
        },
      ],
    [time, metric, indexedAgainst, channel, promotion, segmentation, location]
  );

  const chartContainerReferences = useRef<Array<React.RefObject<HTMLElement>>>(
    []
  );
  useEffect(() => {
    chartContainerReferences.current = chartContainerReferences.current.slice(
      0,
      chartData.length
    );
  }, [chartData]);

  const dataLabelOptions: DataLabelsOptions[] = useMemo(
    () => [
      {
        isSelected: showCustomerProfilingChartDataLabels,
        value: "",
      },
    ],
    [showCustomerProfilingChartDataLabels]
  );

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();
  const reportName = useSelector(
    (state: RootState) => state.customerProfiling.reportName
  );

  const groupChartData = useMemo(
    () =>
      chartData.map((data) =>
        getCustomerProfilingMultiSegmentationGroupChartData(
          data.segmentationBItems
        )
      ),
    [chartData]
  );

  const charts = chartData.map((data, index) => {
    chartContainerReferences.current[index] = React.createRef<HTMLElement>();
    const parameterSummary = getParameterSummary(data.itemName).filter(
      (parameter) => !(parameter.name === "Index against" && !parameter.value)
    );
    if (selectedButton === "Table") {
      const { segmentsA, heatMapData } =
        getCustomerProfilingMultiSegmentationHeatMapData(
          data.segmentationBItems
        );

      return (
        <React.Fragment key={`${data.itemName}-${index + 1}`}>
          <div
            className={classNames({
              [styles.chartOptionsContainer]: isExportEnabled,
            })}
          >
            <DataTableOptions
              filename={exportTableFilename}
              invokeCSVDownload={() =>
                dataTableCsvTransformationCallback(index)
              }
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
            />
          </div>
          <ErrorBoundary>
            <MultiSegmentationChartWrapper
              itemName={data.itemName}
              key={`${data.itemName}-${index + 1}`}
              metricDisplayName={data.displayName}
            >
              {heatMapData.length > 0 ? (
                <HeatMap
                  colorRange={HEAT_MAP_COLOURS}
                  colorRankMethod={ColorRankType.LINEAR}
                  formatter={labelFormatter}
                  leftColumnHeaders={segmentsA}
                  tableData={heatMapData}
                  topCornerHeader="Segments"
                  topRowHeaders={segmentsB}
                />
              ) : (
                <GroupColumnChart
                  categories={segmentsB}
                  data={[]}
                  dataLabelFormatter={labelFormatter}
                  threshold={threshold}
                  tooltipFormatter={labelFormatter}
                  tooltipSecondaryHeader={data.displayName}
                  yAxisTickFormatter={labelFormatter}
                />
              )}
            </MultiSegmentationChartWrapper>
          </ErrorBoundary>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={`${data.itemName}-${index + 1}`}>
          <div
            className={classNames({
              [styles.chartOptionsContainer]: isExportEnabled,
            })}
          >
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.CustomerProfiling} />
                      ),
                      chartTitle: `Customer profiling - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportChartFilename}
              getCSVData={() => chartCsvTransformationCallback(index)}
              getElementToExport={() =>
                chartContainerReferences.current[index]?.current ?? undefined
              }
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
              toggleDataLabels={() => {
                dispatch(toggleCustomerProfilingChartDataLabels());
              }}
            />
          </div>
          <ErrorBoundary>
            <MultiSegmentationChartWrapper
              itemName={data.itemName}
              key={`${data.itemName}-${index + 1}`}
              metricDisplayName={data.displayName}
            >
              <ChartFooterWrapper
                height="385px"
                parameters={parameterSummary}
                ref={chartContainerReferences.current[index]}
              >
                <GroupColumnChart
                  categories={segmentsB}
                  data={groupChartData[index]}
                  dataLabelFormatter={labelFormatter}
                  onOptionsChanged={setCurrentOptions}
                  showChartDataLabels={showCustomerProfilingChartDataLabels}
                  threshold={threshold}
                  tooltipFormatter={labelFormatter}
                  tooltipSecondaryHeader={data.displayName}
                  yAxisTickFormatter={labelFormatter}
                />
              </ChartFooterWrapper>
            </MultiSegmentationChartWrapper>
          </ErrorBoundary>
        </React.Fragment>
      );
    }
  });

  return (
    <div className={styles.reportletButtonGroup}>
      <ButtonToggleGroup
        buttonSelected={selectedButton}
        buttons={buttons}
        setButtonSelected={setSelectedButton}
      />
      {charts}
    </div>
  );
};
