import {
  type RowSelectionState,
  type Updater,
  type Header,
  type ColumnResizeMode,
  type ColumnDef,
  type OnChangeFn,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  type GroupColumnDef,
} from "@tanstack/react-table";
import classNames from "classnames";
import { isNumber } from "highcharts";
import {
  type CSSProperties,
  type ReactElement,
  type PropsWithChildren,
  type PropsWithRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { ColumnResizeModeType } from "../common/column-resizer/ColumnResizeModeType";
import ColumnResizer from "../common/column-resizer/ColumnResizer";
import ColumnSort, {
  getAriaSortedString,
} from "../common/column-sort/ColumnSort";
import {
  DEFAULT_COLUMN_MIN_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_COLUMN_MAX_WIDTH,
  FIRST_COLUMN_WIDTH_MULTIPLY_BY_3,
} from "../common/constants";
import styles from "./BasicTable.module.css";

const DEFAULT_ROW_SELECTION_STATE = {};
const DEFAULT_TABLE_COLUMN_SIZE_CONFIG = {
  minSize: DEFAULT_COLUMN_MIN_WIDTH,
  size: DEFAULT_COLUMN_WIDTH,
  maxSize: DEFAULT_COLUMN_MAX_WIDTH,
};

export type BasicTableRowWithMetadata = {
  meta?: {
    rowClassName?: string;
  };
};

export type BasicTableProps<T> = {
  className?: string;
  columnResizeMode?: ColumnResizeMode;
  columns: Array<ColumnDef<T> | GroupColumnDef<T>>;
  data: T[];
  defaultColumnWidthOverride?:
    | Partial<ColumnDef<T>>
    | Partial<GroupColumnDef<T>>;
  enableColumnResizing?: boolean;
  enableMultiRowSelection?: boolean;
  enableRowSelection?: boolean;
  enableSortingRemoval?: boolean;
  getRowId?: (row: T) => string;
  isSelectOnRowClick?: boolean;
  manualSorting?: boolean;
  onRowClick?: Function;
  onRowDoubleClick?: Function;
  onSortingChange?: OnChangeFn<SortingState>;
  pinFirstColumn?: boolean;
  pinnedColumnStyles?: CSSProperties;
  rowSelectionState?: RowSelectionState;
  setRowSelection?: (rowSelection: Updater<RowSelectionState>) => void;
  sortByDescendingFirst?: boolean;
  sorting?: SortingState;
};

// Important - if setting a default value of a reference type, set it outside the component not inline in the props
// see https://stackoverflow.com/a/71185067
const defaultSort: SortingState = [];

export const BasicTable = <T,>({
  data,
  className,
  columns,
  onRowClick,
  onRowDoubleClick,
  onSortingChange,
  enableMultiRowSelection = false,
  enableRowSelection = false,
  getRowId,
  isSelectOnRowClick = true,
  sorting = defaultSort,
  rowSelectionState = DEFAULT_ROW_SELECTION_STATE,
  setRowSelection,
  manualSorting = false,
  pinFirstColumn,
  pinnedColumnStyles,
  columnResizeMode = ColumnResizeModeType.onChange,
  enableColumnResizing = true,
  defaultColumnWidthOverride,
  enableSortingRemoval = true,
  sortByDescendingFirst = false,
}: PropsWithRef<PropsWithChildren<BasicTableProps<T>>>): ReactElement => {
  const hasClickEvent = useMemo(() => Boolean(onRowClick), [onRowClick]);

  const hasDoubleClickEvent = useMemo(
    () => Boolean(onRowDoubleClick),
    [onRowDoubleClick]
  );

  const isRowSpanColumn = useCallback(
    (header: Header<T, unknown>, index: number) =>
      header.column.depth === 0 && index > 0,
    []
  );

  const firstColumnMinWidth: number = useMemo(
    () =>
      pinFirstColumn &&
      pinnedColumnStyles &&
      isNumber(pinnedColumnStyles.minWidth)
        ? Number(pinnedColumnStyles.minWidth)
        : DEFAULT_COLUMN_MIN_WIDTH * FIRST_COLUMN_WIDTH_MULTIPLY_BY_3,
    [pinFirstColumn, pinnedColumnStyles]
  );

  const table = useReactTable<T>({
    columnResizeMode,
    columns,
    data,
    defaultColumn: defaultColumnWidthOverride
      ? {
          ...DEFAULT_TABLE_COLUMN_SIZE_CONFIG,
          ...defaultColumnWidthOverride,
        }
      : DEFAULT_TABLE_COLUMN_SIZE_CONFIG,
    enableSortingRemoval,
    enableMultiRowSelection,
    enableColumnResizing,
    enablePinning: pinFirstColumn,
    enableRowSelection,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    manualSorting,
    onSortingChange,
    state: {
      rowSelection: rowSelectionState,
      sorting,
    },
    sortDescFirst: sortByDescendingFirst,
  });
  const headerGroupsLength = table.getHeaderGroups().length;

  useEffect(() => {
    if (pinFirstColumn) {
      table.getHeaderGroups()[0].headers[0].column.pin("left");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinFirstColumn]);

  const showColumnResizer = useCallback(
    (header: Header<T, unknown>): boolean => {
      if (pinFirstColumn && header.index === 0) {
        return false;
      }

      if (header.column.getCanResize()) {
        return true;
      }

      return false;
    },
    [pinFirstColumn]
  );

  return (
    <table
      aria-rowcount={data.length || 0}
      className={classNames(styles.basicTable, className)}
      role="table"
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup, index) => (
          <tr key={headerGroup.id} role="row">
            {headerGroup.headers.map((header, headerIndex) => (
              <th
                aria-sort={getAriaSortedString(header.column.getIsSorted())}
                className={classNames(styles.tableHeader, {
                  // NOTE:
                  // hardcoding to always pin first column cells due to react-table
                  // loosing column.getIsPinned state after react lifecycle re-render
                  [styles.pinned]: pinFirstColumn && headerIndex === 0,
                  [styles.placeholder]: header.isPlaceholder,
                  [styles.isrowspanrow]: isRowSpanColumn(header, index),
                  [styles.issubheader]: index === 0 && headerGroupsLength > 1,
                })}
                colSpan={header.colSpan}
                data-testid={`columnheader-${header.id}`}
                key={header.id}
                role="columnheader"
                style={{
                  minWidth:
                    header.index === 0 && pinFirstColumn
                      ? firstColumnMinWidth
                      : header.getSize(),
                  maxWidth:
                    header.index === 0 && pinFirstColumn
                      ? firstColumnMinWidth
                      : header.getSize(),
                }}
              >
                {header.isPlaceholder ? null : (
                  <ColumnSort
                    header={header}
                    isRowSpanColumn={isRowSpanColumn(header, index)}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </ColumnSort>
                )}
                {showColumnResizer(header) ? (
                  <ColumnResizer
                    columnResizeMode={columnResizeMode}
                    deltaOffset={table.getState().columnSizingInfo.deltaOffset}
                    header={header}
                    isRowSpan={isRowSpanColumn(header, index)}
                  />
                ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr
            aria-rowindex={rowIndex}
            className={classNames(styles.tableData, {
              [styles.hasPointer]: hasClickEvent || hasDoubleClickEvent,
              [styles.rowSelected]: row.getIsSelected(),
              [(row.original as BasicTableRowWithMetadata).meta?.rowClassName ??
              ""]: Boolean(
                (row.original as BasicTableRowWithMetadata).meta?.rowClassName
              ),
            })}
            key={row.id}
            onClick={(event: React.MouseEvent<HTMLTableRowElement>) => {
              if (enableRowSelection && isSelectOnRowClick) {
                row.toggleSelected();
              }

              onRowClick?.(event, row.id, row.original);
            }}
            onDoubleClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
              onRowDoubleClick?.(event, row.id, row.original)
            }
            role="row"
          >
            {row.getVisibleCells().map((cell, cellIndex) => (
              <td
                className={classNames({
                  [styles.selected]: row.getIsSelected(),
                  // NOTE:
                  // hardcoding to always pin first column cells due to react-table
                  // loosing column.getIsPinned state after react lifecycle re-render
                  [styles.pinned]: pinFirstColumn && cellIndex === 0,
                })}
                data-testid={`cell-${cell.column.id}`}
                key={cell.id}
                // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
                role="cell"
                style={{
                  minWidth:
                    cellIndex === 0 && pinFirstColumn
                      ? firstColumnMinWidth
                      : cell.column.getSize(),
                  maxWidth:
                    cellIndex === 0 && pinFirstColumn
                      ? firstColumnMinWidth
                      : cell.column.getSize(),
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
