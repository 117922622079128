export const TABLE_PAGE_SIZE = 50;

// Offset is used to allow for persistence of pagination. It is calculated when
// loading persisted selections, and should be equal to the number of additional
// rows to return beyond the page size, so that the first call returns all
// previously loaded rows. This value then offsets the rows that are returned in
// subsequent pages, to continue to load additional data correctly.
export const DEFAULT_PAGINATION = {
  hasNextPage: false,
  hasPreviousPage: false,
  offset: 0,
  pageIndex: 0,
  pageSize: TABLE_PAGE_SIZE,
  totalCount: 0,
  totalPages: 0,
};

export type TableRowsRequestDto = {
  includeMeta: boolean;
  offset: number;
  pageIndex: number;
  pageSize?: number;
  reportId: string;
  searchQuery: string;
};

export type ExportFocalItemRequest = {
  fileName: string;
  localParameters: Array<{ name: string; value: string }>;
  reportId: string;
};
