import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { format, parse } from "date-fns";
import {
  formatMetricValue,
  getCsvColumnHeader,
} from "../../../common/utils/export-parameter-summary-utils";
import { type TrialAndRepeatDistributionPricingResponseDto } from "../../models/TrialAndRepeatDistributionPricingResponseDto";
import { type TrialAndRepeatPurchaseActivityResponseDto } from "../../models/TrialAndRepeatPurchaseActivityResponseDto";
import { type PurchaseActivityTableData } from "../../utils/generateDataTableColumns";

// CSV transformation for Purchase activity chart.
export const paChartCsvTransformation = (
  purchaseActivityResponse: TrialAndRepeatPurchaseActivityResponseDto,
  summaryType: string,
  dataType: string,
  metric: string,
  currencySymbol: string
) => {
  const csvData: string[][] = [];

  if (purchaseActivityResponse.data.length) {
    const data = purchaseActivityResponse.data;
    const dates = purchaseActivityResponse.dates;
    const filteredLegends = purchaseActivityResponse.legends.slice(0, -1);
    const columnHeaders = [
      "Time",
      ...filteredLegends.map((legend) => {
        const metricType = data[0][dataType.toLowerCase()].format;
        if (metricType === MetricTypes.Percentage) {
          return `${metric}(%) - ${legend}`;
        } else {
          return getCsvColumnHeader(metricType, currencySymbol, legend, metric);
        }
      }),
    ];
    csvData.push(columnHeaders);

    for (const [dateIndex, date] of dates.entries()) {
      const row = [format(parse(date, "yyyyMMdd", new Date()), "yyyy-MM-dd")];
      for (
        let legendIndex = 0;
        legendIndex < filteredLegends.length;
        legendIndex++
      ) {
        row.push(
          formatMetricValue(
            data[legendIndex][dataType.toLowerCase()].values[
              summaryType.toLowerCase()
            ][dateIndex]
          )
        );
      }

      csvData.push(row);
    }
  }

  return csvData;
};

// CSV transformation for Purchase activity Table.
export const paTableCsvTransformation = (
  paTableData: PurchaseActivityTableData[] | undefined,
  purchaseActivityResponse: TrialAndRepeatPurchaseActivityResponseDto,
  distributionPricingResponse: TrialAndRepeatDistributionPricingResponseDto,
  currencySymbol: string
) => {
  const csvData: string[][] = [];
  const dates = Array.from(
    new Set([
      ...purchaseActivityResponse.dates,
      ...distributionPricingResponse.dates,
    ])
  ).sort((a: string, b: string) => a.localeCompare(b));

  if (paTableData?.length) {
    const columnHeaders = [
      "",
      ...dates.map((date) =>
        format(parse(date, "yyyyMMdd", new Date()), "yyyy-MM-dd")
      ),
    ];
    csvData.push(columnHeaders);

    for (const tableData of paTableData) {
      const headerValue =
        tableData.format === MetricTypes.Percentage
          ? `${tableData.header}(%)`
          : getCsvColumnHeader(
              tableData.format,
              currencySymbol,
              tableData.header
            );
      const row = [headerValue];
      for (const value of tableData.data) {
        row.push(formatMetricValue(value));
      }

      csvData.push(row);
    }
  }

  return csvData;
};

// CSV transformation for Distribution and pricing chart.
export const dapChartCsvTransformation = (
  distributionPricingResponse: TrialAndRepeatDistributionPricingResponseDto,
  currencySymbol: string
) => {
  const csvData: string[][] = [];
  if (distributionPricingResponse.values.length) {
    const dates = distributionPricingResponse.dates;
    const columnHeaders = [
      "Time",
      ...distributionPricingResponse.legends.map((legend, index) =>
        index === 0 ? `${legend} (${currencySymbol})` : legend
      ),
    ];
    csvData.push(columnHeaders);

    for (const [dateIndex, date] of dates.entries()) {
      const row = [format(parse(date, "yyyyMMdd", new Date()), "yyyy-MM-dd")];
      for (
        let legendIndex = 0;
        legendIndex < distributionPricingResponse.legends.length;
        legendIndex++
      ) {
        row.push(
          formatMetricValue(
            distributionPricingResponse.values[legendIndex][dateIndex]
          )
        );
      }

      csvData.push(row);
    }
  }

  return csvData;
};
