import { type InteractionsVennRequestDto } from "../models/InteractionsVennRequestDto";
import { type InteractionsVennResponseDto } from "../models/InteractionsVennResponseDto";
import { customerCrossShopApiSlice } from "./customer-cross-shop-api-slice";

export const customerCrossShopInteractionsVennApiSlice =
  customerCrossShopApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getInteractionsVennChartData: builder.query<
        InteractionsVennResponseDto,
        { divisionName: string; payload: InteractionsVennRequestDto }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetInteractionsVennChartData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useLazyGetInteractionsVennChartDataQuery } =
  customerCrossShopInteractionsVennApiSlice;
