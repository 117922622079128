import { type SelectableItem } from "../SelectableItem";
import { type SearchStrategy } from "./SearchStrategy";
import { SearchStrategyStatus } from "./SearchStrategy";

export abstract class SearchStrategyBase implements SearchStrategy {
  private status: SearchStrategyStatus = SearchStrategyStatus.Uninitialized;

  private items: SelectableItem[] = [];

  private expandedItems: SelectableItem[] = [];

  public onStatusChanged: ((status: SearchStrategyStatus) => void) | undefined;

  public getStatus(): SearchStrategyStatus {
    return this.status;
  }

  protected setStatus(status: SearchStrategyStatus) {
    this.status = status;

    if (this.onStatusChanged) {
      this.onStatusChanged(status);
    }
  }

  public onItemsChanged: ((status: SelectableItem[]) => void) | undefined;

  public getItems(): SelectableItem[] {
    return this.items;
  }

  protected setItems(items: SelectableItem[]) {
    this.items = items;

    if (this.onItemsChanged) {
      this.onItemsChanged(items);
    }
  }

  public onExpandedItemsChanged:
    | ((items: SelectableItem[]) => void)
    | undefined;

  public getExpandedItems(): SelectableItem[] {
    return this.expandedItems;
  }

  protected setExpandedItems(items: SelectableItem[]) {
    this.expandedItems = items;
    if (this.onExpandedItemsChanged) {
      this.onExpandedItemsChanged(items);
    }
  }

  public abstract setSearchQuery(query: string): void;
  public abstract loadMore(item: SelectableItem): void;
  public abstract expandItem(item: SelectableItem): void;
  public abstract unexpandItem(item: SelectableItem): void;
  public abstract initialize(): void;
  public abstract hierarchyLevelSelected(
    item: SelectableItem,
    shortName: string,
    code: string
  ): void;
}
