import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../../common/components/NoDataChartWrapper";
import { type RootState } from "../../../../store";
import { TimeOfDayDayOfWeekFeatureFlags } from "../../../constants/time-of-day-day-of-week-feature-flags";
import { useGetDayOfWeekChartDataQuery } from "../../../services/time-of-day-day-of-week-day-of-week-api-slice";
import {
  onDayOfWeekDataReceived,
  toggleDayOfWeekChartDataLabels,
} from "../../../services/time-of-day-day-of-week-slice";
import {
  selectFocalItemParents,
  selectFocalItems,
  selectLocalParametersInitialised,
  selectLocalParametersSelections,
} from "../../../services/time-of-day-day-of-week-slice-selectors";
import getLocalParametersSummary from "../../../utils/getLocalParametersSummary";
import getUserSelections from "../../../utils/getUserSelections";
import { ChartSelectors } from "../ChartSelectors";
import styles from "./DayOfWeekReportlet.module.css";
import DayOfWeekReportletChart from "./DayOfWeekReportletChart";
import { csvTransformation } from "./csvTransformation";

export type TimeofDayReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const DayOfWeekReportlet = ({
  infoPanelSummary,
}: TimeofDayReportletProps) => {
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();

  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const localParameterSelections = useSelector(selectLocalParametersSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const {
    reportId,
    dayOfWeekChartData,
    chartSelections,
    showDayOfWeekChartDataLabels,
    reportName,
  } = useSelector((state: RootState) => ({
    reportId: state.timeOfDayDayOfWeek.metaData.reportId,
    dayOfWeekChartData: state.timeOfDayDayOfWeek.dayOfWeekChartData,
    chartSelections: state.timeOfDayDayOfWeek.chartSelections,
    showDayOfWeekChartDataLabels:
      state.timeOfDayDayOfWeek.showDayOfWeekChartDataLabels,
    reportName: state.timeOfDayDayOfWeek.metaData.reportName,
  }));

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[TimeOfDayDayOfWeekFeatureFlags.ReportExport] ?? false;

  const isDataLoaded = localParametersInitialised && reportId && focalItems;
  const { data: dayOfWeekData, isFetching: isDataFetching } =
    useGetDayOfWeekChartDataQuery(
      {
        divisionName: activeDivisionName,
        requestPayload: {
          focalItems,
          localSelectedValues: getUserSelections({
            Channel: localParameterSelections.Channel,
            Metric: localParameterSelections.Metric,
            Promotion: localParameterSelections.Promotion,
            Segmentation: localParameterSelections.Segmentation,
            LocationHierarchy: localParameterSelections.LocationHierarchy,
          }),
          reportId,
        },
      },
      {
        skip: !isDataLoaded,
      }
    );

  useEffect(() => {
    if (dayOfWeekData) {
      dispatch(onDayOfWeekDataReceived(dayOfWeekData));
    }
  }, [dispatch, dayOfWeekData]);

  const chartContainerRef = useRef<HTMLElement>();
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        dayOfWeekChartData,
        currencySymbol,
        chartSelections,
        focalItemParents
      ),
    [dayOfWeekChartData, currencySymbol, chartSelections, focalItemParents]
  );

  const parameterSummary = useMemo(
    () =>
      getLocalParametersSummary(
        localParameterSelections,
        chartSelections,
        focalItems,
        focalItemParents
      ),
    [localParameterSelections, chartSelections, focalItems, focalItemParents]
  );

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Time_of_day_day_of_week_trend_Chart_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const dataLabelOptions: DataLabelsOptions = {
    isSelected: showDayOfWeekChartDataLabels,
    value: "",
  };

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  return (
    <ErrorBoundary>
      <NoDataChartWrapper
        isLoading={isDataFetching || !isDataLoaded || !dayOfWeekData}
        minHeight="460px"
        noData={
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          !dayOfWeekChartData.itemData ||
          dayOfWeekChartData.itemData.length === 0
        }
      >
        <div className={styles.chartSelectorsAndOptionsRow}>
          <ChartSelectors />
          <div className={styles.chartOptionsContainer}>
            <ChartOptions
              dataLabelsOptions={[dataLabelOptions]}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.TimeOfDayDayOfWeek} />
                      ),
                      chartTitle: `Time of day, day of week trends - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={infoPanelSummary}
              toggleDataLabels={() => {
                dispatch(toggleDayOfWeekChartDataLabels());
              }}
            />
          </div>
        </div>
        <ChartFooterWrapper
          height="400px"
          parameters={parameterSummary}
          ref={chartContainerRef}
        >
          <DayOfWeekReportletChart
            onOptionsChanged={setCurrentOptions}
            reportletData={dayOfWeekChartData}
          />
        </ChartFooterWrapper>
      </NoDataChartWrapper>
    </ErrorBoundary>
  );
};

export default DayOfWeekReportlet;
