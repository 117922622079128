import {
  MY_DASHBOARD_PATH,
  type RouteWithLabel,
} from "@quantium-enterprise/common-ui";
import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { dashboardRoutes } from "./Dashboard";
import { store } from "./store";

// eslint-disable-next-line @typescript-eslint/promise-function-async -- this is as per React docs
const DashboardComponent = lazy(() => import("./Dashboard"));

export const dashboardRoute = (): RouteWithLabel => ({
  children: dashboardRoutes,
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <DashboardComponent />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Home",
  path: MY_DASHBOARD_PATH,
});
