export enum GainsAndLossChartColours {
  Gain = "var(--qbit-colour-status-good)",
  Loss = "var(--qbit-colour-status-bad)",
  Net = "var(--qbit-colour-brand-500)",
}

export const getGainsAndLossChartColour: { [key: string]: string } = {
  Gain: GainsAndLossChartColours.Gain,
  Loss: GainsAndLossChartColours.Loss,
  Net: GainsAndLossChartColours.Net,
};
