import { type ParameterDto } from "@quantium-enterprise/common-ui";
import {
  FormBlock,
  FormBlockType,
  Input,
  Select,
} from "@quantium-enterprise/qds-react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { dropdownOptionSelected } from "../../states/report-wizard-slice";
import { ParameterLabel } from "../parameter-label/ParameterLabel";
import styles from "./DropdownParameter.module.css";
import { getDropdownParameterState } from "./DropdownParameterState";

export type DropdownParameterProps = {
  headingText?: string;
  parameterDto: ParameterDto;
};

export const DropdownParameter = ({
  headingText,
  parameterDto,
}: DropdownParameterProps) => {
  const dispatch = useDispatch();

  const { selection } = useAppSelector(
    getDropdownParameterState(parameterDto.id)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelection = parameterDto.options.find(
      (option) => option.value === event.currentTarget.value
    );
    if (newSelection) {
      dispatch(
        dropdownOptionSelected({
          parameterId: parameterDto.id,
          selection: newSelection,
        })
      );
    }
  };

  return (
    <FormBlock
      blockType={FormBlockType.Select}
      className={styles.dropdownParameter}
    >
      <ParameterLabel
        description={parameterDto.description}
        heading={headingText ?? parameterDto.name}
        htmlFor={parameterDto.name}
      />
      <Input>
        <Select
          id={parameterDto.name}
          onChange={handleChange}
          value={selection?.value}
        >
          {parameterDto.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Input>
    </FormBlock>
  );
};
