/*  eslint-disable @typescript-eslint/no-unnecessary-condition -- valid once more ReportTabTypes available */
import {
  FAST_REPORTING_PATH,
  FeatureFlag,
  MY_DASHBOARD_PATH,
  ReportTabTrackingProperties,
  ReportTabTrackingPropertyValue,
  TrackingComponent,
  TrackingEvent,
  ddLog,
  useEventTrackingServiceContext,
  useLazyGetReportParametersQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import { useAppSelector } from "../hooks/hooks";
import { type ReportTab } from "../models/ReportTab";
import { ReportTabType } from "../models/ReportTabType";
import {
  dispatchActivateTab,
  dispatchDeactivateTab,
  dispatchNavigatingToDashboard,
  dispatchRemoveTab,
  dispatchSetActiveTabPosition,
  dispatchAddAdvancedReportingTab,
} from "../services/actions";
import {
  selectActiveFastReportingTabItem,
  selectActiveTab,
  selectActiveTabIndexOnActivate,
  selectNavigateToDashboard,
  selectReportTabs,
} from "../services/report-tabs-slice";
import { isAdvancedReportingTabMetadata } from "../utils/isAdvancedReportingTabMetadata";
import { isFastReportingTabMetadata } from "../utils/isFastReportingTabMetadata";
import { isUrlAdvancedReportingTab } from "../utils/isUrlAdvancedReportingTab";
import { AdancedReportTabsDropdownTab } from "./AdvancedReportingDropdownTab";
import { AdvancedReportingTab } from "./AdvancedReportingTab";
import { FastReportTabsDropdownTab } from "./FastReportingDropdownTab";
import { FastReportingTab } from "./FastReportingTab";
import { ReportTabsDropdown } from "./ReportTabsDropdown";
import styles from "./ReportTabsHeaderContainer.module.css";

export const ReportTabsHeaderContainer = () => {
  const { name: divisionName } = useDivision();
  const navigate = useNavigate();
  const location = useLocation();
  const eventTrackingService = useEventTrackingServiceContext();
  const flags = useFlags();

  const reportTabs = useAppSelector(selectReportTabs);
  const activeTab = useAppSelector(selectActiveTab);
  const activeTabIndexOnActivate = useAppSelector(
    selectActiveTabIndexOnActivate
  );
  const navigateToDashboard = useAppSelector(selectNavigateToDashboard);
  const activeFastReportingTabItem = useAppSelector(
    selectActiveFastReportingTabItem
  );

  const tabSize = 138;
  const tabDropdownSize = 68;

  const [maximumTabs, setMaximumTabs] = useState<number>(reportTabs.length);

  const [triggerReportParameterQuery] = useLazyGetReportParametersQuery();

  const handleSetActiveTab = useCallback(
    (tab: ReportTab) => {
      if (tab.id !== activeTab?.id) {
        dispatchActivateTab(tab.id);
        if (
          tab.reportTabType === ReportTabType.AdvancedReportingTab &&
          isAdvancedReportingTabMetadata(tab.metadata)
        ) {
          navigate(tab.metadata.path);
        }
      }
    },
    [activeTab?.id, navigate]
  );

  const handleSetActiveDropDownTab = useCallback(
    (tab: ReportTab) => {
      dispatchActivateTab(tab.id);
      dispatchSetActiveTabPosition(maximumTabs - 1);

      if (
        tab.reportTabType === ReportTabType.AdvancedReportingTab &&
        isAdvancedReportingTabMetadata(tab.metadata)
      ) {
        navigate(tab.metadata.path);
      }
    },
    [maximumTabs, navigate]
  );

  const handleRemoveTab = useCallback((tab: ReportTab) => {
    dispatchRemoveTab(tab.id);
  }, []);

  // hook for handling resizing of screen to adjust the number of displayable report tabs
  const { ref: reportTabsHeaderRef } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width }) => {
      if (width) {
        const maxTabs = Math.floor((width - tabDropdownSize) / tabSize);

        const newMaxTabs = maxTabs <= 0 ? 1 : maxTabs;

        if (newMaxTabs !== maximumTabs) {
          setMaximumTabs(newMaxTabs);

          if (activeTab && activeTabIndexOnActivate) {
            if (activeTabIndexOnActivate > newMaxTabs - 1) {
              dispatchSetActiveTabPosition(newMaxTabs - 1);
            } else if (activeTabIndexOnActivate <= newMaxTabs - 1) {
              dispatchSetActiveTabPosition(activeTabIndexOnActivate);
            }
          }
        }
      }
    },
  });

  const fetchReportName = useCallback(
    async (path: string, reportId: string) => {
      await triggerReportParameterQuery({ divisionName, reportId })
        .unwrap()
        .then((response) => {
          dispatchAddAdvancedReportingTab(path, response);
        });
    },
    [divisionName, triggerReportParameterQuery]
  );

  // hook for handling deactivating report tabs
  useEffect(
    () => {
      const { matched, reportId } = isUrlAdvancedReportingTab(
        location.pathname
      );

      // if we have an active tab and navigate away from fast reporting deactivate current tab
      if (
        activeTab &&
        !location.pathname.includes(FAST_REPORTING_PATH) &&
        !matched
      ) {
        dispatchDeactivateTab();
      }

      // Only enable AdvancedReportingTabs when feature is turned on
      if (flags?.[FeatureFlag.AdvancedReportingTabs]) {
        // Grab the tabs from the session storage directly as there's a delay when looking in store
        const sessionStorageTabs: ReportTab[] = JSON.parse(
          sessionStorage.getItem(`report-tabs-${divisionName}`) ?? "[]"
        );

        // Should test whether we already have a tab that matches the reportId in the session Storage directly
        const found = sessionStorageTabs.find((tab) => tab.id === reportId);
        if (found) {
          // if the tab isn't active, set it as active tab
          if (found.id !== activeTab?.id) {
            handleSetActiveTab(found);
          }
        } else if (!found && reportId) {
          // Do the fetch for the report name ONLY when there's no tab for the reportId exists
          // trigger a call to fetch for the report name
          fetchReportName(location.pathname, reportId).catch((error) => {
            ddLog(
              `Error fetching report name for reportId: ${reportId}`,
              error
            );
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we dont want to deactivate tabs when "activeTab" changes
    [divisionName, flags, location]
  );

  const handleFastReportingActiveTabChange = useCallback(() => {
    if (divisionName && activeFastReportingTabItem) {
      navigate(`/${divisionName}/${FAST_REPORTING_PATH}`);
      eventTrackingService.trackEvent(
        TrackingComponent.ReportTab,
        TrackingEvent.Opened,
        ReportTabTrackingProperties.reportType(
          ReportTabTrackingPropertyValue.FastReporting
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we dont want to rerender if 'navigate' hook changes
  }, [divisionName, activeFastReportingTabItem, eventTrackingService]);

  const handleAdvancedReportingActiveTabChange = useCallback(() => {
    if (
      divisionName &&
      activeTab?.reportTabType === ReportTabType.AdvancedReportingTab &&
      isAdvancedReportingTabMetadata(activeTab.metadata)
    ) {
      navigate(`${activeTab.metadata.path}`);
      eventTrackingService.trackEvent(
        TrackingComponent.ReportTab,
        TrackingEvent.Opened,
        ReportTabTrackingProperties.reportType(
          ReportTabTrackingPropertyValue.AdvancedReporting
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we dont want to rerender if 'navigate' hook changes
  }, [divisionName, activeTab, eventTrackingService]);

  // hook for handling changing report tabs
  useEffect(() => {
    // if tab removed and it was the last tab then navigate to the dashboard
    if (divisionName && navigateToDashboard) {
      dispatchNavigatingToDashboard();
      navigate(`/${divisionName}/${MY_DASHBOARD_PATH}`);
    } else if (
      activeTab?.reportTabType === ReportTabType.AdvancedReportingTab
    ) {
      handleAdvancedReportingActiveTabChange();
    } else {
      handleFastReportingActiveTabChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we dont want to rerender if 'navigate' hook changes
  }, [
    navigateToDashboard,
    divisionName,
    handleFastReportingActiveTabChange,
    handleAdvancedReportingActiveTabChange,
    activeTab,
  ]);

  const displayableReportTabs = useMemo(
    () => reportTabs.slice(0, maximumTabs),
    [maximumTabs, reportTabs]
  );

  const displayableDropdownTabs = useMemo(
    () => reportTabs.slice(maximumTabs),
    [maximumTabs, reportTabs]
  );

  return (
    <div
      className={styles.headerReportTabs}
      data-testid="report-tabs-container"
      ref={reportTabsHeaderRef}
    >
      {displayableReportTabs.length > 0 &&
        displayableReportTabs.map((drt) => {
          if (
            drt.reportTabType === ReportTabType.FastReportingTab &&
            isFastReportingTabMetadata(drt.metadata)
          ) {
            return (
              <FastReportingTab
                isActive={drt.id === activeTab?.id}
                item={drt.metadata.item}
                key={drt.id}
                onRemoveTab={() => handleRemoveTab(drt)}
                onSetActiveTab={() => handleSetActiveTab(drt)}
              />
            );
          }

          if (
            flags?.[FeatureFlag.AdvancedReportingTabs] &&
            drt.reportTabType === ReportTabType.AdvancedReportingTab &&
            isAdvancedReportingTabMetadata(drt.metadata)
          ) {
            return (
              <AdvancedReportingTab
                isActive={drt.id === activeTab?.id}
                key={drt.id}
                name={drt.metadata.name}
                onRemoveTab={() => handleRemoveTab(drt)}
                onSetActiveTab={() => handleSetActiveTab(drt)}
                type={drt.metadata.type}
              />
            );
          }

          return undefined;
        })}

      {displayableDropdownTabs.length > 0 && (
        <ReportTabsDropdown dropdownTabsCount={displayableDropdownTabs.length}>
          {displayableDropdownTabs.map((ddt) => {
            if (
              ddt.reportTabType === ReportTabType.FastReportingTab &&
              isFastReportingTabMetadata(ddt.metadata)
            ) {
              return (
                <FastReportTabsDropdownTab
                  item={ddt.metadata.item}
                  key={ddt.id}
                  onRemoveTab={() => handleRemoveTab(ddt)}
                  onSetActiveTab={() => {
                    handleSetActiveDropDownTab(ddt);
                  }}
                />
              );
            }

            if (
              flags?.[FeatureFlag.AdvancedReportingTabs] &&
              ddt.reportTabType === ReportTabType.AdvancedReportingTab &&
              isAdvancedReportingTabMetadata(ddt.metadata)
            ) {
              return (
                <AdancedReportTabsDropdownTab
                  key={ddt.id}
                  name={ddt.metadata.name}
                  onRemoveTab={() => handleRemoveTab(ddt)}
                  onSetActiveTab={() => handleSetActiveDropDownTab(ddt)}
                  type={ddt.metadata.type}
                />
              );
            }

            return undefined;
          })}
        </ReportTabsDropdown>
      )}
    </div>
  );
};
