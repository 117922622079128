import { Provider } from "react-redux";
import { store } from "./store";
import { SearchbarOrTab } from "./tab-layout/SearchBarOrTab";

type FastReportingSearchSectionProps = {
  placeholder: string;
  visible: boolean;
};

export const FastReportingSearchSection = ({
  placeholder,
  visible,
}: FastReportingSearchSectionProps) => (
  <Provider store={store}>
    {visible && <SearchbarOrTab placeholder={placeholder} />}
  </Provider>
);
