import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import {
  HighchartsReact,
  type HighchartsReactProps,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { HighchartsCustomTooltip } from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import { type TooltipFormatterContextObject } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type Series } from "../../models/basket-quantities-common-models";

type TooltipProps = {
  benchmark?: number;
  chartDataFormatter?: FormatterFunction;
  hasSentiment?: boolean;
  percentFormatter?: FormatterFunction;
  pointFormatter?: (pt: TooltipFormatterContextObject) => FormatterFunction;
  subtitle: string;
  ttData: TooltipFormatterContextObject;
  yAxisTitle: string;
};

const Tooltip = ({
  ttData,
  yAxisTitle,
  pointFormatter,
  chartDataFormatter,
  percentFormatter,
  hasSentiment,
  benchmark,
  subtitle,
}: TooltipProps) => (
  <HighchartsCustomTooltip.Layout>
    <HighchartsCustomTooltip.Title title={yAxisTitle} />
    <HighchartsCustomTooltip.Subtitle subtitle={subtitle} />
    <HighchartsCustomTooltip.Series
      benchmark={benchmark}
      chartDataFormatter={chartDataFormatter}
      hasSentiment={hasSentiment}
      percentFormatter={percentFormatter}
      pointFormatter={pointFormatter}
      ttData={ttData}
      useSeriesNameForLabels
    />
  </HighchartsCustomTooltip.Layout>
);

export type BasketQuantitiesSummaryBreakdownChartProps = {
  basketSeries: Series[];
  basketSizes: string[];
  metricKey: string;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  viewAs: string;
};

export const BasketQuantitiesSummaryBreakdownChart = ({
  basketSeries,
  basketSizes,
  metricKey,
  onOptionsChanged,
  viewAs,
}: BasketQuantitiesSummaryBreakdownChartProps) => {
  const formatter = useFormatter();

  const categories = useMemo(
    () => basketSizes.filter((basketSize) => basketSize !== "Total"),
    [basketSizes]
  );

  let colourIndex = 0;

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const series = useMemo(() => {
    const basketSeriesToProcess = basketSeries.filter(
      (basket) => basket.metricKey === metricKey && basket.breakdown !== "Total"
    );

    return basketSeriesToProcess.map((basket, index) => {
      const paddedData = basket.data.map((data) =>
        data.metricValue ? data : { ...data, metricValue: null }
      );

      return {
        name: basket.name,
        data: paddedData
          .map((data) => data.metricValue)
          .slice(0, categories.length),
        type: "column",
        color: getColourByIndex(colourIndex++),
        custom: { metricFormat: basket.format },
        visible: !hiddenSeries.includes(index),
      };
    });
  }, [basketSeries, categories.length, colourIndex, hiddenSeries, metricKey]);

  const yAxis: Highcharts.YAxisOptions[] = useMemo(
    () => [
      {
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            const metricFormat =
              self.axis.series[0].userOptions.custom?.metricFormat;
            return formatter(metricFormat)(value, false, "", true);
          },
        },
        title: {
          style: { fontWeight: "bold" },
          text: "Baskets",
        },
        reversedStacks: false,
        visible: true,
        // 1 = Share
        max: viewAs === "1" ? 1 : null,
      },
    ],
    [formatter, viewAs]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      legend: {
        ...defaultOptions.legend,
        // square symbols
        symbolRadius: 0,
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        visible: true,
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          Tooltip({
            pointFormatter: (pt: TooltipFormatterContextObject) =>
              formatter(pt.series.userOptions.custom?.metricFormat),
            hasSentiment: false,
            ttData,
            yAxisTitle: `Baskets`,
            subtitle: `${ttData.x?.toString()} per basket`,
          }),
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
          // sets width of each column by changing padding between them
          pointPadding: 0,
          stacking: "normal",
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        line: {
          marker: {
            enabled: false,
          },
          shadow: {
            color: "white",
            offsetX: 0,
            offsetY: 0,
            opacity: 100,
            width: 3,
          },
          zIndex: 1,
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: series as any,
    }),
    [categories, formatter, legendItemClick, series, yAxis]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
