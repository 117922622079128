import {
  type DivisionDto,
  MY_DASHBOARD_PATH,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import { useNavigate, useParams } from "react-router-dom";

export const useDivision = () => {
  const navigate = useNavigate();
  // This is already handled in the App, so we can assume that the user has loaded
  // The query response should be cached at this point
  const { data: user } = useGetUserQuery();
  const { division: activeDivisionName } = useParams();
  const activeDivision = user?.divisions.find(
    (division) => division.name === activeDivisionName
  );

  if (activeDivision) {
    return activeDivision;
  }

  if (user) {
    // redirect user to the default division when user requested for invalid division or inaccessible division.
    navigate(`/${user.divisions[0].name}/${MY_DASHBOARD_PATH}`);
  }

  return {} as DivisionDto;
};
