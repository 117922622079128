import {
  createRowNameMatchesSearchPredicate,
  type HierarchyValueDto,
} from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { getHierarchyValueId } from "../../basket-quantities/utils/getHierarchyValueId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { type BasketLimitsReportParametersResponseDto } from "../models/BasketLimitsReportParametersResponseDto";
import {
  type InitialTableResponse,
  type TableMetadata,
  type TableRow,
} from "../models/basket-limits-data-table-models";
import { LoadingState } from "../models/basket-limits-parameter-selection-models";
import {
  type BasketLimitsChartRow,
  type ReportletResponseDto,
  type StoreBreakdownRow,
} from "../models/basket-limits-reportlet-models";
import { getDefaultSelections } from "../utils/getDefaultSelection";

const getPersistenceKey = (reportId: string) => `basket-limits-${reportId}`;

export type LocationSelection = {
  name: string;
  shortName: string;
};

export type BasketLimitsLocalSelection = {
  basketLimit: number;
  location: LocationSelection[];
  // locationWithoutShortName: string;
  // both time and location are just the summary info
  time: string;
  timePeriodLength: string;
};

export type BasketLimitsPersistedSelections = {
  focalItem: HierarchyValueDto;
  focalItemTableSelectionState: RowSelectionState;
  localParametersSelection: BasketLimitsLocalSelection;
};

export type BasketLimitsState = {
  basketLimit: {
    maximum?: number;
    minimum?: number;
  };

  basketLimitRangeLoadingState: LoadingState;

  basketLimitsChartRows: BasketLimitsChartRow[];

  focalItemTableMetadata: TableMetadata;
  focalItemTableRows: TableRow[];

  isFetchingReportletData: boolean;

  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;

  persistedSelections: BasketLimitsPersistedSelections;
  persistedSelectionsLoaded: boolean;

  reportId: string;
  reportName: string;
  searchQuery: string;
  showBasketLimitChartDataLabels: boolean;
  storeBreakdownFocalStore: HierarchyValueDto;
  storeBreakdownRows: StoreBreakdownRow[];
  storeBreakdownSearch: string;
  totalTimesExceeded: number;
};

// this is the initial state of the store
export const initialState: BasketLimitsState = {
  basketLimitsChartRows: [],
  focalItemTableRows: [],
  focalItemTableMetadata: {
    metricMetadata: [],
  },
  localParameters: [],
  localParametersInitialised: false,
  reportName: "",
  persistedSelectionsLoaded: false,
  persistedSelections: {
    focalItem: {
      itemCode: "",
      name: "",
      nodeNumber: undefined,
      shortName: "",
    },
    focalItemTableSelectionState: {},
    localParametersSelection: {
      basketLimit: 0,
      location: [],
      time: "",
      timePeriodLength: "",
      // locationWithoutShortName: "",
    },
  },
  searchQuery: "",
  showBasketLimitChartDataLabels: false,
  storeBreakdownRows: [],
  storeBreakdownSearch: "",
  storeBreakdownFocalStore: {
    itemCode: "",
    name: "",
    nodeNumber: undefined,
    shortName: "",
  },
  isFetchingReportletData: true,
  totalTimesExceeded: 0,

  basketLimit: {
    maximum: undefined,
    minimum: undefined,
  },
  basketLimitRangeLoadingState: LoadingState.Uninitialised,
  reportId: "",
};

export const basketLimitsSlice = createSlice({
  initialState,
  name: "basketLimits",
  reducers: {
    onReportOpen: (
      state: BasketLimitsState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: BasketLimitsPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
    onBasketLimitsChange: (
      state: BasketLimitsState,
      action: PayloadAction<number>
    ) => {
      state.persistedSelections.localParametersSelection.basketLimit =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onIsFetchingReportletDataChange: (
      state: BasketLimitsState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingReportletData = payload;
    },
    onFocalItemChange: (
      state: BasketLimitsState,
      { payload }: PayloadAction<HierarchyValueDto>
    ) => {
      const oldFocalItemId = getHierarchyValueId(
        state.persistedSelections.focalItem
      );
      if (
        oldFocalItemId in state.persistedSelections.focalItemTableSelectionState
      ) {
        state.persistedSelections.focalItemTableSelectionState[oldFocalItemId] =
          false;
      }

      state.persistedSelections.focalItem = payload;
      state.persistedSelections.focalItemTableSelectionState[
        getHierarchyValueId(payload)
      ] = true;

      const focalItem = state.focalItemTableRows.find(
        (row) =>
          row.product.shortName === payload.shortName &&
          row.product.itemCode === payload.itemCode
      );

      state.basketLimit = focalItem
        ? {
            maximum: focalItem.basketLimit.maximum,
            minimum: focalItem.basketLimit.minimum,
          }
        : { maximum: 0, minimum: 0 };

      state.persistedSelections.localParametersSelection.basketLimit =
        focalItem?.basketLimit.default ?? 0;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onLocalParametersReceived: (
      state: BasketLimitsState,
      action: PayloadAction<BasketLimitsReportParametersResponseDto>
    ) => {
      state.reportName = action.payload.reportName;
      state.localParameters = action.payload.localParameters;
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParametersSelection =
          getDefaultSelections(action.payload.localParameters);
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onReportletDataReceived: (
      state: BasketLimitsState,
      { payload }: PayloadAction<ReportletResponseDto>
    ) => {
      state.totalTimesExceeded = payload.totalTimesExceeded;
      state.storeBreakdownRows = payload.storeBreakdownRows;
      state.basketLimitsChartRows = payload.basketLimitsChartRows;
      state.isFetchingReportletData = false;
    },
    onSearchQueryChange: (
      state: BasketLimitsState,
      { payload }: PayloadAction<string>
    ) => {
      state.searchQuery = payload;
    },
    onStoreBreakdownSearchChange: (
      state: BasketLimitsState,
      { payload }: PayloadAction<string>
    ) => {
      if (state.storeBreakdownSearch !== payload) {
        state.storeBreakdownSearch = payload;
        // any change should revert the focal selection
        state.storeBreakdownFocalStore = initialState.storeBreakdownFocalStore;
      }
    },
    onStoreBreakdownFocalStoreChange: (
      state: BasketLimitsState,
      { payload }: PayloadAction<HierarchyValueDto>
    ) => {
      state.storeBreakdownFocalStore = payload;
      state.storeBreakdownSearch = `${payload.name} ${payload.itemCode}`;
      document
        .querySelector("#storeBreakdownDataTable")
        ?.scrollIntoView({ behavior: "smooth" });
    },
    resetStoreBreakdownFocalStore: (state: BasketLimitsState) => {
      state.storeBreakdownFocalStore = initialState.storeBreakdownFocalStore;
      state.storeBreakdownSearch = initialState.storeBreakdownSearch;
    },
    onTableDataReceived: (
      state: BasketLimitsState,
      { payload }: PayloadAction<InitialTableResponse>
    ) => {
      state.focalItemTableMetadata = payload.tableMetadata;
      state.focalItemTableRows = payload.tableRows;
      if (!state.persistedSelections.focalItem.itemCode) {
        state.persistedSelections.focalItem = payload.tableRows[0].product;
        state.persistedSelections.focalItemTableSelectionState[
          getHierarchyValueId(state.persistedSelections.focalItem)
        ] = true;
      }

      const defaultTableRow = payload.tableRows[0];

      state.basketLimit = defaultTableRow.basketLimit;
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParametersSelection.basketLimit =
          defaultTableRow.basketLimit.default;
      }

      state.basketLimitRangeLoadingState = LoadingState.Loaded;
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setBasketLimitRangeLoadingState: (
      state: BasketLimitsState,
      { payload }: PayloadAction<LoadingState>
    ) => {
      state.basketLimitRangeLoadingState = payload;
    },
    toggleBasketLimitChartDataLabels: (state: BasketLimitsState) => {
      state.showBasketLimitChartDataLabels =
        !state.showBasketLimitChartDataLabels;
    },
  },
});

// Selectors

export const selectBasketLimit = createSelector(
  (state: RootState) =>
    state.basketLimits.persistedSelections.localParametersSelection.basketLimit,
  (basketLimit: number) => basketLimit
);
export const selectBasketLimitsChartRows = createSelector(
  (state: RootState) => state.basketLimits.basketLimitsChartRows,
  (basketLimitsChartRows: BasketLimitsChartRow[]) => basketLimitsChartRows
);
export const selectBasketLimitsState = (state: RootState) => state.basketLimits;

export const selectFocalItem = createSelector(
  (state: RootState) => state.basketLimits.persistedSelections.focalItem,
  (focalItem: HierarchyValueDto) => focalItem
);
export const selectFocalItemTableMetadata = createSelector(
  (state: RootState) => state.basketLimits.focalItemTableMetadata,
  (tableMetadata: TableMetadata) => tableMetadata
);
export const selectFocalItemTableRows = createSelector(
  (state: RootState) => state.basketLimits.focalItemTableRows,
  (tableRows: TableRow[]) => tableRows
);
export const selectFocalItemTableSelectionState = createSelector(
  (state: RootState) =>
    state.basketLimits.persistedSelections.focalItemTableSelectionState,
  (focalItemTableSelectionState: RowSelectionState) =>
    focalItemTableSelectionState
);
export const selectLocalParameters = createSelector(
  (state: RootState) => state.basketLimits.localParameters,
  (localParameters: SidePanelParameter[]) => localParameters
);
export const selectLocalParametersInitialised = (state: RootState) =>
  state.basketLimits.localParametersInitialised;
export const selectStoreBreakdownSearch = createSelector(
  (state: RootState) => state.basketLimits.storeBreakdownSearch,
  (value) => value
);
export const selectLocalParametersSelection = createSelector(
  (state: RootState) =>
    state.basketLimits.persistedSelections.localParametersSelection,
  (blLocalParametersSelection: BasketLimitsLocalSelection) =>
    blLocalParametersSelection
);
export const selectLocation = createSelector(
  (state: RootState) =>
    state.basketLimits.persistedSelections.localParametersSelection.location,
  (location: LocationSelection[]) => location
);
export const selectReportName = createSelector(
  (state: RootState) => state.basketLimits.reportName,
  (reportName: string) => reportName
);
export const selectReportId = createSelector(
  (state: RootState) => state.basketLimits.reportId,
  (reportId: string) => reportId
);
export const selectSearchQuery = createSelector(
  (state: RootState) => state.basketLimits.searchQuery,
  (value: string) => value
);
export const selectStoreBreakdownFocalStore = createSelector(
  selectBasketLimitsState,
  (state: BasketLimitsState) => state.storeBreakdownFocalStore
);
export const selectStoreBreakdownRows = createSelector(
  (state: RootState) => state.basketLimits.storeBreakdownRows,
  (storeBreakdownRows: StoreBreakdownRow[]) => storeBreakdownRows
);
export const selectTime = createSelector(
  (state: RootState) =>
    state.basketLimits.persistedSelections.localParametersSelection.time,
  (value) => value
);
export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.basketLimits.persistedSelections.localParametersSelection
      .timePeriodLength,
  (value) => value
);
export const selectTotalTimesExceeded = createSelector(
  (state: RootState) => state.basketLimits.totalTimesExceeded,
  (totalTimesExceeded: number) => totalTimesExceeded
);
export const selectIsFetchingReportletData = createSelector(
  (state: RootState) => state.basketLimits.isFetchingReportletData,
  (value) => value
);
export const selectBasketLimitsRange = createSelector(
  (state: RootState) => state.basketLimits.basketLimit,
  (basketLimit: { maximum?: number; minimum?: number }) => basketLimit
);
export const selectBasketLimitRangeLoadingState = createSelector(
  (state: RootState) => state.basketLimits.basketLimitRangeLoadingState,
  (isLoaded: LoadingState) => isLoaded
);
export const selectShowBasketLimitChartDataLabels = createSelector(
  (state: RootState) => state.basketLimits.showBasketLimitChartDataLabels,
  (showBasketLimitChartDataLabels: boolean) => showBasketLimitChartDataLabels
);

// combination selectors

export const selectFocalItemTableRowsSearch = createSelector(
  [selectFocalItemTableRows, selectSearchQuery],
  (focalItemTableRows, searchQuery) => {
    if (searchQuery) {
      const searchQueryLowerCase = searchQuery.toLowerCase();
      const rowNameMatchesSearch =
        createRowNameMatchesSearchPredicate(searchQueryLowerCase);
      return focalItemTableRows.filter((value: TableRow) =>
        rowNameMatchesSearch(value.product.name.toLowerCase())
      );
    } else {
      return focalItemTableRows;
    }
  }
);

export const {
  onReportOpen,
  resetStoreBreakdownFocalStore,
  onBasketLimitsChange,
  onFocalItemChange,
  onIsFetchingReportletDataChange,
  onLocalParametersReceived,
  onReportletDataReceived,
  onSearchQueryChange,
  onStoreBreakdownSearchChange,
  onStoreBreakdownFocalStoreChange,
  onTableDataReceived,
  setBasketLimitRangeLoadingState,
  toggleBasketLimitChartDataLabels,
} = basketLimitsSlice.actions;

export default basketLimitsSlice.reducer;
