import {
  type IconGlyph,
  Button,
  ButtonGroup,
  ButtonHeight,
  ButtonVariant,
  ButtonGroupVariant,
  Icon,
  Text,
} from "@quantium-enterprise/qds-react";
import styles from "./ButtonToggleGroup.module.css";

export type ButtonToggleCustomIcon = {
  iconDefault: string;
  iconSelected: string;
};

export enum ButtonToggleGroupTestIds {
  ButtonIcon = "button-toggle-group-icon",
  ButtonToggle = "button-toggle-group",
}

export type ToggleButton<T extends number | string> = {
  displayText?: string;
  icon?: ButtonToggleCustomIcon | IconGlyph;
  id: T;
};

export type ButtonToggleGroupProps<T extends number | string> = {
  buttonSelected: T;
  buttons: Array<ToggleButton<T>>;
  className?: string;
  getIsDisabled?: (buttonId: T) => boolean;
  setButtonSelected: (buttonId: T) => void;
};

export const ButtonToggleGroup = <T extends number | string>({
  buttonSelected,
  buttons,
  className,
  getIsDisabled,
  setButtonSelected,
}: ButtonToggleGroupProps<T>) => (
  <ButtonGroup
    className={styles.buttonToggleGroup}
    variant={ButtonGroupVariant.Segmented}
  >
    {buttons.map((button) => (
      <Button
        className={className}
        data-testid={ButtonToggleGroupTestIds.ButtonToggle}
        disabled={getIsDisabled ? getIsDisabled(button.id) : false}
        height={ButtonHeight.XSmall}
        key={button.id}
        onClick={() => setButtonSelected(button.id)}
        pressed={buttonSelected === button.id}
        variant={ButtonVariant.Secondary}
      >
        <>
          {button.icon &&
            (typeof button.icon === "string" ? (
              <Icon
                data-testid={ButtonToggleGroupTestIds.ButtonIcon}
                glyph={button.icon}
                text={button.displayText ?? ""}
              />
            ) : (
              <img
                alt={button.displayText ?? ""}
                className={styles.customIcon}
                src={
                  buttonSelected === button.id
                    ? button.icon.iconSelected
                    : button.icon.iconDefault
                }
              />
            ))}
          <Text>{button.displayText ?? ""}</Text>
        </>
      </Button>
    ))}
  </ButtonGroup>
);
