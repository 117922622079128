import {
  type ParameterId,
  type TransactionSource,
  type HierarchyGroupEvaluationType,
  type HierarchyType,
} from "@quantium-enterprise/common-ui";
import {
  FormBlock,
  FormBlockType,
  Input,
  Spinner,
  SpinnerSize,
} from "@quantium-enterprise/qds-react";
import { type ColumnDef } from "@tanstack/react-table";
import classNames from "classnames";
import { ConditionalTooltipCheckbox } from "components-ui/src/checkboxes/ConditionalTooltipCheckbox";
import { GroupTransactionSourceIcon } from "components-ui/src/icons/group-transaction-source-icon/GroupTransactionSourceIcon";
import { WizardGroupsModal } from "components-ui/src/information-modal/WizardGroupsModal";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import commonStyles from "components-ui/src/tables/common/GridTable.module.css";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  groupFolderExpanded,
  selectGroupExpandedIds,
  selectMaxGroupSelectionsReached,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./GroupParameterTable.module.css";
import { GroupTitleCell } from "./GroupTitleCell";

// The table requires the data as an array in this format for displaying.
export type GroupInfo = {
  ancestorIds: string[];
  depth: number;
  evaluationType?: HierarchyGroupEvaluationType;
  folderColour?: string;
  hasChildren: boolean;
  hierarchyType?: HierarchyType;
  id: string;
  indeterminate: boolean;
  isFolder: boolean;
  isTransactionSourceLoading: boolean;
  name: string;
  parentId?: string;
  selected: boolean;
  transactionSource?: TransactionSource | null;
};

type TitleCellProps = {
  checkboxDisabledTooltip?: string;
  checked: boolean;
  depth: number;
  disabled: boolean;
  evaluationType?: HierarchyGroupEvaluationType;
  folderColour?: string;
  hasChildren: boolean;
  hideTooltip: boolean;
  hierarchyType?: HierarchyType;
  id: string;
  indeterminate: boolean;
  isExpanded: boolean;
  isFolder: boolean;
  isTransactionSourceLoading: boolean;
  onChange: (groupId: string) => void;
  text: string;
  toggleFolder: () => void;
};

// internal component for the title cell, showing checkbox, icon and text
const TitleCell = ({
  depth,
  id,
  indeterminate,
  isExpanded,
  isFolder,
  isTransactionSourceLoading,
  checkboxDisabledTooltip = "",
  evaluationType,
  folderColour,
  hasChildren,
  hideTooltip,
  hierarchyType,
  text,
  checked,
  disabled,
  onChange,
  toggleFolder,
}: TitleCellProps) => (
  <div className={styles.titleCell}>
    {isFolder && isTransactionSourceLoading ? (
      <Spinner className={styles.folderSpinner} size={SpinnerSize.Small} />
    ) : (
      <FormBlock
        blockType={FormBlockType.Checkbox}
        className={styles.selectBox}
      >
        <Input>
          <ConditionalTooltipCheckbox
            hideTooltip={!checkboxDisabledTooltip || !disabled || hideTooltip}
            indeterminate={indeterminate}
            isChecked={checked}
            isDisabled={disabled}
            label=""
            onChange={(event) => {
              // we want to skip over indeterminate state changes
              // because, in this case, they represent automatic changes
              // from descendant rows propagating up instead of a user action
              // performing a select operation.
              // i.e. onChange here should only fire for rows which have been
              // performed by the user.
              if (!event.currentTarget.indeterminate) {
                onChange(id);
              }
            }}
            tooltipText={checkboxDisabledTooltip}
          />
        </Input>
      </FormBlock>
    )}
    <GroupTitleCell
      depth={depth}
      evaluationType={evaluationType}
      folderColour={folderColour}
      hasChildren={hasChildren}
      hierarchyType={hierarchyType}
      isExpanded={isExpanded}
      isFolder={isFolder}
      text={text}
      toggleFolder={toggleFolder}
    />
  </div>
);

const TransactionSourceCell = (
  isTransactionSourceLoading: boolean,
  isDataEntitlementsShown: boolean,
  transactionSource?: TransactionSource | null
) => (
  <span className={styles.transactionSourceCell}>
    <GroupTransactionSourceIcon
      isDataEntitlementsShown={isDataEntitlementsShown}
      isLoading={isTransactionSourceLoading}
      transactionSource={transactionSource}
    />
  </span>
);

const headerCell = (headerText: string) => (
  <div className={styles.headerCell}>
    {headerText}
    {(headerText === "Product groups" || headerText === "Location groups") && (
      <WizardGroupsModal groupType={headerText} />
    )}
  </div>
);

export type GroupParameterTableProps = {
  groups: GroupInfo[];
  headerText: string;
  isDataEntitlementsShown: boolean;
  parameterId: string;
  toggleGroupSelection: (id: string) => void;
};

export const GroupParameterTable = ({
  groups,
  headerText,
  isDataEntitlementsShown,
  parameterId,
  toggleGroupSelection,
}: GroupParameterTableProps) => {
  const dispatch = useDispatch();
  const maxGroupSelectionsReached = useSelector((state: RootState) =>
    selectMaxGroupSelectionsReached(parameterId, state)
  );
  const expandedIds = useSelector(
    selectGroupExpandedIds(parameterId as ParameterId)
  );

  const columns = useMemo(() => {
    const groupNameColumn = {
      accessorFn: (row) => row.name,
      cell: (info) =>
        TitleCell({
          checked: info.row.original.selected,
          depth: info.row.original.depth,
          disabled:
            info.row.original.transactionSource === null ||
            (maxGroupSelectionsReached && !info.row.original.selected),
          folderColour: info.row.original.folderColour,
          id: info.row.original.id,
          indeterminate: info.row.original.indeterminate,
          isExpanded: Boolean(expandedIds?.includes(info.row.original.id)),
          isFolder: info.row.original.isFolder,
          isTransactionSourceLoading:
            info.row.original.isTransactionSourceLoading,
          onChange: toggleGroupSelection,
          text: info.getValue() as string,
          evaluationType: info.row.original.evaluationType,
          hasChildren: info.row.original.hasChildren,
          hierarchyType: info.row.original.hierarchyType,
          hideTooltip: false,
          checkboxDisabledTooltip: maxGroupSelectionsReached
            ? "Selection limit reached. Please deselect an item to enable selection."
            : "",
          toggleFolder: () => {
            dispatch(
              groupFolderExpanded({
                expandedIds:
                  (expandedIds?.includes(info.row.original.id)
                    ? expandedIds.filter((id) => id !== info.row.original.id)
                    : expandedIds?.concat(info.row.original.id)) ?? [],
                parameter: parameterId,
              })
            );
          },
        }),
      header: () => headerCell(headerText),
      id: "group",
      minSize: 100,
      maxSize: 900,
      size: 300,
    } as ColumnDef<GroupInfo>;

    return [
      groupNameColumn,
      {
        accessorFn: (row) => row.name,
        cell: (info) =>
          TransactionSourceCell(
            info.row.original.isTransactionSourceLoading,
            isDataEntitlementsShown,
            info.row.original.transactionSource
          ),
        header: () => null,
        id: "transactionSource",
        size: 1,
      } as ColumnDef<GroupInfo>,
    ];
  }, [
    dispatch,
    expandedIds,
    maxGroupSelectionsReached,
    headerText,
    isDataEntitlementsShown,
    toggleGroupSelection,
    parameterId,
  ]);

  return (
    <BasicTable
      className={classNames(styles.groupsTable, commonStyles.table)}
      columns={columns}
      data={groups}
      onRowClick={undefined}
    />
  );
};
