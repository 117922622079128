import classNames from "classnames";

type RerunIconProps = {
  text?: string;
};
export const RerunIcon = ({ text }: RerunIconProps) => {
  const classes = classNames("q-icon", "q-icon-medium");
  return (
    <span className={classes}>
      <svg viewBox="-4 -4 40 40" xmlns="http://www.w3.org/2000/svg">
        <title>{text}</title>
        <path
          d="M21.6478 10.35C20.1978 8.9 18.2078 8 15.9978 8C11.5778 8 8.00781 11.58 8.00781 16C8.00781 20.42 11.5778 24 15.9978 24C19.7278 24 22.8378 21.45 23.7278 18H21.6478C20.8278 20.33 18.6078 22 15.9978 22C12.6878 22 9.99781 19.31 9.99781 16C9.99781 12.69 12.6878 10 15.9978 10C17.6578 10 19.1378 10.69 20.2178 11.78L16.9978 15H23.9978V8L21.6478 10.35Z"
          fill="#333333"
        />
      </svg>
    </span>
  );
};
