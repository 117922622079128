import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import { TooltipHTML } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import type Highcharts from "highcharts";
import { type CustomerProfilingMultiSegmentationItem } from "../../models/CustomerProfilingMultiSegmentationResponseDto";
import { type CustomerProfilingSingleSegmentationItem } from "../../models/CustomerProfilingSingleSegmentationResponseDto";

export const renderSingleItemSingleSegmentationGroupChartTooltip = (
  metrics?: Array<{
    color?: string | null;
    name?: number | string | null;
    value?: string | null;
  }>,
  primaryHeader?: number | string,
  extraHeader?: number | string
) => TooltipHTML(metrics, extraHeader ?? "", undefined);

export const getCustomerProfilingSingleSegmentationGroupChartData = (
  items: CustomerProfilingSingleSegmentationItem[]
): Highcharts.SeriesColumnOptions[] => {
  if (items.length === 0) {
    return [];
  }

  const segments = items[0].data.map((x) => x.name);

  // get data from items for each segment
  const segmentData = segments.map((segment) => ({
    segment,
    value: items.map(
      (item) => item.data.find((data) => data.name === segment)?.value ?? null
    ),
  }));

  return segmentData.map((data, index) => ({
    color: getColourByIndex(index),
    name: data.segment,
    data: data.value,
    type: "column",
  }));
};

export const getCustomerProfilingMultiSegmentationHeatMapData = (
  items: CustomerProfilingMultiSegmentationItem[]
): {
  heatMapData: number[][];
  segmentsA: string[];
} => {
  if (items.length === 0) {
    return { segmentsA: [], heatMapData: [] };
  }

  const segmentsA = items[0].segmentationAData.map((x) => x.segmentName);
  const segmentData = segmentsA.map((segment) =>
    items.map(
      (item) =>
        item.segmentationAData.find((data) => data.segmentName === segment)
          ?.value ?? Number.NaN
    )
  );

  return {
    segmentsA,
    heatMapData: segmentData,
  };
};

export const getCustomerProfilingMultiSegmentationGroupChartData = (
  items: CustomerProfilingMultiSegmentationItem[]
): Highcharts.SeriesColumnOptions[] => {
  if (items.length === 0) {
    return [];
  }

  const segments = items[0].segmentationAData.map((x) => x.segmentName);

  // get data from items for each segment
  const segmentData = segments.map((segment) => ({
    segment,
    value: items.map(
      (item) =>
        item.segmentationAData.find((data) => data.segmentName === segment)
          ?.value ?? null
    ),
  }));

  return segmentData.map((data, index) => ({
    color: getColourByIndex(index),
    name: data.segment,
    data: data.value,
    type: "column",
  }));
};
