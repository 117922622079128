import { useCallback, useMemo, useState } from "react";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import CollapsiblePanel from "../CollapsiblePanel";
import { type SegmentOption } from "../segmentFilter/SegmentFilter";
import { MultiSelectSegmentFilter } from "./MultiSelectSegmentFilter";
import { MultiSelectSegmentFilterSummary } from "./MultiSelectSegmentFilterSummary";

type MultiSelectSegmentationToSegmentFilter = {
  segmentationValue: string;
  selectedSegments: string[];
};

export type MultiSelectSegmentFilterWrapperProps = {
  isCollapsed: boolean;
  onSelection: (values: SegmentOption[]) => void;
  segmentOptions: SegmentOption[];
  selectedValues: string[];
  title: string;
  toggleCollapsed: () => void;
};

export const MultiSelectSegmentFilterWrapper = ({
  isCollapsed,
  onSelection,
  segmentOptions,
  selectedValues,
  title,
  toggleCollapsed,
}: MultiSelectSegmentFilterWrapperProps) => {
  // This tracks what is being selected in the checkboxes, e.g. All, Budget, Mainstream, Premium
  const [selectedFilter, setSelectedFilter] =
    useState<MultiSelectSegmentationToSegmentFilter>(() => {
      // set default segmentation to first index of selectedValues else set it to first in available segmentation in the list
      const defaultSegmentationFilter =
        selectedValues[0] ?? segmentOptions[0].segmentationValue;

      const segmentOptionsForDefaultSegmentation = segmentOptions.filter(
        (option) => option.segmentationValue === defaultSegmentationFilter
      );

      // count of all options excluding "All"
      const allOptionsCount = segmentOptionsForDefaultSegmentation.filter(
        (option) => option.segmentValue !== "All"
      ).length;

      // grabbing all selected values from the selectedValues array
      // without index 0 === segmentation, e.g. AFFLUNCE_T1
      let selectedSegments = selectedValues.slice(1);
      if (selectedSegments.length === allOptionsCount) {
        selectedSegments = ["All"];
      }

      return {
        segmentationValue: defaultSegmentationFilter,
        selectedSegments,
      };
    });

  const segmentationOptions: PanelOption[] = useMemo(() => {
    const uniqueSegmentationValues = Array.from(
      new Set(segmentOptions.map((segment) => segment.segmentationValue))
    );

    return uniqueSegmentationValues.map((segmentationValue) => ({
      label:
        segmentOptions.find(
          (segment) => segment.segmentationValue === segmentationValue
        )?.segmentationLabel ?? "",
      value: segmentationValue,
    }));
  }, [segmentOptions]);

  const handleSegmentationChange = (selectedFilterOption: PanelOption) => {
    // assumption when we update the segmentation, we should reset the selected segments to "All"
    setSelectedFilter({
      segmentationValue: selectedFilterOption.value.toString(),
      selectedSegments: ["All"],
    });

    // update store state
    onSelection(
      segmentOptions.filter(
        (segmentOption) =>
          segmentOption.segmentValue !== "All" &&
          segmentOption.segmentationValue ===
            selectedFilterOption.value.toString()
      )
    );
  };

  const handleSegmentChange = useCallback(
    (selectedSegmentation: string, selectedSegment: string) => {
      let updatedSelectedSegments: string[];

      let localSelectedFilter = { ...selectedFilter };
      if (selectedSegmentation !== localSelectedFilter.segmentationValue) {
        // reset filters since currently stored filter no longer valid
        localSelectedFilter = {
          segmentationValue: selectedSegmentation,
          selectedSegments: [],
        };
      }

      if (selectedSegment === "All") {
        // check if All is already selected
        if (localSelectedFilter.selectedSegments.includes("All")) {
          // if All is selected, remove all selections
          updatedSelectedSegments = [];
        } else {
          // if All is not selected, add "All" selection
          updatedSelectedSegments = ["All"];
        }

        // update visual state
        setSelectedFilter({
          ...localSelectedFilter,
          selectedSegments: updatedSelectedSegments,
        });

        // update store state
        onSelection(
          segmentOptions.filter(
            (segmentOption) =>
              segmentOption.segmentValue !== "All" &&
              segmentOption.segmentationValue ===
                localSelectedFilter.segmentationValue
          )
        );
      } else {
        // Remove value from selection if it is already selected
        if (localSelectedFilter.selectedSegments.includes(selectedSegment)) {
          updatedSelectedSegments = selectedFilter.selectedSegments.filter(
            (segment) => segment !== selectedSegment
          );
        } else {
          updatedSelectedSegments = [
            ...localSelectedFilter.selectedSegments,
            selectedSegment,
          ].filter(
            // Remove all selection from list as we're selecting individual segments
            (segment) => segment !== "All"
          );
        }

        const uniqueSelectedSegments = Array.from(
          new Set(updatedSelectedSegments)
        );

        // update visual state
        setSelectedFilter({
          ...localSelectedFilter,
          selectedSegments: uniqueSelectedSegments,
        });

        // update store state
        onSelection(
          segmentOptions.filter(
            (segmentOption) =>
              uniqueSelectedSegments.includes(segmentOption.segmentValue) &&
              segmentOption.segmentationValue ===
                localSelectedFilter.segmentationValue
          )
        );
      }
    },
    [onSelection, segmentOptions, selectedFilter]
  );

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content: (
          <MultiSelectSegmentFilter
            onCheckboxSelection={handleSegmentChange}
            onDropdownSelectionChange={handleSegmentationChange}
            segmentOptions={segmentOptions.filter(
              (segmentOption) =>
                segmentOption.segmentationValue ===
                selectedFilter.segmentationValue
            )}
            segmentationOptions={segmentationOptions}
            selectedSegmentation={selectedFilter.segmentationValue}
            selectedSegments={selectedFilter.selectedSegments}
          />
        ),
        summary: (
          <MultiSelectSegmentFilterSummary
            segmentOptions={segmentOptions}
            selectedSegmentation={
              segmentationOptions.find(
                (option) => option.value === selectedFilter.segmentationValue
              ) ?? segmentationOptions[0]
            }
            selectedSegments={selectedFilter.selectedSegments}
          />
        ),
      }}
    </CollapsiblePanel>
  );
};

export default MultiSelectSegmentFilterWrapper;
