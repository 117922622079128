import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationGroupParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationGroupSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type TimeOfDayDayOfWeekLocalSelections } from "../services/time-of-day-day-of-week-slice";

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): TimeOfDayDayOfWeekLocalSelections => {
  const selections: TimeOfDayDayOfWeekLocalSelections = {
    Channel: {
      value: "",
      label: "",
    },
    Metric: {
      value: "",
      label: "",
    },
    LocationHierarchy: {
      code: "",
      depth: -1,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      nodeNumber: -1,
      shortName: "",
      isDefault: false,
    },
    Promotion: {
      value: "",
      label: "",
    },
    Segmentation: {
      value: "",
      label: "",
    },
    FocusPeriod: "",
    TimePeriodLength: "",
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const { time, timePeriodLength } = getTimeDefaultSelection(parameter);
          selections.FocusPeriod = time;
          selections.TimePeriodLength = timePeriodLength;
        }

        break;
      case LocalParameters.Metric:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.Metric.label = selection.label;
            selections.Metric.value = selection.value;
          }
        }

        break;
      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.Channel.label = selection.label;
            selections.Channel.value = selection.value;
          }
        }

        break;
      case LocalParameters.Promotion:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.Promotion.label = selection.label;
            selections.Promotion.value = selection.value;
          }
        }

        break;
      case LocalParameters.Segmentation:
        if (isSegmentationGroupParameter(parameter)) {
          const selection =
            getSegmentationGroupSelectDefaultSelection(parameter);
          if (selection) {
            selections.Segmentation.label = selection.name;
            selections.Segmentation.value = selection.id;
          }
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter))
          selections.LocationHierarchy = getLocationSelectDefault(parameter);

        break;
      default:
        break;
    }
  }

  return selections;
};
