import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { type ColumnDef } from "@tanstack/react-table";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { useSelector } from "react-redux";
import { type Series } from "../models/pricing-ladders-common-models";
import {
  selectTopDrawerActiveTab,
  selectViewBy,
} from "../services/pricing-ladders-slice";
import styles from "./PricingLaddersTable.module.css";

export type PricingLaddersTableProps = {
  categories: string[];
  series: Series[];
};

type TableDataSeries = {
  data: Array<number | null | undefined>;
  focalitems: string;
};

const defaultPLColumnWidthOverride = {
  minSize: 120,
  maxSize: 240,
};

const generateDataTableColumns = (
  series: Series[],
  formatter: DeferredFormatFunction,
  topDrawerActiveTab: string
): Array<ColumnDef<TableDataSeries>> => {
  const columns: Array<ColumnDef<TableDataSeries>> = [
    {
      accessorFn: (row) => row.focalitems,
      cell: ({ getValue }) => (
        <div className={styles.firstColumn}>{getValue() as string}</div>
      ),
      footer: (properties) => properties.column.id,
      header: () => (
        <>
          <HierarchyLevelIcon
            shortName={topDrawerActiveTab}
            type={HierarchyItemType.Attribute}
          />
          <span className={styles.colHeaders}>Focal item</span>
        </>
      ),
      id: "Focal item",
      enableSorting: false,
    },
    ...series.map(
      (metricSeries, index) =>
        ({
          accessorFn: (row) => row.data[index],
          cell: ({ getValue }) => (
            <ValueCell
              formatter={formatter(metricSeries.format)}
              value={getValue() as number}
            />
          ),
          header: () => (
            <span className={styles.colHeaders}>
              {metricSeries.metricLabel}
            </span>
          ),
          id: metricSeries.metricLabel,
        } as ColumnDef<TableDataSeries>)
    ),
  ];
  return columns;
};

const pivotChartDataToTableData = (
  chartSeries: Series[],
  chartCategories: string[]
): TableDataSeries[] => {
  const tableData = chartCategories.map(
    (category) =>
      ({
        focalitems: category,
        data: [],
      } as TableDataSeries)
  );
  const tableCategories: string[] = [];
  for (const series of chartSeries) {
    tableCategories.push(series.metricLabel);
    for (const [index, dataPoint] of series.data.entries()) {
      tableData[index].data.push(dataPoint.metricValue ?? null);
    }
  }

  return tableData;
};

export const PricingLaddersTable = ({
  series,
  categories,
}: PricingLaddersTableProps) => {
  const sortBy = useSelector(selectViewBy)[1];
  const formatter = useFormatter();
  const topDrawerActiveTab = useSelector(selectTopDrawerActiveTab);

  return (
    <div className={styles.summaryTable} style={{ height: 500 }}>
      <BasicTable
        columns={generateDataTableColumns(
          series,
          formatter,
          topDrawerActiveTab ?? ""
        )}
        data={pivotChartDataToTableData(series, categories)}
        defaultColumnWidthOverride={defaultPLColumnWidthOverride}
        enableSortingRemoval={false}
        pinFirstColumn
        pinnedColumnStyles={{ minWidth: 250 }}
        sorting={[{ id: sortBy.label, desc: false }]}
      />
    </div>
  );
};
