// eslint-disable-next-line no-warning-comments
// TODO: Hierarchy levels are different across different divisions, so in the future this functionality
// should be placed in a DivisionService similar to what we currently have in Scan. See:
// https://github.com/quantium-enterprise/iip-apollo/blob/master/client/scan/src/services/DivisionService.ts#L126

export enum WowProductHierarchyLevel {
  Category = "CAT",
  Department = "DEP",
  Product = "PRD",
  Segment = "SEG",
  Subcategory = "SCT",
}

const WowProductHierarchyOrdinalMapping: Record<
  WowProductHierarchyLevel,
  number
> = {
  [WowProductHierarchyLevel.Department]: 1,
  [WowProductHierarchyLevel.Category]: 2,
  [WowProductHierarchyLevel.Subcategory]: 3,
  [WowProductHierarchyLevel.Segment]: 4,
  [WowProductHierarchyLevel.Product]: 5,
};

export const getProductHierarchyLevelOrdinal = (
  level: WowProductHierarchyLevel
) => WowProductHierarchyOrdinalMapping[level];
