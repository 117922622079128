import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { ListSelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getSegmentFilterParameterState } from "../../parameters/segment-filter/SegmentFilterParameterState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelSegmentFilterProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelSegmentFilter = ({
  parameterDto,
}: SummaryPanelSegmentFilterProps) => {
  const segmentFilterState = useAppSelector(
    getSegmentFilterParameterState(parameterDto.id)
  );

  if (!segmentFilterState) {
    return null;
  }

  const selectionText = segmentFilterState.selection?.label ?? "";

  return (
    <ListSelectionSummary
      label={parameterDto.name}
      selections={[selectionText]}
    />
  );
};
