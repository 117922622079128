import {
  type SegmentStagingDto,
  type SegmentDto,
  type CustomerGroupDtoWithDisplayName,
  useUpdateStagingMutation,
  EMIT_TOAST_DURATION,
  CustomerGroupStatus,
  FeatureFlag,
  ActivationGuidelineStatus,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  QbitToastPosition,
  Text,
} from "@quantium-enterprise/qds-react";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getSegmentPath } from "../../../groups/src/utilities/route-path-formats";
import { SegmentGuidelinesModal } from "../modals/segment-guidelines-modal/SegmentGuidelinesModal";
import styles from "./CustomerGroupSegments.module.css";
import { InProgressSegmentCard } from "./InProgressSegmentCard";
import { InvalidSegmentCard } from "./InvalidSegmentCard";
import { ValidSegmentCard } from "./ValidSegmentCard";

type SegmentCheckboxProps = {
  isGroupValid: boolean;
  isSelected: boolean;
  onClick: (segment: SegmentDto) => void;
  segment: SegmentDto;
};

const SegmentCheckbox = ({
  segment,
  onClick,
  isSelected,
  isGroupValid,
}: SegmentCheckboxProps) => {
  const division = useDivision();

  const count = segment.count;
  const name = segment.name;
  const isInSegmentLibrary =
    segment.stagedActivationDate !== undefined ||
    segment.activationDate !== undefined;

  return (
    <div
      className={[
        styles.segmentCheckboxContainer,
        isSelected && styles.segmentCheckboxContainerSelected,
        (isInSegmentLibrary || !isGroupValid) &&
          styles.segmentCheckboxContainerDisabled,
      ].join(" ")}
    >
      <input
        checked={isSelected}
        className={styles.segmentCheckbox}
        disabled={isInSegmentLibrary || !isGroupValid}
        id={segment.key}
        onClick={() => onClick(segment)}
        type="checkbox"
      />
      <label className={styles.segmentCheckboxLabel} htmlFor={segment.key}>
        <div className={styles.segmentName}>{name}</div>
        <div className={styles.segmentCount}>
          Customers: {count.toLocaleString()}
        </div>
      </label>
      {isInSegmentLibrary && (
        <Link
          className={styles.linkToSegment}
          to={getSegmentPath(
            division.name,
            segment.customerGroupId,
            segment.key
          )}
        >
          <div>View in segment library</div>
        </Link>
      )}
    </div>
  );
};

export type CustomerGroupSegmentsProps = {
  focalGroup?: CustomerGroupDtoWithDisplayName;
};

export const CustomerGroupSegments = ({
  focalGroup,
}: CustomerGroupSegmentsProps) => {
  const division = useDivision();
  const flags = useFlags();
  const [checkedBoxes, setCheckedBoxes] = useState<SegmentDto[]>([]);
  const [showSegmentGuidelinesModal, setShowSegmentGuidelinesModal] =
    useState(false);
  const [updateStaging] = useUpdateStagingMutation();

  const invalidSegments = focalGroup
    ? focalGroup.segments
        .flatMap((segment) => segment.segmentGuidelines)
        .filter((guideline) => guideline?.valid === false).length
    : 0;

  const activationGuidelineFlag =
    flags[`${FeatureFlag.ActivationGuidelines}-${division.name}`];

  // stage segment payload
  const updateStagingPayload = useMemo(
    () => ({
      divisionName: division.name,
      payload: {
        groupId: focalGroup?.id ?? "",
        segmentKeys: checkedBoxes.map(
          (segment) =>
            ({ segmentKey: segment.key, stage: true } as SegmentStagingDto)
        ),
      },
    }),
    [checkedBoxes, division.name, focalGroup?.id]
  );

  // add to segment library handler
  const handleAddToSegmentLibrary = useCallback(async () => {
    let content;
    let heading;
    if (focalGroup?.id) {
      try {
        await updateStaging(updateStagingPayload).unwrap();

        content = "Added to segment library";

        QbitEmitToast(
          <QbitToastMessage
            content={<p>{content}</p>}
            showIcon
            variant={MessageVariant.Success}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
            position: QbitToastPosition.BottomRight,
          }
        );
      } catch {
        content = "An unknown error has occurred";
        heading = "Error";
        QbitEmitToast(
          <QbitToastMessage
            content={<p>{content}</p>}
            heading={<h5>{heading}</h5>}
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
            position: QbitToastPosition.BottomRight,
          }
        );
      }

      setCheckedBoxes([]);
    }
  }, [focalGroup?.id, updateStaging, updateStagingPayload, setCheckedBoxes]);

  // on click handler for segment checkboxes
  const onClickHandler = useCallback(
    (segment: SegmentDto) => {
      let newCheckedBoxes;
      if (checkedBoxes.includes(segment)) {
        newCheckedBoxes = checkedBoxes.filter(
          (checkedSegment) => checkedSegment !== segment
        );
      } else {
        newCheckedBoxes = [...checkedBoxes, segment];
      }

      setCheckedBoxes(newCheckedBoxes);
    },
    [checkedBoxes]
  );

  return (
    <>
      {activationGuidelineFlag &&
        (focalGroup?.activationGuidelineStatus ===
        ActivationGuidelineStatus.InProgress ? (
          <InProgressSegmentCard
            onClick={() => setShowSegmentGuidelinesModal(true)}
          />
        ) : invalidSegments > 0 ? (
          <InvalidSegmentCard
            onClick={() => setShowSegmentGuidelinesModal(true)}
          />
        ) : (
          <ValidSegmentCard
            activationGuidelinesRefreshDateUtc={
              focalGroup?.activationGuidelinesRefreshDateUtc
            }
            onClick={() => setShowSegmentGuidelinesModal(true)}
          />
        ))}
      <Text className={styles.checkboxHeader}>Available segments:</Text>
      {focalGroup?.segments.map((segment) => (
        <SegmentCheckbox
          isGroupValid={focalGroup.status === CustomerGroupStatus.Valid}
          isSelected={checkedBoxes.includes(segment)}
          key={segment.key}
          onClick={onClickHandler}
          segment={segment}
        />
      ))}
      <div className={styles.addToLibraryButton}>
        {checkedBoxes.length > 0 && (
          <Button
            height={ButtonHeight.XSmall}
            onClick={async () => await handleAddToSegmentLibrary()}
            text="Add to segment library"
            variant={ButtonVariant.Primary}
          />
        )}
      </div>
      <SegmentGuidelinesModal
        inProgress={
          focalGroup?.activationGuidelineStatus ===
          ActivationGuidelineStatus.InProgress
        }
        onClose={() => setShowSegmentGuidelinesModal(false)}
        segments={focalGroup?.segments ?? []}
        show={showSegmentGuidelinesModal}
      />
    </>
  );
};
