import {
  type SegmentationType,
  type SegmentationParameterOption,
  type ParameterDto,
  isSegmentationSelectionDto,
  type SelectionDto,
} from "@quantium-enterprise/common-ui";
import { type SegmentValueDto } from "@quantium-enterprise/common-ui/src/models/segmentation-selection-dto";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

export type SegmentationSelection = {
  id: string;
  name: string;
  segmentationType: SegmentationType;
  segments?: SegmentValueDto[];
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface SegmentationParameterState extends ParameterState {
  selections: SegmentationSelection[];
}

export const isSegmentationParameterState = (
  state: ParameterState | undefined
): state is SegmentationParameterState =>
  state !== undefined && Object.hasOwn(state, "selections");

export const newSegmentationParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): SegmentationParameterState => {
  let selections: SegmentationSelection[] = [];
  const options = parameterConfig.options as SegmentationParameterOption[];
  const defaultSegmentations =
    savedSelections ?? parameterConfig.selections ?? [];
  selections = options
    .filter((option) => option.isDefault)
    .map((option) => {
      const selection = {
        name: option.label,
        id: option.value,
        segmentationType: option.segmentationType,
      };

      return selection;
    });

  if (defaultSegmentations.length > 0) {
    selections = defaultSegmentations.flatMap((item) => {
      if (isSegmentationSelectionDto(item)) {
        return [item];
      }

      return [];
    });
  }

  return {
    isValid: isBetweenMinMax(selections.length, parameterConfig),
    parameterConfig,
    parameterGroup,
    selections,
  };
};

export const getSegmentationParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as SegmentationParameterState
  );

export const handleSegmentationParameterSelection = (
  parameterState: ParameterState,
  selection: SegmentationSelection
): ParameterState => {
  if (isSegmentationParameterState(parameterState)) {
    const selections = parameterState.selections.some(
      (select) => select.id === selection.id
    )
      ? parameterState.selections.filter((select) => select.id !== selection.id)
      : [...parameterState.selections, selection];

    return {
      ...parameterState,
      isValid: isBetweenMinMax(
        selections.length,
        parameterState.parameterConfig
      ),
      selections,
    } as ParameterState;
  }

  return { ...parameterState, isParameterMissing: false };
};
