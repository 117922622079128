import { type CustomerProfilingSegmentsOverTimeRequestDto } from "../models/CustomerProfilingSegmentsOverTimeRequestDto";
import { type SegmentsOverTimeSegmentationItem } from "../models/CustomerProfilingSegmentsOverTimeResponseDto";
import { customerProfilingApiSlice } from "./customer-profiling-api-slice";

export const customerProfilingSegmentsOverTimeReportletApiSlice =
  customerProfilingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getCustomerProfilingSegmentsOverTimeReportlet: builder.query<
        SegmentsOverTimeSegmentationItem[],
        {
          division: string;
          payload: CustomerProfilingSegmentsOverTimeRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetSegmentsOverTimeData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetCustomerProfilingSegmentsOverTimeReportletQuery } =
  customerProfilingSegmentsOverTimeReportletApiSlice;
