import { GROUPS_PATH } from "@quantium-enterprise/common-ui";
import { generatePath } from "react-router-dom";
import { CustomerGroupTab } from "../enums/customer-group-tab-ids";

export const getDefaultGroupsPath = (
  divisionName: string,
  groupType: string,
  customerGroupTab?: CustomerGroupTab
) =>
  customerGroupTab === CustomerGroupTab.SegmentLibrary
    ? generatePath("/:division/groups/:groupType/segment-library", {
        division: divisionName,
        groupType,
      })
    : generatePath("/:division/groups/:groupType", {
        division: divisionName,
        groupType,
      });

export const getCreateGroupPath = (
  divisionName: string,
  groupType: string,
  templateType: string
) => `/${divisionName}/${GROUPS_PATH}/create/${groupType}/${templateType}`;

export const getEditGroupPath = (
  divisionName: string,
  groupType: string,
  groupId: string
) => `/${divisionName}/${GROUPS_PATH}/edit/${groupType}/${groupId}`;

export const getGroupCreatorPath = (divisionName: string, groupType?: string) =>
  `/${divisionName}/${GROUPS_PATH}/create/${groupType}`;

export const getGroupListPath = (
  divisionName: string,
  groupType: string,
  groupId?: string
) =>
  groupId
    ? `/${divisionName}/${GROUPS_PATH}/${groupType}/${groupId}`
    : `/${divisionName}/${GROUPS_PATH}/${groupType}`;

export const getSegmentPath = (
  divisionName: string,
  groupId: string,
  segmentKey: string
) =>
  `/${divisionName}/${GROUPS_PATH}/Customer/segment-library/${groupId}/${segmentKey}`;
