/* eslint-disable typescript-sort-keys/string-enum */
import { TrackingProperties } from "./TrackingProperties";

export enum ItemTrackingProperty {
  AttributeLevel = "Attribute level",
  Hierarchy = "Hierarchy",
  ProductGroup = "Product group",
}

export enum ItemTrackingPropertyValue {
  Category = "CT",
  Department = "DT",
  Product = "PD",
  Segment = "SE",
  Subcategory = "SC",
  Total = "SM",
  Brand = "BR",
  Assortment = "AS",
  Supplier = "SSG",
  StaticProductGroup = "PGS",
  DynamicProductGroup = "PGD",
  Unknown = "Unknown",
}

export class ItemTrackingProperties extends TrackingProperties {
  public constructor(
    property: ItemTrackingProperty,
    value: ItemTrackingPropertyValue
  ) {
    super();

    this.Properties = {};
    this.Properties[property] = value;
  }

  public static item(
    property: ItemTrackingProperty,
    value: string
  ): ItemTrackingProperties {
    let propertyValue: ItemTrackingPropertyValue;

    switch (value.toLowerCase()) {
      case "ct":
        propertyValue = ItemTrackingPropertyValue.Category;
        break;
      case "dt":
        propertyValue = ItemTrackingPropertyValue.Department;
        break;
      case "pd":
        propertyValue = ItemTrackingPropertyValue.Product;
        break;
      case "se":
        propertyValue = ItemTrackingPropertyValue.Segment;
        break;
      case "sc":
        propertyValue = ItemTrackingPropertyValue.Subcategory;
        break;
      case "sm":
        propertyValue = ItemTrackingPropertyValue.Total;
        break;
      case "br":
        propertyValue = ItemTrackingPropertyValue.Brand;
        break;
      case "as":
        propertyValue = ItemTrackingPropertyValue.Assortment;
        break;
      case "ssg":
        propertyValue = ItemTrackingPropertyValue.Supplier;
        break;
      case "pgs":
        propertyValue = ItemTrackingPropertyValue.StaticProductGroup;
        break;
      case "pgd":
        propertyValue = ItemTrackingPropertyValue.DynamicProductGroup;
        break;
      default:
        console.log(`no tracking property ${value}`); // eslint-disable-line no-console
        propertyValue = ItemTrackingPropertyValue.Unknown;
    }

    return new this(property, propertyValue);
  }
}
