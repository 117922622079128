import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { type Metric, type Series } from "components-ui/src/charts/models";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import {
  type TrendsChartResponseDto,
  type SegmentationBreakdownResponseDto,
} from "../models/ChartResponseDto";

const colours: string[] = [
  "var(--qbit-colour-brand-turquoise)",
  "var(--qbit-colour-brand-yellow)",
  "var(--qbit-colour-brand-orange)",
  "var(--qbit-colour-brand-green)",
  "var(--qbit-colour-brand-blue)",
  "var(--qbit-colour-brand-burgundy)",
];

const getColour = (colourCounter: number): string => {
  const result = colours[colourCounter % colours.length];
  return result;
};

const getName = (productName: string, productHierarchyShortName: string) =>
  `(${hierarchyLevelDisplayLabel(
    productHierarchyShortName.replace("PRODUCT_", "")
  )}) ${[productName]}`;

type SegmentSeries = {
  color: string;
  data: number[];
  metric: Metric;
  name: string;
  segment: string;
  showDataLabels: boolean;
};

export const createSegmentBreakdownSeries = (
  { productData, metricsMetadata, segments }: SegmentationBreakdownResponseDto,
  selectedMetrics: string[],
  formatter: DeferredFormatFunction,
  showDataLabels: boolean
): Series[] => {
  let allSeries: SegmentSeries[] = [];
  const metricsIndexAccessors: number[] = selectedMetrics.map((selection) =>
    metricsMetadata.findIndex((metric) => metric.metricKey === selection)
  );

  for (const product of productData) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of -- we want index to access data
    for (const [segmentIndex, segment] of segments.entries()) {
      for (const metricIndex of metricsIndexAccessors) {
        const metric = metricsMetadata[metricIndex];
        const series: SegmentSeries = {
          color: getColour(segmentIndex),
          data: product.data[segmentIndex][metricIndex],
          metric: {
            format: metric.format,
            formatter: formatter(metric.format),
            label: metric.metricLabel,
            name: metric.metricKey,
          },
          name: getName(product.name, product.shortname),
          segment,
          showDataLabels,
        };
        allSeries = allSeries.concat(series);
      }
    }
  }

  // Sort all the series based on name, then metric, then segment
  const sortedSeries = allSeries.sort(
    (seriesOne, seriesTwo) =>
      seriesOne.name.localeCompare(seriesTwo.name) ||
      seriesOne.metric.name.localeCompare(seriesTwo.metric.name)
  );

  const finalSeries: Series[] = [];

  // Finalise the series and convert back to Series
  for (const series of sortedSeries) {
    const newSeries: Series = {
      color: series.color,
      data: series.data,
      metric: series.metric,
      name: series.name,
      segmentation: series.segment,
      showDataLabels: series.showDataLabels,
    };

    finalSeries.push(newSeries);
  }

  return finalSeries;
};

export const createPerformanceTrendSeries = (
  { productData, metricsMetadata }: TrendsChartResponseDto,
  selectedMetrics: string[],
  formatter: DeferredFormatFunction,
  showAxisDataLabels: Record<string, boolean>
): Series[] => {
  let allSeries: Series[] = [];

  let colourCounter = 0;
  for (const selectedMetric of selectedMetrics) {
    const metricIndex = metricsMetadata.findIndex(
      (metricMetadata) => metricMetadata.metricKey === selectedMetric
    );
    const metric = metricsMetadata[metricIndex];
    for (const product of productData) {
      const series: Series = {
        color: getColour(colourCounter % productData.length),
        data: product.data[metricIndex],
        metric: {
          format: metric.format,
          formatter: formatter(metric.format),
          label: metric.metricLabel,
          name: metric.metricKey,
        },
        name: getName(product.name, product.shortname),
        showDataLabels:
          showAxisDataLabels[
            selectedMetrics.indexOf(selectedMetric).toString()
          ],
      };
      allSeries = allSeries.concat(series);
      colourCounter++;
    }
  }

  allSeries.sort(
    (a, b) =>
      selectedMetrics.indexOf(a.metric.name) -
      selectedMetrics.indexOf(b.metric.name)
  );

  return allSeries;
};
