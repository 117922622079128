// Golden Ratio constant used for better hash scattering
const GOLDEN_RATIO = 0x9e_37_79_b9;
// Constants used for splitmix algorithm
const MIX_A = 0x21_f0_aa_ad;
const MIX_B = 0x73_5a_2d_97;

// simple hash
const createSeed = (seedString: string): number => {
  let hash = 0;
  for (let index = 0; index < seedString.length; index++) {
    hash = Math.imul(31, hash) + seedString.charCodeAt(index);
  }

  return Math.trunc(hash);
};

// seeded random number generator implementation using splitmix32
const splitmix32 = (seed: number): (() => number) => {
  let x = seed;
  return () => {
    Math.trunc(x);
    x = Math.trunc(x + GOLDEN_RATIO);
    let result = x ^ (x >>> 16); // eslint-disable-line no-bitwise
    result = Math.imul(result, MIX_A);
    result ^= result >>> 15; // eslint-disable-line no-bitwise
    result = Math.imul(result, MIX_B);
    return ((result ^= result >>> 15) >>> 0) / 4_294_967_296; // eslint-disable-line no-bitwise
  };
};

export const getSeededNumberGenerator = (
  seed: number | string
): (() => number) => {
  if (typeof seed === "string") {
    return splitmix32(createSeed(seed));
  }

  return splitmix32(seed);
};
