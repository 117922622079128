import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
} from "@quantium-enterprise/qds-react";
import { closeSearchModal } from "../focal-item-search/fastReportingSearchSlice";
import { useAppDispatch } from "../hooks";
import styles from "./HeaderTab.module.css";

export type HeaderTabProps = {
  text: string;
};

export const HeaderTab = ({ text }: HeaderTabProps) => {
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(closeSearchModal());
  return (
    <div className={styles.headerModalTab}>
      <span className={styles.tabText}>{text}</span>
      <Button
        className={styles.tabCloseButton}
        data-testid="searchModalCloseButtonTab"
        height={ButtonHeight.XSmall}
        onClick={onClose}
        variant={ButtonVariant.Stealth}
      >
        <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close tab" />
      </Button>
    </div>
  );
};
