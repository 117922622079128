type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const CustomerProfilingIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Customer profiling report"}</title>
      <path
        clipRule="evenodd"
        d="M16.0948 14.6487C17.1146 13.3228 17.721 11.6625 17.721 9.86049C17.721 5.51926 14.2017 2 9.86049 2C5.51926 2 2 5.51926 2 9.86049C2 14.2017 5.51926 17.721 9.86049 17.721C11.6625 17.721 13.3228 17.1146 14.6487 16.0948L18.5539 20L20 18.5539L16.0948 14.6487ZM9.86049 15.6778C6.64769 15.6778 4.0432 13.0733 4.0432 9.86049C4.0432 6.64769 6.64769 4.0432 9.86049 4.0432C13.0733 4.0432 15.6778 6.64769 15.6778 9.86049C15.6778 13.0733 13.0733 15.6778 9.86049 15.6778Z"
        fill={colour2}
        fillRule="evenodd"
      />
      <path
        d="M9.8146 9.63C10.8858 9.63 11.7542 8.76143 11.7542 7.69C11.7542 6.61857 10.8858 5.75 9.8146 5.75C8.74339 5.75 7.875 6.61857 7.875 7.69C7.875 8.76143 8.74339 9.63 9.8146 9.63Z"
        fill={colour3}
      />
      <path
        d="M12.5751 13.5293V11.5134C12.5751 11.4061 12.55 11.2989 12.4869 11.212C12.2241 10.8505 11.531 10.2417 9.85706 10.2417C8.16941 10.2417 7.48865 10.7912 7.17189 11.1962C7.09598 11.2933 7.0625 11.4153 7.0625 11.5385V13.63C7.0625 13.701 7.09213 13.7692 7.14751 13.8136C7.46398 14.0671 7.90707 14.2735 8.30138 14.3651C9.52197 14.6487 10.8019 14.5742 11.9478 14.068C12.1302 13.9874 12.3813 13.8087 12.5142 13.6822C12.5557 13.6427 12.5751 13.5866 12.5751 13.5293Z"
        fill={colour3}
      />
      <path
        d="M19.9461 18.5L21.9385 20.4924C22.0362 20.5901 22.0362 20.7483 21.9385 20.846L20.8459 21.9386C20.7483 22.0362 20.59 22.0362 20.4924 21.9386L18.5 19.9461L19.9461 18.5Z"
        fill={colour1}
      />
    </svg>
  );
};
