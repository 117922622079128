import {
  getBaseQuery,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type GlobalParameterValues } from "../../fast-report/globalParameterValues";
import { type FocalItemDto } from "../focalItemDto";
import { REPORT_DATA_CACHE_LENGTH_SECONDS } from "../reportUtilities";

export type StoresRangeReportRequest = GlobalParameterValues & {
  division: string;
  item: FocalItemDto;
};

export type StoresRangeReportResponse = {
  items: StoresRangeReportItemResult[];
  transactionSource: TransactionSource;
};

export type StoresRangeReportItemResult = {
  locationGroupingValues: { [key: string]: string };
  metricResults: { [key: string]: number };
};

export const storesRangeReportApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    storesRangeReport: builder.query<
      StoresRangeReportResponse,
      StoresRangeReportRequest
    >({
      keepUnusedDataFor: REPORT_DATA_CACHE_LENGTH_SECONDS,
      providesTags: ["StoresRangeReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/range-report/stores`,
      }),
    }),
  }),
  reducerPath: "storesRangeReportApi",
  tagTypes: ["StoresRangeReport"],
});

export const { useStoresRangeReportQuery } = storesRangeReportApi;
