import {
  type ReportParametersDto,
  ddLog,
  HierarchyItemType,
} from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useDebounce,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import {
  Toggle,
  Input,
  NumberInput,
  FormInputHeight,
  FormInputWidth,
  FormBlock,
  FormBlockType,
  FormBlockStatus,
} from "@quantium-enterprise/qds-react";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { type ReportTabItemType } from "../../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabItem } from "../../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabs } from "../../../common/components/ReportTabComponents/ReportTabs";
import { type SidePanelTimePeriodParameter } from "../../../common/models/local-parameters/SidePanelParameters";
import { getFocalItemSummary } from "../../../common/utils/export-parameter-summary-utils";
import { PricingLaddersFeatureFlags } from "../../constants";
import { type PricingLaddersDataTableRequestDto } from "../../models/PricingLaddersDataTableRequestDto";
import { useLazyGetTableQuery } from "../../services/pricing-ladders-data-table-api-slice";
import {
  setTopDrawerActiveTab,
  onTopDrawerTableSuccess,
  onSalesFilterValueChange,
  displaySelectedItemsOnly,
  selectLocationHierarchy,
  selectChannel,
  selectSegmentation,
  selectLocalParameter,
  selectTopDrawerActiveTab,
  selectFocalItems,
  selectMinimumSales,
  selectIsMinimumSalesInvalid,
  selectRowSelection,
  selectHeaderCheckbox,
  selectSelectedItemsOnlyToggle,
  selectLevelOfAnalysis,
  selectMetrics,
  selectTableRows,
  selectLocalParametersInitialised,
  selectReportId,
  selectViewBy,
} from "../../services/pricing-ladders-slice";
import { timeData } from "../../utils/getExportFunctions";
import { getTableRequestDto } from "../../utils/getTableRequestDto";
import { focalDataCSVTransfromation } from "../CsvTransformation";
import styles from "./PricingLaddersTopDrawer.module.css";
import { PricingLaddersTopDrawerItemTable } from "./PricingLaddersTopDrawerDataTable";

export type PricingLaddersTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportParameterSummary?: ReportParametersDto;
  reportTabItems: ReportTabItemType[];
  reportType: string;
};

const MAX_FOCAL_ITEMS = 200;

export const PricingLaddersTopDrawer = ({
  reportType,
  reportName,
  renameReport,
  reportTabItems,
  eventTrackingService,
  reportParameterSummary,
}: PricingLaddersTopDrawerProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();

  const activeTab = useSelector(selectTopDrawerActiveTab);
  const focalItems = useSelector(selectFocalItems);
  const minimumSales = useSelector(selectMinimumSales);
  const isMinimumSalesInvalid = useSelector(selectIsMinimumSalesInvalid);
  const rowSelection = useSelector(selectRowSelection);
  const headerCheckbox = useSelector(selectHeaderCheckbox);
  const selectedItemsOnlyToggle = useSelector(selectSelectedItemsOnlyToggle);
  const levelOfAnalysis = useSelector(selectLevelOfAnalysis);
  const metrics = useSelector(selectMetrics);
  const tableRows = useSelector(selectTableRows);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const location = useSelector(selectLocationHierarchy);
  const channel = useSelector(selectChannel);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const segmentation = useSelector(selectSegmentation);
  const storeReportId = useSelector(selectReportId);
  const viewBy = useSelector(selectViewBy);

  const [filterInput, setFilterInput] = useState<string>(minimumSales);
  const debouncedFilterInput = useDebounce(filterInput, 500);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[PricingLaddersFeatureFlags.ReportExport] ?? false;

  const handleTabClick = useCallback(
    (value: string) => {
      dispatch(setTopDrawerActiveTab(value));
    },
    [dispatch]
  );

  const [getTableQuery, { isError, isFetching, data }] = useLazyGetTableQuery();

  const fetchTableQuery = useCallback(
    async (division: string, payload: PricingLaddersDataTableRequestDto) => {
      await getTableQuery({ divisionName: division, payload });
    },
    [getTableQuery]
  );

  useEffect(() => {
    if (localParametersInitialised && reportId === storeReportId) {
      fetchTableQuery(
        divisionName,
        getTableRequestDto(
          activeTab ?? "",
          channel,
          location,
          segmentation,
          reportId
        )
      ).catch((error) => {
        ddLog(
          "Error retrieving pricing ladders table query",
          {},
          "error",
          error
        );
      });
    }
  }, [
    activeTab,
    channel,
    divisionName,
    fetchTableQuery,
    localParametersInitialised,
    location,
    reportId,
    segmentation,
    storeReportId,
  ]);

  useEffect(() => {
    if (reportId === storeReportId && localParametersInitialised && data) {
      dispatch(onTopDrawerTableSuccess(data));
    } else if (isError) {
      dispatch(
        onTopDrawerTableSuccess({
          tableRows: [],
          levelOfAnalysis: activeTab ?? "",
          metrics: [],
        })
      );
    }
  }, [
    activeTab,
    data,
    dispatch,
    isError,
    localParametersInitialised,
    reportId,
    storeReportId,
  ]);

  useEffect(() => {
    dispatch(onSalesFilterValueChange(debouncedFilterInput));
  }, [debouncedFilterInput, dispatch]);

  useEffect(() => {
    setFilterInput(minimumSales);
  }, [rowSelection, headerCheckbox, minimumSales, activeTab]);
  const localParametersConfig = useSelector(selectLocalParameter);
  const timeValue = timeData(
    localParametersConfig[0] as SidePanelTimePeriodParameter
  );

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Pricing_Ladders_${timeValue.timePeriodLength}_(${locationHierarchy.shortName})${locationHierarchy.name}`
      ),
    [timeValue, locationHierarchy]
  );
  const CsvTransformationCallback = useCallback(
    () => focalDataCSVTransfromation(metrics, tableRows),
    [metrics, tableRows]
  );
  const parameterSummary = useMemo(
    () => [
      { name: "Focal Item", value: getFocalItemSummary(focalItems) },
      { name: "Time", value: timeValue.time },
      { name: "Channel", value: channel.label },
      {
        name: "Level of Analysis",
        value:
          levelOfAnalysis.find((analsyis) => analsyis.value === activeTab)
            ?.label ?? "",
      },
      {
        name: "View By",
        value: viewBy[0].value.toString(),
      },
      {
        name: "Sort By",
        value: viewBy[1]?.label ?? "",
      },
      {
        name: "Segmentation",
        value: segmentation[0].label,
      },
      {
        name: "Customer segment",
        value: segmentation[1]?.label ?? "",
      },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(locationHierarchy.shortName)}) ${
          locationHierarchy.name
        }`,
      },
    ],

    [
      channel,
      viewBy,
      segmentation,
      locationHierarchy,
      focalItems,
      activeTab,
      levelOfAnalysis,
      timeValue,
    ]
  );

  return (
    <ReportTopDrawer
      controls={[
        <Toggle
          checked={selectedItemsOnlyToggle}
          key="selected-items-only-toggle"
          label="Selected items only"
          onClick={() => {
            dispatch(displaySelectedItemsOnly());
          }}
        />,
        <div className={styles.chartOptionsContainer} key="export">
          <DataTableOptions
            filename={exportFilename}
            invokeCSVDownload={CsvTransformationCallback}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={reportParameterSummary}
          />
        </div>,
      ]}
      items={focalItems.map((item) => ({
        code: item.shortName,
        displayName: item.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportTabs activeTab={activeTab ?? ""} onClickTabItem={handleTabClick}>
          {reportTabItems.map((item) => (
            <ReportTabItem
              key={item.value}
              label={item.label}
              value={item.value}
            >
              <div className={styles.salesFilterContainer}>
                <span>Auto select items with at least $</span>
                <FormBlock
                  blockStatus={
                    isMinimumSalesInvalid
                      ? FormBlockStatus.Error
                      : FormBlockStatus.Default
                  }
                  blockType={FormBlockType.Number}
                >
                  <Input>
                    <NumberInput
                      className={styles.salesFilterInput}
                      height={FormInputHeight.XSmall}
                      id="pricing-ladder-filter-Input"
                      min={0}
                      onChange={(x) => {
                        setFilterInput(x.target.value);
                      }}
                      // @ts-expect-error -onKeyDown property works, but undefined in NumberInput type
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onKeyDown={(event: any) => {
                        // eslint-disable-next-line unicorn/no-unsafe-regex
                        const exceptedKeys = /^(?:\d+(?:\.\d{0,3})?)?$/u;
                        if (event?.key === "Enter") event.target.blur();
                        // prevent non-numeric input
                        else if (
                          !exceptedKeys.test(filterInput + event?.keyCode)
                        )
                          event.preventDefault();
                      }}
                      placeholder="N/A"
                      value={filterInput}
                      width={FormInputWidth.Large}
                    />
                  </Input>
                </FormBlock>
                <span>sales</span>
                {isMinimumSalesInvalid && (
                  <div
                    className={styles.minimumSalesErrorMessage}
                  >{`Value must be greater to meet item selection limit of ${MAX_FOCAL_ITEMS}.`}</div>
                )}
              </div>
              <PricingLaddersTopDrawerItemTable
                eventTrackingService={eventTrackingService}
                isQuerySuccess={!isFetching}
              />
            </ReportTabItem>
          ))}
        </ReportTabs>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default PricingLaddersTopDrawer;
