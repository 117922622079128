export const partition = <T>(
  array: T[],
  isValid: (element: T) => boolean
): [T[], T[]] => {
  const pass: T[] = [];
  const fail: T[] = [];
  for (const element of array) {
    if (isValid(element)) {
      pass.push(element);
    } else {
      fail.push(element);
    }
  }

  return [pass, fail];
};

export const createRecord = <T>(
  array: T[],
  propertySelector: (element: T) => string
): Record<string, boolean> => {
  const record: Record<string, boolean> = {};
  for (const element of array) {
    record[propertySelector(element)] = true;
  }

  return record;
};
