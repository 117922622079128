import { createRowNameMatchesSearchPredicate } from "@quantium-enterprise/common-ui";
import { useFormatter, useNumberFormat } from "@quantium-enterprise/hooks-ui";
import {
  type Row,
  type CellContext,
  type SortingState,
  type ColumnDef,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import {
  onTableSortingChange,
  selectFocalItems,
  selectReportletResponseDto,
  selectTableResponseDto,
} from "../../services/repertoire-slice";
import { type RepertoireFocalItemRow } from "../../services/repertoire-top-drawer-api-slice";
import styles from "./RepertoireReportletTable.module.css";

const customFocalItemHeader = (
  focalItem: string,
  value: number | null,
  formatter: Function
) => (
  <div>
    <div>{focalItem}</div>
    <span>{formatter(value)}</span>
  </div>
);

export type RepertoireReportletTableProps = {
  isLoading: boolean;
  noData: boolean;
};

export const RepertoireReportletTable = ({
  isLoading,
  noData,
}: RepertoireReportletTableProps) => {
  // Call the formatter to be used in the column header for the total value
  const { metricFormatter } = useNumberFormat();
  const formatter = useFormatter();
  const dispatch = useDispatch();
  const focalItemTableRows = useSelector(selectTableResponseDto).focalItemData;
  const [searchQuery, setSearchQuery] = useState("");

  const tableRows = useMemo(() => {
    const rowNameMatchesSearch =
      createRowNameMatchesSearchPredicate(searchQuery);
    const updatedTableRows = focalItemTableRows.filter(
      (x) => searchQuery === "" || rowNameMatchesSearch(x.name.toString())
    );
    if (searchQuery.length > 0) {
      return updatedTableRows.sort((a, b) =>
        a.name.toString().localeCompare(b.name.toString())
      );
    }

    return updatedTableRows;
  }, [searchQuery, focalItemTableRows]);

  const displayEmptySearch: boolean =
    searchQuery.length > 0 && tableRows.length === 0;

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return tableRows.length;
  }, [tableRows.length, searchQuery]);

  const reportletDto = useSelector(selectReportletResponseDto);
  const selectedFocalItems = useSelector(selectFocalItems);

  const [tableSortingState, setTableSortingState] = useState<SortingState>([]);

  useEffect(() => {
    if (tableSortingState.length > 0) {
      dispatch(onTableSortingChange(tableSortingState[0]));
    }
  }, [dispatch, tableSortingState]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <div>Cross products</div>
        <SearchBox
          enableDebounce
          onChange={(searchString: string) => {
            setSearchQuery(searchString);
          }}
          placeholder="Type to search"
          resultCount={matchesFound}
        />
      </div>
    ),
    [matchesFound, setSearchQuery]
  );
  // Set the default sorting state of the table to be the first focal item
  useEffect(() => {
    if (selectedFocalItems.length && reportletDto.focalItemColumns.length) {
      if (searchQuery.length === 0) {
        setTableSortingState([
          {
            id: `repertoire-reportlet-table-${selectedFocalItems[0]}-col`,
            desc: true,
          },
        ]);
      } else {
        setTableSortingState([]);
      }
    }
  }, [reportletDto.focalItemColumns.length, selectedFocalItems, searchQuery]);

  // First column is the cross product column
  const crossProductColumnDefinition: ColumnDef<RepertoireFocalItemRow> =
    useMemo(
      () =>
        ({
          cell: ({ row }: { row: Row<RepertoireFocalItemRow> }) =>
            reportletDto.crossProductData[row.original.id]?.name,
          minSize: 250,
          maxSize: 500,
          enableHiding: false,
          enableResizing: true,
          enableSorting: false,
          header: memoizedSearchBox,
          id: `repertoire-reportlet-table-cross-product-col`,
        } as unknown as ColumnDef<RepertoireFocalItemRow>),
      [reportletDto.crossProductData, memoizedSearchBox]
    );

  const formatterCallback = useCallback(
    (value_?: number | string | null) =>
      metricFormatter(reportletDto.totalValues.format, value_),

    // Disable metricFormatter as dependency as it causes - Maximum update depth exceeded
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportletDto.totalValues.format]
  );

  // Remaining columns are each focal item
  const focalItemColumnDefinitions: Array<ColumnDef<RepertoireFocalItemRow>> =
    useMemo(() => {
      const cols: Array<ColumnDef<RepertoireFocalItemRow>> =
        reportletDto.focalItemColumns.map((focalItem, index) => ({
          accessorFn: (row: RepertoireFocalItemRow) =>
            reportletDto.crossProductData[row.id]?.values[index] ?? 0,
          cell: ({ row }: CellContext<RepertoireFocalItemRow, unknown>) =>
            ValueCell({
              formatter: formatter(reportletDto.metricFormat),
              value:
                reportletDto.crossProductData[row.original.id]?.values[index] ??
                "",
            }),
          minSize: 112,
          maxSize: 300,
          enableHiding: false,
          enableResizing: true,
          enableSorting: true,
          header: () =>
            customFocalItemHeader(
              reportletDto.crossProductData[focalItem]?.name ?? focalItem,
              reportletDto.totalValues.data[index],
              formatterCallback
            ),
          id: `repertoire-reportlet-table-${focalItem}-col`,
        }));

      return cols;
    }, [
      formatter,
      formatterCallback,
      reportletDto.crossProductData,
      reportletDto.focalItemColumns,
      reportletDto.metricFormat,
      reportletDto.totalValues.data,
    ]);

  return (
    <div className={styles.reportletTableView}>
      <span className={styles.tableLeftSideLabel}>Repertoire (legend)</span>
      <span className={styles.tableTopSideLabel}>Focal items (x-axis)</span>
      <div className={styles.repertoireReportletTableContainer}>
        <ErrorBoundary>
          <NoDataChartWrapper
            isLoading={isLoading}
            noData={noData && !isLoading}
          >
            <div>
              <BasicTable
                className={styles.repertoireReportletTable}
                columns={[
                  crossProductColumnDefinition,
                  ...focalItemColumnDefinitions,
                ]}
                data={tableRows}
                getRowId={(row) => row.id}
                onSortingChange={setTableSortingState}
                sorting={tableSortingState}
              />
              {displayEmptySearch && (
                <div className={styles.emptySearch}>
                  <EmptySearch />
                </div>
              )}
            </div>
          </NoDataChartWrapper>
        </ErrorBoundary>
      </div>
    </div>
  );
};
