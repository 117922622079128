import {
  type LocalHierarchyNodeSelection,
  type HierarchyValueDto,
} from "@quantium-enterprise/common-ui";
import { createSelector, type PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getReportLocalParameters } from "../../common/utils/local-parameters/getReportLocalParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import {
  PURCHASE_ACTIVITY_DATA_TYPE_TABS,
  PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS,
  PURCHASE_ACTIVITY_TABS,
  PURCHASE_ACTIVITY_VIEW_TYPE_TABS,
} from "../models/PurchaseActivityTabs";
import { type TrialAndRepeatLocalParametersResponseDto } from "../models/TrialAndRepeatLocalParametersResponseDto";
import { type TrialAndRepeatParameterSelections } from "../models/TrialAndRepeatParameterSelections";
import { type TrialAndRepeatProductData } from "../models/TrialAndRepeatProductRow";
import {
  type Pagination,
  type TrialAndRepeatTableResponseDto,
} from "../models/TrialAndRepeatTableResponseDto";
import { getLocalSelections } from "../utils/getLocalSelections";

const getPersistenceKey = (reportId: string) => `trial-and-repeat-${reportId}`;

export type TrialAndRepeatPersistedSelections = {
  activeTab?: string;
  focalItem?: HierarchyValueDto;
  localSelections: TrialAndRepeatParameterSelections;
  rowSelection: RowSelectionState;
};

export type TrialAndRepeatState = {
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  pagination: Pagination;
  persistedSelections: TrialAndRepeatPersistedSelections;
  persistedSelectionsLoaded: boolean;
  purchaseActivityActiveTab: string;
  purchaseActivityDataType: string;
  purchaseActivitySummaryType: string;
  purchaseActivityViewType: string;
  reportError: boolean;
  reportId: string;
  reportName: string;
  searchQuery: string;
  showDistributionDataLabels: boolean;
  showPurchaseDataLabels: boolean;
  topDrawerTableRows: TrialAndRepeatProductData[];
  transactionNumber: string | null;
};

export const initialState: TrialAndRepeatState = {
  localParameters: [],
  localParametersInitialised: false,
  pagination: {
    hasNextPage: false,
    hasPreviousPage: false,
    pageIndex: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },
  persistedSelections: {
    activeTab: undefined,
    focalItem: undefined,
    localSelections: {
      metric: {
        label: "",
        value: "",
      },
      channel: {
        label: "",
        value: "",
      },
      segmentation: [],
      promotion: {
        label: "",
        value: "",
      },
      LocationHierarchy: {
        code: "",
        nodeNumber: -1,
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        shortName: "",
      },
      time: "",
      timePeriodLength: "",
    },
    rowSelection: {} as RowSelectionState,
  },
  purchaseActivityActiveTab: PURCHASE_ACTIVITY_TABS.TrialAndRepeat,
  purchaseActivityViewType: PURCHASE_ACTIVITY_VIEW_TYPE_TABS.Chart,
  purchaseActivitySummaryType: PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS.Cumulative,
  purchaseActivityDataType: PURCHASE_ACTIVITY_DATA_TYPE_TABS.Actual,
  reportName: "Trial and repeat",
  reportError: false,
  transactionNumber: null,
  topDrawerTableRows: [],
  searchQuery: "",
  showDistributionDataLabels: false,
  showPurchaseDataLabels: false,
  persistedSelectionsLoaded: false,
  reportId: "",
};

export const trialAndRepeatSlice = createSlice({
  initialState,
  name: "trialAndRepeat",
  reducers: {
    onReportOpen: (
      state: TrialAndRepeatState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: TrialAndRepeatPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
    onPurchaseActivityActiveTabChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<string>
    ) => {
      state.purchaseActivityActiveTab = action.payload;
      if (action.payload === PURCHASE_ACTIVITY_TABS.TrialAndRepeat) {
        state.transactionNumber = null;
      } else if (!state.transactionNumber) {
        state.transactionNumber = "1";
      }
    },
    onPurchaseActivityViewTypeChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<string>
    ) => {
      state.purchaseActivityViewType = action.payload;
    },
    onPurchaseActivitySummaryTypeChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<string>
    ) => {
      state.purchaseActivitySummaryType = action.payload;
    },
    onPurchaseActivityDataTypeChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<string>
    ) => {
      state.purchaseActivityDataType = action.payload;
    },
    setTopDrawerActiveTab: (
      state: TrialAndRepeatState,
      action: PayloadAction<string>
    ) => {
      state.persistedSelections.activeTab = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setFocalItem: (
      state: TrialAndRepeatState,
      action: PayloadAction<HierarchyValueDto>
    ) => {
      const persistedSelections = state.persistedSelections;
      persistedSelections.focalItem = action.payload;
      for (const key of Object.keys(persistedSelections.rowSelection)) {
        persistedSelections.rowSelection[key] = false;
      }

      persistedSelections.rowSelection[persistedSelections.focalItem.itemCode] =
        true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onTopDrawerTableResponseReceived: (
      state: TrialAndRepeatState,
      action: PayloadAction<TrialAndRepeatTableResponseDto>
    ) => {
      if (
        !state.persistedSelectionsLoaded &&
        state.persistedSelections.activeTab === action.payload.levelOfAnalysis
      ) {
        if (action.payload.data.length > 0) {
          // initial load, if no focal item set to first row
          // if filter triggering and focal item is filled dont change it
          if (!state.persistedSelections.focalItem) {
            state.persistedSelections.focalItem = action.payload.data[0].item;
            state.persistedSelections.rowSelection[
              action.payload.data[0].item.itemCode
            ] = true;
          }
        } else {
          state.persistedSelections.focalItem = undefined;
        }

        persistSelections(
          getPersistenceKey(state.reportId),
          state.persistedSelections
        );
      }

      state.topDrawerTableRows = action.payload.data.map((x, index) => ({
        hierarchyItem: x.item,
        isMoreRow:
          action.payload.pagination.hasNextPage &&
          index === action.payload.data.length - 1,
      }));

      state.pagination = action.payload.pagination;
    },
    onSearchChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<string>
    ) => {
      state.searchQuery = action.payload;
    },
    onLoadMoreSuccess: (
      state: TrialAndRepeatState,
      action: PayloadAction<TrialAndRepeatTableResponseDto>
    ) => {
      state.topDrawerTableRows[state.topDrawerTableRows.length - 1].isMoreRow =
        false;

      const newData = action.payload.data.map((x, index) => ({
        hierarchyItem: x.item,
        isMoreRow:
          action.payload.pagination.hasNextPage &&
          index === action.payload.data.length - 1,
      }));
      state.topDrawerTableRows = state.topDrawerTableRows.concat(newData);

      state.pagination = action.payload.pagination;
    },
    onPurchaseStageChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<string>
    ) => {
      state.transactionNumber = action.payload;
    },
    onReportError: (state: TrialAndRepeatState) => {
      state.reportError = true;
    },
    onReportSuccess: (
      state: TrialAndRepeatState,
      action: PayloadAction<TrialAndRepeatLocalParametersResponseDto>
    ) => {
      state.reportName = action.payload.reportName;

      state.localParameters = getReportLocalParameters(
        action.payload.localParameters
      );

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localSelections = getLocalSelections(
          state.localParameters
        );

        state.persistedSelections.activeTab =
          action.payload.levelsOfAnalysis[0].value;
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<PanelOption[]>
    ) => {
      state.persistedSelections.localSelections.segmentation = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onMetricChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.metric = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onChannelChange: (
      state: TrialAndRepeatState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.channel = {
        label: payload.label,
        value: payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onPromotionChange: (
      state: TrialAndRepeatState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.promotion = {
        label: payload.label,
        value: payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: TrialAndRepeatState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localSelections.LocationHierarchy =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    togglePurchaseDataLabel: (state: TrialAndRepeatState) => {
      state.showPurchaseDataLabels = !state.showPurchaseDataLabels;
    },
    toggleDistributionDataLabel: (state: TrialAndRepeatState) => {
      state.showDistributionDataLabels = !state.showDistributionDataLabels;
    },
  },
});

// SELECTORS
export const selectTrialAndRepeatState = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.trialAndRepeat
);

export const selectPurchaseActivityActiveTab = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) =>
    trialAndRepeat.purchaseActivityActiveTab
);

export const selectLocalParameters = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.localParameters
);

export const selectSearchQuery = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.searchQuery
);

export const selectViewType = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) =>
    trialAndRepeat.purchaseActivityViewType
);

export const selectDataType = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) =>
    trialAndRepeat.purchaseActivityDataType
);

export const selectShowPurchaseDataLabels = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.showPurchaseDataLabels
);

export const selectShowDistributionDataLabels = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) =>
    trialAndRepeat.showDistributionDataLabels
);

export const selectSummaryType = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) =>
    trialAndRepeat.purchaseActivitySummaryType
);

export const selectPagination = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.pagination
);

export const selectTopDrawerTableRows = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.topDrawerTableRows
);

export const selectTransactionNumber = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.transactionNumber
);

export const selectLocalParametersInitialised = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) =>
    trialAndRepeat.localParametersInitialised
);

export const selectPersistedSelections = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.persistedSelections
);

export const selectActiveTab = createSelector(
  selectPersistedSelections,
  (persistedSelections: TrialAndRepeatPersistedSelections) =>
    persistedSelections.activeTab
);

export const selectFocalItem = createSelector(
  selectPersistedSelections,
  (persistedSelections: TrialAndRepeatPersistedSelections) =>
    persistedSelections.focalItem
);

export const selectLocalSelections = createSelector(
  selectPersistedSelections,
  (persistedSelections: TrialAndRepeatPersistedSelections) =>
    persistedSelections.localSelections
);

export const selectMetric = createSelector(
  selectLocalSelections,
  (localSelections: TrialAndRepeatParameterSelections) => localSelections.metric
);

export const selectSegmentation = createSelector(
  selectLocalSelections,
  (localSelections: TrialAndRepeatParameterSelections) =>
    localSelections.segmentation
);

export const selectPromotion = createSelector(
  selectLocalSelections,
  (localSelections: TrialAndRepeatParameterSelections) =>
    localSelections.promotion
);

export const selectChannel = createSelector(
  selectLocalSelections,
  (localSelections: TrialAndRepeatParameterSelections) =>
    localSelections.channel
);

export const selectRowSelection = createSelector(
  selectPersistedSelections,
  (persistedSelections: TrialAndRepeatPersistedSelections) =>
    persistedSelections.rowSelection
);

export const selectReportId = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.reportId
);

export const selectReportName = createSelector(
  selectTrialAndRepeatState,
  (trialAndRepeat: TrialAndRepeatState) => trialAndRepeat.reportName
);

export const {
  onReportOpen,
  onPurchaseActivityActiveTabChange,
  onPurchaseActivityViewTypeChange,
  onPurchaseActivitySummaryTypeChange,
  onPurchaseActivityDataTypeChange,
  setTopDrawerActiveTab,
  setFocalItem,
  onTopDrawerTableResponseReceived,
  onSearchChange,
  onLoadMoreSuccess,
  onPurchaseStageChange,
  onReportError,
  onReportSuccess,
  onSegmentationChange,
  onMetricChange,
  onChannelChange,
  onPromotionChange,
  onLocationChange,
  toggleDistributionDataLabel,
  togglePurchaseDataLabel,
} = trialAndRepeatSlice.actions;

export default trialAndRepeatSlice.reducer;
