import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

// Base query following the code splitting pattern
// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const keyDriverTreeApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/key-driver-tree`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "keyDriverTreeApi",
  tagTypes: ["key-driver-tree"],
});
