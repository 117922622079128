import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SeriesBubbleOptions, type SeriesLineOptions } from "highcharts";
import { type Metric } from "../models/Metric";
import { NONE_OPTION } from "./ProductUniquenessReportletFilterGroup";

const getColumnHeader = (
  metrics: Metric[],
  axis: PanelOption,
  currencySymbol: string
) => {
  for (const metric of metrics) {
    if (axis.value === metric.key && metric.format === MetricTypes.Currency) {
      return `${axis.label}(${currencySymbol})`;
    }
  }

  return axis.label;
};

export const csvTransformation = (
  uniquenessChartSeriesData: Array<SeriesBubbleOptions | SeriesLineOptions>,
  metrics: Metric[],
  currencySymbol: string,
  xAxis: PanelOption,
  yAxis: PanelOption,
  legend: PanelOption,
  bubbleSize: PanelOption
) => {
  const xAxisHeader = getColumnHeader(metrics, xAxis, currencySymbol);
  const yAxisHeader = getColumnHeader(metrics, yAxis, currencySymbol);
  const bubbleSizeHeader = getColumnHeader(metrics, bubbleSize, currencySymbol);

  const columnHeaders = ["Item", xAxisHeader, yAxisHeader];

  if (bubbleSize.value === NONE_OPTION.key) {
    columnHeaders.push(legend.label);
  } else {
    columnHeaders.push(bubbleSizeHeader, legend.label);
  }

  const csvData = [columnHeaders];

  for (const legendItem of uniquenessChartSeriesData) {
    if (legendItem.type === "bubble" && legendItem.data) {
      for (const item of legendItem.data) {
        if (
          "x" in item &&
          "y" in item &&
          "z" in item &&
          typeof item.x === "number" &&
          typeof item.y === "number" &&
          typeof item.z === "number" &&
          item.z
        ) {
          const row = [];

          if (bubbleSize.value === NONE_OPTION.key) {
            row.push(
              item.name as string,
              item.x.toString(),
              item.y.toString(),
              legendItem.name as string
            );
          } else {
            row.push(
              item.name as string,
              item.x.toString(),
              item.y.toString(),
              item.z.toString(),
              legendItem.name as string
            );
          }

          csvData.push(row);
        }
      }
    }
  }

  return csvData;
};
