import {
  type LocalHierarchyNodeSelection,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { type TimeOfDayDayOfWeekLocalSelections } from "../services/time-of-day-day-of-week-slice";

export const getUserSelections = (
  localParameters: TimeOfDayDayOfWeekLocalSelections
) => {
  const userSelections: LocalSelectedValueDto[] = [];

  for (const [parameterID, values] of Object.entries(localParameters)) {
    if (typeof values === "object" && "value" in values && values.value) {
      userSelections.push({
        id: parameterID,
        values: [values.value.toString()],
      });
    } else if (parameterID === "LocationHierarchy") {
      if (values) {
        const castValue = values as LocalHierarchyNodeSelection;
        userSelections.push({
          id: parameterID,
          values: [castValue.nodeNumber.toString()],
        });
      }
    } else if (Array.isArray(values) && values.length > 0) {
      userSelections.push({
        id: parameterID,
        values: [...values],
      });
    }
  }

  return userSelections;
};

export default getUserSelections;
