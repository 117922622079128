import {
  type CustomerGroupWithSharingDto,
  type CustomerGroupTypeDto,
  useGetCustomerGroupTypesQuery,
  CustomerGroupStatus,
  type ParameterDto,
  useGetCustomerGroupsQuery,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  FormBlock,
  FormBlockType,
  Group,
  Input,
  Item,
  ItemHalign,
  RadioButton,
  Spinner,
  SpinnerSize,
} from "@quantium-enterprise/qds-react";
import { type CellContext } from "@tanstack/react-table";
import classNames from "classnames";
import EmptyGroupImage from "components-ui/src/assets/common/empty-group.svg";
import { CustomerGroupStatusTag } from "components-ui/src/customer-group-status-tag/CustomerGroupStatusTag";
import { CustomerGroupIcon } from "components-ui/src/icons";
import { CgDetailsModalTrigger } from "components-ui/src/information-modal/customer-group-details-modal/CgDetailsModalTrigger";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { segmentFilterOptionSelected } from "../../states/report-wizard-slice";
import styles from "./SegmentFilterParameter.module.css";
import { type SegmentFilterOption } from "./SegmentFilterParameterState";
import {
  noneOption,
  getSegmentFilterParameterState,
} from "./SegmentFilterParameterState";

type SegmentFilterParameterProps = {
  parameterDto: ParameterDto;
};

export const SegmentFilterParameter = ({
  parameterDto,
}: SegmentFilterParameterProps) => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();

  const selection = useAppSelector(
    getSegmentFilterParameterState(parameterDto.id)
  )?.selection;

  const { data: customerGroupTypes } = useGetCustomerGroupTypesQuery(
    divisionName,
    { skip: !divisionName }
  );

  const { data: customerGroups, isSuccess } = useGetCustomerGroupsQuery(
    { divisionName },
    { skip: !divisionName, pollingInterval: 10_000 }
  );

  const segmentOptions =
    customerGroups
      ?.filter((group) => group.status === CustomerGroupStatus.Valid)
      .sort(
        (g1, g2) =>
          Date.parse(g1.refreshDateUtc ?? g1.createDateUtc) -
          Date.parse(g2.refreshDateUtc ?? g2.createDateUtc)
      )
      .reverse()
      .flatMap((customerGroup) =>
        customerGroup.segments.map((segment) => ({
          id: `${customerGroup.id}-${segment.ordinal}`,
          label: `${customerGroup.name} ${segment.name}`,
          customerGroup,
          segment,
        }))
      ) ?? [];

  const allOptions: SegmentFilterOption[] =
    segmentOptions.length > 0 ? [noneOption, ...segmentOptions] : [];

  const onChange = useCallback(
    (value: SegmentFilterOption) => {
      dispatch(
        segmentFilterOptionSelected({
          parameterId: parameterDto.id,
          selection: value,
        })
      );
    },
    [parameterDto.id, dispatch]
  );

  const radioCell = useCallback(
    (info: CellContext<SegmentFilterOption, unknown>) => (
      <div>
        <FormBlock blockType={FormBlockType.Checkbox}>
          <Input>
            <RadioButton
              checked={info.row.original.id === selection?.id}
              // disabled={info.row.original.customerGroup?.status !== CustomerGroupStatus.Valid}
              id={info.row.original.id}
              label=""
              name="segmentFilter"
              onChange={() => onChange(info.row.original)}
            />
          </Input>
        </FormBlock>
      </div>
    ),
    [onChange, selection]
  );

  const customerGroupCell = (
    info: CellContext<SegmentFilterOption, unknown>
  ) => <div>{info.getValue<string | undefined>() ?? "None"}</div>;

  const segmentCell = (info: CellContext<SegmentFilterOption, unknown>) => (
    <div>{info.getValue<string | undefined>() ?? ""}</div>
  );

  const statusCell = (info: CellContext<SegmentFilterOption, unknown>) => {
    const status = info.getValue<CustomerGroupStatus | undefined>();
    return (
      <span className={styles.statusTag}>
        {status && <CustomerGroupStatusTag status={status} />}
      </span>
    );
  };

  const typeCell = useCallback(
    (info: CellContext<SegmentFilterOption, unknown>) => (
      <span className={styles.customerGroupIcon}>
        <CustomerGroupIcon
          className={styles.customerGroupIcon}
          type={info.getValue<string>()}
        />
        {customerGroupTypes?.find(
          (type) => type.customerGroupType === info.getValue<string>()
        )?.displayName ?? ""}
      </span>
    ),
    [customerGroupTypes]
  );

  const detailsCell = useCallback(
    (info: CellContext<SegmentFilterOption, unknown>) =>
      info.getValue<boolean>() && (
        <CgDetailsModalTrigger
          customerGroupId={info.row.original.customerGroup?.id ?? ""}
          customerGroupTypes={customerGroupTypes as CustomerGroupTypeDto[]}
          customerGroups={customerGroups as CustomerGroupWithSharingDto[]}
        />
      ),
    [customerGroups, customerGroupTypes]
  );

  const getColumns = useCallback(
    () => [
      {
        accessorFn: (row: SegmentFilterOption) => row,
        cell: radioCell,
        header: "",
        id: "selectColumn",
        maxSize: 40,
        minSize: 40,
        size: 40,
      },
      {
        accessorFn: (row: SegmentFilterOption) => row.customerGroup?.name,
        cell: customerGroupCell,
        header: "Customer group",
        id: "customerGroup",
        minSize: 400,
        maxSize: 400,
        size: 400,
      },
      {
        accessorFn: (row: SegmentFilterOption) => row.segment?.name,
        cell: segmentCell,
        header: "Segment",
        id: "segment",
        maxSize: 150,
        minSize: 150,
        size: 150,
      },
      {
        accessorFn: (row: SegmentFilterOption) => row.customerGroup?.status,
        cell: statusCell,
        header: "Status",
        id: "status",
        maxSize: 150,
        minSize: 150,
        size: 150,
      },
      {
        accessorFn: (row: SegmentFilterOption) => row.customerGroup?.type,
        cell: typeCell,
        header: "Type",
        id: "type",
        maxSize: 250,
        minSize: 250,
        size: 250,
      },
      {
        accessorFn: (row: SegmentFilterOption) =>
          row.customerGroup !== undefined,
        cell: flags[FeatureFlag.ReportWizardCustomerGroupDetails]
          ? detailsCell
          : () => {},
        header: flags[FeatureFlag.ReportWizardCustomerGroupDetails]
          ? "Details"
          : "",
        id: "details",
        maxSize: 80,
        minSize: 80,
        size: 80,
      },
    ],
    [flags, radioCell, typeCell, detailsCell]
  );

  return (
    <Group className={styles.segmentFilterContainer}>
      <Item className={styles.searchContainer} halign={ItemHalign.Left}>
        <SearchBox
          debounceTimeMs={500}
          enableDebounce
          onChange={() => null}
          placeholder="Search customer group filter"
        />
      </Item>
      <Item className={styles.tableContainer}>
        <BasicTable
          className={styles.table}
          columns={getColumns()}
          data={allOptions}
          enableColumnResizing={false}
          onRowClick={undefined}
        />
        <div className={styles.centredContainer}>
          {!isSuccess && <Spinner size={SpinnerSize.Large} text="Loading" />}
          {allOptions.length === 0 && isSuccess && (
            <div
              className={classNames(
                styles.centredContainer,
                styles.emptyIconContainer
              )}
            >
              <img alt="" src={EmptyGroupImage} />
              <p>
                <b>No customer group filters avaliable</b>
              </p>
              <p>
                Please create a customer group to have customer group filters.
              </p>
            </div>
          )}
        </div>
      </Item>
    </Group>
  );
};
