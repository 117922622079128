import { MY_DASHBOARD_PATH } from "@quantium-enterprise/common-ui";
import {
  type UserAccess,
  type UserModuleAccess,
} from "@quantium-enterprise/common-ui/src/models/division-dto";
import { useNavigate } from "react-router-dom";
import { useDivision } from "./use-division";

export const useModuleAccessProtection = (
  accessor: ((moduleAccess: UserModuleAccess) => UserAccess) | null
) => {
  const division = useDivision();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- crashes if not checked
  if (division.moduleAccess && accessor) {
    const access = accessor(division.moduleAccess);
    if (!access.hasAccess) {
      navigate(`/${MY_DASHBOARD_PATH}`);
    }
  }
};
