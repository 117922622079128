import {
  isListSelectionDto,
  type SelectionDto,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";
import { ListParameterSelectionType } from "./ListParameterSelectionType";

export type CheckboxSelection = {
  index: number;
  label: string;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface ListParameterState extends ParameterState {
  selections: CheckboxSelection[];
}

export const isListParameterState = (
  state: ParameterState | undefined
): state is ListParameterState =>
  state !== undefined &&
  Object.prototype.hasOwnProperty.call(state, "selections");

export const newListParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): ListParameterState => {
  const defaultSelections = parameterConfig.options
    .filter((option) => {
      if (savedSelections) {
        return savedSelections.find((savedSelection) => {
          const listSelection = isListSelectionDto(savedSelection)
            ? savedSelection.value
            : undefined;

          return listSelection === option.value;
        });
      }

      return option.isDefault;
    })
    .map((option) => {
      const selection = {
        index: parameterConfig.options.indexOf(option),
        label: option.label,
        value: option.value,
      } as CheckboxSelection;

      return selection;
    });

  let updatedSelections: CheckboxSelection[] = defaultSelections;
  const prefilledSelections = parameterConfig.selections ?? [];

  if (prefilledSelections.length > 0) {
    const options = prefilledSelections.flatMap((item) => {
      if (isListSelectionDto(item)) {
        return [
          {
            index: parameterConfig.options.findIndex(
              (option) => option.value === item.value
            ),
            label: item.label,
            value: item.value,
          } as CheckboxSelection,
        ];
      }

      return [];
    });
    updatedSelections = options;
  }

  return {
    isValid: isBetweenMinMax(updatedSelections.length, parameterConfig),
    parameterConfig,
    parameterGroup,
    selections: updatedSelections,
  };
};

export const getListParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as ListParameterState | undefined
  );

export const handleListParameterSelection = (
  parameterState: ParameterState,
  value: string,
  selectionType: ListParameterSelectionType
): ParameterState => {
  if (isListParameterState(parameterState)) {
    const valueOption = parameterState.parameterConfig.options.find(
      (option) => option.value === value
    );

    if (valueOption) {
      const selection = {
        index: parameterState.parameterConfig.options.indexOf(valueOption),
        label: valueOption.label,
        value: valueOption.value,
      } as CheckboxSelection;

      if (selectionType === ListParameterSelectionType.RadioButton) {
        return {
          ...parameterState,
          isValid: isBetweenMinMax(1, parameterState.parameterConfig),
          selections: [selection],
        } as ParameterState;
      }

      const selections = parameterState.selections.some(
        (select) => select.value === value
      )
        ? parameterState.selections.filter((select) => select.value !== value)
        : [...parameterState.selections, selection];

      // Additional logic if we are handling with defaults and user has deselected all options - reselect the default.
      if (
        selectionType === ListParameterSelectionType.CheckboxWithDefault &&
        selections.length === 0
      ) {
        const defaultOption = parameterState.parameterConfig.options.find(
          (option) => option.isDefault
        );
        if (defaultOption) {
          selections.push({
            index:
              parameterState.parameterConfig.options.indexOf(defaultOption),
            label: defaultOption.label,
            value: defaultOption.value,
          });
        }
      }

      return {
        ...parameterState,
        isValid: isBetweenMinMax(
          selections.length,
          parameterState.parameterConfig
        ),
        isParameterMissing: false,
        selections,
      } as ParameterState;
    }
  }

  // Default is don't do anything to change the state
  return { ...parameterState };
};
