type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const PricingLaddersIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Pricing ladders report"}</title>
      <path
        d="M2.25 2C2.11193 2 2 2.11193 2 2.25V13.7175C2.56511 12.835 3.55424 12.25 4.68 12.25C5.78771 12.25 6.76314 12.8164 7.3325 13.6753V2.25C7.3325 2.11193 7.22057 2 7.0825 2H2.25Z"
        fill={colour3}
      />
      <path
        d="M9.58252 4.75C9.44445 4.75 9.33252 4.86193 9.33252 5V9.71754C9.89763 8.83498 10.8868 8.25 12.0125 8.25C13.1202 8.25 14.0957 8.81637 14.665 9.67533V5C14.665 4.86193 14.5531 4.75 14.415 4.75H9.58252Z"
        fill={colour3}
      />
      <path
        d="M12.0125 14.61C13.1202 14.61 14.0957 14.0436 14.665 13.1847V19.25C14.665 19.3881 14.5531 19.5 14.415 19.5H9.58252C9.44445 19.5 9.33252 19.3881 9.33252 19.25V13.1425C9.89763 14.025 10.8868 14.61 12.0125 14.61Z"
        fill={colour1}
      />
      <path
        d="M19.345 16.36C20.4527 16.36 21.4281 15.7936 21.9975 14.9347V21.75C21.9975 21.8881 21.8855 22 21.7475 22H16.915C16.7769 22 16.665 21.8881 16.665 21.75V14.8925C17.2301 15.775 18.2192 16.36 19.345 16.36Z"
        fill={colour1}
      />
      <path
        d="M14.165 11.4163C14.165 12.6126 13.1952 13.5825 11.9988 13.5825C10.8024 13.5825 9.83252 12.6126 9.83252 11.4163C9.83252 10.2199 10.8024 9.25 11.9988 9.25C13.1952 9.25 14.165 10.2199 14.165 11.4163Z"
        fill={colour2}
      />
      <path
        d="M6.8325 15.4163C6.8325 16.6126 5.86264 17.5825 4.66625 17.5825C3.46986 17.5825 2.5 16.6126 2.5 15.4163C2.5 14.2199 3.46986 13.25 4.66625 13.25C5.86264 13.25 6.8325 14.2199 6.8325 15.4163Z"
        fill={colour2}
      />
      <path
        d="M19.3312 15.3325C20.5276 15.3325 21.4975 14.3626 21.4975 13.1663C21.4975 11.9699 20.5276 11 19.3312 11C18.1348 11 17.165 11.9699 17.165 13.1663C17.165 14.3626 18.1348 15.3325 19.3312 15.3325Z"
        fill={colour2}
      />
    </svg>
  );
};
