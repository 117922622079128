import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { getFocalItemSummaryWithParents } from "../../common/utils/export-parameter-summary-utils";
import { getFocalItemLabels } from "../../common/utils/focal-item-labels-utils";
import { type ChartSelections } from "../models/chart-selections";
import { type TimeOfDayDayOfWeekLocalSelections } from "../services/time-of-day-day-of-week-slice";

export const getLocalParametersSummary = (
  localParameterSelections: TimeOfDayDayOfWeekLocalSelections,
  chartSelections: ChartSelections,
  focalItems: HierarchySliceNodeDto[],
  focalItemParents: HierarchySliceNodeDto[]
) => {
  const benchmark = chartSelections.benchmark.options.find(
    (item) => item.nodeNumber === chartSelections.benchmark.selected
  );
  const benchmarkDescription =
    benchmark === undefined
      ? "None"
      : "(" +
        hierarchyLevelDisplayLabel(benchmark.shortName) +
        ") " +
        benchmark.name;

  const getFocalItems = () => {
    if (chartSelections.viewBy.selected === "Focal item(s)") {
      return getFocalItemSummaryWithParents(focalItems, focalItemParents);
    } else {
      const customerSegmentFocalItem = focalItems.find(
        (item) => item.nodeNumber === chartSelections.focalItem.selected
      );

      if (customerSegmentFocalItem === undefined) {
        return "";
      }

      const labels = getFocalItemLabels(focalItems, focalItemParents);
      return `(${hierarchyLevelDisplayLabel(
        customerSegmentFocalItem.shortName
      )}) ${
        labels[customerSegmentFocalItem.nodeNumber] ??
        customerSegmentFocalItem.name
      }`;
    }
  };

  const parameterSummary = [
    {
      name: "Focal Item",
      value: getFocalItems(),
    },
    { name: "Time", value: localParameterSelections.FocusPeriod as string },
    { name: "Metric", value: localParameterSelections.Metric.label },
    { name: "Promotion", value: localParameterSelections.Promotion.label },
    {
      name: "Segmentation",
      value: localParameterSelections.Segmentation.label,
    },
    { name: "Channel", value: localParameterSelections.Channel.label },
    {
      name: "Location",
      value: `(${hierarchyLevelDisplayLabel(
        localParameterSelections.LocationHierarchy.shortName
      )}) ${localParameterSelections.LocationHierarchy.name}`,
    },
    { name: "View By", value: chartSelections.viewBy.selected as string },
  ];

  if (chartSelections.viewBy.selected === "Focal item(s)") {
    parameterSummary.push(
      { name: "Benchmark", value: benchmarkDescription },
      {
        name: "Customer Segement",
        value: chartSelections.segment.selected as string,
      }
    );

    if (chartSelections.tabSelected === "Time of Day") {
      parameterSummary.push({
        name: "Day of week",
        value: chartSelections.dayOfWeek.selected as string,
      });
    }
  } else if (chartSelections.viewBy.selected === "Customer segments") {
    if (chartSelections.tabSelected === "Time of Day") {
      parameterSummary.push({
        name: "Day of week",
        value: chartSelections.dayOfWeek.selected as string,
      });
    }
  } else if (chartSelections.viewBy.selected === "Days") {
    parameterSummary.push({
      name: "Customer Segement",
      value: chartSelections.segment.selected as string,
    });
  }

  return parameterSummary;
};

export default getLocalParametersSummary;
