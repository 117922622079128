import {
  type LocalHierarchyNodeSelection,
  type HierarchyValue,
  type LocalMetricWithFormatSelection,
} from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type LevelOfAnalysisDto } from "../../common/models/LevelOfAnalysisDto";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getReportLocalParameters } from "../../common/utils/local-parameters/getReportLocalParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { type CustomerLoyaltyHierarchyProductData } from "../models/CustomerLoyaltyHierarchyProductData";
import { type CustomerLoyaltyLocalParametersResponseDto } from "../models/CustomerLoyaltyLocalParametersResponseDto";
import { type CustomerLoyaltyReportletResponseDto } from "../models/CustomerLoyaltyReportletResponseDto";
import {
  type TableChildRowsResponseDto,
  type TableRowsResponseDto,
} from "../models/CustomerLoyaltyTableRowsResponseDto";
import { LevelOfAnalysis } from "../models/LevelOfAnalysisIds";
import { getMetricList } from "../utils/getLocalSelectionList";
import { getLocalSelections } from "../utils/getLocalSelections";

const getPersistenceKey = (reportId: string) => `customer-loyalty-${reportId}`;

export type CustomerLoyaltyLocalSelections = {
  locationHierarchy: LocalHierarchyNodeSelection;
  metric: LocalMetricWithFormatSelection;
  segment: PanelOption;
  segmentation: PanelOption;
  time: string;
  timePeriod: string;
};

export type CustomerLoyaltyPersistedSelections = {
  focalItemCount: number | undefined;
  focalItems: HierarchyValue[];
  focalSegment: HierarchyValue | undefined;
  levelOfAnalysis: LevelOfAnalysisDto[];
  localSelections: CustomerLoyaltyLocalSelections;
  rowSelection: RowSelectionState;
  topDrawerActiveTab: string | undefined;
};

export type CustomerLoyaltyState = {
  childrenPage: Record<string, number>;
  customerLoyaltyChartData: CustomerLoyaltyReportletResponseDto;
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  metricList: LocalMetricWithFormatSelection[];
  persistedSelections: CustomerLoyaltyPersistedSelections;
  persistedSelectionsLoaded: boolean;
  primaryMetricLabel: string;
  reportError: boolean;
  reportId: string;
  reportName: string;
  searchQuery: string;
  tablePage: number;
  topDrawerRowCount: number;
  topDrawerTableRows: CustomerLoyaltyHierarchyProductData[];
  topDrawerTableSuccess: boolean;
};

export const initialState: CustomerLoyaltyState = {
  childrenPage: {},
  customerLoyaltyChartData: {
    brands: [],
    segments: [],
    items: [],
  },
  localParameters: [],
  localParametersInitialised: false,
  metricList: [],
  primaryMetricLabel: "",
  reportName: "",
  reportError: false,
  searchQuery: "",
  tablePage: 0,
  topDrawerTableRows: [],
  topDrawerTableSuccess: false,
  topDrawerRowCount: 0,
  reportId: "",
  persistedSelectionsLoaded: false,
  persistedSelections: {
    focalItemCount: undefined,
    focalItems: [],
    focalSegment: undefined,
    levelOfAnalysis: [],
    localSelections: {
      metric: {
        isDefault: false,
        format: "",
        label: "",
        value: "",
        dataset: "",
      },
      segmentation: {
        label: "",
        value: "",
      },
      segment: {
        label: "",
        value: "",
      },
      time: "",
      timePeriod: "",
      locationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
      },
    },
    rowSelection: {} as RowSelectionState,
    topDrawerActiveTab: undefined,
  },
};

export const customerLoyaltySlice = createSlice({
  initialState,
  name: "customerLoyalty",
  reducers: {
    onLoadMoreResponseReceived: (
      state: CustomerLoyaltyState,
      action: PayloadAction<TableRowsResponseDto>
    ) => {
      state.topDrawerTableRows[state.topDrawerTableRows.length - 1].isMoreRow =
        false;

      const newData = action.payload.pagination.hasNextPage
        ? action.payload.tableRows.map((item, index) => ({
            ...item,
            isMoreRow: index === action.payload.tableRows.length - 1,
            subRows: [],
          }))
        : action.payload.tableRows.map((row) => ({
            ...row,
            subRows: [],
          }));
      state.topDrawerTableRows = state.topDrawerTableRows.concat(newData);

      state.tablePage++;
    },
    onReportError: (state: CustomerLoyaltyState) => {
      state.reportError = true;
    },
    onMetricChange: (
      state: CustomerLoyaltyState,
      action: PayloadAction<LocalMetricWithFormatSelection | undefined>
    ) => {
      state.persistedSelections.localSelections.metric = action.payload ?? {
        isDefault: false,
        format: "",
        label: "",
        value: "",
        dataset: "",
      };

      customerLoyaltySlice.caseReducers.storePersistedSelections(state);
    },
    onSegmentationChange: (
      state: CustomerLoyaltyState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localSelections.segmentation = {
        label: payload.segmentationLabel,
        value: payload.segmentationValue,
      };
      state.persistedSelections.localSelections.segment = {
        label: payload.segmentLabel,
        value: payload.segmentValue,
      };
      state.topDrawerTableSuccess = false;

      customerLoyaltySlice.caseReducers.storePersistedSelections(state);
    },
    onLocationChange: (
      state: CustomerLoyaltyState,
      { payload }: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localSelections.locationHierarchy = payload;
      state.topDrawerTableSuccess = false;

      customerLoyaltySlice.caseReducers.storePersistedSelections(state);
    },
    onTopDrawerTableChildrenResponseReceived: (
      state: CustomerLoyaltyState,
      action: PayloadAction<TableChildRowsResponseDto>
    ) => {
      if (
        action.payload.levelOfAnalysis ===
        state.persistedSelections.topDrawerActiveTab
      ) {
        const parentHierarchyItem = action.payload.parentHierarchyItem;
        const newData = action.payload.pagination.hasNextPage
          ? action.payload.tableRows.map((row, index) => ({
              ...row,
              isMoreRow: index === action.payload.tableRows.length - 1,
              parentHierarchyItem,
              subRows: [],
            }))
          : action.payload.tableRows.map((row) => ({
              ...row,
              parentHierarchyItem,
              subRows: [],
            }));

        const parentIndex = state.topDrawerTableRows.findIndex(
          (item) => item.hierarchyItem.itemCode === parentHierarchyItem.itemCode
        );

        if (parentIndex > -1) {
          const subRowsLength =
            state.topDrawerTableRows[parentIndex].subRows.length;
          if (subRowsLength > 0) {
            state.topDrawerTableRows[parentIndex].subRows[
              subRowsLength - 1
            ].isMoreRow = false;
          }

          state.topDrawerTableRows[parentIndex].subRows =
            state.topDrawerTableRows[parentIndex].subRows.concat(newData);

          const currentPage =
            state.childrenPage[parentHierarchyItem.itemCode] || 0;
          state.childrenPage[parentHierarchyItem.itemCode] = currentPage + 1;
        }

        const isParentSelected = state.persistedSelections.focalItems.find(
          (item) => item.itemCode === parentHierarchyItem.itemCode
        );

        if (isParentSelected) {
          for (const item of action.payload.tableRows) {
            state.persistedSelections.rowSelection[
              item.hierarchyItem.itemCode
            ] = true;
          }
        }
      }
    },
    onTopDrawerTableResponseReceived: (
      state: CustomerLoyaltyState,
      action: PayloadAction<TableRowsResponseDto>
    ) => {
      if (
        state.persistedSelections.topDrawerActiveTab ===
          action.payload.levelOfAnalysis &&
        (state.persistedSelections.localSelections.segment.value as string) ===
          action.payload.segmentValue
      ) {
        if (action.payload.tableRows.length > 0) {
          if (state.persistedSelections.focalItems.length < 1) {
            if (
              action.payload.levelOfAnalysis ===
              LevelOfAnalysis.ProductToSegment
            ) {
              state.persistedSelections.focalItems = action.payload.tableRows
                .slice(0, 1)
                .map((item) => item.hierarchyItem);
              state.persistedSelections.focalItemCount =
                action.payload.tableRows[0].childrenCount;
              state.persistedSelections.focalSegment =
                state.persistedSelections.focalItems[0];
            } else {
              state.persistedSelections.focalItems = action.payload.tableRows
                .slice(0, 10)
                .map((item) => item.hierarchyItem);
            }

            for (const item of state.persistedSelections.focalItems) {
              state.persistedSelections.rowSelection[item.itemCode] = true;
            }
          } else if (state.persistedSelections.focalItemCount) {
            const updatedFocalItem = action.payload.tableRows.find(
              (row) =>
                row.hierarchyItem.itemCode ===
                state.persistedSelections.focalSegment?.itemCode
            );
            state.persistedSelections.focalItemCount =
              updatedFocalItem?.childrenCount;
          }

          state.topDrawerTableRows = action.payload.pagination.hasNextPage
            ? action.payload.tableRows.map((item, index) => ({
                ...item,
                isMoreRow: index === action.payload.tableRows.length - 1,
                subRows: [],
              }))
            : action.payload.tableRows.map((row) => ({
                ...row,
                subRows: [],
              }));
          state.topDrawerRowCount = action.payload.pagination.totalCount;
          state.tablePage = 1;
          state.topDrawerTableSuccess = true;
        } else {
          state.topDrawerTableRows = [];
          state.topDrawerRowCount = 0;
          state.tablePage = 0;
        }

        state.childrenPage = {};
        customerLoyaltySlice.caseReducers.storePersistedSelections(state);
      }
    },
    setFocalItems: (
      state: CustomerLoyaltyState,
      action: PayloadAction<{
        isSelected: boolean;
        selectedRow: CustomerLoyaltyHierarchyProductData;
      }>
    ) => {
      const selectedItem = action.payload.selectedRow.hierarchyItem;
      const currentFocalItemLength =
        state.persistedSelections.focalItems.length;

      if (action.payload.isSelected) {
        state.persistedSelections.focalItems =
          state.persistedSelections.focalItems.filter(
            (value) => value.itemCode !== selectedItem.itemCode
          );

        state.persistedSelections.rowSelection =
          currentFocalItemLength > 1
            ? {
                ...state.persistedSelections.rowSelection,
                [selectedItem.itemCode]: false,
              }
            : {};

        state.persistedSelections.focalSegment =
          currentFocalItemLength > 1
            ? action.payload.selectedRow.parentHierarchyItem
            : undefined;

        state.persistedSelections.focalItemCount = undefined;
      } else {
        state.persistedSelections.focalItems =
          action.payload.selectedRow.childrenCount > 0
            ? [selectedItem]
            : [...state.persistedSelections.focalItems, selectedItem];

        state.persistedSelections.rowSelection[selectedItem.itemCode] = true;
        for (const item of action.payload.selectedRow.subRows) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (item.hierarchyItem) {
            state.persistedSelections.rowSelection[
              item.hierarchyItem.itemCode
            ] = true;
          }
        }

        state.persistedSelections.focalItemCount =
          action.payload.selectedRow.childrenCount > 0
            ? action.payload.selectedRow.childrenCount
            : undefined;

        if (
          state.persistedSelections.topDrawerActiveTab ===
            LevelOfAnalysis.ProductToSegment &&
          selectedItem.shortName === LevelOfAnalysis.Segment
        ) {
          state.persistedSelections.focalSegment = selectedItem;
        } else {
          state.persistedSelections.focalSegment =
            action.payload.selectedRow.parentHierarchyItem;
        }
      }

      customerLoyaltySlice.caseReducers.storePersistedSelections(state);
    },
    setTopDrawerActiveTab: (
      state: CustomerLoyaltyState,
      action: PayloadAction<string>
    ) => {
      if (state.persistedSelections.topDrawerActiveTab !== action.payload) {
        state.persistedSelections.topDrawerActiveTab = action.payload;
        state.persistedSelections.focalItems = [];
        state.persistedSelections.focalItemCount = undefined;
        state.persistedSelections.focalSegment = undefined;
        state.persistedSelections.rowSelection = {};
        state.topDrawerTableSuccess = false;

        customerLoyaltySlice.caseReducers.storePersistedSelections(state);
      }
    },
    storePersistedSelections: (state: CustomerLoyaltyState) => {
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    reset: () => initialState,
    onReportSuccess: (
      state: CustomerLoyaltyState,
      action: PayloadAction<CustomerLoyaltyLocalParametersResponseDto>
    ) => {
      state.reportName = action.payload.reportName;
      state.primaryMetricLabel = action.payload.primaryMetricLabel;
      state.localParameters = getReportLocalParameters(
        action.payload.localParameters
      );
      state.metricList = getMetricList(state.localParameters);

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.levelOfAnalysis =
          action.payload.levelsOfAnalysis;
        state.persistedSelections.topDrawerActiveTab =
          action.payload.levelsOfAnalysis[0].value;
        state.persistedSelections.localSelections = getLocalSelections(
          state.localParameters
        );
      }

      state.localParametersInitialised = true;
      customerLoyaltySlice.caseReducers.storePersistedSelections(state);
    },
    onCustomerLoyaltyReportletResponseReceived: (
      state: CustomerLoyaltyState,
      action: PayloadAction<CustomerLoyaltyReportletResponseDto>
    ) => {
      state.customerLoyaltyChartData = action.payload;
    },
    onSearchQueryChange: (
      state: CustomerLoyaltyState,
      action: PayloadAction<string>
    ) => {
      state.searchQuery = action.payload;
    },
    onReportOpen: (
      state: CustomerLoyaltyState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: CustomerLoyaltyPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
  },
});

export const {
  onCustomerLoyaltyReportletResponseReceived,
  onLoadMoreResponseReceived,
  onReportError,
  onMetricChange,
  onSegmentationChange,
  onLocationChange,
  onTopDrawerTableChildrenResponseReceived,
  onTopDrawerTableResponseReceived,
  reset,
  setFocalItems,
  setTopDrawerActiveTab,
  onReportSuccess,
  onSearchQueryChange,
  onReportOpen,
} = customerLoyaltySlice.actions;

export default customerLoyaltySlice.reducer;

export const selectLocalSelections = createSelector(
  (state: RootState) =>
    state.customerLoyalty.persistedSelections.localSelections,
  (localSelections: CustomerLoyaltyLocalSelections) => localSelections
);

export const selectTopDrawerActiveTab = createSelector(
  (state: RootState) =>
    state.customerLoyalty.persistedSelections.topDrawerActiveTab,
  (topDrawerActiveTab) => topDrawerActiveTab
);

export const selectFocalItemCount = createSelector(
  (state: RootState) =>
    state.customerLoyalty.persistedSelections.focalItemCount,
  (focalItemCount) => focalItemCount
);

export const selectFocalItems = createSelector(
  (state: RootState) => state.customerLoyalty.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectSearchQuery = createSelector(
  (state: RootState) => state.customerLoyalty.searchQuery,
  (searchQuery) => searchQuery
);

export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.customerLoyalty.persistedSelections.localSelections.segmentation,
  (segmentation) => segmentation
);

export const selectSegment = createSelector(
  (state: RootState) =>
    state.customerLoyalty.persistedSelections.localSelections.segment,
  (segment) => segment
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.customerLoyalty.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectReportId = createSelector(
  (state: RootState) => state.customerLoyalty.reportId,
  (reportId) => reportId
);

export const selectLocalParameters = createSelector(
  (state: RootState) => state.customerLoyalty.localParameters,
  (localParameters) => localParameters
);

export const selectMetricList = createSelector(
  (state: RootState) => state.customerLoyalty.metricList,
  (metricList) => metricList
);

export const selectTablePage = createSelector(
  (state: RootState) => state.customerLoyalty.tablePage,
  (tablePage) => tablePage
);

export const selectRowSelection = createSelector(
  (state: RootState) => state.customerLoyalty.persistedSelections.rowSelection,
  (rowSelection) => rowSelection
);

export const selectTableRows = createSelector(
  (state: RootState) => state.customerLoyalty.topDrawerTableRows,
  (topDrawerTableRows) => topDrawerTableRows
);

export const selectTopDrawerRowCount = createSelector(
  (state: RootState) => state.customerLoyalty.topDrawerRowCount,
  (topDrawerRowCount) => topDrawerRowCount
);

export const selectLocationNodeSelection = createSelector(
  (state: RootState) =>
    state.customerLoyalty.persistedSelections.localSelections.locationHierarchy,
  (locationHierarchy) => locationHierarchy
);

export const selectChildrenPage = createSelector(
  (state: RootState) => state.customerLoyalty.childrenPage,
  (childrenPage) => childrenPage
);

export const selectTopDrawerTableSuccess = createSelector(
  (state: RootState) => state.customerLoyalty.topDrawerTableSuccess,
  (topDrawerTableSuccess) => topDrawerTableSuccess
);

export const selectFocalSegment = createSelector(
  (state: RootState) => state.customerLoyalty.persistedSelections.focalSegment,
  (focalSegment) => focalSegment
);

export const selectChartData = createSelector(
  (state: RootState) => state.customerLoyalty.customerLoyaltyChartData,
  (customerLoyaltyChartData) => customerLoyaltyChartData
);

export const selectMetric = createSelector(
  (state: RootState) =>
    state.customerLoyalty.persistedSelections.localSelections.metric,
  (metric) => metric
);

export const selectPrimaryMetricLabel = createSelector(
  (state: RootState) => state.customerLoyalty.primaryMetricLabel,
  (primaryMetricLabel) => primaryMetricLabel
);
