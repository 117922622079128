import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { type SeriesOptionsType } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HighchartsCustomTooltip } from "../../highcharts-custom-tooltip/HighchartsCustomTooltip";
import { type ChartDataSeries } from "../column-and-line-chart/BasicColumnAndLineChart";
import styles from "../column-and-line-chart/BasicColumnAndLineChart.module.scss";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import {
  defaultOptions,
  HighchartsReact,
} from "../highcharts-react/HighchartsReact";

export type CompareMetricsColumnChartProps = {
  readonly categories: string[];
  readonly data: ChartDataSeries[];
  readonly dataFormatter: FormatterFunction;
  readonly height?: string;
  readonly onOptionsChanged?: (options: HighchartsReactProps) => void;
  readonly showChartDataLabels?: boolean;
  readonly threshold?: number;
  readonly tooltipSentiment: boolean;
  readonly yAxisLabel?: string;
};

export const CompareMetricsColumnChart = ({
  data,
  categories,
  dataFormatter,
  height,
  onOptionsChanged,
  yAxisLabel,
  threshold = 0,
  tooltipSentiment,
  showChartDataLabels,
}: CompareMetricsColumnChartProps) => {
  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        height,
        style: {
          ...defaultOptions.chart?.style,
        },
        type: "column",
      },
      legend: {
        ...defaultOptions.legend,
        enabled: true,
        symbolRadius: 0,
      },
      plotOptions: {
        column: {
          pointPadding: categories.length > 1 ? 0.1 : 0.2,
          borderWidth: 0,
          groupPadding: categories.length > 1 ? 0.1 : 0,
          threshold,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        series: {
          dataLabels: {
            enabled: showChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return dataFormatter(this.y);
            },
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
      series: data.map(
        (series: ChartDataSeries, index: number): SeriesOptionsType => ({
          ...series,
          visible: !hiddenSeries.includes(index),
        })
      ),
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsCustomTooltip({
            chartDataFormatter: dataFormatter,
            hasSentiment: tooltipSentiment,
            percentFormatter: () => "",
            ttData,
            yAxisTitle: yAxisLabel ?? "",
          }),
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
      yAxis: {
        ...defaultOptions.yAxis,
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return dataFormatter(this.value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          text: yAxisLabel,
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
    }),
    [
      categories,
      data,
      dataFormatter,
      height,
      hiddenSeries,
      legendItemClick,
      showChartDataLabels,
      threshold,
      tooltipSentiment,
      yAxisLabel,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return (
    <div className={styles.parentContainer}>
      <HighchartsReact options={options} />
    </div>
  );
};
