import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isLocationSelectionParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildLocationHierarchyPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildSingleSelectPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { PrimaryMetricKey, SecondaryMetric, ViewByOptions } from "../constants";
import {
  onChannelChange,
  onLocationChange,
  onSegmentationChange,
  onViewByChange,
  selectLocalParameter,
  selectChartAdditionalMetric,
  selectViewBy,
  selectChannel,
  selectLocationHierarchy,
  selectSegmentation,
} from "../services/pricing-ladders-slice";

export const PricingLaddersSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  const dispatch = useDispatch();
  const additionalMetricState = useSelector(selectChartAdditionalMetric);

  const localParametersConfig = useSelector(selectLocalParameter);
  const channel = useSelector(selectChannel);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const segmentation = useSelector(selectSegmentation);
  const viewBy = useSelector(selectViewBy);

  const additionalMetrics = [
    {
      value: ViewByOptions.Avg,
      label: PrimaryMetricKey.Avg,
      segmentValue: additionalMetricState,
      segmentLabel: additionalMetricState,
      isDefault: false,
    },
    {
      value: ViewByOptions.AvgPricePerVolume,
      label: PrimaryMetricKey.AvgPricePerVolume,
      segmentValue: additionalMetricState,
      segmentLabel: additionalMetricState,
      isDefault: false,
    },
  ];

  const generatePanels = () => {
    const panels: Panel[] = [];
    for (const parameter of localParametersConfig) {
      switch (parameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(parameter))
            panels.push(buildTimePeriodPanel(parameter));

          break;
        case LocalParameters.ViewBy:
          if (isSegmentationParameter(parameter)) {
            const selections =
              additionalMetricState &&
              additionalMetricState !== SecondaryMetric.None
                ? parameter.selections.concat(additionalMetrics)
                : parameter.selections;
            panels.push(
              buildSegmentationPanel(
                { ...parameter, secondaryTitle: "Sort by", selections },
                (value: SegmentOption) => {
                  dispatch(onViewByChange(value));
                },
                viewBy.map((view) => view.value.toString())
              )
            );
          }

          break;
        case LocalParameters.Segmentation:
          if (isSegmentationParameter(parameter)) {
            panels.push(
              buildSegmentationPanel(
                parameter,
                (value: SegmentOption) => {
                  dispatch(onSegmentationChange(value));
                },
                segmentation.map((segment) => segment.value.toString())
              )
            );
          } else if (isPlainTextContentParameter(parameter)) {
            panels.push(buildPlainTextContentPanel(parameter));
          }

          break;
        case LocalParameters.Channel:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onChannelChange(value));
                },
                channel.value.toString()
              )
            );
          }

          break;
        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(parameter)) {
            const onLocationSelection = (
              selection: LocalHierarchyNodeSelection
            ) => {
              dispatch(onLocationChange(selection));
            };

            panels.push(
              buildLocationHierarchyPanel(
                parameter,
                onLocationSelection,
                locationHierarchy
              )
            );
          }

          break;
        default:
          break;
      }
    }

    return panels;
  };

  return (
    <SidePanel
      eventTrackingService={eventTrackingService}
      panels={generatePanels()}
    />
  );
};

export default PricingLaddersSidePanel;
