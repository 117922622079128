import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { REPORT_DATA_CACHE_LENGTH_SECONDS } from "../../reportUtilities";
import {
  type customerProfilingRequest,
  type customerProfilingResponse,
} from "./customerProfilingDtos";

export const customerProfilingApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    customerProfilingMeasures: builder.query<
      customerProfilingResponse,
      customerProfilingRequest & { division: string }
    >({
      keepUnusedDataFor: REPORT_DATA_CACHE_LENGTH_SECONDS,
      providesTags: ["CustomerProfilingReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/customer-profiling-report`,
      }),
    }),
  }),
  reducerPath: "customerProfilingApi",
  tagTypes: ["CustomerProfilingReport"],
});

export const { useCustomerProfilingMeasuresQuery } = customerProfilingApi;
