import { type RouteWithLabel } from "@quantium-enterprise/common-ui";
import { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { store } from "./store";

// eslint-disable-next-line @typescript-eslint/promise-function-async -- this is as per React docs
const RangePerformance = lazy(() => import("./RangePerformance"));

export default (): RouteWithLabel => ({
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <RangePerformance />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Range performance",
  path: "range-performance",
});
