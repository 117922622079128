import { type GainLossData } from "components-ui/src/tables/gains-loss-table/GainsLossTable";
import { type GainsAndLossMetricValue } from "../models/GainsAndLossOverviewReportletDto";

export const waterfallChartCsvTransformation = (
  waterfallData: GainsAndLossMetricValue[],
  metricHeader: string
): string[][] => {
  const columnHeader = ["Measures", metricHeader];
  const csvData = [columnHeader];
  for (const item of waterfallData) {
    const row = [item.name, item.data.toString()];
    csvData.push(row);
  }

  return csvData;
};

export const overviewDataTableCsvTransformation = (
  tableData: GainLossData[],
  headers: string[]
): Array<Array<string | undefined> | undefined> => {
  let csvData: Array<Array<string | undefined> | undefined> = [];
  const columnHeader = ["Measures"].concat(headers);
  csvData = [columnHeader];
  for (const item of tableData) {
    const row = [
      item.header.title,
      item.sales.net?.toString(),
      item.sales.gain?.toString(),
      item.sales.loss?.toString(),
      item.units.net?.toString(),
      item.units.gain?.toString(),
      item.units.loss?.toString(),
      item.volume.net?.toString(),
      item.volume.gain?.toString(),
      item.volume.loss?.toString(),
    ];
    csvData.push(row);
  }

  return csvData;
};
