import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  Group,
  GroupGutters,
  GroupRowspace,
  Item,
  Button,
  ButtonVariant,
  ItemValign,
  ItemHalign,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { useCallback } from "react";
import { DownloadIcon } from "../assets/icons/DownloadIcon";
import { downloadFile, toCSV } from "../export/export-functions";
import styles from "./DataTableOptions.module.scss";

export type DataTableOptionsProps = {
  disabled?: boolean;
  filename: string;
  /**
   * either return string[][] representing the data, which will automatically start the export in browser, or use this function to invoke the backend API to start the export/download of the CSV data from the backend directly
   */
  invokeCSVDownload: (() => string[][]) | (() => void);
  isFeatureEnabled?: boolean;
  localParameters?: Array<{
    name: string;
    value: string;
  }>;
  reportParameters?: ReportParametersDto;
};

export const DataTableOptions = ({
  filename,
  localParameters,
  reportParameters,
  invokeCSVDownload,
  disabled,
  isFeatureEnabled,
}: DataTableOptionsProps) => {
  const downloadAsCsvCallback = useCallback(() => {
    try {
      const data = invokeCSVDownload();
      if (Array.isArray(data)) {
        const csvContent = toCSV(reportParameters, localParameters, data);
        const encodedUri = encodeURI(csvContent);
        downloadFile(encodedUri, `${filename}.csv`);
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  }, [reportParameters, localParameters, filename, invokeCSVDownload]);

  return (
    <>
      {isFeatureEnabled && (
        <Group
          gutters={GroupGutters.None}
          rowspace={GroupRowspace.None}
          wrap={false}
        >
          <Item halign={ItemHalign.Right} valign={ItemValign.Bottom}>
            <Button
              className={styles.iconButton}
              disabled={disabled}
              onClick={downloadAsCsvCallback}
              variant={ButtonVariant.Stealth}
            >
              <span
                className={classNames(
                  styles.buttonContent,
                  styles.centerAlignFix
                )}
                title="Download"
              >
                <DownloadIcon />
              </span>
            </Button>
          </Item>
        </Group>
      )}
    </>
  );
};
