import {
  TransactionSource,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isLocationSelectionParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildLocationHierarchyPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildSingleSelectPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";
import {
  onChannelChange,
  onDatasetChange,
  onLevelOfAnalysisChange,
  onLocationChange,
  onSegmentationChange,
  selectDataset,
  selectLocalParametersSelections,
} from "../services/aggregate-rank-slice";

export const AggregateRankSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  const dispatch = useDispatch();

  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );

  const { localParametersConfig } = useSelector((state: RootState) => ({
    localParametersConfig: state.aggregateRank.localParametersConfig,
  }));

  const dataset = useSelector(selectDataset);

  const generatePanels = () => {
    const panels: Panel[] = [];

    for (const parameter of localParametersConfig) {
      switch (parameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(parameter))
            panels.push(buildTimePeriodPanel(parameter));
          break;

        case LocalParameters.LevelOfAnalysis:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onLevelOfAnalysisChange(value));
                },
                localParametersSelections.LevelOfAnalysis.value.toString()
              )
            );
          }

          break;

        case LocalParameters.Channel:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onChannelChange(value));
                },
                localParametersSelections.Channel.value.toString()
              )
            );
          }

          break;

        case LocalParameters.Segmentation:
          if (isSegmentationParameter(parameter)) {
            panels.push(
              buildSegmentationPanel(
                dataset.value === TransactionSource.Total.toString()
                  ? { ...parameter, isDisabled: true }
                  : parameter,
                (value: SegmentOption) => {
                  dispatch(onSegmentationChange(value));
                },
                localParametersSelections.Segmentation.map((segment) =>
                  segment.value.toString()
                )
              )
            );
          } else if (isPlainTextContentParameter(parameter)) {
            panels.push(buildPlainTextContentPanel(parameter));
          }

          break;

        case LocalParameters.Dataset:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onDatasetChange(value));
                },
                localParametersSelections.Dataset.value.toString()
              )
            );
          }

          break;

        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(parameter)) {
            const onLocationSelection = (
              selection: LocalHierarchyNodeSelection
            ) => {
              dispatch(onLocationChange(selection));
            };

            panels.push(
              buildLocationHierarchyPanel(
                parameter,
                onLocationSelection,
                localParametersSelections.LocationHierarchy
              )
            );
          }

          break;

        default: {
          break;
        }
      }
    }

    return panels;
  };

  return (
    <SidePanel
      eventTrackingService={eventTrackingService}
      panels={generatePanels()}
    />
  );
};

export default AggregateRankSidePanel;
