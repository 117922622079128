import { type MetricsListResponse } from "../models/new-product-benchmarking-metrics-list-dto";
import { newProductBenchmarkingApiSlice } from "./new-product-benchmarking-api-slice";

export const newProductBenchmarkingMetricsListApiSlice =
  newProductBenchmarkingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getMetricsList: builder.query<
        MetricsListResponse,
        { divisionName: string; reportId: string }
      >({
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetMetricsList/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useGetMetricsListQuery } =
  newProductBenchmarkingMetricsListApiSlice;
