import { type TransactionSource } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Icon,
  IconColour,
  IconGlyph,
  IconSize,
  Spinner,
  SpinnerSize,
} from "@quantium-enterprise/qds-react";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import styles from "./GroupTransactionSourceIcon.module.css";

export type GroupTransactionSourceIconProps = {
  isDataEntitlementsShown?: boolean;
  isLoading?: boolean;
  transactionSource?: TransactionSource | null;
};

export const GroupTransactionSourceIcon = ({
  isLoading = false,
  transactionSource,
  isDataEntitlementsShown = false,
}: GroupTransactionSourceIconProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  if (transactionSource === null) {
    return (
      <Icon
        className={styles.transactionSourceIcon}
        colour={IconColour.Bad}
        data-testid="empty-group-txn-source-icon"
        glyph={IconGlyph.AlertsAndNotificationsAlertCircle}
        size={IconSize.Small}
        text="Group is empty."
      />
    );
  }

  if (isLoading) {
    return (
      <Spinner
        className={styles.transactionSourceIcon}
        data-testid="loading-group-txn-source-icon"
        size={SpinnerSize.XSmall}
      />
    );
  }

  return isDataEntitlementsShown ? (
    <TransactionSourceIcon
      availableTransactionSources={availableTransactionSources}
      transactionSource={transactionSource}
    />
  ) : (
    <TransactionSourceIcon />
  );
};
