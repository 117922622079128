import { type LocalSelectedValueDto } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  type SidePanelParameter,
  type SidePanelLocationHierarchyParameter,
  type SidePanelTimePeriodParameter,
  type SidePanelGroupParameter,
} from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type TrialAndRepeatParameterSelections } from "../models/TrialAndRepeatParameterSelections";
// import { initialState } from "../services/trial-and-repeat-slice";

export const getLocalSelections = (
  localParameters: SidePanelParameter[]
): TrialAndRepeatParameterSelections => {
  const localSelections: TrialAndRepeatParameterSelections = {
    metric: {
      label: "",
      value: "",
    },
    channel: {
      label: "",
      value: "",
    },
    segmentation: [],
    promotion: {
      label: "",
      value: "",
    },
    LocationHierarchy: {
      code: "",
      nodeNumber: -1,
      depth: -1,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      shortName: "",
      isDefault: false,
    },
    time: "",
    timePeriodLength: "",
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const { time, timePeriodLength } = getTimeDefaultSelection(parameter);

          localSelections.time = time;
          localSelections.timePeriodLength = timePeriodLength;
        }

        break;
      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.segmentation = [
              { label: selection.label, value: selection.value },
              { label: selection.segmentLabel, value: selection.segmentValue },
            ];
          }
        }

        break;
      case LocalParameters.Metric:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.metric.label = selection.label;
            localSelections.metric.value = selection.value;
          }
        }

        break;
      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.channel.label = selection.label;
            localSelections.channel.value = selection.value;
          }
        }

        break;
      case LocalParameters.Promotion:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.promotion.label = selection.label;
            localSelections.promotion.value = selection.value;
          }
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          localSelections.LocationHierarchy =
            getLocationSelectDefault(parameter);
        }

        break;
      default:
        break;
    }
  }

  return localSelections;
};

export const getUserSelectionsForExport = (
  localParameterSelections: TrialAndRepeatParameterSelections,
  localParametersConfig: SidePanelParameter[]
) => {
  const userSelections: LocalSelectedValueDto[] = [];

  for (const parameter of localParametersConfig) {
    switch (parameter.id) {
      case "ComparsionPeriod":
        break;
      case LocalParameters.Time: {
        const timeParameter = parameter as SidePanelTimePeriodParameter;
        userSelections.push({
          id: LocalParameters.Time,
          values: [getTimeDefaultSelection(timeParameter).time],
        });
        break;
      }

      case LocalParameters.Segmentation: {
        const segmentation = localParameterSelections.segmentation;
        if (Array.isArray(segmentation) && segmentation.length > 0) {
          const [, ...segment] = segmentation;
          const segmetation = segmentation.slice(0, 1);
          userSelections.push({
            id: LocalParameters.Segmentation,
            values: Object.values(segmetation).map((data) =>
              data.label.toString()
            ),
          });
          userSelections.push({
            id: "Customer segment",
            values: Object.values(segment).map((data) => data.label.toString()),
          });
        }

        break;
      }

      case LocalParameters.LocationHierarchy: {
        const locations = localParametersConfig.find(
          (item) => item.id === LocalParameters.LocationHierarchy
        ) as SidePanelLocationHierarchyParameter;
        if (
          Array.isArray(locations.selections) &&
          locations.selections.length > 0
        ) {
          userSelections.push({
            id: LocalParameters.LocationHierarchy,
            values: locations.selections.map(
              (data) =>
                `(${hierarchyLevelDisplayLabel(data.shortName)}) ${data.name}`
            ),
          });
        }

        break;
      }

      case LocalParameters.LocationGroups: {
        const locations = localParametersConfig.find(
          (item) => item.id === LocalParameters.LocationGroups
        ) as SidePanelGroupParameter;
        if (
          Array.isArray(locations.selections) &&
          locations.selections.length > 0
        ) {
          userSelections.push({
            id: LocalParameters.LocationGroups,
            values: locations.selections.map(
              (data) => `(GRP) ${data.groupName}`
            ),
          });
        }

        break;
      }

      default:
        break;
    }
  }

  userSelections.push({
    id: LocalParameters.Channel,
    values: [localParameterSelections.channel.value.toString()],
  });

  userSelections.push({
    id: LocalParameters.Metric,
    values: [localParameterSelections.metric.value.toString()],
  });

  userSelections.push({
    id: LocalParameters.Promotion,
    values: [localParameterSelections.promotion.value.toString()],
  });

  return userSelections;
};
