export const DownloadIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0026 12.4987V14.9987H5.0026V12.4987H3.33594V14.9987C3.33594 15.9154 4.08594 16.6654 5.0026 16.6654H15.0026C15.9193 16.6654 16.6693 15.9154 16.6693 14.9987V12.4987H15.0026ZM14.1693 9.16536L12.9943 7.99036L10.8359 10.1404V3.33203H9.16927V10.1404L7.01094 7.99036L5.83594 9.16536L10.0026 13.332L14.1693 9.16536Z"
      fill="#676D70"
    />
  </svg>
);
