import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "../../store";

export const selectFocalItem = createSelector(
  (state: RootState) => state.keyDriverTree.persistedSelections.focalItem,
  (focalItem) => focalItem
);

export const selectFocalItemTableInitialised = createSelector(
  (state: RootState) => state.keyDriverTree.focalItemTableInitialised,
  (focalItemTableInitialised) => focalItemTableInitialised
);

export const selectHierarchySliceHypercubeId = createSelector(
  (state: RootState) => state.keyDriverTree.hierarchySliceHypercubeId,
  (hierarchySliceHypercubeId) => hierarchySliceHypercubeId
);

export const selectInitialDataRecieved = createSelector(
  (state: RootState) => state.keyDriverTree.focalItemTableInitialised,
  (focalItemTableInitialised) => focalItemTableInitialised
);

export const selectLocalParameterSelections = createSelector(
  (state: RootState) =>
    state.keyDriverTree.persistedSelections.localParameterSelections,
  (localParameterSelections) => localParameterSelections
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.keyDriverTree.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectMeasureGroups = createSelector(
  (state: RootState) => state.keyDriverTree.focalItemTableMeasureGroups,
  (focalItemTableMeasureGroups) => focalItemTableMeasureGroups
);

export const selectRowSelection = createSelector(
  (state: RootState) =>
    state.keyDriverTree.persistedSelections.hierarchyTableRowSelectionState,
  (hierarchyTableRowSelectionState) => hierarchyTableRowSelectionState
);

export const selectTableRows = createSelector(
  (state: RootState) => state.keyDriverTree.focalItemTableRows,
  (focalItemTableRows) => focalItemTableRows
);

export const selectSearchText = createSelector(
  (state: RootState) => state.keyDriverTree.focalItemSearchText,
  (focalItemSearchText) => focalItemSearchText
);

export const selectIsSearchingFocalItem = createSelector(
  (state: RootState) => state.keyDriverTree.isSearchingFocalItem,
  (isSearchingFocalItem) => isSearchingFocalItem
);

export const selectReportId = createSelector(
  (state: RootState) => state.keyDriverTree.metaData.reportId,
  (reportId) => reportId
);

export const selectReportName = createSelector(
  (state: RootState) => state.keyDriverTree.metaData.reportName,
  (reportName) => reportName
);
