import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import {
  FormInputHeight,
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
} from "@quantium-enterprise/qds-react";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../store";
import { type Attribute } from "../models/Attribute";
import { type Metric } from "../models/Metric";
import {
  onAxisSwitchChange,
  onBubbleSizeSelectionChange,
  onLegendSelectionChange,
  onXAxisSelectionChange,
  onYAxisSelectionChange,
} from "../services/product-substitutability-slice";
import { isAttribute } from "../utils/isAttribute";
import { isMetric } from "../utils/isMetric";
import {
  ProductSubstitutabilityDropdownFilter,
  ProductSubstitutabilityDropdownFilterVariant,
} from "./ProductSubstitutabilityDropdownFilter";
import styles from "./ProductUniquenessReportletFilterGroup.module.css";

export const NONE_OPTION: Metric = {
  displayName: "None",
  format: MetricTypes.None,
  key: "None",
};

export const ProductUniquenessReportletFilterGroup = () => {
  const dispatch = useDispatch();

  const { attributes, metrics, percentiles } = useSelector(
    (state: RootState) => ({
      attributes: state.productSubstitutability.attributes,
      metrics: state.productSubstitutability.focalItemTableMetrics,
      percentiles: state.productSubstitutability.percentiles,
    })
  );

  const {
    xAxisSelection,
    yAxisSelection,
    bubbleSizeSelection,
    legendSelection,
  } = useSelector((state: RootState) => ({
    xAxisSelection: state.productSubstitutability.xAxisSelection,
    yAxisSelection: state.productSubstitutability.yAxisSelection,
    bubbleSizeSelection: state.productSubstitutability.bubbleSizeSelection,
    legendSelection: state.productSubstitutability.legendSelection,
  }));

  const constructSelectOptions = useCallback(
    (
      list: Array<Attribute | Metric>,
      omitSelection?: string
    ): PanelOption[] => {
      let baseListOptions: Array<Attribute | Metric> = [...list];

      if (omitSelection && omitSelection !== "") {
        baseListOptions = baseListOptions.filter((entry) => {
          if (isAttribute(entry)) return entry.displayName !== omitSelection;
          if (isMetric(entry)) return entry.key !== omitSelection;
          return false;
        });
      }

      // Only valid metrics can be selected for the bubble size
      const validMetrics: string[] = [
        NONE_OPTION.key ?? "None",
        ...percentiles.map((percentile) => percentile.key),
      ];

      const filteredListOptions = baseListOptions
        .filter((entry) => {
          if (isMetric(entry)) {
            return validMetrics.includes(entry.key ?? entry.displayName);
          }

          return entry;
        })
        .sort((a, b) => {
          if (isMetric(a) && isMetric(b)) {
            const aKey = a.key ?? a.displayName;
            const bKey = b.key ?? b.displayName;

            return validMetrics.indexOf(aKey) - validMetrics.indexOf(bKey);
          }

          return 0;
        });

      return filteredListOptions.map((entry) => {
        if (isMetric(entry)) {
          return {
            label: entry.displayName,
            value: entry.key ?? entry.displayName,
          };
        }

        if (isAttribute(entry)) {
          return {
            label: entry.displayName,
            value: entry.shortName,
          };
        }

        return { label: "", value: "" };
      });
    },
    [percentiles]
  );

  return (
    <div className={styles.productUniquenessReportletFilterGroup}>
      <ProductSubstitutabilityDropdownFilter header="X-Axis">
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          onSelection={(option: PanelOption) =>
            dispatch(onXAxisSelectionChange(option))
          }
          selectOptions={constructSelectOptions(
            metrics,
            yAxisSelection.value as string
          )}
          selectedValue={xAxisSelection.value as string}
        />
      </ProductSubstitutabilityDropdownFilter>
      <div className={styles.switchButton}>
        <Button
          height={ButtonHeight.Small}
          onClick={() => dispatch(onAxisSwitchChange())}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            colour="var(--qbit-colour-shade-10)"
            glyph={IconGlyph.ArrowsSwapHoriz}
            text="Switch"
          />
        </Button>
      </div>
      <ProductSubstitutabilityDropdownFilter header="Y-Axis">
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          onSelection={(option: PanelOption) =>
            dispatch(onYAxisSelectionChange(option))
          }
          selectOptions={constructSelectOptions(
            metrics,
            xAxisSelection.value as string
          )}
          selectedValue={yAxisSelection.value as string}
        />
      </ProductSubstitutabilityDropdownFilter>
      <ProductSubstitutabilityDropdownFilter
        header="Bubble size"
        variant={ProductSubstitutabilityDropdownFilterVariant.WithLeftMargin}
      >
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          onSelection={(option: PanelOption) =>
            dispatch(onBubbleSizeSelectionChange(option))
          }
          selectOptions={constructSelectOptions([NONE_OPTION, ...metrics])}
          selectedValue={bubbleSizeSelection.value as string}
        />
      </ProductSubstitutabilityDropdownFilter>
      <ProductSubstitutabilityDropdownFilter
        header="Legend"
        variant={ProductSubstitutabilityDropdownFilterVariant.WithLeftMargin}
      >
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          onSelection={(option: PanelOption) =>
            dispatch(onLegendSelectionChange(option))
          }
          selectOptions={constructSelectOptions(attributes)}
          selectedValue={legendSelection.value as string}
        />
      </ProductSubstitutabilityDropdownFilter>
    </div>
  );
};
