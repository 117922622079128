import { type HierarchyValueDto } from "@quantium-enterprise/common-ui";
import { type PricingLaddersTableRow } from "../models/PricingLaddersDataTableResponseDto";

export const GetAutoSelectedItems = (
  autoSelectValue: string,
  autoSelectCount: number,
  rows: PricingLaddersTableRow[]
): HierarchyValueDto[] => {
  const result: HierarchyValueDto[] = [];

  if (rows.length === 0) return [];

  if (autoSelectValue) {
    const filterValue = Number.parseFloat(autoSelectValue);

    for (const entry of rows) {
      const value = entry.metricValues[0];
      if (value > filterValue) {
        result.push(entry.hierarchyItem);
      }
    }
  } else {
    for (
      let index = 0;
      index < Math.min(autoSelectCount, rows.length);
      index++
    ) {
      result.push(rows[index].hierarchyItem);
    }
  }

  return result;
};
