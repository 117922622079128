import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

// Base query following the code splitting pattern
// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const aggregateRankApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/aggregate-rank`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "AggregateRankApi",
  tagTypes: ["aggregate-rank"],
});
