import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type MetricMetadata } from "../models/DataTableDto";

export const columnHeadersTransform = (
  name: string,
  metrics: MetricMetadata[],
  currency: string
) => {
  const modifiedHeader = [];
  for (const metric of metrics) {
    if (metric.format === MetricTypes.Currency) {
      modifiedHeader.push(`${metric.metricLabel}(${currency}) - ${name}`);
    } else {
      modifiedHeader.push(`${metric.metricLabel} - ${name}`);
    }
  }

  return modifiedHeader;
};

export const seriesTransform = (modify: number[][], indexes: number[]) => {
  if (indexes.length === 2) {
    const modifiedseries = [modify[indexes[0]].toString().split(",")];
    modifiedseries.push(modify[indexes[1]].toString().split(","));
    return modifiedseries;
  }

  return [modify[indexes[0]].toString().split(",")];
};
