import { PURCHASE_ACTIVITY_TABS } from "../models/PurchaseActivityTabs";

export const getSlugFromBreakdown = (breakdownType: string) => {
  switch (breakdownType) {
    case PURCHASE_ACTIVITY_TABS.TrialAndRepeat:
      return "trial-repeat";
    case PURCHASE_ACTIVITY_TABS.Segmentation:
      return "segmentation";
    case PURCHASE_ACTIVITY_TABS.Promotion:
      return "promotion";
    case PURCHASE_ACTIVITY_TABS.Channel:
      return "channel";
    default:
      return "";
  }
};
