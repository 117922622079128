import {
  FormBlock,
  Input,
  type TypeaheadMultiError,
  TypeaheadFilter,
  TypeaheadMulti,
} from "@quantium-enterprise/qds-react";
import { type Option } from "@quantium-enterprise/qds-react/dist/Typeahead";
import React, { useEffect, useMemo } from "react";
import { CollapsiblePanel } from "./CollapsiblePanel";

export type MultiSelectFilterProps = {
  handleOnError?: (error: TypeaheadMultiError) => unknown;
  hideSelections?: boolean;
  isCollapsed?: boolean;
  isDisabled?: boolean;
  maxSelected?: number | undefined;
  maxShownInSummary?: number | undefined;
  noSelectionMessage?: JSX.Element;
  onSelection?: (selection: Option[]) => unknown;
  selectOptions: Option[];
  selectPlaceholder?: string;
  selectedItems?: string[];
  showSelectionCount?: boolean;
  title: string;
  toggleCollapsed: () => void;
};

const defaultNoSelectionMessage = <div>No active selection</div>;
const noOp = () => {};

export const MultiSelectFilter = ({
  title,
  selectOptions,
  selectedItems,
  hideSelections = false,
  showSelectionCount = false,
  selectPlaceholder = "Add selection",
  maxSelected,
  maxShownInSummary,
  noSelectionMessage = defaultNoSelectionMessage,
  isCollapsed = false,
  isDisabled = false,
  onSelection = noOp,
  handleOnError = noOp,
  toggleCollapsed = noOp,
}: MultiSelectFilterProps) => {
  const optionFromStringValues = useMemo(
    (): Option[] =>
      // Parse selectOptions for these values and return array of options
      selectOptions.filter((x) => selectedItems?.includes(x.value.toString())),
    [selectedItems, selectOptions]
  );

  const [selected, setSelected] = React.useState(optionFromStringValues);

  const handleOnChange = (selection: Option[]) => {
    setSelected(selection);
    onSelection(selection);
  };

  useEffect(() => {
    setSelected(optionFromStringValues);
  }, [optionFromStringValues]);

  const select = (
    <FormBlock>
      <Input>
        <TypeaheadMulti
          disabled={isDisabled}
          filterBy={TypeaheadFilter.Value}
          hideSelections={hideSelections}
          id={`multiSelect-${title}`}
          maxSelected={maxSelected}
          onChange={(selection) => {
            handleOnChange(selection);
          }}
          onError={handleOnError}
          options={[{ options: selectOptions }]}
          placeholder={selectPlaceholder}
          selectedItems={selected}
          showSelectionsCount={showSelectionCount}
        />
      </Input>
    </FormBlock>
  );

  const getSummaryPanel = () => {
    if (selected.length) {
      const selectionsToDisplay = maxShownInSummary
        ? selected.slice(0, maxShownInSummary)
        : selected;
      const selectionString = selectionsToDisplay
        .map((selection) => selection.label)
        .join(", ");
      return maxShownInSummary && selected.length > maxShownInSummary ? (
        <div>
          {selectionString + `...+${selected.length - maxShownInSummary} More`}
        </div>
      ) : (
        <div>{selectionString}</div>
      );
    } else {
      return noSelectionMessage;
    }
  };

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      isDisabled={isDisabled}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content: select,
        summary: getSummaryPanel(),
      }}
    </CollapsiblePanel>
  );
};

export default MultiSelectFilter;
