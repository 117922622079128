import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type ChartDataSeries } from "components-ui/src/charts/trends-chart/TrendsChart";
import { getFocalItemLabels } from "../../../common/utils/focal-item-labels-utils";
import { type ChartSelections } from "../../models/chart-selections";
import { type DayOfWeekResponseDto } from "../../models/day-of-week-chart";
import {
  type HoursData,
  type TimeOfDayResponseDto,
} from "../../models/time-of-day-chart";

const colorRange = [
  "#E88030",
  "#3EAFBF",
  "#C96478",
  "#5885FF",
  "#DBAC49",
  "#B10200",
  "#7256A7",
  "#219653",
  "#1B3B84",
  "#736E68",
];

// repeat the colorRange when focalitems exceeds the range length
const getColor = (index: number) => colorRange[index % 10];

export const getFocalData = (
  focalItems: HierarchySliceNodeDto[],
  reportletData: DayOfWeekResponseDto | TimeOfDayResponseDto,
  chartSelections: ChartSelections,
  focalItemParents: HierarchySliceNodeDto[]
) => {
  const focalNodes = new Map(
    focalItems.map((item) => [item.nodeNumber, item.name])
  );
  const focalItemLabels = getFocalItemLabels(focalItems, focalItemParents);

  const data =
    "itemData" in reportletData
      ? reportletData.itemData
      : reportletData.timeOfDayData;

  const result: ChartDataSeries[] = [];
  let index = 0;
  for (const item of data) {
    if (
      item.segment === chartSelections.segment.selected &&
      focalNodes.has(item.nodeNumber)
    ) {
      const focalItemName =
        focalItemLabels[item.nodeNumber] ?? focalNodes.get(item.nodeNumber);
      if ("hoursData" in item) {
        const dayData = item.hoursData.find(
          (hourData) => hourData.day === chartSelections.dayOfWeek.selected
        );
        if (dayData) {
          result.push({
            colour: getColor(index),
            data: dayData.values,
            name: focalItemName,
          } as ChartDataSeries);
          index++;
        }
      } else {
        result.push({
          colour: getColor(index),
          data: item.values,
          name: focalItemName,
        } as ChartDataSeries);
        index++;
      }
    }
  }

  return result;
};

export const getBenchmarkData = (
  reportletData: DayOfWeekResponseDto | TimeOfDayResponseDto,
  chartSelections: ChartSelections
) => {
  const selectedBenchmark = chartSelections.benchmark.selected;
  if (selectedBenchmark !== undefined && selectedBenchmark !== -1) {
    const benchmarkName = chartSelections.benchmark.options.find(
      (item) => item.nodeNumber === selectedBenchmark
    )?.name;

    const selectedSegment = chartSelections.segment.selected;
    const benchmarkData =
      "itemData" in reportletData
        ? reportletData.itemData.find(
            ({ segment, nodeNumber }) =>
              segment === selectedSegment && nodeNumber === selectedBenchmark
          )
        : reportletData.timeOfDayData.find(
            ({ segment, nodeNumber }) =>
              segment === selectedSegment && nodeNumber === selectedBenchmark
          );

    let itemValues: number[] | undefined = [];
    if (benchmarkData) {
      if ("hoursData" in benchmarkData) {
        itemValues = benchmarkData.hoursData.find(
          (hourData: HoursData) =>
            hourData.day === chartSelections.dayOfWeek.selected
        )?.values;
      } else {
        itemValues = benchmarkData.values;
      }
    }

    if (itemValues && itemValues.length > 0 && benchmarkName) {
      return {
        colour: "#000000",
        data: itemValues,
        name: benchmarkName,
      };
    }
  }

  return undefined;
};

export const getSegmentsData = (
  reportletData: DayOfWeekResponseDto | TimeOfDayResponseDto,
  chartSelections: ChartSelections
) => {
  const data =
    "itemData" in reportletData
      ? reportletData.itemData
      : reportletData.timeOfDayData;
  const result: ChartDataSeries[] = [];
  let index = 0;
  for (const item of data) {
    if (
      item.nodeNumber === chartSelections.focalItem.selected &&
      item.segment !== "All"
    ) {
      if ("hoursData" in item) {
        const dayData = item.hoursData.find(
          (hourData) => hourData.day === chartSelections.dayOfWeek.selected
        );
        if (dayData) {
          result.push({
            colour: getColor(index),
            data: dayData.values,
            name: item.segment,
          } as ChartDataSeries);
          index++;
        }
      } else {
        result.push({
          colour: getColor(index),
          data: item.values,
          name: item.segment,
        } as ChartDataSeries);
        index++;
      }
    }
  }

  return result;
};

export const getDaysData = (
  reportletData: TimeOfDayResponseDto,
  chartSelections: ChartSelections
) => {
  const result: ChartDataSeries[] = [];
  for (const item of reportletData.timeOfDayData) {
    if (
      item.nodeNumber === chartSelections.focalItem.selected &&
      item.segment === chartSelections.segment.selected
    ) {
      let index = 0;
      for (const hourData of item.hoursData) {
        if (hourData.day !== "All") {
          result.push({
            colour: getColor(index),
            data: hourData.values,
            name: hourData.day,
          } as ChartDataSeries);
          index++;
        }
      }
    }
  }

  return result;
};
