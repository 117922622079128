import { type TableRowsRequestDto } from "../models/CustomerLoyaltyTableRowsRequestDto";
import { type TableRowsResponseDto } from "../models/CustomerLoyaltyTableRowsResponseDto";
import { customerLoyaltyApiSlice } from "./customer-loyalty-api-slice";

export const customerLoyaltyDataTableApiSlice =
  customerLoyaltyApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTableRows: builder.query<
        TableRowsResponseDto,
        { division: string; payload: TableRowsRequestDto }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetTableRows/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetTableRowsQuery } = customerLoyaltyDataTableApiSlice;
