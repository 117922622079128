import {
  type GainsAndLossOverviewItem,
  type GainsAndLossOverviewMetric,
} from "../models/GainsAndLossOverviewReportletDto";

// returns metrics sorted in the same order of the metrics in metricgroups
export const getMetrics = (
  measureData: GainsAndLossOverviewItem,
  metricGroups: string[]
) => {
  const metrics: GainsAndLossOverviewMetric[] = measureData.metrics.filter(
    (metric) => metricGroups.includes(metric.metricGroup)
  );
  return metrics.sort(
    (a, b) =>
      metricGroups.indexOf(a.metricGroup) - metricGroups.indexOf(b.metricGroup)
  );
};

export const filterData = (
  overviewDto: GainsAndLossOverviewItem[],
  measure: string,
  valueType: string
): GainsAndLossOverviewItem | undefined =>
  overviewDto.find(
    (item) =>
      item.measureName.toLowerCase() === measure.toLowerCase() &&
      item.valueType.toLowerCase() === valueType.toLowerCase()
  );
