import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";

export const DownloadIconForSidePanel = ({ viewBox }: { viewBox?: string }) => (
  <svg
    fill="none"
    height="24"
    viewBox={viewBox}
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 9V16H2V9H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V9H16ZM10 9.67L12.59 7.09L14 8.5L9 13.5L4 8.5L5.41 7.09L8 9.67V0H10V9.67Z” fill=“#3F69AE"
      fill={tokens.colour["text-primary"]}
    />
  </svg>
);
