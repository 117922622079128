import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { ListSelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getDropdownParameterState } from "../../parameters/dropdown/DropdownParameterState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelDropdownProps = {
  label?: string;
  parameterDto: ParameterDto;
};

export const SummaryPanelDropdown = ({
  label,
  parameterDto,
}: SummaryPanelDropdownProps) => {
  const selection = useAppSelector(
    getDropdownParameterState(parameterDto.id)
  ).selection;

  return (
    <ListSelectionSummary
      label={label ?? parameterDto.name}
      selections={selection ? [selection.label] : []}
    />
  );
};
