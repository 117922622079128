import { type SortDirection, type Header } from "@tanstack/react-table";
import classNames from "classnames";
import { useCallback, type PropsWithChildren } from "react";
import styles from "./VirtuosoTableSort.module.css";

export type ColumnSortProps<T> = {
  className?: string;
  header: Header<T, unknown>;
  isRowSpanColumn?: boolean;
};

export enum SortedType {
  asc = "asc",
  desc = "desc",
}

export const getAriaSortedString = (
  isSorted: SortDirection | false
): "ascending" | "descending" | "none" => {
  if (!isSorted) {
    return "none";
  }

  return isSorted === SortedType.asc ? "ascending" : "descending";
};

export const VirtuosoTableSort = <T,>({
  header,
  isRowSpanColumn = false,
  children,
  className,
}: PropsWithChildren<ColumnSortProps<T>>) => {
  const SortArrows = useCallback(
    () => (
      <>
        {header.column.getIsSorted() === SortedType.asc && (
          <span
            className={`${styles.sortIcon} ${styles.sortIconAscending}`}
            title="Sort in ascending order"
          >
            ascending
          </span>
        )}
        {header.column.getIsSorted() === SortedType.desc && (
          <span
            className={`${styles.sortIcon} ${styles.sortIconDescending}`}
            title="Sort in descending order"
          >
            descending
          </span>
        )}
      </>
    ),
    [header.column]
  );
  return header.column.getCanSort() ? (
    <button
      className={classNames(styles.columnSortBtn, className, {
        [styles.cursorPointer]: header.column.getCanSort(),
        [styles.selectNone]: header.column.getCanSort(),
        [styles.spanRow]: isRowSpanColumn,
      })}
      onClick={header.column.getToggleSortingHandler()}
      onKeyPress={header.column.getToggleSortingHandler()}
      onTouchEnd={header.column.getToggleSortingHandler()}
      tabIndex={0}
      type="button"
    >
      {children}
      <SortArrows />
    </button>
  ) : (
    <>
      {children}
      <SortArrows />
    </>
  );
};
