import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type IndexAgainstOption } from "components-ui/src/local-filters/indexedMetricFilter/IndexedMetricFilter";
import { type TableRow } from "../../common/models/TableRow";
import { getFocalItemPath } from "../../common/utils/data-table-utils";
import { type CustomerProfilingTableRow } from "../models/CustomerProfilingReportTableResponseDto";

const getLowestCommonIndexAgainstLevel = <T>(
  focalItems: HierarchySliceNodeDto[],
  tableRows: Array<TableRow<T>>,
  indexAgainstLevels: string[]
) => {
  const focalItemPaths = focalItems.map((item) =>
    getFocalItemPath(item, tableRows, []).reverse()
  );

  const minPathLength = Math.min(...focalItemPaths.map((path) => path.length));
  let lowestIndexAgainstLevel = "";
  for (let index = 0; index < minPathLength; index++) {
    const nodeNumbers = focalItemPaths.map((path) => path[index]);
    if (new Set(nodeNumbers).size > 1) break;

    const commonParentShortName =
      tableRows.find((row) => row.hierarchyItem.nodeNumber === nodeNumbers[0])
        ?.hierarchyItem.shortName ?? "";

    // set only if node short name is in the list of index options, i.e. ignore attribute levels
    if (indexAgainstLevels.includes(commonParentShortName)) {
      lowestIndexAgainstLevel = commonParentShortName;
    }
  }

  return lowestIndexAgainstLevel;
};

const getIndexAgainstLevel = <T>(
  focalItems: HierarchySliceNodeDto[],
  tableRows: Array<TableRow<T>>,
  indexAgainstLevels: string[]
) => {
  const commonParentShortName = getLowestCommonIndexAgainstLevel(
    focalItems,
    tableRows,
    indexAgainstLevels
  );

  const indexOfCommonParentLevel = indexAgainstLevels.indexOf(
    commonParentShortName
  );

  // if there is a focal item on the same level, we want the level above
  const indexToIndexAgainst = focalItems.some(
    (item) => item.shortName === commonParentShortName
  )
    ? indexOfCommonParentLevel - 1
    : indexOfCommonParentLevel;

  // if already root, then just show root level
  return indexAgainstLevels[indexToIndexAgainst > 0 ? indexToIndexAgainst : 0];
};

export const getFilterIndexAgainst =
  (
    focalItems: HierarchySliceNodeDto[],
    tableRows: CustomerProfilingTableRow[],
    indexAgainstLevels: string[]
  ) =>
  (option: IndexAgainstOption) => {
    if (focalItems.length === 0 || tableRows.length === 0) {
      return false;
    }

    const lowestIndexAgainstDepth = indexAgainstLevels.indexOf(
      getIndexAgainstLevel(focalItems, tableRows, indexAgainstLevels)
    );
    const optionHierarchyDepth = indexAgainstLevels.indexOf(option.shortName);

    return (
      optionHierarchyDepth >= 0 &&
      optionHierarchyDepth <= lowestIndexAgainstDepth
    );
  };
