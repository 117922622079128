import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type FolderDto,
  type CreateFolderDto,
  type GetFoldersDto,
  type MoveFolderDto,
  type MoveReportDto,
  type RenameFolderDto,
} from "../models";

export const foldersApi = createApi({
  baseQuery: getBaseQuery(`/api/report-parameters-web`),
  endpoints: (builder) => ({
    createFolder: builder.mutation<
      unknown,
      { divisionName: string; payload: CreateFolderDto }
    >({
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/folders/CreateFolder/${divisionName}`,
      }),
    }),
    renameFolder: builder.mutation<
      unknown,
      { divisionName: string; payload: RenameFolderDto }
    >({
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/folders/RenameFolder/${divisionName}`,
      }),
    }),
    deleteFolders: builder.mutation<
      unknown,
      { divisionName: string; payload: { FolderIds: string[] } }
    >({
      query: ({ divisionName, payload }) => ({
        method: "DELETE",
        url: `/folders/DeleteFolders/${divisionName}?${payload.FolderIds.map(
          (value) => "folderIds=" + encodeURIComponent(value)
        ).join("&")}`,
      }),
    }),
    getFolders: builder.mutation<
      FolderDto[],
      { divisionName: string; payload: GetFoldersDto }
    >({
      query: ({ divisionName, payload }) => ({
        method: "GET",
        url: `/folders/GetFolders/${divisionName}?ReportType=${payload.reportType}`,
      }),
    }),
    moveFolder: builder.mutation<
      unknown,
      { divisionName: string; payload: MoveFolderDto }
    >({
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/folders/MoveFolder/${divisionName}`,
      }),
    }),
    moveReport: builder.mutation<
      unknown,
      { divisionName: string; payload: MoveReportDto }
    >({
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/folders/MoveReport/${divisionName}`,
      }),
    }),
  }),

  reducerPath: "foldersApi",
  tagTypes: ["folders"],
});

export const {
  useCreateFolderMutation,
  useGetFoldersMutation,
  useMoveFolderMutation,
  useMoveReportMutation,
  useDeleteFoldersMutation,
  useRenameFolderMutation,
} = foldersApi;
