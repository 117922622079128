import { z } from "zod";
import { type NumberFormat } from "../enums/NumberFormat";
import { type ListSelectionDto } from "./list-selection-dto";
import { type SelectionDto } from "./selection-dto";

export type ParameterSelectionDto = {
  id: string;
  name: string;
  selections: SelectionDto[];
  selectionsCount?: number;
};

// have kept schema of selections as unknown type, to avoid having
// to create a schema for every SelectionDto, which would be difficult to maintain
export const ParameterSelectionDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  selections: z.array(z.unknown()),
});

export type BandSelectionDto = {
  maximum: number;
  minimum: number;
  ordinal: number;
};

export const isBandSelectionDto = (
  object: object
): object is BandSelectionDto =>
  Object.hasOwn(object, "ordinal") &&
  Object.hasOwn(object, "maximum") &&
  Object.hasOwn(object, "minimum");

export type NumberSelectionDto = {
  format: NumberFormat;
  number: number;
};

export const isNumberSelectionDto = (
  object: object
): object is NumberSelectionDto =>
  Object.hasOwn(object, "number") && Object.hasOwn(object, "format");

export type MeasureThresholdSelectionDto = {
  comparator: ListSelectionDto;
  measure: ListSelectionDto;
  threshold: NumberSelectionDto;
};

export const isMeasureThresholdSelection = (
  object: object
): object is MeasureThresholdSelectionDto =>
  Object.hasOwn(object, "comparator") &&
  Object.hasOwn(object, "measure") &&
  Object.hasOwn(object, "threshold");

export type GroupSegmentSelectionDto = {
  customerGroupId: string;
  segmentDisplayName: string;
  segmentKey: string;
  standardSegmentationKey?: string;
};

export type TextSelectionDto = {
  text: string;
};

export const isTextSelection = (object: object): object is TextSelectionDto =>
  Object.hasOwn(object, "text");
