import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { LocalParameters } from "../../../common/models/LocalParameterId";
import { type CustomerProfilingSidePanelSelections } from "../../services/customer-profiling-slice";

export const convertPanelOptionToString = (
  segmentation: PanelOption[]
): string[] => segmentation.map((option) => option.value.toString());

export const getLocalSelectionPayload = (
  localSelections: CustomerProfilingSidePanelSelections
) => [
  {
    id: LocalParameters.Channel,
    values: [localSelections.channel.value as string],
  },
  {
    id: LocalParameters.Promotion,
    values: [localSelections.promotion.value as string],
  },
  {
    id: LocalParameters.LocationHierarchy,
    values: [localSelections.location.nodeNumber.toString()],
  },
  {
    id: LocalParameters.Segmentation,
    values: convertPanelOptionToString(localSelections.segmentation),
  },
];
