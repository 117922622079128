import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type GainsAndLossSwitchingBreakdownExportRequestDto } from "../models/GainsAndLossSwitchingBreakdownExportRequestDto";
import { type GainsAndLossSwitchingBreakdownResponseDto } from "../models/GainsAndLossSwitchingBreakdownReportletDto";
import { type GainsAndLossSwitchingBreakdownRequestDto } from "../models/GainsAndLossSwitchingBreakdownRequestDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossSwitchingBreakdownSlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getGainsAndLossSwitchingBreakdown: builder.query<
        GainsAndLossSwitchingBreakdownResponseDto[],
        {
          division: string;
          payload: GainsAndLossSwitchingBreakdownRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetReportSwitchingBreakdownData/${division}`,
        }),
      }),
      getGainsAndLossSwitchingBreakdownExportData: builder.query<
        Blob,
        {
          division: string;
          payload: GainsAndLossSwitchingBreakdownExportRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportSwitchingBreakdownExportCsv/${division}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `gain and loss switching breakdown csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const {
  useLazyGetGainsAndLossSwitchingBreakdownQuery,
  useLazyGetGainsAndLossSwitchingBreakdownExportDataQuery,
} = gainsAndLossSwitchingBreakdownSlice;
