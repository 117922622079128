import {
  type Flags,
  type TransactionSource,
  type SharedUserDto,
  type SharingActivity,
  type SavedParametersDetailsDto,
  TrackingComponent,
} from "@quantium-enterprise/common-ui";
import { FeatureFlag } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Nav,
  NavButton,
  NavVariant,
  Spinner,
  SpinnerSize,
} from "@quantium-enterprise/qds-react";
import { type ButtonContent } from "components-ui/src/button-list/ButtonList";
import { ButtonList } from "components-ui/src/button-list/ButtonList";
import { type DeleteItems } from "components-ui/src/delete-dialog/DeleteDialog";
import { DeleteDialog } from "components-ui/src/delete-dialog/DeleteDialog";
import { transformLocationParameterSelection } from "components-ui/src/info-panel/InfoPanel";
import { InfoPanelActivity } from "components-ui/src/info-panel/info-panel-body/InfoPanelActivity";
import { InfoPanelBody } from "components-ui/src/info-panel/info-panel-body/InfoPanelBody";
import { RenameButton } from "components-ui/src/info-panel/info-panel-header/InfoPanelButtons";
import {
  InfoPanelHeaderLayout,
  InfoPanelLabel,
} from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import commonStyles from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader.module.css";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetParametersState } from "report-parameters-ui/src/states/report-wizard-slice";
import { DeleteButton } from "../my-reports-grid/MyReportsGridQuickActions";

const SavedAndScheduledInfoPanelHeader = ({
  savedParameters,
  onClose,
  deleteItem,
  renameItem,
}: {
  deleteItem: (savedParametersId: string) => Promise<unknown>;
  onClose: () => void;
  renameItem: (savedParametersId: string, newName: string) => Promise<unknown>;
  savedParameters?: SavedParametersDetailsDto;
}) => {
  // This existence of this ref is required to get the dialog to render.
  // Avoids a null ref exception in the modal API.
  const deleteButtonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name: divisionName } = useDivision();
  const [editingReportName, setEditingReportName] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const editableFieldState = {
    isEditing: editingReportName,
    toggleEditing: setEditingReportName,
  };
  const generateReportWizardPath = useCallback((): string => {
    if (savedParameters) {
      return `/${divisionName}/report-wizard/${savedParameters.reportType}/saved-selections/${savedParameters.id}`;
    }

    return "";
  }, [savedParameters, divisionName]);

  // Deletion
  const handleDeleteDialogExit = useCallback(() => {
    setShowDeleteDialog(false);
  }, [setShowDeleteDialog]);
  const handleDeleteReport = useCallback(() => {
    setShowDeleteDialog(false);
    onClose();
  }, [setShowDeleteDialog, onClose]);

  const handleRenameSavedSelection = useCallback(
    async (value: string) => {
      await renameItem(savedParameters?.id ?? "", value);
    },
    [renameItem, savedParameters?.id]
  );

  // Button panel
  const buttonPanel = useMemo(() => {
    const visibleButtons: ButtonContent[] = [
      { ...RenameButton, handleClick: () => setEditingReportName(true) },
    ];
    const availableButtons = [
      visibleButtons,
      [{ ...DeleteButton, handleClick: () => setShowDeleteDialog(true) }],
    ];

    return (
      <div className={commonStyles.infoPanelButtons}>
        <Button
          height={ButtonHeight.XSmall}
          onClick={() => {
            dispatch(resetParametersState());
            if (savedParameters) {
              navigate(generateReportWizardPath(), {
                state: { savedParametersConfig: savedParameters },
              });
            }
          }}
          text="Create report"
          variant={ButtonVariant.Primary}
        />
        <ButtonList buttons={availableButtons} />
        <DeleteDialog
          deleteItem={async (items: DeleteItems) =>
            await deleteItem((items.savedParametersIds ?? [])[0])
          }
          itemIds={{
            savedParametersIds: savedParameters?.id ? [savedParameters.id] : [],
          }}
          onClose={handleDeleteDialogExit}
          onDelete={handleDeleteReport}
          ref={deleteButtonRef}
          show={showDeleteDialog}
          trackingComponent={TrackingComponent.SavedSelections}
        />
      </div>
    );
  }, [
    savedParameters,
    handleDeleteDialogExit,
    handleDeleteReport,
    showDeleteDialog,
    dispatch,
    navigate,
    generateReportWizardPath,
    deleteItem,
  ]);
  return (
    <InfoPanelHeaderLayout
      buttonPanel={buttonPanel}
      editableFieldState={editableFieldState}
      handleRename={handleRenameSavedSelection}
      id={savedParameters?.id}
      title={savedParameters?.name}
    />
  );
};

export const SavedAndScheduledInfoPanelContent = ({
  savedParameters,
  savedParametersSharedUsers,
  isLoading,
  sharingActivities,
  flags,
  currentNavIndex,
  setCurrentNavIndex,
  onClose,
  deleteItem,
  renameItem,
}: {
  currentNavIndex: number;
  deleteItem: (savedParametersId: string) => Promise<unknown>;
  flags: Flags;
  isLoading: boolean;
  onClose: () => void;
  renameItem: (
    savedParametersId: string,
    newItemName: string
  ) => Promise<unknown>;
  savedParameters?: SavedParametersDetailsDto;
  savedParametersSharedUsers?: SharedUserDto[];
  setCurrentNavIndex: (currentNavIndex: number) => void;
  sharingActivities?: SharingActivity[];
}) => {
  if (isLoading) {
    return {
      body: <Spinner size={SpinnerSize.Large} />,
      header: <> </>,
      label: <> </>,
    };
  } else if (savedParameters) {
    const infoPanelTabs = [
      {
        content: (
          <InfoPanelBody
            parameterGroups={transformLocationParameterSelection(
              savedParameters.parameterGroupSelections
            )}
            transactionSource={
              savedParameters.dataSource as TransactionSource[]
            }
          />
        ),
        title: "Selections",
      },
    ];

    if (flags[FeatureFlag.SharingReports]) {
      const activityTab = {
        content: (
          <InfoPanelActivity
            resourceType="saved selection"
            sharedUsers={savedParametersSharedUsers}
            sharingActivities={sharingActivities}
          />
        ),
        title: "Activity",
      };
      infoPanelTabs.push(activityTab);
    }

    return {
      body: infoPanelTabs[currentNavIndex].content,
      header: (
        <>
          <SavedAndScheduledInfoPanelHeader
            deleteItem={deleteItem}
            onClose={onClose}
            renameItem={renameItem}
            savedParameters={savedParameters}
          />
          <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
            {infoPanelTabs.map((tab, index) => (
              <NavButton
                key={tab.title}
                onClick={() => setCurrentNavIndex(index)}
              >
                {tab.title}
              </NavButton>
            ))}
          </Nav>
        </>
      ),
      label: (
        <InfoPanelLabel
          reportType={
            savedParameters.reportTypeDisplayName || savedParameters.reportType
          }
        />
      ),
    };
  } else {
    return {
      body: <p>Failed to retrieve saved selections.</p>,
      header: <> </>,
      label: <> </>,
    };
  }
};
