import {
  MY_REPORTS_PATH,
  type RouteWithLabel,
} from "@quantium-enterprise/common-ui";
import { Suspense } from "react";
import { Provider } from "react-redux";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import MyReports from "./MyReports";
import { myReportsRoutes } from "./MyReportsNoRedux";
import { store } from "./store";

export default (): RouteWithLabel => ({
  children: myReportsRoutes,
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <MyReports />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "My reports",
  path: MY_REPORTS_PATH,
});
