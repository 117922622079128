import { type PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "./store";

export type ParameterState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is type safe when used with useFastReportingParameterState as the schema gets checked before use
  currentParameters: { [key: string]: { copyToNewTabs: boolean; value: any } };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is type safe when used with useFastReportingParameterState as the schema gets checked before use
  newTabParameters: { [key: string]: { copyToNewTabs: boolean; value: any } };
};

export const parametersReducerPath = "fastReportsParameters";

const initialState: ParameterState = {
  currentParameters: {},
  newTabParameters: {},
};

export const fastReportsSlice = createSlice({
  initialState,
  name: parametersReducerPath,
  reducers: {
    updateCurrentParameterValue: (
      state: ParameterState,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is type safe when used with useFastReportingParameterState as the schema gets checked before use
      action: PayloadAction<{ copyToNewTabs: boolean; key: string; value: any }>
    ) => {
      state.currentParameters[action.payload.key] = {
        copyToNewTabs: action.payload.copyToNewTabs,
        value: action.payload.value,
      };
    },
    newParameterValueSet: (state: ParameterState) => {
      state.newTabParameters = state.currentParameters;
    },
  },
});

export const selectNewTabParameters = createSelector(
  (state: RootState) => state.fastReportsParameters,
  (parameters: ParameterState) =>
    Object.fromEntries(
      Object.entries(parameters.newTabParameters)
        .filter(([, value]) => value.copyToNewTabs)
        .map(([key, value]) => [key, value.value])
    )
);

export const { updateCurrentParameterValue, newParameterValueSet } =
  fastReportsSlice.actions;
export default fastReportsSlice.reducer;
