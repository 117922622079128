/* eslint-disable typescript-sort-keys/string-enum */
import { TrackingProperties } from "./TrackingProperties";

export enum NavigationTrackingProperty {
  Source = "Source",
}

export enum NavigationTrackingPropertyValue {
  LogIn = "LogIn",
  Sidebar = "Sidebar",
  HomepageBanner = "HomepageBanner",
}

export class NavigationTrackingProperties extends TrackingProperties {
  public constructor(
    property: NavigationTrackingProperty,
    value: NavigationTrackingPropertyValue
  ) {
    super();

    this.Properties = {};
    this.Properties[property] = value;
  }
}
