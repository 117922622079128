import {
  type LocalHierarchyNodeSelection,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { type KeyDriverTreeLocalSelections } from "../services/key-driver-tree-slice";

export const getUserSelections = (
  localParameters: KeyDriverTreeLocalSelections
) => {
  const userSelections: LocalSelectedValueDto[] = [];

  for (const [parameterID, values] of Object.entries(localParameters)) {
    if (
      typeof values === "object" &&
      "value" in values &&
      values.value &&
      parameterID !== "Segment"
    ) {
      if (parameterID === "Segmentation") {
        userSelections.push({
          id: parameterID,
          values: [
            values.value as string,
            localParameters.Segment.value as string,
          ],
        });
      } else {
        userSelections.push({
          id: parameterID,
          values: [values.value as string],
        });
      }
    } else if (parameterID === "LocationHierarchy") {
      if (values) {
        const castValue = values as LocalHierarchyNodeSelection;
        userSelections.push({
          id: parameterID,
          values: [castValue.nodeNumber.toString()],
        });
      }
    } else if (Array.isArray(values) && values.length > 0) {
      userSelections.push({
        id: parameterID,
        values: [...values],
      });
    }
  }

  return userSelections;
};

export default getUserSelections;
