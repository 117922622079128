import { type HierarchyValue } from "@quantium-enterprise/common-ui";
import { type RowSelectionState } from "@tanstack/react-table";
import { type PricingLaddersTableRow } from "../models/PricingLaddersDataTableResponseDto";

export const updateHeaderCheckbox = (
  focalItems: HierarchyValue[],
  tableRows: PricingLaddersTableRow[],
  rowSelection: RowSelectionState
) => {
  const allRowsSelected = tableRows.every(
    (item) => rowSelection[item.hierarchyItem.itemCode]
  );
  const anyRowsSelected = tableRows.some(
    (item) => rowSelection[item.hierarchyItem.itemCode]
  );

  if (
    !focalItems.length ||
    (!anyRowsSelected && !allRowsSelected) ||
    !tableRows.length
  ) {
    return [false, false];
  } else if (focalItems.length === tableRows.length || allRowsSelected) {
    return [true, false];
  } else {
    return [false, true];
  }
};
