import {
  type HierarchyGroupEvaluationType,
  type HierarchyType,
} from "@quantium-enterprise/common-ui";
import { FolderIcon } from "components-ui/src/assets/icons/FolderIcon";
import { ExpandChevron, HierarchyGroupIcon } from "components-ui/src/icons";
import { folderPadding } from "components-ui/src/tables/common/utils";
import styles from "./GroupTitleCell.module.css";

export type GroupTitleCellProps = {
  depth: number;
  evaluationType?: HierarchyGroupEvaluationType;
  folderColour?: string;
  hasChildren: boolean;
  hierarchyType?: HierarchyType;
  isExpanded: boolean;
  isFolder: boolean;
  text: string;
  toggleFolder: () => void;
};

export const GroupTitleCell = ({
  depth,
  evaluationType,
  folderColour,
  hasChildren,
  hierarchyType,
  isExpanded,
  isFolder,
  text,
  toggleFolder,
}: GroupTitleCellProps) => (
  <span
    className={styles.row}
    style={{ paddingLeft: folderPadding(isFolder, depth) }}
  >
    {isFolder ? (
      <>
        {hasChildren ? (
          <ExpandChevron
            isCompact={false}
            isExpanded={isExpanded}
            isLoading={false}
            onClick={() => toggleFolder()}
          />
        ) : (
          <span className={styles.expandChevronSpacer} />
        )}
        <FolderIcon
          folderColour={
            folderColour &&
            folderColour.toLowerCase() !== "white" &&
            folderColour.toLowerCase() !== "#ffffff"
              ? folderColour
              : undefined
          }
        />
        <span className={styles.groupTitleText}>{text}</span>
      </>
    ) : (
      <>
        <HierarchyGroupIcon
          className={styles.icon}
          evaluationType={evaluationType}
          hierarchyType={hierarchyType}
        />
        <span className={styles.groupTitleText}>{text}</span>
      </>
    )}
  </span>
);
