import {
  FeatureFlag,
  ReportType,
  useGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { type RootState } from "../store";
import { ProductSubstitutabilitySidePanel } from "./components/ProductSubstitutabilitySidePanel";
import ProductSubstitutabilityTopDrawer from "./components/ProductSubstitutabilityTopDrawer";
import { ProductUniquenessReportlet } from "./components/ProductUniquenessReportlet";
import { ProductSubstitutabilityReportlet } from "./components/SubstitutabilityReportlet/ProductSubstitutabilityReportlet";
import { useGetLocalParametersQuery } from "./services/product-substitutability-local-parameters-api-slice";
import {
  onReportError,
  onReportOpen,
  onReportSuccess,
  reset,
} from "./services/product-substitutability-slice";

export const ProductSubstitutabilityReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const featureFlags = useFlags();

  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTabsEnabled]);

  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: !activeDivisionName || !id }
    );

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.productSubstitutability.reportName,
  }));

  const { data, isError, isSuccess } = useGetLocalParametersQuery(
    { divisionName: activeDivisionName, reportId: id ?? "" },
    { skip: !activeDivisionName || !id }
  );

  useEffect(() => {
    if (isSuccess && id) {
      dispatch(onReportSuccess({ data, reportId: id }));
    } else if (isError) {
      dispatch(onReportError());
    }
  }, [data, dispatch, id, isError, isSuccess]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <ProductSubstitutabilityTopDrawer
            renameReport={handleRenameReport}
            reportName={reportName}
            reportType={ReportType.ProductSubstitutability}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <ProductSubstitutabilitySidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <ProductUniquenessReportlet infoPanelSummary={infoPanelSummary} />
          <ProductSubstitutabilityReportlet
            infoPanelSummary={infoPanelSummary}
          />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <ProductSubstitutabilityReport />,
  path: "product-substitutability/:id",
};

export default ProductSubstitutabilityReport;
