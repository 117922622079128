/* eslint-disable typescript-sort-keys/string-enum */
export enum TrackingEvent {
  Added = "Added",
  Created = "Created",
  Collapsed = "Collapsed",
  Deleted = "Deleted",
  Expanded = "Expanded",
  GroupsLimitReached = "10K Limit Reached",
  Navigated = "Navigated",
  Opened = "Opened",
  Organised = "Organised",
  Parameters = "Parameters",
  Reset = "Reset",
  Searched = "Searched",
  Selected = "Selected",
  Submitted = "Submitted",
  Unselected = "Unselected",
}
