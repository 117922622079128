import {
  ddLog,
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { LocalParameters } from "../../../common/models/LocalParameterId";
import { getFocalItemSummaryWithParents } from "../../../common/utils/export-parameter-summary-utils";
import { type RootState } from "../../../store";
import { CustomerProfilingFeatureFlags } from "../../constants/customer-profiling-feature-flags";
import { type CustomerProfilingSegmentsOverTimeRequestDto } from "../../models/CustomerProfilingSegmentsOverTimeRequestDto";
import { useLazyGetCustomerProfilingSegmentsOverTimeReportletQuery } from "../../services/customer-profiling-segments-over-time-api-slice";
import {
  onCustomerProfilingSegmentsOverTimeRequest,
  onCustomerProfilingSegmentsOverTimeResponseReceived,
  onEmptySegmentationOrFocalItemsOverTime,
  selectChannel,
  selectFocalItemParents,
  selectFocalItems,
  selectIndexedAgainst,
  selectLocation,
  selectMetric,
  selectPromotion,
  selectReportId,
  selectSegmentation,
  selectShowSegmentsOverTimeChartDataLabels,
  selectTime,
  selectTimePeriodLength,
  toggleSegmentsOverTimeChartDataLabels,
} from "../../services/customer-profiling-slice";
import { convertPanelOptionToString } from "../TopDrawerProductTable/utils";
import { CustomerProfilingSegmentsOverTimeReportletFilters } from "./CustomerProfilingSegmentsOverTimeReportletFilters";
import styles from "./CustomerProfilingSegmentsOverTimeReportletFilters.module.css";
import { CustomerProfilingTrendedPerformanceChart } from "./CustomerProfilingTrendedPerformanceChart";
import { csvTransformation } from "./csvTransformation";

export const CustomerProfilingSegmentsOverTimeReportlet = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const { id } = useParams();
  const { name: divisionName, locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);
  const dispatch = useDispatch();

  const channel = useSelector(selectChannel);
  const indexedAgainst = useSelector(selectIndexedAgainst);
  const location = useSelector(selectLocation);
  const promotion = useSelector(selectPromotion);
  const metric = useSelector(selectMetric);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const reportId = useSelector(selectReportId);
  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const showSegmentsOverTimeChartDataLabels = useSelector(
    selectShowSegmentsOverTimeChartDataLabels
  );

  const {
    localParametersInitialised,
    chartSeriesActiveButtonIndex,
    segmentationActiveButtonIndex,
    focalItemFilter,
    segmentsFilter,
    reportData,
  } = useSelector((state: RootState) => ({
    localParametersInitialised:
      state.customerProfiling.localParametersInitialised,
    chartSeriesActiveButtonIndex:
      state.customerProfiling.segmentsOverTimeFilters
        .chartSeriesActiveButtonIndex,
    segmentationActiveButtonIndex:
      state.customerProfiling.segmentsOverTimeFilters
        .segmentationActiveButtonIndex,
    focalItemFilter:
      state.customerProfiling.segmentsOverTimeFilters.focalItemFilter,
    segmentsFilter:
      state.customerProfiling.segmentsOverTimeFilters.segmentsFilter,
    reportData: state.customerProfiling.segmentsOverTimeData,
  }));

  const isCustomerProfilingSegmentsOverTimeLoading = useSelector(
    (state: RootState) =>
      state.customerProfiling.isCustomerProfilingSegmentsOverTimeLoading
  );

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CustomerProfilingFeatureFlags.ReportExport] ?? false;

  const [getReportletData, { data, isSuccess }] =
    useLazyGetCustomerProfilingSegmentsOverTimeReportletQuery();

  const fetchData = useCallback(
    async (
      division: string,
      payload: CustomerProfilingSegmentsOverTimeRequestDto
    ) => {
      await getReportletData({
        division,
        payload,
      })
        .unwrap()
        .then((response) => {
          dispatch(
            onCustomerProfilingSegmentsOverTimeResponseReceived(response)
          );
        });
    },
    [dispatch, getReportletData]
  );

  useEffect(() => {
    if (
      divisionName &&
      id &&
      id === reportId &&
      focalItems.length > 0 &&
      segmentation.length > 0 &&
      localParametersInitialised
    ) {
      dispatch(onCustomerProfilingSegmentsOverTimeRequest());

      const localSelectedValues = [
        {
          id: LocalParameters.Segmentation,
          values: convertPanelOptionToString(segmentation),
        },
        {
          id: LocalParameters.Channel,
          values: [channel.value as string],
        },
        {
          id: LocalParameters.Promotion,
          values: [promotion.value as string],
        },
        {
          id: LocalParameters.LocationHierarchy,
          values: [location.nodeNumber.toString()],
        },
      ];

      const payload: CustomerProfilingSegmentsOverTimeRequestDto = {
        focalItems,
        localSelectedValues,
        reportId: id,
      };

      fetchData(divisionName, payload).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    } else {
      dispatch(onEmptySegmentationOrFocalItemsOverTime());
    }
  }, [
    id,
    divisionName,
    focalItems,
    fetchData,
    localParametersInitialised,
    segmentation,
    channel,
    promotion,
    location,
    reportId,
    dispatch,
  ]);

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        chartSeriesActiveButtonIndex,
        segmentationActiveButtonIndex,
        focalItemFilter,
        segmentsFilter,
        reportData,
        metric.value as string,
        convertPanelOptionToString(segmentation),
        currencySymbol
      ),
    [
      chartSeriesActiveButtonIndex,
      segmentationActiveButtonIndex,
      focalItemFilter,
      segmentsFilter,
      reportData,
      metric,
      segmentation,
      currencySymbol,
    ]
  );
  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Segments_Over_Time_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );
  const parameterSummary = useMemo(
    () =>
      [
        {
          name: "Focal item(s)",
          value:
            chartSeriesActiveButtonIndex === 0
              ? `(${hierarchyLevelDisplayLabel(
                  focalItemFilter.activeOption?.shortName ?? ""
                )}) ${focalItemFilter.activeOption?.name ?? ""}`
              : getFocalItemSummaryWithParents(focalItems, focalItemParents),
        },
        { name: "Time", value: time },
        { name: "Metric", value: metric.label },
        { name: "Index against", value: indexedAgainst.label },
        { name: "Channel", value: channel.label },
        { name: "Promotion", value: promotion.label },
        {
          name: "Segmentation",
          value: segmentation[segmentationActiveButtonIndex]?.label ?? "",
        },
        {
          name: "Customer segment",
          value:
            chartSeriesActiveButtonIndex === 1
              ? segmentsFilter.activeOption?.segment ?? ""
              : "All",
        },
        {
          name: "Location",
          value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
            location.name
          }`,
        },
      ].filter(
        (parameter) => !(parameter.name === "Index against" && !parameter.value)
      ),
    [
      chartSeriesActiveButtonIndex,
      focalItemFilter.activeOption?.shortName,
      focalItemFilter.activeOption?.name,
      focalItems,
      focalItemParents,
      time,
      metric.label,
      indexedAgainst.label,
      channel.label,
      promotion.label,
      segmentation,
      segmentationActiveButtonIndex,
      segmentsFilter.activeOption?.segment,
      location.shortName,
      location.name,
    ]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showSegmentsOverTimeChartDataLabels,
      value: "",
    },
  ];

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();
  const reportName = useSelector(
    (state: RootState) => state.customerProfiling.reportName
  );

  return (
    <ReportletAccordion
      subtitle="See a breakdown of how each segment is contributing to your selected measure over a period of time."
      title="Segments over time"
    >
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={isCustomerProfilingSegmentsOverTimeLoading}
          noData={
            (isSuccess && (!data || data.length === 0)) ||
            focalItems.length === 0 ||
            segmentation.length === 0
          }
        >
          <div className={styles.chartOptionsContainer}>
            <CustomerProfilingSegmentsOverTimeReportletFilters />
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.CustomerProfiling} />
                      ),
                      chartTitle: `Segments over time - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
              toggleDataLabels={() => {
                dispatch(toggleSegmentsOverTimeChartDataLabels());
              }}
            />
          </div>
          <ChartFooterWrapper
            height="400px"
            parameters={parameterSummary}
            ref={chartContainerRef}
          >
            <CustomerProfilingTrendedPerformanceChart
              onOptionsChanged={setCurrentOptions}
            />
          </ChartFooterWrapper>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};

export default CustomerProfilingSegmentsOverTimeReportlet;
