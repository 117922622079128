import {
  ddLog,
  type HierarchyType,
  type NodeExpansionRequestDto,
  type HierarchyItem,
  useLazyExpandQuery,
  LocationHierarchy,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  type RowSelectionState,
  type Updater,
  type Row,
  type HeaderContext,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHierarchyItems,
  selectHierarchyExpandedRows,
  selectHierarchySelectedRows,
  setHierarchyNodeExpanding,
  onHierarchyChildNodesReceived,
  onHierarchyNodeSelection,
  hierarchyNodeExpanded,
} from "../../states/group-hierarchy-source-slice";
import { type RootState } from "../../store";
import styles from "./GroupHierarchyTable.module.css";
import { GroupHierarchyTableHeader } from "./GroupHierarchyTableHeader";

const PAGE_SIZE = 500;

export type NestedHierarchyItem = HierarchyItem & {
  isExpanding?: boolean;
  isMoreRow?: boolean;
  loadMoreParentRow?: NestedHierarchyItem;
  parentRow?: NestedHierarchyItem;
  subRows?: NestedHierarchyItem[];
};

export type GroupHierarchyTableProps = {
  hierarchyType: HierarchyType;
  isSuccess: boolean;
  leafNodeShortName: string;
};

export const GroupHierarchyTable = ({
  isSuccess,
  leafNodeShortName,
  hierarchyType,
}: GroupHierarchyTableProps) => {
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();

  const [rowsPagination, setRowsPagination] = useState<Record<string, number>>(
    {}
  );
  const items = useSelector((state: RootState) => selectHierarchyItems(state));
  const selectedRows = useSelector((state: RootState) =>
    selectHierarchySelectedRows(state)
  );

  const expandedRows = useSelector((state: RootState) =>
    selectHierarchyExpandedRows(state)
  );

  const getNodeId = useMemo(
    () => (shortName: string, code: string) => `${shortName}.${code}`,
    []
  );

  const createRowId = useCallback(
    (row: NestedHierarchyItem) =>
      row.loadMoreParentRow?.parent
        ? `${getNodeId(
            row.loadMoreParentRow.parent.shortName,
            row.loadMoreParentRow.parent.code
          )}-load-more`
        : getNodeId(row.shortName, row.code),
    [getNodeId]
  );

  const updatePaginationState = (shortName: string, code: string) => {
    setRowsPagination((pageState) => {
      const rowId = `${shortName}.${code}`;
      const newPageState = { ...pageState };
      newPageState[rowId] = newPageState[rowId] ? newPageState[rowId] + 1 : 1;

      return newPageState;
    });
  };

  const [
    triggerExpandQuery,
    { data: childrenNodesData, isSuccess: isChildrenNodesQuerySuccess },
  ] = useLazyExpandQuery();

  const fetchChildrenNodesData = useCallback(
    async (payload: NodeExpansionRequestDto) => {
      await triggerExpandQuery({
        division: activeDivisionName,
        hierarchyType,
        payload,
      });
    },
    [activeDivisionName, hierarchyType, triggerExpandQuery]
  );

  useEffect(() => {
    if (isSuccess && items.length > 0 && items[0].subRows === undefined) {
      dispatch(
        setHierarchyNodeExpanding({
          nodeCode: items[0].code,
          nodeShortName: items[0].shortName,
        })
      );

      fetchChildrenNodesData({
        page: 0,
        pageSize: PAGE_SIZE,
        parent: {
          code: items[0].code,
          shortName: items[0].shortName,
        },
      }).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [isSuccess, items, fetchChildrenNodesData, dispatch]);

  useEffect(() => {
    if (
      isChildrenNodesQuerySuccess &&
      childrenNodesData &&
      childrenNodesData.count > 0 &&
      leafNodeShortName !== ""
    ) {
      dispatch(
        onHierarchyChildNodesReceived({
          childNodes: childrenNodesData,
          leafNodeShortName,
        })
      );

      const parentNodeShortName = childrenNodesData.results[0].parent.shortName;
      const parentNodeCode = childrenNodesData.results[0].parent.code;

      updatePaginationState(parentNodeShortName, parentNodeCode);

      dispatch(
        setHierarchyNodeExpanding({
          nodeCode: parentNodeCode,
          nodeShortName: parentNodeShortName,
        })
      );
    }
  }, [
    isChildrenNodesQuerySuccess,
    childrenNodesData,
    leafNodeShortName,
    dispatch,
  ]);

  const onToggleExpansionHandler = useCallback(
    (row: Row<NestedHierarchyItem>) => {
      row.toggleExpanded();

      const expandedRowIndex = expandedRows.findIndex(
        (expandedRow) =>
          expandedRow.code === row.original.code &&
          expandedRow.shortName === row.original.shortName
      );

      const newExpandedRows: HierarchyItem[] =
        expandedRowIndex === -1
          ? [...expandedRows, row.original]
          : [
              ...expandedRows.filter(
                (expandedRow) =>
                  getNodeId(expandedRow.shortName, expandedRow.code) !==
                  getNodeId(row.original.shortName, row.original.code)
              ),
            ];

      dispatch(
        hierarchyNodeExpanded({
          expandedRows: newExpandedRows,
        })
      );

      (async () => {
        if (row.subRows.length === 0) {
          dispatch(
            setHierarchyNodeExpanding({
              nodeCode: row.original.code,
              nodeShortName: row.original.shortName,
            })
          );

          await fetchChildrenNodesData({
            page: 0,
            pageSize: PAGE_SIZE,
            parent: {
              code: row.original.code,
              shortName: row.original.shortName,
            },
          });
        }
      })();
    },
    [dispatch, expandedRows, fetchChildrenNodesData, getNodeId]
  );

  const onToggleSelectionHandler = useCallback(
    (row: Row<NestedHierarchyItem>) => {
      row.getToggleSelectedHandler();

      // row.getIsSelected provides the opposite state for checkbox
      const isChecked = !row.getIsSelected();

      // Filter out the current row add it back in if checked later
      let newSelectedRows: HierarchyItem[] = selectedRows.filter(
        (selectedRow) =>
          getNodeId(selectedRow.shortName, selectedRow.code) !==
          getNodeId(row.original.shortName, row.original.code)
      );

      if (isChecked) {
        newSelectedRows = [...newSelectedRows, row.original];
      }

      dispatch(onHierarchyNodeSelection(newSelectedRows));
    },
    [dispatch, selectedRows, getNodeId]
  );

  const onMoreClickedHandler = async (row: Row<NestedHierarchyItem>) => {
    if (row.original.loadMoreParentRow?.parent) {
      const parentNodeCode = row.original.loadMoreParentRow.parent.code;
      const parentNodeShortName =
        row.original.loadMoreParentRow.parent.shortName;
      const nextPage =
        rowsPagination[getNodeId(parentNodeShortName, parentNodeCode)];

      await fetchChildrenNodesData({
        page: nextPage,
        pageSize: PAGE_SIZE,
        parent: {
          code: parentNodeCode,
          shortName: parentNodeShortName,
        },
      });
    }
  };

  const header = useCallback(
    ({ table }: HeaderContext<NestedHierarchyItem, unknown>) => (
      <GroupHierarchyTableHeader
        handleToggleAllRowsSelected={table.getToggleAllRowsSelectedHandler()}
        isChecked={table.getIsAllRowsSelected()}
        showCheckbox={false}
        title={hierarchyType}
      />
    ),
    [hierarchyType]
  );

  const cell = useCallback(
    ({ row, getValue }: CellContext<NestedHierarchyItem, unknown>) => {
      // prepend code for stores only
      const displayValue =
        row.original.shortName === LocationHierarchy.Store
          ? `${row.original.code} - ${String(getValue())}`
          : String(getValue());

      return (
        <ExpandableNameCell
          canExpand={row.subRows.length >= 0 && !row.original.isLeaf}
          depth={row.depth}
          handleToggleExpanded={() => onToggleExpansionHandler(row)}
          handleToggleSelected={() => onToggleSelectionHandler(row)}
          isCompact
          isExpanded={row.subRows.length > 0 && row.getIsExpanded()}
          isExpanding={row.original.isExpanding}
          isSelected={row.getIsSelected()}
          name={row.original.name}
          shortName={row.original.shortName}
          type={row.original.type}
          value={displayValue}
        />
      );
    },
    [onToggleExpansionHandler, onToggleSelectionHandler]
  );

  const columns = useMemo<Array<ColumnDef<NestedHierarchyItem>>>(
    () => [
      {
        accessorKey: "name",
        cell,
        header,
      },
    ],
    [cell, header]
  );

  const handleRowSelectionsChange = (
    newRowSelections: Updater<RowSelectionState>
  ) => {
    const selectionIds = Object.keys(newRowSelections);

    const newSelectedRows = items.filter((item) =>
      selectionIds.includes(item.code)
    );

    dispatch(onHierarchyNodeSelection(newSelectedRows));
  };

  const rowSelectionState = useMemo(() => {
    const newRowSelectionState: Record<string, boolean> = {};

    for (const selectedRow of selectedRows) {
      newRowSelectionState[getNodeId(selectedRow.shortName, selectedRow.code)] =
        true;
    }

    return newRowSelectionState;
  }, [selectedRows, getNodeId]);

  const rowExpansionState = useMemo(() => {
    const newRowExpansionState: Record<string, boolean> = {};

    for (const expandedRow of expandedRows) {
      newRowExpansionState[getNodeId(expandedRow.shortName, expandedRow.code)] =
        true;
    }

    return newRowExpansionState;
  }, [expandedRows, getNodeId]);

  return (
    <div className={styles.groupHierarchy}>
      <ReportHierarchyTableWrapper isSuccess={isSuccess}>
        <ReportHierarchyTable
          // FIXME: should actually be optional and should not allow resizing for single column
          // https://jira.quantium.com.au/browse/CO3-1855
          columnResizeMode="onChange"
          columns={columns}
          compactRows
          data={items}
          depthPadding={50}
          disableSorting
          getRowId={(row: NestedHierarchyItem) => createRowId(row)}
          getSubRows={(row) => row.subRows}
          // FIXME: should actually be optional and should not be passed in if not needed
          // https://jira.quantium.com.au/browse/CO3-1856
          handleMoreClicked={async (row: Row<NestedHierarchyItem>) =>
            await onMoreClickedHandler(row)
          }
          moreText="Load More..."
          rowExpandedState={rowExpansionState}
          rowSelectionState={rowSelectionState}
          setRowSelection={handleRowSelectionsChange}
        />
      </ReportHierarchyTableWrapper>
    </div>
  );
};
