import { type PropsWithChildren, type RefObject } from "react";
import { forwardRef, memo } from "react";
import { PipeSeparatedSummary } from "../../pipe-separated-summary/PipeSeparatedSummary";
import styles from "./ChartFooterWrapper.module.scss";

export type ChartFooterWrapperProps = {
  height: string;
  overflow?: string;
  parameters: Array<{
    name: string;
    value: string;
  }>;
};

const referenceToForward = forwardRef(
  (
    {
      parameters,
      overflow,
      height,
      children,
    }: PropsWithChildren<ChartFooterWrapperProps>,
    ref
  ) => (
    <div className={styles.chartWrapper} style={{ height }}>
      <div
        className={styles.chartExportContainer}
        ref={ref as RefObject<HTMLDivElement>}
      >
        <div style={{ height, overflow }}>{children}</div>
        <div className={styles.chartFooterContainer}>
          <PipeSeparatedSummary parameters={parameters} />
        </div>
      </div>
    </div>
  )
);

export const ChartFooterWrapper = memo(referenceToForward);
