import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import {
  type ChartDataSeries,
  BasicColumnAndLineChart,
} from "../column-and-line-chart/BasicColumnAndLineChart";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import { CompareMetricsColumnChart } from "./CompareMetricsColumnChart";

export type CompareMetricsChartProps = {
  readonly data: ChartDataSeries[];
  readonly dataFormatter: FormatterFunction;
  readonly isStackedColumns: boolean;
  readonly onOptionsChanged?: (options: HighchartsReactProps) => void;
  readonly showChartDataLabels?: boolean;
  readonly threshold: number | undefined;
  readonly tooltipSentiment?: boolean;
  readonly xAxisCategories: string[];
  readonly yAxisLabel: string;
};

export const CompareMetricsChart = ({
  data,
  xAxisCategories,
  dataFormatter,
  isStackedColumns,
  onOptionsChanged,
  yAxisLabel,
  threshold,
  tooltipSentiment = true,
  showChartDataLabels,
}: CompareMetricsChartProps) => {
  if (isStackedColumns) {
    return (
      <BasicColumnAndLineChart
        categories={xAxisCategories}
        chartData={data}
        chartDataFormatter={dataFormatter}
        height="444px"
        onOptionsChanged={onOptionsChanged}
        showChartDataLabels={showChartDataLabels}
        tooltipLabel={yAxisLabel}
        yAxisLabel={yAxisLabel}
      />
    );
  }

  return (
    <CompareMetricsColumnChart
      categories={xAxisCategories}
      data={data}
      dataFormatter={dataFormatter}
      height="444px"
      onOptionsChanged={onOptionsChanged}
      showChartDataLabels={showChartDataLabels}
      threshold={threshold}
      tooltipSentiment={tooltipSentiment}
      yAxisLabel={yAxisLabel}
    />
  );
};
