import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { keyDriversOverTimeApiSlice } from "./key-drivers-over-time-api-slice";
import { onLocalParametersReceived } from "./key-drivers-over-time-slice";

export type LocalParametersResponse = {
  defaultFocalItem?: HierarchySliceNodeDto;
  localParameters: SidePanelParameter[];
};

export const keyDriversOverTimeLocalParametersApiSlice =
  keyDriversOverTimeApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        LocalParametersResponse,
        { divisionName: string; reportId: string }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onLocalParametersReceived(data));
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error retrieving local parameters");
          }
        },
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetLocalParametersQuery } =
  keyDriversOverTimeLocalParametersApiSlice;
