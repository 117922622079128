import type Highcharts from "highcharts";
import { getGainsAndLossChartColour } from "../../models/GainsAndLossChartColours";
import { MetricIdTypes } from "../../models/GainsAndLossReportValues";
import { type UniverseSummaryMeasure } from "../../models/GainsAndLossUniverseSummaryResponseDto";

export const getUniverseSummaryChartData = (
  measures: UniverseSummaryMeasure[]
): Highcharts.SeriesColumnOptions[] =>
  measures.map((measure) => ({
    color: getGainsAndLossChartColour[measure.name],
    data:
      measure.name === MetricIdTypes.Loss
        ? measure.values.map((x) => (x > 0 ? -x : x))
        : measure.values,
    name: measure.name,
    type: "column",
  }));
