import { type ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { BasicTable } from "../basic-table/BasicTable";
import styles from "./ToggleSelectTable.module.css";
import ToggleSelectTableCell from "./ToggleSelectTableCell";

export type ToggleSelectTableOption = {
  label: string;
  value: string;
};

export type ToggleSelectTableProps = {
  onToggle: (value: string) => void;
  options: ToggleSelectTableOption[];
  selectedValues: string[];
  title: string;
};

const createColumnDefs = (
  selectedValues: string[],
  onToggle: (value: string) => void,
  title: string
): Array<ColumnDef<ToggleSelectTableOption>> => [
  {
    accessorFn: (row) => row.label,
    cell: (info) => (
      <ToggleSelectTableCell
        isSelected={selectedValues.includes(info.row.original.value)}
        label={info.row.original.label}
        onSelect={(value: string) => onToggle(value)}
        value={info.row.original.value}
      />
    ),
    header: () => <span className={styles.tableHeaderCell}>{title}</span>,
    id: `toggle-select-table-column-${title}`,
    enableSorting: false,
    enableResizing: false,
  },
];

/**
 * This is a single column table with toggles for each row.
 * Note toggle selection is handled outside of this component and passed in
 */
export const ToggleSelectTable = ({
  options,
  selectedValues,
  title,
  onToggle,
}: ToggleSelectTableProps) => {
  const tableColumnDefs = useMemo(
    () => createColumnDefs(selectedValues, onToggle, title),
    [onToggle, selectedValues, title]
  );

  const table = <BasicTable columns={tableColumnDefs} data={options} />;

  return <div className={styles.tableContainer}>{table}</div>;
};

export default ToggleSelectTable;
