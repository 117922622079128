import {
  type NestedRowDto,
  type FolderOrReportDto,
  type NestedFolderDto,
  type GeneratedReportDto,
} from "@quantium-enterprise/common-ui";

export const convertToFolderOrReportDto = (
  nestedFolder: NestedFolderDto,
  depth = 0,
  parentId: string | null = null
): FolderOrReportDto[] => {
  let result: FolderOrReportDto[] = [];

  const folders = nestedFolder.folders ?? [];
  const reports = nestedFolder.reports;

  if (nestedFolder.id) {
    const folderDto: FolderOrReportDto = {
      depth,
      hasChildren: folders.length > 0 || reports.length > 0,
      isFolder: true,
      parentId: parentId ?? "",
      color: nestedFolder.color,
      creationDateUTC: nestedFolder.creationDateUTC ?? "",
      id: nestedFolder.id,
      name: nestedFolder.name ?? "",
    };

    result.push(folderDto);
  }

  for (const folder of folders) {
    result = result.concat(
      convertToFolderOrReportDto(folder, depth + 1, nestedFolder.id)
    );
  }

  result = result.concat(
    reports.map(
      (report) =>
        ({
          ...report,
          depth: depth + 1,
          hasChildren: false,
          isFolder: false,
          parentId: report.folderId,
        } as GeneratedReportDto & NestedRowDto)
    )
  );

  return result;
};

export const getUniqueSharedWithUserIds = (
  folder: NestedFolderDto
): string[] => {
  let userIds = new Set<string>();

  for (const report of folder.reports) {
    for (const userId of report.sharedWithUserIds) {
      userIds.add(userId);
    }
  }

  if (folder.folders)
    for (const subFolder of folder.folders) {
      const subfolderUserIds = getUniqueSharedWithUserIds(subFolder);
      userIds = new Set([...userIds, ...subfolderUserIds]);
    }

  return Array.from(userIds);
};

export const getChildIds = (
  folderId: string,
  foldersRecord: Record<string, FolderOrReportDto>
) => {
  const childFolderIds: string[] = [];

  // Helper function to recursively find child folder IDs
  const getChildIdsRecursive = (currentFolderId: string) => {
    for (const [id, folder] of Object.entries(foldersRecord)) {
      if (folder.parentId === currentFolderId && folder.isFolder) {
        childFolderIds.push(id);
        getChildIdsRecursive(id);
      }
    }
  };

  getChildIdsRecursive(folderId);
  return childFolderIds;
};
