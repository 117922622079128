import { Accordion } from "components-ui/src/accordion/Accordion";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import styles from "./AggregateRankReportlet.module.css";
import AggregateRankTableQueryWrapper from "./aggregate-rank-table/AggregateRankTableQueryWrapper";

export const AggregateRankReportlet = () => (
  <div className={styles.reportlet}>
    <Accordion
      subtitle="Rank the performance of items in your report using selected metrics."
      title="Aggregate rank"
    >
      <div className={styles.reportletContent}>
        <ErrorBoundary>
          <AggregateRankTableQueryWrapper />
        </ErrorBoundary>
      </div>
    </Accordion>
  </div>
);
export default AggregateRankReportlet;
