type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};
export const StaticProductGroupIcon = ({
  className,
  coloured = true,
  text,
}: Props) => {
  const boxColour = coloured
    ? "var(--marker-boxer-colour)"
    : "var(--icon-colour-two-disabled)";
  const listColour = coloured
    ? "var(--static-colour)"
    : "var(--group-colour-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 96 64"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Static product group"}</title>
      <path
        d="M8.03794 20.9353C8.03794 20.9508 8.02539 20.9662 8.02539 20.9973C8.01085 21.058 8.00225 21.1205 8.00005 21.1836V34.3163C7.99653 34.6493 8.17077 34.946 8.42937 35.0459L19.7599 39.6879L19.7978 39.7033C19.928 39.7656 20.072 39.7656 20.2022 39.7033C20.2161 39.7055 20.2297 39.6998 20.2399 39.6879L31.5704 35.0459C31.829 34.946 32.0035 34.6493 31.9999 34.3163V21.1836C31.9986 21.1102 31.99 21.0371 31.9746 20.9662C31.9552 20.8725 31.916 20.787 31.861 20.718C31.8409 20.65 31.8002 20.5942 31.7473 20.5626C31.7473 20.5542 31.7345 20.5471 31.7345 20.5471L31.6587 20.5006C31.6336 20.4694 31.5957 20.4694 31.5704 20.454L31.5072 20.4229L20.278 15.8277L20.2022 15.7966C20.1421 15.766 20.0777 15.7503 20.0128 15.75H19.9874C19.9224 15.7503 19.8579 15.7657 19.7978 15.7963L19.722 15.8274L8.50537 20.4228H8.49281L8.42959 20.454C8.41197 20.4532 8.39413 20.4586 8.37893 20.4694C8.36637 20.4694 8.3536 20.4851 8.34104 20.5005C8.30778 20.5116 8.27738 20.5333 8.25271 20.5628C8.24016 20.5628 8.24016 20.5628 8.24016 20.5782C8.19082 20.6083 8.15095 20.6573 8.1265 20.7179C8.1265 20.7179 8.11372 20.7247 8.11372 20.7334C8.08596 20.794 8.06063 20.856 8.03794 20.9196V20.9353Z"
        fill={boxColour}
      />
      <path
        d="M11 22.75L20 26.25M20 26.25V36.75M20 26.25L29 22.75"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M65.9062 24.8333H36.4062V29.75H65.9062V24.8333ZM65.9062 15H36.4062V19.9167H65.9062V15ZM36.4062 39.5833H56.0729V34.6667H36.4062V39.5833ZM84.3438 28.5208L88.0312 32.2083L70.8352 49.4167L59.7604 38.3542L63.4479 34.6667L70.8352 42.0417L84.3438 28.5208Z"
        fill={listColour}
      />
    </svg>
  );
};
