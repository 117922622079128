import {
  HierarchyGroupEvaluationType,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import { HierarchyGroupIcon } from "../icons";
import { InformationModal } from "./InformationModal";
import styles from "./InformationModal.module.css";

export type WizardGroupsModalProps = {
  groupType: string;
};

const productGroupModal = {
  title: "Product group",
  content: (
    <div>
      <p>
        If you would like to view certain products together and consider them as
        an aggregate, you can create groups and include them when setting up the
        parameters for a report. Go to the Groups page to create a new product
        group or edit an existing one.{" "}
      </p>
      <p>There are two types of product groups you can create:</p>
      <div className={styles.wizardGroupModal}>
        <li>
          <HierarchyGroupIcon
            evaluationType={HierarchyGroupEvaluationType.Dynamic}
            hierarchyType={HierarchyType.Product}
          />
          <p>
            <strong>Dynamic: </strong> a group of products defined by specific
            rules that updates with changes to those rules (e.g. change in brand
            or product attribute)
          </p>
        </li>
        <li>
          <HierarchyGroupIcon
            evaluationType={HierarchyGroupEvaluationType.Static}
            hierarchyType={HierarchyType.Product}
          />
          <p>
            <strong>Static: </strong> a fixed group of products that does not
            change
          </p>
        </li>
      </div>
    </div>
  ),
  learnMoreLink: "https://portal.quantium.com/s/article/Groups-Q-Checkout",
};

const locationGroupModal = {
  title: "Location group",
  content: (
    <div>
      <p>
        If you would like to view certain store locations together and consider
        them as an aggregate, you can create location groups and include them
        when setting up the parameters for a report. Go to the Groups page to
        create a new location group or edit an existing one.
      </p>
      <p>There are two types of location groups you can create:</p>
      <div className={styles.wizardGroupModal}>
        <li>
          <HierarchyGroupIcon
            evaluationType={HierarchyGroupEvaluationType.Dynamic}
            hierarchyType={HierarchyType.Location}
          />
          <p>
            <strong>Dynamic: </strong> a group of stores defined by specific
            rules that updates with new data
          </p>
        </li>
        <li>
          <HierarchyGroupIcon
            evaluationType={HierarchyGroupEvaluationType.Static}
            hierarchyType={HierarchyType.Location}
          />
          <p>
            <strong>Static: </strong> a fixed group of stores that does not
            change
          </p>
        </li>
      </div>
    </div>
  ),
  learnMoreLink: "https://portal.quantium.com/s/article/Groups-Q-Checkout",
};

export const WizardGroupsModal = ({ groupType }: WizardGroupsModalProps) => {
  const item =
    groupType === "Product groups" ? productGroupModal : locationGroupModal;

  return (
    <InformationModal
      className={styles.wizardGroup}
      detailedDescription={item.content}
      header={item.title}
      label={item.title}
      learnMore={item.learnMoreLink}
    />
  );
};
