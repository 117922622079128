import {
  ErrorProperties,
  NavigationTrackingProperty,
  StatusCodes,
  useEventTrackingServiceContext,
  TrackingComponent,
  TrackingEvent,
  NavigationTrackingPropertyValue,
  NavigationTrackingProperties,
} from "@quantium-enterprise/common-ui";
import { FastReportingFocalItemSearchState } from "@quantium-enterprise/fast-reporting-ui";
import { type HierarchySearchLevel } from "@quantium-enterprise/fast-reporting-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Group,
  Item,
  ItemHalign,
  Spinner,
  SpinnerSize,
  Text,
} from "@quantium-enterprise/qds-react";
import { AppFooter } from "components-ui/src/footer/AppFooter";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import { DashboardTopDrawer } from "./DashboardTopDrawer";
import EmptyWatchlist from "./components/EmptyWatchlist";
import { PopulatedWatchlist } from "./components/PopulatedWatchlist";
import { DashboardWidgetType } from "./enums";
import {
  useCreateDashboardMutation,
  useDeleteWatchlistMutation,
  useGetDashboardQuery,
} from "./services/DashboardService";
import { type DashboardDto } from "./services/dtos/DashboardDto";
import { type WatchlistWidgetDto } from "./services/dtos/DashboardWidgetDto";
import { dashboardSelector, Rendered } from "./states/dashboard-slice";

export const dashboardRoutes = [];

// This must be a default export to support lazy loading: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import
export default () => {
  const eventTrackingService = useEventTrackingServiceContext();

  const dispatch = useDispatch();

  const { initialRender } = useSelector(dashboardSelector);

  const { name: divisionName } = useDivision();

  const [dashboard, setDashboard] = useState<DashboardDto>();
  const [dashboardInitialisedFailed, setDashboardInitialisedFailed] =
    useState<boolean>(false);
  const [watchlistUpsertFailed, setWatchlistUpsertFailed] =
    useState<boolean>(false);

  const handleWatchlistUpsertFailed = useCallback(() => {
    setWatchlistUpsertFailed(true);
  }, []);

  const {
    currentData: dataGetDashboard,
    error: errorGetDashboard,
    isSuccess: isSuccessGetDashboard,
    isError: isErrorGetDashboard,
    refetch: refetchGetDashboard,
  } = useGetDashboardQuery(
    { division: divisionName },
    {
      skip: !divisionName || dashboardInitialisedFailed,
    }
  );

  const [createDashboard] = useCreateDashboardMutation();

  useEffect(() => {
    if (isSuccessGetDashboard) {
      setDashboard(dataGetDashboard);
    }
  }, [isSuccessGetDashboard, dataGetDashboard]);

  useEffect(() => {
    if (isErrorGetDashboard) {
      if (
        (ErrorProperties.Status in errorGetDashboard &&
          errorGetDashboard.status === StatusCodes.NotFound) ||
        (ErrorProperties.OriginalStatus in errorGetDashboard &&
          errorGetDashboard.originalStatus === StatusCodes.NotFound)
      ) {
        createDashboard({ division: divisionName })
          .unwrap()
          .then((result) => {
            setDashboard(result);
          })
          .catch(() => setDashboardInitialisedFailed(true));
      } else {
        setDashboardInitialisedFailed(true);
      }
    }
  }, [isErrorGetDashboard, errorGetDashboard, createDashboard, divisionName]);

  if (!initialRender) {
    eventTrackingService.trackEvent(
      TrackingComponent.Dashboard,
      TrackingEvent.Navigated,
      new NavigationTrackingProperties(
        NavigationTrackingProperty.Source,
        NavigationTrackingPropertyValue.LogIn
      )
    );
    dispatch(Rendered());
  }

  let showDashboardLoading = false;
  let showEmptyWatchlist = false;
  let showPopulatedWatchlist = false;
  let watchlistId: string | undefined;
  if (!dashboard) {
    showDashboardLoading = true;
  } else if (
    (watchlistId = dashboard.widgets.find(
      (widget) => widget.type === DashboardWidgetType.Watchlist
    )?.id) &&
    (
      dashboard.widgets.find(
        (widget) => widget.type === DashboardWidgetType.Watchlist
      ) as WatchlistWidgetDto
    ).itemCount > 0
  ) {
    showPopulatedWatchlist = true;
  } else {
    showEmptyWatchlist = true;
  }

  const [searchLevel, setSearchLevel] = useState<HierarchySearchLevel>();
  const [availableSearchLevels, setAvailableSearchLevels] = useState<
    HierarchySearchLevel[]
  >([]);

  const [searchParameters, setSearchParameters] = useSearchParams();
  const [deleteWatchlist] = useDeleteWatchlistMutation();

  useEffect(() => {
    if (dashboard && searchParameters.get("cleanSlate")) {
      if (watchlistId) {
        void deleteWatchlist({
          dashboardId: dashboard.id,
          division: divisionName,
          watchlistId,
        });
      } else {
        setSearchParameters((current) => {
          current.delete("cleanSlate");
          return current;
        });
      }
    }
  }, [
    searchParameters,
    deleteWatchlist,
    divisionName,
    dashboard,
    setSearchParameters,
    watchlistId,
  ]);

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.dashboardLayout}>
        <FastReportingFocalItemSearchState
          divisionName={divisionName}
          onSearchLevelsChanged={setAvailableSearchLevels}
        />
        <div className={styles.topDrawerContainer}>
          <DashboardTopDrawer />
        </div>
        {showDashboardLoading && (
          <Group>
            <Item halign={ItemHalign.Centre}>
              <Spinner size={SpinnerSize.Large} />
            </Item>
          </Group>
        )}
        {dashboardInitialisedFailed && (
          <Group>
            <Item>
              <Text>Error retrieving/creating dashboard...</Text>
            </Item>
          </Group>
        )}
        {dashboard &&
          availableSearchLevels.length > 0 &&
          showEmptyWatchlist &&
          !searchParameters.get("cleanSlate") && (
            <div className={styles.watchlist}>
              <div className={styles.watchlistArea}>
                <EmptyWatchlist
                  availableSearchLevels={availableSearchLevels}
                  dashboardId={dashboard.id}
                  maxWatchlistItems={dashboard.maxWatchlistItems}
                  onSearchLevelChanged={setSearchLevel}
                  onWatchlistUpsertFailed={handleWatchlistUpsertFailed}
                  onWatchlistUpsertSuccess={refetchGetDashboard}
                  searchLevel={searchLevel}
                  watchlistId={watchlistId}
                />
              </div>
            </div>
          )}
        {dashboard &&
          availableSearchLevels.length > 0 &&
          showPopulatedWatchlist &&
          !searchParameters.get("cleanSlate") && (
            <div className={styles.watchlist}>
              <div className={styles.watchlistArea}>
                <PopulatedWatchlist
                  availableSearchLevels={availableSearchLevels}
                  dashboard={dashboard}
                  division={divisionName}
                  onSearchLevelChanged={setSearchLevel}
                  onWatchlistUpsertFailed={handleWatchlistUpsertFailed}
                  onWatchlistUpsertSuccess={refetchGetDashboard}
                  searchLevel={searchLevel}
                  watchlistId={watchlistId ?? ""}
                />
              </div>
            </div>
          )}
        {watchlistUpsertFailed && (
          <Group>
            <Item>
              <Text>Error creating or updating watchlist..</Text>
            </Item>
          </Group>
        )}
      </div>
      <AppFooter />
    </div>
  );
};
