import { SortIcon } from "components-ui/src/tables/common/SortIcon";
import { SortedType } from "components-ui/src/tables/common/column-sort/ColumnSort";
import { MeasureGroups } from "../../../common/models/MeasureGroups";
import styles from "./SalesHeader.module.css";

export const SalesHeader = () => (
  <div className={styles.salesHeader}>
    <span>{MeasureGroups.Sales}</span>
    <div>
      <SortIcon sortedType={SortedType.desc} />
    </div>
  </div>
);
