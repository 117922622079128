import {
  type HierarchyItem,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { type Table } from "@tanstack/react-table";
import {
  type LocationHierarchyGridItem,
  type HierarchyGridItem,
} from "../models/hierarchy";

export const convertToHierarchyItems = (
  ids: string[],
  hierarchyTable: Table<HierarchyGridItem>
): HierarchyItem[] => {
  const hierarchyItems = ids.map((id) => {
    const row = hierarchyTable.getRowModel().rowsById[id];

    // We are excluding the subRows here as we do not want to include them as part of expanded list
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { subRows, ...item } = row.original;

    return {
      ...item,
      id: row.id,
    };
  });

  return hierarchyItems;
};

export const convertToLocationHierarchyItem = (
  rowId: string,
  hierarchyTable: Table<LocationHierarchyGridItem>
): LocalHierarchyNodeSelection => {
  const row = hierarchyTable.getRowModel().rowsById[rowId];
  // We are excluding the subRows here as we do not want to include them as part of expanded list
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { subRows, ...item } = row.original;

  return {
    ...item,
    // id: row.id,
  };
};

export const convertToLocationHierarchyItems = (
  ids: string[],
  hierarchyTable: Table<LocationHierarchyGridItem>
): LocalHierarchyNodeSelection[] => {
  const hierarchyItems = ids.map((id) => {
    const row = hierarchyTable.getRowModel().rowsById[id];
    // We are excluding the subRows here as we do not want to include them as part of expanded list
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { subRows, ...item } = row.original;

    return {
      ...item,
      id: row.id,
    };
  });

  return hierarchyItems;
};
