import {
  type FolderDto,
  type NestedFolderDto,
  type GeneratedReportDto,
  ReportStatus,
  useEventTrackingServiceContext,
  TrackingEvent,
  TrackingComponent,
  GenericTrackingProperties,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Spinner, SpinnerSize } from "@quantium-enterprise/qds-react";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useMyReportContext } from "../MyReportsNoRedux";
import { MyReportsGrid } from "../my-reports-grid/MyReportsGrid";
import { NoContent, Variant } from "../no-content/NoContent";
import styles from "./GeneratedReports.module.css";

export const isFolderRow = (
  row: GeneratedReportDto | GeneratedReportDto[] | NestedFolderDto
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
): row is NestedFolderDto => row && "folders" in row;

export const isReportRow = (
  row: GeneratedReportDto | NestedFolderDto
): row is GeneratedReportDto => "createDateUtc" in row;

export enum ReportType {
  ReportParameters = "ReportParameters",
  SavedParameters = "SavedParameters",
}

export const convertAndFlattenFolders = (
  folders: NestedFolderDto[],
  parentId?: string
): FolderDto[] => {
  const flatList: FolderDto[] = [];

  const traverse = (folder: NestedFolderDto, parentFolderId?: string) => {
    const convertedFolder: FolderDto = {
      color: folder.color,
      creationDateUTC: folder.creationDateUTC ?? "",
      divisionId: "",
      id: folder.id,
      isDeleted: false,
      name: folder.name ?? "",
      parentId: parentFolderId,
      salesforceUserId: "",
      type: ReportType.ReportParameters,
    };

    flatList.push(convertedFolder);

    if (folder.folders) {
      for (const subFolder of folder.folders) {
        traverse(subFolder, folder.id);
      }
    }
  };

  for (const folder of folders) {
    traverse(folder, parentId);
  }

  // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
  return flatList.sort().reverse();
};

export const GeneratedReportsNoRedux = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<
    GeneratedReportDto | NestedFolderDto
  >();
  const { id: selectedReportId } = useParams();
  const { name: activeDivisionName } = useDivision();

  const { allReportSharedUsers, isRowListLoading, rootFolder } =
    useMyReportContext();

  const myReportsNavigator = useCallback(
    (reportId: string) => {
      if (reportId === selectedReportId) {
        navigate(
          generatePath(`/:division/my-reports`, {
            division: activeDivisionName,
          })
        );
      } else {
        navigate(
          generatePath(`/:division/my-reports/:id`, {
            division: activeDivisionName,
            id: reportId,
          })
        );
      }
    },
    [selectedReportId, navigate, activeDivisionName]
  );

  useEffect(() => {
    if (!selectedRow) {
      return;
    }

    if (
      isReportRow(selectedRow) &&
      selectedRow.reportStatus === ReportStatus.Completed
    ) {
      navigate(
        generatePath(`/:division/${selectedRow.reportType}/:id`, {
          division: activeDivisionName,
          id: selectedRow.id,
        })
      );
      eventTrackingService.trackEvent(
        [TrackingComponent.MyReports, TrackingComponent.Report],
        TrackingEvent.Opened,
        new GenericTrackingProperties({
          reportId: selectedRow.id,
          reportName: selectedRow.name.split(" - ")[0],
          method: "Double click row",
        })
      );
    }
  }, [eventTrackingService, activeDivisionName, navigate, selectedRow]);

  const onRowDoubleClick = useCallback(
    (
      event: React.MouseEvent<HTMLTableRowElement>,
      rowId: number,
      rowData: GeneratedReportDto
    ) => {
      setSelectedRow(rowData);
    },
    []
  );

  return (
    <div>
      <div className={styles.generatedReportsContainer}>
        {isRowListLoading && <Spinner size={SpinnerSize.Large} />}

        {!isRowListLoading &&
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (rootFolder && rootFolder.reports.length > 0 ? (
            <MyReportsGrid
              allReportSharedUsers={allReportSharedUsers ?? []}
              myReportsNavigator={myReportsNavigator}
              onRowDoubleClick={onRowDoubleClick}
              reports={rootFolder.reports}
              selectedReportId={selectedReportId}
            />
          ) : (
            <NoContent
              activeDivisionName={activeDivisionName}
              variant={Variant.GeneratedReports}
            />
          ))}
      </div>
    </div>
  );
};

export default GeneratedReportsNoRedux;
