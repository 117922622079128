import { type PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ErrorMessageWithRetry from "./ErrorMessageWithRetry";

type ErrorBoundaryInnerProps = {
  error: Error | null;
  fallback?: JSX.Element;
  setError: (error: Error | null) => void;
};

type ErrorBoundaryInnerState = {
  hasError: boolean;
};

class ErrorBoundaryInner extends React.Component<
  PropsWithChildren<ErrorBoundaryInnerProps>,
  ErrorBoundaryInnerState
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public constructor(properties: ErrorBoundaryInnerProps) {
    super(properties);
    this.state = { hasError: false };
  }

  public componentDidUpdate(prevProps: ErrorBoundaryInnerProps) {
    if (!this.props.error && prevProps.error) {
      // eslint-disable-next-line react/no-set-state
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch(error: Error) {
    this.props.setError(error);
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      } else {
        return (
          <ErrorMessageWithRetry
            data-testid="error-message"
            errorText="There has been an error."
            retry={() => this.props.setError(null)}
          />
        );
      }
    } else {
      return this.props.children;
    }
  }
}

type ErrorBoundaryProps = {
  fallback?: JSX.Element;
};

const ErrorBoundary = ({
  children,
  fallback,
}: PropsWithChildren<ErrorBoundaryProps>) => {
  const [error, setError] = useState<Error | null>(null);
  const location = useLocation();

  // Putting boundaries around react router outlets won't automatically reset the error boundary when
  // navigating away so we have manually reset
  // https://dev.to/tylerlwsmith/error-boundary-causes-react-router-links-to-stop-working-50gb
  useEffect(() => {
    if (error) {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <ErrorBoundaryInner error={error} fallback={fallback} setError={setError}>
      {children}
    </ErrorBoundaryInner>
  );
};

export default ErrorBoundary;
