import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type ReportingConfigurationDto } from "./reportConfigurationDto";

export const reportConfigurationApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    reportConfiguration: builder.query<
      ReportingConfigurationDto,
      { division: string }
    >({
      providesTags: ["GetReportConfiguration"],
      query: (request) => ({
        method: "GET",
        url: `/${request.division}/report-configuration`,
      }),
    }),
  }),
  reducerPath: "reportConfigurationApi",
  tagTypes: ["GetReportConfiguration"],
});

export const useReportConfigurationPrefetch = () =>
  reportConfigurationApi.usePrefetch("reportConfiguration");

export const { useReportConfigurationQuery } = reportConfigurationApi;
