import { type HierarchyValueDto } from "@quantium-enterprise/common-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { BasicColumnChart } from "components-ui/src/charts/basic-column-chart/BasicColumnChart";
import { useSelector } from "react-redux";
import { type GainsAndLossBreakdownSegmentationResponseDto } from "../../models/GainsAndLossBreakdownSegmentationResponseDto";
import { selectShowSwitchingBreakdownChartDataLabels } from "../../services/gains-and-loss-slice";
import { getSegmentationMiniChartColumns } from "../../utils/segmentation-chart-utils";
import styles from "./GainsAndLossBreakdownMiniChart.module.css";
import { GainsAndLossMiniChartTooltip } from "./GainsAndLossMiniChartTooltip";
import { MINI_CHART_HEIGHT } from "./utils";

type GainsAndLossBreakdownSegmentationChartProps = {
  item: HierarchyValueDto;
  measure: string;
  response?: GainsAndLossBreakdownSegmentationResponseDto[];
};

export const GainsAndLossBreakdownSegmentationChart = ({
  response,
  item,
  measure,
}: GainsAndLossBreakdownSegmentationChartProps) => {
  const formatter = useFormatter();
  const showSwitchingBreakdownChartDataLabels = useSelector(
    selectShowSwitchingBreakdownChartDataLabels
  );

  if (!response) return <div>No data available</div>;

  const dataForMeasure = response.find((x) => x.measure === measure);
  const segmentValues = dataForMeasure?.segmentValues ?? [];
  const format = dataForMeasure?.format ?? MetricTypes.None;

  const renderTooltip = (
    metrics:
      | Array<{
          color?: string | null | undefined;
          name?: number | string | null | undefined;
          value?: string | null | undefined;
        }>
      | undefined
  ) => {
    let header = "";
    let secondary;
    let color;
    let value;
    if (metrics) {
      const metric = metrics[0].name;
      color = metrics[0].color;
      value = metrics[0].value;

      header = String(metric);
      secondary = "Contribution to net";
    }

    return (
      <GainsAndLossMiniChartTooltip
        color={color ?? ""}
        primaryHeader={header}
        secondaryHeader={secondary}
        value={value ?? "0"}
      />
    );
  };

  return (
    <div className={styles.breakdownMiniChart}>
      <h3 className={styles.breakdownMiniChartHeader}>
        Segmentation breakdown
      </h3>
      <h4 className={styles.breakdownMiniChartSubtitle}>of {item.name}</h4>
      <BasicColumnChart
        categories={segmentValues.map((sv) => sv.name)}
        data={getSegmentationMiniChartColumns(segmentValues)}
        dataLabelFormatter={formatter(format)}
        height={MINI_CHART_HEIGHT}
        showChartDataLabels={showSwitchingBreakdownChartDataLabels}
        tooltipFormatter={formatter(format)}
        tooltipOverride={renderTooltip}
        yAxisTickFormatter={formatter(format)}
      />
    </div>
  );
};
