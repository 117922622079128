import { formatStringDate } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { type TimePeriodPanel } from "../local-parameters-panel/FixedSidePanel";
import CollapsiblePanel from "./CollapsiblePanel";
import styles from "./TimePeriodFilter.module.css";

export type TimePeriodFilterProps = {
  isCollapsed: boolean;
  panel: TimePeriodPanel;
  title: string;
  toggleCollapsed: () => void;
};
const defaultToggleCollapsed = () => {};
export const TimePeriodFilter = ({
  panel,
  title,
  isCollapsed,
  toggleCollapsed = defaultToggleCollapsed,
}: TimePeriodFilterProps) => {
  const focusPeriod = panel.focusPeriod.label.toLowerCase();
  const focusStartDateFormatted = formatStringDate(panel.focusPeriod.startDate);
  const focusEndDateFormatted = formatStringDate(panel.focusPeriod.endDate);

  let secondaryTimePanelContent = <div />;
  let secondaryTimePanelSummary = <div />;

  if (panel.comparisonPeriod) {
    const comparisonPeriod = panel.comparisonPeriod.label.toLowerCase();
    const comparisonStartDateFormatted = formatStringDate(
      panel.comparisonPeriod.startDate
    );
    const comparisonEndDateFormatted = formatStringDate(
      panel.comparisonPeriod.endDate
    );

    secondaryTimePanelContent = (
      <>
        <div className={classNames(styles.timePanelLabel)}>
          Comparison period ({comparisonPeriod})
        </div>
        <div className={classNames(styles.timePanelValue)}>
          {comparisonStartDateFormatted} to {comparisonEndDateFormatted}
        </div>
      </>
    );
    secondaryTimePanelSummary = (
      <div>Comparison period: {comparisonPeriod}</div>
    );
  } else if (panel.rollingPeriod) {
    const rollingPeriod = panel.rollingPeriod.label.toLowerCase();

    secondaryTimePanelContent = (
      <>
        <div className={classNames(styles.timePanelLabel)}>Rolling period</div>
        <div className={classNames(styles.timePanelValue)}>{rollingPeriod}</div>
      </>
    );
    secondaryTimePanelSummary = <div>Rolling period: {rollingPeriod}</div>;
  } else if (panel.leadPeriod) {
    const leadPeriod = panel.leadPeriod.label.toLowerCase();
    const leadStartDateFormatted = formatStringDate(panel.leadPeriod.startDate);
    const leadEndDateFormatted = formatStringDate(panel.leadPeriod.endDate);

    const leadPeriodValue =
      panel.leadPeriod.periodValue === "Custom-0"
        ? `${leadStartDateFormatted} to ${leadEndDateFormatted}`
        : panel.leadPeriod.label;

    secondaryTimePanelContent = (
      <>
        <div className={classNames(styles.timePanelLabel)}>
          Lead in period
          {panel.leadPeriod.periodValue === "Custom-0" && ` (${leadPeriod})`}
        </div>
        <div className={classNames(styles.timePanelValue)}>
          {leadPeriodValue}
        </div>
      </>
    );
    secondaryTimePanelSummary = <div>Lead in period: {leadPeriod}</div>;
  }

  const content = (
    <div>
      <div className={classNames(styles.timePanelLabel)}>
        Focus period ({focusPeriod})
      </div>
      <div className={classNames(styles.timePanelValue)}>
        {focusStartDateFormatted} to {focusEndDateFormatted}
      </div>
      <br />
      {secondaryTimePanelContent}
    </div>
  );

  const summary = (
    <div>
      <div>Focus period: {focusPeriod}</div>
      {secondaryTimePanelSummary}
    </div>
  );

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      isDisabled={false}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content,
        summary,
      }}
    </CollapsiblePanel>
  );
};

export default TimePeriodFilter;
