import { BreakdownByParameterId } from "../../models/basket-quantities-common-models";
import { type BasketQuantitiesSummaryResponseDto } from "../../models/basket-quantities-summary-response-dto";
import { getExportColumnHeader } from "../../utils/getExportColumnHeader";

export const csvTransformation = (
  response: BasketQuantitiesSummaryResponseDto | undefined,
  breakdown: string,
  metricKeys: string[],
  currencySymbol: string
) => {
  const columnHeaders = ["Quantity per basket"];
  const csvData = [columnHeaders];
  if (response) {
    const series = response.series
      .filter(
        (metricSeries) =>
          (breakdown === BreakdownByParameterId.Total
            ? metricSeries.breakdown === "Total"
            : metricSeries.breakdown !== "Total") &&
          metricKeys.includes(metricSeries.metricKey)
      )
      .sort(
        (metricSeries1, metricSeries2) =>
          metricKeys.indexOf(metricSeries1.metricKey) -
          metricKeys.indexOf(metricSeries2.metricKey)
      );
    columnHeaders.push(
      ...series.map(getExportColumnHeader(currencySymbol, breakdown))
    );
    for (let index = 0; index < response.categories.length; ++index) {
      const row = [
        response.categories[index],
        ...series.map((metricSeries) =>
          (metricSeries.data[index].metricValue ?? 0).toString()
        ),
      ];
      csvData.push(row);
    }
  }

  return csvData;
};
