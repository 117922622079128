import {
  type AttributeFilter,
  type HierarchyMetadataResponseDto,
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyType,
  useLazySearchQuery,
  ddLog,
} from "@quantium-enterprise/common-ui";
import { HierarchyGroupRuleOperator } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
  Select,
  SelectOption,
  Tooltip,
  TooltipSpaceInside,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import {
  type DropdownOption,
  MultiselectDropdown,
} from "components-ui/src/multiselect-dropdown/MultiselectDropdown";
import { useCallback, useEffect, useId, useMemo, useState } from "react";
import { NULL_SHORT_NAME } from "../../../../groups/src/components/constants";
import styles from "./DynamicGroupRule.module.css";

// the number of results to get at a time
const DEFAULT_PAGE_SIZE = 500;

// limit for selection in rule value dropdown
const MAX_SELECTION = 500;

export type RuleNameOption = HierarchyMetadataResponseDto & {
  disabled?: boolean;
  selected?: boolean;
};

export type DynamicGroupRuleProps = {
  hierarchyType: HierarchyType;
  onRuleChange: (rule: HierarchyGroupRuleWithIdAndName) => void;
  onRuleRemoved: (ruleId: string) => void;
  previousGroupRules: HierarchyGroupRuleWithIdAndName[];
  readonly: boolean;
  rule: HierarchyGroupRuleWithIdAndName;
  ruleNameOptions: RuleNameOption[];
};

export const DynamicGroupRule = ({
  hierarchyType,
  previousGroupRules,
  onRuleChange,
  onRuleRemoved,
  readonly = false,
  rule,
  ruleNameOptions,
}: DynamicGroupRuleProps) => {
  const { name: activeDivisionName } = useDivision();
  const id = useId();
  // the options available from the dropdown
  const [ruleValueOptions, setRuleValueOptions] = useState<DropdownOption[]>(
    []
  );

  // the current page number of the results
  const [page, setPage] = useState<number>(0);
  // whether to keep lazy loading comntent because there is more on the back end
  const [hasMoreResults, setHasMoreResults] = useState(false);
  // the search text in the values component
  const [searchText, setSearchText] = useState("");
  // a reference to the previous rule shortname
  const [previousRequest, setPreviousRequest] = useState<{
    page: number;
    shortName: string;
  }>({
    shortName: rule.shortName,
    page: 0,
  });

  const [triggerSearchQuery, { isFetching: isRuleValueOptionsFetching }] =
    useLazySearchQuery();

  const onLazySearch = useCallback((search: string) => {
    setSearchText(search);
  }, []);

  const onLoadMore = useCallback(() => {
    setPage((oldValue) => oldValue + 1);
  }, []);

  const onRuleItemSelected = useCallback(
    (selectedItems: DropdownOption[]) => {
      if (selectedItems.length > MAX_SELECTION) return;

      onRuleChange({
        ...rule,
        values: selectedItems.map((x) => ({
          name: x.label,
          code: x.value.toString(),
        })),
      });
    },
    [onRuleChange, rule]
  );

  const getOperatorText = (operator: HierarchyGroupRuleOperator): string => {
    switch (operator) {
      case HierarchyGroupRuleOperator.Is:
        return "is";
      case HierarchyGroupRuleOperator.IsNot:
        return "is not";
      default:
        return "";
    }
  };

  const textboxValue = useMemo(() => {
    if (rule.values.length === 1) {
      return rule.values[0].name;
    }

    if (rule.values.length > 1) {
      return `${rule.values.length} items selected`;
    }

    return "";
  }, [rule.values]);

  const handleRuleChange = useCallback(async () => {
    const searchRequestDto = {
      division: activeDivisionName,
      hierarchyType,
      payload: {
        page,
        pageSize: DEFAULT_PAGE_SIZE,
        focalAttributes: [rule.shortName],
        includeCodes: true,
        query: searchText,
        filters: previousGroupRules
          .filter((gr) => gr.operator === HierarchyGroupRuleOperator.Is)
          .map(
            (gr) =>
              ({
                shortName: gr.shortName,
                codes: gr.values.map((value) => value.code),
              } as AttributeFilter)
          ),
        excludeFilters: previousGroupRules
          .filter((gr) => gr.operator === HierarchyGroupRuleOperator.IsNot)
          .map(
            (gr) =>
              ({
                shortName: gr.shortName,
                codes: gr.values.map((value) => value.code),
              } as AttributeFilter)
          ),
      },
    };

    await triggerSearchQuery(searchRequestDto)
      .unwrap()
      .then((result) => {
        setRuleValueOptions((oldValue) => {
          const newResults = result.results.map(
            (x) =>
              ({
                label: x.name,
                value: x.code,
              } as DropdownOption)
          );

          const joinedMappedValuesResults = [...oldValue, ...newResults];

          // Dedupe the list
          const newMappedValuesResults = joinedMappedValuesResults.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (foundValue) =>
                  foundValue.label === value.label &&
                  foundValue.value === value.value
              )
          );

          return newMappedValuesResults;
        });
        setHasMoreResults(result.hasNextPage);
      });
  }, [
    activeDivisionName,
    hierarchyType,
    page,
    previousGroupRules,
    rule.shortName,
    searchText,
    triggerSearchQuery,
  ]);

  useEffect(() => {
    if (
      rule.shortName !== NULL_SHORT_NAME &&
      (rule.shortName !== previousRequest.shortName ||
        page !== previousRequest.page ||
        page === 0)
    ) {
      if (rule.shortName !== previousRequest.shortName) {
        // Reset rule value options and page before triggering request
        setRuleValueOptions([]);
        setPage(0);
      }

      setPreviousRequest({ shortName: rule.shortName, page });

      handleRuleChange().catch((error) => {
        ddLog("Error fetching rule values", undefined, undefined, error);
      });
    }
  }, [
    handleRuleChange,
    page,
    previousRequest.page,
    previousRequest.shortName,
    rule.shortName,
  ]);

  return (
    <div className={styles.groupEditorRuleContainer} role="row">
      {readonly && (
        <>
          <div className={styles.shortName}>{rule.fullShortName}</div>
          <div className={styles.operator}>
            {getOperatorText(rule.operator)}
          </div>
          <Tooltip
            className={styles.selection}
            spaceInside={TooltipSpaceInside.Medium}
            trigger={<div>{textboxValue}</div>}
            variant={TooltipVariant.ArrowDark}
          >
            {rule.values.map((value) => (
              <div key={`item-${value.code}`}>{value.name}</div>
            ))}
          </Tooltip>
          <Button
            onClick={() => onRuleRemoved(rule.id)}
            variant={ButtonVariant.Link}
          >
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Remove rule" />
          </Button>
        </>
      )}
      {!readonly && (
        <>
          <div className={styles.shortName}>
            <Select
              id={`rule-shortName-${id}`}
              onChange={(event) => {
                onRuleChange({
                  ...rule,
                  shortName: event.target.value,
                  fullShortName: ruleNameOptions.find(
                    (option) => option.shortName === event.target.value
                  )?.name,
                });
              }}
              value={rule.shortName}
            >
              {ruleNameOptions.map((a) => (
                <SelectOption
                  key={a.shortName}
                  text={a.name}
                  value={a.shortName}
                  {...a}
                />
              ))}
            </Select>
          </div>
          <div className={styles.operator}>
            <Select
              id={`rule-operator-${id}`}
              onChange={(event) => {
                onRuleChange({
                  ...rule,
                  operator: event.target.value as HierarchyGroupRuleOperator,
                });
              }}
              value={rule.operator}
            >
              <SelectOption
                key={HierarchyGroupRuleOperator.Is}
                text={getOperatorText(HierarchyGroupRuleOperator.Is)}
                value={HierarchyGroupRuleOperator.Is}
              />
              <SelectOption
                key={HierarchyGroupRuleOperator.IsNot}
                text={getOperatorText(HierarchyGroupRuleOperator.IsNot)}
                value={HierarchyGroupRuleOperator.IsNot}
              />
            </Select>
          </div>
          <div className={styles.values}>
            <MultiselectDropdown
              hasMoreResults={hasMoreResults}
              id={`rule-values-${id}`}
              isLoading={isRuleValueOptionsFetching}
              items={ruleValueOptions}
              key={`rule-values-${id}`}
              onLazySearch={onLazySearch}
              onLoadMore={onLoadMore}
              onSelected={onRuleItemSelected}
              values={rule.values.map(
                (value) =>
                  ({
                    label: value.name ?? `Not available - ${value.code}`,
                    value: value.code,
                  } as DropdownOption)
              )}
            />
          </div>
          <Button
            onClick={() => onRuleRemoved(rule.id)}
            variant={ButtonVariant.Link}
          >
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Remove rule" />
          </Button>
        </>
      )}
    </div>
  );
};
