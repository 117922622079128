import Highcharts from "highcharts";
import { ChartColoursList } from "./src/charts/ChartColours";

export { ErrorLayout } from "./src/error-layout/ErrorLayout";

// set default options for all highcharts charts across the entire application.
Highcharts.setOptions({
  colors: ChartColoursList,
});

// https://jira.quantium.com.au/browse/QDS-774
window.setImmediate =
  window.setTimeout as unknown as typeof global.setImmediate;
