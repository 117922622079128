import {
  Dropdown,
  DropdownPositionX,
  DropdownPositionY,
  DropdownSpaceInside,
  StealthInlineIconButton,
  InlineIconGlyph,
  IconSize,
  DropdownVariant,
} from "@quantium-enterprise/qds-react";
import { type PropsWithChildren } from "react";
import styles from "./InfoIconDropdown.module.css";

export type InfoIconDropdownProps = {
  iconText?: string;
};

export const InfoIconDropdown = ({
  iconText = "",
  children,
}: PropsWithChildren<InfoIconDropdownProps>) => (
  <Dropdown
    className={styles.infoIcon}
    position={{
      x: DropdownPositionX.AlignCentre,
      y: DropdownPositionY.Auto,
    }}
    spaceInside={DropdownSpaceInside.Small}
    trigger={
      <StealthInlineIconButton
        data-testid="info-icon"
        iconGlyph={InlineIconGlyph.AlertsAndNotificationsHelpCircleOutline}
        iconSize={IconSize.Small}
        iconText={iconText}
      />
    }
    variant={DropdownVariant.ArrowDark}
  >
    <>{children}</>
  </Dropdown>
);
