type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const CustomerCrossShopIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-four)"
    : "var(--icon-colour-five-disabled)";
  const colour4 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour5 = coloured
    ? "var(--report-wizard-icon-colour-five)"
    : "var(--icon-colour-six-disabled)";
  const colour6 = coloured
    ? "var(--report-wizard-icon-colour-six)"
    : "var(--icon-colour-four-disabled)";
  const colour7 = coloured
    ? "var(--report-wizard-icon-colour-seven)"
    : "var(--icon-colour-seven-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Customer cross shop report"}</title>
      <path
        d="M10.1984 10.9351C10.2215 10.7487 10.2529 10.5614 10.2951 10.3739C10.7635 8.29334 12.2729 6.71969 14.1515 6.07121C13.2499 4.64565 11.8044 3.56185 10.0295 3.16225C6.48224 2.36362 2.96082 4.59081 2.16219 8.13809C1.42486 11.4131 3.26755 14.6644 6.34818 15.7736C6.35767 15.4438 6.39515 15.1095 6.47033 14.7756C6.90752 12.8337 8.39281 11.403 10.1984 10.9351Z"
        fill={colour1}
      />
      <path
        d="M21.8539 12.9763C22.5728 9.78347 20.5685 6.6145 17.3757 5.89568C16.2635 5.64528 15.1546 5.72759 14.1515 6.07121C15.0486 7.49305 15.4044 9.25722 15.0054 11.0296C14.9348 11.343 14.8429 11.6434 14.731 11.9367C16.1841 13.1302 16.9287 15.0736 16.488 17.0309C16.4446 17.2236 16.389 17.4108 16.3237 17.5931C18.9336 17.4844 21.2531 15.6451 21.8539 12.9763Z"
        fill={colour2}
      />
      <path
        d="M10.2951 10.3739C10.2529 10.5614 10.2215 10.7487 10.1984 10.9351C10.9635 10.7376 11.7849 10.7093 12.6068 10.8944C13.4288 11.0795 14.1323 11.443 14.731 11.9367C14.8423 11.646 14.9348 11.343 15.0054 11.0296C15.4038 9.25979 15.048 7.49562 14.1515 6.07121C12.2735 6.71712 10.7635 8.29334 10.2951 10.3739Z"
        fill={colour1}
      />
      <path
        d="M10.2951 10.3739C10.2529 10.5614 10.2215 10.7487 10.1984 10.9351C10.9635 10.7376 11.7849 10.7093 12.6068 10.8944C13.4288 11.0795 14.1323 11.443 14.731 11.9367C14.8423 11.646 14.9348 11.343 15.0054 11.0296C15.4038 9.25979 15.048 7.49562 14.1515 6.07121C12.2735 6.71712 10.7635 8.29334 10.2951 10.3739Z"
        fill={colour3}
      />
      <path
        d="M11.5278 15.4716C10.2165 16.1291 8.67921 16.3524 7.13803 16.0054C6.86576 15.9441 6.60267 15.866 6.34818 15.7736C6.28525 18.1749 7.91899 20.3644 10.3515 20.9121C12.9253 21.4915 15.479 20.0262 16.3237 17.5931C15.8144 17.6134 15.2948 17.5719 14.7733 17.4545C13.4505 17.1567 12.333 16.4382 11.5278 15.4716Z"
        fill={colour4}
      />
      <path
        d="M11.5278 15.4716C10.5037 14.2424 9.98557 12.6118 10.1984 10.9351C8.39596 11.401 6.90752 12.8337 6.47033 14.7756C6.39515 15.1095 6.3551 15.4432 6.34818 15.7736C6.60267 15.866 6.86832 15.9447 7.13803 16.0054C8.67921 16.3524 10.2171 16.1265 11.5278 15.4716Z"
        fill={colour1}
      />
      <path
        d="M11.5278 15.4716C10.5037 14.2424 9.98557 12.6118 10.1984 10.9351C8.39596 11.401 6.90752 12.8337 6.47033 14.7756C6.39515 15.1095 6.3551 15.4432 6.34818 15.7736C6.60267 15.866 6.86832 15.9447 7.13803 16.0054C8.67921 16.3524 10.2171 16.1265 11.5278 15.4716Z"
        fill={colour5}
      />
      <path
        d="M14.731 11.9367C14.1312 13.51 12.9665 14.751 11.5278 15.4716C12.333 16.4382 13.4505 17.1567 14.7733 17.4545C15.2948 17.5719 15.8138 17.6159 16.3237 17.5931C16.3864 17.4102 16.4446 17.2236 16.488 17.0309C16.9287 15.0736 16.1847 13.1276 14.731 11.9367Z"
        fill={colour2}
      />
      <path
        d="M14.731 11.9367C14.1312 13.51 12.9665 14.751 11.5278 15.4716C12.333 16.4382 13.4505 17.1567 14.7733 17.4545C15.2948 17.5719 15.8138 17.6159 16.3237 17.5931C16.3864 17.4102 16.4446 17.2236 16.488 17.0309C16.9287 15.0736 16.1847 13.1276 14.731 11.9367Z"
        fill={colour6}
      />
      <path
        d="M10.1984 10.9351C9.98557 12.6118 10.5037 14.2424 11.5278 15.4716C12.9665 14.751 14.1312 13.51 14.731 11.9367C14.1318 11.4455 13.416 11.0766 12.6068 10.8944C11.7977 10.7122 10.9609 10.737 10.1984 10.9351Z"
        fill={colour1}
      />
      <path
        d="M10.1984 10.9351C9.98557 12.6118 10.5037 14.2424 11.5278 15.4716C12.9665 14.751 14.1312 13.51 14.731 11.9367C14.1318 11.4455 13.416 11.0766 12.6068 10.8944C11.7977 10.7122 10.9609 10.737 10.1984 10.9351Z"
        fill={colour2}
      />
      <path
        d="M10.1984 10.9351C9.98557 12.6118 10.5037 14.2424 11.5278 15.4716C12.9665 14.751 14.1312 13.51 14.731 11.9367C14.1318 11.4455 13.416 11.0766 12.6068 10.8944C11.7977 10.7122 10.9609 10.737 10.1984 10.9351Z"
        fill={colour7}
      />
    </svg>
  );
};
