import classNames from "classnames";
import styles from "./GreySpinner.module.css";

type Props = {
  className?: string;
};

export const GreySpinner = ({ className }: Props) => (
  <div className={classNames(styles.spinnerIcon, className)} />
);
