import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isMinMaxRangeParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type BasketAffinitiesLocalSelections } from "../services/basket-affinities-slice";

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): BasketAffinitiesLocalSelections => {
  const selections: BasketAffinitiesLocalSelections = {
    time: "",
    timePeriodLength: "",
    channel: { value: "", label: "" },
    location: {
      code: "",
      depth: 0,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      nodeNumber: 0,
      shortName: "",
    },
    segment: { value: "", label: "" },
    segmentation: { value: "", label: "" },
    upliftThreshold: 0,
    basketsWithBothThreshold: 0,
    upliftRange: {
      maximum: undefined,
      minimum: undefined,
    },
    basketsWithBothRange: {
      maximum: undefined,
      minimum: undefined,
    },
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const { time, timePeriodLength } = getTimeDefaultSelection(parameter);
          selections.time = time;
          selections.timePeriodLength = timePeriodLength;
        }

        break;
      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.channel = { ...selection };
          }
        }

        break;
      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            selections.segmentation = { ...selection };
            selections.segment = {
              value: "All",
              label: "All",
            };
          }
        }

        break;

      case LocalParameters.BasketsWithBothThreshold:
        if (isMinMaxRangeParameter(parameter)) {
          selections.basketsWithBothThreshold =
            (parameter.maximumPossibleValue + parameter.minimumPossibleValue) /
            2;
        }

        break;

      case LocalParameters.UpliftThreshold:
        if (isMinMaxRangeParameter(parameter)) {
          selections.upliftThreshold =
            (parameter.maximumPossibleValue + parameter.minimumPossibleValue) /
            2;
        }

        break;

      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          selections.location = parameter.selections[0];
        }

        break;

      default:
        break;
    }
  }

  return selections;
};
