import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isSegmentationParameter,
  isSingleSelectionParameter,
  isPlainTextContentParameter,
  isTimePeriodParameter,
  isLocationSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildLocationHierarchyPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildSingleSelectPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";
import {
  onChannelChange,
  onKeyDriverChange,
  onLocationChange,
  onPromotionChange,
  onSegmentationChange,
} from "../services/key-driver-tree-slice";
import { selectLocalParameterSelections } from "../services/key-driver-tree-slice-selectors";

export const KeyDriverTreeSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  const dispatch = useDispatch();

  const localParametersSelections = useSelector(selectLocalParameterSelections);

  const { localParametersConfig } = useSelector((state: RootState) => ({
    localParametersConfig: state.keyDriverTree.localParameterConfig,
  }));

  const generateKDTPanels = () => {
    const panels: Panel[] = [];

    for (const parameter of localParametersConfig) {
      switch (parameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(parameter))
            panels.push(buildTimePeriodPanel(parameter));
          break;

        case LocalParameters.KeyDriver:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onKeyDriverChange(value));
                },
                localParametersSelections.KeyDriver.value as string
              )
            );
          }

          break;

        case LocalParameters.Channel:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onChannelChange(value));
                },
                localParametersSelections.Channel.value as string
              )
            );
          }

          break;

        case LocalParameters.Promotion:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onPromotionChange(value));
                },
                localParametersSelections.Promotion.value as string
              )
            );
          }

          break;

        case LocalParameters.Segmentation:
          if (isSegmentationParameter(parameter)) {
            panels.push(
              buildSegmentationPanel(
                parameter,
                (value: SegmentOption) => {
                  dispatch(onSegmentationChange(value));
                },
                [
                  localParametersSelections.Segmentation.value as string,
                  localParametersSelections.Segment.value as string,
                ]
              )
            );
          } else if (isPlainTextContentParameter(parameter)) {
            panels.push(buildPlainTextContentPanel(parameter));
          }

          break;

        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(parameter)) {
            panels.push(
              buildLocationHierarchyPanel(
                parameter,
                (selection: LocalHierarchyNodeSelection) => {
                  dispatch(onLocationChange(selection));
                },
                localParametersSelections.LocationHierarchy
              )
            );
          }

          break;

        default: {
          break;
        }
      }
    }

    return panels;
  };

  return (
    <SidePanel
      eventTrackingService={eventTrackingService}
      panels={generateKDTPanels()}
    />
  );
};

export default KeyDriverTreeSidePanel;
