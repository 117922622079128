import {
  FeatureFlag,
  type ReportParametersDto,
  useGetOrganizationUsersQuery,
  useGetSharingActivitiesQuery,
  useGetUsersByIdQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  IconSize,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { InfoPanel } from "components-ui/src/info-panel/InfoPanel";
import {
  type Dispatch,
  type SetStateAction,
  type PropsWithChildren,
} from "react";
import { useState, useContext, useMemo, createContext } from "react";
import { EditableField } from "../editable-field/EditableField";
import { AppFooter } from "../footer/AppFooter";
import { ReportIcon } from "../icons";
import { type InfoPanelType } from "../info-panel/info-panel-header/InfoPanelHeader";
import { RenameItem } from "../rename-item/RenameItem";
import styles from "./ReportView.module.scss";

type ReportViewContextType = {
  collapseSidePanel: boolean;
  setCollapseSidePanel: Dispatch<SetStateAction<boolean>>;
  setShowInfoPanel: Dispatch<SetStateAction<boolean>>;
  showInfoPanel: boolean;
};

type ReportLayoutProps = {
  variant: ReportViewLayoutVariant;
};

export enum ReportViewLayoutVariant {
  AdvancedReporting = "advanced-reporting",
  FastReporting = "fast-reporting",
  RangePerformance = "range-performance",
}

export const ReportViewContext = createContext<ReportViewContextType>({
  collapseSidePanel: false,
  setCollapseSidePanel: () => {},
  setShowInfoPanel: () => {},
  showInfoPanel: false,
});

export const ReportView = ({ children }: PropsWithChildren) => {
  const [showInfoPanel, setShowInfoPanel] = useState<boolean>(false);
  const [collapseSidePanel, setCollapseSidePanel] = useState<boolean>(false);

  const reportViewElement = useMemo(
    () => (
      <ReportViewContext.Provider
        value={{
          collapseSidePanel,
          setCollapseSidePanel,
          setShowInfoPanel,
          showInfoPanel,
        }}
      >
        {children}
      </ReportViewContext.Provider>
    ),
    [
      children,
      collapseSidePanel,
      setCollapseSidePanel,
      showInfoPanel,
      setShowInfoPanel,
    ]
  );

  return reportViewElement;
};

const ReportLayout = ({
  children,
  variant,
}: PropsWithChildren<ReportLayoutProps>) => (
  <div className={classNames(styles.reportContainer, variant)}>
    {children}
    <div className={styles.stickyFooterContainer}>
      <AppFooter />
    </div>
  </div>
);

type ReportHeaderType = {
  renameItem: (newItemName: string, itemId: string) => Promise<void>;
  reportId: string;
  reportName: string;
  reportType: string;
  triggerLazyReload?: () => Promise<void>;
};

const ReportHeaderPanel = ({ children }: PropsWithChildren) => (
  <div className={styles.reportHeaderWrapper}>{children}</div>
);

const ReportHeader = ({
  renameItem,
  reportType,
  reportId,
  reportName,
  triggerLazyReload,
}: ReportHeaderType) => {
  const setShowInfoPanel = useContext(ReportViewContext).setShowInfoPanel;
  const { handleRename } = RenameItem({
    itemType: "Report",
    renameItem,
    triggerLazyReload,
  });

  return (
    <ReportHeaderPanel>
      <div className={styles.reportHeader}>
        <div className={styles.gridReportNameSection}>
          <div>
            <ReportIcon type={reportType} />
          </div>
          <EditableField
            className={styles.title}
            save={async (value: string) => {
              const data = await handleRename(value, reportId).then(
                () => true,
                () => false
              );
              return data;
            }}
            value={reportName}
          />

          <div>
            <Button
              height={ButtonHeight.Small}
              id={styles.summaryButton}
              onClick={() => setShowInfoPanel(true)}
              variant={ButtonVariant.Stealth}
            >
              <Icon
                glyph={IconGlyph.AlertsAndNotificationsInform}
                id={styles.summaryIcon}
                size={IconSize.Large}
                text="Report Summary"
              />
            </Button>
          </div>
        </div>
      </div>
    </ReportHeaderPanel>
  );
};

const ReportSidePanel = ({ children }: PropsWithChildren) => {
  const collapseSidePanel = useContext(ReportViewContext).collapseSidePanel;

  return (
    <div
      className={classNames(styles.sidebar, {
        [styles.collapsed]: collapseSidePanel,
        [styles.expanded]: !collapseSidePanel,
      })}
    >
      <div className={styles.sidebarContent}>{children}</div>
    </div>
  );
};

const ReportletPanel = ({ children }: PropsWithChildren) => (
  <div className={styles.mainContent}>
    <div className={styles.stickyContent}>{children}</div>
  </div>
);

type ReportInfoPanelProps = {
  infoPanelSummary: ReportParametersDto | undefined;
  infoPanelType: InfoPanelType;
  isInfoPanelSummaryLoading: boolean;
  renameItem: (newItemName: string, itemId: string) => Promise<void>;
};

const ReportInfoPanel = ({
  infoPanelSummary,
  infoPanelType,
  isInfoPanelSummaryLoading,
  renameItem,
}: ReportInfoPanelProps) => {
  const division = useDivision();
  const flags = useFlags();
  const { showInfoPanel, setShowInfoPanel } = useContext(ReportViewContext);

  const { data: organisationUsers } = useGetOrganizationUsersQuery(
    { divisionName: division.name },
    { skip: !division.name || !flags[FeatureFlag.SharingReports] }
  );

  const { data: sharingActivities, isLoading: isSharingActivitiesLoading } =
    useGetSharingActivitiesQuery(
      { divisionName: division.name, reportId: infoPanelSummary?.id ?? "" },
      {
        skip:
          !division.name ||
          !infoPanelSummary?.id ||
          !flags[FeatureFlag.SharingReports],
      }
    );

  const { data: reportSharedUsers, isLoading: isReportSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      { skip: !sharingActivities || !flags[FeatureFlag.SharingReports] }
    );

  return (
    <div className={styles.reportSummary}>
      <InfoPanel
        focalReport={infoPanelSummary}
        infoPanelType={infoPanelType}
        isLoading={
          isInfoPanelSummaryLoading ||
          isSharingActivitiesLoading ||
          isReportSharedUsersLoading
        }
        isOverlay
        onClose={() => setShowInfoPanel(false)}
        organisationUsers={organisationUsers}
        renameItem={renameItem}
        reportSharedUsers={reportSharedUsers}
        sharingActivities={sharingActivities}
        showPanel={showInfoPanel}
      />
    </div>
  );
};

ReportView.Layout = ReportLayout;
ReportView.Header = ReportHeader;
ReportView.HeaderPanel = ReportHeaderPanel;
ReportView.SidePanel = ReportSidePanel;
ReportView.ReportletPanel = ReportletPanel;
ReportView.InfoPanel = ReportInfoPanel;

export default ReportView;
