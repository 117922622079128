import {
  type HierarchyItem,
  type ParameterDto,
  HierarchyItemType,
  type TransactionSource,
  HierarchyType,
  type SelectionDto,
  isOrderedSelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type Item } from "components-ui/src/drag-and-drop/models/Item";
import { type Zone } from "components-ui/src/drag-and-drop/models/Zone";
import { type ParameterState } from "../../states/ParameterState";
import { type TransactionSourceState } from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";
import { getRerunDroppableStructureZones } from "./HierarchyUtilities";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface StructureState extends ParameterState {
  activeItem?: Item;
  droppableZones: Zone[];
  items: Item[];
}

export const isStructureState = (
  state: ParameterState | undefined
): state is StructureState =>
  state !== undefined &&
  Object.prototype.hasOwnProperty.call(state, "activeItem") &&
  Object.prototype.hasOwnProperty.call(state, "droppableZones") &&
  Object.prototype.hasOwnProperty.call(state, "items");

export const newStructureState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  dataSource?: TransactionSource,
  isDataEntitlementsShown?: boolean,
  savedSelections?: SelectionDto[]
): StructureState => {
  let initialZones: Zone[] = [];

  if (savedSelections) {
    const orderedSelections = savedSelections
      .map((savedSelection) => {
        const orderedSelection = isOrderedSelectionDto(savedSelection)
          ? savedSelection
          : undefined;
        return orderedSelection;
      })
      .filter((selection) => selection !== undefined);

    const selectionOptions = orderedSelections
      .map((orderedSelection) => {
        const selectionOption = parameterConfig.options.find(
          (option) =>
            orderedSelection !== undefined &&
            option.value === orderedSelection.shortName
        );
        return selectionOption;
      })
      .filter((selectionOption) => selectionOption !== undefined);

    const savedItems = selectionOptions.map((selectionOption) => {
      const option = parameterConfig.options.find(
        (item) => item.value === selectionOption?.value
      );
      let ordinal = 0;
      if (option) {
        ordinal = parameterConfig.options.indexOf(option);
      }

      return {
        id: selectionOption ? selectionOption.value : "",
        name: selectionOption ? selectionOption.label : "",
        ordinal,
        shortName: selectionOption ? selectionOption.value : "",
        type: HierarchyItemType.Hierarchy,
        dataSource: isDataEntitlementsShown ? dataSource : null,
      } as Item;
    });

    initialZones = savedItems.map(
      (savedItem, index) =>
        ({
          accepts: [HierarchyItemType.Hierarchy],
          id: index.toString(),
          item: savedItem,
        } as Zone)
    );
  } else if (
    parameterConfig.hierarchyType === HierarchyType.Location &&
    parameterConfig.options.length > 0
  ) {
    const intialItem = {
      count: 1,
      id: parameterConfig.options[0].value,
      name: parameterConfig.options[0].label,
      ordinal: 0,
      shortName: parameterConfig.options[0].value,
      type: HierarchyItemType.Hierarchy,
      dataSource: isDataEntitlementsShown ? dataSource : null,
    } as Item;
    initialZones = [
      {
        accepts: [HierarchyItemType.Hierarchy],
        id: "1",
        item: intialItem,
      },
    ];
  }

  return {
    activeItem: undefined,
    droppableZones: initialZones,
    isValid: isBetweenMinMax(initialZones.length, parameterConfig),
    isWarning:
      !savedSelections &&
      parameterConfig.hierarchyType === HierarchyType.Product,
    items: [],
    parameterConfig,
    parameterGroup,
  };
};

export const getStructureState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as StructureState | undefined
  );

export const handleStructureItemSelection = (
  parameterState: ParameterState,
  actionItem?: Item
): ParameterState => {
  if (isStructureState(parameterState)) {
    parameterState.activeItem = actionItem;
    return {
      ...parameterState,
    } as ParameterState;
  }

  return { ...parameterState, isParameterMissing: false };
};

export const handleDroppableZonesSelection = (
  parameterState: ParameterState,
  selectedZones: Zone[],
  leafShortName?: string,
  selectedRows?: HierarchyItem[],
  isDataEntitlementsShown?: boolean,
  transactionSources?: TransactionSourceState[],
  isRerun?: boolean
): ParameterState => {
  if (isStructureState(parameterState)) {
    const droppableZones = getRerunDroppableStructureZones(
      parameterState.parameterConfig,
      selectedZones,
      leafShortName,
      selectedRows,
      isDataEntitlementsShown,
      transactionSources,
      isRerun
    );
    parameterState.droppableZones = droppableZones;

    return {
      ...parameterState,
      isValid: isBetweenMinMax(
        selectedZones.length,
        parameterState.parameterConfig
      ),
      isParameterMissing: false,
    } as ParameterState;
  }

  return { ...parameterState };
};

export const handleItemsSelection = (
  parameterState: ParameterState,
  selectedItems: Item[]
): ParameterState => {
  if (isStructureState(parameterState)) {
    parameterState.items = selectedItems;
    return {
      ...parameterState,
    } as ParameterState;
  }

  return { ...parameterState };
};

export const checkStructureContainsAttribute = (state: StructureState) => {
  const attributeIndex = state.droppableZones.findIndex(
    (zone) => zone.item?.type === HierarchyItemType.Attribute
  );
  return attributeIndex !== -1;
};
