import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type ColumnSort } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { getDefaultSelections } from "../utils/local-parameter-utils";
import { GetAutoSelectedItems, InsertSorted } from "../utils/top-drawer-utils";
import { type TransformedRepertoireReportletResponse } from "./repertoire-reportlet-api-slice";
import { type RepertoireFocalDataResponse } from "./repertoire-top-drawer-api-slice";

const DEFAULT_THRESHOLD_STRING = "";
const DEFAULT_NUMBER_SELECTIONS = 10;
const MAX_NUMBER_SELECTIONS = 50;

const getPersistenceKey = (reportId: string) => `repertoire-${reportId}`;

export type RepertoireSidePanelSelections = {
  Channel: PanelOption;
  LocationHierarchy: LocalHierarchyNodeSelection;
  Metric: PanelOption;
  Segmentation: PanelOption[];
  Time: string;
  TimePeriod: string;
  ViewAs: PanelOption;
};

export type RepertoirePersistedSelections = {
  focalItems: string[];
  isTableAutoSelectValueInvalid: boolean;
  localSelections: RepertoireSidePanelSelections;
  repertoireItems: string[];
  tableAutoSelectValue: string;
};

export type RepertoireState = {
  focalItemTableInitialised: boolean;
  focalItemTableResponse: RepertoireFocalDataResponse;
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  metaData: {
    reportId: string;
    reportName: string;
  };
  persistedSelections: RepertoirePersistedSelections;
  persistedSelectionsLoaded: boolean;
  reportError: boolean;
  reportletResponse: TransformedRepertoireReportletResponse;
  showRepertoireChartDataLabels: boolean;
  tableSortValue: ColumnSort;
};

export const initialState: RepertoireState = {
  focalItemTableInitialised: false,
  localParameters: [],
  localParametersInitialised: false,
  metaData: {
    reportId: "",
    reportName: "",
  },
  persistedSelections: {
    focalItems: [],
    localSelections: {
      Channel: {
        label: "",
        value: "",
      },
      Metric: {
        label: "",
        value: "",
      },
      Segmentation: [
        {
          label: "",
          value: "",
        },
      ],
      ViewAs: {
        label: "",
        value: "",
      },
      Time: "",
      TimePeriod: "",
      LocationHierarchy: {
        code: "",
        nodeNumber: -1,
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        shortName: "",
      },
    },
    repertoireItems: [],
    tableAutoSelectValue: DEFAULT_THRESHOLD_STRING,
    isTableAutoSelectValueInvalid: false,
  },
  persistedSelectionsLoaded: false,
  reportError: false,
  focalItemTableResponse: {
    focalItemData: [],
    levelOfAnalysis: "",
    metric: { name: "", format: "Percentage" },
  },
  reportletResponse: {
    crossProductData: {},
    crossProductEntries: [],
    focalItemColumns: [],
    metricFormat: "Percentage",
    otherValues: { data: [], format: "Percentage" },
    totalValues: { data: [], format: "Percentage" },
  },
  tableSortValue: { id: "", desc: true },
  showRepertoireChartDataLabels: false,
};

export const RepertoireSlice = createSlice({
  initialState,
  name: "repertoire",
  reducers: {
    onReportError: (state: RepertoireState) => {
      state.reportError = true;
    },
    onLocalParametersReceived: (
      state: RepertoireState,
      { payload }: PayloadAction<SidePanelParameter[]>
    ) => {
      state.localParameters = payload;
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localSelections =
          getDefaultSelections(payload);

        persistSelections(
          getPersistenceKey(state.metaData.reportId),
          state.persistedSelections
        );
      }

      state.localParametersInitialised = true;
    },
    onMetadataSuccess: (
      state: RepertoireState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.metaData = payload;
    },
    onChannelChange: (
      state: RepertoireState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.Channel = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onMetricChange: (
      state: RepertoireState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.Metric = action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: RepertoireState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localSelections.Segmentation = [
        {
          label: payload.segmentationLabel,
          value: payload.segmentationValue,
        },
        {
          label: payload.segmentLabel,
          value: payload.segmentValue,
        },
      ];

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onViewAsChange: (
      state: RepertoireState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.ViewAs = action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onFocalItemSelect: (
      state: RepertoireState,
      { payload: value }: PayloadAction<string>
    ) => {
      state.persistedSelections.tableAutoSelectValue = "";

      // If item does not exist in the focal items add item into list
      // If it also does not exist in the Repertoire list add it into Repertoire list
      // Otherwise remove from the focal item list
      // Also remove from the repertoire

      state.persistedSelections.isTableAutoSelectValueInvalid = false;

      if (state.persistedSelections.focalItems.includes(value)) {
        state.persistedSelections.focalItems =
          state.persistedSelections.focalItems.filter((item) => item !== value);

        state.persistedSelections.repertoireItems =
          state.persistedSelections.repertoireItems.filter(
            (item) => item !== value
          );
        persistSelections(
          getPersistenceKey(state.metaData.reportId),
          state.persistedSelections
        );
        return;
      }

      state.persistedSelections.focalItems = InsertSorted(
        [...state.persistedSelections.focalItems, value],
        state.focalItemTableResponse.focalItemData
      );

      if (!state.persistedSelections.repertoireItems.includes(value)) {
        state.persistedSelections.repertoireItems = InsertSorted(
          [...state.persistedSelections.repertoireItems, value],
          state.focalItemTableResponse.focalItemData
        );
      }

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onRepertoireSelect: (
      state: RepertoireState,
      { payload: value }: PayloadAction<string>
    ) => {
      // If the value does not exist add it to the repertoire list
      // Else remove it from the repertoire list

      if (state.persistedSelections.repertoireItems.includes(value)) {
        state.persistedSelections.repertoireItems =
          state.persistedSelections.repertoireItems.filter(
            (item) => item !== value
          );
        persistSelections(
          getPersistenceKey(state.metaData.reportId),
          state.persistedSelections
        );
        return;
      }

      state.persistedSelections.repertoireItems = InsertSorted(
        [...state.persistedSelections.repertoireItems, value],
        state.focalItemTableResponse.focalItemData
      );

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onTableDataReceived: (
      state: RepertoireState,
      { payload: response }: PayloadAction<RepertoireFocalDataResponse>
    ) => {
      state.focalItemTableResponse = response;

      const autoSelectedItems = GetAutoSelectedItems(
        state.persistedSelections.tableAutoSelectValue,
        DEFAULT_NUMBER_SELECTIONS,
        state.focalItemTableResponse.focalItemData
      );

      state.persistedSelections.isTableAutoSelectValueInvalid =
        autoSelectedItems.length > MAX_NUMBER_SELECTIONS;
      state.persistedSelections.focalItems = autoSelectedItems.slice(
        0,
        MAX_NUMBER_SELECTIONS
      );
      state.persistedSelections.repertoireItems = autoSelectedItems.slice(
        0,
        MAX_NUMBER_SELECTIONS
      );

      state.focalItemTableInitialised = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onTableSortingChange: (
      state: RepertoireState,
      { payload }: PayloadAction<ColumnSort>
    ) => {
      state.tableSortValue = payload;
    },
    onInputValueChange: (
      state: RepertoireState,
      { payload: value }: PayloadAction<string>
    ) => {
      state.persistedSelections.tableAutoSelectValue = value;

      if (value) {
        const autoSelectedItems = GetAutoSelectedItems(
          value,
          DEFAULT_NUMBER_SELECTIONS,
          state.focalItemTableResponse.focalItemData
        );

        state.persistedSelections.isTableAutoSelectValueInvalid =
          autoSelectedItems.length > MAX_NUMBER_SELECTIONS;
        state.persistedSelections.focalItems = autoSelectedItems.slice(
          0,
          MAX_NUMBER_SELECTIONS
        );
        state.persistedSelections.repertoireItems = autoSelectedItems.slice(
          0,
          MAX_NUMBER_SELECTIONS
        );
      }

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onReportletDataReceived: (
      state: RepertoireState,
      { payload }: PayloadAction<TransformedRepertoireReportletResponse>
    ) => {
      state.reportletResponse = payload;
    },
    onLocationChange: (
      state: RepertoireState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localSelections.LocationHierarchy =
        action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    toggleRepertoireChartDataLabels: (state: RepertoireState) => {
      state.showRepertoireChartDataLabels =
        !state.showRepertoireChartDataLabels;
    },
    onReportOpen: (
      state: RepertoireState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          metaData: {
            ...initialState.metaData,
            reportId: action.payload.reportId,
          },
        };
      }

      const persistedSelections: RepertoirePersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          metaData: {
            ...initialState.metaData,
            reportId: action.payload.reportId,
          },
        };
      }

      return {
        ...initialState,
        metaData: {
          ...initialState.metaData,
          reportId: action.payload.reportId,
        },
        persistedSelections,
        persistedSelectionsLoaded: true,
      };
    },
    reset: () => initialState,
  },
});

export const {
  onLocalParametersReceived,
  onMetadataSuccess,
  onChannelChange,
  onMetricChange,
  onSegmentationChange,
  onViewAsChange,
  onReportError,
  onReportOpen,
  onFocalItemSelect,
  onRepertoireSelect,
  onTableDataReceived,
  onInputValueChange,
  onReportletDataReceived,
  onTableSortingChange,
  onLocationChange,
  reset,
  toggleRepertoireChartDataLabels,
} = RepertoireSlice.actions;

export const selectTableResponseDto = createSelector(
  (state: RootState) => state.repertoire.focalItemTableResponse,
  (focalItemTableResponse) => focalItemTableResponse
);

export const selectFocalItems = createSelector(
  (state: RootState) => state.repertoire.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectRepertoireItems = createSelector(
  (state: RootState) => state.repertoire.persistedSelections.repertoireItems,
  (repertoireItems) => repertoireItems
);

export const selectLocalParameters = createSelector(
  (state: RootState) => ({
    localParametersInitialised: state.repertoire.localParametersInitialised,
    localParameterSelections:
      state.repertoire.persistedSelections.localSelections,
  }),
  (localParameters) => localParameters
);

export const selectLocalParametersDto = createSelector(
  (state: RootState) => state.repertoire.localParameters,
  (localParameters) => localParameters
);

export const autoSelectValue = createSelector(
  (state: RootState) =>
    state.repertoire.persistedSelections.tableAutoSelectValue,
  (tableAutoSelectValue) => tableAutoSelectValue
);

export const selectIsAutoSelectValueInvalid = createSelector(
  (state: RootState) =>
    state.repertoire.persistedSelections.isTableAutoSelectValueInvalid,
  (isAutoSelectValueInvalid) => isAutoSelectValueInvalid
);

export const focalItemTableInitialised = createSelector(
  (state: RootState) => state.repertoire.focalItemTableInitialised,
  (selectedFocalItemTableInitialised) => selectedFocalItemTableInitialised
);

export const selectReportletResponseDto = createSelector(
  (state: RootState) => state.repertoire.reportletResponse,
  (reportletResponse) => reportletResponse
);

export const selectedLocalParameterMetric = createSelector(
  (state: RootState) =>
    state.repertoire.persistedSelections.localSelections.Metric,
  (metric) => metric
);

export const selectLocalParameterViewAs = createSelector(
  (state: RootState) =>
    state.repertoire.persistedSelections.localSelections.ViewAs,
  (viewAs) => viewAs
);

export const selectedTableSorting = createSelector(
  (state: RootState) => state.repertoire.tableSortValue,
  (tableSortValue) => tableSortValue
);

export const selectShowRepertoireChartDataLabels = createSelector(
  (state: RootState) => state.repertoire.showRepertoireChartDataLabels,
  (showRepertoireChartDataLabels) => showRepertoireChartDataLabels
);

export const selectMetaData = createSelector(
  (state: RootState) => state.repertoire.metaData,
  (metaData) => metaData
);

export default RepertoireSlice.reducer;
