import { Provider } from "react-redux";
import SearchModal from "./SearchModal";
import { store } from "./store";

export const FastReportingSearchModal = () => (
  <Provider store={store}>
    <SearchModal />
  </Provider>
);

export default FastReportingSearchModal;
