import {
  type LocalSelectedValueDto,
  ddLog,
} from "@quantium-enterprise/common-ui";
import {
  type Dictionary,
  type EntityId,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { repertoireApiSlice } from "./repertoire-api-slice";
import { onReportletDataReceived } from "./repertoire-slice";

export type RepertoireReportletItem = {
  id: string;
  isSelected: boolean;
  name: string;
  values: Array<number | null>;
};

export type RepertoireReportletProductData = {
  data: RepertoireReportletItem[];
  format: string;
};

export type RepertoireReportletInferredData = {
  data: Array<number | null>;
  format: string;
};

type RepertoireReportletDataResponse = {
  focalItems: string[];
  other: RepertoireReportletInferredData;
  productData: RepertoireReportletProductData;
  total: RepertoireReportletInferredData;
};

export type RepertoireReportletDataRequest = {
  focalItems: string[];
  localSelectedValues: LocalSelectedValueDto[];
  repertoireItems: string[];
  reportId: string;
};

export type TransformedRepertoireReportletResponse = {
  crossProductData: Dictionary<RepertoireReportletItem>;
  crossProductEntries: EntityId[];
  focalItemColumns: string[];
  metricFormat: string;
  otherValues?: RepertoireReportletInferredData;
  totalValues: RepertoireReportletInferredData;
};

const rowsAdapter = createEntityAdapter({
  selectId: (row: RepertoireReportletItem) => row.id,
});

const initialState = rowsAdapter.getInitialState();

export const repertoireReportletApiSlice = repertoireApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRepertoireReportletData: builder.query<
      TransformedRepertoireReportletResponse,
      { divisionName: string; requestPayload: RepertoireReportletDataRequest }
    >({
      async onQueryStarted(argument, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(onReportletDataReceived(data));
        } catch (error_) {
          let error: Error | undefined;
          if (error_ instanceof Error) {
            error = error_;
          }

          ddLog("Error retrieving reportlet data", {}, "error", error);
        }
      },
      query: ({ divisionName, requestPayload }) => ({
        body: requestPayload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `GetReportletData/${divisionName}`,
      }),
      transformResponse: (rawResult: RepertoireReportletDataResponse) => {
        const transformed = rowsAdapter.setAll(
          initialState,
          rawResult.productData.data
        );

        return {
          crossProductEntries: transformed.ids,
          crossProductData: transformed.entities,
          focalItemColumns: rawResult.focalItems,
          metricFormat: rawResult.productData.format,
          totalValues: rawResult.total,
          otherValues: rawResult.other,
        };
      },
    }),
  }),
});

export const { useLazyGetRepertoireReportletDataQuery } =
  repertoireReportletApiSlice;
