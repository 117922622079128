import { FormInputHeight } from "@quantium-enterprise/qds-react";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../../store";
import { type OverTimeChartResponse } from "../../models/basket-quantities-over-time-response-dto";
import {
  initialState,
  isBreakdownByTotal,
  onOverTimeBasketSizeChange,
  onOverTimePrimaryMetricChange,
  onOverTimeSecondaryMetricChange,
} from "../../services/basket-quantities-slice";
import styles from "./BasketQuantitiesOverTimeMetrics.module.css";

const createPanelOption = (value: string, label: string = value) => ({
  value,
  label,
});

type BasketQuantitiesOverTimeReportletMetricDropdownsProps = {
  overTimeResponse?: OverTimeChartResponse;
};

export const BasketQuantitiesOverTimeReportletMetricDropdowns = ({
  overTimeResponse: response,
}: BasketQuantitiesOverTimeReportletMetricDropdownsProps) => {
  const dispatch = useDispatch();

  const defaultBasketSize = createPanelOption(
    initialState.overTimeBasketSizeSelection
  );
  const defaultSecondaryMetric = createPanelOption(
    initialState.overTimeSecondaryMetric,
    "No secondary metric selected"
  );

  const isBasketSizeDisabled = useSelector(isBreakdownByTotal);
  const isOverTimeSecondaryMetricDisabled = !isBasketSizeDisabled;

  const {
    overTimeBasketSizeSelection,
    overTimePrimaryMetric,
    overTimeSecondaryMetric,
  } = useSelector((state: RootState) => state.basketQuantities);

  const basketSizeOptions =
    response?.basketSeries
      .filter((item) => item.basketSize !== defaultBasketSize.label)
      .map((item) => createPanelOption(item.basketSize)) ?? [];

  // Opting for a hardcoded approach to metric label options due to:
  // (a) they are static between report runs, and
  // (b) extracting them from OverTimeChartResponse results in much less readable code
  const overTimeMetrics = [
    "Baskets",
    "Sales",
    "Units",
    "Spend per basket",
    "Average unit price",
  ].map((x) => createPanelOption(x));
  const primaryMetricOptions = [...overTimeMetrics].filter(
    (x) => x.label !== overTimeSecondaryMetric
  );
  const secondaryMetricOptions = [...overTimeMetrics].filter(
    (x) => x.label !== overTimePrimaryMetric
  );

  const basketSizeSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onOverTimeBasketSizeChange(event.value as string));
    },
    [dispatch]
  );

  const primaryMetricSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onOverTimePrimaryMetricChange(event.value as string));
    },
    [dispatch]
  );

  const secondaryMetricSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onOverTimeSecondaryMetricChange(event.value as string));
    },
    [dispatch]
  );

  return (
    <div className={styles.overTimeMetricsGrid}>
      <div
        className={
          isBasketSizeDisabled
            ? styles.basketSizeTitleDisabled
            : styles.basketSizeTitle
        }
      >
        Basket size
      </div>
      <div className={styles.primaryMetricTitle}>Primary metric</div>
      <div
        className={
          isOverTimeSecondaryMetricDisabled
            ? styles.secondaryMetricTitleDisabled
            : styles.secondaryMetricTitle
        }
      >
        Secondary metric
      </div>
      <div className={styles.basketSizeDropdown}>
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          isDisabled={isBasketSizeDisabled}
          onSelection={basketSizeSelectionHandler}
          selectOptions={
            isBasketSizeDisabled
              ? [defaultBasketSize]
              : [defaultBasketSize].concat(basketSizeOptions)
          }
          selectedValue={
            isBasketSizeDisabled
              ? defaultBasketSize.label
              : overTimeBasketSizeSelection
          }
          title="Basket size"
        />
      </div>
      <div className={styles.primaryMetricDropdown}>
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          onSelection={primaryMetricSelectionHandler}
          selectOptions={primaryMetricOptions}
          selectedValue={overTimePrimaryMetric}
          title="Primary metric"
        />
      </div>
      <div className={styles.secondaryMetricDropdown}>
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          isDisabled={isOverTimeSecondaryMetricDisabled}
          onSelection={secondaryMetricSelectionHandler}
          selectOptions={[defaultSecondaryMetric].concat(
            secondaryMetricOptions
          )}
          selectedValue={
            isOverTimeSecondaryMetricDisabled
              ? defaultSecondaryMetric.label
              : overTimeSecondaryMetric
          }
          title="Secondary metric"
        />
      </div>
    </div>
  );
};
