import styles from "./TooltipIcon.module.css";

export enum TooltipShapes {
  circle = "circle",
  diamond = "diamond",
  square = "square",
  triangle = "triangle",
  triangleDown = "triangle-down",
}

const tooltipCircle = (color: string | undefined) => (
  <div className={styles.circle} style={{ backgroundColor: color }} />
);

const tooltipDiamond = (color: string | undefined) => (
  <div className={styles.diamond} style={{ backgroundColor: color }} />
);

const tooltipSquare = (color: string | undefined) => (
  <div className={styles.square} style={{ backgroundColor: color }} />
);

const tooltipTriangle = (color: string | undefined) => (
  <div className={styles.triangleUp} style={{ borderBottomColor: color }} />
);

const tooltipTriangleDown = (color: string | undefined) => (
  <div className={styles.triangleDown} style={{ borderTopColor: color }} />
);

export type ToolTipIconProps = {
  color: string | undefined;
  shape: string | undefined;
};

export const TooltipIcon = ({ color, shape }: ToolTipIconProps) => {
  switch (shape) {
    case TooltipShapes.circle:
      return tooltipCircle(color);

    case TooltipShapes.diamond:
      return tooltipDiamond(color);

    case TooltipShapes.square:
      return tooltipSquare(color);

    case TooltipShapes.triangle:
      return tooltipTriangle(color);

    case TooltipShapes.triangleDown:
      return tooltipTriangleDown(color);

    default:
      return tooltipCircle(color);
  }
};
