import { type ActivityType } from "../enums";

export type SharingActivity = {
  activityType: ActivityType;
  salesforceUserIds: string[];
  sharedDate: string;
  sharingNotes?: string;
};

export const isSharingActivity = (
  object: Object | undefined
): object is SharingActivity =>
  object !== undefined && Object.hasOwn(object, "sharedDate");
