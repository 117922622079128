export const Transaction = ({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2733 8.21998L8.27331 2.21998C8.03331 1.97998 7.69998 1.83331 7.33331 1.83331H2.66665C1.93331 1.83331 1.33331 2.43331 1.33331 3.16665V7.83331C1.33331 8.19998 1.47998 8.53331 1.72665 8.77998L7.72665 14.78C7.96665 15.02 8.29998 15.1666 8.66665 15.1666C9.03331 15.1666 9.36665 15.02 9.60665 14.7733L14.2733 10.1066C14.52 9.86665 14.6666 9.53331 14.6666 9.16665C14.6666 8.79998 14.5133 8.45998 14.2733 8.21998ZM8.66665 13.84L2.66665 7.83331V3.16665H7.33331V3.15998L13.3333 9.15998L8.66665 13.84Z"
      fill="#B5B5B5"
    />
    <path
      d="M4.33331 5.83331C4.8856 5.83331 5.33331 5.3856 5.33331 4.83331C5.33331 4.28103 4.8856 3.83331 4.33331 3.83331C3.78103 3.83331 3.33331 4.28103 3.33331 4.83331C3.33331 5.3856 3.78103 5.83331 4.33331 5.83331Z"
      fill="#B5B5B5"
    />
  </svg>
);
