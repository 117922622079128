import { hierarchyServiceApi, userApi } from "@quantium-enterprise/common-ui";
import { SearchConfigurationApi } from "@quantium-enterprise/fast-reporting-ui";
import {
  type PreloadedState,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import { dashboardServiceApi } from "./services/DashboardService";
import dashboardReducer from "./states/dashboard-slice";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [hierarchyServiceApi.reducerPath]: hierarchyServiceApi.reducer,
  [dashboardServiceApi.reducerPath]: dashboardServiceApi.reducer,
  [SearchConfigurationApi.reducerPath]: SearchConfigurationApi.reducer,
  dashboard: dashboardReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "dashboard" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        userApi.middleware,
        hierarchyServiceApi.middleware,
        dashboardServiceApi.middleware,
        SearchConfigurationApi.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
