import { Icon, IconGlyph } from "@quantium-enterprise/qds-react/dist/Icon";
import {
  Module,
  ModuleVariant,
} from "@quantium-enterprise/qds-react/dist/Module";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import classNames from "classnames";
import { type PropsWithChildren, useState } from "react";
import styles from "./Expander.module.css";

export type ExpanderProps = {
  className?: string;
  expanderState?: {
    isExpanderOpen: boolean;
    toggleOpen: (open: boolean) => void;
  };
  title: JSX.Element | string;
};

export const Expander = ({
  title,
  children,
  expanderState,
  className,
}: PropsWithChildren<ExpanderProps>) => {
  // This way we can use either local state or manage state from parent component(s)
  // Depending on how we go on the review I can look into abstracting this pattern to other components
  // NOTE: Open to naming suggestions
  const [isOpen, setIsOpen] = useState(
    expanderState ? expanderState.isExpanderOpen : false
  );
  const isOpenLocal = expanderState ? expanderState.isExpanderOpen : isOpen;
  const toggleOpenLocal = expanderState ? expanderState.toggleOpen : setIsOpen;

  return (
    <Module
      className={classNames(styles.expander, className, {
        [styles.expanded]: isOpenLocal === true,
      })}
      content={
        <div className={classNames(styles.expanderContent)}>{children}</div>
      }
      header={
        <div
          className={styles.expanderHeader}
          onClick={() => toggleOpenLocal(!isOpenLocal)}
          onKeyUp={() => toggleOpenLocal(!isOpenLocal)}
          role="button"
          tabIndex={0}
        >
          <Icon
            colour={tokens.colour["text-primary"]}
            glyph={
              isOpenLocal
                ? IconGlyph.ArrowsChevronDown
                : IconGlyph.ArrowsChevronRight
            }
            text={isOpenLocal ? "Collapse" : "Expand"}
          />
          <h6 className={styles.expanderTitle}>{title}</h6>
        </div>
      }
      variant={ModuleVariant.Default}
    />
  );
};
