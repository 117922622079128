type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const CompareMetricsIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Compare metrics report"}</title>
      <g clipPath="url(#clip0_1991_122576)">
        <path
          d="M3.21656 14.5245C2.67904 14.4035 2.14534 14.7418 2.02451 15.2802C1.90367 15.8186 2.24145 16.3531 2.77897 16.4741L10.5618 18.2265L7.22241 21.5737C7.06539 21.731 7.17668 22 7.39881 22H15.9142C16.1363 22 16.2476 21.731 16.0906 21.5737L13.3856 18.8624L20.7834 20.5281C21.321 20.6491 21.8547 20.3108 21.9755 19.7724C22.0963 19.234 21.7585 18.6995 21.221 18.5785L3.21656 14.5245Z"
          fill={colour2}
        />
        <path
          d="M2.99988 4C2.99988 3.86193 3.11181 3.75 3.24988 3.75H8.08238C8.22045 3.75 8.33238 3.86193 8.33238 4V6H2.99988V4Z"
          fill={colour1}
        />
        <path
          d="M8.33238 13.6988C8.33238 13.8553 8.19015 13.9733 8.0363 13.9445L3.2038 13.0382C3.08556 13.0161 2.99988 12.9128 2.99988 12.7925L2.99988 8.25L8.33238 8.25L8.33238 13.6988Z"
          fill={colour3}
        />
        <path
          d="M8.33238 8.25L2.99988 8.25L2.99988 6H8.33238L8.33238 8.25Z"
          fill={colour2}
        />
        <path
          d="M15.675 2.25C15.675 2.11193 15.7869 2 15.925 2H20.7575C20.8956 2 21.0075 2.11193 21.0075 2.25V8.75H15.675V2.25Z"
          fill={colour3}
        />
        <path
          d="M21.0075 12.25H15.675L15.675 8.75H21.0075L21.0075 12.25Z"
          fill={colour2}
        />
        <path
          d="M21.0075 16.1988C21.0075 16.3553 20.8653 16.4733 20.7114 16.4445L15.8789 15.5382C15.7607 15.5161 15.675 15.4128 15.675 15.2925L15.675 12.25H21.0075V16.1988Z"
          fill={colour1}
        />
      </g>
      <defs>
        <clipPath id="clip0_1991_122576">
          <rect
            fill="white"
            height="20"
            transform="translate(2 2)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
