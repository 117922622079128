import { Text } from "@quantium-enterprise/qds-react";

export type BufferSelectionSummaryProps = {
  buffer: number | undefined;
};

export const BufferSelectionSummary = ({
  buffer,
}: BufferSelectionSummaryProps) =>
  buffer === undefined ? null : (
    <div>
      <Text>{`Buffer: ${String(buffer)}%`}</Text>
    </div>
  );
