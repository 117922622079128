import { type ParameterId } from "@quantium-enterprise/common-ui";
import {
  Tooltip,
  Icon,
  IconGlyph,
  IconSize,
  TooltipVariant,
  TooltipPlacement,
  TooltipSpaceInside,
} from "@quantium-enterprise/qds-react";
import styles from "./LocalParametersTooltip.module.css";

export type LocalParametersTooltipProps = {
  label: string;
  parameterId: ParameterId;
};

export const LocalParametersTooltip = ({
  label,
  parameterId,
}: LocalParametersTooltipProps) => (
  <div className={styles.label}>
    <div>{label}</div>
    <Tooltip
      className={styles.tooltip}
      data-testid="local-parameters-tooltip"
      id="local-parameters-tooltip"
      placement={TooltipPlacement.RightBottom}
      spaceInside={TooltipSpaceInside.Medium}
      trigger={
        <Icon
          className={styles.infoIcon}
          data-testid="tooltip-icon-trigger"
          glyph={IconGlyph.AlertsAndNotificationsInform}
          size={IconSize.Small}
          text=""
        />
      }
      variant={TooltipVariant.ArrowDark}
    >
      <div className={styles.tooltipTitle}>Total (aggregated)</div>
      <div className={styles.tooltipContent}>
        Includes all {parameterId.toLocaleLowerCase()} values
      </div>
    </Tooltip>
  </div>
);
