import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import {
  type TooltipFormatterContextObject,
  type GradientColorObject,
  type PatternObject,
  type YAxisOptions,
  type SeriesLineOptions,
  type SeriesOptionsType,
} from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import {
  defaultOptions,
  HighchartsReact,
  TooltipHTML,
} from "../highcharts-react/HighchartsReact";
import { type Metric, type ChartProps, type Series } from "../models";

const DEFAULT_VALUE_STRING: string[] = [];
const DEFAULT_VALUE_SERIES: Series[] = [];
const DEFAULT_VALUE_SERIES_WITH_OPTIONS: SeriesLineOptions[] = [];

type PerformanceTrendChartProps = ChartProps & {
  legendEnabled?: boolean;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  primaryMetricUIName?: string;
  secondaryMetricUIName?: string;
  series: Series[];
  seriesWithOptions: SeriesLineOptions[];
  tooltipFormatter?: (
    point: TooltipFormatterContextObject,
    ...args: any[]
  ) => JSX.Element;
  tooltipPrimaryHeader?: string;
  tooltipSecondaryHeader?: string;
};

export const getColorFromDataPoints = (
  index: number,
  points?: TooltipFormatterContextObject[]
): GradientColorObject | PatternObject | string | null | undefined =>
  index >= 0 && points && index < points.length && points[index]?.color
    ? points[index].color
    : null;

export const getValueFromDataPoints = (
  index: number,
  points?: TooltipFormatterContextObject[]
): number | null | undefined =>
  index >= 0 && points && index < points.length && points[index]?.y
    ? points[index].y
    : null;

export const getLegendNameFromDataPoints = (
  index: number,
  points?: TooltipFormatterContextObject[]
): string =>
  index >= 0 && points && index < points.length && points[index]?.series.name
    ? points[index].series.name
    : "";

export const getLabelFromDataPoints = (
  series: Series[],
  index: number,
  points?: TooltipFormatterContextObject[],
  secondaryMetric?: Metric
) => {
  if (!points) {
    return "";
  }

  const legendName = getLegendNameFromDataPoints(index, points);

  return secondaryMetric
    ? `${legendName} - ${series[index].metric.label}`
    : legendName;
};

const baseTooltipFormatter = (
  data: TooltipFormatterContextObject,
  series: Series[],
  primaryMetric?: Metric,
  secondaryMetric?: Metric,
  tooltipPrimaryHeader?: string,
  tooltipSecondaryHeader?: string
) => {
  if (series.length === 0) {
    return TooltipHTML();
  }

  const tooltipData = series
    .map((item, index: number) => ({
      color: String(getColorFromDataPoints(index, data.points)),
      name: getLabelFromDataPoints(series, index, data.points, secondaryMetric),
      value: item.metric.formatter(getValueFromDataPoints(index, data.points)),
    }))
    .filter((tooltipItem) => tooltipItem.color !== "null");

  return tooltipPrimaryHeader
    ? TooltipHTML(
        tooltipData,
        tooltipPrimaryHeader,
        tooltipSecondaryHeader,
        data.x
      )
    : TooltipHTML(tooltipData, data.x);
};

const createYAxis = (
  isOpposite: boolean,
  metric?: Metric,
  metricUIName?: string
): YAxisOptions => {
  if (!metric) return {};

  return {
    id: metric.name,
    labels: {
      formatter({ value }: { value: number | string }) {
        return metric.formatter(value, false, "", true);
      },
      style: {
        color: "var(--qbit-colour-text-secondary)",
        fontSize: "0.75rem",
      },
    },
    plotLines: [
      {
        value: 0,
        color: "var(--qbit-colour-shade-6)",
        width: 2,
        zIndex: 1,
      },
    ],
    title: {
      style: {
        color: "var(--qbit-colour-text-primary)",
        fontWeight: "var(--qbit-font-weight-medium)",
      },
      text: metricUIName ?? metric.name,
    },
    allowDecimals: !(
      metric.format === MetricTypes.Integer ||
      metric.format === MetricTypes.SmallInteger
    ),
    opposite: isOpposite,
  };
};

const PerformanceTrendChart = ({
  dates = DEFAULT_VALUE_STRING,
  primaryMetric,
  primaryMetricUIName,
  series = DEFAULT_VALUE_SERIES,
  seriesWithOptions = DEFAULT_VALUE_SERIES_WITH_OPTIONS,
  secondaryMetric,
  secondaryMetricUIName,
  legendEnabled = true,
  tooltipPrimaryHeader,
  tooltipSecondaryHeader,
  tooltipFormatter,
  onOptionsChanged,
}: PerformanceTrendChartProps) => {
  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      plotOptions: {
        series: {
          // NOTE: disabling dataGrouping forces highcharts line chart to not auto merge the series as found in bug CO3-4540
          dataGrouping: {
            enabled: false,
          },
          label: {
            enabled: false,
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
      series: seriesWithOptions.map(
        (
          seriesOption: SeriesLineOptions,
          index: number
        ): SeriesOptionsType => ({
          ...seriesOption,
          visible: !hiddenSeries.includes(index),
        })
      ),
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (data) =>
          (tooltipFormatter ? tooltipFormatter : baseTooltipFormatter)(
            data,
            series,
            primaryMetric,
            secondaryMetric,
            tooltipPrimaryHeader,
            tooltipSecondaryHeader
          ),
      },
      xAxis: {
        categories: dates,
        crosshair: {
          color: "var(--qbit-colour-secondary)",
          dashStyle: "Dash",
          width: 1,
          zIndex: 1,
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        min: 0,
      },
      yAxis: [
        createYAxis(false, primaryMetric, primaryMetricUIName),
        createYAxis(true, secondaryMetric, secondaryMetricUIName),
      ].filter((item) => Object.keys(item).length > 0),
      legend: {
        align: "left",
        enabled: legendEnabled,
        itemStyle: {
          color: "var(--qbit-colour-text-secondary)",
          fontSize: "0.75rem",
          fontWeight: "var(--qbit-font-weight-regular)",
        },
        verticalAlign: "bottom",
      },
    }),
    [
      seriesWithOptions,
      dates,
      primaryMetric,
      primaryMetricUIName,
      secondaryMetric,
      secondaryMetricUIName,
      legendEnabled,
      legendItemClick,
      hiddenSeries,
      tooltipFormatter,
      series,
      tooltipPrimaryHeader,
      tooltipSecondaryHeader,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};

export { PerformanceTrendChart };
