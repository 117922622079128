import { ddLog } from "@quantium-enterprise/common-ui";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import {
  type ExportTableRequest,
  type FetchChildrenTableRequest,
  type FetchChildrenTableResponse,
  type InitialTableRequest,
  type InitialTableResponse,
  type RefetchTableRequest,
  type RefetchTableResponse,
} from "../models/compare-metrics-data-table-models";
import { compareMetricsApiSlice } from "./compare-metrics-api-slice";
import {
  onTableChildrenDataRecieved,
  onTableDataRecieved,
} from "./compare-metrics-slice";

export const compareMetricsDataTableApiSlice =
  compareMetricsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getChildrenTableData: builder.query<
        FetchChildrenTableResponse,
        { divisionName: string; payload: FetchChildrenTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableChildrenDataRecieved(data));
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTableChildren/${divisionName}`,
        }),
      }),
      getInitialTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: InitialTableRequest }
      >({
        async onQueryStarted(argument, { queryFulfilled }) {
          // `onStart` side-effect
          try {
            await queryFulfilled;
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetInitialTable/${divisionName}`,
        }),
      }),
      getTableData: builder.query<
        RefetchTableResponse,
        { divisionName: string; payload: RefetchTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataRecieved(data));
          } catch {
            // What should message and context be
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportTableData/${divisionName}`,
        }),
      }),
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportTableRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `compare metrics focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const {
  useLazyGetChildrenTableDataQuery,
  useGetInitialTableQuery,
  useLazyGetTableDataQuery,
  useLazyDownloadTableQuery,
} = compareMetricsDataTableApiSlice;
