import {
  type TransactionSource,
  type HierarchyServiceResponseDto,
  ddLog,
  type NodeExpansionRequestDto,
  type HierarchyItem,
  useLazyExpandQuery,
  HierarchyType,
  getHierarchyItemTransactionSources,
  getPreferredTransactionSource,
  useHierarchyMetadataQuery,
  ParameterId,
  useLazyBulkExpandQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  type Updater,
  type RowSelectionState,
  type Row,
  type HeaderContext,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import { type HierarchyGridItem } from "components-ui/src/hierarchy-select-grid/models/hierarchy";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import {
  type ContextMenuElements,
  type ContextMenuWrapper,
  ContextMenuType,
  type ContextMenuSelectOption,
} from "components-ui/src/tables/common/table-cell/ContextMenu";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHierarchyNodeExpanding,
  hierarchyNodeExpanded,
  selectIsDataEntitlementsShown,
  selectHierarchyItems,
  selectHierarchySelectedRows,
  selectHierarchyExpandedRows,
  selectHierarchyDisabledLevelShortNames,
  onHierarchyChildNodesReceived,
  onAddHierarchyNodeSelection,
  onRemoveHierarchyNodeSelection,
  onHierarchyNodeSelection,
  selectTransactionSources,
  onBulkHierarchyChildNodesReceived,
  selectMaxGroupSelectionsReached,
  selectHierarchyLevelLock,
  selectSearchString,
  selectUniverseRestrictedLevelShortName,
  selectHierarchyRootDepth,
  selectIsSelectedItemsShown,
  toggleShowSelectedItems,
  selectHierarchySelectedItemsFiltered,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { isDisabledByHierarchyLevelLimit } from "../utils/HierarchyParameterTableUtils";
import styles from "./ProductHierarchyParameterTable.module.css";
import { ProductHierarchyParameterTableHeader } from "./ProductHierarchyParameterTableHeader";

// pagination messes with select all, we're going all out on expansion
const PAGE_SIZE = 10_000;
const hierarchyType = HierarchyType.Product;
const parameterType = ParameterId.ProductHierarchy;
const MAX_SELECTION_LEVELS = 2;

/*
 * Tanstack table has a built-in row.getIsAllSubRowsSelected() method,
 * however, this method has a known bug (https://github.com/TanStack/table/issues/4878)
 * hence we're using our own extention.
 */
const areAllSubrowsSelected = (row: Row<ProductHierarchyParameterItem>) =>
  row.subRows.length > 0 && row.subRows.every((rw) => rw.getIsSelected());

const isSecondLevelChecked = (row: Row<ProductHierarchyParameterItem>) =>
  row.subRows.length > 0 &&
  row.subRows.every((sr) => areAllSubrowsSelected(sr));

export type ProductHierarchyParameterItem = HierarchyItem & {
  contextMenuWrapper?: ContextMenuWrapper;
  isExpanding?: boolean;
  isMoreRow?: boolean;
  loadMoreParentRow?: ProductHierarchyParameterItem;
  parentRow?: ProductHierarchyParameterItem;
  subRows?: ProductHierarchyParameterItem[];
};

export type ProductHierarchyParameterTableProps = {
  contextMenuType: ContextMenuType;
  isSuccess: boolean;
};

export const ProductHierarchyParameterTable = ({
  isSuccess,
  contextMenuType,
}: ProductHierarchyParameterTableProps) => {
  const dispatch = useDispatch();
  const {
    name: activeDivisionName,
    transactionSources: availableTransactionSources,
  } = useDivision();
  const { data: productHierarchyMetadata } = useHierarchyMetadataQuery(
    {
      division: activeDivisionName,
      hierarchyType: "Product",
      getAllAttributes: true,
    },
    { skip: !activeDivisionName }
  );

  const numberOfHierarchyLevels = useMemo(
    () =>
      productHierarchyMetadata?.filter(
        (meta) => meta.structureName === "Product"
      ).length ?? 0,
    [productHierarchyMetadata]
  );

  const rootDepth = useSelector((state: RootState) =>
    selectHierarchyRootDepth(parameterType, state)
  );

  const maxDepth = useMemo(
    () => rootDepth + numberOfHierarchyLevels,
    [rootDepth, numberOfHierarchyLevels]
  );

  const getHierarchicalLevelsForContextMenu = useCallback(
    (shortCode: string) => {
      if (productHierarchyMetadata) {
        const hierarchicalProductHierarchyMetadata =
          productHierarchyMetadata.filter(
            (meta) => meta.structureName === "Product"
          );
        const index = hierarchicalProductHierarchyMetadata.findIndex(
          (meta) => meta.shortName === shortCode
        );
        return hierarchicalProductHierarchyMetadata.slice(index + 1, index + 3);
      } else {
        return [];
      }
    },
    [productHierarchyMetadata]
  );

  const [activeContextMenu, setActiveContextMenu] = useState("");
  const [contextMenuTimeout, setContextMenuTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  // Used for tooltip context menu
  const [autoSelectedRows, setAutoSelectedRows] = useState<HierarchyGridItem[]>(
    []
  );

  const [triggerExpandQuery] = useLazyExpandQuery();
  const [triggerBulkExpandQuery] = useLazyBulkExpandQuery();

  const bulkSilentExpandAndFetchChildNodes = useCallback(
    async (codes: string[][], recur: number, pageSize = PAGE_SIZE) => {
      const { data: results } = await triggerBulkExpandQuery({
        division: activeDivisionName,
        hierarchyType,
        payload: {
          page: 0,
          pageSize,
          parent: codes.map((codePair) => ({
            code: codePair[0],
            shortName: codePair[1],
          })),
        },
      });
      const rows = [
        {
          childNodes: results,
          parameterType,
        },
      ].filter((row) => Boolean(row.childNodes)) as [
        {
          childNodes: HierarchyServiceResponseDto;
          parameterType: ParameterId;
        }
      ];
      if (recur > 0 && results && results.results[0].depth < maxDepth) {
        const childResults = await bulkSilentExpandAndFetchChildNodes(
          results.results.map((cn) => [cn.code, cn.shortName]),
          recur - 1
        );
        rows.push(...childResults);
      }

      return rows;
    },
    [triggerBulkExpandQuery, activeDivisionName, maxDepth]
  );

  const silentExpandAndFetchChildNodes = useCallback(
    (
      nodeCode: string,
      nodeShortName: string,
      level: number,
      pageSize = PAGE_SIZE
    ) => {
      triggerExpandQuery({
        division: activeDivisionName,
        hierarchyType,
        payload: {
          page: 0,
          pageSize,
          parent: {
            code: nodeCode,
            shortName: nodeShortName,
          },
        },
      })
        .then(async (response) => {
          if (response.data) {
            const pairs = response.data.results.map((child) => [
              child.code,
              child.shortName,
            ]);
            dispatch(
              onHierarchyChildNodesReceived({
                childNodes: response.data,
                parameterType,
                overwrite: true,
              })
            );
            if (level > 0) {
              const resp = await bulkSilentExpandAndFetchChildNodes(
                pairs,
                level - 1
              );
              dispatch(onBulkHierarchyChildNodesReceived(resp));
            }
          }
        })
        .catch((error) => {
          ddLog("ERROR", {}, "error", error);
        });
    },
    [
      dispatch,
      activeDivisionName,
      triggerExpandQuery,
      bulkSilentExpandAndFetchChildNodes,
    ]
  );

  const resetContextMenuTimeout = useCallback(() => {
    if (contextMenuTimeout) {
      clearTimeout(contextMenuTimeout);
    }

    const timeoutId = setTimeout(setActiveContextMenu, 3_000, "");
    setContextMenuTimeout(timeoutId);
  }, [contextMenuTimeout, setActiveContextMenu, setContextMenuTimeout]);

  const stopContextMenuTimeout = useCallback(() => {
    clearTimeout(contextMenuTimeout);
  }, [contextMenuTimeout]);

  const openContextMenu = useCallback(
    (
      id: string,
      code: string,
      shortName: string,
      row: Row<ProductHierarchyParameterItem>
    ) => {
      // only load more rows if the row and the levels of subrows have not been expanded
      if (
        row.subRows.length === 0 ||
        row.subRows[row.subRows.length - 1].original.isMoreRow ||
        row.subRows.some((sr) => sr.subRows.length === 0) ||
        row.subRows.some(
          (sr) => sr.subRows[sr.subRows.length - 1].original.isMoreRow
        )
      ) {
        silentExpandAndFetchChildNodes(code, shortName, 1, 10_000);
      }

      setActiveContextMenu(id);
      resetContextMenuTimeout();
    },
    [
      silentExpandAndFetchChildNodes,
      setActiveContextMenu,
      resetContextMenuTimeout,
    ]
  );

  const closeContextMenu = useCallback(() => {
    setActiveContextMenu("");
    stopContextMenuTimeout();
  }, [setActiveContextMenu, stopContextMenuTimeout]);

  const toggleContextMenu = useCallback(
    (
      id: string,
      code: string,
      shortName: string,
      row: Row<ProductHierarchyParameterItem>
    ) => {
      if (activeContextMenu === id) {
        closeContextMenu();
      } else {
        openContextMenu(id, code, shortName, row);
      }
    },
    [openContextMenu, closeContextMenu, activeContextMenu]
  );

  const firstSelectionMade = useSelector(
    (state: RootState) => state.reportParameter.firstProductSelectionMade
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(parameterType, state)
  );

  const productHierarchyLevelLock = useSelector((state: RootState) =>
    selectHierarchyLevelLock(parameterType, state)
  );

  const lockedProductHierarchyLevels = useMemo(() => {
    if (productHierarchyLevelLock) {
      const allowed =
        MAX_SELECTION_LEVELS -
        productHierarchyLevelLock.max +
        productHierarchyLevelLock.min;
      if (allowed > 0) {
        return {
          min: productHierarchyLevelLock.min - allowed,
          max: productHierarchyLevelLock.max + allowed,
        };
      } else {
        return productHierarchyLevelLock;
      }
    }

    return undefined;
  }, [productHierarchyLevelLock]);

  const selectionHandlerWithContextMenu = useCallback(
    (row: Row<ProductHierarchyParameterItem>, handler: Function) => {
      if (!firstSelectionMade && !row.getIsSelected()) {
        openContextMenu(row.id, row.original.code, row.original.shortName, row);
      }

      handler();
    },
    [openContextMenu, firstSelectionMade]
  );

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(parameterType, state)
  );

  const selectedRows = useSelector((state: RootState) =>
    selectHierarchySelectedRows(parameterType, state)
  );

  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectIsSelectedItemsShown(parameterType, state)
  );

  const selectedRowsFiltered = useSelector((state: RootState) =>
    selectHierarchySelectedItemsFiltered(parameterType, state)
  );

  const expandedRows = useSelector((state: RootState) =>
    selectHierarchyExpandedRows(parameterType, state)
  );

  const findRowInGrid = useCallback(
    (shortName: string, code: string, depth: number) => {
      let searchSet = [...items];

      // Rapid recurssion, instead of removing iterable indices from the search array,
      // we push the first index along, saving us on splicing the array with each iteration.
      // Linter is asking for a "for-of" loop, however as the array size can mutate which each
      // iteration with recursion, the for-of loop doesn't complete all iterations correctly.
      // eslint-disable-next-line @typescript-eslint/prefer-for-of -- for-of doesn't work here
      for (let index = 0; index < searchSet.length; index++) {
        const searchRow = searchSet[index];
        // No need to search for levels below our target depth
        if (searchRow.depth < depth && searchRow.subRows) {
          searchSet = searchSet.concat(searchRow.subRows);
        }

        // Compare short name first, short circuit out if we're not at the right level
        if (searchRow.shortName === shortName && searchRow.code === code) {
          return searchRow;
        }
      }

      return null;
    },
    [items]
  );

  useEffect(() => {
    if (contextMenuType === ContextMenuType.Tooltip) {
      const rows = selectedRows
        .map((sr) => findRowInGrid(sr.shortName, sr.code, sr.depth))
        .filter((rw) => rw !== null) as HierarchyGridItem[];
      let rowsToAdd = rows;
      const newAutoSelectedRows: HierarchyGridItem[] = [];

      // Rapid recurssion, instead of removing iterable indicies from the search array,
      // we push the first index along, saving us on splicing the array with each iteration.
      // Linter is asking for a "for-of" loop, however as the array size can mutate which each
      // iteration with recursion, the for-of loop doesn't complete all iterations correctly.
      // eslint-disable-next-line @typescript-eslint/prefer-for-of -- for-of doesn't work here
      for (let index = 0; index < rowsToAdd.length; index++) {
        const processedRow = rowsToAdd[index];
        if (processedRow.subRows) {
          rowsToAdd = rowsToAdd.concat(processedRow.subRows);
        }

        newAutoSelectedRows.push(processedRow);
      }

      setAutoSelectedRows(newAutoSelectedRows);
    }
  }, [
    items,
    selectedRows,
    contextMenuType,
    findRowInGrid,
    expandedRows,
    isSuccess,
  ]);

  const disabledLevelShortNames = useSelector((state: RootState) =>
    selectHierarchyDisabledLevelShortNames(parameterType, state)
  );

  const universeRestrictedLevelShortName = useSelector((state: RootState) =>
    selectUniverseRestrictedLevelShortName(parameterType, state)
  );

  const isDataEntitlementsShown = useSelector((state: RootState) =>
    selectIsDataEntitlementsShown(state)
  );

  const reportTransactionSources = useSelector((state: RootState) =>
    selectTransactionSources(state)
  );

  const maxGroupSelectionsReached = useSelector((state: RootState) =>
    selectMaxGroupSelectionsReached(parameterType, state)
  );

  const getNodeId = useMemo(
    () => (shortName: string, code: string) => `${shortName}.${code}`,
    []
  );

  const createRowId = useMemo(
    () => (row: ProductHierarchyParameterItem) =>
      row.loadMoreParentRow?.parent
        ? `${getNodeId(
            row.loadMoreParentRow.parent.shortName,
            row.loadMoreParentRow.parent.code
          )}-load-more`
        : getNodeId(row.shortName, row.code),
    [getNodeId]
  );

  const isHierarchyInLockedRange = useCallback(
    (depth: number) =>
      Boolean(
        lockedProductHierarchyLevels &&
          (depth < lockedProductHierarchyLevels.min ||
            depth > lockedProductHierarchyLevels.max)
      ),
    [lockedProductHierarchyLevels]
  );

  const getSubRowsForSelection = useCallback(
    (row: Row<ProductHierarchyParameterItem>) => {
      const newSelectedRows: HierarchyItem[] = [];

      /* We're checking if we shoud add the sub rows of our current row. This will be current depth + 1
       by checking once here we can get away with not checking at every step of the loop. */
      if (!isHierarchyInLockedRange(row.original.depth + 1)) {
        for (const sr of row.subRows) {
          if (
            !selectedRows.some(
              (rw) =>
                rw.code === sr.original.code &&
                !isHierarchyInLockedRange(rw.depth)
            )
          ) {
            newSelectedRows.push(sr.original);
          }
        }
      }

      return newSelectedRows;
    },
    [selectedRows, isHierarchyInLockedRange]
  );

  const dispatchAddRowSelection = useCallback(
    (selection: HierarchyItem[]) => {
      dispatch(
        onAddHierarchyNodeSelection({
          nodeSelection: selection,
          parameterType,
          lockSelection: contextMenuType === ContextMenuType.Menu,
        })
      );
    },
    [dispatch, contextMenuType]
  );

  const dispatchRemoveRowSelection = useCallback(
    (selection: HierarchyItem[]) => {
      dispatch(
        onRemoveHierarchyNodeSelection({
          nodeSelection: selection,
          parameterType,
          lockSelection: contextMenuType === ContextMenuType.Menu,
        })
      );
    },
    [dispatch, contextMenuType]
  );

  const contextMenuSelectFirstLevel = useCallback(
    (row: Row<ProductHierarchyParameterItem>, addRows: boolean) => {
      if (addRows) {
        const newSelectedRows = getSubRowsForSelection(row);
        dispatchAddRowSelection(newSelectedRows);
      } else {
        dispatchRemoveRowSelection(row.subRows.map((sr) => sr.original));
      }
    },
    [
      dispatchAddRowSelection,
      getSubRowsForSelection,
      dispatchRemoveRowSelection,
    ]
  );

  const getSecondLevelSubRowsForSelection = useCallback(
    (row: Row<ProductHierarchyParameterItem>) => {
      const newSelectedRows: HierarchyItem[] = [];

      /* We're checking if we shoud add the sub rows of sub rows. This will be current depth + 2
       by checking once here we can get away with not checking at every step of the loop. */
      if (!isHierarchyInLockedRange(row.original.depth + 2)) {
        for (const sr of row.subRows) {
          for (const subSubRow of sr.subRows) {
            if (
              !selectedRows.some((rw) => rw.code === subSubRow.original.code)
            ) {
              newSelectedRows.push(subSubRow.original);
            }
          }
        }
      }

      return newSelectedRows;
    },
    [selectedRows, isHierarchyInLockedRange]
  );

  const contextMenuSelectSecondLevel = useCallback(
    (row: Row<ProductHierarchyParameterItem>, addRows: boolean) => {
      if (addRows) {
        const newSelectedRows = getSecondLevelSubRowsForSelection(row);
        dispatchAddRowSelection(newSelectedRows);
      } else {
        let rowsToRemove: HierarchyItem[] = [];
        for (const sr of row.subRows) {
          rowsToRemove = rowsToRemove.concat(
            sr.subRows.map((subSubRow) => subSubRow.original)
          );
        }

        dispatchRemoveRowSelection(rowsToRemove);
      }
    },
    [
      dispatchRemoveRowSelection,
      getSecondLevelSubRowsForSelection,
      dispatchAddRowSelection,
    ]
  );

  const fetchChildNodesData = useCallback(
    async (payload: NodeExpansionRequestDto) => {
      try {
        const result = await triggerExpandQuery({
          division: activeDivisionName,
          hierarchyType,
          payload,
        }).unwrap();

        if (result.count > 0) {
          dispatch(
            onHierarchyChildNodesReceived({
              childNodes: result,
              parameterType,
            })
          );

          const parentNodeShortName = result.results[0].parent.shortName;
          const parentNodeCode = result.results[0].parent.code;

          dispatch(
            setHierarchyNodeExpanding({
              nodeCode: parentNodeCode,
              nodeShortName: parentNodeShortName,
              parameterType,
            })
          );
        }
      } catch (error) {
        ddLog("Error retrieving children nodes", {}, "error", error as Error);
      }
    },
    [activeDivisionName, dispatch, triggerExpandQuery]
  );

  const expandAndFetchChildNodes = useCallback(
    (nodeCode: string, nodeShortName: string, pageSize = PAGE_SIZE) => {
      dispatch(
        setHierarchyNodeExpanding({
          nodeCode,
          nodeShortName,
          parameterType,
        })
      );

      fetchChildNodesData({
        page: 0,
        pageSize,
        parent: {
          code: nodeCode,
          shortName: nodeShortName,
        },
      }).catch((error) => {
        ddLog("Error retrieving children nodes", {}, "error", error);
      });
    },
    [dispatch, fetchChildNodesData]
  );

  useEffect(() => {
    if (isSuccess && items.length === 1 && items[0].subRows === undefined) {
      if (contextMenuType === ContextMenuType.Menu) {
        silentExpandAndFetchChildNodes(items[0].code, items[0].shortName, 2);
      } else {
        expandAndFetchChildNodes(items[0].code, items[0].shortName, 10_000);
      }
    }
    // configure dependency array to trigger auto expansion only once on the top node
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, items.length, items[0]?.subRows, expandAndFetchChildNodes]);

  const getNewExpandedRows = useMemo(
    () => (row: Row<ProductHierarchyParameterItem>) => {
      const expanedRowIndex = expandedRows.findIndex(
        (expandedRow) =>
          expandedRow.code === row.original.code &&
          expandedRow.shortName === row.original.shortName
      );

      let newExpandedRows = [...expandedRows];

      if (expanedRowIndex === -1) {
        newExpandedRows = [...expandedRows, row.original];
      } else {
        newExpandedRows = expandedRows.filter(
          (expandedRow) =>
            getNodeId(expandedRow.shortName, expandedRow.code) !==
            getNodeId(row.original.shortName, row.original.code)
        );
      }

      return newExpandedRows;
    },
    [expandedRows, getNodeId]
  );

  const onToggleExpansionHandler = useCallback(
    (row: Row<ProductHierarchyParameterItem>) => {
      row.toggleExpanded();

      const newExpandedRows = getNewExpandedRows(row);

      dispatch(
        hierarchyNodeExpanded({
          expandedRows: newExpandedRows,
          parameter: parameterType,
        })
      );

      if (contextMenuType === ContextMenuType.Menu) {
        if (
          (row.depth < maxDepth - 2 && row.subRows.length === 0) ||
          row.subRows.some((sr) => sr.subRows.length === 0) ||
          row.subRows.some((sr) =>
            sr.subRows.some((ssr) => ssr.subRows.length === 0)
          )
        ) {
          silentExpandAndFetchChildNodes(
            row.original.code,
            row.original.shortName,
            2
          );
        } else if (row.depth === maxDepth - 2 && row.subRows.length === 0) {
          silentExpandAndFetchChildNodes(
            row.original.code,
            row.original.shortName,
            1
          );
        } else if (row.depth === maxDepth - 1) {
          expandAndFetchChildNodes(row.original.code, row.original.shortName);
        }
      } else if (row.subRows.length === 0) {
        expandAndFetchChildNodes(
          row.original.code,
          row.original.shortName,
          10_000
        );
      }
    },
    [
      dispatch,
      expandAndFetchChildNodes,
      getNewExpandedRows,
      contextMenuType,
      maxDepth,
      silentExpandAndFetchChildNodes,
    ]
  );

  const getNewSelectedRows = useCallback(
    (row: Row<ProductHierarchyParameterItem>) => {
      // row.getIsSelected provides the opposite state for checkbox
      const isChecked = !row.getIsSelected();

      let newSelectedRows: HierarchyItem[];

      if (isChecked) {
        newSelectedRows = selectedRows.concat(row.original);
      } else {
        newSelectedRows = selectedRows.filter(
          (selectedRow) =>
            !(
              selectedRow.shortName === row.original.shortName &&
              selectedRow.code === row.original.code
            )
        );
      }

      return newSelectedRows;
    },
    [selectedRows]
  );

  const onToggleSelectionHandler = useCallback(
    (row: Row<ProductHierarchyParameterItem>) => {
      const newSelectedRows = getNewSelectedRows(row);

      dispatch(
        onHierarchyNodeSelection({
          nodeSelection: newSelectedRows,
          parameterType,
          lockSelection: contextMenuType === ContextMenuType.Menu,
        })
      );
    },
    [dispatch, getNewSelectedRows, contextMenuType]
  );

  const hierarchyItemHeader = useCallback(
    ({ table }: HeaderContext<ProductHierarchyParameterItem, unknown>) => (
      <ProductHierarchyParameterTableHeader
        handleToggleAllRowsSelected={table.getToggleAllRowsSelectedHandler()}
        isChecked={table.getIsAllRowsSelected()}
        isDisabled={!isSelectedItemsShown && isDataEntitlementsShown}
        selectedItemsToggleConfig={{
          show: isSelectedItemsShown,
          toggleShow: () =>
            dispatch(toggleShowSelectedItems({ parameterType })),
          count: selectedRows.length,
        }}
        title="Product"
      />
    ),
    [isDataEntitlementsShown, dispatch, isSelectedItemsShown, selectedRows]
  );

  const buildContextMenu = useCallback(
    (row: Row<ProductHierarchyParameterItem>) => {
      const contextMenuElements = {
        closeContextMenu,
        isOpen: activeContextMenu === row.id,
        openContextMenu: () =>
          openContextMenu(
            row.id,
            row.original.code,
            row.original.shortName,
            row
          ),
        resetTimeout: resetContextMenuTimeout,
        selectOptions: [],
        shortCode: row.original.shortName,
        stopTimeout: stopContextMenuTimeout,
        toggleContextMenu: () =>
          toggleContextMenu(
            row.id,
            row.original.code,
            row.original.shortName,
            row
          ),
        hierarchyItemType: row.original.type,
      } as ContextMenuElements;

      if (activeContextMenu === row.id) {
        const levelsToDisplay = getHierarchicalLevelsForContextMenu(
          row.original.shortName
        );
        const selectOptions: ContextMenuSelectOption[] = [];

        if (levelsToDisplay.length > 0)
          selectOptions.push({
            shortName: levelsToDisplay[0].shortName,
            name: levelsToDisplay[0].name,
            isChecked: areAllSubrowsSelected(row),
            isDisabled: isHierarchyInLockedRange(row.original.depth + 1),
            handleOnChange: (addRows: boolean) =>
              contextMenuSelectFirstLevel(row, addRows),
            isLoading: !row.subRows.length,
          });

        if (levelsToDisplay.length > 1)
          selectOptions.push({
            shortName: levelsToDisplay[1].shortName,
            name: levelsToDisplay[1].name,
            isChecked: isSecondLevelChecked(row),
            isDisabled: isHierarchyInLockedRange(row.original.depth + 2),
            handleOnChange: (addRows: boolean) =>
              contextMenuSelectSecondLevel(row, addRows),
            isLoading: !row.subRows[0]?.subRows?.length,
          });

        contextMenuElements.selectOptions = selectOptions;
      }

      return contextMenuElements;
    },
    [
      activeContextMenu,
      closeContextMenu,
      isHierarchyInLockedRange,
      contextMenuSelectFirstLevel,
      contextMenuSelectSecondLevel,
      getHierarchicalLevelsForContextMenu,
      openContextMenu,
      resetContextMenuTimeout,
      stopContextMenuTimeout,
      toggleContextMenu,
    ]
  );

  const buildContextMenuWrapper = useCallback(
    (row: Row<ProductHierarchyParameterItem>) => {
      if (
        contextMenuType === ContextMenuType.Menu &&
        row.original.depth < maxDepth - 1
      ) {
        return {
          contextMenuType,
          contextMenuElements: buildContextMenu(row),
        } as ContextMenuWrapper;
      } else {
        return {
          contextMenuType,
        } as ContextMenuWrapper;
      }
    },
    [contextMenuType, maxDepth, buildContextMenu]
  );

  const isHierarchyLevelAvailable = useCallback(
    (
      shortName: string,
      displayedTransactionSource: TransactionSource | null | undefined
    ) =>
      displayedTransactionSource === null ||
      displayedTransactionSource === undefined ||
      disabledLevelShortNames.includes(shortName),
    [disabledLevelShortNames]
  );

  const hierarchyItemCell = useCallback(
    ({
      row,
      getValue,
    }: CellContext<ProductHierarchyParameterItem, unknown>) => {
      const itemTransactionSources = getHierarchyItemTransactionSources(
        row.original
      );
      const displayedTransactionSource = getPreferredTransactionSource(
        reportTransactionSources,
        itemTransactionSources
      )?.[0];

      const contextMenuWrapper = buildContextMenuWrapper(row);

      const isRowAutoSelected = autoSelectedRows.some(
        (rw) =>
          rw.shortName === row.original.shortName &&
          rw.code === row.original.code
      );

      const isSelected =
        row.getIsSelected() ||
        (contextMenuType === ContextMenuType.Tooltip && isRowAutoSelected);

      const isRowHierarchyDisabled = isHierarchyLevelAvailable(
        row.original.shortName,
        displayedTransactionSource
      );

      const displayReportRunOnProductLevelTooltip =
        contextMenuType === ContextMenuType.Tooltip &&
        !row.getIsSelected() &&
        isRowAutoSelected;

      const isRowHierarchyLevelLocked = isHierarchyInLockedRange(
        row.original.depth
      );

      const displaySelectionLimitReachedTooltip =
        maxGroupSelectionsReached && !isSelected;

      const displayHierarchyLevelLimitTooltip = universeRestrictedLevelShortName
        ? isDisabledByHierarchyLevelLimit(
            selectedRows,
            row,
            universeRestrictedLevelShortName
          )
        : false;

      const displayNameOfRestrictedLevel = productHierarchyMetadata?.find(
        (x) => x.shortName === universeRestrictedLevelShortName
      )?.name;

      const getCheckboxDisabledTooltip = () => {
        if (isRowHierarchyDisabled)
          return "The hierarchy level is not available for selection in this report.";
        if (displayReportRunOnProductLevelTooltip)
          return "This report is run on product level";
        if (isRowHierarchyLevelLocked)
          return "This hierarchy level is not available based on your current selection.";
        if (displayHierarchyLevelLimitTooltip)
          return `Selection limited to within one ${displayNameOfRestrictedLevel?.toLowerCase()}. Deselect all items in currently-selected ${displayNameOfRestrictedLevel?.toLowerCase()} to re-enable.`;
        if (displaySelectionLimitReachedTooltip)
          return "Selection limit reached. Please deselect an item to enable selection.";
        return "";
      };

      const selectionFunction = () => {
        selectionHandlerWithContextMenu(row, () => {
          if (isSelected) {
            dispatchRemoveRowSelection([row.original]);
          } else {
            const newSelection =
              contextMenuType === ContextMenuType.Menu
                ? [row.original]
                    .concat(getSubRowsForSelection(row))
                    .concat(getSecondLevelSubRowsForSelection(row))
                : [row.original];
            dispatchAddRowSelection(newSelection);
          }
        });
      };

      const showContextMenuTrigger = contextMenuType !== ContextMenuType.None;

      const checkboxDisabledTooltip = getCheckboxDisabledTooltip();

      return (
        <ExpandableNameCell
          canExpand={
            row.subRows.length >= 0 &&
            !row.original.isLeaf &&
            searchString === ""
          }
          checkboxDisabledTooltip={checkboxDisabledTooltip}
          contextMenuWrapper={contextMenuWrapper}
          depth={row.depth}
          handleToggleExpanded={() => onToggleExpansionHandler(row)}
          handleToggleSelected={selectionFunction}
          hideCellTooltip={isRowHierarchyDisabled}
          isCheckboxDisabled={Boolean(checkboxDisabledTooltip)}
          isCompact
          isExpanded={row.subRows.length > 0 && row.getIsExpanded()}
          isExpanding={row.original.isExpanding}
          isSelected={isSelected}
          name={row.original.name}
          shortName={row.original.shortName}
          showContextMenuTrigger={showContextMenuTrigger}
          type={row.original.type}
          value={String(getValue())}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      disabledLevelShortNames,
      onToggleExpansionHandler,
      onToggleSelectionHandler,
      reportTransactionSources,
      activeContextMenu,
      contextMenuTimeout,
      autoSelectedRows,
      items,
    ]
  );

  const transactionSourceHeader = useCallback(() => <span>Dataset</span>, []);

  const transactionSourceCell = useCallback(
    ({ row }: CellContext<ProductHierarchyParameterItem, unknown>) => {
      const itemTransactionSources = getHierarchyItemTransactionSources(
        row.original
      );
      const displayedTransactionSource = getPreferredTransactionSource(
        reportTransactionSources,
        itemTransactionSources
      )?.[0];

      return (
        <span className={styles.transactionSourceCell}>
          <TransactionSourceIcon
            availableTransactionSources={availableTransactionSources}
            greyedOut={!row.getIsSelected()}
            transactionSource={displayedTransactionSource}
          />
        </span>
      );
    },
    [availableTransactionSources, reportTransactionSources]
  );

  const columns = useMemo<Array<ColumnDef<ProductHierarchyParameterItem>>>(
    () =>
      isDataEntitlementsShown
        ? [
            {
              accessorKey: "name",
              cell: hierarchyItemCell,
              header: hierarchyItemHeader,
            },
            {
              accessorKey: "transactionSource",
              cell: transactionSourceCell,
              header: transactionSourceHeader,
            },
          ]
        : [
            {
              accessorKey: "name",
              cell: hierarchyItemCell,
              header: hierarchyItemHeader,
            },
          ],
    [
      transactionSourceCell,
      transactionSourceHeader,
      hierarchyItemCell,
      hierarchyItemHeader,
      isDataEntitlementsShown,
    ]
  );

  const handleRowSelectionsChange = (
    newRowSelections: Updater<RowSelectionState>
  ) => {
    const selectionIds = Object.keys(newRowSelections);

    const newSelectedRows = items.filter((item) =>
      selectionIds.includes(item.code)
    );
    dispatch(
      onHierarchyNodeSelection({
        nodeSelection: newSelectedRows,
        parameterType,
      })
    );
  };

  const rowSelectionState = useMemo(() => {
    const newRowSelectionState: Record<string, boolean> = {};

    for (const selectedRow of selectedRows) {
      newRowSelectionState[getNodeId(selectedRow.shortName, selectedRow.code)] =
        true;
    }

    return newRowSelectionState;
  }, [getNodeId, selectedRows]);

  const rowExpansionState = useMemo(() => {
    const newRowExpansionState: Record<string, boolean> = {};

    for (const expandedRow of expandedRows) {
      newRowExpansionState[getNodeId(expandedRow.shortName, expandedRow.code)] =
        true;
    }

    return newRowExpansionState;
  }, [expandedRows, getNodeId]);

  return (
    <div className={styles.productHierarchyParameter}>
      <ReportHierarchyTableWrapper isSuccess={isSuccess}>
        <ReportHierarchyTable
          className={styles.table}
          columnResizeMode="onChange"
          columns={columns}
          compactRows
          data={isSelectedItemsShown ? selectedRowsFiltered : items}
          depthPadding={50}
          disableSorting
          getRowId={(row: ProductHierarchyParameterItem) => createRowId(row)}
          getSubRows={(row) => row.subRows}
          moreText="Load More..."
          rowExpandedState={rowExpansionState}
          rowSelectionState={rowSelectionState}
          setRowSelection={handleRowSelectionsChange}
        />
      </ReportHierarchyTableWrapper>
    </div>
  );
};
