import {
  Button,
  ButtonVariant,
  Dialog,
  DialogHeight,
  DialogWidth,
  Icon,
  IconGlyph,
} from "@quantium-enterprise/qds-react";
import { type PropsWithChildren } from "react";
import styles from "./LocationHierarchyFilterModal.module.css";

export type LocationHierarchyFilterModalProps = {
  header: JSX.Element;
  isShown: boolean;
  onClose: (modalShown: boolean) => void;
  triggerRef: React.MutableRefObject<undefined>;
};

export const LocationHierarchyFilterModal = ({
  children,
  header,
  onClose,
  isShown,
  triggerRef,
}: PropsWithChildren<LocationHierarchyFilterModalProps>) => {
  const modifiedHeader = (
    <div className={styles.headerContainer}>
      {header}
      <Button
        className={styles.closeDialogButton}
        onClick={() => {
          onClose(false);
        }}
        variant={ButtonVariant.Stealth}
      >
        <Icon
          glyph={IconGlyph.DeleteAndCloseClose}
          text="Close location select"
        />
      </Button>
    </div>
  );

  return (
    <Dialog
      className={styles.modal}
      header={modifiedHeader}
      height={DialogHeight.Large}
      onClose={() => {
        onClose(false);
      }}
      show={isShown}
      titleId="modal-select-title"
      triggeredBy={triggerRef}
      width={DialogWidth.Large}
    >
      <>{children}</>
    </Dialog>
  );
};

export default LocationHierarchyFilterModal;
