import {
  CUSTOMER_GROUP_WIZARD,
  CustomerGroupType,
  GroupType,
  HierarchyGroupEvaluationType,
  HierarchyType,
  isEnumValue,
  useGetCustomerGroupQuery,
  useGetGroupQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Spinner, SpinnerSize } from "@quantium-enterprise/qds-react";
import { Navigate, useParams } from "react-router-dom";
import { CombinedGroupEditor } from "../../components/combined-group-editor/CombinedGroupEditor";
import { CustomSegmentationEditor } from "../../components/custom-segmentation-editor/CustomSegmentationEditor";
import { DynamicGroupEditor } from "../../components/dynamic-group-editor/DynamicGroupEditor";
import { StaticGroupEditor } from "../../components/static-group-editor/StaticGroupEditor";
import styles from "./GroupWizard.module.css";

type HierarchyGroupWizardProps = {
  divisionName: string;
  groupId?: string;
  groupType: HierarchyType;
  templateType?: string;
};

const HierarchyGroupWizard = ({
  divisionName,
  groupId,
  groupType,
  templateType,
}: HierarchyGroupWizardProps) => {
  const { data: existingGroup } = useGetGroupQuery(
    {
      divisionName,
      groupId: groupId as string,
    },
    { skip: !divisionName || !groupId }
  );

  if (groupId && !existingGroup) {
    return <Spinner className={styles.spinner} size={SpinnerSize.Large} />;
  }

  let hierarchyType = groupType;
  let evaluationType = templateType;
  if (groupId && existingGroup) {
    hierarchyType = existingGroup.hierarchyType;
    evaluationType = existingGroup.evaluationType;
  }

  switch (evaluationType) {
    case HierarchyGroupEvaluationType.Dynamic:
      return (
        <DynamicGroupEditor
          existingGroup={existingGroup}
          hierarchyType={hierarchyType}
        />
      );
    case HierarchyGroupEvaluationType.Static:
      return (
        <StaticGroupEditor
          existingGroup={existingGroup}
          hierarchyType={hierarchyType}
        />
      );
    default:
      return null;
  }
};

type CustomerGroupWizardProps = {
  divisionName: string;
  groupId?: string;
  templateType?: string;
};

const CustomerGroupWizard = ({
  divisionName,
  groupId,
  templateType,
}: CustomerGroupWizardProps) => {
  const { data: customerGroup } = useGetCustomerGroupQuery(
    {
      divisionName,
      groupId: groupId as string,
    },
    { skip: !divisionName || !groupId }
  );

  if (groupId && !customerGroup) {
    return <Spinner className={styles.spinner} size={SpinnerSize.Large} />;
  }

  const actualTemplateType = customerGroup?.type ?? templateType;

  switch (actualTemplateType) {
    case CustomerGroupType.GrowingStableDeclining:
    case CustomerGroupType.HeavyMediumLight:
    case CustomerGroupType.NewLapsedContinuous:
    case CustomerGroupType.MeasureThreshold:
    case CustomerGroupType.PurchaserNonPurchaser:
      return groupId ? (
        <Navigate
          to={`/${divisionName}/${CUSTOMER_GROUP_WIZARD}/${actualTemplateType}/${groupId}`}
        />
      ) : (
        <Navigate
          to={`/${divisionName}/${CUSTOMER_GROUP_WIZARD}/${actualTemplateType}`}
        />
      );
    case CustomerGroupType.Combined:
      return <CombinedGroupEditor existingGroup={customerGroup} />;
    case CustomerGroupType.CustomSegmentation:
      return <CustomSegmentationEditor />;
    default:
      return null;
  }
};

export const GroupWizard = () => {
  const { name: divisionName } = useDivision();
  const { templateType } = useParams();
  const { groupId } = useParams();
  const { groupType } = useParams();

  if (isEnumValue(HierarchyType)(groupType)) {
    return (
      <HierarchyGroupWizard
        divisionName={divisionName}
        groupId={groupId}
        groupType={groupType as HierarchyType}
        templateType={templateType}
      />
    );
  }

  if (groupType === GroupType.Customer) {
    return (
      <CustomerGroupWizard
        divisionName={divisionName}
        groupId={groupId}
        templateType={templateType}
      />
    );
  }

  return null;
};
