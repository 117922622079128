import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type SavedParametersDetailsDto,
  type CreateSavedParametersRequestDto,
  type SavedParametersDto,
  type RenameSavedParametersDto,
} from "../models";

export const savedParametersApi = createApi({
  baseQuery: getBaseQuery(`/api/report-parameters-web`),
  endpoints: (builder) => ({
    createSavedParameters: builder.mutation<
      string,
      { divisionName: string; payload: CreateSavedParametersRequestDto }
    >({
      invalidatesTags: ["saved-parameters"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/SavedParameters/${divisionName}/CreateSavedParameters`,
      }),
    }),
    getSavedParameters: builder.query<
      SavedParametersDto[],
      { divisionName: string }
    >({
      providesTags: ["saved-parameters"],
      query: ({ divisionName }) => ({
        method: "GET",
        url: `/SavedParameters/${divisionName}/GetSavedParameters`,
      }),
    }),
    getSavedParametersDetails: builder.query<
      SavedParametersDetailsDto,
      { divisionName: string; savedParametersId: string }
    >({
      providesTags: ["saved-parameters"],
      query: ({ divisionName, savedParametersId }) => ({
        method: "GET",
        url: `/SavedParameters/${divisionName}/GetSavedParametersDetails/${savedParametersId}`,
      }),
    }),
    deleteSavedParameters: builder.mutation<
      unknown,
      { divisionName: string; savedParametersId: string }
    >({
      invalidatesTags: ["saved-parameters"],
      query: ({ divisionName, savedParametersId }) => ({
        method: "DELETE",
        url: `/SavedParameters/${divisionName}/DeleteSavedParameters/${savedParametersId}`,
      }),
    }),
    renameSavedParameters: builder.mutation<
      unknown,
      { divisionName: string; payload: RenameSavedParametersDto }
    >({
      invalidatesTags: ["saved-parameters"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/SavedParameters/${divisionName}/RenameSavedParameters`,
      }),
    }),
  }),
  reducerPath: "savedParametersApi",
  tagTypes: ["saved-parameters"],
});

export const {
  useCreateSavedParametersMutation,
  useGetSavedParametersQuery,
  useLazyGetSavedParametersQuery,
  useGetSavedParametersDetailsQuery,
  useLazyGetSavedParametersDetailsQuery,
  useDeleteSavedParametersMutation,
  useRenameSavedParametersMutation,
} = savedParametersApi;
