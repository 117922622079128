import { type ParameterDto } from "@quantium-enterprise/common-ui";
import {
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  Input,
  InputStatus,
  NumberInput,
  Tag,
  TagColour,
  TagVariant,
} from "@quantium-enterprise/qds-react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { bufferUpdated } from "../../states/report-wizard-slice";
import { ParameterLabel } from "../parameter-label/ParameterLabel";
import styles from "./BufferParameter.module.css";
import { getBufferParameterState } from "./BufferParameterState";

export type BufferParameterProps = {
  headingText?: string;
  parameterDto: ParameterDto;
};

export const BufferParameter = ({
  headingText,
  parameterDto,
}: BufferParameterProps) => {
  const dispatch = useDispatch();

  const { buffer, errors } = useAppSelector(
    getBufferParameterState(parameterDto.id)
  );

  const updateBuffer = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const input = Number(event.currentTarget.value);

      // Prevent user from inputting numbers with more than one decimal place
      // and numbers greater than or equal to 100
      const value = Math.floor(input * 10) / 10;
      if (value >= 100) {
        return;
      }

      dispatch(bufferUpdated({ parameterId: parameterDto.id, buffer: value }));
    },
    [dispatch, parameterDto.id]
  );

  return (
    <FormBlock
      blockStatus={
        errors.length === 0 ? FormBlockStatus.Default : FormBlockStatus.Error
      }
      blockType={FormBlockType.Number}
      className={styles.bufferParameter}
    >
      <ParameterLabel
        description={parameterDto.description}
        heading={headingText ?? parameterDto.name}
        htmlFor={parameterDto.name}
      />
      <Input>
        <NumberInput
          id={parameterDto.name}
          max={100}
          min={0}
          onChange={updateBuffer}
          step={1}
          value={String(buffer)}
        />
        <span>%</span>
      </Input>
      <div className={styles.bufferErrorsContainer}>
        {errors.map((error) => (
          <div className={styles.bufferError} key={error}>
            <Tag
              className={styles.errorIcon}
              colour={TagColour.Bad}
              text={error}
              variant={TagVariant.Badge}
            />
            <InputStatus id={`${parameterDto.name}-${error}`} text={error} />
          </div>
        ))}
      </div>
    </FormBlock>
  );
};
