import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { ListSelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getListParameterState } from "../../parameters/list/ListParameterState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelListProps = {
  defaultText?: string;
  parameterDto: ParameterDto;
};

export const SummaryPanelList = ({
  defaultText,
  parameterDto,
}: SummaryPanelListProps) => {
  const listParameterState = useAppSelector(
    getListParameterState(parameterDto.id)
  );
  if (!listParameterState) {
    return null;
  }

  let selectedLabels = listParameterState.selections
    .slice()
    .sort((a, b) => a.index - b.index)
    .map((selection) => selection.label);

  if (selectedLabels.length === 0 && defaultText) {
    selectedLabels = [defaultText];
  }

  return (
    <ListSelectionSummary
      label={parameterDto.name}
      selections={selectedLabels}
    />
  );
};
