import {
  groupsApi,
  hierarchyServiceApi,
  userApi,
} from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import { reportConfigurationApi } from "./fast-report/api/fastReportConfigurationApi";
import fastReportingSearchSlice, {
  fastReportingSearchReducerPath,
} from "./focal-item-search/fastReportingSearchSlice";
import { SearchConfigurationApi } from "./focal-item-search/services/SearchConfigurationApi";
import parameterSlice, { parametersReducerPath } from "./parameterSlice";
import { customerProfilingApi } from "./report-panels/customer-profiling/api/customerProfilingApi";
import { keyDriverTreeApi } from "./report-panels/key-driver-tree/api/keyDriverTreeApi";
import { performanceReportApi } from "./report-panels/performance/api/performanceReportApi";
import { productsRangeReportApi } from "./report-panels/range/rangeProductsReportApi";
import { storesRangeReportApi } from "./report-panels/range/rangeStoresReportApi";
import { storeByStoreApi } from "./report-panels/store-by-store/storeByStoreApi";
import { topAndBottomApi } from "./report-panels/top-and-bottom/api/topAndBottomApi";
import { reportSummaryApi } from "./report-summary/api/reportSummaryApi";

const rootReducer = combineReducers({
  [fastReportingSearchReducerPath]: fastReportingSearchSlice,
  [parametersReducerPath]: parameterSlice,
  [SearchConfigurationApi.reducerPath]: SearchConfigurationApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [performanceReportApi.reducerPath]: performanceReportApi.reducer,
  [hierarchyServiceApi.reducerPath]: hierarchyServiceApi.reducer,
  [reportConfigurationApi.reducerPath]: reportConfigurationApi.reducer,
  [keyDriverTreeApi.reducerPath]: keyDriverTreeApi.reducer,
  [topAndBottomApi.reducerPath]: topAndBottomApi.reducer,
  [customerProfilingApi.reducerPath]: customerProfilingApi.reducer,
  [storeByStoreApi.reducerPath]: storeByStoreApi.reducer,
  [reportSummaryApi.reducerPath]: reportSummaryApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [productsRangeReportApi.reducerPath]: productsRangeReportApi.reducer,
  [storesRangeReportApi.reducerPath]: storesRangeReportApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "fast-reporting" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        SearchConfigurationApi.middleware,
        userApi.middleware,
        performanceReportApi.middleware,
        hierarchyServiceApi.middleware,
        reportConfigurationApi.middleware,
        keyDriverTreeApi.middleware,
        topAndBottomApi.middleware,
        customerProfilingApi.middleware,
        storeByStoreApi.middleware,
        reportSummaryApi.middleware,
        groupsApi.middleware,
        productsRangeReportApi.middleware,
        storesRangeReportApi.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
