import { ddLog } from "@quantium-enterprise/common-ui";
import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
  Tag,
  TagTextTransform,
  TagVariant,
  Text,
} from "@quantium-enterprise/qds-react";
import { toPng } from "html-to-image";
import { type ReactNode, useMemo, useRef, useState } from "react";
import { downloadFile } from "../../export/export-functions";
import { PipeSeparatedSummary } from "../../pipe-separated-summary/PipeSeparatedSummary";
import {
  HighchartsReact,
  type HighchartsReactProps,
} from "../highcharts-react/HighchartsReact";
import styles from "./HighchartsDownloadWizard.module.css";
import { type ChartSize } from "./controls/ChartSizeControl";
import { ChartSizeControl } from "./controls/ChartSizeControl";

export type HighchartsDownloadWizardProps = {
  chartOptions: HighchartsReactProps;
  chartTitle?: string;
  customLegend?: ReactNode;
  fileName: string;
  onCloseRequested: () => void;
  parameters?: Array<{
    name: string;
    value: string;
  }>;
  reportIcon: ReactNode;
  reportTitle: string;
};

export const HighchartsDownloadWizard = ({
  onCloseRequested,
  chartOptions,
  chartTitle,
  fileName,
  parameters,
  reportIcon,
  reportTitle,
  customLegend,
}: HighchartsDownloadWizardProps) => {
  const [chartSize, setChartSize] = useState<ChartSize>({
    widthPx: 960,
    heightPx: 640,
  });

  const modifiedOptions: HighchartsReactProps = useMemo(
    () =>
      ({
        ...chartOptions,
        chart: {
          ...chartOptions.chart,
          animation: false,
          zooming: {
            type: undefined,
          },
          width: undefined,
          height: undefined,
          backgroundColor: "transparent",
        },
        tooltip: {
          ...chartOptions.tooltip,
          followTouchMove: false,
          followPointer: false,
        },
        plotOptions: {
          ...chartOptions.plotOptions,
          series: {
            ...chartOptions.plotOptions?.series,
            animation: false,
            enableMouseTracking: false,
          },
        },
        legend: {
          ...chartOptions.legend,
          enabled: customLegend
            ? false
            : chartOptions.legend?.enabled !== false,
          y: chartOptions.legend?.layout === "vertical" ? 40 : 0,
        },
        title: {
          text: chartTitle,
        },
      } as HighchartsReactProps),
    [chartOptions, chartTitle, customLegend]
  );

  const elementToDownload = useRef<HTMLDivElement>(null);

  const initiateDownload = async () => {
    try {
      if (elementToDownload.current) {
        const dataUrl = await toPng(elementToDownload.current, {
          cacheBust: true,
          width: chartSize.widthPx,
          height: chartSize.heightPx,
          pixelRatio: 1,
        });
        downloadFile(dataUrl, `${fileName}.png`);
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  };

  return (
    <div className={styles.reportWizard}>
      <div className={styles.header}>
        <div className={styles.backButton}>
          <Button onClick={onCloseRequested} variant={ButtonVariant.Stealth}>
            <Icon glyph={IconGlyph.ArrowsChevronLeft} text="Back" />
          </Button>
        </div>
        <div className={styles.title}>
          <div className={styles.heading}>Download Image</div>
          <div className={styles.subHeading}>
            <div className={styles.reportIcon}>{reportIcon}</div>
            <div className={styles.reportTitle}>{reportTitle}</div>
          </div>
        </div>
        <div className={styles.exitButton}>
          <Button onClick={onCloseRequested} variant={ButtonVariant.Stealth}>
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Exit" />
            <Text>Exit preview</Text>
          </Button>
        </div>
      </div>
      <div className={styles.wizard}>
        <div className={styles.preview}>
          <div className={styles.chartContainer}>
            <div className={styles.widthLabel}>
              <Tag
                text={`W ${chartSize.widthPx} px`}
                textTransform={TagTextTransform.None}
                variant={TagVariant.Lozenge}
              />
            </div>
            <div className={styles.heightLabel}>
              <Tag
                text={`H ${chartSize.heightPx} px`}
                textTransform={TagTextTransform.None}
                variant={TagVariant.Lozenge}
              />
            </div>
            <div
              className={styles.content}
              ref={elementToDownload}
              style={{ height: chartSize.heightPx, width: chartSize.widthPx }}
            >
              <HighchartsReact
                className={styles.highcharts}
                options={modifiedOptions}
              />
              {customLegend}
              {parameters && (
                <div className={styles.parameters}>
                  <PipeSeparatedSummary parameters={parameters} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.options}>
          <div className={styles.heading}>Image preferences</div>
          <div className={styles.controls}>
            <ChartSizeControl
              currentChartSize={chartSize}
              setChartSize={setChartSize}
            />
            <Button
              onClick={async () => await initiateDownload()}
              text="Download image"
              variant={ButtonVariant.Primary}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
