import { ddLog } from "@quantium-enterprise/common-ui";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import {
  type ExportCsvTableRequest,
  type FetchChildrenTableRequest,
  type FetchChildrenTableResponse,
  type InitialTableRequest,
  type InitialTableResponse,
  type RefetchTableRequest,
  type RefetchTableResponse,
} from "../models/time-of-day-day-of-week-data-table-models";
import { timeOfDayDayOfWeekApiSlice } from "./time-of-day-day-of-week-api-slice";
import {
  onTableChildrenDataRecieved,
  onTableDataRecieved,
} from "./time-of-day-day-of-week-slice";

export const timeofDayDayOfWeekDataTableApiSlice =
  timeOfDayDayOfWeekApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getChildrenTableData: builder.query<
        FetchChildrenTableResponse,
        { divisionName: string; payload: FetchChildrenTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableChildrenDataRecieved(data));
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTableChildren/${divisionName}`,
        }),
      }),
      getInitialTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: InitialTableRequest }
      >({
        async onQueryStarted(argument, { queryFulfilled }) {
          // `onStart` side-effect
          try {
            await queryFulfilled;
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetInitialTable/${divisionName}`,
        }),
      }),
      getExportData: builder.query<
        Blob,
        { divisionName: string; payload: ExportCsvTableRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportFocalItemToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `Time Of Day Day Of Week focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
      getTableData: builder.query<
        RefetchTableResponse,
        { divisionName: string; payload: RefetchTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataRecieved(data));
          } catch {
            // What should message and context be
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportTableData/${divisionName}`,
        }),
      }),
    }),
  });

export const {
  useLazyGetChildrenTableDataQuery,
  useGetInitialTableQuery,
  useLazyGetExportDataQuery,
  useLazyGetTableDataQuery,
} = timeofDayDayOfWeekDataTableApiSlice;
