import {
  isNumberSelectionDto,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

export enum BufferError {
  OutOfRange = "Enter a valid number between 0 and 100",
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface BufferParameterState extends ParameterState {
  buffer: number | undefined;
  errors: string[];
}

export const isBufferParameterState = (
  state: ParameterState | undefined
): state is BufferParameterState =>
  state !== undefined && Object.hasOwn(state, "buffer");

export const validateBuffer = (buffer: number) => {
  const errors: BufferError[] = [];

  if (buffer < 0 || buffer > 100) {
    errors.push(BufferError.OutOfRange);
  }

  return errors;
};

export const newBufferParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string
): BufferParameterState => {
  let buffer = 10;

  if (
    parameterConfig.selections &&
    parameterConfig.selections.length > 0 &&
    isNumberSelectionDto(parameterConfig.selections[0])
  ) {
    buffer = parameterConfig.selections[0].number * 100;
  }

  return {
    isValid: isBetweenMinMax(1, parameterConfig),
    parameterConfig,
    parameterGroup,
    buffer,
    errors: validateBuffer(buffer),
  };
};

export const getBufferParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as BufferParameterState
  );

export const handleBufferParameterSelection = (
  parameterState: ParameterState,
  selection: number
): ParameterState => {
  if (isBufferParameterState(parameterState)) {
    const errors = validateBuffer(selection);
    return {
      ...parameterState,
      isValid:
        errors.length === 0 &&
        isBetweenMinMax(1, parameterState.parameterConfig),
      buffer: selection,
      errors,
    } as BufferParameterState;
  }

  return { ...parameterState };
};
