import {
  Icon,
  IconColour,
  IconGlyph,
  IconSize,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { DeleteOutlineIcon } from "components-ui/src/assets/icons/DeleteOutlineIcon";
import { GreySpinner } from "components-ui/src/loader/GreySpinner";
import styles from "./FileDetails.module.css";

const formatFileSize = (bytes: number) => {
  if (bytes < 1_024) {
    return `${bytes}B`;
  }

  if (bytes < 1_024 * 1_024) {
    return `${(bytes / 1_024).toFixed(0)}KB`;
  }

  return `${(bytes / 1_024 / 1_024).toFixed(0)}MB`;
};

type FileDetailsProps = {
  errors: string[];
  file: File;
  processing: boolean;
  removeFile: () => void;
};

export const FileDetails = ({
  errors,
  file,
  processing,
  removeFile,
}: FileDetailsProps) => (
  <div
    className={classNames(styles.fileDetails, {
      [styles.bad]: errors.length > 0,
    })}
  >
    <div className={styles.fileIcon}>
      <Icon
        className={styles.fileIcon}
        glyph={IconGlyph.FilesAndFoldersFileOutline}
        size={IconSize.Small}
        text="File"
      />
    </div>
    <div className={styles.fileName}>
      {errors.length > 0 ? `${file.name} failed, please try again` : file.name}
    </div>
    <div className={styles.fileStatus}>
      {processing ? (
        <GreySpinner className={styles.spinner} />
      ) : errors.length > 0 ? null : (
        <Icon
          colour={IconColour.Good}
          glyph={IconGlyph.SelectionCheckCircle}
          size={IconSize.Small}
          text="Valid"
        />
      )}
    </div>
    <div className={styles.fileDelete}>
      <button onClick={removeFile} type="button">
        <DeleteOutlineIcon />
      </button>
    </div>
    <div className={styles.fileSize}>{formatFileSize(file.size)}</div>
    {errors.length > 0 && (
      <div className={styles.fileErrors}>
        Reasons for failed import:
        <ul>
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </div>
    )}
  </div>
);
