import {
  createRowNameMatchesSearchPredicate,
  FeatureFlag,
  useGetActivatedSegmentsQuery,
  useGetStagedActivatedCustomerSegmentsQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  type CellContext,
  type RowSelectionState,
  type ColumnDef,
} from "@tanstack/react-table";
import { EmptyGroupTemplate } from "components-ui/src/search/EmptyGroup";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { type TableVirtuosoHandle } from "react-virtuoso";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import {
  selectShowActivatedSegments,
  selectSearchText,
  removeSelectedSegments,
  addSelectedSegments,
  selectSelectedSegments,
} from "../../states/group-list-slice";
import {
  getDefaultGroupsPath,
  getSegmentPath,
} from "../../utilities/route-path-formats";
import styles from "./SegmentLibraryTable.module.css";
import {
  type CustomerCountCellProps,
  type DateCellProps,
  type NameCellProps,
  type SegmentCellProps,
  type StatusCellProps,
  CustomerCountCell,
  DateCell,
  NameCell,
  SegmentCell,
  StatusCell,
  type SegmentRow,
  CheckboxCell,
  CheckboxClassName,
} from "./SegmentLibraryTableCells";

export type SegmentLibraryTableProps = {
  groupType: string;
  setFocalGroup: (group: SegmentRow | undefined) => void;
};

export const SegmentLibraryTable = ({
  groupType,
  setFocalGroup,
}: SegmentLibraryTableProps) => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();
  const navigate = useNavigate();
  const { groupId, segmentKey } = useParams();
  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
    {}
  );
  const showActivatedSegments = useSelector(selectShowActivatedSegments);
  const selectedSegments = useSelector(selectSelectedSegments);
  const [initialised, setInitialised] = useState<boolean>(false);
  const searchTerm = useSelector(selectSearchText);
  const virtuosoTableComponentRef = useRef<TableVirtuosoHandle>();

  const getRowId = useCallback(
    (row: SegmentRow) => `${row.customerGroupId}:${row.key}`,
    []
  );

  const handleRowClick = useCallback(
    (
      event: React.MouseEvent<HTMLTableRowElement>,
      rowId: number,
      rowData: SegmentRow
    ) => {
      if ((event.target as Element).closest(`.${CheckboxClassName}`)) {
        return;
      }

      if (rowData.customerGroupId === groupId && rowData.key === segmentKey) {
        setFocalGroup(undefined);
        navigate(
          getDefaultGroupsPath(
            divisionName,
            groupType,
            CustomerGroupTab.SegmentLibrary
          )
        );
      } else {
        setFocalGroup(rowData);
        navigate(
          getSegmentPath(divisionName, rowData.customerGroupId, rowData.key)
        );
        setRowSelectionState({ [getRowId(rowData)]: true });
      }
    },
    [
      divisionName,
      groupId,
      segmentKey,
      groupType,
      navigate,
      setFocalGroup,
      getRowId,
    ]
  );

  const getDisplayedSegments = showActivatedSegments
    ? useGetActivatedSegmentsQuery
    : useGetStagedActivatedCustomerSegmentsQuery;

  const {
    data: segmentList,
    isLoading,
    isUninitialized: isFetchUninitialized,
  } = getDisplayedSegments(
    {
      divisionName,
    },
    { skip: !divisionName }
  );

  // when groupId or segmentKey changes we need to update the focal segment to the new segment in that group
  // segmentList is updated when the user activates/deactivates a segment, we should update the focal segment accordingly
  useEffect(() => {
    if (groupId && segmentKey && segmentList) {
      for (const row of segmentList) {
        if (row.customerGroupId === groupId && row.key === segmentKey) {
          setFocalGroup(row);
          return;
        }
      }
    }

    setRowSelectionState({});
  }, [groupId, segmentList, segmentKey, setFocalGroup]);

  const filteredSegmentList = useMemo(() => {
    if (searchTerm) {
      const rowNameMatchesSearchPredicate =
        createRowNameMatchesSearchPredicate(searchTerm);
      return (
        segmentList?.filter(
          (segment) =>
            segment.customerGroupName &&
            rowNameMatchesSearchPredicate(segment.customerGroupName)
        ) ?? []
      );
    }

    return segmentList ?? [];
  }, [segmentList, searchTerm]);

  useEffect(() => {
    if (
      !initialised &&
      virtuosoTableComponentRef.current &&
      filteredSegmentList.length
    ) {
      if (groupId && segmentKey) {
        const index = filteredSegmentList.findIndex(
          (segment) =>
            segment.customerGroupId === groupId && segment.key === segmentKey
        );
        if (index >= 0) {
          // Defer scroll so that the table has time to render.
          // Needed some time more than 1ms. 500ms should be adequate most of the time.
          setTimeout(
            () => virtuosoTableComponentRef.current?.scrollToIndex(index),
            500
          );
          setRowSelectionState({
            [getRowId(filteredSegmentList[index])]: true,
          });
        }
      }

      setInitialised(true);
    }
  }, [
    groupId,
    segmentKey,
    virtuosoTableComponentRef,
    initialised,
    filteredSegmentList,
    getRowId,
    setInitialised,
    setRowSelectionState,
  ]);

  const checkboxCell = useCallback(
    (info: CellContext<SegmentRow, unknown>) => {
      const { name, row } = info.getValue<{
        name: string;
        row: SegmentRow;
      }>();
      const id = getRowId(row);
      const checked = selectedSegments.some(
        (segment) =>
          segment.customerGroupId === row.customerGroupId &&
          segment.key === row.key
      );
      const onChange = () => {
        if (checked) {
          dispatch(removeSelectedSegments(row));
        } else {
          dispatch(addSelectedSegments(row));
        }
      };

      return (
        <CheckboxCell
          checked={checked}
          id={id}
          name={name}
          onChange={onChange}
        />
      );
    },
    [selectedSegments, dispatch, getRowId]
  );

  const columns: Array<ColumnDef<SegmentRow>> = [];
  if (flags[FeatureFlag.SegmentBuilderDetailsDownloads]) {
    columns.push({
      accessorFn: (row) => ({
        name: row.name,
        row,
      }),
      cell: checkboxCell,
      header: "",
      id: "selectColumn",
      minSize: 20,
      maxSize: 20,
      size: 20,
    });
  }

  columns.push(
    {
      accessorFn: (row: SegmentRow): NameCellProps => ({
        name: row.customerGroupName ?? "",
        type: row.customerGroupTypeName ?? "",
      }),
      cell: NameCell,
      header: "Group name",
      id: "group-name",
      minSize: 400,
    },
    {
      accessorFn: (row: SegmentRow): SegmentCellProps => ({
        key: row.name,
      }),
      cell: SegmentCell,
      header: "Segment",
      id: "segment",
    },
    {
      accessorFn: (row: SegmentRow): CustomerCountCellProps => ({
        count: row.count,
      }),
      cell: CustomerCountCell,
      header: "Customer count",
      id: "customer-count",
    },
    {
      accessorFn: (row: SegmentRow): StatusCellProps => ({
        activationDateUtc: row.activationDate,
      }),
      cell: StatusCell,
      header: "Media status",
      id: "media-status",
    },
    {
      accessorFn: (row: SegmentRow): DateCellProps => ({
        date: row.customerGroupCreatedDate,
      }),
      cell: DateCell,
      header: "Created",
      id: "created",
    }
  );

  return (
    <>
      <VirtuosoTableComponent
        className={styles.segmentLibraryTable}
        columns={columns}
        data={filteredSegmentList}
        getRowId={getRowId}
        onRowClick={handleRowClick}
        ref={virtuosoTableComponentRef}
        refreshingData={isLoading}
        rowSelectionState={rowSelectionState}
        sorting={[{ desc: true, id: "last-refreshed" }]}
      />
      {!isLoading &&
      !isFetchUninitialized &&
      filteredSegmentList.length === 0 &&
      !searchTerm ? (
        <div className={styles.emptyContent}>
          <EmptyGroupTemplate
            altText="No customer segments available"
            noGroupsSubtitle="You can add segments to the library from available customer groups."
            noGroupsTitle="No customer segments available"
          />
        </div>
      ) : (
        !isLoading &&
        filteredSegmentList.length === 0 &&
        Boolean(searchTerm) && (
          <div className={styles.emptySearch}>
            <EmptySearch />
          </div>
        )
      )}
    </>
  );
};
