import { type ReportletDataResponseDto } from "../models/reportlet-reponse";
import { type ReportletDataRequestDto } from "../models/reportlet-request";
import { keyDriversOverTimeApiSlice } from "./key-drivers-over-time-api-slice";

export const keyDriversOverTimeReportletDataSlice =
  keyDriversOverTimeApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getKeyDriversOverTimeReportletData: builder.query<
        ReportletDataResponseDto,
        { divisionName: string; requestPayload: ReportletDataRequestDto }
      >({
        async onQueryStarted(_argument, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error fetching KDOT reportlet data", Error);
          }
        },
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportletData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetKeyDriversOverTimeReportletDataQuery } =
  keyDriversOverTimeReportletDataSlice;
