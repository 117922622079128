import {
  type HierarchyItemType,
  type HierarchyValueDto,
} from "@quantium-enterprise/common-ui";
import { Text } from "@quantium-enterprise/qds-react";
// TODO remove HierarchyLevelToDisplayMap once we have the uiShortName passed from the backend
import {
  HierarchyLevelIcon,
  HierarchyLevelToDisplayMap,
} from "../../hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./HierarchySelectionSummary.module.css";

export const HIERARCHY_ITEM_NAME_TEST_ID =
  "hierarchy-parameter-hierarchy-item-name";

type HierarchyItem = HierarchyValueDto & {
  type: HierarchyItemType;
};

export type HierarchySelectionSummaryProps = {
  hasShortNamePrefix?: boolean;
  itemCount?: number;
  items: HierarchyItem[] | undefined;
  maxDepth: number;
  moreItemsText: string;
};

export const HierarchySelectionSummary = ({
  hasShortNamePrefix = false,
  items,
  maxDepth,
  moreItemsText,
  itemCount,
}: HierarchySelectionSummaryProps) => {
  const count = itemCount ?? items?.length ?? 0;

  return (
    <>
      {items?.slice(0, maxDepth).map((item) => {
        const itemName = hasShortNamePrefix
          ? `${HierarchyLevelToDisplayMap[item.shortName]} - ${item.name}`
          : item.name;
        return (
          <div
            className={styles.hierarchyItem}
            key={`${item.shortName}${item.itemCode}`}
          >
            <span className={styles.structureFolderContainer}>
              <HierarchyLevelIcon shortName={item.shortName} type={item.type} />
            </span>
            <Text
              className={styles.hierarchyItemName}
              data-testid={HIERARCHY_ITEM_NAME_TEST_ID}
              title={itemName}
            >
              {itemName}
            </Text>
          </div>
        );
      })}
      {items && count > maxDepth && (
        <Text>{`+ ${count - maxDepth}${moreItemsText}`}</Text>
      )}
    </>
  );
};
