import {
  ddLog,
  HierarchyType,
  ParameterId,
  useHierarchyMetadataQuery,
  useLazyGetRootNodesQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onHierarchyMetadataReceived,
  onHierarchyRootNodeReceived,
  selectHierarchyItems,
  selectLeafShortName,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { LocationHierarchyParameterTable } from "./LocationHierarchyParameterTable";

const hierarchyType = HierarchyType.Location;
const parameterType = ParameterId.LocationHierarchy;

export const LocationHierarchyParameterQuery = () => {
  const dispatch = useDispatch();
  const { name: division } = useDivision();

  const { items, leafShortName } = useSelector((state: RootState) => ({
    items: selectHierarchyItems(parameterType, state),
    leafShortName: selectLeafShortName(parameterType, state),
  }));

  const [
    triggerLazyRootNodesQuery,
    { data: rootNodesData, isSuccess: isRootNodesSuccess },
  ] = useLazyGetRootNodesQuery();

  const fetchRootNode = useCallback(async () => {
    await triggerLazyRootNodesQuery({
      division,
      hierarchyType,
      payload: {
        page: 0,
      },
    });
  }, [division, triggerLazyRootNodesQuery]);

  useEffect(() => {
    if (items.length === 0) {
      fetchRootNode().catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [items.length, fetchRootNode]);

  useEffect(() => {
    if (isRootNodesSuccess && rootNodesData) {
      dispatch(
        onHierarchyRootNodeReceived({
          parameterType,
          response: rootNodesData,
        })
      );
    }
  }, [isRootNodesSuccess, rootNodesData, dispatch]);

  const { data: hierarchyMetadata, isSuccess: isHierarchyMetadataSuccess } =
    useHierarchyMetadataQuery(
      {
        division,
        hierarchyType,
        getAllAttributes: true,
      },
      {
        skip: leafShortName !== "",
      }
    );

  useEffect(() => {
    if (isHierarchyMetadataSuccess && hierarchyMetadata.length > 0) {
      dispatch(
        onHierarchyMetadataReceived({ hierarchyMetadata, parameterType })
      );
    }
  }, [hierarchyMetadata, isHierarchyMetadataSuccess, dispatch]);

  return <LocationHierarchyParameterTable isSuccess={items.length !== 0} />;
};
