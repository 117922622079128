import type Highcharts from "highcharts";
import { type CustomerLoyaltyReportletItem } from "../../models/CustomerLoyaltyReportletResponseDto";

export const TICK_INTERVAL = 0.05;
export const MIN_VALUE = 0;
export const MAX_VALUE = 100;

export const filterToValidData = (
  items: CustomerLoyaltyReportletItem[],
  metricIndex: number,
  primaryMetricIndex: number,
  segmentIndex: number
) =>
  items.filter(
    (item) =>
      item.segmentData[segmentIndex].metricValue.length > 0 &&
      Number.isFinite(
        item.segmentData[segmentIndex].metricValue[primaryMetricIndex]
      ) &&
      Number.isFinite(item.segmentData[segmentIndex].metricValue[metricIndex])
  );

export const createDataPoint = (
  item: CustomerLoyaltyReportletItem,
  metricIndex: number,
  primaryMetricIndex: number,
  segmentIndex: number
) => ({
  name: item.focalItemName,
  x:
    item.segmentData[segmentIndex].metricValue[primaryMetricIndex] ?? undefined,
  y: item.segmentData[segmentIndex].metricValue[metricIndex],
});

export const getCustomerLoyaltyChartData = (
  items: CustomerLoyaltyReportletItem[],
  metricIndex: number,
  primaryMetricIndex: number,
  segmentIndex: number
): Highcharts.SeriesScatterOptions[] =>
  filterToValidData(items, metricIndex, primaryMetricIndex, segmentIndex).map(
    (item) => ({
      name: item.focalItemName,
      type: "scatter",
      data: [
        createDataPoint(item, metricIndex, primaryMetricIndex, segmentIndex),
      ],
    })
  );

export const getCustomerLoyaltyBrandGroupedChartData = (
  brands: string[],
  items: CustomerLoyaltyReportletItem[],
  metricIndex: number,
  primaryMetricIndex: number,
  segmentIndex: number
): Highcharts.SeriesScatterOptions[] =>
  brands.map((brand) => ({
    name: brand,
    type: "scatter",
    data: filterToValidData(
      items,
      metricIndex,
      primaryMetricIndex,
      segmentIndex
    )
      .filter((item) => item.groupedby === brand)
      .map((item) =>
        createDataPoint(item, metricIndex, primaryMetricIndex, segmentIndex)
      ),
  }));

export const getMinMaxDataValues = (
  items: CustomerLoyaltyReportletItem[],
  primaryMetricIndex: number,
  segmentIndex: number
): number[] => {
  let currentMinValue = MAX_VALUE;
  let currentMaxValue = MIN_VALUE;
  const tickIntervalInteger = TICK_INTERVAL * 100;

  if (items.length === 0) {
    return [MIN_VALUE, MAX_VALUE];
  }

  for (const item of items) {
    if (
      item.segmentData[segmentIndex].metricValue[primaryMetricIndex] === null
    ) {
      continue;
    }

    const loyaltyValue =
      (item.segmentData[segmentIndex].metricValue[
        primaryMetricIndex
      ] as number) * 100;

    if (loyaltyValue < currentMinValue) currentMinValue = loyaltyValue;
    if (loyaltyValue > currentMaxValue) currentMaxValue = loyaltyValue;
  }

  if (currentMinValue === MAX_VALUE && currentMaxValue === MIN_VALUE) {
    return [MIN_VALUE, MAX_VALUE];
  }

  const loyaltyValueFloor = Math.floor(currentMinValue);
  const loyaltyValueCeiling = Math.ceil(currentMaxValue);

  const roundedMinValue =
    loyaltyValueFloor % tickIntervalInteger === 0 &&
    loyaltyValueFloor === currentMinValue &&
    currentMinValue !== MIN_VALUE
      ? currentMinValue - tickIntervalInteger
      : currentMinValue - (currentMinValue % tickIntervalInteger);

  const roundedMaxValue =
    loyaltyValueCeiling % tickIntervalInteger === 0 &&
    loyaltyValueCeiling === currentMaxValue
      ? currentMaxValue + tickIntervalInteger
      : currentMaxValue +
        tickIntervalInteger -
        (currentMaxValue % tickIntervalInteger);

  return [
    roundedMinValue,
    roundedMaxValue > MAX_VALUE ? MAX_VALUE : roundedMaxValue,
  ];
};
