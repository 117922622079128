import { MetricTypes } from "@quantium-enterprise/hooks-ui";

export const getCsvColumnHeader = (
  currencyFormat: string,
  currencySymbol: string,
  data: string,
  metrics: string
) => {
  if (currencyFormat === MetricTypes.Currency) {
    return `${metrics} (${currencySymbol}) - ${data}`;
  }

  return `${metrics} - ${data}`;
};
