import { BasicTable, type BasicTableProps } from "../basic-table/BasicTable";
import styles from "./GainsLossTable.module.css";

export type Header = {
  depth?: number;
  title: string;
};

export type GainLossNetData = {
  gain?: number;
  loss?: number;
  net?: number;
};

export type GainLossData = {
  header: Header;
  sales: GainLossNetData;
  units: GainLossNetData;
  volume: GainLossNetData;
};

export const GainsLossTable = ({
  columns,
  data,
  pinFirstColumn,
}: BasicTableProps<GainLossData>) => (
  <div className={styles.gainsLossDatatable}>
    <BasicTable columns={columns} data={data} pinFirstColumn={pinFirstColumn} />
  </div>
);
