import { z } from "zod";

export type MyReportsDto = {
  reports: GeneratedReportDto[];
};

export type GeneratedReportDto = {
  createDateUtc: string;
  dataSource: string[];
  finishDateUtc: string | null;
  folderId?: string;
  id: string;
  isViewed: boolean;
  name: string;
  reportStatus: string;
  reportType: string;
  sharedByUserId?: string;
  sharedWithUserIds: string[];
};

const GeneratedReportDtoSchema = z.object({
  createDateUtc: z.string().datetime(),
  dataSource: z.string().array(),
  finishDateUtc: z.string().datetime().nullable(),
  id: z.string(),
  isViewed: z.boolean(),
  name: z.string(),
  reportStatus: z.string(),
  reportType: z.string(),
  sharedByUserId: z.string().optional(),
  sharedWithUserIds: z.string().array(),
  folderId: z.string().optional(),
});

export const parseGeneratedReportDto = (input: unknown) =>
  GeneratedReportDtoSchema.parse(input);
