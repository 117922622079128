export const getexportHeader = (
  metric: string,
  format: string,
  currency: string
) => {
  if (format === "Currency") {
    return `${metric} (${currency})`;
  } else {
    return `${metric}`;
  }

  return "";
};
