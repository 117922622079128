import {
  Module,
  ModuleVariant,
} from "@quantium-enterprise/qds-react/dist/Module";
import classNames from "classnames";
import { type PropsWithChildren } from "react";
import styles from "./Panel.module.css";

type PanelProps = {
  className?: string;
  subtitle?: string;
  title: string;
};

// Reportlet panel with title and sub title for view report page without chevron for expand/collapse.
export const Panel = ({
  title,
  children,
  subtitle,
  className,
}: PropsWithChildren<PanelProps>) => (
  <Module
    className={classNames(className, styles.panelContainer)}
    content={<div className={styles.panelContent}>{children}</div>}
    header={
      <div className={styles.panelHeader}>
        <div>
          <h6 className={styles.panelTitle}>{title}</h6>
          <p className={styles.panelSubtitle}>{subtitle}</p>
        </div>
      </div>
    }
    variant={ModuleVariant.Default}
  />
);
