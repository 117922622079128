import {
  type FormatterFunction,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type ChartDataSeries } from "components-ui/src/charts/trends-chart/TrendsChart";
import { TrendsChart } from "components-ui/src/charts/trends-chart/TrendsChart";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { type RootState } from "../../../../store";
import { type TimeOfDayResponseDto } from "../../../models/time-of-day-chart";
import {
  selectIsTrendsChartDaysView,
  selectIsTrendsChartFocalItemsView,
  selectIsTrendsChartSegmentsView,
} from "../../../services/time-of-day-day-of-week-slice";
import {
  selectFocalItemParents,
  selectFocalItems,
} from "../../../services/time-of-day-day-of-week-slice-selectors";
import {
  getFocalData,
  getBenchmarkData,
  getSegmentsData,
  getDaysData,
} from "../filter-data";

type TimeOfDayReportletChartProps = {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  reportletData: TimeOfDayResponseDto;
};

export const TimeOfDayReportletChart = ({
  onOptionsChanged,
  reportletData,
}: TimeOfDayReportletChartProps) => {
  const formatter = useFormatter();

  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const { chartSelections, showTimeOfDayChartDataLabels } = useSelector(
    (state: RootState) => ({
      chartSelections: state.timeOfDayDayOfWeek.chartSelections,
      showTimeOfDayChartDataLabels:
        state.timeOfDayDayOfWeek.showTimeOfDayChartDataLabels,
    })
  );

  const { isFocalView, isSegmentsView, isDaysView } = useSelector(
    (state: RootState) => ({
      isFocalView: selectIsTrendsChartFocalItemsView(state.timeOfDayDayOfWeek),
      isSegmentsView: selectIsTrendsChartSegmentsView(state.timeOfDayDayOfWeek),
      isDaysView: selectIsTrendsChartDaysView(state.timeOfDayDayOfWeek),
    })
  );

  const { filteredChartData, selectedBenchmarkIndex } = useMemo(() => {
    let filteredData: ChartDataSeries[] = [];
    let benchmarkIndex: number | undefined;

    if (isFocalView) {
      filteredData = getFocalData(
        focalItems,
        reportletData,
        chartSelections,
        focalItemParents
      );
      const benchmarkData = getBenchmarkData(reportletData, chartSelections);
      if (benchmarkData) {
        filteredData = [...filteredData, benchmarkData];
        benchmarkIndex = filteredData.length - 1;
      }
    } else if (isSegmentsView) {
      filteredData = getSegmentsData(reportletData, chartSelections);
    } else if (isDaysView) {
      filteredData = getDaysData(reportletData, chartSelections);
    }

    return {
      filteredChartData: filteredData,
      selectedBenchmarkIndex: benchmarkIndex,
    };
  }, [
    chartSelections,
    focalItemParents,
    focalItems,
    isDaysView,
    isFocalView,
    isSegmentsView,
    reportletData,
  ]);

  const percentFormatter: FormatterFunction = useMemo(
    () => (value) => `${value}`,
    []
  );

  return (
    <TrendsChart
      benchmark={selectedBenchmarkIndex}
      categories={reportletData.hoursMetadata}
      chartData={filteredChartData}
      chartDataFormatter={formatter(reportletData.format)}
      onOptionsChanged={onOptionsChanged}
      percentFormatter={percentFormatter}
      showChartDataLabels={showTimeOfDayChartDataLabels}
      yAxisLabel={reportletData.metric}
    />
  );
};

export default TimeOfDayReportletChart;
