import {
  type HierarchyGroupDto,
  type FolderOrGroupDto,
  type HierarchyGroupWithSharingDtoWithDepth,
  type GroupFolderDto,
} from "../models";

export const isHierarchyGroup = (
  object: FolderOrGroupDto | undefined
): object is HierarchyGroupWithSharingDtoWithDepth =>
  object !== undefined && Object.hasOwn(object, "evaluationType");

const sortByName = (a: { name?: string }, b: { name?: string }) => {
  if (!a.name || a.name.trim() === "") {
    return -1;
  }

  if (!b.name || b.name.trim() === "") {
    return 1;
  }

  return a.name.localeCompare(b.name);
};

export const mapFolderDto = <T>(
  dto: GroupFolderDto,
  transformFolder: (
    folder: GroupFolderDto,
    depth: number,
    ancestorIds: string[],
    parentId?: string
  ) => T,
  transformGroup: (
    group: HierarchyGroupDto,
    depth: number,
    ancestorIds: string[],
    parentId?: string
  ) => T,
  depth: number = 0,
  ancestorIds?: string[],
  parentId?: string
): T[] => {
  const result: T[] = [
    transformFolder(dto, depth, ancestorIds ?? [], parentId),
  ];

  const newAncestorIds = ancestorIds ? [...ancestorIds] : [];
  if (dto.id) {
    newAncestorIds.push(dto.id);
  }

  const foldersCopy = dto.folders ? [...dto.folders] : [];
  foldersCopy.sort(sortByName);

  for (const folder of foldersCopy) {
    result.push(
      ...mapFolderDto(
        folder,
        transformFolder,
        transformGroup,
        depth + 1,
        newAncestorIds,
        dto.id
      )
    );
  }

  const groupsCopy = dto.groups ? [...dto.groups] : [];
  groupsCopy.sort(sortByName);

  for (const group of groupsCopy) {
    result.push(transformGroup(group, depth + 1, newAncestorIds, dto.id));
  }

  return result;
};

// Convert the hierarchical folder/group DTO to a flat array
export const mapFolderDtoToFolderOrGroupDto = (
  dto: GroupFolderDto
): FolderOrGroupDto[] =>
  mapFolderDto(
    dto,
    (folder, depth, ancestorIds, parentId) => {
      const hasChildren =
        Boolean(folder.folders?.length) || Boolean(folder.groups?.length);
      const dtoWithoutGroupsOrFolders = {
        ...folder,
        ancestorIds,
        depth,
        parentId,
        isFolder: true,
        hasChildren,
      };

      delete dtoWithoutGroupsOrFolders.groups;
      delete dtoWithoutGroupsOrFolders.folders;

      return dtoWithoutGroupsOrFolders;
    },
    (group, depth, ancestorIds, parentId) => ({
      ...group,
      ancestorIds,
      depth,
      parentId,
      isFolder: false,
      hasChildren: false,
    })
  );

export const searchGroups = (
  folder: GroupFolderDto,
  ...predicates: Array<(group: HierarchyGroupDto) => boolean>
): Array<{
  matched: number;
  total: number;
}> => {
  const results = predicates.map(() => ({
    matched: 0,
    total: 0,
  }));
  if (folder.groups) {
    for (const group of folder.groups) {
      for (const [index, predicate] of predicates.entries()) {
        const result = results[index];
        ++result.total;
        if (predicate(group)) {
          ++result.matched;
        }
      }
    }
  }

  if (folder.folders) {
    for (const subFolder of folder.folders) {
      const subResults = searchGroups(subFolder, ...predicates);
      for (const [index, subResult] of subResults.entries()) {
        const result = results[index];
        result.matched += subResult.matched;
        result.total += subResult.total;
      }
    }
  }

  return results;
};
