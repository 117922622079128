import { type SharedUserDto } from "@quantium-enterprise/common-ui";
import { Text } from "@quantium-enterprise/qds-react";
import { useMemo } from "react";
import styles from "./ShareText.module.css";

export type SharedWithTextProps = {
  maxDisplayedUsers: number;
  resourceType: string;
  users: SharedUserDto[];
};

export const SharedWithText = ({
  maxDisplayedUsers,
  users,
  resourceType,
}: SharedWithTextProps) => {
  const shareText = useMemo(() => {
    const itemsToDisplay = Math.min(maxDisplayedUsers + 1, users.length);

    let result = "";
    for (let index = 0; index < itemsToDisplay; index++) {
      switch (index) {
        case itemsToDisplay - 1:
          if (index === maxDisplayedUsers) {
            result += `${users.length - maxDisplayedUsers} more`;
          } else {
            result += `${users.at(index)?.firstName} ${
              users.at(index)?.lastName
            }`;
          }

          break;
        case itemsToDisplay - 2:
          result += `${users.at(index)?.firstName} ${
            users.at(index)?.lastName
          } and `;
          break;
        default:
          result += `${users.at(index)?.firstName} ${
            users.at(index)?.lastName
          }, `;
          break;
      }
    }

    return result;
  }, [maxDisplayedUsers, users]);

  return (
    <>
      {users.length > 0 && (
        <>
          <Text className={styles.boldText}>You</Text>
          <Text>{` shared this ${resourceType} with `}</Text>
          {shareText}
        </>
      )}
    </>
  );
};
