type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};
export const TimeOfDayDayOfWeekIcon = ({
  className,
  coloured,
  text,
}: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Time of day day of week report"}</title>
      <path
        d="M10 15.75C10 15.6119 10.1119 15.5 10.25 15.5H13.75C13.8881 15.5 14 15.6119 14 15.75V19.25C14 19.3881 13.8881 19.5 13.75 19.5H10.25C10.1119 19.5 10 19.3881 10 19.25V15.75Z"
        fill={colour3}
      />
      <path
        d="M16 9.75C16 9.61193 16.1119 9.5 16.25 9.5H19.75C19.8881 9.5 20 9.61193 20 9.75V13.25C20 13.3881 19.8881 13.5 19.75 13.5H16.25C16.1119 13.5 16 13.3881 16 13.25V9.75Z"
        fill={colour3}
      />
      <path
        d="M4 15.75C4 15.6119 4.11193 15.5 4.25 15.5H7.75C7.88807 15.5 8 15.6119 8 15.75V19.25C8 19.3881 7.88807 19.5 7.75 19.5H4.25C4.11193 19.5 4 19.3881 4 19.25V15.75Z"
        fill={colour1}
      />
      <path
        d="M10 9.75C10 9.61193 10.1119 9.5 10.25 9.5H13.75C13.8881 9.5 14 9.61193 14 9.75V13.25C14 13.3881 13.8881 13.5 13.75 13.5H10.25C10.1119 13.5 10 13.3881 10 13.25V9.75Z"
        fill={colour1}
      />
      <path
        d="M16 15.75C16 15.6119 16.1119 15.5 16.25 15.5H19.75C19.8881 15.5 20 15.6119 20 15.75V19.25C20 19.3881 19.8881 19.5 19.75 19.5H16.25C16.1119 19.5 16 19.3881 16 19.25V15.75Z"
        fill={colour1}
      />
      <path
        d="M7.25 2C6.97386 2 6.75 2.22386 6.75 2.5V5C6.75 5.27614 6.97386 5.5 7.25 5.5C7.52614 5.5 7.75 5.27614 7.75 5V2.5C7.75 2.22386 7.52614 2 7.25 2Z"
        fill={colour2}
      />
      <path
        d="M16.75 2C16.4739 2 16.25 2.22386 16.25 2.5V5C16.25 5.27614 16.4739 5.5 16.75 5.5C17.0261 5.5 17.25 5.27614 17.25 5V2.5C17.25 2.22386 17.0261 2 16.75 2Z"
        fill={colour2}
      />
      <path
        d="M4 9.75C4 9.61193 4.11193 9.5 4.25 9.5H7.75C7.88807 9.5 8 9.61193 8 9.75V13.25C8 13.3881 7.88807 13.5 7.75 13.5H4.25C4.11193 13.5 4 13.3881 4 13.25V9.75Z"
        fill={colour2}
      />
      <path
        clipRule="evenodd"
        d="M5.75 5C5.75 5.82843 6.42157 6.5 7.25 6.5C8.07843 6.5 8.75 5.82843 8.75 5V3.75H15.25V5C15.25 5.82843 15.9216 6.5 16.75 6.5C17.5784 6.5 18.25 5.82843 18.25 5V3.75H21C21.5523 3.75 22 4.19772 22 4.75V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V4.75C2 4.19772 2.44772 3.75 3 3.75H5.75V5ZM3 8H21V20.483C21 20.7592 20.7761 20.983 20.5 20.983H3.5C3.22386 20.983 3 20.7592 3 20.483V8Z"
        fill={colour2}
        fillRule="evenodd"
      />
    </svg>
  );
};
