import { useGetUserQuery } from "@quantium-enterprise/common-ui";
import { useState, useEffect } from "react";
import { type HierarchySearchLevel } from "./focal-item-search/HierarchySearchLevel";
import { AllSearchStrategy } from "./focal-item-search/search-strategies/AllSearchStrategy";
import { AttributeStrategy } from "./focal-item-search/search-strategies/AttributeStrategy";
import { DefaultHierarchyStrategy } from "./focal-item-search/search-strategies/DefaultHierarchyStrategy";
import { ProductGroupStrategy } from "./focal-item-search/search-strategies/ProductGroupStrategy";
import { useSearchConfigurationQuery } from "./focal-item-search/services/SearchConfigurationApi";

export type FastReportingFocalItemSearchStateProps = {
  divisionName: string;
  onSearchLevelsChanged: (searchLevels: HierarchySearchLevel[]) => void;
};

export const FastReportingFocalItemSearchState = ({
  divisionName,
  onSearchLevelsChanged,
}: FastReportingFocalItemSearchStateProps) => {
  const pageSize = 25;

  const [searchLevels, setSearchLevels] = useState<HierarchySearchLevel[]>([]);

  const { data: searchConfigData } = useSearchConfigurationQuery(
    {
      division: divisionName,
    },
    { skip: !divisionName }
  );

  const { data: user } = useGetUserQuery();

  useEffect(() => {
    if (searchConfigData && user) {
      const hierarchyStrategy = new DefaultHierarchyStrategy(
        divisionName,
        pageSize,
        searchConfigData.hierarchyStartLevel,
        user.isSupplier
      );
      const hierarchyLevel: HierarchySearchLevel = {
        flatSearchEnabled: true,
        name: "Hierarchy",
        searchStrategy: hierarchyStrategy,
      };
      const productGroupLevel: HierarchySearchLevel = {
        flatSearchEnabled: true,
        name: "Product groups",
        searchStrategy: new ProductGroupStrategy(divisionName),
      };

      const attributeStrategies = searchConfigData.attributes.map(
        (attribute) => {
          const strategy = new AttributeStrategy(
            divisionName,
            attribute.shortName,
            pageSize
          );
          return {
            level: {
              flatSearchEnabled: true,
              name: attribute.name,
              searchStrategy: strategy,
            } as HierarchySearchLevel,
            strategy,
          };
        }
      );
      const attributeLevels: HierarchySearchLevel[] = attributeStrategies.map(
        (strategy) => strategy.level
      );

      const allSearchLevel: HierarchySearchLevel = {
        flatSearchEnabled: true,
        name: "All",
        searchStrategy: new AllSearchStrategy(
          hierarchyStrategy,
          attributeStrategies.map((level) => level.strategy),
          divisionName,
          pageSize
        ),
      };

      if (searchConfigData.productGroupsDisabled) {
        setSearchLevels([allSearchLevel, hierarchyLevel, ...attributeLevels]);
      } else {
        setSearchLevels([
          allSearchLevel,
          hierarchyLevel,
          productGroupLevel,
          ...attributeLevels,
        ]);
      }
    }
  }, [searchConfigData, divisionName, user]);

  useEffect(() => {
    onSearchLevelsChanged(searchLevels);
  }, [searchLevels, onSearchLevelsChanged]);

  // eslint-disable-next-line react/jsx-no-useless-fragment -- We need to come back and make this all more "React-y"
  return <></>;
};

export default FastReportingFocalItemSearchState;
