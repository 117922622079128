import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type VisibilityState } from "@tanstack/react-table";
import { type Attribute } from "../../models/Attribute";
import { type Metric } from "../../models/Metric";
import { type SubstitutabilityTableRow } from "../../models/SubstitutabilityTableData";

const getRowdata = (
  product: SubstitutabilityTableRow,
  attributes: Attribute[],
  columnVisibility: VisibilityState
) => {
  const visibleAttributeValues: string[] = [];
  for (const [index, attribute] of attributes.entries()) {
    if (columnVisibility[attribute.shortName]) {
      visibleAttributeValues.push(product.attributeValues[index]);
    }
  }

  const metricValues = product.metricValues.map((metricValue) =>
    typeof metricValue === "number" ? metricValue.toString() : "-"
  );

  return [...visibleAttributeValues, ...metricValues];
};

export const csvTransformation = (
  attributes: Attribute[],
  columnVisibility: VisibilityState,
  substitutabilityTableData: SubstitutabilityTableRow[],
  metrics: Metric[],
  currencySymbol: string
) => {
  const attributeColumnHeaders = attributes
    .filter((attribute) => columnVisibility[attribute.shortName])
    .map((attribute) => attribute.displayName);
  const metricColumnHeaders = metrics.map((metric) => {
    if (metric.format === MetricTypes.Currency) {
      return `${metric.displayName}(${currencySymbol})`;
    }

    return metric.displayName;
  });
  const columnHeaders = [
    "Focal Product",
    "Substitute Product",
    ...attributeColumnHeaders,
    ...metricColumnHeaders,
  ];
  const csvData = [columnHeaders];

  for (const focalProduct of substitutabilityTableData) {
    const focalProductRowItemNames = [focalProduct.item.name, ""];
    csvData.push([
      ...focalProductRowItemNames,
      ...getRowdata(focalProduct, attributes, columnVisibility),
    ]);
    for (const substituteProduct of focalProduct.subRows) {
      const substituteProductRowItemNames = [
        focalProduct.item.name,
        substituteProduct.item.name,
      ];
      csvData.push([
        ...substituteProductRowItemNames,
        ...getRowdata(substituteProduct, attributes, columnVisibility),
      ]);
    }
  }

  return csvData;
};
