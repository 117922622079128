import {
  Button,
  ButtonVariant,
  EmptyState,
  Icon,
  IconGlyph,
  IllustrationSize,
  SpaceAround,
  Spinner,
  SpinnerSize,
  SpotColour,
  StateType,
  Text,
} from "@quantium-enterprise/qds-react";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import { type PropsWithChildren } from "react";
import styles from "./ReportLoadingWrapper.module.css";

export type ReportLoadingWrapperProps = PropsWithChildren & {
  disableLoadingSpinner?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  noData?: boolean;
  reportMinimumHeight: number;
  retry?: () => void;
};

export const ReportLoadingWrapper = ({
  children,
  isLoading,
  isError,
  retry,
  reportMinimumHeight,
  noData,
  disableLoadingSpinner,
  ...properties
}: ReportLoadingWrapperProps) => {
  if (isLoading && !disableLoadingSpinner) {
    return (
      <div
        {...properties}
        className={styles.container}
        style={{ height: reportMinimumHeight }}
      >
        <Spinner size={SpinnerSize.XLarge} />
      </div>
    );
  }

  if (isError && !isLoading) {
    return (
      <div
        {...properties}
        className={styles.container}
        style={{ height: reportMinimumHeight }}
      >
        <EmptyState
          description=""
          heading=""
          size={IllustrationSize.Large}
          spaceOutside={SpaceAround.XXXSmall}
          spotColour={SpotColour.Theme}
          stateType={StateType.Insights}
        />
        <div className={styles.errorTitle}>
          <Icon
            colour={tokens.colour["status-bad-text"]}
            glyph={IconGlyph.AlertsAndNotificationsWarning}
            text="Error"
          />
          <Text>
            <b>There has been an error retrieving the data.</b>
          </Text>
        </div>
        <Text className={styles.errorDescription}>
          <p>
            Please try again. If the error persists, please contact{" "}
            <a href="mailto:ProductSupport@quantium.com">user support</a>.
          </p>
        </Text>
        <Button onClick={retry} text="Retry" variant={ButtonVariant.Primary} />
      </div>
    );
  }

  if (noData) {
    return (
      <div
        {...properties}
        className={styles.container}
        data-testid="report-loading-wrapper-no-data"
        style={{ height: reportMinimumHeight }}
      />
    );
  }

  return (
    <div {...properties} className={styles.reportContainer}>
      {children}
    </div>
  );
};

export default ReportLoadingWrapper;
