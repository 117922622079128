import { type PropsWithChildren } from "react";
import styles from "./PanelWithSideDrawer.module.css";
import { type SideDrawerContent, SideDrawer } from "./SideDrawer";

type PanelWithSideDrawerProps = {
  closeFunc: () => void;
  drawerContent: SideDrawerContent;
  isOverlay: boolean;
  showDrawer: boolean;
};

export const PanelWithSideDrawer = ({
  closeFunc,
  showDrawer,
  isOverlay,
  drawerContent,
  children,
}: PropsWithChildren<PanelWithSideDrawerProps>) => (
  <div className={styles.panelWithSideDrawer}>
    <div className={styles.mainContent}>{children}</div>
    <SideDrawer
      closeFunc={closeFunc}
      content={drawerContent}
      isOverlay={isOverlay}
      showDrawer={showDrawer}
    />
  </div>
);

export default PanelWithSideDrawer;
