export enum FeatureFlag {
  ActivationGuidelines = "CO3-6465-Activation-Guidelines",
  AdvancedReportingTabs = "CO3-5573-Advanced-Reporting-Tabs",
  CombineStandardSegments = "CO3-5902-Combine-Standard-Segments",
  DownloadGroups = "CO3-5356-Download-Groups",
  EditCustomerGroupButtonInfoPanel = "co-3-5505-edit-customer-group-button-in-info-panel",
  Folders = "CO3-5005-Folders",
  FoldersForGroups = "CO3-5289-Folders-Groups",
  LoadRecentReportInReportWizard = "CO3-5773-load-recent-report-in-report-wizard",
  LocationHierarchySelectedItemsToggle = "CO3-6535-location-hierarchy-selected-items",
  ProductGroupsTrueShare = "CO3-6950-Product-Groups-True-Share",
  ProductHierarchySelectedItemsToggle = "CO3-215-product-hierarchy-selected-items",
  RangePerformanceDashboard = "CO3-6861-Range-Performance-Dashboard",
  ReportWizardCustomerGroupDetails = "co-3-5514-customer-group-details-in-report-wizard",
  Rerun = "CO3-4069-Report-Rerun",
  SavedSelections = "CO3-5763-Saved-Selections",
  SegmentBuilder = "CO3-5711-Segment-Builder",
  SegmentBuilderDetailsDownloads = "CO3-5785-Download-Segment-Details",
  SharingGroups = "CO3-5290-Sharing-Groups",
  SharingReports = "CO3-4079-Sharing",
  TopAndBottomImprovements = "co3-3046-fast-reporting-top-and-bottom-improvements",
}
