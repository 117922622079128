import { formatNumberDate } from "@quantium-enterprise/common-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import {
  HighchartsReact,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import HighchartsCustomTooltip from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import { type TooltipFormatterContextObject } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PURCHASE_ACTIVITY_DATA_TYPE_TABS } from "../models/PurchaseActivityTabs";
import { type TrialAndRepeatPurchaseActivityResponseDto } from "../models/TrialAndRepeatPurchaseActivityResponseDto";
import {
  sharedMouseMove,
  sharedMouseOut,
} from "../utils/synchronisedChartUtils";

export type PurchaseActivityChartProps = {
  data: TrialAndRepeatPurchaseActivityResponseDto;
  dataType: string;
  hoveredPoints: React.MutableRefObject<TooltipFormatterContextObject[]>;
  onHoveredPointsChange: (points: TooltipFormatterContextObject[]) => void;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  primaryMetricLabel: string;
  showDataLabels?: boolean;
  summaryType: string;
};

export const PurchaseActivityChart = ({
  data,
  summaryType,
  dataType,
  primaryMetricLabel,
  hoveredPoints,
  onHoveredPointsChange,
  onOptionsChanged,
  showDataLabels,
}: PurchaseActivityChartProps) => {
  const formatter = useFormatter();

  const categories = useMemo(
    () => data.dates.map((pw) => formatNumberDate(Number.parseInt(pw, 10))),
    [data.dates]
  );

  const format =
    data.data[0]?.[dataType.toLowerCase()]?.format ?? MetricTypes.Decimal;

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const yAxis: Highcharts.YAxisOptions[] = useMemo(
    () => [
      {
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            const metricFormat =
              self.axis.series[0].userOptions.custom?.metricFormat;
            return formatter(metricFormat)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
            fontWeight: "var(--qbit-font-weight-medium)",
          },
          text: primaryMetricLabel,
        },
        allowDecimals: !(
          format === MetricTypes.Integer || format === MetricTypes.SmallInteger
        ),
        reversedStacks: false,
        visible: true,
        max: dataType === PURCHASE_ACTIVITY_DATA_TYPE_TABS.Share ? 1 : null,
      },
    ],
    [dataType, format, formatter, primaryMetricLabel]
  );

  const series: Highcharts.SeriesOptionsType[] = useMemo(
    () =>
      structuredClone(data.data.slice(0, -1)).map((category, index) => ({
        data: category[dataType.toLowerCase()].values[
          summaryType.toLowerCase()
        ],
        name: data.legends[index],
        type: "column",
        custom: {
          metricFormat: category[dataType.toLowerCase()].format,
        },
        dataLabels: {
          enabled: showDataLabels,
          padding: 10,
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const point = self.point;
            const metricFormat =
              point.series.userOptions.custom?.metricFormat ?? "None";
            return formatter(metricFormat)(point.y, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-white)",
            fontFamily: "var(--qbit-font-family)",
            fontSize: "0.7rem",
            fontWeight: "var(--qbit-font-weight-medium)",
            textOutline: "var(--qbit-colour-text-primary)",
            strokeWidth: 3,
            cursor: "pointer",
          },
        },
        visible: !hiddenSeries.includes(index),
      })),
    [
      data.data,
      data.legends,
      dataType,
      summaryType,
      showDataLabels,
      hiddenSeries,
      formatter,
    ]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      legend: {
        ...defaultOptions.legend,
        // square symbols
        symbolRadius: 0,
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        visible: true,
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsCustomTooltip({
            pointFormatter: (pt: TooltipFormatterContextObject) =>
              formatter(pt.series.userOptions.custom?.metricFormat),
            hasSentiment: false,
            ttData: { ...ttData, points: hoveredPoints.current },
            yAxisTitle: "",
          }),
      },
      plotOptions: {
        column: {
          // sets width of each column by changing padding between them
          pointPadding: 0,
          stacking: "normal",
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        line: {
          marker: {
            enabled: false,
          },
          shadow: {
            color: "white",
            offsetX: 0,
            offsetY: 0,
            opacity: 100,
            width: 3,
          },
          zIndex: 1,
        },
        series: {
          point: {
            events: {
              mouseOver: sharedMouseMove(onHoveredPointsChange),
              mouseOut: sharedMouseOut,
            },
          },
        },
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: series as any,
      // o align x axis with distribution pricing chart
      chart: { ...defaultOptions.chart, marginRight: 60 },
    }),
    [
      categories,
      formatter,
      hoveredPoints,
      legendItemClick,
      onHoveredPointsChange,
      series,
      yAxis,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
