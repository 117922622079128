import { type CustomerProfilingRequestDto } from "../models/CustomerProfilingRequestDto";
import { type CustomerProfilingSingleSegmentationResponseDto } from "../models/CustomerProfilingSingleSegmentationResponseDto";
import { customerProfilingApiSlice } from "./customer-profiling-api-slice";

export const customerProfilingSingleSegmentationApiSlice =
  customerProfilingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getCustomerProfilingSingleSegmentation: builder.query<
        CustomerProfilingSingleSegmentationResponseDto,
        {
          division: string;
          payload: CustomerProfilingRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetCustomerProfilingSingleSegmentationData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetCustomerProfilingSingleSegmentationQuery } =
  customerProfilingSingleSegmentationApiSlice;
