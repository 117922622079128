import { ddLog } from "@quantium-enterprise/common-ui";
import {
  type FetchChildrenTableRequest,
  type FetchChildrenTableResponse,
  type InitialTableResponse,
  type InitialTableRequest,
  type RefetchTableRequest,
  type RefetchTableResponse,
  type SearchTableRequest,
} from "../models/DataTableDto";
import { keyMeasureTrendsApiSlice } from "./key-measure-trend-api-slice";
import {
  onInitialTableDataReceived,
  onTableChildrenDataRecieved,
  onTableDataRecieved,
} from "./key-measure-trends-slice";

export const keyMeasureTrendsDataTableApiSlice =
  keyMeasureTrendsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getChildrenTableData: builder.query<
        FetchChildrenTableResponse,
        { divisionName: string; payload: FetchChildrenTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableChildrenDataRecieved(data));
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog("Error retrieving children data", undefined, "error", error);
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTableChildren/${divisionName}`,
        }),
      }),
      getInitialTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: InitialTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onInitialTableDataReceived(data));
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error retrieving initial table data",
              undefined,
              "error",
              error
            );
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetInitialTable/${divisionName}`,
        }),
      }),
      /// Perform an RTK query response to call `/GetTableSearch/${divisionName}` and return the response
      getSearchTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: SearchTableRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTableSearch/${divisionName}`,
        }),
      }),
      getTableData: builder.query<
        RefetchTableResponse,
        { divisionName: string; payload: RefetchTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataRecieved(data));
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog("Error retrieving table data", undefined, "error", error);
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportTableData/${divisionName}`,
        }),
      }),
    }),
  });

export const {
  useLazyGetChildrenTableDataQuery,
  useLazyGetInitialTableQuery,
  useGetSearchTableQuery,
  useLazyGetTableDataQuery,
} = keyMeasureTrendsDataTableApiSlice;
