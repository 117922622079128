import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import {
  HighchartsReact,
  type HighchartsReactProps,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { HighchartsCustomTooltip } from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import { format, parse } from "date-fns";
import { type TooltipFormatterContextObject } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BreakdownByParameterId } from "../../models/basket-quantities-common-models";
import { type BasketSeries } from "../../models/basket-quantities-over-time-response-dto";
import { selectShowBasketQuantitiesOverTimeChartDataLabels } from "../../services/basket-quantities-slice";

export type BasketQuantitiesOverTimeChartProps = {
  basketSeries: BasketSeries[];
  basketSize?: string;
  breakdown: string;
  dates: number[];
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  primaryMetric: string;
  primaryMetricKey: string;
  secondaryMetric?: string;
  secondaryMetricKey?: string;
};

export const BasketQuantitiesOverTimeChart = ({
  dates,
  basketSeries,
  primaryMetric,
  primaryMetricKey,
  breakdown,
  basketSize,
  secondaryMetric,
  secondaryMetricKey,
  onOptionsChanged,
}: BasketQuantitiesOverTimeChartProps) => {
  const formatter = useFormatter();
  const showBasketQuantitiesOverTimeChartDataLabels = useSelector(
    selectShowBasketQuantitiesOverTimeChartDataLabels
  );

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const categories = useMemo(
    () =>
      dates.map((pw) =>
        format(parse(pw.toString(), "yyyyMMdd", new Date()), "d MMM yy")
      ),
    [dates]
  );

  // 0 = Total
  const showNonBasketBreakdown = breakdown !== BreakdownByParameterId.Total;
  const basketSeriesToProcess = useMemo(
    () =>
      showNonBasketBreakdown
        ? basketSeries.filter((basket) => basket.basketSize === basketSize)
        : basketSeries.filter((basket) => basket.basketSize !== "Total"),
    [basketSeries, basketSize, showNonBasketBreakdown]
  );
  let colourIndex = 0;

  const series: Highcharts.SeriesOptionsType[] = useMemo(() => {
    const seriesData: Highcharts.SeriesOptionsType[] =
      basketSeriesToProcess.flatMap((basket) => {
        const metricSeries = showNonBasketBreakdown
          ? basket.series.filter(
              (mSeries) =>
                mSeries.metricKey === primaryMetricKey &&
                mSeries.breakdown !== "Total"
            )
          : basket.series.filter(
              (mSeries) => mSeries.metricKey === primaryMetricKey
            );
        return metricSeries.map((mSeries) => ({
          data: mSeries.data.map((metric, index) => ({
            y: metric.metricValue,
            custom: {
              date: dates[index],
            },
          })),
          color: getColourByIndex(colourIndex++),
          custom: {
            metricFormat: mSeries.format,
          },
          name: showNonBasketBreakdown
            ? mSeries.name
            : `${basket.basketSize} per basket`,
          type: "column",
        }));
      });

    if (secondaryMetric && !showNonBasketBreakdown) {
      const secondaryMetricSeries = basketSeries
        .find((basket) => basket.basketSize === "Total")
        ?.series.find((mSeries) => mSeries.metricKey === secondaryMetricKey);

      const secondaryMetricLabel =
        secondaryMetricSeries?.metricLabel ?? secondaryMetric;

      const secondarySeries: Highcharts.SeriesLineOptions = {
        data: secondaryMetricSeries?.data.map((metric, index) => ({
          y: metric.metricValue,
          custom: {
            date: dates[index],
          },
        })),
        color: "var(--qbit-colour-text-primary)",
        custom: {
          metricFormat: secondaryMetricSeries?.format ?? "",
        },
        name: secondaryMetricLabel,
        type: "line",
        yAxis: 1,
      };

      seriesData.push(secondarySeries);
    }

    for (const [index, seriesDataItem] of seriesData.entries()) {
      seriesDataItem.visible = !hiddenSeries.includes(index);
    }

    return seriesData;
  }, [
    basketSeries,
    basketSeriesToProcess,
    colourIndex,
    dates,
    hiddenSeries,
    primaryMetricKey,
    secondaryMetric,
    secondaryMetricKey,
    showNonBasketBreakdown,
  ]);

  const primaryMetricLabel =
    basketSeriesToProcess[0]?.series.find(
      (mSeries) => mSeries.metricKey === primaryMetricKey
    )?.metricLabel ?? primaryMetric;

  const yAxis: Highcharts.YAxisOptions[] = useMemo(() => {
    const axisData: Highcharts.YAxisOptions[] = [
      {
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            const metricFormat =
              self.axis.series[0].userOptions.custom?.metricFormat;
            return formatter(metricFormat)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: primaryMetricLabel,
        },
        reversedStacks: false,
        visible: true,
        max: primaryMetric.includes("(%)") ? 1 : null,
      },
    ];

    if (secondaryMetric && !showNonBasketBreakdown) {
      const secondaryMetricSeries = basketSeries
        .find((basket) => basket.basketSize === "Total")
        ?.series.find((mSeries) => mSeries.metricKey === secondaryMetricKey);

      const secondaryMetricLabel =
        secondaryMetricSeries?.metricLabel ?? secondaryMetric;

      axisData.push({
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            const metricFormat =
              self.axis.series[0].userOptions.custom?.metricFormat;
            return formatter(metricFormat)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: secondaryMetricLabel,
        },
        visible: true,
        reversedStacks: false,
        opposite: true,
      });
    }

    return axisData;
  }, [
    basketSeries,
    formatter,
    primaryMetric,
    primaryMetricLabel,
    secondaryMetric,
    secondaryMetricKey,
    showNonBasketBreakdown,
  ]);

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      legend: {
        ...defaultOptions.legend,
        // square symbols
        symbolRadius: 0,
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        visible: true,
      },

      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsCustomTooltip({
            pointFormatter: (pt: TooltipFormatterContextObject) =>
              formatter(pt.series.userOptions.custom?.metricFormat),
            hasSentiment: false,
            ttData,
            yAxisTitle: `${primaryMetricLabel} (${
              basketSize === "Total" ? basketSize : `${basketSize} per basket`
            })`,
          }),
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: showBasketQuantitiesOverTimeChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              const metricFormat = self.series.userOptions.custom?.metricFormat;
              return formatter(metricFormat)(value);
            },
          },
          // sets width of each column by changing padding between them
          pointPadding: 0,
          stacking: "normal",
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        line: {
          dataLabels: {
            enabled: showBasketQuantitiesOverTimeChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              const metricFormat = self.series.userOptions.custom?.metricFormat;
              return formatter(metricFormat)(value);
            },
          },
          marker: {
            enabled: false,
          },
          shadow: {
            color: "white",
            offsetX: 0,
            offsetY: 0,
            opacity: 100,
            width: 3,
          },
          zIndex: 1,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: series as any,
    }),
    [
      basketSize,
      categories,
      formatter,
      legendItemClick,
      primaryMetricLabel,
      series,
      showBasketQuantitiesOverTimeChartDataLabels,
      yAxis,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
