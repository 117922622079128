import { type InteractionsVennMetricColumnHeaderKey } from "../models/InteractionsVennMetricColumnHeaderKey";
import { CROSS_SHOP_BASE_COLOUR_MAP } from "./cross-shop-base-colour-map";

export const INTERACTIONS_VENN_CHART_COLOUR_MAP: Record<
  InteractionsVennMetricColumnHeaderKey,
  string
> = {
  ...CROSS_SHOP_BASE_COLOUR_MAP,
  AB: "#67A0A7",
  AC: "#8D6D8A",
  BC: "#A38E5F",
  ABC: "#63655F",
};
