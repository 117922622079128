import { type Data, type UniqueIdentifier } from "@dnd-kit/core";
import { type SimpleItem } from "../models/Item";
import { ContainerIdType, type SimpleZone } from "../models/Zone";
import {
  addPlaceholderItemAtIndex,
  insertItemIntoStructure,
  insertTemporaryZone,
  moveItemBack,
  updateItemColumn,
} from "./utilities";

/**
 * This function handles the changes in state when a user removes an item from the structure
 *
 * @param currentItem - The item being removed
 * @param droppableZones - The list of drop zones in the structure
 * @param setDroppableZones - Callback to modify the structure of droppable zones
 * @param items - The list of draggable items
 * @param setItems - Callback to modify the list of draggable items
 */
export const handleRemove = (
  currentItem: SimpleItem,
  droppableZones: SimpleZone[],
  setDroppableZones: (zones: SimpleZone[]) => void,
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void
) => {
  const foundZone = droppableZones.find(
    (zone) => zone.item?.id === currentItem.id
  );

  if (foundZone) {
    const newZones = droppableZones
      .filter((zone) => zone.item?.id !== currentItem.id)
      .filter((zone) => zone.item !== undefined);

    updateItemColumn(items, setItems, currentItem);

    setDroppableZones([...newZones]);
  }
};

/**
 * This function handles the changes in state when a user picks up a draggable item
 *
 * @param items - The list of draggable items
 * @param setItems - Callback to modify the list of draggable items
 * @param setActiveItem - Callback to modify the active item state
 * @param droppableZones - The list of drop zones in the structure
 * @param setDroppableZones - Callback to modify the structure of droppable zones
 * @param setSavedZoneState - Callback to modify the state that saves on drag state
 * @param currentEventData - Data from dnd-kit's DragStartEvent
 */
export const handleDragStart = (
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void,
  setActiveItem: (item: SimpleItem | undefined) => void,
  droppableZones: SimpleZone[],
  setDroppableZones: (zones: SimpleZone[]) => void,
  setSavedZoneState: (zones: SimpleZone[] | undefined) => void,
  currentEventData?: Data
) => {
  if (currentEventData) {
    setSavedZoneState(droppableZones);
    const activeItem = currentEventData as SimpleItem;
    setActiveItem(activeItem);
    const index = items.findIndex((item) => item.id === activeItem.id);
    addPlaceholderItemAtIndex(items, setItems, index);

    const newDroppableZones: SimpleZone[] = [];
    let previousZoneActive = false;
    for (const zone of droppableZones) {
      if (zone.item?.id === activeItem.id) {
        insertTemporaryZone(newDroppableZones, zone.containerId, zone.id);
        previousZoneActive = true;
      } else {
        if (previousZoneActive) {
          previousZoneActive = false;
        } else {
          insertTemporaryZone(newDroppableZones, zone.containerId);
        }

        newDroppableZones.push(zone);
      }
    }

    if (previousZoneActive) {
      if (activeItem.containerId === ContainerIdType.RANKED) {
        insertTemporaryZone(newDroppableZones, ContainerIdType.UNRANKED);
      } else {
        insertTemporaryZone(newDroppableZones, ContainerIdType.RANKED);
      }
    } else {
      insertTemporaryZone(newDroppableZones, ContainerIdType.RANKED);
      insertTemporaryZone(newDroppableZones, ContainerIdType.UNRANKED);
    }

    setDroppableZones([...newDroppableZones]);
  }
};

/**
 * This function handles the changes in state when a user drops a draggable item
 *
 * @param setActiveItem - Callback to modify the active item state
 * @param droppableZones - The list of drop zones in the structure
 * @param setDroppableZones - Callback to modify the structure of droppable zones
 * @param items - The list of draggable items
 * @param setItems - Callback to modify the list of draggable items
 * @param setSavedZoneState -- Callback to modify the saved zone state
 * @param savedZoneState - A list containing the state saved on Drag Start
 * @param overZone - Current zone being hovered over or undefined
 * @param overId - Id of the current zone or undefined
 * @param activeItem - The item being dropped
 */
export const handleDragEnd = (
  setActiveItem: (item: SimpleItem | undefined) => void,
  droppableZones: SimpleZone[],
  setDroppableZones: (zones: SimpleZone[]) => void,
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void,
  setSavedZoneState: (zones: SimpleZone[] | undefined) => void,
  savedZoneState?: SimpleZone[],
  overZone?: Data,
  overId?: UniqueIdentifier,
  activeItem?: SimpleItem
) => {
  if (!overZone || !overId || overZone.type !== "zone" || overZone.item) {
    moveItemBack(
      setActiveItem,
      items,
      setItems,
      setDroppableZones,
      activeItem,
      savedZoneState
    );
  } else {
    const canDrop = activeItem && overId && !overZone.item;
    if (canDrop) {
      insertItemIntoStructure(
        overId,
        activeItem,
        setActiveItem,
        droppableZones,
        setDroppableZones,
        items,
        setItems
      );
    }
  }

  setSavedZoneState(undefined);
};

/**
 * This function handles the changes in state when a user cancels a drag
 *
 * @param setActiveItem - Callback to modify the active item state
 * @param items - The list of draggable items
 * @param setItems - Callback to modify the list of draggable items
 * @param setDroppableZones - Callback to modify the structure of droppable zones
 * @param setSavedZoneState -- Callback to modify the saved zone state
 * @param savedZoneState - A list containing the state saved on Drag Start
 * @param activeItem - The item being dropped
 */
export const handleDragCancel = (
  setActiveItem: (item: SimpleItem | undefined) => void,
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void,
  setDroppableZones: (zones: SimpleZone[]) => void,
  setSavedZoneState: (zones: SimpleZone[] | undefined) => void,
  savedZoneState?: SimpleZone[],
  activeItem?: SimpleItem
) => {
  moveItemBack(
    setActiveItem,
    items,
    setItems,
    setDroppableZones,
    activeItem,
    savedZoneState
  );

  setSavedZoneState(undefined);
};
