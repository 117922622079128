import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type ExportFocalItemRequest } from "../models/DataTableDto";
import { keyMeasureTrendsApiSlice } from "./key-measure-trend-api-slice";

export const keyMeasureTrendsExportApiSlice =
  keyMeasureTrendsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportFocalItemRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `key measure trends focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const { useLazyDownloadTableQuery } = keyMeasureTrendsExportApiSlice;
