import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type SegmentsOverTimeSegmentItem } from "./CustomerProfilingSegmentsOverTimeResponseDto";

export enum FilterLabels {
  ChartSeries = "Chart series",
  CustomerSegment = "Customer segment",
  FocalItem = "Focal item",
  Segmentation = "Segmentation",
}

export enum ChartSeriesLabels {
  CustomerSegments = "Customer segments",
  FocalItem = "Focal item(s)",
}

export type DropdownFilter<T> = {
  activeOption: T | undefined;
  options: T[];
};

export type CustomerProfilingSegmentsOverTimeFilters = {
  chartSeriesActiveButtonIndex: number;
  focalItemFilter: DropdownFilter<HierarchySliceNodeDto>;
  segmentationActiveButtonIndex: number;
  segmentsFilter: DropdownFilter<SegmentsOverTimeSegmentItem>;
};
