import { ddLog } from "@quantium-enterprise/common-ui";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import {
  type ExportFocalItemRequest,
  type InitialTableRequest,
  type InitialTableResponse,
} from "../models/basket-quantities-data-table-models";
import { basketQuantitiesApiSlice } from "./basket-quantities-api-slice";
import { onTableDataReceived } from "./basket-quantities-slice";

export const basketQuantitiesDataTableApiSlice =
  basketQuantitiesApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: InitialTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataReceived(data));
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error downloading basket quantity focal item data",
              undefined,
              "error",
              error
            );
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTable/${divisionName}`,
        }),
      }),
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportFocalItemRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `basket quantity focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const { useLazyGetTableQuery, useLazyDownloadTableQuery } =
  basketQuantitiesDataTableApiSlice;
