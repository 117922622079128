import { Group } from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { ChildArrow } from "../../../child-arrow/ChildArrow";
import styles from "./Cell.module.css";

type StructureNameCellProps = {
  isSubRow: boolean;
  name: string;
};

export const StructureNameCell = ({
  isSubRow,
  name,
}: StructureNameCellProps) => (
  <Group className={classNames(styles.flex, styles.marginLeft)}>
    {isSubRow && (
      <div className={styles.arrow}>
        <ChildArrow />
      </div>
    )}
    <span aria-label={name} className={styles.rowValue}>
      {name}
    </span>
  </Group>
);
