import {
  type LocalHierarchyNodeSelection,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { type PricingLaddersLocalSelections } from "../services/pricing-ladders-slice";

export const getUserSelections = (
  localParameterSelections: PricingLaddersLocalSelections
) => {
  const userSelections: LocalSelectedValueDto[] = [];

  for (const [parameterID, values] of Object.entries(
    localParameterSelections
  )) {
    if (typeof values === "object" && "value" in values && values.value) {
      userSelections.push({
        id: parameterID,
        values: [values.value as string],
      });
    } else if (parameterID === "LocationHierarchy") {
      const castValue = values as LocalHierarchyNodeSelection;
      userSelections.push({
        id: parameterID,
        values: [castValue.nodeNumber.toString()],
      });
    } else if (
      Array.isArray(values) &&
      values.length > 0 &&
      parameterID !== "ViewBy"
    )
      userSelections.push({
        id: parameterID,
        values: values.map((value) => value.value.toString()),
      });
  }

  return userSelections;
};

export default getUserSelections;
