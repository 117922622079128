type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};
export const KeyDriversOverTimeIcon = ({
  className,
  coloured,
  text,
}: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Key drivers over time report"}</title>
      <path
        d="M11.0565 7.25H6.02517V9.03118L6.85406 8.4671C7.7533 7.85516 8.96363 7.96672 9.73427 8.73256L11.0565 10.0466V7.25Z"
        fill={colour3}
      />
      <path
        d="M11.0565 15.7034L7.92193 12.5884L6.02517 13.8791V18.5C6.02517 18.6381 6.1378 18.75 6.27673 18.75H10.805C10.9439 18.75 11.0565 18.6381 11.0565 18.5V15.7034Z"
        fill={colour3}
      />
      <path d="M6.02517 6H11.0565V7.25H6.02517V6Z" fill={colour2} />
      <path
        d="M6.02517 2.25C6.02517 2.11193 6.1378 2 6.27673 2H10.805C10.9439 2 11.0565 2.11193 11.0565 2.25V6H6.02517V2.25Z"
        fill={colour1}
      />
      <path
        d="M13.0691 17.7034V21.75C13.0691 21.8881 13.1817 22 13.3206 22H17.8489C17.9878 22 18.1004 21.8881 18.1004 21.75V18.7907L16.8374 19.3688C15.9764 19.7628 14.9596 19.5821 14.2893 18.9161L13.0691 17.7034Z"
        fill={colour1}
      />
      <path
        d="M18.1004 14.3869V11.75H13.0691V12.0466L16.268 15.2255L18.1004 14.3869Z"
        fill={colour1}
      />
      <path d="M13.0691 7.25H18.1004V11.75H13.0691V7.25Z" fill={colour3} />
      <path
        d="M13.0691 5.75C13.0691 5.61193 13.1817 5.5 13.3206 5.5H17.8489C17.9878 5.5 18.1004 5.61193 18.1004 5.75V7.25H13.0691V5.75Z"
        fill={colour2}
      />
      <path
        d="M7.91862 11.2864L3.56936 14.2039C3.10872 14.5129 2.48325 14.3923 2.17233 13.9345C1.8614 13.4767 1.98276 12.8552 2.44339 12.5462L7.30423 9.28556C7.80027 8.95282 8.46352 9.01347 8.89013 9.43058L16.0348 16.4162L20.5523 14.2262C21.0518 13.9841 21.6543 14.1902 21.8979 14.6866C22.1416 15.183 21.9342 15.7817 21.4347 16.0238L16.4374 18.4463C15.9583 18.6785 15.3838 18.5854 15.0038 18.2139L7.91862 11.2864Z"
        fill={colour2}
      />
    </svg>
  );
};
