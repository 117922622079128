export enum PrimaryMetricKey {
  Avg = "Avg price",
  AvgPricePerVolume = "Avg price per volume",
  AvgPromo = "Avg promotion price",
  AvgPromoPricePerVolume = "Avg promotion price per volume",
  MaxAvg = "Max avg price",
  MaxAvgPricePerVolume = "Max avg price per volume",
}

export enum SecondaryMetric {
  None = "No additional metric selected",
}

export enum ViewByOptions {
  Avg = "AvgPrice",
  AvgPricePerVolume = "AvgPricePerVolume",
}

export const DEFAULT_SALES_COUNT = 20;

export const PricingLaddersFeatureFlags = {
  ReportExport: "CO3-3273-report-export",
};
