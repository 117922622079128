export enum PURCHASE_ACTIVITY_TABS {
  Channel = "Channel",
  Promotion = "Promotion",
  Segmentation = "Segmentation",
  TrialAndRepeat = "Trial and repeat",
}

export const enum PURCHASE_ACTIVITY_VIEW_TYPE_TABS {
  Chart = "Chart",
  Table = "Table",
}

export const enum PURCHASE_ACTIVITY_SUMMARY_TYPE_TABS {
  Cumulative = "Cumulative",
  Weekly = "Weekly",
}

export const enum PURCHASE_ACTIVITY_DATA_TYPE_TABS {
  Actual = "Actual",
  Share = "Share",
}
