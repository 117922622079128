import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type GainsAndLossReportTopDrawerExportRequestDto } from "../models/GainsAndLossReportTopDrawerExportRequestDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossDataTableApiSlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      downloadTable: builder.query<
        Blob,
        {
          divisionName: string;
          payload: GainsAndLossReportTopDrawerExportRequestDto;
        }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `gains and loss focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const { useLazyDownloadTableQuery } = gainsAndLossDataTableApiSlice;
