import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { type CustomerLoyaltyReportletRequestDto } from "../../models/CustomerLoyaltyReportletRequestDto";
import { useGetCustomerLoyaltyReportletQuery } from "../../services/customer-loyalty-reportlet-api-slice";
import {
  onCustomerLoyaltyReportletResponseReceived,
  selectFocalItems,
  selectLocalParameters,
  selectLocalParametersInitialised,
  selectLocationNodeSelection,
  selectReportId,
  selectTableRows,
  selectTopDrawerActiveTab,
} from "../../services/customer-loyalty-slice";
import { getSegmentList } from "../../utils/getLocalSelectionList";
import { CustomerLoyaltyScatterChart } from "./CustomerLoyaltyScatterChart";

export const CustomerLoyaltyReportlet = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();
  const { id } = useParams();

  const focalItems = useSelector(selectFocalItems);
  const localParameters = useSelector(selectLocalParameters);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const topDrawerActiveTab = useSelector(selectTopDrawerActiveTab);
  const tableResponse = useSelector(selectTableRows);
  const locationNodeSelection = useSelector(selectLocationNodeSelection);
  const reportId = useSelector(selectReportId);

  const localSegmentSelections = useMemo(
    () => getSegmentList(localParameters),
    [localParameters]
  );

  const payload: CustomerLoyaltyReportletRequestDto = useMemo(
    () => ({
      focalItems,
      levelOfAnalysis: topDrawerActiveTab ?? "",
      segmentSelections: localSegmentSelections,
      reportId: id ?? "",
      locationNodeNumber: locationNodeSelection.nodeNumber,
    }),
    [
      focalItems,
      id,
      localSegmentSelections,
      locationNodeSelection.nodeNumber,
      topDrawerActiveTab,
    ]
  );

  const {
    data: reportletData,
    isFetching,
    error,
  } = useGetCustomerLoyaltyReportletQuery(
    { division: divisionName, payload },
    {
      skip:
        !id ||
        id !== reportId ||
        !localParametersInitialised ||
        !topDrawerActiveTab ||
        focalItems.length === 0,
    }
  );

  useEffect(() => {
    if (error) {
      let message = "";
      if ("status" in error) {
        message = "error" in error ? error.error : JSON.stringify(error.data);
      } else {
        message = error.message ?? "unknown error";
      }

      ddLog(
        "Error retrieving customer loyalty chart data",
        {},
        "error",
        new Error(message)
      );
    }
  }, [error]);

  useEffect(() => {
    if (reportletData) {
      dispatch(onCustomerLoyaltyReportletResponseReceived(reportletData));
    }
  }, [reportletData, dispatch]);

  return (
    <ReportletAccordion
      subtitle="Understand how loyal customers are within your selected universe."
      title="Customer loyalty"
    >
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={isFetching || !localParametersInitialised}
          noData={
            (localParametersInitialised && tableResponse.length === 0) ||
            !reportletData
          }
        >
          <CustomerLoyaltyScatterChart reportParameters={reportParameters} />
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
