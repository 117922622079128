import { CustomerGroupType } from "@quantium-enterprise/common-ui";
import { type RouteObject } from "react-router-dom";
import { CustomerGroupParametersBody } from "../common/wizard/parameters/CustomerGroupParametersBody";

export const route: RouteObject = {
  element: (
    <CustomerGroupParametersBody
      customerGroupUri={CustomerGroupType.NewLapsedContinuous}
    />
  ),
  path: CustomerGroupType.NewLapsedContinuous,
};

export const routeWithId: RouteObject = {
  element: (
    <CustomerGroupParametersBody
      customerGroupUri={CustomerGroupType.NewLapsedContinuous}
    />
  ),
  path: `${CustomerGroupType.NewLapsedContinuous}/:groupId`,
};
