export type FolderIconProps = {
  folderColour?: string;
};

export const FolderIcon = ({ folderColour }: FolderIconProps) => (
  <svg
    data-testid="folder-or-report-icon"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33854 3.32812H3.33854C2.42188 3.32812 1.68021 4.07812 1.68021 4.99479L1.67188 14.9948C1.67188 15.9115 2.42188 16.6615 3.33854 16.6615H16.6719C17.5885 16.6615 18.3385 15.9115 18.3385 14.9948V6.66146C18.3385 5.74479 17.5885 4.99479 16.6719 4.99479H10.0052L8.33854 3.32812Z"
      fill={folderColour ?? "var(--folder-icon-colour)"}
    />
  </svg>
);
