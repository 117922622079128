import {
  type GeneratedReportDto,
  type SharedUserDto,
  FeatureFlag,
  ReportStatus,
  getTimeAgoString,
  TIME_ELAPSED_UPDATE_INTERVAL_MS,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  type RowSelectionState,
  type ColumnDef,
  type CellContext,
} from "@tanstack/react-table";
import cx from "classnames";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { SharedUserIconDisplay } from "components-ui/src/shared-user-icon-display/SharedUserIconDisplay";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { useState, useEffect } from "react";
import styles from "./MyReportsGrid.module.css";
import { ReportStatusTag } from "./ReportStatusTag";

const defaultMyReportsColumnWidthOverride = {
  minSize: 80,
  maxSize: 640,
};

export type MyReportsGridProps = {
  allReportSharedUsers: SharedUserDto[];
  myReportsNavigator: (reportId: string) => void;
  onRowDoubleClick?: (
    event: React.MouseEvent<HTMLTableRowElement>,
    rowId: number,
    rowData: GeneratedReportDto
  ) => void;
  reports: GeneratedReportDto[];
  selectedReportId?: string;
};

const NameHeader = () => <span className={cx(styles.nameHeader)}>Name</span>;
const NameCell = (info: CellContext<GeneratedReportDto, unknown>) => (
  <div
    className={cx(styles.name, {
      [styles.failed]: info.row.original.reportStatus === ReportStatus.Failed,
    })}
    title={info.getValue<string>()}
  >
    {info.getValue<string>()}
  </div>
);

const StatusHeader = () => <span>Status</span>;
const StatusCell = (info: CellContext<GeneratedReportDto, unknown>) => (
  <span className={styles.status}>
    <ReportStatusTag status={info.getValue<ReportStatus>()} />
  </span>
);

const CreatedHeader = () => <span className={styles.sortedBy}>Created</span>;
const TimeElapsedCell = ({
  info,
}: {
  info: CellContext<GeneratedReportDto, unknown>;
}) => {
  const [timeAgoString, setTimeAgoString] = useState(
    getTimeAgoString(info.getValue<string>())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgoString(getTimeAgoString(info.getValue<string>()));
    }, TIME_ELAPSED_UPDATE_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [info]);

  return <span className={styles.createdDate}>{timeAgoString}</span>;
};

const DatasetHeader = () => <span>Dataset</span>;
const DatasetCell = (
  info: CellContext<GeneratedReportDto, unknown> | undefined
) => {
  const { transactionSources: availableTransactionSources } = useDivision();
  return (
    <span className={styles.transactionSourceIconContainer}>
      {info?.getValue<TransactionSource[]>().map((source) => (
        <TransactionSourceIcon
          availableTransactionSources={availableTransactionSources}
          key={source}
          transactionSource={source}
        />
      ))}
    </span>
  );
};

const SharedHeader = () => <span>Shared</span>;
const SharedCell = (
  allReportSharedUsers: SharedUserDto[],
  info: CellContext<GeneratedReportDto, unknown>
) => (
  <SharedUserIconDisplay
    users={allReportSharedUsers.filter((user) =>
      info.row.original.sharedWithUserIds.includes(user.salesforceUserId)
    )}
  />
);

const MyReportsGrid = ({
  allReportSharedUsers,
  myReportsNavigator,
  reports,
  onRowDoubleClick,
  selectedReportId,
}: MyReportsGridProps) => {
  const flags = useFlags();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    const preSelectedReport: RowSelectionState = {};

    for (const [index, report] of reports.entries()) {
      if (selectedReportId && report.id === selectedReportId) {
        const indexAsString = index.toString();
        preSelectedReport[indexAsString] = true;
      }
    }

    setRowSelection(preSelectedReport);
  }, [reports, selectedReportId]);

  const defaultMyReportColumns: Array<ColumnDef<GeneratedReportDto>> = [
    {
      accessorFn: (row) => row.name,
      cell: NameCell,
      footer: (properties) => properties.column.id,
      header: NameHeader,
      id: "name",
      size: 720,
    },
    {
      accessorFn: (row) => row.reportStatus,
      cell: StatusCell,
      footer: (properties) => properties.column.id,
      header: StatusHeader,
      id: "reportStatus",
    },
    {
      accessorFn: (row) => row.createDateUtc,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => <TimeElapsedCell info={info} />,
      footer: (properties) => properties.column.id,
      header: CreatedHeader,
      id: "created",
      sortingFn: (a, b): number =>
        new Date(a.original.createDateUtc).getTime() -
        new Date(b.original.createDateUtc).getTime(),
    },
    {
      accessorFn: (row) => row.dataSource,
      cell: DatasetCell,
      footer: (properties) => properties.column.id,
      header: DatasetHeader,
      id: "data",
    },
  ];

  if (flags[FeatureFlag.SharingReports]) {
    defaultMyReportColumns.push({
      accessorFn: (row) => row.sharedWithUserIds,
      cell: (info) => SharedCell(allReportSharedUsers, info),
      footer: (properties) => properties.column.id,
      header: SharedHeader,
      id: "shared",
    });
  }

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    rowId: number,
    rowData: GeneratedReportDto
  ) => {
    myReportsNavigator(rowData.id);
  };

  return (
    <BasicTable
      className={styles.myReportsTable}
      columns={defaultMyReportColumns}
      data={reports}
      defaultColumnWidthOverride={defaultMyReportsColumnWidthOverride}
      enableRowSelection
      onRowClick={onRowClick}
      onRowDoubleClick={onRowDoubleClick}
      rowSelectionState={rowSelection}
      sorting={reports.length > 0 ? [{ desc: true, id: "created" }] : []}
    />
  );
};

export { MyReportsGrid };
