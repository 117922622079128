import { useDroppable } from "@dnd-kit/core";
import classnames from "classnames";
import React from "react";
import { ChildArrow } from "../../child-arrow/ChildArrow";
import { TestId } from "../constants";
import { RemovableItem } from "../item/RemovableItem";
import { type Item } from "../models/Item";
import { type Zone } from "../models/Zone";
import styles from "./DroppableZone.module.css";

export type DroppableZoneProps = {
  newEntry?: boolean;
  onRemove: (item: Item, zone: Zone) => void;
  style?: React.CSSProperties;
  validDropLocation: boolean;
  zone: Zone;
};

export const DroppableZone = ({
  zone,
  validDropLocation,
  onRemove,
  newEntry,
  style,
}: DroppableZoneProps) => {
  const { isOver, setNodeRef } = useDroppable({
    data: {
      ...zone,
    },
    id: zone.id,
  });

  return (
    <div
      className={classnames(
        styles.droppableZone,
        validDropLocation && styles.valid,
        isOver && validDropLocation && styles.isOver,
        { [styles.hasItem]: zone.item }
      )}
      data-testid={TestId.DroppableZone}
      ref={setNodeRef}
      style={{ ...style }}
    >
      {zone.item && (
        <>
          {newEntry && (
            <div className={styles.arrowIcon} data-testid={TestId.ArrowIcon}>
              <ChildArrow />
            </div>
          )}
          <div data-testid={TestId.RemovableItem}>
            <RemovableItem
              item={zone.item}
              onRemove={(item: Item) => {
                onRemove(item, zone);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
