export enum TestId {
  ArrowIcon = "arrow-icon",
  CountTag = "count-tag",
  DragIcon = "drag-icon",
  DraggableItem = "draggable-item",
  DroppableZone = "droppable-zone",
  FolderIcon = "folder-icon",
  ItemList = "item-list",
  ItemName = "item-name",
  ListHeading = "list-heading",
  NonDraggableItem = "non-draggable-item",
  RemovableItem = "removable-item",
  RemoveIcon = "remove-icon",
  ShowLessButton = "show-less-button",
  ShowMoreButton = "show-more-button",
}
