/* eslint-disable id-length */
import { CROSS_SHOP_BASE_COLOUR_MAP } from "./cross-shop-base-colour-map";

export const INTERACTIONS_VENN_TABLE_COLOUR_MAP = {
  A: [CROSS_SHOP_BASE_COLOUR_MAP.A],
  B: [CROSS_SHOP_BASE_COLOUR_MAP.B],
  C: [CROSS_SHOP_BASE_COLOUR_MAP.C],
  AB: [CROSS_SHOP_BASE_COLOUR_MAP.A, CROSS_SHOP_BASE_COLOUR_MAP.B],
  AC: [CROSS_SHOP_BASE_COLOUR_MAP.A, CROSS_SHOP_BASE_COLOUR_MAP.C],
  BC: [CROSS_SHOP_BASE_COLOUR_MAP.B, CROSS_SHOP_BASE_COLOUR_MAP.C],
  ABC: [
    CROSS_SHOP_BASE_COLOUR_MAP.A,
    CROSS_SHOP_BASE_COLOUR_MAP.B,
    CROSS_SHOP_BASE_COLOUR_MAP.C,
  ],
};
