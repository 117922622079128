import Papa from "papaparse";

const segmentNameHeader = "Segment name";
const customerIdHeader = "Customer identifier";

const templateColumns = [segmentNameHeader, customerIdHeader];
const template = "data:text/csv;charset=utf-8," + templateColumns.join(",");
export const templateUri = encodeURI(template);

export const maxMbSize = 512;

type Customer = {
  [customerIdHeader]: string;
  [segmentNameHeader]: string;
};

export const validateFile = (
  file: File,
  handleErrors: (errors: string[]) => void
): void => {
  if (file.size > 1_024 * 1_024 * maxMbSize) {
    handleErrors([`File size exceeds the ${maxMbSize}MB limit.`]);
    return;
  }

  if (file.type !== "text/csv") {
    handleErrors([
      "File type is incorrect. Please use the csv template above.",
    ]);
    return;
  }

  const errors = new Set<string>();
  const segmentCounts: Record<string, number> = {};
  let rowCount = 0;

  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    delimiter: ",",
    transformHeader: (header: string, index: number) => {
      if (index === 0) return segmentNameHeader;
      if (index === 1) return customerIdHeader;
      return header;
    },
    step: (results: Papa.ParseStepResult<Customer>, parser) => {
      const segmentName = results.data[segmentNameHeader];
      const customerId = results.data[customerIdHeader];
      rowCount++;

      if (!segmentName || segmentName.trim() === "") {
        errors.add(`Row ${rowCount} is missing a segment name.`);
      }

      if (!customerId || customerId.trim() === "") {
        errors.add(`Row ${rowCount} is missing a customer identifier.`);
      }

      if (errors.size > 5) {
        parser.abort();
        return;
      }

      const segmentNameTrimmed = segmentName.trim();
      if (!segmentCounts[segmentNameTrimmed]) {
        segmentCounts[segmentNameTrimmed] = 0;
      }

      segmentCounts[segmentNameTrimmed] += 1;
    },
    complete: (results) => {
      if (results.meta.aborted) {
        handleErrors([...errors]);
        return;
      }

      const segmentNames = Object.keys(segmentCounts);

      if (rowCount === 0) {
        errors.add("At least one segment is required to make a group.");
      }

      if (segmentNames.length > 10) {
        errors.add("At most 10 segments can be in a group.");
      }

      if (segmentNames.some((segment) => segmentCounts[segment] < 100)) {
        errors.add("At least 100 customers are required per segment.");
      }

      handleErrors([...errors]);
    },
    error: (error) => {
      handleErrors([error.message]);
    },
  });
};
