import { formatStringDate } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelHierarchySelectionParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isLocationSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import { type BasketLimitsLocalSelection } from "../services/basket-limits-slice";

export const getDefaultSelections = (
  localParameters: SidePanelHierarchySelectionParameter[]
): BasketLimitsLocalSelection => {
  const selections: BasketLimitsLocalSelection = {
    basketLimit: 0,
    location: [],
    time: "",
    timePeriodLength: "",
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const focusPeriod = parameter.focusPeriod.label;
          const focusStartDateFormatted = formatStringDate(
            parameter.focusPeriod.startDate
          );
          const focusEndDateFormatted = formatStringDate(
            parameter.focusPeriod.endDate
          );
          selections.time = `${focusPeriod} (focus period ${focusStartDateFormatted} - ${focusEndDateFormatted})`;
          selections.timePeriodLength = focusPeriod;
        }

        break;

      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          for (const selection of parameter.selections) {
            selections.location.push({
              name: selection.hierarchyItem.name,
              shortName: hierarchyLevelDisplayLabel(
                selection.hierarchyItem.shortName
              ),
            });
          }
        }

        break;

      default:
        break;
    }
  }

  return selections;
};
