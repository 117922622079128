import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "../../store";

export const selectCompareMetricsState = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.compareMetrics
);

export const selectContributionMetrics = createSelector(
  (state: RootState) => state.compareMetrics.contributionMetrics,
  (contributionMetrics) => contributionMetrics
);

export const selectFocalItems = createSelector(
  (state: RootState) => state.compareMetrics.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectFocalItemParents = createSelector(
  (state: RootState) => state.compareMetrics.focalItemParents,
  (focalItemParents) => focalItemParents
);

export const selectXAxisSelection = createSelector(
  (state: RootState) => state.compareMetrics.persistedSelections.xAxisSelection,
  (xAxisSelection) => xAxisSelection
);

export const selectChartContentSelection = createSelector(
  (state: RootState) =>
    state.compareMetrics.persistedSelections.chartContentSelection,
  (chartContentSelection) => chartContentSelection
);

export const selectMetricSelectionSelection = createSelector(
  (state: RootState) =>
    state.compareMetrics.persistedSelections.metricSelection,
  (metricSelection) => metricSelection
);

export const selectReportletBreakdownOptions = createSelector(
  (state: RootState) => state.compareMetrics.reportletBreakdownOptions,
  (reportletBreakdownOptions) => reportletBreakdownOptions
);

export const selectInitialDataRecieved = createSelector(
  (state: RootState) => state.compareMetrics.focalItemTableInitialised,
  (focalItemTableInitialised) => focalItemTableInitialised
);

export const selectLocalParametersSelections = createSelector(
  (state: RootState) =>
    state.compareMetrics.persistedSelections.localParametersSelections,
  (localParametersSelections) => localParametersSelections
);

export const selectLocalParametersConfig = createSelector(
  (state: RootState) => state.compareMetrics.localParametersConfig,
  (localParametersConfig) => localParametersConfig
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.compareMetrics.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectTableMetaData = createSelector(
  (state: RootState) => state.compareMetrics.focalItemTableMetaData,
  (focalItemTableMetaData) => focalItemTableMetaData
);

export const selectTableRows = createSelector(
  (state: RootState) => state.compareMetrics.focalItemTableRows,
  (focalItemTableRows) => focalItemTableRows
);

export const selectSearchQuery = createSelector(
  (state: RootState) => state.compareMetrics.focalItemSearchText,
  (focalItemSearchText) => focalItemSearchText
);

export const selectFocalItemTableRowSelectionState = createSelector(
  (state: RootState) =>
    state.compareMetrics.persistedSelections.focalItemTableRowSelectionState,
  (focalItemTableRowSelectionState) => focalItemTableRowSelectionState
);

export const selectMetadata = createSelector(
  (state: RootState) => state.compareMetrics.metaData,
  (metaData) => metaData
);

export const selectFocalItemSelection = createSelector(
  (state: RootState) =>
    state.compareMetrics.persistedSelections.focalItemSelection,
  (focalItemSelection) => focalItemSelection
);

export const selectButtonSelections = createSelector(
  (state: RootState) => state.compareMetrics.buttonSelections,
  (buttonSelections) => buttonSelections
);

export const selectReportletData = createSelector(
  (state: RootState) => state.compareMetrics.reportletData,
  (reportletData) => reportletData
);

export const selectReportletDataReceived = createSelector(
  (state: RootState) => state.compareMetrics.reportletDataReceived,
  (reportletDataReceived) => reportletDataReceived
);

export const selectShowChartDataLabels = createSelector(
  (state: RootState) => state.compareMetrics.showChartDataLabels,
  (showChartDataLabels) => showChartDataLabels
);

export const selectReportletMetricOptions = createSelector(
  (state: RootState) => state.compareMetrics.reportletMetricOptions,
  (reportletMetricOptions) => reportletMetricOptions
);

export const selectMetricDataTypes = createSelector(
  (state: RootState) => state.compareMetrics.metricDataTypes,
  (metricDataTypes) => metricDataTypes
);
