import {
  EMIT_TOAST_DURATION,
  type SharedUserDto,
  type HierarchyGroupWithSharingDto,
  HierarchyType,
  isEnumValue,
  FeatureFlag,
  useGetUsersByIdQuery,
  useGetGroupsAndFoldersQuery,
  useLazyDownloadGroupQuery,
  useRenameGroupMutation,
  type FolderOrGroupDto,
  useRenameGroupFolderMutation,
  GroupType,
  type GroupFolder,
} from "@quantium-enterprise/common-ui";
import { type RenameGroupRequestDto } from "@quantium-enterprise/common-ui/src/models/group-dto";
import { type RenameGroupFolderRequest } from "@quantium-enterprise/common-ui/src/models/rename-group-folder-dto";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
} from "@quantium-enterprise/qds-react";
import { type CellContext, type ColumnDef } from "@tanstack/react-table";
import { FolderIcon } from "components-ui/src/assets/icons/FolderIcon";
import { EditableField } from "components-ui/src/editable-field/EditableField";
import { ExpandChevron, HierarchyGroupIcon } from "components-ui/src/icons";
import { SharedUserIconDisplay } from "components-ui/src/shared-user-icon-display/SharedUserIconDisplay";
import sortStyles from "components-ui/src/tables/common/column-sort/ColumnSort.module.css";
import { folderPadding } from "components-ui/src/tables/common/utils";
import { useCallback, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  selectExpandedIds,
  selectFoldersAndGroups,
  setEditingState,
  setFolderExpandedState,
  selectRenameState,
  setRenameId,
  selectSearchText,
} from "../../../states/group-list-slice";
import {
  TimeElapsedCell,
  TypeCell,
  GroupListTable,
  QuickActionsCell,
} from "../GroupListTable";
import styles from "./HierarchyGroupListTable.module.css";

const timeElapsedCell = (info: CellContext<FolderOrGroupDto, unknown>) =>
  info.getValue() === "" ? (
    ""
  ) : (
    <TimeElapsedCell timestamp={info.getValue<string>()} />
  );

const typeCell = (info: CellContext<FolderOrGroupDto, unknown>) => (
  <TypeCell
    icon={<HierarchyGroupIcon evaluationType={info.getValue<string>()} />}
    text={info.getValue<string>()}
  />
);

const SharedCell = (
  allGroupSharedUsers: SharedUserDto[],
  info: CellContext<FolderOrGroupDto, unknown>
) =>
  info.row.original.isFolder ? (
    ""
  ) : (
    <SharedUserIconDisplay
      users={allGroupSharedUsers.filter((user) =>
        (
          info.row.original as HierarchyGroupWithSharingDto
        ).sharedWithUserIds?.includes(user.salesforceUserId)
      )}
    />
  );

const NameHeader = () => (
  <span>
    Name
    <span className={`${sortStyles.sortIcon} ${sortStyles.sortIconDescending}`}>
      descending
    </span>
  </span>
);

export type HierarchyGroupListTableProps = {
  groupType: string;
  handleDeleteFolder: (folderId: string) => void;
  handleDeleteGroup: (groupId: string) => void;
  handleMoveFolder: (group: FolderOrGroupDto | undefined) => void;
  handleMoveGroup: (group: FolderOrGroupDto | undefined) => void;
  onCheckboxSelect: (groupId: string) => void;
  setContextMenuGroup: (group: FolderOrGroupDto | undefined) => void;
  setFocalGroup: (group: FolderOrGroupDto | undefined) => void;
};

export const HierarchyGroupListTable = ({
  groupType,
  handleDeleteFolder,
  handleDeleteGroup,
  handleMoveFolder,
  handleMoveGroup,
  onCheckboxSelect,
  setFocalGroup,
  setContextMenuGroup,
}: HierarchyGroupListTableProps) => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();
  const flags = useFlags();
  const foldersAndGroups = useSelector(selectFoldersAndGroups);
  const expandedIds = useSelector(selectExpandedIds);
  const renameState = useSelector(selectRenameState);
  const searchTerm = useSelector(selectSearchText);

  const filteredFoldersAndGroups = useMemo(
    () =>
      searchTerm.length > 0
        ? foldersAndGroups
        : foldersAndGroups.filter(
            (x) => !x.parentId || expandedIds.includes(x.parentId)
          ),
    [expandedIds, foldersAndGroups, searchTerm]
  );

  const getDefaultGroupsPath = useCallback(
    () =>
      generatePath("/:division/groups/:groupType", {
        division: divisionName,
        groupType,
      }),
    [divisionName, groupType]
  );

  const { isFetching: isGroupListFetching, isUninitialized } =
    useGetGroupsAndFoldersQuery(
      {
        divisionName,
        hierarchyType: groupType,
      },
      { skip: !divisionName || !isEnumValue(HierarchyType)(groupType) }
    );

  const setIsEditingLocal = (editing: boolean) => {
    dispatch(setEditingState({ editing }));
  };

  const [downloadGroupTrigger] = useLazyDownloadGroupQuery();

  const [renameGroupFolderMutation] = useRenameGroupFolderMutation();
  const [renameGroupMutation] = useRenameGroupMutation();

  const renameFolderTrigger = async (value: string) => {
    const hierarchyType: GroupType =
      GroupType[groupType as keyof typeof GroupType];
    const payload: RenameGroupFolderRequest = {
      folderId: renameState.id ?? "",
      hierarchyType,
      proposedName: value,
    };
    try {
      await renameGroupFolderMutation({ divisionName, payload }).unwrap();
    } catch (error) {
      // @ts-expect-error cant cast it into the right type to access the status
      const errorStatus = error?.status;
      const is409 = errorStatus === 409;
      const content = is409
        ? "A folder with the same name already exists."
        : "An unknown error has occurred";
      const heading = is409 ? "Folder creation failed." : "Unknown";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
        }
      );
    }

    dispatch(setRenameId({ id: undefined }));
    dispatch(setEditingState({ editing: false }));
    return true;
  };

  const renameGroupTrigger = async (value: string) => {
    const hierarchyType: GroupType =
      GroupType[groupType as keyof typeof GroupType];
    const renameRequest: RenameGroupRequestDto = {
      groupId: renameState.id ?? "",
      hierarchyType,
      proposedName: value,
    };
    try {
      await renameGroupMutation({ divisionName, renameRequest }).unwrap();
    } catch (error) {
      // @ts-expect-error cant cast it into the right type to access the status
      const errorStatus = error?.status;
      const is409 = errorStatus === 409;
      const content = is409
        ? "Please use a group name that is unique."
        : "An unknown error has occurred";
      const heading = is409 ? "Rename error" : "Unknown error";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
        }
      );
    }

    dispatch(setRenameId({ id: undefined }));
    return true;
  };

  const { data: allGroupSharedUsers, isLoading: isAllGroupSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds: foldersAndGroups.flatMap((group) =>
            group.isFolder
              ? []
              : (group as HierarchyGroupWithSharingDto).sharedWithUserIds ?? []
          ),
        },
      },
      {
        skip:
          foldersAndGroups.length === 0 || !flags[FeatureFlag.SharingGroups],
      }
    );

  const setFocalGroupById = useCallback(
    (id: string | undefined) => {
      const groupOrFolder = foldersAndGroups.find((row) => row.id === id);
      if (!groupOrFolder) return;
      setFocalGroup(groupOrFolder);
    },
    [foldersAndGroups, setFocalGroup]
  );

  type NameCellProps = {
    depth: number;
    folderColour?: string;
    hasChildren: boolean;
    hierarchyType: string;
    id?: string;
    isFolder: boolean;
    name: string;
  };

  const useNameCell = (info: CellContext<FolderOrGroupDto, unknown>) => {
    const {
      id,
      depth,
      folderColour,
      isFolder,
      hasChildren,
      hierarchyType,
      name,
    } = info.getValue<NameCellProps>();

    const toggleFolder = (): void => {
      if (!id) return;

      if (isFolder) {
        dispatch(
          setFolderExpandedState({ id, isExpanded: !expandedIds.includes(id) })
        );
      }
    };

    const setFocalFolder = (): void => {
      if (!id) return;

      if (groupId === id) {
        navigate(getDefaultGroupsPath());
        setFocalGroupById(undefined);
      } else if (isFolder) {
        setFocalGroupById(id);
      }
    };

    const stylePadding = { paddingLeft: folderPadding(isFolder, depth) };

    const editableFieldContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          dispatch(setRenameId({ id: undefined }));
        }
      };

      const container = editableFieldContainerRef.current;
      container?.addEventListener("keydown", handleKeyDown);

      return () => {
        container?.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    return (
      <div className={styles.row} style={stylePadding}>
        <div
          onClick={(event) => {
            if (renameState.id === id) {
              event.stopPropagation();
            } else {
              setFocalFolder();
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder();
            }
          }}
          ref={editableFieldContainerRef}
          role="button"
          style={{ display: "flex", alignItems: "center" }}
          tabIndex={0}
        >
          {isFolder ? (
            <>
              {hasChildren ? (
                <ExpandChevron
                  isCompact={false}
                  isExpanded={expandedIds.includes(id ?? "")}
                  isLoading={false}
                  onClick={() => toggleFolder()}
                />
              ) : (
                <div className={styles.expandChevronSpacer} />
              )}
              <FolderIcon
                folderColour={
                  folderColour &&
                  folderColour.toLowerCase() !== "white" &&
                  folderColour.toLowerCase() !== "#ffffff"
                    ? folderColour
                    : undefined
                }
              />
              <EditableField
                editableFieldState={{
                  isEditing: renameState.id === id,
                  toggleEditing: setIsEditingLocal,
                }}
                minCharacters={1}
                onlyExternalState
                save={renameFolderTrigger}
                stopEditing={() => dispatch(setRenameId({ id: undefined }))}
                value={name}
              />
            </>
          ) : (
            <>
              <HierarchyGroupIcon
                className={styles.nameIcon}
                hierarchyType={hierarchyType}
              />
              <EditableField
                editableFieldState={{
                  isEditing: renameState.id === id,
                  toggleEditing: setIsEditingLocal,
                }}
                onlyExternalState
                save={renameGroupTrigger}
                stopEditing={() => dispatch(setRenameId({ id: undefined }))}
                value={name}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const handleMove = useCallback(
    (id: string | undefined) => {
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      if (group.isFolder) {
        handleMoveFolder(group);
      } else {
        handleMoveGroup(group);
      }
    },
    [foldersAndGroups, handleMoveFolder, handleMoveGroup]
  );

  const handleShare = useCallback(
    (id: string | undefined) => {
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      setContextMenuGroup(group);
    },
    [foldersAndGroups, setContextMenuGroup]
  );

  const handleRename = useCallback(
    (id: string | undefined) => {
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      dispatch(setRenameId({ id }));
      dispatch(setEditingState({ editing: true }));
    },
    [dispatch, foldersAndGroups]
  );

  const handleDownload = useCallback(async () => {
    if (groupId) {
      await downloadGroupTrigger({ divisionName, groupId });
    }
  }, [downloadGroupTrigger, divisionName, groupId]);

  const handleDelete = useCallback(
    (id: string | undefined) => {
      if (!id) return;
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      if (group.isFolder) {
        handleDeleteFolder(group.id as string);
      } else {
        handleDeleteGroup(group.id as string);
      }
    },
    [foldersAndGroups, handleDeleteFolder, handleDeleteGroup]
  );

  const quickActionsCell = useCallback(
    (info: CellContext<FolderOrGroupDto, unknown>) => (
      <QuickActionsCell
        groupId={info.getValue<string>()}
        handleDelete={handleDelete}
        handleDownload={
          flags[FeatureFlag.DownloadGroups] && groupType === GroupType.Product
            ? handleDownload
            : undefined
        }
        handleMove={
          flags[FeatureFlag.FoldersForGroups] ? handleMove : undefined
        }
        handleRename={handleRename}
        handleShare={handleShare}
      />
    ),
    [
      handleDelete,
      handleDownload,
      groupType,
      flags,
      handleMove,
      handleRename,
      handleShare,
    ]
  );

  const columns: Array<ColumnDef<FolderOrGroupDto>> = [
    {
      accessorFn: (row: FolderOrGroupDto): NameCellProps => ({
        id: row.id,
        depth: row.depth,
        folderColour: row.isFolder ? (row as GroupFolder).color : "",
        hasChildren: row.hasChildren,
        isFolder: row.isFolder,
        hierarchyType: (row as HierarchyGroupWithSharingDto).hierarchyType,
        name: row.name ?? "",
      }),
      cell: useNameCell,
      header: NameHeader,
      id: "name",
      minSize: 100,
      maxSize: 900,
      size: 300,
    },
    {
      accessorFn: (row: FolderOrGroupDto) => row.id,
      cell: quickActionsCell,
      header: "",
      id: "menu",
      minSize: 20,
      maxSize: 20,
      size: 20,
    },
    {
      accessorFn: (row: FolderOrGroupDto) => row.updateDateUtc,
      cell: timeElapsedCell,
      header: "Updated",
      id: "updated",
      minSize: 50,
      maxSize: 100,
      size: 70,
    },
    {
      accessorFn: (row: FolderOrGroupDto) =>
        row.isFolder
          ? ""
          : (row as HierarchyGroupWithSharingDto).evaluationType,
      cell: typeCell,
      header: "Type",
      id: "type",
      minSize: 50,
      maxSize: 100,
      size: 75,
    },
  ];

  if (flags[FeatureFlag.SharingGroups]) {
    columns.push({
      accessorFn: (row: FolderOrGroupDto) =>
        row.isFolder
          ? []
          : (row as HierarchyGroupWithSharingDto).sharedWithUserIds,
      cell: (info) => SharedCell(allGroupSharedUsers ?? [], info),
      header: "Shared",
      id: "shared",
      minSize: 50,
      maxSize: 100,
      size: 75,
    });
  }

  return (
    <GroupListTable
      columns={columns as Array<ColumnDef<FolderOrGroupDto>>}
      groupType={groupType}
      isFetchUnitialised={isUninitialized}
      isFetching={isGroupListFetching || isAllGroupSharedUsersLoading}
      onCheckboxSelect={onCheckboxSelect}
      rows={filteredFoldersAndGroups}
      setFocalGroup={setFocalGroupById}
      sortByColumnId=""
    />
  );
};
