import { Button, ButtonVariant, Text } from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import UploadFileOutlineIcon from "components-ui/src/assets/common/upload-file-outline-icon.svg";
import { useEffect, useRef, useState } from "react";
import styles from "./FileInput.module.css";

type FileType = "csv";

const InputAcceptMap = {
  csv: ".csv",
};

type FileInputProps = {
  disabled?: boolean;
  fileType: FileType;
  handleFileInput: (file: File | null) => void;
  maxMbSize?: number;
};

export const FileInput = ({
  disabled = false,
  fileType,
  handleFileInput,
  maxMbSize,
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const dragCounter = useRef(0);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (file && !disabled) {
      handleFileInput(file);
    }
  };

  const onDragEnter = () => {
    dragCounter.current++;
    setIsDragOver(true);
  };

  const onDragLeave = () => {
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragOver(false);
    }
  };

  const onDrop = () => {
    setIsDragOver(false);
    dragCounter.current = 0;
  };

  useEffect(() => {
    if (disabled && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [disabled]);

  return (
    <div
      className={classNames(styles.fileInput, {
        [styles.fileInputDisabled]: disabled,
        [styles.fileInputDragOver]: isDragOver,
      })}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <img alt="File icon" src={UploadFileOutlineIcon} />
      <div>
        <div>
          <Button
            className={styles.buttonLink}
            disabled={disabled}
            onClick={() => inputRef.current?.click()}
            text={`Click to upload ${fileType} file`}
            variant={ButtonVariant.Link}
          />
          <Text> or drag and drop</Text>
        </div>
        {maxMbSize && (
          <Text>{`Limited to one file (max. ${maxMbSize}MB size)`}</Text>
        )}
      </div>
      <input
        accept={InputAcceptMap[fileType]}
        disabled={disabled}
        hidden
        onChange={onInputChange}
        ref={inputRef}
        type="file"
      />
    </div>
  );
};
