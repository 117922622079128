import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type ProductInteractionData } from "components-ui/src/tables/matrix/Matrix";
import {
  formatMetricValue,
  getCsvColumnHeader,
} from "../../../common/utils/export-parameter-summary-utils";
import { type CrossShopTableItem } from "../../models/CrossShopDataTableResponseDto";

export type matrixProperties = {
  headers: CrossShopTableItem[];
  productInteractionData: ProductInteractionData;
  values: Array<Array<number | null>>;
} | null;

export const CrossShopInteractionsMatrixCsvTransformation = (
  matrixProperties: matrixProperties,
  currencySymbol: string,
  showDetails: boolean
) => {
  const columnHeaders = ["Focal Item"];
  const csvData = [columnHeaders];

  if (matrixProperties) {
    if (showDetails) {
      columnHeaders.push(
        getCsvColumnHeader(
          matrixProperties.productInteractionData.format,
          currencySymbol,
          matrixProperties.productInteractionData.displayName
        )
      );
    }

    columnHeaders.push(
      ...matrixProperties.headers.map(
        (item) => `(${hierarchyLevelDisplayLabel(item.shortName)}) ${item.name}`
      )
    );

    for (const [index, value] of matrixProperties.values.entries()) {
      const row = [];
      row.push(
        `(${hierarchyLevelDisplayLabel(
          matrixProperties.headers[index].shortName
        )}) ${matrixProperties.headers[index].name}`
      );
      if (showDetails) {
        row.push(
          `${formatMetricValue(
            matrixProperties.productInteractionData.values[index]
          )}`
        );
      }

      row.push(...value.map((data) => `${formatMetricValue(data)}`));
      csvData.push(row);
    }
  }

  return csvData;
};
