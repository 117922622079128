import { type ParameterGroupDto } from "@quantium-enterprise/common-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import React from "react";
import { useSelector } from "react-redux";
import { getGroupIsValid } from "../../states/validation-utils";
import { type RootState } from "../../store";
import { SummaryPanel } from "../summary-panel/SummaryPanel";
import SummaryPanelItem from "./SummaryPanelItem";

export type SummaryPanelBodyProps = {
  parameterGroupsConfiguration?: ParameterGroupDto[];
};

export const SummaryPanelBody = ({
  parameterGroupsConfiguration,
}: SummaryPanelBodyProps) => {
  const { parameterGroupStates, serverError } = useSelector(
    (state: RootState) => ({
      parameterGroupStates: state.reportParameter.parameterGroups,
      serverError: state.reportParameter.serverError,
    })
  );

  const visitedTabs = useSelector(
    (state: RootState) => state.wizard.visitedTabs
  );

  if (!parameterGroupsConfiguration) {
    return null;
  }

  return (
    <SummaryPanel.Body>
      {parameterGroupsConfiguration.map(
        (parameterGroupConfiguration, index) => {
          const groupState =
            parameterGroupStates[parameterGroupConfiguration.label];

          if (!groupState) {
            return <React.Fragment key={uniqueId()} />;
          }

          const isServerError = serverError.isError
            ? serverError.parameters.some((errorParameter) =>
                parameterGroupConfiguration.parameters.some(
                  (parameter) => parameter.id === errorParameter
                )
              )
            : false;

          const isCompleted = getGroupIsValid(groupState);

          const isErrored =
            visitedTabs[parameterGroupConfiguration.label] && !isCompleted;

          return (
            <SummaryPanel.Item
              isCompleted={isCompleted && !isServerError}
              isErrored={isErrored || isServerError}
              isOptional={groupState.isOptional}
              isPrefilled={groupState.isPrefilled}
              key={parameterGroupConfiguration.label}
              tabItemIndex={index}
              title={parameterGroupConfiguration.label}
            >
              {parameterGroupConfiguration.parameters.map((parameter) => (
                <SummaryPanelItem key={parameter.id} parameterDto={parameter} />
              ))}
            </SummaryPanel.Item>
          );
        }
      )}
    </SummaryPanel.Body>
  );
};
