import {
  ddLog,
  getBaseQuery,
  isParameterConfigurationDto,
  type ParameterConfigurationsDto,
} from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type AvailableReportsDto } from "../../common/model/AvailableReportsDto";
import {
  onConfigReceived,
  resetParametersState,
} from "../../states/report-wizard-slice";
import {
  type StoreParametersRequestDto,
  type StoreReportParametersResponseDto,
} from "../model/StoreParametersDto";
import { resetTabs, setTabs } from "../wizard/wizard-slice";

export const reportParametersApi = createApi({
  baseQuery: getBaseQuery(`/`),
  endpoints: (builder) => ({
    createParameters: builder.mutation<
      StoreReportParametersResponseDto,
      {
        divisionName: string;
        reportUri: string;
        requestDto: StoreParametersRequestDto;
      }
    >({
      invalidatesTags: ["SaveReportParameters"],
      query: ({ divisionName, reportUri, requestDto }) => ({
        body: requestDto,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/api/${reportUri}/StoreParameters/${divisionName}`,
      }),
    }),
    getAvailableReports: builder.query<
      AvailableReportsDto,
      { divisionName: string; reportUri: string }
    >({
      providesTags: ["availableReports"],
      query: ({ divisionName }) =>
        `/api/available-reports/AvailableReports/Get/${divisionName}`,
    }),
    getParameterConfigurations: builder.query<
      ParameterConfigurationsDto,
      { divisionName: string; reportPath: string }
    >({
      async onQueryStarted(argument, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          dispatch(resetTabs());
          dispatch(resetParametersState());

          const { data } = await queryFulfilled;

          // `onSuccess` side-effect
          if (!isParameterConfigurationDto(data)) {
            throw new Error(
              `getParameterConfigurations error: received response does not match what is expected: ${JSON.stringify(
                data
              )} `
            );
          }

          if (!data.parameterGroups) {
            throw new Error(
              `getParameterConfigurations error: received response does not contain parameterConfig: ${JSON.stringify(
                data
              )} `
            );
          }

          dispatch(
            setTabs(
              data.parameterGroups.map((parameterGroup) => parameterGroup.label)
            )
          );

          dispatch(
            onConfigReceived({
              parameterConfig: data,
            })
          );
        } catch (error) {
          // `onError` side-effect
          ddLog("ERROR", {}, "error", error as Error);
        }
      },
      providesTags: ["ReportParameterConfigurations"],
      query: ({ divisionName, reportPath }) =>
        `/api/${reportPath}/ReportParameters/${divisionName}`,
    }),
  }),
  reducerPath: "reportParameterConfigurations",
  tagTypes: [
    "ReportParameterConfigurations",
    "availableReports",
    "SaveReportParameters",
  ],
});

export const {
  useGetParameterConfigurationsQuery,
  useLazyGetParameterConfigurationsQuery,
  useLazyGetAvailableReportsQuery,
  useCreateParametersMutation,
} = reportParametersApi;

export default reportParametersApi.reducer;
