import type Highcharts from "highcharts";
import { type Metric, type Series } from "../../models";

export const mapToPerformanceTrendSeries = (
  series: Series[],
  hiddenSeriesIndexes?: number[],
  secondaryMetric?: Metric
): Highcharts.SeriesLineOptions[] => {
  const highchartsSeries = series.map(
    (item, index) =>
      ({
        color: item.color,
        dashStyle:
          secondaryMetric?.name === item.metric.name ? "Dash" : "Solid",
        data: Array.from(item.data),
        dataLabels: {
          enabled: item.showDataLabels,
          formatter() {
            return item.metric.formatter(this.y);
          },
        },
        marker: { enabled: false, symbol: "circle" },
        name: item.name,
        tooltip: {
          headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
          pointFormatter(this) {
            return `<tr><td>${
              this.series.name
            }</td> <td style="text-align: right"><b>${secondaryMetric?.formatter(
              this.y
            )}</b></td></tr>`;
          },
          footerFormat: "</table>",
        },
        type: "line",
        yAxis: item.metric.name,
        visible: !hiddenSeriesIndexes?.includes(index),
        showInLegend: !hiddenSeriesIndexes?.includes(index),
      } as Highcharts.SeriesLineOptions)
  );

  return highchartsSeries;
};
