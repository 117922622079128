import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import {
  type SidePanelParameter,
  type SidePanelLocationHierarchyParameter,
  type SidePanelSegmentationParameter,
  type SidePanelMultiTypeParameter,
} from "../../common/models/local-parameters/SidePanelParameters";
import { getFocalItemLabels } from "../../common/utils/focal-item-labels-utils";
import {
  isLocationSelectionParameter,
  isMultiTypeParameter,
  isSegmentationParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";

export const ReportletDropdownValues = {
  FocalItems: "FocalItems",
  Metric: "Metric",
};

const getMultiTypeParameterMapping = (
  parameter: SidePanelMultiTypeParameter,
  metaData: string[]
): string[] =>
  metaData.map(
    (item) =>
      parameter.selections.find((selection) => selection.value === item)
        ?.label ?? ""
  );

const getSegmentationParameterMapping = (
  parameter: SidePanelSegmentationParameter,
  metaData: string[]
): string[] =>
  metaData.map(
    (item) =>
      parameter.selections.find((selection) => selection.segmentValue === item)
        ?.segmentLabel ?? ""
  );

const getLocationParameterMapping = (
  parameter: SidePanelLocationHierarchyParameter,
  metaData: string[]
): string[] =>
  metaData.map(
    (item) =>
      parameter.selections.find(
        (selection) => selection.nodeNumber.toString() === item
      )?.name ?? ""
  );

const getLocalParameterMapping = (
  selection: string,
  metaData: string[],
  localParametersConfig?: SidePanelParameter[]
): string[] => {
  if (localParametersConfig === undefined) {
    return [];
  }

  const localParameter = localParametersConfig.find(
    (parameter) => parameter.id === selection
  );
  if (localParameter === undefined) {
    return [];
  }

  if (isMultiTypeParameter(localParameter)) {
    return getMultiTypeParameterMapping(localParameter, metaData);
  } else if (isSegmentationParameter(localParameter)) {
    return getSegmentationParameterMapping(localParameter, metaData);
  } else if (isLocationSelectionParameter(localParameter)) {
    return getLocationParameterMapping(localParameter, metaData);
  }

  return [];
};

const getFocalItemMapping = (
  metaData: string[],
  focalItems?: HierarchySliceNodeDto[],
  focalItemParents?: HierarchySliceNodeDto[]
) => {
  if (focalItems === undefined) {
    return [];
  }

  if (focalItemParents === undefined) {
    return metaData.map(
      (item) =>
        focalItems.find((focalItem) => item === focalItem.nodeNumber.toString())
          ?.name ?? ""
    );
  }

  const focalItemLabels = getFocalItemLabels(focalItems, focalItemParents);
  return metaData.map(
    (item) => focalItemLabels[Number.parseInt(item, 10)] ?? ""
  );
};

/**
 * Helper function that converts the metadata returned from the backend into the labels that can be used in the frontend
 *
 * @param "selection", "metaData" local parameter and metaData from the backend
 * @returns string[] of the values for the frontent
 */
export const getMetaDataMapping = (
  selection: string,
  metaData: string[],
  focalItems?: HierarchySliceNodeDto[],
  focalItemParents?: HierarchySliceNodeDto[],
  localParametersConfig?: SidePanelParameter[]
): string[] => {
  switch (selection) {
    case ReportletDropdownValues.FocalItems:
      return getFocalItemMapping(metaData, focalItems, focalItemParents);
    case ReportletDropdownValues.Metric:
      return metaData;
    default:
      return getLocalParameterMapping(
        selection,
        metaData,
        localParametersConfig
      );
  }
};
