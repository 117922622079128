type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const CombinedIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--cg-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--cg-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--cg-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Combined customer group"}</title>
      <path
        clipRule="evenodd"
        d="M2.5 2C2.22386 2 2 2.22386 2 2.5V14.8333C2 15.1095 2.22386 15.3333 2.5 15.3333H8.66667V9.16667C8.66667 8.89052 8.89052 8.66667 9.16667 8.66667H15.3333V2.5C15.3333 2.22386 15.1095 2 14.8333 2H2.5Z"
        fill={colour1}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.6665 15.3334V21C8.6665 21.5523 9.11422 22 9.6665 22H21.4998C21.776 22 21.9998 21.7762 21.9998 21.5V9.16669C21.9998 8.89054 21.776 8.66669 21.4998 8.66669H15.3332V14.8334C15.3332 15.1095 15.1093 15.3334 14.8332 15.3334H8.6665Z"
        fill={colour3}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.75 11.5V10H11.5L11.5 11.5H10V12.75H11.5L11.5 14.25H12.75V12.75H14.25V11.5H12.75Z"
        fill={colour2}
        fillRule="evenodd"
      />
    </svg>
  );
};
