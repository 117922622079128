import {
  type SegmentDto,
  type CustomerGroupDto,
  type ParameterDto,
  isSegmentationSelectionDto,
  type SelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";

export type SegmentFilterOption = {
  customerGroup?: CustomerGroupDto;
  id: string;
  label: string;
  segment?: SegmentDto;
};

export const noneOption: SegmentFilterOption = {
  id: "none",
  label: "None selected",
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface SegmentFilterParameterState extends ParameterState {
  selection: SegmentFilterOption | undefined;
}

export const isSegmentFilterParameterState = (
  state: ParameterState | undefined
): state is SegmentFilterParameterState =>
  state !== undefined && Object.hasOwn(state, "selection");

export const newSegmentFilterParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): SegmentFilterParameterState => {
  let selection: SegmentFilterOption = noneOption;
  const segmentFilters = savedSelections ?? parameterConfig.selections ?? [];

  if (segmentFilters.length > 0) {
    const options = segmentFilters.flatMap((option) => {
      if (isSegmentationSelectionDto(option) && option.segments) {
        return option.segments.map((segmentOption) => ({
          id: `${option.id}-${segmentOption.ordinal}`,
          label: `${option.name} ${segmentOption.name}`,
          customerGroup: {
            id: option.id,
            name: option.name,
          } as CustomerGroupDto,
          segment: {
            key: segmentOption.key,
            name: segmentOption.name,
            ordinal: segmentOption.ordinal,
          } as SegmentDto,
        }));
      }

      return [];
    });
    if (options.length > 0) {
      selection = options[0];
    }
  }

  return {
    isValid: true,
    parameterConfig,
    parameterGroup,
    selection,
  };
};

export const getSegmentFilterParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as SegmentFilterParameterState | undefined
  );

export const handleSegmentFilterParameterSelection = (
  parameterState: ParameterState,
  newSelection: SegmentFilterOption
): ParameterState => {
  if (isSegmentFilterParameterState(parameterState)) {
    return {
      ...parameterState,
      selection: newSelection,
    } as SegmentFilterParameterState;
  }

  return { ...parameterState, isParameterMissing: false };
};
