import { MetricTypes } from "./models/MetricTypes";
import { defaultCurrency, defaultLocale } from "./models/constants";
import { useDivision } from "./use-division";
import { getNumberFormat } from "./use-number-format";

const getLabelFormat = (
  label: string,
  metricType: MetricTypes,
  locale: string,
  currency: string
) => {
  if (!label) return "";

  // Default first letter to uppercase
  let formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);

  // Apply formatting based on metricType
  switch (metricType) {
    case MetricTypes.Percentage:
    case MetricTypes.PercentagePoint: {
      formattedLabel = `${formattedLabel} (%)`;
      break;
    }

    case MetricTypes.Currency: {
      const { getCurrencySymbol } = getNumberFormat(locale, currency);
      const symbol = getCurrencySymbol();

      formattedLabel = `${formattedLabel} (${symbol})`;
      break;
    }

    default:
      break;
  }

  return formattedLabel;
};

export const useLabelFormatter = (label: string, metricType: MetricTypes) => {
  const { currency, locale } = useDivision();

  return getLabelFormat(
    label,
    metricType,
    locale || defaultLocale,
    currency || defaultCurrency
  );
};
