import { type PointOptionsObject } from "highcharts";
import { getexportHeader } from "../../utils/getExportValues";

export const csvTransformation = (
  data: PointOptionsObject[][],
  currencySymbol: string,
  primaryMetric: string,
  secondaryMetricFormat: string,
  secondaryMetric: string
) => {
  const columnHeaders = [
    "Focal Item(s)",
    primaryMetric,
    getexportHeader(secondaryMetric, secondaryMetricFormat, currencySymbol),
  ];
  const csvData = [columnHeaders];
  const row: string[][][] = [
    ...data.map((dataRow) =>
      dataRow.map((dataValues) => [
        dataValues.name?.toString() ?? " ",
        ((dataValues.x ?? 0) * 100).toString() + " %",
        dataValues.y?.toString() ?? " ",
      ])
    ),
  ];
  row.map((rowValues) => rowValues.map((finalRows) => csvData.push(finalRows)));
  return csvData;
};
