import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { format, parse } from "date-fns";
import { type ReportletDataResponseDto } from "../models/reportlet-reponse";

export const csvTransformation = (
  reportletData: ReportletDataResponseDto | undefined,
  currencySymbol: string
) => {
  const columnHeaders = ["Date"];
  const csvData = [columnHeaders];

  if (reportletData?.chartData) {
    const convertedPromoWeeks = reportletData.promoWeeks.map((pw) =>
      format(parse(pw, "yyyyMMdd", new Date()), "yyyy-MM-dd")
    );

    columnHeaders.push(
      ...reportletData.chartData.map((data, index) => {
        if (data.format === MetricTypes.Currency) {
          if (index === 0) {
            return `${data.label} growth (${currencySymbol})`;
          }

          return `${data.label} (${currencySymbol})`;
        }

        return index === 0 ? `${data.label} growth` : data.label;
      })
    );

    for (let index = 0; index < reportletData.promoWeeks.length; ++index) {
      const row = [
        convertedPromoWeeks[index],
        ...reportletData.chartData.map((data) =>
          (data.values[index] ?? 0).toString()
        ),
      ];
      csvData.push(row);
    }
  }

  return csvData;
};
