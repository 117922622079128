import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useFormatter,
  getNumberFormat,
} from "@quantium-enterprise/hooks-ui";
import { Spinner, SpinnerSize } from "@quantium-enterprise/qds-react";
import { type DataLabelsOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { NpbChart } from "components-ui/src/charts/npb-chart/NpbChart";
import {
  toggleAddToSeries,
  benchmarkColours,
  reshapeNpbChartData,
  chartColours,
  getFormatterFromMetricDefinitons,
} from "components-ui/src/charts/npb-chart/npb-chart-utils";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { getFocalItemSummary } from "../../common/utils/export-parameter-summary-utils";
import { isfeatureEnable } from "../../common/utils/feature-flag-utils";
import { type RootState } from "../../store";
import { NewProductBenchmarkingFeatureFlags } from "../constants/new-product-benchmarking-feature-flags";
import { useGetMetricsListQuery } from "../services/new-product-benchmarking-metrics-list-api-slice";
import { useGetNewProductBenchmarkingReportletDataQuery } from "../services/new-product-benchmarking-reportlet-api-slice";
import {
  onHiddenSeriesChange,
  onHiddenBenchmarkSeriesChange,
  onChartSelectedMetricChange,
  onMetricListChange,
  selectLocalParametersSelections,
  selectLocalParametersInitialised,
  selectHiddenBenchmarkSeries,
  selectHiddenSeries,
  selectFocalItems,
  selectSelectedMetric,
  selectMetricsList,
  selectReportId,
  onToggleShowChartDataLables,
  selectShowChartDataLabels,
} from "../services/new-product-benchmarking-slice";
import styles from "./NewProductBenchmarkingReportlet.module.css";
import { reportLetCsvTransformation } from "./csvTransformation";

const { percentFormatter } = getNumberFormat();
export type NewProductBenchMarkReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

// 26 Weeks, starting a 1
const NUM_WEEKS_IN_CHART = 26;
const chartCategories = [...Array.from({ length: NUM_WEEKS_IN_CHART })].map(
  (_, index) => index + 1
);

export const NewProductBenchmarkingReportlet = ({
  infoPanelSummary,
}: NewProductBenchMarkReportletProps) => {
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const hiddenSeries = useSelector(selectHiddenSeries);
  const hiddenBenchmarkSeries = useSelector(selectHiddenBenchmarkSeries);
  const focalItems = useSelector(selectFocalItems);
  const selectedMetric = useSelector(selectSelectedMetric);
  const metricsList = useSelector(selectMetricsList);
  const reportId = useSelector(selectReportId);
  const showChartDataLables = useSelector(selectShowChartDataLabels);
  const isfeatureEnabled = isfeatureEnable(
    NewProductBenchmarkingFeatureFlags.ReportExport
  );
  const chartContainerRef = useRef<HTMLElement>();
  const exportChartFilename = useMemo(
    () =>
      cleanFilename(
        `New_Product_Benchmarking_Chart_${localParametersSelections.TimePeriodLength}_${localParametersSelections.LocationHierarchy.name}`
      ),
    [localParametersSelections]
  );

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.newProductBenchmarking.metaData.reportName,
  }));

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();
  const [currentLegend, setCurrentLegend] = useState<ReactNode>();

  const { data: metricsResponse, isFetching: isMetricsListFetching } =
    useGetMetricsListQuery(
      {
        divisionName: activeDivisionName,
        reportId,
      },
      {
        skip: !(reportId && activeDivisionName),
      }
    );

  useEffect(() => {
    if (metricsResponse) {
      dispatch(onMetricListChange(metricsResponse.groups));
      if (metricsResponse.defaultMetric)
        dispatch(onChartSelectedMetricChange(metricsResponse.defaultMetric));
    }
  }, [dispatch, metricsResponse]);

  const { data: reportletData, isFetching: isReportletDataFetching } =
    useGetNewProductBenchmarkingReportletDataQuery(
      {
        divisionName: activeDivisionName,
        requestPayload: {
          focalProducts: focalItems.map((item) => item.name),
          localSelectedValues: [
            {
              id: "Channel",
              values: [localParametersSelections.Channel.value.toString()],
            },
            {
              id: "Segmentation",
              values: localParametersSelections.Segmentation.map(
                (item) => item.value as string
              ),
            },
            {
              id: "LocationHierarchy",
              values: [
                localParametersSelections.LocationHierarchy.nodeNumber.toString(),
              ],
            },
          ],
          metric: selectedMetric,
          reportId,
        },
      },
      {
        skip:
          Boolean(!selectedMetric) ||
          !(reportId && activeDivisionName && localParametersInitialised),
      }
    );

  const toggleBenchmarks = useCallback(
    (index: number) =>
      toggleAddToSeries(index, hiddenBenchmarkSeries, (series: number[]) =>
        dispatch(onHiddenBenchmarkSeriesChange(series))
      ),
    [dispatch, hiddenBenchmarkSeries]
  );

  const toggleProductSeries = useCallback(
    (index: number) =>
      toggleAddToSeries(index, hiddenSeries, (series: number[]) =>
        dispatch(onHiddenSeriesChange(series))
      ),
    [dispatch, hiddenSeries]
  );

  const benchmarkData = useMemo(
    () =>
      reshapeNpbChartData(
        [...(reportletData?.benchmarkData ?? [])].reverse(),
        benchmarkColours,
        true
      ),
    [reportletData?.benchmarkData]
  );

  const chartData = useMemo(
    () =>
      reshapeNpbChartData(
        reportletData?.focalProductsData.map((dataPoint) => ({
          label: dataPoint.name,
          values: dataPoint.values,
        })) ?? [],
        chartColours
      ),
    [reportletData?.focalProductsData]
  );

  const { locale, currency } = useDivision();

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const currencyFormat = getFormatterFromMetricDefinitons(
    metricsList,
    selectedMetric
  );

  const chartCsvTransformationCallback = useCallback(
    () =>
      reportLetCsvTransformation(
        benchmarkData,
        chartData,
        chartCategories,
        currencySymbol,
        currencyFormat
      ),
    [benchmarkData, chartData, currencySymbol, currencyFormat]
  );

  if (!reportletData || isReportletDataFetching || isMetricsListFetching) {
    return (
      <div className={styles.reportletSpinnerContainer}>
        <Spinner
          data-testid="data-table-loading-icon"
          size={SpinnerSize.Large}
        />
      </div>
    );
  }

  const parameterSummary = [
    {
      name: "Focal Item",
      value: getFocalItemSummary(focalItems),
    },
    { name: "Time", value: localParametersSelections.FocusPeriod as string },
    { name: "Metrics", value: selectedMetric },
    {
      name: "Segmentation",
      value:
        localParametersSelections.Segmentation.length > 0
          ? localParametersSelections.Segmentation[0]?.label
          : "",
    },
    {
      name: "Customer segment",
      value:
        localParametersSelections.Segmentation.length > 1
          ? localParametersSelections.Segmentation[1]?.label
          : "",
    },
    { name: "Channel", value: localParametersSelections.Channel.label },
    {
      name: "Location",
      value: `(${hierarchyLevelDisplayLabel(
        localParametersSelections.LocationHierarchy.shortName
      )}) ${localParametersSelections.LocationHierarchy.name}`,
    },
  ];

  const dataLabelOptions: DataLabelsOptions = {
    isSelected: showChartDataLables,
    value: "",
  };

  return (
    <ReportletAccordion
      subtitle="Evaluate new product performance against benchmarks within your category."
      title="New product benchmarking"
    >
      <div className={styles.chartOptionsContainer}>
        <ChartOptions
          dataLabelsOptions={[dataLabelOptions]}
          downloadWizardOptions={
            currentOptions
              ? {
                  chartOptions: currentOptions,
                  reportIcon: (
                    <ReportIcon type={ReportType.NewProductBenchmarking} />
                  ),
                  chartTitle: `New product benchmarking - ${reportName}`,
                  reportTitle: reportName,
                  customLegend: currentLegend,
                }
              : undefined
          }
          filename={exportChartFilename}
          getCSVData={chartCsvTransformationCallback}
          getElementToExport={() => chartContainerRef.current}
          isFeatureEnabled={isfeatureEnabled}
          localParameters={parameterSummary}
          reportParameters={infoPanelSummary}
          toggleDataLabels={() => {
            dispatch(onToggleShowChartDataLables());
          }}
        />
      </div>
      <ErrorBoundary>
        <NpbChart
          benchmarkData={benchmarkData}
          categories={chartCategories}
          chartContainerRef={chartContainerRef}
          chartData={chartData}
          chartDataFormatter={formatter(
            getFormatterFromMetricDefinitons(metricsList, selectedMetric)
          )}
          hiddenBenchmarkSeries={hiddenBenchmarkSeries}
          hiddenSeries={hiddenSeries}
          metricDefinitions={metricsList}
          onLegendChanged={setCurrentLegend}
          onOptionsChanged={setCurrentOptions}
          parameterSummary={parameterSummary}
          percentFormatter={percentFormatter}
          selectMetric={(label: string) =>
            dispatch(onChartSelectedMetricChange(label))
          }
          selectedMetric={selectedMetric}
          showChartDataLables={showChartDataLables}
          toggleBenchmarks={toggleBenchmarks}
          toggleSeries={toggleProductSeries}
          yAxisLabel={selectedMetric}
        />
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
