import {
  HierarchyItemType,
  type HierarchyAttributeParameterOption,
} from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { HierarchyLevelIcon } from "../hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./HierarchyLabelWithIcon.module.css";

type HierarchyLabelWithIconProps = {
  isDisabled: boolean;
  parameterOption: HierarchyAttributeParameterOption;
};

export const HierarchyLabelWithIcon = ({
  parameterOption,
  isDisabled,
}: HierarchyLabelWithIconProps) => (
  <div className={styles.checkboxLabel}>
    <div
      className={classNames(styles.hierarchyLevelIcon, {
        [styles.disabledIcon]: isDisabled,
      })}
    >
      <HierarchyLevelIcon
        shortName={parameterOption.value}
        type={
          parameterOption.isLeaf
            ? HierarchyItemType.Leaf
            : parameterOption.isHierarchical
            ? HierarchyItemType.Hierarchy
            : HierarchyItemType.Attribute
        }
      />
    </div>
    <span
      className={classNames(styles.itemText, {
        [styles.disabledText]: isDisabled,
      })}
    >
      {parameterOption.label}
    </span>
  </div>
);
