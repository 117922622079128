import {
  type HierarchyGroupEvaluationType,
  type TransactionSource,
  type FocalItem,
  HierarchyShortName,
} from "@quantium-enterprise/common-ui";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";

type BaseSelectableItem = {
  isLoadMoreRow?: boolean;
  isMoreRow?: boolean;
  name: string;
  parent?: BaseSelectableItem;
  subRows?: BaseSelectableItem[];
};

export type SelectableHierarchyItem = BaseSelectableItem & {
  code: string;
  entitlements: TransactionSource[];
  hasLoadableChildRows: boolean;
  isExpanding: boolean;
  parent?: SelectableHierarchyItem;
  shortName: string;
  subRows?: SelectableHierarchyItem[];
  suffix?: string;
  type: HierarchyItemType.Hierarchy | HierarchyItemType.Leaf;
};

export type SelectableAttributeItem = BaseSelectableItem & {
  additionalHierarchyFilter?: {
    code: string;
    entitlements: TransactionSource[];
    shortName: string;
  };
  code: string;
  isExpanding: boolean;
  parent?: SelectableAttributeItem;
  shortName: string;
  subRows?: SelectableAttributeLevelItem[];
  suffix?: string;
  type: HierarchyItemType.Attribute;
};

export type SelectableAttributeLevelItem = BaseSelectableItem & {
  code: string;
  entitlements: TransactionSource[];
  parent: SelectableAttributeItem;
  shortName: string;
  type: HierarchyItemType.Attribute;
};

export type SelectableProductGroupItem = BaseSelectableItem & {
  evaluationType: HierarchyGroupEvaluationType;
  parent?: SelectableProductGroupItem;
  productGroupId: string;
  type: HierarchyShortName.ProductGroup;
};

export type SelectableItem =
  | SelectableAttributeItem
  | SelectableAttributeLevelItem
  | SelectableHierarchyItem
  | SelectableProductGroupItem;

export const isSelectableHierarchyItem = (
  item?: SelectableItem
): item is SelectableHierarchyItem =>
  item?.type === HierarchyItemType.Hierarchy ||
  item?.type === HierarchyItemType.Leaf;

export const isSelectableAttributeItem = (
  item?: SelectableItem
): item is SelectableAttributeItem =>
  item?.type === HierarchyItemType.Attribute &&
  !Object.prototype.hasOwnProperty.call(item, "entitlements");

export const isSelectableAttributeLevelItem = (
  item?: SelectableItem
): item is SelectableAttributeLevelItem =>
  item?.type === HierarchyItemType.Attribute &&
  Object.prototype.hasOwnProperty.call(item, "entitlements");

export const isSelectableProductGroupItem = (
  item?: SelectableItem
): item is SelectableProductGroupItem =>
  item?.type === HierarchyShortName.ProductGroup;

export const selectableItemToFocalItem = (
  item: SelectableItem,
  ignoreIncompleteAttribute: boolean = false
): FocalItem => {
  if (isSelectableAttributeItem(item)) {
    if (!ignoreIncompleteAttribute && !item.additionalHierarchyFilter)
      throw new Error(
        "Attribute Items must have an additional hierarchy filter"
      );

    return {
      additionalHierarchyFilter: item.additionalHierarchyFilter,
      code: item.code,
      displayName: item.name,
      shortName: item.shortName,
      type: item.type,
    };
  } else if (isSelectableAttributeLevelItem(item)) {
    return {
      additionalHierarchyFilter: {
        code: item.code,
        shortName: item.shortName,
      },
      code: item.parent.code,
      displayName: item.parent.name,
      shortName: item.parent.shortName,
      type: HierarchyItemType.Attribute,
    };
  } else if (isSelectableHierarchyItem(item)) {
    return {
      code: item.code,
      displayName: item.name,
      shortName: item.shortName,
      type: item.type,
    };
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Here for safety in case a new type gets added
  } else if (isSelectableProductGroupItem(item)) {
    return {
      type: HierarchyShortName.ProductGroup,
      displayName: item.name,
      productGroupId: item.productGroupId,
      evaluationType: item.evaluationType,
    };
  }

  throw new Error("Unable to convert selection to FocalItem");
};

export const getUniqueKey = (item: SelectableItem): string => {
  if (isSelectableHierarchyItem(item)) {
    return `Hier:${item.shortName}:${item.code}`;
  } else if (isSelectableAttributeItem(item)) {
    return `Attr:${item.shortName}:${item.code}`;
  } else if (isSelectableAttributeLevelItem(item)) {
    return `Attr:${item.parent.shortName}:${item.parent.code}:in:${item.shortName}:${item.code}`;
  } else if (isSelectableProductGroupItem(item)) {
    return `PG:${item.productGroupId}`;
  }

  throw new Error("Unable to generate unique key");
};
