type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const KeyDriverTreeIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Key driver tree report"}</title>
      <g clipPath="url(#clip0_1991_122296)">
        <path
          d="M5.25 2C5.11193 2 5 2.11193 5 2.25V8C5 8.13807 5.11193 8.25 5.25 8.25H11.5V12.375H7C6.58579 12.375 6.25 12.7108 6.25 13.125V17.5H7.25V13.375H16.75V17.5H17.75V13.125C17.75 12.7108 17.4142 12.375 17 12.375H12.5V8.25H18.75C18.8881 8.25 19 8.13807 19 8V2.25C19 2.11193 18.8881 2 18.75 2H5.25Z"
          fill={colour2}
        />
        <path
          d="M2 16C2 15.8619 2.11193 15.75 2.25 15.75H10.75C10.8881 15.75 11 15.8619 11 16V21.75C11 21.8881 10.8881 22 10.75 22H2.25C2.11193 22 2 21.8881 2 21.75V16Z"
          fill={colour1}
        />
        <path
          d="M13 16C13 15.8619 13.1119 15.75 13.25 15.75H21.75C21.8881 15.75 22 15.8619 22 16V21.75C22 21.8881 21.8881 22 21.75 22H13.25C13.1119 22 13 21.8881 13 21.75V16Z"
          fill={colour3}
        />
      </g>
      <defs>
        <clipPath id="clip0_1991_122296">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
