import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const productSubstitutabilityApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/product-substitutability`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "productSubstitutabilityApi",
  tagTypes: ["productSubstitutability"],
});
