import { FeatureFlag } from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Tag,
  TagVariant,
  Toggle,
  ToggleSize,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { ConditionalTooltipCheckbox } from "components-ui/src/checkboxes/ConditionalTooltipCheckbox";
import styles from "./ProductHierarchyParameterTableHeader.module.scss";

type SelectedItemsToggleConfig = {
  count: number;
  show: boolean;
  toggleShow: () => void;
};

type HeaderProps = {
  handleToggleAllRowsSelected: (event: unknown) => void;
  isChecked: boolean;
  isDisabled: boolean;
  selectedItemsToggleConfig?: SelectedItemsToggleConfig;
  title: string;
};

export const ProductHierarchyParameterTableHeader = ({
  handleToggleAllRowsSelected,
  isChecked,
  isDisabled,
  title,
  selectedItemsToggleConfig,
}: HeaderProps) => {
  const featureFlags = useFlags();

  return (
    <div className={styles.productHierarchyParameterTableHeader}>
      <div className={styles.hierarchyCheckbox}>
        <ConditionalTooltipCheckbox
          hideTooltip={selectedItemsToggleConfig?.show}
          isChecked={isChecked}
          isDisabled={isDisabled}
          label="Toggle selecting all rows"
          onChange={handleToggleAllRowsSelected}
          tooltipText="The hierarchy level is not available for selection in this report."
        />
        <span className={styles.headerText}>{title}</span>
      </div>
      {selectedItemsToggleConfig &&
        Boolean(
          featureFlags[FeatureFlag.ProductHierarchySelectedItemsToggle]
        ) && (
          <div className={styles.selectedItemsToggle}>
            <Toggle
              checked={selectedItemsToggleConfig.show}
              disabled={
                selectedItemsToggleConfig.count === 0 &&
                !selectedItemsToggleConfig.show
              }
              label="Show selected item(s)"
              onClick={() => selectedItemsToggleConfig.toggleShow()}
              size={ToggleSize.XSmall}
            />
            <Tag
              className={classNames(styles.selectedItemsCount, {
                [styles.selectedItemsCountDisabled]:
                  selectedItemsToggleConfig.count === 0,
                [styles.selectedItemsCountEnabled]:
                  selectedItemsToggleConfig.count,
              })}
              text={selectedItemsToggleConfig.count.toString()}
              variant={TagVariant.Lozenge}
            />
          </div>
        )}
    </div>
  );
};
