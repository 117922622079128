import { type GainsAndLossReportParametersResponseDto } from "../models/GainsAndLossReportParametersDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossLocalParametersApiSlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getReport: builder.query<
        GainsAndLossReportParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetReportQuery } = gainsAndLossLocalParametersApiSlice;
