import { type CSSProperties } from "react";

// Solution comes from:
// https://stackoverflow.com/questions/63771649/react-convert-cssproperties-to-styled-component
const regex = /[A-Z]/u;
const kebabCase = (value: string) =>
  value.replace(regex, (char) => `-${char.toLowerCase()}`);

export const getCssStyledString = (style: CSSProperties): string => {
  // eslint-disable-next-line unicorn/no-array-reduce
  const result = Object.keys(style).reduce((accumulator, key) => {
    // transform the key from camelCase to kebab-case
    const cssKey = kebabCase(key);

    // remove ' in value
    // @ts-expect-error trying to retrieve value using key:string
    const cssValue = style[key].replace("'", "");

    // build the result
    // you can break the line, add indent for it if you need
    return `${accumulator}${cssKey}:${cssValue};`;
  }, "");

  return result;
};
