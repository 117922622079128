import { FeatureFlag } from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import { MyReportsNoRedux } from "./MyReportsNoRedux";
import { MyReportsWithRedux } from "./MyReportsWithRedux";

const MyReports = () => {
  const flags = useFlags();
  return import.meta.env.VITE_FOLDERS_FEATURE_ENABLED === "true" ||
    Boolean(flags[FeatureFlag.Folders]) ? (
    <MyReportsWithRedux />
  ) : (
    <MyReportsNoRedux />
  );
};

export default MyReports;
