import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type KeyDriversOverTimeLocalSelections } from "../services/key-drivers-over-time-slice";

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): KeyDriversOverTimeLocalSelections => {
  const selections: KeyDriversOverTimeLocalSelections = {
    Channel: {
      value: "",
      label: "",
    },
    KeyDriver: {
      value: "",
      label: "",
    },
    LocationHierarchy: {} as LocalHierarchyNodeSelection,
    Time: "",
    TimePeriodLength: "",
    Segmentation: {
      value: "",
      label: "",
    },
    Segment: {
      value: "",
      label: "",
    },
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const selection = getTimeDefaultSelection(parameter);
          selections.Time = selection.time;
          selections.TimePeriodLength = selection.timePeriodLength;
        }

        break;
      case LocalParameters.KeyDriver:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.KeyDriver.label = selection.label;
            selections.KeyDriver.value = selection.value;
          }
        }

        break;
      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.Channel.label = selection.label;
            selections.Channel.value = selection.value;
          }
        }

        break;
      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            selections.Segmentation = {
              value: selection.value,
              label: selection.label,
            };
            selections.Segment = {
              value: "All",
              label: "All",
            };
          }
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          selections.LocationHierarchy = getLocationSelectDefault(parameter);
        }

        break;
      default:
        break;
    }
  }

  return selections;
};
