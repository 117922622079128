import { type LocalSelectedValueDto } from "@quantium-enterprise/common-ui";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type HierarchySliceNodeDto } from "../../../../common/src/models/hierarchy-slice-dto";
import {
  type KeyDriverTreeTableRow,
  type KeyDriverTreeTableMeasureGrouping,
  type ExportFocalItemRequest,
} from "../models/key-driver-tree-table-models";
import { keyDriverTreeApiSlice } from "./key-driver-tree-api-slice";
import {
  onInitialTableDataReceived,
  onTableChildrenDataRecieved,
  onTableDataRecieved,
  onTableSearchDataReceived,
} from "./key-driver-tree-slice";

// Inital Table type declarations
export type InitialTableRequest = {
  defaultFocalItem: HierarchySliceNodeDto;
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
};

export type InitialTableResponse = {
  hierarchySliceHypercubeId: string;
  measureGroups: KeyDriverTreeTableMeasureGrouping[];
  tableRows: KeyDriverTreeTableRow[];
};

// Refetch Table type declarations
export type KeyDriverTreeTableRequest = {
  dataTableItems: number[];
  hierarchySliceHypercubeId: string;
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
};

export type KeyDriverTreeTableResponse = {
  measureGroups: KeyDriverTreeTableMeasureGrouping[];
  tableRows: KeyDriverTreeTableRow[];
};

// Fetch Children type declarations
export type FetchChildrenTableRequest = {
  hierarchySliceHypercubeId: string;
  localSelectedValues: LocalSelectedValueDto[];
  parentNode: number;
  reportId: string;
};

export type FetchChildrenTableResponse = {
  parentNode: number;
  tableRows: KeyDriverTreeTableRow[];
};

export type SearchTableRequest = {
  hierarchySliceHypercubeId: string;
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
  searchText: string;
};

export type SearchTableResponse = {
  tableRows: KeyDriverTreeTableRow[];
};

export const keyDriverTreeTableDataApiSlice =
  keyDriverTreeApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getChildrenTableData: builder.query<
        FetchChildrenTableResponse,
        { divisionName: string; payload: FetchChildrenTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableChildrenDataRecieved(data));
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error retrieving children data");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTableChildren/${divisionName}`,
        }),
      }),
      getInitialTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: InitialTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onInitialTableDataReceived(data));
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error retrieving table data");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetInitialTable/${divisionName}`,
        }),
      }),
      getTableData: builder.query<
        KeyDriverTreeTableResponse,
        { divisionName: string; payload: KeyDriverTreeTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataRecieved(data));
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error retrieving table data");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportTableData/${divisionName}`,
        }),
      }),
      getSearchResultsTable: builder.query<
        SearchTableResponse,
        { divisionName: string; payload: SearchTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableSearchDataReceived(data));
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error retrieving search data");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetTableSearch/${divisionName}`,
        }),
      }),
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportFocalItemRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `key driver tree focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const {
  useLazyGetChildrenTableDataQuery,
  useLazyGetInitialTableQuery,
  useLazyGetTableDataQuery,
  useLazyDownloadTableQuery,
  useLazyGetSearchResultsTableQuery,
} = keyDriverTreeTableDataApiSlice;
