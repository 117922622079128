import {
  type LocalSegmentationSelection,
  type LocalMetricWithFormatSelection,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { EMPTY_HIERARCHY_SLICE_NODE } from "../../constants";
import {
  type SidePanelSegmentationGroupSelectionParameter,
  type SidePanelLocationHierarchyParameter,
  type SidePanelHierarchySelectionParameter,
  type SidePanelMetricWithFormatParameter,
  type SidePanelSegmentationParameter,
  type SidePanelSingleSelectionParameter,
  type SidePanelMultiSelectionParameter,
  type SidePanelMultiCheckboxParameter,
  type SidePanelMultiTypeParameter,
} from "../../models/local-parameters/SidePanelParameters";

export const getSingleSelectDefaultSelection = (
  parameter: SidePanelSingleSelectionParameter
) => parameter.selections.find((selection) => selection.isDefault);

export const getSingleSelectDefault = (
  parameter: SidePanelSingleSelectionParameter
) => getSingleSelectDefaultSelection(parameter)?.value ?? "";

export const getSegmentationGroupSelectDefaultSelection = (
  parameter: SidePanelSegmentationGroupSelectionParameter
) => parameter.selections.find((selection) => selection.isDefault);

export const getSegmentationGroupSelectDefault = (
  parameter: SidePanelSegmentationGroupSelectionParameter
) => getSegmentationGroupSelectDefaultSelection(parameter)?.id ?? "";

export const getMultipleSegmentationGroupSelectDefaultSelection = (
  parameter: SidePanelSegmentationGroupSelectionParameter
) => parameter.selections.filter((selection) => selection.isDefault);

export const getMultipleSegmentationGroupSelectDefault = (
  parameter: SidePanelSegmentationGroupSelectionParameter
) =>
  getMultipleSegmentationGroupSelectDefaultSelection(parameter).map(
    (selection) => selection.id
  );

export const getMetricWithFormatSelectDefault = (
  parameter: SidePanelMetricWithFormatParameter
): LocalMetricWithFormatSelection => {
  const defaultSelection = parameter.selections.find(
    (selection) => selection.isDefault
  );

  if (defaultSelection) return defaultSelection;

  if (parameter.selections.length > 0) return parameter.selections[0];

  return {
    isDefault: false,
    format: "",
    label: "",
    value: "",
    dataset: "",
  };
};

export const getMultiCheckboxDefault = (
  parameter: SidePanelMultiCheckboxParameter
) =>
  parameter.selections
    .filter((selection) => selection.isDefault)
    .map((selection) => selection.value);

// How do we handle hierarchy local parameters.
// Can we always assume they will have a default selection?
// It has a nested structure should we do a Breadth-first-search
export const getHierarchySelectDefault = (
  parameter: SidePanelHierarchySelectionParameter
) => {
  const initialSelection = parameter.selections.find(
    (selection) => selection.isDefault
  )?.hierarchyItem;

  if (initialSelection) {
    return [initialSelection.shortName, initialSelection.itemCode];
  }

  return [];
};

export const getLocationSelectDefault = (
  parameter: SidePanelLocationHierarchyParameter
): LocalHierarchyNodeSelection => {
  const initialSelection = parameter.selections.find(
    (selection) => selection.isDefault
  );

  if (initialSelection) {
    return initialSelection;
  }

  return EMPTY_HIERARCHY_SLICE_NODE;
};

export const getMultiTypeSelectDefault = (
  parameter: SidePanelMultiTypeParameter
) =>
  parameter.selections
    .filter((selection) => selection.isDefault)
    .map((selection) => selection.value);

export const getSegmentationSelectDefaultSelection = (
  parameter: SidePanelSegmentationParameter
): LocalSegmentationSelection | undefined =>
  parameter.selections.find((selection) => selection.isDefault === true) ??
  parameter.selections[0];

export const getSegmentationSelectDefault = (
  parameter: SidePanelSegmentationParameter
): string[] => {
  const selection = getSegmentationSelectDefaultSelection(parameter);
  if (selection) {
    return [selection.value, selection.segmentValue];
  }

  return [];
};

export const getMultiSelectDefaultSelection = (
  parameter: SidePanelMultiSelectionParameter
) => parameter.selections.filter((selection) => selection.isDefault);

export const getMultiSelectDefault = (
  parameter: SidePanelMultiSelectionParameter
) =>
  getMultiSelectDefaultSelection(parameter).map((selection) => selection.value);
