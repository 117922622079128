import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type CrossShopTableItem } from "../../models/CrossShopDataTableResponseDto";
import {
  type CrossShopSegmentationResponseDto,
  type SegmentationInteractionDataCut,
} from "../../models/CrossShopSegmentationResponseDto";
import { ProductInteraction } from "../../models/CrossShopSegmentationResponseDto";
import {
  CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS,
  CROSS_SHOP_SEGMENTATION_REPORTLET_TABS,
} from "./CrossShopSegmentationReportlet";

export type FIlteredData = {
  category: string;
  data: SegmentationInteractionDataCut;
};

export const getItemDescriptionString = (focalItems: CrossShopTableItem[]) => {
  const vennSegments = ["A", "B", "C"];
  const itemDescription = focalItems.map(
    (item, index) =>
      `${vennSegments[index]}(${hierarchyLevelDisplayLabel(item.shortName)} ${
        item.name
      })`
  );
  return itemDescription.join(", ");
};

export const csvTransformation = (
  segmentationChartData: CrossShopSegmentationResponseDto | undefined,
  activeTab: string,
  activeProductInteractionsTab: string,
  activeSegmentTab: string,
  focalItems: CrossShopTableItem[],
  interactionsFilteredData: FIlteredData[] | undefined,
  segmentsFilteredData: FIlteredData[] | undefined,
  productInteractionLocalSelection: PanelOption,
  currencySymbol: string
) => {
  const csvData = [];

  csvData.push(["Item description", getItemDescriptionString(focalItems)]);

  // empty line
  csvData.push([]);

  const columnHeaders = ["Category"];
  csvData.push(columnHeaders);

  if (segmentationChartData) {
    if (
      activeTab === CROSS_SHOP_SEGMENTATION_REPORTLET_TABS.ProductInteractions
    ) {
      if (interactionsFilteredData) {
        columnHeaders.push(
          ...interactionsFilteredData.map((data) => {
            if (
              productInteractionLocalSelection.value ===
                ProductInteraction.SalesL &&
              segmentationChartData.dataFormat[ProductInteraction.SalesL] ===
                MetricTypes.Currency &&
              activeProductInteractionsTab ===
                CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual
            ) {
              return `${productInteractionLocalSelection.label}(${currencySymbol}) - ${data.category}`;
            }

            return `${productInteractionLocalSelection.label} - ${data.category}`;
          })
        );
        for (
          let index = 0;
          index < segmentationChartData.interaction.categories.length;
          ++index
        ) {
          const row = [
            segmentationChartData.interaction.categories[index],
            ...interactionsFilteredData.map((item) => {
              if (
                activeProductInteractionsTab ===
                CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual
              ) {
                return typeof item.data.actual[index] === "number"
                  ? `${item.data.actual[index]}`
                  : "-";
              }

              return typeof item.data.index[index] === "number"
                ? `${item.data.index[index]}`
                : "-";
            }),
          ];
          csvData.push(row);
        }
      }
    } else if (segmentsFilteredData) {
      columnHeaders.push(
        ...segmentsFilteredData.map((data) => {
          if (
            productInteractionLocalSelection.value ===
              ProductInteraction.SalesL &&
            segmentationChartData.dataFormat[ProductInteraction.SalesL] ===
              MetricTypes.Currency &&
            activeSegmentTab ===
              CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual
          ) {
            return `${productInteractionLocalSelection.label}(${currencySymbol}) - ${data.category}`;
          }

          return `${productInteractionLocalSelection.label} - ${data.category}`;
        })
      );
      for (
        let index = 0;
        index < segmentationChartData.segment.categories.length;
        ++index
      ) {
        const row = [
          segmentationChartData.segment.categories[index],
          ...segmentsFilteredData.map((item) => {
            if (
              activeSegmentTab ===
              CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual
            ) {
              return typeof item.data.actual[index] === "number"
                ? `${item.data.actual[index]}`
                : "-";
            }

            return typeof item.data.index[index] === "number"
              ? `${item.data.index[index]}`
              : "-";
          }),
        ];
        csvData.push(row);
      }
    }
  }

  return csvData;
};
