export const DataLabelsIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1060_3341)">
      <path
        d="M17.5 3.25H6.25C5.7325 3.25 5.3275 3.5125 5.0575 3.91L1 10L5.0575 16.0825C5.3275 16.48 5.785 16.75 6.3025 16.75H17.5C18.325 16.75 19 16.075 19 15.25V4.75C19 3.925 18.325 3.25 17.5 3.25ZM17.5 15.25H6.3025L2.8 10L6.295 4.75H17.5V15.25Z"
        fill="#676D70"
      />
      <path
        d="M7.75 11.125C8.37132 11.125 8.875 10.6213 8.875 10C8.875 9.37868 8.37132 8.875 7.75 8.875C7.12868 8.875 6.625 9.37868 6.625 10C6.625 10.6213 7.12868 11.125 7.75 11.125Z"
        fill="#676D70"
      />
      <path
        d="M11.5 11.125C12.1213 11.125 12.625 10.6213 12.625 10C12.625 9.37868 12.1213 8.875 11.5 8.875C10.8787 8.875 10.375 9.37868 10.375 10C10.375 10.6213 10.8787 11.125 11.5 11.125Z"
        fill="#676D70"
      />
      <path
        d="M15.25 11.125C15.8713 11.125 16.375 10.6213 16.375 10C16.375 9.37868 15.8713 8.875 15.25 8.875C14.6287 8.875 14.125 9.37868 14.125 10C14.125 10.6213 14.6287 11.125 15.25 11.125Z"
        fill="#676D70"
      />
    </g>
    <defs>
      <clipPath id="clip0_1060_3341">
        <rect fill="white" height="18" transform="translate(1 1)" width="18" />
      </clipPath>
    </defs>
  </svg>
);
