import { type DragEvent } from "react";
import { createRoot } from "react-dom/client";

export const setDraggedImageToElement = (
  event: DragEvent<HTMLElement>,
  element: JSX.Element
): void => {
  const ghost = document.createElement("div");
  // Gets rid of black line above dragged element
  ghost.style.transform = "translate(-10000px, -10000px)";
  // Reduces transparency
  ghost.style.position = "absolute";

  document.body.appendChild(ghost);

  event.dataTransfer.setDragImage(ghost, 0, 0);

  const ghostRoot = createRoot(ghost);

  ghostRoot.render(element);
};
