import { Navigate, Outlet } from "react-router-dom";
import { CustomerGroupTab } from "./enums/customer-group-tab-ids";
import { GroupType } from "./enums/group-type";
import GroupCreator from "./pages/group-creator/GroupCreator";
import { GroupList } from "./pages/group-list/GroupList";
import { GroupWizard } from "./pages/group-wizard/GroupWizard";

// Order matters for path matching
export const groupsRoutes = [
  {
    element: <GroupWizard />,
    label: "Group creation wizard",
    path: "create/:groupType/:templateType",
  },
  {
    element: <GroupWizard />,
    label: "Group edit wizard",
    path: "edit/:groupType/:groupId",
  },
  {
    element: <GroupCreator />,
    label: "Group creator",
    path: "create/:groupType",
  },
  {
    element: <GroupList customerGroupTab={CustomerGroupTab.SegmentLibrary} />,
    label: "Segment library with info panel",
    path: ":groupType/segment-library/:groupId/:segmentKey",
  },
  {
    element: <GroupList customerGroupTab={CustomerGroupTab.SegmentLibrary} />,
    label: "Segment library",
    path: ":groupType/segment-library",
  },
  {
    element: <GroupList customerGroupTab={CustomerGroupTab.CustomerGroups} />,
    label: "Groups list with info panel",
    path: ":groupType/:groupId",
  },
  {
    element: <GroupList customerGroupTab={CustomerGroupTab.CustomerGroups} />,
    label: "Groups list",
    path: ":groupType",
  },
  {
    element: <Navigate to={GroupType.Product} />,
    label: "Groups list",
    path: "",
  },
];

// the component matching each of the routes matched by the url will be output here
export default () => <Outlet />;
