import {
  MetricTypes,
  useFormatter,
  useLabelFormatter,
} from "@quantium-enterprise/hooks-ui";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { GroupColumnChart } from "../../../../../components/src/charts/group-column-chart/GroupColumnChart";
import { type RootState } from "../../../store";
import { selectShowUniverseSummaryChartDataLabels } from "../../services/gains-and-loss-slice";
import { getUniverseSummaryChartData } from "./utils";

export type UniverseSummaryProps = {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
};

export const UniverseSummary = ({ onOptionsChanged }: UniverseSummaryProps) => {
  const showUniverseSummaryChartDataLabels = useSelector(
    selectShowUniverseSummaryChartDataLabels
  );
  const { data } = useSelector((state: RootState) => ({
    data: state.gainsAndLoss.universeSummaryData,
  }));

  const formatter = useFormatter()(data.data.format);

  const chartData = useMemo(
    () => structuredClone(getUniverseSummaryChartData(data.data.measures)),
    [data.data.measures]
  );
  const yAxisLabel = useLabelFormatter(
    data.data.measureName,
    MetricTypes[data.data.format as keyof typeof MetricTypes]
  );

  return (
    <GroupColumnChart
      categories={data.measureDisplayNames}
      data={chartData}
      dataLabelFormatter={formatter}
      onOptionsChanged={onOptionsChanged}
      showChartDataLabels={showUniverseSummaryChartDataLabels}
      tooltipFormatter={formatter}
      yAxisLabel={yAxisLabel}
      yAxisTickFormatter={formatter}
    />
  );
};
