import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import * as tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import { type HeaderContext, type CellContext } from "@tanstack/react-table";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import { EllipsisLabel } from "components-ui/src/ellipsis-label/EllipsisLabel";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { type InteractionsVennMetric } from "../../models/InteractionsVennMetric";
import { type InteractionsVennMetricColumn } from "../../models/InteractionsVennMetricColumn";
import { type InteractionsVennMetricColumnHeaderKey } from "../../models/InteractionsVennMetricColumnHeaderKey";
import { selectFocalItems } from "../../services/customer-cross-shop-slice";
import { ItemCombinationColumnHeader } from "./ItemCombinationColumnHeader";
import styles from "./ItemCombinationTable.module.css";
import { ItemTable, ItemTableVariant } from "./ItemTable";
import { ItemTableCellFormatter } from "./ItemTableCellFormatter";

const LoyaltyMetricHeader = () => (
  <span className={styles.loyaltyMetricHeaderContent}>Loyalty (L) Metrics</span>
);

const InteractionsVennMetricCellFormatter = ({
  displayName,
}: {
  displayName: string;
}) => (
  <EllipsisLabel className={styles.ellipseContentOuter}>
    {displayName}
  </EllipsisLabel>
);

export type ItemCombinationTableProps = {
  colourMap: Record<InteractionsVennMetricColumnHeaderKey, string[]>;
  columns: InteractionsVennMetricColumn[];
  metrics: InteractionsVennMetric[];
  selectedColumnColour?: string;
  selectedColumns?: string[];
};

export const ItemCombinationTable = ({
  columns,
  metrics,
  colourMap,
  selectedColumns,
  selectedColumnColour,
}: ItemCombinationTableProps) => {
  const focalItems = useSelector(selectFocalItems);

  const table = useReactTable({
    data: metrics,
    columns: useMemo(() => {
      const itemCombinationColumnDefs = columns.map((column) => {
        let style = {};

        if (
          selectedColumns &&
          selectedColumnColour &&
          selectedColumns.includes(column.key)
        ) {
          style = { backgroundColor: selectedColumnColour };
        }

        return {
          accessorFn: (row: InteractionsVennMetric): number | null =>
            row.metricValues.find((x) => x.key === column.key)?.value ?? null,
          cell: (info: CellContext<InteractionsVennMetric, number>) =>
            ItemTableCellFormatter({
              measureFormat: info.row.original.format,
              value: info.getValue(),
              className: styles.cell,
              style,
            }),
          header: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _properties: HeaderContext<InteractionsVennMetric, unknown>
          ) => ItemCombinationColumnHeader({ ...column, colourMap }),
          id: `${column.key}-${uniqueId()}`,
        };
      });

      return [
        {
          accessorFn: (row: InteractionsVennMetric) => row.displayName,
          header: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _properties: HeaderContext<InteractionsVennMetric, string>
          ) => LoyaltyMetricHeader(),
          cell: ({ getValue }: CellContext<InteractionsVennMetric, string>) =>
            InteractionsVennMetricCellFormatter({ displayName: getValue() }),
          id: "loyalty-metrics",
        },
        ...itemCombinationColumnDefs,
      ];
    }, [colourMap, columns, selectedColumnColour, selectedColumns]),
    getCoreRowModel: getCoreRowModel(),
    enablePinning: true,
  });

  useEffect(() => {
    table.getHeaderGroups()[0].headers[0].column.pin("left");
  }, [table]);

  return (
    <ItemTable
      className={styles.itemCombinationTableWrapper}
      heading="Item combinations"
      pinFirstColumn
      // TODO: need to figure out a better way to pass a long the solid colour for the first column of table
      pinnedColumnStyles={{
        backgroundColor: tokens.colour["brand-primary"].white,
      }}
      table={table}
      variant={
        // TODO: update hardcoded limit to the provided one from report
        focalItems.length === 3
          ? ItemTableVariant.Grow
          : ItemTableVariant.Default
      }
    />
  );
};
