import {
  SegmentationType,
  type HierarchySliceNodeDto,
  ParameterId,
  type LocalHierarchyNodeSelection,
  type LocalSegmentationGroupSelection,
} from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import {
  addSelection,
  isMaximumSelected,
  isSelected,
  removeSelection,
} from "components-ui/src/local-filters/multiCheckboxFilter/utils";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import {
  type SidePanelParameter,
  type SidePanelMultiCheckboxParameter,
} from "../../common/models/local-parameters/SidePanelParameters";
import {
  focalItemsHasDuplicateNames,
  getFocalItemLabels,
} from "../../common/utils/focal-item-labels-utils";
import { isLocalSegmentationSelection } from "../../common/utils/local-parameters/LocalParametersUtils";
import { getReportLocalParameters } from "../../common/utils/local-parameters/getReportLocalParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { convertPanelOptionToString } from "../components/TopDrawerProductTable/utils";
import {
  type CustomerProfilingMultiSegmentationReportletData,
  type CustomerProfilingMultiSegmentationResponseDto,
} from "../models/CustomerProfilingMultiSegmentationResponseDto";
import { type CustomerProfilingReportParametersResponseDto } from "../models/CustomerProfilingReportParametersDto";
import {
  type TableChildrenResponseDto,
  type TableResponseDto,
} from "../models/CustomerProfilingReportTableResponseDto";
import { type CustomerProfilingSegmentsOverTimeFilters } from "../models/CustomerProfilingSegmentsOverTimeReportlet";
import { type SegmentsOverTimeSegmentationItem } from "../models/CustomerProfilingSegmentsOverTimeResponseDto";
import {
  type CustomerProfilingSingleSegmentationResponseDto,
  type CustomerProfilingSingleSegmentationMetricValue,
} from "../models/CustomerProfilingSingleSegmentationResponseDto";
import { getLocalSelections } from "../utils/getLocalSelections";

export type CustomerProfilingSidePanelSelections = {
  channel: PanelOption;
  indexedAgainst: PanelOption;
  location: LocalHierarchyNodeSelection;
  metric: PanelOption;
  promotion: PanelOption;
  segmentation: PanelOption[];
  time: string;
  timePeriodLength: string;
};

export type CustomerProfilingPeristedSelections = {
  focalItems: HierarchySliceNodeDto[];
  localSelections: CustomerProfilingSidePanelSelections;
};

export type CustomerProfilingState = {
  customerProfilingMultiSegmentationData: CustomerProfilingMultiSegmentationReportletData[];
  customerProfilingSingleSegmentationData: CustomerProfilingSingleSegmentationMetricValue;
  focalItemParents: HierarchySliceNodeDto[];
  focalItemTableInitialised: boolean;
  isCustomerProfilingMultiSegmentationLoading: boolean;
  isCustomerProfilingSegmentsOverTimeLoading: boolean;
  isCustomerProfilingSingleSegmentationLoading: boolean;
  latestSegmentsOverTimeRequestId: string;
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  persistedSelections: CustomerProfilingPeristedSelections;
  persistedSelectionsLoaded: boolean;
  reportError: boolean;
  reportId: string;
  reportName: string;
  reportSegmentationMeta: LocalSegmentationGroupSelection[];
  segmentsOverTimeData: SegmentsOverTimeSegmentationItem[];
  segmentsOverTimeFilters: CustomerProfilingSegmentsOverTimeFilters;
  showCustomerProfilingChartDataLabels: boolean;
  showSegmentsOverTimeChartDataLabels: boolean;
  tableResponse: TableResponseDto;
};

export const initialState: CustomerProfilingState = {
  customerProfilingSingleSegmentationData: {
    format: "",
    metricName: "",
    displayName: "",
    items: [],
  },
  customerProfilingMultiSegmentationData: [
    {
      displayName: "",
      format: "",
      itemName: "",
      metricName: "",
      segmentationBItems: [],
    },
  ],
  focalItemTableInitialised: false,
  focalItemParents: [],
  isCustomerProfilingMultiSegmentationLoading: true,
  isCustomerProfilingSingleSegmentationLoading: true,
  isCustomerProfilingSegmentsOverTimeLoading: true,
  localParameters: [],
  localParametersInitialised: false,
  persistedSelections: {
    focalItems: [],
    localSelections: {
      channel: {
        value: "",
        label: "",
      },
      indexedAgainst: {
        value: "",
        label: "",
      },
      location: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
        isDefault: false,
      },
      metric: {
        value: "",
        label: "",
      },
      promotion: {
        value: "",
        label: "",
      },
      segmentation: [],
      time: "",
      timePeriodLength: "",
    },
  },
  persistedSelectionsLoaded: false,
  reportId: "",
  reportName: "",
  tableResponse: { metricValues: [], segments: [] },
  reportError: false,
  segmentsOverTimeFilters: {
    chartSeriesActiveButtonIndex: 0,
    segmentationActiveButtonIndex: 0,
    focalItemFilter: {
      activeOption: undefined,
      options: [],
    },
    segmentsFilter: {
      activeOption: undefined,
      options: [],
    },
  },
  latestSegmentsOverTimeRequestId: "",
  segmentsOverTimeData: [],
  reportSegmentationMeta: [],
  showCustomerProfilingChartDataLabels: false,
  showSegmentsOverTimeChartDataLabels: false,
};

const getPersistenceKey = (reportId: string) =>
  `customer-profiling-${reportId}`;

export const customerProfilingSlice = createSlice({
  initialState,
  name: "customerProfiling",
  reducers: {
    onChannelChange: (
      state: CustomerProfilingState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.channel = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onIndexedAgainstChange: (
      state: CustomerProfilingState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.indexedAgainst = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onTableResponseReceived: (
      state: CustomerProfilingState,
      { payload }: PayloadAction<TableResponseDto>
    ) => {
      state.tableResponse = payload;
      state.focalItemTableInitialised = true;
    },
    onTableChildrenDataRecieved: (
      state: CustomerProfilingState,
      { payload }: PayloadAction<TableChildrenResponseDto>
    ) => {
      for (const metricValue of state.tableResponse.metricValues) {
        const newItems = payload.metricValues.find(
          (x) => x.metricName === metricValue.metricName
        )?.items;
        if (newItems) {
          metricValue.items.push(...newItems);
        }
      }
    },
    onLocationChange: (
      state: CustomerProfilingState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localSelections.location = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onMetricChange: (
      state: CustomerProfilingState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.metric = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onPromotionChange: (
      state: CustomerProfilingState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.promotion = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onReportError: (state: CustomerProfilingState) => {
      state.reportError = true;
      state.isCustomerProfilingMultiSegmentationLoading = false;
      state.isCustomerProfilingSingleSegmentationLoading = false;
    },
    onReportSuccess: (
      state: CustomerProfilingState,
      action: PayloadAction<CustomerProfilingReportParametersResponseDto>
    ) => {
      state.reportName = action.payload.reportName;
      state.localParameters = getReportLocalParameters(
        action.payload.localParameters
      );

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.focalItems = action.payload.focalItems;
        state.persistedSelections.localSelections = getLocalSelections(
          state.localParameters
        );
      }

      state.localParametersInitialised = true;
      state.reportSegmentationMeta =
        action.payload.localParameters
          .find((parameter) => parameter.id === ParameterId.Segmentation)
          ?.selections.map((selection) =>
            isLocalSegmentationSelection(selection)
              ? {
                  isDefault: selection.isDefault,
                  name: selection.name,
                  id: selection.id,
                  segmentationType: selection.segmentationType,
                }
              : {
                  isDefault: false,
                  name: "",
                  id: "",
                  segmentationType: SegmentationType.Standard,
                }
          ) ?? [];

      if (focalItemsHasDuplicateNames(state.persistedSelections.focalItems)) {
        const focalItemLabels = getFocalItemLabels(
          state.persistedSelections.focalItems,
          state.focalItemParents
        );
        const filters = state.persistedSelections.focalItems.map((item) => ({
          ...item,
          name: focalItemLabels[item.nodeNumber] ?? item.name,
        }));
        state.segmentsOverTimeFilters.focalItemFilter.options = filters;
      } else {
        state.segmentsOverTimeFilters.focalItemFilter.options =
          state.persistedSelections.focalItems;
      }

      state.segmentsOverTimeFilters.focalItemFilter.activeOption =
        state.persistedSelections.focalItems[0];

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: CustomerProfilingState,
      action: PayloadAction<PanelOption>
    ) => {
      if (
        isSelected(
          convertPanelOptionToString(
            state.persistedSelections.localSelections.segmentation
          ),
          action.payload.value.toString()
        )
      ) {
        state.persistedSelections.localSelections.segmentation =
          removeSelection(
            state.persistedSelections.localSelections.segmentation,
            action.payload
          );

        persistSelections(
          getPersistenceKey(state.reportId),
          state.persistedSelections
        );
        return;
      }

      if (
        !isMaximumSelected(
          convertPanelOptionToString(
            state.persistedSelections.localSelections.segmentation
          ),
          (
            state.localParameters.find(
              (localParameter) => localParameter.displayType === "MultiCheckbox"
            ) as SidePanelMultiCheckboxParameter
          ).maxSelections ?? 1
        )
      ) {
        state.persistedSelections.localSelections.segmentation = addSelection(
          state.persistedSelections.localSelections.segmentation,
          action.payload
        );
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setFocalItems: (
      state: CustomerProfilingState,
      action: PayloadAction<HierarchySliceNodeDto[]>
    ) => {
      state.persistedSelections.focalItems = action.payload;

      if (focalItemsHasDuplicateNames(state.persistedSelections.focalItems)) {
        const focalItemLabels = getFocalItemLabels(
          state.persistedSelections.focalItems,
          state.focalItemParents
        );
        const filters = state.persistedSelections.focalItems.map((item) => ({
          ...item,
          name: focalItemLabels[item.nodeNumber] ?? item.name,
        }));
        state.segmentsOverTimeFilters.focalItemFilter.options = filters;
      } else {
        state.segmentsOverTimeFilters.focalItemFilter.options = action.payload;
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onCustomerProfilingMultiSegmentationRequest: (
      state: CustomerProfilingState
    ) => {
      state.isCustomerProfilingMultiSegmentationLoading = true;
    },
    onCustomerProfilingMultiSegmentationResponseReceived: (
      state: CustomerProfilingState,
      action: PayloadAction<{
        metric: PanelOption;
        multiSegmentationData: CustomerProfilingMultiSegmentationResponseDto[];
      }>
    ) => {
      state.customerProfilingMultiSegmentationData =
        action.payload.multiSegmentationData.map((item) => ({
          ...(item.metricValues.find(
            (metricValue) =>
              metricValue.metricName === action.payload.metric.value
          ) ?? {
            displayName: "",
            format: "",
            metricName: "",
            segmentationBItems: [],
          }),
          itemName: item.itemName,
          nodeNumber: item.nodeNumber,
        }));

      state.isCustomerProfilingMultiSegmentationLoading = false;
    },
    onCustomerProfilingSingleSegmentationRequest: (
      state: CustomerProfilingState
    ) => {
      state.isCustomerProfilingSingleSegmentationLoading = true;
    },
    onCustomerProfilingSingleSegmentationResponseReceived: (
      state: CustomerProfilingState,
      action: PayloadAction<{
        metric: PanelOption;
        singleSegmentationData:
          | CustomerProfilingSingleSegmentationResponseDto
          | undefined;
      }>
    ) => {
      const filteredData =
        action.payload.singleSegmentationData?.metricValues.find(
          (metricValue) =>
            metricValue.metricName === (action.payload.metric.value as string)
        ) ?? { format: "", metricName: "", displayName: "", items: [] };

      state.customerProfilingSingleSegmentationData = {
        format: filteredData.format,
        metricName: filteredData.metricName,
        displayName: filteredData.displayName,
        items: filteredData.items,
      };

      state.isCustomerProfilingSingleSegmentationLoading = false;
    },
    onEmptySegmentationOrFocalItemsProfiling: (
      state: CustomerProfilingState
    ) => {
      if (state.localParametersInitialised) {
        state.customerProfilingSingleSegmentationData = {
          format: "",
          metricName: "",
          displayName: "",
          items: [],
        };
        state.isCustomerProfilingSingleSegmentationLoading = false;

        state.customerProfilingMultiSegmentationData = [
          {
            displayName: "",
            format: "",
            itemName: "",
            metricName: "",
            segmentationBItems: [],
          },
        ];
        state.isCustomerProfilingMultiSegmentationLoading = false;
      }
    },
    onEmptySegmentationOrFocalItemsOverTime: (
      state: CustomerProfilingState
    ) => {
      if (state.localParametersInitialised) {
        state.segmentsOverTimeData = [];
        state.isCustomerProfilingSegmentsOverTimeLoading = false;
      }
    },
    onChartSeriesToggleSelectionChange: (
      state: CustomerProfilingState,
      action: PayloadAction<number>
    ) => {
      state.segmentsOverTimeFilters.chartSeriesActiveButtonIndex =
        action.payload;

      // Reset dropdown selections to first option
      state.segmentsOverTimeFilters.focalItemFilter.activeOption =
        state.segmentsOverTimeFilters.focalItemFilter.options[0];

      customerProfilingSlice.caseReducers.setSegmentsOverTimeSegmentsFilterOptions(
        state
      );
    },
    onSegmentationToggleSelectionChange: (
      state: CustomerProfilingState,
      action: PayloadAction<number>
    ) => {
      state.segmentsOverTimeFilters.segmentationActiveButtonIndex =
        action.payload;

      customerProfilingSlice.caseReducers.setSegmentsOverTimeSegmentsFilterOptions(
        state
      );
    },
    setSegmentsOverTimeSegmentsFilterOptions: (
      state: CustomerProfilingState
    ) => {
      // Consolidate all segments from all focal items into a duduped list of
      // segments matching the selected segmentation
      const selectedSegmentation =
        state.persistedSelections.localSelections.segmentation[
          state.segmentsOverTimeFilters.segmentationActiveButtonIndex
        ];

      state.segmentsOverTimeFilters.segmentsFilter.options =
        state.segmentsOverTimeData
          .find(
            (item) =>
              item.segmentation === (selectedSegmentation.value as string)
          )
          ?.focalItems.flatMap((focalItem) => focalItem.segments)
          .filter((segment, index, self) => {
            const foundIndex = self.findIndex(
              (seg) => seg.segment === segment.segment
            );
            return foundIndex === index;
          }) ?? [];

      state.segmentsOverTimeFilters.segmentsFilter.activeOption =
        state.segmentsOverTimeFilters.segmentsFilter.options[0];
    },
    onFocalItemOptionSelectionChange: (
      state: CustomerProfilingState,
      action: PayloadAction<number>
    ) => {
      state.segmentsOverTimeFilters.focalItemFilter.activeOption =
        state.segmentsOverTimeFilters.focalItemFilter.options.find(
          (item) => item.nodeNumber === action.payload
        );
    },
    onSegmentOptionSelectionChange: (
      state: CustomerProfilingState,
      action: PayloadAction<string>
    ) => {
      const selectedSegment =
        state.segmentsOverTimeFilters.segmentsFilter.options.find(
          (option) => option.segment === action.payload
        );

      state.segmentsOverTimeFilters.segmentsFilter.activeOption =
        selectedSegment;
    },
    onCustomerProfilingSegmentsOverTimeRequest: (
      state: CustomerProfilingState
    ) => {
      state.isCustomerProfilingSegmentsOverTimeLoading = true;
    },
    onCustomerProfilingSegmentsOverTimeResponseReceived: (
      state: CustomerProfilingState,
      action: PayloadAction<SegmentsOverTimeSegmentationItem[]>
    ) => {
      state.segmentsOverTimeData = action.payload;

      state.segmentsOverTimeFilters.chartSeriesActiveButtonIndex = 0;
      state.segmentsOverTimeFilters.segmentationActiveButtonIndex = 0;

      // Reset dropdown selections to first option
      state.segmentsOverTimeFilters.focalItemFilter.activeOption =
        state.segmentsOverTimeFilters.focalItemFilter.options[0];

      state.segmentsOverTimeFilters.segmentsFilter.activeOption =
        state.segmentsOverTimeFilters.segmentsFilter.options[0];

      state.isCustomerProfilingSegmentsOverTimeLoading = false;
    },
    onReportOpen: (
      state: CustomerProfilingState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: CustomerProfilingPeristedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
    toggleCustomerProfilingChartDataLabels: (state: CustomerProfilingState) => {
      state.showCustomerProfilingChartDataLabels =
        !state.showCustomerProfilingChartDataLabels;
    },
    toggleSegmentsOverTimeChartDataLabels: (state: CustomerProfilingState) => {
      state.showSegmentsOverTimeChartDataLabels =
        !state.showSegmentsOverTimeChartDataLabels;
    },
    reset: () => initialState,
    onParentNodeDataReceived: (
      state: CustomerProfilingState,
      { payload }: PayloadAction<HierarchySliceNodeDto[]>
    ) => {
      state.focalItemParents = payload;

      // Update the segments over time filter options to have hierarchy slice nodes with modified names
      if (focalItemsHasDuplicateNames(state.persistedSelections.focalItems)) {
        const focalItemLabels = getFocalItemLabels(
          state.persistedSelections.focalItems,
          payload
        );
        const filters = state.persistedSelections.focalItems.map((item) => ({
          ...item,
          name: focalItemLabels[item.nodeNumber] ?? item.name,
        }));

        state.segmentsOverTimeFilters.focalItemFilter.options = filters;
      }
    },
  },
});

// SELECTORS
// These are for getting data out of the store. They are memoized, so they only recalculate if the state they depend on has changed.
export const selectReportId = createSelector(
  (state: RootState) => state.customerProfiling.reportId,
  (reportId) => reportId
);

export const selectFocalItems = createSelector(
  (state: RootState) => state.customerProfiling.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectShowCustomerProfilingChartDataLabels = createSelector(
  (state: RootState) =>
    state.customerProfiling.showCustomerProfilingChartDataLabels,
  (showCustomerProfilingChartDataLabels) => showCustomerProfilingChartDataLabels
);

export const selectShowSegmentsOverTimeChartDataLabels = createSelector(
  (state: RootState) =>
    state.customerProfiling.showSegmentsOverTimeChartDataLabels,
  (showSegmentsOverTimeChartDataLabels) => showSegmentsOverTimeChartDataLabels
);

export const selectChannel = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections.channel,
  (channel) => channel
);

export const selectIndexedAgainst = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections.indexedAgainst,
  (indexedAgainst) => indexedAgainst
);

export const selectLocation = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections.location,
  (location) => location
);

export const selectMetric = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections.metric,
  (metric) => metric
);

export const selectPromotion = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections.promotion,
  (promotion) => promotion
);

export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections.segmentation,
  (segmentation) => segmentation
);

export const selectTime = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections.time,
  (time) => time
);

export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.customerProfiling.persistedSelections.localSelections
      .timePeriodLength,
  (timePeriodLength) => timePeriodLength
);

export const selectFocalItemParents = createSelector(
  (state: RootState) => state.customerProfiling.focalItemParents,
  (focalItemParents) => focalItemParents
);

export const selectFocalItemFilter = createSelector(
  (state: RootState) =>
    state.customerProfiling.segmentsOverTimeFilters.focalItemFilter,
  (focalItemFilter) => focalItemFilter
);

export const selectLocalSelections = createSelector(
  [
    selectChannel,
    selectIndexedAgainst,
    selectLocation,
    selectMetric,
    selectPromotion,
    selectSegmentation,
    selectTime,
    selectTimePeriodLength,
  ],
  (
    channel,
    indexedAgainst,
    location,
    metric,
    promotion,
    segmentation,
    time,
    timePeriodLength
  ): CustomerProfilingSidePanelSelections => ({
    channel,
    indexedAgainst,
    location,
    metric,
    promotion,
    segmentation,
    time,
    timePeriodLength,
  })
);

export const {
  onChannelChange,
  onTableResponseReceived,
  onTableChildrenDataRecieved,
  onIndexedAgainstChange,
  onLocationChange,
  onMetricChange,
  onPromotionChange,
  onReportError,
  onReportSuccess,
  onSegmentationChange,
  setFocalItems,
  onCustomerProfilingMultiSegmentationRequest,
  onCustomerProfilingMultiSegmentationResponseReceived,
  onCustomerProfilingSingleSegmentationRequest,
  onCustomerProfilingSingleSegmentationResponseReceived,
  onEmptySegmentationOrFocalItemsProfiling,
  onEmptySegmentationOrFocalItemsOverTime,
  onFocalItemOptionSelectionChange,
  onChartSeriesToggleSelectionChange,
  onSegmentationToggleSelectionChange,
  onSegmentOptionSelectionChange,
  onCustomerProfilingSegmentsOverTimeRequest,
  onCustomerProfilingSegmentsOverTimeResponseReceived,
  onReportOpen,
  reset,
  setSegmentsOverTimeSegmentsFilterOptions,
  toggleCustomerProfilingChartDataLabels,
  toggleSegmentsOverTimeChartDataLabels,
  onParentNodeDataReceived,
} = customerProfilingSlice.actions;

export default customerProfilingSlice.reducer;
