import { type HierarchySelectLevel } from "./HierarchySelectFilter";

/* Hierarchy Select Filter utils */
export const isValidSelectedLevel = (
  levels: HierarchySelectLevel[],
  index: number
) => levels.length > index;

export const levelHasValidOptions = (
  levels: HierarchySelectLevel[],
  index: number
) =>
  isValidSelectedLevel(levels, index) &&
  levels[index].options.length > 0 &&
  levels[index].options.some((option) => option.options.length > 0);

export const isTypeaheadDisabled = (
  levels: HierarchySelectLevel[],
  level: number
) => {
  if (levelHasValidOptions(levels, level)) {
    return (
      levels[level].options.length <= 1 &&
      levels[level].options[0].options.length <= 1
    );
  }

  return true;
};

export const getDefaultItem = (
  levels: HierarchySelectLevel[],
  level: number
) => {
  if (levelHasValidOptions(levels, level)) {
    // A valid option list must not be empty
    const firstValidOptionsList =
      levels[level].options.find((option) => option.options.length > 0)
        ?.options ?? [];

    if (firstValidOptionsList.length === 0) {
      return undefined;
    }

    // Return the default item or the first item in the list
    return (
      firstValidOptionsList.find((option) => option.isDefault) ??
      firstValidOptionsList[0]
    );
  }

  return undefined;
};

// parse the levels options > options to find the option with the specified value
export const findOption = (levels: HierarchySelectLevel[], value: string) =>
  levels
    .flatMap((item) => item.options)
    .flatMap((opt) => opt.options)
    .find((x) => x.value === value);

// find the top level item index that contains this child option value
export const findSelectedLevelIndex = (
  levels: HierarchySelectLevel[],
  value: string
) => {
  const option = findOption(levels, value);
  if (option) {
    return levels.findIndex((level) =>
      level.options.find((subOption) => subOption.options.includes(option))
    );
  }

  return -1;
};
