import {
  savedParametersApi,
  reportParametersWebApi,
  userApi,
  foldersApi,
} from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  configureStore,
  combineReducers,
  type Middleware,
  type AnyAction,
  type Dispatch,
} from "@reduxjs/toolkit";
import { aggregateRankApiSlice } from "./aggregate-rank/services/aggregate-rank-api-slice";
import aggregateRankReducer from "./aggregate-rank/services/aggregate-rank-slice";
import { basketAffinitiesApiSlice } from "./basket-affinities/services/basket-affinities-api-slice";
import basketAffinitiesReducer from "./basket-affinities/services/basket-affinities-slice";
import { basketLimitsApiSlice } from "./basket-limits/services/basket-limits-api-slice";
import basketLimitsReducer from "./basket-limits/services/basket-limits-slice";
import { basketQuantitiesApiSlice } from "./basket-quantities/services/basket-quantities-api-slice";
import basketQuantitiesReducer from "./basket-quantities/services/basket-quantities-slice";
import { hierarchySliceApi } from "./common/services/hierarchy-slice";
import { compareMetricsApiSlice } from "./compare-metrics/services/compare-metrics-api-slice";
import compareMetricsReducer from "./compare-metrics/services/compare-metrics-slice";
import { customerCrossShopApiSlice } from "./customer-cross-shop/services/customer-cross-shop-api-slice";
import customerCrossShopReducer from "./customer-cross-shop/services/customer-cross-shop-slice";
import { customerLoyaltyApiSlice } from "./customer-loyalty/services/customer-loyalty-api-slice";
import customerLoyaltyReducer from "./customer-loyalty/services/customer-loyalty-slice";
import { customerProfilingApiSlice } from "./customer-profiling/services/customer-profiling-api-slice";
import customerProfilingReducer from "./customer-profiling/services/customer-profiling-slice";
import { gainsAndLossApiSlice } from "./gains-and-loss/services/gains-and-loss-api-slice";
import gainsAndLossReducer from "./gains-and-loss/services/gains-and-loss-slice";
import { keyDriverTreeApiSlice } from "./key-driver-tree/services/key-driver-tree-api-slice";
import keyDriverTreeReducer from "./key-driver-tree/services/key-driver-tree-slice";
import { keyDriversOverTimeApiSlice } from "./key-drivers-over-time/services/key-drivers-over-time-api-slice";
import keyDriversOverTimeReducer from "./key-drivers-over-time/services/key-drivers-over-time-slice";
import { keyMeasureTrendsApiSlice } from "./key-measure-trends/services/key-measure-trend-api-slice";
import keyMeasureTrendsReducer from "./key-measure-trends/services/key-measure-trends-slice";
import { newProductBenchmarkingApiSlice } from "./new-product-benchmarking/services/new-product-benchmarking-api-slice";
import newProductBenchmarkingReducer from "./new-product-benchmarking/services/new-product-benchmarking-slice";
import { pricingLaddersApiSlice } from "./pricing-ladders/services/pricing-ladders-api-slice";
import pricingLaddersReducer from "./pricing-ladders/services/pricing-ladders-slice";
import { productSubstitutabilityApiSlice } from "./product-substitutability/services/product-substitutability-api-slice";
import productSubstitutabilityReducer from "./product-substitutability/services/product-substitutability-slice";
import { repertoireApiSlice } from "./repertoire/services/repertoire-api-slice";
import repertoireReducer from "./repertoire/services/repertoire-slice";
import { timeOfDayDayOfWeekApiSlice } from "./time-of-day-day-of-week/services/time-of-day-day-of-week-api-slice";
import timeOfDayDayOfWeekReducer from "./time-of-day-day-of-week/services/time-of-day-day-of-week-slice";
import { trialAndRepeatApiSlice } from "./trial-and-repeat/services/trial-and-repeat-api-slice";
import trialAndRepeatReducer from "./trial-and-repeat/services/trial-and-repeat-slice";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [savedParametersApi.reducerPath]: savedParametersApi.reducer,
  [reportParametersWebApi.reducerPath]: reportParametersWebApi.reducer,
  [foldersApi.reducerPath]: foldersApi.reducer,
  [hierarchySliceApi.reducerPath]: hierarchySliceApi.reducer,
  [aggregateRankApiSlice.reducerPath]: aggregateRankApiSlice.reducer,
  aggregateRank: aggregateRankReducer,
  [basketAffinitiesApiSlice.reducerPath]: basketAffinitiesApiSlice.reducer,
  basketAffinities: basketAffinitiesReducer,
  [basketLimitsApiSlice.reducerPath]: basketLimitsApiSlice.reducer,
  basketLimits: basketLimitsReducer,
  [basketQuantitiesApiSlice.reducerPath]: basketQuantitiesApiSlice.reducer,
  basketQuantities: basketQuantitiesReducer,
  [compareMetricsApiSlice.reducerPath]: compareMetricsApiSlice.reducer,
  compareMetrics: compareMetricsReducer,
  [customerCrossShopApiSlice.reducerPath]: customerCrossShopApiSlice.reducer,
  customerCrossShop: customerCrossShopReducer,
  [customerLoyaltyApiSlice.reducerPath]: customerLoyaltyApiSlice.reducer,
  customerLoyalty: customerLoyaltyReducer,
  [customerProfilingApiSlice.reducerPath]: customerProfilingApiSlice.reducer,
  customerProfiling: customerProfilingReducer,
  [gainsAndLossApiSlice.reducerPath]: gainsAndLossApiSlice.reducer,
  gainsAndLoss: gainsAndLossReducer,
  [keyDriversOverTimeApiSlice.reducerPath]: keyDriversOverTimeApiSlice.reducer,
  keyDriversOverTime: keyDriversOverTimeReducer,
  [keyDriverTreeApiSlice.reducerPath]: keyDriverTreeApiSlice.reducer,
  keyDriverTree: keyDriverTreeReducer,
  [keyMeasureTrendsApiSlice.reducerPath]: keyMeasureTrendsApiSlice.reducer,
  keyMeasureTrends: keyMeasureTrendsReducer,
  [newProductBenchmarkingApiSlice.reducerPath]:
    newProductBenchmarkingApiSlice.reducer,
  newProductBenchmarking: newProductBenchmarkingReducer,
  pricingLadders: pricingLaddersReducer,
  [pricingLaddersApiSlice.reducerPath]: pricingLaddersApiSlice.reducer,
  [productSubstitutabilityApiSlice.reducerPath]:
    productSubstitutabilityApiSlice.reducer,
  productSubstitutability: productSubstitutabilityReducer,
  [repertoireApiSlice.reducerPath]: repertoireApiSlice.reducer,
  repertoire: repertoireReducer,
  [timeOfDayDayOfWeekApiSlice.reducerPath]: timeOfDayDayOfWeekApiSlice.reducer,
  timeOfDayDayOfWeek: timeOfDayDayOfWeekReducer,
  [trialAndRepeatApiSlice.reducerPath]: trialAndRepeatApiSlice.reducer,
  trialAndRepeat: trialAndRepeatReducer,
});

const middlewareFunction =
  (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    if (
      action.meta?.baseQueryMeta?.response?.status === 401 ||
      action.payload?.status === 401
    ) {
      window.location.assign(
        `/sso-proxy/challenge-with-fragment?redirectUri=${encodeURIComponent(
          window.location.href
        )}`
      );
    }

    return next(action);
  };

export const rtkQueryErrorHandler: Middleware = () => middlewareFunction;

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "reports" },
    middleware: (getDefaultMiddleware) =>
      // serializableCheck is set to false since members of payload having JSX.Element which are not serializable and causing console errors.
      getDefaultMiddleware({ serializableCheck: false }).concat(
        rtkQueryErrorHandler,
        userApi.middleware,
        savedParametersApi.middleware,
        reportParametersWebApi.middleware,
        foldersApi.middleware,
        hierarchySliceApi.middleware,
        aggregateRankApiSlice.middleware,
        basketAffinitiesApiSlice.middleware,
        basketLimitsApiSlice.middleware,
        basketQuantitiesApiSlice.middleware,
        compareMetricsApiSlice.middleware,
        customerCrossShopApiSlice.middleware,
        customerLoyaltyApiSlice.middleware,
        customerProfilingApiSlice.middleware,
        gainsAndLossApiSlice.middleware,
        keyDriverTreeApiSlice.middleware,
        keyDriversOverTimeApiSlice.middleware,
        keyMeasureTrendsApiSlice.middleware,
        newProductBenchmarkingApiSlice.middleware,
        pricingLaddersApiSlice.middleware,
        productSubstitutabilityApiSlice.middleware,
        repertoireApiSlice.middleware,
        timeOfDayDayOfWeekApiSlice.middleware,
        trialAndRepeatApiSlice.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
