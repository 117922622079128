import { ReportType } from "@quantium-enterprise/common-ui";
import { AggregateRankIcon } from "./icons/AggregateRankIcon";
import { BasketAffinitiesIcon } from "./icons/BasketAffinitiesIcon";
import { BasketLimitsIcon } from "./icons/BasketLimitsIcon";
import { BasketQuantitiesIcon } from "./icons/BasketQuantitiesIcon";
import { CompareMetricsIcon } from "./icons/CompareMetricsIcon";
import { CustomerCrossShopIcon } from "./icons/CustomerCrossShopIcon";
import { CustomerLoyaltyIcon } from "./icons/CustomerLoyaltyIcon";
import { CustomerProfilingIcon } from "./icons/CustomerProfilingIcon";
import { GainsAndLossIcon } from "./icons/GainsAndLossIcon";
import { KeyDriverTreeIcon } from "./icons/KeyDriverTreeIcon";
import { KeyDriversOverTimeIcon } from "./icons/KeyDriversOverTimeIcon";
import { KeyMeasureTrendsIcon } from "./icons/KeyMeasureTrendsIcon";
import { NewProductBenchmarkingIcon } from "./icons/NewProductBenchmarkingIcon";
import { PricingLaddersIcon } from "./icons/PricingLaddersIcon";
import { ProductSubstitutabilityIcon } from "./icons/ProductSubstitutabilityIcon";
import { RepertoireIcon } from "./icons/RepertoireIcon";
import { TimeOfDayDayOfWeekIcon } from "./icons/TimeOfDayDayOfWeekIcon";
import { TrialAndRepeatIcon } from "./icons/TrialAndRepeatIcon";

export type ReportIconProps = {
  className?: string;
  coloured?: boolean;
  text?: string;
  type: string | undefined;
};

export const ReportIcon = ({
  className,
  coloured = true,
  text,
  type,
}: ReportIconProps) => {
  switch (true) {
    case type === ReportType.AggregateRank:
      return (
        <AggregateRankIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.BasketAffinities:
      return (
        <BasketAffinitiesIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.BasketLimits:
      return (
        <BasketLimitsIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.BasketQuantities:
      return (
        <BasketQuantitiesIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.CompareMetrics:
      return (
        <CompareMetricsIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.CustomerCrossShop:
      return (
        <CustomerCrossShopIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.CustomerLoyalty:
      return (
        <CustomerLoyaltyIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.CustomerProfiling:
      return (
        <CustomerProfilingIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.GainsAndLoss:
      return (
        <GainsAndLossIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.KeyDriversOverTime:
      return (
        <KeyDriversOverTimeIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.KeyDriverTree:
      return (
        <KeyDriverTreeIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.KeyMeasureTrends:
      return (
        <KeyMeasureTrendsIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.NewProductBenchmarking:
      return (
        <NewProductBenchmarkingIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.PricingLadders:
      return (
        <PricingLaddersIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.ProductSubstitutability:
      return (
        <ProductSubstitutabilityIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.Repertoire:
      return (
        <RepertoireIcon className={className} coloured={coloured} text={text} />
      );
    case type === ReportType.TimeOfDayDayOfWeek:
      return (
        <TimeOfDayDayOfWeekIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === ReportType.TrialAndRepeat:
      return (
        <TrialAndRepeatIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    default:
      return null;
  }
};
