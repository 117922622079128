import { type SidePanelTimePeriodParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";

export const columnName = (
  metric: string,
  format: string,
  CurrencySymbol: string
) => {
  if (format === "Currency") {
    return `${metric} (${CurrencySymbol})`;
  } else {
    return `${metric}`;
  }

  return "";
};

export const getFileName = (reportletSelection: String) => {
  if (reportletSelection === "Chart") {
    return "Pricing_ladders_Chart";
  } else {
    return "Pricing_ladders_Table";
  }

  return "";
};

export const timeData = (time: SidePanelTimePeriodParameter | undefined) => {
  if (time) {
    return getTimeDefaultSelection(time);
  }

  return { time: "", timePeriodLength: "" };
};
