import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import {
  formatMetricValue,
  getCsvColumnHeader,
} from "../../../common/utils/export-parameter-summary-utils";
import {
  type CrossShopMetric,
  type CrossShopTableRow,
} from "../../models/CrossShopDataTableResponseDto";
import { type InteractionsVennResponseDto } from "../../models/InteractionsVennResponseDto";
import { type MiniFocalTableRow } from "../../models/MiniFocalTableRow";

export const CrossShopInteractionsVennFocalItemCsvTransformation = (
  metrics: CrossShopMetric[],
  currencySymbol: string,
  tableRows: CrossShopTableRow[] | MiniFocalTableRow[] | undefined
) => {
  const columnHeaders = ["Item Description"];
  const csvData = [columnHeaders];

  if (tableRows) {
    columnHeaders.push(
      ...metrics.map((data) =>
        getCsvColumnHeader(data.format, currencySymbol, data.displayName)
      )
    );

    for (const tableRow of tableRows) {
      const row = [];
      row.push(
        `(${hierarchyLevelDisplayLabel(tableRow.item.shortName)}) ${
          tableRow.item.name
        }`
      );
      row.push(
        ...tableRow.metricValues.map((value) => `${formatMetricValue(value)}`)
      );
      csvData.push(row);
    }
  }

  return csvData;
};

export const CrossShopInteractionsVennItemCombinationCsvTransformation = (
  tableRows: InteractionsVennResponseDto | undefined,
  currencySymbol: string
) => {
  const columnHeaders = ["Loyalty (L) Metrics"];
  const csvData = [columnHeaders];

  if (tableRows) {
    columnHeaders.push(...tableRows.columns.map((col) => col.displayName));

    for (const metric of tableRows.metrics) {
      const row = [];
      row.push(
        getCsvColumnHeader(metric.format, currencySymbol, metric.displayName)
      );

      for (const column of tableRows.columns) {
        const rowData = metric.metricValues.find(
          (metricValue) => metricValue.key === column.key
        );
        row.push(`${formatMetricValue(rowData?.value)}`);
      }

      csvData.push(row);
    }
  }

  return csvData;
};
