export const SALES_INDEXED = "SalesIndexed";
export const SALES_INDEXED_THRESHOLD = 100;

export const EXPORT_LOCATION_SUFFIX = "locations";
export const EXPORT_ITEMS_SUFFIX = "items";

// Initial hierarchy node - set node number to impossibly low
// so no other node would be mistaken as initial state
export const EMPTY_NODE_NUMBER = Number.MIN_SAFE_INTEGER;
export const EMPTY_HIERARCHY_SLICE_NODE = {
  code: "",
  depth: -1,
  isBenchmark: false,
  isLeaf: false,
  name: "",
  nodeNumber: EMPTY_NODE_NUMBER,
  shortName: "",
  isDefault: false,
};
