type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const GainsAndLossIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Gains and loss report"}</title>
      <path
        d="M17.3478 12.3045C17.5507 12.1531 17.5507 11.8469 17.3478 11.6955L11.3204 7.19981C11.0728 7.01511 10.7223 7.19353 10.7223 7.5043V9.34967H6.87513C6.66795 9.34967 6.5 9.51918 6.5 9.72829V14.2717C6.5 14.4808 6.66795 14.6503 6.87513 14.6503H10.7223V16.4957C10.7223 16.8065 11.0728 16.9849 11.3204 16.8002L17.3478 12.3045Z"
        fill={colour2}
      />
      <path
        d="M5.5 12.125C5.5 13.0915 4.7165 13.875 3.75 13.875C2.7835 13.875 2 13.0915 2 12.125C2 11.1585 2.7835 10.375 3.75 10.375C4.7165 10.375 5.5 11.1585 5.5 12.125Z"
        fill={colour1}
      />
      <path
        d="M20.125 9.82217C20.2024 9.77751 20.2976 9.77751 20.375 9.82217L21.8571 10.6778C21.9344 10.7225 21.9821 10.805 21.9821 10.8943V12.6057C21.9821 12.695 21.9344 12.7775 21.857 12.8222L20.375 13.6778C20.2976 13.7225 20.2024 13.7225 20.125 13.6778L18.643 12.8222C18.5656 12.7775 18.518 12.695 18.518 12.6057V10.8943C18.518 10.805 18.5656 10.7225 18.643 10.6778L20.125 9.82217Z"
        fill={colour3}
      />
    </svg>
  );
};
