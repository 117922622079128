import { type SeriesLineOptions, type SeriesScatterOptions } from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TooltipIcon, TooltipShapes } from "../TooltipIcon";
import {
  defaultOptions,
  HighchartsReact,
  type HighchartsReactProps,
} from "../highcharts-react/HighchartsReact";
import styles from "../highcharts-react/HighchartsReact.module.css";
import { type Metric } from "../models";

const ScatterChartTooltipHTML = (
  metrics: Array<{
    name: number | string | null;
    value: string | null;
  }>,
  primaryHeader: {
    color: string | undefined;
    name: number | string;
    shape: string | undefined;
  }
) => (
  <div className={styles.chartTooltip}>
    <div className={styles.textContainer}>
      <div className={styles.leftText}>
        <TooltipIcon color={primaryHeader.color} shape={primaryHeader.shape} />
        <div className={styles.primaryHeader}>{primaryHeader.name}</div>
      </div>
    </div>
    {metrics.map((metric, index) => (
      <div
        className={styles.textContainer}
        // eslint-disable-next-line react/no-array-index-key
        key={`${metric.name}_${metric.value}_${index}`}
      >
        <div className={styles.leftText}>{metric.name}</div>
        <div className={styles.spacer} />
        <div className={styles.rightText}>{metric.value}</div>
      </div>
    ))}
  </div>
);

export type ScatterChartProps = {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  primaryMetric: Metric;
  secondaryMetric: Metric;
  series: Array<SeriesLineOptions | SeriesScatterOptions>;
  tickInterval?: number;
  xAxisExtendedConfig?: {
    max: number;
    min: number;
  };
  yAxisExtendedConfig?: {
    max: number;
    min: number;
  };
};

export const ScatterChart = ({
  onOptionsChanged,
  primaryMetric,
  secondaryMetric,
  series,
  tickInterval,
  xAxisExtendedConfig,
  yAxisExtendedConfig,
}: ScatterChartProps) => {
  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const options: HighchartsReactProps = useMemo(() => {
    let xAxisConfig = {
      crosshair: {
        color: "var(--qbit-colour-chrome-background)",
        zIndex: 0,
      },
      tickInterval,
      tickLength: 4,
      title: {
        style: { fontWeight: "bold" },
        text: primaryMetric.label,
      },
      visible: true,
    };

    if (xAxisExtendedConfig) {
      xAxisConfig = {
        ...xAxisConfig,
        ...xAxisExtendedConfig,
      };
    }

    let yAxisConfig = {
      title: {
        style: { fontWeight: "bold" },
        text: secondaryMetric.label,
      },
      visible: true,
    };

    if (yAxisExtendedConfig) {
      yAxisConfig = {
        ...yAxisConfig,
        ...yAxisExtendedConfig,
      };
    }

    return {
      ...defaultOptions,
      chart: {
        backgroundColor: "none",
        ignoreHiddenSeries: false,
        style: {
          fontFamily: `var(--qbit-font-family)`,
        },
        type: "scatter",
      },
      legend: {
        enabled: true,
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
      },
      series: series.map((seriesData, index) => ({
        ...seriesData,
        visible: !hiddenSeries.includes(index),
      })),
      symbols: [
        TooltipShapes.circle,
        TooltipShapes.diamond,
        TooltipShapes.square,
        TooltipShapes.triangle,
        TooltipShapes.triangleDown,
      ],
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) => {
          const tooltipData = [
            {
              name: primaryMetric.label,
              value: primaryMetric.formatter(ttData.point.x),
            },
            {
              name: secondaryMetric.label,
              value: secondaryMetric.formatter(ttData.point.y),
            },
          ];
          return ScatterChartTooltipHTML(tooltipData, {
            name: ttData.point.name ? ttData.point.name : ttData.series.name,
            color: String(ttData.color),
            // @ts-expect-error symbolName doesn't seem to be exposed to external use
            shape: ttData.point.graphic?.symbolName
              ? // eslint-disable-next-line line-comment-position, no-inline-comments
                // @ts-expect-error symbolName doesn't seem to be exposed to external use
                ttData.point.graphic.symbolName
              : TooltipShapes.circle,
          });
        },
      },
      xAxis: {
        ...xAxisConfig,
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return primaryMetric.formatter(this.value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
      yAxis: {
        ...yAxisConfig,
        ...defaultOptions.yAxis,
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return secondaryMetric.formatter(this.value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
      plotOptions: {
        scatter: {
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
      },
    };
  }, [
    hiddenSeries,
    legendItemClick,
    primaryMetric,
    secondaryMetric,
    series,
    tickInterval,
    xAxisExtendedConfig,
    yAxisExtendedConfig,
  ]);

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
