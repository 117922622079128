import { type GroupSelectionDto } from "../models/group-selection-dto";
import { type SelectionDto } from "../models/selection-dto";

export const isGroupSelectionDto = (
  selectionObject: SelectionDto
): selectionObject is GroupSelectionDto =>
  "evaluationType" in selectionObject &&
  "groupId" in selectionObject &&
  "groupName" in selectionObject &&
  "hierarchyType" in selectionObject;
