import {
  Form,
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  FormInputWidth,
  Icon,
  IconGlyph,
  IconSize,
  Input,
  InputStatus,
  TextInput,
  Textarea,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./EditableField.module.css";

type EditableFieldProps = {
  className?: string;
  editableFieldState?: {
    isEditing: boolean;
    toggleEditing: (editing: boolean) => void;
  };
  isProcessing?: boolean;
  maxCharacters?: number;
  minCharacters?: number;
  onlyExternalState?: boolean;
  preventBlur?: boolean;
  resizeMode?: string;
  rows?: number;
  save: (value: string) => Promise<boolean>;
  stopEditing?: () => void;
  strictInputLimit?: boolean;
  textStyle?: string;
  value: string;
};

export const EditableField = ({
  className,
  editableFieldState,
  value,
  save,
  stopEditing,
  strictInputLimit = false,
  textStyle,
  rows,
  onlyExternalState,
  isProcessing,
  maxCharacters = 255,
  minCharacters,
  preventBlur = false,
  resizeMode = "both",
}: EditableFieldProps) => {
  const [editing, setEditing] = useState(
    editableFieldState ? editableFieldState.isEditing : false
  );

  const isEditingLocal = editableFieldState
    ? editableFieldState.isEditing
    : editing;
  const setIsEditingLocal = editableFieldState
    ? editableFieldState.toggleEditing
    : setEditing;

  const [text, setText] = useState(value);

  const charactersLeft = useMemo(
    () => maxCharacters - text.length,
    [text, maxCharacters]
  );

  useEffect(() => {
    if (!isProcessing) {
      setText(value);
    }
  }, [value, isProcessing]);

  const noCharactersLeft = useMemo(() => charactersLeft < 0, [charactersLeft]);
  const underMinCharacters = useMemo(
    () => text.length < (minCharacters ?? 1),
    [minCharacters, text.length]
  );

  const isErrored = useMemo(
    () => noCharactersLeft || underMinCharacters,
    [noCharactersLeft, underMinCharacters]
  );

  const errorMessage = useMemo(() => {
    if (noCharactersLeft) {
      return "Too many characters";
    } else if (underMinCharacters) {
      return "Too few characters";
    }

    return `${charactersLeft} characters left`;
  }, [charactersLeft, noCharactersLeft, underMinCharacters]);

  const submit = useCallback(async () => {
    if (!isErrored) {
      setIsEditingLocal(false);
      if (text !== value) {
        const savedSuccess = await save(text);
        if (!savedSuccess) {
          setText(value);
        }

        stopEditing?.();
      }
    }
  }, [isErrored, save, setIsEditingLocal, stopEditing, text, value]);

  const handleBlur = async () => {
    if (!preventBlur) {
      await submit();
    }
  };

  return (
    <>
      {isEditingLocal && rows ? (
        <Form
          className={classNames(styles.editableField, className)}
          onSubmit={submit}
        >
          <FormBlock
            blockStatus={
              isErrored ? FormBlockStatus.Error : FormBlockStatus.Default
            }
            blockType={FormBlockType.Text}
          >
            <Input>
              <Textarea
                autofocus
                className={classNames(styles.editableFieldForm, textStyle)}
                data-testid="editable-field-text-area"
                id="editable-field-text-area"
                // @ts-expect-error style & maxLength properties work, but undefined in Textarea type
                maxLength={strictInputLimit ? maxCharacters : ""}
                onBlur={handleBlur}
                onChange={(event) => {
                  setText(event.target.value);
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onKeyDown={(event: any) => {
                  if (event?.key === "Enter") {
                    if (!isErrored) {
                      event.target.blur();
                    }

                    event.stopPropagation();
                    event.preventDefault();
                  } else if (event?.key === "Escape") {
                    setText(value);
                    setIsEditingLocal(false);
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                style={{ resize: resizeMode }}
                value={text}
                width={FormInputWidth.Fill}
              />
              <InputStatus
                data-testid="editable-field-input-status"
                id="input-status"
                text={errorMessage}
              />
            </Input>
          </FormBlock>
        </Form>
      ) : isEditingLocal ? (
        <Form
          className={classNames(
            styles.editableField,
            className,
            styles.singleLine
          )}
          onSubmit={submit}
        >
          <FormBlock
            blockStatus={
              isErrored ? FormBlockStatus.Error : FormBlockStatus.Default
            }
            blockType={FormBlockType.Text}
          >
            <Input>
              <TextInput
                autofocus
                className={classNames(styles.editableFieldForm, textStyle)}
                data-testid="editable-field-text-input"
                id="editable-field-text-input"
                // @ts-expect-error property works, but undefined in Textarea type
                maxLength={strictInputLimit ? maxCharacters : ""}
                onBlur={handleBlur}
                onChange={(event) => {
                  setText(event.target.value);
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onKeyDown={(event: any) => {
                  if (event?.key === "Enter") {
                    if (!isErrored) {
                      event.target.blur();
                    }

                    event.stopPropagation();
                    event.preventDefault();
                  } else if (event?.key === "Escape") {
                    setText(value);
                    setIsEditingLocal(false);
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                value={text}
              />
            </Input>
            <InputStatus
              data-testid="editable-field-input-status"
              id="input-status"
              text={errorMessage}
            />
          </FormBlock>
        </Form>
      ) : (
        <div
          className={classNames(
            styles.editableField,
            className,
            { [styles.editableFieldHoverable]: !onlyExternalState },
            { [styles.editableFieldNotHoverable]: onlyExternalState },
            { [styles.multiLine]: rows }
          )}
          data-testid="editable-field-hoverable"
          onClick={
            onlyExternalState
              ? () => {}
              : () => {
                  setIsEditingLocal(true);
                }
          }
          onKeyUp={
            onlyExternalState
              ? () => {}
              : () => {
                  setIsEditingLocal(true);
                }
          }
          role="button"
          tabIndex={0}
        >
          {rows ? (
            <h2 className={textStyle}>{text}</h2>
          ) : (
            <span className={textStyle}>{text}</span>
          )}
          <span
            className={styles.editableFieldIcon}
            data-testid="editable-field-edit-icon"
          >
            <Icon
              glyph={IconGlyph.TextAndEditEdit}
              size={IconSize.Medium}
              text="Edit Field"
            />
          </span>
        </div>
      )}
    </>
  );
};
