import { type ReportParametersDto } from "@quantium-enterprise/common-ui";
import {
  useDivision,
  type DeferredFormatFunction,
  getNumberFormat,
} from "@quantium-enterprise/hooks-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { type GroupColumnDef, type ColumnDef } from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import {
  GainsLossTable,
  type GainLossData,
  type GainLossNetData,
  type Header,
} from "components-ui/src/tables/gains-loss-table/GainsLossTable";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "../../../../components/src/tables/gains-loss-table/GainsLossTable.module.css";
import { type GainsAndLossOverviewItem } from "../models/GainsAndLossOverviewReportletDto";
import {
  MetricIdTypes,
  measureLabels,
} from "../models/GainsAndLossReportValues";
import {
  selectChannel,
  selectFocalItem,
  selectLocation,
  selectMetricSet,
  selectSegment,
  selectSegmentation,
  selectTime,
  selectTimePeriodLength,
} from "../services/gains-and-loss-slice";
import { getParameterSummaryValueStrings } from "../utils/getParameterSummaryValueStrings";
import { generateDataTableColumnsForExport } from "../utils/overview-data-table-export-utils";
import { getDataTableData } from "../utils/overview-datatable-utils";
import { overviewDataTableCsvTransformation } from "./csvTransformation";

type GainsAndLossOverviewDataTableProps = {
  className?: string;
  isExportEnabled: boolean;
  items: GainsAndLossOverviewItem[];
  reportParamters?: ReportParametersDto;
};

export const createColumn = (
  measure: string,
  metricId: string,
  formatter: DeferredFormatFunction
): ColumnDef<GainLossData> => {
  const measureKey = measure.toLowerCase() as keyof GainLossData;
  const metricIdKey = metricId.toLowerCase() as keyof GainLossNetData;

  const column: ColumnDef<GainLossData> = {
    accessorFn: (row) => (row[measureKey] as GainLossNetData)[metricIdKey],
    cell: ({ getValue }) =>
      ValueCell({
        hasSentiment: metricId === MetricIdTypes.Net,
        formatter:
          measure === measureLabels.Sales
            ? formatter(MetricTypes.Currency)
            : formatter(MetricTypes.Decimal),
        value: getValue() as number,
      }),
    footer: (properties) => properties.column.id,
    header: () => <span className={styles.alignRight}>{metricId}</span>,
    id: uniqueId(),
  };

  return column;
};

export const createColumnGroup = (
  measure: string,
  formatter: DeferredFormatFunction
): ColumnDef<GainLossData> | GroupColumnDef<GainLossData> => {
  const columnGroup: ColumnDef<GainLossData> | GroupColumnDef<GainLossData> = {
    columns: [
      createColumn(measure, MetricIdTypes.Net, formatter),
      createColumn(measure, MetricIdTypes.Gains, formatter),
      createColumn(measure, MetricIdTypes.Loss, formatter),
    ],
    footer: (properties) => properties.column.id,
    header: () => <span className={styles.alignCenter}>{measure}</span>,
    id: uniqueId(),
  };

  return columnGroup;
};

export const generateDataTableColumns = (
  formatter: DeferredFormatFunction
): Array<ColumnDef<GainLossData> | GroupColumnDef<GainLossData>> => {
  const columns: Array<ColumnDef<GainLossData> | GroupColumnDef<GainLossData>> =
    [
      {
        accessorFn: (row) => row.header,
        cell: ({ getValue }) => (
          <div
            className={styles.pinnedHeader}
            style={{
              paddingLeft: (getValue<Header>().depth ?? 0) * 15,
            }}
          >
            {getValue<Header>().title}
          </div>
        ),
        footer: (properties) => properties.column.id,
        header: () => <span>Measures</span>,
        id: uniqueId(),
      },
      createColumnGroup(measureLabels.Sales, formatter),
      createColumnGroup(measureLabels.Units, formatter),
      createColumnGroup(measureLabels.Volume, formatter),
    ];

  return columns;
};

export const GainsAndLossOverviewDataTable = ({
  className,
  items,
  reportParamters,
  isExportEnabled,
}: GainsAndLossOverviewDataTableProps) => {
  const tableData: GainLossData[] = getDataTableData(items);
  const formatter = useFormatter();
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const focalItem = useSelector(selectFocalItem);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const metricSet = useSelector(selectMetricSet);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Gain_And_Loss_Overview_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const parameterSummary = useMemo(
    () =>
      getParameterSummaryValueStrings(
        channel,
        location,
        metricSet,
        segment,
        segmentation,
        time,
        focalItem
      ),
    [channel, location, segment, segmentation, time, focalItem, metricSet]
  );
  const dataTableCsvTransformationCallback = useCallback(
    () =>
      overviewDataTableCsvTransformation(
        tableData,
        generateDataTableColumnsForExport(currencySymbol)
      ),
    [tableData, currencySymbol]
  );

  return (
    <div className={className}>
      <div className={styles.container}>
        <DataTableOptions
          filename={exportFilename}
          invokeCSVDownload={dataTableCsvTransformationCallback}
          isFeatureEnabled={isExportEnabled}
          localParameters={parameterSummary}
          reportParameters={reportParamters}
        />
      </div>
      <GainsLossTable
        columns={generateDataTableColumns(formatter)}
        data={tableData}
        pinFirstColumn
      />
    </div>
  );
};
