import {
  type FormatterFunction,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type ChartDataSeries } from "components-ui/src/charts/trends-chart/TrendsChart";
import { TrendsChart } from "components-ui/src/charts/trends-chart/TrendsChart";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { type RootState } from "../../../../store";
import { type DayOfWeekResponseDto } from "../../../models/day-of-week-chart";
import {
  selectIsTrendsChartFocalItemsView,
  selectIsTrendsChartSegmentsView,
} from "../../../services/time-of-day-day-of-week-slice";
import {
  selectFocalItemParents,
  selectFocalItems,
} from "../../../services/time-of-day-day-of-week-slice-selectors";
import {
  getFocalData,
  getBenchmarkData,
  getSegmentsData,
} from "../filter-data";

type DayOfWeekReportletChartProps = {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  reportletData: DayOfWeekResponseDto;
};

export const DayOfWeekReportletChart = ({
  onOptionsChanged,
  reportletData,
}: DayOfWeekReportletChartProps) => {
  const formatter = useFormatter();

  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);

  const { chartSelections, showDayOfWeekChartDataLabels } = useSelector(
    (state: RootState) => ({
      chartSelections: state.timeOfDayDayOfWeek.chartSelections,
      showDayOfWeekChartDataLabels:
        state.timeOfDayDayOfWeek.showDayOfWeekChartDataLabels,
    })
  );

  const { isFocalView, isSegmentsView } = useSelector((state: RootState) => ({
    isFocalView: selectIsTrendsChartFocalItemsView(state.timeOfDayDayOfWeek),
    isSegmentsView: selectIsTrendsChartSegmentsView(state.timeOfDayDayOfWeek),
  }));

  const { filteredChartData, selectedBenchmarkIndex } = useMemo(() => {
    let filteredData: ChartDataSeries[] = [];
    let benchmarkIndex: number | undefined;
    if (isFocalView) {
      filteredData = getFocalData(
        focalItems,
        reportletData,
        chartSelections,
        focalItemParents
      );
      const benchmarkData = getBenchmarkData(reportletData, chartSelections);
      if (benchmarkData) {
        filteredData = [...filteredData, benchmarkData];
        benchmarkIndex = filteredData.length - 1;
      }
    } else if (isSegmentsView) {
      filteredData = getSegmentsData(reportletData, chartSelections);
    }

    return {
      filteredChartData: filteredData,
      selectedBenchmarkIndex: benchmarkIndex,
    };
  }, [
    chartSelections,
    focalItemParents,
    focalItems,
    isFocalView,
    isSegmentsView,
    reportletData,
  ]);

  const percentFormatter: FormatterFunction = useMemo(
    () => (value) => `${value}`,
    []
  );

  return (
    <TrendsChart
      benchmark={selectedBenchmarkIndex}
      categories={reportletData.daysMetadata}
      chartData={filteredChartData}
      chartDataFormatter={formatter(reportletData.format)}
      onOptionsChanged={onOptionsChanged}
      percentFormatter={percentFormatter}
      showChartDataLabels={showDayOfWeekChartDataLabels}
      yAxisLabel={reportletData.metric}
    />
  );
};

export default DayOfWeekReportletChart;
