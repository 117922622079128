import { type ReactNode } from "react";
import styles from "./NoEditingPanel.module.scss";

type NoEditingPanelProps = {
  children: ReactNode;
  title: string;
};

export const NoEditingPanel = ({ title, children }: NoEditingPanelProps) => (
  <div className={styles.panel}>
    <div className={styles.titlePanel}>
      <p className={styles.title}>{title}</p>
      <div className={styles.summary}>{children}</div>
    </div>
  </div>
);

export default NoEditingPanel;
