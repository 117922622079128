import { type PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  type FolderOrReportDto,
  type NestedFolderDto,
  type SharedUserDto,
  type SharingActivity,
} from "../models";
import { convertToFolderOrReportDto, getChildIds } from "../utilities";

export type MyReportsState = {
  allReportSharedUsers: SharedUserDto[];
  expandedIds: string[];
  isPollingEnabled: boolean;
  quickActionRowId?: string;
  renameState: { id?: string; isEditing: boolean };
  reportSharingActivities: SharingActivity[];
  rowData: Record<string, FolderOrReportDto>;
  rowDataInitialized: boolean;
  showDeleteItemModal: boolean;
  showMoveItemModal: boolean;
  showShareModal: boolean;
};

export const initialState: MyReportsState = {
  rowData: {},
  rowDataInitialized: false,
  allReportSharedUsers: [],
  isPollingEnabled: true,
  reportSharingActivities: [],
  showDeleteItemModal: false,
  showMoveItemModal: false,
  showShareModal: false,
  expandedIds: [],
  renameState: { id: undefined, isEditing: false },
};

export const myReportsSlice = createSlice({
  initialState,
  name: "myReports",
  reducers: {
    onRootFolderReceived: (
      state: MyReportsState,
      action: PayloadAction<NestedFolderDto>
    ) => {
      // eslint-disable-next-line unicorn/no-array-reduce
      state.rowData = convertToFolderOrReportDto(action.payload).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accumulator: any, row) => {
          accumulator[row.id] = row;
          return accumulator;
        },
        {}
      );
      state.rowDataInitialized = true;
    },
    setAllReportSharedUsers: (
      state: MyReportsState,
      action: PayloadAction<SharedUserDto[]>
    ) => {
      state.allReportSharedUsers = action.payload;
    },
    onReportSharingActivitiesReceived: (
      state: MyReportsState,
      action: PayloadAction<SharingActivity[]>
    ) => {
      state.reportSharingActivities = action.payload;
    },
    setIsPollingEnabled: (
      state: MyReportsState,
      action: PayloadAction<boolean>
    ) => {
      state.isPollingEnabled = action.payload;
    },
    setShowMoveItemModal: (
      state: MyReportsState,
      action: PayloadAction<boolean>
    ) => {
      state.showMoveItemModal = action.payload;
    },
    setShowDeleteItemModal: (
      state: MyReportsState,
      action: PayloadAction<boolean>
    ) => {
      state.showDeleteItemModal = action.payload;
    },
    setQuickActionRowId: (
      state: MyReportsState,
      action: PayloadAction<string>
    ) => {
      state.quickActionRowId = action.payload;
    },
    setShowShareModal: (
      state: MyReportsState,
      action: PayloadAction<boolean>
    ) => {
      state.showShareModal = action.payload;
    },
    setFolderExpandedState: (
      state: MyReportsState,
      {
        payload: { id, isExpanded },
      }: PayloadAction<{
        id: string;
        isExpanded: boolean;
      }>
    ) => {
      let newExpandedIds = [...state.expandedIds];

      if (isExpanded) {
        if (!state.expandedIds.includes(id)) {
          newExpandedIds.push(id);
        }
      } else {
        // recursively remove this id and any of its children from the newExpandedIds array
        // eslint-disable-next-line no-lonely-if -- readability
        const idsToRemove = getChildIds(id, state.rowData).concat([id]);

        newExpandedIds = newExpandedIds.filter(
          (groupOrFolderId: string) => !idsToRemove.includes(groupOrFolderId)
        );
      }

      state.expandedIds = newExpandedIds;
    },
    setRenameId: (
      state: MyReportsState,
      { payload: { id } }: PayloadAction<{ id?: string }>
    ) => {
      state.renameState.id = id;
    },
    setEditingState: (
      state: MyReportsState,
      { payload: { editing } }: PayloadAction<{ editing: boolean }>
    ) => {
      state.renameState.isEditing = editing;
    },
    updateRowData: (
      state: MyReportsState,
      action: PayloadAction<Record<string, FolderOrReportDto>>
    ) => {
      state.rowData = action.payload;
    },
  },
});

export const {
  onRootFolderReceived,
  setIsPollingEnabled,
  onReportSharingActivitiesReceived,
  setAllReportSharedUsers,
  setShowDeleteItemModal,
  setShowMoveItemModal,
  setQuickActionRowId,
  setShowShareModal,
  setFolderExpandedState,
  setRenameId,
  setEditingState,
  updateRowData,
} = myReportsSlice.actions;

export default myReportsSlice.reducer;
