import { addWeeks, subWeeks } from "date-fns";
import { type ReactElement } from "react";
import {
  type CustomTimePeriodProps,
  type CustomTimePeriodOption,
} from "./CustomTimePeriod";
import { type TimePeriodOption } from "./time-period-option";

type MinWeeksRestrictionProps = {
  /**
   * Creates a CustomTimePeriod instance
   *
   * @param beginningOfEndDate the earliest date of the end date picker
   * @param endOfStartDate the latest date of the start date picker
   * @returns
   */
  customTimePeriodBuilder: (
    beginningOfEndDate?: Date,
    endOfStartDate?: Date
  ) => ReactElement<CustomTimePeriodProps>;
  customTimePeriodOption?: TimePeriodOption;
  customTimePeriodOptions: CustomTimePeriodOption[];
  endDate?: Date;
  startDate?: Date;
};

/**
 * Update the earliest date of the end date picker and the latest date of the start date picker
 *
 * @param {MinWeeksRestrictionProps} props
 * @param props.customTimePeriodBuilder creates the target {@link CustomTimePeriod} instance
 * @param props.startDate the selected start date
 * @param props.endDate the selected end date
 * @param props.customTimePeriodOptions
 * @param props.customTimePeriodOption
 * @returns
 */
export const WithMinWeeksRestriction = ({
  customTimePeriodBuilder,
  startDate,
  endDate,
  customTimePeriodOptions,
  customTimePeriodOption,
}: MinWeeksRestrictionProps): ReactElement<CustomTimePeriodProps> => {
  if (customTimePeriodOption?.minWeeks) {
    const minWeeks = customTimePeriodOption.minWeeks;
    // computes the earliest date of the end date picker
    const beginningOfEndDate = startDate
      ? addWeeks(startDate, minWeeks - 1)
      : customTimePeriodOptions[minWeeks - 1].weekEndDate;

    // computes the latest date of the start date picker
    const endOfStartDate = endDate
      ? subWeeks(endDate, minWeeks - 1)
      : customTimePeriodOptions[customTimePeriodOptions.length - minWeeks]
          .weekStartDate;

    /**
     * if the computed dates are out of range, the @type {CustomTimePeriod} component will disable them,
     * so we don't check the validity of the dates here.
     */
    return customTimePeriodBuilder(beginningOfEndDate, endOfStartDate);
  }

  return customTimePeriodBuilder();
};
