import { Icon, IconColour, IconGlyph } from "@quantium-enterprise/qds-react";
import { Link } from "react-router-dom";
import styles from "./CustomerGroupSegments.module.css";

export type InvalidSegmentCardProps = {
  onClick: () => void;
};

export const InvalidSegmentCard = ({ onClick }: InvalidSegmentCardProps) => (
  <div className={[styles.cardContainer, styles.warningContainer].join(" ")}>
    <Icon
      colour={IconColour.Warning}
      glyph={IconGlyph.AlertsAndNotificationsWarning}
      text="warning-icon"
    />
    <div className={styles.warningHeader}>Media segment guidelines not met</div>
    <div className={styles.warningContent}>
      This group doesn't meet the guidelines for a media segment. You can still
      choose to activate it for a campaign.{" "}
      <Link onClick={() => onClick()} to="">
        <span className={styles.linkText}>Learn more</span>
      </Link>
    </div>
  </div>
);
