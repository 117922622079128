import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export const newProductBenchmarkingApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/new-product-benchmarking`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "newProductBenchmarkingApi",
  tagTypes: ["new-product-benchmarking"],
});
