import { type SortDirection } from "@tanstack/react-table";
import AscendingIcon from "../../assets/common/table-ascending-arrow.svg";
import DescendingIcon from "../../assets/common/table-descending-arrow.svg";
import styles from "./GridTable.module.css";
import { SortedType } from "./column-sort/ColumnSort";
import { TestId } from "./constants";

type SortIconProps = {
  sortedType: SortDirection | false;
};

export const SortIcon = ({ sortedType }: SortIconProps) =>
  sortedType ? (
    <span className={styles.sortIcon} data-testid={TestId.SortIcon}>
      <img
        alt={`${sortedType === SortedType.asc ? "ascending" : "descending"}`}
        src={sortedType === SortedType.asc ? AscendingIcon : DescendingIcon}
      />
    </span>
  ) : null;
