import { useMemo } from "react";
import EmptyGroupImage from "../assets/common/empty-group.svg";
import styles from "./EmptyGroup.module.css";

// generic template for any groups component
export type EmptyGroupTemplateProps = {
  altText: string;
  noGroupsSubtitle: string;
  noGroupsTitle: string;
};

export const EmptyGroupTemplate = ({
  altText,
  noGroupsSubtitle,
  noGroupsTitle,
}: EmptyGroupTemplateProps) => (
  <div className={styles.emptyGroupSection}>
    <img
      alt={altText}
      className={styles.emptyGroupImage}
      src={EmptyGroupImage}
    />
    <p className={styles.textInfo}>
      <span className={styles.title}>{noGroupsTitle}</span>
      <br />
      <span className={styles.subTitle}>{noGroupsSubtitle}</span>
    </p>
  </div>
);

export type EmptyGroupProps = {
  type: string;
};

// pre defined version for product, location, customer groups
export const EmptyGroup = ({ type }: EmptyGroupProps) => {
  const groupType = type.toLowerCase();

  const altText = useMemo(
    () => `No ${groupType} groups available`,
    [groupType]
  );

  const noGroupsTitle = useMemo(
    () => `No ${groupType}s
  available`,
    [groupType]
  );

  const noGroupsSubtitle = useMemo(
    () => `Please create 
  ${groupType} 
  groups to view selections.`,
    [groupType]
  );

  return (
    <EmptyGroupTemplate
      altText={altText}
      noGroupsSubtitle={noGroupsSubtitle}
      noGroupsTitle={noGroupsTitle}
    />
  );
};
