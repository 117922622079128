import { type Row } from "@tanstack/react-table";

/**
 * TODO: somehow fix our original data structure so that it would provide object keys
 * which matches the actual meta data of the columns
 * e.g.
 * {
 *    DL: "N",
 * }
 */
// TODO: WARNING THIS IS GOING TO BREAK IF WE CHANGE ORDER OF COLUMNS
// JIRA: https://quantium.atlassian.net/browse/CO3-4902
const DELETED_LINE_FILTER_COLUMN_INDEX = 3;

export const DELETED_LINE_FILTER_COLUMN_ID = "DL";
export const DEFAULT_DELETED_LINE_FILTER_VALUE = "N";
export const IGNORE_LOAD_MORE_ROW = true;
export const LOAD_MORE_ROW_ID = "load-more";

export type AttributeValuesType = {
  attributeValues: string[];
};

export const excludeDeletedLineFilter = <T>(
  row: Row<AttributeValuesType & T>,
  columnId: string,
  filterValue: string
): boolean => {
  const shouldFilter = false;

  if (row.id.includes(LOAD_MORE_ROW_ID)) {
    return IGNORE_LOAD_MORE_ROW;
  }

  if (
    columnId === DELETED_LINE_FILTER_COLUMN_ID &&
    Array.isArray(row.original.attributeValues)
  ) {
    const deletedValue =
      row.original.attributeValues[DELETED_LINE_FILTER_COLUMN_INDEX];

    return deletedValue === filterValue;
  }

  return shouldFilter;
};
