import {
  FeatureFlag,
  GenericTrackingProperties,
  ReportType,
  type TrackingComponent,
  type TrackingEvent,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import CompareMetricsReportlet from "./components/CompareMetricsReportlet";
import CompareMetricsSidePanel from "./components/CompareMetricsSidePanel";
import CompareMetricsTopDrawer from "./components/top-drawer/CompareMetricsTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/compare-metrics-local-parameters-api-slice";
import {
  onMetadataSuccess,
  onReportOpen,
  reset,
} from "./services/compare-metrics-slice";
import {
  selectLocalParametersInitialised,
  selectMetadata,
} from "./services/compare-metrics-slice-selectors";

export const CompareMetricsReport = () => {
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const isLocalParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  // Reset redux state when new CM report is open
  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabsEnabled, id]);

  // info panel
  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: id === undefined || !activeDivisionName }
    );

  // Save metadata to the state
  useEffect(() => {
    if (infoPanelSummary) {
      dispatch(
        onMetadataSuccess({
          reportId: infoPanelSummary.id,
          reportName: infoPanelSummary.reportName,
        })
      );
    }
  }, [dispatch, infoPanelSummary]);

  // Local Parameters Query
  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, reportId: string) => {
      await getLocalParameters({ divisionName: division, reportId });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !isLocalParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    isLocalParametersInitialised,
  ]);

  const metaData = useSelector(selectMetadata);

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Compare metrics",
        reportId: metaData.reportId,
        ...properties,
      })
    );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <CompareMetricsTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={metaData.reportName}
            reportType={ReportType.CompareMetrics}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <CompareMetricsSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <CompareMetricsReportlet reportParameters={infoPanelSummary} />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <CompareMetricsReport />,
  path: "compare-metrics/:id",
};

export default CompareMetricsReport;
