import {
  type UserDto,
  ActivityType,
  type SharedUserDto,
} from "@quantium-enterprise/common-ui";
import { Text } from "@quantium-enterprise/qds-react";
import styles from "./SegmentBuilderActivityText.module.css";

export type SegmentBuilderActivityTextProps = {
  activity: ActivityType;
  currentUser: UserDto | undefined;
  user: SharedUserDto | undefined;
};

export const SegmentBuilderActivityText = ({
  activity,
  currentUser,
  user,
}: SegmentBuilderActivityTextProps) => {
  if (!user) {
    return null;
  }

  return (
    <>
      <Text className={styles.boldText}>
        {currentUser?.salesForceId === user.salesforceUserId
          ? "You"
          : `${user.firstName} ${user.lastName}`}
      </Text>
      <Text>{` ${
        activity === ActivityType.SegmentBuilderActivate
          ? "activated"
          : "deactivated"
      } this segment`}</Text>
    </>
  );
};
