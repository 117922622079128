import {
  type HierarchyItemDto,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import {
  HierarchyType,
  HierarchyItemType,
  hierarchyServiceApi,
} from "@quantium-enterprise/common-ui";
import { store } from "../../store";
import {
  getUniqueKey,
  type SelectableAttributeItem,
  type SelectableItem,
  isSelectableAttributeItem,
} from "../SelectableItem";
import { SearchStrategyStatus } from "./SearchStrategy";
import { SearchStrategyBase } from "./SearchStrategyBase";
import { getAttributeSubRows } from "./search-strategy-utils";

export class AttributeStrategy extends SearchStrategyBase {
  private readonly division: string;

  public readonly shortName: string;

  private readonly pageSize: number;

  private searchQuery: string = "";

  private currentPage: number = 0;

  private selectedHierarchyLevels: {
    [code: string]: {
      code: string;
      entitlements: TransactionSource[];
      shortName: string;
    };
  } = {};

  public constructor(division: string, shortName: string, pageSize: number) {
    super();
    this.division = division;
    this.shortName = shortName;
    this.pageSize = pageSize;
  }

  public setSearchQuery(query: string): void {
    if (query !== this.searchQuery) {
      if (this.getStatus() !== SearchStrategyStatus.Uninitialized) {
        this.setStatus(SearchStrategyStatus.Loading);
      }

      this.currentPage = 0;
      this.searchQuery = query;
      this.search();
      this.setExpandedItems([]);
    }
  }

  public loadMore(): void {
    this.currentPage++;
    this.search();
  }

  public async expandItem(item: SelectableItem) {
    if (isSelectableAttributeItem(item)) {
      if ((item.subRows?.length ?? 0) === 0) {
        item.isExpanding = true;
        await this.loadSubRows(item);
      }

      this.setExpandedItems([...this.getExpandedItems(), item]);
    }
  }

  public unexpandItem(item: SelectableItem): void {
    if (isSelectableAttributeItem(item)) {
      this.setExpandedItems(
        this.getExpandedItems().filter(
          (existing) => getUniqueKey(existing) !== getUniqueKey(item)
        )
      );
    }
  }

  private async loadSubRows(item: SelectableAttributeItem) {
    const subRows = await getAttributeSubRows(item, this.division);

    if (subRows) {
      const items = this.getItems() as SelectableAttributeItem[];
      const expandItem = this.findItem(item.code, item.shortName, items);

      if (expandItem) {
        expandItem.subRows = subRows;

        if (this.onItemsChanged) {
          this.onItemsChanged(items);
        }
      }

      this.setStatus(SearchStrategyStatus.Success);
    } else {
      this.setStatus(SearchStrategyStatus.Error);
    }
  }

  private findItem(
    code: string,
    shortName: string,
    items: SelectableAttributeItem[] | undefined
  ): SelectableAttributeItem | undefined {
    if (items) {
      for (const item of items) {
        if (item.code === code && item.shortName === shortName) {
          return item;
        }
      }
    }

    return undefined;
  }

  public initialize(): void {
    if (this.getStatus() === SearchStrategyStatus.Uninitialized) {
      this.search();
    }
  }

  private search() {
    const requestSearchQuery = this.searchQuery;
    (async () => {
      const response = await store.dispatch(
        hierarchyServiceApi.endpoints.search.initiate({
          division: this.division,
          hierarchyType: HierarchyType.Product,
          payload: {
            focalAttributes: [this.shortName],
            includeCodes: true,
            page: this.currentPage,
            pageSize: this.pageSize,
            query: requestSearchQuery,
          },
        })
      );

      if (requestSearchQuery !== this.searchQuery) {
        // Search query doesn't match what's in the state, ignore as it must be an old request.
        return;
      }

      if (response.data) {
        const mappedResults = this.convertFromHierarchyResponse(
          response.data.results
        );

        const existingItems = this.getItems();
        for (const item of existingItems) {
          item.isMoreRow = false;
        }

        if (response.data.count > 0) {
          mappedResults[response.data.count - 1].isMoreRow =
            response.data.hasNextPage;
        }

        if (this.currentPage === 0) {
          this.setItems(mappedResults);
        } else {
          const newItems = [...existingItems, ...mappedResults];
          this.setItems(newItems);
        }

        this.setStatus(SearchStrategyStatus.Success);
      } else {
        this.setStatus(SearchStrategyStatus.Error);
      }
    })();
  }

  public convertFromHierarchyResponse(response: HierarchyItemDto[]) {
    return response.map(
      (result) =>
        ({
          additionalHierarchyFilter: this.selectedHierarchyLevels[result.code],
          code: result.code,
          isExpanding: false,
          name: result.name,
          shortName: result.shortName,
          suffix: "in",
          type: HierarchyItemType.Attribute,
        } as SelectableAttributeItem)
    );
  }

  public hierarchyLevelSelected(
    item: SelectableItem,
    shortName: string,
    code: string
  ): void {
    if (!isSelectableAttributeItem(item)) {
      return;
    }

    if (item.shortName === this.shortName) {
      const entitlements = item.additionalHierarchyFilter?.entitlements ?? [];
      this.selectedHierarchyLevels[item.code] = {
        code,
        shortName,
        entitlements,
      };

      const existingItems = this.getItems();
      const existingItem = existingItems.find(
        (index) => getUniqueKey(index) === getUniqueKey(item)
      ) as SelectableAttributeItem | undefined;
      if (existingItem) {
        existingItem.additionalHierarchyFilter = {
          code,
          entitlements,
          shortName,
        };
      }

      this.setItems(existingItems);

      store.getState();
    }
  }
}
