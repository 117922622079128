import {
  type MeasureThresholdParameterOption,
  type ParameterOptionDto,
  type ParameterDto,
  isMeasureThresholdSelection,
  type SelectionDto,
} from "@quantium-enterprise/common-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

export type MeasureThresholdSelection = {
  comparator: ParameterOptionDto;
  id: string;
  measure: MeasureThresholdParameterOption;
  threshold: number | undefined;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface MeasureThresholdParameterState extends ParameterState {
  selections: MeasureThresholdSelection[];
}

export const isMeasureThresholdParameterState = (
  state: ParameterState | undefined
): state is MeasureThresholdParameterState =>
  state !== undefined && Object.hasOwn(state, "selections");

export const newMeasureThresholdParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): MeasureThresholdParameterState => {
  let selections: MeasureThresholdSelection[] = [];
  const prefilledSelections =
    savedSelections ?? parameterConfig.selections ?? [];

  if (prefilledSelections.length > 0) {
    const options = prefilledSelections.flatMap((item) => {
      if (isMeasureThresholdSelection(item)) {
        const measure = parameterConfig.options.find(
          (option) => option.value === item.measure.value
        ) as MeasureThresholdParameterOption;

        const comparator = measure.options.find(
          (option) => option.value === item.comparator.value
        );
        return [
          {
            measure,
            comparator,
            threshold: item.threshold.number,
            id: uniqueId(),
          } as MeasureThresholdSelection,
        ];
      }

      return [];
    });
    selections = options;
  }

  return {
    isValid: isBetweenMinMax(selections.length, parameterConfig),
    parameterConfig,
    parameterGroup,
    selections,
  };
};

export const getMeasureThresholdParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as MeasureThresholdParameterState
  );

export const getValidComparators = (
  selections: MeasureThresholdSelection[],
  measure: ParameterOptionDto,
  includeComparator?: ParameterOptionDto
) => {
  const selectedComparatorValues = selections
    .filter((selection) => selection.measure.value === measure.value)
    .map((selection) => selection.comparator.value);

  return (
    measure.options?.filter(
      (option) =>
        !selectedComparatorValues.includes(option.value) ||
        option.value === includeComparator?.value
    ) ?? []
  );
};

const validateComparator = (
  parameterState: MeasureThresholdParameterState,
  selection: MeasureThresholdSelection
) => {
  const validComparators = getValidComparators(
    parameterState.selections,
    selection.measure,
    selection.comparator
  );
  return validComparators
    .map((option) => option.value)
    .includes(selection.comparator.value);
};

const validateThresholds = (selections: MeasureThresholdSelection[]) =>
  selections.every((selection) => selection.threshold !== undefined);

export const handleMeasureThresholdUpdated = (
  parameterState: ParameterState,
  newSelection: MeasureThresholdSelection,
  index: number
) => {
  if (
    isMeasureThresholdParameterState(parameterState) &&
    validateComparator(parameterState, newSelection)
  ) {
    const newSelections = parameterState.selections.map((selection) => ({
      ...selection,
    }));
    newSelections[index] = newSelection;
    return {
      ...parameterState,
      isValid:
        validateThresholds(newSelections) &&
        isBetweenMinMax(newSelections.length, parameterState.parameterConfig),
      selections: newSelections,
    } as MeasureThresholdParameterState;
  }

  return { ...parameterState };
};

export const handleMeasureThresholdAdded = (
  parameterState: ParameterState,
  newSelection: MeasureThresholdSelection
) => {
  if (
    isMeasureThresholdParameterState(parameterState) &&
    validateComparator(parameterState, newSelection)
  ) {
    const newSelections = parameterState.selections.map((selection) => ({
      ...selection,
    }));
    newSelections.push(newSelection);
    return {
      ...parameterState,
      isValid: false,
      selections: newSelections,
    } as MeasureThresholdParameterState;
  }

  return { ...parameterState };
};

export const handleMeasureThresholdRemoved = (
  parameterState: ParameterState,
  index: number
) => {
  if (isMeasureThresholdParameterState(parameterState)) {
    const newSelections = parameterState.selections.map((selection) => ({
      ...selection,
    }));
    newSelections.splice(index, 1);
    return {
      ...parameterState,
      isValid:
        validateThresholds(newSelections) &&
        isBetweenMinMax(newSelections.length, parameterState.parameterConfig),
      selections: newSelections,
    } as MeasureThresholdParameterState;
  }

  return { ...parameterState };
};
