import {
  type TrackingComponent,
  type TrackingEvent,
  GenericTrackingProperties,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
  FeatureFlag,
  ReportType,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { useFetchNodesQuery } from "../common/services/hierarchy-slice";
import { parentNodesToFetch } from "../common/utils/focal-item-labels-utils";
import { type RootState } from "../store";
import CustomerProfilingReportlet from "./components/CustomerProfilingReportlet/CustomerProfilingReportlet";
import { CustomerProfilingSidePanel } from "./components/CustomerProfilingSidePanel";
import CustomerProfilingTopDrawer from "./components/CustomerProfilingTopDrawer";
import CustomerProfilingSegmentsOverTimeReportlet from "./components/SegmentsOverTimeReportlet/CustomerProfilingSegmentsOverTimeReportlet";
import { useGetReportQuery } from "./services/customer-profiling-local-parameters-api-slice";
import {
  onParentNodeDataReceived,
  onReportError,
  onReportOpen,
  onReportSuccess,
  reset,
  selectFocalItems,
} from "./services/customer-profiling-slice";

export const CustomerProfilingReport = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTabsEnabled]);

  // InfoPanel
  const { data: reportParameters, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: !activeDivisionName || !id }
    );

  // Local Parameters Query
  const {
    data: report,
    isError: isReportError,
    isSuccess: isReportSuccess,
  } = useGetReportQuery(
    {
      divisionName: activeDivisionName,
      reportId: id ?? "",
    },
    { skip: !activeDivisionName || !id }
  );

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.customerProfiling.reportName,
  }));

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Customer profiling",
        reportId: id,
        ...properties,
      })
    );

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  const focalItems = useSelector(selectFocalItems);
  useEffect(() => {
    if (isReportSuccess) {
      dispatch(onReportSuccess(report));
    } else if (isReportError) {
      dispatch(onReportError());
    }
  }, [dispatch, isReportError, isReportSuccess, report]);

  const parentNodeNumbers = useMemo(
    () => parentNodesToFetch(focalItems),
    [focalItems]
  );

  const { data: hierarchySliceParentNodes } = useFetchNodesQuery(
    {
      hierarchyType: "Product",
      nodeNumbers: parentNodeNumbers,
      reportId: id ?? "",
    },
    {
      skip: parentNodeNumbers.length === 0 || !id,
    }
  );

  useEffect(() => {
    if (hierarchySliceParentNodes) {
      dispatch(onParentNodeDataReceived(hierarchySliceParentNodes.nodes));
    }
  }, [dispatch, hierarchySliceParentNodes]);

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <CustomerProfilingTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportType={ReportType.CustomerProfiling}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <CustomerProfilingSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <CustomerProfilingReportlet reportParameters={reportParameters} />
          <CustomerProfilingSegmentsOverTimeReportlet
            reportParameters={reportParameters}
          />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={reportParameters}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <CustomerProfilingReport />,
  path: "customer-profiling/:id",
};

export default CustomerProfilingReport;
