import {
  type AssociatedItem,
  type PurchasedWithProduct,
} from "../models/basket-affinities-chart-models";

export const associatedItemsAggregation: (
  purchasedWithProducts: PurchasedWithProduct[],
  upliftThreshold: number,
  basketsWithBothThreshold: number
) => AssociatedItem[] = (
  purchasedWithProducts: PurchasedWithProduct[],
  upliftThreshold: number,
  basketsWithBothThreshold: number
) => {
  const associatedItems: {
    [itemCode: string]: AssociatedItem;
  } = {};
  for (const product of purchasedWithProducts) {
    const key = product.associatedAncestor.itemCode;
    let associatedItem = associatedItems[key];
    if (
      // only here will the property value possibly be undefined, but once it is defined the type will be correct.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !associatedItem &&
      product.basketsWithBoth >= basketsWithBothThreshold &&
      product.uplift >= upliftThreshold
    ) {
      associatedItem = {
        associatedAncestor: product.associatedAncestor,
        associatedItemsCount: 0,
      };
      associatedItems[key] = associatedItem;
    }

    if (
      product.basketsWithBoth >= basketsWithBothThreshold &&
      product.uplift >= upliftThreshold
    )
      ++associatedItem.associatedItemsCount;
  }

  // sort by associatedItemsCount desc, associatedAncestor.name asc
  return Object.values(associatedItems).sort(
    (item1, item2) =>
      item2.associatedItemsCount - item1.associatedItemsCount ||
      item1.associatedAncestor.name.localeCompare(item2.associatedAncestor.name)
  );
};
