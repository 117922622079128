import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { getFocalItemSummary } from "../../common/utils/export-parameter-summary-utils";
import { type CrossShopTableItem } from "../models/CrossShopDataTableResponseDto";
import { type CrossShopParameterSelections } from "../models/CrossShopParameterSelections";

export const getLocalParametersSummary = (
  localSelections: CrossShopParameterSelections,
  focalItems: CrossShopTableItem[],
  segmentationChartTabs?: {
    activeInnerTab: string;
    activeTab: string;
  }
) => {
  const parameterSummary = [
    {
      name: "Focal Item",
      value: getFocalItemSummary(focalItems),
    },
    {
      name: "Time",
      value: localSelections.time as string,
    },
    {
      name: "Segmentation",
      value: localSelections.segmentation[0]?.label,
    },
    {
      name: "Customer Segment",
      value: localSelections.segmentation[1]?.label,
    },
    {
      name: "Product interaction",
      value: localSelections.productInteraction.label,
    },
    {
      name: "Channel",
      value: localSelections.channel.label,
    },
    {
      name: "Location",
      value: `(${hierarchyLevelDisplayLabel(
        localSelections.locationHierarchy.shortName
      )}) ${localSelections.locationHierarchy.name}`,
    },
  ];

  if (segmentationChartTabs) {
    parameterSummary.push({
      name: "Grouping",
      value: segmentationChartTabs.activeTab,
    });
    parameterSummary.push({
      name: "View by",
      value: segmentationChartTabs.activeInnerTab,
    });
  }

  return parameterSummary;
};

export default getLocalParametersSummary;
