import { formatStringDate } from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type BasketQuantitiesLocalSelections } from "../services/basket-quantities-slice";

// eslint-disable-next-line complexity -- most complexity comes from null and type checking
export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): BasketQuantitiesLocalSelections => {
  const selections: BasketQuantitiesLocalSelections = {
    breakdown: {
      value: "",
      label: "",
    },
    channel: {
      value: "",
      label: "",
    },
    locationHierarchy: {
      code: "",
      depth: -1,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      nodeNumber: -1,
      shortName: "",
      isDefault: false,
    },
    promotion: {
      value: "",
      label: "",
    },
    segmentation: {
      value: "",
      label: "",
    },
    segment: {
      value: "",
      label: "",
    },
    time: "",
    timePeriodLength: "",
    viewAs: {
      value: "",
      label: "",
    },
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const focusPeriod = parameter.focusPeriod.label;
          const focusStartDateFormatted = formatStringDate(
            parameter.focusPeriod.startDate
          );
          const focusEndDateFormatted = formatStringDate(
            parameter.focusPeriod.endDate
          );
          selections.time = `${focusPeriod} (focus period ${focusStartDateFormatted} - ${focusEndDateFormatted})`;
          selections.timePeriodLength = focusPeriod;
        }

        break;

      case LocalParameters.ViewAs:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.viewAs = { ...selection };
          }
        }

        break;

      case LocalParameters.Breakdown:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.breakdown = { ...selection };
          }
        }

        break;

      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.channel = { ...selection };
          }
        }

        break;

      case LocalParameters.Promotion:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.promotion = { ...selection };
          }
        }

        break;

      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            selections.segmentation = { ...selection };
            selections.segment = {
              value: "All",
              label: "All",
            };
          }
        }

        break;

      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter))
          selections.locationHierarchy = getLocationSelectDefault(parameter);
        break;

      default:
        break;
    }
  }

  return selections;
};
