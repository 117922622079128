import { formatNumberDate } from "@quantium-enterprise/common-ui";
import { getFormatterFromMetricDefinitons } from "components-ui/src/charts/npb-chart/npb-chart-utils";
import {
  formatMetricValue,
  getCsvColumnHeader,
} from "../../common/utils/export-parameter-summary-utils";
import {
  type FocalTableBenchmarksDataResponse,
  type FocalTableProductsDataResponse,
} from "../models/new-product-benchmarking-data-table-models";
import { type MetricsListGroup } from "../models/new-product-benchmarking-metrics-list-dto";

export type BenchmarkCsvData = {
  colour: string;
  data: number[];
  isBenchmark: boolean | undefined;
  name: string;
};

export const reportLetCsvTransformation = (
  benchmarkData: BenchmarkCsvData[],
  chartData: BenchmarkCsvData[],
  chartCategories: number[],
  currencySymbol: string,
  currencyFormat: string
) => {
  const columnHeaders = ["Weeks in market"];
  const csvData = [columnHeaders];
  const weekData = chartCategories.filter(
    (category) => category <= benchmarkData[0].data.length
  );

  if (chartData.length > 0) {
    for (const chartDatum of chartData) {
      columnHeaders.push(
        getCsvColumnHeader(currencyFormat, currencySymbol, chartDatum.name)
      );
    }
  }

  for (const benchmarkDatum of benchmarkData) {
    columnHeaders.push(
      getCsvColumnHeader(currencyFormat, currencySymbol, benchmarkDatum.name)
    );
  }

  for (const [index, weekDatum] of weekData.entries()) {
    const row = [];
    row.push(weekDatum.toString());

    for (const data of chartData) {
      row.push(formatMetricValue(data.data[index]));
    }

    for (const data of benchmarkData) {
      row.push(formatMetricValue(data.data[index]));
    }

    csvData.push(row);
  }

  return csvData;
};

export const focalItemCsvTransformation = (
  focalTableProductsData: FocalTableProductsDataResponse,
  focalTableBenchmarksData: FocalTableBenchmarksDataResponse,
  metricsList: MetricsListGroup[],
  showBenchmarks: boolean,
  currencySymbol: string
) => {
  const columnHeaders = ["", "Launch week", "Weeks in market"];
  const csvData = [columnHeaders];

  for (const week of focalTableProductsData.weeks) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of -- we just want to loop through metrics
    for (
      let metricsIndex = 0;
      metricsIndex < focalTableProductsData.metrics.length;
      ++metricsIndex
    ) {
      columnHeaders.push(week);
    }
  }

  const headerRow = ["", "", ""];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of -- we just want to loop through weeks
  for (
    let weekIndex = 0;
    weekIndex < focalTableProductsData.weeks.length;
    ++weekIndex
  ) {
    headerRow.push(
      ...focalTableProductsData.metrics.map((metric) =>
        getCsvColumnHeader(
          getFormatterFromMetricDefinitons(metricsList, metric),
          currencySymbol,
          metric
        )
      )
    );
  }

  csvData.push(headerRow);

  if (showBenchmarks) {
    for (const benchmark of focalTableBenchmarksData.benchmarkRows) {
      const dataRow = [];
      dataRow.push(benchmark.label, "-", "-");

      for (
        let weekIndex = 0;
        weekIndex < focalTableProductsData.weeks.length;
        ++weekIndex
      ) {
        for (
          let metricIndex = 0;
          metricIndex < focalTableProductsData.metrics.length;
          ++metricIndex
        ) {
          if (benchmark.values[weekIndex]) {
            dataRow.push(
              formatMetricValue(benchmark.values[weekIndex][metricIndex])
            );
          } else {
            dataRow.push("-");
          }
        }
      }

      csvData.push(dataRow);
    }
  }

  for (const product of focalTableProductsData.productRows) {
    const dataRow = [];
    dataRow.push(
      product.name,
      formatNumberDate(Number(product.launchWeek)),
      product.weeksInMarket
    );

    for (
      let weekIndex = 0;
      weekIndex < focalTableProductsData.weeks.length;
      ++weekIndex
    ) {
      for (
        let metricIndex = 0;
        metricIndex < focalTableProductsData.metrics.length;
        ++metricIndex
      ) {
        if (product.values[weekIndex]) {
          dataRow.push(
            formatMetricValue(product.values[weekIndex][metricIndex])
          );
        } else {
          dataRow.push("-");
        }
      }
    }

    csvData.push(dataRow);
  }

  return csvData;
};
