type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};

export const DynamicLocationGroupIcon = ({
  className,
  coloured = true,
  text,
}: Props) => {
  const markerColour = coloured
    ? "var(--marker-boxer-colour)"
    : "Var(--icon-colour-two-disabled)";
  const loopColour = coloured
    ? "var(--dynamic-colour)"
    : "var(--group-colour-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 96 64"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Dynamic location group"}</title>
      <path
        d="M18 17.6558C12.9857 17.6558 9 21.3758 9 26.0558C9 33.2558 18 41.6558 18 41.6558C18 41.6558 27 33.1358 27 26.0558C27 21.3758 23.0143 17.6558 18 17.6558ZM18 29.0558C16.2 29.0558 14.7857 27.7358 14.7857 26.0558C14.7857 24.3758 16.2 23.0558 18 23.0558C19.8 23.0558 21.2143 24.3758 21.2143 26.0558C21.2143 27.7358 19.8 29.0558 18 29.0558Z"
        fill={markerColour}
      />
      <path
        d="M72.8754 17.7771C69.3108 14.2125 64.4188 12 58.9858 12C48.12 12 39.3438 20.8009 39.3438 31.6667C39.3438 42.5325 48.12 51.3334 58.9858 51.3334C68.1554 51.3334 75.8008 45.0646 77.9888 36.5834H72.8754C70.8596 42.3113 65.4021 46.4167 58.9858 46.4167C50.8488 46.4167 44.2358 39.8038 44.2358 31.6667C44.2358 23.5296 50.8488 16.9167 58.9858 16.9167C63.0667 16.9167 66.705 18.613 69.36 21.2925L61.4442 29.2084H78.6525V12L72.8754 17.7771Z"
        fill={loopColour}
      />
    </svg>
  );
};
