import {
  Checkbox,
  FormBlock,
  FormBlockType,
  Input,
  FormBlockEditability,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import styles from "./CheckboxRow.module.css";

export type CheckboxProps = {
  isChecked: boolean;
  isDisabled: boolean;
  // label is what is displayed next to the checkbox - ie display name
  label: JSX.Element | JSX.Element[] | string;
  onChange: (clickedValue: string) => void;
  parameterName: string;
  tooltipText?: string;
  // value is keyID
  value: string;
};

export const CheckboxRow = ({
  parameterName,
  label,
  isChecked,
  isDisabled,
  onChange,
  tooltipText,
  value,
}: CheckboxProps) => {
  const rowFormBlock = (
    <div>
      <FormBlock
        blockType={FormBlockType.Checkbox}
        className={styles.formBlock}
      >
        <Input className={styles.input}>
          <Checkbox
            checked={isChecked}
            className={styles.checkbox}
            data-testid="checkbox"
            editability={
              isDisabled
                ? FormBlockEditability.Disabled
                : FormBlockEditability.Editable
            }
            label={label}
            // note this has to be unique otherwise, other checkboxes will change as well
            name={parameterName + "-" + value}
            onChange={() => {
              if (!isDisabled) onChange(value);
            }}
          />
        </Input>
      </FormBlock>
    </div>
  );

  return (
    <div className={isChecked ? styles.selected : ""}>
      <div className={isDisabled ? styles.disabledContainer : styles.container}>
        {tooltipText ? (
          <Tooltip
            placement={TooltipPlacement.RightCentre}
            spaceInside={TooltipSpaceInside.Medium}
            trigger={rowFormBlock}
            variant={TooltipVariant.ArrowDark}
          >
            <div
              className={styles.checkboxRowTooltip}
              data-testid="checkbox-tooltip"
            >
              {tooltipText}
            </div>
          </Tooltip>
        ) : (
          rowFormBlock
        )}
      </div>
    </div>
  );
};

export default CheckboxRow;
