import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { addDays } from "date-fns";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  endDateSelected,
  focusPeriodSelected,
  startDateSelected,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { getTimePeriodState } from "./TimePeriodState";
import { TimePeriod } from "./components/TimePeriod";
import { TimePeriodOptions } from "./components/TimePeriodConstants";
import {
  convertParametersToCustomPeriodOptions,
  timestampToDate,
  DAYS_IN_WEEK,
  dateToIsoString,
} from "./utilities";

type FocusPeriodParameterProps = {
  parameterDto: ParameterDto;
};

export const FocusPeriodParameter = ({
  parameterDto,
}: FocusPeriodParameterProps) => {
  // React Store Hooks
  const dispatch = useDispatch();

  const focusPeriodState = useAppSelector(getTimePeriodState(parameterDto.id));
  const { wizard } = useSelector((state: RootState) => ({
    wizard: state.wizard,
  }));

  const [maxEndDate, setMaxEndDate] = useState<Date | undefined>();
  const [minStartDate, setMinStartDate] = useState<Date | undefined>();

  if (!focusPeriodState) {
    return null;
  }

  const isTabVisited = wizard.visitedTabs[focusPeriodState.parameterGroup];

  const startDate = timestampToDate(focusPeriodState.startDate);
  const endDate = timestampToDate(focusPeriodState.endDate);

  const customTimePeriodOptions = convertParametersToCustomPeriodOptions(
    parameterDto.options
  );

  const customTimePeriodOption = focusPeriodState.options.find(
    (fp) => fp.value === TimePeriodOptions.CUSTOM_PERIOD
  );
  const maxNumberWeeks = customTimePeriodOption?.maxWeeks;
  const minNumberWeeks = customTimePeriodOption?.minWeeks;

  const hasOnlyCustomOption =
    focusPeriodState.options.length === 1 &&
    focusPeriodState.options[0].value === TimePeriodOptions.CUSTOM_PERIOD;

  const timePeriodOnChangeHandler = (newTimePeriod: string) => {
    dispatch(
      focusPeriodSelected({
        parameter: parameterDto.id,
        timePeriodValue: newTimePeriod,
      })
    );

    setMaxEndDate(undefined);
    setMinStartDate(undefined);
  };

  const startDateHandler = (newStartDate: string) => {
    dispatch(
      startDateSelected({
        parameter: parameterDto.id,
        startDate: newStartDate,
      })
    );

    if (maxNumberWeeks && maxNumberWeeks > 0) {
      const convertedStartDate = timestampToDate(newStartDate);
      if (convertedStartDate) {
        const computedMaxEndDate = addDays(
          convertedStartDate,
          maxNumberWeeks * DAYS_IN_WEEK - 1
        );
        if (maxNumberWeeks === minNumberWeeks) {
          dispatch(
            endDateSelected({
              endDate: dateToIsoString(computedMaxEndDate),
              parameter: parameterDto.id,
            })
          );
        } else {
          setMaxEndDate(computedMaxEndDate);
        }
      } else {
        setMaxEndDate(undefined);
      }
    }
  };

  const endDateHandler = (newEndDate: string) => {
    dispatch(
      endDateSelected({
        endDate: newEndDate,
        parameter: parameterDto.id,
      })
    );

    if (maxNumberWeeks && maxNumberWeeks > 0) {
      const convertedEndDate = timestampToDate(newEndDate);
      if (convertedEndDate) {
        const computedMinStartDate = addDays(
          convertedEndDate,
          -(maxNumberWeeks * DAYS_IN_WEEK - 1)
        );
        if (maxNumberWeeks === minNumberWeeks) {
          dispatch(
            startDateSelected({
              parameter: parameterDto.id,
              startDate: dateToIsoString(computedMinStartDate),
            })
          );
        } else {
          setMinStartDate(computedMinStartDate);
        }
      } else {
        setMinStartDate(undefined);
      }
    }
  };

  return (
    <TimePeriod
      customTimePeriodOption={customTimePeriodOption}
      customTimePeriodOptions={customTimePeriodOptions}
      endDate={endDate}
      endDateHandler={endDateHandler}
      errors={focusPeriodState.errors}
      infoIcon={parameterDto.description}
      isDisabled={focusPeriodState.options.length === 1}
      isDropdownVisible={!hasOnlyCustomOption}
      isVisited={isTabVisited}
      label={parameterDto.name}
      maxEndDate={maxEndDate}
      maxStartDate={endDate}
      minEndDate={startDate}
      minStartDate={minStartDate}
      onChange={timePeriodOnChangeHandler}
      options={focusPeriodState.options}
      startDate={startDate}
      startDateHandler={startDateHandler}
      timePeriod={focusPeriodState.value}
      weeks={focusPeriodState.weeks}
    />
  );
};
