import {
  FormInputHeight,
  FormInputWidth,
  QSearchInput,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { ToggleSelectTable } from "components-ui/src/tables/toggle-select-table/ToggleSelectTable";
import { useState } from "react";
import { type AggregateRankAttribute } from "../../models/aggregate-rank-common-models";
import styles from "./AggregateRankModal.module.css";

export const AggregateRankAttributesContent = ({
  attributeMetaData,
  selectedAttributes,
  handleOnToggle,
}: {
  attributeMetaData: AggregateRankAttribute[];
  handleOnToggle: (value: string) => void;
  selectedAttributes: string[];
}) => {
  const [searchString, setSearchString] = useState("");

  return (
    <>
      <div className={styles.attributesSearchBar}>
        <QSearchInput
          height={FormInputHeight.XSmall}
          id="searchinput_youridhere"
          onChange={(event) => {
            setSearchString(event.target.value.toLowerCase());
          }}
          placeholder="Search attributes"
          width={FormInputWidth.Large}
        />
      </div>
      <div
        className={classNames(styles.modalContentContainer, styles.overflow)}
      >
        <ToggleSelectTable
          onToggle={handleOnToggle}
          options={attributeMetaData
            .filter((x) => x.displayName.toLowerCase().includes(searchString))
            .map((attribute) => ({
              value: attribute.shortName,
              label: attribute.displayName,
            }))}
          selectedValues={selectedAttributes}
          title="Attributes"
        />
      </div>
    </>
  );
};

export default AggregateRankAttributesContent;
