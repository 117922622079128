import { TooltipHTML } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type Point, type TooltipFormatterContextObject } from "highcharts";
import { PrimaryMetricKey, ViewByOptions } from "../constants";
import {
  type laddervalues,
  type Series,
} from "../models/pricing-ladders-common-models";

const primaryMetricNames: string[] = Object.values(PrimaryMetricKey);

type pointWithMid = Point & { mid?: number };

// getting the default sort option for selected viewby when no additional metric is selected
export const getDefaultSortby = (viewBy: PanelOption[]): PanelOption[] => {
  if (!primaryMetricNames.includes(viewBy[1].value.toString())) {
    viewBy[1].value =
      viewBy[0].value.toString() === ViewByOptions.Avg
        ? PrimaryMetricKey.Avg
        : PrimaryMetricKey.AvgPricePerVolume;
    viewBy[1].label = viewBy[1].value;
  }

  return viewBy;
};

export const getSeriesInfo = (viewBy: string): string[] => {
  switch (viewBy) {
    case ViewByOptions.AvgPricePerVolume:
      return [
        PrimaryMetricKey.MaxAvgPricePerVolume,
        PrimaryMetricKey.AvgPricePerVolume,
        PrimaryMetricKey.AvgPromoPricePerVolume,
      ];
    default:
      return [
        PrimaryMetricKey.MaxAvg,
        PrimaryMetricKey.Avg,
        PrimaryMetricKey.AvgPromo,
      ];
  }
};

export const getPricingSeriesData = (
  pricingSeriesToProcess: Series[],
  seriesInfo: string[]
) => {
  const highSeries =
    pricingSeriesToProcess.find(
      (series) => series.metricLabel === seriesInfo[0]
    )?.data ?? [];

  const midSeries =
    pricingSeriesToProcess.find(
      (series) => series.metricLabel === seriesInfo[1]
    )?.data ?? [];

  const lowSeries =
    pricingSeriesToProcess.find(
      (series) => series.metricLabel === seriesInfo[2]
    )?.data ?? [];

  return { highSeries, midSeries, lowSeries };
};

// finding the null values and moving them to start while sorting
const comparenull = (
  a: number | null | undefined,
  b: number | null | undefined
) => {
  if (a === null && b !== null) {
    return -1;
  } else if (a !== null && b === null) {
    return 1;
  }

  return 0;
};

export const sortLadderValues = (
  ladderValues: laddervalues[],
  sortBy: string,
  additionalMetricKey: string,
  // flag for GBB, for GBB low can be higher than mid or high can be lower than mid
  isGBB: boolean = false
): laddervalues[] => {
  const laddervalues: laddervalues[] = JSON.parse(JSON.stringify(ladderValues));

  // Data Sanity checks for ladders and reject invalid data. Ideally avgprice should be greater than avgpromoprice and less than maxavgprice data
  for (const ladderValue of laddervalues) {
    if (ladderValue.mid === null || ladderValue.mid === undefined) {
      // If we don't have a midpoint we can't interpret *any* of this data point
      continue;
    }

    if (
      ladderValue.low !== null &&
      ladderValue.low !== undefined &&
      ladderValue.low > ladderValue.mid &&
      !isGBB
    ) {
      ladderValue.low = null;
    }

    if (
      ladderValue.high !== null &&
      ladderValue.high !== undefined &&
      ladderValue.high < ladderValue.mid &&
      !isGBB
    ) {
      ladderValue.high = null;
    }
  }

  // sorting laddervalues based on selected sortby option
  laddervalues.sort((a, b) => {
    if (sortBy !== additionalMetricKey) {
      const midcomp = comparenull(a.mid, b.mid);
      if (midcomp !== 0) return midcomp;
    }

    switch (sortBy) {
      // sort with high data
      case PrimaryMetricKey.MaxAvg:
      case PrimaryMetricKey.MaxAvgPricePerVolume: {
        const highcomp = comparenull(a.high, b.high);
        if (highcomp !== 0) return highcomp;

        return (a.high ?? 0) - (b.high ?? 0);
      }

      // sort with low data
      case PrimaryMetricKey.AvgPromo:
      case PrimaryMetricKey.AvgPromoPricePerVolume: {
        const lowcomp = comparenull(a.low, b.low);
        if (lowcomp !== 0) return lowcomp;

        return (a.low ?? 0) - (b.low ?? 0);
      }

      // sort with selected additional metric
      case additionalMetricKey:
        return (a.secondaryvalue ?? 0) - (b.secondaryvalue ?? 0);

      // default sort with mid data
      default:
        return (a.mid ?? 0) - (b.mid ?? 0);
    }
  });
  return laddervalues;
};

export const formatMetricValue = (metricValue: number | null | undefined) => {
  if (
    metricValue === undefined ||
    !Number.isFinite(metricValue) ||
    Number.isNaN(metricValue)
  )
    return null;

  return metricValue;
};

export const getRoundedValue = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

/**
 * Custom tooltip formater function for Good-Better Best of Pricing Ladders chart
 *
 *   @param data - TooltipFormatterContextObject, contains the point and series data of the highlighted context
 *   @param seriesInfo - string[], contains the series names of the chart
 */
export const plGbbToolTipFormatter = (
  data: TooltipFormatterContextObject,
  seriesInfo: string[]
) => {
  const tooltipData = [{}];
  let point: pointWithMid;

  if (
    seriesInfo.length === 0 ||
    data.points === undefined ||
    data.points.length === 0
  ) {
    return TooltipHTML();
  } else {
    // type conversion to 'any' used to as typecheck sometimes fails with dynamic property accessing after validation check also
    const columnRange = data.points.filter(
      (item) =>
        "initialType" in item.point.series &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item.point.series as any).initialType === "columnrange"
    );
    // check for gbb column range
    if (columnRange.length > 0) {
      point = columnRange[0].point;
      if ("high" in point) {
        tooltipData.push({
          color: String(point.color),
          name: seriesInfo[0],
          value: point.high ? getRoundedValue(point.high) : 0,
        });
      }

      if ("mid" in point) {
        tooltipData.push({
          color: "var(--qbit-colour-text-primary)",
          name: seriesInfo[1],
          value: point.mid ? getRoundedValue(Number(point.mid)) : 0,
        });
      }

      if ("low" in point) {
        tooltipData.push({
          color: String(point.color),
          name: seriesInfo[2],
          value: point.low ? getRoundedValue(point.low) : 0,
        });
      }
    }
    // if gbb is hidden by legends filetering
    else {
      const series = data.points.filter(
        (item) =>
          "initialType" in item.point.series &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item.point.series as any).initialType === "line"
      );
      if (series.length > 0) {
        point = series[0].point;
        tooltipData.push({
          color: String(point.color),
          name: seriesInfo[1],
          value: point.y ? getRoundedValue(Number(point.y)) : 0,
        });
      }
    }

    const additionMetrics = data.points.filter(
      (item) =>
        "initialType" in item.point.series &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item.point.series as any).initialType === "column"
    );
    if (additionMetrics.length > 0) {
      point = additionMetrics[0].point;
      tooltipData.push({
        color: String(point.color),
        name: point.series.name,
        value: point.y ? getRoundedValue(Number(point.y)) : 0,
      });
    }
  }

  return TooltipHTML(tooltipData);
};
