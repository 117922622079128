import { createSelector, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "../store";

export type DashboardState = {
  initialRender: boolean;
};

const initialState: DashboardState = {
  initialRender: false,
};

export const dashboardSlice = createSlice({
  initialState,
  name: "dashboard-slice",
  reducers: {
    Rendered: (state: DashboardState) => {
      state.initialRender = true;
    },
  },
});

export const dashboardSelector = createSelector(
  (state: RootState) => state.dashboard,
  (dashboard: DashboardState) => dashboard
);

export const { Rendered } = dashboardSlice.actions;

export default dashboardSlice.reducer;
