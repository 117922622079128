import {
  type HierarchyValueDto,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { type ChartLocalParameterSelections } from "../models/basket-quantities-common-models";
import { type TableLocalParameterSelections } from "../models/basket-quantities-data-table-models";

const createSegmentationSelection = (segmentation?: string, segment?: string) =>
  segmentation && segment
    ? {
        segmentation,
        segment,
      }
    : undefined;

export const getFocalItemTableLocalSelections = (
  location: LocalHierarchyNodeSelection,
  channel?: string,
  promotion?: string,
  segmentation?: string,
  segment?: string
): TableLocalParameterSelections => ({
  location: location.nodeNumber,
  channel,
  promotion,
  segmentation: createSegmentationSelection(segmentation, segment),
});

export const getReportletLocalSelections = (
  breakdown: string,
  location: LocalHierarchyNodeSelection,
  focalItem: HierarchyValueDto,
  channel?: string,
  promotion?: string,
  segmentation?: string,
  segment?: string
): ChartLocalParameterSelections => ({
  breakdown,
  location: location.nodeNumber,
  product: {
    itemCode: focalItem.itemCode,
    shortName: focalItem.shortName,
  },
  channel,
  promotion,
  segmentation: createSegmentationSelection(segmentation, segment),
});
