import { type AggregateRankTableRow } from "../models/aggregate-rank-common-models";
import {
  type AggregateRankTableResponseMetricId,
  type AggregateRankTableResponseRow,
} from "../models/aggregate-rank-table-models";

// Some utility to convert array of objects to record

const GetValue = (value?: number | string) => {
  if (typeof value === "number") {
    return value;
  }

  // If value is null, "NaN" or "Infinity" replace with a "-"
  return "-";
};

const MapAttributesToRecord = (
  attributes: Array<{ name?: string; shortName: string }>
) => {
  const attributesRecord: Record<string, string> = {};

  for (const attribute of attributes) {
    attributesRecord[attribute.shortName] = attribute.name ?? "-";
  }

  return attributesRecord;
};

const MapRankedMetricsToRecord = (
  rankedMetrics: Array<{ id: number; rank: number; value?: number | string }>,
  metricIds: AggregateRankTableResponseMetricId[]
): Record<string, { rank: number; value: number | string }> => {
  const rankedMetricsRecord: Record<
    string,
    { rank: number; value: number | string }
  > = {};

  for (const metric of rankedMetrics) {
    const metricName = metricIds[metric.id].name;
    rankedMetricsRecord[metricName] = {
      rank: metric.rank,
      value: GetValue(metric.value),
    };
  }

  return rankedMetricsRecord;
};

const MapUnrankedMetricsToRecord = (
  unRankedMetrics: Array<{ id: number; value?: number | string }>,
  metricIds: AggregateRankTableResponseMetricId[]
): Record<string, number | string> => {
  const unrankedMetricRecord: Record<string, number | string> = {};

  for (const metric of unRankedMetrics) {
    const metricName = metricIds[metric.id].name;
    unrankedMetricRecord[metricName] = GetValue(metric.value);
  }

  return unrankedMetricRecord;
};

export const ConvertProductDataToTableRow = (
  responseRow: AggregateRankTableResponseRow,
  metricIds: AggregateRankTableResponseMetricId[]
): AggregateRankTableRow => ({
  aggregateScore: responseRow.aggregateResult.score,
  aggregateRank: responseRow.aggregateResult.rank,
  attributes: MapAttributesToRecord(responseRow.attributes),
  productName: responseRow.name,
  isDeleted: responseRow.isDeleted,
  rankedMetrics: MapRankedMetricsToRecord(responseRow.rankedMetrics, metricIds),
  unrankedMetrics: MapUnrankedMetricsToRecord(
    responseRow.unRankedMetrics,
    metricIds
  ),
});

/**
 * Converts the rows returned from the backend API into table rows for frontend.
 * Converts the arrays of attribute, ranked metrics and unranked metrics to dictionaries
 *
 * @param responseRows
 * @param metricIds
 * @returns
 */
export const ResponseToTableRows = (
  responseRows: AggregateRankTableResponseRow[],
  metricIds: AggregateRankTableResponseMetricId[]
): AggregateRankTableRow[] => {
  const dataTableRows: AggregateRankTableRow[] = responseRows.map((row) =>
    ConvertProductDataToTableRow(row, metricIds)
  );

  return dataTableRows;
};
