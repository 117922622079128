import { z } from "zod";
import {
  ParameterSelectionDtoSchema,
  type ParameterSelectionDto,
} from "./parameter-selection-dto";

export type ParameterGroupSelectionDto = {
  name: string;
  parameterSelections: ParameterSelectionDto[];
};

export const ParameterGroupSelectionDtoSchema = z.object({
  name: z.string(),
  parameterSelections: z.array(ParameterSelectionDtoSchema),
});
