import {
  type TransactionSource,
  type HierarchyGroupDto,
  type ParameterDto,
  isGroupSelectionDto,
  type SelectionDto,
  createRecord,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface GroupParameterState extends ParameterState {
  confirmedSelections: HierarchyGroupDto[];
  expandedIds: string[];
  knownGroupTransactionSources: Record<string, TransactionSource[] | undefined>;
  pendingSelections: HierarchyGroupDto[];
}

export const isGroupParameterState = (
  state: ParameterState | undefined
): state is GroupParameterState =>
  state !== undefined &&
  Object.prototype.hasOwnProperty.call(state, "confirmedSelections") &&
  Object.prototype.hasOwnProperty.call(state, "knownGroupTransactionSources") &&
  Object.prototype.hasOwnProperty.call(state, "pendingSelections");

export const newGroupParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): GroupParameterState => {
  let selectedGroups: HierarchyGroupDto[] = [];
  const defaultSelections = savedSelections ?? parameterConfig.selections ?? [];
  selectedGroups = defaultSelections.flatMap((option) => {
    if (isGroupSelectionDto(option)) {
      return [
        {
          name: option.groupName,
          id: option.groupId,
          evaluationType: option.evaluationType,
          hierarchyType: option.hierarchyType,
        } as HierarchyGroupDto,
      ];
    }

    return [];
  });

  return {
    isValid: isBetweenMinMax(0, parameterConfig),
    confirmedSelections: [],
    expandedIds: [],
    knownGroupTransactionSources: {},
    parameterConfig,
    parameterGroup,
    pendingSelections: selectedGroups,
  };
};

export const getGroupParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as GroupParameterState | undefined
  );

export const toggleGroupSelection = (
  parameterState: ParameterState,
  selectedGroups: HierarchyGroupDto[]
): ParameterState => {
  if (isGroupParameterState(parameterState)) {
    const pendingIds = createRecord(
      parameterState.pendingSelections,
      (group) => group.id ?? ""
    );
    const confirmedIds = createRecord(
      parameterState.confirmedSelections,
      (group) => group.id ?? ""
    );

    let everySelectedAlreadyPendingOrConfirmed = true;
    const selectedIds: Record<string, boolean> = {};
    for (const group of selectedGroups) {
      const groupId = group.id ?? "";
      selectedIds[groupId] = true;
      if (
        everySelectedAlreadyPendingOrConfirmed &&
        !(pendingIds[groupId] || confirmedIds[groupId])
      ) {
        everySelectedAlreadyPendingOrConfirmed = false;
      }
    }

    // If all leaf nodes of a folder were already pending or confirmed,
    // then we want to perform the mass-deselection of all those leaf nodes.
    // If, however, any of the leaf nodes were not pending or confirmed,
    // then we want to perform the "select all remaining" operation.
    // This behaviour is to align with standard hierarchical checkbox behaviour
    // where the root node of the selection is in an "indeterminate" state.
    if (everySelectedAlreadyPendingOrConfirmed) {
      parameterState.confirmedSelections =
        parameterState.confirmedSelections.filter(
          (pending) => !selectedIds[pending.id ?? ""]
        );
      parameterState.pendingSelections =
        parameterState.pendingSelections.filter(
          (pending) => !selectedIds[pending.id ?? ""]
        );
    } else {
      parameterState.pendingSelections = [
        ...parameterState.pendingSelections,
        ...selectedGroups.filter((selected) => !pendingIds[selected.id ?? ""]),
      ];
    }
  }

  return { ...parameterState, isParameterMissing: false };
};

export const updateGroupTransactionSources = (
  parameterState: ParameterState,
  groupId: string,
  transactionSources: TransactionSource[]
): ParameterState => {
  if (isGroupParameterState(parameterState)) {
    parameterState.knownGroupTransactionSources[groupId] = transactionSources;
    if (transactionSources.length > 0) {
      const validGroup = parameterState.pendingSelections.find(
        (group) => group.id === groupId
      );
      if (validGroup) {
        parameterState.confirmedSelections.push(validGroup);
      }
    }

    parameterState.pendingSelections = parameterState.pendingSelections.filter(
      (pendingGroup) => pendingGroup.id !== groupId
    );
  }

  return { ...parameterState };
};
