import { ddLog } from "@quantium-enterprise/common-ui";
import { type MetricsWeightsAttributes } from "../models/aggregate-rank-common-models";
import { aggregateRankApiSlice } from "./aggregate-rank-api-slice";
import { onModalParametersReceived } from "./aggregate-rank-slice";

export const aggregateRankModalParametersApiSlice =
  aggregateRankApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getModalData: builder.query<
        MetricsWeightsAttributes,
        { divisionName: string; reportId: string }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onModalParametersReceived(data));
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetModalData/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetModalDataQuery } =
  aggregateRankModalParametersApiSlice;
