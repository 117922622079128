import { type HierarchyValue } from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type GainsAndLossMiniChartRequestDto } from "../models/GainsAndLossMiniChartRequestDto";

export const getMiniChartRequestDto = (
  focalItem: HierarchyValue,
  switchingToItem: HierarchyValue,
  location: string,
  channel: string,
  reportId: string
) => {
  const payload: GainsAndLossMiniChartRequestDto = {
    focalItem: {
      itemCode: focalItem.itemCode,
      shortName: focalItem.shortName,
    },
    localSelectedValues: [
      { id: LocalParameters.Channel, values: [channel] },
      {
        id: LocalParameters.LocationHierarchy,
        values: [location],
      },
    ],
    reportId,
    switchingToItem: {
      itemCode: switchingToItem.itemCode,
      shortName: switchingToItem.shortName,
    },
  };
  return payload;
};
