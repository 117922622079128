import { type ChartDataSeries } from "components-ui/src/charts/contribution-drivers-chart/ContributionDriversChart";
import {
  type ContributionMeasureItem,
  type ContributionItem,
} from "../models/ContributionChartDriverDto";

const colors = [
  "#000006",
  "#EB5138",
  "#DBAC49",
  "#1FBA77",
  "#5885FF",
  "#7C60AF",
];

export const reshapeChartData = (
  chartData: ContributionItem[],
  metricFormatter: Function
) =>
  chartData.map(({ label, format, values }, index) => ({
    color: colors[index],
    data: values.map((value) => value),
    formatter: (
      value?: number | string | null,
      alwaysDisplaySign: boolean = false
    ) => metricFormatter(format, value, alwaysDisplaySign),
    name: index === 0 ? label + " growth" : label,
    type: (index === 0 ? "line" : "column") as "column" | "line",
  }));

export const reshapeTableData = (
  tableData: ContributionItem[],
  metricFormatter: Function
) =>
  tableData.map(({ label, format, values }, index) => ({
    data: values.map((value_) => value_),
    formatter: (
      value?: number | string | null,
      alwaysDisplaySign: boolean = false
    ) => metricFormatter(format, value, alwaysDisplaySign),
    hasSentiment: index !== 0,
    name: label,
  }));

export const reshapedMeasuresData = (measures: ContributionMeasureItem) => [
  {
    color: "#3F69AE",
    data: measures.values.map((value) => ({
      change: value[1],
      y: value[0],
    })),
    name: "",
    type: "column",
  } as ChartDataSeries,
];
