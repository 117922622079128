export enum MetricTypes {
  Currency = "Currency",
  Decimal = "Decimal",
  Integer = "Integer",
  None = "None",
  Percentage = "Percentage",
  PercentagePoint = "PercentagePoint",
  SmallInteger = "SmallInteger",
  String = "String",
}
