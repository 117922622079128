import { type Data, type UniqueIdentifier } from "@dnd-kit/core";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { type Item } from "./models/Item";
import { type Zone } from "./models/Zone";
import {
  defaultDropZone,
  insertTemporaryZone,
  sortHierarchyItems,
  reorderExistingStructure,
  addNewDropZone,
  insertItemIntoStructure,
  moveItemBackToList,
  removeEmptyDropZones,
  defaultFindValidDropIndex,
  defaultCanDropAttribute,
  addPlaceholderItemAtIndex,
} from "./utilities/utilities";

/**
 * This function handles the changes in state when a user picks up a draggable item
 *
 * @param currentEventData - Data from dnd-kit's DragStartEvent
 * @param items - The list of draggable items
 * @param setItems - Callback to modify the list of draggable items
 * @param setActiveItem - Callback to modify the active item state
 * @param droppableZones - The list of drop zones in the structure
 * @param setDroppableZones - Callback to modify the structure of droppable zones
 * @param canDropAttribute - Callback to determine whether an attribute item can be dropped below a given zone. If not provided, a default function will be used.
 * @param findValidDropIndex - Callback to find a valid drop index in the structure for a hierarchy item. If not provided, a default function will be used.
 * @returns
 */
export const handleDragStart = (
  currentEventData: Data | undefined,
  items: Item[],
  setItems: (items: Item[]) => void,
  setActiveItem: (item: Item | undefined) => void,
  droppableZones: Zone[],
  setDroppableZones: (zones: Zone[]) => void,
  canDropAttribute: (
    activeItem: Item,
    zone: Zone
  ) => boolean = defaultCanDropAttribute,
  findValidDropIndex: (
    activeItem: Item,
    droppableZones: Zone[]
  ) => number = defaultFindValidDropIndex
) => {
  if (currentEventData) {
    const activeItem = currentEventData as Item;
    setActiveItem(activeItem);
    const index = items.findIndex((item) => item.id === activeItem.id);

    if (index < 0) {
      // If item can't be found in list, it means we're dragging from the right side container
      // In this case we'll first remove the existing zone, then generate new ones
      reorderExistingStructure(
        activeItem,
        droppableZones,
        setDroppableZones,
        canDropAttribute
      );
      return;
    } else {
      addPlaceholderItemAtIndex(items, setItems, index);
    }

    // For attribute items, add new zone below every existing droppable zones
    if (activeItem.type === HierarchyItemType.Attribute) {
      const newDroppableZones: Zone[] = [];

      for (const zone of droppableZones) {
        newDroppableZones.push(zone);
        if (canDropAttribute(activeItem, zone)) {
          insertTemporaryZone(newDroppableZones, activeItem);
        }
      }

      setDroppableZones([...newDroppableZones]);
    } else {
      // When no droppable zones exist yet, create one
      if (droppableZones.length === 0) {
        setDroppableZones([defaultDropZone(activeItem)]);
        return;
      }

      // Ensure item doesn't already exist in the structure
      const isFound = droppableZones.find(
        (zone) => zone.item && zone.item.id === activeItem.id
      );

      if (!isFound) {
        const injectionIndex = findValidDropIndex(activeItem, droppableZones);

        addNewDropZone(
          activeItem,
          droppableZones,
          setDroppableZones,
          injectionIndex
        );
      }
    }
  }
};

/**
 * This function handles the changes in state when a user drops a draggable item
 *
 * @param overZone - Current zone being hovered over or undefined
 * @param overId - Id of the current zone or undefined
 * @param activeItem - The item being dropped
 * @param setActiveItem - Callback to modify the active item state
 * @param droppableZones - The list of drop zones in the structure
 * @param setDroppableZones - Callback to modify the structure of droppable zones
 * @param items - The list of draggable items
 * @param setItems - Callback to modify the list of draggable items
 */
export const handleDragEnd = (
  overZone: Data | undefined,
  overId: UniqueIdentifier | undefined,
  activeItem: Item | undefined,
  setActiveItem: (item: Item | undefined) => void,
  droppableZones: Zone[],
  setDroppableZones: (zones: Zone[]) => void,
  items: Item[],
  setItems: (items: Item[]) => void
) => {
  if (overZone && overId) {
    // Item can be dropped if drop zone exists, has no items & can accept the active item type
    const canDrop =
      activeItem &&
      overId &&
      overZone.accepts.includes(activeItem.type) &&
      !overZone.item;

    if (canDrop) {
      insertItemIntoStructure(
        overId,
        activeItem,
        setActiveItem,
        droppableZones,
        setDroppableZones,
        items,
        setItems
      );
    } else {
      moveItemBackToList(activeItem, setActiveItem, items, setItems);
      removeEmptyDropZones(droppableZones, setDroppableZones);
    }
  } else {
    moveItemBackToList(activeItem, setActiveItem, items, setItems);
    removeEmptyDropZones(droppableZones, setDroppableZones);
  }
};

/**
 * This function handles the changes in state when a user removes an item from the structure
 *
 * @param item - The item being removed
 * @param currentZone - The zone containing the item being removed
 * @param droppableZones - The list of drop zones in the structure
 * @param setDroppableZones - Callback to modify the structure of droppable zones
 * @param items - The list of draggable items
 * @param setItems - Callback to modify the list of draggable items
 */
export const handleRemove = (
  item: Item,
  currentZone: Zone,
  droppableZones: Zone[],
  setDroppableZones: (zones: Zone[]) => void,
  items: Item[],
  setItems: (items: Item[]) => void
) => {
  const foundZone = droppableZones.find((zone) => zone.id === currentZone.id);

  if (foundZone) {
    const newZones = droppableZones
      .filter((zone) => zone.id !== currentZone.id)
      .filter((zone) => zone.item !== undefined);
    const newItems = [...items, item];

    // Attribute items can't be the root node
    while (
      newZones.length > 0 &&
      newZones[0].item &&
      newZones[0].item.type === HierarchyItemType.Attribute
    ) {
      newItems.push(newZones[0].item);
      newZones.splice(0, 1);
    }

    newItems.sort(sortHierarchyItems);

    setDroppableZones([...newZones]);
    setItems([...newItems]);
  }
};
