/* eslint-disable typescript-sort-keys/string-enum */
import { TrackingProperties } from "./TrackingProperties";

export enum SearchTrackingProperty {
  TabSelected = "Tab selected",
}

export enum SearchTrackingPropertyValue {
  All = "All",
  Assortment = "Assortment",
  Brand = "Brand",
  Hierarchy = "Hierarchy",
  Supplier = "Supplier",
  ProductGroups = "Product groups",
  Unknown = "Unknown",
}

export class SearchTrackingProperties extends TrackingProperties {
  public constructor(
    property: SearchTrackingProperty,
    value: SearchTrackingPropertyValue
  ) {
    super();

    this.Properties = {};
    this.Properties[property] = value;
  }

  public static search(
    property: SearchTrackingProperty,
    value: string
  ): SearchTrackingProperties {
    let propertyValue: SearchTrackingPropertyValue;

    switch (value.toLowerCase()) {
      case "all":
        propertyValue = SearchTrackingPropertyValue.All;
        break;
      case "hierarchy":
        propertyValue = SearchTrackingPropertyValue.Hierarchy;
        break;
      case "supplier":
        propertyValue = SearchTrackingPropertyValue.Supplier;
        break;
      case "brand":
        propertyValue = SearchTrackingPropertyValue.Brand;
        break;
      case "assortment":
        propertyValue = SearchTrackingPropertyValue.Assortment;
        break;
      case "product groups":
        propertyValue = SearchTrackingPropertyValue.ProductGroups;
        break;
      default:
        console.log(`no tracking property ${value}`); // eslint-disable-line no-console
        propertyValue = SearchTrackingPropertyValue.Unknown;
    }

    return new this(property, propertyValue);
  }
}
