import { type FocalItem } from "@quantium-enterprise/common-ui";
import {
  Button,
  ButtonHeight,
  ButtonWidth,
  Icon,
  IconGlyph,
} from "@quantium-enterprise/qds-react";
import { FocalItemIcon } from "./FocalItemIcon";
import styles from "./ReportingDropdownTab.module.css";

export type FastReportTabsDropdownTabProps = {
  item: FocalItem;
  onRemoveTab: () => void;
  onSetActiveTab: () => void;
};

export const FastReportTabsDropdownTab = ({
  item,
  onRemoveTab,
  onSetActiveTab,
}: FastReportTabsDropdownTabProps) => (
  <div className={styles.reportTabsDropdownTab}>
    <Button onClick={onSetActiveTab}>
      <div className={styles.icon}>
        <FocalItemIcon focalItem={item} isActive />
      </div>
      <div className={styles.text}>{item.displayName}</div>
    </Button>
    <Button
      className={styles.close}
      height={ButtonHeight.XSmall}
      onClick={(event) => {
        event.stopPropagation();
        onRemoveTab();
      }}
      width={ButtonWidth.Fit}
    >
      <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
    </Button>
  </div>
);
