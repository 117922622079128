import { useNumberFormat } from "@quantium-enterprise/hooks-ui";
import {
  type SortingState,
  type Updater,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { HierarchyValueCell } from "components-ui/src/tables/common/table-cell/HierarchyValueCell";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import {
  ColumnType,
  HeaderVerticalAlign,
} from "components-ui/src/tables/virtuoso-table/VirtuosoTableHeaderElement";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportletTableId } from "../constants/basket-affinities-common";
import {
  type AssociatedAncestor,
  type PurchasedWithProduct,
} from "../models/basket-affinities-chart-models";
import {
  selectAssociatedItemColours,
  selectMaxUplift,
  selectFilteredPurchasedWithProducts,
  selectPurchasedWithProductsSortingState,
  onReportletTableSort,
  selectReportletRowSelectionState,
  onReportletSearchQueryChange,
  selectReportletSearchQuery,
} from "../services/basket-affinities-slice";
import styles from "./BasketAffinitiesPurchasedWithFocalItemsTable.module.css";

export const BasketAffinitiesPurchasedWithFocalItemsTable = () => {
  const searchQuery = useSelector(selectReportletSearchQuery);
  const tableData: PurchasedWithProduct[] = useSelector(
    selectFilteredPurchasedWithProducts
  );
  const sortingState = useSelector(selectPurchasedWithProductsSortingState);
  const associatedItemColours = useSelector(selectAssociatedItemColours);
  const maxUplift = useSelector(selectMaxUplift);
  const rowSelectionState = useSelector(selectReportletRowSelectionState);

  const searchReportletRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const resultSize = useMemo(() => tableData.length, [tableData.length]);

  const {
    integerFormatter,
    currencyFormatter,
    decimalFormatter,
    percentFormatter,
  } = useNumberFormat();

  const createNameCell = useCallback(
    ({ row }: CellContext<PurchasedWithProduct, unknown>) => (
      <HierarchyValueCell item={row.original.product} />
    ),
    []
  );

  const createNumberCell = useCallback(
    (formatter: (value: number | string) => string, value: string) => (
      <div className={styles.numberCell}>{formatter(value)}</div>
    ),
    []
  );

  const createTextCell = useCallback(
    (value: string) => <div className={styles.textCell}>{value}</div>,
    []
  );

  const onSearchChange = useCallback(
    (value: string) => {
      dispatch(onReportletSearchQueryChange(value));
      searchReportletRef.current?.querySelector("input")?.focus();
    },
    [dispatch]
  );

  const createUpliftCell = useCallback(
    (associatedItem: AssociatedAncestor, value: string) => {
      const colour = associatedItemColours.get(associatedItem);
      const widthAssociatedItemPercent = Math.round(
        (Number(value) / maxUplift) * 100
      );
      const widthRemainingPercent = 100 - widthAssociatedItemPercent;
      return (
        <div className={styles.upliftCell}>
          <div aria-hidden className={styles.upliftRange}>
            <div
              style={{
                width: `${widthAssociatedItemPercent}%`,
                backgroundColor: `${colour}`,
              }}
            />
            <div
              className={styles.upliftRangeRest}
              style={{ width: `${widthRemainingPercent}%` }}
            />
          </div>
          <div className={styles.upliftRangeValue}>
            {decimalFormatter(value)}
          </div>
        </div>
      );
    },
    [associatedItemColours, decimalFormatter, maxUplift]
  );

  const searchHeader = useMemo(
    () => (
      <div className={styles.searchHeader} ref={searchReportletRef}>
        <div className={styles.searchHeaderTitle}>
          Associated products ({resultSize})
        </div>
        <SearchBox
          onChange={onSearchChange}
          placeholder="Type to search"
          searchQuery={searchQuery}
        />
      </div>
    ),
    [onSearchChange, resultSize, searchQuery]
  );

  const columns: Array<ColumnDef<PurchasedWithProduct>> = useMemo(
    () => [
      {
        accessorFn: (x: PurchasedWithProduct) => x.product.name,
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        enableRowFiltering: false,
        header: () => searchHeader,
        id: "product",
        meta: {
          columnType: ColumnType.Text,
        },
      },
      {
        accessorFn: (x: PurchasedWithProduct) => x.associatedAncestor.name,
        cell: (cell: CellContext<PurchasedWithProduct, unknown>) =>
          createTextCell(`${cell.getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Subcategory",
        meta: {
          columnType: ColumnType.Text,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: PurchasedWithProduct) => x.uplift,
        cell: (cell: CellContext<PurchasedWithProduct, unknown>) =>
          createUpliftCell(
            cell.row.original.associatedAncestor,
            `${cell.getValue()}`
          ),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Uplift",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: PurchasedWithProduct) => x.confidence,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(percentFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Confidence (Basket With both/ Baskets with focal prd)",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: PurchasedWithProduct) => x.support,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(percentFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Support (Associated baskets / Total baskets)",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: PurchasedWithProduct) => x.basketsWithBoth,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(integerFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Baskets with both",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: PurchasedWithProduct) => x.associatedSalesPerBasket,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(currencyFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Associated sales per basket",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: PurchasedWithProduct) => x.allTargetSales,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(currencyFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "All target sales",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
    ],
    [
      createNameCell,
      searchHeader,
      createTextCell,
      createUpliftCell,
      createNumberCell,
      percentFormatter,
      integerFormatter,
      currencyFormatter,
    ]
  );

  const onSort = (sorting: Updater<SortingState>): void => {
    dispatch(onReportletTableSort(sorting));
  };

  return (
    <div className={styles.table} id={ReportletTableId} style={{ height: 400 }}>
      <VirtuosoTableComponent
        columns={columns}
        data={tableData}
        enableSorting
        getRowId={(row) => row.product.itemCode}
        getSubRows={() => []}
        onSortingChange={onSort}
        pinFirstColumn
        rowExpandedState
        rowSelectionState={rowSelectionState}
        sorting={sortingState}
      />
      {searchQuery.length > 0 && tableData.length === 0 && (
        <div className={styles.noSearchResults}>
          <EmptySearch />
        </div>
      )}
    </div>
  );
};
