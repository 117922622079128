import { type ReportParametersDto } from "@quantium-enterprise/common-ui";
import { HierarchyItemType, ddLog } from "@quantium-enterprise/common-ui";
import { getNumberFormat, useDivision } from "@quantium-enterprise/hooks-ui";
import { Toggle, ToggleSize } from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { isfeatureEnable } from "../../common/utils/feature-flag-utils";
import { NewProductBenchmarkingFeatureFlags } from "../constants/new-product-benchmarking-feature-flags";
import {
  useLazyGetBenchmarkRowsQuery,
  useLazyGetProductRowsQuery,
} from "../services/new-product-benchmarking-data-table-api-slice";
import { useGetMetricsListQuery } from "../services/new-product-benchmarking-metrics-list-api-slice";
import {
  onFocalTableBenchmarksDataReceived,
  onMetricsListReceived,
  onMoreFocalTableProductsDataReceived,
  onUpdatedFocalTableProductsDataReceived,
  selectBenchmarkData,
  selectFocalItems,
  selectFocalTableMetrics,
  selectFocalTableProductsData,
  selectLocalParametersInitialised,
  selectLocalParametersSelections,
  selectMetricsList,
  selectReportId,
  selectSearchQuery,
  selectShowBenchmarks,
  setFocalItemTableMetricsList,
  toggleShowBenchmarks,
} from "../services/new-product-benchmarking-slice";
import { getUserSelections } from "../utils/local-parameter-utils";
import { NewProductBenchmarkingFocalTable } from "./NewProductBenchmarkingFocalTable";
import { NewProductBenchmarkingMultiSelectDropdown } from "./NewProductBenchmarkingMultiSelectDropdown";
import { focalItemCsvTransformation } from "./csvTransformation";

const NPB_FOCAL_TABLE_PAGE_SIZE = 100;

export type NewProductBenchmarkingTopDrawerProps = {
  eventTrackingService: Function;
  infoPanelSummary: ReportParametersDto | undefined;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const NewProductBenchmarkingTopDrawer = ({
  eventTrackingService,
  renameReport,
  reportType,
  reportName,
  infoPanelSummary,
}: NewProductBenchmarkingTopDrawerProps) => {
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();

  const metricsList = useSelector(selectMetricsList);
  const focalItems = useSelector(selectFocalItems);
  const selectedMetrics = useSelector(selectFocalTableMetrics);
  const showBenchmarks = useSelector(selectShowBenchmarks);
  const localParameterSelections = useSelector(selectLocalParametersSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const productData = useSelector(selectFocalTableProductsData);
  const reportId = useSelector(selectReportId);
  const searchQuery = useSelector(selectSearchQuery);
  const benchmarkData = useSelector(selectBenchmarkData);

  const [updateProductRowsTrigger, { isFetching: focalTableProductsUpdating }] =
    useLazyGetProductRowsQuery();
  const [getProductRowsTrigger, { isFetching: focalTableProductsLoading }] =
    useLazyGetProductRowsQuery();

  const [getBenchmarkRowsTrigger, { isFetching: focalTableBenchmarksLoading }] =
    useLazyGetBenchmarkRowsQuery();

  const isfeatureEnabled = isfeatureEnable(
    NewProductBenchmarkingFeatureFlags.ReportExport
  );
  const focalTableLoading = useMemo(
    () => focalTableBenchmarksLoading || focalTableProductsUpdating,
    [focalTableBenchmarksLoading, focalTableProductsUpdating]
  );

  const { data: metricsResponse } = useGetMetricsListQuery(
    {
      divisionName,
      reportId,
    },
    {
      skip: !(reportId && divisionName && localParametersInitialised),
    }
  );

  useEffect(() => {
    if (metricsResponse?.groups)
      dispatch(onMetricsListReceived(metricsResponse));
  }, [metricsResponse, dispatch]);

  const updateBenchmarkRows = useCallback(async () => {
    const { data } = await getBenchmarkRowsTrigger({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections(localParameterSelections),
        reportId,
        metrics: selectedMetrics,
      },
    });
    if (data) dispatch(onFocalTableBenchmarksDataReceived(data));
  }, [
    getBenchmarkRowsTrigger,
    reportId,
    localParameterSelections,
    divisionName,
    selectedMetrics,
    dispatch,
  ]);

  const updateProductRows = useCallback(async () => {
    const { data } = await updateProductRowsTrigger({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections(localParameterSelections),
        reportId,
        metrics: selectedMetrics,
        skipSize: 0,
        pageSize:
          productData.lastRowIndex === 0
            ? NPB_FOCAL_TABLE_PAGE_SIZE
            : productData.lastRowIndex + 1,
        searchQuery,
      },
    });
    if (data)
      dispatch(
        onUpdatedFocalTableProductsDataReceived({
          data,
          isSearch: Boolean(searchQuery),
        })
      );
  }, [
    localParameterSelections,
    selectedMetrics,
    productData,
    reportId,
    divisionName,
    dispatch,
    updateProductRowsTrigger,
    searchQuery,
  ]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const multiselectDropdownMemoized = useMemo(
    () => (
      <NewProductBenchmarkingMultiSelectDropdown
        key="multiselect-dropdown"
        metricsList={metricsList}
        selectedMetrics={selectedMetrics}
        setDropdownOpen={setIsDropdownOpen}
        toggleMetricSelected={(metricName: string) => {
          dispatch(setFocalItemTableMetricsList({ metricName }));
        }}
      />
    ),
    [metricsList, selectedMetrics, dispatch]
  );

  const getMoreProductRows = useCallback(async () => {
    const { data } = await getProductRowsTrigger({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections(localParameterSelections),
        reportId,
        metrics: selectedMetrics,
        skipSize: productData.lastRowIndex + 1,
        pageSize: NPB_FOCAL_TABLE_PAGE_SIZE,
      },
    });
    if (data) dispatch(onMoreFocalTableProductsDataReceived(data));
  }, [
    localParameterSelections,
    getProductRowsTrigger,
    selectedMetrics,
    productData,
    divisionName,
    reportId,
    dispatch,
  ]);

  useEffect(
    () => {
      if (selectedMetrics.length > 0) {
        updateBenchmarkRows().catch((error) => {
          ddLog("ERROR", {}, "error", error);
        });
        updateProductRows().catch((error) => {
          ddLog("ERROR", {}, "error", error);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMetrics, updateBenchmarkRows, ddLog, searchQuery]
  );

  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const focalItemCsvTransformationCallback = useCallback(
    () =>
      focalItemCsvTransformation(
        productData,
        benchmarkData,
        metricsList,
        showBenchmarks,
        currencySymbol
      ),
    [productData, currencySymbol, metricsList, showBenchmarks, benchmarkData]
  );

  const exportFocalItemFilename = useMemo(
    () =>
      cleanFilename(
        `New_Product_Benchmarking_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const parameterSummary = [
    { name: "Time", value: localParameterSelections.FocusPeriod as string },
    {
      name: "Segmentation",
      value:
        localParameterSelections.Segmentation.length > 0
          ? localParameterSelections.Segmentation[0]?.label
          : "",
    },
    {
      name: "Customer segment",
      value:
        localParameterSelections.Segmentation.length > 1
          ? localParameterSelections.Segmentation[1]?.label
          : "",
    },
    { name: "Channel", value: localParameterSelections.Channel.label },
    {
      name: "Location",
      value: `(${hierarchyLevelDisplayLabel(
        localParameterSelections.LocationHierarchy.shortName
      )}) ${localParameterSelections.LocationHierarchy.name}`,
    },
  ];

  return (
    <ReportTopDrawer
      controls={[
        multiselectDropdownMemoized,
        <Toggle
          checked={showBenchmarks}
          key={uniqueId()}
          label="Show benchmarks"
          onClick={() => dispatch(toggleShowBenchmarks())}
          size={ToggleSize.Small}
        />,
        <div key={reportId} style={{ width: "30px", overflow: "hidden" }}>
          <DataTableOptions
            filename={exportFocalItemFilename}
            invokeCSVDownload={focalItemCsvTransformationCallback}
            isFeatureEnabled={isfeatureEnabled}
            localParameters={parameterSummary}
            reportParameters={infoPanelSummary}
          />
        </div>,
      ]}
      disableEscape={isDropdownOpen}
      items={focalItems.map((item) => ({
        code: "PRD",
        displayName: item.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <NewProductBenchmarkingFocalTable
          eventTrackingService={eventTrackingService}
          getMoreProductRows={getMoreProductRows}
          moreRowsLoading={focalTableProductsLoading}
          tableLoading={focalTableLoading}
        />
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default NewProductBenchmarkingTopDrawer;
