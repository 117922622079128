import {
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyGroupDto,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  HierarchyType,
  HierarchyGroupEvaluationType,
  formatHierarchyName,
  GroupsTrackingProperty,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  GenericTrackingProperties,
  formatShortDate,
  useHierarchyMetadataQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Group,
  Item,
  ItemValign,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  Spinner,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../../states/group-hierarchy-source-slice";
import {
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { DynamicGroupLeafsTable } from "../dynamic-group-leafs-table/DynamicGroupLeafsTable";
import { DynamicGroupRulesForm } from "../dynamic-group-rules-form/DynamicGroupRulesForm";
import { GroupEditor } from "../group-editor/GroupEditor";
import { TwoPanelForm } from "../two-panel-form/TwoPanelForm";
import styles from "./DynamicGroupEditor.module.css";

const { Header, BackButton, Title, Content, Footer, SaveButton } = GroupEditor;
const { Heading, Details, LeftPanel, RightPanel } = TwoPanelForm;

const getGroupDto = (
  groupName: string,
  hierarchyType: HierarchyType,
  rules: HierarchyGroupRuleWithIdAndName[],
  id?: string
): HierarchyGroupDto => ({
  evaluationType: HierarchyGroupEvaluationType.Dynamic,
  hierarchyType,
  id,
  name: groupName,
  rules: rules.map((rule) => ({
    operator: rule.operator,
    shortName: rule.shortName,
    values: rule.values.map((value) => value.code),
  })),
});

type DynamicGroupProps = {
  existingGroup?: HierarchyGroupDto;
  hierarchyType: HierarchyType;
};

export const DynamicGroupEditor = ({
  existingGroup,
  hierarchyType,
}: DynamicGroupProps) => {
  const { name: divisionName } = useDivision();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const eventTrackingService = useEventTrackingServiceContext();
  const trackingComponent =
    hierarchyType === HierarchyType.Product
      ? TrackingComponent.ProductGroup
      : TrackingComponent.LocationGroup;
  const countTrackingProperty =
    hierarchyType === HierarchyType.Product
      ? GroupsTrackingProperty.ProductCount
      : GroupsTrackingProperty.LocationCount;

  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  const [isLeafItemsTableLoaded, setIsLeafItemsTableLoaded] = useState(false);
  const [leafItemsCount, setLeafItemsCount] = useState(0);

  const [groupRules, setGroupRules] = useState<
    HierarchyGroupRuleWithIdAndName[]
  >(
    existingGroup?.rules?.map((rule) => ({
      id: uniqueId("rule-"),
      operator: rule.operator,
      shortName: rule.shortName,
      fullShortName: "",
      values: rule.values.map((value) => ({
        code: value,
        name: undefined,
      })),
    })) ?? []
  );
  const [readOnlyRules, setReadOnlyRules] = useState(groupRules.slice(0, -1));
  const [editableRule, setEditableRule] = useState(groupRules.at(-1));

  const { data: hierarchyAttributes } = useHierarchyMetadataQuery(
    {
      division: divisionName,
      hierarchyType,
      getAllAttributes: false,
    },
    { skip: !divisionName }
  );

  const leafShortName = hierarchyAttributes?.find(
    (attribute) =>
      attribute.isLeaf &&
      attribute.structureName?.toUpperCase() === hierarchyType.toUpperCase()
  )?.shortName;

  const saveRequestHandler = useCallback(
    async (request: Promise<HierarchyGroupDto>, successMessage: string) => {
      let isSuccess = false;
      try {
        const groupDto = await request;

        if (!groupDto.id) {
          throw new Error("Created group ID cannot be undefined or empty.");
        }

        isSuccess = true;
        navigate(getGroupListPath(divisionName, hierarchyType, groupDto.id));
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<h5>{successMessage}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />
        );
      } finally {
        eventTrackingService.trackEvent(
          trackingComponent,
          TrackingEvent.Created,
          new GenericTrackingProperties({
            [countTrackingProperty]: leafItemsCount,
            [GroupsTrackingProperty.GroupStatus]: isSuccess
              ? "Success"
              : "Failure",
            [GroupsTrackingProperty.GroupType]:
              HierarchyGroupEvaluationType.Dynamic,
            [GroupsTrackingProperty.RulesCount]: groupRules.length,
          })
        );
      }
    },
    [
      navigate,
      divisionName,
      hierarchyType,
      eventTrackingService,
      trackingComponent,
      countTrackingProperty,
      leafItemsCount,
      groupRules.length,
    ]
  );

  const handleCreateGroup = useCallback(
    async (name: string) => {
      const group = getGroupDto(name, hierarchyType, groupRules);
      const request = createGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been created.");
    },
    [createGroup, divisionName, groupRules, hierarchyType, saveRequestHandler]
  );

  const handleEditGroup = useCallback(
    async (name: string) => {
      if (!existingGroup) {
        throw new Error("Tried to edit a group that doesn't exist!");
      }

      const group = getGroupDto(
        name,
        hierarchyType,
        groupRules,
        existingGroup.id
      );
      const request = updateGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been saved.");
      dispatch(reset({ hierarchyType }));
    },
    [
      dispatch,
      divisionName,
      existingGroup,
      groupRules,
      hierarchyType,
      saveRequestHandler,
      updateGroup,
    ]
  );

  useEffect(() => {
    setGroupRules(
      editableRule ? readOnlyRules.concat([editableRule]) : readOnlyRules
    );
  }, [readOnlyRules, editableRule]);

  return (
    <GroupEditor>
      <Header>
        <BackButton
          onClick={() => {
            dispatch(reset({ hierarchyType }));
          }}
          returnPath={
            existingGroup
              ? getGroupListPath(divisionName, hierarchyType, existingGroup.id)
              : getGroupCreatorPath(divisionName, hierarchyType)
          }
          showExitDialog
        />
        <Title
          icon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Dynamic}
              hierarchyType={hierarchyType}
            />
          }
          subtitle="Dynamic"
          title={`Create a ${formatHierarchyName(
            hierarchyType,
            false,
            false
          )} group`}
        />
      </Header>
      <Content>
        <TwoPanelForm>
          <Heading
            subText={`Add rules to define your ${formatHierarchyName(
              hierarchyType,
              false,
              false
            )} group.`}
            text="Add rules"
          />
          <Details>
            <Group>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>Group type</div>
                <div className={styles.detailContent}>
                  <span className={styles.detailIcon}>
                    <HierarchyGroupIcon
                      evaluationType={HierarchyGroupEvaluationType.Dynamic}
                    />
                  </span>
                  <span>Dynamic</span>
                </div>
              </Item>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>
                  <span>
                    Total {formatHierarchyName(hierarchyType, false, true)}
                  </span>
                </div>
                <div className={styles.detailContent}>
                  <span>{leafItemsCount}</span>
                </div>
              </Item>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>Created</div>
                <div className={styles.detailContent}>
                  {existingGroup
                    ? formatShortDate(existingGroup.createDateUtc)
                    : "-"}
                </div>
              </Item>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>Updated</div>
                <div className={styles.detailContent}>
                  {existingGroup
                    ? formatShortDate(existingGroup.updateDateUtc)
                    : "-"}
                </div>
              </Item>
            </Group>
          </Details>
          <LeftPanel>
            {!hierarchyAttributes && <Spinner />}
            {hierarchyAttributes && (
              <DynamicGroupRulesForm
                editableRule={editableRule}
                hierarchyAttributes={hierarchyAttributes}
                hierarchyType={hierarchyType}
                leafShortName={leafShortName ?? ""}
                readonlyRules={readOnlyRules}
                setEditableRule={setEditableRule}
                setReadOnlyRules={setReadOnlyRules}
              />
            )}
          </LeftPanel>
          <RightPanel>
            <DynamicGroupLeafsTable
              hierarchyType={hierarchyType}
              leafShortName={leafShortName ?? ""}
              rules={groupRules}
              setIsLeafItemsTableLoaded={setIsLeafItemsTableLoaded}
              setLeafItemsCount={setLeafItemsCount}
            />
          </RightPanel>
        </TwoPanelForm>
      </Content>
      <Footer>
        <SaveButton
          disabled={!isLeafItemsTableLoaded}
          groupTypeIcon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Dynamic}
              hierarchyType={hierarchyType}
            />
          }
          groupTypeName="Dynamic"
          handleCreateGroup={handleCreateGroup}
          handleEditGroup={existingGroup ? handleEditGroup : undefined}
          initialGroupName={existingGroup?.name}
        />
      </Footer>
    </GroupEditor>
  );
};
