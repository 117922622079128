import { ddLog } from "@quantium-enterprise/common-ui";
import {
  type TableRequest,
  type TableResponse,
} from "../models/basket-affinities-data-table-models";
import { basketAffinitiesApiSlice } from "./basket-affinities-api-slice";
import {
  onTableDataReceived,
  setFocalItemTableInitialised,
} from "./basket-affinities-slice";

export const basketAffinitiesDataTableApiSlice =
  basketAffinitiesApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTable: builder.query<
        TableResponse,
        { divisionName: string; payload: TableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          dispatch(setFocalItemTableInitialised(false));
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataReceived(data));
            dispatch(setFocalItemTableInitialised(true));
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error downloading basket affinities focal item data",
              undefined,
              "error",
              error
            );
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTable/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetTableQuery } = basketAffinitiesDataTableApiSlice;
