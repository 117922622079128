import classNames from "classnames";
import styles from "./GroupedItemsTooltip.module.css";

export enum SeriesTypes {
  Circle = "circle",
  Dash = "dash",
  Line = "line",
}

export type SeriesIconProps = {
  color?: string;
  seriesType?: SeriesTypes;
};

export const SeriesIcon = ({ color, seriesType }: SeriesIconProps) => (
  <svg
    className={classNames(styles.seriesIcon, {
      [styles.line]: seriesType === SeriesTypes.Line,
      [styles.circle]: seriesType === SeriesTypes.Circle,
      [styles.dash]: seriesType === SeriesTypes.Dash,
      [styles.default]: seriesType === undefined,
    })}
    style={{
      backgroundColor: color ?? undefined,
    }}
  >
    <line x1="0" x2="10" />
  </svg>
);

export type GroupItems = {
  items?: Array<{
    color?: string | undefined;
    name?: number | string | null;
    value?: string | null;
  }>;
  primaryHeader?: string;
  secondaryHeader?: string;
  seriesType?: SeriesTypes;
};

export type GroupedItemsTooltipProps = {
  groupedItems: GroupItems[];
  topAuxiliaryHeader?: number | string;
};

export const GroupedItemsTooltip = ({
  groupedItems,
  topAuxiliaryHeader,
}: GroupedItemsTooltipProps) => (
  <div className={styles.tooltipContainer}>
    {topAuxiliaryHeader && (
      <div className={styles.topAuxiliaryHeader}>{topAuxiliaryHeader}</div>
    )}

    <div className={styles.tooltipContent}>
      {groupedItems.map((group, index) => (
        <div
          className={styles.itemGroupContainer}
          // eslint-disable-next-line react/no-array-index-key
          key={`${group.primaryHeader}_${index}`}
        >
          {group.primaryHeader && (
            <div className={styles.primaryHeader}>{group.primaryHeader}</div>
          )}

          {group.secondaryHeader && (
            <div className={styles.secondaryHeader}>
              {group.secondaryHeader}
            </div>
          )}

          {group.items?.map((item) => (
            <div
              className={styles.itemContainer}
              key={`${item.name}_${item.value}`}
            >
              <div className={styles.leftContent}>
                <SeriesIcon color={item.color} seriesType={group.seriesType} />
              </div>
              <div className={styles.middleContent}>{item.name}</div>
              <div className={styles.rightContent}>{item.value}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);
