/* eslint-disable import/no-unassigned-import -- this is for the CSS imports ONLY */
/* eslint-disable import/extensions -- this is for the CSS imports ONLY */
import "@quantium-enterprise/qds-styles/dist/default.css";
import "./global.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./store";
import "./configure-datadog";

const container = document.querySelector("#root");
if (container) {
  const root = createRoot(container);
  const helmetContext = {};

  // Add a global fetch event listener to handle 401 responses
  const originalFetch = window.fetch;
  window.fetch = async (resource, config) => {
    const response = await originalFetch(resource, config);

    if (response.status === 401) {
      const responseHostName = new URL(response.url).hostname;
      const currentHostName = window.location.hostname;

      if (responseHostName === currentHostName) {
        // Refresh the page only if the request was for the same domain
        window.location.reload();
      }
    }

    return response;
  };

  root.render(
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter basename={new URL(document.baseURI).pathname}>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  );
}
