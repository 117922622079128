import {
  ddLog,
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { type SeriesLineOptions, type SeriesBubbleOptions } from "highcharts";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { getFocalItemSummary } from "../../common/utils/export-parameter-summary-utils";
import { type RootState } from "../../store";
import { ProductSubstitutabilityFeatureFlags } from "../constants/product-substitutability-feature-flags";
import {
  onProductUniquenessReportletResponseReceived,
  selectFocalItems,
  selectLocation,
  selectTime,
  selectTimePeriodLength,
  toggleUniquenessChartDataLabels,
} from "../services/product-substitutability-slice";
import { useLazyGetProductUniquenessReportletQuery } from "../services/product-uniqueness-reportlet-api-slice";
import styles from "./ProductUniquenessReportlet.module.css";
import { ProductUniquenessReportletBubbleChart } from "./ProductUniquenessReportletBubbleChart";
import { ProductUniquenessReportletFilterGroup } from "./ProductUniquenessReportletFilterGroup";
import { csvTransformation } from "./csvTransformation";

export type ProductUniquenessReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const ProductUniquenessReportlet = ({
  infoPanelSummary,
}: ProductUniquenessReportletProps) => {
  const dispatch = useDispatch();
  const { name: divisionName, locale, currency } = useDivision();
  const { id } = useParams();
  const chartContainerRef = useRef<HTMLElement>();
  const [uniquenessChartSeriesData, setUniquenessChartSeriesData] = useState<
    Array<SeriesBubbleOptions | SeriesLineOptions>
  >([]);

  const focalItems = useSelector(selectFocalItems);
  const location = useSelector(selectLocation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);

  const {
    isUniquenessReportletLoading,
    xAxisSelection,
    yAxisSelection,
    bubbleSizeSelection,
    legendSelection,
    metrics,
    showUniquenessChartDataLabels,
    reportName,
  } = useSelector((state: RootState) => ({
    isUniquenessReportletLoading:
      state.productSubstitutability.isUniquenessReportletLoading,
    xAxisSelection: state.productSubstitutability.xAxisSelection,
    yAxisSelection: state.productSubstitutability.yAxisSelection,
    bubbleSizeSelection: state.productSubstitutability.bubbleSizeSelection,
    legendSelection: state.productSubstitutability.legendSelection,
    metrics: state.productSubstitutability.focalItemTableMetrics,
    showUniquenessChartDataLabels:
      state.productSubstitutability.showUniquenessChartDataLabels,
    reportName: state.productSubstitutability.reportName,
  }));

  const [triggerQuery, { isSuccess }] =
    useLazyGetProductUniquenessReportletQuery();

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[ProductSubstitutabilityFeatureFlags.ReportExport] ?? false;

  const fetchData = useCallback(
    async (division: string, reportId: string) => {
      await triggerQuery({ division, reportId })
        .unwrap()
        .then((response) => {
          dispatch(onProductUniquenessReportletResponseReceived(response));
        });
    },
    [dispatch, triggerQuery]
  );

  const updateChartSeriesData = useCallback(
    (data: Array<SeriesBubbleOptions | SeriesLineOptions>) => {
      setUniquenessChartSeriesData(data);
    },
    []
  );

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const parametersSummary = useMemo(
    () => [
      { name: "Focal Items", value: getFocalItemSummary(focalItems) },
      { name: "Time", value: time },
      { name: "Location", value: location },
      { name: "X-Axis", value: xAxisSelection.label },
      { name: "Y-Axis", value: yAxisSelection.label },
      { name: "Bubble size", value: bubbleSizeSelection.label },
      { name: "Legend", value: legendSelection.label },
    ],
    [
      focalItems,
      time,
      location,
      xAxisSelection,
      yAxisSelection,
      bubbleSizeSelection,
      legendSelection,
    ]
  );

  const fileName = useMemo(
    () =>
      cleanFilename(
        `Uniqueness_Chart_${timePeriodLength}_${location.split(" ")[1]}`
      ),
    [timePeriodLength, location]
  );

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        uniquenessChartSeriesData,
        metrics,
        currencySymbol,
        xAxisSelection,
        yAxisSelection,
        legendSelection,
        bubbleSizeSelection
      ),
    [
      uniquenessChartSeriesData,
      metrics,
      currencySymbol,
      xAxisSelection,
      yAxisSelection,
      legendSelection,
      bubbleSizeSelection,
    ]
  );

  useEffect(() => {
    if (divisionName && id) {
      fetchData(divisionName, id).catch((error) => {
        ddLog(
          "Error retrieving product uniqueness reportlet chart data",
          {},
          "error",
          error
        );
      });
    }
  }, [divisionName, fetchData, id]);

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showUniquenessChartDataLabels,
      value: "",
    },
  ];

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  return (
    <ReportletAccordion
      subtitle="Understand the uniqueness of your product in relation to other products."
      title="Uniqueness"
    >
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={isUniquenessReportletLoading}
          noData={isSuccess && focalItems.length === 0}
        >
          <ProductUniquenessReportletFilterGroup />
          <div className={styles.chartOptionsContainer}>
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.ProductSubstitutability} />
                      ),
                      chartTitle: `Uniqueness - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={fileName}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parametersSummary}
              reportParameters={infoPanelSummary}
              toggleDataLabels={() => {
                dispatch(toggleUniquenessChartDataLabels());
              }}
            />
          </div>
          <ChartFooterWrapper
            height="390px"
            parameters={parametersSummary}
            ref={chartContainerRef}
          >
            <ProductUniquenessReportletBubbleChart
              onChartSeriesUpdate={updateChartSeriesData}
              onOptionsChanged={setCurrentOptions}
            />
          </ChartFooterWrapper>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
