import classnames from "classnames";
import styles from "./GainsAndLossMiniChartTooltip.module.css";

type GainsAndLossMiniChartTooltipProps = {
  color: string | null;
  primaryHeader: number | string;
  secondaryHeader?: number | string;
  value: string | null;
};

export const GainsAndLossMiniChartTooltip = ({
  primaryHeader,
  value,
  color,
  secondaryHeader,
}: GainsAndLossMiniChartTooltipProps) => (
  <div className={styles.chartTooltip}>
    <div
      className={classnames(styles.textContainer, styles.primaryHeader)}
      data-testid="tooltip-primary-header"
    >
      <div className={styles.primaryHeaderText}>
        <div
          className={styles.circle}
          style={{ backgroundColor: color ?? undefined }}
        />
        {primaryHeader}
      </div>
      {!secondaryHeader && (
        <>
          <div className={styles.spacer} />
          <div className={styles.rightText}>{value}</div>
        </>
      )}
    </div>
    {secondaryHeader && (
      <div
        className={classnames(styles.textContainer, styles.secondaryHeader)}
        data-testid="tooltip-secondary-header"
        key={secondaryHeader}
      >
        <div className={styles.leftText}>{secondaryHeader}</div>
        <div className={styles.spacer} />
        <div className={styles.rightText}>{value}</div>
      </div>
    )}
  </div>
);
