import classNames from "classnames";
import { Fragment } from "react";
import styles from "./DriverTree.module.scss";
import { type HiddenDriverTreeNodeProps } from "./DriverTreeNode";

export type DriverTreeType = {
  children?: DriverTreeType[];
  // internal use
  // help modify tree structure to remove redundant whitespace and allow subtrees to overlap
  isHidden?: boolean;
  isLeftMostEdge?: boolean;
  isRightMostEdge?: boolean;
  nodes: Array<{
    connectorStyles?: {
      child?: "dashed" | "dotted" | "hidden";
      parent?: "dashed" | "dotted" | "hidden";
    };
    element: JSX.Element;
  }>;
};

type DriverTreeProps = {
  // internal use
  // generate a key
  branch?: string;
  createHiddenNode: (properties: HiddenDriverTreeNodeProps) => JSX.Element;
  root?: boolean;
  tree: DriverTreeType;
};

export const DriverTree = ({
  tree,
  root = false,
  createHiddenNode,
  branch = "0",
}: DriverTreeProps) => {
  let childElements: JSX.Element[] = [];
  let driverTreeClassSuffix = "";
  let childrenHaveCombinedTree = false;
  if (root) {
    driverTreeClassSuffix = styles.topNode;
  }

  if (tree.children) {
    const childrenWithChildren = tree.children.filter(
      (child) => child.children
    ).length;
    const maxGrandChildCount = Math.max(
      ...tree.children.map((child) => child.children?.length ?? 0)
    );
    if (
      childrenWithChildren === 0 ||
      childrenWithChildren === tree.children.length ||
      maxGrandChildCount === 1
    ) {
      childElements = tree.children.map((child, index) => {
        const branchKey = `${branch}${index}`;
        return (
          <DriverTree
            branch={branchKey}
            createHiddenNode={createHiddenNode}
            key={`driver-tree-${branchKey}`}
            tree={child}
          />
        );
      });
    } else {
      const grandChildren = tree.children.flatMap(
        (child) =>
          child.children ?? [
            {
              isHidden: true,
              nodes: [
                {
                  element: createHiddenNode({
                    key: `driver-tree-hidden-node-${branch}`,
                  }),
                  connectorStyles: { child: "hidden", parent: "hidden" },
                },
              ],
            } as DriverTreeType,
          ]
      );
      for (const [index, child] of grandChildren.entries()) {
        if (child.isHidden) {
          if (index > 0) {
            grandChildren[index - 1].isRightMostEdge = true;
          } else if (index < grandChildren.length - 1) {
            grandChildren[index + 1].isLeftMostEdge = true;
          }
        }
      }

      const combinedTree: DriverTreeType = {
        children: grandChildren,
        nodes: tree.children.flatMap((child) => child.nodes),
      };
      const branchKey = `${branch}${0}`;
      childElements = [
        <DriverTree
          branch={branchKey}
          createHiddenNode={createHiddenNode}
          key={`driver-tree-${branchKey}`}
          tree={combinedTree}
        />,
      ];
      driverTreeClassSuffix = styles.combinedTreeNodes;
      childrenHaveCombinedTree = true;
    }
  } else {
    driverTreeClassSuffix = styles.leafNode;
  }

  const treeParentLinkAdditionalClassName = tree.isLeftMostEdge
    ? styles.leftMostEdge
    : tree.isRightMostEdge
    ? styles.rightMostEdge
    : "";

  return (
    <div
      className={classNames(styles.driverTreeSection, driverTreeClassSuffix)}
    >
      <div
        className={classNames(
          styles.parentLink,
          treeParentLinkAdditionalClassName
        )}
      >
        {tree.nodes.map((node, index) => {
          // HACK: `:first-of-type` and `:last-of-type` was not working.
          // Use explicit classes to work around this.
          // Ideally should be using inbuilt CSS pseudo-classes.
          let additionalClassName = "";
          if (treeParentLinkAdditionalClassName) {
            additionalClassName = treeParentLinkAdditionalClassName;
          } else if (tree.nodes.length > 1) {
            if (index === 0) {
              additionalClassName = styles.leftMostEdge;
            } else if (index === tree.nodes.length - 1) {
              additionalClassName = styles.rightMostEdge;
            }
          }

          const keyPart = `${branch}${index}`;

          const parentStyle = node.connectorStyles?.parent ?? "solid";
          return (
            <Fragment key={`top-connectors-${keyPart}`}>
              <div
                className={styles.leftEdge}
                style={{ borderTopStyle: parentStyle }}
              />
              <div
                className={classNames(styles.topConnector, additionalClassName)}
              >
                {parentStyle !== "hidden" && (
                  <div className={styles.connectors}>
                    <div className={styles.leftConnector} />
                    <div className={styles.rightConnector} />
                  </div>
                )}
                <div
                  className={styles.topEdge}
                  style={{ borderLeftStyle: parentStyle }}
                />
              </div>
              <div
                className={styles.rightEdge}
                style={{ borderTopStyle: parentStyle }}
              />
            </Fragment>
          );
        })}
      </div>
      <div className={styles.content}>
        {tree.nodes.map((node) => node.element)}
      </div>
      <div className={styles.childLink}>
        {tree.nodes.map((node, index) => {
          const childStyle = node.connectorStyles?.child ?? "solid";
          const keyPart = `${branch}${index}`;
          return (
            <div
              className={styles.bottomConnectors}
              key={`bottom-connectors-${keyPart}`}
            >
              <div
                className={classNames(styles.bottomEdge, {
                  [styles.extraLong]: childElements.length,
                })}
                style={{ borderLeftStyle: childStyle }}
              />
              <div className={styles.junctions}>
                {childStyle !== "hidden" &&
                  (childElements.length > 1 || childrenHaveCombinedTree) && (
                    <>
                      <div className={styles.leftJunction} />
                      <div className={styles.rightJunction} />
                    </>
                  )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.childrenSection}>{childElements}</div>
    </div>
  );
};

export default DriverTree;
