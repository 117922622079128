export const getViewAsShare = (viewAs: string) => viewAs === "1";
export const getViewAsMetricLabel = (
  primaryMetric: string,
  viewAsShare: boolean
) => {
  switch (primaryMetric) {
    case "Baskets":
    case "Sales":
    case "Units":
      return viewAsShare ? `${primaryMetric} (%)` : primaryMetric;
    default:
      return primaryMetric;
  }
};

export const getViewAsMetricKey = (
  primaryMetricLabel: string,
  breakdown: string,
  segmentation: string
) => {
  if (!primaryMetricLabel.includes("(%)")) {
    return primaryMetricLabel;
  }

  switch (breakdown) {
    // segmentation
    case "1":
      return `${primaryMetricLabel}-${breakdown}-${segmentation}`;
    default:
      return `${primaryMetricLabel}-${breakdown}`;
  }
};
