import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { StructurePreview } from "../../../../structure-preview/StructurePreview";
import { getFullHierarchyForLocation } from "../../common/utils";
import styles from "./MultiLocationHierarchyFilterSummary.module.css";

export type MultiLocationHierarchyFilterSummaryProps = {
  allSelections: LocalHierarchyNodeSelection[];
  isCollapsed: boolean;
  selections: LocalHierarchyNodeSelection[];
};

export const MultiLocationHierarchyFilterSummary = ({
  selections,
  isCollapsed,
  allSelections,
}: MultiLocationHierarchyFilterSummaryProps) => {
  const renderSingleSelect = (collapsed: boolean) => {
    const fullHierarchyForSelectedValue = getFullHierarchyForLocation(
      selections[0],
      allSelections
    );
    const rootParent =
      fullHierarchyForSelectedValue[fullHierarchyForSelectedValue.length - 1];
    const displayHierarchy =
      rootParent.isBenchmark &&
      rootParent.shortName === "DIV" &&
      rootParent.nodeNumber !== selections[0].nodeNumber
        ? fullHierarchyForSelectedValue.slice(0, -1)
        : fullHierarchyForSelectedValue;

    return collapsed ? (
      <StructurePreview
        items={[
          {
            label: selections[0].name,
            shortName: selections[0].shortName,
            type: HierarchyItemType.Hierarchy,
          },
        ]}
        maxDepth={1}
        moreItemsText=""
      />
    ) : (
      <StructurePreview
        items={[...displayHierarchy]
          .map((item) => ({
            label: item.name,
            shortName: item.shortName,
            itemCode: item.code,
            type: HierarchyItemType.Hierarchy,
          }))
          .reverse()}
        maxDepth={displayHierarchy.length}
        moreItemsText=""
      />
    );
  };

  return (
    <>
      {selections.length === 1 ? (
        renderSingleSelect(isCollapsed)
      ) : (
        <div className={styles.summaryContainer}>
          <div className={styles.itemCount}>{selections.length}</div> locations
          selected
        </div>
      )}
    </>
  );
};

export default MultiLocationHierarchyFilterSummary;
