import { ColorRankType } from "../models/ColorRankType";

export const getHeatMapColor = (
  colorRankMethod: ColorRankType,
  value: number | null,
  colorRange: string[],
  sortedValues: number[]
) => {
  let rank = 0;

  if (value === null) {
    return "auto";
  }

  if (colorRankMethod === ColorRankType.PERCENTILE) {
    let count = 0;
    for (const comparison of sortedValues) {
      if (comparison >= value) {
        break;
      }

      count++;
    }

    rank = count / sortedValues.length;
  } else {
    const [minValue, maxValue] = [
      sortedValues[0],
      sortedValues[sortedValues.length - 1],
    ];
    rank = (value - minValue) / (maxValue - minValue);
  }

  const colorIndex = Math.max(Math.round(colorRange.length * rank) - 1, 0);
  return colorRange[colorIndex];
};
