import { type LocalParametersResponseDto } from "../models/LocalParametersResponseDto";
import { keyMeasureTrendsApiSlice } from "./key-measure-trend-api-slice";
import { onLocalParametersReceived } from "./key-measure-trends-slice";

export const keyMeasureTrendsLocalParametersApiSlice =
  keyMeasureTrendsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        LocalParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onLocalParametersReceived(data));
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error retrieving local parameters");
          }
        },
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetLocalParametersQuery } =
  keyMeasureTrendsLocalParametersApiSlice;
