import {
  type FocalTableBenchmarksDataRequest,
  type FocalTableProductsDataRequest,
  type FocalTableBenchmarksDataResponse,
  type FocalTableProductsDataResponse,
} from "../models/new-product-benchmarking-data-table-models";
import { newProductBenchmarkingApiSlice } from "./new-product-benchmarking-api-slice";

export const NewProductBenchmarkingDataTableApiSlice =
  newProductBenchmarkingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getBenchmarkRows: builder.query<
        FocalTableBenchmarksDataResponse,
        { divisionName: string; payload: FocalTableBenchmarksDataRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetFocalTableBenchmarksData/${divisionName}`,
        }),
      }),
      getProductRows: builder.query<
        FocalTableProductsDataResponse,
        { divisionName: string; payload: FocalTableProductsDataRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetFocalTableProductsData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useLazyGetBenchmarkRowsQuery, useLazyGetProductRowsQuery } =
  NewProductBenchmarkingDataTableApiSlice;
