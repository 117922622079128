import {
  type ReportParametersDto,
  ParameterId,
  ddLog,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { type RootState } from "../../../store";
import { CustomerProfilingFeatureFlags } from "../../constants/customer-profiling-feature-flags";
import { type CustomerProfilingRequestDto } from "../../models/CustomerProfilingRequestDto";
import { useLazyGetCustomerProfilingMultiSegmentationQuery } from "../../services/customer-profiling-multi-segmentation-api-slice";
import { useLazyGetCustomerProfilingSingleSegmentationQuery } from "../../services/customer-profiling-single-segmentation-api-slice";
import {
  onCustomerProfilingMultiSegmentationRequest,
  onCustomerProfilingMultiSegmentationResponseReceived,
  onCustomerProfilingSingleSegmentationRequest,
  onCustomerProfilingSingleSegmentationResponseReceived,
  onEmptySegmentationOrFocalItemsProfiling,
  selectChannel,
  selectFocalItems,
  selectLocation,
  selectMetric,
  selectPromotion,
  selectReportId,
  selectSegmentation,
} from "../../services/customer-profiling-slice";
import { convertPanelOptionToString } from "../TopDrawerProductTable/utils";
import { CustomerProfilingMultiSegmentationChart } from "./CustomerProfilingMultiSegmentationChart";
import { CustomerProfilingSingleSegmentationChart } from "./CustomerProfilingSingleSegmentationChart";

export const CustomerProfilingReportlet = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();
  const { id } = useParams();

  const reportId = useSelector(selectReportId);
  const focalItems = useSelector(selectFocalItems);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const metric = useSelector(selectMetric);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);

  const { isLoadingMulti, isLoadingSingle, localParameters } = useSelector(
    (state: RootState) => ({
      isLoadingMulti:
        state.customerProfiling.isCustomerProfilingMultiSegmentationLoading,
      isLoadingSingle:
        state.customerProfiling.isCustomerProfilingSingleSegmentationLoading,
      localParameters: state.customerProfiling.localParameters,
    })
  );

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CustomerProfilingFeatureFlags.ReportExport] ?? false;

  const [
    getSingleSegmentationData,
    {
      isError: isErrorSingle,
      isFetching: isFetchingSingle,
      isSuccess: isSuccessSingle,
      data: singleSegmentationData,
    },
  ] = useLazyGetCustomerProfilingSingleSegmentationQuery();

  const fetchSingleSegmentationData = useCallback(
    async (division: string, payload: CustomerProfilingRequestDto) => {
      await getSingleSegmentationData({ division, payload });
    },
    [getSingleSegmentationData]
  );

  const [
    getMultiSegmentationData,
    {
      isError: isErrorMulti,
      isFetching: isFetchingMulti,
      isSuccess: isSuccessMulti,
      data: multiSegmentationData,
    },
  ] = useLazyGetCustomerProfilingMultiSegmentationQuery();

  const fetchMultiSegmentationData = useCallback(
    async (division: string, payload: CustomerProfilingRequestDto) => {
      await getMultiSegmentationData({ division, payload });
    },
    [getMultiSegmentationData]
  );

  useEffect(() => {
    if (
      id &&
      reportId === id &&
      focalItems.length > 0 &&
      segmentation.length > 0
    ) {
      const payload: CustomerProfilingRequestDto = {
        localSelectedValues: [
          { id: ParameterId.Channel, values: [channel.value as string] },
          {
            id: ParameterId.LocationHierarchy,
            values: [location.nodeNumber.toString()],
          },
          { id: ParameterId.Promotion, values: [promotion.value as string] },
          {
            id: ParameterId.Segmentation,
            values: convertPanelOptionToString(segmentation),
          },
        ],
        reportId: id,
        focalItems,
      };

      if (segmentation.length === 1) {
        dispatch(onCustomerProfilingSingleSegmentationRequest());
        fetchSingleSegmentationData(divisionName, payload).catch((error) => {
          ddLog(
            "Error retrieving customer profiling single segmentation data",
            {},
            "error",
            error
          );
        });
      } else {
        dispatch(onCustomerProfilingMultiSegmentationRequest());
        fetchMultiSegmentationData(divisionName, payload).catch((error) => {
          ddLog(
            "Error retrieving customer profiling multi segmentation data",
            {},
            "error",
            error
          );
        });
      }
    } else {
      dispatch(onEmptySegmentationOrFocalItemsProfiling());
    }
  }, [
    channel,
    dispatch,
    divisionName,
    fetchMultiSegmentationData,
    fetchSingleSegmentationData,
    focalItems,
    id,
    localParameters,
    location,
    promotion,
    reportId,
    segmentation,
  ]);

  useEffect(() => {
    if (
      isSuccessSingle &&
      !isFetchingSingle &&
      focalItems.length > 0 &&
      segmentation.length === 1
    ) {
      dispatch(
        onCustomerProfilingSingleSegmentationResponseReceived({
          singleSegmentationData,
          metric,
        })
      );
    } else if (isErrorSingle) {
      dispatch(
        onCustomerProfilingSingleSegmentationResponseReceived({
          singleSegmentationData: undefined,
          metric,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isErrorSingle,
    isFetchingSingle,
    isSuccessSingle,
    metric,
    singleSegmentationData,
  ]);

  useEffect(() => {
    if (
      isSuccessMulti &&
      !isFetchingMulti &&
      multiSegmentationData &&
      focalItems.length > 0 &&
      segmentation.length === 2
    ) {
      dispatch(
        onCustomerProfilingMultiSegmentationResponseReceived({
          multiSegmentationData,
          metric,
        })
      );
    } else if (isErrorMulti) {
      dispatch(
        onCustomerProfilingMultiSegmentationResponseReceived({
          multiSegmentationData: [{ itemName: "", metricValues: [] }],
          metric,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isErrorMulti,
    isFetchingMulti,
    isSuccessMulti,
    metric,
    multiSegmentationData,
  ]);

  return (
    <ReportletAccordion
      subtitle="See which customers your selected items resonate with most."
      title="Customer profiling"
    >
      {segmentation.length <= 1 ? (
        <ErrorBoundary>
          <NoDataChartWrapper isLoading={isLoadingSingle} noData={false}>
            <CustomerProfilingSingleSegmentationChart
              isExportEnabled={isExportEnabled}
              reportParameters={reportParameters}
            />
          </NoDataChartWrapper>
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <NoDataChartWrapper isLoading={isLoadingMulti} noData={false}>
            <CustomerProfilingMultiSegmentationChart
              isExportEnabled={isExportEnabled}
              reportParameters={reportParameters}
            />
          </NoDataChartWrapper>
        </ErrorBoundary>
      )}
    </ReportletAccordion>
  );
};

export default CustomerProfilingReportlet;
