type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};
export const DynamicProductGroupIcon = ({
  className,
  coloured = true,
  text,
}: Props) => {
  const boxColour = coloured
    ? "var(--marker-boxer-colour)"
    : "var(--icon-colour-two-disabled)";
  const loopColour = coloured
    ? "var(--dynamic-colour)"
    : "var(--group-colour-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 96 64"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Dynamic product group"}</title>
      <path
        d="M10.038 22.8409C10.038 22.8564 10.025 22.8718 10.025 22.9029C10.011 22.9636 10.002 23.0261 10 23.0892V36.2219C9.99704 36.5549 10.171 36.8516 10.429 36.9515L21.76 41.5935L21.798 41.6089C21.928 41.6712 22.072 41.6712 22.202 41.6089C22.216 41.6111 22.23 41.6054 22.24 41.5935L33.57 36.9515C33.829 36.8516 34.003 36.5549 34 36.2219V23.0892C33.999 23.0158 33.99 22.9427 33.975 22.8718C33.955 22.7781 33.916 22.6926 33.861 22.6236C33.841 22.5556 33.8 22.4998 33.747 22.4682C33.747 22.4598 33.735 22.4527 33.735 22.4527L33.659 22.4062C33.634 22.375 33.596 22.375 33.57 22.3596L33.507 22.3285L22.278 17.7333L22.202 17.7022C22.142 17.6716 22.078 17.6559 22.013 17.6556H21.987C21.922 17.6559 21.858 17.6713 21.798 17.7019L21.722 17.733L10.505 22.3284H10.493L10.43 22.3596C10.412 22.3588 10.394 22.3642 10.379 22.375C10.366 22.375 10.354 22.3907 10.341 22.4061C10.308 22.4172 10.277 22.4389 10.253 22.4684C10.24 22.4684 10.24 22.4684 10.24 22.4838C10.191 22.5139 10.151 22.5629 10.126 22.6235C10.126 22.6235 10.114 22.6303 10.114 22.639C10.086 22.6996 10.061 22.7616 10.038 22.8252V22.8409Z"
        fill={boxColour}
      />
      <path
        d="M13 24.6556L22 28.1556M22 28.1556V38.6556M22 28.1556L31 24.6556"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M76.875 17.777C73.311 14.2125 68.419 12 62.986 12C52.12 12 43.344 20.8008 43.344 31.6666C43.344 42.5325 52.12 51.3333 62.986 51.3333C72.155 51.3333 79.801 45.0645 81.989 36.5833H76.875C74.86 42.3112 69.402 46.4166 62.986 46.4166C54.849 46.4166 48.236 39.8037 48.236 31.6666C48.236 23.5295 54.849 16.9166 62.986 16.9166C67.067 16.9166 70.705 18.6129 73.36 21.2925L65.444 29.2083H82.653V12L76.875 17.777Z"
        fill={loopColour}
      />
    </svg>
  );
};
