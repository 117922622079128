import { ddLog } from "@quantium-enterprise/common-ui";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import {
  type ExportFocalItemRequest,
  type ReportletRequestDto,
  type ReportletResponseDto,
} from "../models/basket-limits-reportlet-models";
import { basketLimitsApiSlice } from "./basket-limits-api-slice";
import { onReportletDataReceived } from "./basket-limits-slice";

export const basketLimitsReportletApiSlice =
  basketLimitsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getReportletData: builder.query<
        ReportletResponseDto,
        { divisionName: string; payload: ReportletRequestDto }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onReportletDataReceived(data));
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error downloading basket limits reportlet data",
              undefined,
              "error",
              error
            );
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportletData/${divisionName}`,
        }),
      }),
      downloadStoreBreakdownData: builder.query<
        Blob,
        { divisionName: string; payload: ExportFocalItemRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `basket limits store breakdown csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const {
  useLazyGetReportletDataQuery,
  useLazyDownloadStoreBreakdownDataQuery,
} = basketLimitsReportletApiSlice;
