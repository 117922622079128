import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { addDays } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  comparisonPeriodSelected,
  endDateSelected,
  startDateSelected,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { getTimePeriodState } from "./TimePeriodState";
import { TimePeriod } from "./components/TimePeriod";
import { getMaxDate, getMinDate } from "./components/utilities";
import {
  timestampToDate,
  DAYS_IN_WEEK,
  convertParametersToCustomPeriodOptions,
  getMinCustomPeriodStartDate,
} from "./utilities";

type ComparisonPeriodParameterProps = {
  parameterDto: ParameterDto;
};

export const ComparisonPeriodParameter = ({
  parameterDto,
}: ComparisonPeriodParameterProps) => {
  // React Store Hooks
  const dispatch = useDispatch();

  const comparisonPeriodState = useAppSelector(
    getTimePeriodState(parameterDto.id)
  );
  const focusPeriodState = useAppSelector(
    getTimePeriodState(ParameterId.FocusPeriod)
  );
  const { wizard } = useSelector((state: RootState) => ({
    wizard: state.wizard,
  }));

  if (!comparisonPeriodState || !focusPeriodState) {
    return null;
  }

  const startDate = timestampToDate(comparisonPeriodState.startDate);
  const endDate = timestampToDate(comparisonPeriodState.endDate);
  const focusStartDate = timestampToDate(focusPeriodState.startDate);
  const isTabVisited = wizard.visitedTabs[comparisonPeriodState.parameterGroup];

  const customTimePeriodOptions = convertParametersToCustomPeriodOptions(
    parameterDto.options
  );

  const minStartDateOption = timestampToDate(
    getMinCustomPeriodStartDate(parameterDto)
  );

  let maxStartDate;
  let maxEndDate;
  let minEndDate;
  let isDisabled = false;

  if (minStartDateOption && focusStartDate && focusPeriodState.weeks) {
    maxEndDate = addDays(focusStartDate, -1);

    maxStartDate = addDays(
      focusStartDate,
      -focusPeriodState.weeks * DAYS_IN_WEEK
    );
    maxStartDate = getMinDate(maxStartDate, endDate);

    minEndDate = addDays(
      minStartDateOption,
      focusPeriodState.weeks * DAYS_IN_WEEK - 1
    );
    minEndDate = getMaxDate(minEndDate, startDate);
  } else {
    isDisabled = true;
  }

  const timePeriodOnChangeHandler = (newTimePeriod: string) => {
    dispatch(
      comparisonPeriodSelected({
        parameter: parameterDto.id,
        timePeriodValue: newTimePeriod,
      })
    );
  };

  const startDateHandler = (newStartDate: string) => {
    dispatch(
      startDateSelected({
        parameter: parameterDto.id,
        startDate: newStartDate,
      })
    );
  };

  const endDateHandler = (newEndDate: string) => {
    dispatch(
      endDateSelected({
        endDate: newEndDate,
        parameter: parameterDto.id,
      })
    );
  };

  return (
    <TimePeriod
      customTimePeriodOptions={customTimePeriodOptions}
      endDate={endDate}
      endDateHandler={endDateHandler}
      errors={comparisonPeriodState.errors}
      infoIcon={parameterDto.description}
      isDisabled={isDisabled}
      isVisited={isTabVisited}
      label={parameterDto.name}
      maxEndDate={maxEndDate}
      maxStartDate={maxStartDate}
      minEndDate={minEndDate}
      minStartDate={undefined}
      onChange={timePeriodOnChangeHandler}
      options={comparisonPeriodState.options}
      startDate={startDate}
      startDateHandler={startDateHandler}
      timePeriod={comparisonPeriodState.value}
      weeks={comparisonPeriodState.weeks}
    />
  );
};
