import { type KeyDriverTreeTableRow } from "../../models/key-driver-tree-table-models";

// Recursive function to create nested rows
const createNode = (
  row: KeyDriverTreeTableRow,
  visited: KeyDriverTreeTableRow[],
  rootNodes: KeyDriverTreeTableRow[],
  tableData: KeyDriverTreeTableRow[],
  flatList: boolean
) => {
  // If node has already been visited ignore
  if (visited[row.hierarchyItem.nodeNumber]) {
    return;
  }

  // Mark that we have visited
  visited[row.hierarchyItem.nodeNumber] = row;

  const parentNodeNumber = row.hierarchyItem.parentNodeNumber;
  // If row does not have a parent then it is a root node
  // Append this to the list of root nodes
  if (parentNodeNumber === undefined || flatList) {
    rootNodes.push(row);
    return;
  }

  // Otherwise it is a child node do the following:
  // Recursively create parent if parent has not been visited
  if (!visited[parentNodeNumber]) {
    const parentRow = tableData.find(
      (rw) => rw.hierarchyItem.nodeNumber === parentNodeNumber
    );
    if (parentRow) {
      createNode(
        {
          hierarchyItem: parentRow.hierarchyItem,
          measures: parentRow.measures,
          subRows: [],
        },
        visited,
        rootNodes,
        tableData,
        flatList
      );
    }
  }

  visited[parentNodeNumber].subRows.push(row);
};

// Function that takes a flat list of KeyDriverTreeTableRows and converts
// it into a nested array of root nodes  with subRows
export const buildNestedRows = (
  rows: KeyDriverTreeTableRow[],
  flatList: boolean = false
) => {
  const visitedNodes: KeyDriverTreeTableRow[] = [];
  const rootNodes: KeyDriverTreeTableRow[] = [];

  for (const rw of rows) {
    createNode(
      { hierarchyItem: rw.hierarchyItem, measures: rw.measures, subRows: [] },
      visitedNodes,
      rootNodes,
      rows,
      flatList
    );
  }

  return rootNodes;
};

// Function that finds a node given an array of root nodes
export const findNode = (rows: KeyDriverTreeTableRow[], nodeNumber: number) => {
  let nodeStack: KeyDriverTreeTableRow[] = [...rows];

  while (nodeStack.length > 0) {
    // take top item from stack
    const currentRow = nodeStack.pop();

    // if there are no more items then we failed to find
    if (currentRow === undefined) {
      return null;
    }

    // if it is the row return
    if (currentRow.hierarchyItem.nodeNumber === nodeNumber) {
      return currentRow;
    }

    // otherwise add the subrow to the stack to check
    // for (const subRow of currentRow.subRows) {
    //   nodeStack.push(subRow);
    // }

    nodeStack = nodeStack.concat(currentRow.subRows);
  }

  return null;
};

// Function that finds all the parents for flat-list of rows
// used to determine which nodes are expanded on table load
export const getExpandedRows = (rows: KeyDriverTreeTableRow[]) => {
  const parents = new Set<number>();
  for (const row of rows) {
    if (row.hierarchyItem.parentNodeNumber !== undefined) {
      parents.add(row.hierarchyItem.parentNodeNumber);
    }
  }

  return Array.from(parents);
};

// Function to find all descendants of a given row
export const getDescendantRows = (row: KeyDriverTreeTableRow): number[] => {
  const descendants: number[] = [];
  const nodeStack: KeyDriverTreeTableRow[] = [];

  for (const node of row.subRows) {
    nodeStack.push(node);
  }

  while (nodeStack.length > 0) {
    const currentRow = nodeStack.pop();

    if (currentRow === undefined) {
      return descendants;
    }

    descendants.push(currentRow.hierarchyItem.nodeNumber);
    for (const subRow of currentRow.subRows) {
      nodeStack.push(subRow);
    }
  }

  return descendants;
};
