import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { SidePanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isTimePeriodParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isLocationSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildSegmentationPanel,
  buildTimePeriodPanel,
  buildPlainTextContentPanel,
  buildSingleSelectPanel,
  buildLocationHierarchyPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import {
  onSegmentationChange,
  onMetricChange,
  onChannelChange,
  onPromotionChange,
  onLocationChange,
  selectLocalSelections,
  selectLocalParameters,
} from "../services/trial-and-repeat-slice";

export const TrialAndRepeatSidePanel = () => {
  const dispatch = useDispatch();
  const localSelections = useSelector(selectLocalSelections);
  const localParameters = useSelector(selectLocalParameters);

  const generatedPanels = useCallback((): Panel[] => {
    const panels: Panel[] = [];

    for (const localParameter of localParameters) {
      if (isTimePeriodParameter(localParameter)) {
        panels.push(buildTimePeriodPanel(localParameter));
      } else if (
        localParameter.id === LocalParameters.Metric &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onMetricSelection = (value: PanelOption) => {
          dispatch(onMetricChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onMetricSelection,
            localSelections.metric.value as string
          )
        );
      } else if (
        localParameter.id === LocalParameters.Segmentation &&
        isSegmentationParameter(localParameter)
      ) {
        const onSegmentSelection = (value: SegmentOption) => {
          dispatch(
            onSegmentationChange([
              {
                value: value.segmentationValue,
                label: value.segmentationLabel,
              },
              { value: value.segmentValue, label: value.segmentLabel },
            ])
          );
        };

        panels.push(
          buildSegmentationPanel(
            localParameter,
            onSegmentSelection,
            localSelections.segmentation.map((index) => index.value as string)
          )
        );
      } else if (
        localParameter.id === LocalParameters.Segmentation &&
        isPlainTextContentParameter(localParameter)
      ) {
        panels.push(buildPlainTextContentPanel(localParameter));
      } else if (
        localParameter.id === LocalParameters.Channel &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onChannelSelection = (value: PanelOption) => {
          dispatch(onChannelChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onChannelSelection,
            localSelections.channel.value as string
          )
        );
      } else if (
        localParameter.id === LocalParameters.Promotion &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onPromotionSelection = (value: PanelOption) => {
          dispatch(onPromotionChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onPromotionSelection,
            localSelections.promotion.value as string
          )
        );
      } else if (
        localParameter.id === LocalParameters.LocationHierarchy &&
        isLocationSelectionParameter(localParameter)
      ) {
        panels.push(
          buildLocationHierarchyPanel(
            localParameter,
            (selection: LocalHierarchyNodeSelection) => {
              dispatch(onLocationChange(selection));
            },
            localSelections.LocationHierarchy
          )
        );
      }
    }

    return panels;
  }, [dispatch, localParameters, localSelections]);

  return <SidePanel panels={generatedPanels()} />;
};
