import {
  getBaseQuery,
  type HierarchySliceNodeDto,
} from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

type HierarchySliceLevel = {
  codeColumn?: string;
  depth: number;
  nameColumn: string;
  nodeNumberColumn: string;
  shortName: string;
  type: string;
};

export type HierarchySliceResponse = {
  ancestors: HierarchySliceNodeDto[];
  hasMoreNodes: boolean;
  hypercubeId: string;
  lastNodeNumber: number;
  levels: HierarchySliceLevel[];
  nodeNumberColumn: string;
  nodes: HierarchySliceNodeDto[];
};

export const hierarchySliceApi = createApi({
  baseQuery: getBaseQuery(`/api/hierarchy-slice/HierarchySlice/`),
  endpoints: (builder) => ({
    fetchNodes: builder.query<
      HierarchySliceResponse,
      {
        hierarchyType: string;
        nodeNumbers: number[];
        reportId: string;
      }
    >({
      query: ({ hierarchyType, reportId, nodeNumbers }) => ({
        method: "GET",
        url: `FetchNodes?reportId=${reportId}&hierarchyType=${hierarchyType}${nodeNumbers
          .map((node) => "&nodeNumbers=" + node)
          .join("")}`,
      }),
    }),
  }),
  reducerPath: "hierarchySliceApi",
  keepUnusedDataFor: 0,
});

export const { useLazyFetchNodesQuery, useFetchNodesQuery } = hierarchySliceApi;
