import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import classNames from "classnames";
import styles from "./SegmentationModal.module.css";

export type SegmentationIconWithLegendProps = {
  className?: string;
  icon: string;
  legend: string;
};

export const SegmentationIconWithLegend = ({
  className,
  legend,
  icon,
}: SegmentationIconWithLegendProps) => (
  <div className={classNames(styles.iconWithLegend, className)}>
    <img alt={legend} src={icon} />
    <span>{legend}</span>
  </div>
);

export type SegmentationContentProps = {
  segmentIcons?: SegmentationIconWithLegendProps[];
  segmentList: string[];
  segmentationDefinition: string;
};

export const SegmentationContent = ({
  segmentationDefinition,
  segmentList,
  segmentIcons,
}: SegmentationContentProps) => (
  <>
    <p>{segmentationDefinition}</p>
    <ul>
      {segmentList.map((listItem) => (
        <li key={uniqueId()}>{listItem}</li>
      ))}
    </ul>
    {segmentIcons && (
      <div className={styles.iconGroup}>
        {segmentIcons.map((segmentationIcon) => (
          <SegmentationIconWithLegend
            className={segmentationIcon.className}
            icon={segmentationIcon.icon}
            key={uniqueId()}
            legend={segmentationIcon.legend}
          />
        ))}
      </div>
    )}
  </>
);
