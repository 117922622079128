const search = (name: string, token: string): boolean =>
  name.toLowerCase().includes(token.toLowerCase());

const searchMatchesAllTokens = (name: string, tokens: string[]): boolean => {
  for (const token of tokens) {
    if (!search(name, token.trim())) {
      return false;
    }
  }

  return true;
};

export const createRowNameMatchesSearchPredicate = (
  query: string
): ((name: string) => boolean) => {
  if (query.length === 0) return () => false;

  const queries = query.split(",").map((token) => token.trim());

  return (name: string) => {
    if (name.length === 0) return false;
    for (const text of queries) {
      let flag = false;
      if (text.includes(" ")) {
        const tokens = text.split(" ").map((token) => token.trim());
        flag = searchMatchesAllTokens(name, tokens);
      }

      if (flag || search(name, text)) return true;
    }

    return false;
  };
};
