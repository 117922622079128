import {
  type ReportParametersDto,
  type LocalHierarchyNodeSelection,
  ParameterId,
  formatNumberDate,
  TransactionSource,
} from "@quantium-enterprise/common-ui";
import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui/src/models/hierarchy-slice-dto";
import { type Option } from "@quantium-enterprise/qds-react/dist/Typeahead";
import {
  type PayloadAction,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  focalItemsHasDuplicateNames,
  getFocalItemLabels,
} from "../../common/utils/focal-item-labels-utils";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import {
  type TrendsChartResponseDto,
  type SegmentationBreakdownResponseDto,
} from "../models/ChartResponseDto";
import {
  type FetchChildrenTableResponse,
  type HierarchicalTableRow,
  type InitialTableResponse,
  type KeyMeasureTrendsMetadata,
  type RefetchTableResponse,
} from "../models/DataTableDto";
import { type LocalParametersResponseDto } from "../models/LocalParametersResponseDto";
import { findNode, rebuildNestedRows } from "../utils/data-table-utils";
import {
  getDefaultSelections,
  getSelectedDatasetMetrics,
} from "../utils/getDefaultSelections";

const getPersistenceKey = (reportId: string) =>
  `key-measure-trends-${reportId}`;

export type KeyMeasureTrendsLocalSelections = {
  changeOn: string;
  channel: PanelOption;
  dataset: PanelOption;
  locationHierarchy: LocalHierarchyNodeSelection;
  metrics: Option[];
  promotion: PanelOption;
  segmentation: PanelOption;
  time: string;
  timePeriodLength: string;
};

export type KeyMeasureTrendsPersistedSelections = {
  focalItemTableRowSelectionState: RowSelectionState;
  focalItems: HierarchySliceNodeDto[];
  localParameterSelections: KeyMeasureTrendsLocalSelections;
};

export type KeyMeasureTrendsDataState = {
  focalItemParents: HierarchySliceNodeDto[];
  focalItemTableInitialised: boolean;
  focalItemTableMetadata: KeyMeasureTrendsMetadata;
  focalItemTableRows: HierarchicalTableRow[];
  hasSegmentation: boolean;
  hiddenPrimary: number[];
  hiddenSecondary: number[];
  latestSegmentationBreakdownRequest: string;
  latestTrendsChartRequest: string;
  localParameterConfig: SidePanelParameter[];
  localParametersInitialised: boolean;
  persistedSelections: KeyMeasureTrendsPersistedSelections;
  persistedSelectionsLoaded: boolean;
  promoWeeks: string[];
  reportId: string;
  reportName: string;
  reportParameters: ReportParametersDto | undefined;
  segmentationBreakdownResponse: SegmentationBreakdownResponseDto;
  showSegmentationChartDataLabels: boolean;
  showTrendedChartDataLabels: Record<string, boolean>;
  trendsChartResponse: TrendsChartResponseDto;
};

export const initialState: KeyMeasureTrendsDataState = {
  focalItemParents: [],
  focalItemTableInitialised: false,
  focalItemTableMetadata: {
    metricMetadata: [],
    promoWeeks: [],
  },
  focalItemTableRows: [],
  hasSegmentation: false,
  hiddenPrimary: [],
  hiddenSecondary: [],
  localParametersInitialised: false,
  localParameterConfig: [],
  persistedSelections: {
    focalItems: [],
    focalItemTableRowSelectionState: {},
    localParameterSelections: {
      changeOn: "",
      channel: {
        value: "",
        label: "",
      },
      metrics: [],
      locationHierarchy: { nodeNumber: -1 } as LocalHierarchyNodeSelection,
      promotion: {
        value: "",
        label: "",
      },
      segmentation: {
        value: "",
        label: "",
      },
      time: "",
      timePeriodLength: "",
      dataset: {
        label: "",
        value: "",
      },
    },
  },
  persistedSelectionsLoaded: false,
  reportId: "",
  reportName: "",
  trendsChartResponse: {
    productData: [],
    promoWeeks: [],
    metricsMetadata: [],
  },
  promoWeeks: [],
  latestTrendsChartRequest: "",
  segmentationBreakdownResponse: {
    segments: [],
    productData: [],
    promoWeeks: [],
    metricsMetadata: [],
  },
  latestSegmentationBreakdownRequest: "",
  reportParameters: undefined,
  showSegmentationChartDataLabels: false,
  showTrendedChartDataLabels: { 0: false, 1: false },
};

export const keyMeasureTrendsSlice = createSlice({
  initialState,
  name: "key-measure-trends",
  reducers: {
    onInitialTableDataReceived: (
      state: KeyMeasureTrendsDataState,
      { payload }: PayloadAction<InitialTableResponse>
    ) => {
      state.focalItemTableMetadata = payload.tableMetadata;
      state.focalItemTableRows = payload.tableRows;
      state.focalItemTableInitialised = true;
    },
    onTableChildrenDataRecieved: (
      state: KeyMeasureTrendsDataState,
      { payload }: PayloadAction<FetchChildrenTableResponse>
    ) => {
      const parentRow = findNode(
        state.focalItemTableRows,
        payload.parentNodeNumber
      );
      if (parentRow) {
        parentRow.subRows = payload.tableRows;
      }
    },
    onTableDataRecieved: (
      state: KeyMeasureTrendsDataState,
      { payload }: PayloadAction<RefetchTableResponse>
    ) => {
      state.focalItemTableRows = rebuildNestedRows(
        state.focalItemTableRows,
        payload.tableRows
      );
    },
    onReportParamtersReceived: (
      state: KeyMeasureTrendsDataState,
      { payload }: PayloadAction<ReportParametersDto>
    ) => {
      state.reportParameters = payload;
    },
    onLocalParametersReceived: (
      state: KeyMeasureTrendsDataState,
      { payload }: PayloadAction<LocalParametersResponseDto>
    ) => {
      state.reportName = payload.reportName;
      state.localParameterConfig = payload.localParameters;

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.focalItems = payload.focalItems;

        state.persistedSelections.focalItemTableRowSelectionState = {};
        for (const defaultFocalItem of payload.focalItems) {
          state.persistedSelections.focalItemTableRowSelectionState[
            defaultFocalItem.nodeNumber
          ] = true;
        }

        state.persistedSelections.localParameterSelections =
          getDefaultSelections(payload.localParameters);
      }

      const segmentationParameter = payload.localParameters.find(
        (parameter) => parameter.id === ParameterId.Segmentation
      );
      if (segmentationParameter && "selections" in segmentationParameter) {
        state.hasSegmentation = segmentationParameter.selections.length > 0;
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onChangeOnChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<string>
    ) => {
      state.persistedSelections.localParameterSelections.changeOn =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onChannelChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.channel = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onDatasetChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.dataset = {
        label: action.payload.label,
        value: action.payload.value,
      };

      state.persistedSelections.localParameterSelections.metrics =
        getSelectedDatasetMetrics(
          state.persistedSelections.localParameterSelections,
          state.localParameterConfig
        );

      const isSegmentationDisabled =
        action.payload.value === TransactionSource.Total.toString();

      const Segmentation =
        state.persistedSelections.localParameterSelections.segmentation;

      if (isSegmentationDisabled) {
        state.persistedSelections.localParameterSelections.segmentation = {
          value: Segmentation.value,
          label: Segmentation.label,
        };
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },

    onLocationChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localParameterSelections.locationHierarchy =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onMetricsChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<Option[]>
    ) => {
      state.persistedSelections.localParameterSelections.metrics =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onPrimaryHiddenChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<number[]>
    ) => {
      state.hiddenPrimary = action.payload;
    },
    onSecondaryHiddenChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<number[]>
    ) => {
      state.hiddenSecondary = action.payload;
    },
    onPromotionChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.promotion = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.segmentation =
        action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onFocalItemsChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<HierarchySliceNodeDto[]>
    ) => {
      state.persistedSelections.focalItems = action.payload;
      for (const key of Object.keys(
        state.persistedSelections.focalItemTableRowSelectionState
      )) {
        state.persistedSelections.focalItemTableRowSelectionState[key] = false;
      }

      for (const node of state.persistedSelections.focalItems) {
        state.persistedSelections.focalItemTableRowSelectionState[
          node.nodeNumber
        ] = true;
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onShowSegmentationChartDataLabelsChange: (
      state: KeyMeasureTrendsDataState
    ) => {
      state.showSegmentationChartDataLabels =
        !state.showSegmentationChartDataLabels;
    },
    onShowTrendedChartDataLabelsChange: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<string>
    ) => {
      state.showTrendedChartDataLabels[action.payload] =
        !state.showTrendedChartDataLabels[action.payload];
    },
    onReportOpen: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: KeyMeasureTrendsPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
    resetHiddenSelections: (state: KeyMeasureTrendsDataState) => {
      state.hiddenPrimary = [];
      state.hiddenSecondary = [];
    },
    setTrendsChartResponse: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<TrendsChartResponseDto>
    ) => {
      state.promoWeeks = action.payload.promoWeeks.map((pw) =>
        formatNumberDate(pw)
      );

      if (!focalItemsHasDuplicateNames(state.persistedSelections.focalItems)) {
        state.trendsChartResponse = action.payload;
        return;
      }

      const focalItemLabels = getFocalItemLabels(
        state.persistedSelections.focalItems,
        state.focalItemParents
      );
      const modifiedProductData = action.payload.productData.map(
        (productData) => ({
          ...productData,
          name: focalItemLabels[productData.nodeNumber] ?? productData.name,
        })
      );
      state.trendsChartResponse = {
        ...action.payload,
        productData: modifiedProductData,
      };
    },
    setLatestTrendsChartRequestId: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<string>
    ) => {
      state.latestTrendsChartRequest = action.payload;
    },
    setSegmentationBreakdownResponse: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<SegmentationBreakdownResponseDto>
    ) => {
      if (!focalItemsHasDuplicateNames(state.persistedSelections.focalItems)) {
        state.segmentationBreakdownResponse = action.payload;
        return;
      }

      const focalItemLabels = getFocalItemLabels(
        state.persistedSelections.focalItems,
        state.focalItemParents
      );
      const modifiedProductData = action.payload.productData.map(
        (productData) => ({
          ...productData,
          name: focalItemLabels[productData.nodeNumber] ?? productData.name,
        })
      );
      state.segmentationBreakdownResponse = {
        ...action.payload,
        productData: modifiedProductData,
      };
    },
    setLatestSegmentationBreakdownRequestId: (
      state: KeyMeasureTrendsDataState,
      action: PayloadAction<string>
    ) => {
      state.latestSegmentationBreakdownRequest = action.payload;
    },
    onParentNodeDataReceived: (
      state: KeyMeasureTrendsDataState,
      { payload }: PayloadAction<HierarchySliceNodeDto[]>
    ) => {
      state.focalItemParents = payload;
    },
  },
});

// SELECTORS
// These are for getting data out of the store. They are memoized, so they only recalculate if the state they depend on has changed.
export const selectReportId = createSelector(
  (state: RootState) => state.keyMeasureTrends.reportId,
  (reportId) => reportId
);

export const selectFocalItems = createSelector(
  (state: RootState) => state.keyMeasureTrends.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectFocalItemParents = createSelector(
  (state: RootState) => state.keyMeasureTrends.focalItemParents,
  (focalItemParents) => focalItemParents
);

export const selectChangeOn = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections
      .changeOn,
  (changeOn) => changeOn
);

export const selectChannel = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections.channel,
  (channel) => channel
);

export const selectLocationHierarchy = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections
      .locationHierarchy,
  (locationHierarchy) => locationHierarchy
);

export const selectPromotion = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections
      .promotion,
  (promotion) => promotion
);

export const selectMetrics = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections.metrics,
  (metrics) => metrics
);

export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections
      .segmentation,
  (segmentation) => segmentation
);

export const selectTime = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections.time,
  (time) => time
);

export const selectDataset = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections.dataset,
  (dataset) => dataset
);

export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.keyMeasureTrends.persistedSelections.localParameterSelections
      .timePeriodLength,
  (timePeriodLength) => timePeriodLength
);

export const {
  onInitialTableDataReceived,
  onTableChildrenDataRecieved,
  onTableDataRecieved,
  onFocalItemsChange,
  onReportParamtersReceived,
  onLocalParametersReceived,
  onChangeOnChange,
  onChannelChange,
  onDatasetChange,
  onLocationChange,
  onMetricsChange,
  onPromotionChange,
  onSegmentationChange,
  onShowSegmentationChartDataLabelsChange,
  onShowTrendedChartDataLabelsChange,
  onPrimaryHiddenChange,
  onSecondaryHiddenChange,
  onReportOpen,
  resetHiddenSelections,
  setTrendsChartResponse,
  setLatestTrendsChartRequestId,
  setSegmentationBreakdownResponse,
  setLatestSegmentationBreakdownRequestId,
  onParentNodeDataReceived,
} = keyMeasureTrendsSlice.actions;

export default keyMeasureTrendsSlice.reducer;
