import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import {
  defaultOptions,
  HighchartsReact,
  TooltipHTML,
} from "../highcharts-react/HighchartsReact";
import { type BasicColumnChartProps } from "../models";

export const BasicColumnChart = ({
  data,
  categories,
  dataLabelFormatter,
  showChartDataLabels,
  yAxisLabel,
  yAxisTickFormatter,
  tooltipOverride,
  height,
}: BasicColumnChartProps) => {
  const options: HighchartsReactProps = {
    ...defaultOptions,
    chart: {
      backgroundColor: "none",
      height,
      style: {
        fontFamily: `var(--qbit-font-family)`,
      },
      type: "column",
    },
    legend: { enabled: false },
    plotOptions: {
      column: {
        dataLabels: {
          crop: false,
          enabled: showChartDataLabels,
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return dataLabelFormatter(this.y);
          },
          inside: false,
          style: {
            color: "var(--qbit-colour-text-primary)",
            fontSize: "0.75rem",
            fontWeight: "var(--qbit-font-weight-medium)",
            textOutline: "white",
          },
        },
        // sets width of bar chart
        pointPadding: 0.3,
      },
    },
    series: [
      {
        data,
        type: "column",
      },
    ],
    tooltip: {
      ...defaultOptions.tooltip,
      ReactFormatter: (ttData) => {
        const tooltipData = ttData.points?.map((pt) => ({
          color: String(pt.color),
          name: yAxisLabel ? yAxisLabel : pt.x,
          value: dataLabelFormatter(pt.y),
        }));
        return tooltipOverride
          ? tooltipOverride(tooltipData, yAxisLabel ? ttData.x : undefined)
          : TooltipHTML(tooltipData, yAxisLabel ? ttData.x : undefined);
      },
    },
    xAxis: {
      categories,
      crosshair: {
        color: "var(--qbit-colour-chrome-background)",
        zIndex: 0,
      },
      visible: true,
      title: {
        style: {
          color: "var(--qbit-colour-text-primary)",
        },
      },
      labels: {
        style: {
          color: "var(--qbit-colour-text-secondary)",
        },
      },
    },
    yAxis: {
      ...defaultOptions.yAxis,
      labels: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return yAxisTickFormatter(this.value, false, "", true);
        },
        style: {
          color: "var(--qbit-colour-text-secondary)",
        },
      },

      title: {
        text: yAxisLabel,
        style: {
          color: "var(--qbit-colour-text-primary)",
        },
      },
      visible: true,
    },
  };

  return <HighchartsReact options={options} />;
};
