import { type SelectionDto } from "./selection-dto";

export type TimePeriodSelectionDto = {
  endDate: string;
  label: string;
  periodValue: string;
  startDate: string;
};

export const isTimePeriodSelectionDto = (
  selectionObject: SelectionDto
): selectionObject is TimePeriodSelectionDto =>
  "endDate" in selectionObject &&
  "label" in selectionObject &&
  "periodValue" in selectionObject &&
  "startDate" in selectionObject;
