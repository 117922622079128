import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import { type SegmentOption } from "./SegmentFilter";

// Function takes a flat-list of SegmentOptions and extracts the Segmentations options
export const createSegmentationOptions = (
  options: SegmentOption[]
): PanelOption[] => {
  const segmentationDictionary: Record<string, string> = {};

  for (const option of options) {
    segmentationDictionary[option.segmentationValue] = option.segmentationLabel;
  }

  return Object.entries(segmentationDictionary).map(([value, label]) => ({
    label,
    value,
  }));
};

export const createSegmentOptions = (
  segmentations: Array<number | string>,
  segmentOptions: SegmentOption[]
) => {
  const segmentOptionsDictionary: Record<string, PanelOption[]> = {};

  for (const segmentation of segmentations) {
    const options: PanelOption[] = [];
    for (const segmentOption of segmentOptions) {
      if (segmentOption.segmentationValue === segmentation) {
        options.push({
          label: segmentOption.segmentLabel,
          value: segmentOption.segmentValue,
          optionDisabled: segmentOption.optionDisabled ?? false,
        });
      }
    }

    segmentOptionsDictionary[segmentation] = options;
  }

  return segmentOptionsDictionary;
};

// Simple helper function that gets the display name for an option
export const getDisplayName = (
  value: string,
  options: PanelOption[]
): string => {
  const match = options.find((option) => option.value === value);
  if (match?.label) {
    return match.label;
  }

  return value;
};
