import { getExportColumnHeader } from "../utils/getExportfunctions";

export const csvTransformation = (
  XaxisMeta: string[],
  data: Array<Array<number | string>>,
  metric: string,
  chartContent: string[],
  CurrencySymbol: string,
  Xaxis: string,
  format: string
) => {
  let columnName;
  if (Xaxis === "FocalItems") {
    columnName = "Focal Item(s)";
  } else {
    columnName = Xaxis;
  }

  const columnHeaders = [columnName];
  columnHeaders.push(
    ...chartContent.map((content) =>
      getExportColumnHeader(CurrencySymbol, content, format, metric)
    )
  );
  const csvData = [columnHeaders];
  for (const [index, element] of XaxisMeta.entries()) {
    const row = [element, ...data.map((value) => value[index].toString())];
    csvData.push(row);
  }

  return csvData;
};

export const csvTransformationTable = (
  XaxisMeta: string[],
  data: number[][],
  metric: string,
  chartContent: string[],
  CurrencySymbol: string,
  Xaxis: string,
  format: string
) => {
  let columnName;
  if (Xaxis === "FocalItems") {
    columnName = "Focal Item(s)";
  } else {
    columnName = Xaxis;
  }

  const columnHeaders = [columnName];
  columnHeaders.push(
    ...chartContent.map((content) =>
      getExportColumnHeader(CurrencySymbol, content, format, metric)
    )
  );
  const csvData = [columnHeaders];
  for (const [index, element] of XaxisMeta.entries()) {
    const row = [element, ...data[index].map((value) => value.toString())];
    csvData.push(row);
  }

  return csvData;
};
