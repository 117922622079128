import { Spinner, SpinnerSize } from "@quantium-enterprise/qds-react";
import { type PropsWithChildren } from "react";
import ErrorMessageWithRetry from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorMessageWithRetry";
import styles from "./ChartDataWrapper.module.css";

export type NoDataChartWrapperProps = {
  disableLoadingSpinner?: boolean;
  errorText?: string;
  isError: boolean;
  isSuccess: boolean;
  minHeight?: string;
  retry: () => void;
  showNoDataAvailable?: boolean;
};

type NoDataAvailableContainerProps = {
  minHeight?: string;
};

const NoDataAvailableContainer = ({
  children,
  minHeight,
  ...properties
}: PropsWithChildren<NoDataAvailableContainerProps>) => (
  <div {...properties} className={styles.noDataAvailable} style={{ minHeight }}>
    {children}
  </div>
);

const SpinnerComponent = () => (
  <div className={styles.loadingContainer}>
    <Spinner
      className={styles.loading}
      size={SpinnerSize.Large}
      text="Loading..."
    />
  </div>
);

export const ChartDataWrapper = ({
  disableLoadingSpinner,
  errorText,
  isError,
  isSuccess,
  minHeight,
  showNoDataAvailable,
  children,
  retry,
  ...properties
}: PropsWithChildren<NoDataChartWrapperProps>) => {
  if (isError) {
    return (
      <NoDataAvailableContainer minHeight={minHeight}>
        <ErrorMessageWithRetry
          errorText={errorText ? errorText : ""}
          retry={retry}
          {...properties}
        />
      </NoDataAvailableContainer>
    );
  }

  if (isSuccess && showNoDataAvailable) {
    return (
      <NoDataAvailableContainer minHeight={minHeight}>
        <div className={styles.messageContainer}>
          <p className={styles.message}>No data available.</p>
        </div>
      </NoDataAvailableContainer>
    );
  }

  if (isSuccess) {
    return (
      <NoDataAvailableContainer minHeight={minHeight}>
        <div className={styles.content}>{children}</div>
      </NoDataAvailableContainer>
    );
  }

  if (disableLoadingSpinner) {
    return null;
  }

  return (
    <NoDataAvailableContainer minHeight={minHeight}>
      <SpinnerComponent />
    </NoDataAvailableContainer>
  );
};
