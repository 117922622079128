import {
  FormBlock,
  FormBlockType,
  FormInputHeight,
  Input,
  Select,
  SelectOption,
} from "@quantium-enterprise/qds-react";
import React from "react";
import { type PanelOption } from "../local-parameters-panel/FixedSidePanel";

export type SingleSelectDropdownProps = {
  height?: FormInputHeight;
  isDisabled?: boolean;
  onSelection?: (value: PanelOption) => unknown;
  selectOptions: PanelOption[];
  selectedValue?: string;
  title?: string;
};

const defaultOnSelection = () => {};

export const SingleSelectDropdown = ({
  height = FormInputHeight.Small,
  isDisabled,
  onSelection = defaultOnSelection,
  selectOptions,
  selectedValue,
  title = "",
}: SingleSelectDropdownProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const option = selectOptions.find((option2) => option2.value === value);
    if (option) {
      onSelection(option);
    } else {
      throw new Error(
        `Could not find value ${value} in options: [${selectOptions
          .map((option2) => option2.value)
          .join(",")}]`
      );
    }
  };

  return (
    <FormBlock blockType={FormBlockType.Select}>
      <Input>
        <Select
          disabled={isDisabled}
          height={height}
          id={title + " dropdown"}
          onChange={handleOnChange}
          value={selectedValue}
        >
          {selectOptions.map((option) => (
            <SelectOption
              key={option.value}
              text={option.label || option.value.toString()}
              value={option.value.toString()}
            />
          ))}
        </Select>
      </Input>
    </FormBlock>
  );
};

export default SingleSelectDropdown;
