import { type HierarchyGroupRuleWithIdAndName } from "../models";

export const isHierarchyItemFilterMatch = (
  itemCode: string,
  filterRules: HierarchyGroupRuleWithIdAndName[]
) =>
  filterRules.some(
    (rule) =>
      rule.values.length === 0 ||
      rule.values.some(({ code: ruleCode }) =>
        rule.operator === "Is"
          ? itemCode.startsWith(ruleCode)
          : !itemCode.startsWith(ruleCode)
      )
  );
