import { type Panel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { SidePanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isTimePeriodParameter,
  isLocationSelectionParameter,
  isHierarchySelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildTimePeriodPanel,
  buildFixedLocationHierarchyPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";

export const ProductSubstitutabilitySidePanel = () => {
  const { localParameters } = useSelector((state: RootState) => ({
    localParameters: state.productSubstitutability.localParameters,
  }));

  const generatedPanels = useCallback((): Panel[] => {
    const panels: Panel[] = [];

    for (const localParameter of localParameters) {
      switch (localParameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(localParameter))
            panels.push(buildTimePeriodPanel(localParameter));
          break;

        case LocalParameters.LocationHierarchy:
          if (
            isLocationSelectionParameter(localParameter) &&
            isHierarchySelectionParameter(localParameter)
          ) {
            panels.push(buildFixedLocationHierarchyPanel(localParameter));
          }

          break;

        default: {
          break;
        }
      }
    }

    return panels;
  }, [localParameters]);

  return <SidePanel panels={generatedPanels()} />;
};
