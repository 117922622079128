import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RootState } from "./store";

export type AvailableReportsState = {
  selectedMenuItem: { id: string | undefined; label: string };
};

export const initialState: AvailableReportsState = {
  selectedMenuItem: { id: undefined, label: "All reports" },
};

export const availableReportsSlice = createSlice({
  initialState,
  name: "availableReports",
  reducers: {
    onSelectedMenuItem: (
      state: AvailableReportsState,
      action: PayloadAction<{ id: string | undefined; label: string }>
    ) => {
      state.selectedMenuItem =
        state.selectedMenuItem.id === action.payload.id
          ? initialState.selectedMenuItem
          : action.payload;
    },
    reset: () => initialState,
  },
});

export const selectedMenuItemSelector = createSelector(
  (state: RootState) => state.availableReports,
  (availableReports: AvailableReportsState) => availableReports.selectedMenuItem
);

export const { onSelectedMenuItem, reset } = availableReportsSlice.actions;

export default availableReportsSlice.reducer;
