import classNames from "classnames";
import { type PropsWithChildren } from "react";
import styles from "./ProductSubstitutabilityDropdownFilter.module.css";

export enum ProductSubstitutabilityDropdownFilterVariant {
  WithLeftMargin = 0,
}

export type ProductSubstitutabilityDropdownFilterProps = {
  header: string;
  variant?: ProductSubstitutabilityDropdownFilterVariant;
};

export const ProductSubstitutabilityDropdownFilter = ({
  header,
  children,
  variant,
}: PropsWithChildren<ProductSubstitutabilityDropdownFilterProps>) => (
  <div
    className={classNames(
      styles.productSubstitutabilityDropdownFilter,

      {
        [styles.leftMargin]:
          variant ===
          ProductSubstitutabilityDropdownFilterVariant.WithLeftMargin,
      }
    )}
  >
    <h6 className={styles.productSubstitutabilityDropdownFilterHeading}>
      {header}
    </h6>
    <div>{children}</div>
  </div>
);
