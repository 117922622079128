import { type RouteWithLabel } from "@quantium-enterprise/common-ui";
import ErrorBoundary from "checkout-ui/src/components/error-boundary/ErrorBoundary";
import { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import AdminApp from "./components/AdminApp";
import { store } from "./store";

const InProgressReports = lazy(
  async () => await import("./components/in-progress-reports/InProgressReports")
);

export default (): RouteWithLabel => ({
  children: [
    {
      element: (
        <ErrorBoundary>
          <Suspense fallback={<>loading...</>}>
            <InProgressReports />
          </Suspense>
        </ErrorBoundary>
      ),
      path: "in-progress-reports",
    },
  ],
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <AdminApp />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Admin",
  path: "/co3k-admin-control-panel",
});
