export enum GainsAndLossMetrics {
  ComparisonPeriod = "Comparison period",
  CustomersSpendingMoreLess = "Spending more / less",
  FocusPeriod = "Focus period",
  NetChange = "Net change",
  Switching = "Switching in / out",
  TotalNewLostCustomers = "New / lost customers",
  TotalNewLostRetailer = "New / lost to retailer",
  TotalNewLostUniverse = "New / lost to universe",
}

export enum ValueTypes {
  Absolute = "Absolute",
  Percentage = "Percentage",
}

export enum MetricIdTypes {
  Gains = "Gain",
  Loss = "Loss",
  Net = "Net",
}

export enum measureLabels {
  Sales = "Sales",
  Units = "Units",
  Volume = "Volume",
}

export const getMeasureLabel: { [key: string]: string } = {
  sales: "Sales",
  units: "Units",
  volume: "Volume",
};
