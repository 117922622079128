import Highcharts, {
  type TooltipFormatterContextObject,
  type Point,
  type PointOptionsObject,
  type SeriesVennOptions,
} from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsVenn from "highcharts/modules/venn";
import { forwardRef, memo, useEffect, useMemo } from "react";
import {
  HighchartsReact,
  defaultOptions,
} from "../highcharts-react/HighchartsReact";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import mainStyles from "../highcharts-react/HighchartsReact.module.css";
import styles from "./VennChart.module.css";

// init the module
highchartsVenn(Highcharts);
highchartsAccessibility(Highcharts);

export type VennChartProps = {
  CustomTooltip?: ({ point }: TooltipFormatterContextObject) => JSX.Element;
  data: PointOptionsObject[];
  getCustomLabel?: (point: Point) => string;
  name?: string;
  onAreaClicked?: (selectedSets: string[]) => void;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
};

export const VennChartTooltip = ({
  series,
  point,
}: TooltipFormatterContextObject) => (
  <div className={mainStyles.chartTooltip}>
    <div className={styles.tooltipTitle}>
      <div
        className={mainStyles.circle}
        style={{ backgroundColor: `${String(point.color)}` }}
      />
      <span className={styles.tooltipTitleContent}>{series.name}</span>
    </div>
    <p className={styles.tooltipContent}>
      {point.name}: {point.options.value}
    </p>
  </div>
);

const referenceToForward = forwardRef(
  (
    {
      name,
      data,
      onAreaClicked,
      getCustomLabel,
      CustomTooltip,
      onOptionsChanged,
    }: VennChartProps,
    ref
  ) => {
    const series: SeriesVennOptions[] = useMemo(
      () => [
        {
          type: "venn",
          name,
          data,

          dataLabels: {
            useHTML: true,
            formatter() {
              // eslint-disable-next-line unicorn/no-this-assignment
              const self = this;

              if (getCustomLabel) {
                return getCustomLabel(self.point);
              }

              return "";
            },
            style: {
              fontWeight: "normal",
              backgroundColor: "white",
              border: "1px solid red",
            },
          },
        },
      ],
      [data, getCustomLabel, name]
    );

    const highchartsOptions: HighchartsReactProps = useMemo(
      () => ({
        ...defaultOptions,
        // reseting here for the chart configurations as we do not require zoom functionality
        chart: {
          style: {
            fontFamily: "var(--qbit-font-family)",
          },
          backgroundColor: "transparent",
          renderTo: "container",
        },
        // https://stackoverflow.com/questions/76044013/cannot-solve-uncaught-typeerror-when-using-highcharts-react-wrapper-to-draw-venn
        // https://github.com/highcharts/highcharts/issues/16946
        plotOptions: {
          // the following line fixed the issue for me!:
          venn: { keys: [] },
          series: {
            dataLabels: {
              enabled: true,
              inside: true,
            },
            cursor: "pointer",
            point: {
              events: {
                click() {
                  // eslint-disable-next-line unicorn/no-this-assignment
                  const self = this;
                  const selectedSets = self.options.sets;

                  if (selectedSets && onAreaClicked) {
                    onAreaClicked(selectedSets);
                  }
                },
              },
            },
          },
        },
        series,
        title: {
          text: name,
        },
        tooltip: {
          ...defaultOptions.tooltip,
          // NOTE do not set it to true as this does not support shared points
          shared: false,
          ReactFormatter: (ttData) =>
            CustomTooltip ? CustomTooltip(ttData) : VennChartTooltip(ttData),
        },
      }),
      [CustomTooltip, name, onAreaClicked, series]
    );

    useEffect(() => {
      if (onOptionsChanged) {
        onOptionsChanged(highchartsOptions);
      }
    }, [onOptionsChanged, highchartsOptions]);

    return <HighchartsReact options={highchartsOptions} ref={ref} />;
  }
);

export const VennChart = memo(referenceToForward);
