import {
  type SegmentGuideline,
  useRunSegmentGuidelinesMutation,
  type SegmentDto,
  type RunSegmentGuidelinesRequestDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  DialogWidth,
  Icon,
  IconColour,
  IconGlyph,
  IconSize,
  Spinner,
  SpinnerSize,
  Text,
  TextSentiment,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import styles from "./SegmentGuidelinesModal.module.scss";
import {
  SegmentGuidelineWarningMessage,
  aggregateSegmentGuidelines,
} from "./SegmentGuidelinesModalUtils";

export type SegmentGuidelineItemProps = {
  guidelineKey: SegmentGuidelineWarningMessage;
  guidelines?: SegmentGuideline;
  inProgress: boolean;
};

export const SegmentGuidelinesItem = ({
  guidelines,
  guidelineKey,
  inProgress,
}: SegmentGuidelineItemProps) => {
  const invalidGuidelines = inProgress ? undefined : guidelines?.result;

  return (
    <div
      className={classNames(styles.segmentGuidelineItem, {
        [styles.invalidSegmentGuidelineItem]: invalidGuidelines,
        [styles.validSegmentGuidelineItem]: !invalidGuidelines,
      })}
    >
      {inProgress ? (
        <Spinner
          className={styles.segmentGuidelineIcon}
          size={SpinnerSize.Small}
        />
      ) : (
        <Icon
          className={styles.segmentGuidelineIcon}
          colour={invalidGuidelines ? IconColour.Bad : IconColour.Good}
          glyph={
            invalidGuidelines
              ? IconGlyph.AlertsAndNotificationsAlertCircle
              : IconGlyph.SelectionCheckCircle
          }
          size={IconSize.Small}
          text="warning"
        />
      )}
      <Text
        className={[
          styles.segmentGuideline,
          invalidGuidelines
            ? styles.invalidSegmentGuideline
            : styles.validSegmentGuideline,
        ].join(" ")}
        sentiment={
          invalidGuidelines ? TextSentiment.Bad : TextSentiment.Neutral
        }
      >
        {
          SegmentGuidelineWarningMessage[
            guidelineKey as unknown as keyof typeof SegmentGuidelineWarningMessage
          ]
        }
      </Text>
      {invalidGuidelines && (
        <Text className={styles.segmentText} key={invalidGuidelines}>
          {invalidGuidelines}
        </Text>
      )}
    </div>
  );
};

type SegmentGuidelinesModalProps = {
  inProgress: boolean;
  onClose: () => void;
  segments: SegmentDto[];
  show: boolean;
};

export const SegmentGuidelinesModal = ({
  show,
  onClose,
  segments,
  inProgress,
}: SegmentGuidelinesModalProps) => {
  const divisionName = useDivision().name;
  const { groupId } = useParams();
  const triggerRef = useRef<HTMLDivElement>(null);
  const [inProgressInternal, setInProgressInternal] = useState(inProgress);

  useEffect(() => {
    setInProgressInternal(inProgress);
  }, [inProgress]);

  const activationGuidelinesAggregate = aggregateSegmentGuidelines(segments);

  const [runSegmentGuidelines] = useRunSegmentGuidelinesMutation();

  const onRevalidateClick = useCallback(async () => {
    setInProgressInternal(true);
    const requestPayload: RunSegmentGuidelinesRequestDto = {
      customerGroupId: groupId ?? "",
    };
    await runSegmentGuidelines({
      divisionName,
      payload: requestPayload,
    }).unwrap();
  }, [divisionName, groupId, runSegmentGuidelines, setInProgressInternal]);

  return (
    <>
      {createPortal(
        <div
          aria-hidden="true"
          className={styles.dialogContainer}
          onClick={(event) => event.stopPropagation()}
        >
          <Dialog
            footer={
              <div className={styles.footerContainer}>
                <Button
                  disabled={inProgressInternal}
                  height={ButtonHeight.Small}
                  onClick={async () => await onRevalidateClick()}
                  text="Revalidate"
                  variant={ButtonVariant.Primary}
                />
                <Button
                  height={ButtonHeight.Small}
                  onClick={() => onClose()}
                  text="Close"
                  variant={ButtonVariant.Secondary}
                />
              </div>
            }
            header={
              <div className={styles.headerContainer}>
                <Text>Segment guidelines</Text>
                <Button
                  // @ts-expect-error works but not specified
                  autofocus
                  height={ButtonHeight.Small}
                  onClick={() => onClose()}
                  variant={ButtonVariant.Stealth}
                >
                  <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
                </Button>
              </div>
            }
            onClose={onClose}
            show={show}
            titleId="segment-guidelines-modal"
            triggeredBy={triggerRef}
            width={DialogWidth.Small}
          >
            <div>
              <Text>
                Check segments against guidelines before use. If you proceed,
                limit access to the purchasing supplier only.
              </Text>
              {inProgressInternal && (
                <div className={styles.inProgressNotification}>
                  <div className={styles.header}>Validation in progress.</div>
                  <div>
                    This could take several minutes to complete. You can check
                    on the status later or stay and wait to see the outcome.
                  </div>
                </div>
              )}
              {Object.keys(activationGuidelinesAggregate).map((key) => {
                const guidelines =
                  activationGuidelinesAggregate[
                    key as keyof typeof SegmentGuidelineWarningMessage
                  ] ?? undefined;
                return (
                  <SegmentGuidelinesItem
                    guidelineKey={key as SegmentGuidelineWarningMessage}
                    guidelines={guidelines}
                    inProgress={inProgressInternal}
                    key={key}
                  />
                );
              })}
            </div>
          </Dialog>
        </div>,
        document.body
      )}
    </>
  );
};
