import { type DragEndEvent, type DragStartEvent } from "@dnd-kit/core";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { DragAndMultiDrop } from "components-ui/src/drag-and-multi-drop/container/DragAndMultiDrop";
import { DragAndMultiDropContainerType } from "components-ui/src/drag-and-multi-drop/container/DragAndMultiDropContainer";
import { type SimpleItem } from "components-ui/src/drag-and-multi-drop/models/Item";
import {
  type Container,
  type SimpleZone,
  ContainerIdType,
} from "components-ui/src/drag-and-multi-drop/models/Zone";
import {
  handleDragCancel,
  handleDragEnd,
  handleDragStart,
  handleRemove,
} from "components-ui/src/drag-and-multi-drop/utilities/eventHandlers";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useState, useEffect } from "react";
import { type AggregateRankMetricMetaData } from "../../models/aggregate-rank-common-models";
import styles from "./AggregateRankModal.module.css";

type DefaultWrapperProps = {
  dropdownOptions: PanelOption[];
  initialItems: SimpleItem[];
  initialStructure: SimpleZone[];
  updateSelectedMetrics: Function;
};

export const containers = [
  {
    title: "METRICS",
    id: ContainerIdType.METRIC,
    containerType: DragAndMultiDropContainerType.Left,
  },
  {
    title: "RANKED",
    id: ContainerIdType.RANKED,
    containerType: DragAndMultiDropContainerType.Right,
  },
  {
    title: "UNRANKED",
    id: ContainerIdType.UNRANKED,
    containerType: DragAndMultiDropContainerType.Right,
  },
] as Container[];

export const MetricsDaDWrapper = ({
  initialItems,
  initialStructure,
  dropdownOptions,
  updateSelectedMetrics,
}: DefaultWrapperProps) => {
  const [zones, setZones] = useState<SimpleZone[]>(initialStructure);
  const [items, setItems] = useState<SimpleItem[]>(initialItems);
  const [activeItem, setActiveItem] = useState<SimpleItem | undefined>(
    undefined
  );
  const [savedZoneState, setSavedZoneState] = useState<
    SimpleZone[] | undefined
  >(undefined);

  useEffect(() => {
    const rankedMetrics = zones
      .filter(
        (zone) => zone.containerId === ContainerIdType.RANKED && zone.item
      )
      .map((zone) => zone.item?.name);

    const unrankedMetrics = zones
      .filter(
        (zone) => zone.containerId === ContainerIdType.UNRANKED && zone.item
      )
      .map((zone) => zone.item?.name);

    updateSelectedMetrics(rankedMetrics, unrankedMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zones]);

  return (
    <DragAndMultiDrop
      activeItem={activeItem}
      containers={containers}
      dropdownOptions={dropdownOptions}
      items={items}
      onDragCancel={() => {
        handleDragCancel(
          setActiveItem,
          items,
          setItems,
          setZones,
          setSavedZoneState,
          savedZoneState,
          activeItem
        );
      }}
      onDragEnd={(event: DragEndEvent) => {
        handleDragEnd(
          setActiveItem,
          zones,
          setZones,
          items,
          setItems,
          setSavedZoneState,
          savedZoneState,
          event.over?.data.current,
          event.over?.id,
          activeItem
        );
      }}
      onDragStart={(event: DragStartEvent) => {
        handleDragStart(
          items,
          setItems,
          setActiveItem,
          zones,
          setZones,
          setSavedZoneState,
          event.active.data.current?.item
        );
      }}
      onRemove={(item: SimpleItem) => {
        handleRemove(item, zones, setZones, items, setItems);
      }}
      zones={zones}
    />
  );
};

const createPanelOption = (value: string) => ({
  value,
  label: value,
});

export const AggregateRankMetricsContent = ({
  metricsMetaData,
  rankedMetrics,
  unrankedMetrics,
  updateSelectedMetrics,
}: {
  metricsMetaData: AggregateRankMetricMetaData[];
  rankedMetrics: string[];
  unrankedMetrics: string[];
  updateSelectedMetrics: Function;
}) => {
  const generateZoneItem = (metric: string, ranked: boolean, index: number) => {
    const metaData = metricsMetaData.find(
      (meta) => meta.metricLabel === metric
    );
    return {
      containerId: ranked ? ContainerIdType.RANKED : ContainerIdType.UNRANKED,
      type: "zone",
      id: uniqueId(),
      hasItem: true,
      name: metric,
      ordinal: index,
      grouping: metaData?.grouping,
      isPlaceholder: false,
      item: {
        containerId: ranked ? ContainerIdType.RANKED : ContainerIdType.UNRANKED,
        id: uniqueId(),
        name: metric,
        grouping: metaData?.grouping ?? "",
        ordinal: index,
      },
    };
  };

  const selectedMetricItems: SimpleZone[] = rankedMetrics
    .map((metricLabel, index) => generateZoneItem(metricLabel, true, index))
    .concat(
      unrankedMetrics.map((metricLabel, index) =>
        generateZoneItem(metricLabel, false, index)
      )
    );

  const availableMetricItems: SimpleItem[] = metricsMetaData
    .filter(
      (metric) =>
        // filter out selected metric items
        !selectedMetricItems
          .map((x) => x.item?.name)
          .includes(metric.metricLabel)
    )
    .map((metricItem, index) => ({
      containerId: ContainerIdType.METRIC,
      id: uniqueId(),
      name: metricItem.metricLabel,
      ordinal: index,
      grouping: metricItem.grouping,
    }));

  const dropdownOptions = [createPanelOption("All types")].concat(
    Array.from(new Set(metricsMetaData.map((x) => x.grouping))).map(
      createPanelOption
    )
  );

  return (
    <div className={styles.modalContentContainer}>
      <MetricsDaDWrapper
        dropdownOptions={dropdownOptions}
        initialItems={availableMetricItems}
        initialStructure={selectedMetricItems}
        updateSelectedMetrics={updateSelectedMetrics}
      />
    </div>
  );
};

export default AggregateRankMetricsContent;
