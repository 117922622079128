/* eslint-disable react/no-this-in-sfc */
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import { useEffect, useMemo } from "react";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import {
  defaultOptions,
  HighchartsReact,
  TooltipHTML,
} from "../highcharts-react/HighchartsReact";
import { type WaterfallChartProps } from "../models";

HighchartsMore(Highcharts);

type WaterfallchartProps = WaterfallChartProps;

export const WaterfallChart = ({
  data,
  dataLabelFormatter,
  showChartDataLabels,
  yAxisLabel,
  yAxisTickFormatter,
  onOptionsChanged,
}: WaterfallchartProps) => {
  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        borderColor: "white",
        style: {
          fontFamily: `var(--qbit-font-family)`,
        },
        type: "waterfall",
      },
      legend: { enabled: false },
      plotOptions: {
        waterfall: {
          borderColor: "white",
          dataLabels: {
            crop: false,
            enabled: showChartDataLabels,
            formatter() {
              return dataLabelFormatter(this.y);
            },
            inside: false,
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontSize: "0.75rem",
              fontWeight: "var(--qbit-font-weight-medium)",
              textOutline: "white",
            },
          },
        },
      },
      series: [{ data, type: "waterfall", label: { enabled: false } }],
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) => {
          const tooltipData = ttData.points?.map((pt) => ({
            color: String(pt.color),
            name: yAxisLabel ? yAxisLabel : pt.key,
            value: dataLabelFormatter(pt.y),
          }));
          return TooltipHTML(
            tooltipData,
            yAxisLabel && ttData.points && ttData.points[0]
              ? ttData.points[0].key
              : undefined
          );
        },
      },
      xAxis: {
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        lineWidth: 0,
        type: "category",
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
      yAxis: {
        ...defaultOptions.yAxis,
        labels: {
          formatter() {
            return yAxisTickFormatter(this.value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          text: yAxisLabel,
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
      },
    }),
    [
      data,
      dataLabelFormatter,
      showChartDataLabels,
      yAxisLabel,
      yAxisTickFormatter,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
