import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type TrialAndRepeatTopDrawerExportRequestDto } from "../models/TrialAndRepeatTopDrawerExportRequestDto";
import { trialAndRepeatApiSlice } from "./trial-and-repeat-api-slice";

export const trialAndRepeatExportApiSlice =
  trialAndRepeatApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      downloadTable: builder.query<
        Blob,
        {
          division: string;
          payload: TrialAndRepeatTopDrawerExportRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `ExportToCsv/${division}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `trial and repeat focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });
export const { useLazyDownloadTableQuery } = trialAndRepeatExportApiSlice;
