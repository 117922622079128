import { type RouteWithLabel } from "@quantium-enterprise/common-ui";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { type RouteObject } from "react-router-dom";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import Reports from "./Reports";
import { route as arRoute } from "./aggregate-rank/AggregateRankReport";
import { route as baRoute } from "./basket-affinities/BasketAffinitiesReport";
import { route as blRoute } from "./basket-limits/BasketLimitsReport";
import { route as bqRoute } from "./basket-quantities/BasketQuantitiesReport";
import { route as cmRoute } from "./compare-metrics/CompareMetricsReport";
import { route as ccsRoute } from "./customer-cross-shop/CustomerCrossShopReport";
import { route as clRoute } from "./customer-loyalty/CustomerLoyaltyReport";
import { route as cpRoute } from "./customer-profiling/CustomerProfilingReport";
import { route as glRoute } from "./gains-and-loss/GainsAndLossReport";
import { route as kdtRoute } from "./key-driver-tree/KeyDriverTreeReport";
import { route as kdotRoute } from "./key-drivers-over-time/KeyDriversOverTimeReport";
import { route as kmtRoute } from "./key-measure-trends/KeyMeasureTrendsReport";
import { route as npbRoute } from "./new-product-benchmarking/NewProductBenchmarkingReport";
import { route as plRoute } from "./pricing-ladders/PricingLaddersReport";
import { route as psRoute } from "./product-substitutability/ProductSubstitutabilityReport";
import { route as rptRoute } from "./repertoire/RepertoireReport";
import { store } from "./store";
import { route as toddowRoute } from "./time-of-day-day-of-week/TimeOfDayDayOfWeekReport";
import { route as tarRoute } from "./trial-and-repeat/TrialAndRepeatReport";

const routes: RouteObject[] = [
  kmtRoute,
  glRoute,
  kdotRoute,
  kdtRoute,
  cpRoute,
  toddowRoute,
  cmRoute,
  baRoute,
  blRoute,
  bqRoute,
  clRoute,
  ccsRoute,
  plRoute,
  arRoute,
  psRoute,
  npbRoute,
  rptRoute,
  tarRoute,
];

export default (): RouteWithLabel => ({
  children: routes,
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <Reports />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Reports",
  path: ``,
});
