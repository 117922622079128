/* eslint-disable canonical/filename-match-regex */
import { ChartColour } from "components-ui/src/charts/ChartColours";
import React, { useEffect, useRef } from "react";
import gbbStyles from "./PricingLaddersGBBSlider.module.css";

export type PricingLaddersGBBChartProps = {
  bestStart: number;
  betterStart: number;
  max: number;
  min: number;
  setBestValue: (value: number) => void;
  setBetterValue: (value: number) => void;
};

export const PricingLaddersGBBSlider: React.FC<PricingLaddersGBBChartProps> = ({
  min,
  max,
  betterStart,
  bestStart,
  setBetterValue,
  setBestValue,
}) => {
  const rangeGood = useRef<HTMLDivElement>(null);
  const rangeBetter = useRef<HTMLDivElement>(null);
  const rangeBest = useRef<HTMLDivElement>(null);
  const divGoodText = useRef<HTMLDivElement>(null);
  const divBetterText = useRef<HTMLDivElement>(null);
  const divBestText = useRef<HTMLDivElement>(null);
  const inputSliderGood = useRef<HTMLInputElement>(null);
  const inputSliderBetter = useRef<HTMLInputElement>(null);
  const divRangesContainer = useRef<HTMLDivElement>(null);
  const stepSize = (max - min) / 100;

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (
        inputSliderGood.current !== null &&
        inputSliderBetter.current !== null
      ) {
        inputSliderGood.current.style.width = `${entries[0].contentRect.width}px`;
        inputSliderBetter.current.style.width = `${entries[0].contentRect.width}px`;
      }
    });
    const referenceDivRangeCurrent = divRangesContainer.current as HTMLElement;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (referenceDivRangeCurrent !== undefined) {
      observer.observe(referenceDivRangeCurrent);
    }

    return () =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      referenceDivRangeCurrent && observer.unobserve(referenceDivRangeCurrent);
  }, []);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const getPercent = (value: number) =>
      Math.round(((value - min) / (max - min)) * 100);
    const minValuePercent = getPercent(min);
    const maxValuePercent = getPercent(max);
    const betterStartvalPercent = getPercent(betterStart);
    const bestStartvalPercent = getPercent(bestStart);

    // set the width of the ranges based on input slider and also make text visible only if width of range is more than 60px
    let width = 0;
    let element = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (
      rangeGood.current !== null &&
      rangeBetter.current !== null &&
      rangeBest.current !== null &&
      divGoodText.current !== null &&
      divBetterText.current !== null &&
      divBestText.current !== null
    ) {
      rangeGood.current.style.width = `${
        betterStartvalPercent - minValuePercent
      }%`;
      element = rangeGood.current;
      width = element.clientWidth || element.getBoundingClientRect().width;

      if (width && width < 60) {
        divGoodText.current.style.display = "none";
      } else {
        divGoodText.current.style.display = "block";
      }

      rangeBetter.current.style.width = `${
        bestStartvalPercent - betterStartvalPercent
      }%`;
      element = rangeBetter.current;
      width = element.clientWidth || element.getBoundingClientRect().width;
      if (width && width < 60) {
        divBetterText.current.style.display = "none";
      } else {
        divBetterText.current.style.display = "block";
      }

      rangeBest.current.style.width = `${
        maxValuePercent - bestStartvalPercent
      }%`;
      element = rangeBest.current;
      width = element.clientWidth || element.getBoundingClientRect().width;
      if (width && width < 60) {
        divBestText.current.style.display = "none";
      } else {
        divBestText.current.style.display = "block";
      }
    }
  }, [min, betterStart, bestStart, max]);

  return (
    <div className={gbbStyles.container}>
      <div className={gbbStyles.sliderContainer}>
        <input
          className={`${gbbStyles.thumbLeft} ${gbbStyles.thumb}`}
          max={max}
          min={min}
          onChange={(event) => {
            const value =
              Math.round(
                (Math.min(Number(event.target.value), bestStart) +
                  Number.EPSILON) *
                  100
              ) / 100;
            setBetterValue(value);
          }}
          ref={inputSliderGood}
          step={stepSize}
          type="range"
          value={betterStart}
        />
        <input
          className={`${gbbStyles.thumb}  ${gbbStyles.thumbRight}`}
          max={max}
          min={min}
          onChange={(event) => {
            const value =
              Math.round(
                (Math.max(Number(event.target.value), betterStart) +
                  Number.EPSILON) *
                  100
              ) / 100;
            setBestValue(value);
          }}
          ref={inputSliderBetter}
          step={stepSize}
          type="range"
          value={bestStart}
        />
      </div>

      <div className={gbbStyles.rangeContainer}>
        <div className={gbbStyles.rangeTextNumberLeft}>{min}</div>
        <div
          className={gbbStyles.containerRanges}
          id="divRangesContainer"
          ref={divRangesContainer}
        >
          <div className={gbbStyles.verticalLinesRange} />
          <div
            className={gbbStyles.sliderRanges}
            ref={rangeGood}
            style={{ backgroundColor: ChartColour.BrandCyan800 }}
            title={"Good : " + min + " - " + betterStart}
          >
            <div
              className={gbbStyles.rangeText}
              id="divGoodText"
              ref={divGoodText}
            >
              Good{" "}
            </div>
            <div className={gbbStyles.rangeTextNumber}>{betterStart} </div>
          </div>

          <div
            className={gbbStyles.sliderRanges}
            ref={rangeBetter}
            style={{ backgroundColor: ChartColour.BrandTurquoiseNew }}
            title={"Better : " + betterStart + " - " + bestStart}
          >
            <div
              className={gbbStyles.rangeText}
              id="divBetterText"
              ref={divBetterText}
            >
              {" "}
              Better{" "}
            </div>
            <div className={gbbStyles.rangeTextNumber}>{bestStart} </div>
          </div>

          <div
            className={gbbStyles.sliderRanges}
            ref={rangeBest}
            style={{ backgroundColor: ChartColour.BrandYellowNew }}
            title={"Best : " + bestStart + " - " + max}
          >
            <div
              className={gbbStyles.rangeText}
              id="divBestText"
              ref={divBestText}
            >
              {" "}
              Best{" "}
            </div>
            <div className={gbbStyles.rangeTextNumber}>{max} </div>
          </div>
          <div className={gbbStyles.verticalLinesRange} />
        </div>
      </div>
    </div>
  );
};
