import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type WizardState = {
  currentTab: number;
  isFirst: boolean;
  isLast: boolean;
  segmentationModalShown: boolean;
  subTitle: string;
  tabLength: number;
  tabs: string[];
  title: string;
  visitedTabs: Record<string, boolean>;
};

const initialState: WizardState = {
  currentTab: 0,
  isFirst: true,
  isLast: false,
  segmentationModalShown: false,
  subTitle: "",
  title: "",
  tabLength: 0,
  tabs: [],
  visitedTabs: {},
};

export const wizardSlice = createSlice({
  initialState,
  name: "wizard",
  reducers: {
    goToEnd: (state: WizardState) => {
      if (state.tabLength) {
        state.visitedTabs[state.tabs[state.currentTab]] = true;
        state.currentTab = state.tabLength - 1;
        state.isFirst = false;
        state.isLast = true;
      }
    },
    goToStart: (state: WizardState) => {
      state.visitedTabs[state.tabs[state.currentTab]] = true;
      state.currentTab = 0;
      state.isFirst = true;
      state.isLast = false;
    },
    nextTab: (state: WizardState) => {
      if (state.currentTab < state.tabLength) {
        state.visitedTabs[state.tabs[state.currentTab]] = true;
        state.currentTab += 1;
        state.isFirst = state.currentTab === 0;
        state.isLast = state.currentTab === state.tabLength - 1;
      }
    },
    previousTab: (state: WizardState) => {
      if (state.currentTab > 0) {
        state.visitedTabs[state.tabs[state.currentTab]] = true;
        state.currentTab -= 1;
        state.isFirst = state.currentTab === 0;
        state.isLast = state.currentTab === state.tabLength - 1;
      }
    },
    setSegmentationModalShown: (
      state: WizardState,
      action: PayloadAction<boolean>
    ) => {
      state.segmentationModalShown = action.payload;
    },
    setSubTitle: (state: WizardState, action: PayloadAction<string>) => {
      state.subTitle = action.payload;
    },
    setTitle: (state: WizardState, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setVisitedTabs: (state: WizardState, action: PayloadAction<number>) => {
      if (action.payload >= 0 && action.payload <= state.tabLength) {
        for (let index = 0; index < action.payload; index++) {
          state.visitedTabs[state.tabs[index]] = true;
        }
      }
    },
    setTabIndex: (state: WizardState, action: PayloadAction<number>) => {
      if (action.payload >= 0 && action.payload < state.tabLength) {
        // if the user skips the compulsory parameter group tabs and moves to last tabs,
        // then the error banner/icon should apppear for the not visited tabs also
        for (let index = 0; index < action.payload; index++) {
          state.visitedTabs[state.tabs[index]] = true;
        }

        state.visitedTabs[state.tabs[state.currentTab]] = true;
        state.currentTab = action.payload;
        state.isFirst = action.payload === 0;
        state.isLast = action.payload === state.tabLength - 1;
      }
    },
    setTabs: (state: WizardState, action: PayloadAction<string[]>) => {
      state.tabs = action.payload;
      state.tabLength = action.payload.length;
      state.isFirst = true;
      state.isLast = false;
      state.currentTab = 0;
    },
    resetTitle: (state: WizardState) => {
      state.title = initialState.title;
    },
    resetAndSetTabs: (state: WizardState, action: PayloadAction<string[]>) => ({
      ...initialState,
      tabs: action.payload,
      tabLength: action.payload.length,
      title: state.title,
    }),
    resetTabs: (state: WizardState) => ({
      ...initialState,
      title: state.title,
    }),
  },
});

export const {
  nextTab,
  goToEnd,
  goToStart,
  previousTab,
  setSegmentationModalShown,
  setSubTitle,
  setTabIndex,
  setVisitedTabs,
  setTitle,
  setTabs,
  resetTitle,
  resetTabs,
  resetAndSetTabs,
} = wizardSlice.actions;

export default wizardSlice.reducer;
