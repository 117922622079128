import { type DataPoint } from "./DataPoint";

export type SegmentationSelection = {
  segment: string;
  segmentation: string;
};

export type LocalHierarchySelection = {
  itemCode: string;
  shortName: string;
};

export type ChartLocalParameterSelections = {
  breakdown: string;
  channel?: string;
  location: number;
  product: LocalHierarchySelection;
  promotion?: string;
  segmentation?: SegmentationSelection;
};

export enum BreakdownByParameterId {
  Promotion = "2",
  Segmentation = "1",
  Total = "0",
}

export type Series = {
  // e.g. "Total", "Promotion", "Segmentation"
  breakdown: string;
  data: DataPoint[];
  // e.g. "Number", "Percentage", "Currency"
  format: string;
  metricKey: string;
  // e.g. "Baskets", "Baskets (%)", "Sales", etc.
  metricLabel: string;
  name: string;
};
