import {
  type SelectionDto,
  isListSelectionDto,
  type ReportParametersDto,
  ReportType,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import {
  HighchartsReact,
  type HighchartsReactProps,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { RepertoireChartCustomTooltip } from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import { ReportIcon } from "components-ui/src/icons";
import {
  type PointOptionsObject,
  type TooltipFormatterContextObject,
} from "highcharts";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { type RootState } from "../../../store";
import { RepertoireFeatureFlags } from "../../constants/repertoire-feature-flags";
import {
  selectLocalParameterViewAs,
  selectRepertoireItems,
  selectReportletResponseDto,
  selectTableResponseDto,
  selectedLocalParameterMetric,
  selectLocalParameters,
  selectShowRepertoireChartDataLabels,
  toggleRepertoireChartDataLabels,
} from "../../services/repertoire-slice";
import { VIEW_AS_SHARE } from "../../utils/constants";
import { getLocalParameterSummary } from "../../utils/export-utils";
import styles from "./RepertoireReportletChart.module.css";
import { csvTransformation } from "./csvTransformation";

export type RepertoireReportletChartProps = {
  isLoading: boolean;
  noData: boolean;
  reportParameters?: ReportParametersDto;
};

export const RepertoireReportletChart = ({
  isLoading,
  noData,
  reportParameters,
}: RepertoireReportletChartProps) => {
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);
  const reportletDto = useSelector(selectReportletResponseDto);
  const focalItemTableResponse = useSelector(selectTableResponseDto);
  const levelOfAnalysisShortname = useSelector(
    selectTableResponseDto
  ).levelOfAnalysis;
  const selectedMetric = useSelector(selectedLocalParameterMetric);
  const viewAs = useSelector(selectLocalParameterViewAs);
  const { localParameterSelections } = useSelector(selectLocalParameters);
  const showRepertoireChartDataLabel = useSelector(
    selectShowRepertoireChartDataLabels
  );
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[RepertoireFeatureFlags.ReportExport] ?? false;

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const levelOfAnalysisName = useMemo(() => {
    const selection =
      reportParameters?.parameterGroupSelections
        .flatMap((item) => item.parameterSelections)
        .find((item) => item.name === "Level of analysis")?.selections[0] ??
      ({} as SelectionDto);

    return isListSelectionDto(selection) ? selection.label : "";
  }, [reportParameters]);

  // Find all matching entities (dicitionary keys)
  const selectedRepertoireItems = useSelector(selectRepertoireItems);
  const repertoireItems = useMemo(
    () =>
      focalItemTableResponse.focalItemData.filter((item) =>
        selectedRepertoireItems.includes(item.id)
      ),
    [focalItemTableResponse.focalItemData, selectedRepertoireItems]
  );

  const metricDisplayLabel = useMemo(
    () =>
      `${selectedMetric.label}${
        (viewAs.value as string) === VIEW_AS_SHARE ? " - % share" : ""
      }`,
    [selectedMetric, viewAs]
  );

  // Chart categories are just the focal items retured from response.
  // When viewAs = SHARE there is the extra "Overall" focal item
  const categories = reportletDto.focalItemColumns.map(
    (item) => `${reportletDto.crossProductData[item]?.name ?? item}`
  );

  const yAxis: Highcharts.YAxisOptions[] = [
    {
      plotLines: [
        {
          value: 0,
          color: "var(--qbit-colour-shade-6)",
          width: 2,
          zIndex: 1,
        },
      ],
      labels: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
          const self = this;
          const value = self.value;
          const metricFormatter =
            self.axis.series[0].userOptions.custom?.metricFormat;
          return formatter(metricFormatter)(value, false, "", true);
        },
        style: {
          color: "var(--qbit-colour-text-secondary)",
        },
      },
      title: {
        style: { fontWeight: "bold", color: "var(--qbit-colour-text-primary)" },
        text: metricDisplayLabel,
      },
      reversedStacks: false,
      visible: true,
      endOnTick: false,
    },
  ];

  // Create the data series for the chart - Each series is a "layer" of the stacked bar
  const newSeries: Highcharts.SeriesColumnOptions[] = [];

  for (const [index, item] of repertoireItems.entries()) {
    const data = reportletDto.crossProductData[item.id];
    newSeries.push({
      data: data?.values.map((metric) => ({
        y: metric === null ? 0 : metric,
      })),
      color: getColourByIndex(index),
      name: `(${hierarchyLevelDisplayLabel(levelOfAnalysisShortname)}) ${
        data?.name
      }`,
      custom: {
        metricFormat: reportletDto.metricFormat,
      },
      type: "column",
    });
  }

  // If our results contains the "Other" values then it is our last stack
  if (reportletDto.otherValues) {
    newSeries.push({
      data: reportletDto.otherValues.data.map((metric) => ({
        y: metric === null ? 0 : metric,
      })),

      // Custom colour defined by UX
      color: "#93908E",
      name: "OTHERS",
      custom: {
        metricFormat: reportletDto.otherValues.format,
      },
      type: "column",
    });
  }

  // We display the total values at the top of each stack if viewAs=Share
  // This function take the last series and modifies it to provide that top value
  if ((viewAs.value as string) === VIEW_AS_SHARE) {
    const lastSeries = newSeries.pop();
    if (lastSeries?.data) {
      const totalData = reportletDto.totalValues;
      newSeries.push({
        ...lastSeries,
        data: lastSeries.data.map((metric, index) => {
          const point = metric as PointOptionsObject;
          return {
            y: point.y,
            custom: {
              dataLabel: formatter(totalData.format)(totalData.data[index]),
            },
          };
        }),

        dataLabels: [
          {
            enabled: showRepertoireChartDataLabel,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              const metricFormat = self.series.userOptions.custom?.metricFormat;
              return formatter(metricFormat)(value);
            },
          },
          {
            enabled: true,
            format: "{point.custom.dataLabel}",
            verticalAlign: "top",
            y: -20,
          },
        ],
      });
    }
  }

  for (const [index, seriesDataItem] of newSeries.entries()) {
    seriesDataItem.visible = !hiddenSeries.includes(index);
  }

  const options: HighchartsReactProps = {
    ...defaultOptions,
    legend: {
      ...defaultOptions.legend,
      symbolRadius: 0,
    },
    xAxis: {
      categories,
      crosshair: {
        color: "var(--qbit-colour-chrome-background)",
        zIndex: 0,
      },
      visible: true,
      plotLines:
        (viewAs.value as string) === VIEW_AS_SHARE
          ? [
              {
                color: "#ececec",
                width: 2,
                value: 0.5,
                dashStyle: "dash",
              },
            ]
          : [],
      title: {
        style: {
          color: "var(--qbit-colour-text-primary)",
        },
      },
      labels: {
        style: {
          color: "var(--qbit-colour-text-secondary)",
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- typescript cant handle all the customising
    } as any,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yAxis: yAxis as any,
    tooltip: {
      ...defaultOptions.tooltip,
      ReactFormatter: (ttData) =>
        RepertoireChartCustomTooltip({
          pointFormatter: (pt: TooltipFormatterContextObject) =>
            formatter(pt.series.userOptions.custom?.metricFormat),
          ttData,
          yAxisTitle: metricDisplayLabel,
          otherSeriesPresent: Boolean(reportletDto.otherValues),
          maxItemsShown: 12,
        }),
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: showRepertoireChartDataLabel,
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.y;
            const metricFormat = self.series.userOptions.custom?.metricFormat;
            return formatter(metricFormat)(value);
          },
        },
        pointPadding: 0,
        stacking: "normal",
        events: {
          legendItemClick: (event) => legendItemClick(event.target.index),
        },
      },
      line: {
        marker: {
          enabled: false,
        },
        shadow: {
          color: "white",
          offsetX: 0,
          offsetY: 0,
          opacity: 100,
          width: 3,
        },
        zIndex: 1,
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- typescript having trou
    series: newSeries as any,
  };

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        repertoireItems.map((item) => item.id),
        reportletDto,
        metricDisplayLabel,
        viewAs.value as string,
        currencySymbol
      ),
    [repertoireItems, reportletDto, viewAs, metricDisplayLabel, currencySymbol]
  );
  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Repertoire_Chart_${localParameterSelections.TimePeriod}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const parameterSummary = useMemo(
    () =>
      getLocalParameterSummary(
        localParameterSelections,
        repertoireItems.map((item) => item.name),
        levelOfAnalysisName
      ),
    [localParameterSelections, repertoireItems, levelOfAnalysisName]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showRepertoireChartDataLabel,
      value: "",
    },
  ];

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.repertoire.metaData.reportName,
  }));

  return (
    <div className={styles.chartOptionsContainer}>
      <ChartFooterWrapper
        height="480px"
        parameters={parameterSummary}
        ref={chartContainerRef}
      >
        <ErrorBoundary>
          <NoDataChartWrapper isLoading={isLoading} noData={noData}>
            <div className={styles.chartContainerWrapper}>
              <HighchartsReact options={options} />
            </div>
          </NoDataChartWrapper>
        </ErrorBoundary>
      </ChartFooterWrapper>
      <div className={styles.container}>
        <ChartOptions
          dataLabelsOptions={dataLabelOptions}
          downloadWizardOptions={{
            chartOptions: options,
            reportIcon: <ReportIcon type={ReportType.Repertoire} />,
            chartTitle: `Repertoire - ${reportName}`,
            reportTitle: reportName,
          }}
          filename={exportFilename}
          getCSVData={chartCsvTransformationCallback}
          getElementToExport={() => chartContainerRef.current}
          isFeatureEnabled={isExportEnabled}
          localParameters={parameterSummary}
          reportParameters={reportParameters}
          toggleDataLabels={() => {
            dispatch(toggleRepertoireChartDataLabels());
          }}
        />
      </div>
    </div>
  );
};

export default RepertoireReportletChart;
