import { type RepertoireFocalItemRow } from "../services/repertoire-top-drawer-api-slice";

export const GetAutoSelectedItems = (
  autoSelectValue: string,
  autoSelectNumber: number,
  rows: RepertoireFocalItemRow[]
): string[] => {
  const result: string[] = [];

  if (autoSelectValue === "") {
    for (
      let index = 0;
      index < Math.min(autoSelectNumber, rows.length);
      index++
    ) {
      result.push(rows[index].id.toString());
    }
  } else {
    const threshold = Number.parseFloat(autoSelectValue) / 100;

    for (const entry of rows) {
      const value = entry.value;
      if (value !== undefined && value >= threshold) {
        result.push(entry.id.toString());
      }
    }
  }

  return result;
};

export const InsertSorted = (
  selectedItems: string[],
  focalItemTableRowOrder: RepertoireFocalItemRow[]
) => {
  const orderIndex = new Map(
    focalItemTableRowOrder.map((item, index) => [item.id, index])
  );
  return selectedItems.sort(
    (a, b) => (orderIndex.get(a) ?? -1) - (orderIndex.get(b) ?? -1)
  );
};
