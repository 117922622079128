import classNames from "classnames";
import styles from "./SeriesIcon.module.css";

export type SeriesIconProps = {
  benchmark?: boolean;
  color?: string;
  dashed?: boolean;
  seriesType?: string;
  wideIcon?: boolean;
};

export const SeriesIcon = ({
  benchmark,
  color,
  dashed,
  wideIcon,
  seriesType,
}: SeriesIconProps) => (
  <svg
    className={classNames(
      styles.seriesIcon,
      {
        [styles.line]: seriesType === "line",
        [styles.bar]:
          seriesType === "bar" ||
          seriesType === "column" ||
          seriesType === "columnrange",
        [styles.default]: !seriesType,
      },
      { [styles.benchmark]: benchmark },
      {
        [styles.dashed]: dashed,
      },
      { [styles.wide]: wideIcon }
    )}
    style={{
      // eslint-disable-next-line no-negated-condition
      backgroundColor: !(benchmark || dashed)
        ? color ?? "var(--qbit-colour-black)"
        : undefined,
    }}
  >
    <line
      className={classNames(styles.seriesIcon, {
        [styles.benchmark]: benchmark,
      })}
      stroke={dashed ? color : "none"}
      x1="0"
      x2="12"
    />
  </svg>
);
