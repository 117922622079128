import {
  type RouteWithLabel,
  REPORT_CREATOR_PATH,
} from "@quantium-enterprise/common-ui";
import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { store } from "./store";

// eslint-disable-next-line arrow-body-style, @typescript-eslint/promise-function-async -- this is as per React docs
const AvailableReports = lazy(() => import("./AvailableReports"));

export default (): RouteWithLabel => ({
  children: [],
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>Loading...</>}>
        <Provider store={store}>
          <AvailableReports />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Report creator",
  path: REPORT_CREATOR_PATH,
});
