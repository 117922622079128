import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { EXPORT_LOCATION_SUFFIX } from "../../common/constants";
import { getExportParameterSummary } from "../../common/utils/export-parameter-summary-utils";
import { BasketlimitsFeatureFlags } from "../constants/basket-limits-feature-flags";
import { type ReportletRequestDto } from "../models/basket-limits-reportlet-models";
import { useLazyGetReportletDataQuery } from "../services/basket-limits-reportlet-api-slice";
import {
  onStoreBreakdownSearchChange,
  onIsFetchingReportletDataChange,
  selectBasketLimit,
  selectFocalItem,
  selectLocalParametersInitialised,
  selectStoreBreakdownSearch,
  selectTotalTimesExceeded,
  selectStoreBreakdownRows,
  selectLocation,
  selectTime,
  selectTimePeriodLength,
  selectReportId,
} from "../services/basket-limits-slice";
import styles from "./StoreBreakdownReportlet.module.scss";
import { StoreBreakdownTable } from "./StoreBreakdownTable";
import { csvTransformationStoreBreakdown } from "./csvTransformation";

type StoreBreakdownReportletProps = {
  reportParameters?: ReportParametersDto;
};

export const StoreBreakdownReportlet = ({
  reportParameters,
}: StoreBreakdownReportletProps) => {
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();

  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const id = useSelector(selectReportId);
  const searchQuery = useSelector(selectStoreBreakdownSearch);
  const totalTimesExceeded = useSelector(selectTotalTimesExceeded);
  const storeBreakdownRows = useSelector(selectStoreBreakdownRows);
  const location = useSelector(selectLocation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const storeBreakdownTableIsEmpty = storeBreakdownRows.length === 0;
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketlimitsFeatureFlags.ReportExport] ?? false;

  const focalItem = useSelector(selectFocalItem);
  const basketLimit = useSelector(selectBasketLimit);

  const handleSearchChange = useCallback(
    (searchText: string) => {
      dispatch(onStoreBreakdownSearchChange(searchText));
    },
    [dispatch]
  );

  const isDataLoaded =
    localParametersInitialised &&
    Boolean(reportId) &&
    Boolean(focalItem.itemCode);
  const [getReportletDataTrigger, { isUninitialized, isFetching, isError }] =
    useLazyGetReportletDataQuery();
  useEffect(() => {
    if (reportId === id && isDataLoaded) {
      dispatch(onIsFetchingReportletDataChange(true));
      getReportletDataTrigger({
        divisionName,
        payload: {
          parameterSelections: {
            product: focalItem,
            minBasketLimit: basketLimit,
          },
          reportId,
          searchQuery,
        } as ReportletRequestDto,
      }).catch((error) => {
        ddLog("ERROR", {}, "error", error);
        dispatch(onIsFetchingReportletDataChange(false));
      });
    }
  }, [
    dispatch,
    getReportletDataTrigger,
    isDataLoaded,
    divisionName,
    reportId,
    searchQuery,
    focalItem,
    basketLimit,
    id,
  ]);
  const noData = totalTimesExceeded === 0;
  // Memoize the component for performance and to avoid freezing when changing
  // the basket limit slider filter local parameter.
  const storeBreakdownTable = useMemo(() => <StoreBreakdownTable />, []);
  const searchBox = useMemo(
    () => (
      <SearchBox
        debounceTimeMs={500}
        enableDebounce
        onChange={handleSearchChange}
        placeholder="Search store name, store number, state"
        searchQuery={searchQuery}
      />
    ),
    [handleSearchChange, searchQuery]
  );

  const filename = useMemo(
    () =>
      cleanFilename(
        `Store_breakdown_${timePeriodLength}_${getExportParameterSummary(
          location.map((selection) => selection.name).join(","),
          EXPORT_LOCATION_SUFFIX
        )}`
      ),
    [timePeriodLength, location]
  );

  const localParameters = useMemo(
    () => [
      { name: "Focal item", value: focalItem.name },
      { name: "Time", value: time },
      { name: "Basket limit", value: basketLimit.toString() },
      {
        name: "Location",
        value: getExportParameterSummary(
          location
            .map(
              (selection) =>
                `(${hierarchyLevelDisplayLabel(selection.shortName)}) ${
                  selection.name
                }`
            )
            .join(", "),
          EXPORT_LOCATION_SUFFIX
        ),
      },
    ],
    [time, focalItem, location, basketLimit]
  );

  const chartCsvTransformationCallback = useCallback(
    () => csvTransformationStoreBreakdown(storeBreakdownRows),
    [storeBreakdownRows]
  );

  return (
    <ReportletAccordion
      subtitle={`Basket limit exceeded ${totalTimesExceeded} times.`}
      title="Store breakdown"
    >
      <div className={styles.searchOptionsContainer}>
        {searchBox}
        <DataTableOptions
          filename={filename}
          invokeCSVDownload={chartCsvTransformationCallback}
          isFeatureEnabled={isExportEnabled}
          localParameters={localParameters}
          reportParameters={reportParameters}
        />
      </div>
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={isUninitialized || isFetching || isError}
          minHeight="500px"
          noData={isDataLoaded && noData}
        >
          <div
            className={styles.storeBreakdownContainer}
            id="storeBreakdownDataTable"
          >
            {storeBreakdownTable}
            {storeBreakdownTableIsEmpty && (
              <div className={styles.storeBreakdownEmptyResultsContainer}>
                <EmptySearch />
              </div>
            )}
          </div>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
