import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { MeasureThresholdSelectionSummary } from "components-ui/src/parameter-selection-summary/measure-threshold-selection-summary/MeasureThresholdSelectionSummary";
import { getMeasureThresholdParameterState } from "../../parameters/measure-threshold/MeasureThresholdParameterState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelMeasureThresholdProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelMeasureThreshold = ({
  parameterDto,
}: SummaryPanelMeasureThresholdProps) => {
  const selections = useAppSelector(
    getMeasureThresholdParameterState(parameterDto.id)
  ).selections;

  const completeSelections = selections
    .filter((selection) => selection.threshold !== undefined)
    .map((selection) => ({
      measure: selection.measure,
      comparator: selection.comparator,
      threshold: {
        number: selection.threshold as number,
        format: selection.measure.thresholdFormat,
      },
    }));

  return <MeasureThresholdSelectionSummary selections={completeSelections} />;
};
