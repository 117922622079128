import {
  useNumberFormat,
  type FormatterFunction,
} from "@quantium-enterprise/hooks-ui";
import { type FormBlockEditability } from "@quantium-enterprise/qds-react";
import { RangeSlider } from "../../range-slider/RangeSlider";
import CollapsiblePanel from "../CollapsiblePanel";
import styles from "./SliderFilter.module.css";

export type SliderFilterProps = {
  currentValue?: number;
  editability: FormBlockEditability;
  endValues: { maximum: number; minimum: number };
  formatter?: FormatterFunction;
  isCollapsed: boolean;
  isInverted?: boolean;
  onChange?: (value: number) => void;
  percentageThresholds?: { maximum: number; minimum: number };
  stepSize?: number;
  summary?: string;
  title: string;
  toggleCollapsed: () => void;
};

const defaultOnChange = () => {};
const defaultThresholds = {
  minimum: 1,
  maximum: 97,
};

export const SliderFilter = ({
  currentValue,
  endValues,
  isCollapsed,
  isInverted = true,
  editability,
  onChange = defaultOnChange,
  percentageThresholds = defaultThresholds,
  stepSize = 1,
  summary,
  title,
  toggleCollapsed,
  formatter,
}: SliderFilterProps) => {
  const { integerFormatter } = useNumberFormat();

  const usedFormatter = formatter ?? integerFormatter;

  const summaryText = `${usedFormatter(currentValue)} - ${usedFormatter(
    endValues.maximum
  )}`;
  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content: (
          <div className={styles.slider}>
            <RangeSlider
              editability={editability}
              formatter={usedFormatter}
              inverseSliderColor={isInverted}
              onChange={onChange}
              percentageThresholds={percentageThresholds}
              rangeValues={endValues}
              step={stepSize}
              value={currentValue}
            />
          </div>
        ),
        summary: summary ?? summaryText,
      }}
    </CollapsiblePanel>
  );
};
