import { type PanelOption } from "../local-parameters-panel/FixedSidePanel";
import { CollapsiblePanel } from "./CollapsiblePanel";
import NoEditingPanel from "./NoEditingPanel";
import SingleSelectDropdown from "./SingleSelectDropdown";
import SingleSelectRadio from "./SingleSelectRadio";

export enum SingleSelectType {
  Dropdown = 0,
  Radio = 1,
}

export type SingleSelectProps = {
  isCollapsed?: boolean;
  isDisabled?: boolean;
  onSelection: (value: PanelOption) => void;
  selectOptions: PanelOption[];
  selectType: SingleSelectType;
  selectedValue?: string;
  title: string;
  toggleCollapsed?: () => void;
};
const defaultToggleCollapsed = () => {};
export const SingleSelectFilter = ({
  title,
  selectOptions,
  selectType,
  selectedValue,
  isCollapsed,
  isDisabled,
  onSelection,
  toggleCollapsed = defaultToggleCollapsed,
}: SingleSelectProps) => {
  let activePanel;
  switch (selectType) {
    case SingleSelectType.Dropdown:
      activePanel = (
        <SingleSelectDropdown
          isDisabled={isDisabled}
          onSelection={onSelection}
          selectOptions={selectOptions}
          selectedValue={selectedValue}
          title={title}
        />
      );
      break;
    case SingleSelectType.Radio:
      activePanel = (
        <SingleSelectRadio
          isDisabled={isDisabled}
          onSelection={onSelection}
          selectOptions={selectOptions}
          selectedValue={selectedValue}
          title={title}
        />
      );
      break;
    default:
      throw new RangeError("Unknown single select filter type");
  }

  if (selectOptions.length > 1) {
    const foundOption = selectOptions.find(
      (item) => item.value === selectedValue
    );
    return (
      <CollapsiblePanel
        isCollapsed={isCollapsed}
        isDisabled={isDisabled}
        title={title}
        toggleCollapsed={toggleCollapsed}
      >
        {{
          content: activePanel,
          summary: <div>{foundOption?.labelElement ?? foundOption?.label}</div>,
        }}
      </CollapsiblePanel>
    );
  }

  if (selectOptions.length === 1) {
    return (
      <NoEditingPanel title={title}>
        {selectOptions[0].labelElement ?? selectOptions[0].label}
      </NoEditingPanel>
    );
  }

  // no select options
  return null;
};

export default SingleSelectFilter;
