import { LocalParameters } from "../../common/models/LocalParameterId";
import { type TrialAndRepeatParameterSelections } from "../models/TrialAndRepeatParameterSelections";
import { type TrialAndRepeatTableRequestDto } from "../models/TrialAndRepeatTableRequestDto";

const TABLE_PAGE_SIZE = 50;

export const getTableRequestDto = (
  activeTab: string,
  localSelections: TrialAndRepeatParameterSelections,
  page: number,
  searchQuery: string,
  transactionNumber: string | null
): TrialAndRepeatTableRequestDto => {
  const payload: TrialAndRepeatTableRequestDto = {
    loaShortName: activeTab,
    localSelectedValues: [
      {
        id: LocalParameters.Metric,
        values: [localSelections.metric.value as string],
      },
      {
        id: LocalParameters.Channel,
        values: [localSelections.channel.value as string],
      },
      {
        id: LocalParameters.Promotion,
        values: [localSelections.promotion.value as string],
      },
      {
        id: LocalParameters.Segmentation,
        values: localSelections.segmentation.map(
          (index) => index.value as string
        ),
      },
      {
        id: LocalParameters.LocationHierarchy,
        values: [localSelections.LocationHierarchy.nodeNumber.toString()],
      },
    ],
    transactionNumber: transactionNumber === "" ? null : transactionNumber,
    page,
    pageSize: TABLE_PAGE_SIZE,
    searchQuery,
  };

  return payload;
};
