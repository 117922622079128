type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const AggregateRankIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Aggregate rank report"}</title>
      <path
        d="M9.3325 9.50005C9.3325 9.36198 9.44443 9.25005 9.5825 9.25005L14.4175 9.25005C14.5556 9.25005 14.6675 9.36198 14.6675 9.50005L14.6675 22L9.3325 22L9.3325 9.50005Z"
        fill={colour2}
      />
      <path
        d="M2 14C2 13.862 2.11193 13.75 2.25 13.75H7.0825C7.22057 13.75 7.3325 13.862 7.3325 14L7.3325 22H2L2 14Z"
        fill={colour3}
      />
      <path
        d="M16.6675 17.4133C16.6675 17.2752 16.7794 17.1633 16.9175 17.1633L21.75 17.1633C21.8881 17.1633 22 17.2752 22 17.4133V22H16.6675L16.6675 17.4133Z"
        fill={colour1}
      />
      <path
        d="M11.6372 2.18181C11.7121 1.95148 12.0379 1.95148 12.1128 2.18181L12.745 4.12761C12.7785 4.23061 12.8744 4.30035 12.9828 4.30035H15.0287C15.2709 4.30035 15.3716 4.61026 15.1756 4.75261L13.5204 5.95517C13.4328 6.01884 13.3962 6.13168 13.4296 6.23468L14.0618 8.18048C14.1367 8.41081 13.8731 8.60234 13.6771 8.45999L12.0219 7.25742C11.9343 7.19376 11.8157 7.19376 11.7281 7.25742L10.0729 8.45999C9.87693 8.60234 9.61331 8.41081 9.68815 8.18048L10.3204 6.23468C10.3538 6.13168 10.3172 6.01884 10.2296 5.95517L8.57437 4.75261C8.37844 4.61026 8.47913 4.30035 8.72131 4.30035L10.7672 4.30035C10.8756 4.30035 10.9715 4.23061 11.005 4.12761L11.6372 2.18181Z"
        fill={colour1}
      />
    </svg>
  );
};
