import { type Division, type UserDto } from "@quantium-enterprise/common-ui";
import {
  Dropdown,
  DropdownTriggerButton,
  Icon,
  IconGlyph,
  Menu,
  MenuItemLink,
  MenuSection,
  Text,
} from "@quantium-enterprise/qds-react";
import { type RefObject, forwardRef } from "react";
import styles from "./DivisionDropdown.module.css";

export type DivisionDropdownProps = {
  activeDivision: Division;
  user: UserDto;
};

const DivisionDropdown = forwardRef(
  ({ user, activeDivision }: DivisionDropdownProps, ref) => (
    <Dropdown
      className={styles.divisionDropdown}
      ref={ref as RefObject<Dropdown>}
      trigger={
        <DropdownTriggerButton
          className={styles.triggerButton}
          data-testid="division-dropdown-trigger-button"
          text=""
        />
      }
    >
      <Menu>
        {user.divisions.map((division) => (
          <MenuSection className={styles.menuSection} key={division.name}>
            <MenuItemLink
              data-testid="division-menu-item-link"
              href={`/${division.name}`}
              iconEnd={
                activeDivision.name === division.name && (
                  <Icon
                    className={styles.tickIcon}
                    glyph={IconGlyph.SelectionSuccess}
                    text={`${division.displayName} selected`}
                  />
                )
              }
              selected={activeDivision.name === division.name}
              text={<Text>{division.displayName}</Text>}
            />
          </MenuSection>
        ))}
      </Menu>
    </Dropdown>
  )
);

export { DivisionDropdown };
