import { GainsAndLossChartColours } from "../models/GainsAndLossChartColours";
import {
  type GainsAndLossMetricValue,
  type GainsAndLossOverviewItem,
  type GainsAndLossOverviewMetric,
  type waterfallChartBar,
} from "../models/GainsAndLossOverviewReportletDto";
import {
  GainsAndLossMetrics,
  MetricIdTypes,
} from "../models/GainsAndLossReportValues";
import { getMetrics } from "./common-utils";

export const getWaterfallChartBarColour = (value: number) => {
  if (value >= 0) {
    return GainsAndLossChartColours.Gain;
  } else {
    return GainsAndLossChartColours.Loss;
  }
};

export const formatWaterfallChartBarColour = (
  waterfallChartBars: waterfallChartBar[]
) => {
  if (waterfallChartBars.length !== 0) {
    waterfallChartBars[0].color = "#D3D3D3";
    waterfallChartBars[
      waterfallChartBars.length - 1
    ].color = `var(--qbit-colour-brand-500)`;
    waterfallChartBars[waterfallChartBars.length - 1].isIntermediateSum =
      Boolean(waterfallChartBars[waterfallChartBars.length - 1].y);
  }
};

export const getWaterfallChartMetricValue = (
  metricValues: GainsAndLossMetricValue[],
  metricValueId: string
) => {
  const metric = metricValues.find(
    (metricValue) => metricValue.id === metricValueId
  );

  if (metric) {
    return metric;
  } else {
    return null;
  }
};

export const createWaterfallChartBars = (
  waterfallMetrics: GainsAndLossOverviewMetric[],
  metricId: string
) => {
  const waterfallChartBars: waterfallChartBar[] = [];

  for (const metric of waterfallMetrics) {
    const metricValue = getWaterfallChartMetricValue(
      metric.metricValues,
      metricId
    );
    if (metricValue) {
      const data =
        metricId === MetricIdTypes.Loss && metricValue.data > 0
          ? -metricValue.data
          : metricValue.data;
      waterfallChartBars.push({
        color: getWaterfallChartBarColour(data),
        isIntermediateSum: false,
        name: metricValue.name,
        y: data,
      });
    }
  }

  return waterfallChartBars;
};

export const getBasicWaterfallChartData = (
  waterfallMeasureData?: GainsAndLossOverviewItem
) => {
  if (!waterfallMeasureData) {
    return [];
  }

  const waterfallChartMetricGroups = [
    GainsAndLossMetrics.ComparisonPeriod,
    GainsAndLossMetrics.TotalNewLostCustomers,
    GainsAndLossMetrics.Switching,
    GainsAndLossMetrics.CustomersSpendingMoreLess,
    GainsAndLossMetrics.FocusPeriod,
  ];

  const waterfallMetrics = getMetrics(
    waterfallMeasureData,
    waterfallChartMetricGroups
  );

  const waterfallChartBars = createWaterfallChartBars(
    waterfallMetrics,
    MetricIdTypes.Net
  );

  formatWaterfallChartBarColour(waterfallChartBars);
  return waterfallChartBars;
};

export const getBreakdownWaterfallChartData = (
  waterfallMeasureData?: GainsAndLossOverviewItem | null
) => {
  if (!waterfallMeasureData) {
    return [];
  }

  const waterfallChartPeriodMetricGroups = [
    GainsAndLossMetrics.ComparisonPeriod,
    GainsAndLossMetrics.FocusPeriod,
  ];

  const waterfallChartMainMetricGroups = [
    GainsAndLossMetrics.TotalNewLostRetailer,
    GainsAndLossMetrics.TotalNewLostUniverse,
    GainsAndLossMetrics.CustomersSpendingMoreLess,
    GainsAndLossMetrics.Switching,
  ];

  const periodMetrics = getMetrics(
    waterfallMeasureData,
    waterfallChartPeriodMetricGroups
  );

  const gainsAndLossMetrics = getMetrics(
    waterfallMeasureData,
    waterfallChartMainMetricGroups
  );

  const comparisonPeriodWaterfallChartBar = createWaterfallChartBars(
    periodMetrics.filter(
      (metric) => metric.metricGroup === GainsAndLossMetrics.ComparisonPeriod
    ),
    MetricIdTypes.Net
  );

  const focusPeriodWaterfallChartBar = createWaterfallChartBars(
    periodMetrics.filter(
      (metric) => metric.metricGroup === GainsAndLossMetrics.FocusPeriod
    ),
    MetricIdTypes.Net
  );

  const gainsWaterfallChartBars = createWaterfallChartBars(
    gainsAndLossMetrics,
    MetricIdTypes.Gains
  );

  const lossWaterfallChartBars = createWaterfallChartBars(
    gainsAndLossMetrics.reverse(),
    MetricIdTypes.Loss
  );

  const waterfallChartBars = comparisonPeriodWaterfallChartBar
    .concat(gainsWaterfallChartBars)
    .concat(lossWaterfallChartBars)
    .concat(focusPeriodWaterfallChartBar);

  formatWaterfallChartBarColour(waterfallChartBars);
  return waterfallChartBars;
};
