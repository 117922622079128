import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type Series } from "../models/basket-quantities-common-models";
import { BreakdownByParameterId } from "../models/basket-quantities-common-models";

export const getExportColumnHeader =
  (currencySymbol: string, breakdown: string) =>
  ({ metricLabel, format, name }: Series) => {
    let columnHeader = metricLabel;
    if (format === MetricTypes.Currency) {
      // we only want to show the currency symbol in the CSV.
      // rest of the application will have currency formatting in some other form.
      columnHeader = `${metricLabel} (${currencySymbol})`;
    }

    return breakdown === BreakdownByParameterId.Total
      ? columnHeader
      : `${columnHeader} - ${name}`;
  };
