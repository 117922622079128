import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { format, parse } from "date-fns";
import { type SegmentationBreakdownResponseDto } from "reports-ui/src/key-measure-trends/models/ChartResponseDto";
import { getExportColumnHeader } from "./utils";

const getName = (productName: string, productHierarchyShortName: string) =>
  `(${hierarchyLevelDisplayLabel(
    productHierarchyShortName.replace("PRODUCT_", "")
  )}) ${[productName]}`;

export const csvTransformation = (
  response: SegmentationBreakdownResponseDto | undefined,
  currencySymbol: string,
  selectedMetric?: string,
  selectedItem?: string
) => {
  const columnHeaders = ["Dates"];
  const csvData = [columnHeaders];
  if (response) {
    const headers = response.segments;
    const metricindex = response.metricsMetadata.findIndex(
      (metrics) => metrics.metricKey === selectedMetric
    );
    const metricFormat = response.metricsMetadata[metricindex].format;
    columnHeaders.push(
      ...headers.map(
        getExportColumnHeader(currencySymbol, metricFormat, selectedMetric)
      )
    );

    const productdata =
      response.productData
        .find(
          (product) => getName(product.name, product.shortname) === selectedItem
        )
        ?.data.map((data) => data[metricindex]) ?? [];

    for (let index = 0; index < response.promoWeeks.length; ++index) {
      const row = [
        format(
          parse(response.promoWeeks[index].toString(), "yyyyMMdd", new Date()),
          "yyyy-MM-dd"
        ),
        ...productdata.map((data) => (data[index] ?? 0).toString()),
      ];

      csvData.push(row);
    }
  }

  return csvData;
};
