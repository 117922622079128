import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type Metric } from "components-ui/src/charts/models";
import { format, parse } from "date-fns";
import { formatMetricValue } from "../../../common/utils/export-parameter-summary-utils";
import { type DropdownFilter } from "../../models/CustomerProfilingSegmentsOverTimeReportlet";
import { ChartSeriesLabels } from "../../models/CustomerProfilingSegmentsOverTimeReportlet";
import {
  type SegmentsOverTimeSegmentItem,
  type SegmentsOverTimeSegmentationItem,
} from "../../models/CustomerProfilingSegmentsOverTimeResponseDto";
import { getExportColumnHeader } from "../../utils/csvTransformUtils";
import {
  getChartMetricFormatAndDisplayName,
  getChartSeriesData,
  getChartXAxisDates,
} from "../../utils/getSegmentsOverTimeSeries";

export const csvTransformation = (
  chartSeriesActiveButtonIndex: number,
  segmentationActiveButtonIndex: number,
  focalItemFilter: DropdownFilter<HierarchySliceNodeDto>,
  segmentsFilter: DropdownFilter<SegmentsOverTimeSegmentItem>,
  reportData: SegmentsOverTimeSegmentationItem[] | undefined,
  metric: string,
  segmentation: string[],
  currencySymbol: string
) => {
  const columnHeaders = ["Time"];
  const csvData = [columnHeaders];
  if (reportData) {
    const selectedChartSeries: ChartSeriesLabels =
      Object.values(ChartSeriesLabels)[chartSeriesActiveButtonIndex];

    const selectedSegmentation = segmentation[segmentationActiveButtonIndex];

    const { metricDisplayName, metricFormat } =
      getChartMetricFormatAndDisplayName(
        reportData,
        metric,
        selectedSegmentation
      );

    // using the existing Charttransorm method
    const dates = getChartXAxisDates(
      selectedChartSeries,
      focalItemFilter.options,
      reportData,
      focalItemFilter.activeOption,
      selectedSegmentation,
      segmentsFilter.activeOption
    );

    let focalItems = [];
    if (selectedChartSeries === ChartSeriesLabels.CustomerSegments) {
      focalItems = focalItemFilter.activeOption
        ? [focalItemFilter.activeOption]
        : [];
    } else {
      focalItems = focalItemFilter.options;
    }

    const series = getChartSeriesData(
      selectedChartSeries,
      focalItems,
      metric,
      {} as Metric,
      reportData,
      false,
      focalItemFilter.activeOption,
      selectedSegmentation,
      segmentsFilter.activeOption
    );
    const headers = series.map((item) => item.name);
    columnHeaders.push(
      ...headers.map(
        getExportColumnHeader(currencySymbol, metricFormat, metricDisplayName)
      )
    );
    for (const [index, date] of dates.entries()) {
      const row = [
        format(parse(date, "dd MMM yy", new Date()), "yyyy-MM-dd"),
        ...series.map((item) => formatMetricValue(item.data[index])),
      ];

      csvData.push(row);
    }
  }

  return csvData;
};
