import { type CustomerLoyaltyReportletRequestDto } from "../models/CustomerLoyaltyReportletRequestDto";
import { type CustomerLoyaltyReportletResponseDto } from "../models/CustomerLoyaltyReportletResponseDto";
import { customerLoyaltyApiSlice } from "./customer-loyalty-api-slice";

export const customerLoyaltyReportletApiSlice =
  customerLoyaltyApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      GetCustomerLoyaltyReportlet: builder.query<
        CustomerLoyaltyReportletResponseDto,
        {
          division: string;
          payload: CustomerLoyaltyReportletRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetCustomerLoyaltyReportletData/${division}`,
        }),
      }),
    }),
  });

export const { useGetCustomerLoyaltyReportletQuery } =
  customerLoyaltyReportletApiSlice;
