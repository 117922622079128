import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import classNames from "classnames";
import { type ReactNode } from "react";
import { useState } from "react";
import { Expander } from "./Expander";
import styles from "./Expander.module.css";

export type ExpanderSetProps = {
  className?: string;
  expanders: Array<{
    className?: string;
    content: ReactNode;
    name?: string;
    title: JSX.Element | string;
  }>;
};

export const ExpanderSet = ({ className, expanders }: ExpanderSetProps) => {
  const [openExpanderId, setOpenExpanderId] = useState<number>();
  const handleToggle = (open: boolean, id: number): void => {
    if (open) {
      setOpenExpanderId(id);
    } else {
      setOpenExpanderId(undefined);
    }
  };

  const expandersWithState = expanders.map((item, index) => ({
    ...item,
    expanderState: {
      isExpanderOpen: openExpanderId === index,
      toggleOpen: (open: boolean) => handleToggle(open, index),
    },
  }));

  return (
    <div className={classNames(className, styles.expanderSet)}>
      {expandersWithState.map((item) => (
        <Expander
          className={item.className}
          expanderState={item.expanderState}
          key={uniqueId()}
          title={item.title}
        >
          {item.content}
        </Expander>
      ))}
    </div>
  );
};
