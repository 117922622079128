import { TrackingProperties } from "./TrackingProperties";

export class GenericTrackingProperties extends TrackingProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(value: Record<string, any>) {
    super();

    this.Properties = value;
  }

  public static single(
    property: string,
    value: string
  ): GenericTrackingProperties {
    return new this({ [property]: value });
  }
}
