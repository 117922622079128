import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type SharingActivity,
  type ParameterConfigurationsDto,
  isParameterConfigurationDto,
  type ParameterGroupSelectionDto,
  type RenameCustomerGroupRequestDto,
} from "../models";
import { type Activity } from "../models/activity-dto";
import {
  type UpdateActivationNotesRequestDto,
  type CombinedSegmentDto,
  type UpdateStagingRequestDto,
  type UpdateActivationRequestDto,
  type CustomerGroupWithSharingDto,
  type CustomerGroupTypeDto,
  type CustomerGroupDto,
  type SegmentDto,
  type RunSegmentGuidelinesRequestDto,
} from "../models/customer-group-dto";
import { type SharedGroupRequestDto } from "../models/sharing-dto";
import { ddLog } from "../utilities";

export const customerGroupsApi = createApi({
  baseQuery: getBaseQuery(`/api/customer-group-service`),
  endpoints: (builder) => ({
    createCustomerGroup: builder.mutation<
      CustomerGroupDto,
      {
        customerGroupType: string;
        divisionName: string;
        groupName: string;
        parameterGroupSelections: ParameterGroupSelectionDto[];
      }
    >({
      invalidatesTags: ["CustomerGroups"],
      query: ({
        divisionName,
        customerGroupType,
        groupName,
        parameterGroupSelections,
      }) => ({
        body: { customerGroupType, groupName, parameterGroupSelections },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/${divisionName}/CustomerGroup/CreateGroup`,
      }),
    }),
    updateCustomerGroup: builder.mutation<
      CustomerGroupDto,
      {
        customerGroupType: string;
        divisionName: string;
        groupId: string;
        groupName: string;
        parameterGroupSelections: ParameterGroupSelectionDto[];
      }
    >({
      invalidatesTags: ["CustomerGroups"],
      query: ({
        groupId,
        divisionName,
        customerGroupType,
        groupName,
        parameterGroupSelections,
      }) => ({
        body: {
          groupId,
          customerGroupType,
          groupName,
          parameterGroupSelections,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/${divisionName}/CustomerGroup/UpdateGroup`,
      }),
    }),
    renameCustomerGroup: builder.mutation<
      unknown,
      {
        divisionName: string;
        renameRequest: RenameCustomerGroupRequestDto;
      }
    >({
      invalidatesTags: ["CustomerGroups"],
      query: ({ divisionName, renameRequest }) => ({
        body: renameRequest,
        method: "POST",
        url: `/${divisionName}/CustomerGroup/RenameGroup`,
      }),
    }),
    createCustomerGroupValidationTask: builder.mutation<
      unknown,
      {
        divisionName: string;
        groupId: string;
      }
    >({
      invalidatesTags: ["CustomerGroups"],
      query: ({ divisionName, groupId }) => ({
        method: "POST",
        url: `/${divisionName}/CustomerGroup/CreateCustomerGroupValidationTask?groupId=${groupId}`,
      }),
    }),
    deleteCustomerGroups: builder.mutation<
      unknown,
      {
        divisionName: string;
        itemIds: string[];
      }
    >({
      invalidatesTags: ["CustomerGroups"],
      query: ({ divisionName, itemIds }) => ({
        method: "DELETE",
        url: `/${divisionName}/CustomerGroup/DeleteGroups?groupIds=${itemIds.join(
          "&groupIds="
        )}`,
      }),
    }),
    getCustomerGroups: builder.query<
      CustomerGroupWithSharingDto[],
      {
        divisionName: string;
        searchTerm?: string;
      }
    >({
      providesTags: ["CustomerGroups"],
      query: ({ divisionName, searchTerm }) => ({
        method: "GET",
        url: `/${divisionName}/CustomerGroup/GetUserGroups${
          searchTerm ? `?searchTerm=${encodeURIComponent(searchTerm)}` : ``
        }`,
      }),
    }),
    getCustomerGroup: builder.query<
      CustomerGroupDto,
      {
        divisionName: string;
        groupId: string;
      }
    >({
      providesTags: ["CustomerGroups"],
      query: ({ divisionName, groupId }) => ({
        method: "GET",
        url: `/${divisionName}/CustomerGroup/GetGroup?groupId=${groupId}`,
      }),
    }),
    getCustomerGroupTypes: builder.query<CustomerGroupTypeDto[], string>({
      providesTags: ["CustomerGroupTypes"],
      query: (divisionName) => ({
        method: "GET",
        url: `/${divisionName}/CustomerGroupType/GetGroupTypes`,
      }),
    }),
    getParameterConfigs: builder.query<
      ParameterConfigurationsDto,
      { customerGroupType: string; divisionName: string }
    >({
      async onQueryStarted(argument, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (!isParameterConfigurationDto(data) || !data.parameterGroups) {
            throw new Error(
              `Response payload does not match expected. Response payload: ${JSON.stringify(
                data
              )} `
            );
          }
        } catch (error) {
          ddLog("ERROR", {}, "error", error as Error);
        }
      },
      providesTags: ["ParameterConfigs"],
      query: ({ divisionName, customerGroupType }) =>
        `/${divisionName}/CustomerGroupType/${customerGroupType}/GetParameterConfigs`,
    }),
    getExistingGroupParameterConfigs: builder.query<
      ParameterConfigurationsDto,
      {
        divisionName: string;
        groupId: string;
      }
    >({
      providesTags: ["CustomerGroups"],
      query: ({ divisionName, groupId }) =>
        `/${divisionName}/CustomerGroup/GetExistingGroupParameterConfig?groupId=${groupId}`,
    }),
    shareCustomerGroup: builder.mutation<
      string[],
      { divisionName: string; payload: SharedGroupRequestDto }
    >({
      invalidatesTags: ["sharing-activities"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/Sharing/ShareGroup`,
      }),
    }),
    getCustomerGroupSharingActivities: builder.query<
      SharingActivity[],
      { divisionName: string; groupId: string }
    >({
      providesTags: ["sharing-activities"],
      query: ({ divisionName, groupId }) =>
        `/${divisionName}/Sharing/GetSharingActivities?groupId=${groupId}`,
    }),
    getStagedActivatedCustomerSegments: builder.query<
      SegmentDto[],
      { divisionName: string }
    >({
      providesTags: ["segment-builder"],
      query: ({ divisionName }) =>
        `/${divisionName}/CustomerGroup/GetStagedSegments`,
    }),
    getActivatedSegments: builder.query<SegmentDto[], { divisionName: string }>(
      {
        providesTags: ["segment-builder"],
        query: ({ divisionName }) =>
          `/${divisionName}/CustomerGroup/GetActivatedSegments`,
      }
    ),
    updateStaging: builder.mutation<
      unknown,
      { divisionName: string; payload: UpdateStagingRequestDto }
    >({
      invalidatesTags: ["CustomerGroups", "segment-builder"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/CustomerGroup/UpdateStaging`,
      }),
    }),
    updateActivation: builder.mutation<
      unknown,
      { divisionName: string; payload: UpdateActivationRequestDto }
    >({
      invalidatesTags: ["CustomerGroups", "segment-builder"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/CustomerGroup/UpdateActivation`,
      }),
    }),
    runSegmentGuidelines: builder.mutation<
      unknown,
      { divisionName: string; payload: RunSegmentGuidelinesRequestDto }
    >({
      invalidatesTags: ["CustomerGroups", "segment-builder"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/CustomerGroup/RunSegmentGuidelines`,
      }),
    }),
    getSegments: builder.query<
      CombinedSegmentDto[],
      {
        divisionName: string;
        searchTerm?: string;
      }
    >({
      providesTags: ["CustomerGroups"],
      query: ({ divisionName, searchTerm }) => ({
        method: "GET",
        url: `/${divisionName}/CustomerGroup/GetSegments${
          searchTerm ? `?searchTerm=${encodeURIComponent(searchTerm)}` : ``
        }`,
      }),
    }),
    getSegmentActivationActivity: builder.query<
      Activity[],
      {
        divisionName: string;
        groupId: string;
        segmentKey: string;
      }
    >({
      providesTags: ["segment-builder"],
      query: ({ divisionName, groupId, segmentKey }) => ({
        method: "GET",
        url: `/${divisionName}/CustomerGroup/GetSegmentActivationActivity?groupId=${groupId}&segmentKey=${segmentKey}`,
      }),
    }),
    updateActivationNotes: builder.mutation<
      unknown,
      { divisionName: string; payload: UpdateActivationNotesRequestDto }
    >({
      invalidatesTags: ["CustomerGroups", "segment-builder"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/CustomerGroup/UpdateActivationNotes`,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
  reducerPath: "customerGroupApi",
  tagTypes: [
    "CustomerGroups",
    "CustomerGroupTypes",
    "ParameterConfigs",
    "sharing-activities",
    "segment-builder",
  ],
});

export const {
  useDeleteCustomerGroupsMutation,
  useGetCustomerGroupsQuery,
  useGetCustomerGroupQuery,
  useGetCustomerGroupTypesQuery,
  useLazyGetParameterConfigsQuery,
  useGetExistingGroupParameterConfigsQuery,
  useCreateCustomerGroupMutation,
  useCreateCustomerGroupValidationTaskMutation,
  useShareCustomerGroupMutation,
  useGetCustomerGroupSharingActivitiesQuery,
  useUpdateCustomerGroupMutation,
  useRenameCustomerGroupMutation,
  useGetStagedActivatedCustomerSegmentsQuery,
  useGetActivatedSegmentsQuery,
  useUpdateStagingMutation,
  useUpdateActivationMutation,
  useRunSegmentGuidelinesMutation,
  useGetSegmentsQuery,
  useUpdateActivationNotesMutation,
  useGetSegmentActivationActivityQuery,
} = customerGroupsApi;
export type customerGroupsApiType = typeof customerGroupsApi;
