import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { REPORT_DATA_CACHE_LENGTH_SECONDS } from "../../reportUtilities";
import {
  type topAndBottomRequest,
  type topAndBottomResponse,
} from "./topAndBottomDtos";

export const topAndBottomApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    topAndBottomMeasures: builder.query<
      topAndBottomResponse,
      topAndBottomRequest & { division: string }
    >({
      keepUnusedDataFor: REPORT_DATA_CACHE_LENGTH_SECONDS,
      providesTags: ["TopAndBottomReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/top-and-bottom-report`,
      }),
    }),
  }),
  reducerPath: "topAndBottomApi",
  tagTypes: ["TopAndBottomReport"],
});

export const { useTopAndBottomMeasuresQuery } = topAndBottomApi;
