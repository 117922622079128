import {
  type HierarchyItem,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { HierarchySelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getHierarchyState } from "../../parameters/hierarchy/HierarchyState";
import { useAppSelector } from "../../states/hooks";

const getProductSelections = (selectedRows: HierarchyItem[]) => {
  const productSelectionsCopy = selectedRows.slice();
  return productSelectionsCopy
    .sort((a, b) => a.depth - b.depth)
    .map((selection) => ({
      itemCode: selection.code,
      name: selection.name,
      shortName: selection.shortName,
      type: selection.type,
    }));
};

export type SummaryPanelHierarchyProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelHierarchy = ({
  parameterDto,
}: SummaryPanelHierarchyProps) => {
  const hierarchyState = useAppSelector(getHierarchyState(parameterDto.id));

  if (!hierarchyState) {
    return null;
  }

  const selectedRows = getProductSelections(hierarchyState.selectedRows);

  return (
    <HierarchySelectionSummary
      items={selectedRows}
      maxDepth={3}
      moreItemsText=" more"
    />
  );
};
