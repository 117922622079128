import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Form,
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  FormInputHeight,
  Group,
  GroupGutters,
  Input,
  Item,
  ItemValign,
  Label,
  NumberInput,
} from "@quantium-enterprise/qds-react";
import styles from "./RangeTextInput.module.css";

export type RangeTextInputProps = {
  absoluteMax: number;
  absoluteMin: number;
  defaultMaxValue: number;
  defaultMinValue: number;
  id: string;
  maxValue: number | null;
  minValue: number | null;
  onMaxChange: (value: number | null) => void;
  onMinChange: (value: number | null) => void;
};

const getBlockStatus = (
  value: number | null,
  minValue: number | null,
  maxValue: number | null,
  absoluteMin: number,
  absoluteMax: number
) => {
  if (
    value === null ||
    minValue === null ||
    maxValue === null ||
    minValue >= maxValue ||
    value < absoluteMin ||
    value > absoluteMax
  ) {
    return FormBlockStatus.Error;
  }

  return FormBlockStatus.Default;
};

export const RangeTextInput = ({
  id,
  onMinChange,
  onMaxChange,
  minValue,
  maxValue,
  defaultMinValue,
  defaultMaxValue,
  absoluteMin,
  absoluteMax,
}: RangeTextInputProps) => (
  <Form>
    <Group gutters={GroupGutters.Small}>
      <Item>
        <FormBlock
          blockStatus={getBlockStatus(
            minValue,
            minValue,
            maxValue,
            absoluteMin,
            absoluteMax
          )}
          blockType={FormBlockType.Number}
        >
          <Group gutters={GroupGutters.Small}>
            <Item>
              <Label
                className={styles.rangeInputLabel}
                htmlFor={`${id}-range-input-min`}
                text="from"
              />
            </Item>
            <Item>
              <Input>
                <NumberInput
                  data-testid={`${id}-range-input-min`}
                  height={FormInputHeight.XSmall}
                  id={`${id}-range-input-min`}
                  max={absoluteMax}
                  min={absoluteMin}
                  onChange={(x) => {
                    const parsedValue = Number.parseInt(x.target.value, 10);
                    onMinChange(Number.isNaN(parsedValue) ? null : parsedValue);
                  }}
                  value={minValue?.toString() ?? ""}
                />
              </Input>
            </Item>
          </Group>
        </FormBlock>
      </Item>
      <Item>
        <FormBlock
          blockStatus={getBlockStatus(
            maxValue,
            minValue,
            maxValue,
            absoluteMin,
            absoluteMax
          )}
          blockType={FormBlockType.Number}
        >
          <Group gutters={GroupGutters.Small}>
            <Item>
              <Label
                className={styles.rangeInputLabel}
                htmlFor={`${id}-range-input-max`}
                text="to"
              />
            </Item>
            <Item>
              <Input>
                <NumberInput
                  data-testid={`${id}-range-input-max`}
                  height={FormInputHeight.XSmall}
                  id={`${id}-range-input-max`}
                  max={absoluteMax}
                  min={absoluteMin}
                  onChange={(x) => {
                    const parsedValue = Number.parseInt(x.target.value, 10);
                    onMaxChange(Number.isNaN(parsedValue) ? null : parsedValue);
                  }}
                  value={maxValue?.toString() ?? ""}
                />
              </Input>
            </Item>
          </Group>
        </FormBlock>
      </Item>
      <Item valign={ItemValign.Top}>
        <FormBlock blockType={FormBlockType.Button}>
          <Button
            disabled={
              minValue === defaultMinValue && maxValue === defaultMaxValue
            }
            height={ButtonHeight.XSmall}
            onClick={() => {
              onMinChange(defaultMinValue);
              onMaxChange(defaultMaxValue);
            }}
            text="Reset"
            variant={ButtonVariant.Stealth}
          />
        </FormBlock>
      </Item>
    </Group>
  </Form>
);

export default RangeTextInput;
