import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const customerProfilingApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/customer-profiling`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "customerProfilingApi",
  tagTypes: ["customerProfiling"],
});
