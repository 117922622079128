import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export const repertoireApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/repertoire`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "repertoireApi",
  tagTypes: ["repertoire"],
});
