import {
  AppContext,
  FeatureFlag,
  GROUPS_PATH,
  GenericTrackingProperties,
  MY_REPORTS_PATH,
  NavigationTrackingProperty,
  NavigationTrackingPropertyValue,
  RANGE_PERFORMANCE_PATH,
  REPORT_CREATOR_PATH,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { FastReportingSearchSection } from "@quantium-enterprise/fast-reporting-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./DashboardTopDrawer.module.scss";
import GroupsButton from "./assets/groups-button.svg";
import MyReportsButton from "./assets/my-reports-button.svg";
import RangePerformanceButton from "./assets/range-performance-button.svg";
import ReportCreatorButton from "./assets/report-creator-button.svg";

export const DashboardTopDrawer = () => {
  const featureFlags = useFlags();
  const isRangePerformanceEnabled =
    featureFlags[FeatureFlag.RangePerformanceDashboard];
  const division = useDivision();
  const eventTrackingService = useEventTrackingServiceContext();
  const { userState } = useContext(AppContext);

  return (
    <div className={styles.heading}>
      <div className={styles.searchContainer}>
        <h3 className={styles.titleText}>Discover more with Quick insights</h3>
        <FastReportingSearchSection
          placeholder="Search for products, brands, product groups, etc."
          visible
        />
        {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          division.moduleAccess && (
            <div className={styles.buttonContainer}>
              {division.moduleAccess.rangePerformance.hasAccess &&
                isRangePerformanceEnabled && (
                  <div>
                    <div className={styles.button}>
                      <Link
                        onClick={() =>
                          eventTrackingService.trackEvent(
                            TrackingComponent.RangePerformance,
                            TrackingEvent.Opened,
                            new GenericTrackingProperties({
                              [NavigationTrackingProperty.Source]:
                                NavigationTrackingPropertyValue.HomepageBanner,
                              division: division.name,
                              userType: userState.currentUser?.isSupplier
                                ? "supplier"
                                : "retailer",
                            })
                          )
                        }
                        to={`/${division.name}/${RANGE_PERFORMANCE_PATH}`}
                      >
                        <img
                          alt="Range performance"
                          className={styles.buttonImage}
                          src={RangePerformanceButton}
                        />
                      </Link>
                    </div>
                    <p className={styles.buttonText}>Range performance</p>
                  </div>
                )}
              {division.moduleAccess.reportCreator.hasAccess && (
                <div>
                  <div className={styles.button}>
                    <Link to={`/${division.name}/${REPORT_CREATOR_PATH}`}>
                      <img
                        alt="Report creator"
                        className={styles.buttonImage}
                        src={ReportCreatorButton}
                      />
                    </Link>
                  </div>
                  <p className={styles.buttonText}>Report creator</p>
                </div>
              )}
              {division.moduleAccess.myReports.hasAccess && (
                <div>
                  <div className={styles.button}>
                    <Link to={`/${division.name}/${MY_REPORTS_PATH}`}>
                      <img
                        alt="My reports"
                        className={styles.buttonImage}
                        src={MyReportsButton}
                      />
                    </Link>
                  </div>
                  <p className={styles.buttonText}>My reports</p>
                </div>
              )}
              <div>
                <div className={styles.button}>
                  <Link to={`/${division.name}/${GROUPS_PATH}`}>
                    <img
                      alt="Groups"
                      className={styles.buttonImage}
                      src={GroupsButton}
                    />
                  </Link>
                </div>
                <p className={styles.buttonText}>Groups</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};
