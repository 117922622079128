export enum ParameterId {
  Bands = "Bands",
  Buffer = "Buffer",
  Channel = "Channel",
  CombinedGroupRule = "CombinedGroupRule",
  ComparisonPeriod = "ComparisonPeriod",
  Dataset = "Dataset",
  FocusPeriod = "FocusPeriod",
  GroupSegments = "GroupSegments",
  LeadPeriod = "LeadPeriod",
  LevelOfAnalysis = "LevelOfAnalysis",
  LocationGroups = "LocationGroups",
  LocationHierarchy = "LocationHierarchy",
  LocationStructure = "LocationStructure",
  Measure = "Measure",
  MeasureThreshold = "MeasureThreshold",
  Metric = "Metric",
  NewLapsedContinuous = "NewLapsedContinuous",
  Notes = "Notes",
  ProductGroups = "ProductGroups",
  ProductHierarchy = "ProductHierarchy",
  ProductStructure = "ProductStructure",
  Promotion = "Promotion",
  PurchasingBehaviour = "PurchasingBehaviour",
  PurchasingTrends = "PurchasingTrends",
  RetailerCustomers = "TotalRetailerCustomers",
  RollingPeriod = "RollingPeriod",
  SegmentFilter = "SegmentFilter",
  Segmentation = "Segmentation",
}
