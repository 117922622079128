import {
  type HierarchyValue,
  type LocalHierarchyNodeSelection,
  createRowNameMatchesSearchPredicate,
} from "@quantium-enterprise/common-ui";
import { createSelector, type PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type LevelOfAnalysisDto } from "../../common/models/LevelOfAnalysisDto";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { DEFAULT_SALES_COUNT } from "../constants";
import {
  type PricingLaddersTableRow,
  type PricingLaddersTopDrawerItemTableData,
  type PricingLaddersDataTableResponseDto,
} from "../models/PricingLaddersDataTableResponseDto";
import { type HeaderCheckbox } from "../models/PricingLaddersHeaderCheckboxModel";
import { type PricingLaddersLocalParametersResponseDto } from "../models/PricingLaddersLocalParametersResponseDto";
import { type PricingLaddersResponseDto } from "../models/reportlet-response";
import { getDefaultSortby } from "../utils/common-utils";
import { getDefaultSelections } from "../utils/getDefaultSelections";
import { GetAutoSelectedItems } from "../utils/top-drawer-utils";
import { updateHeaderCheckbox } from "../utils/updateHeaderCheckBox";

const MAX_FOCAL_ITEMS = 200;

const getPersistenceKey = (reportId: string) => `pricing-ladders-${reportId}`;

export type PricingLaddersLocalSelections = {
  Channel: PanelOption;
  LocationHierarchy: LocalHierarchyNodeSelection;
  // [0] = Segmentation; [1] = segment;
  Segmentation: PanelOption[];
  ViewBy: PanelOption[];
};

export type PricingLaddersPersistedSelections = {
  focalItems: HierarchyValue[];
  isMinimumSalesInvalid: boolean;
  localParametersSelections: PricingLaddersLocalSelections;
  minimumSales: string;
  rowSelection: RowSelectionState;
  topDrawerActiveTab: string | undefined;
};

export type PricingLaddersState = {
  allRows: PricingLaddersTableRow[];
  chartAdditionalMetric: string;
  chartTypeActive: string;
  // FOCAL ITEM TABLE
  headerCheckbox: HeaderCheckbox;
  isGetTableRowsQueryLoading: boolean;
  isUniverseSummaryLoading: boolean;
  levelOfAnalysis: LevelOfAnalysisDto[];
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;

  persistedSelections: PricingLaddersPersistedSelections;
  persistedSelectionsLoaded: boolean;
  reportId: string;
  reportName: string;
  reportletData: PricingLaddersResponseDto;
  reportletDataRecieved: boolean;
  reportletTabActive: string;
  searchQuery: string;
  selectedItemsOnlyToggle: boolean;
  showChartDataLabels: boolean;
  topDrawerTableData: PricingLaddersTopDrawerItemTableData;
};

export const initialState: PricingLaddersState = {
  allRows: [],
  reportId: "",
  reportName: "Pricing ladders",
  chartAdditionalMetric: "No additional metric selected",
  reportletTabActive: "Chart",
  chartTypeActive: "Standard",
  headerCheckbox: {
    isChecked: true,
    isIndeterminate: false,
  },
  persistedSelections: {
    focalItems: [],
    localParametersSelections: {
      ViewBy: [
        { label: "", value: "AvgPrice" },
        { label: "", value: "Avg price" },
      ],
      Channel: { label: "", value: "" },
      Segmentation: [{ label: "", value: "" }],
      LocationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
        isDefault: false,
      },
    },
    isMinimumSalesInvalid: false,
    minimumSales: "",
    topDrawerActiveTab: undefined,
    rowSelection: {},
  },
  persistedSelectionsLoaded: false,
  isGetTableRowsQueryLoading: true,
  isUniverseSummaryLoading: true,
  levelOfAnalysis: [],
  localParameters: [],
  localParametersInitialised: false,
  searchQuery: "",
  reportletData: {
    categories: [],
    series: [],
  },
  selectedItemsOnlyToggle: false,
  reportletDataRecieved: false,
  topDrawerTableData: {
    metrics: [],
    tableRows: [],
  },
  showChartDataLabels: false,
};

export const pricingLaddersSlice = createSlice({
  initialState,
  name: "pricing-ladders",
  reducers: {
    reset: () => initialState,
    onReportOpen: (
      state: PricingLaddersState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
        };
      }

      const persistedSelections: PricingLaddersPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
      };
    },
    onMetadataSuccess: (
      state: PricingLaddersState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.reportId = payload.reportId;
      state.reportName = payload.reportName;
    },
    onReportletTabChange: (
      state: PricingLaddersState,
      { payload }: PayloadAction<string>
    ) => {
      state.reportletTabActive = payload;
    },
    onChartTypeChange: (
      state: PricingLaddersState,
      { payload }: PayloadAction<string>
    ) => {
      state.chartTypeActive = payload;
    },
    onChartAdditionalMetricChange: (
      state: PricingLaddersState,
      { payload }: PayloadAction<string>
    ) => {
      state.chartAdditionalMetric = payload;
      state.persistedSelections.localParametersSelections.ViewBy =
        getDefaultSortby(
          state.persistedSelections.localParametersSelections.ViewBy
        );
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onReportletDataReceived: (
      state: PricingLaddersState,
      { payload }: PayloadAction<PricingLaddersResponseDto>
    ) => {
      state.reportletData = payload;
      state.reportletDataRecieved = true;
    },
    onLocalParametersReceived: (
      state: PricingLaddersState,
      { payload }: PayloadAction<PricingLaddersLocalParametersResponseDto>
    ) => {
      state.reportName = payload.reportName;
      state.localParameters = payload.localParameters;
      state.levelOfAnalysis = payload.levelsOfAnalysis;
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.topDrawerActiveTab =
          payload.levelsOfAnalysis[0].value;
        state.persistedSelections.localParametersSelections =
          getDefaultSelections(payload.localParameters);
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onViewByChange: (
      state: PricingLaddersState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localParametersSelections.ViewBy = [
        {
          value: payload.segmentationValue,
          label: payload.segmentationLabel,
        },
        {
          value: payload.segmentValue,
          label: payload.segmentLabel,
        },
      ];
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onChannelChange: (
      state: PricingLaddersState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.minimumSales = "";
      state.allRows = [];
      state.persistedSelections.localParametersSelections.Channel = {
        label: payload.label,
        value: payload.value,
      };
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: PricingLaddersState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.minimumSales = "";
      state.allRows = [];
      state.persistedSelections.localParametersSelections.Segmentation = [
        {
          value: payload.segmentationValue,
          label: payload.segmentationLabel,
        },
        {
          value: payload.segmentValue,
          label: payload.segmentLabel,
        },
      ];
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: PricingLaddersState,
      { payload }: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.minimumSales = "";
      state.allRows = [];
      state.persistedSelections.localParametersSelections.LocationHierarchy =
        payload;
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setTopDrawerActiveTab: (
      state: PricingLaddersState,
      action: PayloadAction<string>
    ) => {
      // clear the focal items, table rows, all rows on tab change in top drawer
      if (state.persistedSelections.topDrawerActiveTab !== action.payload) {
        state.persistedSelections.topDrawerActiveTab = action.payload;
        state.persistedSelections.focalItems = [];
        state.topDrawerTableData.tableRows = [];
        state.allRows = [];
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onTopDrawerTableSuccess: (
      state: PricingLaddersState,
      action: PayloadAction<PricingLaddersDataTableResponseDto>
    ) => {
      // initial values for sales filter input and search box input
      state.searchQuery = "";
      if (
        state.persistedSelections.topDrawerActiveTab ===
        action.payload.levelOfAnalysis
      ) {
        state.topDrawerTableData.metrics = action.payload.metrics;
        state.allRows = action.payload.tableRows;
        state.topDrawerTableData.tableRows = action.payload.tableRows;

        if (action.payload.tableRows.length > 0) {
          state.persistedSelections.focalItems = GetAutoSelectedItems(
            state.persistedSelections.minimumSales,
            DEFAULT_SALES_COUNT,
            state.topDrawerTableData.tableRows
          ).slice(0, MAX_FOCAL_ITEMS);

          for (const item of state.topDrawerTableData.tableRows) {
            state.persistedSelections.rowSelection[
              item.hierarchyItem.itemCode
            ] = false;
          }

          for (const item of state.persistedSelections.focalItems) {
            state.persistedSelections.rowSelection[item.itemCode] = true;
          }

          if (state.selectedItemsOnlyToggle) {
            state.topDrawerTableData.tableRows =
              state.topDrawerTableData.tableRows.filter(
                (item) =>
                  state.persistedSelections.rowSelection[
                    item.hierarchyItem.itemCode
                  ]
              );
          }
        }
      } else {
        state.topDrawerTableData.metrics = [];
        state.topDrawerTableData.tableRows = [];
      }

      state.isGetTableRowsQueryLoading = false;
      [state.headerCheckbox.isChecked, state.headerCheckbox.isIndeterminate] =
        updateHeaderCheckbox(
          state.persistedSelections.focalItems,
          state.topDrawerTableData.tableRows,
          state.persistedSelections.rowSelection
        );
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setFocalItems: (
      state: PricingLaddersState,
      action: PayloadAction<{
        isSelected: boolean;
        selectedItem: HierarchyValue;
      }>
    ) => {
      // set minimum sales to empty string on manual selection of focal items in the table
      state.persistedSelections.minimumSales = "";

      if (action.payload.isSelected) {
        state.persistedSelections.focalItems =
          state.persistedSelections.focalItems.filter(
            (value) => value.itemCode !== action.payload.selectedItem.itemCode
          );
        state.persistedSelections.rowSelection[
          action.payload.selectedItem.itemCode
        ] = false;
      } else {
        state.persistedSelections.focalItems = [
          ...state.persistedSelections.focalItems,
          action.payload.selectedItem,
        ];
        state.persistedSelections.rowSelection[
          action.payload.selectedItem.itemCode
        ] = true;
      }

      [state.headerCheckbox.isChecked, state.headerCheckbox.isIndeterminate] =
        updateHeaderCheckbox(
          state.persistedSelections.focalItems,
          state.topDrawerTableData.tableRows,
          state.persistedSelections.rowSelection
        );

      state.persistedSelections.isMinimumSalesInvalid = false;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onHeaderCheckBoxToggled: (state: PricingLaddersState) => {
      state.persistedSelections.minimumSales = "";

      if (
        state.headerCheckbox.isChecked ||
        state.headerCheckbox.isIndeterminate
      ) {
        state.headerCheckbox.isChecked = false;
        state.headerCheckbox.isIndeterminate = false;

        for (const item of state.topDrawerTableData.tableRows) {
          state.persistedSelections.rowSelection[item.hierarchyItem.itemCode] =
            false;
        }

        if (!state.searchQuery && !state.selectedItemsOnlyToggle) {
          state.persistedSelections.focalItems = [];
        } else {
          // filter out the all items based on search are deselected
          state.persistedSelections.focalItems =
            state.persistedSelections.focalItems.filter(
              (item) => state.persistedSelections.rowSelection[item.itemCode]
            );
        }
      } else {
        state.headerCheckbox.isChecked = true;
        state.headerCheckbox.isIndeterminate = false;

        for (const item of state.topDrawerTableData.tableRows) {
          state.persistedSelections.focalItems = [
            ...state.persistedSelections.focalItems,
            item.hierarchyItem,
          ];
        }

        for (const item of state.persistedSelections.focalItems) {
          state.persistedSelections.rowSelection[item.itemCode] = true;
        }
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSalesFilterValueChange: (
      state: PricingLaddersState,
      { payload: value }: PayloadAction<string>
    ) => {
      state.persistedSelections.minimumSales = value;

      if (value) {
        const focalItems = GetAutoSelectedItems(
          state.persistedSelections.minimumSales,
          DEFAULT_SALES_COUNT,
          state.allRows
        );

        state.persistedSelections.isMinimumSalesInvalid =
          focalItems.length > MAX_FOCAL_ITEMS;

        state.persistedSelections.focalItems = focalItems.slice(
          0,
          MAX_FOCAL_ITEMS
        );

        for (const row in state.persistedSelections.rowSelection) {
          if (Object.hasOwn(state.persistedSelections.rowSelection, row)) {
            state.persistedSelections.rowSelection[row] = false;
          }
        }

        for (const item of state.persistedSelections.focalItems) {
          state.persistedSelections.rowSelection[item.itemCode] = true;
        }

        [state.headerCheckbox.isChecked, state.headerCheckbox.isIndeterminate] =
          updateHeaderCheckbox(
            state.persistedSelections.focalItems,
            state.topDrawerTableData.tableRows,
            state.persistedSelections.rowSelection
          );
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSearchQueryChange: (
      state: PricingLaddersState,
      action: PayloadAction<string>
    ) => {
      state.searchQuery = action.payload;

      if (state.searchQuery) {
        state.topDrawerTableData.tableRows = [];
        const rowNameMatchesSearch = createRowNameMatchesSearchPredicate(
          state.searchQuery
        );

        for (const item of state.allRows) {
          if (rowNameMatchesSearch(item.hierarchyItem.name)) {
            if (
              state.selectedItemsOnlyToggle &&
              !state.persistedSelections.rowSelection[
                item.hierarchyItem.itemCode
              ]
            ) {
              continue;
            }

            state.topDrawerTableData.tableRows.push(item);
          }
        }

        state.topDrawerTableData.tableRows =
          state.topDrawerTableData.tableRows.sort((a, b) =>
            a.hierarchyItem.name.localeCompare(b.hierarchyItem.name)
          );
      } else if (state.selectedItemsOnlyToggle) {
        state.topDrawerTableData.tableRows = state.allRows.filter(
          (item) =>
            state.persistedSelections.rowSelection[item.hierarchyItem.itemCode]
        );
      } else {
        state.topDrawerTableData.tableRows = state.allRows;
      }

      [state.headerCheckbox.isChecked, state.headerCheckbox.isIndeterminate] =
        updateHeaderCheckbox(
          state.persistedSelections.focalItems,
          state.topDrawerTableData.tableRows,
          state.persistedSelections.rowSelection
        );
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    displaySelectedItemsOnly: (state: PricingLaddersState) => {
      if (state.selectedItemsOnlyToggle === false) {
        state.selectedItemsOnlyToggle = true;

        state.topDrawerTableData.tableRows =
          state.topDrawerTableData.tableRows.filter(
            (item) =>
              state.persistedSelections.rowSelection[
                item.hierarchyItem.itemCode
              ]
          );
        // display selected items only when the name includes the search query
      } else if (state.searchQuery) {
        const rowNameMatchesSearch = createRowNameMatchesSearchPredicate(
          state.searchQuery
        );
        state.selectedItemsOnlyToggle = false;
        state.topDrawerTableData.tableRows = state.allRows
          .filter((item) => rowNameMatchesSearch(item.hierarchyItem.name))
          .sort((a, b) =>
            a.hierarchyItem.name.localeCompare(b.hierarchyItem.name)
          );
        // display all the selected items available when there is no search query
      } else {
        state.selectedItemsOnlyToggle = false;
        state.topDrawerTableData.tableRows = state.allRows.map((item) => item);
      }

      [state.headerCheckbox.isChecked, state.headerCheckbox.isIndeterminate] =
        updateHeaderCheckbox(
          state.persistedSelections.focalItems,
          state.topDrawerTableData.tableRows,
          state.persistedSelections.rowSelection
        );
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    toggleDataLabels: (state: PricingLaddersState) => {
      state.showChartDataLabels = !state.showChartDataLabels;
    },
  },
});

export const selectReportName = createSelector(
  (state: RootState) => state.pricingLadders.reportName,
  (reportName) => reportName
);

export const selectLevelOfAnalysis = createSelector(
  (state: RootState) => state.pricingLadders.levelOfAnalysis,
  (levelOfAnalysis) => levelOfAnalysis
);

export const selectShowChartDataLabels = createSelector(
  (state: RootState) => state.pricingLadders.showChartDataLabels,
  (showChartDataLabels) => showChartDataLabels
);

export const selectReportId = createSelector(
  (state: RootState) => state.pricingLadders.reportId,
  (reportId) => reportId
);

export const selectReportletTabActive = createSelector(
  (state: RootState) => state.pricingLadders.reportletTabActive,
  (reportletTabActive) => reportletTabActive
);

export const selectChartTypeActive = createSelector(
  (state: RootState) => state.pricingLadders.chartTypeActive,
  (chartTypeActive) => chartTypeActive
);

export const selectReportletData = createSelector(
  (state: RootState) => state.pricingLadders.reportletData,
  (reportletData) => reportletData
);

export const selectAllRows = createSelector(
  (state: RootState) => state.pricingLadders.allRows,
  (allRows) => allRows
);

export const selectLocalParameter = createSelector(
  (state: RootState) => state.pricingLadders.localParameters,
  (localParameters) => localParameters
);

export const selectHeaderCheckbox = createSelector(
  (state: RootState) => state.pricingLadders.headerCheckbox,
  (headerCheckbox) => headerCheckbox
);

export const selectSearchQuery = createSelector(
  (state: RootState) => state.pricingLadders.searchQuery,
  (searchQuery) => searchQuery
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.pricingLadders.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectSelectedItemsOnlyToggle = createSelector(
  (state: RootState) => state.pricingLadders.selectedItemsOnlyToggle,
  (selectedItemsOnlyToggle) => selectedItemsOnlyToggle
);

export const selectMetrics = createSelector(
  (state: RootState) => state.pricingLadders.topDrawerTableData.metrics,
  (metrics) => metrics
);

export const selectTableRows = createSelector(
  (state: RootState) => state.pricingLadders.topDrawerTableData.tableRows,

  (tableRows) => tableRows
);

export const selectFocalItems = createSelector(
  (state: RootState) => state.pricingLadders.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectMinimumSales = createSelector(
  (state: RootState) => state.pricingLadders.persistedSelections.minimumSales,
  (minimumSales) => minimumSales
);

export const selectIsMinimumSalesInvalid = createSelector(
  (state: RootState) =>
    state.pricingLadders.persistedSelections.isMinimumSalesInvalid,
  (isMinimumSalesInvalid) => isMinimumSalesInvalid
);

export const selectRowSelection = createSelector(
  (state: RootState) => state.pricingLadders.persistedSelections.rowSelection,
  (rowSelection) => rowSelection
);

export const selectTopDrawerActiveTab = createSelector(
  (state: RootState) =>
    state.pricingLadders.persistedSelections.topDrawerActiveTab,
  (topDrawerActiveTab) => topDrawerActiveTab
);

export const selectViewBy = createSelector(
  (state: RootState) =>
    state.pricingLadders.persistedSelections.localParametersSelections.ViewBy,
  (viewBy) => viewBy
);

export const selectChartAdditionalMetric = createSelector(
  (state: RootState) => state.pricingLadders.chartAdditionalMetric,
  (chartAdditionalMetric) => chartAdditionalMetric
);

export const selectChannel = createSelector(
  (state: RootState) =>
    state.pricingLadders.persistedSelections.localParametersSelections.Channel,
  (channel) => channel
);
export const selectLocationHierarchy = createSelector(
  (state: RootState) =>
    state.pricingLadders.persistedSelections.localParametersSelections
      .LocationHierarchy,
  (locationHierarchy) => locationHierarchy
);
export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.pricingLadders.persistedSelections.localParametersSelections
      .Segmentation,
  (segmentation) => segmentation
);

export const selectLocalParametersSelections = createSelector(
  [selectChannel, selectLocationHierarchy, selectSegmentation, selectViewBy],
  (
    Channel,
    LocationHierarchy,
    Segmentation,
    ViewBy
  ): PricingLaddersLocalSelections => ({
    Channel,
    LocationHierarchy,
    Segmentation,
    ViewBy,
  })
);

export const {
  reset,
  onReportOpen,
  onMetadataSuccess,
  onReportletTabChange,
  onChartTypeChange,
  onReportletDataReceived,
  onLocalParametersReceived,
  onViewByChange,
  onChannelChange,
  onSegmentationChange,
  onLocationChange,
  onChartAdditionalMetricChange,
  setTopDrawerActiveTab,
  onTopDrawerTableSuccess,
  setFocalItems,
  onHeaderCheckBoxToggled,
  onSalesFilterValueChange,
  onSearchQueryChange,
  displaySelectedItemsOnly,
  toggleDataLabels,
} = pricingLaddersSlice.actions;

export default pricingLaddersSlice.reducer;
