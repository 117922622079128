import { ddLog } from "@quantium-enterprise/common-ui";
import {
  type DayOfWeekRequestDto,
  type DayOfWeekResponseDto,
} from "../models/day-of-week-chart";
import { timeOfDayDayOfWeekApiSlice } from "./time-of-day-day-of-week-api-slice";

export const dayOfWeekChartDataSlice =
  timeOfDayDayOfWeekApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getDayOfWeekChartData: builder.query<
        DayOfWeekResponseDto,
        { divisionName: string; requestPayload: DayOfWeekRequestDto }
      >({
        async onQueryStarted(argument, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch {
            ddLog("Error fetching GetTimeOfDayData data", {}, "error");
          }
        },
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetDayOfWeekData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetDayOfWeekChartDataQuery } = dayOfWeekChartDataSlice;
