import {
  type HierarchyValue,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { createSelector, type PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { EMPTY_HIERARCHY_SLICE_NODE } from "../../common/constants";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getReportLocalParameters } from "../../common/utils/local-parameters/getReportLocalParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import {
  getSwitchingBreakdownForMeasure,
  sortSwitchingItems,
} from "../components/GainsAndLossSwitchingBreakdown/utils";
import { type GainsAndLossReportParametersResponseDto } from "../models/GainsAndLossReportParametersDto";
import { type GainsAndLossReportTableResponseDto } from "../models/GainsAndLossReportTableResponseDto";
import { type GainsAndLossSwitchingBreakdownResponseDto } from "../models/GainsAndLossSwitchingBreakdownReportletDto";
import { type GainsAndLossTopDrawerProductTableData } from "../models/GainsAndLossTopDrawerProductTableParameters";
import {
  type GainsAndLossUniverseSummary,
  type GainsAndLossUniverseSummaryResponseDto,
} from "../models/GainsAndLossUniverseSummaryResponseDto";
import { getLocalSelections } from "../utils/getLocalSelections";

const getPersistenceKey = (reportId: string) => `gains-and-loss-${reportId}`;

export type GainsAndLossLocalSelections = {
  channel: PanelOption;
  location: LocalHierarchyNodeSelection;
  metricSet: PanelOption;
  segment: PanelOption;
  segmentation: PanelOption;
  time: string;
  timePeriodLength: string;
};

export type GainsAndLossPersistedSelections = {
  focalItem?: HierarchyValue;
  localSelections: GainsAndLossLocalSelections;
  rowSelection: RowSelectionState;
  topDrawerActiveTab: string | undefined;
};

export type GainsAndLossReportState = {
  isSwitchingBreakdownDataLoading: boolean;
  isUniverseSummaryLoading: boolean;
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  persistedSelections: GainsAndLossPersistedSelections;
  persistedSelectionsLoaded: boolean;
  reportId: string;
  reportName: string;
  searchQuery: string;
  showOverviewWaterfallChartDataLabels: boolean;
  showSwitchingBreakdownChartDataLabels: boolean;
  showUniverseSummaryChartDataLabels: boolean;
  switchingBreakdownData: GainsAndLossSwitchingBreakdownResponseDto;
  tablePage: number;
  topDrawerProductTableData: GainsAndLossTopDrawerProductTableData;
  topDrawerRowCount: number;
  universeSummaryData: GainsAndLossUniverseSummary;
};

export const initialState: GainsAndLossReportState = {
  isSwitchingBreakdownDataLoading: true,
  isUniverseSummaryLoading: true,
  localParameters: [],
  localParametersInitialised: false,
  persistedSelections: {
    focalItem: undefined,
    localSelections: {
      channel: {
        label: "",
        value: "",
      },
      location: EMPTY_HIERARCHY_SLICE_NODE,
      metricSet: {
        label: "",
        value: "",
      },
      segmentation: {
        label: "",
        value: "",
      },
      segment: {
        label: "",
        value: "",
      },
      time: "",
      timePeriodLength: "",
    },
    rowSelection: {} as RowSelectionState,
    topDrawerActiveTab: undefined,
  },
  persistedSelectionsLoaded: false,
  reportId: "",
  reportName: "",
  searchQuery: "",
  showOverviewWaterfallChartDataLabels: true,
  showSwitchingBreakdownChartDataLabels: true,
  showUniverseSummaryChartDataLabels: false,
  switchingBreakdownData: { format: "", items: [], measureName: "" },
  tablePage: 0,
  topDrawerProductTableData: {
    data: [],
    measures: [],
  },
  topDrawerRowCount: 0,
  universeSummaryData: {
    data: { format: "", measureName: "", measures: [] },
    measureDisplayNames: [],
  },
};

export const gainsAndLossSlice = createSlice({
  initialState,
  name: "gains-and-loss",
  reducers: {
    onChannelChange: (
      state: GainsAndLossReportState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.channel = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onLoadMoreSuccess: (
      state: GainsAndLossReportState,
      action: PayloadAction<GainsAndLossReportTableResponseDto>
    ) => {
      // remove isMoreRow from last item
      state.topDrawerProductTableData.data[
        state.topDrawerProductTableData.data.length - 1
      ].isMoreRow = false;

      const newData = action.payload.hasNextPage
        ? action.payload.data.map((item, index) => ({
            ...item,
            isMoreRow: index === action.payload.data.length - 1,
          }))
        : action.payload.data;
      state.topDrawerProductTableData.data =
        state.topDrawerProductTableData.data.concat(newData);

      state.tablePage++;
    },
    onLocationChange: (
      state: GainsAndLossReportState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localSelections.location = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onMetricSetChange: (
      state: GainsAndLossReportState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.metricSet = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSearchQueryChange: (
      state: GainsAndLossReportState,
      action: PayloadAction<string>
    ) => {
      state.searchQuery = action.payload;
    },
    onReportError: (state: GainsAndLossReportState) => {
      state.isSwitchingBreakdownDataLoading = false;
      state.isUniverseSummaryLoading = false;
    },
    onReportOpen: (
      state: GainsAndLossReportState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: GainsAndLossPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
    onReportSuccess: (
      state: GainsAndLossReportState,
      action: PayloadAction<GainsAndLossReportParametersResponseDto>
    ) => {
      state.localParameters = getReportLocalParameters(
        action.payload.localParameters
      );

      state.reportName = action.payload.reportName;

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localSelections = getLocalSelections(
          state.localParameters
        );

        state.persistedSelections.topDrawerActiveTab =
          action.payload.levelsOfAnalysis.length > 0
            ? action.payload.levelsOfAnalysis[0].value
            : undefined;
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: GainsAndLossReportState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localSelections.segmentation = {
        value: payload.segmentationValue,
        label: payload.segmentationLabel,
      };
      state.persistedSelections.localSelections.segment = {
        value: payload.segmentValue,
        label: payload.segmentLabel,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSwitchingBreakdownRequest: (
      state: GainsAndLossReportState,
      action: PayloadAction<boolean>
    ) => {
      state.isSwitchingBreakdownDataLoading = action.payload;
    },
    onSwitchingBreakdownResponseReceived: (
      state: GainsAndLossReportState,
      action: PayloadAction<{
        data: GainsAndLossSwitchingBreakdownResponseDto[] | undefined;
        metricSet: PanelOption;
      }>
    ) => {
      const filteredData = getSwitchingBreakdownForMeasure(
        action.payload.data ?? [],
        action.payload.metricSet.value as string
      );
      state.switchingBreakdownData = sortSwitchingItems(filteredData);
      state.isSwitchingBreakdownDataLoading = false;
    },
    onTopDrawerTableSuccess: (
      state: GainsAndLossReportState,
      action: PayloadAction<GainsAndLossReportTableResponseDto>
    ) => {
      if (
        state.persistedSelections.topDrawerActiveTab ===
        action.payload.levelOfAnalysis
      ) {
        if (action.payload.data.length > 0) {
          // initial load, if no focal item set to first row
          // if filter triggering and focal item is filled dont change it
          if (!state.persistedSelections.focalItem) {
            state.persistedSelections.focalItem =
              action.payload.data[0].hierarchyItem;
            state.persistedSelections.rowSelection[
              state.persistedSelections.focalItem.itemCode
            ] = true;
          }

          state.topDrawerProductTableData.measures = action.payload.measures;
          state.topDrawerProductTableData.data = action.payload.hasNextPage
            ? action.payload.data.map((item, index) => ({
                ...item,
                isMoreRow: index === action.payload.data.length - 1,
              }))
            : action.payload.data;

          state.tablePage = 1;
          state.topDrawerRowCount = action.payload.totalResults;
        } else {
          state.topDrawerProductTableData.measures = [];
          state.topDrawerProductTableData.data = [];
          state.tablePage = 0;
          state.topDrawerRowCount = 0;
          state.isSwitchingBreakdownDataLoading = false;
        }
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onUniverseSummaryRequest: (
      state: GainsAndLossReportState,
      action: PayloadAction<boolean>
    ) => {
      state.isUniverseSummaryLoading = action.payload;
    },
    onUniverseSummaryResponseReceived: (
      state: GainsAndLossReportState,
      action: PayloadAction<{
        data: GainsAndLossUniverseSummaryResponseDto | undefined;
        metricSet: PanelOption;
      }>
    ) => {
      const filteredData = action.payload.data?.data.find(
        (item) => item.measureName === action.payload.metricSet.value
      ) ?? { format: "", measureName: "", measures: [] };

      state.universeSummaryData = {
        data: filteredData,
        measureDisplayNames: action.payload.data?.measureDisplayNames ?? [],
      };

      state.isUniverseSummaryLoading = false;
    },
    setFocalItem: (
      state: GainsAndLossReportState,
      action: PayloadAction<HierarchyValue>
    ) => {
      state.persistedSelections.focalItem = action.payload;
      for (const key of Object.keys(state.persistedSelections.rowSelection)) {
        state.persistedSelections.rowSelection[key] = false;
      }

      state.persistedSelections.rowSelection[
        state.persistedSelections.focalItem.itemCode
      ] = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setTopDrawerActiveTab: (
      state: GainsAndLossReportState,
      action: PayloadAction<string>
    ) => {
      state.persistedSelections.topDrawerActiveTab = action.payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    toggleOverviewWaterfallChartDataLabels: (
      state: GainsAndLossReportState
    ) => {
      state.showOverviewWaterfallChartDataLabels =
        !state.showOverviewWaterfallChartDataLabels;
    },
    toggleSwitchingBreakdownChartDataLabels: (
      state: GainsAndLossReportState
    ) => {
      state.showSwitchingBreakdownChartDataLabels =
        !state.showSwitchingBreakdownChartDataLabels;
    },
    toggleUniverseSummaryChartDataLabels: (state: GainsAndLossReportState) => {
      state.showUniverseSummaryChartDataLabels =
        !state.showUniverseSummaryChartDataLabels;
    },
    reset: () => initialState,
  },
});

export const selectReportId = createSelector(
  (state: RootState) => state.gainsAndLoss.reportId,
  (reportId) => reportId
);

export const selectFocalItem = createSelector(
  (state: RootState) => state.gainsAndLoss.persistedSelections.focalItem,
  (focalItem) => focalItem
);

export const selectRowSelection = createSelector(
  (state: RootState) => state.gainsAndLoss.persistedSelections.rowSelection,
  (rowSelection) => rowSelection
);

export const selectTopDrawerActiveTab = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.topDrawerActiveTab,
  (topDrawerActiveTab) => topDrawerActiveTab
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.gainsAndLoss.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectIsSwitchingBreakdownDataLoading = createSelector(
  (state: RootState) => state.gainsAndLoss.isSwitchingBreakdownDataLoading,
  (isSwitchingBreakdownDataLoading) => isSwitchingBreakdownDataLoading
);

export const selectChannel = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.localSelections.channel,
  (channel) => channel
);

export const selectLocation = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.localSelections.location,
  (location) => location
);

export const selectMetricSet = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.localSelections.metricSet,
  (metricSet) => metricSet
);

export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.localSelections.segmentation,
  (segmentation) => segmentation
);

export const selectSegment = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.localSelections.segment,
  (segment) => segment
);

export const selectTime = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.localSelections.time,
  (time) => time
);

export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.persistedSelections.localSelections.timePeriodLength,
  (timePeriodLength) => timePeriodLength
);

export const selectLocalSelections = createSelector(
  [
    selectChannel,
    selectLocation,
    selectMetricSet,
    selectSegmentation,
    selectSegment,
    selectTime,
    selectTimePeriodLength,
  ],
  (
    channel,
    location,
    metricSet,
    segmentation,
    segment,
    time,
    timePeriodLength
  ): GainsAndLossLocalSelections => ({
    channel,
    location,
    metricSet,
    segmentation,
    segment,
    time,
    timePeriodLength,
  })
);

export const selectShowOverviewWaterfallChartDataLabels = createSelector(
  (state: RootState) => state.gainsAndLoss.showOverviewWaterfallChartDataLabels,
  (showOverviewWaterfallChartDataLabels) => showOverviewWaterfallChartDataLabels
);

export const selectShowSwitchingBreakdownChartDataLabels = createSelector(
  (state: RootState) =>
    state.gainsAndLoss.showSwitchingBreakdownChartDataLabels,
  (showSwitchingBreakdownChartDataLabels) =>
    showSwitchingBreakdownChartDataLabels
);

export const selectShowUniverseSummaryChartDataLabels = createSelector(
  (state: RootState) => state.gainsAndLoss.showUniverseSummaryChartDataLabels,
  (showUniverseSummaryChartDataLabels) => showUniverseSummaryChartDataLabels
);

export const selectLocalParameters = createSelector(
  (state: RootState) => state.gainsAndLoss.localParameters,
  (localParameters) => localParameters
);

export const selectuniverseSummaryData = createSelector(
  (state: RootState) => state.gainsAndLoss.universeSummaryData,
  (universeSummaryData) => universeSummaryData
);

export const {
  onChannelChange,
  onLoadMoreSuccess,
  onLocationChange,
  onMetricSetChange,
  onSearchQueryChange,
  onReportError,
  onReportOpen,
  onReportSuccess,
  onSegmentationChange,
  onSwitchingBreakdownRequest,
  onSwitchingBreakdownResponseReceived,
  onTopDrawerTableSuccess,
  onUniverseSummaryRequest,
  onUniverseSummaryResponseReceived,
  setFocalItem,
  setTopDrawerActiveTab,
  toggleOverviewWaterfallChartDataLabels,
  toggleSwitchingBreakdownChartDataLabels,
  toggleUniverseSummaryChartDataLabels,
  reset,
} = gainsAndLossSlice.actions;

export default gainsAndLossSlice.reducer;
