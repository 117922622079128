import { type TrialAndRepeatLocalParametersResponseDto } from "../models/TrialAndRepeatLocalParametersResponseDto";
import { trialAndRepeatApiSlice } from "./trial-and-repeat-api-slice";

export const trialAndRepeatLocalParametersApiSlice =
  trialAndRepeatApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        TrialAndRepeatLocalParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetLocalParametersQuery } =
  trialAndRepeatLocalParametersApiSlice;
