import {
  type DragEndEvent,
  type DragStartEvent,
  type DragMoveEvent,
  type DragOverEvent,
  type DragCancelEvent,
  DndContext,
  closestCorners,
  DragOverlay,
} from "@dnd-kit/core";
import classnames from "classnames";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import { Item } from "../item/SimplifiedItem";
import { type SimpleItem } from "../models/Item";
import { type SimpleZone, type Container } from "../models/Zone";
import styles from "./DragAndMultiDrop.module.css";
import { DragAndMultiDropContainer } from "./DragAndMultiDropContainer";

export type DragAndMultiDropProps = {
  activeItem: SimpleItem | undefined;
  className?: string;
  containers: Container[];
  dropdownOptions: PanelOption[];
  items: SimpleItem[];
  onDragCancel?: (event: DragCancelEvent) => void;
  onDragEnd?: (event: DragEndEvent) => void;
  onDragMove?: (event: DragMoveEvent) => void;
  onDragOver?: (event: DragOverEvent) => void;
  onDragStart?: (event: DragStartEvent) => void;
  onRemove: (item: SimpleItem) => void;
  zones: SimpleZone[];
};

export const DragAndMultiDrop = ({
  activeItem,
  items,
  zones,
  containers,
  onRemove,
  onDragStart,
  onDragEnd,
  onDragMove,
  onDragOver,
  onDragCancel,
  className,
  dropdownOptions,
}: DragAndMultiDropProps) => (
  <div
    className={classnames(styles.dndContainer, className, styles.aggRankModal)}
  >
    <DndContext
      collisionDetection={closestCorners}
      onDragCancel={onDragCancel}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
    >
      {containers.map((container) => (
        <DragAndMultiDropContainer
          container={container}
          dropdownOptions={dropdownOptions}
          items={items.filter((item) => item.containerId === container.id)}
          key={container.id}
          onRemove={onRemove}
          type={container.containerType}
          zones={zones.filter((zone) => zone.containerId === container.id)}
        />
      ))}
      <DragOverlay>
        {activeItem ? <Item item={activeItem} onRemove={() => {}} /> : null}
      </DragOverlay>
    </DndContext>
  </div>
);
