import { type GainsAndLossUniverseSummary } from "../../models/GainsAndLossUniverseSummaryResponseDto";

export const csvMeasuresTransformation = (
  universeSummary: GainsAndLossUniverseSummary,
  metricHeader: string
): string[][] => {
  const columnHeader = ["Category"];
  for (const measure of universeSummary.data.measures) {
    columnHeader.push(`${metricHeader} - ${measure.name}`);
  }

  const csvData = [columnHeader];
  for (const [
    measureIndex,
    displayName,
  ] of universeSummary.measureDisplayNames.entries()) {
    const row = [displayName];
    for (const measure of universeSummary.data.measures) {
      row.push(measure.values[measureIndex].toString());
    }

    csvData.push(row);
  }

  return csvData;
};
