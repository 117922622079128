import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type NestedFolderDto } from "../models";

export const myReportsApi = createApi({
  baseQuery: getBaseQuery(`/api/report-parameters-web`),
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getGeneratedReports: builder.query<
      NestedFolderDto,
      { divisionName: string }
    >({
      providesTags: ["generated-reports"],
      query: ({ divisionName }) =>
        `/ReportParameters/GetMyReportsNested/${divisionName}`,
    }),
  }),
  reducerPath: "myReportsApi",
  tagTypes: ["generated-reports"],
});

export const { useGetGeneratedReportsQuery, useLazyGetGeneratedReportsQuery } =
  myReportsApi;
