type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const KeyMeasureTrendsIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Key measure trends report"}</title>
      <g clipPath="url(#clip0_1991_122268)">
        <path
          d="M2 15.6824C2 15.6169 2.02571 15.5541 2.0716 15.5073L6.9041 10.5846C7.0609 10.4248 7.3325 10.5358 7.3325 10.7597V22H2V15.6824Z"
          fill={colour1}
        />
        <path
          d="M21.9457 2.29766C21.9792 2.14019 21.8537 1.99367 21.6909 2.00021L18.8812 2.11318C18.6733 2.12154 18.5661 2.36248 18.7006 2.51938L19.1523 3.04666L13.4329 7.56567L9.5827 4.59494C9.09338 4.21739 8.39764 4.25183 7.94899 4.67581L2.30866 10.006C1.90962 10.3831 1.89565 11.0083 2.27747 11.4024C2.65929 11.7965 3.2923 11.8103 3.69134 11.4332L8.86281 6.54611L12.6689 9.4828C13.1254 9.83499 13.767 9.83133 14.2193 9.47395L20.4447 4.55517L20.9323 5.12439C21.0667 5.28129 21.3251 5.21678 21.3678 5.01564L21.9457 2.29766Z"
          fill={colour2}
        />
        <path
          d="M9.33252 9.68643C9.33252 9.4758 9.57703 9.35958 9.74037 9.49256L13.1366 12.2578C13.2193 12.3252 13.3357 12.3326 13.4263 12.2763L14.2844 11.7434C14.4509 11.6399 14.6663 11.7597 14.6663 11.9557V22H9.33252V9.68643Z"
          fill={colour2}
        />
        <path
          d="M16.6675 10.2652C16.6675 10.191 16.7004 10.1207 16.7574 10.0732L21.5899 6.04547C21.7527 5.90976 22 6.02555 22 6.23752V22H16.6675V10.2652Z"
          fill={colour3}
        />
      </g>
      <defs>
        <clipPath id="clip0_1991_122268">
          <rect
            fill="white"
            height="20"
            transform="translate(2 2)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
