import {
  ddLog,
  type HierarchyAttributeParameterOption,
  HierarchyGroupRuleOperator,
  HierarchyShortName,
  HierarchyType,
  useLazyAttributesCountQuery,
  type ParameterDto,
  type FilterCriteria,
  useLazyCountChildrenQuery,
  type AttributeFilter,
  ParameterId,
} from "@quantium-enterprise/common-ui";
import { Spinner, SpinnerSize } from "@quantium-enterprise/qds-react";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../states/hooks";
import { getGroupParameterState } from "../groups/GroupParameterState";
import { getHierarchyState } from "./HierarchyState";
import { LevelOfAnalysisParameter } from "./LevelOfAnalysisParameter";
import styles from "./LevelOfAnalysisParameter.module.css";
import { getLevelOfAnalysisState } from "./LevelOfAnalysisState";

type LevelOfAnalysisParameterQueryProps = {
  parameterDto: ParameterDto;
};

export const LevelOfAnalysisParameterQuery = ({
  parameterDto,
}: LevelOfAnalysisParameterQueryProps) => {
  const { division } = useParams();

  const loaState = useAppSelector(getLevelOfAnalysisState(parameterDto.id));
  const hierarchyState = useAppSelector(
    getHierarchyState(ParameterId.ProductHierarchy)
  );
  const groupsState = useAppSelector(
    getGroupParameterState(ParameterId.ProductGroups)
  );

  const hasHierarchySelections = useMemo(
    () => hierarchyState && hierarchyState.selectedRows.length > 0,
    [hierarchyState]
  );

  const hasGroupSelections = useMemo(
    () => groupsState && groupsState.confirmedSelections.length > 0,
    [groupsState]
  );

  const isGroupsValidating = useMemo(
    () => groupsState && groupsState.pendingSelections.length > 0,
    [groupsState]
  );

  const [
    triggerAttributesCountQuery,
    { data: attributesCount, isSuccess: isAttributesCountSuccess },
  ] = useLazyAttributesCountQuery();

  const [
    triggerChildrenCountQuery,
    { data: childrenCount, isSuccess: isChildrenCountSuccess },
  ] = useLazyCountChildrenQuery();

  const fetchLOACounts = useCallback(async () => {
    if ((!hierarchyState && !groupsState) || !loaState) {
      return;
    }

    const uniqueShortNames = new Set(
      hierarchyState?.selectedRows.map((selectedRow) => selectedRow.shortName)
    );
    const selectedItems = Array.from(uniqueShortNames).map(
      (uniqueShortName: string) => ({
        codes: hierarchyState
          ? hierarchyState.selectedRows
              .filter(
                (selectedRow) => selectedRow.shortName === uniqueShortName
              )
              .map((matchedRow) => matchedRow.code)
          : [],
        shortName: uniqueShortName,
      })
    );

    const productHierarchyGroupedByShortName: { [key: string]: string[] } = {};
    for (const selectedItem of selectedItems) {
      if (
        Object.keys(productHierarchyGroupedByShortName).includes(
          selectedItem.shortName
        )
      )
        productHierarchyGroupedByShortName[selectedItem.shortName] = [
          ...productHierarchyGroupedByShortName[selectedItem.shortName],
          ...selectedItem.codes,
        ];
      else
        productHierarchyGroupedByShortName[selectedItem.shortName] = [
          ...selectedItem.codes,
        ];
    }

    const productHierarchyFilterCriteria: FilterCriteria[] = Object.keys(
      productHierarchyGroupedByShortName
    ).map(
      (key: string): FilterCriteria => ({
        includeFilters: [
          { shortName: key, codes: productHierarchyGroupedByShortName[key] },
        ],
        excludeFilters: [],
      })
    );

    // product group selections
    const productGroupsFilterCriteria: FilterCriteria[] =
      groupsState?.confirmedSelections
        ? groupsState.confirmedSelections.map((pg) => ({
            includeFilters: pg.rules
              ? pg.rules
                  .filter(
                    (rule) => rule.operator === HierarchyGroupRuleOperator.Is
                  )
                  .map((rule) => ({
                    shortName: rule.shortName,
                    codes: rule.values,
                  }))
              : [],
            excludeFilters: pg.rules
              ? pg.rules
                  .filter(
                    (rule) => rule.operator === HierarchyGroupRuleOperator.IsNot
                  )
                  .map((rule) => ({
                    shortName: rule.shortName,
                    codes: rule.values,
                  }))
              : [],
          }))
        : [];

    const loaOptions = (
      loaState.parameterConfig.options as HierarchyAttributeParameterOption[]
    ).filter((option) => option.value !== HierarchyShortName.ProductGroup);

    const focalAttributes = loaOptions
      .filter((option) => !option.isHierarchical || option.isLeaf)
      .map((option) => option.value);

    const attributesCountRequestDto = {
      division: division ?? "",
      hierarchyType: HierarchyType.Product,
      payload: {
        focalAttributes,
        filters: [
          ...productHierarchyFilterCriteria,
          ...productGroupsFilterCriteria,
        ],
      },
    };

    await triggerAttributesCountQuery(attributesCountRequestDto);

    const hierarchyLevels = loaOptions
      .filter((option) => option.isHierarchical)
      .map((option) => option.value)
      .filter((value) => !focalAttributes.includes(value));

    const productHierarchyAsAttributeFilters: AttributeFilter[] = Object.keys(
      productHierarchyGroupedByShortName
    ).map(
      (key: string): AttributeFilter => ({
        shortName: key,
        codes: productHierarchyGroupedByShortName[key],
      })
    );

    const hierarchyLevelsCountRequestDto = {
      division: division ?? "",
      hierarchyType: HierarchyType.Product,
      payload: {
        shortNamesToCount: hierarchyLevels,
        selectedItems: productHierarchyAsAttributeFilters,
      },
    };

    await triggerChildrenCountQuery(hierarchyLevelsCountRequestDto);
  }, [
    hierarchyState,
    groupsState,
    loaState,
    division,
    triggerAttributesCountQuery,
    triggerChildrenCountQuery,
  ]);

  useEffect(() => {
    if (!isGroupsValidating && (hasHierarchySelections || hasGroupSelections)) {
      fetchLOACounts().catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
    // We only want to execute the count on first load of component after groups are validated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupsValidating]);

  const isLoading =
    isGroupsValidating ||
    ((hasHierarchySelections || hasGroupSelections) &&
      !(isAttributesCountSuccess && isChildrenCountSuccess));

  return (
    <div className={styles.levelOfAnalysisParameter}>
      <LevelOfAnalysisParameter
        attributesCounts={attributesCount}
        childrenCount={childrenCount}
        isAttributesCountSuccess={isAttributesCountSuccess}
        isChildrenCountSuccess={isChildrenCountSuccess}
        isLoading={isLoading}
        parameterDto={parameterDto}
      />
      {isLoading && (
        <div className={styles.loading}>
          <Spinner size={SpinnerSize.Large} />
        </div>
      )}
    </div>
  );
};
