import { useMemo, useState } from "react";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import CollapsiblePanel from "../CollapsiblePanel";
import SingleSelectDropdown from "../SingleSelectDropdown";
import SingleSelectRadio from "../SingleSelectRadio";
import styles from "./SegmentFilter.module.css";
import {
  createSegmentOptions,
  createSegmentationOptions,
  getDisplayName,
} from "./utils";

export type SegmentOption = {
  isDefault?: boolean;
  optionDisabled?: boolean;
  segmentLabel: string;
  segmentValue: string;
  segmentationLabel: string;
  segmentationValue: string;
};

export type SegmentFilterProps = {
  isCollapsed: boolean;
  isDisabled?: boolean;
  onSelection?: (values: string[]) => unknown;
  secondaryTitle?: string;
  segmentOptions: SegmentOption[];
  selectedValues: string[];
  title?: string;
  toggleCollapsed: () => void;
};

const defaultSelectedValues: string[] = [];
const defaultOnSelection = () => {};

export const SegmentFilter = ({
  isCollapsed,
  isDisabled = false,
  onSelection = defaultOnSelection,
  selectedValues = defaultSelectedValues,
  segmentOptions,
  title = "Segmentation",
  secondaryTitle = "Customer segment",
  toggleCollapsed,
}: SegmentFilterProps) => {
  const [selectedSegmentation, setSelectedSegmentation] = useState(
    selectedValues[0]
  );

  const [selectedSegment, setSelectedSegment] = useState(selectedValues[1]);

  // Create the list of options for the segmentation
  const segmentationOptions = createSegmentationOptions(segmentOptions);

  useMemo(() => {
    if (selectedSegment !== selectedValues[1]) {
      setSelectedSegment(selectedValues[1]);
    }
  }, [selectedSegment, selectedValues]);

  // Create a record of segment options, the key is the segmentation value
  const segmentOptionsDictionary = createSegmentOptions(
    segmentationOptions.map((segmentation) => segmentation.value),
    segmentOptions
  );

  const handleSegmentationSelection = (option: PanelOption) => {
    const value = option.value as string;
    setSelectedSegmentation(value);
    setSelectedSegment(segmentOptionsDictionary[value][0].value.toString());
    onSelection([value, segmentOptionsDictionary[value][0].value.toString()]);
  };

  const handleSegmentSelection = (option: PanelOption) => {
    const value = option.value as string;
    setSelectedSegment(value);
    onSelection([selectedSegmentation, value]);
  };

  const summaryPanel = isDisabled
    ? "N/A"
    : getDisplayName(selectedSegmentation, segmentationOptions).concat(
        ` - ${getDisplayName(
          selectedSegment,
          segmentOptionsDictionary[selectedSegmentation]
        )}`
      );

  const segmentationComponent =
    segmentationOptions.length === 1 ? (
      <p className={styles.singleSegmentationText}>
        {" "}
        {segmentationOptions[0].labelElement ??
          segmentationOptions[0].label}{" "}
      </p>
    ) : (
      <SingleSelectDropdown
        isDisabled={isDisabled}
        onSelection={handleSegmentationSelection}
        selectOptions={segmentationOptions}
        selectedValue={selectedSegmentation}
        title={title}
      />
    );

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content: (
          <div>
            {segmentationComponent}
            <p className={styles.title}>{secondaryTitle}</p>
            <SingleSelectRadio
              isDisabled={isDisabled}
              onSelection={handleSegmentSelection}
              selectOptions={segmentOptionsDictionary[selectedSegmentation]}
              selectedValue={isDisabled ? "All" : selectedSegment}
              title={title}
            />
          </div>
        ),
        summary: summaryPanel,
      }}
    </CollapsiblePanel>
  );
};

export default SegmentFilter;
