import {
  HierarchyGroupEvaluationType,
  HierarchyItemType,
  HierarchyShortName,
  ItemTrackingProperties,
  ItemTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  type FocalItem,
} from "@quantium-enterprise/common-ui";
import { useCallback } from "react";
import { dispatchAddFastReportingTabs } from "report-tabs-ui";
import { useFastReportingSelector } from "./hooks";
import { selectNewTabParameters } from "./parameterSlice";

export const useOpenFastReportingTabs = () => {
  const newTabState = useFastReportingSelector(selectNewTabParameters);
  const eventTrackingService = useEventTrackingServiceContext();

  const openTab = useCallback(
    (items: FocalItem[]) => {
      dispatchAddFastReportingTabs(
        items.map((item) => ({ focalItem: item, initialState: newTabState }))
      );

      for (const item of items) {
        let focalItemType: ItemTrackingProperty | null = null;
        let focalItemShortname: string | null = null;
        switch (item.type) {
          case HierarchyItemType.Hierarchy:
          case HierarchyItemType.Leaf:
            focalItemType = ItemTrackingProperty.Hierarchy;
            focalItemShortname = item.shortName;
            break;
          case HierarchyItemType.Attribute:
            focalItemType = ItemTrackingProperty.AttributeLevel;
            focalItemShortname = item.shortName;
            break;
          case HierarchyShortName.ProductGroup:
            focalItemType = ItemTrackingProperty.ProductGroup;
            focalItemShortname =
              item.evaluationType === HierarchyGroupEvaluationType.Static
                ? "PGS"
                : "PGD";
            break;
          default:
            break;
        }

        if (focalItemType && focalItemShortname) {
          eventTrackingService.trackEvent(
            TrackingComponent.FastReportingTab,
            TrackingEvent.Opened,
            ItemTrackingProperties.item(focalItemType, focalItemShortname)
          );
        }
      }
    },
    [newTabState, eventTrackingService]
  );

  return openTab;
};

export default useOpenFastReportingTabs;
