import { type ReportParametersDto } from "@quantium-enterprise/common-ui/src/models/report-parameters-dto";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { useFetchNodesQuery } from "../../common/services/hierarchy-slice";
import { parentNodesToFetch } from "../../common/utils/focal-item-labels-utils";
import { useLazyGetCompareMetricsReportletDataQuery } from "../services/compare-metrics-reportlet-api-slice";
import {
  onParentNodeDataReceived,
  onReportletDataReceived,
} from "../services/compare-metrics-slice";
import {
  selectButtonSelections,
  selectChartContentSelection,
  selectFocalItems,
  selectFocalItemSelection,
  selectInitialDataRecieved,
  selectLocalParametersInitialised,
  selectLocalParametersSelections,
  selectMetricSelectionSelection,
  selectMetadata,
  selectReportletData,
  selectReportletDataReceived,
  selectXAxisSelection,
} from "../services/compare-metrics-slice-selectors";
import { getUserSelections } from "../utils/local-parameter-utils";
import styles from "./CompareMetricsReportlet.module.css";
import { CompareMetricsReportletButtons } from "./CompareMetricsReportletButtons";
import { CompareMetricsReportletChart } from "./CompareMetricsReportletChart";
import { CompareMetricsReportletFilterGroup } from "./CompareMetricsReportletFilterGroup";
import { CompareMetricsReportletTable } from "./CompareMetricsReportletTable";

const focalItemsKey = "focalItems";
export type CompareMetricReportletProps = {
  reportParameters?: ReportParametersDto;
};

export const CompareMetricsReportlet = ({
  reportParameters,
}: CompareMetricReportletProps) => {
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();
  const focalItems = useSelector(selectFocalItems);
  const xAxisSelection = useSelector(selectXAxisSelection);
  const chartContentSelection = useSelector(selectChartContentSelection);
  const metricSelection = useSelector(selectMetricSelectionSelection);
  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const reportId = useSelector(selectMetadata).reportId;
  const focalItemSelection = useSelector(selectFocalItemSelection);
  const buttonSelections = useSelector(selectButtonSelections);
  const reportletData = useSelector(selectReportletData);
  const reportletDataReceived = useSelector(selectReportletDataReceived);
  const focalTableDataReceived = useSelector(selectInitialDataRecieved);
  const compareMetricsTabs = [
    {
      content: (
        <ErrorBoundary key="chart">
          <CompareMetricsReportletChart reportParameter={reportParameters} />
        </ErrorBoundary>
      ),
      title: "Chart",
    },
    {
      content: (
        <ErrorBoundary key="table">
          <CompareMetricsReportletTable reportParameter={reportParameters} />
        </ErrorBoundary>
      ),
      title: "Table",
    },
  ];

  const selectedTabIndex = compareMetricsTabs.findIndex(
    (tab) => tab.title === buttonSelections.chartTableSelection
  );

  const isDataLoaded = Boolean(localParametersInitialised && reportId);
  const isRequestInvalid =
    focalTableDataReceived &&
    focalItems.length === 0 &&
    chartContentSelection !== focalItemsKey &&
    xAxisSelection !== focalItemsKey;

  const [
    getCompareMetricsReportletDataQuery,
    { data: compareMetricsReportletData, isFetching: isDataFetching },
  ] = useLazyGetCompareMetricsReportletDataQuery();

  const fetchReportletData = useCallback(async () => {
    await getCompareMetricsReportletDataQuery({
      divisionName: activeDivisionName,
      requestPayload: {
        focalItems,
        localSelectedValues: getUserSelections(localParametersSelections),
        chartContent: chartContentSelection,
        metric: metricSelection,
        reportId,
        selectedFocalItem: focalItemSelection,
        xAxis: xAxisSelection,
      },
    });
  }, [
    activeDivisionName,
    chartContentSelection,
    focalItems,
    focalItemSelection,
    getCompareMetricsReportletDataQuery,
    localParametersSelections,
    metricSelection,
    reportId,
    xAxisSelection,
  ]);

  useEffect(() => {
    if (compareMetricsReportletData) {
      dispatch(onReportletDataReceived(compareMetricsReportletData));
    }
  }, [dispatch, compareMetricsReportletData]);

  useEffect(() => {
    if (isDataLoaded && !isRequestInvalid) {
      void fetchReportletData();
    }
  }, [fetchReportletData, isDataLoaded, isRequestInvalid]);

  const parentNodeNumbers = useMemo(
    () => parentNodesToFetch(focalItems),
    [focalItems]
  );

  const { data: hierarchySliceParentNodes, isFetching: isParentNodesFetching } =
    useFetchNodesQuery(
      {
        hierarchyType: "Product",
        nodeNumbers: parentNodeNumbers,
        reportId,
      },
      {
        skip: parentNodeNumbers.length === 0 || !reportId,
      }
    );

  useEffect(() => {
    if (hierarchySliceParentNodes) {
      dispatch(onParentNodeDataReceived(hierarchySliceParentNodes.nodes));
    }
  }, [dispatch, hierarchySliceParentNodes]);

  return (
    <div className={styles.reportlet}>
      <Accordion
        subtitle="Identify and compare performance drivers across selected products, brands or categories, locations and time."
        title="Compare metrics"
      >
        <CompareMetricsReportletFilterGroup
          isParentNodesFetching={isParentNodesFetching}
        />
        <CompareMetricsReportletButtons />
        <NoDataChartWrapper
          isLoading={
            (isDataFetching || !isDataLoaded || !reportletDataReceived) &&
            !isRequestInvalid
          }
          noData={reportletData.itemData.length === 0 || isRequestInvalid}
        >
          {compareMetricsTabs[selectedTabIndex].content}
        </NoDataChartWrapper>
      </Accordion>
    </div>
  );
};

export default CompareMetricsReportlet;
