import { type TrialAndRepeatTableRequestDto } from "../models/TrialAndRepeatTableRequestDto";
import { type TrialAndRepeatTableResponseDto } from "../models/TrialAndRepeatTableResponseDto";
import { getSlugFromBreakdown } from "../utils/getSlugFromBreakdown";
import { trialAndRepeatApiSlice } from "./trial-and-repeat-api-slice";

export const trialAndRepeatDataTableApiSlice =
  trialAndRepeatApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getReportDataTable: builder.query<
        TrialAndRepeatTableResponseDto,
        {
          breakdownType: string;
          division: string;
          payload: TrialAndRepeatTableRequestDto;
          reportId: string | undefined;
        }
      >({
        query: ({ division, reportId, breakdownType, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetTableRows/${getSlugFromBreakdown(
            breakdownType
          )}/${division}/${reportId ?? ""}`,
        }),
      }),
    }),
  });

export const { useLazyGetReportDataTableQuery } =
  trialAndRepeatDataTableApiSlice;
