import {
  type BasketLimitsChartRow,
  type StoreBreakdownRow,
} from "../models/basket-limits-reportlet-models";

export const csvTransformationBasketLimits = (
  chartRows: BasketLimitsChartRow[]
) => {
  const columnHeaders = ["Store", "Store number", "Number of baskets"];
  const csvData = [columnHeaders];
  for (const row of chartRows) {
    csvData.push([
      row.store.name,
      row.store.itemCode,
      row.numberOfBaskets.toString(),
    ]);
  }

  return csvData;
};

export const csvTransformationStoreBreakdown = (
  chartRows: StoreBreakdownRow[]
) => {
  const columnHeaders = [
    "Store",
    "Store number",
    "State",
    "Qty purchased",
    "Value of baskets",
    "Number of baskets",
    "Average price",
    "% of baskets",
  ];
  const csvData = [columnHeaders];
  for (const row of chartRows) {
    csvData.push([
      row.store.name,
      row.store.itemCode,
      row.state,
      row.qtyPurchased.toLocaleString(),
      row.valueOfBaskets ? row.valueOfBaskets.toLocaleString() : "",
      row.numberOfBaskets ? row.numberOfBaskets.toLocaleString() : "",
      row.averagePrice ? row.averagePrice.toLocaleString() : "",
      row.percentOfBaskets ? (row.percentOfBaskets * 100).toLocaleString() : "",
    ]);
  }

  return csvData;
};
