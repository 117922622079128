import { range } from "ramda";
import styles from "./DriverTreeHeatMap.module.css";
import { getHeatMapColour, defaultMaxNumberOfColours } from "./utils";

type DriverTreeHeatMapProps = {
  maxAbsGrowth: string;
};

export const DriverTreeHeatMap = ({ maxAbsGrowth }: DriverTreeHeatMapProps) => {
  const barWidth = 8;
  const barGap = 7;
  const barHeight = 24;
  const halfNumberOfBars = Math.floor(defaultMaxNumberOfColours / 2);
  // we have an odd number of bars so the mid point will === 0
  const numbers = [
    ...range(-halfNumberOfBars, 1),
    ...range(1, halfNumberOfBars + 1),
  ];
  const heatMap = numbers.map((x, index) => (
    <rect
      fill={getHeatMapColour(halfNumberOfBars, x)}
      height={barHeight}
      key={x}
      rx="3"
      width={barWidth}
      x={index * (barWidth + barGap)}
    />
  ));

  return (
    <div className={styles.driverTreeHeatMap}>
      <div className={styles.driverTreeHeatMapText}>
        <span>Decline</span>
        <span>Growth</span>
      </div>
      <svg
        height={barHeight}
        width={defaultMaxNumberOfColours * (barWidth + barGap) - barGap}
      >
        {heatMap}
      </svg>
      <div className={styles.driverTreeHeatMapText}>
        <span>-{maxAbsGrowth}</span>
        <span>+{maxAbsGrowth}</span>
      </div>
    </div>
  );
};
