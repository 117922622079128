import { ddLog } from "@quantium-enterprise/common-ui";
import { type SummaryRequestDto } from "../models/basket-quantities-summary-request-dto";
import { type BasketQuantitiesSummaryResponseDto } from "../models/basket-quantities-summary-response-dto";
import { basketQuantitiesApiSlice } from "./basket-quantities-api-slice";
import { onBasketQuantitiesSummaryResponseReceived } from "./basket-quantities-slice";

export const basketQuantitiesSummaryApiSlice =
  basketQuantitiesApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getBasketQuantitiesSummaryReportlet: builder.query<
        BasketQuantitiesSummaryResponseDto,
        {
          division: string;
          payload: SummaryRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetSummaryChartData/${division}`,
        }),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data, meta } = await queryFulfilled;

            dispatch(
              onBasketQuantitiesSummaryResponseReceived(
                meta?.response?.status === 200
                  ? data
                  : { categories: [], series: [] }
              )
            );
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error retrieving summary chart data for basket quantities report",
              undefined,
              "error",
              error
            );
          }
        },
      }),
    }),
  });

export const { useLazyGetBasketQuantitiesSummaryReportletQuery } =
  basketQuantitiesSummaryApiSlice;
