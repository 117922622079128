import { type HierarchyValueDto } from "@quantium-enterprise/common-ui";
import { createSelector, type PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  type ColumnFiltersState,
  type RowSelectionState,
  type VisibilityState,
} from "@tanstack/react-table";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import {
  DEFAULT_DELETED_LINE_FILTER_VALUE,
  DELETED_LINE_FILTER_COLUMN_ID,
} from "components-ui/src/tables/common/filters/excludeDeletedLineFilter";
import {
  DEFAULT_SELECTED_ITEMS_ONLY_FILTER_VALUE,
  SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID,
} from "components-ui/src/tables/common/filters/selectedItemsOnlyFilter";
import { type Series } from "highcharts";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getReportLocalParameters } from "../../common/utils/local-parameters/getReportLocalParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { DEFAULT_SLIDER_VALUES } from "../components/SubstitutabilityReportlet/SubstitutabilitySlider";
import { type Attribute } from "../models/Attribute";
import { type LocalParameterValues } from "../models/LocalParameterValues";
import { type Metric } from "../models/Metric";
import { type Percentiles } from "../models/Percentiles";
import { type ProductSubstitutabilityHierarchyProductData } from "../models/ProductSubstitutabilityHierarchyProductData";
import { type ProductSubstitutabilityLocalParametersResponseDto } from "../models/ProductSubstitutabilityLocalParametersResponseDto";
import { DEFAULT_PAGINATION } from "../models/ProductSubstitutabilityTableRowsRequestDto";
import {
  type Pagination,
  type TableRowsResponseDto,
} from "../models/ProductSubstitutabilityTableRowsResponseDto";
import { type ProductUniquenessReportletResponseDto } from "../models/ProductUniquessReportletResponseDto";
import {
  type FocalProduct,
  type SubstitutabilityResponseDto,
} from "../models/SubstitutabilityResponseDto";
import { type SubstitutabilityTableRow } from "../models/SubstitutabilityTableData";
import { getLocalParameterValues } from "../utils/getLocalParameterValues";
import { getSubstitutabilityTableData } from "../utils/getSubstitutabilityTableData";

const DEFAULT_FOCAL_ITEM_TABLE_SELECTED_ROW_SIZE = 30;

// Default selections for Uniqueness Reportlet Dropdowns

const DEFAULT_BUBBLE_SIZE_SELECTION = {
  label: "None",
  value: "None",
};
const DEFAULT_LEGEND_SELECTION = {
  label: "Brand",
  value: "BR",
};

export const getPersistenceKey = (reportId: string) =>
  `product-substitutability-${reportId}`;

export const DEFAULT_BUBBLE_SIZE = 10;

export type ProductSubstitutabilityPeristedSelections = {
  focalItems: HierarchyValueDto[];
  localParameterValues: LocalParameterValues;
  pagination: Pagination;
  rowSelection: RowSelectionState;
};

export type ProductSubstitutabilityState = {
  attributes: Attribute[];
  bubbleSizeSelection: PanelOption;
  columnFilters: ColumnFiltersState;
  columnVisibility: VisibilityState;
  enableExcludeDeletedLineFilter: boolean;
  enableSelectedItemsOnlyFilter: boolean;
  focalItemTableMetrics: Metric[];
  focalItemsData: ProductSubstitutabilityHierarchyProductData[];
  isSubstitutabilityReportletLoading: boolean;
  isUniquenessReportletLoading: boolean;
  legendSelection: PanelOption;
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;
  maxDisplayedSubstitutes: number;
  percentiles: Percentiles[];
  persistedSelections: ProductSubstitutabilityPeristedSelections;
  persistedSelectionsLoaded: boolean;
  reportError: boolean;
  reportId: string;
  reportName: string;
  searchQuery: string;
  showUniquenessChartDataLabels: boolean;
  substitutabilityData: FocalProduct[];
  substitutabilityTableData: SubstitutabilityTableRow[];
  substitutabilityTableMetrics: Metric[];
  topDrawerTableResponseReceived: boolean;
  topDrawerTableRows: ProductSubstitutabilityHierarchyProductData[];
  xAxisSelection: PanelOption;
  yAxisSelection: PanelOption;
};

export const initialState: ProductSubstitutabilityState = {
  attributes: [],
  bubbleSizeSelection: DEFAULT_BUBBLE_SIZE_SELECTION,
  columnVisibility: {},
  focalItemTableMetrics: [],
  focalItemsData: [],
  isSubstitutabilityReportletLoading: true,
  isUniquenessReportletLoading: true,
  legendSelection: DEFAULT_LEGEND_SELECTION,
  localParameters: [],
  localParametersInitialised: false,
  maxDisplayedSubstitutes: DEFAULT_SLIDER_VALUES.initial,
  persistedSelections: {
    focalItems: [],
    localParameterValues: {
      time: "",
      timePeriodLength: "",
      locationString: "",
    },
    pagination: DEFAULT_PAGINATION,
    rowSelection: {},
  },
  persistedSelectionsLoaded: false,
  reportError: false,
  reportId: "",
  reportName: "Product substitutability",
  searchQuery: "",
  showUniquenessChartDataLabels: false,
  substitutabilityData: [],
  substitutabilityTableData: [],
  substitutabilityTableMetrics: [],
  topDrawerTableResponseReceived: false,
  topDrawerTableRows: [],
  percentiles: [],
  xAxisSelection: {
    label: "",
    value: "",
  },
  yAxisSelection: {
    label: "",
    value: "",
  },
  columnFilters: [],
  enableExcludeDeletedLineFilter: false,
  enableSelectedItemsOnlyFilter: false,
};

export const productSubstitutabilitySlice = createSlice({
  initialState,
  name: "productSubstitutability",
  reducers: {
    onLoadMoreResponseReceived: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<TableRowsResponseDto>
    ) => {
      state.topDrawerTableRows[state.topDrawerTableRows.length - 1].isMoreRow =
        false;

      const newData = action.payload.pagination.hasNextPage
        ? action.payload.tableRows.map((item, index) => ({
            ...item,
            isMoreRow: index === action.payload.tableRows.length - 1,
          }))
        : action.payload.tableRows;
      state.topDrawerTableRows = state.topDrawerTableRows.concat(newData);

      state.persistedSelections.pagination = action.payload.pagination;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onReportError: (state: ProductSubstitutabilityState) => {
      state.reportError = true;
    },
    onReportOpen: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
        };
      }

      const persistedSelections: ProductSubstitutabilityPeristedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
        };
      }

      // Return pagination to default, and calculate the offset needed to return all
      // previously loaded rows in the first call.
      const updatedPagination: Pagination = {
        ...DEFAULT_PAGINATION,
        offset:
          persistedSelections.pagination.offset +
          persistedSelections.pagination.pageIndex *
            persistedSelections.pagination.pageSize,
      };

      return {
        ...initialState,
        persistedSelections: {
          ...persistedSelections,
          pagination: updatedPagination,
        },
        persistedSelectionsLoaded: true,
      };
    },
    onReportSuccess: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<{
        data: ProductSubstitutabilityLocalParametersResponseDto;
        reportId: string;
      }>
    ) => {
      state.reportName = action.payload.data.reportName;
      state.localParameters = getReportLocalParameters(
        action.payload.data.localParameters
      );

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParameterValues =
          getLocalParameterValues(state.localParameters);
      }

      state.localParametersInitialised = true;
      state.reportId = action.payload.reportId;

      // We do not persist localParameter values because user can't actually
      // modify them, but we also don't wait for this call before triggering
      // the TopDrawer endpoint, so potentially race condition if we try
      // and persist localParameters here
    },
    onSearchQueryChange: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<string>
    ) => {
      if (state.searchQuery !== action.payload) {
        state.persistedSelections.pagination.pageIndex = 0;
        state.searchQuery = action.payload;
      }
    },
    onSubstitutabilityReportletSliderChange: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<number>
    ) => {
      state.maxDisplayedSubstitutes = action.payload;
      state.substitutabilityTableData = getSubstitutabilityTableData(
        state.topDrawerTableRows,
        state.substitutabilityData,
        action.payload
      );
    },
    onSubstitutabilityReportletRequest: (
      state: ProductSubstitutabilityState
    ) => {
      state.isSubstitutabilityReportletLoading = true;
    },
    onSubstitutabilityReportletResponseReceived: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<SubstitutabilityResponseDto>
    ) => {
      if (state.topDrawerTableResponseReceived) {
        state.substitutabilityTableMetrics =
          action.payload.metrics.length > 0
            ? action.payload.metrics
            : state.substitutabilityTableMetrics;

        state.substitutabilityData = action.payload.focalProducts;
        state.substitutabilityTableData = getSubstitutabilityTableData(
          state.focalItemsData,
          action.payload.focalProducts,
          state.maxDisplayedSubstitutes
        );

        state.isSubstitutabilityReportletLoading = false;
      }
    },
    onTopDrawerTableResponseReceived: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<TableRowsResponseDto>
    ) => {
      if (state.persistedSelectionsLoaded) {
        const itemCodesToMatch = state.persistedSelections.focalItems.map(
          (item) => item.itemCode
        );

        state.focalItemsData = action.payload.tableRows.filter((row) =>
          itemCodesToMatch.includes(row.item.itemCode)
        );
      } else {
        state.focalItemsData = action.payload.tableRows.slice(
          0,
          DEFAULT_FOCAL_ITEM_TABLE_SELECTED_ROW_SIZE
        );
        state.persistedSelections.focalItems = state.focalItemsData.map(
          (item) => item.item
        );

        for (const item of state.persistedSelections.focalItems) {
          state.persistedSelections.rowSelection[item.itemCode] = true;
        }
      }

      state.persistedSelectionsLoaded = true;

      state.topDrawerTableRows = action.payload.pagination.hasNextPage
        ? action.payload.tableRows.map((item, index) => ({
            ...item,
            isMoreRow: index === action.payload.tableRows.length - 1,
          }))
        : action.payload.tableRows;

      state.persistedSelections.pagination = action.payload.pagination;

      state.attributes = action.payload.attributes;
      state.focalItemTableMetrics = action.payload.metrics;

      state.columnVisibility = Object.fromEntries(
        action.payload.attributes.map((attribute) => [
          attribute.shortName,
          attribute.visible,
        ])
      );

      state.topDrawerTableResponseReceived = true;

      if (action.payload.tableRows.length === 0) {
        state.isSubstitutabilityReportletLoading = false;
        state.isUniquenessReportletLoading = false;
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    setFocalItems: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<{
        isSelected: boolean;
        selectedItem: HierarchyValueDto;
      }>
    ) => {
      const currentFocalItemLength =
        state.persistedSelections.focalItems.length;

      if (action.payload.isSelected) {
        state.persistedSelections.focalItems =
          state.persistedSelections.focalItems.filter(
            (value) => value.itemCode !== action.payload.selectedItem.itemCode
          );

        state.persistedSelections.rowSelection =
          currentFocalItemLength > 1
            ? {
                ...state.persistedSelections.rowSelection,
                [action.payload.selectedItem.itemCode]: false,
              }
            : {};

        state.focalItemsData = state.focalItemsData.filter(
          (itemData) =>
            itemData.item.itemCode !== action.payload.selectedItem.itemCode
        );
      } else {
        state.persistedSelections.focalItems = [
          ...state.persistedSelections.focalItems,
          action.payload.selectedItem,
        ];
        state.persistedSelections.rowSelection[
          action.payload.selectedItem.itemCode
        ] = true;
        const selectedItemData = state.topDrawerTableRows.find(
          (row) => row.item.itemCode === action.payload.selectedItem.itemCode
        );
        if (selectedItemData) {
          state.focalItemsData.push(selectedItemData);
        }
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    unsetFocalItems: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<ProductSubstitutabilityHierarchyProductData[]>
    ) => {
      if (action.payload.length === 0) {
        state.persistedSelections.focalItems = [];
        state.persistedSelections.rowSelection = {};
        state.focalItemsData = [];
      } else {
        for (const row of action.payload) {
          const item = row.item;
          state.persistedSelections.focalItems =
            state.persistedSelections.focalItems.filter(
              (value) => value.itemCode !== item.itemCode
            );
          state.persistedSelections.rowSelection = {
            ...state.persistedSelections.rowSelection,
            [item.itemCode]: false,
          };
          state.focalItemsData = state.focalItemsData.filter(
            (itemData) => itemData.item.itemCode !== item.itemCode
          );
        }
      }

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onProductUniquenessReportletResponseReceived: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<ProductUniquenessReportletResponseDto>
    ) => {
      state.percentiles = [...action.payload.percentiles].sort(
        (a, b) => a.ordinal - b.ordinal
      );

      state.isUniquenessReportletLoading = false;

      state.yAxisSelection = {
        value: state.percentiles[0].key,
        label: state.percentiles[0].label,
      };

      state.xAxisSelection = {
        value: state.percentiles[1].key,
        label: state.percentiles[1].label,
      };
    },
    onXAxisSelectionChange: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<PanelOption>
    ) => {
      state.xAxisSelection.label = action.payload.label;
      state.xAxisSelection.value = action.payload.value;
    },
    onYAxisSelectionChange: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<PanelOption>
    ) => {
      state.yAxisSelection.label = action.payload.label;
      state.yAxisSelection.value = action.payload.value;
    },
    onAxisSwitchChange: (state: ProductSubstitutabilityState) => {
      const currentXAxisSelection = state.xAxisSelection;
      const currentYAxisSelection = state.yAxisSelection;

      state.xAxisSelection = currentYAxisSelection;
      state.yAxisSelection = currentXAxisSelection;
    },
    onBubbleSizeSelectionChange: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<PanelOption>
    ) => {
      state.bubbleSizeSelection.label = action.payload.label;
      state.bubbleSizeSelection.value = action.payload.value;
    },
    onLegendSelectionChange: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<PanelOption>
    ) => {
      state.legendSelection.label = action.payload.label;
      state.legendSelection.value = action.payload.value;
    },
    onModalSubmit: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<string>
    ) => {
      const columnToUpdate = action.payload;
      for (const column of Object.keys(state.columnVisibility)) {
        if (column === columnToUpdate) {
          state.columnVisibility[column] = !state.columnVisibility[column];
        }
      }
    },
    onUniquenessChartPercentileLegendUpdate: (
      state: ProductSubstitutabilityState,
      action: PayloadAction<Series>
    ) => {
      state.percentiles = state.percentiles.map((percentile) =>
        percentile.key === action.payload.userOptions.custom?.key
          ? {
              ...percentile,
              data: percentile.data.map((data) =>
                data.name === action.payload.userOptions.name
                  ? { ...data, visible: !data.visible }
                  : data
              ),
            }
          : percentile
      );
    },
    reset: () => initialState,
    toggleExcludeDeletedLineFilter: (state: ProductSubstitutabilityState) => {
      state.enableExcludeDeletedLineFilter =
        !state.enableExcludeDeletedLineFilter;

      const newColumnFilters: ColumnFiltersState = state.columnFilters.filter(
        (column) => column.id !== DELETED_LINE_FILTER_COLUMN_ID
      );

      state.columnFilters = [
        ...newColumnFilters,
        {
          id: DELETED_LINE_FILTER_COLUMN_ID,
          value: state.enableExcludeDeletedLineFilter
            ? DEFAULT_DELETED_LINE_FILTER_VALUE
            : undefined,
        },
      ];
    },
    toggleSelectedItemsOnlyFilter: (state: ProductSubstitutabilityState) => {
      state.enableSelectedItemsOnlyFilter =
        !state.enableSelectedItemsOnlyFilter;

      const newColumnFilters: ColumnFiltersState = state.columnFilters.filter(
        (column) => column.id !== SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID
      );

      state.columnFilters = [
        ...newColumnFilters,
        {
          id: SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID,
          value: state.enableSelectedItemsOnlyFilter
            ? DEFAULT_SELECTED_ITEMS_ONLY_FILTER_VALUE
            : undefined,
        },
      ];
    },
    toggleUniquenessChartDataLabels: (state: ProductSubstitutabilityState) => {
      state.showUniquenessChartDataLabels =
        !state.showUniquenessChartDataLabels;
    },
  },
});

// SELECTORS
// These are for getting data out of the store. They are memoized, so they only recalculate if the state they depend on has changed.
export const selectFocalItems = createSelector(
  (state: RootState) =>
    state.productSubstitutability.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectPagination = createSelector(
  (state: RootState) =>
    state.productSubstitutability.persistedSelections.pagination,
  (pagination) => pagination
);

export const selectReportId = createSelector(
  (state: RootState) => state.productSubstitutability.reportId,
  (reportId) => reportId
);

export const selectRowSelection = createSelector(
  (state: RootState) =>
    state.productSubstitutability.persistedSelections.rowSelection,
  (rowSelection) => rowSelection
);

export const selectTopDrawerTableResponseReceived = createSelector(
  (state: RootState) =>
    state.productSubstitutability.topDrawerTableResponseReceived,
  (topDrawerTableResponseReceived) => topDrawerTableResponseReceived
);

export const selectLocation = createSelector(
  (state: RootState) =>
    state.productSubstitutability.persistedSelections.localParameterValues
      .locationString,
  (location) => location
);

export const selectTime = createSelector(
  (state: RootState) =>
    state.productSubstitutability.persistedSelections.localParameterValues.time,
  (time) => time
);

export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.productSubstitutability.persistedSelections.localParameterValues
      .timePeriodLength,
  (timePeriodLength) => timePeriodLength
);

export const {
  onLoadMoreResponseReceived,
  onReportError,
  onReportOpen,
  onReportSuccess,
  onSearchQueryChange,
  onSubstitutabilityReportletRequest,
  onSubstitutabilityReportletResponseReceived,
  onSubstitutabilityReportletSliderChange,
  onTopDrawerTableResponseReceived,
  setFocalItems,
  unsetFocalItems,
  onProductUniquenessReportletResponseReceived,
  onXAxisSelectionChange,
  onYAxisSelectionChange,
  onAxisSwitchChange,
  onBubbleSizeSelectionChange,
  onLegendSelectionChange,
  onModalSubmit,
  onUniquenessChartPercentileLegendUpdate,
  reset,
  toggleExcludeDeletedLineFilter,
  toggleSelectedItemsOnlyFilter,
  toggleUniquenessChartDataLabels,
} = productSubstitutabilitySlice.actions;

export default productSubstitutabilitySlice.reducer;
