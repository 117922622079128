import {
  HierarchyItemType,
  LocationHierarchy as LocationHierarchyCommon,
} from "@quantium-enterprise/common-ui";
import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import {
  type Updater,
  type ExpandedState,
  type ColumnDef,
  type RowSelectionState,
  useReactTable,
  getExpandedRowModel,
  flexRender,
  type CellContext,
  type HeaderContext,
  getCoreRowModel,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, {
  type ReactElement,
  useMemo,
  useCallback,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";
import { Cell } from "./Cell";
import { Header } from "./Header";
import styles from "./HierarchySelectGrid.module.css";
import {
  type LocationHierarchy,
  type LocationHierarchyGridItem,
} from "./models/hierarchy";
import { convertToLocationHierarchyItems } from "./utils/HierarchySelectGridUtils";

/**
 * Notes:
 * onRowSelection: Use this if you want to get back the singular row that was selected/deselected.
 * onSelectionsChange: Use this if you want to get back all the rows selected after a select/deselect has occured.
 */
export type LocationSelectGridProps = {
  data: LocationHierarchy;
  enableSubRowSelection?: boolean;
  enableTitleSelection?: boolean;
  expandedRows: LocalHierarchyNodeSelection[];
  hideRowCheckbox?: boolean;
  id?: string;
  onExpandedChange: (expandedItems: LocalHierarchyNodeSelection[]) => void;
  onRowClick?: Function;
  onRowDoubleClick?: Function;
  onRowSelection?: (row: LocalHierarchyNodeSelection) => void;
  onSelectionsChange?: (selectedItems: LocalHierarchyNodeSelection[]) => void;
  selectedRows: LocalHierarchyNodeSelection[];
  title: string;
};

export const LocationSelectGrid = ({
  data,
  expandedRows,
  onExpandedChange,
  selectedRows,
  onSelectionsChange,
  onRowClick,
  onRowDoubleClick,
  title,
  id = "",
  hideRowCheckbox = false,
  enableTitleSelection = false,
  enableSubRowSelection = false,
  onRowSelection,
}: LocationSelectGridProps): ReactElement => {
  // Row expansions
  const [expanded, setExpanded] = useState<ExpandedState>({});

  // Row selections
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // prettier-ignore
  const hasDoubleClickEvent = useMemo(() => Boolean(onRowDoubleClick), [onRowDoubleClick]);
  const hasClickEvent = useMemo(() => Boolean(onRowClick), [onRowClick]);

  const cell = useCallback(
    ({ row, getValue }: CellContext<LocationHierarchyGridItem, unknown>) => {
      // eslint-disable-next-line no-warning-comments
      // TODO: remove undefined condition when hierarchy service returns datasets [CO3-1861]
      const isDisabled = false;

      // prepend code for stores only
      const displayValue =
        row.original.shortName === LocationHierarchyCommon.Store
          ? `${row.original.code} - ${String(getValue())}`
          : String(getValue());

      return (
        <Cell
          canExpand={row.getCanExpand()}
          depth={row.depth}
          handleToggleExpanded={row.getToggleExpandedHandler()}
          handleToggleSelected={(event) => {
            // const row = convertToLocationHierarchyItem(row.id)

            if (onRowSelection) {
              onRowSelection(row.original);
            }

            row.getToggleSelectedHandler()(event);
          }}
          hideCheckbox={hideRowCheckbox}
          id={row.original.nodeNumber.toString()}
          isDisabled={isDisabled}
          isExpanded={row.getIsExpanded()}
          isSelected={row.getIsSelected()}
          name={row.original.name}
          shortName={row.original.shortName}
          type={HierarchyItemType.Hierarchy}
          value={displayValue}
        />
      );
    },
    [hideRowCheckbox, onRowSelection]
  );

  const header = useCallback(
    ({ table }: HeaderContext<LocationHierarchyGridItem, unknown>) => (
      <Header
        handleToggleAllRowsSelected={table.getToggleAllRowsSelectedHandler()}
        id={id ? `${id}-header` : undefined}
        isChecked={table.getIsAllRowsSelected()}
        showCheckbox={enableTitleSelection}
        title={title}
      />
    ),
    [title, enableTitleSelection, id]
  );

  const nameColumn: ColumnDef<LocationHierarchyGridItem> = useMemo(
    () => ({
      accessorKey: "name",
      cell,
      header,
    }),
    [cell, header]
  );

  const columns = useMemo<Array<ColumnDef<LocationHierarchyGridItem>>>(
    () => [nameColumn],
    [nameColumn]
  );

  const getRowId = (row: LocationHierarchyGridItem) =>
    row.nodeNumber.toString();

  const hierarchyTable = useReactTable<LocationHierarchyGridItem>({
    columns,
    data: data.items,
    enableSubRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    onExpandedChange: (updaterOrValue: Updater<ExpandedState>) => {
      if (typeof updaterOrValue === "function") {
        const expandedIds = updaterOrValue(expanded);
        const expandedIdKeys = Object.keys(expandedIds);

        const expandedHierarchyItemRows = convertToLocationHierarchyItems(
          expandedIdKeys,
          hierarchyTable
        );
        onExpandedChange(expandedHierarchyItemRows);
      }
    },
    onRowSelectionChange: (updaterOrValue: Updater<RowSelectionState>) => {
      if (typeof updaterOrValue === "function") {
        const selectedIds = updaterOrValue(rowSelection);
        const selectedIdKeys = Object.keys(selectedIds);

        if (onSelectionsChange) {
          const selectedHierarchyItems = convertToLocationHierarchyItems(
            selectedIdKeys,
            hierarchyTable
          );
          onSelectionsChange(selectedHierarchyItems);
        }
      }
    },
    getRowId,
    state: {
      expanded,
      rowSelection,
    },
  });

  const rowModel = hierarchyTable.getRowModel();

  const tableRef = useRef<HTMLTableElement>(null);
  const headerRef = useRef<HTMLTableSectionElement>(null);

  const [tbodyMaxHeight, setTbodyMaxHeight] = useState("30vh");

  useEffect(() => {
    const newExpandedRows: ExpandedState = {};

    for (const row of expandedRows) {
      const localId = rowModel.flatRows.find(
        (model) => model.original.nodeNumber === row.nodeNumber
      )?.id;
      if (localId) {
        newExpandedRows[localId] = true;
      }
    }

    setExpanded(newExpandedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows]);

  useEffect(() => {
    const newSelectedRows: RowSelectionState = {};

    for (const row of selectedRows) {
      const localId = rowModel.flatRows.find(
        (model) => model.original.nodeNumber === row.nodeNumber
      )?.id;
      if (localId) {
        newSelectedRows[localId] = true;
      }
    }

    setRowSelection(newSelectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useLayoutEffect(() => {
    // Caters for an initial call when component is first loaded we calculate tbody max height
    if (tableRef.current && headerRef.current) {
      const newTbodyMaxHeight =
        tableRef.current.offsetHeight - headerRef.current.offsetHeight;
      setTbodyMaxHeight(`${newTbodyMaxHeight}px`);
    }
  }, []);

  return (
    <div className={classNames(styles.tableWrapper, styles.tableBody)}>
      <table className={styles.table} ref={tableRef}>
        <thead className={styles.tableHeader} ref={headerRef}>
          {hierarchyTable.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((visibleHeader) => (
                <th colSpan={visibleHeader.colSpan} key={visibleHeader.id}>
                  <div className={classNames(styles.headerValue, styles.flex)}>
                    {flexRender(
                      visibleHeader.column.columnDef.header,
                      visibleHeader.getContext()
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className={styles.tableBody}
          style={{ maxHeight: tbodyMaxHeight }}
        >
          {hierarchyTable.getRowModel().rows.map((row) => (
            <tr
              className={classNames(styles.tableRow, styles.locationRow, {
                [styles.hasPointer]: hasClickEvent || hasDoubleClickEvent,
                [styles.selectedRow]: row.getIsSelected(),
              })}
              data-testid="table-row"
              id="table-row"
              key={row.original.nodeNumber}
              onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                onRowClick?.(event, row.original.nodeNumber, row)
              }
              onDoubleClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                onRowDoubleClick?.(event, row.original.nodeNumber, row.original)
              }
            >
              {row.getVisibleCells().map((visibleCell) => (
                <td key={visibleCell.id}>
                  {flexRender(
                    visibleCell.column.columnDef.cell,
                    visibleCell.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LocationSelectGrid;
