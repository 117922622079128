import { type HeatMapDataRequestDto } from "../models/reportlet-request";
import { type HeatMapResponseDto } from "../models/reportlet-response";
import { timeOfDayDayOfWeekApiSlice } from "./time-of-day-day-of-week-api-slice";

export const timeOfDayDayOfWeekHeatmapDataSlice =
  timeOfDayDayOfWeekApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTimeOfDayDayOfWeekHeatMapData: builder.query<
        HeatMapResponseDto,
        { divisionName: string; requestPayload: HeatMapDataRequestDto }
      >({
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetHeatMapData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetTimeOfDayDayOfWeekHeatMapDataQuery } =
  timeOfDayDayOfWeekHeatmapDataSlice;
