import { type GainsAndLossBreakdownMiniChartResponseDto } from "../models/GainsAndLossBreakdownMiniChartResponseDto";
import { type GainsAndLossMiniChartRequestDto } from "../models/GainsAndLossMiniChartRequestDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossBreakdownMiniChartApiSlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getBreakdownMiniData: builder.query<
        GainsAndLossBreakdownMiniChartResponseDto,
        { division: string; payload: GainsAndLossMiniChartRequestDto }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `BreakdownMiniData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetBreakdownMiniDataQuery } =
  gainsAndLossBreakdownMiniChartApiSlice;
