import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type ColumnSort } from "@tanstack/react-table";
import {
  formatMetricValue,
  sortByIndex,
} from "../../../common/utils/export-parameter-summary-utils";
import { type TransformedRepertoireReportletResponse } from "../../services/repertoire-reportlet-api-slice";
import { getExportColumnHeader } from "../../utils/export-utils";

export const csvTransformation = (
  response: TransformedRepertoireReportletResponse,
  metricLabel: string,
  currencySymbol: string,
  tableSortingValue: ColumnSort,
  focalItemTableRows: string[]
) => {
  const columnHeaders = ["Cross products"];

  const csvData = [columnHeaders];
  const sortIndex = response.focalItemColumns.indexOf(
    tableSortingValue.id.split("-")[3]
  );
  const headers = response.focalItemColumns.map(
    (item) => response.crossProductData[item]?.name ?? item
  );
  columnHeaders.push(
    ...headers.map(
      getExportColumnHeader(currencySymbol, response.metricFormat, metricLabel)
    )
  );

  for (const item of focalItemTableRows) {
    const data = response.crossProductData[item];
    if (data) {
      const row = [
        data.name,
        ...data.values.map((metric) => formatMetricValue(metric)),
      ];
      csvData.push(row);
    }
  }

  const sortedCsvData = sortByIndex(
    csvData,
    sortIndex + 1,
    tableSortingValue.desc
  );
  const totalRow = [
    "Total",
    ...response.totalValues.data.map((metric) => {
      if (response.totalValues.format === MetricTypes.Currency) {
        return `${currencySymbol}${formatMetricValue(metric)}`;
      }

      return formatMetricValue(metric);
    }),
  ];
  sortedCsvData.push(totalRow);

  return sortedCsvData;
};
