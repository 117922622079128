import {
  type SegmentGuideline,
  type SegmentDto,
} from "@quantium-enterprise/common-ui";

export enum SegmentGuidelineWarningMessage {
  // disabled to match activation guideline key
  // eslint-disable-next-line unicorn/prevent-abbreviations
  MinNumBrands = "Minimum of three (3) brands (unless targeting own brand)",
  RetailerBrand = "Retailer brands should not exceed 30% of sales",
  TopAndRetailerBrand = "Top brand + retailer combined should not exceed 70% of sales",
  TopBrand = "Top brand should not exceed 60% of sales",
}

export const aggregateSegmentGuidelines = (segments: SegmentDto[]) => {
  const agg: Record<
    keyof typeof SegmentGuidelineWarningMessage,
    SegmentGuideline | null
  > = {
    MinNumBrands: null,
    RetailerBrand: null,
    TopAndRetailerBrand: null,
    TopBrand: null,
  };

  for (const segment of segments) {
    const segmentGuidelines = segment.segmentGuidelines ?? [];

    for (const guideline of segmentGuidelines) {
      const key =
        guideline.guidelineKey as keyof typeof SegmentGuidelineWarningMessage;
      if (!agg[key]) {
        agg[key] = guideline;
      }
    }
  }

  return agg;
};
