import {
  createRowNameMatchesSearchPredicate,
  type GroupFolderDto,
  groupsApi,
  mapFolderDtoToFolderOrGroupDto,
} from "@quantium-enterprise/common-ui";
import {
  type PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { type SegmentRow } from "../components/segment-library-table/SegmentLibraryTableCells";
import { type RootState } from "../store";
import { getChildIds } from "../utilities/folder-helper";

export type GroupListState = {
  activatedGroupIds: string[];
  expandedIds: string[];
  folders?: GroupFolderDto;
  renameState: { id?: string; isEditing: boolean };
  searchText: string;
  selectedSegments: SegmentRow[];
  showActiveSegments: boolean;
};
const initialState: GroupListState = {
  expandedIds: [],
  folders: undefined,
  renameState: { id: undefined, isEditing: false },
  searchText: "",
  activatedGroupIds: [],
  showActiveSegments: false,
  selectedSegments: [],
};

export const groupListSlice = createSlice({
  initialState,
  name: "group-list-slice",
  reducers: {
    // toggling and unsetting when child folders change expanded state
    setFolderExpandedState: (
      state: GroupListState,
      {
        payload: { id, isExpanded },
      }: PayloadAction<{
        id: string;
        isExpanded: boolean;
      }>
    ) => {
      let newExpandedIds = [...state.expandedIds];

      if (isExpanded) {
        if (!state.expandedIds.includes(id)) {
          newExpandedIds.push(id);
        }
      } else {
        // recursively remove this id and any of its children from the newExpandedIds array
        // eslint-disable-next-line no-lonely-if -- readability
        if (state.folders) {
          const idsToRemove = getChildIds(id, state.folders);

          newExpandedIds = newExpandedIds.filter(
            (groupOrFolderId: string) => !idsToRemove.includes(groupOrFolderId)
          );
        }
      }

      state.expandedIds = newExpandedIds;
    },
    setRenameId: (
      state: GroupListState,
      { payload: { id } }: PayloadAction<{ id?: string }>
    ) => {
      state.renameState.id = id;
    },

    setEditingState: (
      state: GroupListState,
      { payload: { editing } }: PayloadAction<{ editing: boolean }>
    ) => {
      state.renameState.isEditing = editing;
    },
    setSearchText: (state: GroupListState, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setActivatedGroupIds: (
      state: GroupListState,
      action: PayloadAction<string[]>
    ) => {
      state.activatedGroupIds = action.payload;
    },
    setShowActiveSegments: (
      state: GroupListState,
      action: PayloadAction<boolean>
    ) => {
      state.showActiveSegments = action.payload;
    },
    addSelectedSegments: (
      state: GroupListState,
      action: PayloadAction<SegmentRow>
    ) => {
      state.selectedSegments = [...state.selectedSegments, action.payload];
    },
    removeSelectedSegments: (
      state: GroupListState,
      action: PayloadAction<SegmentRow>
    ) => {
      state.selectedSegments = state.selectedSegments.filter(
        (segment) =>
          segment.customerGroupId !== action.payload.customerGroupId ||
          segment.key !== action.payload.key
      );
    },
    clearSelectedSegments: (state: GroupListState) => {
      state.selectedSegments = [];
    },
  },
  extraReducers: (builder) => {
    // whenever getGroupsAndFolders is called in the api slice, update the folders here.
    builder.addMatcher(
      groupsApi.endpoints.getGroupsAndFolders.matchFulfilled,
      (state, action) => {
        state.folders = action.payload;
      }
    );
  },
});

export const {
  setFolderExpandedState,
  setRenameId,
  setEditingState,
  setSearchText,
  setActivatedGroupIds,
  setShowActiveSegments,
  addSelectedSegments,
  removeSelectedSegments,
  clearSelectedSegments,
} = groupListSlice.actions;

export const groupListSliceReducer = groupListSlice.reducer;

/* Selectors */

export const selectSearchText = createSelector(
  (state: RootState) => state.groupListSlice.searchText,
  (searchText) => searchText
);

export const selectFoldersRaw = createSelector(
  (state: RootState) => state.groupListSlice.folders,
  (folders) => folders
);

export const selectExpandedIds = createSelector(
  (state: RootState) => state.groupListSlice.expandedIds,
  (expandedIds) => expandedIds
);

export const selectRenameState = createSelector(
  (state: RootState) => state.groupListSlice.renameState,
  (renameState) => renameState
);

export const selectActivatedGroupIds = createSelector(
  (state: RootState) => state.groupListSlice.activatedGroupIds,
  (activatedGroupIds) => activatedGroupIds
);

export const selectShowActivatedSegments = createSelector(
  (state: RootState) => state.groupListSlice.showActiveSegments,
  (showActiveSegments) => showActiveSegments
);

export const selectSelectedSegments = createSelector(
  (state: RootState) => state.groupListSlice.selectedSegments,
  (selectedSegments) => selectedSegments
);

// Converts the hierarchical list of folders and groups into a flat list with depth property
export const selectFoldersAndGroups = createSelector(
  [selectSearchText, selectFoldersRaw],
  (searchText, folders) => {
    if (!folders) return [];

    const flatFolders = mapFolderDtoToFolderOrGroupDto(folders);
    flatFolders.shift();

    if (searchText) {
      const rowNameMatchesSearch =
        createRowNameMatchesSearchPredicate(searchText);
      return flatFolders
        .filter((x) => !x.isFolder && rowNameMatchesSearch(x.name ?? ""))
        .map((group) => ({
          ...group,
          depth: 0,
        }));
    }

    return flatFolders;
  }
);

export const selectGroupFolders = createSelector(
  [selectFoldersAndGroups],
  (foldersAndGroups) => foldersAndGroups.filter((x) => x.isFolder)
);
