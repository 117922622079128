import {
  ddLog,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { SingleSelectType } from "components-ui/src/local-filters/SingleSelectFilter";
import { isSelected } from "components-ui/src/local-filters/multiCheckboxFilter/utils";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type MultiSegmentationSelectPanel,
  type PanelOption,
  type MultiCheckboxPanel,
  PanelType,
  type SingleSelectPanel,
  type SegmentationSelectPanel,
  type LocationHierarchySelectPanel,
  type MultiLocationHierarchySelectPanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import {
  type SidePanelSegmentationParameter,
  type SidePanelMultiTypeParameter,
  type SidePanelLocationHierarchyParameter,
} from "../../common/models/local-parameters/SidePanelParameters";
import { getSingleSelectLabelElement } from "../../common/utils/local-parameters/PanelBuilderUtils";

export const convertToString = (items: PanelOption[]): string[] =>
  items.map((item) => item.value.toString());
export const buildMultiTypeStringPanel = (
  localParameter: SidePanelMultiTypeParameter,
  isMulti: boolean,
  onSelection: (value: string[]) => void,
  selectedValues: string[]
) => {
  const handleMultiSelect = (option: PanelOption) => {
    const value = option.value as string;
    if (selectedValues.includes(value)) {
      const newValues = selectedValues.filter(
        (selections) => selections !== value
      );
      // prevent having all options deselected
      if (newValues.length > 0) {
        onSelection(newValues);
      }
    } else {
      const newValues = localParameter.selections
        .filter(
          (selection) =>
            selectedValues.includes(selection.value) ||
            selection.value === value
        )
        .map((selection) => selection.value);
      onSelection(newValues);
    }
  };

  if (isMulti) {
    const multiModePanel: MultiCheckboxPanel = {
      id: localParameter.id,
      label: localParameter.name,
      options: localParameter.selections.map((selection) => ({
        label: selection.label,
        labelElement: getSingleSelectLabelElement(localParameter.id, selection),
        value: selection.value,
      })),
      panelType: PanelType.MULTI_CHECKBOX,
      onSelection: handleMultiSelect,
      isDisabled: (value: string) =>
        selectedValues.length === 1 ? !selectedValues.includes(value) : true,
      selected: selectedValues,
      subtitle: "",
      summary: localParameter.selections
        .filter((selection) => isSelected(selectedValues, selection.value))
        .map((selection) => selection.label)
        .join(", "),
    };
    return multiModePanel;
  }

  const handleSingleSelect = (value: PanelOption) => {
    onSelection([value.value.toString()]);
  };

  const singleModePanel: SingleSelectPanel = {
    displayFieldType: SingleSelectType.Radio,
    id: localParameter.id,
    label: localParameter.name,
    onSelection: handleSingleSelect,
    panelType: PanelType.SINGLE,
    selectedValue: selectedValues[0],
    selections: localParameter.selections.map((selection) => ({
      label: selection.label,
      labelElement: getSingleSelectLabelElement(localParameter.id, selection),
      value: selection.value,
    })),
  };
  return singleModePanel;
};

export const buildSingleTypeSegmentationPanel = (
  localParameter: SidePanelSegmentationParameter,
  onSelection: (value: SegmentOption) => void,
  selectedValues: string[]
) => {
  const singleModePanel: SegmentationSelectPanel = {
    id: localParameter.id,
    label: localParameter.name,
    onSelection,
    panelType: PanelType.SEGMENTATION,
    selectedValues,
    segmentOptions: localParameter.selections.map((selection) => ({
      segmentationLabel: selection.label,
      segmentationValue: selection.value,
      segmentLabel: selection.segmentLabel,
      segmentValue: selection.segmentValue,
    })),
  };

  return singleModePanel;
};

export const buildMultiTypeSegmentationPanel = (
  localParameter: SidePanelSegmentationParameter,
  onSelection: (values: SegmentOption[]) => void,
  selectedValues: string[]
) => {
  const multiSelectSegmentOptions = localParameter.selections.map(
    (selection) => ({
      segmentationLabel: selection.label,
      segmentationValue: selection.value,
      segmentLabel: selection.segmentLabel,
      segmentValue: selection.segmentValue,
      isDefault: selection.isDefault,
    })
  );

  const multiModeSegmentationPanel: MultiSegmentationSelectPanel = {
    id: localParameter.id,
    label: localParameter.name,
    onSelection,
    panelType: PanelType.MULTI_SELECT_CUSTOMER_SEGMENTATION,
    selectedValues,
    segmentOptions: multiSelectSegmentOptions,
  };

  return multiModeSegmentationPanel;
};

export const buildMultiTypeLocationPanel = (
  localParameter: SidePanelLocationHierarchyParameter,
  isMulti: boolean,
  onSelection: (value: PanelOption[]) => void,
  selectedValues: string[]
) => {
  const getLocationNode = (nodeNumber: string) => {
    const node = localParameter.selections.find(
      (hsnode) => hsnode.nodeNumber === Number.parseInt(nodeNumber, 10)
    );

    if (node === undefined) {
      ddLog("Unable to find location node in local parameters", {}, "error");
      // eslint-disable-next-line unicorn/error-message -- Message omitted because of data-dog logging
      throw new TypeError();
    }

    return node;
  };

  const handleMultiSelectSubmit = (nodes: LocalHierarchyNodeSelection[]) => {
    // nodes.map(node => {node.name , node.nodeNumber})
    onSelection(
      nodes.map((node) => ({
        label: `(${hierarchyLevelDisplayLabel(node.shortName)}) ${node.name}`,
        value: node.nodeNumber,
      }))
    );
  };

  if (isMulti) {
    const multiModeLocationPanel: MultiLocationHierarchySelectPanel = {
      allSelections: localParameter.selections,
      id: localParameter.id,
      label: localParameter.name,
      onSubmit: handleMultiSelectSubmit,
      panelType: PanelType.MULTI_SELECT_LOCATION,
      selectedValues: selectedValues.map((selection) =>
        getLocationNode(selection)
      ),
    };

    return multiModeLocationPanel;
  }

  const handleSingleSelect = (node: LocalHierarchyNodeSelection) => {
    onSelection([
      {
        label: `(${hierarchyLevelDisplayLabel(node.shortName)}) ${node.name}`,
        value: node.nodeNumber,
      },
    ]);
  };

  const singlePanel: LocationHierarchySelectPanel = {
    allSelections: localParameter.selections,
    id: localParameter.id,
    label: localParameter.name,
    onSelection: handleSingleSelect,
    panelType: PanelType.LOCATION,
    selectedValue: getLocationNode(selectedValues[0]),
  };

  return singlePanel;
};
