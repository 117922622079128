import {
  getBaseQuery,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type GlobalParameterValues } from "../../fast-report/globalParameterValues";
import { type FocalItemDto } from "../focalItemDto";
import { REPORT_DATA_CACHE_LENGTH_SECONDS } from "../reportUtilities";

export type ProductsRangeReportRequest = GlobalParameterValues & {
  division: string;
  item: FocalItemDto;
};

export type ProductsRangeReportResponse = {
  hierarchyLevel: string;
  items: ProductsRangeReportItemResult[];
  transactionSource: TransactionSource;
};

export type ProductsRangeReportItemResult = {
  hierarchyName: string;
  isDeletedLine: boolean;
  launchWeek: Date;
  metricResults: { [key: string]: number };
  productCode: string;
  productName: string;
};

export const productsRangeReportApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    productsRangeReport: builder.query<
      ProductsRangeReportResponse,
      ProductsRangeReportRequest
    >({
      keepUnusedDataFor: REPORT_DATA_CACHE_LENGTH_SECONDS,
      providesTags: ["ProductsRangeReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/range-report/products`,
      }),
    }),
  }),
  reducerPath: "productsRangeReportApi",
  tagTypes: ["ProductsRangeReport"],
});

export const { useProductsRangeReportQuery } = productsRangeReportApi;
