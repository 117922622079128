import { type ParameterDto } from "@quantium-enterprise/common-ui";
import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { bandsUpdated } from "../../states/report-wizard-slice";
import { ParameterLabel } from "../parameter-label/ParameterLabel";
import { Band } from "./Band";
import styles from "./BandsParameter.module.css";
import { getBandsParameterState } from "./BandsParameterState";

export type BandsParameterProps = {
  headingText?: string;
  parameterDto: ParameterDto;
};

export const BandsParameter = ({
  headingText,
  parameterDto,
}: BandsParameterProps) => {
  const dispatch = useDispatch();

  const { bands } = useAppSelector(getBandsParameterState(parameterDto.id));
  const maxSelections = parameterDto.attributes.maxSelections;

  const addBand = useCallback(() => {
    const newBands = bands.map((band) => ({ ...band }));
    const newBand = {
      errors: [],
      id: uniqueId(),
      minimum: newBands[newBands.length - 1].maximum,
      maximum: Math.min(100, newBands[newBands.length - 1].maximum + 10),
    };

    newBands.push(newBand);

    dispatch(bandsUpdated({ parameterId: parameterDto.id, bands: newBands }));
  }, [dispatch, parameterDto.id, bands]);

  const removeBand = useCallback(
    (index: number) => {
      if (bands.length === 1) {
        return;
      }

      const newBands = bands.map((band) => ({ ...band }));
      newBands.splice(index, 1);

      dispatch(bandsUpdated({ parameterId: parameterDto.id, bands: newBands }));
    },
    [dispatch, parameterDto.id, bands]
  );

  const updateBandMaximum = useCallback(
    (index: number, input: number) => {
      // Prevent user from inputting numbers with more than one decimal place
      // and numbers greater than to 100
      const value = Math.floor(input * 10) / 10;
      if (value > 100) {
        return;
      }

      // Update the maximum of the current band
      // and minimum of the next band
      const newBands = bands.map((band) => ({ ...band }));
      newBands[index].maximum = value;
      if (index < newBands.length - 1) {
        newBands[index + 1].minimum = value;
      }

      dispatch(bandsUpdated({ parameterId: parameterDto.id, bands: newBands }));
    },
    [dispatch, parameterDto.id, bands]
  );

  const isAddButtonDisabled =
    bands.length >= maxSelections || bands[bands.length - 1].maximum === 100;

  return (
    <div className={styles.bandsParameter}>
      <ParameterLabel
        description={parameterDto.description}
        heading={headingText ?? parameterDto.name}
        htmlFor={parameterDto.name}
      />
      {bands.map((band, index) => (
        <Band
          errors={band.errors}
          id={band.id}
          index={index}
          key={band.id}
          maximum={band.maximum}
          minimum={band.minimum}
          // Allow any band to be removed once CO3-5857 is complete
          removeBand={index === bands.length - 1 ? removeBand : undefined}
          updateMaximum={updateBandMaximum}
        />
      ))}
      <Button
        className={styles.addButton}
        disabled={isAddButtonDisabled}
        onClick={addBand}
        variant={ButtonVariant.Stealth}
      >
        <Icon glyph={IconGlyph.AddAndPlusAddPlus} text="Add range" />
        <span>Add range</span>
      </Button>
    </div>
  );
};
