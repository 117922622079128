import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";

/**
 * Generates labels for focal items, adding parent prefixes to disambiguate duplicates.
 *
 * @param {HierarchySliceNodeDto[]} focalItems - Array of focal items to generate labels for.
 * @param {HierarchySliceNodeDto[]} focalItemParents - Array of potential parent items for the focal items.
 * @returns {Record<number, string | undefined>} An object mapping node numbers to their labels.
 * @description
 * This function performs two passes over the focal items:
 * 1. First pass counts duplicate labels.
 * 2. Second pass generates the final labels, adding parent prefixes for duplicates.
 */
export const getFocalItemLabels = (
  focalItems: HierarchySliceNodeDto[],
  focalItemParents: HierarchySliceNodeDto[]
) => {
  const duplicateLabelCount: Record<string, number> = {};
  for (const item of focalItems) {
    const key = item.name;
    if (duplicateLabelCount[key]) {
      duplicateLabelCount[key]++;
    } else {
      duplicateLabelCount[key] = 1;
    }
  }

  const labels: Record<number, string | undefined> = {};
  for (const item of focalItems) {
    const key = `${item.name}`;
    let parentPrefix = "";
    if (duplicateLabelCount[key] > 1) {
      const parent = focalItemParents.find(
        (node) => node.nodeNumber === item.parentNodeNumber
      );
      parentPrefix = parent ? `${parent.name} / ` : "";
    }

    labels[item.nodeNumber] = `${parentPrefix}${item.name}`;
  }

  return labels;
};

/**
 * Checks if the focal items contain duplicate names.
 *
 * @param {HierarchySliceNodeDto[]} focalItems - Array of focal items to check for duplicates.
 * @returns {boolean} `true` if the focal items contain duplicate names.
 */
export const focalItemsHasDuplicateNames = (
  focalItems: HierarchySliceNodeDto[]
) => {
  const names = focalItems.map((item) => item.name);
  return new Set(names).size !== names.length;
};

/**
 * Returns an array of parent node numbers to fetch based on the focal items.
 *
 * @param {HierarchySliceNodeDto[]} focalItems - Array of focal items to generate labels for.
 * @returns {number[]} An array of parent node numbers to fetch.
 * @description
 * If there are no duplicate names in the focal items, an empty array is returned.
 */
export const parentNodesToFetch = (focalItems: HierarchySliceNodeDto[]) => {
  const parents: number[] = [];

  if (!focalItemsHasDuplicateNames(focalItems)) {
    return parents;
  }

  const nameCount: Record<string, number> = {};
  for (const item of focalItems) {
    if (nameCount[item.name]) {
      nameCount[item.name]++;
    } else {
      nameCount[item.name] = 1;
    }
  }

  const duplicateItems = focalItems.filter((item) => nameCount[item.name] > 1);

  for (const item of duplicateItems) {
    if (item.parentNodeNumber !== undefined) {
      parents.push(item.parentNodeNumber);
    }
  }

  return parents;
};

/**
 * Generates dropdown options for focal items, adding parent prefixes to disambiguate duplicates.
 *
 * @param {HierarchySliceNodeDto[]} focalItems - Array of focal items to generate dropdown options for.
 * @param {HierarchySliceNodeDto[]} focalItemParents - Array of potential parent items for the focal items.
 * @returns {PanelOption[]} An array of dropdown options for the focal items.
 */
export const getFocalItemDropdownOptions = (
  focalItems: HierarchySliceNodeDto[],
  focalItemParents: HierarchySliceNodeDto[]
): PanelOption[] => {
  if (!focalItemsHasDuplicateNames(focalItems)) {
    return focalItems.map((item) => ({
      label: item.name,
      value: item.nodeNumber.toString(),
    }));
  }

  const focalItemLabels = getFocalItemLabels(focalItems, focalItemParents);
  const options = focalItems.map((item) => ({
    label: `(${hierarchyLevelDisplayLabel(item.shortName)}) ${
      focalItemLabels[item.nodeNumber]
    }`,
    value: item.nodeNumber.toString(),
  }));
  return options;
};
