import { type InteractionsMatrixRequestDto } from "../models/InteractionsMatrixRequestDto";
import { type InteractionsMatrixResponseDto } from "../models/InteractionsMatrixResponseDto";
import { customerCrossShopApiSlice } from "./customer-cross-shop-api-slice";

export const customerCrossShopInteractionsMatrixApiSlice =
  customerCrossShopApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getInteractionsMatrixData: builder.query<
        InteractionsMatrixResponseDto,
        { divisionName: string; payload: InteractionsMatrixRequestDto }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetInteractionsMatrixData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useLazyGetInteractionsMatrixDataQuery } =
  customerCrossShopInteractionsMatrixApiSlice;
