import {
  type TransactionSource,
  type HierarchyItem,
  getStructureLevelTransactionSources,
  getPreferredTransactionSource,
  type ParameterDto,
  ParameterId,
  HierarchyItemType,
  HierarchyType,
  LocationHierarchy,
  ProductHierarchy,
  isHierarchyAttributeParameterOptionDto,
  isOrderedSelectionDto,
  getAttributeLevelTransactionSources,
} from "@quantium-enterprise/common-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { type Item } from "components-ui/src/drag-and-drop/models/Item";
import { type Zone } from "components-ui/src/drag-and-drop/models/Zone";
import {
  sortDroppableZones,
  sortHierarchyItems,
} from "components-ui/src/drag-and-drop/utilities/utilities";
import { type TransactionSourceState } from "../../states/report-wizard-slice";

export const getStructureParameterId = (hierarchyType: string) => {
  switch (hierarchyType) {
    case HierarchyType.Product:
      return ParameterId.ProductStructure;
    case HierarchyType.Location:
      return ParameterId.LocationStructure;
    default:
      return undefined;
  }
};

export const productShortNameToName = (shortName: ProductHierarchy) => {
  const index = Object.values(ProductHierarchy).indexOf(shortName);
  return Object.keys(ProductHierarchy)[index];
};

const locationShortNameToName = (shortName: LocationHierarchy) => {
  const index = Object.values(LocationHierarchy).indexOf(shortName);
  return Object.keys(LocationHierarchy)[index];
};

const getHierarchyLevelName = (hierarchyType: string, shortName: string) => {
  switch (hierarchyType) {
    case HierarchyType.Product:
      return productShortNameToName(shortName as ProductHierarchy);
    case HierarchyType.Location:
      return locationShortNameToName(shortName as LocationHierarchy);
    default:
      return "";
  }
};

const getHierarchyLevelType = (shortName: string) => {
  switch (shortName) {
    case ProductHierarchy.Product:
    case LocationHierarchy.Store:
      return HierarchyItemType.Leaf;
    default:
      return HierarchyItemType.Hierarchy;
  }
};

const groupHierarchyItems = (
  items: HierarchyItem[],
  key: (index: HierarchyItem) => string
) =>
  // Reduce makes sense here, eslint docs recommend disabling the rule in the off case reduce is viable
  // https://github.com/sindresorhus/eslint-plugin-unicorn/blob/main/docs/rules/no-array-reduce.md
  //
  // Here we use reduce to mimic SQL's "GROUP BY" behaviour, we're provided the selected hierarchy items
  // as a flat list, we group them into seperate lists by a key provided. Though flexible, this function
  // is used only once, grouping selected items by ther "shortName" of their hierarchy level.
  //
  // We later use the grouped record to determine how many items of each hierarchy level are selected
  //
  // eslint-disable-next-line unicorn/no-array-reduce
  items.reduce<Record<string, HierarchyItem[]>>((groups, item) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- type definitions need to be updated here. It's possible that the `key` function returns `undefined`.
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {});

export const getHierarchicalStructureZones = (
  rows: HierarchyItem[],
  structureParameter: ParameterDto,
  isDataEntitlementsShown: boolean,
  transactionSources: TransactionSourceState[]
): Zone[] => {
  const groupedSelectedItems = groupHierarchyItems(
    rows,
    (index) => index.shortName
  );

  const hierarchyItems = Object.keys(groupedSelectedItems).map((shortName) => {
    let displayedTransactionSource: TransactionSource[] | null | undefined;

    if (isDataEntitlementsShown) {
      const levelTransactionSources = getStructureLevelTransactionSources(
        shortName,
        rows
      );

      displayedTransactionSource = getPreferredTransactionSource(
        transactionSources,
        levelTransactionSources
      );
    }

    return {
      count: groupedSelectedItems[shortName].length,
      dataSource: displayedTransactionSource?.[0],
      id: shortName,
      name: getHierarchyLevelName(structureParameter.hierarchyType, shortName),
      ordinal: groupedSelectedItems[shortName][0].depth,
      shortName,
      type: getHierarchyLevelType(shortName),
    };
  });

  return hierarchyItems.sort(sortHierarchyItems).map((item) => ({
    accepts: [item.type],
    id: item.id,
    item,
  }));
};

// This is to handle the structure parameters attributes zone selections for re-run reports
export const getRerunDroppableStructureZones = (
  structureParameter: ParameterDto,
  selectedZones: Zone[],
  leafShortName?: string,
  selectedRows?: HierarchyItem[],
  isDataEntitlementsShown?: boolean,
  transactionSources?: TransactionSourceState[],
  isRerun?: boolean
): Zone[] => {
  let structureZones: Zone[] = selectedZones;

  const prefilledSelections =
    structureParameter.selections?.flatMap((item) => {
      if (isOrderedSelectionDto(item)) return [item];
      return [];
    }) ?? [];

  if (prefilledSelections.length && isRerun) {
    let displayedTransactionSource: TransactionSource[] | null | undefined;

    if (isDataEntitlementsShown) {
      const levelTransactionSources = getAttributeLevelTransactionSources(
        structureParameter.hierarchyType as HierarchyType,
        leafShortName ?? "",
        selectedRows ?? []
      );

      displayedTransactionSource = getPreferredTransactionSource(
        transactionSources,
        levelTransactionSources
      );
    }

    const attributes = structureParameter.options.filter(
      (option) =>
        isHierarchyAttributeParameterOptionDto(option) &&
        prefilledSelections.some((item) => option.value === item.shortName) &&
        !option.isHierarchical
    );

    const items = attributes.map(
      (attribute, attributeIndex) =>
        ({
          dataSource: displayedTransactionSource,
          id: attribute.value,
          name: attribute.label,
          ordinal: attributeIndex,
          shortName: attribute.value,
          type: HierarchyItemType.Attribute,
          hierarchyType: structureParameter.hierarchyType as HierarchyType,
        } as Item)
    );

    structureZones.push(
      ...items.map((item) => ({
        accepts: [item.type],
        id: uniqueId(),
        item,
      }))
    );

    structureZones = sortDroppableZones(structureZones, prefilledSelections);
  }

  return structureZones;
};
