export const formatDayId: { [key: string]: string } = {
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
  SUN: "Sunday",
};

export const formatHourId = (hourId: number) => {
  const hourString = String(hourId <= 12 ? hourId : hourId - 12);
  return `${hourString} - ${hourString}:59${
    hourId >= 12 && hourId <= 23 ? "pm" : "am"
  }`;
};
