import {
  FeatureFlag,
  useGetUserQuery,
  useShareCustomerGroupMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
} from "@quantium-enterprise/qds-react";
import { DownloadIconForSidePanel } from "components-ui/src/assets/icons/DownloadIconForSidePanel";
import { type ButtonContent } from "components-ui/src/button-list/ButtonList";
import { ButtonList } from "components-ui/src/button-list/ButtonList";
import {
  cleanFilename,
  downloadFile,
  toCSV,
} from "components-ui/src/export/export-functions";
import { GreySpinner } from "components-ui/src/loader/GreySpinner";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGroupListPath } from "../../../utilities/route-path-formats";
import { toCSVData } from "../../../utilities/segment-library-csv-utils";
import { type SegmentRow } from "../../segment-library-table/SegmentLibraryTableCells";
import commonStyles from "./GroupInfoPanelHeader.module.css";
import styles from "./SegmentLibraryInfoPanelHeader.module.css";

export type SegmentLibraryInfoPanelHeaderProps = {
  focalGroup?: SegmentRow;
};

export const SegmentLibraryInfoPanelHeader = ({
  focalGroup,
}: SegmentLibraryInfoPanelHeaderProps) => {
  const groupName = focalGroup?.customerGroupName;
  const { groupType } = useParams();
  const flags = useFlags();
  const navigate = useNavigate();
  const { data: userDto, isLoading } = useGetUserQuery();
  const currentUser = useMemo(() => userDto, [userDto]);
  const currentUserIsLoading = useMemo(() => isLoading, [isLoading]);
  const division = useDivision();
  const divisionName = division.name;
  const [shareCustomerGroup, { isLoading: isShareLoading }] =
    useShareCustomerGroupMutation();

  // create copy of group by sharing with current user
  const handleCreateCopy = useCallback(async () => {
    if (currentUser && focalGroup) {
      const newGroups = await shareCustomerGroup({
        divisionName,
        payload: {
          entityType: "Group",
          userIds: [currentUser.salesForceId],
          sharingNotes: "",
          sourceId: focalGroup.customerGroupId,
        },
      }).unwrap();
      const copiedGroupId = newGroups[newGroups.length - 1];
      navigate(getGroupListPath(divisionName, groupType ?? "", copiedGroupId));
    }
  }, [
    currentUser,
    divisionName,
    focalGroup,
    groupType,
    navigate,
    shareCustomerGroup,
  ]);
  const handleDownloadSegmentDetails = useCallback(async () => {
    if (focalGroup) {
      const data = toCSVData([focalGroup]);
      const csvContent = toCSV(undefined, undefined, data);
      const encodedUri = encodeURI(csvContent);
      const filename = cleanFilename(
        `${focalGroup.customerGroupName}-${focalGroup.name}`
      );
      downloadFile(encodedUri, `${filename}.csv`);
    }
  }, [focalGroup]);
  const iconButtons = useMemo((): ButtonContent[][] => {
    const visibleButtons: ButtonContent[] = [];
    if (flags[FeatureFlag.SegmentBuilderDetailsDownloads]) {
      visibleButtons.push({
        // viewBox to center align the icon correctly within 32px stealth button.
        buttonIcon: <DownloadIconForSidePanel viewBox="-4 -4 26 26" />,
        handleClick: handleDownloadSegmentDetails,
        name: "download-segment-details",
        testId: "download-segment-details",
        text: "Download segment details",
        title: "Download segment details",
      });
    }

    return [visibleButtons];
  }, [handleDownloadSegmentDetails, flags]);

  return (
    <div className={commonStyles.infoPanelHeader}>
      <div className={commonStyles.infoPanelTitleContainer}>
        <h2 className={commonStyles.infoPanelTitle} title={groupName}>
          {groupName}
        </h2>
      </div>
      <div className={commonStyles.buttonPanel}>
        <div className={commonStyles.infoPanelButtons}>
          {!currentUserIsLoading &&
            focalGroup?.ownerId !== currentUser?.salesForceId && (
              <Button
                aria-label="Create a copy"
                className={styles.copyButton}
                data-testid="copy-segment"
                disabled={isShareLoading}
                height={ButtonHeight.XSmall}
                onClick={handleCreateCopy}
                variant={ButtonVariant.Primary}
              >
                {isShareLoading ? <GreySpinner /> : <div>Create a copy</div>}
              </Button>
            )}
          <ButtonList buttons={iconButtons} />
        </div>
      </div>
    </div>
  );
};
