import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  Icon,
  IconGlyph,
  Nav,
  NavButton,
  NavFontWeight,
  NavSize,
  NavVariant,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { uniqueKey } from "highcharts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { type RootState } from "../../../store";
import { type AggregateRankMetricWeight } from "../../models/aggregate-rank-common-models";
import { useLazyGetModalDataQuery } from "../../services/aggregate-rank-modal-api-slice";
import {
  onModalSave,
  selectLevelOfAnalysis,
} from "../../services/aggregate-rank-slice";
import AggregateRankAttributesContent from "./AggregateRankAttributesContent";
import AggregateRankMetricsContent from "./AggregateRankMetricsContent";
import styles from "./AggregateRankModal.module.css";
import AggregateRankWeightsContent from "./AggregateRankWeightsContent";

const NavButtonWrapper = ({
  disabled,
  tab,
  onClickHandler,
}: {
  disabled: boolean;
  onClickHandler: () => void;
  tab: {
    content: JSX.Element;
    label: string;
  };
}) => (
  <NavButton
    className={classNames(styles.modalTabButton, {
      [styles.disabled]: disabled,
    })}
    key={tab.label}
    onClick={onClickHandler}
  >
    {tab.label}
  </NavButton>
);

export const AggregateRankModal = () => {
  const triggerRef = useRef();
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();

  const [getModalData] = useLazyGetModalDataQuery();

  const fetchModalData = useCallback(
    async (divisionName: string, reportId: string) => {
      await getModalData({ divisionName, reportId });
    },
    [getModalData]
  );

  useEffect(() => {
    if (activeDivisionName && id) {
      void fetchModalData(activeDivisionName, id);
    }
  }, [activeDivisionName, id, fetchModalData]);

  const levelOfAnalysis = useSelector(selectLevelOfAnalysis);

  const {
    attributeMetaData,
    selectedAttributes,
    metricMetaData,
    metricWeights,
    rankedMetrics,
    unrankedMetrics,
  } = useSelector((state: RootState) => ({
    attributeMetaData: state.aggregateRank.attributeMetaData,
    selectedAttributes: state.aggregateRank.selectedAttributes,
    metricMetaData: state.aggregateRank.metricsMetaData,
    metricWeights: state.aggregateRank.weights,
    rankedMetrics: state.aggregateRank.rankedMetrics,
    unrankedMetrics: state.aggregateRank.unrankedMetrics,
  }));

  const modalDataLoaded: boolean = useMemo(
    () => attributeMetaData.length > 0,
    [attributeMetaData]
  );

  const [localRankedMetrics, setLocalRankedMetrics] = useState<string[]>([]);
  const [localUnrankedMetrics, setLocalUnrankedMetrics] = useState<string[]>(
    []
  );
  const [localMetricWeights, setLocalMetricWeights] = useState<
    AggregateRankMetricWeight[]
  >([]);
  const [localSelectedAttributes, setLocalSelectedAttributes] = useState<
    string[]
  >([]);

  useEffect(() => {
    setLocalSelectedAttributes(selectedAttributes);
    setLocalRankedMetrics(rankedMetrics);
    setLocalUnrankedMetrics(unrankedMetrics);
    setLocalMetricWeights(
      metricWeights.map((metric) => ({ ...metric, errorState: false }))
    );
  }, [
    selectedAttributes,
    metricWeights,
    rankedMetrics,
    unrankedMetrics,
    isModalShown,
  ]);

  useEffect(() => {
    setLocalMetricWeights(
      localRankedMetrics.map((metric) => {
        const matchingWeightObject = localMetricWeights.find(
          (x) => x.metricLabel === metric
        );
        return {
          metricLabel: metric,
          errorState: matchingWeightObject?.errorState ?? false,
          metricWeight: matchingWeightObject?.metricWeight ?? 1,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRankedMetrics]);

  // callbacks for child tabs to update parent modal component
  const metricsTabCallback = (
    newRankedMetrics: string[],
    newUnrankedMetrics: string[]
  ) => {
    setLocalRankedMetrics(newRankedMetrics);
    setLocalUnrankedMetrics(newUnrankedMetrics);
  };

  const weightsTabCallback = (metricName: string, newWeight: number) => {
    const newErrorState = newWeight < 1;
    setLocalMetricWeights((previousRows) =>
      previousRows.map((row) =>
        row.metricLabel === metricName
          ? {
              ...row,
              metricWeight: newWeight,
              errorState: newErrorState,
            }
          : row
      )
    );
  };

  const attributesTabCallback = (value: string) => {
    setLocalSelectedAttributes((previousAttributes) => {
      if (previousAttributes.includes(value))
        return previousAttributes.filter((attribute) => attribute !== value);
      else return [...previousAttributes, value];
    });
  };

  const modalTabs = [
    {
      content: (
        <AggregateRankMetricsContent
          metricsMetaData={metricMetaData}
          rankedMetrics={localRankedMetrics}
          unrankedMetrics={localUnrankedMetrics}
          updateSelectedMetrics={metricsTabCallback}
        />
      ),
      label: `Metrics (${
        localRankedMetrics.length + localUnrankedMetrics.length
      })`,
    },
    {
      content: (
        <AggregateRankWeightsContent
          metricWeights={localMetricWeights}
          updateWeights={weightsTabCallback}
        />
      ),
      label: "Weights",
    },
    {
      content: (
        <AggregateRankAttributesContent
          attributeMetaData={attributeMetaData}
          handleOnToggle={attributesTabCallback}
          selectedAttributes={localSelectedAttributes}
        />
      ),
      label: "Attributes",
    },
  ];

  const trigger = useMemo(
    () => (
      <Button
        disabled={!modalDataLoaded}
        height={ButtonHeight.XSmall}
        onClick={() => {
          setIsModalShown(true);
        }}
        ref={triggerRef}
        variant={ButtonVariant.Stealth}
      >
        <svg
          className={styles.triggerButtonIcon}
          fill={
            modalDataLoaded
              ? "var(--qbit-colour-brand-700)"
              : "var(--qbit-colour-shade-8)"
          }
          viewBox="0 -960 960 960"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M120-280v-400q0-33 23.5-56.5T200-760h559q33 0 56.5 23.5T839-680v400q0 33-23.5 56.5T759-200H200q-33 0-56.5-23.5T120-280Zm80 0h133v-400H200v400Zm213 0h133v-400H413v400Zm213 0h133v-400H626v400Z" />
        </svg>
        <span>Metrics, weights and attributes</span>
      </Button>
    ),
    [modalDataLoaded]
  );

  const handleOnModalClose = useCallback(() => {
    setIsModalShown(false);

    // Reset local state
    setSelectedTab(0);
    setLocalSelectedAttributes(selectedAttributes);
    setLocalRankedMetrics(rankedMetrics);
    setLocalUnrankedMetrics(unrankedMetrics);
    setLocalMetricWeights(metricWeights);
  }, [selectedAttributes, rankedMetrics, unrankedMetrics, metricWeights]);

  const onSubmit = useCallback(() => {
    dispatch(
      onModalSave({
        selectedAttributes: localSelectedAttributes,
        selectedRankedMetrics: localRankedMetrics,
        selectedUnrankedMetrics: localUnrankedMetrics,
        selectedWeights: localMetricWeights.map((x) => ({
          metricLabel: x.metricLabel,
          metricWeight: Math.max(1, x.metricWeight),
        })),
      })
    );
  }, [
    dispatch,
    localSelectedAttributes,
    localRankedMetrics,
    localUnrankedMetrics,
    localMetricWeights,
  ]);

  const headerContent = (
    <div>
      <h2 className={styles.modalHeader}>Metrics, weights and attributes</h2>
      <Nav
        activeIndex={selectedTab}
        fontWeight={NavFontWeight.Regular}
        size={NavSize.Small}
        variant={NavVariant.Tab}
      >
        {modalTabs.map((tab, index) =>
          tab.label === "Attributes" && levelOfAnalysis.value !== "PD" ? (
            <Tooltip
              key={uniqueKey()}
              placement={TooltipPlacement.BottomCentre}
              spaceInside={TooltipSpaceInside.Medium}
              trigger={
                <span style={{ cursor: "pointer" }}>
                  <NavButtonWrapper
                    disabled
                    onClickHandler={() => setSelectedTab(index)}
                    tab={tab}
                  />
                </span>
              }
              variant={TooltipVariant.ArrowDark}
            >
              <div className={styles.tabDisabledTooltip}>
                Available only when the level of analysis is Product
              </div>
            </Tooltip>
          ) : (
            <NavButtonWrapper
              disabled={false}
              key={uniqueKey()}
              onClickHandler={() => setSelectedTab(index)}
              tab={tab}
            />
          )
        )}
      </Nav>
      <Button
        className={styles.closeDialogButton}
        height={ButtonHeight.Large}
        onClick={() => {
          handleOnModalClose();
        }}
        variant={ButtonVariant.Stealth}
      >
        <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close selection" />
      </Button>
    </div>
  );

  const footerContent = useMemo(
    () => (
      <Button
        className={styles.saveChangesButton}
        height={ButtonHeight.XSmall}
        onClick={() => {
          onSubmit();
          handleOnModalClose();
        }}
        text="Save changes"
        variant={ButtonVariant.Primary}
      />
    ),
    [handleOnModalClose, onSubmit]
  );

  return (
    <>
      {/* React portal used to have modal appear over the entire page and grey out everything */}
      {createPortal(
        <Dialog
          className={styles.modal}
          footer={footerContent}
          header={headerContent}
          onClose={() => handleOnModalClose()}
          show={isModalShown}
          titleId="metrics-weights-attributes-modal"
          triggeredBy={triggerRef}
        >
          <div className={styles.modalContent}>
            {modalTabs[selectedTab].content}
          </div>
        </Dialog>,
        document.body
      )}
      {trigger}
    </>
  );
};

export default AggregateRankModal;
