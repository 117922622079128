import {
  useDebounce,
  useExternalClickListener,
} from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonVariant,
  Checkbox,
  FormBlock,
  FormBlockDesignVariant,
  FormBlockEditability,
  FormBlockType,
  Icon,
  IconGlyph,
  Input,
  Label,
  QSearchInput,
  Spinner,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./MultiselectDropdown.module.scss";

export type DropdownOption = {
  label: string;
  value: number | string;
};

export type MultiselectDropdownProps = {
  // let the component know there are more results externally, that need to be lazy loaded
  hasMoreResults?: boolean;
  // identity
  id?: number | string;
  // whether the dropdown is currently unavailable because it is loading items
  isLoading?: boolean;
  // the items in the options list
  items: DropdownOption[];
  // if there are more results externally, search externally rather than internally
  onLazySearch?: (searchstring: string) => void;
  // callback for when a user hits the bottom of the list of options. Use this callback to request more items from the server
  onLoadMore?: () => void;
  // callback for when an item is selected
  onSelected: (selectedValues: DropdownOption[]) => void;
  // the set selected values for the component
  values?: DropdownOption[];
};

const ESCAPE_KEYCODE = 27;
const ENTER_KEYCODE = 13;

const isNameLongerThanDropdownWidth = (
  document: Document,
  id: number | string,
  label: string
) => {
  const dropdownPadding = 13;
  const checkboxWidth = 16;
  const checkboxId = CSS.escape(`checkbox-options-input-${label}`);
  return (
    (document.querySelector(`[id="${checkboxId}"]`)?.getBoundingClientRect()
      .width ?? 0) +
      2 * dropdownPadding +
      checkboxWidth >
    (document
      .querySelector(`[id="${CSS.escape(id.toString())}"]`)
      ?.getBoundingClientRect().width ?? 0)
  );
};

export const MultiselectDropdown = ({
  hasMoreResults = false,
  values,
  id,
  isLoading = false,
  items,
  onLazySearch,
  onLoadMore,
  onSelected,
}: MultiselectDropdownProps) => {
  // whether the dropdown is open or not
  const [isOpen, setIsOpen] = useState(false);
  // all of the items, selected and unselected
  const [allItems, setAllItems] = useState<DropdownOption[]>([...items]);

  // items that are in the selected list
  const selectedValues = useMemo(() => values ?? [], [values]);

  // for lazy loaded items, whether to show a loading spinner
  const [isMaxScrollLoading, setIsMaxScrollLoading] = useState(false);
  // watch when load more called
  const [isOnLoadMoreCalled, setIsOnLoadMoreCalled] = useState(false);
  // select all checkbox toggle
  const [selectAll, setSelectAll] = useState(true);
  const [displayLongName, setDisplayLongName] = useState(true);
  // search text
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500) as string;
  const searchId = useId();
  const scrollSectionRef = useRef(null);
  const scrollTriggerRef = useRef(null);
  const componentRef = useRef<HTMLDivElement>(null);

  // set initial items
  useEffect(() => {
    setAllItems([...items]);
    setIsOnLoadMoreCalled(false);
  }, [items]);

  useEffect(() => {
    setIsMaxScrollLoading(false);
  }, [allItems, selectedValues]);

  const placeholderText = useMemo(
    () => (isLoading ? "Loading..." : "Select option"),
    [isLoading]
  );

  // items that are not in the selected list
  const unselectedItems = useMemo(() => {
    const selectedValueIds = selectedValues.map((y) => y.value);
    return allItems.filter((x) => !selectedValueIds.includes(x.value));
  }, [allItems, selectedValues]);

  // unselected items that also match the search query
  const filteredItems = useMemo(
    () =>
      [...unselectedItems]
        .filter((x) =>
          x.label.toLowerCase().includes(debouncedSearch.toLowerCase())
        )
        .sort((a, b) => (b.label > a.label ? -1 : a.label < b.label ? 1 : 0)),
    [debouncedSearch, unselectedItems]
  );

  // fire search event when debounce finishes
  useEffect(() => {
    if (onLazySearch) {
      onLazySearch(debouncedSearch);
    }
  }, [debouncedSearch, search, onLazySearch]);

  // whether to show the search box
  const showSearchBox = useMemo(
    () => Boolean(allItems.length) || search,
    [allItems, search]
  );

  // whether to show the select all checkbox
  const showSelectAll = useMemo(
    () => filteredItems.length >= 0 && filteredItems.length <= 500,
    [filteredItems.length]
  );

  // whether every item in the list is selected
  const isAllItemsSelected = useMemo(
    () => selectedValues.length > 0 && unselectedItems.length === 0,
    [selectedValues, unselectedItems]
  );

  const isSearching = useMemo(() => Boolean(search), [search]);

  // the text to display in the closed dropdown
  const textboxValue = useMemo(() => {
    if (selectedValues.length === 1) {
      return selectedValues[0].label;
    }

    if (selectedValues.length > 1) {
      return `${selectedValues.length} items selected`;
    }

    return "";
  }, [selectedValues]);

  const toggleDropdown = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (
      isOpen &&
      (event.key === "Escape" || event.keyCode === ESCAPE_KEYCODE)
    ) {
      toggleDropdown();
    }

    if (!isOpen && (event.key === "Enter" || event.keyCode === ENTER_KEYCODE)) {
      toggleDropdown();
    }
  };

  // handle click outside the component to close
  const handleClickOutside = (target: Element) => {
    if (!componentRef.current?.parentElement?.contains(target)) {
      setIsOpen(false);
    }
  };

  useExternalClickListener(componentRef, document.body, handleClickOutside);

  const addItem = useCallback(
    (item: DropdownOption) => {
      // add to selected items
      const newSelectedValues = [...selectedValues, item];

      onSelected(newSelectedValues);
    },
    [onSelected, selectedValues]
  );

  const removeItem = useCallback(
    (item: DropdownOption) => {
      const newSelectedValues = selectedValues.filter(
        (x) => x.value !== item.value
      );

      onSelected(newSelectedValues);
    },
    [onSelected, selectedValues]
  );

  const toggleAllItems = useCallback(() => {
    setSelectAll(!selectAll);

    let newSelectedValues: DropdownOption[] = [];
    if (selectAll) {
      const filteredItemsValues = filteredItems.map((y) => y.value);
      // remove all the unselected items from the unselected items list that match the filter
      const itemsSelected = unselectedItems.filter((x) =>
        filteredItemsValues.includes(x.value)
      );

      // move all the items in the options list to the selected list
      newSelectedValues = selectedValues.concat(itemsSelected);
    }

    onSelected(newSelectedValues);
  }, [filteredItems, onSelected, selectAll, selectedValues, unselectedItems]);

  const onSearch = useCallback((event: Event) => {
    if (event.target) {
      setSearch((event.target as HTMLInputElement).value);
    }
  }, []);

  const onCancelSearch = () => {
    setSearch("");
  };

  // only observe the scroll trigger if there are more items on the server, we are not loading items, if we are not searching
  const observeEnabled = useMemo(
    () => !search && hasMoreResults && !isMaxScrollLoading,
    [hasMoreResults, isMaxScrollLoading, search]
  );

  // Lazy loading handling
  const onHitBottom = useCallback(() => {
    if (observeEnabled && onLoadMore) {
      setIsMaxScrollLoading(true);
      // prevent onLoadMore being called constantly if the trigger is in view
      if (!isOnLoadMoreCalled) {
        onLoadMore();
        setIsOnLoadMoreCalled(true);
      }
    }
  }, [isOnLoadMoreCalled, observeEnabled, onLoadMore]);

  const root = scrollSectionRef.current;
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          // callback code
          if (
            Math.floor(entries[0].intersectionRatio) === 1 &&
            entries[0].isIntersecting
          ) {
            onHitBottom();
          }
        },
        { root, threshold: [1] }
      ),
    [onHitBottom, root]
  );

  // observe the lazy loading trigger
  const addObserver = useCallback(() => {
    const trigger = scrollTriggerRef.current;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- could be null when disabling lazy loading
    if (trigger) {
      observer.observe(trigger);
    }
  }, [observer]);

  useEffect(() => {
    if (onLoadMore) {
      // observe when a trigger is visible
      addObserver();
    }
  }, [addObserver, onLoadMore]);

  const showLongName = (label: string) => {
    if (isNameLongerThanDropdownWidth(document, id ?? "", label))
      setDisplayLongName(true);
    else setDisplayLongName(false);
  };

  const hideLongName = () => {
    setDisplayLongName(false);
  };

  return (
    <div
      className={styles.multiselectdropdown}
      id={id?.toString()}
      ref={componentRef}
    >
      <div
        className={styles.inputcontainer}
        onClick={toggleDropdown}
        onKeyUp={handleKeyPress}
        role="button"
        tabIndex={0}
      >
        <div className={styles.qFormInput}>
          <input
            className={classNames([styles.qFormInputElText, styles.input])}
            placeholder={placeholderText}
            readOnly
            type="text"
            value={textboxValue}
          />
        </div>
        <Icon
          className={styles.caret}
          glyph={IconGlyph.ArrowsChevronDown}
          text="Select items"
        />
      </div>
      <div
        className={classNames(styles.opencontent, {
          [styles.open]: isOpen,
          [styles.closed]: !isOpen,
        })}
        data-testid="opencontent"
      >
        {showSearchBox && (
          <div className={styles.search}>
            <FormBlock
              blockType={FormBlockType.Search}
              className={styles.searchformblock}
              designvariant={FormBlockDesignVariant.Default}
            >
              <Label
                className={styles.searchlabel}
                htmlFor={searchId}
                text="Search"
              />
              <Input>
                <QSearchInput
                  id={searchId}
                  onChange={onSearch}
                  placeholder="Type to search"
                  value={search}
                />
              </Input>
              <>
                {isSearching && (
                  <Button
                    className={styles.searchcancel}
                    onClick={onCancelSearch}
                    variant={ButtonVariant.Link}
                  >
                    <Icon
                      glyph={IconGlyph.DeleteAndCloseClose}
                      text="Clear search"
                    />
                  </Button>
                )}
              </>
            </FormBlock>
          </div>
        )}
        <div className={styles.scrollsection} ref={scrollSectionRef}>
          <div className={styles.selectedcontainer}>
            <div className={styles.selectedtitle}>Selected</div>
            <div className={styles.selectedoptions}>
              <ul>
                {selectedValues.map((x) => (
                  <li key={x.value}>
                    <span className={styles.selectedlabel}>{x.label}</span>
                    <Button
                      onClick={() => {
                        removeItem(x);
                      }}
                      role="button"
                      variant={ButtonVariant.Link}
                    >
                      <Icon
                        glyph={IconGlyph.DeleteAndCloseClose}
                        text="Remove"
                      />
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.optionscontainer}>
            <div className={styles.optionstitle}>Options</div>
            <div className={styles.options}>
              <FormBlock blockType={FormBlockType.Checkbox}>
                <span
                  title={
                    showSelectAll
                      ? "Select all will only select up to the top 500 items currently present."
                      : "Select all is disabled when more than 500 items present. Refine search to reenable this option."
                  }
                >
                  <Input className={styles.checkbox}>
                    <Checkbox
                      checked={isAllItemsSelected}
                      editability={
                        showSelectAll
                          ? FormBlockEditability.Editable
                          : FormBlockEditability.Disabled
                      }
                      label="All"
                      name="selectall"
                      onChange={() => {
                        toggleAllItems();
                      }}
                    />
                  </Input>
                </span>
              </FormBlock>
              {filteredItems.map((x) => (
                <FormBlock blockType={FormBlockType.Checkbox} key={x.value}>
                  <Input className={styles.checkbox}>
                    <Checkbox
                      checked={false}
                      className={styles.label}
                      label={
                        <span
                          id={`checkbox-options-input-${x.label}`}
                          onMouseEnter={() => showLongName(x.label)}
                          onMouseLeave={hideLongName}
                          title={displayLongName ? x.label : ""}
                        >
                          {x.label}
                        </span>
                      }
                      name={x.label}
                      onChange={() => {
                        addItem(x);
                      }}
                    />
                  </Input>
                </FormBlock>
              ))}
              {filteredItems.length === 0 && !isLoading && (
                <div className={styles.nooptions}>No options available.</div>
              )}
            </div>
            {observeEnabled && (
              <div
                className={styles.scrolltrigger}
                data-testid="scrolltrigger"
                ref={scrollTriggerRef}
              />
            )}
            {(isMaxScrollLoading || isLoading) && (
              <div className={styles.loading}>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
