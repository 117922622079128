import {
  Checkbox,
  FormBlockEditability,
  Tooltip,
  TooltipSpaceInside,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import classNames from "classnames";
import styles from "./ConditionalTooltipCheckbox.module.css";

export const TooltipCheckboxTestId = "tooltip-checkbox";

export type ConditionalTooltipCheckboxProps = {
  className?: string;
  hideTooltip?: boolean;
  indeterminate?: boolean;
  isChecked: boolean;
  isDisabled?: boolean;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tooltipText: string;
};

export const ConditionalTooltipCheckbox = ({
  className,
  label,
  indeterminate,
  isDisabled,
  isChecked,
  onChange,
  tooltipText,
  hideTooltip,
}: ConditionalTooltipCheckboxProps) => {
  const uniqueIdName = `checkbox-tooltip-${uniqueId()}`;

  const checkbox = hideTooltip ? (
    <Checkbox
      assistiveLabel
      checked={isChecked}
      className={classNames(styles.checkbox, className)}
      data-testid={TooltipCheckboxTestId}
      editability={
        isDisabled
          ? FormBlockEditability.Disabled
          : FormBlockEditability.Editable
      }
      indeterminate={indeterminate}
      label={label}
      name={uniqueIdName}
      onChange={(event) => {
        if (!isDisabled && onChange) {
          onChange(event);
        }
      }}
    />
  ) : (
    <Tooltip
      spaceInside={TooltipSpaceInside.Medium}
      trigger={
        <span>
          <Checkbox
            assistiveLabel
            checked={isChecked}
            className={classNames(styles.checkbox, className)}
            data-testid={TooltipCheckboxTestId}
            editability={
              isDisabled
                ? FormBlockEditability.Disabled
                : FormBlockEditability.Editable
            }
            id={uniqueIdName}
            label={label}
            name={uniqueIdName}
            onChange={(event) => {
              if (!isDisabled && onChange) {
                onChange(event);
              }
            }}
          />
        </span>
      }
      variant={TooltipVariant.ArrowDark}
    >
      <div className={styles.tooltip}>{tooltipText}</div>
    </Tooltip>
  );

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- not actually interactive, just disabling further propagation
  return <div onClick={(event) => event.stopPropagation()}>{checkbox}</div>;
};
