import { userApi } from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { rangePerformanceApi } from "./services/range-performance-api-slice";
import rangePerformanceReducer from "./services/range-performance-slice";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [rangePerformanceApi.reducerPath]: rangePerformanceApi.reducer,
  rangePerformance: rangePerformanceReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "rangePerformance" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        userApi.middleware,
        rangePerformanceApi.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
