import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { InteractionsVennMetricColumnHeaderKey as ColumnHeaderKey } from "../../models/InteractionsVennMetricColumnHeaderKey";
import styles from "./ItemCombinationColumnHeader.module.css";

export type ItemCombinationColumnHeaderProps = {
  colourMap: Record<ColumnHeaderKey, string[]>;
  displayName: string;
  key: string;
};

export const ItemCombinationColumnHeader = ({
  key,
  displayName,
  colourMap,
}: ItemCombinationColumnHeaderProps) => {
  switch (key) {
    case ColumnHeaderKey.A:
    case ColumnHeaderKey.B:
    case ColumnHeaderKey.C:
    case ColumnHeaderKey.AB:
    case ColumnHeaderKey.AC:
    case ColumnHeaderKey.BC: {
      return (
        <div className={styles.itemCombinationColumnHeader}>
          <div className={styles.colourKeyWrapper}>
            {colourMap[key].map((colour) => (
              <span
                className={styles.colourKey}
                key={uniqueId()}
                style={{ backgroundColor: colour }}
              >
                {displayName}
              </span>
            ))}
          </div>

          <span>{displayName}</span>
        </div>
      );
    }

    case ColumnHeaderKey.ABC: {
      const colourList = colourMap[key];
      const firstRowColour = colourList[1];
      const secondRowColours = [colourList[0], colourList[2]];

      return (
        <div className={styles.itemCombinationColumnHeader}>
          <div className={styles.colourKeyWrapper}>
            <div className={styles.colourKeyRow}>
              <span
                className={styles.colourKey}
                style={{ backgroundColor: firstRowColour }}
              >
                {displayName}
              </span>
            </div>
            <div className={styles.colourKeyRow}>
              {secondRowColours.map((colour) => (
                <span
                  className={styles.colourKey}
                  key={uniqueId()}
                  style={{ backgroundColor: colour }}
                >
                  {displayName}
                </span>
              ))}
            </div>
          </div>

          <span>{displayName}</span>
        </div>
      );
    }

    default:
      return (
        <span className={styles.itemCombinationColumnHeader}>
          {displayName}
        </span>
      );
  }
};
