import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useEffect } from "react";
import { dispatchLoadTabsFromSessionStorage } from "../services/actions";
import { ReportTabsHeaderContainer } from "./ReportTabsHeaderContainer";

export const ReportTabsHeaderContainerWrapper = () => {
  const { name: divisionName } = useDivision();

  useEffect(() => {
    if (divisionName) {
      dispatchLoadTabsFromSessionStorage(divisionName);
    }
  }, [divisionName]);

  return divisionName ? <ReportTabsHeaderContainer /> : null;
};
