/* eslint-disable typescript-sort-keys/string-enum */
export enum TrackingComponent {
  CustomerGroup = "CustomerGroup",
  Dashboard = "Dashboard",
  FocalItemTableRow = "FocalItemTableRow",
  GroupCreator = "GroupCreator",
  Groups = "Groups",
  HierarchyBreadcrumb = "Hierarchy Breadcrumb",
  Item = "Item",
  LocalParameterPanel = "LocalParameterPanel",
  LocationGroup = "LocationGroup",
  MyReports = "MyReports",
  Parameter = "Parameter",
  ParameterGroup = "ParameterGroup",
  ProductGroup = "ProductGroup",
  RangePerformance = "UCM.RangePerformance",
  Report = "Report",
  ReportCreator = "ReportCreator",
  ReportTab = "ReportTab",
  SavedSelections = "SavedSelections",
  Watchlist = "Watchlist",
  WatchlistAddItemsModal = "Watchlist AddItemsModal",
  WatchlistItem = "Watchlist Item",
  WatchlistRow = "Watchlist Row",
  Wizard = "Wizard",
  FastReportingTab = "COQI.ReportPage",
  FastReportingGlobal = "COQI.Global",
  FastReportingKDTReport = "COQI.KDT",
  FastReportingPerformanceReport = "COQI.Performance",
  FastReportingTopAndBottomReport = "COQI.TAndB",
  FastReportingCustomerProfilingReport = "COQI.CustomerProfiling",
}
