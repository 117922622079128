import { type TrialAndRepeatDistributionPricingRequestDto } from "../models/TrialAndRepeatDistributionPricingRequestDto";
import { type TrialAndRepeatDistributionPricingResponseDto } from "../models/TrialAndRepeatDistributionPricingResponseDto";
import { trialAndRepeatApiSlice } from "./trial-and-repeat-api-slice";

export const trialAndRepeatDistributionPricingApiSlice =
  trialAndRepeatApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getDistributionData: builder.query<
        TrialAndRepeatDistributionPricingResponseDto,
        {
          division: string;
          payload: TrialAndRepeatDistributionPricingRequestDto;
          reportId: string | undefined;
        }
      >({
        query: ({ division, reportId, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `getDistributionData/${division}/${reportId ?? ""}`,
        }),
      }),
    }),
  });

export const { useLazyGetDistributionDataQuery } =
  trialAndRepeatDistributionPricingApiSlice;
