import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";

export const getParameterSummaryValueStrings = (
  channel: PanelOption,
  location: LocalHierarchyNodeSelection,
  metric: PanelOption,
  segmentation: PanelOption[],
  promotion: PanelOption,
  time: string
) => [
  {
    name: "Channel",
    value: channel.label,
  },
  {
    name: "Location",
    value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
      location.name
    }`,
  },
  {
    name: "Metrics",
    value: metric.label,
  },
  {
    name: "Segmentation",
    value: segmentation.map((option) => option.label.toString()).join(","),
  },
  {
    name: "Promotion",
    value: promotion.label,
  },
  {
    name: "Time",
    value: time,
  },
];
