import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import { type IndexAgainstOption, type Option } from "./IndexedMetricFilter";

export const convertToSelectOption = (
  indexAgainstOptions: IndexAgainstOption[]
): PanelOption[] =>
  indexAgainstOptions.map((option) => ({
    label: option.hierarchyLabel,
    value: option.shortName,
  }));

export const convertToPanelOption = (
  indexAgainstOption: IndexAgainstOption
): PanelOption => {
  const panelOption = {
    label: indexAgainstOption.hierarchyLabel,
    value: indexAgainstOption.shortName,
  };
  return panelOption;
};

export const getIndexedAgainstDisplayName = (
  indexAgainstOptions: IndexAgainstOption[],
  value: string
): string =>
  indexAgainstOptions.find((option) => option.shortName === value)
    ?.hierarchyLabel ?? value;

export const getIndexedAgainstMetricValue = (
  indexAgainstOptions: IndexAgainstOption[],
  metricLabel: string,
  shortName: string
): PanelOption | undefined => {
  const selection = indexAgainstOptions.find(
    (option) => option.shortName === shortName && option.label === metricLabel
  );
  if (selection)
    return {
      label: selection.label,
      value: selection.value as string,
    };
  return {} as PanelOption;
};

export const getMetricDisplayName = (
  value: string,
  options: Option[]
): string => options.find((option) => option.value === value)?.label ?? value;

export const isIndexedAgainstMetric = (
  indexAgainstOptions: IndexAgainstOption[],
  selectedMetric: string
): boolean =>
  indexAgainstOptions.some((option) => option.value === selectedMetric);
