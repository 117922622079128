import classNames from "classnames";
import { type PropsWithChildren } from "react";
import styles from "./EllipsisLabel.module.css";

export type EllipsisLabelProps = {
  className?: string;
  innerContentClassName?: string;
};

export const EllipsisLabel = ({
  className,
  children,
  innerContentClassName,
}: PropsWithChildren<EllipsisLabelProps>) => (
  <div className={classNames(styles.ellipseContent, className)}>
    <span
      className={classNames(styles.ellipseInnerContent, innerContentClassName)}
    >
      {children}
    </span>
  </div>
);
