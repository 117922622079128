import { closeDropdown } from "@quantium-enterprise/common-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconGlyph,
  Menu,
  MenuItemButton,
  MenuModule,
  MenuModuleWidth,
  MenuSection,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import classNames from "classnames";
import { useRef } from "react";
import { type ButtonContent } from "../button-list/ButtonList";
import { ButtonIcon } from "../button-list/ButtonList";
import styles from "./ButtonDropdown.module.css";

type ButtonDropdownSectionProps = {
  // buttons is a list of lists that is separated into sections
  buttons: ButtonContent[][];
  dropdownRef: React.RefObject<Dropdown>;
};

const ButtonDropdownSections = ({
  buttons,
  dropdownRef,
}: ButtonDropdownSectionProps) => (
  <Menu className={styles.buttonMenu}>
    {buttons.map((buttonSection) => (
      <MenuSection key={uniqueId()} minWidthStart="2em">
        {buttonSection.map((button) => (
          <MenuItemButton
            contentStart={<ButtonIcon button={button} />}
            key={button.name}
            onClick={() => {
              button.handleClick?.();
              closeDropdown(dropdownRef);
            }}
            text={
              <span
                className={classNames(styles.buttonText)}
                style={{
                  color:
                    button.buttonTextColour ?? tokens.colour["text-primary"],
                }}
              >
                {button.text}
              </span>
            }
          />
        ))}
      </MenuSection>
    ))}
  </Menu>
);

export type ButtonDropdownProps = {
  buttons: ButtonContent[][];
  className?: string;
  dropdownPlacement?: DropdownPlacement;
};

export const ButtonDropdown = ({
  buttons,
  className,
  dropdownPlacement = DropdownPlacement.BottomRight,
}: ButtonDropdownProps) => {
  const dropdownRef = useRef<Dropdown>(null);
  return (
    <Dropdown
      className={classNames(styles.dropdownButton)}
      placement={dropdownPlacement}
      ref={dropdownRef}
      trigger={
        <Button
          aria-label="Open button dropdown"
          className={className}
          height={ButtonHeight.Small}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            colour={tokens.colour["text-primary"]}
            glyph={IconGlyph.MenuAndSettingsMoreHoriz}
            text="more options"
          />
        </Button>
      }
    >
      <div className={styles.verticalButtonList}>
        <MenuModule width={MenuModuleWidth.Fill}>
          <ButtonDropdownSections buttons={buttons} dropdownRef={dropdownRef} />
        </MenuModule>
      </div>
    </Dropdown>
  );
};
