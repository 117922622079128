import { type SharedUserDto } from "@quantium-enterprise/common-ui";
import {
  ddLog,
  FeatureFlag,
  useCreateSavedParametersMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  Spinner,
  SpinnerSize,
  Text,
} from "@quantium-enterprise/qds-react";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ReportIcon } from "../../assets/icons/ReportIcon";
import { type ButtonContent } from "../../button-list/ButtonList";
import { ButtonList } from "../../button-list/ButtonList";
import { EditableField } from "../../editable-field/EditableField";
import { ShareModal } from "../../modals";
import { RenameItem } from "../../rename-item/RenameItem";
import {
  RenameButton,
  DeleteButton,
  ShareButton,
  RerunButton,
  MoveButton,
  SaveParametersButton,
} from "./InfoPanelButtons";
import styles from "./InfoPanelHeader.module.css";

export enum InfoPanelType {
  MyReports = "My Reports",
  ReportViewer = "Report Viewer",
}

export const InfoPanelHeaderLayout = ({
  handleRename,
  id,
  title,
  buttonPanel,
  editableFieldState,
}: {
  buttonPanel?: JSX.Element;
  editableFieldState?: {
    isEditing: boolean;
    toggleEditing: (editing: boolean) => void;
  };
  handleRename: (value: string, id: string) => Promise<unknown>;
  id?: string;

  title?: string;
}) => (
  <div className={styles.infoPanelHeader}>
    <div className={styles.infoPanelTitleContainer}>
      {title ? (
        <EditableField
          editableFieldState={editableFieldState}
          resizeMode="vertical"
          rows={2}
          save={async (value: string) => {
            const data = await handleRename(value, id ?? "").then(
              () => true,
              () => false
            );
            return data;
          }}
          textStyle={styles.infoPanelTitle}
          value={title}
        />
      ) : (
        <h2 className={styles.infoPanelTitle} title={title}>
          {title}
        </h2>
      )}
    </div>
    {buttonPanel && <div className={styles.buttonPanel}>{buttonPanel} </div>}
  </div>
);

export type InfoPanelHeaderProps = {
  infoPanelType: InfoPanelType;
  isReportDisabled?: boolean;
  isRerunLoading?: boolean;
  onRerun?: () => void;
  organisationUsers?: SharedUserDto[];
  renameItem: (newItemName: string, itemId: string) => Promise<unknown>;
  reportId: string | undefined;
  reportName: string | undefined;
  reportPath?: string;
  setIsPollingEnabled: (newState: boolean) => void;
  setShowDeleteModal: (newState: boolean) => void;
  setShowMoveModal: (newState: boolean) => void;
  setShowShareModal: (newState: boolean) => void;
  trackButtonClick: () => void;
  triggerLazyReload?: () => Promise<void>;
};

export const InfoPanelHeader = ({
  infoPanelType,
  isReportDisabled,
  isRerunLoading,
  renameItem,
  reportName,
  organisationUsers,
  reportId,
  reportPath,
  trackButtonClick,
  triggerLazyReload,
  onRerun,
  setShowShareModal,
  setShowDeleteModal,
  setShowMoveModal,
  setIsPollingEnabled,
}: InfoPanelHeaderProps) => {
  const flags = useFlags();
  const division = useDivision();

  const [editingReportName, setEditingReportName] = useState(false);
  const editableFieldState = {
    isEditing: editingReportName,
    toggleEditing: setEditingReportName,
  };

  const [showShareDialog, setShowShareDialog] = useState(false);
  const handleShareDialogExit = useCallback(() => {
    setShowShareDialog(false);
  }, []);

  const { handleRename } = RenameItem({
    itemType: "Report",
    renameItem,
    triggerLazyReload,
  });

  const [createSavedParametersTrigger] = useCreateSavedParametersMutation();

  const handleCreateSavedParameters = useCallback(async () => {
    try {
      await createSavedParametersTrigger({
        divisionName: division.name,
        payload: { reportParametersId: reportId ?? "" },
      }).unwrap();
      const content = "Selections for 1 report saved";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          showIcon
          variant={MessageVariant.Success}
        />,
        {
          autoClose: 3_000,
        }
      );
    } catch (error) {
      const content = "An unknown error has occurred";
      const heading = "Unknown error";
      ddLog("ERROR", {}, "error", error as Error);
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: 3_000,
        }
      );
    }
  }, [createSavedParametersTrigger, division.name, reportId]);

  const buttonPanel = useMemo(() => {
    const isDisabled =
      infoPanelType === InfoPanelType.MyReports && reportPath === undefined;
    let availableButtons: ButtonContent[][];
    let visibleButtons: ButtonContent[] = [];

    // feature flag condition can be added here
    if (
      infoPanelType === InfoPanelType.MyReports &&
      !isReportDisabled &&
      !isRerunLoading &&
      onRerun &&
      flags[FeatureFlag.Rerun]
    ) {
      visibleButtons = [
        ...visibleButtons,
        {
          ...RerunButton,
          handleClick: onRerun,
        },
      ];
    }

    if (flags[FeatureFlag.SharingReports]) {
      visibleButtons = [
        ...visibleButtons,
        {
          ...ShareButton,
          disabled: isDisabled,
          handleClick: () => setShowShareModal(true),
        },
      ];
    }

    visibleButtons = [
      ...visibleButtons,
      {
        ...RenameButton,
        handleClick: () => {
          setIsPollingEnabled(false);
          setEditingReportName(true);
        },
      },
    ];

    let dropdownButtons: ButtonContent[] = [];
    if (reportPath) {
      dropdownButtons = [
        ...dropdownButtons,
        {
          ...SaveParametersButton,
          handleClick: async () => await handleCreateSavedParameters(),
        },
      ];
    }

    switch (infoPanelType) {
      case InfoPanelType.MyReports:
        availableButtons = [
          visibleButtons,
          [
            ...dropdownButtons,
            ...(flags[FeatureFlag.Folders]
              ? [{ ...MoveButton, handleClick: () => setShowMoveModal(true) }]
              : []),
          ],
          [{ ...DeleteButton, handleClick: () => setShowDeleteModal(true) }],
        ];

        return (
          <div className={styles.infoPanelButtons}>
            {reportPath ? (
              <Link to={reportPath}>
                <Button
                  height={ButtonHeight.XSmall}
                  onClick={() => trackButtonClick()}
                  text="View report"
                  variant={ButtonVariant.Primary}
                />
              </Link>
            ) : (
              <Button
                disabled
                height={ButtonHeight.XSmall}
                onClick={() => trackButtonClick()}
                text="View report"
                variant={ButtonVariant.Primary}
              />
            )}
            {isRerunLoading && (
              <div className={styles.loading}>
                <Spinner size={SpinnerSize.Medium} text="Loading" />
              </div>
            )}
            <ButtonList buttons={availableButtons} />
          </div>
        );
      case InfoPanelType.ReportViewer:
        availableButtons = [visibleButtons];

        return (
          <div className={styles.infoPanelButtons}>
            <ButtonList buttons={availableButtons} />
            <ShareModal.Reports
              onClose={handleShareDialogExit}
              organisationUsers={organisationUsers}
              reportId={reportId}
              reportName={reportName}
              showModal={showShareDialog}
            />
          </div>
        );
      default:
        return undefined;
    }
  }, [
    setShowShareModal,
    infoPanelType,
    reportPath,
    isReportDisabled,
    isRerunLoading,
    onRerun,
    flags,
    handleCreateSavedParameters,
    trackButtonClick,
    setIsPollingEnabled,
    setShowDeleteModal,
    setShowMoveModal,
    handleShareDialogExit,
    organisationUsers,
    reportId,
    reportName,
    showShareDialog,
  ]);

  return (
    <InfoPanelHeaderLayout
      buttonPanel={buttonPanel}
      editableFieldState={editableFieldState}
      handleRename={handleRename}
      id={reportId}
      title={reportName}
    />
  );
};

export type InfoPanelLabelProps = {
  reportType?: string;
};

export const InfoPanelLabel = ({ reportType }: InfoPanelLabelProps) => (
  <div className={styles.infoPanelLabel}>
    <ReportIcon reportFailed={false} />
    <Text>{reportType ?? ""}</Text>
  </div>
);
