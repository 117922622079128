import { ddLog } from "@quantium-enterprise/common-ui";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { repertoireApiSlice } from "./repertoire-api-slice";
import { onLocalParametersReceived } from "./repertoire-slice";

export const repertoireLocalParametersApiSlice =
  repertoireApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        SidePanelParameter[],
        { divisionName: string; reportId: string }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onLocalParametersReceived(data));
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetLocalParametersQuery } =
  repertoireLocalParametersApiSlice;
