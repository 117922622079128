import { isSameDay } from "date-fns";
import { type ClassNames, DayPicker } from "react-day-picker";
import styles from "react-day-picker/dist/style.module.css";
import customDatePickerStyles from "./DatePicker.module.css";
import modifierStyles from "./DatePickerModifiers.module.css";
import { CustomCaption } from "./date-picker-custom-caption/CustomCaption";

export type DatePickerProps = {
  disabled: (day: Date) => boolean;
  endDate: Date | undefined;
  focusDate: Date;
  fromDate: Date;
  onSelect: (day: Date | undefined) => void;
  selected: Date | undefined;
  startDate: Date | undefined;
  toDate: Date;
};

export const DatePicker = (properties: DatePickerProps) => {
  const { endDate, focusDate, onSelect, selected, startDate } = properties;

  const modifierBuilder = () => {
    let modifiers = {};

    if (startDate) {
      modifiers = { startDate };
    }

    if (endDate) {
      modifiers = { ...modifiers, endDate };
    }

    if (startDate && endDate) {
      modifiers = {
        ...modifiers,
        betweenDates: { after: startDate, before: endDate },
      };
    }

    return modifiers;
  };

  const handleOnDayClick = (day: Date) => {
    // Unselect functionality
    if (selected && isSameDay(selected, day)) {
      onSelect(undefined);
    } else {
      // Otherwise select the day
      onSelect(day);
    }
  };

  const classNames: ClassNames = {
    ...styles,
    button: customDatePickerStyles.customButton,
    day: customDatePickerStyles.customDay,
    root: customDatePickerStyles.customRoot,
  };

  return (
    <DayPicker
      {...properties}
      classNames={classNames}
      components={{
        Caption: CustomCaption,
      }}
      mode="single"
      modifiers={modifierBuilder()}
      modifiersClassNames={{
        betweenDates: modifierStyles.betweenDates,
        endDate: modifierStyles.endDate,
        startDate: modifierStyles.startDate,
      }}
      onDayClick={handleOnDayClick}
      required
      showOutsideDays
      today={focusDate}
      weekStartsOn={1}
    />
  );
};

export default DatePicker;
