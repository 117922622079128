import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type reportSummaryLeafRequest,
  type reportSummaryLeafResponse,
} from "./reportSummaryDtos";

export const reportSummaryApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    reportSummaryLeaf: builder.query<
      reportSummaryLeafResponse,
      reportSummaryLeafRequest & { division: string }
    >({
      providesTags: ["ReportSummaryLeaf"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/report-summary/leaf`,
      }),
    }),
  }),
  reducerPath: "reportSummaryApi",
  tagTypes: ["ReportSummaryLeaf"],
});

export const { useReportSummaryLeafQuery } = reportSummaryApi;
