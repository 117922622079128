import {
  formatMetricValue,
  sortByIndex,
} from "../../common/utils/export-parameter-summary-utils";
import {
  type PricingLaddersMetric,
  type PricingLaddersTableRow,
} from "../models/PricingLaddersDataTableResponseDto";
import { type Series } from "../models/pricing-ladders-common-models";
import { columnName } from "../utils/getExportFunctions";

export const tableCsvTransformation = (
  columnData: Series[],
  rowData: string[],
  sortValue: string,
  currency: string
) => {
  const columnHeaders = [
    "Focal Item(s)",
    ...columnData.map((name) =>
      columnName(name.name ?? "", name.format, currency)
    ),
  ];
  const sortIndex = columnHeaders.indexOf(sortValue + ` (${currency})`);
  const csvData = [columnHeaders];
  for (const [index, rowElement] of rowData.entries()) {
    const row = [
      rowElement,
      ...columnData.map((data) =>
        formatMetricValue(data.data[index].metricValue).toString()
      ),
    ];
    csvData.push(row);
  }

  return sortByIndex(csvData, sortIndex, false);
};

export const chartCsvTransformation = (
  columnData: Series[],
  rowData: string[],
  sortValue: string,
  viewBySeries: string[],
  secondaryValue: string[],
  currency: string
) => {
  const columnHeaders = [
    "Focal item",
    ...columnData
      .filter(
        (series) =>
          viewBySeries.includes(series.metricLabel) ||
          secondaryValue.includes(series.metricLabel)
      )
      .map((data) => columnName(data.name ?? "", data.format, currency)),
  ];
  const sortIndex = columnHeaders.indexOf(sortValue + ` (${currency})`);

  const csvData = [columnHeaders];
  for (const [index, rowElement] of rowData.entries()) {
    const row = [
      rowElement,
      ...columnData
        .filter(
          (series) =>
            viewBySeries.includes(series.metricLabel) ||
            secondaryValue.includes(series.metricLabel)
        )
        .map((rowValue) =>
          formatMetricValue(rowValue.data[index].metricValue).toString()
        ),
    ];
    csvData.push(row);
  }

  return sortByIndex(csvData, sortIndex, false);
};

export const focalDataCSVTransfromation = (
  metrics: PricingLaddersMetric[],
  rowData: PricingLaddersTableRow[]
) => {
  const columnHeaders = [
    "Focal Item(s)",
    ...metrics.map((columnValue) => columnValue.displayName),
  ];
  const csvData = [columnHeaders];
  for (const rowElement of rowData) {
    const row = [
      rowElement.hierarchyItem.name,
      ...rowElement.metricValues.map((value) =>
        formatMetricValue(value).toString()
      ),
    ];
    csvData.push(row);
  }

  return csvData;
};
