import {
  SidePanel,
  type Panel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { LocalParameterDisplayTypes } from "../../common/models/local-parameters/LocalParameterDisplayTypes";
import { type SidePanelSliderParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isHierarchySelectionParameter,
  isLocationSelectionParameter,
  isMinMaxRangeParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildFixedLocationHierarchyPanelWithPrefix,
  buildSliderPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { LoadingState } from "../models/basket-limits-parameter-selection-models";
import {
  onBasketLimitsChange,
  selectBasketLimit,
  selectBasketLimitRangeLoadingState,
  selectBasketLimitsRange,
  selectLocalParameters,
} from "../services/basket-limits-slice";
import { getEditability } from "../utils/sidePanelUtil";

export const BasketLimitsSidePanel = () => {
  const dispatch = useDispatch();

  const localParametersConfig = useSelector(selectLocalParameters);

  const basketLimit = useSelector(selectBasketLimit);
  const basketLimitRange = useSelector(selectBasketLimitsRange);
  const basketLimitRangeLoadingState = useSelector(
    selectBasketLimitRangeLoadingState
  );

  const basketLimitEditability = getEditability(basketLimitRange, basketLimit);

  const isBasketLimitSliderReady =
    basketLimitRangeLoadingState === LoadingState.Loaded;

  const generatePanels = useCallback(() => {
    const panels: Panel[] = [];

    for (const parameter of localParametersConfig) {
      switch (parameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(parameter)) {
            panels.push(buildTimePeriodPanel(parameter));
          }

          break;

        case LocalParameters.BasketLimitThreshold:
          if (isMinMaxRangeParameter(parameter)) {
            panels.push(
              buildSliderPanel(
                {
                  ...parameter,
                  minimumPossibleValue: basketLimitRange.minimum,
                  maximumPossibleValue: basketLimitRange.maximum,
                } as SidePanelSliderParameter,
                (value: number) => {
                  dispatch(onBasketLimitsChange(value));
                },
                basketLimitEditability,
                isBasketLimitSliderReady ? basketLimit : undefined
              )
            );
          }

          break;

        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(parameter)) {
            const locationHierarchy = localParametersConfig.find(
              (localParameter) =>
                localParameter.id === LocalParameters.LocationHierarchy
            ) ?? {
              displayType: LocalParameterDisplayTypes.Hierarchy,
              id: "",
              isDisabled: false,
              name: "",
              selections: [],
            };

            if (isHierarchySelectionParameter(locationHierarchy)) {
              panels.push(
                buildFixedLocationHierarchyPanelWithPrefix(locationHierarchy)
              );
            }
          }

          break;
        default:
          break;
      }
    }

    return panels;
  }, [
    localParametersConfig,
    basketLimitRange.minimum,
    basketLimitRange.maximum,
    basketLimitEditability,
    isBasketLimitSliderReady,
    basketLimit,
    dispatch,
  ]);

  return <SidePanel panels={generatePanels()} />;
};

export default BasketLimitsSidePanel;
