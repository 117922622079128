import { createContext, useContext } from "react";
import { type EventTrackingService } from "./EventTrackingService";

export const EventTrackingServiceContext = createContext<
  EventTrackingService | undefined
>(undefined);

export const useEventTrackingServiceContext = (): EventTrackingService => {
  const context = useContext(EventTrackingServiceContext);

  if (context === undefined) {
    throw new Error(
      "useEventTrackingServiceContext can only be used in a provider tree"
    );
  }

  return context;
};
