import { useDraggable } from "@dnd-kit/core";
import { type Item } from "../models/Item";
import { HierarchyItem } from "./HierarchyItem";

export type DraggableItemProps = {
  item: Item;
};

export const DraggableItem = ({ item }: DraggableItemProps) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    data: {
      ...item,
    },
    id: item.id,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      <HierarchyItem {...item} />
    </div>
  );
};
