import {
  HierarchyType,
  HierarchyItemType,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import {
  Button,
  ButtonVariant,
  Dialog,
  DialogWidth,
  DialogHeight,
  Icon,
  IconGlyph,
  FormInputHeight,
  TextInput,
  ButtonHeight,
} from "@quantium-enterprise/qds-react";
import { type Row } from "@tanstack/react-table";
import { useState, useRef, useMemo, useEffect } from "react";
import LocationSelectGrid from "../hierarchy-select-grid/LocationSelectGrid";
import { type HierarchyGridItem } from "../hierarchy-select-grid/models/hierarchy";
import { StructurePreview } from "../structure-preview/StructurePreview";
import { CollapsiblePanel } from "./CollapsiblePanel";
import styles from "./LocationHierarchyFilter.module.css";
import {
  buildNestedSelections,
  getFullHierarchyForLocation,
} from "./location-hierarchy-filter/common/utils";

export type LocationHierarchyFilterProps = {
  allSelections: LocalHierarchyNodeSelection[];
  isCollapsed: boolean;
  onSelection: (selection: LocalHierarchyNodeSelection) => void;
  selectedValue: LocalHierarchyNodeSelection;
  title: string;
  toggleCollapsed: () => void;
};

export const LocationHierarchyFilter = ({
  allSelections,
  isCollapsed,
  onSelection,
  selectedValue,
  title,
  toggleCollapsed,
}: LocationHierarchyFilterProps) => {
  const [isModalShown, setIsModalShown] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [expandedRows, setExpandedRows] = useState<
    LocalHierarchyNodeSelection[]
  >([]);

  const isDisabled = allSelections.length < 2;

  const modalRef = useRef();

  const nestedSelections = useMemo(
    () => buildNestedSelections(allSelections),
    [allSelections]
  );

  const gridData = {
    type: HierarchyType.Location,
    items: searchString
      ? allSelections.filter((sel) =>
          sel.name
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : nestedSelections,
  };

  const onRowClick = (
    event: React.MouseEvent<HTMLElement>,
    id: string,
    row: Row<HierarchyGridItem>
  ) => {
    if (!row.getIsSelected()) {
      row.getToggleSelectedHandler()(true);
      setIsModalShown(false);
      setSearchString("");
      setExpandedRows([]);
    }
  };

  const onExpandedBridge = (items: LocalHierarchyNodeSelection[]) => {
    setExpandedRows(items);
  };

  const onSelectionBridge = (items: LocalHierarchyNodeSelection[]) => {
    if (items.length > 1) {
      // This line alone would probably suffice, but feels like shady practice
      // All we want to do is override the selection to the latest selection
      onSelection(
        items.find((index) => index.nodeNumber !== selectedValue.nodeNumber) ??
          items[0]
      );
    } else if (items.length === 1) {
      onSelection(items[0]);
    }
  };

  const fullHierarchyForSelectedValue = useMemo(
    () => getFullHierarchyForLocation(selectedValue, allSelections),
    [allSelections, selectedValue]
  );

  const defaultExpanded = useMemo(
    () =>
      fullHierarchyForSelectedValue.length === 1
        ? [selectedValue]
        : fullHierarchyForSelectedValue.slice(1),
    [fullHierarchyForSelectedValue, selectedValue]
  );

  const rootParent =
    fullHierarchyForSelectedValue[fullHierarchyForSelectedValue.length - 1];
  const displayHierarchy =
    rootParent.isBenchmark &&
    rootParent.shortName === "DIV" &&
    rootParent.nodeNumber !== selectedValue.nodeNumber
      ? fullHierarchyForSelectedValue.slice(0, -1)
      : fullHierarchyForSelectedValue;

  useEffect(() => {
    if (expandedRows.length === 0) {
      setExpandedRows(defaultExpanded);
    }

    // The default should be applied only once, it makes no sense applying it
    // on every change of expandedRows. Hence we must not include expandedRows in the deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExpanded]);

  const grid = (
    <LocationSelectGrid
      data={gridData}
      expandedRows={expandedRows}
      hideRowCheckbox
      onExpandedChange={onExpandedBridge}
      onRowClick={onRowClick}
      onSelectionsChange={onSelectionBridge}
      selectedRows={[selectedValue]}
      title="Location"
    />
  );

  const selectedItemHierarchy = (
    <StructurePreview
      items={[...displayHierarchy]
        .map((item) => ({
          label: item.name,
          shortName: item.shortName,
          itemCode: item.code,
          type: HierarchyItemType.Hierarchy,
        }))
        .reverse()}
      maxDepth={displayHierarchy.length}
      moreItemsText=""
    />
  );

  const collapsedHierarchy = (
    <StructurePreview
      items={[
        {
          label: selectedValue.name,
          shortName: selectedValue.shortName,
          type: HierarchyItemType.Hierarchy,
        },
      ]}
      maxDepth={1}
      moreItemsText=""
    />
  );

  const trigger = useMemo(
    () => (
      <Button
        height={ButtonHeight.XSmall}
        onClick={() => {
          setIsModalShown(true);
        }}
        ref={modalRef}
        variant={ButtonVariant.Stealth}
      >
        <span>Change Location</span>
      </Button>
    ),
    []
  );

  const header = (
    <div className={styles.header}>
      <div>
        <TextInput
          height={FormInputHeight.XSmall}
          id="Search Location"
          onChange={(event) => setSearchString(event.target.value)}
          placeholder="Search Locations..."
        />
      </div>
      <Button
        onClick={() => {
          setIsModalShown(false);
        }}
        variant={ButtonVariant.Stealth}
      >
        <Icon
          glyph={IconGlyph.DeleteAndCloseClose}
          text="close location select"
        />
      </Button>
    </div>
  );

  const modal = (
    <Dialog
      className={styles.modal}
      header={header}
      height={DialogHeight.Large}
      onClose={() => {
        setIsModalShown(false);
      }}
      show={isModalShown}
      titleId="modal-select-title"
      triggeredBy={modalRef}
      width={DialogWidth.Large}
    >
      <>{grid}</>
    </Dialog>
  );

  return (
    <>
      <CollapsiblePanel
        isCollapsed={isCollapsed || isDisabled}
        isDisabled={isDisabled}
        title={title}
        toggleCollapsed={toggleCollapsed}
      >
        {{
          content: (
            <>
              {selectedItemHierarchy}
              {trigger}
            </>
          ),
          summary: (
            <>
              {collapsedHierarchy}
              {!isDisabled && trigger}
            </>
          ),
        }}
      </CollapsiblePanel>
      {modal}
    </>
  );
};

export default LocationHierarchyFilter;
