export type ReportIconProps = {
  reportFailed?: boolean;
};

export const ReportIcon = ({ reportFailed = false }: ReportIconProps) => (
  <svg
    data-testid="folder-or-report-icon"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16406 7.66797H6.66406V15.8346H4.16406V7.66797ZM8.83073 4.16797H11.1641V15.8346H8.83073V4.16797ZM13.4974 10.8346H15.8307V15.8346H13.4974V10.8346Z"
      fill={
        reportFailed
          ? "var(--report-icon-colour-failed)"
          : "var(--report-icon-colour-success)"
      }
    />
  </svg>
);
