import {
  HierarchyItemType,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import { Text } from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { EllipsisLabel } from "components-ui/src/ellipsis-label/EllipsisLabel";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { type MiniFocalTableItem } from "../../models/MiniFocalTableItem";
import styles from "./MiniFocalItemTableItemDescriptionCellFormatter.module.css";

export type MiniFocalItemTableItemDescriptionCellFormatterProps = {
  item: MiniFocalTableItem;
};

export const MiniFocalItemTableItemDescriptionCellFormatter = ({
  item,
}: MiniFocalItemTableItemDescriptionCellFormatterProps) => (
  <div className={styles.miniFocalItemTableItemDescriptionCellFormatter}>
    <div className={styles.colourKeyWrapper}>
      <span
        className={styles.colourKey}
        style={{ backgroundColor: item.color }}
      >
        {item.key}: {item.name}
      </span>
    </div>
    <Text className={styles.itemKey}>
      <>
        {item.key}
        <span className={styles.spacer}>:</span>
      </>
    </Text>
    <span
      className={classNames(styles.icon, {
        [styles.nonGroupIcon]: !item.evaluationType,
      })}
    >
      {item.evaluationType ? (
        <HierarchyGroupIcon
          evaluationType={item.evaluationType}
          hierarchyType={HierarchyType.Product}
        />
      ) : (
        <HierarchyLevelIcon
          shortName={item.shortName}
          type={HierarchyItemType.Hierarchy}
        />
      )}
    </span>
    <EllipsisLabel className={styles.ellipseContentOuter}>
      {item.name}
    </EllipsisLabel>
  </div>
);
