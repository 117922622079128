import { ddLog, getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type BasketLimitsReportParametersResponseDto } from "../models/BasketLimitsReportParametersResponseDto";
import { onLocalParametersReceived } from "./basket-limits-slice";

export const basketLimitsApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/basket-limits`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builder) => ({
    getReport: builder.query<
      BasketLimitsReportParametersResponseDto,
      { divisionName: string; reportId: string }
    >({
      async onQueryStarted(argument, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(onLocalParametersReceived(data));
        } catch {
          ddLog("Error retrieving local parameters");
        }
      },
      query: ({ divisionName, reportId }) => ({
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "GET",
        url: `/GetLocalParameters/${divisionName}/${reportId}`,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
  reducerPath: "basketLimitsApi",
  tagTypes: ["basketLimits"],
});

export const { useLazyGetReportQuery } = basketLimitsApiSlice;
