type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const BasketLimitsIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Basket limits report"}</title>
      <path
        clipRule="evenodd"
        d="M6.09889 22C5.3898 22 4.77129 21.5313 4.47082 20.8543L2.09123 11.0012L2 10.5429C1.99981 10.2667 2.0954 10.0018 2.26575 9.80643C2.43611 9.6111 2.66726 9.50136 2.90837 9.50136L11.248 9.50266L11.9895 9.5L12.748 9.50287L21.0902 9.50136C21.3313 9.50136 21.5626 9.6111 21.7332 9.80643C21.9038 10.0018 21.9998 10.2667 22 10.5429L21.9638 10.845L19.5436 20.8543C19.244 21.5313 18.6262 22 17.9171 22H6.09889ZM12.9148 17.3281L13.1108 11.2727H10.9673L11.1634 17.3281H12.9148ZM11.2528 19.7997C11.4716 20.0156 11.733 20.1236 12.0369 20.1236C12.2358 20.1236 12.4176 20.0739 12.5824 19.9744C12.75 19.875 12.8849 19.7415 12.9872 19.5739C13.0923 19.4062 13.1449 19.2202 13.1449 19.0156C13.1449 18.7145 13.0327 18.4574 12.8082 18.2443C12.5866 18.0284 12.3295 17.9205 12.0369 17.9205C11.733 17.9205 11.4716 18.0284 11.2528 18.2443C11.0369 18.4574 10.929 18.7145 10.929 19.0156C10.929 19.3224 11.0369 19.5838 11.2528 19.7997Z"
        fill={colour2}
        fillRule="evenodd"
      />
      <path
        d="M15.25 2.25C15.25 2.11193 15.3619 2 15.5 2H19C19.1381 2 19.25 2.11193 19.25 2.25V8.5H15.25V2.25Z"
        fill={colour1}
      />
      <path
        d="M10 3.75C10 3.61193 10.1119 3.5 10.25 3.5H13.75C13.8881 3.5 14 3.61193 14 3.75V8.5H10V3.75Z"
        fill={colour2}
      />
      <path
        d="M4.75 5.5C4.75 5.36193 4.86193 5.25 5 5.25H8.5C8.63807 5.25 8.75 5.36193 8.75 5.5V8.5H4.75V5.5Z"
        fill={colour3}
      />
    </svg>
  );
};
