import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { Text } from "@quantium-enterprise/qds-react";
import { getRollingPeriodState } from "../../parameters/time/RollingPeriodState";
import { getTimePeriodState } from "../../parameters/time/TimePeriodState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelRollingPeriodProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelRollingPeriod = ({
  parameterDto,
}: SummaryPanelRollingPeriodProps) => {
  const label = useAppSelector(getRollingPeriodState(parameterDto.id))?.label;
  const focusPeriod = useAppSelector(
    getTimePeriodState(ParameterId.FocusPeriod)
  );
  if (!focusPeriod || !label) {
    return null;
  }

  const showRollingPeriod = focusPeriod.startDate && focusPeriod.endDate;

  if (label && showRollingPeriod) {
    return <Text>{`${parameterDto.name}: ${label}`}</Text>;
  }

  return <div />;
};
