import { type CustomerProfilingReportParametersResponseDto } from "../models/CustomerProfilingReportParametersDto";
import { customerProfilingApiSlice } from "./customer-profiling-api-slice";

export const customerProfilingLocalParametersApiSlice =
  customerProfilingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getReport: builder.query<
        CustomerProfilingReportParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useGetReportQuery } = customerProfilingLocalParametersApiSlice;
