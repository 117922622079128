import { type PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "../store";

export type FastReportingSearchState = {
  isOpen: boolean;
  searchQuery: string;
};

const initialState: FastReportingSearchState = {
  isOpen: false,
  searchQuery: "",
};

export const fastReportingSearchReducerPath = "fastReportingSearch";

const fastReportingSearchSlice = createSlice({
  initialState,
  name: fastReportingSearchReducerPath,
  reducers: {
    closeSearchModal: (state) => {
      state.isOpen = false;
    },
    openSearchModal: (state) => {
      state.isOpen = true;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const selectIsOpen = createSelector(
  (state: RootState) => state.fastReportingSearch,
  (fastReporting: FastReportingSearchState) => fastReporting.isOpen
);

export const selectSearchQuery = createSelector(
  (state: RootState) => state.fastReportingSearch,
  (fastReporting: FastReportingSearchState) => fastReporting.searchQuery
);

export const { openSearchModal, closeSearchModal, setSearchQuery } =
  fastReportingSearchSlice.actions;

export default fastReportingSearchSlice.reducer;
