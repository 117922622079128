import { ErrorLayout } from "components-ui/";

export const FullPageError = () => (
  <ErrorLayout>
    <div>
      <p>
        Contact <a href="mailto:ProductSupport@quantium.com">Support</a> if
        error persists.
      </p>
    </div>
  </ErrorLayout>
);
