import { CustomerGroupType } from "@quantium-enterprise/common-ui";
import { CombinedIcon } from "./icons/CombinedIcon";
import { CustomSegmentationIcon } from "./icons/CustomSegmentationIcon";
import { GrowingStableDecliningIcon } from "./icons/GrowingStableDecliningIcon";
import { HeavyMediumLightIcon } from "./icons/HeavyMediumLightIcon";
import { MeasureThresholdIcon } from "./icons/MeasureThresholdIcon";
import { NewLapsedContinuousIcon } from "./icons/NewLapsedContinuousIcon";
import { PurchaserNonPurchaserIcon } from "./icons/PurchaserNonPurchaserIcon";

export type CustomerGroupIconProps = {
  className?: string;
  coloured?: boolean;
  text?: string;
  type: string | undefined;
};

export const CustomerGroupIcon = ({
  className,
  coloured = true,
  text,
  type,
}: CustomerGroupIconProps) => {
  switch (true) {
    case type === CustomerGroupType.Combined:
      return (
        <CombinedIcon className={className} coloured={coloured} text={text} />
      );
    case type === CustomerGroupType.CustomSegmentation:
      return (
        <CustomSegmentationIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === CustomerGroupType.GrowingStableDeclining:
      return (
        <GrowingStableDecliningIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === CustomerGroupType.HeavyMediumLight:
      return (
        <HeavyMediumLightIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === CustomerGroupType.MeasureThreshold:
      return (
        <MeasureThresholdIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === CustomerGroupType.NewLapsedContinuous:
      return (
        <NewLapsedContinuousIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    case type === CustomerGroupType.PurchaserNonPurchaser:
      return (
        <PurchaserNonPurchaserIcon
          className={className}
          coloured={coloured}
          text={text}
        />
      );
    default:
      return null;
  }
};
