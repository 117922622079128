type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const BasketAffinitiesIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Basket affinities report"}</title>
      <path
        d="M8.375 12.5722C8.45235 12.5275 8.54765 12.5275 8.625 12.5722L11.4061 14.1778C11.4834 14.2225 11.5311 14.305 11.5311 14.3943V17.6057C11.5311 17.695 11.4834 17.7775 11.4061 17.8222L8.625 19.4278C8.54765 19.4725 8.45235 19.4725 8.375 19.4278L5.59391 17.8222C5.51656 17.7775 5.46891 17.695 5.46891 17.6057V14.3943C5.46891 14.305 5.51656 14.2225 5.59391 14.1778L8.375 12.5722Z"
        fill={colour3}
      />
      <path
        d="M18.75 15.875C18.75 17.6009 17.3509 19 15.625 19C13.8991 19 12.5 17.6009 12.5 15.875C12.5 14.1491 13.8991 12.75 15.625 12.75C17.3509 12.75 18.75 14.1491 18.75 15.875Z"
        fill={colour1}
      />
      <path
        clipRule="evenodd"
        d="M17.0373 9.5021L12.8317 2.34839C12.5479 1.86554 11.8541 1.85292 11.5529 2.32513L6.97461 9.502L2.90837 9.50137C2.66726 9.50137 2.43611 9.61167 2.26575 9.80801C2.0954 10.0044 1.99981 10.2707 2 10.5483L2.09123 11.009L4.47082 20.9132C4.77129 21.5937 5.3898 22.0649 6.09889 22.0649H17.9171C18.6262 22.0649 19.244 21.5937 19.5436 20.9132L21.9638 10.8519L22 10.5483C21.9998 10.2707 21.9038 10.0044 21.7332 9.80801C21.5626 9.61167 21.3313 9.50137 21.0902 9.50137L17.0373 9.5021ZM15.8774 9.50231L12.1765 3.20698L8.16064 9.50219L11.248 9.50267L11.9895 9.5L12.748 9.50288L15.8774 9.50231ZM11.9894 10.5L12.7462 10.5029L20.9942 10.5014C20.9953 10.5055 20.9962 10.5098 20.997 10.5143L20.9778 10.6753L18.5953 20.5796C18.42 20.92 18.1415 21.0649 17.9171 21.0649H6.09889C5.87429 21.0649 5.59512 20.9197 5.41901 20.5791L3.06827 10.795L3.01013 10.5014L11.2497 10.5027L11.9894 10.5Z"
        fill={colour2}
        fillRule="evenodd"
      />
    </svg>
  );
};
