import { format } from "date-fns";

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);
  return format(date, "dd MMM yy");
};

export type TimePeriodItem = {
  endDate: string;
  label: string;
  periodValue: string;
  startDate: string;
};

export type TimePeriodSelectionSummaryProps = {
  items: TimePeriodItem[] | undefined;
  name: string;
};

export const TimePeriodSelectionSummary = ({
  name,
  items,
}: TimePeriodSelectionSummaryProps) => {
  const itemStrings = items
    ? items.map((item) => {
        const startDateString = formatTimestamp(item.startDate);
        const endDateString = formatTimestamp(item.endDate);
        return `${item.label} (${startDateString} - ${endDateString})`;
      })
    : [];
  return <div>{`${name}: ${itemStrings.join(", ")}`}</div>;
};
