import {
  type GroupSelectionDto,
  HierarchyShortName,
} from "@quantium-enterprise/common-ui";
import { Text } from "@quantium-enterprise/qds-react";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import styles from "./GroupSelectionSummary.module.css";

export type GroupSelectionSummaryProps = {
  itemCount?: number;
  items: GroupSelectionDto[] | undefined;
  maxDepth: number;
  moreItemsText: string;
};

export const GroupSelectionSummary = ({
  items,
  maxDepth,
  moreItemsText,
  itemCount,
}: GroupSelectionSummaryProps) => {
  const count = itemCount ?? items?.length ?? 0;
  return (
    <>
      {items?.slice(0, maxDepth).map((item) => (
        <div
          className={styles.groupItem}
          key={`${HierarchyShortName.ProductGroup}${item.groupName}`}
        >
          <span className={styles.structureFolderContainer}>
            <HierarchyGroupIcon
              evaluationType={item.evaluationType}
              hierarchyType={item.hierarchyType}
            />
          </span>
          <span className={styles.groupItemName}>{item.groupName}</span>
        </div>
      ))}
      {items && count > maxDepth && (
        <Text>{`+ ${count - maxDepth}${moreItemsText}`}</Text>
      )}
    </>
  );
};
