import { type PointOptionsObject } from "highcharts";
import { INTERACTIONS_VENN_CHART_COLOUR_MAP } from "../constants/interactions-venn-chart-colour-map";
import { type CrossShopTableItem } from "../models/CrossShopDataTableResponseDto";
import { type InteractionsVennMetric } from "../models/InteractionsVennMetric";
import { InteractionsVennMetricColumnHeaderKey } from "../models/InteractionsVennMetricColumnHeaderKey";

export enum PRODUCT_INTERACTION_DISPLAY_NAME_MAP {
  CustomersL = "Customers",
  CustomersLPercentage = "Customers %",
  SalesL = "Sales",
  SalesLPercentage = "Sales %",
}

/**
 * getVennChartSeries
 *
 * @param focalItems
 * @param productInteraction
 * @param metrics
 * @returns a venn chart series of type PointOptionsObject[]
 *
 * NOTE: highcharts/PointOptionsObject type provides `custom` as a Dictionary
 * that allows you to add additional data passed along, useful for Tooltips
 */
export const getVennChartSeries = (
  focalItems: CrossShopTableItem[],
  productInteraction: string,
  metrics?: InteractionsVennMetric[]
): PointOptionsObject[] => {
  if (!metrics) {
    return [];
  }

  // Grab tooltip data
  const tooltipDisplayNames: string[] = Object.values(
    PRODUCT_INTERACTION_DISPLAY_NAME_MAP
  );

  const tooltipData = metrics
    .filter((metric) => tooltipDisplayNames.includes(metric.displayName))
    .sort((a, b) => {
      if (a.displayName < b.displayName) {
        return -1;
      }

      if (a.displayName > b.displayName) {
        return 1;
      }

      return 0;
    });

  // Construct series
  const displayName =
    PRODUCT_INTERACTION_DISPLAY_NAME_MAP[
      productInteraction as keyof typeof PRODUCT_INTERACTION_DISPLAY_NAME_MAP
    ];

  const metricValues = metrics
    .filter(
      (metric) =>
        metric.productInteraction === productInteraction &&
        metric.displayName === displayName
    )
    .flatMap((metric) => metric.metricValues);

  // Construct the label for series
  const displayLabelName =
    PRODUCT_INTERACTION_DISPLAY_NAME_MAP[
      `${productInteraction}Percentage` as keyof typeof PRODUCT_INTERACTION_DISPLAY_NAME_MAP
    ];

  const metricValuesInPercentage = metrics
    .filter(
      (metric) =>
        metric.productInteraction === productInteraction &&
        metric.displayName === displayLabelName
    )
    .flatMap((metric) => metric.metricValues);

  const vennChartSeries: PointOptionsObject[] = [];

  // Order of rendering matters in Venn diagram for z-index
  metricValues.sort((a, b) => {
    if (a.key < b.key) {
      return -1;
    }

    if (a.key > b.key) {
      return 1;
    }

    return 0;
  });

  const custom = {
    // Pass along filtered metrics to tooltip
    tooltipData,
  };

  for (const metricValue of metricValues) {
    let name = "";
    // Assigning focal item index [0, 1, 2] with A,B,C series
    switch (metricValue.key) {
      case InteractionsVennMetricColumnHeaderKey.A:
        if (focalItems.length > 0) name = focalItems[0].name;
        break;
      case InteractionsVennMetricColumnHeaderKey.B:
        if (focalItems.length > 1) name = focalItems[1].name;
        break;
      case InteractionsVennMetricColumnHeaderKey.C:
        if (focalItems.length > 2) name = focalItems[2].name;
        break;
      default:
        // Require the empty space as highcharts will default to the internal name if
        // no characters is passed in
        name = " ";
    }

    switch (metricValue.key) {
      case InteractionsVennMetricColumnHeaderKey.A:
      case InteractionsVennMetricColumnHeaderKey.B:
      case InteractionsVennMetricColumnHeaderKey.C: {
        vennChartSeries.push({
          sets: [metricValue.key],
          value: metricValue.valueWithIntersections,
          color: INTERACTIONS_VENN_CHART_COLOUR_MAP[metricValue.key],
          name,
          custom: {
            ...custom,
            displayValue: metricValuesInPercentage.find(
              (mvip) => mvip.key === metricValue.key
            )?.value,
          },
        });
        break;
      }

      case InteractionsVennMetricColumnHeaderKey.AB:
      case InteractionsVennMetricColumnHeaderKey.AC:
      case InteractionsVennMetricColumnHeaderKey.BC:
      case InteractionsVennMetricColumnHeaderKey.ABC: {
        const splitKeys = metricValue.key.split("");

        vennChartSeries.push({
          sets: [...splitKeys],
          value: metricValue.valueWithIntersections,
          color: INTERACTIONS_VENN_CHART_COLOUR_MAP[metricValue.key],
          name,
          custom: {
            ...custom,
            displayValue: metricValuesInPercentage.find(
              (mvip) => mvip.key === metricValue.key
            )?.value,
          },
        });
        break;
      }

      default: {
        // Do nothing when given case did not match
        break;
      }
    }
  }

  const orderedVennChartSeries = vennChartSeries.sort((a, b) => {
    if ((a.sets?.length ?? 0) < (b.sets?.length ?? 0)) {
      return -1;
    }

    if ((a.sets?.length ?? 0) > (b.sets?.length ?? 0)) {
      return 1;
    }

    return 0;
  });

  return orderedVennChartSeries;
};
