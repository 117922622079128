import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import styles from "./PipeSeparatedSummary.module.scss";

export type PipeSeparatedSummaryProps = {
  parameters: Array<{
    name: string;
    value: string;
  }>;
};

export const PipeSeparatedSummary = ({
  parameters,
}: PipeSeparatedSummaryProps) => {
  const id = uniqueId();
  return (
    <div className={styles.pipeSeparatedSummaryContainer}>
      <div className={styles.pipeSeparatedSummaryContent}>
        {parameters
          .filter((parameter) => Boolean(parameter.value))
          .map((parameter) => (
            <span key={`${id}:${parameter.name}:${parameter.value}`}>
              <label>{parameter.name}:</label>
              {" " + parameter.value}
            </span>
          ))}
      </div>
    </div>
  );
};
