import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import mainStyles from "components-ui/src/charts/highcharts-react/HighchartsReact.module.css";
import styles from "./CrossShopInteractionsVennCustomTooltip.module.css";

export type CrossShopInteractionsVennCustomTooltipProps = {
  colours: string[];
  displayName: string;
  metrics: Array<{
    displayName: string;
    value: string;
  }>;
  names: string[];
};

export const CrossShopInteractionsVennCustomTooltip = ({
  colours,
  displayName,
  metrics,
  names,
}: CrossShopInteractionsVennCustomTooltipProps) => (
  <div className={mainStyles.chartTooltip}>
    <div className={styles.tooltipTitle}>
      <div className={styles.tooltipTitleColourMap}>
        {colours.map((colour) => (
          <div
            className={mainStyles.circle}
            key={uniqueId()}
            style={{ backgroundColor: `${colour}` }}
          />
        ))}
      </div>
      <p className={styles.tooltipTitleContent}>{displayName}</p>
    </div>
    <div className={styles.tooltipNameContent}>
      {names.map((name) => (
        <p className={styles.tooltipNameContentItem} key={uniqueId()}>
          {name}
        </p>
      ))}
    </div>
    <ul className={styles.tooltipTableContent}>
      {metrics.map((metric: { displayName: string; value: string }) => (
        <li
          className={styles.tooltipTableContentItem}
          key={metric.displayName.toLowerCase().replace(" ", "")}
        >
          <span>{metric.displayName}</span>
          <span>{metric.value}</span>
        </li>
      ))}
    </ul>
  </div>
);
