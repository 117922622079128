type Props = {
  className?: string;
  coloured?: boolean;
  text?: string;
};
export const StaticLocationGroupIcon = ({
  className,
  coloured = true,
  text,
}: Props) => {
  const markerColour = coloured
    ? "var(--marker-boxer-colour)"
    : "Var(--icon-colour-two-disabled)";
  const listColour = coloured
    ? "var(--static-colour)"
    : "var(--group-colour-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 96 64"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Static location group"}</title>
      <path
        d="M18 15.75C12.9857 15.75 9 19.47 9 24.15C9 31.35 18 39.75 18 39.75C18 39.75 27 31.23 27 24.15C27 19.47 23.0143 15.75 18 15.75ZM18 27.15C16.2 27.15 14.7857 25.83 14.7857 24.15C14.7857 22.47 16.2 21.15 18 21.15C19.8 21.15 21.2143 22.47 21.2143 24.15C21.2143 25.83 19.8 27.15 18 27.15Z"
        fill={markerColour}
      />
      <path
        d="M63.9062 24.833H34.4062V29.75H63.9062V24.833ZM63.9062 15H34.4062V19.917H63.9062V15ZM34.4062 39.583H54.0729V34.667H34.4062V39.583ZM82.3438 28.521L86.0312 32.208L68.8352 49.417L57.7604 38.354L61.4479 34.667L68.8352 42.042L82.3438 28.521Z"
        fill={listColour}
      />
    </svg>
  );
};
