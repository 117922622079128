import { FAST_REPORTING_PATH } from "@quantium-enterprise/common-ui";
import { type RouteWithLabel } from "@quantium-enterprise/common-ui";
import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { store } from "./store";

export * from "./FastReportingSearchSection";
export * from "./FastReportingSearchModal";
export * from "./FastReportingFocalItemSearch";
export * from "./FastReportingFocalItemSearchState";
export * from "./focal-item-search/SelectableItem";
export * from "./focal-item-search/services/SearchConfigurationApi";
export * from "./focal-item-search/HierarchySearchLevel";
export * from "./useOpenFastReportingTabs";
export * from "./fast-report/ReportLoadingWrapper";

// eslint-disable-next-line @typescript-eslint/promise-function-async -- this is as per React docs
const FastReporting = lazy(() => import("./FastReporting"));

export const fastReporting = (): RouteWithLabel => ({
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <FastReporting />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Fast reporting",
  path: FAST_REPORTING_PATH,
});
