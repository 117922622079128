import {
  NumberFormat,
  type MeasureThresholdSelectionDto,
} from "@quantium-enterprise/common-ui";

export type MeasureThresholdSelectionSummaryProps = {
  selections: MeasureThresholdSelectionDto[];
};

const thresholdText = (selection: MeasureThresholdSelectionDto) =>
  selection.threshold.format === NumberFormat.Percent
    ? `${selection.threshold.number}%`
    : selection.threshold.number.toString();

export const MeasureThresholdSelectionSummary = ({
  selections,
}: MeasureThresholdSelectionSummaryProps) => (
  <div>
    {selections.map((selection) => (
      <div key={`${selection.measure.value}-${selection.comparator.value}`}>
        Measure: {selection.measure.label} {selection.comparator.label}{" "}
        {thresholdText(selection)}
      </div>
    ))}
  </div>
);
