import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type ChartDataSeries } from "components-ui/src/charts/trends-chart/TrendsChart";
import { type ChartSelections } from "../../../models/chart-selections";
import { type DayOfWeekResponseDto } from "../../../models/day-of-week-chart";
import { formatDayId, formatHourId } from "../../../utils/chart-utils";
import { getCsvColumnHeader } from "../../../utils/csv-util";
import {
  getBenchmarkData,
  getFocalData,
  getSegmentsData,
} from "../filter-data";

export const csvTransformation = (
  reportletData: DayOfWeekResponseDto | undefined,
  currencySymbol: string,
  chartSelections: ChartSelections,
  focalItemParents: HierarchySliceNodeDto[]
) => {
  const focalItemsView = "Focal item(s)";
  const segmentsView = "Customer segments";
  let filteredData: ChartDataSeries[] = [];
  const columnHeaders = ["Day Of Week"];
  const csvData = [columnHeaders];

  if (reportletData?.itemData) {
    const convertedDays = reportletData.daysMetadata.map((item) => {
      if (typeof item === "number") {
        return formatHourId(item);
      } else {
        return formatDayId[item.toUpperCase()];
      }
    });
    if (chartSelections.viewBy.selected === focalItemsView) {
      filteredData = getFocalData(
        reportletData.focalItems,
        reportletData,
        chartSelections,
        focalItemParents
      );
      const benchmarkData = getBenchmarkData(reportletData, chartSelections);
      if (benchmarkData) {
        filteredData = [...filteredData, benchmarkData];
      }
    } else if (chartSelections.viewBy.selected === segmentsView) {
      filteredData = getSegmentsData(reportletData, chartSelections);
    }

    columnHeaders.push(
      ...filteredData.map((data) =>
        getCsvColumnHeader(
          reportletData.format,
          currencySymbol,
          data.name,
          reportletData.metric
        )
      )
    );

    for (const [index, convertedDay] of convertedDays.entries()) {
      const row = [];
      row.push(convertedDay);
      row.push(...filteredData.map((data) => `${data.data[index] ?? 0}`));
      csvData.push(row);
    }
  }

  return csvData;
};
