import { CustomerGroupStatus } from "@quantium-enterprise/common-ui";
import { Tag, TagColour } from "@quantium-enterprise/qds-react";
import { type TagProps } from "@quantium-enterprise/qds-react/dist/Tag";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import { type ReactElement } from "react";
import styles from "./CustomerGroupStatusTag.module.css";

export const CustomerGroupStatusTag = ({
  status,
  customerGroupDetailsModal,
}: {
  customerGroupDetailsModal?: boolean;
  status: CustomerGroupStatus;
}): ReactElement => {
  const tagProps = ((st): Partial<TagProps> => {
    switch (st) {
      case CustomerGroupStatus.Invalid:
        return customerGroupDetailsModal
          ? { colour: TagColour.Bad }
          : {
              customBackground: tokens.colour["brand-coral-palette"].A200,
              customColour: "#bf2d2b",
            };
      case CustomerGroupStatus.InProgress:
        return { colour: TagColour.Notification, text: "IN PROGRESS" };
      case CustomerGroupStatus.Failed:
        return { customBackground: tokens.colour["status-bad-text"] };
      case CustomerGroupStatus.Valid:
        return {
          colour: customerGroupDetailsModal
            ? TagColour.Good
            : TagColour.Neutral,
          text: customerGroupDetailsModal ? "Valid" : "AVAILABLE",
        };
      default:
        return { colour: TagColour.Neutral };
    }
  })(status);

  return <Tag className={styles.statusTag} text={status} {...tagProps} />;
};
