import { type ParameterDto } from "@quantium-enterprise/common-ui";
import {
  Nav,
  NavButton,
  NavSize,
  NavVariant,
} from "@quantium-enterprise/qds-react";
import { useState } from "react";
import ParameterItem from "../parameters/ParameterItem";
import styles from "./ParameterGroupTab.module.css";

export type TabbedParametersProps = {
  parameters: ParameterDto[];
};

export const TabbedParameters = ({ parameters }: TabbedParametersProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  // remove selected tab now, and add it when its developed
  const parametersWithSelectedTab = [...parameters];
  const parameterNavItems = parametersWithSelectedTab.map(
    (parameter, index) => (
      <NavButton key={parameter.name} onClick={() => setCurrentTab(index)}>
        <span className={styles.parameterNav}>{parameter.name}</span>
      </NavButton>
    )
  );

  const parameterNavContent = () => {
    const tabName = parametersWithSelectedTab[currentTab].name;
    switch (tabName) {
      case "Selected":
        return (
          <div className={styles.parameterContainer}>
            <br />
          </div>
        );
      default: {
        const currentTabParameter = parameters.find(
          (parameter) => parameter.name === tabName
        );
        return (
          <>
            {currentTabParameter && (
              <ParameterItem
                key={currentTabParameter.name}
                parameterDto={currentTabParameter}
              />
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <div className={styles.tabs}>
        <Nav
          activeIndex={currentTab}
          className={styles.tab}
          size={NavSize.Small}
          variant={NavVariant.Tab}
        >
          {parameterNavItems}
        </Nav>
      </div>
      <div className={styles.tabsContent}>{parameterNavContent()}</div>
    </>
  );
};

export default TabbedParameters;
