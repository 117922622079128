import classNames from "classnames";
import { type ReactNode } from "react";
import styles from "./DragAndDropContainer.module.css";

export enum DragAndDropContainerType {
  Left = "left-container",
  Right = "right-container",
}

export type DragAndDropContainerContainerProps = {
  children?: ReactNode;
  description?: string;
  title?: string;
  type: DragAndDropContainerType;
};

export const DragAndDropContainer = ({
  type,
  children,
  description,
  title,
}: DragAndDropContainerContainerProps) => (
  <div
    className={classNames({
      [styles.draggableListContainer]: type === DragAndDropContainerType.Left,
      [styles.droppableContainer]: type === DragAndDropContainerType.Right,
    })}
  >
    {title && <div className={styles.droppableContainerTitle}> {title} </div>}
    {description && (
      <div className={styles.droppableContainerDescription}>{description}</div>
    )}
    {children}
  </div>
);
