import { type LocalSelectedValueDto } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  type SidePanelLocationHierarchyParameter,
  type SidePanelMultiTypeParameter,
  type SidePanelParameter,
  type SidePanelSegmentationParameter,
} from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isMultiTypeParameter,
  isSegmentationParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getMultiSelectDefaultSelection,
  getSegmentationSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type CompareMetricsLocalSelections } from "../services/compare-metrics-slice";

/**
 * Helper function that gets the default selection of a single parameter.
 *
 * @param parameter singular generic parameter config
 * @returns string[] of the default values
 */
export const getDefaultSelectionSingle = (
  parameter: SidePanelParameter
): PanelOption[] => {
  switch (parameter.id) {
    case LocalParameters.Channel:
      if (isMultiTypeParameter(parameter))
        return getMultiSelectDefaultSelection(parameter);
      break;
    case LocalParameters.Promotion:
      if (isMultiTypeParameter(parameter))
        return getMultiSelectDefaultSelection(parameter);
      break;
    case LocalParameters.Segmentation:
      if (isSegmentationParameter(parameter)) {
        const selection = getSegmentationSelectDefaultSelection(parameter);
        if (selection)
          return [
            { label: selection.label, value: selection.value },
            { label: selection.segmentLabel, value: selection.segmentValue },
          ];
      }

      break;
    case "segment":
      if (isSegmentationParameter(parameter)) {
        const selection = getSegmentationSelectDefaultSelection(parameter);
        if (selection)
          return [
            { label: selection.segmentLabel, value: selection.segmentValue },
          ];
      }

      break;
    case LocalParameters.LocationHierarchy:
      if (isLocationSelectionParameter(parameter)) {
        const selection = getLocationSelectDefault(parameter);

        return [
          {
            label: `(${hierarchyLevelDisplayLabel(selection.shortName)} ${
              selection.name
            })`,
            value: selection.nodeNumber.toString(),
          },
        ];
      }

      break;
    default:
      break;
  }

  return [];
};

/**
 * Helper function that returns a user selection state given a local parameters config
 *
 * @param localParametersConfig local parameter config from the backend
 * @returns CompareMetricsLocalSelections
 */
export const getDefaultSelections = (
  localParametersConfig: SidePanelParameter[]
): CompareMetricsLocalSelections => {
  const selections: CompareMetricsLocalSelections = {
    Time: [
      {
        value: "",
        label: "",
      },
    ],
    ComparsionPeriod: [
      {
        value: "",
        label: "",
      },
    ],
    Channel: [
      {
        value: "",
        label: "",
      },
    ],
    LocationHierarchy: [
      {
        value: "",
        label: "",
      },
    ],
    Promotion: [
      {
        value: "",
        label: "",
      },
    ],
    Segmentation: [
      {
        value: "",
        label: "",
      },
    ],
  };

  for (const parameter of localParametersConfig) {
    switch (parameter.id) {
      case LocalParameters.Channel:
        if (isMultiTypeParameter(parameter)) {
          const selection = getMultiSelectDefaultSelection(parameter);
          selections.Channel = selection;
        }

        break;
      case LocalParameters.Promotion:
        if (isMultiTypeParameter(parameter)) {
          const selection = getMultiSelectDefaultSelection(parameter);
          selections.Promotion = selection;
        }

        break;
      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            selections.Segmentation = [
              { label: selection.label, value: selection.value },
              { label: selection.segmentLabel, value: selection.segmentValue },
            ];
          }
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          const selection = getLocationSelectDefault(parameter);

          selections.LocationHierarchy = [
            {
              label: `(${hierarchyLevelDisplayLabel(selection.shortName)}) ${
                selection.name
              }`,
              value: selection.nodeNumber.toString(),
            },
          ];
        }

        break;
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const timeParameter = getTimeDefaultSelection(parameter);
          selections.Time = [
            {
              label: timeParameter.time,
              value: timeParameter.timePeriodLength,
            },
          ];
        }

        break;
      default:
        break;
    }
  }

  return selections;
};

/**
 * Helper function that returns all selectable options as an array
 * Used for Channel and Promotion local parameters
 *
 * @param parameter singular parameter config
 * @returns string[] of the default values. Eg) ["All", "INSTORE", "ONLINE"]
 */
export const getMultiTypeMultiSelectDefault = (
  parameter: SidePanelMultiTypeParameter
) =>
  parameter.selections.map((selection) => ({
    label: selection.label,
    value: selection.value,
  }));

/**
 * Helper function that returns an array representing all segments selected in a multi-selectable
 * customer segment local parameter. Filters out the "All" segment option
 *
 * @param parameter singular segmentation parameter config
 * @returns string[] Eg) ["AFFLUENCE_T1", "budget", "mainstream", "premium"]
 */

export const getSegmentationMultiSelectDefault = (
  parameter: SidePanelSegmentationParameter,
  segmentationSelection: string[]
): PanelOption[] => {
  const nonAllOptions = parameter.selections.filter(
    (option) =>
      option.segmentValue !== "All" && option.value === segmentationSelection[0]
  );
  if (nonAllOptions.length > 0) {
    const firstOption = {
      label: nonAllOptions[0].label,
      value: nonAllOptions[0].value,
    };
    return [
      firstOption,
      ...nonAllOptions
        .filter((selection) => selection.value === firstOption.value)
        .map((item) => ({
          label: item.segmentLabel,
          value: item.segmentValue,
        })),
    ];
  }

  return [];
};

/**
 * Helper function that returns an array representing all locations selected in a multi-selectable
 * location hierarchy local parameter. toString()'s node numbers so return array is string[].
 *
 * @param parameter singular location hierarchy parameter config
 * @returns string[] Eg) ["0", "1", "2"]
 */
export const getLocationHierarchyMultiSelectDefault = (
  parameter: SidePanelLocationHierarchyParameter
) => {
  const allSelections = parameter.selections.map((selections) => selections);

  return allSelections;
};

/**
 * Helper function that gets all the selections of a single parameter when in multi-select mode.
 *
 * @param parameter singular generic parameter config
 * @returns string[] of the default values
 */
export const getAllSelectionSingle = (
  parameter: SidePanelParameter,
  localParameterSelection: CompareMetricsLocalSelections
): PanelOption[] => {
  switch (parameter.id) {
    case LocalParameters.Channel:
      if (isMultiTypeParameter(parameter))
        return getMultiTypeMultiSelectDefault(parameter);
      break;
    case LocalParameters.Promotion:
      if (isMultiTypeParameter(parameter))
        return getMultiTypeMultiSelectDefault(parameter);
      break;
    case LocalParameters.Segmentation:
      if (isSegmentationParameter(parameter)) {
        const parameters = getSegmentationMultiSelectDefault(
          parameter,
          Object.values(localParameterSelection.Segmentation).map(
            (data) => data.value as string
          )
        );
        return parameters;
      }

      break;
    case LocalParameters.LocationHierarchy:
      if (isLocationSelectionParameter(parameter)) {
        const parameters = getLocationHierarchyMultiSelectDefault(parameter);
        return parameters.map((Parameter) => ({
          label: `(${hierarchyLevelDisplayLabel(Parameter.shortName)}) ${
            Parameter.name
          }`,
          value: Parameter.nodeNumber,
        }));
      }

      break;
    default:
      break;
  }

  return [];
};

/**
 * Function that takes the local parameter selections and creates the localSelectedValues object for
 * compare metrics reportlet and focal item table endpoint.
 *
 * @param localParameterSelections
 * @returns
 */
export const getUserSelections = (
  localParameterSelections: CompareMetricsLocalSelections
) => {
  const userSelections: LocalSelectedValueDto[] = [];

  for (const [parameterID, values] of Object.entries(
    localParameterSelections
  )) {
    if (Array.isArray(values) && values.length > 0) {
      if (parameterID === LocalParameters.Segmentation) {
        userSelections.push({
          id: parameterID,
          values: [...values.map((data) => data.value as string)],
        });
      } else {
        userSelections.push({
          id: parameterID,
          values: Object.values(values).map((data) => data.value as string),
        });
      }
    }
  }

  return userSelections;
};

/**
 * Function that takes the local parameter selections and creates the localSelectedValues object for
 * compare metrics export endpoint.
 *
 * @param localParameterSelections
 * @returns
 */
export const getUserSelectionsForExport = (
  localParameterSelections: CompareMetricsLocalSelections,
  localParametersConfig: SidePanelParameter[]
) => {
  const userSelections: LocalSelectedValueDto[] = [];
  const locationLookup = localParametersConfig.find(
    (item) => item.id === LocalParameters.LocationHierarchy
  ) as SidePanelLocationHierarchyParameter;
  const locations = locationLookup.selections;

  for (const [parameterID, values] of Object.entries(
    localParameterSelections
  )) {
    switch (parameterID) {
      case "ComparsionPeriod":
        break;
      case LocalParameters.Time:
        if (Array.isArray(values) && values.length > 0) {
          userSelections.push({
            id: parameterID,
            values: Object.values(values).map((data) =>
              data.label
                .toString()
                .slice(0, Math.max(0, data.label.toString().indexOf(",")))
            ),
          });
        }

        break;
      case LocalParameters.Segmentation:
        if (Array.isArray(values) && values.length > 0) {
          const [, ...segment] = values;
          const temporaryValues = values.slice(0, 1);
          userSelections.push({
            id: parameterID,
            values: Object.values(temporaryValues).map(
              (data) => data.label as string
            ),
          });

          userSelections.push({
            id: "Segment",
            values: Object.values(segment).map((data) => data.label as string),
          });
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (Array.isArray(values) && values.length > 0) {
          userSelections.push({
            id: parameterID,
            values: locations.map(
              (data) =>
                "(" +
                hierarchyLevelDisplayLabel(data.shortName) +
                ") " +
                data.name
            ),
          });
        }

        break;

      default:
        if (Array.isArray(values) && values.length > 0) {
          userSelections.push({
            id: parameterID,
            values: Object.values(values).map((data) => data.label as string),
          });
        }
    }
  }

  return userSelections;
};
