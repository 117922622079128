import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type CrossShopParameterSelections } from "../models/CrossShopParameterSelections";
import { initialState } from "../services/customer-cross-shop-slice";

export const getLocalSelections = (
  localParameters: SidePanelParameter[]
): CrossShopParameterSelections => {
  const localSelections: CrossShopParameterSelections = {
    channel: {
      label: "",
      value: "",
    },
    segmentation: [],
    productInteraction: {
      label: "",
      value: "",
    },
    locationHierarchy:
      initialState.persistedSelections.localSelections.locationHierarchy,
    time: "",
    timePeriodLength: "",
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const { time, timePeriodLength } = getTimeDefaultSelection(parameter);

          localSelections.time = time;
          localSelections.timePeriodLength = timePeriodLength;
        }

        break;
      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.segmentation = [
              { label: selection.label, value: selection.value },
              { label: selection.segmentLabel, value: selection.segmentValue },
            ];
          }
        }

        break;
      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.channel.label = selection.label;
            localSelections.channel.value = selection.value;
          }
        }

        break;
      case LocalParameters.ProductInteraction:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            localSelections.productInteraction.label = selection.label;
            localSelections.productInteraction.value = selection.value;
          }
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          localSelections.locationHierarchy =
            getLocationSelectDefault(parameter);
        }

        break;
      default:
        break;
    }
  }

  return localSelections;
};
