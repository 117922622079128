import { type DriverTreeRequestDto } from "../models/DriverTreeRequestDto";
import { type DriverTreeResponseDto } from "../models/DriverTreeResponseDto";
import { keyDriverTreeApiSlice } from "./key-driver-tree-api-slice";

export const keyDriverTreeDriverTreeDataSlice =
  keyDriverTreeApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getKeyDriverTreeDriverTreeData: builder.query<
        DriverTreeResponseDto,
        { divisionName: string; requestPayload: DriverTreeRequestDto }
      >({
        async onQueryStarted(_argument, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch {
            // eslint-disable-next-line no-console
            console.log("Error fetching KDT driver tree data", Error);
          }
        },
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetDriverTreeChartData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetKeyDriverTreeDriverTreeDataQuery } =
  keyDriverTreeDriverTreeDataSlice;
