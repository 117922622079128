export const persistSelections = (key: string | undefined, value: unknown) => {
  if (key) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const getPersistedSelections = (key: string | undefined) => {
  if (key) {
    const item = sessionStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch {
        return null;
      }
    }
  }

  return null;
};
