import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { EXPORT_LOCATION_SUFFIX } from "../../common/constants";
import { getExportParameterSummary } from "../../common/utils/export-parameter-summary-utils";
import { type RootState } from "../../store";
import { BasketlimitsFeatureFlags } from "../constants/basket-limits-feature-flags";
import {
  selectBasketLimit,
  selectBasketLimitsChartRows,
  selectFocalItem,
  selectIsFetchingReportletData,
  selectLocalParametersInitialised,
  selectLocation,
  selectTime,
  selectTimePeriodLength,
  selectTotalTimesExceeded,
  selectShowBasketLimitChartDataLabels,
  toggleBasketLimitChartDataLabels,
} from "../services/basket-limits-slice";
import { BasketLimitsChart } from "./BasketLimitsChart";
import styles from "./BasketLimitsReportlet.module.css";
import { csvTransformationBasketLimits } from "./csvTransformation";

type BasketLimitsReportletProps = {
  reportParameters?: ReportParametersDto;
};

export const BasketLimitsReportlet = ({
  reportParameters,
}: BasketLimitsReportletProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const focalItem = useSelector(selectFocalItem);
  const totalTimesExceeded = useSelector(selectTotalTimesExceeded);
  const isFetchingReportletData = useSelector(selectIsFetchingReportletData);
  const basketLimitRows = useSelector(selectBasketLimitsChartRows);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const location = useSelector(selectLocation);
  const basketLimit = useSelector(selectBasketLimit);
  const showBasketLimitChartDataLabels = useSelector(
    selectShowBasketLimitChartDataLabels
  );
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketlimitsFeatureFlags.ReportExport] ?? false;

  const chartContainerRef = useRef<HTMLElement>();

  const isDataLoaded =
    localParametersInitialised &&
    Boolean(reportId) &&
    Boolean(focalItem.itemCode);
  const noData = totalTimesExceeded === 0;

  const chartCsvTransformationCallback = useCallback(
    () => csvTransformationBasketLimits(basketLimitRows),
    [basketLimitRows]
  );

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Basket_limits_${timePeriodLength}_${getExportParameterSummary(
          location.map((selection) => selection.name).join(","),
          EXPORT_LOCATION_SUFFIX
        )}`
      ),
    [timePeriodLength, location]
  );
  const localParameters = useMemo(
    () => [
      { name: "Focal item", value: focalItem.name },
      { name: "Time", value: time },
      { name: "Basket limit", value: basketLimit.toLocaleString() },
      {
        name: "Location",
        value: getExportParameterSummary(
          location
            .map((selection) => `(${selection.shortName}) ${selection.name}`)
            .join(", "),
          EXPORT_LOCATION_SUFFIX
        ),
      },
    ],
    [focalItem, time, basketLimit, location]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showBasketLimitChartDataLabels,
      value: "",
    },
  ];

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.basketLimits.reportName,
  }));

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  // Memoize the component for performance and to avoid freezing when changing
  // the basket limit slider filter local parameter.
  const basketLimitsChart = useMemo(
    () => <BasketLimitsChart onOptionsChanged={setCurrentOptions} />,
    []
  );

  return (
    <ReportletAccordion
      subtitle="Understand which stores exceeded the basket limit."
      title="Basket limits"
    >
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={!isDataLoaded || isFetchingReportletData}
          noData={isDataLoaded && noData}
        >
          <div className={styles.chartOptionsContainer}>
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: <ReportIcon type={ReportType.BasketLimits} />,
                      chartTitle: `Basket limits - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={localParameters}
              reportParameters={reportParameters}
              toggleDataLabels={() => {
                dispatch(toggleBasketLimitChartDataLabels());
              }}
            />
          </div>
          <ChartFooterWrapper
            height="400px"
            parameters={localParameters}
            ref={chartContainerRef}
          >
            {basketLimitsChart}
          </ChartFooterWrapper>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
