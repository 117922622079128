import { type IconGlyph } from "@quantium-enterprise/qds-react";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  Text,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { ButtonToggleGroupTestIds } from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import styles from "./ReportButtonTabItem.module.css";

export type ReportButtonTabItemProps = {
  className?: string;
  defaultImgIcon?: string;
  icon?: IconGlyph;
  label: string;
  selected: boolean;
  selectedImgIcon?: string;
  setSelectedButton: (value: string) => void;
  value: string;
};

export const ReportButtonTabItem = ({
  defaultImgIcon,
  icon,
  label,
  value,
  selected,
  selectedImgIcon,
  setSelectedButton,
  className,
}: ReportButtonTabItemProps) => (
  <Button
    className={className}
    height={ButtonHeight.XSmall}
    key={value}
    onClick={() => setSelectedButton(value)}
    pressed={selected}
    variant={ButtonVariant.Secondary}
  >
    <>
      {icon ? (
        <Icon
          data-testid={ButtonToggleGroupTestIds.ButtonIcon}
          glyph={icon}
          text={label}
        />
      ) : null}

      {defaultImgIcon && selectedImgIcon ? (
        <span
          className={classNames(
            "q-icon",
            "q-icon-medium",
            styles.reportButtonTabItemImgIconWrapper
          )}
        >
          <img
            alt={label}
            className={styles.reportButtonTabItemImgIcon}
            src={selected ? selectedImgIcon : defaultImgIcon}
          />
        </span>
      ) : null}
      <Text>{label}</Text>
    </>
  </Button>
);
