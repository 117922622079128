import {
  type ItemIdentifier,
  type HierarchyItemsRequestDto,
  HierarchyGroupRuleOperator,
  type HierarchyType,
  type HierarchyMetadataResponseDto,
  HierarchyStructureName,
  useGetItemsQuery,
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyItemName,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
  Spinner,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NULL_SHORT_NAME } from "../constants";
import { DynamicGroupRule } from "../dynamic-group-rule/DynamicGroupRule";
import styles from "./DynamicGroupRulesForm.module.css";

const MAX_NUMBER_OF_RULES = 100;

export type DynamicGroupRulesFormProps = {
  editableRule: HierarchyGroupRuleWithIdAndName | undefined;
  hierarchyAttributes: HierarchyMetadataResponseDto[];
  hierarchyType: HierarchyType;
  leafShortName: string;
  readonlyRules: HierarchyGroupRuleWithIdAndName[];
  setEditableRule: React.Dispatch<
    React.SetStateAction<HierarchyGroupRuleWithIdAndName | undefined>
  >;
  setReadOnlyRules: React.Dispatch<
    React.SetStateAction<HierarchyGroupRuleWithIdAndName[]>
  >;
};

export const DynamicGroupRulesForm = ({
  hierarchyType,
  hierarchyAttributes,
  leafShortName,
  readonlyRules,
  editableRule,
  setReadOnlyRules,
  setEditableRule,
}: DynamicGroupRulesFormProps) => {
  const { name: activeDivisionName } = useDivision();

  const allRules = editableRule
    ? readonlyRules.concat([editableRule])
    : readonlyRules;

  const isRulesEmpty = allRules.length === 0;

  const [isRuleNamesUpdated, setIsRuleNamesUpdated] = useState(isRulesEmpty);

  const allRuleItems = allRules.flatMap((rule) =>
    rule.values.map(
      (value) =>
        ({ code: value.code, shortName: rule.shortName } as ItemIdentifier)
    )
  );

  const { data: ruleItemsData, isSuccess: isGetRuleItemsQuerySuccess } =
    useGetItemsQuery(
      {
        division: activeDivisionName,
        hierarchyType: hierarchyType.toString() as HierarchyType,
        payload: {
          items: allRuleItems,
          page: 0,
          pageSize: allRuleItems.length,
        } as HierarchyItemsRequestDto,
      },
      {
        skip: !activeDivisionName || isRulesEmpty || isRuleNamesUpdated,
      }
    );

  // do not allow the user to add more rules if they are up to 100 rules, or the currently edited rule is not complete
  const isAddButtonDisabled = useMemo(() => {
    if (allRules.length === 0) return false;

    return (
      allRules.length >= MAX_NUMBER_OF_RULES ||
      allRules[allRules.length - 1].shortName === NULL_SHORT_NAME ||
      allRules[allRules.length - 1].values.length === 0
    );
  }, [allRules]);

  const ruleNameOptions = useMemo(
    () => [
      {
        name: "",
        shortName: NULL_SHORT_NAME,
      } as HierarchyMetadataResponseDto,
      ...hierarchyAttributes,
    ],
    [hierarchyAttributes]
  );

  useEffect(() => {
    if (isGetRuleItemsQuerySuccess) {
      const updateRuleItemName = (
        rule: HierarchyGroupRuleWithIdAndName | undefined
      ) =>
        ({
          ...rule,
          fullShortName: ruleNameOptions.find(
            (option) => option.shortName === rule?.shortName
          )?.name,
          values: rule?.values.map(
            (value) =>
              ({
                ...value,
                name: ruleItemsData.results.find(
                  (result) =>
                    result.code === value.code &&
                    result.shortName === rule.shortName
                )?.name,
              } as HierarchyItemName)
          ),
        } as HierarchyGroupRuleWithIdAndName);

      setReadOnlyRules((oldRules) => oldRules.map(updateRuleItemName));

      setEditableRule(updateRuleItemName);

      setIsRuleNamesUpdated(true);
    }
  }, [
    ruleItemsData,
    isGetRuleItemsQuerySuccess,
    ruleNameOptions,
    setEditableRule,
    setReadOnlyRules,
  ]);

  const getLastHierarchicalRuleInGroupRules = useCallback(
    (structureName: HierarchyStructureName) => {
      const hierarchyNames = hierarchyAttributes
        .filter((x) => x.structureName === structureName)
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((x) => x.shortName);

      return readonlyRules
        .filter((x) => hierarchyNames.includes(x.shortName))
        .at(-1);
    },
    [hierarchyAttributes, readonlyRules]
  );

  // cannot be the final item in the hierarchy, or be in the rules already, or be higher than the last rule if its a hierarchical item
  // suggested improvement: move to a helper function, optimise and test.
  const canAttributeBeUsedWithRule = useCallback(
    (rule: HierarchyGroupRuleWithIdAndName, shortName: string) => {
      // assume its an attribute, or its the first rule added
      let isRuleLaterOrAttribute = true;

      if (editableRule) {
        const wantedShortName = hierarchyAttributes.find(
          (x) => x.shortName === shortName
        );

        // only check if its a hierarchical attribute
        if (
          wantedShortName?.structureName === HierarchyStructureName.Product ||
          wantedShortName?.structureName === HierarchyStructureName.Location
        ) {
          const lastRuleOfSameHierarchyName =
            getLastHierarchicalRuleInGroupRules(
              wantedShortName.structureName
            )?.shortName;
          const lastShortName = hierarchyAttributes.find(
            (x) => x.shortName === lastRuleOfSameHierarchyName
          );

          isRuleLaterOrAttribute =
            wantedShortName.ordinal >= (lastShortName?.ordinal ?? 0);
        }
      }

      const usedRules = readonlyRules.map((x) => x.shortName);

      return (
        shortName === NULL_SHORT_NAME ||
        (shortName !== leafShortName &&
          !usedRules.includes(shortName) &&
          isRuleLaterOrAttribute)
      );
    },
    [
      getLastHierarchicalRuleInGroupRules,
      editableRule,
      hierarchyAttributes,
      leafShortName,
      readonlyRules,
    ]
  );

  const getAllowedRuleNameOptions = useCallback(
    (rule: HierarchyGroupRuleWithIdAndName) =>
      ruleNameOptions.filter((attribute) =>
        canAttributeBeUsedWithRule(rule, attribute.shortName)
      ),
    [canAttributeBeUsedWithRule, ruleNameOptions]
  );

  const handleAddRule = () => {
    setReadOnlyRules(allRules);
    setEditableRule({
      id: uniqueId("rule-"),
      operator: HierarchyGroupRuleOperator.Is,
      shortName: NULL_SHORT_NAME,
      fullShortName: undefined,
      values: [],
    } as HierarchyGroupRuleWithIdAndName);
  };

  const handleReadonlyRuleRemoved = (ruleId: string) => {
    setReadOnlyRules((oldRules) =>
      oldRules.filter((rule) => ruleId !== rule.id)
    );
  };

  const handleEditableRuleRemoved = () => {
    setEditableRule(readonlyRules.at(-1));
    setReadOnlyRules((oldRules) => oldRules.slice(0, -1));
  };

  const handleRuleChange = (updatedRule: HierarchyGroupRuleWithIdAndName) =>
    setEditableRule(updatedRule);

  return (
    <div className={styles.dynamicGroupRulesContainer}>
      {!isRuleNamesUpdated && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {isRuleNamesUpdated &&
        readonlyRules.map((rule) => (
          <DynamicGroupRule
            hierarchyType={hierarchyType}
            key={rule.id}
            onRuleChange={(x) => handleRuleChange(x)}
            onRuleRemoved={() => handleReadonlyRuleRemoved(rule.id)}
            previousGroupRules={readonlyRules}
            readonly
            rule={rule}
            ruleNameOptions={getAllowedRuleNameOptions(rule)}
          />
        ))}
      {isRuleNamesUpdated && editableRule && (
        <DynamicGroupRule
          hierarchyType={hierarchyType}
          key={editableRule.id}
          onRuleChange={(x) => handleRuleChange(x)}
          onRuleRemoved={() => handleEditableRuleRemoved()}
          previousGroupRules={readonlyRules}
          readonly={false}
          rule={editableRule}
          ruleNameOptions={getAllowedRuleNameOptions(editableRule)}
        />
      )}
      <Button
        disabled={isAddButtonDisabled}
        onClick={handleAddRule}
        variant={ButtonVariant.Stealth}
      >
        <Icon glyph={IconGlyph.AddAndPlusAddPlus} text="Add rule" />
        <span>Add rule</span>
      </Button>
    </div>
  );
};
