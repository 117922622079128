import { ddLog } from "@quantium-enterprise/common-ui";
import {
  type InitialTableRequest,
  type InitialTableResponse,
} from "../models/basket-limits-data-table-models";
import { LoadingState } from "../models/basket-limits-parameter-selection-models";
import { basketLimitsApiSlice } from "./basket-limits-api-slice";
import {
  onTableDataReceived,
  setBasketLimitRangeLoadingState,
} from "./basket-limits-slice";

export const basketLimitsDataTableApiSlice =
  basketLimitsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTable: builder.query<
        InitialTableResponse,
        { divisionName: string; payload: InitialTableRequest }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          dispatch(setBasketLimitRangeLoadingState(LoadingState.Loading));
          try {
            const { data } = await queryFulfilled;
            dispatch(onTableDataReceived(data));
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error downloading basket limits focal item data",
              undefined,
              "error",
              error
            );
          }

          dispatch(setBasketLimitRangeLoadingState(LoadingState.Loaded));
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTable/${divisionName}`,
        }),
      }),
    }),
  });

export const { useLazyGetTableQuery } = basketLimitsDataTableApiSlice;
