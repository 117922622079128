import {
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  Icon,
  IconGlyph,
  IconSize,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import styles from "./InformationTooltip.module.css";

export type TooltipContentProps = {
  displayText: string;
};

export const InformationTooltip = ({ displayText }: TooltipContentProps) => (
  <Tooltip
    placement={TooltipPlacement.TopCentre}
    spaceInside={TooltipSpaceInside.Large}
    trigger={
      <Icon
        glyph={IconGlyph.AlertsAndNotificationsInform}
        size={IconSize.Small}
        text=""
      />
    }
    variant={TooltipVariant.ArrowDark}
  >
    <div className={styles.wrappedTooltip}>{displayText}</div>
  </Tooltip>
);
