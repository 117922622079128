import {
  hierarchyServiceApi,
  userApi,
  groupsApi,
  customerGroupsApi,
  reportRerunApi,
  reportParametersWebApi,
  savedParametersApi,
} from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { reportParametersApi } from "./common/services/ReportParametersService";
import wizardReducer from "./common/wizard/wizard-slice";
import reportParametersReducer from "./states/report-wizard-slice";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [reportParametersApi.reducerPath]: reportParametersApi.reducer,
  [hierarchyServiceApi.reducerPath]: hierarchyServiceApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [customerGroupsApi.reducerPath]: customerGroupsApi.reducer,
  [reportParametersWebApi.reducerPath]: reportParametersWebApi.reducer,
  [reportRerunApi.reducerPath]: reportRerunApi.reducer,
  [savedParametersApi.reducerPath]: savedParametersApi.reducer,
  reportParameter: reportParametersReducer,
  wizard: wizardReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const newStore = configureStore({
    devTools: { name: "report-parameters" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        userApi.middleware,
        reportParametersApi.middleware,
        hierarchyServiceApi.middleware,
        groupsApi.middleware,
        customerGroupsApi.middleware,
        reportParametersWebApi.middleware,
        reportRerunApi.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });
  return newStore;
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type ReportWizardStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
