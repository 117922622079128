import {
  HierarchyItemType,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { HierarchySelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getLevelOfAnalysisState } from "../../parameters/hierarchy/LevelOfAnalysisState";
import { useAppSelector } from "../../states/hooks";

export type SummaryPanelLevelOfAnalysisProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelLevelOfAnalysis = ({
  parameterDto,
}: SummaryPanelLevelOfAnalysisProps) => {
  const levelOfAnalysisState = useAppSelector(
    getLevelOfAnalysisState(parameterDto.id)
  );

  if (!levelOfAnalysisState) {
    return null;
  }

  const selections = levelOfAnalysisState.selections;

  const convertedSelections = [
    ...selections
      .map((selection) => ({
        index: selection.index,
        itemCode: selection.value,
        name: selection.label,
        shortName: selection.value,
        type: selection.isHierarchical
          ? HierarchyItemType.Hierarchy
          : HierarchyItemType.Attribute,
      }))
      .sort((a, b) => a.index - b.index),
  ];

  return (
    <HierarchySelectionSummary
      items={convertedSelections}
      maxDepth={5}
      moreItemsText=" more"
    />
  );
};
