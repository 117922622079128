import { type ReactElement } from "react";
import { selectIsOpen } from "../focal-item-search/fastReportingSearchSlice";
import { useAppSelector } from "../hooks";
import HeaderSearchBox from "./HeaderSearchBox";
import { HeaderTab } from "./HeaderTab";

type HeaderSearchBoxProps = {
  placeholder: string;
};

export const SearchbarOrTab = ({ placeholder }: HeaderSearchBoxProps) => {
  const isOpen = useAppSelector(selectIsOpen);

  let displayedComponent: ReactElement;
  if (isOpen) {
    displayedComponent = (
      <HeaderTab text="View performance and customer insights" />
    );
  } else {
    displayedComponent = <HeaderSearchBox placeholder={placeholder} />;
  }

  return displayedComponent;
};
