import {
  type CustomerGroupTypeDto,
  type CustomerGroupWithSharingDto,
  type SegmentDto,
} from "@quantium-enterprise/common-ui";
import {
  CustomerGroupStatus,
  CustomerGroupType,
  GroupType,
  formatStringDate,
} from "@quantium-enterprise/common-ui";
import { useNumberFormat } from "@quantium-enterprise/hooks-ui";
import {
  InlineIcon,
  InlineIconGlyph,
  NavFontWeight,
  Icon,
  IconColour,
  IconGlyph,
  Text,
  Nav,
  NavButton,
  NavVariant,
  Message,
  MessageVariant,
} from "@quantium-enterprise/qds-react";
import { type ColumnDef, type CellContext } from "@tanstack/react-table";
import classNames from "classnames";
import React, { useState } from "react";
import { ParameterGroup } from "report-parameters-ui/src/common/model/ParameterGroup";
import { CustomerGroupStatusTag } from "../../customer-group-status-tag/CustomerGroupStatusTag";
import { CustomerGroupIcon } from "../../icons";
import { getParameterSummary } from "../../info-panel/info-panel-body/InfoPanelBody";
import { BasicTable } from "../../tables/basic-table/BasicTable";
import { InformationModal } from "../InformationModal";
import styles from "./CustomerGroupDetailsModal.module.css";

export type CustomerGroupDetailsModalProps = {
  customerGroupInfo: CustomerGroupWithSharingDto;
  customerGroupTypes: CustomerGroupTypeDto[];
  modalVisbilityState?: {
    isShown: boolean;
    setIsShown: (shown: boolean) => void;
  };
};

export const CustomerGroupDetailsModal = ({
  customerGroupInfo,
  customerGroupTypes,
  modalVisbilityState,
}: CustomerGroupDetailsModalProps) => {
  const { currencyFormatter } = useNumberFormat();
  const [currentNavIndex, setCurrentNavIndex] = useState(0);

  // error message to show when a custome group is invalid
  const errorMessage =
    customerGroupInfo.type === CustomerGroupType.Combined
      ? "One or more of its constituent groups is invalid"
      : "One or more segment has a customer count less than 100";

  const infoData = [
    {
      label: "Created using",
      value: (
        <span className={styles.customerGroupIcon}>
          Template:
          <CustomerGroupIcon
            className={styles.customerGroupIcon}
            type={customerGroupInfo.type}
          />
          {customerGroupTypes.find(
            (type) => type.customerGroupType === customerGroupInfo.type
          )?.displayName ?? ""}
        </span>
      ),
    },
    {
      label: "Updated",
      value: formatStringDate(customerGroupInfo.updateDateUtc as string),
    },
  ];

  // parameter selections
  const parameterSelections = customerGroupInfo.parameters
    .filter(
      (parameter) =>
        // rules parameter selections are shown separatley at the end
        parameter.name !== ParameterGroup.Rules
    )
    .map((parameter) => ({
      label: parameter.name,
      value: (
        <div>
          {parameter.parameterSelections.map((selection) => (
            <React.Fragment key={selection.name}>
              {getParameterSummary(selection)}
            </React.Fragment>
          ))}
        </div>
      ),
    }));

  if (parameterSelections.length) {
    infoData.push(...parameterSelections);
  }

  const customerGroupName = (info: CellContext<SegmentDto, unknown>) =>
    info.row.original.name;
  const loyaltyCustomersCount = (info: CellContext<SegmentDto, unknown>) =>
    info.row.original.count;
  const thresholdValue = (info: CellContext<SegmentDto, unknown>) => {
    if (
      info.row.original.maximumValue !== undefined &&
      info.row.original.minimumValue !== undefined
    ) {
      return `${currencyFormatter(
        info.row.original.minimumValue
      )} - ${currencyFormatter(info.row.original.maximumValue)}`;
    }

    return "-";
  };

  const defaultCgCountColumns: Array<ColumnDef<SegmentDto>> = [
    {
      accessorFn: (row) => row.name,
      cell: customerGroupName,
      header: "SEGMENT",
      id: "customerGroupName",
      size: 460,
    },
    {
      accessorFn: (row) => row.customerGroupId,
      cell: loyaltyCustomersCount,
      header: "LOYALTY CUSTOMERS",
      id: "customersCou",
      size: 120,
    },
    {
      accessorFn: (row) => row.customerGroupId,
      cell: thresholdValue,
      header: "THRESHOLD VALUE",
      id: "customersCount",
      size: 120,
    },
  ];

  const rules = customerGroupInfo.parameters.find(
    (parameter) => parameter.name === ParameterGroup.Rules
  );

  const infoAndSelecionsData = [
    {
      content: (
        <div className={styles.selectionsContainer}>
          {infoData.map((selection) => (
            <div className={styles.selection} key={selection.label}>
              <h6 className={styles.selectionHeader}>{selection.label}</h6>
              <div className="value">{selection.value}</div>
            </div>
          ))}
        </div>
      ),
      id: "selectionsAndInfo",
    },
    {
      label: "Customer group count",
      content: (
        <BasicTable
          className={styles.cgDetailsModalTable}
          columns={defaultCgCountColumns}
          data={customerGroupInfo.segments as SegmentDto[]}
          enableColumnResizing={false}
        />
      ),
      id: "customerGroupCount",
    },
    {
      label: "Rules",
      content: (
        <>
          {rules?.parameterSelections.map((selection) => (
            <div key={selection.id}>{getParameterSummary(selection)}</div>
          ))}
        </>
      ),
      id: "rules",
    },
  ];

  const infoAndSelectionsTabContent = (
    <ul>
      {infoAndSelecionsData.map((data) => (
        <li key={data.id}>
          {data.label && (
            <h6 className={styles.selectionHeader}>{data.label}</h6>
          )}
          <div>{data.content}</div>
        </li>
      ))}
    </ul>
  );

  const cgDetailsTabs = [
    {
      content: infoAndSelectionsTabContent,
      title: "Info & Selections",
    },
    // Activity tab can be added here
    // {
    //   content: "",
    //   title: "Activity",
    // },
  ];

  // modal header
  const header = (
    <div className={styles.headerContainer}>
      <div className={styles.customerGroupHeaderFirstRow}>
        <Icon
          colour={IconColour.Notification}
          glyph={IconGlyph.AccountAndUserAccount}
          text="Customer group"
        />
        <Text>{`${GroupType.Customer} group`}</Text>
      </div>
      <div className={styles.customerGroupHeaderSecondRow}>
        <h4>
          <strong>{customerGroupInfo.name}</strong>
        </h4>
        <CustomerGroupStatusTag
          customerGroupDetailsModal
          status={customerGroupInfo.status}
        />
      </div>
    </div>
  );

  // body content of modal
  const content = (
    <div className="detailsContainer">
      {customerGroupInfo.status !== CustomerGroupStatus.Valid && (
        <Message
          customIcon={
            <InlineIcon
              colour={IconColour.Bad}
              glyph={InlineIconGlyph.AlertsAndNotificationsAlertCircle}
              text="Invalid customer group"
            />
          }
          heading={<h5>Customer Group failed to generate</h5>}
          showIcon
          text={errorMessage}
          variant={MessageVariant.Danger}
        />
      )}
      <Nav
        activeIndex={currentNavIndex}
        className={styles.navigation}
        fontWeight={NavFontWeight.Medium}
        variant={NavVariant.Tab}
      >
        {cgDetailsTabs.map((tab, index) => (
          <NavButton
            className={styles.navButton}
            key={tab.title}
            onClick={() => setCurrentNavIndex(index)}
          >
            {tab.title}
          </NavButton>
        ))}
      </Nav>
      <div
        className={classNames(styles.tabContentContainer, {
          [styles.tabContentContainerError]:
            customerGroupInfo.status !== CustomerGroupStatus.Valid,
        })}
      >
        {cgDetailsTabs[currentNavIndex].content}
      </div>
    </div>
  );

  return (
    <InformationModal
      detailedDescription={content}
      header={header}
      label="CBG"
      modalVisbilityState={modalVisbilityState}
    />
  );
};
