import {
  type HierarchyItem,
  type HierarchyGroupDto,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  HierarchyType,
  HierarchyGroupEvaluationType,
  formatHierarchyName,
  HierarchyGroupRuleOperator,
  GroupsTrackingProperty,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  GenericTrackingProperties,
  ddLog,
  useGetItemsQuery,
  useLazyGetLeafItemsQuery,
  HierarchyItemType,
  formatShortDate,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Group,
  IconGlyph,
  Item,
  ItemValign,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
} from "@quantium-enterprise/qds-react";
import { type Hierarchy } from "components-ui/src/hierarchy-select-grid/models/hierarchy";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  type HierarchyGridItem,
  type HierarchyState,
} from "report-parameters-ui/src/parameters";
import {
  reset,
  selectHierarchySelectedRows,
} from "../../states/group-hierarchy-source-slice";
import {
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { GroupEditor } from "../group-editor/GroupEditor";
import {
  SEARCH_MAX_PAGE_SIZE,
  StaticGroupHierarchySearchTable,
} from "../static-group-hierarchy-search-table/StaticGroupHierarchySearchTable";
import { GroupHierarchyTableWrapper } from "../static-group-hierarchy-table/GroupHierarchyTableWrapper";
import { StaticGroupLeafsTable } from "../static-group-leafs-table/StaticGroupLeafsTable";
import { TwoPanelForm } from "../two-panel-form/TwoPanelForm";
import styles from "./StaticGroupEditor.module.css";

const MAX_ITEMS_IN_GROUP = 10_000;
const MAX_ITEMS_FETCH_REQUEST = MAX_ITEMS_IN_GROUP + 1;

const { Header, BackButton, Title, Content, Footer, SaveButton } = GroupEditor;
const { Heading, Details, Divider, LeftPanel, RightPanel } = TwoPanelForm;

const getGroupDto = (
  name: string,
  hierarchyType: HierarchyType,
  groupItemsState: HierarchyState,
  id?: string
) => ({
  id,
  evaluationType: HierarchyGroupEvaluationType.Static,
  hierarchyType,
  name,
  rules:
    groupItemsState.data.items.length > 0
      ? [
          {
            operator: HierarchyGroupRuleOperator.Is,
            shortName: groupItemsState.data.items[0].shortName,
            values: groupItemsState.data.items.map((item) => item.code),
          },
        ]
      : [],
});

const getDistinctHierarchyGridItems = (items: HierarchyGridItem[]) => {
  const distinctItems: Map<string, HierarchyItem> = new Map<
    string,
    HierarchyItem
  >();

  for (const item of items) {
    if (!distinctItems.has(item.code)) {
      distinctItems.set(item.code, item);
    }
  }

  return Array.from(distinctItems.values());
};

type StaticGroupProps = {
  existingGroup?: HierarchyGroupDto;
  hierarchyType: HierarchyType;
};

export const StaticGroupEditor = ({
  existingGroup,
  hierarchyType,
}: StaticGroupProps) => {
  const { name: divisionName } = useDivision();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const eventTrackingService = useEventTrackingServiceContext();
  const trackingComponent =
    hierarchyType === HierarchyType.Product
      ? TrackingComponent.ProductGroup
      : TrackingComponent.LocationGroup;
  const countTrackingProperty =
    hierarchyType === HierarchyType.Product
      ? GroupsTrackingProperty.ProductCount
      : GroupsTrackingProperty.LocationCount;

  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  const hierarchySelectedRows = useSelector(selectHierarchySelectedRows);
  const [lhsSearchText, setLhsSearchText] = useState("");
  const [rhsSearchText, setRhsSearchText] = useState("");
  const [lhsSearchResultsCount, setLhsSearchResultsCount] = useState<
    number | undefined
  >(undefined);
  const [maxItemsReached, setMaxItemsReached] = useState(false);
  const [groupItemsState, setGroupItemsState] = useState({
    data: {
      items: [],
      type: hierarchyType.toString() as HierarchyType,
      disabledLevelShortNames: [],
    } as Hierarchy,
    expandedRows: [] as HierarchyItem[],
    selectedRows: [] as HierarchyItem[],
  } as HierarchyState);

  const initialGroupItems =
    existingGroup?.rules && existingGroup.rules.length > 0
      ? existingGroup.rules.flatMap((rule) =>
          rule.values.map((code) => ({ code, shortName: rule.shortName }))
        )
      : [];

  const {
    data: itemsData,
    isFetching: isGetItemsQueryFetching,
    isSuccess: isGetItemsQuerySuccess,
  } = useGetItemsQuery(
    {
      division: divisionName,
      hierarchyType: hierarchyType.toString() as HierarchyType,
      payload: {
        items: initialGroupItems,
        page: 0,
        pageSize: MAX_ITEMS_FETCH_REQUEST,
      },
    },
    {
      skip: !divisionName || !initialGroupItems,
    }
  );

  const [
    triggerGetLeafItemsQuery,
    {
      currentData: leafItemsQueryData,
      isFetching: isGetLeafItemsQueryFetching,
      isSuccess: isGetLeafItemsQuerySuccess,
    },
  ] = useLazyGetLeafItemsQuery();

  const saveRequestHandler = useCallback(
    async (request: Promise<HierarchyGroupDto>, successMessage: string) => {
      let isSuccess = false;
      try {
        const groupDto = await request;

        if (!groupDto.id) {
          throw new Error("Created group ID cannot be undefined or empty.");
        }

        isSuccess = true;
        navigate(getGroupListPath(divisionName, hierarchyType, groupDto.id));
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<h5>{successMessage}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />
        );
        dispatch(reset({ hierarchyType }));
      } finally {
        eventTrackingService.trackEvent(
          trackingComponent,
          TrackingEvent.Created,
          new GenericTrackingProperties({
            [countTrackingProperty]: groupItemsState.data.items.length,
            [GroupsTrackingProperty.GroupStatus]: isSuccess
              ? "Success"
              : "Failure",
            [GroupsTrackingProperty.GroupType]:
              HierarchyGroupEvaluationType.Static,
          })
        );
      }
    },
    [
      navigate,
      divisionName,
      hierarchyType,
      dispatch,
      eventTrackingService,
      trackingComponent,
      countTrackingProperty,
      groupItemsState.data.items.length,
    ]
  );

  const handleCreateGroup = useCallback(
    async (name: string) => {
      const group = getGroupDto(name, hierarchyType, groupItemsState);
      const request = createGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been created.");
    },
    [
      createGroup,
      divisionName,
      groupItemsState,
      hierarchyType,
      saveRequestHandler,
    ]
  );

  const handleEditGroup = useCallback(
    async (name: string) => {
      if (!existingGroup) {
        throw new Error("Tried to edit a group that doesn't exist!");
      }

      const group = getGroupDto(
        name,
        hierarchyType,
        groupItemsState,
        existingGroup.id
      );
      const request = updateGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been saved.");
      dispatch(reset({ hierarchyType }));
    },
    [
      dispatch,
      divisionName,
      existingGroup,
      groupItemsState,
      hierarchyType,
      saveRequestHandler,
      updateGroup,
    ]
  );

  const onLhsSearch = useCallback((searchText: string) => {
    setLhsSearchText(searchText);
  }, []);

  const onRhsSearch = useCallback((searchText: string) => {
    setRhsSearchText(searchText);
  }, []);

  const appendItems = useCallback(
    (state: HierarchyState, items: HierarchyItem[]) => {
      const newItems = getDistinctHierarchyGridItems(
        state.data.items.concat(items)
      );

      if (newItems.length > MAX_ITEMS_IN_GROUP) {
        setMaxItemsReached(true);
        return state;
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            items: newItems,
          },
        };
      }
    },
    []
  );

  const handleAddItems = useCallback(() => {
    if (hierarchySelectedRows.some((row) => !row.isLeaf)) {
      triggerGetLeafItemsQuery({
        division: divisionName,
        hierarchyType: hierarchyType.toString() as HierarchyType,
        payload: {
          items: hierarchySelectedRows,
          page: 0,
          pageSize: MAX_ITEMS_FETCH_REQUEST,
        },
        // eslint-disable-next-line @typescript-eslint/no-loop-func
      }).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    } else {
      setGroupItemsState((state) => appendItems(state, hierarchySelectedRows));
    }
  }, [
    appendItems,
    divisionName,
    hierarchySelectedRows,
    hierarchyType,
    triggerGetLeafItemsQuery,
  ]);

  useEffect(() => {
    if (
      leafItemsQueryData &&
      !isGetLeafItemsQueryFetching &&
      isGetLeafItemsQuerySuccess
    ) {
      const selectedItems = leafItemsQueryData.results.map(
        (item) =>
          ({
            code: item.code,
            depth: item.depth,
            isLeaf: true,
            name: item.name,
            ordinal: 0,
            parent: item.parent,
            shortName: item.shortName,
            type: HierarchyItemType.Hierarchy,
          } as HierarchyItem)
      );

      setGroupItemsState((state) => appendItems(state, selectedItems));
    }
  }, [
    isGetLeafItemsQueryFetching,
    leafItemsQueryData,
    isGetLeafItemsQuerySuccess,
    appendItems,
  ]);

  useEffect(() => {
    if (isGetItemsQuerySuccess && !isGetItemsQueryFetching)
      setGroupItemsState((state) => ({
        ...state,
        data: {
          ...state.data,
          items: itemsData.results as HierarchyGridItem[],
        },
      }));
  }, [itemsData, isGetItemsQuerySuccess, isGetItemsQueryFetching]);

  useEffect(() => {
    if (maxItemsReached) {
      eventTrackingService.trackEvent(
        trackingComponent,
        TrackingEvent.GroupsLimitReached
      );

      QbitEmitToast(
        <QbitToastMessage
          content={
            <span>
              You can only select a maximum of {MAX_ITEMS_IN_GROUP / 1_000}k
              products to use in a static group
            </span>
          }
          heading={<h5>Maximum reached</h5>}
          showCloseButton
          showIcon
          variant={MessageVariant.Danger}
        />
      );
    }
  }, [eventTrackingService, maxItemsReached, trackingComponent]);

  const handleRemoveItems = useCallback(() => {
    const newItems: Map<string, HierarchyItem> = new Map<
      string,
      HierarchyItem
    >();

    for (const item of groupItemsState.data.items) {
      if (!newItems.has(item.code)) {
        newItems.set(item.code, item);
      }
    }

    for (const item of groupItemsState.selectedRows) {
      newItems.delete(item.code);
    }

    setGroupItemsState({
      ...groupItemsState,
      data: {
        ...groupItemsState.data,
        items: Array.from(newItems.values()),
      },
      selectedRows: [],
    });
  }, [groupItemsState]);

  const rhsFilteredItems = useMemo(() => {
    const newItems = [...groupItemsState.data.items];

    const searchTokens = rhsSearchText
      .split(",")
      .map((token) => token.toLowerCase().trim());
    return searchTokens.length
      ? newItems.filter((item) =>
          searchTokens.some(
            (token) =>
              item.code.toLowerCase().includes(token) ||
              item.name.toLowerCase().includes(token)
          )
        )
      : newItems;
  }, [rhsSearchText, groupItemsState.data.items]);

  const rhsFilteredItemsData = useMemo(
    () => ({
      ...groupItemsState.data,
      items: rhsFilteredItems,
      isSearchResult: Boolean(rhsSearchText),
    }),
    [rhsSearchText, rhsFilteredItems, groupItemsState.data]
  );

  useEffect(() => {
    if (!lhsSearchText) {
      setLhsSearchResultsCount(undefined);
    }
  }, [lhsSearchText]);

  const dividerButtons = useMemo(
    () => [
      {
        disabled: hierarchySelectedRows.length === 0,
        handleClick: handleAddItems,
        icon: IconGlyph.ArrowsNext,
        text: "Add",
      },
      {
        disabled: groupItemsState.selectedRows.length === 0,
        handleClick: handleRemoveItems,
        icon: IconGlyph.ArrowsBack,
        text: "Remove",
      },
    ],
    [
      groupItemsState.selectedRows.length,
      handleAddItems,
      handleRemoveItems,
      hierarchySelectedRows.length,
    ]
  );

  return (
    <GroupEditor>
      <Header>
        <BackButton
          onClick={() => {
            dispatch(reset({ hierarchyType }));
          }}
          returnPath={
            existingGroup
              ? getGroupListPath(divisionName, hierarchyType, existingGroup.id)
              : getGroupCreatorPath(divisionName, hierarchyType)
          }
          showExitDialog
        />
        <Title
          icon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Static}
              hierarchyType={hierarchyType}
            />
          }
          subtitle="Static"
          title={`Create a ${formatHierarchyName(
            hierarchyType,
            false,
            false
          )} group`}
        />
      </Header>
      <Content>
        <TwoPanelForm>
          <Heading
            subText={`Select ${formatHierarchyName(
              hierarchyType,
              false,
              true
            )} from the ${formatHierarchyName(
              hierarchyType,
              false,
              false
            )} hierarchy. Move your selections to the ‘Selected’ column.`}
            text={`${formatHierarchyName(
              hierarchyType,
              true,
              false
            )} hierarchy`}
          />
          <Details>
            <Group>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>Group type</div>
                <div className={styles.detailContent}>
                  <span className={styles.detailIcon}>
                    <HierarchyGroupIcon
                      evaluationType={HierarchyGroupEvaluationType.Static}
                    />
                  </span>
                  <span>Static</span>
                </div>
              </Item>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>
                  Total {formatHierarchyName(hierarchyType, false, true)}
                </div>
                <div className={styles.detailContent}>
                  {groupItemsState.data.items.length}
                </div>
              </Item>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>Created</div>
                <div className={styles.detailContent}>
                  {existingGroup
                    ? formatShortDate(existingGroup.createDateUtc)
                    : "-"}
                </div>
              </Item>
              <Item valign={ItemValign.Top}>
                <div className={styles.detailHeader}>Updated</div>
                <div className={styles.detailContent}>
                  {existingGroup
                    ? formatShortDate(existingGroup.updateDateUtc)
                    : "-"}
                </div>
              </Item>
            </Group>
          </Details>
          <LeftPanel>
            <div
              className={styles.groupEditorSearch}
              data-testid="lhs-search"
              id={styles.lhsSearch}
            >
              <SearchBox
                debounceTimeMs={500}
                enableDebounce
                maximumCount={SEARCH_MAX_PAGE_SIZE}
                onChange={onLhsSearch}
                placeholder={`Search ${formatHierarchyName(
                  hierarchyType,
                  false,
                  true
                )}`}
                resultCount={lhsSearchResultsCount}
              />
            </div>
            {lhsSearchText && (
              <div className={styles.table}>
                <StaticGroupHierarchySearchTable
                  hierarchyType={hierarchyType}
                  searchtext={lhsSearchText}
                  setSearchResultsCount={setLhsSearchResultsCount}
                />
              </div>
            )}
            {!lhsSearchText && (
              <div className={styles.table}>
                <GroupHierarchyTableWrapper hierarchyType={hierarchyType} />
              </div>
            )}
          </LeftPanel>
          <Divider
            buttons={dividerButtons}
            className={styles.staticGroupDivider}
            showBar
          />
          <RightPanel>
            <div
              className={styles.groupEditorSearch}
              data-testid="rhs-search"
              id={styles.rhsSearch}
            >
              <SearchBox
                debounceTimeMs={500}
                enableDebounce
                onChange={onRhsSearch}
                placeholder={`Search ${formatHierarchyName(
                  hierarchyType,
                  false,
                  true
                )}`}
                resultCount={
                  rhsSearchText === ""
                    ? undefined
                    : rhsFilteredItemsData.items.length
                }
              />
            </div>
            {/* Migrate tests and make sure tests still work */}
            {/* <div data-testid="rhs-table"> */}
            {/* {isGetItemsQueryFetching && (
                <div className={styles.loading}>
                  <Spinner />
                </div>
              )} */}
            <div className={styles.table} data-testid="rhs-table">
              <StaticGroupLeafsTable
                data={rhsFilteredItemsData}
                hierarchyType={formatHierarchyName(hierarchyType, true, false)}
                isLoading={isGetLeafItemsQueryFetching}
                onSelectionsChange={(selectedItems) => {
                  setGroupItemsState({
                    ...groupItemsState,
                    selectedRows: selectedItems,
                  });
                }}
                searchQuery={rhsSearchText}
                selectedItems={groupItemsState.selectedRows}
              />
            </div>
            {/* </div> */}
          </RightPanel>
        </TwoPanelForm>
      </Content>
      <Footer>
        <SaveButton
          disabled={
            !groupItemsState.data.items.length || isGetLeafItemsQueryFetching
          }
          groupTypeIcon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Static}
              hierarchyType={hierarchyType}
            />
          }
          groupTypeName="Static"
          handleCreateGroup={handleCreateGroup}
          handleEditGroup={existingGroup ? handleEditGroup : undefined}
          initialGroupName={existingGroup?.name}
        />
      </Footer>
    </GroupEditor>
  );
};
