import { ddLog } from "@quantium-enterprise/common-ui";
import { type OverTimeChartRequest } from "../models/basket-quantities-over-time-request-dto";
import { type OverTimeChartResponse } from "../models/basket-quantities-over-time-response-dto";
import { basketQuantitiesApiSlice } from "./basket-quantities-api-slice";
import { onOverTimeResponseReceived } from "./basket-quantities-slice";

export const basketQuantitiesOverTimeApiSlice =
  basketQuantitiesApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getBasketQuantitiesOverTimeReportlet: builder.query<
        OverTimeChartResponse,
        {
          divisionName: string;
          payload: OverTimeChartRequest;
        }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: { "Content-type": "application/json; charset=UTF-8" },
          method: "POST",
          url: `/GetOverTimeChartData/${divisionName}`,
        }),

        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data, meta } = await queryFulfilled;

            dispatch(
              onOverTimeResponseReceived(
                meta?.response?.status === 200
                  ? data
                  : { basketSeries: [], dates: [] }
              )
            );
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error retrieving over time chart data for basket quantities report",
              undefined,
              "error",
              error
            );
          }
        },
      }),
    }),
  });

export const { useLazyGetBasketQuantitiesOverTimeReportletQuery } =
  basketQuantitiesOverTimeApiSlice;
