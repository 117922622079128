import {
  type HierarchyGroupDto,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { GroupSelectionSummary } from "components-ui/src/parameter-selection-summary";
import { getGroupParameterState } from "../../parameters/groups/GroupParameterState";
import { useAppSelector } from "../../states/hooks";

const getSelections = (selectedRows: HierarchyGroupDto[]) => {
  const selectionsCopy = selectedRows.slice();
  return selectionsCopy.map((selection) => ({
    groupId: selection.id ?? "",
    groupName: selection.name,
    evaluationType: selection.evaluationType,
    hierarchyType: selection.hierarchyType,
  }));
};

export type SummaryPanelGroupsProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelGroups = ({
  parameterDto,
}: SummaryPanelGroupsProps) => {
  const groupParameterState = useAppSelector(
    getGroupParameterState(parameterDto.id)
  );

  if (!groupParameterState) {
    return null;
  }

  const selectedRows = getSelections(groupParameterState.confirmedSelections);

  return (
    <GroupSelectionSummary
      items={selectedRows}
      maxDepth={2}
      moreItemsText=" more"
    />
  );
};
