import {
  ButtonVariant,
  Checkbox,
  Dropdown,
  DropdownTriggerButton,
  FormBlockEditability,
  Input,
  Tooltip,
  TooltipPlacement,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import classNames from "classnames";
import { useRef } from "react";
import { type MetricsListGroup } from "../models/new-product-benchmarking-metrics-list-dto";
import styles from "./NewProductBenchmakingMultiSelectDropdown.module.scss";

const getButtonSelectionsText = (selectedMetrics: string[]) => {
  let firstSelectedMetric = selectedMetrics[0] ?? "";
  let suffix = "";
  const maxStringLength = 20;
  if (selectedMetrics.length > 1) {
    if (firstSelectedMetric.length > maxStringLength)
      firstSelectedMetric =
        firstSelectedMetric.slice(0, maxStringLength) + "...";
    suffix = ` + ${selectedMetrics.length - 1} more`;
  }

  return firstSelectedMetric + suffix;
};

export const NewProductBenchmarkingMultiSelectDropdown = ({
  metricsList,
  selectedMetrics,
  toggleMetricSelected,
  setDropdownOpen,
}: {
  metricsList: MetricsListGroup[];
  selectedMetrics: string[];
  setDropdownOpen: Function;
  toggleMetricSelected: Function;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdownRef = useRef<any>(null);

  return (
    <Dropdown
      className={styles.multiSelectDropdownContainer}
      id="text-trigger-example"
      // @ts-expect-error: these events work, but are not type valid
      onClick={() => setDropdownOpen(!dropdownRef.current?.state?.isOpen)}
      onKeyDown={() =>
        setDropdownOpen(dropdownRef.current?.state?.isOpen ?? false)
      }
      ref={dropdownRef}
      trigger={
        <DropdownTriggerButton
          text={getButtonSelectionsText(selectedMetrics)}
          variant={ButtonVariant.Secondary}
        />
      }
    >
      <div className={styles.menu}>
        {metricsList.flatMap(({ groupName, metrics }) => (
          <div key={uniqueId()}>
            <div className={styles.metricGroup}>{groupName}</div>
            {metrics.map(({ label, name }) => {
              const isSelected = selectedMetrics.includes(name);
              const isOnlySelected = isSelected && selectedMetrics.length === 1;
              const item = (
                <div key={uniqueId()}>
                  <Input
                    className={classNames(styles.metricItem, {
                      [styles.isSelected]: isSelected,
                      [styles.isOnlySelected]: isOnlySelected,
                    })}
                    onClick={() => toggleMetricSelected(name)}
                  >
                    <div className={styles.selectionIndicator} />

                    <Checkbox
                      checked={selectedMetrics.includes(name)}
                      className={styles.checkbox}
                      editability={
                        isOnlySelected
                          ? FormBlockEditability.Disabled
                          : FormBlockEditability.Editable
                      }
                      key={uniqueId()}
                      label={label}
                      name={name}
                      onChange={() => undefined}
                    />
                  </Input>
                </div>
              );

              return isOnlySelected ? (
                <Tooltip
                  key={uniqueId()}
                  placement={TooltipPlacement.RightCentre}
                  // @ts-expect-error: style prop works but is not defined in type
                  style={{ width: "100%" }}
                  trigger={item}
                  variant={TooltipVariant.ArrowDark}
                >
                  <div className={styles.minSelectionTooltip}>
                    Minimum 1 selection required
                  </div>
                </Tooltip>
              ) : (
                item
              );
            })}
          </div>
        ))}
      </div>
    </Dropdown>
  );
};
