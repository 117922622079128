import { type GainsAndLossReportTableRequestDto } from "../models/GainsAndLossReportTableRequestDto";
import { type GainsAndLossReportTableResponseDto } from "../models/GainsAndLossReportTableResponseDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossDataTableApiSlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getReportDataTable: builder.query<
        GainsAndLossReportTableResponseDto,
        { division: string; payload: GainsAndLossReportTableRequestDto }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetReportTableData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetReportDataTableQuery } = gainsAndLossDataTableApiSlice;
