import {
  HierarchyType,
  type HierarchyItem,
  HierarchyItemType,
  type HierarchyServiceResponseDto,
} from "@quantium-enterprise/common-ui";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type NestedHierarchyItem } from "../components/static-group-hierarchy-table/GroupHierarchyTable";
import { type RootState } from "../store";

export type GroupHierarchySourceState = {
  dataItems: NestedHierarchyItem[];
  expandedRows: HierarchyItem[];
  selectedRows: HierarchyItem[];
  type: HierarchyType;
};

const initialState: GroupHierarchySourceState = {
  dataItems: [],
  expandedRows: [],
  selectedRows: [],
  type: HierarchyType.Product,
};

const toggleNodeExpanding = (
  data: NestedHierarchyItem[],
  code: string,
  shortName: string
): NestedHierarchyItem[] => {
  for (const row of data) {
    if (row.code === code && row.shortName === shortName) {
      row.isExpanding = row.subRows === undefined || row.subRows.length === 0;
      return data;
    }

    if (row.subRows?.length) {
      toggleNodeExpanding(row.subRows, code, shortName);
    }
  }

  return data;
};

const insertChildrenIntoPosition = (
  data: NestedHierarchyItem[],
  subRows: NestedHierarchyItem[],
  parentCode: string,
  parentShortName: string
): NestedHierarchyItem[] => {
  for (const row of data) {
    if (row.code === parentCode && row.shortName === parentShortName) {
      if (row.subRows === undefined || row.subRows.length === 0) {
        row.subRows = subRows;
      } else {
        row.subRows[row.subRows.length - 1].isMoreRow = false;
        row.subRows = [...row.subRows, ...subRows];
      }

      return data;
    }

    if (row.subRows?.length) {
      insertChildrenIntoPosition(
        row.subRows,
        subRows,
        parentCode,
        parentShortName
      );
    }
  }

  return data;
};

export const groupHierarchySourceSlice = createSlice({
  initialState,
  name: "group-hierarchy-source-slice",
  reducers: {
    hierarchyNodeExpanded: (
      state: GroupHierarchySourceState,
      {
        payload: { expandedRows },
      }: PayloadAction<{
        expandedRows: HierarchyItem[];
      }>
    ) => {
      state.expandedRows = expandedRows;
    },

    onHierarchyChildNodesReceived: (
      state: GroupHierarchySourceState,
      {
        payload: { childNodes, leafNodeShortName },
      }: PayloadAction<{
        childNodes: HierarchyServiceResponseDto;
        leafNodeShortName: string;
      }>
    ) => {
      if (childNodes.count > 0 && leafNodeShortName !== "") {
        const subRows = [
          ...childNodes.results.map((node) => ({
            code: node.code,
            depth: node.depth,
            isLeaf: node.shortName === leafNodeShortName,
            name: node.name,
            ordinal: 0,
            parent: node.parent,
            shortName: node.shortName,
            type: HierarchyItemType.Hierarchy,
          })),
        ] as NestedHierarchyItem[];

        if (childNodes.hasNextPage) {
          subRows[subRows.length - 1].isMoreRow = true;
        }

        const parentCode = childNodes.results[0].parent.code;
        const parentShortName = childNodes.results[0].parent.shortName;

        insertChildrenIntoPosition(
          state.dataItems,
          subRows,
          parentCode,
          parentShortName
        );

        toggleNodeExpanding(state.dataItems, parentCode, parentShortName);
      }
    },

    onHierarchyNodeSelection: (
      state: GroupHierarchySourceState,
      { payload }: PayloadAction<HierarchyItem[]>
    ) => {
      const selectedRows = payload;

      // eslint-disable-next-line no-warning-comments
      // todo: if the level selected is above product, all levels under (including products) whether expanded
      //  or not should also be selected (https://quantium.atlassian.net/browse/CO3-2202)
      state.selectedRows = selectedRows;
    },

    onHierarchyRootNodeReceived: (
      state: GroupHierarchySourceState,
      { payload }: PayloadAction<HierarchyServiceResponseDto>
    ) => {
      // FIXME: type and ordinal is not sent through from the HierarchyService at present, contract mismatch
      // Need isLeaf, ordinal and type from Hierarchy Service
      // https://jira.quantium.com.au/browse/CO3-1085
      const rootHierarchyItems: NestedHierarchyItem[] = payload.results.map(
        (item) => ({
          code: item.code,
          depth: item.depth,
          isLeaf: false,
          name: item.name,
          ordinal: 0,
          parent: item.parent,
          shortName: item.shortName,
          type: HierarchyItemType.Hierarchy,
        })
      );

      // Update HierarchyTable with root nodes
      state.dataItems = [...rootHierarchyItems];
      state.expandedRows =
        rootHierarchyItems.length === 1 ? [rootHierarchyItems[0]] : [];
    },

    reset: (
      state: GroupHierarchySourceState,
      {
        payload: { hierarchyType },
      }: PayloadAction<{ hierarchyType: HierarchyType }>
    ) => {
      state.dataItems = [];
      state.type = hierarchyType;
      state.expandedRows = [];
      state.selectedRows = [];
    },

    setHierarchyNodeExpanding: (
      state: GroupHierarchySourceState,
      {
        payload: { nodeCode, nodeShortName },
      }: PayloadAction<{
        nodeCode: string;
        nodeShortName: string;
      }>
    ) => {
      toggleNodeExpanding(state.dataItems, nodeCode, nodeShortName);
    },
  },
});

export const {
  reset,
  onHierarchyRootNodeReceived,
  onHierarchyChildNodesReceived,
  onHierarchyNodeSelection,
  setHierarchyNodeExpanding,
  hierarchyNodeExpanded,
} = groupHierarchySourceSlice.actions;

export default groupHierarchySourceSlice.reducer;

export const selectHierarchyItems = (state: RootState): NestedHierarchyItem[] =>
  state.groupHierarchySource.dataItems;

export const selectHierarchySelectedRows = (
  state: RootState
): HierarchyItem[] => state.groupHierarchySource.selectedRows;

export const selectHierarchyExpandedRows = (
  state: RootState
): HierarchyItem[] => state.groupHierarchySource.expandedRows;
