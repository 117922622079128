import { type SubstitutabilityRequestDto } from "../models/SubstitutabilityRequestDto";
import { type SubstitutabilityResponseDto } from "../models/SubstitutabilityResponseDto";
import { productSubstitutabilityApiSlice } from "./product-substitutability-api-slice";

export const productSubstitutabilityReportletTableDataApiSlice =
  productSubstitutabilityApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getSubstitutabilityReportletTableData: builder.query<
        SubstitutabilityResponseDto,
        {
          division: string;
          payload: SubstitutabilityRequestDto;
          reportId: string;
        }
      >({
        query: ({ division, reportId, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetSubstitutabilityReportletTableData/${division}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetSubstitutabilityReportletTableDataQuery } =
  productSubstitutabilityReportletTableDataApiSlice;
