export const SubDirectoryIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 12.0807L11.25 17.0807L10.0667 15.8974L13.0583 12.9141H3.75V2.91406H5.41667V11.2474H13.0583L10.0667 8.26406L11.25 7.08073L16.25 12.0807Z"
      fill="#C3C3C3"
    />
  </svg>
);
