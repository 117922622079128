import {
  HierarchyType,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { type Zone } from "components-ui/src/drag-and-drop/models/Zone";
import { StructurePreview } from "components-ui/src/structure-preview/StructurePreview";
import { getStructureState } from "../../parameters/hierarchy/StructureState";
import { useAppSelector } from "../../states/hooks";

const getStructureSelections = (droppableZones: Zone[]) =>
  droppableZones
    .filter((zone) => zone.item !== undefined)
    .map((zone) => ({
      count: zone.item?.count,
      // We filter the undefined entries out
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      label: zone.item!.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      shortName: zone.item!.shortName,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type: zone.item!.type,
    }));

export type SummaryPanelStructureProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelStructure = ({
  parameterDto,
}: SummaryPanelStructureProps) => {
  const structureState = useAppSelector(getStructureState(parameterDto.id));
  if (!structureState) {
    return null;
  }

  const structureSelections = getStructureSelections(
    structureState.droppableZones
  );

  return (
    <StructurePreview
      displayTitle={parameterDto.hierarchyType === HierarchyType.Location}
      items={structureSelections}
      maxDepth={5}
      moreItemsText=" more"
    />
  );
};
