import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import {
  DecimalScaleLegend,
  ScaleLegendColors,
} from "components-ui/src/tables/common/legend/ScaleLegend";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { getFocalItemSummary } from "../../../common/utils/export-parameter-summary-utils";
import { type RootState } from "../../../store";
import { ProductSubstitutabilityFeatureFlags } from "../../constants/product-substitutability-feature-flags";
import { type SubstitutabilityRequestDto } from "../../models/SubstitutabilityRequestDto";
import { useLazyGetSubstitutabilityReportletTableDataQuery } from "../../services/product-substitutability-reportlet-api-slice";
import {
  onSubstitutabilityReportletRequest,
  onSubstitutabilityReportletResponseReceived,
  selectFocalItems,
  selectLocation,
  selectTime,
  selectTimePeriodLength,
  selectTopDrawerTableResponseReceived,
} from "../../services/product-substitutability-slice";
import ProductSubstitutabilityModal from "../ProductSubstitutabilityModal/ProductSubstitutabilityModal";
import styles from "./ProductSubstitutabilityReportlet.module.css";
import { SubstitutabilitySlider } from "./SubstitutabilitySlider";
import { SubstitutabilityTable } from "./SubstitutabilityTable";
import { csvTransformation } from "./csvTransformation";

export const ScaleLegendColorsReversedForSubs =
  ScaleLegendColors.slice().reverse();

type ProductSubstitutabilityReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const ProductSubstitutabilityReportlet = ({
  infoPanelSummary,
}: ProductSubstitutabilityReportletProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName, locale, currency } = useDivision();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[ProductSubstitutabilityFeatureFlags.ReportExport] ?? false;

  const focalItems = useSelector(selectFocalItems);
  const location = useSelector(selectLocation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const topDrawerTableResponseReceived = useSelector(
    selectTopDrawerTableResponseReceived
  );

  const {
    isSubstitutabilityReportletLoading,
    metrics,
    substitutabilityData,
    attributes,
    columnVisibility,
    data,
    maxDisplayedSubstitutes,
  } = useSelector((state: RootState) => ({
    isSubstitutabilityReportletLoading:
      state.productSubstitutability.isSubstitutabilityReportletLoading,
    metrics: state.productSubstitutability.substitutabilityTableMetrics,
    substitutabilityData: state.productSubstitutability.substitutabilityData,
    attributes: state.productSubstitutability.attributes,
    columnVisibility: state.productSubstitutability.columnVisibility,
    data: state.productSubstitutability.substitutabilityTableData,
    maxDisplayedSubstitutes:
      state.productSubstitutability.maxDisplayedSubstitutes,
  }));

  const [getTableRows, { isSuccess }] =
    useLazyGetSubstitutabilityReportletTableDataQuery();

  const fetchData = useCallback(
    async (
      division: string,
      id: string,
      payload: SubstitutabilityRequestDto
    ) => {
      await getTableRows({ division, reportId: id, payload })
        .unwrap()
        .then((response) => {
          dispatch(onSubstitutabilityReportletResponseReceived(response));
        })
        .catch((error) => {
          ddLog(
            "Error retrieving product substitutability reportlet data",
            {},
            "error",
            error
          );
          dispatch(
            onSubstitutabilityReportletResponseReceived({
              focalProducts: [],
              metrics: [],
            })
          );
        });
    },
    [dispatch, getTableRows]
  );

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const parametersSummary = useMemo(
    () => [
      { name: "Focal Items", value: getFocalItemSummary(focalItems) },
      { name: "Time", value: time },
      { name: "Location", value: location },
      {
        name: "Substitutes per product",
        value: maxDisplayedSubstitutes.toString(),
      },
    ],
    [focalItems, time, location, maxDisplayedSubstitutes]
  );

  const fileName = useMemo(
    () =>
      cleanFilename(
        `Substitutability_Table_${timePeriodLength}_${location.split(" ")[1]}`
      ),
    [timePeriodLength, location]
  );

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        attributes,
        columnVisibility,
        data,
        metrics,
        currencySymbol
      ),
    [attributes, columnVisibility, data, metrics, currencySymbol]
  );

  useEffect(() => {
    if (reportId && divisionName && topDrawerTableResponseReceived) {
      if (focalItems.length > 0) {
        dispatch(onSubstitutabilityReportletRequest());
        const payload: SubstitutabilityRequestDto = {
          focalItems,
          includeMeta: metrics.length === 0,
        };

        fetchData(divisionName, reportId, payload).catch((error) => {
          ddLog(
            "Error retrieving product substitutability reportlet data",
            {},
            "error",
            error
          );
        });
      } else {
        dispatch(
          onSubstitutabilityReportletResponseReceived({
            focalProducts: [],
            metrics: [],
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    divisionName,
    fetchData,
    focalItems,
    reportId,
    topDrawerTableResponseReceived,
  ]);

  return (
    <ReportletAccordion
      subtitle="Understand the top substitutes for the selected products."
      title="Substitutability"
    >
      <ErrorBoundary>
        <div className={styles.substitutabilityReportletControls}>
          <ProductSubstitutabilityModal />
          <SubstitutabilitySlider />
          <DataTableOptions
            filename={fileName}
            invokeCSVDownload={chartCsvTransformationCallback}
            isFeatureEnabled={isExportEnabled}
            localParameters={parametersSummary}
            reportParameters={infoPanelSummary}
          />
        </div>
        <ErrorBoundary>
          <NoDataChartWrapper
            isLoading={isSubstitutabilityReportletLoading}
            noData={substitutabilityData.length === 0}
          >
            <SubstitutabilityTable isQuerySuccess={isSuccess} />
          </NoDataChartWrapper>
        </ErrorBoundary>
        <DecimalScaleLegend
          colors={ScaleLegendColorsReversedForSubs}
          title="Substitute value:"
        />
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
