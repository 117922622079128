import { type PropsWithChildren } from "react";
import ErrorGraphic from "../assets/error-graphic.svg";
import CheckoutLogo from "../assets/q-checkout-logo.svg";
import styles from "./ErrorLayout.module.css";

export type ErrorLayoutProps = {
  errorCode?: string;
  errorMessage?: string;
};

export const ErrorLayout = ({
  errorCode = "500",
  errorMessage = "Something went wrong, please try again.",
  children,
}: PropsWithChildren<ErrorLayoutProps>) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.logo}>
        <img
          alt={import.meta.env.VITE_TITLE ?? "Q.Checkout"}
          src={CheckoutLogo}
        />
      </div>
      <div className={styles.heading}>
        <span>{errorMessage}</span>
      </div>
      <div className={styles.errorCode}>
        <span>Error code: {errorCode}</span>
      </div>
      <div className={styles.additionalInfo}>{children}</div>
    </div>
    <div className={styles.graphic}>
      <img alt="An error has occurred" src={ErrorGraphic} />
    </div>
  </div>
);
