import EmptySearchImage from "../assets/common/empty-search.svg";
import styles from "./EmptySearch.module.css";

export const EmptySearch = () => (
  <div className={styles.emptySearchSection}>
    <img
      alt="No results found, please try modifying your search term."
      className={styles.emptySearchImage}
      src={EmptySearchImage}
    />
    <p className={styles.textInfo}>
      <span className={styles.title}>No results found</span>
      <br />
      <span className={styles.subTitle}>
        Please try modifying your search term.
      </span>
    </p>
  </div>
);
