import { useWindowSize } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  Icon,
  IconGlyph,
  Text,
} from "@quantium-enterprise/qds-react";
import {
  Modal,
  ScrollBehaviour,
} from "@quantium-enterprise/qds-react/dist/Modal";
import { type PropsWithChildren, useCallback, useRef } from "react";
import { useState } from "react";
import { createPortal } from "react-dom";
import styles from "./ReportTabsDropdown.module.css";

export type ReportTabsDropdownProps = {
  dropdownTabsCount: number;
};

export const ReportTabsDropdown = ({
  children,
  dropdownTabsCount,
}: PropsWithChildren<ReportTabsDropdownProps>) => {
  const dropdownTabWidth = 294;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const reportTabsDropdownTriggerRef = useRef<Element>();

  useWindowSize();

  const positionToTrigger = useCallback(
    (anchor: Element | undefined, width: number) => {
      const position = {} as React.CSSProperties;

      if (
        /* eslint-disable @typescript-eslint/no-unnecessary-condition */
        anchor !== undefined &&
        document.documentElement !== null
        /* eslint-enable @typescript-eslint/no-unnecessary-condition */
      ) {
        const { top, right, bottom, left } = anchor.getBoundingClientRect();

        const { clientWidth: windowWidth, clientHeight: windowHeight } =
          document.documentElement;

        if (left + width < windowWidth) {
          position.left = left;
        } else {
          position.right = windowWidth - right;
        }

        if (bottom < windowHeight / 2) {
          position.top = bottom;
        } else {
          position.bottom = windowHeight - top;
        }
      }

      return position;
    },
    []
  );

  return (
    <div className={styles.reportTabsDropdown}>
      <Button
        height={ButtonHeight.Small}
        onClick={() => setIsOpen(true)}
        pressed={isOpen}
        ref={reportTabsDropdownTriggerRef}
      >
        <Text>{`+ ${dropdownTabsCount.toString()}`}</Text>
        <Icon glyph={IconGlyph.ArrowsChevronDown} text="" />
      </Button>
      {isOpen &&
        createPortal(
          <Modal
            onClose={() => setIsOpen(false)}
            scrollBehaviour={ScrollBehaviour.CloseOnScroll}
            triggeredBy={reportTabsDropdownTriggerRef}
          >
            <div
              className={styles.reportTabsDropdownContent}
              style={positionToTrigger(
                reportTabsDropdownTriggerRef.current,
                dropdownTabWidth
              )}
            >
              {children}
            </div>
          </Modal>,
          document.body
        )}
    </div>
  );
};
