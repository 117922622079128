import {
  ddLog,
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { type RootState } from "../../../store";
import { BasketQuantitiesFeatureFlags } from "../../constants/basket-quantities-feature-flags";
import { type OverTimeChartRequest } from "../../models/basket-quantities-over-time-request-dto";
import { useLazyGetBasketQuantitiesOverTimeReportletQuery } from "../../services/basket-quantities-over-time-slice";
import {
  selectLocalParametersInitialised,
  selectBreakdown,
  selectChannel,
  selectLocationHierarchy,
  selectPromotion,
  selectSegmentation,
  selectFocalItem,
  selectViewAs,
  selectOverTimeBasketSize,
  selectOverTimePrimaryMetric,
  selectOverTimeSecondaryMetric,
  selectTimePeriodLength,
  selectTime,
  selectSegment,
  selectShowBasketQuantitiesOverTimeChartDataLabels,
  toggleBasketQuantitiesOverTimeChartDataLabels,
  selectReportId,
} from "../../services/basket-quantities-slice";
import { getReportletLocalSelections } from "../../utils/getReportletLocalSelections";
import {
  getViewAsMetricLabel,
  getViewAsMetricKey,
  getViewAsShare,
} from "../../utils/getViewAsMetrics";
import { BasketQuantitiesOverTimeChart } from "./BasketQuantitiesOverTimeChart";
import styles from "./BasketQuantitiesOverTimeReportlet.module.scss";
import { BasketQuantitiesOverTimeReportletMetricDropdowns } from "./BasketQuantitiesOverTimeReportletMetricDropdowns";
import { csvTransformation } from "./csvTransformation";

export type BasketQuantitiesOverTimeReportletProps = {
  reportParameters?: ReportParametersDto;
};

export const BasketQuantitiesOverTimeReportlet = ({
  reportParameters,
}: BasketQuantitiesOverTimeReportletProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName, locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const breakdown = useSelector(selectBreakdown);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocationHierarchy);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const focalItem = useSelector(selectFocalItem);
  const viewAs = useSelector(selectViewAs);
  const viewAsShare = getViewAsShare(viewAs.value as string);
  const overTimeBasketSizeSelection = useSelector(selectOverTimeBasketSize);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const showBasketQuantitiesOverTimeChartDataLabels = useSelector(
    selectShowBasketQuantitiesOverTimeChartDataLabels
  );
  const id = useSelector(selectReportId);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketQuantitiesFeatureFlags.ReportExport] ?? false;

  const isDataLoaded =
    localParametersInitialised && Boolean(reportId) && Boolean(focalItem);

  const [
    getBasketQuantitiesOverTime,
    { isFetching: isDataFetching, isUninitialized, data: response },
  ] = useLazyGetBasketQuantitiesOverTimeReportletQuery();

  const fetchBasketQuantitiesOverTime = useCallback(async () => {
    await getBasketQuantitiesOverTime({
      divisionName,
      payload: {
        parameterSelections: getReportletLocalSelections(
          breakdown.value.toString(),
          location,
          focalItem,
          channel.value.toString(),
          promotion.value.toString(),
          segmentation.value.toString(),
          segment.value.toString()
        ),
        reportId,
      } as OverTimeChartRequest,
    });
  }, [
    breakdown.value,
    channel.value,
    divisionName,
    focalItem,
    getBasketQuantitiesOverTime,
    location,
    promotion.value,
    reportId,
    segment.value,
    segmentation.value,
  ]);

  useEffect(() => {
    if (reportId === id && localParametersInitialised) {
      fetchBasketQuantitiesOverTime().catch((error) => {
        ddLog(
          "Error retrieving basket quantities over time data",
          {},
          "error",
          error
        );
      });
    }
  }, [localParametersInitialised, fetchBasketQuantitiesOverTime, reportId, id]);

  const noData =
    response === undefined ||
    response.basketSeries.every((basketSeries) =>
      basketSeries.series.every((metricSeries) =>
        metricSeries.data.every((dataPoint) => !dataPoint.metricValue)
      )
    );
  const primaryMetric = useSelector(selectOverTimePrimaryMetric);
  const primaryMetricLabel = getViewAsMetricLabel(primaryMetric, viewAsShare);
  const primaryMetricKey = getViewAsMetricKey(
    primaryMetricLabel,
    breakdown.value as string,
    segmentation.value as string
  );
  const secondaryMetric = useSelector(selectOverTimeSecondaryMetric);
  const secondaryMetricKey = getViewAsMetricKey(
    secondaryMetric,
    breakdown.value as string,
    segmentation.value as string
  );
  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        response,
        breakdown.value as string,
        overTimeBasketSizeSelection,
        [primaryMetricKey, secondaryMetricKey].filter(Boolean),
        currencySymbol
      ),
    [
      response,
      breakdown,
      overTimeBasketSizeSelection,
      primaryMetricKey,
      secondaryMetricKey,
      currencySymbol,
    ]
  );
  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Basket_Quantities_Over_Time_${timePeriodLength}_${location.name}`
      ),
    [location, timePeriodLength]
  );
  const parameterSummary = useMemo(
    () => [
      { name: "Time", value: time },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
          location.name
        }`,
      },
      { name: "Product", value: focalItem.name },
      { name: "View as", value: viewAs.label },
      { name: "Breakdown", value: breakdown.label },
      { name: "Channel", value: channel.label },
      { name: "Promotion", value: promotion.label },
      { name: "Segmentation", value: segmentation.label },
      { name: "Customer segment", value: segment.label },
      { name: "Basket size", value: overTimeBasketSizeSelection },
    ],
    [
      time,
      focalItem,
      viewAs,
      breakdown,
      channel,
      promotion,
      segmentation,
      segment,
      location,
      overTimeBasketSizeSelection,
    ]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showBasketQuantitiesOverTimeChartDataLabels,
      value: "",
    },
  ];

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.basketQuantities.reportName,
  }));

  const chartSeriesData = useMemo(
    () => response?.basketSeries ?? [],
    [response?.basketSeries]
  );

  return (
    <ReportletAccordion
      subtitle="View the breakdown of total baskets, by basket size, each week."
      title="Basket quantities over time"
    >
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={isUninitialized || isDataFetching}
          noData={isDataLoaded && noData}
        >
          <div className={styles.chartOptionsContainer}>
            <BasketQuantitiesOverTimeReportletMetricDropdowns
              overTimeResponse={response}
            />
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.BasketQuantities} />
                      ),
                      chartTitle: `Basket quantities over time - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
              toggleDataLabels={() => {
                dispatch(toggleBasketQuantitiesOverTimeChartDataLabels());
              }}
            />
          </div>
          <ChartFooterWrapper
            height="400px"
            parameters={parameterSummary}
            ref={chartContainerRef}
          >
            <BasketQuantitiesOverTimeChart
              basketSeries={chartSeriesData}
              basketSize={overTimeBasketSizeSelection}
              breakdown={breakdown.value as string}
              dates={response?.dates ?? []}
              onOptionsChanged={setCurrentOptions}
              primaryMetric={primaryMetricLabel}
              primaryMetricKey={primaryMetricKey}
              secondaryMetric={secondaryMetric}
              secondaryMetricKey={secondaryMetricKey}
            />
          </ChartFooterWrapper>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};
