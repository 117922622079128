import { useCallback, useMemo } from "react";
import { MetricTypes } from "./models/MetricTypes";
import { defaultLocale, defaultCurrency } from "./models/constants";
import { useDivision } from "./use-division";

export type NotationType = "compact" | "standard";
export type NumberStyle = "currency" | "decimal" | "percent";

export type NumberFormatOptions = {
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  notation: NotationType;
  style: NumberStyle;
};

export type ExtraFormatOptions = Omit<
  Intl.NumberFormatOptions,
  "currency" | "notation" | "style"
>;

export type FormatterFunction = (
  value: number | string | null | undefined,
  alwaysDisplaySign?: boolean,
  suffix?: string,
  removeTrailingDecimals?: boolean
) => string;

export type DeferredFormatFunction = (
  metricType: MetricTypes | string
) => FormatterFunction;

export const compactDecimalFormat: NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  notation: "compact",
  style: "decimal",
};

export const compactCurrencyFormat: NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  notation: "compact",
  style: "currency",
};

export const standardIntegerFormat: NumberFormatOptions = {
  maximumFractionDigits: 0,
  notation: "standard",
  style: "decimal",
};

export const compactIntegerFormatLarge: NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  notation: "compact",
  style: "decimal",
};

export const compactIntegerFormatSmall: NumberFormatOptions = {
  maximumFractionDigits: 0,
  notation: "compact",
  style: "decimal",
};

export const standardPercentageFormat: NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  notation: "standard",
  style: "percent",
};

const mapMetricTypeToNumberFormatOptions = (
  metricType: string,
  value: number,
  removeTrailingDecimals: boolean
) => {
  let numberFormatOption: NumberFormatOptions;
  switch (metricType) {
    case MetricTypes.Percentage:
      numberFormatOption = standardPercentageFormat;
      break;
    case MetricTypes.PercentagePoint:
      numberFormatOption = standardPercentageFormat;
      break;
    case MetricTypes.Currency:
      numberFormatOption = compactCurrencyFormat;
      break;
    case MetricTypes.SmallInteger:
      numberFormatOption = standardIntegerFormat;
      break;
    case MetricTypes.Integer:
      numberFormatOption =
        value >= 1_000 || value <= -1_000
          ? compactIntegerFormatLarge
          : compactIntegerFormatSmall;
      break;
    case MetricTypes.Decimal:
      numberFormatOption = compactDecimalFormat;
      break;
    case MetricTypes.None:
      numberFormatOption = compactDecimalFormat;
      break;
    default:
      throw new Error("Invalid number formatter type.");
  }

  return {
    maximumFractionDigits: numberFormatOption.maximumFractionDigits,
    minimumFractionDigits: removeTrailingDecimals
      ? 0
      : numberFormatOption.minimumFractionDigits,
    notation: numberFormatOption.notation,
    style: numberFormatOption.style,
  };
};

const universalNumberFormatter = (
  value: number | string | null | undefined,
  metricType: string,
  alwaysDisplaySign: boolean,
  locale: string,
  currency: string,
  suffix: string,
  removeTrailingDecimals: boolean
) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    Number.isNaN(Number(value))
  )
    return "-";
  return (
    Intl.NumberFormat(locale, {
      ...mapMetricTypeToNumberFormatOptions(
        metricType,
        // @ts-expect-error //value undefined handled by noValue
        value,
        removeTrailingDecimals
      ),
      currency,
      signDisplay: alwaysDisplaySign ? "always" : "auto",
    }).format(Number(value)) + suffix
  );
};

export const getNumberFormat = (
  locale: string = defaultLocale,
  currency: string = defaultCurrency
) => {
  const universalNumberFormatterReduced: DeferredFormatFunction =
    (metricType: MetricTypes | string) =>
    (
      value: number | string | null | undefined,
      alwaysDisplaySign?: boolean,
      suffix?: string,
      removeTrailingDecimals?: boolean
    ) =>
      universalNumberFormatter(
        value,
        metricType,
        alwaysDisplaySign ?? false,
        locale,
        currency,
        suffix ?? "",
        removeTrailingDecimals ?? false
      );

  return {
    currencyFormatter: universalNumberFormatterReduced(MetricTypes.Currency),
    decimalFormatter: universalNumberFormatterReduced(MetricTypes.Decimal),
    getCurrencySymbol: () =>
      Intl.NumberFormat(locale, { currency, style: "currency" })
        .formatToParts(0)
        .find((part) => part.type === "currency")?.value ?? "",
    integerFormatter: universalNumberFormatterReduced(MetricTypes.Integer),
    metricFormatter: (
      metricType: string,
      value: number | string | null | undefined,
      alwaysDisplaySign: boolean = false,
      suffix: string = "",
      removeTrailingDecimals: boolean = false
    ) =>
      universalNumberFormatter(
        value,
        metricType,
        alwaysDisplaySign,
        locale,
        currency,
        metricType === MetricTypes.PercentagePoint ? " pt" : suffix,
        removeTrailingDecimals
      ),
    noneFormatter: universalNumberFormatterReduced(MetricTypes.None),
    percentFormatter: universalNumberFormatterReduced(MetricTypes.Percentage),
  };
};

export const useNumberFormat = () => {
  const { currency: currencyValue, locale: localeValue } = useDivision();

  const currency = currencyValue ? currencyValue : defaultCurrency;
  const locale = localeValue ? localeValue : defaultLocale;

  const numberFormat = useMemo(
    () => getNumberFormat(locale, currency),
    [locale, currency]
  );

  return numberFormat;
};

export const stringFormatter: FormatterFunction = (
  value: number | string | null | undefined
) => {
  switch (typeof value) {
    case "string":
      return value;
    case "number":
      return value.toString();
    default:
      return "-";
  }
};

export const useFormatter: () => DeferredFormatFunction = () => {
  const formatters = useNumberFormat();
  const callback = useCallback(
    (metricFormat: string) => {
      switch (metricFormat) {
        case MetricTypes.Percentage:
          return formatters.percentFormatter;
        case MetricTypes.Currency:
          return formatters.currencyFormatter;
        case MetricTypes.Integer:
          return formatters.integerFormatter;
        case MetricTypes.SmallInteger:
          return formatters.integerFormatter;
        case MetricTypes.Decimal:
          return formatters.decimalFormatter;
        case MetricTypes.None:
          return formatters.noneFormatter;
        case MetricTypes.String:
          return stringFormatter;
        default:
          throw new Error("Invalid format type");
      }
    },
    [formatters]
  );
  return callback;
};
