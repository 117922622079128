import {
  type LocalSegmentationGroupSelection,
  type LocalGroupSelection,
  type LocalHierarchyNodeSelection,
  type LocalHierarchySelection,
  type LocalSegmentationSelection,
  type LocalStringSelection,
  type LocalParameterDto,
} from "@quantium-enterprise/common-ui";
import { SingleSelectType } from "components-ui/src/local-filters/SingleSelectFilter";
import { LocalParameters } from "../../models/LocalParameterId";
import { LocalParameterDisplayTypes } from "../../models/local-parameters/LocalParameterDisplayTypes";
import {
  type SidePanelGroupParameter,
  type SidePanelPlainTextContentParameter,
  type SidePanelParameter,
  type SidePanelTimePeriodParameter,
  type SidePanelSingleSelectionParameter,
  type SidePanelMetricWithFormatParameter,
  type SidePanelMultiSelectionParameter,
  type SidePanelHierarchySelectionParameter,
  type SidePanelSegmentationParameter,
  type SidePanelLocationHierarchyParameter,
  type SidePanelMultiCheckboxParameter,
  type SidePanelSegmentationGroupSelectionParameter,
  type SidePanelSliderParameter,
} from "../../models/local-parameters/SidePanelParameters";

export const isGroupParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelGroupParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.Groups &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isHierarchySelectionParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelHierarchySelectionParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.Hierarchy &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isLocationSelectionParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelLocationHierarchyParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.Hierarchy &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isSegmentationParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelSegmentationParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.Segmentation &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isSegmentationGroupParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelSegmentationGroupSelectionParameter =>
  localParameter.id === LocalParameters.Segmentation &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isSingleSelectionParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelSingleSelectionParameter =>
  Object.values(SingleSelectType).includes(localParameter.displayType) &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isMetricWithFormatParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelMetricWithFormatParameter =>
  Object.values(SingleSelectType).includes(localParameter.displayType) &&
  (localParameter.id === LocalParameters.Metrics ||
    localParameter.id === LocalParameters.Metric) &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isMultiSelectionParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelMultiSelectionParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.MultiSelect &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections") &&
  Object.prototype.hasOwnProperty.call(localParameter, "maxSelections");

export const isMultiCheckboxParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelMultiCheckboxParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.MultiCheckbox &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");

export const isTimePeriodParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelTimePeriodParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.TimePeriod &&
  Object.prototype.hasOwnProperty.call(localParameter, "focusPeriod");

export const isPlainTextContentParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelPlainTextContentParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.PlainTextContent &&
  Object.prototype.hasOwnProperty.call(localParameter, "plainTextContent");

export const isMinMaxRangeParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelSliderParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.MinMaxRange &&
  Object.prototype.hasOwnProperty.call(
    localParameter,
    "minimumPossibleValue"
  ) &&
  Object.prototype.hasOwnProperty.call(localParameter, "maximumPossibleValue");

export const isLocalHierarchySelection = (
  localHierarchySelection:
    | LocalGroupSelection
    | LocalHierarchyNodeSelection
    | LocalHierarchySelection
    | LocalSegmentationSelection
    | LocalStringSelection
): localHierarchySelection is LocalHierarchySelection =>
  Object.prototype.hasOwnProperty.call(localHierarchySelection, "isDefault") &&
  Object.prototype.hasOwnProperty.call(localHierarchySelection, "children") &&
  Object.prototype.hasOwnProperty.call(
    localHierarchySelection,
    "hierarchyItem"
  ) &&
  Object.prototype.hasOwnProperty.call(localHierarchySelection, "parents");

export const isLocalStringSelection = (
  localStringSelection:
    | LocalGroupSelection
    | LocalHierarchyNodeSelection
    | LocalHierarchySelection
    | LocalSegmentationSelection
    | LocalStringSelection
): localStringSelection is LocalStringSelection =>
  Object.prototype.hasOwnProperty.call(localStringSelection, "isDefault") &&
  Object.prototype.hasOwnProperty.call(localStringSelection, "label") &&
  Object.prototype.hasOwnProperty.call(localStringSelection, "value");

export const isLocalSegmentationSelection = (
  localSegmentationSelection:
    | LocalGroupSelection
    | LocalHierarchyNodeSelection
    | LocalHierarchySelection
    | LocalSegmentationGroupSelection
    | LocalSegmentationSelection
    | LocalStringSelection
): localSegmentationSelection is LocalSegmentationGroupSelection =>
  Object.prototype.hasOwnProperty.call(
    localSegmentationSelection,
    "segmentationType"
  );

export const isMultiTypeParameter = (
  localParameter: LocalParameterDto | SidePanelParameter
): localParameter is SidePanelMultiSelectionParameter =>
  localParameter.displayType === LocalParameterDisplayTypes.MultiType &&
  Object.prototype.hasOwnProperty.call(localParameter, "selections");
