import { type RefObject, useEffect } from "react";

export const useExternalClickListener = (
  targetRef: RefObject<Element>,
  outerMostContainer: Element | RefObject<Element>,
  callback: (target: Element) => void
) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      /* eslint-disable no-eq-null,eqeqeq */
      if (
        targetRef.current == null ||
        event.target == null ||
        !(event.target instanceof Element)
      ) {
        return;
      }
      /* eslint-enable no-eq-null,eqeqeq */

      const outer =
        outerMostContainer instanceof Element
          ? outerMostContainer
          : outerMostContainer.current;

      if (
        outer?.contains(event.target) &&
        !targetRef.current.contains(event.target)
      ) {
        callback(event.target);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [targetRef, outerMostContainer, callback]);
};
