import {
  FormBlock,
  FormBlockEditability,
  FormBlockType,
  Input,
  RadioButton,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { type PanelOption } from "../local-parameters-panel/FixedSidePanel";
import styles from "./CollapsiblePanel.module.scss";

export type SingleSelectRadioProps = {
  isDisabled?: boolean;
  onSelection?: (value: PanelOption) => unknown;
  selectOptions: PanelOption[];
  selectedValue?: string;
  title?: string;
};
const defaultOnSelection = () => {};
export const SingleSelectRadio = ({
  isDisabled,
  onSelection = defaultOnSelection,
  selectOptions,
  selectedValue,
  title,
}: SingleSelectRadioProps) => (
  <div>
    {selectOptions.map((option) => (
      <FormBlock blockType={FormBlockType.Radio} key={`radio-${option.value}`}>
        <Input>
          <RadioButton
            checked={selectedValue?.toString() === option.value.toString()}
            className={styles.radioButton}
            editability={
              isDisabled || option.optionDisabled
                ? FormBlockEditability.Disabled
                : FormBlockEditability.Editable
            }
            id={`radiobutton-${option.value}-${uniqueId()}`}
            key={option.value}
            label={
              option.labelElement ?? (option.label || option.value.toString())
            }
            name={`${title}-radio-form`}
            onChange={() => {
              onSelection(option);
            }}
          />
        </Input>
      </FormBlock>
    ))}
  </div>
);

export default SingleSelectRadio;
