/*
 * Documented Rules: https://quantium.atlassian.net/wiki/spaces/RP/pages/12101569/Q.CO3K+Entitlements+Permissions+Model
 */
import { HierarchyType, TransactionSource } from "../enums";
import { type TransactionSourceAccess } from "../models";

type TransactionSourceConfig = {
  id: TransactionSource;
  idOverwrite?: TransactionSource;
  preferenceOrder: number;
};

type HierarchyItem = {
  shortName: string;
  transactionSourceAccess?: TransactionSourceAccess;
};

export const getPreferredTransactionSource = (
  configs: TransactionSourceConfig[] | undefined,
  filters?: TransactionSource[]
): TransactionSource[] | null => {
  if (!configs) {
    return null;
  }

  const preferredSources = configs
    .slice()
    .filter((source) => !filters || filters.includes(source.id))
    .sort((a, b) => a.preferenceOrder - b.preferenceOrder);

  if (preferredSources.length === 0) {
    return null;
  }

  // Check if all items have same preferenceOrder defined
  const allWithEqualPreferenceOrder = preferredSources.every(
    (source) => source.preferenceOrder === preferredSources[0].preferenceOrder
  );

  if (allWithEqualPreferenceOrder) {
    // If all items have equal preferenceOrder, return the whole array
    return preferredSources.map((source) => source.idOverwrite ?? source.id);
  } else {
    // If there is a unique preferenceOrder, return only the first item
    return [preferredSources[0].idOverwrite ?? preferredSources[0].id];
  }
};

export const getHierarchyItemTransactionSources = (
  item: HierarchyItem
): TransactionSource[] => {
  if (!item.transactionSourceAccess) {
    return [];
  }

  return item.transactionSourceAccess[item.shortName] ?? [];
};

const getHierarchyLevelTransactionSources = (
  levelShortName: string,
  selectedItems: HierarchyItem[]
): TransactionSource[] => {
  if (selectedItems.length === 0) {
    return [];
  }

  let levelSources = Object.values(TransactionSource);
  for (const item of selectedItems) {
    if (!item.transactionSourceAccess) {
      return [];
    }

    const itemSources = item.transactionSourceAccess[levelShortName];
    if (!itemSources) {
      return [];
    }

    levelSources = levelSources.filter((source) =>
      itemSources.includes(source)
    );
  }

  return levelSources;
};

export const getStructureLevelTransactionSources = (
  levelShortName: string,
  selectedItems: HierarchyItem[]
): TransactionSource[] => {
  const levelItems = selectedItems.filter(
    (item) => item.shortName === levelShortName
  );

  return getHierarchyLevelTransactionSources(levelShortName, levelItems);
};

export const getAttributeLevelTransactionSources = (
  hierarchyType: HierarchyType,
  leafShortName: string,
  selectedItems: HierarchyItem[]
): TransactionSource[] => {
  if (hierarchyType === HierarchyType.Location) {
    return Object.values(TransactionSource);
  }

  return getHierarchyLevelTransactionSources(leafShortName, selectedItems);
};

export const getSelectedHierarchyTransactionSources = (
  selectedItems: HierarchyItem[],
  aggregateLevels: string[],
  transactionSourceAccessShortNameOverride?: string
): TransactionSource[] => {
  let reportSources = Object.values(TransactionSource);

  for (const item of selectedItems) {
    if (!item.transactionSourceAccess) {
      return [];
    }

    const itemSources =
      item.transactionSourceAccess[
        transactionSourceAccessShortNameOverride
          ? transactionSourceAccessShortNameOverride
          : item.shortName
      ];

    if (!itemSources) {
      return [];
    }

    reportSources = reportSources.filter((source) =>
      itemSources.includes(source)
    );

    for (const level of aggregateLevels) {
      const levelSources = item.transactionSourceAccess[level];
      if (!levelSources) {
        continue;
      }

      reportSources = reportSources.filter((source) =>
        levelSources.includes(source)
      );
    }
  }

  return reportSources;
};

export const getTransactionSourceFromEntitlements = (
  entitlements: string[]
): TransactionSource | undefined => {
  if (entitlements.includes(TransactionSource.Total)) {
    return TransactionSource.Total;
  } else if (entitlements.includes(TransactionSource.Sample)) {
    return TransactionSource.Sample;
  } else if (entitlements.includes(TransactionSource.Customer)) {
    return TransactionSource.Customer;
  }

  return undefined;
};
