import { type PurchasedWithProduct } from "../models/basket-affinities-chart-models";

export const getMaxUplift = (purchasedWithProducts: PurchasedWithProduct[]) => {
  if (purchasedWithProducts.length === 0) {
    return 0;
  }

  // eslint-disable-next-line unicorn/no-array-reduce -- reduce is the best way to find the maximum value of all the products
  return purchasedWithProducts.reduce(
    (maxUplift, product) =>
      product.uplift > maxUplift ? product.uplift : maxUplift,
    purchasedWithProducts[0].uplift
  );
};
