import { userApi } from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { adminApi } from "./services/admin-service";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "admin" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(userApi.middleware, adminApi.middleware),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
