import {
  type TrackingComponent,
  type TrackingEvent,
  GenericTrackingProperties,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
  FeatureFlag,
  ddLog,
  ReportType,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { type RootState } from "../store";
import { GainsAndLossOverviewReportlet } from "./components/GainsAndLossOverviewReportlet";
import { GainsAndLossSidePanel } from "./components/GainsAndLossSidePanel";
import { GainsAndLossSwitchingBreakdownReportlet } from "./components/GainsAndLossSwitchingBreakdown/GainsAndLossSwitchingBreakdownReportlet";
import { GainsAndLossTopDrawer } from "./components/GainsAndLossTopDrawer";
import { GainsAndLossUniverseSummaryReportlet } from "./components/GainsAndLossUniverseSummary/GainsAndLossUniverseSummaryReportlet";
import { useLazyGetReportQuery } from "./services/gains-and-loss-local-parameters-api-slice";
import {
  onReportError,
  onReportSuccess,
  onReportOpen,
  selectLocalParametersInitialised,
  reset,
} from "./services/gains-and-loss-slice";

export const GainsAndLossReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTabsEnabled]);

  // InfoPanel
  const { data: reportParameters, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: !activeDivisionName || !id }
    );

  // Store
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.gainsAndLoss.reportName,
  }));

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Gains and loss",
        reportId: id,
        ...properties,
      })
    );

  // Local Parameters Query
  const [getReportQuery, { data: report }] = useLazyGetReportQuery();

  const fetchReport = useCallback(
    async (division: string, reportId: string) => {
      await getReportQuery({ divisionName: division, reportId })
        .unwrap()
        .then((response) => {
          dispatch(onReportSuccess(response));
        });
    },
    [dispatch, getReportQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      fetchReport(activeDivisionName, id).catch((error) => {
        ddLog(
          "Error retrieving gains and loss local parameters",
          {},
          "error",
          error
        );
        dispatch(onReportError());
      });
    }
  }, [
    activeDivisionName,
    dispatch,
    fetchReport,
    id,
    localParametersInitialised,
  ]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <GainsAndLossTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportTabItems={
              report?.levelsOfAnalysis
                ? report.levelsOfAnalysis.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))
                : []
            }
            reportType={ReportType.GainsAndLoss}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <GainsAndLossSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <GainsAndLossOverviewReportlet reportParameters={reportParameters} />
          <GainsAndLossSwitchingBreakdownReportlet
            reportParameters={reportParameters}
          />
          <GainsAndLossUniverseSummaryReportlet
            reportParameters={reportParameters}
          />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={reportParameters}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <GainsAndLossReport />,
  path: "gains-and-loss/:id",
};

export default GainsAndLossReport;
