import { HierarchyItemType, ddLog } from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { type ReportTabItemType } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabItem } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabs } from "../../common/components/ReportTabComponents/ReportTabs";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { CustomerLoyaltyFeatureFlags } from "../constants/customer-loyalty-feature-flags";
import { type TableRowsRequestDto } from "../models/CustomerLoyaltyTableRowsRequestDto";
import { LevelOfAnalysis } from "../models/LevelOfAnalysisIds";
import { useLazyGetTableRowsQuery } from "../services/customer-loyalty-data-table-api-slice";
import { useLazyDownloadTableQuery } from "../services/customer-loyalty-export-api-slice";
import {
  onTopDrawerTableResponseReceived,
  selectFocalItemCount,
  selectFocalItems,
  selectLocalParametersInitialised,
  selectLocalSelections,
  selectReportId,
  selectSearchQuery,
  selectSegment,
  selectSegmentation,
  selectTopDrawerActiveTab,
  setTopDrawerActiveTab,
} from "../services/customer-loyalty-slice";
import { CustomerLoyaltyTopDrawerProductTable } from "./CustomerLoyaltyTopDrawerProductTable/CustomerLoyaltyTopDrawerProductTable";
import { CustomerLoyaltyTopDrawerProductToSegmentTable } from "./CustomerLoyaltyTopDrawerProductTable/CustomerLoyaltyTopDrawerProductToSegmentTable";
import { getTableRequestDto } from "./CustomerLoyaltyTopDrawerProductTable/utils";

export type CustomerLoyaltyTopDrawerProps = {
  renameReport: (newItemName: string, itemId: string) => Promise<void>;
  reportName: string;
  reportTabItems: ReportTabItemType[];
  reportType: string;
};

export const CustomerLoyaltyTopDrawer = ({
  renameReport,
  reportType,
  reportName,
  reportTabItems,
}: CustomerLoyaltyTopDrawerProps) => {
  const dispatch = useDispatch();
  const { id: currentReportId } = useParams();
  const { name: divisionName } = useDivision();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CustomerLoyaltyFeatureFlags.ReportExport] ?? false;

  const reportId = useSelector(selectReportId);
  const activeTab = useSelector(selectTopDrawerActiveTab);
  const focalItemCount = useSelector(selectFocalItemCount);
  const searchQuery = useSelector(selectSearchQuery);
  const focalItems = useSelector(selectFocalItems);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const localSelections = useSelector(selectLocalSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const handleTabClick = useCallback(
    (value: string) => {
      dispatch(setTopDrawerActiveTab(value));
    },
    [dispatch]
  );

  const [getTableRows, { isFetching: isTableFetching }] =
    useLazyGetTableRowsQuery();

  const fetchData = useCallback(
    async (
      division: string,
      payload: TableRowsRequestDto,
      segmentValue: string
    ) => {
      await getTableRows({ division, payload })
        .unwrap()
        .then((response) => {
          dispatch(onTopDrawerTableResponseReceived(response));
        })
        .catch((error) => {
          dispatch(
            onTopDrawerTableResponseReceived({
              levelOfAnalysis: activeTab ?? "",
              pagination: {
                hasNextPage: false,
                hasPreviousPage: false,
                pageIndex: 0,
                pageSize: 0,
                totalCount: 0,
                totalPages: 0,
              },
              segmentValue,
              tableRows: [],
            })
          );

          ddLog(
            "Error retrieving customer loyalty table data",
            {},
            "error",
            error
          );
        });
    },
    [activeTab, dispatch, getTableRows]
  );

  useEffect(() => {
    if (currentReportId !== reportId || !localParametersInitialised) return;
    if (currentReportId && activeTab) {
      const payload = getTableRequestDto(
        activeTab,
        [segmentation.value as string, segment.value as string],
        localSelections.locationHierarchy,
        0,
        currentReportId,
        searchQuery
      );

      void fetchData(divisionName, payload, segment.value as string);
    }
  }, [
    currentReportId,
    divisionName,
    activeTab,
    searchQuery,
    segmentation,
    segment,
    fetchData,
    localSelections.locationHierarchy,
    reportId,
    localParametersInitialised,
  ]);

  const [downloadTableTrigger] = useLazyDownloadTableQuery();

  const fileName = useMemo(
    () =>
      cleanFilename(
        `Customer_Loyalty_${localSelections.timePeriod}_${localSelections.locationHierarchy.name}`
      ),
    [localSelections]
  );

  const levelOfAnalysis = useMemo(
    () => reportTabItems.find((item) => item.value === activeTab),
    [reportTabItems, activeTab]
  );

  const parameterSummary = useMemo(
    () => [
      { name: "Level of analysis", value: levelOfAnalysis?.label ?? "" },
      { name: "Time", value: localSelections.time },
      { name: "Segmentation", value: segmentation.label },
      { name: "Customer segment", value: segment.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localSelections.locationHierarchy.shortName
        )}) ${localSelections.locationHierarchy.name}`,
      },
    ],

    [localSelections, segmentation, segment, levelOfAnalysis]
  );

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        levelOfAnalysis: activeTab ?? "",
        localParameters: parameterSummary,
        localParameterSelections: [
          {
            id: LocalParameters.Segmentation,
            values: [segmentation.value as string, segment.value as string],
          },
          {
            id: LocalParameters.LocationHierarchy,
            values: [localSelections.locationHierarchy.nodeNumber.toString()],
          },
        ],
        fileName,
        reportId: currentReportId ?? "",
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    activeTab,
    parameterSummary,
    segmentation.value,
    segment.value,
    localSelections.locationHierarchy.nodeNumber,
    fileName,
    currentReportId,
  ]);

  return (
    <ReportTopDrawer
      controls={[
        <div key="data-table-options">
          <DataTableOptions
            filename={fileName}
            invokeCSVDownload={handleDownloadButtonClick}
            isFeatureEnabled={isExportEnabled}
            key={currentReportId}
          />
        </div>,
      ]}
      itemCount={focalItemCount}
      items={focalItems.map((item) => ({
        code: item.shortName,
        displayName: item.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={currentReportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportTabs activeTab={activeTab ?? ""} onClickTabItem={handleTabClick}>
          {reportTabItems.map((item) => (
            <ReportTabItem
              key={item.value}
              label={item.label}
              value={item.value}
            >
              {activeTab === LevelOfAnalysis.ProductToSegment ? (
                <CustomerLoyaltyTopDrawerProductToSegmentTable
                  isQuerySuccess={!isTableFetching}
                />
              ) : (
                <CustomerLoyaltyTopDrawerProductTable
                  isQuerySuccess={!isTableFetching}
                />
              )}
            </ReportTabItem>
          ))}
        </ReportTabs>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};
