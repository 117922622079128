import {
  type SidePanelSingleSelectionParameter,
  type SidePanelSegmentationParameter,
} from "../../common/models/local-parameters/SidePanelParameters";

export const disableNonDefaultSegments = (
  parameter: SidePanelSegmentationParameter
): SidePanelSegmentationParameter => {
  // The `All` segment for all the segmentations should not be disabled when changing to breakdown by segmentation
  const nondisabledSegment = "All";

  const disabledSelections = parameter.selections.map((selection) => {
    if (!selection.isDefault && selection.segmentValue !== nondisabledSegment)
      return { ...selection, optionDisabled: true };
    return { ...selection };
  });

  return { ...parameter, selections: disabledSelections };
};

export const disableNonDefaultPromo = (
  parameter: SidePanelSingleSelectionParameter
): SidePanelSingleSelectionParameter => {
  const disabledSelections = parameter.selections.map((selection) => {
    if (!selection.isDefault) return { ...selection, optionDisabled: true };
    return { ...selection };
  });

  return { ...parameter, selections: disabledSelections };
};
