import { Spinner, SpinnerSize } from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./CustomerGroupSegments.module.css";

export type InProgressSegmentCardProps = {
  onClick: () => void;
};

export const InProgressSegmentCard = ({
  onClick,
}: InProgressSegmentCardProps) => (
  <div className={classNames(styles.cardContainer, styles.inProgressContainer)}>
    <Spinner size={SpinnerSize.Medium} />
    <div className={styles.inProgressContent}>
      <div className={styles.cardHeader}>Validation in progress</div>
      <Link onClick={() => onClick()} to="">
        <div className={styles.linkText}>Learn more</div>
      </Link>
    </div>
  </div>
);
