import { formatNumberDate } from "@quantium-enterprise/common-ui";
import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { type ColumnDef } from "@tanstack/react-table";
import classNames from "classnames";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import styles from "../components/PurchaseActivityDataTable.module.css";

export type PurchaseActivityTableData = {
  data: Array<number | null>;
  format: string;
  header: string;
  isSeparatorRow?: boolean;
};

type PurchaseActivityTableCell = {
  format: string;
  header: string;
  isSeparatorRow?: boolean;
  value: number | null;
};

export const generateDataTableColumns = (
  dates: string[],
  formatter: DeferredFormatFunction
): Array<ColumnDef<PurchaseActivityTableData>> => [
  {
    accessorFn: (row) => ({
      header: row.header,
      isSeparatorRow: row.isSeparatorRow,
    }),
    cell: ({ getValue }) => (
      <div
        className={classNames({
          [styles.totalRow]:
            getValue<PurchaseActivityTableCell>().header === "Total",
          [styles.separatorLine]:
            getValue<PurchaseActivityTableCell>().isSeparatorRow,
        })}
      >
        {getValue<PurchaseActivityTableCell>().header}
      </div>
    ),
    footer: (properties) => properties.column.id,
    header: "",
    id: uniqueId(),
  },
  ...dates.map(
    (date, index): ColumnDef<PurchaseActivityTableData> => ({
      accessorFn: (row) => ({
        value: row.data[index],
        format: row.format,
        header: row.header,
        isSeparatorRow: row.isSeparatorRow,
      }),
      cell: ({ getValue }) =>
        ValueCell({
          formatter: formatter(getValue<PurchaseActivityTableCell>().format),
          value: getValue<PurchaseActivityTableCell>().value,
          className: classNames({
            [styles.totalRow]:
              getValue<PurchaseActivityTableCell>().header === "Total",
            [styles.separatorLine]:
              getValue<PurchaseActivityTableCell>().isSeparatorRow,
          }),
        }),
      header: () => <span>{formatNumberDate(Number.parseInt(date, 10))}</span>,
      id: date,
    })
  ),
];
