import {
  type ReportParametersDto,
  GenericTrackingProperties,
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  ddLog,
  ReportType,
} from "@quantium-enterprise/common-ui";
import {
  useFlags,
  type DeferredFormatFunction,
} from "@quantium-enterprise/hooks-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import { Text } from "@quantium-enterprise/qds-react";
import {
  type ColumnDef,
  type CellContext,
  type ColumnResizeMode,
  createColumnHelper,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { getHierarchyValueId } from "../../basket-quantities/utils/getHierarchyValueId";
import { EXPORT_LOCATION_SUFFIX } from "../../common/constants";
import { getExportParameterSummary } from "../../common/utils/export-parameter-summary-utils";
import { BasketlimitsFeatureFlags } from "../constants/basket-limits-feature-flags";
import {
  type MetricMetadata,
  type TableMetadata,
  type TableRow,
} from "../models/basket-limits-data-table-models";
import { useLazyGetTableQuery } from "../services/basket-limits-data-table-api-slice";
import {
  onFocalItemChange,
  onSearchQueryChange,
  selectFocalItem,
  selectFocalItemTableMetadata,
  selectFocalItemTableRows,
  selectFocalItemTableRowsSearch,
  selectFocalItemTableSelectionState,
  selectLocalParametersInitialised,
  selectLocalParametersSelection,
  selectLocation,
  selectReportName,
  selectSearchQuery,
  selectTimePeriodLength,
} from "../services/basket-limits-slice";
import styles from "./BasketLimitsTopDrawer.module.css";
import { csvTransformation } from "./topDrawerCsvTransformation";

const columnResizeMode: ColumnResizeMode = "onChange";

const getRowId = (row: TableRow) => getHierarchyValueId(row.product);

const columnHelper = createColumnHelper<TableRow>();

// Export to enable testing
export const measuresColumnDefs = (
  formatter: DeferredFormatFunction,
  metadata?: TableMetadata
): Array<ColumnDef<TableRow, number>> => {
  if (!metadata) {
    return [];
  }

  const secondHeader = (metric: MetricMetadata) => (
    <div className={styles.secondHeader}>
      <Text>{metric.metricLabel}</Text>
    </div>
  );

  return metadata.metricMetadata.map((metric, index) =>
    columnHelper.accessor((row: TableRow) => row.metrics[index].metricValue, {
      cell: ({ getValue }: CellContext<TableRow, number>) =>
        ValueCell({
          formatter: formatter(metric.format),
          value: getValue(),
        }),
      header: () => secondHeader(metric),
      id: metric.metricLabel,
    })
  );
};

export const BasketLimitsTopDrawer = ({
  renameReport,
  reportParameters,
}: {
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportParameters?: ReportParametersDto;
}) => {
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketlimitsFeatureFlags.ReportExport] ?? false;

  const reportName = useSelector(selectReportName);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const localParametersSelection = useSelector(selectLocalParametersSelection);
  const focalItem = useSelector(selectFocalItem);
  const focalItemTableSelectionState = useSelector(
    selectFocalItemTableSelectionState
  );
  const location = useSelector(selectLocation);
  const searchQuery = useSelector(selectSearchQuery);
  const tableMetadata = useSelector(selectFocalItemTableMetadata);
  const tableRows = useSelector(selectFocalItemTableRows);
  const tableRowsSearch = useSelector(selectFocalItemTableRowsSearch);
  const timePeriodLength = useSelector(selectTimePeriodLength);

  const eventTrackingService = useEventTrackingServiceContext();

  const focalItemSummary = useMemo(
    () =>
      focalItem.itemCode
        ? [
            {
              code: focalItem.shortName,
              displayName: focalItem.name,
              // TODO: temp until the backend can return this
              type: HierarchyItemType.Attribute,
            },
          ]
        : [],
    [focalItem]
  );

  const onSearchChange = useCallback(
    (value: string) => {
      dispatch(onSearchQueryChange(value));
    },
    [dispatch]
  );

  const [getTableQuery, { isFetching: isTableFetching, isSuccess }] =
    useLazyGetTableQuery();

  const fetchTableQuery = useCallback(async () => {
    await getTableQuery({
      divisionName,
      payload: {
        reportId: reportId ?? "",
      },
    });
  }, [divisionName, getTableQuery, reportId]);

  useEffect(() => {
    if (localParametersInitialised) {
      fetchTableQuery().catch((error) => {
        ddLog(
          "Error retrieving basket limits summary focal item table data",
          {},
          "error",
          error
        );
      });
    }
  }, [fetchTableQuery, localParametersInitialised]);

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return tableRowsSearch.length;
  }, [tableRowsSearch, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchBox}>
        <SearchBox
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={matchesFound}
        />
      </div>
    ),
    [onSearchChange, matchesFound]
  );

  const displayEmptySearch: boolean =
    isSuccess && searchQuery.length > 0 && tableRowsSearch.length === 0;

  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<TableRow, string>) => (
      <ExpandableNameCell
        canExpand={false}
        cssClassName={styles.nameCell}
        depth={0}
        handleToggleExpanded={row.getToggleExpandedHandler()}
        handleToggleSelected={row.getToggleSelectedHandler()}
        isCheckboxHidden
        isExpanded={false}
        isSelected={row.getIsSelected()}
        name={row.original.product.name}
        onClick={() => {
          if (!row.getIsSelected()) {
            eventTrackingService.trackEvent(
              [
                TrackingComponent.MyReports,
                TrackingComponent.Report,
                TrackingComponent.FocalItemTableRow,
              ],
              TrackingEvent.Selected,
              new GenericTrackingProperties({
                reportType: "Basket limits",
                reportId,
                selection: row.original.product,
              })
            );
            dispatch(onFocalItemChange(row.original.product));
          }
        }}
        shortName={row.original.product.shortName}
        // TODO: temp until the backend can return this
        type={HierarchyItemType.Attribute}
        value={getValue()}
      />
    ),
    [dispatch, eventTrackingService, reportId]
  );

  const headerColumn = useMemo(
    () => (
      <div className={styles.header}>
        <Text>Item description</Text>
        {memoizedSearchBox}
      </div>
    ),
    [memoizedSearchBox]
  );

  // `any` can be `string | number`. single type to make types work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: Array<ColumnDef<TableRow, any>> = useMemo(
    () => [
      columnHelper.accessor((row) => row.product.name, {
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        // @ts-expect-error This works just fine, but I haven't figured out how to make the type checker happy with it.
        header: headerColumn,
        id: "FocalItemTableLowerHeader",
      }),
      ...measuresColumnDefs(formatter, tableMetadata),
    ],
    [createNameCell, formatter, headerColumn, tableMetadata]
  );

  const parameterSummary = [
    { name: "Focal Item", value: focalItem.name },
    { name: "Time", value: localParametersSelection.time },
    {
      name: "Time Period Length",
      value: localParametersSelection.timePeriodLength,
    },
    {
      name: "Basket Limit",
      value: localParametersSelection.basketLimit.toLocaleString(),
    },
    {
      name: "Location",
      value: getExportParameterSummary(
        location
          .map((selection) => `(${selection.shortName}) ${selection.name}`)
          .join(", "),
        EXPORT_LOCATION_SUFFIX
      ),
    },
  ];

  const filename = useMemo(
    () =>
      cleanFilename(
        `Basket_Limits_${timePeriodLength}_${getExportParameterSummary(
          location.map((selection) => selection.name).join(","),
          EXPORT_LOCATION_SUFFIX
        )}`
      ),
    [timePeriodLength, location]
  );
  // Use client side data since the focal item data table will have a
  // finite number of rows that will always be present in the frontend store slice.
  // This avoids backend call to fetch data we already have.
  const csvTransformationCallback = useCallback(
    () => csvTransformation(tableRows, tableMetadata.metricMetadata),
    [tableRows, tableMetadata]
  );

  return (
    <ReportTopDrawer
      controls={[
        <DataTableOptions
          filename={filename}
          invokeCSVDownload={csvTransformationCallback}
          isFeatureEnabled={isExportEnabled}
          key={reportId}
          localParameters={parameterSummary}
          reportParameters={reportParameters}
        />,
      ]}
      items={focalItemSummary}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={ReportType.BasketLimits}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper isSuccess={!isTableFetching && isSuccess}>
          <VirtuosoTableComponent
            columnResizeMode={columnResizeMode}
            columns={columns}
            data={tableRowsSearch}
            getRowId={getRowId}
            pinFirstColumn
            rowExpandedState={{ "0": true }}
            rowSelectionState={focalItemTableSelectionState}
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default BasketLimitsTopDrawer;
