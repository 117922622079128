import {
  type CustomerGroupDtoWithDisplayName,
  type GroupFolderDto,
  type FolderOrGroupDto,
} from "@quantium-enterprise/common-ui";
import { type SegmentRow } from "../components/segment-library-table/SegmentLibraryTableCells";

export const getChildIds = (
  targetId: string,
  folder: GroupFolderDto,
  isChildOfOriginalTarget: boolean = false
): string[] => {
  // Initialize an empty array to store the child ids
  const childIds: string[] = [];

  // Check if this matches our target, or is a child of our original parent, in which case we want to add it
  if (folder.id === targetId || isChildOfOriginalTarget) {
    // Check if the current folder has the target id
    if (folder.id) {
      childIds.push(folder.id);
    }

    // Recursively process the child folders
    for (const childFolder of folder.folders ?? []) {
      childIds.push(...getChildIds(targetId, childFolder, true));
    }
  } else {
    // Recursively process the child folders that are not children yet, but might be
    for (const childFolder of folder.folders ?? []) {
      childIds.push(...getChildIds(targetId, childFolder, false));
    }
  }

  return childIds;
};

export const findAncestors = (
  targetId: string | undefined,
  rootFolder: GroupFolderDto
) => {
  const ancestors: string[] = [];

  const allChildren = rootFolder.folders;
  let currentDepth: GroupFolderDto[] | undefined = allChildren?.slice();

  // user does not have any folders
  if (!currentDepth || !targetId) {
    return ancestors;
  }

  // start at the first child if there is a child
  let currentFolder = currentDepth[0];
  while (currentFolder.id) {
    const currentFolderChildren = getChildIds(currentFolder.id, rootFolder);
    // if the current folder has the target id we have found where we want to expand to
    if (currentFolder.id === targetId) {
      break;
    }

    // if the current folder at the current depth we are at has the target as a child, we move on to the next depth level
    if (currentFolderChildren.includes(targetId)) {
      ancestors.push(currentFolder.id);
      currentDepth = currentFolder.folders;
    }

    // if not move on to the next item in the current depth and branch
    else {
      currentDepth = currentDepth.slice(1);
    }

    if (!currentDepth) {
      break;
    }

    // eslint-disable-next-line @typescript-eslint/no-loop-func
    currentFolder = currentDepth[0];
  }

  return ancestors;
};

export const isFolder = (
  object:
    | CustomerGroupDtoWithDisplayName
    | FolderOrGroupDto
    | SegmentRow
    | undefined
): object is FolderOrGroupDto =>
  object !== undefined &&
  Object.hasOwn(object, "isFolder") &&
  (object as FolderOrGroupDto).isFolder;
