import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { REPORT_DATA_CACHE_LENGTH_SECONDS } from "../../reportUtilities";
import {
  type performanceAggregateRequestDto,
  type performanceAggregateResponseDto,
  type performanceReportRequestDto,
  type performanceReportResponseDto,
} from "./dto/performanceReportDtos";

export const performanceReportApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    performanceReportMeasures: builder.query<
      performanceReportResponseDto,
      performanceReportRequestDto & { division: string }
    >({
      keepUnusedDataFor: REPORT_DATA_CACHE_LENGTH_SECONDS,
      providesTags: ["PerformanceReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/performance-report`,
      }),
    }),
    performanceAggregateMeasures: builder.query<
      performanceAggregateResponseDto,
      performanceAggregateRequestDto & { division: string }
    >({
      keepUnusedDataFor: REPORT_DATA_CACHE_LENGTH_SECONDS,
      providesTags: ["PerformanceReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/performance-report/aggregate`,
      }),
    }),
  }),
  reducerPath: "performanceReportApi",
  tagTypes: ["PerformanceReport"],
});

export const {
  usePerformanceReportMeasuresQuery,
  usePerformanceAggregateMeasuresQuery,
} = performanceReportApi;
