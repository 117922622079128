import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { type ColumnDef } from "@tanstack/react-table";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import styles from "./KeyDriversOverTimeContributionBreakdownTable.module.css";

type KeyDriversOverTimeContributionBreakdownTableProps = {
  categories: string[];
  metricType: string;
  tableData: TableDataSeries[];
};

export type TableDataSeries = {
  data: number[];
  header: Header;
};

export type Header = {
  depth?: number;
  name: string;
};

export const generateDataTableColumns = (
  categories: string[],
  metricType: string,
  formatter: DeferredFormatFunction
): Array<ColumnDef<TableDataSeries>> => {
  const columns: Array<ColumnDef<TableDataSeries>> = [
    {
      accessorFn: (row) => row.header,
      cell: ({ getValue }) => (
        <div
          className={styles.pinnedHeader}
          style={{
            fontWeight: getValue<Header>().depth
              ? "var(--qbit-font-weight-small)"
              : "var(--qbit-font-weight-medium)",
            paddingLeft: (getValue<Header>().depth ?? 0) * 15,
          }}
        >
          {getValue<Header>().name}
        </div>
      ),
      footer: (properties) => properties.column.id,
      header: () => <span>Measures</span>,
      id: uniqueId(),
    },
    ...categories.map(
      (category: string, index) =>
        ({
          accessorFn: (row) => row.data[index],
          cell: ({ getValue }) =>
            ValueCell({
              hasSentiment: true,
              formatter: formatter(metricType),
              value: getValue() as number,
            }),
          header: () => <span className={styles.colHeaders}>{category}</span>,
          id: uniqueId(),
        } as ColumnDef<TableDataSeries>)
    ),
  ];
  return columns;
};

export const KeyDriversOverTimeContributionBreakdownTable = ({
  categories,
  metricType,
  tableData,
}: KeyDriversOverTimeContributionBreakdownTableProps) => {
  const formatter = useFormatter();

  return (
    <div className={styles.contributionBreakdownTable}>
      <BasicTable
        columns={generateDataTableColumns(categories, metricType, formatter)}
        data={tableData}
        pinFirstColumn
      />
    </div>
  );
};
