import { type HierarchyType } from "../enums";

export const formatHierarchyName = (
  hierarchyType: HierarchyType,
  capitalized: boolean,
  plural: boolean
): string => {
  let name = hierarchyType.toString();
  name = capitalized ? name : name.toLowerCase();
  return plural ? name + "s" : name;
};
