import {
  type LocalHierarchyNodeSelection,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import {
  type NewProductBenchmarkingSidePanelSelections,
  initialState,
} from "../services/new-product-benchmarking-slice";

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): NewProductBenchmarkingSidePanelSelections => {
  const selections: NewProductBenchmarkingSidePanelSelections = {
    Channel: {
      value: "",
      label: "",
    },
    Segmentation: [],
    LocationHierarchy: {
      ...initialState.persistedSelections.localSelections.LocationHierarchy,
    },
    FocusPeriod: "",
    TimePeriodLength: "",
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const { time, timePeriodLength } = getTimeDefaultSelection(parameter);
          selections.FocusPeriod = time;
          selections.TimePeriodLength = timePeriodLength;
        }

        break;

      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.Channel.label = selection.label;
            selections.Channel.value = selection.value;
          }
        }

        break;

      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            selections.Segmentation = [
              { label: selection.label, value: selection.value },
              { label: selection.segmentLabel, value: selection.segmentValue },
            ];
          }
        }

        break;

      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter)) {
          selections.LocationHierarchy = getLocationSelectDefault(parameter);
        }

        break;

      default:
        break;
    }
  }

  return selections;
};

export const getUserSelections = (
  localParameters: NewProductBenchmarkingSidePanelSelections
) => {
  const userSelections: LocalSelectedValueDto[] = [];

  for (const [parameterID, values] of Object.entries(localParameters)) {
    if (typeof values === "object" && "value" in values && values.value) {
      userSelections.push({
        id: parameterID,
        values: [values.value.toString()],
      });
    } else if (Array.isArray(values) && values.length > 0) {
      if (parameterID === LocalParameters.Segmentation) {
        userSelections.push({
          id: parameterID,
          values: [...values.map((data) => data.value as string)],
        });
      } else {
        userSelections.push({
          id: parameterID,
          values: Object.values(values).map((data) => data.value as string),
        });
      }
    } else if (parameterID === "LocationHierarchy" && values) {
      const castValue = values as LocalHierarchyNodeSelection;
      userSelections.push({
        id: parameterID,
        values: [castValue.nodeNumber.toString()],
      });
    }
  }

  return userSelections;
};
