import {
  userApi,
  reportParametersWebApi,
} from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import reportTabsSlice, {
  reportTabsReducerPath,
} from "./services/report-tabs-slice";

const rootReducer = combineReducers({
  [reportTabsReducerPath]: reportTabsSlice,
  [userApi.reducerPath]: userApi.reducer,
  [reportParametersWebApi.reducerPath]: reportParametersWebApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "report-tabs" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        userApi.middleware,
        reportParametersWebApi.middleware
      ),

    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
