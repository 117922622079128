import { type ReportletDataRequestDto } from "../models/reportlet-request";
import { type ReportletDataResponseDto } from "../models/reportlet-response";
import { compareMetricsApiSlice } from "./compare-metrics-api-slice";

export const compareMetricsReportletDataSlice =
  compareMetricsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getCompareMetricsReportletData: builder.query<
        ReportletDataResponseDto,
        { divisionName: string; requestPayload: ReportletDataRequestDto }
      >({
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportletData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useLazyGetCompareMetricsReportletDataQuery } =
  compareMetricsReportletDataSlice;
