import {
  HierarchyGroupEvaluationType,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import { DynamicIcon } from "./icons/DynamicIcon";
import { DynamicLocationGroupIcon } from "./icons/DynamicLocationGroupIcon";
import { DynamicProductGroupIcon } from "./icons/DynamicProductGroupIcon";
import { LocationGroupIcon } from "./icons/LocationGroupIcon";
import { ProductGroupIcon } from "./icons/ProductGroupIcon";
import { StaticIcon } from "./icons/StaticIcon";
import { StaticLocationGroupIcon } from "./icons/StaticLocationGroupIcon";
import { StaticProductGroupIcon } from "./icons/StaticProductGroupIcon";

export type HierarchyGroupIconProps = {
  className?: string;
  disabled?: boolean;
  evaluationType?: string;
  hierarchyType?: string;
  text?: string;
};

export const HierarchyGroupIcon = ({
  className,
  disabled = false,
  evaluationType,
  hierarchyType,
  text,
}: HierarchyGroupIconProps) => {
  switch (true) {
    case evaluationType === HierarchyGroupEvaluationType.Dynamic &&
      hierarchyType === HierarchyType.Product:
      return (
        <DynamicProductGroupIcon
          className={className}
          coloured={!disabled}
          text={text}
        />
      );

    case evaluationType === HierarchyGroupEvaluationType.Static &&
      hierarchyType === HierarchyType.Product:
      return (
        <StaticProductGroupIcon
          className={className}
          coloured={!disabled}
          text={text}
        />
      );

    case evaluationType === HierarchyGroupEvaluationType.Dynamic &&
      hierarchyType === HierarchyType.Location:
      return (
        <DynamicLocationGroupIcon
          className={className}
          coloured={!disabled}
          text={text}
        />
      );

    case evaluationType === HierarchyGroupEvaluationType.Static &&
      hierarchyType === HierarchyType.Location:
      return (
        <StaticLocationGroupIcon
          className={className}
          coloured={!disabled}
          text={text}
        />
      );

    case hierarchyType === HierarchyType.Product:
      return (
        <ProductGroupIcon
          className={className}
          coloured={!disabled}
          text={text}
        />
      );

    case hierarchyType === HierarchyType.Location:
      return (
        <LocationGroupIcon
          className={className}
          coloured={!disabled}
          text={text}
        />
      );

    case evaluationType === HierarchyGroupEvaluationType.Dynamic:
      return (
        <DynamicIcon className={className} coloured={!disabled} text={text} />
      );

    case evaluationType === HierarchyGroupEvaluationType.Static:
      return (
        <StaticIcon className={className} coloured={!disabled} text={text} />
      );

    default:
      return null;
  }
};
