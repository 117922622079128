import {
  type TrackingEvent,
  type TrackingComponent,
  useGetReportParametersQuery,
  useEventTrackingServiceContext,
  GenericTrackingProperties,
  useRenameReportMutation,
  FeatureFlag,
  ReportType,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { useFetchNodesQuery } from "../common/services/hierarchy-slice";
import { parentNodesToFetch } from "../common/utils/focal-item-labels-utils";
import { type RootState } from "../store";
import TimeOfDayDayOfWeekChartReportlet from "./components/TimeOfDayDayOfWeekChartReportlet";
import TimeOfDayDayOfWeekHeatmapReportlet from "./components/TimeOfDayDayOfWeekHeatmapReportlet";
import TimeOfDayDayOfWeekSidePanel from "./components/TimeOfDayDayOfWeekSidePanel";
import TimeOfDayDayOfWeekTopDrawer from "./components/top-drawer/TimeOfDayDayOfWeekTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/time-of-day-day-of-week-local-parameters-api-slice";
import {
  onMetadataSuccess,
  onParentNodeDataReceived,
  onReportOpen,
  reset,
} from "./services/time-of-day-day-of-week-slice";
import {
  selectFocalItems,
  selectLocalParametersInitialised,
} from "./services/time-of-day-day-of-week-slice-selectors";

export const TimeOfDayDayOfWeekReport = () => {
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  // Store
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const { reportName, reportId } = useSelector((state: RootState) => ({
    reportName: state.timeOfDayDayOfWeek.metaData.reportName,
    reportId: state.timeOfDayDayOfWeek.metaData.reportId,
  }));
  const focalItems = useSelector(selectFocalItems);

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
  }, [id, isTabsEnabled, dispatch]);

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Time of day, day of week",
        reportId,
        ...properties,
      })
    );

  // info panel
  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: id === undefined || !activeDivisionName }
    );

  // Local Parameters Query
  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, activeReportId: string) => {
      await getLocalParameters({
        divisionName: division,
        reportId: activeReportId,
      });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );
  // Save metadata to the state
  useEffect(() => {
    if (infoPanelSummary) {
      dispatch(
        onMetadataSuccess({
          reportId: infoPanelSummary.id,
          reportName: infoPanelSummary.reportName,
        })
      );
    }
  }, [dispatch, infoPanelSummary]);

  const parentNodeNumbers = useMemo(
    () => parentNodesToFetch(focalItems),
    [focalItems]
  );

  const { data: hierarchySliceParentNodes } = useFetchNodesQuery(
    {
      hierarchyType: "Product",
      nodeNumbers: parentNodeNumbers,
      reportId,
    },
    {
      skip: parentNodeNumbers.length === 0 || !reportId,
    }
  );

  useEffect(() => {
    if (hierarchySliceParentNodes) {
      dispatch(onParentNodeDataReceived(hierarchySliceParentNodes.nodes));
    }
  }, [dispatch, hierarchySliceParentNodes]);

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <TimeOfDayDayOfWeekTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportType={ReportType.TimeOfDayDayOfWeek}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <TimeOfDayDayOfWeekSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <TimeOfDayDayOfWeekChartReportlet
            infoPanelSummary={infoPanelSummary}
          />
          <TimeOfDayDayOfWeekHeatmapReportlet
            infoPanelSummary={infoPanelSummary}
          />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <TimeOfDayDayOfWeekReport />,
  path: "time-of-day-day-of-week/:id",
};

export default TimeOfDayDayOfWeekReport;
