import {
  Button,
  ButtonHeight,
  ButtonWidth,
  Icon,
  IconGlyph,
} from "@quantium-enterprise/qds-react";
import { ReportIcon } from "components-ui/src/icons";
import styles from "./ReportingDropdownTab.module.css";

export type AdancedReportTabsDropdownTabProps = {
  name: string;
  onRemoveTab: () => void;
  onSetActiveTab: () => void;
  type: string;
};

export const AdancedReportTabsDropdownTab = ({
  name,
  onRemoveTab,
  onSetActiveTab,
  type,
}: AdancedReportTabsDropdownTabProps) => (
  <div className={styles.reportTabsDropdownTab}>
    <Button onClick={onSetActiveTab}>
      <ReportIcon type={type} />
      <div className={styles.text}>{name}</div>
    </Button>
    <Button
      className={styles.close}
      height={ButtonHeight.XSmall}
      onClick={(event) => {
        event.stopPropagation();
        onRemoveTab();
      }}
      width={ButtonWidth.Fit}
    >
      <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
    </Button>
  </div>
);
