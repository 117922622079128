import {
  type HeaderGroup,
  type ColumnResizeMode,
  type Header,
  type ColumnMeta,
  type RowData,
} from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import commonStyles from "../common/GridTable.module.css";
import ColumnResizer from "../common/column-resizer/ColumnResizer";
import styles from "./VirtuosoTable.module.css";
import { getAriaSortedString, VirtuosoTableSort } from "./VirtuosoTableSort";

export enum ColumnType {
  Numeric = "Numeric",
  Text = "Text",
}

export enum HeaderVerticalAlign {
  Bottom = "bottom",
  Middle = "middle",
  Top = "top",
}

type ColumnDefinitionMeta = {
  columnType: ColumnType;
  headerVerticalAlign: HeaderVerticalAlign;
};

export type CustomColumnMeta<
  TData extends RowData,
  TValue
> = ColumnDefinitionMeta & ColumnMeta<TData, TValue> & {};

export type VirtuosoTableHeaderElementProps<T> = {
  columnResizeMode: ColumnResizeMode;
  deltaOffset: number | null;
  enableSorting?: boolean;
  firstColumnMinWidth: number;
  headerGroup: HeaderGroup<T>;
  pinFirstColumn: boolean;
};

const isRowSpanColumn = <T,>(headerValue: Header<T, unknown>, index: number) =>
  headerValue.column.depth === 0 && index > 0;

const showColumnResizer = <T,>(
  headerValue: Header<T, unknown>,
  pinFirstColumn: boolean
): boolean => {
  if (pinFirstColumn && headerValue.index === 0) {
    return false;
  }

  return headerValue.column.getCanResize();
};

export const VirtuosoTableHeaderElement = <T,>({
  columnResizeMode,
  deltaOffset,
  firstColumnMinWidth,
  headerGroup,
  pinFirstColumn,
  enableSorting,
}: VirtuosoTableHeaderElementProps<T>) => (
  <tr
    className={classNames(commonStyles.tableRow)}
    key={headerGroup.id}
    role="row"
  >
    {headerGroup.headers.map((header) => (
      <th
        aria-sort={getAriaSortedString(header.column.getIsSorted())}
        className={classNames(commonStyles.tableHeader, {
          [commonStyles.pinned]: header.column.getIsPinned(),
        })}
        colSpan={header.colSpan}
        data-testid={`columnheader-${header.id}`}
        key={header.id}
        onClick={() => {
          if (enableSorting && header.column.getCanSort()) {
            if (header.column.getNextSortingOrder() === false) {
              header.column.clearSorting();
            } else {
              header.column.toggleSorting();
            }
          }
        }}
        role="columnheader"
        style={{
          minWidth:
            header.index === 0 && pinFirstColumn
              ? firstColumnMinWidth
              : header.getSize(),
          maxWidth:
            header.index === 0 && pinFirstColumn
              ? firstColumnMinWidth
              : header.getSize(),
        }}
      >
        <div
          className={classNames(styles.headerCell, {
            [styles.alignRight]:
              header.column.columnDef.meta &&
              (header.column.columnDef.meta as ColumnDefinitionMeta)
                .columnType === ColumnType.Numeric,
            [styles.alignLeft]:
              header.column.columnDef.meta &&
              (header.column.columnDef.meta as ColumnDefinitionMeta)
                .columnType === ColumnType.Text,
            [styles.alignTop]:
              header.column.columnDef.meta &&
              (header.column.columnDef.meta as ColumnDefinitionMeta)
                .headerVerticalAlign === HeaderVerticalAlign.Top,
            [styles.alignBottom]:
              header.column.columnDef.meta &&
              (header.column.columnDef.meta as ColumnDefinitionMeta)
                .headerVerticalAlign === HeaderVerticalAlign.Bottom,
          })}
        >
          <VirtuosoTableSort header={header}>
            <div
              className={styles.ellipsis}
              data-text={
                typeof header.column.columnDef.header === "string"
                  ? header.column.columnDef.header
                  : ""
              }
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </div>
          </VirtuosoTableSort>

          {showColumnResizer(header, pinFirstColumn) ? (
            <ColumnResizer
              columnResizeMode={columnResizeMode}
              deltaOffset={deltaOffset}
              header={header}
              isRowSpan={isRowSpanColumn(header, header.index)}
            />
          ) : null}
        </div>
      </th>
    ))}
  </tr>
);
