import {
  Checkbox,
  FormBlock,
  FormBlockType,
  Text,
  TextVariant,
  FormBlockEditability,
  Label,
  LabelVariant,
  Input,
} from "@quantium-enterprise/qds-react";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import CollapsiblePanel from "../CollapsiblePanel";
import NoEditingPanel from "../NoEditingPanel";
import styles from "./MultiCheckbox.module.css";

export type MultiCheckboxProps = {
  checkboxOptions: PanelOption[];
  isCollapsed: boolean;
  isDisabled: (option: string) => boolean;
  onSelect: (selection: string) => void;
  selected: string[];
  subtitle?: string;
  summary: string;
  title: string;
  toggleCollapsed: () => void;
};

export const MultiCheckbox = ({
  checkboxOptions,
  subtitle,
  title,
  isCollapsed,
  isDisabled,
  onSelect,
  summary,
  toggleCollapsed,
  selected,
}: MultiCheckboxProps) => {
  if (checkboxOptions.length === 1) {
    return (
      <NoEditingPanel title={title}>
        {checkboxOptions[0].labelElement ?? checkboxOptions[0].label}
      </NoEditingPanel>
    );
  }

  const createLabel = (option: PanelOption) => {
    if (option.labelElement) {
      return <div>{option.labelElement}</div>;
    }

    return (
      <Text className={styles.checkboxItem} variant={TextVariant.Default}>
        {option.label || option.value.toString()}
      </Text>
    );
  };

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content: (
          <>
            {subtitle && (
              <Label
                className={styles.multiCheckboxSubtitle}
                htmlFor=""
                text={subtitle}
                variant={LabelVariant.GroupLabel}
              />
            )}
            <FormBlock
              blockType={FormBlockType.Checkbox}
              className={styles.checkboxGroup}
            >
              {checkboxOptions.map((checkboxOption) => (
                <Input key={checkboxOption.value}>
                  <Checkbox
                    checked={selected
                      .map(String)
                      .includes(checkboxOption.value.toString())}
                    className={styles.checkboxInputContainer}
                    editability={
                      isDisabled(checkboxOption.value.toString())
                        ? FormBlockEditability.Editable
                        : FormBlockEditability.Disabled
                    }
                    id={`checkbox-${title}-${checkboxOption.value}`}
                    key={checkboxOption.value}
                    label={createLabel(checkboxOption)}
                    name={`checkboxOption-${checkboxOption.value}`}
                    onChange={() => {
                      onSelect(checkboxOption.value.toString());
                    }}
                  />
                </Input>
              ))}
            </FormBlock>
          </>
        ),
        summary,
      }}
    </CollapsiblePanel>
  );
};

export default MultiCheckbox;
