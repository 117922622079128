import {
  ddLog,
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { type DataLabelsOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { type SidePanelTimePeriodParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getFocalItemSummary } from "../../common/utils/export-parameter-summary-utils";
import styles from "../PricingLaddersReport.module.css";
import { PricingLaddersFeatureFlags, PrimaryMetricKey } from "../constants";
import { type RequestDto } from "../models/reportlet-request";
import { useLazyGetPricingLaddersReportletQuery } from "../services/pricing-ladders-reportlet-api-slice";
import {
  onReportletDataReceived,
  selectAllRows,
  selectChannel,
  selectChartAdditionalMetric,
  selectChartTypeActive,
  selectFocalItems,
  selectLevelOfAnalysis,
  selectLocalParameter,
  selectLocalParametersInitialised,
  selectLocalParametersSelections,
  selectLocationHierarchy,
  selectReportId,
  selectReportName,
  selectReportletData,
  selectReportletTabActive,
  selectSegmentation,
  selectShowChartDataLabels,
  selectTopDrawerActiveTab,
  selectViewBy,
  toggleDataLabels,
} from "../services/pricing-ladders-slice";
import { formatMetricValue, getSeriesInfo } from "../utils/common-utils";
import { getFileName, timeData } from "../utils/getExportFunctions";
import getUserSelections from "../utils/getUserSelections";
import {
  chartCsvTransformation,
  tableCsvTransformation,
} from "./CsvTransformation";
import { PricingLaddersGBBSlider } from "./PricingLaddersGBBSlider";
import { PricingLaddersGoodBetterBestChart } from "./PricingLaddersGoodBetterBestChart";
import { PricingLaddersMetrics } from "./PricingLaddersMetrics";
import { PricingLaddersStandardChart } from "./PricingLaddersStandardChart";
import { PricingLaddersTable } from "./PricingLaddersTable";

export type PricingLaddersReportletProps = {
  reportParameters?: ReportParametersDto;
};
export const PricingLaddersReportlet = ({
  reportParameters,
}: PricingLaddersReportletProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: division } = useDivision();

  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );
  const channel = useSelector(selectChannel);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const segmentation = useSelector(selectSegmentation);
  const viewBy = useSelector(selectViewBy)[0];
  const sortBy = useSelector(selectViewBy)[1];
  const additionalMetricKey = useSelector(selectChartAdditionalMetric);
  const storeReportId = useSelector(selectReportId);

  const [maxValue, setMaxValue] = useState<number>(0);
  const [minValue, setMinValue] = useState<number>(0);
  const [betterValue, setBetterValue] = useState<number>(0);
  const [bestValue, setBestValue] = useState<number>(0);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[PricingLaddersFeatureFlags.ReportExport] ?? false;

  const focalItems = useSelector(selectFocalItems);
  const reportletTabActive = useSelector(selectReportletTabActive);
  const chartTabActive = useSelector(selectChartTypeActive);
  const reportletData = useSelector(selectReportletData);
  const topDrawerActiveTab = useSelector(selectTopDrawerActiveTab);
  const allRows = useSelector(selectAllRows);
  const levelOfAnalysis = useSelector(selectLevelOfAnalysis);
  const showChartDataLabels = useSelector(selectShowChartDataLabels);
  const reportName = useSelector(selectReportName);

  const isDataLoaded =
    localParametersInitialised && Boolean(reportId) && allRows.length > 0;

  const [
    getPricingLaddersReportletQuery,
    { data: response, isUninitialized, isFetching: isDataFetching },
  ] = useLazyGetPricingLaddersReportletQuery();

  const fetchPricingLaddersReportletQuery = useCallback(
    async (divisionName: string, payload: RequestDto) => {
      await getPricingLaddersReportletQuery({
        division: divisionName,
        payload,
      });
    },
    [getPricingLaddersReportletQuery]
  );

  useEffect(() => {
    if (reportId === storeReportId && isDataLoaded) {
      fetchPricingLaddersReportletQuery(division, {
        focalItems,
        localSelectedValues: getUserSelections(localParametersSelections),
        levelOfAnalysis: topDrawerActiveTab ?? "",
        reportId,
      }).catch((error) => {
        ddLog(
          "Error retrieving pricing ladders reportlet query",
          {},
          "error",
          error
        );
      });
    }
  }, [
    division,
    fetchPricingLaddersReportletQuery,
    focalItems,
    storeReportId,
    isDataLoaded,
    localParametersSelections,
    reportId,
    topDrawerActiveTab,
  ]);

  useEffect(() => {
    if (response) {
      dispatch(onReportletDataReceived(response));
    }
  }, [dispatch, response]);

  const noData =
    response === undefined ||
    response.series.every((metricSeries) =>
      metricSeries.data.every((dataPoint) => !dataPoint.metricValue)
    );
  const primaryMetrics: string[] = Object.values(PrimaryMetricKey);

  const additionalMetrics =
    response?.series
      .map((metric) => metric.metricLabel)
      .filter(
        (value, index, array) =>
          !primaryMetrics.includes(value) && array.indexOf(value) === index
      ) ?? [];

  const additionalMetric = additionalMetrics.includes(additionalMetricKey)
    ? additionalMetricKey
    : "";

  useEffect(() => {
    if (response) {
      const seriesInfo = getSeriesInfo(viewBy.value as string);
      const pricingSeriesToProcess = response.series.filter((series) =>
        seriesInfo.includes(series.metricLabel)
      );

      // view by information
      const Maxavgpricedata =
        pricingSeriesToProcess.find(
          (series) => series.metricLabel === seriesInfo[0]
        )?.data ?? [];

      const avgpricedata =
        pricingSeriesToProcess.find(
          (series) => series.metricLabel === seriesInfo[1]
        )?.data ?? [];

      const avgpromopricedata =
        pricingSeriesToProcess.find(
          (series) => series.metricLabel === seriesInfo[2]
        )?.data ?? [];

      const ladderValues = avgpricedata
        .map(({ metricValue }, index) => ({
          x: index,
          low: formatMetricValue(avgpromopricedata[index]?.metricValue),
          mid: formatMetricValue(metricValue),
          high: formatMetricValue(Maxavgpricedata[index]?.metricValue),
        }))
        .filter((item) => formatMetricValue(item.mid) !== null);

      if (ladderValues.length > 0) {
        const maxValueResponse = ladderValues.sort((a, b) =>
          b.mid && a.mid ? b.mid - a.mid : 0
        )[0];
        const minValueResponse = ladderValues.sort((a, b) =>
          b.mid && a.mid ? a.mid - b.mid : 0
        )[0];
        if (maxValueResponse.mid !== null && minValueResponse.mid !== null) {
          const maxRoundedValue =
            maxValueResponse.mid && minValueResponse.mid
              ? Math.round((maxValueResponse.mid + Number.EPSILON) * 100) / 100
              : 0;
          const minRoundedValue =
            maxValueResponse.mid && minValueResponse.mid
              ? Math.round((minValueResponse.mid + Number.EPSILON) * 100) / 100
              : 0;
          const betterStartRoundedValue =
            maxValueResponse.mid && minValueResponse.mid
              ? Math.round(
                  (minValueResponse.mid +
                    (maxValueResponse.mid - minValueResponse.mid) * 0.34 +
                    Number.EPSILON) *
                    100
                ) / 100
              : 0;
          const bestStartRoundedValue =
            maxValueResponse.mid && minValueResponse.mid
              ? Math.round(
                  (minValueResponse.mid +
                    (maxValueResponse.mid - minValueResponse.mid) * 0.66 +
                    Number.EPSILON) *
                    100
                ) / 100
              : 0;
          setMaxValue(maxRoundedValue);
          setMinValue(minRoundedValue);
          setBetterValue(betterStartRoundedValue);
          setBestValue(bestStartRoundedValue);
        }
      }
    }
  }, [response, maxValue, minValue, viewBy]);

  const localParametersConfig = useSelector(selectLocalParameter);
  const timeValue = timeData(
    localParametersConfig[0] as SidePanelTimePeriodParameter
  );
  const fileName = getFileName(reportletTabActive);
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);
  const CsvTransformationCallback = useCallback(
    () =>
      tableCsvTransformation(
        reportletData.series,
        reportletData.categories,
        sortBy.label,
        currencySymbol
      ),
    [reportletData, sortBy, currencySymbol]
  );
  const ChartCsvTransformationCallback = useCallback(
    () =>
      chartCsvTransformation(
        reportletData.series,
        reportletData.categories,
        sortBy.label,
        getSeriesInfo(viewBy.value.toString()),
        [additionalMetric],
        currencySymbol
      ),
    [reportletData, sortBy, viewBy, additionalMetric, currencySymbol]
  );
  const chartContainerRef = useRef<HTMLElement>();
  const parameterSummary = useMemo(
    () => [
      { name: "Focal Item", value: getFocalItemSummary(focalItems) },
      { name: "Time", value: timeValue.time },
      { name: "Channel", value: channel.label },
      {
        name: "Level of Analysis",
        value:
          levelOfAnalysis.find(
            (analsyis) => analsyis.value === topDrawerActiveTab
          )?.label ?? "",
      },
      { name: "View By", value: viewBy.label },
      {
        name: "Sort By",
        value: sortBy.label,
      },
      {
        name: "Segmentation",
        value: segmentation[0]?.label,
      },
      {
        name: "Customer segment",
        value: segmentation[1]?.label,
      },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(locationHierarchy.shortName)}) ${
          locationHierarchy.name
        }`,
      },
    ],

    [
      channel,
      viewBy,
      sortBy,
      segmentation,
      locationHierarchy,
      focalItems,
      topDrawerActiveTab,
      levelOfAnalysis,
      timeValue,
    ]
  );
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `${fileName}_${timeValue.timePeriodLength}_${locationHierarchy.name}`
      ),
    [fileName, timeValue, locationHierarchy]
  );

  const dataLabelsOptions: DataLabelsOptions = {
    isSelected: showChartDataLabels,
    value: "",
  };

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  return (
    <ReportletAccordion
      subtitle="Understand the pricing architecture of a product range."
      title="Pricing ladders"
    >
      <div className={styles.chartOptionsContainer}>
        <PricingLaddersMetrics
          additionalMetricDropdownValues={additionalMetrics}
        />

        {reportletTabActive === "Chart" ? (
          <ChartOptions
            dataLabelsOptions={[dataLabelsOptions]}
            downloadWizardOptions={
              currentOptions
                ? {
                    chartOptions: currentOptions,
                    reportIcon: <ReportIcon type={ReportType.PricingLadders} />,
                    chartTitle: `Pricing ladders - ${reportName}`,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={exportFilename}
            getCSVData={ChartCsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={reportParameters}
            toggleDataLabels={() => {
              dispatch(toggleDataLabels());
            }}
          />
        ) : (
          <DataTableOptions
            filename={exportFilename}
            invokeCSVDownload={CsvTransformationCallback}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={reportParameters}
          />
        )}
      </div>
      <NoDataChartWrapper
        isLoading={isUninitialized || isDataFetching}
        noData={isDataLoaded && noData}
      >
        <div className={styles.reportletChartContainer}>
          {reportletTabActive === "Chart" ? (
            chartTabActive === "Standard" ? (
              <ErrorBoundary key="standard">
                <ChartFooterWrapper
                  height="450px"
                  parameters={parameterSummary}
                  ref={chartContainerRef}
                >
                  <PricingLaddersStandardChart
                    categories={reportletData.categories}
                    onOptionsChanged={setCurrentOptions}
                    pricingSeries={reportletData.series}
                    secondaryMetric={additionalMetric}
                    showChartDataLabels={showChartDataLabels}
                    sortBy={sortBy.value.toString()}
                    viewBy={viewBy.value.toString()}
                  />
                </ChartFooterWrapper>
              </ErrorBoundary>
            ) : (
              <ErrorBoundary key="good-better-best">
                <ChartFooterWrapper
                  height="550px"
                  parameters={parameterSummary}
                  ref={chartContainerRef}
                >
                  <PricingLaddersGBBSlider
                    bestStart={bestValue}
                    betterStart={betterValue}
                    max={maxValue}
                    min={minValue}
                    setBestValue={setBestValue}
                    setBetterValue={setBetterValue}
                  />

                  <div className={styles.reportletButtonGroup}>
                    <PricingLaddersGoodBetterBestChart
                      bestStartValue={bestValue}
                      betterStartValue={betterValue}
                      categories={reportletData.categories}
                      maxValue={maxValue}
                      minValue={minValue}
                      onOptionsChanged={setCurrentOptions}
                      pricingSeries={reportletData.series}
                      secondaryMetric={additionalMetricKey}
                      showChartDataLabels={showChartDataLabels}
                      sortBy={sortBy.value.toString()}
                      viewBy={viewBy.value.toString()}
                    />
                  </div>
                </ChartFooterWrapper>
              </ErrorBoundary>
            )
          ) : (
            <ErrorBoundary key="table">
              <PricingLaddersTable
                categories={reportletData.categories}
                series={reportletData.series}
              />
            </ErrorBoundary>
          )}
        </div>
      </NoDataChartWrapper>
    </ReportletAccordion>
  );
};
