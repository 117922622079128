import { type TimeOfDayDayOfWeekHeatmapFilters } from "../models/TimeOfDayDayOfWeekHeatmap";
import { type HeatMapResponseDto } from "../models/reportlet-response";
import { formatDayId, formatHourId } from "../utils/chart-utils";
import { getCsvColumnHeader } from "../utils/csv-util";

export const csvTransformation = (
  reportletData: HeatMapResponseDto,
  currencySymbol: string,
  heatmapFilters: TimeOfDayDayOfWeekHeatmapFilters
) => {
  const columnHeaders = ["Day Of Week"];
  const csvData = [columnHeaders];

  const topRowHeaders = reportletData.hoursMetaData.map((hourId) =>
    formatHourId(hourId)
  );
  const leftColumnHeaders = reportletData.segmentedData[
    heatmapFilters.activeSegmentIndex
  ].dayData.map((ele) => formatDayId[ele.day.toUpperCase()]);

  const tableData = reportletData.segmentedData[
    heatmapFilters.activeSegmentIndex
  ].dayData.map((ele) => ele.values);

  columnHeaders.push(
    ...topRowHeaders.map((data) =>
      getCsvColumnHeader(
        reportletData.format,
        currencySymbol,
        data,
        reportletData.metric
      )
    )
  );

  for (const [index, convertedDay] of leftColumnHeaders.entries()) {
    const row = [];
    row.push(convertedDay);
    row.push(...tableData[index].map((data) => `${data}`));
    csvData.push(row);
  }

  return csvData;
};
