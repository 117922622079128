import { useEffect } from "react";

export const useEscapeKeyListener = (
  callback: () => void,
  disableEscape: boolean = false
) => {
  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Escape" && !disableEscape) {
        callback();
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [callback, disableEscape]);
};
