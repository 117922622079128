import {
  type TrackingComponent,
  type TrackingEvent,
  GenericTrackingProperties,
  useEventTrackingServiceContext,
  useRenameReportMutation,
  FeatureFlag,
  useLazyGetReportParametersQuery,
  ddLog,
  ReportType,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { type RootState } from "../store";
import { PurchaseActivityReportlet } from "./components/PurchaseActivityReportlet";
import { TrialAndRepeatSidePanel } from "./components/TrialAndRepeatSidePanel";
import TrialAndRepeatTopDrawer from "./components/TrialAndRepeatTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/trial-and-repeat-local-parameters-api-slice";
import {
  onReportError,
  onReportOpen,
  onReportSuccess,
  selectLocalParametersInitialised,
} from "./services/trial-and-repeat-slice";

export const TrialAndRepeatReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const [
    getReportParametersQuery,
    { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading },
  ] = useLazyGetReportParametersQuery();

  const fetchReportParametersQuery = useCallback(
    async (division: string, reportId: string) => {
      await getReportParametersQuery({
        divisionName: division,
        reportId,
      }).catch((error) => {
        ddLog(
          "Error retrieving trial and repeat report parameters",
          {},
          "error",
          error
        );
      });
    },
    [getReportParametersQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchReportParametersQuery(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    fetchReportParametersQuery,
    id,
    localParametersInitialised,
  ]);

  const [getLocalParametersQuery, { data, isError, isSuccess }] =
    useLazyGetLocalParametersQuery();

  const fetchLocalParametersQuery = useCallback(
    async (division: string, reportId: string) => {
      await getLocalParametersQuery({ divisionName: division, reportId }).catch(
        (error) => {
          ddLog(
            "Error retrieving trial and repeat local parameters",
            {},
            "error",
            error
          );
        }
      );
    },
    [getLocalParametersQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParametersQuery(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    fetchLocalParametersQuery,
    id,
    localParametersInitialised,
  ]);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(onReportSuccess(data));
    } else if (isError) {
      dispatch(onReportError());
    }
  }, [data, dispatch, isError, isSuccess]);

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.trialAndRepeat.reportName,
  }));

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
  }, [dispatch, id, isTabsEnabled]);

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Trial and Repeat",
        reportId: id,
        ...properties,
      })
    );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <TrialAndRepeatTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportTabItems={
              data?.levelsOfAnalysis
                ? data.levelsOfAnalysis.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))
                : []
            }
            reportType={ReportType.TrialAndRepeat}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <TrialAndRepeatSidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <PurchaseActivityReportlet infoPanelSummary={infoPanelSummary} />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <TrialAndRepeatReport />,
  path: "trial-and-repeat/:id",
};

export default TrialAndRepeatReport;
