export const CameraIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6665 4.16667H14.0248L12.4998 2.5H7.49984L5.97484 4.16667H3.33317C2.4165 4.16667 1.6665 4.91667 1.6665 5.83333V15.8333C1.6665 16.75 2.4165 17.5 3.33317 17.5H16.6665C17.5832 17.5 18.3332 16.75 18.3332 15.8333V5.83333C18.3332 4.91667 17.5832 4.16667 16.6665 4.16667ZM16.6665 15.8333H3.33317V5.83333H6.70817L8.23317 4.16667H11.7665L13.2915 5.83333H16.6665V15.8333ZM9.99984 6.66667C7.69984 6.66667 5.83317 8.53333 5.83317 10.8333C5.83317 13.1333 7.69984 15 9.99984 15C12.2998 15 14.1665 13.1333 14.1665 10.8333C14.1665 8.53333 12.2998 6.66667 9.99984 6.66667ZM9.99984 13.3333C8.62484 13.3333 7.49984 12.2083 7.49984 10.8333C7.49984 9.45833 8.62484 8.33333 9.99984 8.33333C11.3748 8.33333 12.4998 9.45833 12.4998 10.8333C12.4998 12.2083 11.3748 13.3333 9.99984 13.3333Z"
      fill="#676D70"
    />
  </svg>
);
