import {
  customerGroupsApi,
  groupsApi,
  hierarchyServiceApi,
  userApi,
  sseApi,
} from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import groupHierarchySourceReducer from "./states/group-hierarchy-source-slice";
import { groupListSliceReducer } from "./states/group-list-slice";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [customerGroupsApi.reducerPath]: customerGroupsApi.reducer,
  [hierarchyServiceApi.reducerPath]: hierarchyServiceApi.reducer,
  groupHierarchySource: groupHierarchySourceReducer,
  groupListSlice: groupListSliceReducer,
  [sseApi.reducerPath]: sseApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "groups" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        hierarchyServiceApi.middleware,
        groupsApi.middleware,
        userApi.middleware,
        customerGroupsApi.middleware,
        sseApi.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
