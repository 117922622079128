import {
  ddLog,
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { type RootState } from "../../../store";
import { SummaryDisplayType } from "../../constants";
import { BasketQuantitiesFeatureFlags } from "../../constants/basket-quantities-feature-flags";
import { BreakdownByParameterId } from "../../models/basket-quantities-common-models";
import { type SummaryRequestDto } from "../../models/basket-quantities-summary-request-dto";
import {
  isBreakdownByTotal,
  selectLocalParametersInitialised,
  selectBreakdown,
  selectChannel,
  selectLocationHierarchy,
  selectPromotion,
  selectSegmentation,
  selectFocalItem,
  selectSummaryChartAdditionalMetric,
  selectTime,
  selectTimePeriodLength,
  selectViewAs,
  selectSegment,
  selectShowBasketQuantitiesSummaryChartDataLabels,
  toggleBasketQuantitiesSummaryChartDataLabels,
  selectReportId,
} from "../../services/basket-quantities-slice";
import { useLazyGetBasketQuantitiesSummaryReportletQuery } from "../../services/basket-quantities-summary-api-slice";
import { getReportletLocalSelections } from "../../utils/getReportletLocalSelections";
import {
  getViewAsMetricLabel,
  getViewAsMetricKey,
  getViewAsShare,
} from "../../utils/getViewAsMetrics";
import { BasketQuantitiesSummaryMetrics } from "../BasketQuantitiesSummaryMetrics";
import { BasketQuantitiesSummaryBreakdownChart } from "./BasketQuantitiesSummaryBreakdownChart";
import { BasketQuantitiesSummaryChart } from "./BasketQuantitiesSummaryChart";
import styles from "./BasketQuantitiesSummaryReportlet.module.scss";
import { BasketQuantitiesSummaryTable } from "./BasketQuantitiesSummaryTable";
import { csvTransformation } from "./csvTransformation";

export type BasketQuantitiesSummaryReportletProps = {
  defaultChart: SummaryDisplayType;
  reportParameters?: ReportParametersDto;
};

export const BasketQuantitiesSummaryReportlet = ({
  defaultChart,
  reportParameters,
}: BasketQuantitiesSummaryReportletProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: division, locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketQuantitiesFeatureFlags.ReportExport] ?? false;

  const [displayType, setDisplayType] = useState(defaultChart);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const breakdown = useSelector(selectBreakdown);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocationHierarchy);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const focalItem = useSelector(selectFocalItem);
  const additionalMetricState = useSelector(selectSummaryChartAdditionalMetric);
  const viewAs = useSelector(selectViewAs);
  const viewAsShare = getViewAsShare(viewAs.value as string);
  const showBasketQuantitiesSummaryChartDataLabels = useSelector(
    selectShowBasketQuantitiesSummaryChartDataLabels
  );
  const id = useSelector(selectReportId);

  const isDataLoaded =
    localParametersInitialised && Boolean(reportId) && Boolean(focalItem);

  const [
    getBasketQuantitiesSummaryReportletQuery,
    { data: response, isUninitialized, isFetching: isDataFetching },
  ] = useLazyGetBasketQuantitiesSummaryReportletQuery();

  const fetchBasketQuantitiesSummaryReportletQuery = useCallback(async () => {
    await getBasketQuantitiesSummaryReportletQuery({
      division,
      payload: {
        parameterSelections: getReportletLocalSelections(
          breakdown.value as string,
          location,
          focalItem,
          channel.value as string,
          promotion.value as string,
          segmentation.value as string,
          segment.value as string
        ),
        reportId,
      } as SummaryRequestDto,
    });
  }, [
    breakdown.value,
    channel.value,
    division,
    focalItem,
    getBasketQuantitiesSummaryReportletQuery,
    location,
    promotion.value,
    reportId,
    segment.value,
    segmentation.value,
  ]);

  useEffect(() => {
    if (reportId === id && localParametersInitialised) {
      fetchBasketQuantitiesSummaryReportletQuery().catch((error) => {
        ddLog(
          "Error retrieving basket quantities summary reportlet query data",
          {},
          "error",
          error
        );
      });
    }
  }, [
    fetchBasketQuantitiesSummaryReportletQuery,
    id,
    localParametersInitialised,
    reportId,
  ]);

  const noData =
    response === undefined ||
    response.series.every((metricSeries) =>
      metricSeries.data.every((dataPoint) => !dataPoint.metricValue)
    );

  const chartCategoriesData = useMemo(
    () => response?.categories.filter((x) => x !== "Total") ?? [],
    [response]
  );
  const allMetrics = useMemo(
    () =>
      response?.series.filter(
        (metricSeries) => metricSeries.breakdown === "Total"
      ) ?? [],
    [response]
  );

  const additionalMetrics = allMetrics
    .map((metric) => metric.metricLabel)
    // remove basket metrics (they are primary metrics only)
    // && only distinct values (% metrics have different variants for different breakdown values)
    .filter(
      (value, index, array) =>
        !["Baskets", "Baskets (%)"].includes(value) &&
        array.indexOf(value) === index
    );
  const additionalMetricEnabled = useSelector(isBreakdownByTotal);
  const additionalMetric =
    additionalMetrics.includes(additionalMetricState) && additionalMetricEnabled
      ? additionalMetricState
      : "";
  const additionalMetricKey = getViewAsMetricKey(
    additionalMetric,
    breakdown.value as string,
    segmentation.value as string
  );
  // primary metric is always Baskets
  const primaryMetricLabel = getViewAsMetricLabel("Baskets", viewAsShare);
  const primaryMetricKey = getViewAsMetricKey(
    primaryMetricLabel,
    breakdown.value as string,
    segmentation.value as string
  );
  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        response,
        breakdown.value as string,
        [primaryMetricKey, additionalMetricKey].filter(Boolean),
        currencySymbol
      ),
    [response, breakdown, primaryMetricKey, additionalMetricKey, currencySymbol]
  );
  // Summary data table always shows "Total"
  const dataTableCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        response,
        BreakdownByParameterId.Total,
        allMetrics.map((series) => series.metricKey),
        currencySymbol
      ),
    [response, allMetrics, currencySymbol]
  );
  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(() => {
    const convertDisplayTypeToString = () => {
      switch (displayType) {
        case SummaryDisplayType.CHART:
          return "Chart";
        case SummaryDisplayType.TABLE:
          return "Table";
        default:
          return "";
      }
    };

    return cleanFilename(
      `Basket_Quantities_Overview_${convertDisplayTypeToString()}_${timePeriodLength}_${
        location.name
      }`
    );
  }, [displayType, location.name, timePeriodLength]);

  const parameterSummary = useMemo(
    () => [
      { name: "Time", value: time },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
          location.name
        }`,
      },
      { name: "Product", value: focalItem.name },
      { name: "View as", value: viewAs.label },
      { name: "Breakdown", value: breakdown.label },
      { name: "Channel", value: channel.label },
      { name: "Promotion", value: promotion.label },
      { name: "Segmentation", value: segmentation.label },
      { name: "Customer segment", value: segment.label },
    ],
    [
      time,
      focalItem,
      viewAs,
      breakdown,
      channel,
      promotion,
      segmentation,
      segment,
      location,
    ]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showBasketQuantitiesSummaryChartDataLabels,
      value: "",
    },
  ];

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.basketQuantities.reportName,
  }));

  const chartSeries = useMemo(() => response?.series ?? [], [response?.series]);

  return (
    <ReportletAccordion
      subtitle="View the proportion of baskets split by product quantity."
      title="Basket quantities overview"
    >
      <NoDataChartWrapper
        isLoading={isUninitialized || isDataFetching}
        noData={isDataLoaded && noData}
      >
        <div className={styles.chartOptionsContainer}>
          <BasketQuantitiesSummaryMetrics
            additionalMetricDropdownValues={additionalMetrics}
            displayType={displayType}
            onDisplayTypeChanged={(type: SummaryDisplayType) => {
              setDisplayType(type);
            }}
          />
          {displayType === SummaryDisplayType.CHART ? (
            <ChartOptions
              dataLabelsOptions={dataLabelOptions}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.BasketQuantities} />
                      ),
                      chartTitle: `Basket quantities overview - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
              toggleDataLabels={() => {
                dispatch(toggleBasketQuantitiesSummaryChartDataLabels());
              }}
            />
          ) : (
            <DataTableOptions
              filename={exportFilename}
              invokeCSVDownload={dataTableCsvTransformationCallback}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
            />
          )}
        </div>
        {displayType === SummaryDisplayType.CHART ? (
          <ChartFooterWrapper
            height="400px"
            parameters={parameterSummary}
            ref={chartContainerRef}
          >
            <ErrorBoundary>
              {breakdown.value === BreakdownByParameterId.Total ? (
                <BasketQuantitiesSummaryChart
                  additionalMetricKey={additionalMetricKey}
                  categories={chartCategoriesData}
                  onOptionsChanged={setCurrentOptions}
                  primaryMetricKey={primaryMetricKey}
                  series={chartSeries}
                />
              ) : (
                <BasketQuantitiesSummaryBreakdownChart
                  basketSeries={chartSeries}
                  basketSizes={response?.categories ?? []}
                  metricKey={primaryMetricKey}
                  onOptionsChanged={setCurrentOptions}
                  viewAs={viewAs.value as string}
                />
              )}
            </ErrorBoundary>
          </ChartFooterWrapper>
        ) : (
          <ErrorBoundary>
            <BasketQuantitiesSummaryTable
              categories={response?.categories ?? []}
              series={allMetrics}
            />
          </ErrorBoundary>
        )}
      </NoDataChartWrapper>
    </ReportletAccordion>
  );
};
