import { type HierarchyGroupEvaluationType } from "@quantium-enterprise/common-ui";

export enum WatchlistItemType {
  Attribute = "attribute",
  Hierarchy = "hierarchy",
  ProductGroup = "product-group",
}

type BaseWatchlistItemDto = {
  entitlements: string[];
  id: string;
  name: string;
  type: WatchlistItemType;
};

export type WatchlistHierarchyItemDto = BaseWatchlistItemDto & {
  code: string;
  isLeaf?: boolean;
  shortName: string;
  type: WatchlistItemType.Hierarchy;
};

export type WatchlistAttributeItemDto = BaseWatchlistItemDto & {
  code: string;
  hierarchyCode?: string;
  hierarchyLevel?: string;
  hierarchyName?: string;
  shortName: string;
  type: WatchlistItemType.Attribute;
};

export type WatchlistProductGroupItemDto = BaseWatchlistItemDto & {
  evaluationType: HierarchyGroupEvaluationType;
  productGroupId: string;
  shortName: string;
  type: WatchlistItemType.ProductGroup;
};

export type WatchlistItemDto =
  | WatchlistAttributeItemDto
  | WatchlistHierarchyItemDto
  | WatchlistProductGroupItemDto;

type BaseAddWatchlistItemDto = {
  type: WatchlistItemType;
};

export type AddWatchlistHierarchyItemDto = BaseAddWatchlistItemDto & {
  code: string;
  shortName: string;
  type: WatchlistItemType.Hierarchy;
};

export type AddWatchlistAttributeItemDto = BaseAddWatchlistItemDto & {
  code: string;
  hierarchyCode?: string;
  hierarchyLevel?: string;
  shortName: string;
  type: WatchlistItemType.Attribute;
};

export type AddWatchlistProductGroupItemDto = BaseAddWatchlistItemDto & {
  productGroupId: string;
  type: WatchlistItemType.ProductGroup;
};

export type AddWatchlistItemDto =
  | AddWatchlistAttributeItemDto
  | AddWatchlistHierarchyItemDto
  | AddWatchlistProductGroupItemDto;
