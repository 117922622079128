import DecreaseIcon from "../../../../../dashboard/src/assets/growth-decrease.svg";
import IncreaseIcon from "../../../../../dashboard/src/assets/growth-increase.svg";
import NeutralIcon from "../../../../../dashboard/src/assets/growth-neutral.svg";
import styles from "./GraphicsCell.module.css";
import { ValueCell } from "./ValueCell";

export type GraphicsCellProps = {
  color?: string;
  formatter: Function;
  hoverOverflow?: boolean;
  justifyContent?: string;
  type?: string;
  value: number | string | null | undefined;
};

export enum GraphicsCellMetricType {
  Growth = "Growth",
  Percentile = "Percentile",
}

const PercentileTile = ({ color }: { color?: string }) => (
  <div className={styles.percentileTile} style={{ backgroundColor: color }} />
);

const DeltaIcon = ({ source }: { source: string }) => (
  <img alt="Growth delta icon" className={styles.icon} src={source} />
);

export const GraphicsCell = ({
  type,
  formatter,
  value,
  color,
  justifyContent = "flex-end",
  hoverOverflow = false,
}: GraphicsCellProps) => (
  <div className={styles.cellWrapper} style={{ justifyContent }}>
    {hoverOverflow ? (
      <ValueCell
        className={styles.valueCell}
        formatter={formatter}
        value={value}
      />
    ) : (
      <div className={styles.cellValue}>{formatter(value)}</div>
    )}
    {type === GraphicsCellMetricType.Growth && typeof value === "number" && (
      <>
        {value > 0 && <DeltaIcon source={IncreaseIcon} />}
        {value === 0 && <DeltaIcon source={NeutralIcon} />}
        {value < 0 && <DeltaIcon source={DecreaseIcon} />}
      </>
    )}
    {type === GraphicsCellMetricType.Percentile && (
      <PercentileTile color={color} />
    )}
  </div>
);
