import {
  getUserColour,
  getUserInitials,
  type SharedUserDto,
} from "@quantium-enterprise/common-ui";
import {
  Text,
  Tooltip,
  TooltipPlacement,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import classNames from "classnames";
import { ShareHorizontalMenuIcon } from "../assets/icons/ShareHorizontalMenuIcon";
import { CircleIcon, CircleIconSize } from "../icons";
import styles from "./SharedUserIconDisplay.module.css";

const MAX_DISPLAYED_USERS = 3;

export type SharedUserIconDisplayProps = {
  className?: string;
  users: SharedUserDto[];
};

export const SharedUserIconDisplay = ({
  className,
  users,
}: SharedUserIconDisplayProps) => (
  <>
    {users.length > 0 && (
      <Tooltip
        className={styles.tooltip}
        placement={TooltipPlacement.TopCentre}
        trigger={
          <div className={classNames(styles.iconDisplay, className)}>
            {users.slice(0, MAX_DISPLAYED_USERS).map((user) => (
              <span
                className={styles.iconWrapper}
                key={`user-icon-${user.salesforceUserId}`}
              >
                <CircleIcon
                  color={getUserColour(user.salesforceUserId)}
                  content={getUserInitials(user.firstName, user.lastName)}
                  size={CircleIconSize.Small}
                />
              </span>
            ))}
            {users.length > MAX_DISPLAYED_USERS && (
              <span key="user-icon-more">
                <CircleIcon
                  color={tokens.colour["shade-2"]}
                  content={
                    <div className={styles.horizontalMenuIcon}>
                      <ShareHorizontalMenuIcon />
                    </div>
                  }
                  size={CircleIconSize.Small}
                />
              </span>
            )}
          </div>
        }
        variant={TooltipVariant.ArrowDark}
      >
        <Text
          className={styles.tooltipText}
        >{`Shared with ${users.length} users`}</Text>
      </Tooltip>
    )}
  </>
);
