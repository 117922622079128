import { type GainsAndLossUniverseSummaryRequestDto } from "../models/GainsAndLossUniverseSummaryRequestDto";
import { type GainsAndLossUniverseSummaryResponseDto } from "../models/GainsAndLossUniverseSummaryResponseDto";
import { gainsAndLossApiSlice } from "./gains-and-loss-api-slice";

export const gainsAndLossUniverseSummarySlice =
  gainsAndLossApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getGainsAndLossUniverseSummary: builder.query<
        GainsAndLossUniverseSummaryResponseDto,
        {
          division: string;
          payload: GainsAndLossUniverseSummaryRequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetUniverseSummaryReportletData/${division}`,
        }),
      }),
    }),
  });

export const { useLazyGetGainsAndLossUniverseSummaryQuery } =
  gainsAndLossUniverseSummarySlice;
