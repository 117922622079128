type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};
export const CustomSegmentationIcon = ({
  className,
  coloured,
  text,
}: Props) => {
  const colour1 = coloured
    ? "var(--cg-custom-segmentation-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--cg-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 96 96"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Custom segmentation group"}</title>

      <g clipPath="url(#clip0_5885_109024)">
        <path
          d="M9 61.3024H11.5266C12.0788 61.3024 12.5266 61.7501 12.5266 62.3024V86.9998C12.5266 87.5521 12.9743 87.9998 13.5266 87.9998H29.1709C29.7232 87.9998 30.1709 87.5521 30.1709 86.9998V62.3024C30.1709 61.7501 30.6186 61.3024 31.1709 61.3024H33.6051C34.1574 61.3024 34.6051 60.8547 34.6051 60.3024V35.9708C34.6051 35.802 34.5649 35.637 34.477 35.4929C33.4825 33.8613 30.3036 30.3555 21.4873 30.3555C12.6279 30.3555 9.51777 33.4992 8.16419 35.4679C8.05447 35.6275 8 35.8166 8 36.0102V60.3024C8 60.8547 8.44772 61.3024 9 61.3024Z"
          fill={colour2}
        />
        <circle cx="21.2098" cy="16.9607" fill={colour2} r="8.96073" />
        <path
          d="M65.9276 23.4219H46.2609C43.5567 23.4219 41.3688 25.6344 41.3688 28.3385L41.3442 67.6719C41.3442 70.376 43.5322 72.5885 46.2363 72.5885H75.7609C78.4651 72.5885 80.6776 70.376 80.6776 67.6719V38.1719L65.9276 23.4219ZM75.7609 67.6719H46.2609V28.3385H63.4692V40.6302H75.7609V67.6719ZM51.1776 55.4048L54.6438 58.871L58.5526 54.9869V65.2135H63.4692V54.9869L67.378 58.8956L70.8442 55.4048L61.0355 45.5469L51.1776 55.4048Z"
          fill={colour1}
        />
      </g>
      <defs>
        <clipPath id="clip0_5885_109024">
          <rect
            fill="white"
            height="80"
            transform="translate(8 8)"
            width="80"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
