import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { TestId } from "../constants";
import { type Item } from "../models/Item";
import { DraggableItem } from "./DraggableItem";
import { HierarchyItem } from "./HierarchyItem";
import styles from "./HierarchyItem.module.css";
import { RemoveIcon } from "./RemoveIcon";

export type RemovableItemProps = {
  item: Item;
  onRemove: (item: Item) => void;
};

export const RemovableItem = ({ item, onRemove }: RemovableItemProps) => (
  <div className={styles.removableItem}>
    {item.type === HierarchyItemType.Attribute ? (
      <div data-testid={TestId.DraggableItem}>
        <DraggableItem item={item} />
      </div>
    ) : (
      <div data-testid={TestId.NonDraggableItem}>
        <HierarchyItem {...item} isDraggable={false} />
      </div>
    )}
    <button
      className={styles.removableItemBtn}
      data-testid={TestId.RemoveIcon}
      onClick={() => {
        onRemove(item);
      }}
      type="button"
    >
      <RemoveIcon />
    </button>
  </div>
);
