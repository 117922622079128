import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  Tooltip,
  TooltipSpaceInside,
  TooltipPlacement,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import {
  type CellContext,
  type ColumnDef,
  type ColumnResizeMode,
  type Row,
  type Table,
} from "@tanstack/react-table";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../../store";
import { type CrossShopTableRow } from "../../models/CrossShopDataTableResponseDto";
import {
  selectFocalItems,
  setFocalItems,
} from "../../services/customer-cross-shop-slice";
import styles from "./CrossShopTopDrawerProductTable.module.css";

const MAX_SELECTIONS = 3;

const columnResizeMode: ColumnResizeMode = "onChange";

const getRowId = (row: CrossShopTableRow) => row.item.itemCode;

export type CrossShopTopDrawerProductTableProps = {
  isQuerySuccess: boolean;
};

export const CrossShopTopDrawerProductTable = ({
  isQuerySuccess,
}: CrossShopTopDrawerProductTableProps) => {
  const dispatch = useDispatch();
  const formatter = useFormatter();

  const focalItems = useSelector(selectFocalItems);

  const { metrics, rowSelection, tableRows } = useSelector(
    (state: RootState) => ({
      metrics: state.customerCrossShop.topDrawerTableData.metrics,
      rowSelection: state.customerCrossShop.rowSelection,
      tableRows: state.customerCrossShop.topDrawerTableData.tableRows,
    })
  );

  const getIsRowDisabled = useCallback(
    (row: Row<CrossShopTableRow>, table: Table<CrossShopTableRow>) =>
      !table.getState().rowSelection[row.id] &&
      focalItems.length >= MAX_SELECTIONS,
    [focalItems]
  );

  const createNameCell = useCallback(
    ({ row, table }: CellContext<CrossShopTableRow, unknown>) => {
      const isRowDisabled = getIsRowDisabled(row, table);

      const cellBody = (
        <ExpandableNameCell
          canExpand={false}
          depth={0}
          handleToggleExpanded={row.getToggleExpandedHandler()}
          handleToggleSelected={(event) => {
            row.getToggleSelectedHandler()(event);
            dispatch(
              setFocalItems({
                selectedItem: row.original.item,
                isSelected: row.getIsSelected(),
              })
            );
          }}
          hideTooltip
          isCheckboxDisabled={isRowDisabled}
          isExpanded={false}
          isSelected={row.getIsSelected()}
          name={row.original.item.name}
          shortName={row.original.item.shortName}
          type={HierarchyItemType.Hierarchy}
          value={row.original.item.name}
        />
      );

      return isRowDisabled ? (
        <Tooltip
          placement={TooltipPlacement.RightCentre}
          spaceInside={TooltipSpaceInside.Medium}
          trigger={<div>{cellBody}</div>}
          variant={TooltipVariant.ArrowDark}
        >
          <div className={styles.disabledRowTooltipContent}>
            Selection limit reached. Please deselect an item to enable
            selection.
          </div>
        </Tooltip>
      ) : (
        cellBody
      );
    },
    [dispatch, getIsRowDisabled]
  );

  const metricColumnsDefs = useMemo(
    (): Array<ColumnDef<CrossShopTableRow>> =>
      metrics.map((metric, index) => ({
        accessorKey: metric.displayName,
        cell: ({ row }) =>
          ValueCell({
            formatter: formatter(metric.format),
            value: row.original.metricValues[index],
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        header: metric.displayName,
        id: index + metric.displayName,
      })),
    [formatter, metrics]
  );

  const columnDefs: Array<ColumnDef<CrossShopTableRow>> = useMemo(
    () => [
      {
        accessorKey: "name",
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        header: "Search",
      },
      ...metricColumnsDefs,
    ],
    [metricColumnsDefs, createNameCell]
  );

  return (
    <ReportHierarchyTableWrapper isSuccess={isQuerySuccess}>
      <ReportHierarchyTable
        columnResizeMode={columnResizeMode}
        columns={columnDefs}
        data={tableRows}
        getIsRowDisabled={getIsRowDisabled}
        getRowId={getRowId}
        getSubRows={() => undefined}
        pinFirstColumn
        rowExpandedState={{ "0": true }}
        rowSelectionState={rowSelection}
      />
    </ReportHierarchyTableWrapper>
  );
};
