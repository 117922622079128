import { AppContext } from "@quantium-enterprise/common-ui";
import { type SerializedError } from "@reduxjs/toolkit";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { lazy, useContext, useEffect } from "react";
import { ProtectedRoute } from "./ProtectedRoute";

// eslint-disable-next-line arrow-body-style, @typescript-eslint/promise-function-async -- this is as per React docs
const Admin = lazy(() => import("./Admin"));

const notLoggedInRedirectIfError = (
  error: Error | FetchBaseQueryError | SerializedError | object | undefined
) => {
  if (
    error &&
    (("status" in error && error.status === 401) ||
      ("originalStatus" in error && error.originalStatus === 401))
  ) {
    window.location.assign(
      `/sso-proxy/challenge-with-fragment?redirectUri=${encodeURIComponent(
        window.location.href
      )}`
    );
  }
};

const AdminApp = () => {
  const { userState } = useContext(AppContext);

  useEffect(() => {
    notLoggedInRedirectIfError(userState.error);
  }, [userState.error, userState.isError]);

  return (
    <ProtectedRoute
      isAllowed={userState.currentUser?.isAdmin ?? false}
      isError={userState.isError}
      isLoading={userState.isLoading}
    >
      <Admin user={userState.currentUser} />
    </ProtectedRoute>
  );
};

export default AdminApp;
