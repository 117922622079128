import { type SegmentationType } from "../enums";
import { type SelectionDto } from "./selection-dto";

export type SegmentationSelectionDto = {
  id: string;
  name: string;
  segmentationType: SegmentationType;
  segments?: SegmentValueDto[];
};

export type SegmentValueDto = {
  key: string;
  name: string;
  ordinal: number;
};

export const isSegmentationSelectionDto = (
  selectionObject: SelectionDto
): selectionObject is SegmentationSelectionDto =>
  "segmentationType" in selectionObject;
