import { type SidePanelTimePeriodParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";

export const timeData = (time: SidePanelTimePeriodParameter | undefined) => {
  if (time) {
    return getTimeDefaultSelection(time);
  }

  return { time: "", timePeriodLength: "" };
};
