import { formatShortDate } from "@quantium-enterprise/common-ui";
import { Icon, IconColour, IconGlyph } from "@quantium-enterprise/qds-react";
import { Link } from "react-router-dom";
import styles from "./CustomerGroupSegments.module.css";

export type ValidSegmentCardProps = {
  activationGuidelinesRefreshDateUtc?: string;
  onClick: () => void;
};

export const ValidSegmentCard = ({
  onClick,
  activationGuidelinesRefreshDateUtc,
}: ValidSegmentCardProps) => {
  const activationGuidelinesRefreshDate = activationGuidelinesRefreshDateUtc
    ? formatShortDate(activationGuidelinesRefreshDateUtc)
    : "";
  return (
    <div className={[styles.cardContainer, styles.validContainer].join(" ")}>
      <Icon
        colour={IconColour.Good}
        glyph={IconGlyph.SelectionCheckCircle}
        text="validated-icon"
      />
      <div className={styles.validContent}>
        <div className={styles.cardHeader}>
          Validated {activationGuidelinesRefreshDate}
        </div>
        <Link onClick={() => onClick()} to="">
          <div className={styles.linkText}>Learn more</div>
        </Link>
      </div>
    </div>
  );
};
