import {
  type HierarchyValueDto,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";

export const getParameterSummaryValueStrings = (
  channel: PanelOption,
  location: LocalHierarchyNodeSelection,
  metricSet: PanelOption,
  segment: PanelOption,
  segmentation: PanelOption,
  time: string,
  focalItem?: HierarchyValueDto
) => [
  {
    name: "Focal item",
    value: `(${hierarchyLevelDisplayLabel(focalItem?.shortName ?? "")}) ${
      focalItem?.name
    }`,
  },
  {
    name: "Channel",
    value: channel.label,
  },
  {
    name: "Location",
    value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
      location.name
    }`,
  },
  {
    name: "Metrics",
    value: metricSet.label,
  },
  {
    name: "Segmentation",
    value: segmentation.label,
  },
  {
    name: "Customer segment",
    value: segment.label,
  },
  {
    name: "Time",
    value: time,
  },
];
