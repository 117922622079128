export const LevelOfAnalysis = ({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7244 12.3059C14.0356 11.8294 14.2222 11.2559 14.2222 10.6471C14.2222 8.94412 12.8267 7.55882 11.1111 7.55882C9.39556 7.55882 8 8.94412 8 10.6471C8 12.35 9.39556 13.7353 11.1111 13.7353C11.7244 13.7353 12.3022 13.55 12.7822 13.2412L15.0578 15.5L16 14.5647L13.7244 12.3059ZM11.1111 12.4118C10.1333 12.4118 9.33333 11.6176 9.33333 10.6471C9.33333 9.67647 10.1333 8.88235 11.1111 8.88235C12.0889 8.88235 12.8889 9.67647 12.8889 10.6471C12.8889 11.6176 12.0889 12.4118 11.1111 12.4118ZM14.9156 0.5L16 1.26765L12.5511 6.47353C12.0978 6.31471 11.6178 6.23529 11.1111 6.23529L14.9156 0.5ZM11.1111 6.23529C10.5956 6.23529 10.1067 6.32353 9.64444 6.48235L8.95111 5.51176L5.92 10.2412L3.71556 7.62059L1.08444 11.8647L0 11.0882L3.55556 5.35294L5.77778 8L8.88889 3.14706L11.1111 6.23529Z"
      fill="#B5B5B5"
    />
  </svg>
);
