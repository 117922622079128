import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isHierarchySelectionParameter,
  isLocalHierarchySelection,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import { type LocalParameterValues } from "../models/LocalParameterValues";

export const getLocalParameterValues = (
  localParameters: SidePanelParameter[]
): LocalParameterValues => {
  const localParameterValues: LocalParameterValues = {
    locationString: "",
    time: "",
    timePeriodLength: "",
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        if (isTimePeriodParameter(parameter)) {
          const { time, timePeriodLength } = getTimeDefaultSelection(parameter);
          localParameterValues.time = time;
          localParameterValues.timePeriodLength = timePeriodLength;
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (isHierarchySelectionParameter(parameter)) {
          const location = parameter.selections
            .map(
              (selection) =>
                isLocalHierarchySelection(selection) &&
                `(${selection.hierarchyItem.shortName}) ${selection.hierarchyItem.name}`
            )
            .join(", ");
          localParameterValues.locationString = location;
        }

        break;
      default:
        break;
    }
  }

  return localParameterValues;
};
