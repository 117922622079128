import { type LocalParameterDto } from "@quantium-enterprise/common-ui";
import { type SidePanelParameter } from "../../models/local-parameters/SidePanelParameters";
import {
  isGroupParameter,
  isHierarchySelectionParameter,
  isMetricWithFormatParameter,
  isMultiCheckboxParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "./LocalParametersUtils";

export const getReportLocalParameters = (
  localParameters?: LocalParameterDto[]
): SidePanelParameter[] => {
  if (!localParameters) {
    return [];
  }

  return localParameters.map((localParameter) => {
    const parameter = {
      displayType: localParameter.displayType,
      id: localParameter.id,
      isDisabled: localParameter.isDisabled,
      name: localParameter.name,
      selections: [],
    };
    if (isGroupParameter(localParameter)) {
      return {
        ...parameter,
        selections: localParameter.selections,
      };
    } else if (isHierarchySelectionParameter(localParameter)) {
      return {
        ...parameter,
        maxSelections: localParameter.maxSelections,
        selections: localParameter.selections,
      };
    } else if (isSegmentationParameter(localParameter)) {
      return {
        ...parameter,
        selections: localParameter.selections,
      };
    } else if (isMultiCheckboxParameter(localParameter)) {
      return {
        ...parameter,
        maxSelections: localParameter.maxSelections,
        selections: localParameter.selections,
      };
    } else if (isSingleSelectionParameter(localParameter)) {
      return {
        ...parameter,
        maxSelections: localParameter.maxSelections,
        selections: localParameter.selections,
      };
    } else if (isTimePeriodParameter(localParameter)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { selections, ...newTimeParameter } = {
        ...parameter,
        comparisonPeriod: localParameter.comparisonPeriod,
        focusPeriod: localParameter.focusPeriod,
        rollingPeriod: localParameter.rollingPeriod,
        leadPeriod: localParameter.leadPeriod,
      };
      return newTimeParameter;
    } else if (isPlainTextContentParameter(localParameter)) {
      return {
        ...parameter,
        plainTextContent: localParameter.plainTextContent,
      };
    } else if (isMetricWithFormatParameter(localParameter)) {
      return {
        ...parameter,
        selections: localParameter.selections,
      };
    } else {
      return parameter;
    }
  });
};
