import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type LocationHierarchyGridItem } from "../../../hierarchy-select-grid/models/hierarchy";

/**
 *
 * @param rows all rows of the hierarchy grid
 * @returns an array of parent rows for the expanded state
 */
export const getParentRows = (rows: LocalHierarchyNodeSelection[]) => {
  const parents = new Set<LocalHierarchyNodeSelection>();
  for (const row of rows) {
    if (row.parentNodeNumber !== undefined) {
      parents.add(row);
    }
  }

  return Array.from(parents);
};

export const getFullHierarchyForLocation = (
  node: LocalHierarchyNodeSelection,
  allNodes: LocalHierarchyNodeSelection[]
) => {
  const selectedItemFullHierarchy = [node];

  let current = node;
  while (current.parentNodeNumber !== undefined) {
    const parent = allNodes.find(
      // eslint-disable-next-line @typescript-eslint/no-loop-func -- needed for recursion
      (sel) => sel.nodeNumber === current.parentNodeNumber
    );
    if (parent) {
      current = parent;
      selectedItemFullHierarchy.push(current);
    }
  }

  return selectedItemFullHierarchy;
};

const convertToHierarchyItems = (
  root: LocalHierarchyNodeSelection,
  children: LocalHierarchyNodeSelection[][]
) => {
  const subRows: LocationHierarchyGridItem[] = children[root.nodeNumber]?.map(
    (child) => convertToHierarchyItems(child, children)
  );
  return {
    ...root,
    subRows,
  } as LocationHierarchyGridItem;
};

export const buildNestedSelections = (
  allSelections: LocalHierarchyNodeSelection[]
) => {
  const children: LocalHierarchyNodeSelection[][] = [];
  const roots: LocalHierarchyNodeSelection[] = [];
  for (const sel of allSelections) {
    if (sel.parentNodeNumber === undefined) {
      roots.push(sel);
      // linter did this, not me (grouping the 'else' conditions into 'else if')
    } else if (children[sel.parentNodeNumber]) {
      children[sel.parentNodeNumber].push(sel);
    } else {
      children[sel.parentNodeNumber] = [sel];
    }
  }

  const hierarchyTree = roots.map((root) =>
    convertToHierarchyItems(root, children)
  );
  return hierarchyTree;
};
