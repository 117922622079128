import { type HierarchyGroupRuleWithIdAndName } from "@quantium-enterprise/common-ui";

export const areFiltersEqual = (
  filters1: HierarchyGroupRuleWithIdAndName[],
  filters2: HierarchyGroupRuleWithIdAndName[]
): boolean => {
  if (filters1.length !== filters2.length) {
    return false;
  }

  for (const [index, filter1] of filters1.entries()) {
    const filter2 = filters2[index];

    if (filter1.id !== filter2.id) {
      return false;
    }

    if (filter1.operator !== filter2.operator) {
      return false;
    }

    if (filter1.shortName !== filter2.shortName) {
      return false;
    }

    if (filter1.values.length !== filter2.values.length) {
      return false;
    }

    for (let index2 = 0; index2 < filter1.values.length; index2++) {
      const value1 = filter1.values[index2];
      const value2 = filter2.values[index2];

      if (value1.code !== value2.code) {
        return false;
      }

      if (value1.name !== value2.name) {
        return false;
      }
    }
  }

  return true;
};
