import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { BubbleChart } from "components-ui/src/charts/bubble-chart/BubbleChart";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import {
  type PlotBubbleOptions,
  type SeriesLineOptions,
  type SeriesBubbleOptions,
  type SeriesLegendItemClickCallbackFunction,
} from "highcharts";
import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../store";
import { type PercentileSeries } from "../models/PercentileSeries";
import { type ProductSubstitutabilityHierarchyProductData } from "../models/ProductSubstitutabilityHierarchyProductData";
import {
  DEFAULT_BUBBLE_SIZE,
  onUniquenessChartPercentileLegendUpdate,
} from "../services/product-substitutability-slice";
import { getPercentileConfig } from "../utils/getPercentileConfig";
import { NONE_OPTION } from "./ProductUniquenessReportletFilterGroup";

type ProductUniquenessReportletBubbleChartProps = {
  onChartSeriesUpdate: (
    data: Array<SeriesBubbleOptions | SeriesLineOptions>
  ) => void;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
};

export const ProductUniquenessReportletBubbleChart = ({
  onChartSeriesUpdate,
  onOptionsChanged,
}: ProductUniquenessReportletBubbleChartProps) => {
  const formatter = useFormatter();
  const dispatch = useDispatch();

  const {
    focalItemsData,
    xAxisSelection,
    yAxisSelection,
    bubbleSizeSelection,
    legendSelection,
    metrics,
    attributes,
    percentiles,
    showUniquenessChartDataLabels,
  } = useSelector((state: RootState) => ({
    focalItemsData: state.productSubstitutability.focalItemsData,
    localParametersInitialised:
      state.productSubstitutability.localParametersInitialised,
    metrics: state.productSubstitutability.focalItemTableMetrics,
    attributes: state.productSubstitutability.attributes,
    xAxisSelection: state.productSubstitutability.xAxisSelection,
    yAxisSelection: state.productSubstitutability.yAxisSelection,
    bubbleSizeSelection: state.productSubstitutability.bubbleSizeSelection,
    legendSelection: state.productSubstitutability.legendSelection,
    percentiles: state.productSubstitutability.percentiles,
    showUniquenessChartDataLabels:
      state.productSubstitutability.showUniquenessChartDataLabels,
  }));

  const xAxisMetric = useMemo(() => {
    const metric = metrics.find((mt) => mt.key === xAxisSelection.value);
    return metric
      ? {
          ...metric,
          formatter: formatter(metric.format),
          label: metric.displayName,
          name: metric.displayName,
        }
      : {
          format: "Decimal",
          formatter: formatter(MetricTypes.Decimal),
          label: "Uniqueness",
          name: "Uniqueness",
        };
  }, [formatter, metrics, xAxisSelection]);

  const yAxisMetric = useMemo(() => {
    const metric = metrics.find((mt) => mt.key === yAxisSelection.value);

    return metric
      ? {
          ...metric,
          formatter: formatter(metric.format),
          label: metric.displayName,
          name: metric.displayName,
        }
      : {
          format: "Percentage",
          formatter: formatter(MetricTypes.Percentage),
          label: "Sales",
          name: "Sales",
        };
  }, [formatter, metrics, yAxisSelection]);

  const zAxisMetric = useMemo(() => {
    const metric = metrics.find((mt) => mt.key === bubbleSizeSelection.value);

    return metric
      ? {
          ...metric,
          formatter: formatter(metric.format),
          label: metric.displayName,
          name: metric.displayName,
        }
      : undefined;
  }, [formatter, metrics, bubbleSizeSelection]);

  const { legendSelectionIndex, legendSelectionName } = useMemo(() => {
    const attributeIndex = attributes.findIndex(
      (attribute) => attribute.shortName === legendSelection.value
    );
    return {
      legendSelectionIndex: attributeIndex,
      legendSelectionName: attributes[attributeIndex]?.displayName || "",
    };
  }, [attributes, legendSelection]);

  const seriesWithPlotOptionsAndAxisConfigs: {
    plotOptions: PlotBubbleOptions;
    series: Array<SeriesBubbleOptions | SeriesLineOptions>;
  } = useMemo(() => {
    const isNoneOption: boolean = bubbleSizeSelection.value === NONE_OPTION.key;

    // find selected x and y axes
    const xAxisIndex = metrics.findIndex(
      (metric) => metric.key === xAxisSelection.value
    );
    const yAxisIndex = metrics.findIndex(
      (metric) => metric.key === yAxisSelection.value
    );

    // find selected bubble size axis
    let zAxisIndex = -1;
    if (!isNoneOption) {
      zAxisIndex = metrics.findIndex(
        (metric) => metric.key === bubbleSizeSelection.value
      );
    }

    // arrays to collect all x, y, and z values
    const allXValues = [];
    const allYValues = [];
    const allZValues = [];

    const groupedData: Record<string, SeriesBubbleOptions> = {};
    for (const item of focalItemsData) {
      const key = item.attributeValues[legendSelectionIndex];
      const xValue = item.metricValues[xAxisIndex];
      const yValue = item.metricValues[yAxisIndex];

      let entry: {
        custom: { item: ProductSubstitutabilityHierarchyProductData };
        name: string;
        x: number;
        y: number;
        z?: number;
      } = {
        x: xValue,
        y: yValue,
        name: item.item.name,
        custom: { item },
      };

      let zValue = DEFAULT_BUBBLE_SIZE;
      if (zAxisIndex !== -1) {
        zValue = item.metricValues[zAxisIndex];
      }

      // add x, y, and z value to arrays to find min and max values later
      allXValues.push(xValue);
      allYValues.push(yValue);
      allZValues.push(zValue);

      // eslint-disable-next-line id-length
      entry = { ...entry, z: zValue };

      if (key in groupedData) {
        const groupEntry = groupedData[key];
        if (groupEntry.data) groupEntry.data.push(entry);
      } else {
        groupedData[key] = {
          name: key,
          type: "bubble",
          data: [entry],
        };
      }
    }

    // find min and max values across all metrics to set the x-axis, y-axis and z-axis range
    const xMax = Math.ceil(Math.max(...allXValues.filter(Boolean)));
    const xMaxPadding = xMax + xMax * (10 / 100);
    const xAxisExtendedConfig = {
      min: 0,
      max: xMax,
      maxPadding: xMaxPadding,
    };

    const yMax = Math.ceil(Math.max(...allYValues.filter(Boolean)));
    const yMaxPadding = yMax + yMax * (10 / 100);
    const yAxisExtendedConfig = {
      min: 0,
      max: yMax,
      maxPadding: yMaxPadding,
    };

    const zMin = Math.min(...allZValues.filter(Boolean));
    const zMax = Math.max(...allZValues.filter(Boolean));
    const plotOptions: PlotBubbleOptions = {
      zMax,
      zMin,
      minSize:
        bubbleSizeSelection.value === "None" ? DEFAULT_BUBBLE_SIZE : "10%",
      maxSize:
        bubbleSizeSelection.value === "None" ? DEFAULT_BUBBLE_SIZE : "50%",
    };

    const dataSeries = Object.values(groupedData);

    // Y Axis
    const yAxisSelectedPercentile = percentiles.find(
      (pc) => pc.key === yAxisSelection.value
    );

    const yPercentiles: PercentileSeries[] = yAxisSelectedPercentile
      ? yAxisSelectedPercentile.data.map((percentile) => ({
          name: percentile.name,
          key: yAxisSelectedPercentile.key,
          visible: percentile.visible,
          data: [
            [0, percentile.value],
            [xMax + xMaxPadding, percentile.value],
          ],
        }))
      : [];

    const yPercentilesChartAxises: SeriesLineOptions[] =
      getPercentileConfig(yPercentiles);

    // X Axis
    const xAxisSelectedPercentile = percentiles.find(
      (pc) => pc.key === xAxisSelection.value
    );

    const xPercentiles: PercentileSeries[] = xAxisSelectedPercentile
      ? xAxisSelectedPercentile.data.map((percentile) => ({
          name: percentile.name,
          key: xAxisSelectedPercentile.key,
          visible: percentile.visible,
          data: [
            [percentile.value, 0],
            [percentile.value, yMax + yMaxPadding],
          ],
        }))
      : [];

    const xPercentilesChartAxises: SeriesLineOptions[] = getPercentileConfig(
      xPercentiles,
      true
    );

    // Combine data series with percentile series
    const chartSeriesWithPercentiles: Array<
      SeriesBubbleOptions | SeriesLineOptions
    > = [...dataSeries, ...yPercentilesChartAxises, ...xPercentilesChartAxises];

    return {
      series: chartSeriesWithPercentiles,
      plotOptions,
      xAxisExtendedConfig,
      yAxisExtendedConfig,
    };
  }, [
    bubbleSizeSelection,
    focalItemsData,
    legendSelectionIndex,
    metrics,
    percentiles,
    xAxisSelection,
    yAxisSelection,
  ]);

  useEffect(() => {
    onChartSeriesUpdate([...seriesWithPlotOptionsAndAxisConfigs.series]);
  }, [onChartSeriesUpdate, seriesWithPlotOptionsAndAxisConfigs]);

  const lineLegendItemClick: SeriesLegendItemClickCallbackFunction =
    useCallback(
      (event) => {
        dispatch(onUniquenessChartPercentileLegendUpdate(event.target));
      },
      [dispatch]
    );

  return (
    <BubbleChart
      legendName={legendSelectionName}
      lineLegendItemClick={lineLegendItemClick}
      onOptionsChanged={onOptionsChanged}
      showChartDataLabels={showUniquenessChartDataLabels}
      xAxisMetric={xAxisMetric}
      yAxisMetric={yAxisMetric}
      zAxisMetric={zAxisMetric}
      {...seriesWithPlotOptionsAndAxisConfigs}
    />
  );
};
