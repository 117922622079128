import {
  Button,
  ButtonVariant,
  Text,
  Icon,
  IconGlyph,
  ButtonGroup,
  ButtonGroupVariant,
} from "@quantium-enterprise/qds-react";
import { format } from "date-fns";
import { useState } from "react";
import {
  type CaptionProps,
  useNavigation,
  useDayPicker,
} from "react-day-picker";
import styles from "./CustomCaption.module.css";
import { MonthPicker } from "./month-picker/MonthPicker";
import { YearPicker } from "./year-picker/YearPicker";

export enum ViewState {
  Datepicker = "Datepicker",
  Monthpicker = "Monthpicker",
  Yearpicker = "Yearpicker",
}

export const CustomCaption = (captionProps: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth, goToDate } = useNavigation();
  const [viewState, setViewState] = useState(ViewState.Datepicker);

  // use this hook to access props
  const { fromDate, toDate } = useDayPicker();

  const changeToMonthPicker = () => {
    setViewState(ViewState.Monthpicker);
  };

  const changeToYearPicker = () => {
    setViewState(ViewState.Yearpicker);
  };

  const handleMonthSelect = (newDate: Date) => {
    goToDate(newDate);
    setViewState(ViewState.Datepicker);
  };

  const handleYearSelect = (newDate: Date) => {
    goToDate(newDate);
    setViewState(ViewState.Monthpicker);
  };

  return (
    <div>
      {viewState !== ViewState.Monthpicker &&
        viewState !== ViewState.Yearpicker && (
          <div className={styles.datePickerNavbar}>
            <Button
              className={styles.datePickerCustomButton}
              onClick={changeToMonthPicker}
              variant={ButtonVariant.Stealth}
            >
              <Text>{format(captionProps.displayMonth, "MMM yyyy")}</Text>
              <Icon glyph={IconGlyph.ArrowsDropdown} text="Select Month" />
            </Button>
            <ButtonGroup
              className={styles.navButtonGroup}
              variant={ButtonGroupVariant.Joined}
            >
              <Button
                disabled={!previousMonth}
                onClick={() => previousMonth && goToMonth(previousMonth)}
                variant={ButtonVariant.Stealth}
              >
                <Icon
                  glyph={IconGlyph.ArrowsChevronLeft}
                  text="Previous Month"
                />
              </Button>
              <Button
                disabled={!nextMonth}
                onClick={() => nextMonth && goToMonth(nextMonth)}
                variant={ButtonVariant.Stealth}
              >
                <Icon glyph={IconGlyph.ArrowsChevronRight} text="Next Month" />
              </Button>
            </ButtonGroup>
          </div>
        )}

      {viewState === ViewState.Monthpicker && (
        <div className={styles.customCaptionContainer}>
          <Button
            className={styles.changeToYearPickerBtn}
            onClick={changeToYearPicker}
            variant={ButtonVariant.Stealth}
          >
            <Text>{format(captionProps.displayMonth, "yyyy")}</Text>
            <Icon glyph={IconGlyph.ArrowsDropdown} text="Select Year" />
          </Button>

          <MonthPicker
            displayMonth={captionProps.displayMonth}
            fromDate={fromDate}
            onSelect={handleMonthSelect}
            toDate={toDate}
          />
        </div>
      )}

      {viewState === ViewState.Yearpicker && (
        <div className={styles.customCaptionContainer}>
          <Button
            className={styles.changeToMonthPickerBtn}
            onClick={changeToMonthPicker}
            variant={ButtonVariant.Stealth}
          >
            <Text>{format(captionProps.displayMonth, "MMMM")}</Text>
            <Icon glyph={IconGlyph.ArrowsDropdown} text="Select Month" />
          </Button>
          <YearPicker
            displayMonth={captionProps.displayMonth}
            fromDate={fromDate ?? new Date()}
            onSelect={handleYearSelect}
            toDate={toDate}
          />
        </div>
      )}
    </div>
  );
};

export default CustomCaption;
