import { format, parse } from "date-fns";
import { type TrendsChartResponseDto } from "./models/ChartResponseDto";
import {
  columnHeadersTransform,
  seriesTransform,
} from "./utils/getExportFunction";

export const csvTransformation = (
  response: TrendsChartResponseDto | undefined,
  metricSelection: Array<string | undefined>,
  currency: string
) => {
  let columnHeaders = ["Date"];
  let csvData = [columnHeaders];
  if (response) {
    const indexes =
      metricSelection.length === 2
        ? [
            response.metricsMetadata.findIndex(
              (metric) => metric.metricLabel === metricSelection[0]
            ),
            response.metricsMetadata.findIndex(
              (metric) => metric.metricLabel === metricSelection[1]
            ),
          ]
        : [
            response.metricsMetadata.findIndex(
              (metric) => metric.metricLabel === metricSelection[0]
            ),
          ];
    const metrics = indexes.map((index) => response.metricsMetadata[index]);
    columnHeaders = columnHeaders.concat(
      response.productData.flatMap((data) =>
        columnHeadersTransform(data.name, metrics, currency)
      )
    );
    csvData = [columnHeaders];
    const series = response.productData.flatMap((element) =>
      seriesTransform(element.data, indexes)
    );
    for (let index = 0; index < response.promoWeeks.length; ++index) {
      const date = response.promoWeeks[index];
      const row = [
        format(parse(date.toString(), "yyyyMMdd", new Date()), "yyyy-MM-dd"),
      ];
      series.map((value) => row.push(value[index]));
      csvData.push(row);
    }
  }

  return csvData;
};
