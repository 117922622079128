import {
  MetricIdTypes,
  measureLabels,
} from "../models/GainsAndLossReportValues";

const createExportColumnHeader = (
  measure: string,
  metricId: string,
  currencySymbol: string
): string => {
  const measureHeader =
    measure === measureLabels.Sales
      ? `${measure} (${currencySymbol})`
      : measure;
  const columnHeader = `${measureHeader} - ${metricId}`;
  return columnHeader;
};

const createColumnHeader = (
  measure: string,
  currencySymbol: string
): string[] => {
  const columnGroup: string[] = [
    createExportColumnHeader(measure, MetricIdTypes.Net, currencySymbol),
    createExportColumnHeader(measure, MetricIdTypes.Gains, currencySymbol),
    createExportColumnHeader(measure, MetricIdTypes.Loss, currencySymbol),
  ];
  return columnGroup;
};

export const generateDataTableColumnsForExport = (
  currencySymbol: string
): string[] => {
  const columns = createColumnHeader(measureLabels.Sales, currencySymbol)
    .concat(createColumnHeader(measureLabels.Units, currencySymbol))
    .concat(createColumnHeader(measureLabels.Volume, currencySymbol));
  return columns;
};
