import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type ExportFocalItemRequest } from "../models/CustomerLoyaltyTableRowsRequestDto";
import { customerLoyaltyApiSlice } from "./customer-loyalty-api-slice";

export const customerLoyaltyExportApiSlice =
  customerLoyaltyApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportFocalItemRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/ExportToCsv/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `customer loyalty focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const { useLazyDownloadTableQuery } = customerLoyaltyExportApiSlice;
