import { flexRender, type Table } from "@tanstack/react-table";
import classNames from "classnames";
import {
  DEFAULT_COLUMN_MAX_WIDTH,
  DEFAULT_COLUMN_MIN_WIDTH,
} from "components-ui/src/tables/common/constants";
import { type CSSProperties } from "react";
import styles from "./ItemTable.module.css";

export enum ItemTableVariant {
  Default = 1,
  Grow = 2,
}

export type ItemTableProps<T> = {
  className?: string;
  heading: string;
  pinFirstColumn?: boolean;
  pinnedColumnStyles?: CSSProperties;
  table: Table<T>;
  variant?: ItemTableVariant;
};

export const ItemTable = <T,>({
  heading,
  table,
  className,
  pinFirstColumn,
  pinnedColumnStyles,
  variant = ItemTableVariant.Default,
}: ItemTableProps<T>) => (
  <div
    className={classNames(
      styles.itemTableWrapper,
      { [styles.flexGrow]: variant === ItemTableVariant.Grow },
      className
    )}
  >
    <div
      className={classNames(styles.itemTableHeader, {
        [styles.pinned]: pinFirstColumn,
      })}
    >
      <div className={styles.leftContent}>
        <h3 className={styles.heading}>{heading}</h3>
      </div>
      <div className={styles.rightContent} />
    </div>
    <div
      className={classNames(styles.itemTableFlexWrapper, {
        [styles.overflow]: pinFirstColumn,
      })}
    >
      <table className={styles.itemTable}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} role="row">
              {headerGroup.headers.map((header, headerIndex) => (
                <th
                  // NOTE:
                  // hardcoding to always pin first column cells due to react-table
                  // loosing column.getIsPinned state after react lifecycle re-render
                  className={classNames({
                    [styles.pinned]: pinFirstColumn && headerIndex === 0,
                  })}
                  key={header.id}
                  role="columnheader"
                  style={{
                    minWidth:
                      header.index === 0 && pinFirstColumn
                        ? DEFAULT_COLUMN_MIN_WIDTH
                        : "auto",
                    maxWidth:
                      header.index === 0 && pinFirstColumn
                        ? DEFAULT_COLUMN_MAX_WIDTH
                        : "auto",
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, cellIndex) => {
                let tDataCell = {
                  minWidth:
                    cellIndex === 0 && pinFirstColumn
                      ? DEFAULT_COLUMN_MIN_WIDTH
                      : "auto",
                  maxWidth:
                    cellIndex === 0 && pinFirstColumn
                      ? DEFAULT_COLUMN_MAX_WIDTH
                      : "auto",
                };

                if (pinFirstColumn && pinnedColumnStyles && cellIndex === 0) {
                  tDataCell = { ...tDataCell, ...pinnedColumnStyles };
                }

                return (
                  <td
                    className={classNames({
                      // NOTE:
                      // hardcoding to always pin first column cells due to react-table
                      // loosing column.getIsPinned state after react lifecycle re-render
                      [styles.pinned]: pinFirstColumn && cellIndex === 0,
                    })}
                    key={cell.id}
                    style={tDataCell}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
