import {
  type TrackingComponent,
  type TrackingEvent,
  GenericTrackingProperties,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
  FeatureFlag,
  ReportType,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { NewProductBenchmarkingReportlet } from "./components/NewProductBenchmarkingReportlet";
import { NewProductBenchmarkingSidePanel } from "./components/NewProductBenchmarkingSidePanel";
import NewProductBenchmarkingTopDrawer from "./components/NewProductBenchmarkingTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/new-product-benchmarking-local-parameters-api-slice";
import {
  onMetadataSuccess,
  onReportOpen,
  reset,
  selectLocalParametersInitialised,
  selectReportId,
  selectReportName,
} from "./services/new-product-benchmarking-slice";

const NewProductBenchmarkingReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  // Store
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const reportId = useSelector(selectReportId);
  const reportName = useSelector(selectReportName);

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTabsEnabled]);

  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: !activeDivisionName || !id }
    );

  useEffect(() => {
    if (infoPanelSummary) {
      dispatch(
        onMetadataSuccess({
          reportId: infoPanelSummary.id,
          reportName: infoPanelSummary.reportName,
        })
      );
    }
  }, [dispatch, infoPanelSummary]);

  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, activeReportId: string) => {
      await getLocalParameters({
        divisionName: division,
        reportId: activeReportId,
      });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = useCallback(
    (
      componentHierarchy: TrackingComponent[],
      event: TrackingEvent,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      properties: Record<string, any>
    ) =>
      eventTrackingService.trackEvent(
        componentHierarchy,
        event,
        new GenericTrackingProperties({
          reportName: "New product benchmarking",
          reportId,
          ...properties,
        })
      ),
    [eventTrackingService, reportId]
  );

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <NewProductBenchmarkingTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            infoPanelSummary={infoPanelSummary}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportType={ReportType.NewProductBenchmarking}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <NewProductBenchmarkingSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <NewProductBenchmarkingReportlet
            infoPanelSummary={infoPanelSummary}
          />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <NewProductBenchmarkingReport />,
  path: "new-product-benchmarking/:id",
};

export default NewProductBenchmarkingReport;
