import {
  type EventTrackingService,
  type TrackingEvent,
  TrackingComponent,
  ItemTrackingProperties,
  ItemTrackingProperty,
} from "@quantium-enterprise/common-ui";
import { type AddWatchlistItemDto } from "./dtos/WatchlistItemDto";
import { WatchlistItemType } from "./dtos/WatchlistItemDto";

export const trackWatchlistItemEvent = (
  eventTrackingService: EventTrackingService,
  watchlistItem: AddWatchlistItemDto,
  event: TrackingEvent
) => {
  if (
    watchlistItem.type === WatchlistItemType.Hierarchy ||
    watchlistItem.type === WatchlistItemType.Attribute
  ) {
    eventTrackingService.trackEvent(
      TrackingComponent.WatchlistItem,
      event,
      ItemTrackingProperties.item(
        watchlistItem.type === WatchlistItemType.Attribute
          ? ItemTrackingProperty.AttributeLevel
          : ItemTrackingProperty.Hierarchy,
        watchlistItem.shortName
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Think this makes it clearer
  } else if (watchlistItem.type === WatchlistItemType.ProductGroup) {
    eventTrackingService.trackEvent(
      TrackingComponent.WatchlistItem,
      event,
      ItemTrackingProperties.item(
        ItemTrackingProperty.ProductGroup,
        watchlistItem.productGroupId
      )
    );
  }
};
