import { ErrorLayout } from "components-ui/";
import styles from "./NotFoundPage.module.css";

export const NotFoundPage = () => (
  <ErrorLayout errorCode="404" errorMessage="This page doesn't exist.">
    <div className={styles.info}>
      <p className={styles.paragraph}>
        Contact <a href="/">Support</a> if error persists.
      </p>
      <p className={styles.paragraph}>Email ID:</p>
      <p className={styles.paragraph}>Contact Number:</p>
    </div>
  </ErrorLayout>
);
