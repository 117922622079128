import { type Header } from "@tanstack/react-table";
import classNames from "classnames";
import styles from "./ColumnResizer.module.css";

export type ColumnResizerProps<T> = {
  columnResizeMode?: "onChange" | "onEnd";
  deltaOffset: number | null;
  header: Header<T, unknown>;
  isRowSpan?: boolean;
};

const ColumnResizer = <T,>({
  columnResizeMode,
  deltaOffset,
  header,
  isRowSpan,
}: ColumnResizerProps<T>) => (
  <button
    className={classNames(styles.resizer, {
      [styles.isResizing]: header.column.getIsResizing(),
      [styles.isRowSpan]: isRowSpan,
    })}
    data-testid="separator"
    onKeyUp={header.getResizeHandler()}
    onMouseDown={header.getResizeHandler()}
    onTouchStart={header.getResizeHandler()}
    style={{
      transform:
        columnResizeMode === "onEnd" &&
        header.column.getIsResizing() &&
        deltaOffset
          ? `translateX(${deltaOffset}px)`
          : "",
    }}
    type="button"
  >
    <span className={styles.resizerIcon} title="resizer separator">
      resizer separator
    </span>
  </button>
);

export default ColumnResizer;
