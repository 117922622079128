export const Products = ({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6665 4.82667H11.4732L8.55317 0.45334C8.4265 0.266673 8.21317 0.17334 7.99984 0.17334C7.7865 0.17334 7.57317 0.266673 7.4465 0.460007L4.5265 4.82667H1.33317C0.966504 4.82667 0.666504 5.12667 0.666504 5.49334C0.666504 5.55334 0.673171 5.61334 0.693171 5.67334L2.3865 11.8533C2.53984 12.4133 3.05317 12.8267 3.6665 12.8267H12.3332C12.9465 12.8267 13.4598 12.4133 13.6198 11.8533L15.3132 5.67334L15.3332 5.49334C15.3332 5.12667 15.0332 4.82667 14.6665 4.82667ZM7.99984 2.02667L9.8665 4.82667H6.13317L7.99984 2.02667ZM12.3332 11.4933L3.67317 11.5L2.2065 6.16001H13.7998L12.3332 11.4933ZM7.99984 7.49334C7.2665 7.49334 6.6665 8.09334 6.6665 8.82667C6.6665 9.56001 7.2665 10.16 7.99984 10.16C8.73317 10.16 9.33317 9.56001 9.33317 8.82667C9.33317 8.09334 8.73317 7.49334 7.99984 7.49334Z"
      fill="#333333"
    />
  </svg>
);
