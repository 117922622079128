import {
  type LocalHierarchyNodeSelection,
  type HierarchyValue,
} from "@quantium-enterprise/common-ui";
import { type PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type HierarchySliceNodeDto } from "../../../../common/src/models/hierarchy-slice-dto";
import { EMPTY_HIERARCHY_SLICE_NODE } from "../../common/constants";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { buildNestedRows, findNode } from "../components/top-drawer/utils";
import { type ContributionResponseDto } from "../models/ContributionChartDriverDto";
import { type DriverTreeResponseDto } from "../models/DriverTreeResponseDto";
import {
  type KeyDriverTreeTableMeasureGrouping,
  type KeyDriverTreeTableRow,
} from "../models/key-driver-tree-table-models";
import { getDefaultSelections } from "../utils/getDefaultSelections";
import { type KeyDriverTreeLocalParametersResponse } from "./key-driver-tree-local-parameters-api-slice";
import {
  type SearchTableResponse,
  type FetchChildrenTableResponse,
  type InitialTableResponse,
  type KeyDriverTreeTableResponse,
} from "./key-driver-tree-table-data-api-slice";

// Currently using a hard-coded selection state
// can move to a dynamic record if better
export type KeyDriverTreeLocalSelections = {
  Channel: PanelOption;
  KeyDriver: PanelOption;
  LocationHierarchy: LocalHierarchyNodeSelection;
  Promotion: PanelOption;
  Segment: PanelOption;
  Segmentation: PanelOption;
  Time: string;
  TimePeriodLength: string;
};

export type KeyDriverTreePersistedSelections = {
  focalItem: HierarchySliceNodeDto;
  hierarchyTableRowSelectionState: RowSelectionState;
  localParameterSelections: KeyDriverTreeLocalSelections;
};

export type KeyDriverTreeState = {
  // CONTRIBUTION CHARTS
  activeContributionIndex: number;
  benchmark?: HierarchyValue;
  contributionChartData: ContributionResponseDto;
  contributionChartOptions: {
    hiddenSeries: number[];
  };

  driverTreeData: DriverTreeResponseDto;

  // FOCAL ITEM TABLE
  focalItemSearchText: string;
  focalItemTableInitialised: boolean;
  focalItemTableMeasureGroups: KeyDriverTreeTableMeasureGrouping[];
  focalItemTableRows: KeyDriverTreeTableRow[];
  hierarchySliceHypercubeId: string;
  isSearchingFocalItem: boolean;

  // LOCAL PARAMETERS
  localParameterConfig: SidePanelParameter[];
  localParametersInitialised: boolean;

  metaData: {
    reportId: string;
    reportName: string;
  };
  // PERSISTED SELECTIONS
  persistedSelections: KeyDriverTreePersistedSelections;
  persistedSelectionsLoaded: boolean;
};

export const initialState: KeyDriverTreeState = {
  activeContributionIndex: 0,
  contributionChartData: {
    categories: [],
    label: "",
  },
  contributionChartOptions: {
    hiddenSeries: [],
  },

  driverTreeData: {
    maxContribution: 0,
  },
  focalItemTableInitialised: false,
  focalItemTableMeasureGroups: [],
  focalItemTableRows: [],
  hierarchySliceHypercubeId: "",

  localParameterConfig: [],

  localParametersInitialised: false,

  metaData: {
    reportId: "",
    reportName: "Key driver tree",
  },
  focalItemSearchText: "",
  isSearchingFocalItem: false,

  persistedSelections: {
    focalItem: EMPTY_HIERARCHY_SLICE_NODE,
    hierarchyTableRowSelectionState: {},
    localParameterSelections: {
      Channel: {
        value: "",
        label: "",
      },
      KeyDriver: {
        value: "",
        label: "",
      },
      LocationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
        isDefault: false,
      },
      Promotion: {
        value: "",
        label: "",
      },
      Segmentation: {
        value: "",
        label: "",
      },
      Segment: {
        value: "",
        label: "",
      },
      Time: "",
      TimePeriodLength: "",
    },
  },
  persistedSelectionsLoaded: false,
};

const getPersistenceKey = (reportId: string) => `key-driver-tree-${reportId}`;

export const keyDriverTreeSlice = createSlice({
  initialState,
  name: "key-driver-tree",
  reducers: {
    onActiveContributionIndexChange: (
      state: KeyDriverTreeState,
      action: PayloadAction<number>
    ) => {
      state.activeContributionIndex = action.payload;
    },
    onBenchmarkChange: (
      state: KeyDriverTreeState,
      action: PayloadAction<HierarchyValue | undefined>
    ) => {
      state.benchmark = action.payload;
    },
    onChannelChange: (
      state: KeyDriverTreeState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.Channel = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onContributionChartDataReceived: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<ContributionResponseDto>
    ) => {
      state.contributionChartData = payload;
    },
    onDriverTreeDataChange: (
      state: KeyDriverTreeState,
      action: PayloadAction<DriverTreeResponseDto>
    ) => {
      state.driverTreeData = action.payload;
    },
    onFocalItemChange: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<HierarchySliceNodeDto>
    ) => {
      // deselect old focal item
      for (const key of Object.keys(
        state.persistedSelections.hierarchyTableRowSelectionState
      )) {
        state.persistedSelections.hierarchyTableRowSelectionState[key] = false;
      }

      // select new focal item
      state.persistedSelections.focalItem = payload;
      state.persistedSelections.hierarchyTableRowSelectionState[
        payload.nodeNumber
      ] = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onInitialTableDataReceived: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<InitialTableResponse>
    ) => {
      state.hierarchySliceHypercubeId = payload.hierarchySliceHypercubeId;
      state.focalItemTableMeasureGroups = payload.measureGroups;
      state.focalItemTableRows = buildNestedRows(payload.tableRows);

      state.focalItemTableInitialised = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onKeyDriverChange: (
      state: KeyDriverTreeState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.KeyDriver =
        action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onLegendToggle: (
      state: KeyDriverTreeState,
      action: PayloadAction<number[]>
    ) => {
      state.contributionChartOptions.hiddenSeries = action.payload;
    },
    onLocalParametersReceived: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<KeyDriverTreeLocalParametersResponse>
    ) => {
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParameterSelections =
          getDefaultSelections(payload.localParameters);

        if (payload.defaultFocalItem) {
          state.persistedSelections.focalItem = payload.defaultFocalItem;

          for (const key of Object.keys(
            state.persistedSelections.hierarchyTableRowSelectionState
          )) {
            state.persistedSelections.hierarchyTableRowSelectionState[key] =
              false;
          }

          state.persistedSelections.hierarchyTableRowSelectionState[
            payload.defaultFocalItem.nodeNumber
          ] = true;
        }
      }

      state.localParameterConfig = payload.localParameters;
      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: KeyDriverTreeState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localParameterSelections.LocationHierarchy =
        action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onMetadataSuccess: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.metaData = payload;
    },
    onPromotionChange: (
      state: KeyDriverTreeState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.Promotion = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onReportOpen: (
      state: KeyDriverTreeState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
        };
      }

      const persistedSelections: KeyDriverTreePersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
      };
    },
    onSegmentationChange: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localParameterSelections.Segmentation = {
        value: payload.segmentationValue,
        label: payload.segmentationLabel,
      };
      state.persistedSelections.localParameterSelections.Segment = {
        value: payload.segmentValue,
        label: payload.segmentLabel,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onTableChildrenDataRecieved: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<FetchChildrenTableResponse>
    ) => {
      const parentRow = findNode(state.focalItemTableRows, payload.parentNode);
      if (parentRow) {
        parentRow.subRows = payload.tableRows.map((row) => ({
          hierarchyItem: row.hierarchyItem,
          measures: row.measures,
          subRows: [],
        }));
      }
    },
    onTableDataRecieved: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<KeyDriverTreeTableResponse>
    ) => {
      state.focalItemTableMeasureGroups = payload.measureGroups;
      state.focalItemTableRows = buildNestedRows(payload.tableRows);
      state.isSearchingFocalItem = false;
    },
    reset: () => initialState,
    onSearchChange: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<string>
    ) => {
      state.focalItemSearchText = payload.trim();
      if (state.focalItemSearchText.length > 0) {
        state.isSearchingFocalItem = true;
      }
    },
    onTableSearchDataReceived: (
      state: KeyDriverTreeState,
      { payload }: PayloadAction<SearchTableResponse>
    ) => {
      state.focalItemTableRows = buildNestedRows(payload.tableRows, true);
    },
  },
});

export const {
  onActiveContributionIndexChange,
  onBenchmarkChange,
  onChannelChange,
  onDriverTreeDataChange,
  onFocalItemChange,
  onInitialTableDataReceived,
  onContributionChartDataReceived,
  onKeyDriverChange,
  onMetadataSuccess,
  onLocalParametersReceived,
  onPromotionChange,
  onSegmentationChange,
  onLocationChange,
  onLegendToggle,
  onReportOpen,
  onTableChildrenDataRecieved,
  onTableDataRecieved,
  onSearchChange,
  onTableSearchDataReceived,
  reset,
} = keyDriverTreeSlice.actions;

export default keyDriverTreeSlice.reducer;
