enum RGB {
  Grey = "224, 224, 224",
  MaxGreen = "33, 150, 83",
  MaxRed = "235, 87, 87",
}

export const defaultMaxNumberOfColours = 11;
export const getHeatMapColour = (max: number, value: number = 0) => {
  // Grey strictly for 0 values only. Remaining range will be coloured
  if (value === 0) return `rgb(${RGB.Grey}, 1)`;
  const ratio = Math.abs(value) / Math.abs(max);
  const colour = value < 0 ? RGB.MaxRed : RGB.MaxGreen;
  // (`defaultMaxNumberOfColours` - 1) / 2 = (11 - 1) / 2
  return `rgb(${colour}, ${Math.ceil(ratio * 5) / 5})`;
};
