import {
  type LocalHierarchyNodeSelection,
  type HierarchyValueDto,
} from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import {
  type ProductRow,
  type FocalTableBenchmarksDataResponse,
  type FocalTableProductsDataResponse,
} from "../models/new-product-benchmarking-data-table-models";
import {
  type MetricsListGroup,
  type MetricsListResponse,
} from "../models/new-product-benchmarking-metrics-list-dto";
import { getDefaultSelections } from "../utils/local-parameter-utils";

export type NewProductBenchmarkingSidePanelSelections = {
  Channel: PanelOption;
  FocusPeriod?: string;
  LocationHierarchy: LocalHierarchyNodeSelection;
  Segmentation: PanelOption[];
  TimePeriodLength?: string;
};

export type NewProductBenchmarkingPersistedSelections = {
  focalItems: HierarchyValueDto[];
  localSelections: NewProductBenchmarkingSidePanelSelections;
  rowSelection: RowSelectionState;
};

export type NewProductBenchmarkingState = {
  // FOCAL ITEM TABLE
  focalTableBenchmarksData: FocalTableBenchmarksDataResponse;
  focalTableInitialLoad: boolean;
  focalTableProductsData: FocalTableProductsDataResponse;
  focalTableSearch: string;
  focalTableShowBenchmarks: boolean;
  hiddenBenchmarkSeries: number[];

  // CHART MANAGEMENT
  hiddenSeries: number[];

  // LOCAL PARAMETERS
  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;

  metaData: {
    reportId: string;
    reportName: string;
  };
  metricsList: MetricsListGroup[];

  // PERSISTED SELECTIONS
  persistedSelections: NewProductBenchmarkingPersistedSelections;
  persistedSelectionsLoaded: boolean;

  reportError: boolean;
  selectedFocalTableMetrics: string[];
  selectedMetric: string;
  showChartDataLabels: boolean;
};

export const initialState: NewProductBenchmarkingState = {
  hiddenSeries: [],
  hiddenBenchmarkSeries: [1, 3],
  localParameters: [],
  localParametersInitialised: false,
  focalTableSearch: "",
  focalTableInitialLoad: false,
  focalTableBenchmarksData: { metrics: [], benchmarkRows: [], weeks: [] },
  selectedFocalTableMetrics: [],
  focalTableProductsData: {
    lastRowIndex: 0,
    totalRows: 0,
    metrics: [],
    productRows: [],
    weeks: [],
  },
  focalTableShowBenchmarks: false,
  selectedMetric: "",
  metricsList: [],
  metaData: {
    reportId: "",
    reportName: "",
  },
  persistedSelections: {
    focalItems: [],
    localSelections: {
      Channel: {
        label: "",
        value: "",
      },
      Segmentation: [],
      LocationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
      },
      FocusPeriod: "",
      TimePeriodLength: "",
    },
    rowSelection: {},
  },
  persistedSelectionsLoaded: false,
  reportError: false,
  showChartDataLabels: false,
};

const productRowSortComparison = (a: ProductRow, b: ProductRow) => {
  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

const getPersistenceKey = (reportId: string) =>
  `new-product-benchmarking-${reportId}`;

export const NewProductBenchmarkingSlice = createSlice({
  initialState,
  name: "new-product-benchmarking",
  reducers: {
    onReportError: (state: NewProductBenchmarkingState) => {
      state.reportError = true;
    },
    setFocalItems: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<{
        isSelected: boolean;
        selectedItem: HierarchyValueDto;
        selectedRow: ProductRow;
      }>
    ) => {
      const currentFocalItemLength =
        state.persistedSelections.focalItems.length;

      if (action.payload.isSelected) {
        state.persistedSelections.focalItems =
          state.persistedSelections.focalItems.filter(
            (value) => value.itemCode !== action.payload.selectedItem.itemCode
          );

        state.persistedSelections.rowSelection =
          currentFocalItemLength > 1
            ? {
                ...state.persistedSelections.rowSelection,
                [action.payload.selectedItem.itemCode]: false,
              }
            : {};
      } else {
        state.persistedSelections.focalItems = [
          ...state.persistedSelections.focalItems,
          action.payload.selectedItem,
        ];
        state.persistedSelections.rowSelection[
          action.payload.selectedItem.itemCode
        ] = true;
      }

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onHiddenSeriesChange: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<number[]>
    ) => {
      state.hiddenSeries = action.payload;
    },
    onHiddenBenchmarkSeriesChange: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<number[]>
    ) => {
      state.hiddenBenchmarkSeries = action.payload;
    },
    onMetadataSuccess: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.metaData = payload;
    },
    onLocalParametersReceived: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<SidePanelParameter[]>
    ) => {
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localSelections =
          getDefaultSelections(payload);
      }

      state.localParameters = payload;
      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onChannelChange: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localSelections.Channel = {
        label: payload.label,
        value: payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localSelections.LocationHierarchy = payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onMetricsListReceived: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<MetricsListResponse>
    ) => {
      state.metricsList = payload.groups;
      state.selectedFocalTableMetrics = payload.defaultMetric
        ? [payload.defaultMetric]
        : [];
    },
    setFocalItemTableMetricsList: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<{ metricName: string }>
    ) => {
      const metricIndex = state.selectedFocalTableMetrics.indexOf(
        payload.metricName
      );
      if (metricIndex === -1) {
        state.selectedFocalTableMetrics.push(payload.metricName);
      } else {
        state.selectedFocalTableMetrics.splice(metricIndex, 1);
      }
    },
    toggleShowBenchmarks: (state: NewProductBenchmarkingState) => {
      state.focalTableShowBenchmarks = !state.focalTableShowBenchmarks;
    },
    onFocalTableBenchmarksDataReceived: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<FocalTableBenchmarksDataResponse>
    ) => {
      state.focalTableBenchmarksData = payload;
    },
    onMoreFocalTableProductsDataReceived: (
      state: NewProductBenchmarkingState,
      { payload }: PayloadAction<FocalTableProductsDataResponse>
    ) => {
      state.focalTableProductsData.productRows =
        state.focalTableProductsData.productRows.concat(payload.productRows);
      state.focalTableProductsData.lastRowIndex = payload.lastRowIndex;
    },
    onUpdatedFocalTableProductsDataReceived: (
      state: NewProductBenchmarkingState,
      {
        payload,
      }: PayloadAction<{
        data: FocalTableProductsDataResponse;
        isSearch: boolean;
      }>
    ) => {
      state.focalTableInitialLoad = true;

      state.focalTableProductsData = {
        lastRowIndex: payload.data.lastRowIndex,
        metrics: payload.data.metrics,

        totalRows: payload.data.totalRows,
        weeks: payload.data.weeks,
        productRows: payload.isSearch
          ? [...payload.data.productRows].sort(productRowSortComparison)
          : payload.data.productRows,
      };
    },
    onChartSelectedMetricChange: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<string>
    ) => {
      state.selectedMetric = action.payload;
    },
    onMetricListChange: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<MetricsListGroup[]>
    ) => {
      state.metricsList = action.payload;
    },
    onReportOpen: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
        };
      }

      const persistedSelections: NewProductBenchmarkingPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
      };
    },
    onSegmentationChange: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<PanelOption[]>
    ) => {
      state.persistedSelections.localSelections.Segmentation = action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onSearchQueryChange: (
      state: NewProductBenchmarkingState,
      action: PayloadAction<string>
    ) => {
      state.focalTableSearch = action.payload.trim();
    },
    onToggleShowChartDataLables: (state: NewProductBenchmarkingState) => {
      state.showChartDataLabels = !state.showChartDataLabels;
    },
    reset: () => initialState,
  },
});

// Selectors
export const selectLocalParameters = createSelector(
  (state: RootState) => state.newProductBenchmarking.localParameters,
  (localParameters: SidePanelParameter[]) => localParameters
);

export const selectLocalParametersSelections = createSelector(
  (state: RootState) =>
    state.newProductBenchmarking.persistedSelections.localSelections,
  (localSelections: NewProductBenchmarkingSidePanelSelections) =>
    localSelections
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.newProductBenchmarking.localParametersInitialised,
  (localParametersInitialised: boolean) => localParametersInitialised
);

export const selectFocalItems = createSelector(
  (state: RootState) =>
    state.newProductBenchmarking.persistedSelections.focalItems,
  (focalItems: HierarchyValueDto[]) => focalItems
);

export const selectReportMetadata = createSelector(
  (state: RootState) => state.newProductBenchmarking.metaData,
  (metadata: { reportId: string; reportName: string }) => metadata
);

export const selectReportId = createSelector(
  (state: RootState) => state.newProductBenchmarking.metaData.reportId,
  (value) => value
);

export const selectReportName = createSelector(
  (state: RootState) => state.newProductBenchmarking.metaData.reportName,
  (value) => value
);

export const selectMetricsList = createSelector(
  (state: RootState) => state.newProductBenchmarking.metricsList,
  (value) => value
);

export const selectFocalTableMetrics = createSelector(
  (state: RootState) => state.newProductBenchmarking.selectedFocalTableMetrics,
  (value) => value
);

export const selectShowBenchmarks = createSelector(
  (state: RootState) => state.newProductBenchmarking.focalTableShowBenchmarks,
  (value) => value
);

export const selectFocalTableProductsData = createSelector(
  (state: RootState) => state.newProductBenchmarking.focalTableProductsData,
  (value) => value
);

export const selectSearchQuery = createSelector(
  (state: RootState) => state.newProductBenchmarking.focalTableSearch,
  (value) => value
);

export const selectBenchmarkData = createSelector(
  (state: RootState) => state.newProductBenchmarking.focalTableBenchmarksData,
  (value) => value
);

export const selectHiddenSeries = createSelector(
  (state: RootState) => state.newProductBenchmarking.hiddenSeries,
  (value) => value
);

export const selectHiddenBenchmarkSeries = createSelector(
  (state: RootState) => state.newProductBenchmarking.hiddenBenchmarkSeries,
  (value) => value
);

export const selectSelectedMetric = createSelector(
  (state: RootState) => state.newProductBenchmarking.selectedMetric,
  (value) => value
);

export const selectRowSelection = createSelector(
  (state: RootState) =>
    state.newProductBenchmarking.persistedSelections.rowSelection,
  (value) => value
);

export const selectFocalTableInitialised = createSelector(
  (state: RootState) => state.newProductBenchmarking.focalTableInitialLoad,
  (value) => value
);

export const selectShowChartDataLabels = createSelector(
  (state: RootState) => state.newProductBenchmarking.showChartDataLabels,
  (value) => value
);

export const {
  setFocalItems,
  onMetadataSuccess,
  onHiddenSeriesChange,
  onHiddenBenchmarkSeriesChange,
  onLocalParametersReceived,
  onMetricListChange,
  onChannelChange,
  onLocationChange,
  onSegmentationChange,
  setFocalItemTableMetricsList,
  onMetricsListReceived,
  onFocalTableBenchmarksDataReceived,
  onUpdatedFocalTableProductsDataReceived,
  onMoreFocalTableProductsDataReceived,
  toggleShowBenchmarks,
  onChartSelectedMetricChange,
  reset,
  onReportError,
  onReportOpen,
  onSearchQueryChange,
  onToggleShowChartDataLables,
} = NewProductBenchmarkingSlice.actions;

export default NewProductBenchmarkingSlice.reducer;
