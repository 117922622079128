import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelSingleSelectionParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isLocationSelectionParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildLocationHierarchyPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildSingleSelectPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { BreakdownByParameterId } from "../models/basket-quantities-common-models";
import {
  selectLocalParameter,
  onViewAsChange,
  onBreakdownChange,
  onChannelChange,
  onPromotionChange,
  onSegmentationChange,
  onLocationChange,
  selectBreakdown,
  selectChannel,
  selectPromotion,
  selectSegment,
  selectSegmentation,
  selectLocationHierarchy,
  selectViewAs,
} from "../services/basket-quantities-slice";
import {
  disableNonDefaultPromo,
  disableNonDefaultSegments,
} from "../utils/summaryBreakdownUtils";

export const BasketQuantitiesSidePanel = () => {
  const dispatch = useDispatch();

  const localParametersConfig = useSelector(selectLocalParameter);
  const breakdown = useSelector(selectBreakdown);
  const channel = useSelector(selectChannel);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const promotion = useSelector(selectPromotion);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const viewAs = useSelector(selectViewAs);

  const generatePanels = () => {
    const panels: Panel[] = [];

    for (const parameter of localParametersConfig) {
      switch (parameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(parameter))
            panels.push(buildTimePeriodPanel(parameter));

          break;
        case LocalParameters.ViewAs:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onViewAsChange(value));
                },
                viewAs.value as string
              )
            );
          }

          break;
        case LocalParameters.Breakdown:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onBreakdownChange(value));
                },
                breakdown.value as string
              )
            );
          }

          break;
        case LocalParameters.Channel:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onChannelChange(value));
                },
                channel.value as string
              )
            );
          }

          break;
        case LocalParameters.Promotion:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                breakdown.value === BreakdownByParameterId.Promotion
                  ? disableNonDefaultPromo(
                      parameter as SidePanelSingleSelectionParameter
                    )
                  : parameter,
                (value: PanelOption) => {
                  dispatch(onPromotionChange(value));
                },
                promotion.value as string
              )
            );
          }

          break;
        case LocalParameters.Segmentation:
          if (isSegmentationParameter(parameter)) {
            panels.push(
              buildSegmentationPanel(
                breakdown.value === BreakdownByParameterId.Segmentation
                  ? disableNonDefaultSegments(parameter)
                  : parameter,
                (value: SegmentOption) => {
                  dispatch(onSegmentationChange(value));
                },
                [segmentation.value as string, segment.value as string]
              )
            );
          } else if (isPlainTextContentParameter(parameter)) {
            panels.push(buildPlainTextContentPanel(parameter));
          }

          break;
        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(parameter)) {
            const onLocationSelection = (
              selection: LocalHierarchyNodeSelection
            ) => {
              dispatch(onLocationChange(selection));
            };

            panels.push(
              buildLocationHierarchyPanel(
                parameter,
                onLocationSelection,
                locationHierarchy
              )
            );
          }

          break;
        default:
          break;
      }
    }

    return panels;
  };

  return <SidePanel panels={generatePanels()} />;
};

export default BasketQuantitiesSidePanel;
