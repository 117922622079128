import {
  ddLog,
  FeatureFlag,
  GenericTrackingProperties,
  ReportType,
  useEventTrackingServiceContext,
  useLazyGetReportParametersQuery,
  useRenameReportMutation,
  type TrackingComponent,
  type TrackingEvent,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import styles from "./PricingLaddersReport.module.css";
import { PricingLaddersReportlet } from "./components/PricingLaddersReportlet";
import PricingLaddersSidePanel from "./components/PricingLaddersSidePanel";
import { PricingLaddersTopDrawer } from "./components/PricingLaddersTopDrawer/PricingLaddersTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/pricing-ladders-local-parameters-api-slice";
import {
  onMetadataSuccess,
  onReportOpen,
  reset,
  selectLevelOfAnalysis,
  selectLocalParametersInitialised,
  selectReportId,
  selectReportName,
} from "./services/pricing-ladders-slice";

export const PricingLaddersReport = () => {
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const reportName = useSelector(selectReportName);
  const levelOfAnalysis = useSelector(selectLevelOfAnalysis);
  const storeReportId = useSelector(selectReportId);
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      if (isTabsEnabled) {
        dispatch(
          onReportOpen({
            reportId: id,
            isTabsEnabled,
          })
        );
      } else {
        dispatch(reset());
      }
    }
  }, [dispatch, id, isTabsEnabled]);

  // info panel
  const [
    getReportParametersQuery,
    { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading },
  ] = useLazyGetReportParametersQuery();

  const fetchReportParametersQuery = useCallback(
    async (division: string, reportId: string) => {
      await getReportParametersQuery({
        divisionName: division,
        reportId,
      });
    },
    [getReportParametersQuery]
  );

  useEffect(() => {
    if (activeDivisionName && !localParametersInitialised) {
      fetchReportParametersQuery(activeDivisionName, id ?? "").catch(
        (error) => {
          ddLog(
            "Error retrieving pricing ladders report parameters",
            {},
            "error",
            error
          );
        }
      );
    }
  }, [
    activeDivisionName,
    fetchReportParametersQuery,
    id,
    localParametersInitialised,
  ]);

  // Save storeReportId and reportName to the state
  useEffect(() => {
    if (infoPanelSummary) {
      dispatch(
        onMetadataSuccess({
          reportId: infoPanelSummary.id,
          reportName: infoPanelSummary.reportName,
        })
      );
    }
  }, [dispatch, infoPanelSummary]);

  // local parameters query
  const [getLocalParametersQuery] = useLazyGetLocalParametersQuery();

  const fetchLocalParametersQuery = useCallback(
    async (division: string, reportId: string) => {
      await getLocalParametersQuery({
        divisionName: division,
        reportId,
      });
    },
    [getLocalParametersQuery]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      fetchLocalParametersQuery(activeDivisionName, id).catch((error) => {
        ddLog(
          "Error retrieving pricing ladders local parameters",
          {},
          "error",
          error
        );
      });
    }
  }, [
    activeDivisionName,
    fetchLocalParametersQuery,
    id,
    localParametersInitialised,
  ]);

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = useCallback(
    (
      componentHierarchy: TrackingComponent[],
      event: TrackingEvent,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      properties: Record<string, any>
    ) =>
      eventTrackingService.trackEvent(
        componentHierarchy,
        event,
        new GenericTrackingProperties({
          reportName: "Pricing ladders",
          storeReportId,
          ...properties,
        })
      ),
    [storeReportId, eventTrackingService]
  );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <PricingLaddersTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportParameterSummary={infoPanelSummary}
            reportTabItems={levelOfAnalysis.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            reportType={ReportType.PricingLadders}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <PricingLaddersSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <div className={styles.reportlet}>
            <PricingLaddersReportlet reportParameters={infoPanelSummary} />
          </div>
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <PricingLaddersReport />,
  path: "pricing-ladders/:id",
};

export default PricingLaddersReport;
