import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const keyMeasureTrendsApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/key-measure-trends`),
  endpoints: () => ({}),
  reducerPath: "keyMeasureTrendsApi",
  tagTypes: ["key-measure-trends"],
});

export default keyMeasureTrendsApiSlice.reducer;
