import {
  FormBlock,
  FormBlockType,
  FormInputHeight,
  Item,
  ItemValign,
  ItemWidth,
  Input,
  QSearchInput,
  Group,
  GroupRowspace,
} from "@quantium-enterprise/qds-react";
import SingleSelectDropdown from "../../local-filters/SingleSelectDropdown";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import { type Container } from "../models/Zone";
import styles from "./DragAndMultiDrop.module.css";

export type DragAndDropTitleProps = {
  container: Container;
  onSelection: (value: string) => unknown;
  searchAndFilter: boolean;
  selectOptions: PanelOption[];
  selectedValue: string;
  setSearchString: (searchString: string) => void;
};

export const DragAndDropTitle = ({
  container,
  searchAndFilter,
  setSearchString,
  selectOptions,
  onSelection,
  selectedValue,
}: DragAndDropTitleProps) => (
  <div className={styles.searchAndFilter}>
    {container.title && (
      <div className={styles.containerTitle}> {container.title} </div>
    )}
    {searchAndFilter && (
      <>
        <Group rowspace={GroupRowspace.Small}>
          <Item valign={ItemValign.Bottom} width={ItemWidth.Fill}>
            <FormBlock blockType={FormBlockType.Search}>
              <Input>
                <QSearchInput
                  height={FormInputHeight.XSmall}
                  id="dragAndDropSearchInput"
                  onChange={(event) => {
                    setSearchString(event.target.value);
                  }}
                  placeholder="Search measures"
                />
              </Input>
            </FormBlock>
          </Item>
        </Group>
        <Group rowspace={GroupRowspace.XSmall}>
          <Item valign={ItemValign.Bottom} width={ItemWidth.Fill}>
            <SingleSelectDropdown
              height={FormInputHeight.XSmall}
              onSelection={(value) => onSelection(value.value as string)}
              selectOptions={selectOptions}
              selectedValue={selectedValue}
            />
          </Item>
        </Group>
      </>
    )}
  </div>
);
