import { type SerializedError } from "@reduxjs/toolkit";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { createContext } from "react";
import { type UserDto } from "./models";

export type GlobalState = {
  userState: {
    currentUser: UserDto | undefined;
    error: FetchBaseQueryError | SerializedError | undefined;
    isError: boolean;
    isLoading: boolean;
  };
};

export const AppContext = createContext<GlobalState>({} as GlobalState);
