import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { SidePanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isTimePeriodParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isLocationSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildTimePeriodPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildSingleSelectPanel,
  buildLocationHierarchyPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";
import {
  onChannelChange,
  onLocationChange,
  onProductInteractionChange,
  onSegmentationChange,
  selectChannel,
  selectLocation,
  selectProductInteraction,
  selectSegmentation,
} from "../services/customer-cross-shop-slice";

export const CustomerCrossShopSidePanel = () => {
  const dispatch = useDispatch();

  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const productInteraction = useSelector(selectProductInteraction);
  const segmentation = useSelector(selectSegmentation);
  const { localParameters } = useSelector((state: RootState) => ({
    localParameters: state.customerCrossShop.localParameters,
  }));

  const generatedPanels = useCallback((): Panel[] => {
    const panels: Panel[] = [];

    for (const localParameter of localParameters) {
      switch (localParameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(localParameter)) {
            panels.push(buildTimePeriodPanel(localParameter));
          }

          break;
        case LocalParameters.Segmentation:
          if (isSegmentationParameter(localParameter)) {
            const onSegmentSelection = (value: SegmentOption) => {
              dispatch(
                onSegmentationChange([
                  {
                    label: value.segmentationLabel,
                    value: value.segmentationValue,
                  },
                  {
                    label: value.segmentLabel,
                    value: value.segmentValue,
                  },
                ])
              );
            };

            panels.push(
              buildSegmentationPanel(localParameter, onSegmentSelection, [
                segmentation[0].value as string,
                segmentation[1].value as string,
              ])
            );
          } else if (isPlainTextContentParameter(localParameter)) {
            panels.push(buildPlainTextContentPanel(localParameter));
          }

          break;
        case LocalParameters.Channel:
          if (isSingleSelectionParameter(localParameter)) {
            const onChannelSelection = (value: PanelOption) => {
              dispatch(onChannelChange(value));
            };

            panels.push(
              buildSingleSelectPanel(
                localParameter,
                onChannelSelection,
                channel.value as string
              )
            );
          }

          break;
        case LocalParameters.ProductInteraction:
          if (isSingleSelectionParameter(localParameter)) {
            const onProductInteractionSelection = (value: PanelOption) => {
              dispatch(onProductInteractionChange(value));
            };

            panels.push(
              buildSingleSelectPanel(
                localParameter,
                onProductInteractionSelection,
                productInteraction.value as string
              )
            );
          }

          break;

        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(localParameter)) {
            panels.push(
              buildLocationHierarchyPanel(
                localParameter,
                (selection: LocalHierarchyNodeSelection) => {
                  dispatch(onLocationChange(selection));
                },
                location
              )
            );
          }

          break;

        default:
          break;
      }
    }

    return panels;
  }, [
    dispatch,
    localParameters,
    segmentation,
    channel,
    productInteraction,
    location,
  ]);

  return <SidePanel panels={generatedPanels()} />;
};
