import {
  HierarchyType,
  useSearchQuery,
  type SearchRequestDto,
  type HierarchyItemDto,
  HierarchyItemType,
  type HierarchyItem,
  formatHierarchyName,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Checkbox, Spinner } from "@quantium-enterprise/qds-react";
import { type ColumnDef } from "@tanstack/react-table";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onHierarchyNodeSelection,
  selectHierarchySelectedRows,
} from "../../states/group-hierarchy-source-slice";
import { type RootState } from "../../store";
import styles from "./StaticGroupHierarchySearchTable.module.css";

export type StaticGroupHierarchySearchTableProps = {
  hierarchyType: HierarchyType;
  searchtext: string;
  setSearchResultsCount?: (count: number | undefined) => void;
};

export const SEARCH_MAX_PAGE_SIZE = 1_000;

export const curatedSearchText = (
  searchtext: string,
  hierarchyType: HierarchyType
) => {
  if (hierarchyType === HierarchyType.Product) return searchtext;

  // matches string starting with digits followed by a hyphen and a word, whitespaces are optional
  const regex = /(\d+\s*-\s?)(\w+)/gu;
  return searchtext.replaceAll(regex, "$2");
};

// only search barcodes when searching for 4+ digit numbers
export const isSearchBarcodes = (
  searchtext: string,
  hierarchyType: HierarchyType
) => {
  const onlyDigits = /^[\d\s,]+$/gu;
  const atLeastFourDigits = /\d{4}/gu;
  return (
    onlyDigits.test(curatedSearchText(searchtext, hierarchyType)) &&
    atLeastFourDigits.test(curatedSearchText(searchtext, hierarchyType))
  );
};

export const StaticGroupHierarchySearchTable = ({
  hierarchyType,
  searchtext,
  setSearchResultsCount,
}: StaticGroupHierarchySearchTableProps) => {
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  // the rows currently selected
  const selectedRows = useSelector((state: RootState) =>
    selectHierarchySelectedRows(state)
  );

  const [checkedCheckboxes, setCheckedCheckboxes] =
    useState<HierarchyItem[]>(selectedRows);

  const searchRequest = useMemo(() => {
    const request = {
      page: 0,
      pageSize: SEARCH_MAX_PAGE_SIZE,
      query: curatedSearchText(searchtext, hierarchyType),
      includeBarcodes: isSearchBarcodes(searchtext, hierarchyType),
      includeCodes: true,
    } as SearchRequestDto;

    return request;
  }, [searchtext, hierarchyType]);

  const { currentData, isFetching, isSuccess } = useSearchQuery(
    {
      division: activeDivisionName,
      hierarchyType,
      payload: searchRequest,
    },
    {
      skip: !activeDivisionName,
    }
  );

  useEffect(() => {
    dispatch(onHierarchyNodeSelection(checkedCheckboxes));
  }, [checkedCheckboxes, dispatch]);

  // maybe not needed now, but dto might change
  const dtoToItem = (item: HierarchyItemDto): HierarchyItem =>
    ({
      ...item,
    } as HierarchyItem);

  const tableData = useMemo(() => {
    if (!currentData?.results) return [];

    return currentData.results.map((x) => dtoToItem(x));
  }, [currentData]);

  const itemCheckbox = useCallback(
    (item: HierarchyItem) => (
      <div className={styles.row}>
        <Checkbox
          assistiveLabel
          checked={
            checkedCheckboxes.findIndex(
              (x) => x.code === item.code && x.shortName === item.shortName
            ) > -1
          }
          className={styles.itemCheckbox}
          data-testid={`${item.shortName}-${item.code}`}
          id={`${item.shortName}-${item.code}`}
          label="Select row"
          name="selectrow"
          // handled by row click
          onChange={() => {}}
        />
        <HierarchyLevelIcon
          shortName={item.shortName}
          type={HierarchyItemType.Leaf}
        />
        <span>
          {hierarchyType === HierarchyType.Product
            ? item.name
            : `${item.code} - ${item.name}`}
        </span>
      </div>
    ),
    [checkedCheckboxes, hierarchyType]
  );

  const toggleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        const newCheckboxes: HierarchyItem[] = [];

        // if the lengths match, remove all rows from selection
        if (checkedCheckboxes.length !== tableData.length) {
          // Remove each row of tableData from checkedCheckboxes by
          // iterating through checkedCheckboxes and adding rows which are not in tableData
          for (const selection of checkedCheckboxes) {
            const foundIndex = tableData.findIndex(
              (row) =>
                row.code === selection.code &&
                row.shortName === selection.shortName
            );
            if (foundIndex === -1) {
              newCheckboxes.push(selection);
            }
          }
        }

        setCheckedCheckboxes(newCheckboxes);
      } else if (checkedCheckboxes.length === 0) {
        // if there are no rows selected, select all rows
        setCheckedCheckboxes(tableData);
      } else {
        const newCheckboxes: HierarchyItem[] = [...checkedCheckboxes];
        for (const row of tableData) {
          const foundIndex = checkedCheckboxes.findIndex(
            (selection) =>
              selection.code === row.code &&
              selection.shortName === row.shortName
          );

          // if the row is not already selected, add it to new selection
          if (foundIndex === -1) {
            newCheckboxes.push(row);
          }
        }

        setCheckedCheckboxes(newCheckboxes);
      }
    },
    [tableData, setCheckedCheckboxes, checkedCheckboxes]
  );

  const isChecked = useMemo(
    () =>
      tableData.every((row) =>
        checkedCheckboxes.find(
          (selection) =>
            selection.code === row.code && selection.shortName === row.shortName
        )
      ),
    [checkedCheckboxes, tableData]
  );

  const headerCheckbox = useCallback(
    () => (
      <div className={styles.selectAllCheckboxWrapper}>
        <Checkbox
          checked={isChecked}
          label=""
          name="selectAll"
          // handled by row click
          onChange={() => {}}
          // onChange is not being registered due to layers of buttons on header
          // we need an onClick event to handle the toggleSelectAll function
          // @ts-expect-error onClick not recognised by Checkbox, but works
          onClick={() => {
            toggleSelectAll(isChecked);
          }}
        />
        <span className={styles.selectAllLabel}>{`${formatHierarchyName(
          hierarchyType,
          true,
          false
        )}`}</span>
      </div>
    ),
    [hierarchyType, isChecked, toggleSelectAll]
  );

  const columns: Array<ColumnDef<HierarchyItem>> = [
    {
      accessorFn: (row) => row,
      cell: (info) => itemCheckbox(info.row.original),
      header: headerCheckbox,
      size: 80,
      maxSize: 80,
      id: "products",
    },
  ];

  const onRowClicked = useCallback(
    (event: Event, id: string, original: HierarchyItem) => {
      const newCheckboxes = [...checkedCheckboxes];
      const foundIndex = newCheckboxes.findIndex(
        (x) => x.code === original.code && x.name === original.name
      );

      if (foundIndex > -1) {
        newCheckboxes.splice(foundIndex, 1);
      } else {
        newCheckboxes.push(original);
      }

      setCheckedCheckboxes(newCheckboxes);
    },
    [checkedCheckboxes]
  );

  useEffect(() => {
    setSearchResultsCount?.(currentData?.count);
  }, [currentData?.count, setSearchResultsCount]);

  return (
    <div className={styles.searchTable}>
      {isFetching && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {isSuccess && Boolean(currentData?.results.length) && (
        <div className={styles.tableContainer}>
          <BasicTable
            columns={columns}
            data={tableData}
            enableMultiRowSelection
            onRowClick={onRowClicked}
          />
        </div>
      )}
      {!isFetching && isSuccess && Boolean(!currentData.results.length) && (
        <div className={styles.noresults}>
          <EmptySearch />
        </div>
      )}
    </div>
  );
};
