import classNames from "classnames";
import { type AccordionProps } from "components-ui/src/accordion/Accordion";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { type PropsWithChildren } from "react";
import { useState } from "react";
import styles from "./ReportletAccordion.module.css";

export type ReportletAccordionProps = AccordionProps;

export const ReportletAccordion = (
  properties: PropsWithChildren<ReportletAccordionProps>
) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const boundClassnames = classNames.bind(styles);

  return (
    <Accordion
      accordionState={{ isAccordionOpen: isOpen, toggleOpen: setIsOpen }}
      className={boundClassnames(styles.reportletAccordion, {
        [styles.open]: isOpen,
      })}
      {...properties}
    />
  );
};
