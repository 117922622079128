import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const basketQuantitiesApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/basket-quantities`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (_builder) => ({}),
  reducerPath: "basketQuantitiesApi",
  tagTypes: ["basketQuantities"],
});
