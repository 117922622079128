import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Tag,
  TagVariant,
  Tooltip,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import classnames from "classnames";
import { HierarchyLevelIcon } from "../../hierarchy-level-icon/HierarchyLevelIcon";
import { TransactionSourceIcon } from "../../icons/transaction-source-icon/TransactionSourceIcon";
import { TestId } from "../constants";
import { type Item } from "../models/Item";
import { DragIcon } from "./DragIcon";
import styles from "./HierarchyItem.module.css";

export type HierarchyItemProps = Item & {
  dragActive?: boolean;
  isDisabled?: boolean;
  isDraggable?: boolean;
  tooltipText?: string;
};

export const HierarchyItem = ({
  name,
  shortName,
  count,
  type,
  isDisabled = false,
  isDraggable = true,
  dataSource,
  dragActive = false,
  isPlaceholder,
  tooltipText,
}: HierarchyItemProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  const element = isPlaceholder ? (
    <div className={classnames(styles.itemContainer, styles.itemPlaceholder)} />
  ) : (
    <div
      className={classnames(
        isDraggable && styles.draggableItem,
        isDisabled && styles.disabled,
        styles.itemContainer
      )}
    >
      <div className={styles.itemInfo}>
        <div
          className={styles.itemInfoLevelIcon}
          data-testid={TestId.FolderIcon}
        >
          <HierarchyLevelIcon shortName={shortName} type={type} />
        </div>
        <span
          className={styles.itemName}
          data-testid={TestId.ItemName}
          title={name}
        >
          {name}
        </span>
        {count !== undefined && (
          <div className={styles.countTag} data-testid={TestId.CountTag}>
            <Tag
              className={styles.itemCountTag}
              text={count.toString()}
              title={count.toString()}
              variant={TagVariant.Lozenge}
            />
          </div>
        )}
      </div>
      <div className={styles.iconGroup}>
        <TransactionSourceIcon
          availableTransactionSources={availableTransactionSources}
          greyedOut={!dragActive}
          transactionSource={dataSource}
        />
        {isDraggable && (
          <div data-testid={TestId.DragIcon}>
            <DragIcon />
          </div>
        )}
      </div>
    </div>
  );

  return tooltipText ? (
    <Tooltip
      key={`disabled-${shortName}`}
      trigger={element}
      variant={TooltipVariant.ArrowDark}
    >
      <div className={styles.tooltipText}>{tooltipText}</div>
    </Tooltip>
  ) : (
    element
  );
};
