import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Suspense, type PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";

export const AppMeta = ({ children }: PropsWithChildren) => {
  const { locale, uiTheme } = useDivision();

  // not inlcuded title as we get this in from yaml and can be updated in remote-dev.config.ts for local
  // no linking of theme.css as retailer-logo.svg is not getting bunlded as it is refernced in theme.css

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <html lang={locale} />
        <body data-qtheme={uiTheme} />
      </Helmet>
      {children}
    </Suspense>
  );
};
