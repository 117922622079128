import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type RangePerformanceParametersDto } from "../models/RangePerformanceParametersDto";

const TagTypes = {
  GetParameters: "GetParameters",
};

export const rangePerformanceApi = createApi({
  baseQuery: getBaseQuery(`/api/range-performance`),
  endpoints: (builder) => ({
    getParameters: builder.query<RangePerformanceParametersDto, string>({
      query: (division) => `Parameters/GetParameters/${division}`,
      providesTags: [TagTypes.GetParameters],
    }),
  }),
  reducerPath: "rangePerformanceApi",
  tagTypes: Object.keys(TagTypes),
});

export const { useGetParametersQuery } = rangePerformanceApi;
