import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type TableRow } from "../models/TableRow";

// Function to find parent and insert children into subrows
export const insertChildrenIntoPosition = <T>(
  data: Array<TableRow<T>>,
  subRows: Array<TableRow<T>>,
  parentCode: number
): Array<TableRow<T>> => {
  for (let index = 0; index < data.length; index++) {
    if (data[index].hierarchyItem.nodeNumber === parentCode) {
      const deepCopy = JSON.parse(JSON.stringify(data[index]));
      deepCopy.subRows = [...data[index].subRows, ...subRows];
      data[index] = deepCopy;
      return data;
    }

    if (data[index].subRows.length) {
      insertChildrenIntoPosition(data[index].subRows, subRows, parentCode);
    }
  }

  return data;
};

// Recursive function to create nested rows
const createNode = <T>(
  row: TableRow<T>,
  visited: Array<TableRow<T>>,
  rootNodes: Array<TableRow<T>>,
  tableData: Array<TableRow<T>>
) => {
  // If node has already been visited ignore
  if (visited[row.hierarchyItem.nodeNumber]) {
    return;
  }

  // Mark that we have visited
  visited[row.hierarchyItem.nodeNumber] = row;

  const parentNodeNumber = row.hierarchyItem.parentNodeNumber;
  // If row does not have a parent then it is a root node
  // Append this to the list of root nodes
  if (parentNodeNumber === undefined) {
    rootNodes.push(row);
    return;
  }

  // Otherwise it is a child node do the following:
  // Recursively create parent if parent has not been visited
  if (!visited[parentNodeNumber]) {
    const parentRow = tableData.find(
      (rw) => rw.hierarchyItem.nodeNumber === parentNodeNumber
    );
    if (parentRow) {
      createNode(parentRow, visited, rootNodes, tableData);
    }
  }

  insertChildrenIntoPosition(rootNodes, [row], parentNodeNumber);
};

/// Function that takes a flat list and converts
// it into a nested array of root nodes with subRows
export const buildNestedRows = <T>(
  rows: Array<TableRow<T>>
): Array<TableRow<T>> => {
  const visitedNodes: Array<TableRow<T>> = [];
  const rootNodes: Array<TableRow<T>> = [];

  for (const rw of rows) {
    createNode(
      { data: rw.data, hierarchyItem: rw.hierarchyItem, subRows: rw.subRows },
      visitedNodes,
      rootNodes,
      rows
    );
  }

  return rootNodes;
};

// Function to find all descendants of a given row
export const getDescendantRows = <T>(row: TableRow<T>): number[] => {
  const descendants: number[] = [];
  const nodeStack: Array<TableRow<T>> = [];

  for (const node of row.subRows) {
    nodeStack.push(node);
  }

  while (nodeStack.length > 0) {
    const currentRow = nodeStack.pop();

    if (currentRow === undefined) {
      return descendants;
    }

    descendants.push(currentRow.hierarchyItem.nodeNumber);
    for (const subRow of currentRow.subRows) {
      nodeStack.push(subRow);
    }
  }

  return descendants;
};

// Function that takes a flat list of rows and returns all parents
// used to get the initally expanded rows
export const getParentRows = <T>(rows: Array<TableRow<T>>) => {
  const parents = new Set<number>();
  for (const row of rows) {
    if (row.hierarchyItem.parentNodeNumber !== undefined) {
      parents.add(row.hierarchyItem.parentNodeNumber);
    }
  }

  return Array.from(parents);
};

export const getFocalItemPath = <T>(
  focalItem: HierarchySliceNodeDto,
  tableRows: Array<TableRow<T>>,
  path: number[]
) => {
  path.push(focalItem.nodeNumber);
  const parentNode = tableRows.find(
    (row) => row.hierarchyItem.nodeNumber === focalItem.parentNodeNumber
  );

  if (parentNode) {
    getFocalItemPath(parentNode.hierarchyItem, tableRows, path);
  }

  return path;
};

export const getLowestCommonNodeNumber = <T>(
  focalItems: HierarchySliceNodeDto[],
  tableRows: Array<TableRow<T>>
) => {
  const focalItemPaths = focalItems.map((item) =>
    getFocalItemPath(item, tableRows, []).reverse()
  );

  const minPathLength =
    focalItemPaths.length === 0
      ? 0
      : focalItemPaths.map((path) => path.length).sort((a, b) => a - b)[0];
  let lowestCommonNodeNumber = 0;
  for (let index = 0; index < minPathLength; index++) {
    const nodeNumbers = focalItemPaths.map((path) => path[index]);

    if (
      nodeNumbers.filter((nodeNumber) => nodeNumber === nodeNumbers[0])
        .length !== nodeNumbers.length
    ) {
      break;
    }

    lowestCommonNodeNumber = nodeNumbers[0];
  }

  return lowestCommonNodeNumber;
};

export const getLowestCommonDepth = <T>(
  focalItems: HierarchySliceNodeDto[],
  tableRows: Array<TableRow<T>>
) => {
  const lowestCommonNodeNumber = getLowestCommonNodeNumber(
    focalItems,
    tableRows
  );
  const commonParentDepth =
    tableRows.find(
      (row) => row.hierarchyItem.nodeNumber === lowestCommonNodeNumber
    )?.hierarchyItem.depth ?? 0;
  const depthToIndexAgainst = focalItems.some(
    (item) => item.nodeNumber === lowestCommonNodeNumber
  )
    ? commonParentDepth - 1
    : commonParentDepth;

  return depthToIndexAgainst < 0 ? 0 : depthToIndexAgainst;
};
