import {
  type GeneratedReportDto,
  type NestedFolderDto,
  FeatureFlag,
  ReportStatus,
  useGetAvailableReportsQuery,
  useGetGeneratedReportsQuery,
  useGetOrganizationUsersQuery,
  useGetReportParametersQuery,
  useGetSharingActivitiesQuery,
  useGetUsersByIdQuery,
  type SharedUserDto,
  useDeleteReportsMutation,
  useLazyGetRerunParameterConfigurationsQuery,
  ddLog,
  useLazyGetGeneratedReportsQuery,
  useGetSavedParametersDetailsQuery,
  useGetSavedParametersSharingActivitiesQuery,
  useDeleteSavedParametersMutation,
  useLazyGetSavedParametersQuery,
  useRenameSavedParametersMutation,
} from "@quantium-enterprise/common-ui";
import {
  useEventTrackingServiceContext,
  TrackingEvent,
  TrackingComponent,
  GenericTrackingProperties,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useFlags,
  useModuleAccessProtection,
} from "@quantium-enterprise/hooks-ui";
import {
  MessageVariant,
  Nav,
  NavSize,
  NavVariant,
  QbitEmitToast,
  QbitToastMessage,
} from "@quantium-enterprise/qds-react";
import { AppFooter } from "components-ui/src/footer/AppFooter";
import { ReportInfoPanelDrawerContent } from "components-ui/src/info-panel/InfoPanel";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import PanelWithSideDrawer from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import { RenameItem } from "components-ui/src/rename-item/RenameItem";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  NavLink,
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { resetParametersState } from "report-parameters-ui/src/states/report-wizard-slice";
import styles from "./MyReports.module.css";
import GeneratedReports, {
  convertAndFlattenFolders,
} from "./generated-reports/GeneratedReportsNoRedux";
import SavedAndScheduled from "./saved-and-scheduled/SavedAndScheduled";
import { SavedAndScheduledInfoPanelContent } from "./saved-and-scheduled/SavedAndScheduledInfoPanelContent";

export type MyReportContextType = {
  allReportSharedUsers: SharedUserDto[] | undefined;
  isRowListLoading: boolean;
  organisationUsers: SharedUserDto[] | undefined;
  rootFolder: NestedFolderDto;
};

export const useMyReportContext = () => useOutletContext<MyReportContextType>();

const getReportsFlatList = (folder: NestedFolderDto): GeneratedReportDto[] => {
  let reports: GeneratedReportDto[] = [...folder.reports];

  if (folder.folders) {
    for (const subFolder of folder.folders) {
      reports = reports.concat(getReportsFlatList(subFolder));
    }
  }

  return reports;
};

const generatedReportsId = "generated-reports";
const savedAndScheduledId = "saved-and-scheduled";
const tabs = [
  {
    element: <GeneratedReports />,
    id: generatedReportsId,
    label: "Generated reports",
    path: "",
  },
  {
    element: <SavedAndScheduled />,
    id: savedAndScheduledId,
    label: "Saved selections",
    path: "saved-and-scheduled",
  },
];

export const myReportsRoutes = tabs.flatMap((tab) => [
  tab,
  {
    ...tab,
    path: tab.path ? `${tab.path}/:id` : ":id",
  },
]);

const MyReportsTabs = ({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
}) => (
  <div className={styles.myReportsHeading}>
    <h3 className={styles.myReportsHeader}>My reports</h3>
    <Nav
      activeIndex={activeIndex}
      size={NavSize.Medium}
      variant={NavVariant.Tab}
    >
      {/* NavLink from react-router requires this to work with qbit */}
      {/* Can use qbit NavLink but because it links via href, the navigation triggers page reload */}
      {tabs.map((tab, index) => (
        <NavLink
          className="q-nav-item-control q-nav-item-link"
          data-testid={tab.id}
          key={tab.path}
          onClick={() => setActiveIndex(index)}
          to={tab.path}
        >
          {tab.label}
        </NavLink>
      ))}
    </Nav>
  </div>
);

const findLastIndex = <T,>(array: T[], predicate: (value: T) => boolean) => {
  let index = array.length - 1;
  for (; index >= 0; --index) {
    if (predicate(array[index])) {
      return index;
    }
  }

  // -1 will be returned at the end
  return index;
};

// eslint-disable-next-line complexity
export const MyReportsNoRedux = () => {
  useModuleAccessProtection((moduleAccess) => moduleAccess.myReports);
  const eventTrackingService = useEventTrackingServiceContext();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const division = useDivision();
  const [renameReport] = useRenameReportMutation();
  const [deleteReports] = useDeleteReportsMutation();
  const [deleteSavedParameters] = useDeleteSavedParametersMutation();
  const [renameSavedParameters] = useRenameSavedParametersMutation();
  const [triggerGetGeneratedReports] = useLazyGetGeneratedReportsQuery();
  const [triggerGetSavedParameters] = useLazyGetSavedParametersQuery();
  const [triggerRerunQuery, { isLoading: isRerunLoading }] =
    useLazyGetRerunParameterConfigurationsQuery();
  const flags = useFlags();
  const { id: parametersId } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [rootFolder, setRootFolder] = useState<NestedFolderDto>();
  // findLastIndex because the first tab will be empty path which will always match
  const [activeIndex, setActiveIndex] = useState<number>(
    findLastIndex(tabs, (tab) => location.pathname.includes(tab.path))
  );
  const activeTabId = tabs[activeIndex]?.id;
  const focalReportId =
    activeTabId === generatedReportsId ? parametersId : undefined;
  const focalSavedParametersId =
    activeTabId === savedAndScheduledId ? parametersId : undefined;
  const [infoPanelCurrentNavIndex, setInfoPanelCurrentNavIndex] = useState(0);

  const trackButtonClick = (reportName: string, reportId: string) =>
    eventTrackingService.trackEvent(
      [TrackingComponent.MyReports, TrackingComponent.Report],
      TrackingEvent.Opened,
      new GenericTrackingProperties({
        reportName,
        reportId,
        method: "View report button",
      })
    );

  const {
    data: focalReport,
    isLoading: isFocalReportLoading,
    isError: isFocalReportError,
  } = useGetReportParametersQuery(
    { divisionName: activeDivisionName, reportId: focalReportId ?? "" },
    { skip: !focalReportId || !activeDivisionName }
  );

  const {
    data: savedParameters,
    isLoading: isSavedParametersLoading,
    isError: isSavedParametersError,
  } = useGetSavedParametersDetailsQuery(
    {
      divisionName: activeDivisionName,
      savedParametersId: focalSavedParametersId ?? "",
    },
    { skip: !focalSavedParametersId || !activeDivisionName }
  );

  const { data: availableReportsData } = useGetAvailableReportsQuery(
    activeDivisionName,
    {
      skip: !activeDivisionName,
    }
  );

  const {
    data: fetchedRootFolderData,
    isLoading: isFetchedRootFolderDataLoading,
    isSuccess: isFetchedRootFolderDataSuccess,
  } = useGetGeneratedReportsQuery(
    {
      divisionName: activeDivisionName,
    },
    {
      skip: !activeDivisionName,
      refetchOnMountOrArgChange: true,
      pollingInterval: 30_000,
    }
  );

  useEffect(
    () => setRootFolder(fetchedRootFolderData),
    [fetchedRootFolderData]
  );

  const reportsFlatList = useMemo(
    () => (rootFolder ? getReportsFlatList(rootFolder) : []),
    [rootFolder]
  );

  const { data: organisationUsers } = useGetOrganizationUsersQuery(
    { divisionName: activeDivisionName },
    { skip: !activeDivisionName || !flags[FeatureFlag.SharingReports] }
  );

  const {
    data: reportSharingActivities,
    isLoading: isReportSharingActivitiesLoading,
  } = useGetSharingActivitiesQuery(
    { divisionName: activeDivisionName, reportId: focalReportId ?? "" },
    {
      skip:
        !focalReportId ||
        !activeDivisionName ||
        !flags[FeatureFlag.SharingReports],
    }
  );

  const {
    data: savedParametersSharingActivities,
    isLoading: isSavedParametersSharingActivitiesLoading,
  } = useGetSavedParametersSharingActivitiesQuery(
    {
      divisionName: activeDivisionName,
      savedParametersId: focalSavedParametersId ?? "",
    },
    {
      skip:
        !focalSavedParametersId ||
        !activeDivisionName ||
        !flags[FeatureFlag.SharingReports],
    }
  );

  const sharingActivities =
    activeTabId === generatedReportsId
      ? reportSharingActivities
      : savedParametersSharingActivities;
  const isSharingActivitiesLoading =
    activeTabId === generatedReportsId
      ? isReportSharingActivitiesLoading
      : isSavedParametersSharingActivitiesLoading;

  const { data: sharedUsers, isLoading: isSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      { skip: !sharingActivities || !flags[FeatureFlag.SharingReports] }
    );

  const {
    data: allReportSharedUsers,
    isLoading: isAllReportSharedUsersLoading,
  } = useGetUsersByIdQuery(
    {
      payload: {
        SalesforceUserIds: reportsFlatList.flatMap(
          (report) => report.sharedWithUserIds
        ),
      },
    },
    {
      skip: !flags[FeatureFlag.SharingReports],
    }
  );
  const focalReportInList = reportsFlatList.find(
    (item) => item.id === focalReportId
  );

  const focalReportStatus = focalReportInList?.reportStatus;

  const focalItemPath =
    focalReportStatus === ReportStatus.Completed
      ? `/${activeDivisionName}/${focalReport?.reportType}/${focalReportId}`
      : undefined;

  const reportTypeDisplayName = availableReportsData?.availableReports
    .filter((report) => report.name === focalReport?.reportType)
    .map((report) => report.displayName)[0];

  const isReportDisabled = availableReportsData?.availableReports.find(
    (report) => report.name === focalReport?.reportType
  )?.disabled;

  const getDefaultMyReportsPath = useCallback(
    () =>
      activeTabId === savedAndScheduledId
        ? generatePath("/:division/my-reports/saved-and-scheduled", {
            division: activeDivisionName,
          })
        : generatePath("/:division/my-reports", {
            division: activeDivisionName,
          }),
    [activeDivisionName, activeTabId]
  );

  useEffect(() => {
    if (isFocalReportError || isSavedParametersError) {
      navigate(getDefaultMyReportsPath());
    }
  }, [
    isFocalReportError,
    isSavedParametersError,
    getDefaultMyReportsPath,
    navigate,
  ]);

  const renameFunction = async (newReportName: string, reportId: string) =>
    await renameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId },
    })
      .unwrap()
      .then(
        async () =>
          await triggerGetGeneratedReports({ divisionName: division.name })
      );

  const { handleRename } = RenameItem({
    itemType: "Report",
    renameItem: renameFunction,
  });

  const deleteFromList = (reportId: string) => {
    setRootFolder((previousState) => {
      if (!previousState) return undefined;
      const newState = { ...previousState };
      newState.reports = previousState.reports.filter((x) => x.id !== reportId);
      return newState;
    });
  };

  const handleDelete = async (itemIds: string[]) =>
    await deleteReports({
      divisionName: activeDivisionName,
      itemIds,
    })
      .unwrap()
      .then(() => deleteFromList(itemIds[0]));

  const handleDeleteSavedParameters = useCallback(
    async (savedParametersId: string) =>
      await deleteSavedParameters({
        divisionName: activeDivisionName,
        savedParametersId,
      })
        .unwrap()
        .then(
          async () =>
            await triggerGetSavedParameters({
              divisionName: activeDivisionName,
            })
        ),
    [activeDivisionName, deleteSavedParameters, triggerGetSavedParameters]
  );

  const handleRenameSavedParameters = useCallback(
    async (savedParametersId: string, newItemName: string) =>
      await renameSavedParameters({
        divisionName: activeDivisionName,
        payload: {
          newName: newItemName,
          savedParametersId,
        },
      })
        .unwrap()
        .then(
          async () =>
            await triggerGetSavedParameters({
              divisionName: activeDivisionName,
            })
        ),
    [activeDivisionName, renameSavedParameters, triggerGetSavedParameters]
  );

  const handleRerun = async () => {
    const requestDto = {
      divisionName: activeDivisionName,
      reportType: focalReport?.reportType ?? "",
      rerunReportId: focalReportId ?? "",
    };
    await triggerRerunQuery(requestDto)
      .unwrap()
      .then((data) => {
        dispatch(resetParametersState());
        navigate(
          `/${activeDivisionName}/report-wizard/${focalReport?.reportType}`,
          {
            state: {
              reRunConfig: data,
            },
          }
        );
      })
      .catch((error) => {
        ddLog("Validation failed for report re-run.", {}, "error", error);
        QbitEmitToast(
          <QbitToastMessage
            content={
              <p>
                Something went wrong. Please try again later. Contact support if
                error persists.
              </p>
            }
            heading={<h5>Report wasn’t re-run</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />
        );
      });
  };

  const showDrawer = Boolean(focalReportId) || Boolean(focalSavedParametersId);
  const drawerContext =
    activeTabId === savedAndScheduledId
      ? SavedAndScheduledInfoPanelContent({
          isLoading:
            isSavedParametersLoading ||
            isSharingActivitiesLoading ||
            isSharedUsersLoading,
          savedParameters,
          savedParametersSharedUsers: sharedUsers,
          sharingActivities,
          currentNavIndex: infoPanelCurrentNavIndex,
          setCurrentNavIndex: setInfoPanelCurrentNavIndex,
          flags,
          onClose: () => navigate(getDefaultMyReportsPath()),
          deleteItem: handleDeleteSavedParameters,
          renameItem: handleRenameSavedParameters,
        })
      : ReportInfoPanelDrawerContent({
          deleteItem: handleDelete,
          focalReport,
          focalReportPath: focalItemPath,
          infoPanelType: InfoPanelType.MyReports,
          isLoading:
            isFocalReportLoading ||
            isSharingActivitiesLoading ||
            isSharedUsersLoading,
          isReportDisabled,
          isRerunLoading,
          onDelete: () => navigate(getDefaultMyReportsPath()),
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- fire and forget, the handleRerun function will deal with the result
          onRerun: handleRerun,
          organisationUsers,
          renameItem: handleRename,
          reportSharedUsers: sharedUsers,
          reportTypeDisplayName,
          sharingActivities,
          trackButtonClick,
          currentNavIndex: infoPanelCurrentNavIndex,
          setCurrentNavIndex: setInfoPanelCurrentNavIndex,
          flags,
          folders: convertAndFlattenFolders(rootFolder?.folders ?? []),
        });

  return (
    <div className={styles.myReportsContainer}>
      <PanelWithSideDrawer
        closeFunc={() => navigate(getDefaultMyReportsPath())}
        drawerContent={drawerContext}
        isOverlay={false}
        showDrawer={showDrawer}
      >
        <div className={styles.myReportsLayout}>
          <MyReportsTabs
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <div className={styles.myReportsContent}>
            <Outlet
              context={{
                allReportSharedUsers,
                isRowListLoading:
                  isFetchedRootFolderDataLoading ||
                  !isFetchedRootFolderDataSuccess,
                isAllReportSharedUsersLoading,
                rootFolder,
                organisationUsers,
              }}
            />
          </div>
        </div>
      </PanelWithSideDrawer>
      <AppFooter />
    </div>
  );
};

export default MyReportsNoRedux;
