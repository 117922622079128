import { type HierarchyValueDto } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { type BasketAffinitiesLocalSelections } from "../services/basket-affinities-slice";

export const getExportParameterSummary = (
  localSelections: BasketAffinitiesLocalSelections,
  focalItem?: HierarchyValueDto
) => [
  {
    name: "Focal item",
    value: `(${hierarchyLevelDisplayLabel(focalItem?.shortName ?? "")}) ${
      focalItem?.name
    }`,
  },
  { name: "Time", value: localSelections.time },
  {
    name: "Location",
    value: `(${hierarchyLevelDisplayLabel(
      localSelections.location.shortName
    )}) ${localSelections.location.name}`,
  },
  { name: "Channel", value: localSelections.channel.label },
  { name: "Segmentation", value: localSelections.segmentation.label },
  { name: "Customer segment", value: localSelections.segment.label },
  {
    name: "Baskets with both threshold",
    value: localSelections.basketsWithBothThreshold.toString(),
  },
  {
    name: "Uplift threshold",
    value: localSelections.upliftThreshold.toString(),
  },
];
