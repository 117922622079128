export const Structure = ({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6637 7.83333V2.5H9.99705V4.5H5.99705V2.5H1.33038V7.83333H5.99705V5.83333H7.33038V12.5H9.99705V14.5H14.6637V9.16667H9.99705V11.1667H8.66372V5.83333H9.99705V7.83333H14.6637ZM4.66372 6.5H2.66372V3.83333H4.66372V6.5ZM11.3304 10.5H13.3304V13.1667H11.3304V10.5ZM11.3304 3.83333H13.3304V6.5H11.3304V3.83333Z"
      fill="#B5B5B5"
    />
  </svg>
);
