import {
  ddLog,
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { ReportButtonTabs } from "../../../common/components/ReportTabComponents/ReportButtonTabs";
import { ReportTabItem } from "../../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabs } from "../../../common/components/ReportTabComponents/ReportTabs";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { LocalParameters } from "../../../common/models/LocalParameterId";
import { type RootState } from "../../../store";
import { CrossShopFeatureFlags } from "../../constants/cross-shop-feature-flags";
import { type ProductInteraction } from "../../models/CrossShopSegmentationResponseDto";
import { useLazyGetSegmentationChartDataQuery } from "../../services/customer-cross-shop-segmentation-api-slice";
import {
  selectChannel,
  selectFocalItems,
  selectLocalParametersInitialised,
  selectLocalSelections,
  selectLocation,
  selectProductInteraction,
  selectReportId,
  selectSegmentation,
  selectTimePeriodLength,
  toggleSegmentationChartDataLabels,
} from "../../services/customer-cross-shop-slice";
import { getLocalParametersSummary } from "../../utils/getLocalParametersSummary";
import { CrossShopSegmentationChart } from "./CrossShopSegmentationChart";
import styles from "./CrossShopSegmentationReportlet.module.css";
import { csvTransformation } from "./csvTransformation";

export enum CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS {
  Actual = "Actual",
  Index = "Index",
}

export const enum CROSS_SHOP_SEGMENTATION_REPORTLET_TABS {
  ProductInteractions = "Product interactions",
  Segment = "Segment",
}

export type CrossShopSegmentationReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const CrossShopSegmentationReportlet = ({
  infoPanelSummary,
}: CrossShopSegmentationReportletProps) => {
  const { name: divisionName, locale, currency } = useDivision();
  const { id } = useParams();
  const dispatch = useDispatch();
  const chartContainerRef = useRef<HTMLElement>();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CrossShopFeatureFlags.ReportExport] ?? false;

  const [activeTab, setActiveTab] = useState<string>(
    CROSS_SHOP_SEGMENTATION_REPORTLET_TABS.ProductInteractions
  );

  const [activeProductInteractionsTab, setActiveProductInteractionsTab] =
    useState<string>(CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Index);

  const [activeSegmentTab, setActiveSegmentTab] = useState<string>(
    CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Index
  );

  const focalItems = useSelector(selectFocalItems);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const productInteraction = useSelector(selectProductInteraction);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const segmentation = useSelector(selectSegmentation)[0];
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const localSelections = useSelector(selectLocalSelections);
  const reportId = useSelector(selectReportId);

  const { showSegmentationChartDataLabels } = useSelector(
    (state: RootState) => ({
      showSegmentationChartDataLabels:
        state.customerCrossShop.showSegmentationChartDataLabels,
    })
  );

  const reportName = useSelector(
    (state: RootState) => state.customerCrossShop.reportName
  );

  // Chart Query
  const [
    triggerChartDataQuery,
    { data, isFetching: isLoadingSegmentationData },
  ] = useLazyGetSegmentationChartDataQuery();

  const interactionsFilteredData = useMemo(
    () =>
      data?.interaction.data.map((x) => ({
        category: x.name,
        data: x.data[
          productInteraction.value as keyof typeof ProductInteraction
        ],
      })),
    [data, productInteraction]
  );

  const segmentsFilteredData = useMemo(
    () =>
      data?.segment.data.map((x) => ({
        category: x.name,
        data: x.data[
          productInteraction.value as keyof typeof ProductInteraction
        ],
      })),
    [data, productInteraction]
  );

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Cross_Shop_Segmentation_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location.name]
  );

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        data,
        activeTab,
        activeProductInteractionsTab,
        activeSegmentTab,
        focalItems,
        interactionsFilteredData,
        segmentsFilteredData,
        productInteraction,
        currencySymbol
      ),
    [
      data,
      activeTab,
      activeProductInteractionsTab,
      activeSegmentTab,
      focalItems,
      interactionsFilteredData,
      productInteraction,
      segmentsFilteredData,
      currencySymbol,
    ]
  );

  const fetchData = useCallback(async () => {
    await triggerChartDataQuery({
      divisionName,
      payload: {
        focalItems,
        localSelectedValues: [
          {
            id: LocalParameters.Channel,
            values: [channel.value as string],
          },
          {
            id: LocalParameters.Segmentation,
            // Only sending Segmentation to backend, not including Segments
            values: [segmentation.value as string],
          },
          {
            id: LocalParameters.LocationHierarchy,
            values: [location.nodeNumber.toString()],
          },
        ],
        reportId: id ?? "",
      },
    });
  }, [
    channel.value,
    divisionName,
    focalItems,
    id,
    location.nodeNumber,
    segmentation.value,
    triggerChartDataQuery,
  ]);

  useEffect(() => {
    if (
      divisionName &&
      focalItems.length >= 0 &&
      localParametersInitialised &&
      reportId === id
    ) {
      fetchData()
        // make sure to catch any error
        .catch((error) => {
          // FIXME throw this somewhere
          ddLog("ERROR", {}, "error", error);
        });
    }
  }, [
    divisionName,
    fetchData,
    focalItems.length,
    localParametersInitialised,
    reportId,
    id,
  ]);

  const parameterSummary = useMemo(
    () =>
      getLocalParametersSummary(localSelections, focalItems, {
        activeTab,
        activeInnerTab:
          activeTab ===
          CROSS_SHOP_SEGMENTATION_REPORTLET_TABS.ProductInteractions
            ? activeProductInteractionsTab
            : activeSegmentTab,
      }),
    [
      localSelections,
      focalItems,
      activeTab,
      activeSegmentTab,
      activeProductInteractionsTab,
    ]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showSegmentationChartDataLabels,
      value: "",
    },
  ];

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  const interactionsIndexData = useMemo(
    () =>
      structuredClone(
        interactionsFilteredData?.map((x) => ({
          name: x.category,
          data: x.data.index,
          type: "column" as const,
        })) ?? []
      ),
    [interactionsFilteredData]
  );

  const interactionsActualData = useMemo(
    () =>
      structuredClone(
        interactionsFilteredData?.map((x) => ({
          name: x.category,
          data: x.data.actual,
          type: "column" as const,
        })) ?? []
      ),
    [interactionsFilteredData]
  );

  const segmentIndexData = useMemo(
    () =>
      structuredClone(
        segmentsFilteredData?.map((x) => ({
          name: x.category,
          data: x.data.index,
          type: "column" as const,
        })) ?? []
      ),
    [segmentsFilteredData]
  );

  const segmentActualData = useMemo(
    () =>
      structuredClone(
        segmentsFilteredData?.map((x) => ({
          name: x.category,
          data: x.data.actual,
          type: "column" as const,
        })) ?? []
      ),
    [segmentsFilteredData]
  );

  return (
    <ReportletAccordion
      subtitle="Understand the customer breakdown of your item interactions."
      title="Cross shop segmentation"
    >
      <div className={styles.chartOptionsContainer}>
        <ReportTabs activeTab={activeTab} onClickTabItem={setActiveTab}>
          <ReportTabItem
            label={CROSS_SHOP_SEGMENTATION_REPORTLET_TABS.ProductInteractions}
            value={CROSS_SHOP_SEGMENTATION_REPORTLET_TABS.ProductInteractions}
          >
            <ReportButtonTabs
              activeTab={activeProductInteractionsTab}
              onClickButtonTabItem={setActiveProductInteractionsTab}
            >
              <ReportTabItem
                label={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Index}
                value={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Index}
              >
                <ChartFooterWrapper
                  height="400px"
                  parameters={parameterSummary}
                  ref={chartContainerRef}
                >
                  <ErrorBoundary>
                    <NoDataChartWrapper
                      isLoading={isLoadingSegmentationData}
                      noData={
                        !isLoadingSegmentationData &&
                        data?.interaction.categories.length === 0
                      }
                    >
                      <CrossShopSegmentationChart
                        categories={data?.interaction.categories ?? []}
                        data={interactionsIndexData}
                        format="Integer"
                        indexMetric
                        onOptionsChanged={setCurrentOptions}
                      />
                    </NoDataChartWrapper>
                  </ErrorBoundary>
                </ChartFooterWrapper>
              </ReportTabItem>
              <ReportTabItem
                label={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual}
                value={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual}
              >
                <ChartFooterWrapper
                  height="400px"
                  parameters={parameterSummary}
                  ref={chartContainerRef}
                >
                  <ErrorBoundary>
                    <NoDataChartWrapper
                      isLoading={isLoadingSegmentationData}
                      noData={
                        !isLoadingSegmentationData &&
                        data?.interaction.categories.length === 0
                      }
                    >
                      <CrossShopSegmentationChart
                        categories={data?.interaction.categories ?? []}
                        data={interactionsActualData}
                        format={
                          data?.dataFormat[
                            productInteraction.value as keyof typeof ProductInteraction
                          ] ?? "Decimal"
                        }
                        indexMetric={false}
                        onOptionsChanged={setCurrentOptions}
                      />
                    </NoDataChartWrapper>
                  </ErrorBoundary>
                </ChartFooterWrapper>
              </ReportTabItem>
            </ReportButtonTabs>
          </ReportTabItem>
          <ReportTabItem
            label={CROSS_SHOP_SEGMENTATION_REPORTLET_TABS.Segment}
            value={CROSS_SHOP_SEGMENTATION_REPORTLET_TABS.Segment}
          >
            <ReportButtonTabs
              activeTab={activeSegmentTab}
              onClickButtonTabItem={setActiveSegmentTab}
            >
              <ReportTabItem
                label={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Index}
                value={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Index}
              >
                <ChartFooterWrapper
                  height="400px"
                  parameters={parameterSummary}
                  ref={chartContainerRef}
                >
                  <ErrorBoundary>
                    <NoDataChartWrapper
                      isLoading={isLoadingSegmentationData}
                      noData={
                        !isLoadingSegmentationData &&
                        data?.segment.categories.length === 0
                      }
                    >
                      <CrossShopSegmentationChart
                        categories={data?.segment.categories ?? []}
                        data={segmentIndexData}
                        format="Integer"
                        indexMetric
                        onOptionsChanged={setCurrentOptions}
                      />
                    </NoDataChartWrapper>
                  </ErrorBoundary>
                </ChartFooterWrapper>
              </ReportTabItem>
              <ReportTabItem
                label={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual}
                value={CROSS_SHOP_SEGMENTATION_REPORTLET_INNER_TABS.Actual}
              >
                <ChartFooterWrapper
                  height="400px"
                  parameters={parameterSummary}
                  ref={chartContainerRef}
                >
                  <ErrorBoundary>
                    <NoDataChartWrapper
                      isLoading={isLoadingSegmentationData}
                      noData={
                        !isLoadingSegmentationData &&
                        data?.segment.categories.length === 0
                      }
                    >
                      <CrossShopSegmentationChart
                        categories={data?.segment.categories ?? []}
                        data={segmentActualData}
                        format={
                          data?.dataFormat[
                            productInteraction.value as keyof typeof ProductInteraction
                          ] ?? "Decimal"
                        }
                        indexMetric={false}
                        onOptionsChanged={setCurrentOptions}
                      />
                    </NoDataChartWrapper>
                  </ErrorBoundary>
                </ChartFooterWrapper>
              </ReportTabItem>
            </ReportButtonTabs>
          </ReportTabItem>
        </ReportTabs>
        <div className={styles.container}>
          <ChartOptions
            dataLabelsOptions={dataLabelOptions}
            downloadWizardOptions={
              currentOptions
                ? {
                    chartOptions: currentOptions,
                    reportIcon: (
                      <ReportIcon type={ReportType.CustomerCrossShop} />
                    ),
                    chartTitle: `Cross shop segmentation - ${reportName}`,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={exportFilename}
            getCSVData={chartCsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={infoPanelSummary}
            toggleDataLabels={() => {
              dispatch(toggleSegmentationChartDataLabels());
            }}
          />
        </div>
      </div>
    </ReportletAccordion>
  );
};
