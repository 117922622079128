type BandSelection = {
  maximum: number;
  minimum: number;
};

export type BandsSelectionSummaryProps = {
  bands: BandSelection[];
};

export const BandsSelectionSummary = ({
  bands,
}: BandsSelectionSummaryProps) => (
  <>
    {bands.map((band, index) =>
      index === 0 ? (
        <div key={band.maximum}>Range 1: Top {band.maximum}%</div>
      ) : (
        <div key={band.maximum}>
          Range {index + 1}: {">"}
          {band.minimum}% to {band.maximum}%
        </div>
      )
    )}
  </>
);
