import { type EventTrackingService } from "./EventTrackingService";
import { type TrackingComponent } from "./TrackingComponent";
import { type TrackingEvent } from "./TrackingEvent";
import { type TrackingProperties } from "./TrackingProperties";

export class NoOpEventTrackingService implements EventTrackingService {
  public trackEvent(
    component: TrackingComponent | TrackingComponent[],
    event: TrackingEvent,
    properties?: TrackingProperties
  ) {
    // remove later
    const formattedComponent = Array.isArray(component)
      ? component.join(".")
      : component;

    // eslint-disable-next-line no-console
    console.log(
      `DEBUG: trackEvent simulated (NoOp): ${formattedComponent}.${event}`,
      properties?.getProperties()
    );
  }
}
