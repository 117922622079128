import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationGroupParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getSingleSelectDefaultSelection,
  getMultipleSegmentationGroupSelectDefaultSelection,
  getLocationSelectDefault,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type CustomerProfilingSidePanelSelections } from "../services/customer-profiling-slice";

export const getLocalSelections = (
  localParameters: SidePanelParameter[]
): CustomerProfilingSidePanelSelections => {
  const localSelections: CustomerProfilingSidePanelSelections = {
    channel: {
      value: "",
      label: "",
    },
    indexedAgainst: {
      value: "",
      label: "",
    },
    location: {
      code: "",
      depth: -1,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      nodeNumber: -1,
      shortName: "",
      isDefault: false,
    },
    metric: {
      value: "",
      label: "",
    },
    promotion: {
      value: "",
      label: "",
    },
    segmentation: [
      {
        value: "",
        label: "",
      },
    ],
    time: "",
    timePeriodLength: "",
  };

  for (const parameter of localParameters) {
    if (
      parameter.id === LocalParameters.Time &&
      isTimePeriodParameter(parameter)
    ) {
      const selection = getTimeDefaultSelection(parameter);
      localSelections.time = selection.time;
      localSelections.timePeriodLength = selection.timePeriodLength;
    } else if (
      parameter.id === LocalParameters.Channel &&
      isSingleSelectionParameter(parameter)
    ) {
      const selection = getSingleSelectDefaultSelection(parameter);
      if (selection) {
        localSelections.channel = {
          label: selection.label,
          value: selection.value,
        };
      }
    } else if (
      parameter.id === LocalParameters.LocationHierarchy &&
      isLocationSelectionParameter(parameter)
    ) {
      localSelections.location = getLocationSelectDefault(parameter);
    } else if (
      parameter.id === LocalParameters.Promotion &&
      isSingleSelectionParameter(parameter)
    ) {
      const selection = getSingleSelectDefaultSelection(parameter);
      if (selection) {
        localSelections.promotion = {
          label: selection.label,
          value: selection.value,
        };
      }
    } else if (
      parameter.id === LocalParameters.Metrics &&
      isSingleSelectionParameter(parameter)
    ) {
      const selection = getSingleSelectDefaultSelection(parameter);
      if (selection) {
        localSelections.metric = {
          label: selection.label,
          value: selection.value,
        };
      }
    } else if (isSegmentationGroupParameter(parameter)) {
      const selection =
        getMultipleSegmentationGroupSelectDefaultSelection(parameter);

      localSelections.segmentation = [
        {
          label: selection[0].name,
          value: selection[0].id,
        },
      ];
    }
  }

  return localSelections;
};
