import {
  GenericTrackingProperties,
  type TrackingComponent,
  type TrackingEvent,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
  FeatureFlag,
  ReportType,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { type RootState } from "../store";
import AggregateRankReportlet from "./components/AggregateRankReportlet";
import AggregateRankSidePanel from "./components/AggregateRankSidePanel";
import { useLazyGetLocalParametersQuery } from "./services/aggregate-rank-local-parameters-api-slice";
import {
  onMetadataSuccess,
  onReportOpen,
  reset,
  selectLocalParametersInitialised,
} from "./services/aggregate-rank-slice";

export const AggregateRankReport = () => {
  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  // Store
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );

  const { reportName, reportId } = useSelector((state: RootState) => ({
    reportName: state.aggregateRank.metaData.reportName,
    reportId: state.aggregateRank.metaData.reportId,
  }));

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTabsEnabled]);

  // info panel
  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: id === undefined || !activeDivisionName }
    );

  // Save metadata to the state
  useEffect(() => {
    if (infoPanelSummary) {
      dispatch(
        onMetadataSuccess({
          reportId: infoPanelSummary.id,
          reportName: infoPanelSummary.reportName,
        })
      );
    }
  }, [dispatch, infoPanelSummary]);

  // Local Parameters Query
  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, activeReportId: string) => {
      await getLocalParameters({
        divisionName: division,
        reportId: activeReportId,
      });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Aggregate rank",
        reportId,
        ...properties,
      })
    );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.Header
          renameItem={handleRenameReport}
          reportId={reportId}
          reportName={reportName}
          reportType={ReportType.AggregateRank}
        />

        <ReportView.SidePanel>
          <AggregateRankSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <AggregateRankReportlet />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <AggregateRankReport />,
  path: "aggregate-rank/:id",
};

export default AggregateRankReport;
