/* eslint-disable @typescript-eslint/no-unnecessary-condition -- The highcharts.d.ts file is wrong. Some fields are nullable. */
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import {
  Group,
  Item,
  ItemWidth,
  GroupGutters,
  ItemValign,
  GroupRowspace,
  Text,
  InlineIcon,
  InlineIconGlyph,
  Button,
  ButtonVariant,
  ButtonHeight,
} from "@quantium-enterprise/qds-react";
import { GroupJustify } from "@quantium-enterprise/qds-react/dist/Layout";
import { type TooltipFormatterContextObject } from "highcharts";
import { type PropsWithChildren } from "react";
import { SeriesIcon } from "../charts/SeriesIcon";
import { CustomValueCell } from "../charts/utils";
import styles from "./HighchartsCustomTooltip.module.css";

export type HighchartsCustomTooltipTitleProps = {
  seriesIcon?: {
    color: string;
    type: string;
  };
  title: string;
};

export type HighchartsCustomTooltipSubtitleProps = {
  subtitle: string;
};

export type HighchartsCustomTooltipSeriesProps = {
  alwaysDisplaySign?: boolean;
  benchmark?: number;
  chartDataFormatter?: FormatterFunction;
  hasSentiment?: boolean;
  hideSeriesIcon?: boolean;
  isBenchmarkSeries?: boolean;
  percentFormatter?: FormatterFunction;
  // will be used over chartDataFormatter if provided
  // provides more flexibility to choose which formatter is used per point
  pointFormatter?: (pt: TooltipFormatterContextObject) => FormatterFunction;
  ttData: TooltipFormatterContextObject;
  useHighchartsDefaultIcon?: boolean;
  useSeriesNameForLabels?: boolean;
};

export type HighchartsCustomTooltipProps = {
  alwaysDisplaySign?: boolean;
  benchmark?: number;
  chartDataFormatter?: FormatterFunction;
  hasSentiment?: boolean;
  percentFormatter?: FormatterFunction;
  // will be used over chartDataFormatter if provided
  // provides more flexibility to choose which formatter is used per point
  pointFormatter?: (pt: TooltipFormatterContextObject) => FormatterFunction;
  reverseSeries?: boolean;
  ttData: TooltipFormatterContextObject;
  yAxisTitle: string;
};

const Layout = ({ children }: PropsWithChildren) => (
  <div className={styles.tooltipContainer}>{children}</div>
);

const Title = ({ seriesIcon, title }: HighchartsCustomTooltipTitleProps) =>
  seriesIcon ? (
    <Group className={styles.mainHeader} gutters={GroupGutters.XSmall}>
      <Item valign={ItemValign.Middle} width={ItemWidth.Fit}>
        <SeriesIcon
          color={seriesIcon.color}
          seriesType={seriesIcon.type}
          wideIcon
        />
      </Item>
      <Item valign={ItemValign.Middle}>{title}</Item>
    </Group>
  ) : (
    <Group className={styles.mainHeader}>{title}</Group>
  );

const Subtitle = ({ subtitle }: HighchartsCustomTooltipSubtitleProps) => (
  <Group className={styles.secondaryHeader}>{subtitle}</Group>
);

const Series = ({
  alwaysDisplaySign,
  ttData,
  pointFormatter,
  chartDataFormatter,
  percentFormatter,
  hasSentiment,
  hideSeriesIcon,
  benchmark,
  isBenchmarkSeries,
  useHighchartsDefaultIcon,
  useSeriesNameForLabels,
}: HighchartsCustomTooltipSeriesProps) => (
  <>
    {ttData.points?.map((pt, index) => (
      <Group
        gutters={GroupGutters.Small}
        // eslint-disable-next-line react/no-array-index-key
        key={`${index}_${pt.x}`}
        rowspace={GroupRowspace.XXSmall}
      >
        <Item>
          <Group gutters={GroupGutters.XSmall}>
            {!hideSeriesIcon && (
              <Item valign={ItemValign.Middle} width={ItemWidth.Fit}>
                <SeriesIcon
                  benchmark={
                    Boolean(isBenchmarkSeries) ||
                    (pt.point?.options?.custom?.benchmark ??
                      benchmark === index)
                  }
                  color={String(pt.color)}
                  seriesType={
                    useHighchartsDefaultIcon ? undefined : pt.series.type
                  }
                  // the trends chart doesn't have sentiment
                  wideIcon={!hasSentiment}
                />
              </Item>
            )}
            <Item className={styles.tooltipItem} valign={ItemValign.Middle}>
              {useSeriesNameForLabels ? pt.series.name : String(ttData.x)}
            </Item>
          </Group>
        </Item>

        {pt.point?.options?.custom?.change ? (
          <Item>
            <Group
              gutters={GroupGutters.XXSmall}
              justify={GroupJustify.FlexEnd}
            >
              <Item width={ItemWidth.Fit}>
                {pointFormatter
                  ? pointFormatter(pt)(pt.y)
                  : chartDataFormatter
                  ? chartDataFormatter(pt.y, alwaysDisplaySign)
                  : `${pt.y}`}
              </Item>
              <Item className={styles.divider} width={ItemWidth.Fit}>
                |
              </Item>
              <Item width={ItemWidth.Fit}>
                <CustomValueCell
                  formatter={percentFormatter ?? ((value) => `${value}`)}
                  hasSentiment
                  value={pt.point?.options?.custom?.change}
                />
              </Item>
            </Group>
          </Item>
        ) : (
          <Item>
            <Group
              gutters={GroupGutters.XXSmall}
              justify={GroupJustify.FlexEnd}
            >
              <Item width={ItemWidth.Fit}>
                <CustomValueCell
                  alwaysDisplaySign={alwaysDisplaySign}
                  formatter={
                    pointFormatter
                      ? pointFormatter(pt)
                      : chartDataFormatter ?? ((value) => `${value}`)
                  }
                  hasSentiment={
                    hasSentiment ??
                    pt.point?.options?.custom?.hasSentiment ??
                    false
                  }
                  value={pt.y}
                />
              </Item>
            </Group>
          </Item>
        )}
      </Group>
    ))}
  </>
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UnPlottedSeries = ({ unplottedSeries }: any) => (
  <>
    {unplottedSeries.map(
      (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        series: any,
        index: number
      ) => (
        <Group
          gutters={GroupGutters.Small}
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}_${series.name}`}
          rowspace={GroupRowspace.XXSmall}
        >
          <Item>
            <Group gutters={GroupGutters.XSmall}>
              <Item valign={ItemValign.Middle} width={ItemWidth.Fit}>
                <SeriesIcon
                  benchmark={false}
                  color={String(series.color)}
                  seriesType={series.type}
                  // the trends chart doesn't have sentiment
                  wideIcon={!false}
                />
              </Item>
              <Item valign={ItemValign.Middle}>{series.name}</Item>
            </Group>
          </Item>
          <Item>
            <Group
              gutters={GroupGutters.XXSmall}
              justify={GroupJustify.FlexEnd}
            >
              <Item width={ItemWidth.Fit}>
                <CustomValueCell
                  formatter={() => `${"-"}`}
                  hasSentiment={false}
                  value={0}
                />
              </Item>
            </Group>
          </Item>
        </Group>
      )
    )}
  </>
);

const ExpandablePanel = ({
  isExpanded,
  title,
  toggleIsExpanded,
  children,
}: {
  children: JSX.Element | JSX.Element[];
  isExpanded: boolean;
  title: string;
  toggleIsExpanded: () => void;
}) => (
  <div>
    <button
      className={styles.expandButton}
      onClick={toggleIsExpanded}
      type="button"
    >
      <Text>{title}</Text>
      <InlineIcon
        className={styles.expandIcon}
        glyph={
          isExpanded
            ? InlineIconGlyph.ArrowsDropup
            : InlineIconGlyph.ArrowsDropdown
        }
        text={`${isExpanded ? "expand" : "collapse"} ${title}`}
      />
    </button>
    {isExpanded && children}
  </div>
);

const ClickableLink = ({
  text,
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  text: string;
}) => (
  <div>
    <Button
      className={styles.clickableLink}
      height={ButtonHeight.XSmall}
      onClick={onClick}
      text={text}
      type="button"
      variant={ButtonVariant.Link}
    />
  </div>
);

// Default implementation. Use this when in doubt.
// Use individual components (compound pattern) for further customisation.
export const HighchartsCustomTooltip = ({
  alwaysDisplaySign,
  ttData,
  yAxisTitle,
  pointFormatter,
  chartDataFormatter,
  percentFormatter,
  hasSentiment,
  benchmark,
  reverseSeries = false,
}: HighchartsCustomTooltipProps) => {
  const multipleSeries = ttData.points && ttData.points.length > 1;
  return (
    <Layout>
      <Title title={yAxisTitle} />
      {multipleSeries && <Subtitle subtitle={String(ttData.x)} />}
      <Series
        alwaysDisplaySign={alwaysDisplaySign}
        benchmark={benchmark}
        chartDataFormatter={chartDataFormatter}
        hasSentiment={hasSentiment}
        percentFormatter={percentFormatter}
        pointFormatter={pointFormatter}
        ttData={{
          ...ttData,
          points:
            ttData.points && reverseSeries
              ? [...ttData.points].reverse()
              : ttData.points,
        }}
        useHighchartsDefaultIcon={!multipleSeries}
        useSeriesNameForLabels={multipleSeries}
      />
    </Layout>
  );
};

export type HighchartsNpbTooltipProps = {
  benchmarkSeriesNames?: string[];
  chartDataFormatter?: FormatterFunction;
  hasSentiment?: boolean;
  isBenchmarkPanelExpanded: boolean;
  maxProductsShown?: number;
  percentFormatter?: FormatterFunction;
  // will be used over chartDataFormatter if provided
  // provides more flexibility to choose which formatter is used per point
  pointFormatter?: (pt: TooltipFormatterContextObject) => FormatterFunction;
  toggleIsBenchmarkPanelExpanded: () => void;
  ttData: TooltipFormatterContextObject;
};

export const HighchartsNpbTooltip = ({
  ttData,
  pointFormatter,
  chartDataFormatter,
  percentFormatter,
  hasSentiment,
  benchmarkSeriesNames,
  isBenchmarkPanelExpanded,
  maxProductsShown = 5,
  toggleIsBenchmarkPanelExpanded,
}: HighchartsNpbTooltipProps) => {
  const benchmarkPoints: TooltipFormatterContextObject[] = [];
  const productPoints: TooltipFormatterContextObject[] = [];
  let productData = null;
  let benchmarkData = null;
  let more = null;
  if (benchmarkSeriesNames && ttData.points) {
    for (const point of ttData.points) {
      if (benchmarkSeriesNames.includes(point.series.name)) {
        benchmarkPoints.push(point);
      } else {
        productPoints.push(point);
      }
    }

    more = productPoints.length - maxProductsShown;
    productData = { ...ttData, points: productPoints.slice(0, 5) };
    benchmarkData = { ...ttData, points: benchmarkPoints };
  }

  const multipleSeries = ttData.points && ttData.points.length > 1;
  return (
    <Layout>
      <Title title={"Week " + ttData.x} />
      <div className={styles.npbTitle}>Products</div>
      {productData && productData.points.length !== 0 ? (
        <>
          <Series
            chartDataFormatter={chartDataFormatter}
            hasSentiment={hasSentiment}
            percentFormatter={percentFormatter}
            pointFormatter={pointFormatter}
            ttData={productData}
            useHighchartsDefaultIcon={!multipleSeries}
            useSeriesNameForLabels={multipleSeries}
          />
          {more && more > 0 ? <div>+ {more} more</div> : ""}
        </>
      ) : (
        <div className={styles.selectFocalItemsPrompt}>Select focal items</div>
      )}

      {benchmarkData && (
        <ExpandablePanel
          isExpanded={isBenchmarkPanelExpanded}
          title="Benchmarks"
          toggleIsExpanded={toggleIsBenchmarkPanelExpanded}
        >
          <Series
            chartDataFormatter={chartDataFormatter}
            hasSentiment={hasSentiment}
            isBenchmarkSeries
            percentFormatter={percentFormatter}
            pointFormatter={pointFormatter}
            ttData={benchmarkData}
            useHighchartsDefaultIcon={!multipleSeries}
            useSeriesNameForLabels={multipleSeries}
          />
        </ExpandablePanel>
      )}
    </Layout>
  );
};

export type RepertoireChartCustomTooltipProps = {
  benchmark?: number;
  chartDataFormatter?: FormatterFunction;
  maxItemsShown?: number;
  otherSeriesPresent: boolean;
  pointFormatter?: (pt: TooltipFormatterContextObject) => FormatterFunction;
  ttData: TooltipFormatterContextObject;
  yAxisTitle: string;
};

export const RepertoireChartCustomTooltip = ({
  ttData,
  yAxisTitle,
  pointFormatter,
  chartDataFormatter,
  benchmark,
  otherSeriesPresent,
  maxItemsShown = 5,
}: RepertoireChartCustomTooltipProps) => {
  const multipleSeries = ttData.points && ttData.points.length > 1;

  let modifiedSeries: TooltipFormatterContextObject[] | undefined =
    ttData.points;
  let otherSeries: TooltipFormatterContextObject | undefined;
  let more = null;

  if (ttData.points) {
    const shallowCopySeries = [...ttData.points];

    if (otherSeriesPresent) {
      otherSeries = shallowCopySeries.pop();
    }

    more = shallowCopySeries.length - maxItemsShown;
    modifiedSeries = shallowCopySeries.slice(0, maxItemsShown).reverse();

    if (otherSeries) {
      modifiedSeries = [otherSeries, ...modifiedSeries];
    }
  }

  return (
    <Layout>
      <Title title={yAxisTitle} />
      {multipleSeries && <Subtitle subtitle={String(ttData.x)} />}
      <Series
        benchmark={benchmark}
        chartDataFormatter={chartDataFormatter}
        pointFormatter={pointFormatter}
        ttData={{
          ...ttData,
          points: modifiedSeries,
        }}
        useHighchartsDefaultIcon={!multipleSeries}
        useSeriesNameForLabels={multipleSeries}
      />
      {more && more > 0 ? <div>+ {more} more</div> : ""}
    </Layout>
  );
};

HighchartsCustomTooltip.Layout = Layout;
HighchartsCustomTooltip.Title = Title;
HighchartsCustomTooltip.Subtitle = Subtitle;
HighchartsCustomTooltip.Series = Series;
HighchartsCustomTooltip.UnPlottedSeries = UnPlottedSeries;
HighchartsCustomTooltip.ClickableLink = ClickableLink;

export default HighchartsCustomTooltip;
