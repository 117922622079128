import {
  type FormatOptions,
  type AttributeFilter,
  useSearchQuery,
  type HierarchyMetadataResponseDto,
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyType,
  formatNumberDate,
} from "@quantium-enterprise/common-ui";
import { HierarchyGroupRuleOperator } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
  Select,
  SelectOption,
  Tooltip,
  TooltipSpaceInside,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import {
  type DropdownOption,
  MultiselectDropdown,
} from "components-ui/src/multiselect-dropdown/MultiselectDropdown";
import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { NULL_SHORT_NAME } from "../constants";
import styles from "./DynamicGroupRule.module.css";

export type DynamicGroupRuleProps = {
  hierarchyType: HierarchyType;
  onRuleChange: (rule: HierarchyGroupRuleWithIdAndName) => void;
  onRuleRemoved: (ruleId: string) => void;
  previousGroupRules: HierarchyGroupRuleWithIdAndName[];
  readonly: boolean;
  rule: HierarchyGroupRuleWithIdAndName;
  ruleNameOptions: HierarchyMetadataResponseDto[];
};

const isDateTypeAttribute = (shortName: string) => {
  const dateTypeAttributeList = ["LW", "FLW"];

  return dateTypeAttributeList.includes(shortName);
};

export const DynamicGroupRule = ({
  hierarchyType,
  previousGroupRules,
  onRuleChange,
  onRuleRemoved,
  readonly = false,
  rule,
  ruleNameOptions,
}: DynamicGroupRuleProps) => {
  const { name: activeDivisionName, locale } = useDivision();
  const id = useId();
  // the options available from the dropdown
  const [ruleValueOptions, setRuleValueOptions] = useState<DropdownOption[]>(
    []
  );

  const [localRule, setLocalRule] = useState<HierarchyGroupRuleWithIdAndName>({
    ...rule,
  });

  // the current page number of the results
  const [page, setPage] = useState<number>(0);
  // whether to keep lazy loading comntent because there is more on the back end
  const [hasMoreResults, setHasMoreResults] = useState(false);
  // the search text in the values component
  const [searchText, setSearchText] = useState("");
  // a reference to the previous rule shortname
  const previousShortName = useRef<string>(rule.shortName);
  // the number of results to get at a time
  const DEFAULT_PAGE_SIZE = 500;

  // Paging not supported in search in MultiSelect component, set to big number (it won't ever be this big)
  const pageSize = useMemo(
    () => (searchText ? 30_000 : DEFAULT_PAGE_SIZE),
    [searchText]
  );

  // Search query to find the possible options when the user has chosen a shortName
  const { data: valuesResults, isFetching: isSearchFetching } = useSearchQuery(
    {
      division: activeDivisionName,
      hierarchyType,
      payload: {
        page,
        pageSize,
        focalAttributes: [localRule.shortName],
        includeCodes: true,
        query: searchText,
        filters: previousGroupRules
          .filter((gr) => gr.operator === HierarchyGroupRuleOperator.Is)
          .map(
            (gr) =>
              ({
                shortName: gr.shortName,
                codes: gr.values.map((value) => value.code),
              } as AttributeFilter)
          ),
        excludeFilters: previousGroupRules
          .filter((gr) => gr.operator === HierarchyGroupRuleOperator.IsNot)
          .map(
            (gr) =>
              ({
                shortName: gr.shortName,
                codes: gr.values.map((value) => value.code),
              } as AttributeFilter)
          ),
      },
    },
    {
      skip:
        !activeDivisionName ||
        localRule.shortName === NULL_SHORT_NAME ||
        readonly,
    }
  );

  // map back end search results to dropdown options used by the component
  const mappedValuesResults = useMemo(
    () =>
      isDateTypeAttribute(localRule.shortName)
        ? valuesResults?.results.map(
            (x) =>
              ({
                label: Number.isNaN(Number(x.name))
                  ? x.name
                  : formatNumberDate(Number(x.name), {
                      locale,
                      dropdown: true,
                    } as FormatOptions),
                value: x.code,
              } as DropdownOption)
          )
        : valuesResults?.results.map(
            (x) =>
              ({
                label: x.name,
                value: x.code,
              } as DropdownOption)
          ),
    [valuesResults, locale, localRule.shortName]
  );

  useEffect(() => {
    // if the rule shortname changes then reset the paging and the options while they are reloaded.
    setPage(0);
    setRuleValueOptions([]);

    // if the user changes the shortname, then the selected values need to be reset.
    if (localRule.shortName !== previousShortName.current) {
      setLocalRule((original) => ({
        ...original,
        values: [],
      }));
    }

    previousShortName.current = localRule.shortName;
  }, [localRule.shortName]);

  // if the last query has more results on the server, store it
  useEffect(() => {
    setHasMoreResults(valuesResults?.hasNextPage ?? false);
  }, [valuesResults?.hasNextPage]);

  // reset values if searching
  useEffect(() => {
    setRuleValueOptions([]);
    setPage(0);
  }, [searchText, previousGroupRules]);

  // append the results to the existing results, unless its fetching page 0 results
  useEffect(() => {
    if (page === 0) {
      setRuleValueOptions(mappedValuesResults ?? []);
    } else {
      setRuleValueOptions((original) => {
        const newOption = [...original];
        for (const item of mappedValuesResults ?? []) {
          newOption.push(item);
        }

        return newOption;
      });
    }
  }, [mappedValuesResults, page]);

  // update the parent when rule changes
  useEffect(() => {
    onRuleChange(localRule);
  }, [localRule, onRuleChange]);

  const onLazySearch = useCallback((search: string) => {
    setSearchText(search);
  }, []);

  const onLoadMore = useCallback(() => {
    setPage((oldValue) => oldValue + 1);
  }, []);

  const onRuleSelected = useCallback((selectedItems: DropdownOption[]) => {
    setLocalRule((original) => ({
      ...original,
      values: selectedItems.map((x) => ({
        name: x.label,
        code: x.value.toString(),
      })),
    }));
  }, []);

  const getOperatorText = (operator: HierarchyGroupRuleOperator): string => {
    switch (operator) {
      case HierarchyGroupRuleOperator.Is:
        return "is";
      case HierarchyGroupRuleOperator.IsNot:
        return "is not";
      default:
        return "";
    }
  };

  const textboxValue = useMemo(() => {
    if (rule.values.length === 1) {
      return rule.values[0].name;
    }

    if (rule.values.length > 1) {
      return `${rule.values.length} items selected`;
    }

    return "";
  }, [rule.values]);

  return (
    <div className={styles.groupEditorRuleContainer} role="row">
      {readonly && (
        <div className={styles.readonlyRules}>
          <div>{rule.fullShortName}</div>
          <div>{getOperatorText(rule.operator)}</div>
          <Tooltip
            spaceInside={TooltipSpaceInside.Medium}
            trigger={<div>{textboxValue}</div>}
            variant={TooltipVariant.ArrowDark}
          >
            {rule.values.map((value) => (
              <div key={`item-${value.code}`}>{value.name}</div>
            ))}
          </Tooltip>
          <Button
            onClick={() => onRuleRemoved(rule.id)}
            variant={ButtonVariant.Link}
          >
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Remove rule" />
          </Button>
        </div>
      )}
      {!readonly && (
        <>
          <div className={styles.shortname}>
            <Select
              id={`rule-shortName-${id}`}
              onChange={(event) => {
                setLocalRule({
                  ...localRule,
                  shortName: event.target.value,
                  fullShortName: ruleNameOptions.find(
                    (option) => option.shortName === event.target.value
                  )?.name,
                });
              }}
              value={rule.shortName}
            >
              {ruleNameOptions.map((a) => (
                <SelectOption
                  key={a.shortName}
                  text={a.name}
                  value={a.shortName}
                />
              ))}
            </Select>
          </div>
          <div className={styles.operator}>
            <Select
              id={`rule-operator-${id}`}
              onChange={(event) =>
                setLocalRule({
                  ...localRule,
                  operator: event.target.value as HierarchyGroupRuleOperator,
                })
              }
              value={rule.operator}
            >
              <SelectOption
                key={HierarchyGroupRuleOperator.Is}
                text={getOperatorText(HierarchyGroupRuleOperator.Is)}
                value={HierarchyGroupRuleOperator.Is}
              />
              <SelectOption
                key={HierarchyGroupRuleOperator.IsNot}
                text={getOperatorText(HierarchyGroupRuleOperator.IsNot)}
                value={HierarchyGroupRuleOperator.IsNot}
              />
            </Select>
          </div>
          <div className={styles.values}>
            <MultiselectDropdown
              hasMoreResults={hasMoreResults}
              id={`rule-values-${id}`}
              isLoading={isSearchFetching}
              items={ruleValueOptions}
              key={`rule-values-${id}`}
              onLazySearch={onLazySearch}
              onLoadMore={onLoadMore}
              onSelected={onRuleSelected}
              values={rule.values.map(
                (value) =>
                  ({
                    label: value.name ?? `Not available - ${value.code}`,
                    value: value.code,
                  } as DropdownOption)
              )}
            />
          </div>
          <Button
            onClick={() => onRuleRemoved(rule.id)}
            variant={ButtonVariant.Link}
          >
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Remove rule" />
          </Button>
        </>
      )}
    </div>
  );
};
