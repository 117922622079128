import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { type ProblemDetails } from "../models/problem-details";

export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError =>
  typeof error === "object" &&
  Boolean(error) &&
  Object.prototype.hasOwnProperty.call(error, "data");

export const isProblemDetail = (
  problemDetails?: unknown
): problemDetails is ProblemDetails =>
  typeof problemDetails === "object" &&
  Boolean(problemDetails) &&
  Object.prototype.hasOwnProperty.call(problemDetails, "errors") &&
  Object.prototype.hasOwnProperty.call(problemDetails, "status") &&
  Object.prototype.hasOwnProperty.call(problemDetails, "title") &&
  Object.prototype.hasOwnProperty.call(problemDetails, "type");

export const clearErrorForFields = (
  errors: Record<string, string[]>,
  clearErrorFieldNames: string[]
) => {
  const newErrors = { ...errors };
  for (const fieldName of clearErrorFieldNames) {
    newErrors[fieldName] = [];
  }

  return newErrors;
};

export const hasErrorsForField = (
  errors: Record<string, string[] | undefined>,
  fieldName: string
) => (errors[fieldName]?.length ?? 0) !== 0;

export const addErrorForField = (
  errors: Record<string, string[]>,
  fieldName: string,
  errorMessage: string
) => {
  const newErrors = { ...errors };
  const keys = Object.keys(errors);
  if (!keys.includes(fieldName)) {
    newErrors[fieldName] = [];
  }

  if (!newErrors[fieldName].includes(errorMessage)) {
    newErrors[fieldName].push(errorMessage);
  }

  return newErrors;
};

export const setErrorsForField = (
  errors: Record<string, string[]>,
  fieldName: string,
  fieldErrors: string[]
): Record<string, string[]> => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const oldFieldErrors = errors[fieldName] ?? [];
  const sameErrors =
    fieldErrors.length === oldFieldErrors.length &&
    fieldErrors.every((error, index) => error === oldFieldErrors[index]);
  if (sameErrors) {
    return errors;
  }

  return { ...errors, [fieldName]: fieldErrors };
};

export const getErrorsForField = (
  errors: Record<string, string[] | undefined>,
  fieldName: string
): string[] => errors[fieldName] ?? [];

export const getErrorsForFields = (
  errors: Record<string, string[]>,
  fieldNames: string[]
): string[] => {
  let result: string[] = [];
  const keys = Object.keys(errors);
  for (const fieldName of fieldNames) {
    if (keys.includes(fieldName)) {
      result = result.concat(errors[fieldName]);
    }
  }

  return result;
};
