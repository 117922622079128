import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { current } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type DashboardDto } from "./dtos/DashboardDto";
import { type WatchlistDto } from "./dtos/WatchlistDto";
import { type AddWatchlistItemDto } from "./dtos/WatchlistItemDto";
import { type WatchlistMetricsResultDto } from "./dtos/WatchlistMetricsResultDto";

export const dashboardServiceApi = createApi({
  baseQuery: getBaseQuery(`/api/dashboard-service`),
  endpoints: (builder) => ({
    addWatchlistItems: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        watchlistId: string;
        watchlistItems: AddWatchlistItemDto[];
      }
    >({
      invalidatesTags: ["GetDashboard", "GetWatchlist", "GetWatchlistMetrics"],
      query: ({ dashboardId, division, watchlistId, watchlistItems }) => ({
        body: watchlistItems,
        method: "PATCH",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/items`,
      }),
    }),
    createDashboard: builder.mutation<DashboardDto, { division: string }>({
      invalidatesTags: ["GetDashboard"],
      query: ({ division }) => ({
        method: "POST",
        url: `/${division}/dashboard`,
      }),
    }),
    createWatchlist: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        watchlistItems: AddWatchlistItemDto[];
      }
    >({
      invalidatesTags: ["GetDashboard"],
      query: ({ dashboardId, division, watchlistItems }) => ({
        body: watchlistItems,
        method: "POST",
        url: `/${division}/dashboard/${dashboardId}/watchlist`,
      }),
    }),
    deleteWatchlist: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        watchlistId: string;
      }
    >({
      invalidatesTags: ["GetDashboard"],
      query: ({ dashboardId, division, watchlistId }) => ({
        method: "DELETE",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}`,
      }),
    }),
    deleteWatchlistItems: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        watchlistId: string;
        watchlistItemIds: string[];
      }
    >({
      invalidatesTags: ["GetDashboard", "GetWatchlist"],
      query: ({ dashboardId, division, watchlistId, watchlistItemIds }) => ({
        method: "DELETE",
        params: watchlistItemIds.map((watchlistItemId) => [
          "id",
          watchlistItemId,
        ]),
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/items`,
      }),
    }),
    getDashboard: builder.query<DashboardDto, { division: string }>({
      providesTags: ["GetDashboard"],
      query: ({ division }) => ({
        method: "GET",
        url: `/${division}/dashboard`,
      }),
    }),
    getWatchlist: builder.query<
      WatchlistDto,
      { dashboardId: string; division: string; watchlistId: string }
    >({
      providesTags: ["GetWatchlist"],
      query: ({ division, dashboardId, watchlistId }) => ({
        method: "GET",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}`,
      }),
    }),
    getWatchlistMetrics: builder.query<
      WatchlistMetricsResultDto,
      {
        comparisonPeriod: string;
        dashboardId: string;
        division: string;
        focusPeriod: number;
        transactionSource?: string;
        watchlistId: string;
      }
    >({
      providesTags: ["GetWatchlistMetrics"],
      query: ({
        division,
        dashboardId,
        watchlistId,
        focusPeriod,
        comparisonPeriod,
        transactionSource,
      }) => ({
        method: "POST",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/metrics`,
        body: {
          focusPeriod,
          comparisonPeriod,
          transactionSource,
        },
      }),
    }),
    reorderWatchlistItems: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        newPosition: number;
        watchlistId: string;
        watchlistItemId: string;
      }
    >({
      async onQueryStarted(argument, endpoint) {
        endpoint.dispatch(
          dashboardServiceApi.util.updateQueryData(
            "getWatchlist",
            {
              dashboardId: argument.dashboardId,
              division: argument.division,
              watchlistId: argument.watchlistId,
            },
            (draft) => {
              const watchlistItems = [...current(draft).items];
              const newPosition = argument.newPosition;
              const itemToBeMoved = watchlistItems.find(
                (items) => items.id === argument.watchlistItemId
              );

              if (itemToBeMoved) {
                watchlistItems.splice(watchlistItems.indexOf(itemToBeMoved), 1);
                watchlistItems.splice(newPosition, 0, itemToBeMoved);
                const patch = { items: watchlistItems };
                Object.assign(draft, patch);
              }
            }
          )
        );
        try {
          await endpoint.queryFulfilled;
        } catch {
          endpoint.dispatch(
            dashboardServiceApi.util.invalidateTags(["GetWatchlist"])
          );
        }
      },
      query: ({
        dashboardId,
        division,
        newPosition,
        watchlistId,
        watchlistItemId,
      }) => ({
        method: "PATCH",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/items/${watchlistItemId}/reorder/${newPosition}`,
      }),
    }),
    updateTransactionSource: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        transactionSource: string;
        watchlistId: string;
      }
    >({
      async onQueryStarted(argument, endpoint) {
        endpoint.dispatch(
          dashboardServiceApi.util.updateQueryData(
            "getWatchlist",
            {
              dashboardId: argument.dashboardId,
              division: argument.division,
              watchlistId: argument.watchlistId,
            },
            (draft) => {
              const patch = {
                transactionSource: argument.transactionSource,
              };
              Object.assign(draft, patch);
            }
          )
        );
        try {
          await endpoint.queryFulfilled;
        } catch {
          endpoint.dispatch(
            dashboardServiceApi.util.invalidateTags(["GetWatchlist"])
          );
        }
      },
      query: ({ dashboardId, division, watchlistId, transactionSource }) => ({
        body: { transactionSource },
        method: "PATCH",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/transaction-source`,
      }),
    }),
    updateComparisonPeriod: builder.mutation<
      unknown,
      {
        comparisonPeriod: string;
        dashboardId: string;
        division: string;
        watchlistId: string;
      }
    >({
      async onQueryStarted(argument, endpoint) {
        endpoint.dispatch(
          dashboardServiceApi.util.updateQueryData(
            "getWatchlist",
            {
              dashboardId: argument.dashboardId,
              division: argument.division,
              watchlistId: argument.watchlistId,
            },
            (draft) => {
              const patch = {
                comparisonPeriod: argument.comparisonPeriod,
              };
              Object.assign(draft, patch);
            }
          )
        );
        try {
          await endpoint.queryFulfilled;
        } catch {
          endpoint.dispatch(
            dashboardServiceApi.util.invalidateTags(["GetWatchlist"])
          );
        }
      },
      query: ({ dashboardId, division, watchlistId, comparisonPeriod }) => ({
        body: { comparisonPeriod },
        method: "PATCH",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/comparison-period`,
      }),
    }),
    updateFocusPeriod: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        focusPeriod: number;
        watchlistId: string;
      }
    >({
      async onQueryStarted(argument, endpoint) {
        endpoint.dispatch(
          dashboardServiceApi.util.updateQueryData(
            "getWatchlist",
            {
              dashboardId: argument.dashboardId,
              division: argument.division,
              watchlistId: argument.watchlistId,
            },
            (draft) => {
              const patch = {
                focusPeriod: argument.focusPeriod,
              };
              Object.assign(draft, patch);
            }
          )
        );
        try {
          await endpoint.queryFulfilled;
        } catch {
          endpoint.dispatch(
            dashboardServiceApi.util.invalidateTags(["GetWatchlist"])
          );
        }
      },
      query: ({ dashboardId, division, watchlistId, focusPeriod }) => ({
        body: { focusPeriod },
        method: "PATCH",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/focus-period`,
      }),
    }),
    updateMetrics: builder.mutation<
      unknown,
      {
        dashboardId: string;
        division: string;
        metrics: string[];
        watchlistId: string;
      }
    >({
      // Optimistically sets the cache for the watchlist response to have the new selected metrics.
      // This means that for most cases a refetch is not needed, only when the server does not respond with a 2XX
      async onQueryStarted(argument, endpoint) {
        endpoint.dispatch(
          dashboardServiceApi.util.updateQueryData(
            "getWatchlist",
            {
              dashboardId: argument.dashboardId,
              division: argument.division,
              watchlistId: argument.watchlistId,
            },
            (draft) => {
              const patch = {
                metrics: argument.metrics,
              };
              Object.assign(draft, patch);
            }
          )
        );
        try {
          await endpoint.queryFulfilled;
        } catch {
          endpoint.dispatch(
            dashboardServiceApi.util.invalidateTags(["GetWatchlist"])
          );
        }
      },
      query: ({ dashboardId, division, watchlistId, metrics }) => ({
        body: { metrics },
        method: "PATCH",
        url: `/${division}/dashboard/${dashboardId}/watchlist/${watchlistId}/metrics`,
      }),
    }),
  }),
  reducerPath: "dashboardServiceApi",
  tagTypes: [
    "GetDashboard",
    "GetWatchlist",
    "GetWatchlistMetrics",
    "CreateDashboard",
    "CreateWatchlist",
  ],
});

export const {
  useGetDashboardQuery,
  useGetWatchlistQuery,
  useDeleteWatchlistMutation,
  useGetWatchlistMetricsQuery,
  useCreateDashboardMutation,
  useCreateWatchlistMutation,
  useDeleteWatchlistItemsMutation,
  useReorderWatchlistItemsMutation,
  useAddWatchlistItemsMutation,
  useUpdateFocusPeriodMutation,
  useUpdateComparisonPeriodMutation,
  useUpdateTransactionSourceMutation,
  useUpdateMetricsMutation,
} = dashboardServiceApi;
