import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { ENVIRONMENT } from "../config/environment-config";

export const normaliseBaseUrl = (url: string): string => {
  // If the URL is empty or just a slash, return an empty string
  if (url === "" || url === "/" || url === "./") {
    return "";
  }

  const normalisedUrl = url.replace(/\/$/iu, "");

  const hasProtocol = /^https?:\/\//iu.test(normalisedUrl);

  if (hasProtocol) {
    return normalisedUrl;
  }

  return normalisedUrl.startsWith("/") ? normalisedUrl : `/${normalisedUrl}`;
};

export const getBaseQuery = (baseUrl: string) => {
  if (!baseUrl.startsWith("/")) {
    throw new Error("RTK query api baseUrl must start with a forward slash");
  }

  return fetchBaseQuery({
    baseUrl: normaliseBaseUrl(String(ENVIRONMENT.BASE_URL)) + baseUrl,
  });
};
