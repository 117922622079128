import { ReportletDropdownValues } from "./reportlet-utils";

const ExtendedReportletDropdownValues = {
  LocationHierarchy: "LocationHierarchy",
};

export type CompareMetricsTableData = {
  format: string;
  header: string;
  type: string;
  values: number[];
};

export const getChartHeader = (xAxisSelection: string): string => {
  switch (xAxisSelection) {
    case ReportletDropdownValues.FocalItems:
      return "Item description";
    case ExtendedReportletDropdownValues.LocationHierarchy:
      return "Location";
    default:
      return xAxisSelection;
  }
};
