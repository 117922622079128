import { type FolderOrReportDto } from "@quantium-enterprise/common-ui";
import {
  onRootFolderReceived,
  setFolderExpandedState,
  setIsPollingEnabled,
  setShowDeleteItemModal,
  setShowMoveItemModal,
  setShowShareModal,
  TrackingComponent,
  useDeleteFoldersMutation,
  useDeleteReportsMutation,
  useGetOrganizationUsersQuery,
  useLazyGetGeneratedReportsQuery,
  useMoveFolderMutation,
  useMoveReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { type DeleteItems } from "components-ui/src/delete-dialog/DeleteDialog";
import { DeleteDialog } from "components-ui/src/delete-dialog/DeleteDialog";
import { MoveItemModal, ShareModal } from "components-ui/src/modals";
import {
  type Folder,
  type MoveItemModalSubmitProps,
} from "components-ui/src/modals/move-item-modal/MoveItemModal";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReportType } from "./generated-reports/GeneratedReports";
import { isFolderRow } from "./my-reports-grid/MyReportsGridWithFolders";
import { type RootState } from "./store";

export const GeneratedReportsModals = ({
  getDefaultMyReportsPath,
  updateReportSharingActivities,
}: {
  getDefaultMyReportsPath: () => string;
  updateReportSharingActivities: (
    divisionName: string,
    reportId: string
  ) => void;
}) => {
  const [moveFolder] = useMoveFolderMutation();
  const [moveReport] = useMoveReportMutation();
  const [deleteReport] = useDeleteReportsMutation();
  const [deleteFolder] = useDeleteFoldersMutation();
  const [triggerGetGeneratedReports] = useLazyGetGeneratedReportsQuery();
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteButtonRef = useRef(null);
  const { id: focalRowId } = useParams();

  const {
    rowData,
    quickActionRowId,
    showMoveItemModal,
    showDeleteItemModal,
    showShareModal,
  } = useSelector((state: RootState) => ({
    rowData: state.generatedReports.rowData,
    quickActionRowId: state.generatedReports.quickActionRowId,
    showMoveItemModal: state.generatedReports.showMoveItemModal,
    showDeleteItemModal: state.generatedReports.showDeleteItemModal,
    showShareModal: state.generatedReports.showShareModal,
  }));

  const quickActionOrFocalRowId = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => quickActionRowId ?? focalRowId ?? "",
    [quickActionRowId, focalRowId]
  );

  const selectedRowData = useMemo(
    () => rowData[quickActionOrFocalRowId] as FolderOrReportDto | undefined,
    [rowData, quickActionOrFocalRowId]
  );

  useEffect(() => {
    dispatch(
      setIsPollingEnabled(
        !(showMoveItemModal || showDeleteItemModal || showShareModal)
      )
    );
  }, [showMoveItemModal, showDeleteItemModal, showShareModal, dispatch]);

  const triggerLazyRowDataReload: () => Promise<void> =
    useCallback(async () => {
      const response = await triggerGetGeneratedReports({
        divisionName: activeDivisionName,
      }).unwrap();
      dispatch(onRootFolderReceived(response));
    }, [activeDivisionName, triggerGetGeneratedReports, dispatch]);

  const { data: organisationUsers } = useGetOrganizationUsersQuery(
    { divisionName: activeDivisionName },
    { skip: !activeDivisionName }
  );

  const handleOnMoveSubmit = useCallback(
    async ({ newParentFolderId }: MoveItemModalSubmitProps) => {
      const response = isFolderRow(selectedRowData)
        ? await moveFolder({
            divisionName: activeDivisionName,
            payload: {
              reportType: ReportType.ReportParameters,
              folderId: quickActionOrFocalRowId,
              newParentFolderId,
            },
          }).unwrap()
        : await moveReport({
            divisionName: activeDivisionName,
            payload: {
              newFolderId: newParentFolderId,
              reportId: quickActionOrFocalRowId,
            },
          }).unwrap();

      if (newParentFolderId) {
        dispatch(
          setFolderExpandedState({ id: newParentFolderId, isExpanded: true })
        );
      }

      await triggerLazyRowDataReload();
      return response;
    },
    [
      quickActionOrFocalRowId,
      triggerLazyRowDataReload,
      selectedRowData,
      activeDivisionName,
      dispatch,
      moveFolder,
      moveReport,
    ]
  );

  const handleOnDelete = useCallback(
    async (itemIds: DeleteItems) => {
      const response = isFolderRow(selectedRowData)
        ? await deleteFolder({
            divisionName: activeDivisionName,
            payload: { FolderIds: itemIds.folderIds ?? [] },
          }).unwrap()
        : await deleteReport({
            divisionName: activeDivisionName,
            itemIds: itemIds.reportIds ?? [],
          }).unwrap();
      await triggerLazyRowDataReload();
      navigate(getDefaultMyReportsPath());
      return response;
    },
    [
      deleteFolder,
      deleteReport,
      selectedRowData,
      activeDivisionName,
      getDefaultMyReportsPath,
      triggerLazyRowDataReload,
      navigate,
    ]
  );

  return (
    <>
      <MoveItemModal
        folders={
          Object.values(rowData).filter(
            (row) => row.isFolder
          ) as unknown as Folder[]
        }
        onClose={() => {
          dispatch(setShowMoveItemModal(false));
        }}
        onSubmit={handleOnMoveSubmit}
        selectedItemFolderId={selectedRowData?.parentId}
        selectedItemId={quickActionOrFocalRowId}
        show={showMoveItemModal}
      />

      <DeleteDialog
        deleteItem={handleOnDelete}
        itemIds={
          isFolderRow(selectedRowData)
            ? ({ folderIds: [quickActionOrFocalRowId] } as DeleteItems)
            : ({ reportIds: [quickActionOrFocalRowId] } as DeleteItems)
        }
        onClose={() => dispatch(setShowDeleteItemModal(false))}
        ref={deleteButtonRef}
        show={showDeleteItemModal}
        trackingComponent={TrackingComponent.Report}
      />

      <ShareModal.Reports
        onClose={() => {
          dispatch(setShowShareModal(false));
        }}
        organisationUsers={organisationUsers}
        reportId={quickActionOrFocalRowId}
        reportName={selectedRowData?.name}
        showModal={showShareModal}
        triggerLazyReload={async () => {
          await triggerLazyRowDataReload();
          updateReportSharingActivities(
            activeDivisionName,
            quickActionOrFocalRowId
          );
        }}
      />
    </>
  );
};
