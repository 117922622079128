import {
  HierarchyShortName,
  HierarchyType,
  groupsApi,
} from "@quantium-enterprise/common-ui";
import { store } from "../../store";
import {
  type SelectableItem,
  type SelectableProductGroupItem,
} from "../SelectableItem";
import { SearchStrategyStatus } from "./SearchStrategy";
import { SearchStrategyBase } from "./SearchStrategyBase";

export class ProductGroupStrategy extends SearchStrategyBase {
  private readonly division: string;

  private searchQuery: string = "";

  public constructor(division: string) {
    super();
    this.division = division;
  }

  public initialize(): void {
    if (this.getStatus() === SearchStrategyStatus.Uninitialized) {
      this.search();
    }
  }

  public setSearchQuery(query: string): void {
    if (query !== this.searchQuery) {
      if (this.getStatus() !== SearchStrategyStatus.Uninitialized) {
        this.setStatus(SearchStrategyStatus.Loading);
      }

      this.searchQuery = query;
      this.search();
    }
  }

  public loadMore(): void {
    this.search();
  }

  private search(forceRefetch: boolean = false) {
    const requestSearchQuery = this.searchQuery;
    (async () => {
      const response = await store.dispatch(
        groupsApi.endpoints.getGroups.initiate(
          {
            divisionName: this.division,
            hierarchyType: HierarchyType.Product,
            includeRules: false,
            searchTerm: requestSearchQuery,
          },
          { forceRefetch }
        )
      );

      if (requestSearchQuery !== this.searchQuery) {
        // Search query doesn't match what's in the state, ignore as it must be an old request.
        return;
      }

      if (response.data) {
        const mappedResults = response.data
          .map(
            (result) =>
              ({
                name: result.name,
                productGroupId: result.id,
                evaluationType: result.evaluationType,
                type: HierarchyShortName.ProductGroup,
              } as SelectableProductGroupItem)
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        this.setItems(mappedResults);
        this.setStatus(SearchStrategyStatus.Success);
      } else {
        this.setStatus(SearchStrategyStatus.Error);
      }
    })();
  }

  public expandItem(): void {
    // Product groups cannot be expanded
    throw new Error("Method not implemented.");
  }

  public unexpandItem(): void {
    // Product groups cannot be expanded
    throw new Error("Method not implemented.");
  }

  public hierarchyLevelSelected(): void {
    // N/A to product groups
    throw new Error("Method not implemented.");
  }

  // This is a bit of a hack, where we force a refetch when the section is
  // loaded again. This is so that newly created product groups will show up.
  // Can't rely on cache invalidation, as that happens in a different redux store.
  public override getItems(): SelectableItem[] {
    this.search(true);
    return super.getItems();
  }
}
