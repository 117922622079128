export const Rules = ({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6615 5.52714H8.66146V6.86047H14.6615V5.52714ZM14.6615 10.8605H8.66146V12.1938H14.6615V10.8605ZM3.68812 8.1938L1.32812 5.8338L2.26812 4.8938L3.68146 6.30713L6.50812 3.48047L7.44812 4.42047L3.68812 8.1938ZM3.68812 13.5271L1.32812 11.1671L2.26812 10.2271L3.68146 11.6405L6.50812 8.8138L7.44812 9.7538L3.68812 13.5271Z"
      fill="#333333"
    />
  </svg>
);
