import { useSortable } from "@dnd-kit/sortable";
import classnames from "classnames";
import { TestId } from "../../drag-and-drop/constants";
import { type SimpleItem } from "../models/Item";
import { type SimpleZone } from "../models/Zone";
import styles from "./Item.module.css";
import { Item } from "./SimplifiedItem";

export type SimpleDroppableZoneProps = {
  onRemove: (item: SimpleItem) => void;
  zone: SimpleZone;
};

export const SimpleDroppableZone = ({
  onRemove,
  zone,
}: SimpleDroppableZoneProps) => {
  const { isOver, setNodeRef } = useSortable({
    data: {
      ...zone,
    },
    id: zone.id,
  });

  return (
    <div
      className={classnames(
        styles.droppableZone,
        isOver && styles.isOver,
        !zone.hasItem && styles.noItem,
        zone.isPlaceholder && styles.isPlaceholder,
        {
          [styles.hasItem]: zone.item,
        }
      )}
      data-testid={TestId.DroppableZone}
      ref={setNodeRef}
    >
      {zone.item && <Item item={zone.item} onRemove={onRemove} />}
    </div>
  );
};
