export const ShareHorizontalMenuIcon = () => (
  <svg
    fill="none"
    height="2"
    viewBox="0 0 14 2"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1" cy="1" fill="var(--qbit-colour-shade-16)" r="1" />
    <circle cx="7" cy="1" fill="var(--qbit-colour-shade-16)" r="1" />
    <circle cx="13" cy="1" fill="var(--qbit-colour-shade-16)" r="1" />
  </svg>
);
