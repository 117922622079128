import classNames from "classnames";

export const RenameIcon = () => {
  const classes = classNames("q-icon", "q-icon-medium");
  return (
    <span className={classes}>
      <svg
        fill="none"
        viewBox="-10 -10 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2319 2.18334L14.0556 1.04861C13.2972 0.31713 12.0625 0.31713 11.3042 1.04861L0.25 11.7113V15.5H4.17778L15.2319 4.83729C16 4.10581 16 2.91482 15.2319 2.18334ZM3.37083 13.6244H2.19444V12.4897L10.6139 4.3684L11.7903 5.50313L3.37083 13.6244ZM8.02778 15.5L11.9167 11.7488H17.75V15.5H8.02778Z"
          fill="#333333"
        />
      </svg>
    </span>
  );
};
