import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type PricingLaddersLocalSelections } from "../services/pricing-ladders-slice";

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): PricingLaddersLocalSelections => {
  const selections: PricingLaddersLocalSelections = {
    ViewBy: [{ label: "", value: "" }],
    Channel: { label: "", value: "" },
    Segmentation: [{ label: "", value: "" }],
    LocationHierarchy: {
      code: "",
      depth: -1,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      nodeNumber: -1,
      shortName: "",
      isDefault: false,
    },
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.ViewBy:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          selections.ViewBy = [
            {
              label: selection?.label ?? "",
              value: selection?.value ?? "",
            },
            {
              label: selection?.segmentLabel ?? "",
              value: selection?.segmentValue ?? "",
            },
          ];
        }

        break;

      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.Channel = {
              label: selection.label,
              value: selection.value,
            };
          }
        }

        break;

      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          selections.Segmentation = [
            {
              label: selection?.label ?? "",
              value: selection?.value ?? "",
            },
            {
              label: selection?.segmentLabel ?? "",
              value: selection?.segmentValue ?? "",
            },
          ];
        }

        break;

      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter))
          selections.LocationHierarchy = getLocationSelectDefault(parameter);
        break;

      default:
        break;
    }
  }

  return selections;
};
