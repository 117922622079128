import { ddLog } from "@quantium-enterprise/common-ui";
import { type BasketQuantitiesLocalParametersResponseDto } from "../models/BasketQuantitiesLocalParametersResponseDto";
import { basketQuantitiesApiSlice } from "./basket-quantities-api-slice";
import { onLocalParametersReceived } from "./basket-quantities-slice";

export const basketQuantitiesLocalParametersApiSlice =
  basketQuantitiesApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        BasketQuantitiesLocalParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        async onQueryStarted(argument, { dispatch, queryFulfilled }) {
          // `onStart` side-effect
          try {
            const { data } = await queryFulfilled;
            dispatch(onLocalParametersReceived(data));
          } catch {
            ddLog("Error retrieving local parameters");
          }
        },
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetLocalParametersQuery } =
  basketQuantitiesLocalParametersApiSlice;
