import {
  type NpbReportletDataRequestDto,
  type NpbReportletDataResponseDto,
} from "../models/new-product-benchmarking-reportlet-dto";
import { newProductBenchmarkingApiSlice } from "./new-product-benchmarking-api-slice";

export const newProductBenchmarkingReportletDataSlice =
  newProductBenchmarkingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getNewProductBenchmarkingReportletData: builder.query<
        NpbReportletDataResponseDto,
        { divisionName: string; requestPayload: NpbReportletDataRequestDto }
      >({
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportletData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetNewProductBenchmarkingReportletDataQuery } =
  newProductBenchmarkingReportletDataSlice;
