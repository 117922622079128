import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { type CellContext, type ColumnDef } from "@tanstack/react-table";
import {
  GraphicsCell,
  GraphicsCellMetricType,
} from "components-ui/src/tables/common/table-cell/GraphicsCell";
import { StructureNameCell } from "components-ui/src/tables/common/table-cell/StructureNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { type RootState } from "../../../store";
import { type SubstitutabilityTableRow } from "../../models/SubstitutabilityTableData";
import { calculatePercentile } from "../../utils/calculatePercentile";
import { ScaleLegendColorsReversedForSubs } from "./ProductSubstitutabilityReportlet";
import { SubstitutabilityStructureHeader } from "./SubstitutabilityStructureHeader";
import styles from "./SubstitutabilityTable.module.css";

const GRAPHIC_CELL_FORMATTER_COLUMNS = ["Substitute value"];

export type SubstitutabilityTableProps = {
  isQuerySuccess: boolean;
};

export const SubstitutabilityTable = ({
  isQuerySuccess,
}: SubstitutabilityTableProps) => {
  const formatter = useFormatter();

  const { attributes, columnFilters, columnVisibility, data, metrics } =
    useSelector((state: RootState) => ({
      attributes: state.productSubstitutability.attributes,
      columnFilters: state.productSubstitutability.columnFilters,
      columnVisibility: state.productSubstitutability.columnVisibility,
      data: state.productSubstitutability.substitutabilityTableData,
      metrics: state.productSubstitutability.substitutabilityTableMetrics,
    }));

  const createNameCell = useCallback(
    ({ row }: CellContext<SubstitutabilityTableRow, unknown>) => (
      <StructureNameCell
        isSubRow={row.original.parentItem !== undefined}
        name={row.original.item.name}
      />
    ),
    []
  );

  const memoisedStructureHeader = useMemo(
    () => <SubstitutabilityStructureHeader />,
    []
  );

  const cellFormatterAttribute = useCallback(
    (
      { row }: CellContext<SubstitutabilityTableRow, unknown>,
      index: number
    ): React.ReactNode => (
      <ValueCell
        formatter={formatter(MetricTypes.String)}
        value={row.original.attributeValues[index]}
      />
    ),
    [formatter]
  );

  const cellFormatterMetric = useCallback(
    (
      { row }: CellContext<SubstitutabilityTableRow, unknown>,
      index: number,
      format: string
    ): React.ReactNode => (
      <ValueCell
        formatter={formatter(format)}
        value={row.original.metricValues[index]}
      />
    ),
    [formatter]
  );

  const cellFormatterMetricWithGraphic = useCallback(
    (
      { row }: CellContext<SubstitutabilityTableRow, unknown>,
      index: number,
      format: string
    ): React.ReactNode => {
      const value = row.original.metricValues[index];
      return value ? (
        <GraphicsCell
          color={ScaleLegendColorsReversedForSubs[calculatePercentile(value)]}
          formatter={formatter(format)}
          type={GraphicsCellMetricType.Percentile}
          value={row.original.metricValues[index]}
        />
      ) : null;
    },
    [formatter]
  );

  const columnDefs: Array<ColumnDef<SubstitutabilityTableRow>> = useMemo(() => {
    // Attributes
    const attributesColumns: Array<ColumnDef<SubstitutabilityTableRow>> =
      attributes.map((attribute, index) => ({
        accessorKey: attribute.shortName,
        cell: (cellInfo: CellContext<SubstitutabilityTableRow, unknown>) =>
          cellFormatterAttribute(cellInfo, index),
        enableHiding: false,
        header: attribute.displayName,
        id: attribute.shortName,
      }));

    // Metrics
    const metricsColumns: Array<ColumnDef<SubstitutabilityTableRow>> =
      metrics.map((metric, index) => ({
        accessorKey: metric.displayName,
        cell: (cellInfo: CellContext<SubstitutabilityTableRow, unknown>) =>
          GRAPHIC_CELL_FORMATTER_COLUMNS.includes(metric.displayName)
            ? cellFormatterMetricWithGraphic(cellInfo, index, metric.format)
            : cellFormatterMetric(cellInfo, index, metric.format),
        enableHiding: false,
        header: metric.displayName,
        id: index + metric.displayName,
      }));

    return [
      {
        accessorKey: "name",
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        header: () => memoisedStructureHeader,
      },
      ...attributesColumns,
      ...metricsColumns,
    ];
  }, [
    attributes,
    metrics,
    createNameCell,
    cellFormatterAttribute,
    cellFormatterMetricWithGraphic,
    cellFormatterMetric,
    memoisedStructureHeader,
  ]);

  return (
    <ReportHierarchyTableWrapper
      className={styles.tableContainer}
      isSuccess={isQuerySuccess}
    >
      <VirtuosoTableComponent
        columnFilters={columnFilters}
        columnVisibility={columnVisibility}
        columns={columnDefs}
        data={data}
        getRowId={(row: SubstitutabilityTableRow) => row.item.itemCode}
        getSubRows={(row) => row.subRows}
        pinFirstColumn
        rowExpandedState
      />
    </ReportHierarchyTableWrapper>
  );
};
