import { getTextColor } from "@quantium-enterprise/common-ui";
import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";
import classNames from "classnames";
import { type ReactNode } from "react";
import styles from "./CircleIcon.module.css";

export enum CircleIconSize {
  Medium = "medium",
  Small = "small",
  XSmall = "xsmall",
}

const CircleTextSize = {
  medium: "1rem",
  small: "0.875rem",
  xsmall: "0.75rem",
};

export type CircleIconProps = {
  color?: string;
  content: ReactNode | ReactNode[];
  size: CircleIconSize;
};

export const CircleIcon = ({
  color = tokens.colour["shade-2"],
  content,
  size,
}: CircleIconProps) => (
  <div
    className={classNames(styles.circleIcon, {
      [styles.textContent]: typeof content === "string",
    })}
    style={{
      backgroundColor: color,
      color: getTextColor(color),
      fontSize: CircleTextSize[size],
      height: tokens["icon-size"][size],
      width: tokens["icon-size"][size],
    }}
  >
    {content}
  </div>
);
