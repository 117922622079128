import { availableReportsApi, userApi } from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import availableReportsReducer from "./available-reports-slice";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [availableReportsApi.reducerPath]: availableReportsApi.reducer,
  availableReports: availableReportsReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "available-reports" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        userApi.middleware,
        availableReportsApi.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
