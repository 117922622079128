import { ReportType } from "@quantium-enterprise/common-ui";

const ignoredPaths = { "report-wizard": true };

export const isUrlAdvancedReportingTab = (url: string) => {
  const pathnameTokens = url.split("/");

  if (pathnameTokens.some((token) => token in ignoredPaths)) {
    return { matched: false, reportId: undefined };
  }

  // loop through pathname to test for Report specific paths
  const reportTypeArray = Object.values(ReportType);
  let matched = false;
  let reportId;
  for (const token of pathnameTokens) {
    if (reportTypeArray.includes(token as ReportType)) {
      matched = true;
      reportId = pathnameTokens[pathnameTokens.length - 1];
      break;
    }
  }

  return { matched, reportId };
};
