import {
  type GainsAndLossMetricValue,
  type GainsAndLossOverviewItem,
  type GainsAndLossOverviewMetric,
} from "../models/GainsAndLossOverviewReportletDto";
import {
  GainsAndLossMetrics,
  MetricIdTypes,
} from "../models/GainsAndLossReportValues";
import { getMetrics } from "./common-utils";
import { getWaterfallChartMetricValue } from "./overview-waterfall-chart-utils";

export const createWaterfallExportData = (
  waterfallMetrics: GainsAndLossOverviewMetric[],
  metricId: string
) => {
  const waterfallExportData: GainsAndLossMetricValue[] = [];

  for (const metric of waterfallMetrics) {
    const metricValue = getWaterfallChartMetricValue(
      metric.metricValues,
      metricId
    );
    if (metricValue) {
      const data =
        metricId === MetricIdTypes.Loss && metricValue.data > 0
          ? -metricValue.data
          : metricValue.data;
      waterfallExportData.push({
        name: metricValue.name,
        data,
        id: metricId,
      });
    }
  }

  return waterfallExportData;
};

export const getBreakdownWaterfallExportData = (
  waterfallMeasureData?: GainsAndLossOverviewItem | null
) => {
  if (!waterfallMeasureData) {
    return [];
  }

  const waterfallChartPeriodMetricGroups = [
    GainsAndLossMetrics.ComparisonPeriod,
    GainsAndLossMetrics.FocusPeriod,
  ];

  const waterfallChartMainMetricGroups = [
    GainsAndLossMetrics.TotalNewLostRetailer,
    GainsAndLossMetrics.TotalNewLostUniverse,
    GainsAndLossMetrics.CustomersSpendingMoreLess,
    GainsAndLossMetrics.Switching,
  ];

  const periodMetrics = getMetrics(
    waterfallMeasureData,
    waterfallChartPeriodMetricGroups
  );

  const gainsAndLossMetrics = getMetrics(
    waterfallMeasureData,
    waterfallChartMainMetricGroups
  );

  const comparisonPeriodWaterfallData = createWaterfallExportData(
    periodMetrics.filter(
      (metric) => metric.metricGroup === GainsAndLossMetrics.ComparisonPeriod
    ),
    MetricIdTypes.Net
  );

  const focusPeriodWaterfallData = createWaterfallExportData(
    periodMetrics.filter(
      (metric) => metric.metricGroup === GainsAndLossMetrics.FocusPeriod
    ),
    MetricIdTypes.Net
  );

  const gainsWaterfallData = createWaterfallExportData(
    gainsAndLossMetrics,
    MetricIdTypes.Gains
  );

  const lossWaterfallData = createWaterfallExportData(
    gainsAndLossMetrics.reverse(),
    MetricIdTypes.Loss
  );

  const waterfallChartExportData = comparisonPeriodWaterfallData
    .concat(gainsWaterfallData)
    .concat(lossWaterfallData)
    .concat(focusPeriodWaterfallData);

  return waterfallChartExportData;
};

export const getBasicWaterfallExportData = (
  waterfallMeasureData?: GainsAndLossOverviewItem
) => {
  if (!waterfallMeasureData) {
    return [];
  }

  const waterfallChartMetricGroups = [
    GainsAndLossMetrics.ComparisonPeriod,
    GainsAndLossMetrics.TotalNewLostCustomers,
    GainsAndLossMetrics.Switching,
    GainsAndLossMetrics.CustomersSpendingMoreLess,
    GainsAndLossMetrics.FocusPeriod,
  ];

  const waterfallMetrics = getMetrics(
    waterfallMeasureData,
    waterfallChartMetricGroups
  );

  const waterfallChartBars = createWaterfallExportData(
    waterfallMetrics,
    MetricIdTypes.Net
  );
  return waterfallChartBars;
};
