import { type HierarchyValueDto } from "@quantium-enterprise/common-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { BasicColumnChart } from "components-ui/src/charts/basic-column-chart/BasicColumnChart";
import { TooltipHTML } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { useSelector } from "react-redux";
import { type GainsAndLossBreakdownMiniChartData } from "../../models/GainsAndLossBreakdownMiniChartResponseDto";
import { MetricIdTypes } from "../../models/GainsAndLossReportValues";
import { selectShowSwitchingBreakdownChartDataLabels } from "../../services/gains-and-loss-slice";
import styles from "./GainsAndLossBreakdownMiniChart.module.css";
import { GainsAndLossMiniChartTooltip } from "./GainsAndLossMiniChartTooltip";
import {
  BREAKDOWN_TYPES,
  MINI_CHART_HEIGHT,
  getBreakdownMiniChartColumns,
} from "./utils";

type GainsAndLossBreakdownMiniChartProps = {
  dataForMeasure: GainsAndLossBreakdownMiniChartData;
  item: HierarchyValueDto;
};

// override existing column chart tooltip
const renderTooltip = (
  metrics?: Array<{
    color?: string | null;
    name?: number | string | null;
    value?: string | null;
  }>,
  primaryHeader?: number | string
) => {
  if (metrics && metrics.length > 0) {
    let header = "Net gain";
    let secondary;
    const metric = metrics[0].name;
    const color = metrics[0].color;
    const value = metrics[0].value;

    if (metric !== MetricIdTypes.Net) {
      header = String(metric);
      secondary = "Contribution to net";
    }

    return (
      <GainsAndLossMiniChartTooltip
        color={color ?? ""}
        primaryHeader={header}
        secondaryHeader={secondary}
        value={value ?? "0"}
      />
    );
  }

  return TooltipHTML(metrics, primaryHeader);
};

export const GainsAndLossBreakdownMiniChart = ({
  dataForMeasure,
  item,
}: GainsAndLossBreakdownMiniChartProps) => {
  const formatter = useFormatter()(dataForMeasure.format);
  const showSwitchingBreakdownChartDataLabels = useSelector(
    selectShowSwitchingBreakdownChartDataLabels
  );

  const gainsOrLoss =
    (dataForMeasure.gainsAndLossValues.find((x) => x.name === MetricIdTypes.Net)
      ?.data ?? 0) < 0
      ? "loss"
      : "gain";

  return (
    <div className={styles.breakdownMiniChart}>
      <h3 className={styles.breakdownMiniChartHeader}>Gains and loss</h3>
      <h4 className={styles.breakdownMiniChartSubtitle}>
        Net {gainsOrLoss} from {item.name}
      </h4>
      <BasicColumnChart
        categories={BREAKDOWN_TYPES}
        data={getBreakdownMiniChartColumns(dataForMeasure.gainsAndLossValues)}
        dataLabelFormatter={formatter}
        height={MINI_CHART_HEIGHT}
        showChartDataLabels={showSwitchingBreakdownChartDataLabels}
        tooltipFormatter={formatter}
        tooltipOverride={renderTooltip}
        yAxisTickFormatter={formatter}
      />
    </div>
  );
};
