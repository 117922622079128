import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";

export type ItemTableCellFormatterProps = {
  className?: string;
  measureFormat: string;
  style?: React.CSSProperties;
  value: number;
};

export const ItemTableCellFormatter = ({
  value,
  measureFormat,
  className,
  style,
}: ItemTableCellFormatterProps) => (
  <ValueCell
    className={className}
    formatter={useFormatter()(measureFormat)}
    style={style}
    value={value}
  />
);
