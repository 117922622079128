import { type SwitchingBreakdownType } from "../models/SwitchingBreakdownType";

// Gets the maximum value in an array
const getMax = (array: number[]): number => Math.max(...array);

// Gets the minimum value in an array
const getMin = (array: number[]): number => Math.min(...array);

// Returns a map of the impact values from the data array.
const mapImpactVals = (dataArray: SwitchingBreakdownType[]): number[] =>
  dataArray.map((x) => x.impact);

// Returns the larger integer between two.
const compareInt = (number1: number, number2: number): number =>
  number1 > number2 ? number1 : number2;

// Get Maximum Absolute Value in an Array
export const getMaxAbsoluteValueInArray = (
  dataArray: SwitchingBreakdownType[]
): number => {
  const impactVals = mapImpactVals(dataArray);

  const maxPosImpactValue = getMax(impactVals);
  const maxNegImpactValue = Math.abs(getMin(impactVals));

  return compareInt(maxPosImpactValue, maxNegImpactValue);
};

// Returns the width of the impact bar in string format e.g. "50%"
export const getImpactBarWidth = (
  maxImpactValue: number,
  impactValue: number
): string => ((Math.abs(impactValue) * 100) / maxImpactValue).toString() + "%";

// Returns class name depending on the index value. The comparison values used in this function are arbrituary.
export const getIndexColourClass = (indexValue: number): string => {
  if (indexValue > 200) {
    return "indexBand5";
  } else if (indexValue > 110) {
    return "indexBand4";
  } else if (indexValue >= 90) {
    return "indexBand3";
  } else if (indexValue >= 50) {
    return "indexBand2";
  } else {
    return "indexBand1";
  }
};

// Returns class name depending on whether the impact value is positive or negative
export const getImpactColourClass = (impactValue: number): string =>
  impactValue >= 0 ? "impactPositive" : "impactNegative";
