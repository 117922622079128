import {
  type HierarchyValueDto,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { type BasketQuantitiesLocalParametersResponseDto } from "../models/BasketQuantitiesLocalParametersResponseDto";
import { BreakdownByParameterId } from "../models/basket-quantities-common-models";
import {
  type TableRow,
  type InitialTableResponse,
  type BasketQuantitiesMetadata,
} from "../models/basket-quantities-data-table-models";
import { type OverTimeChartResponse } from "../models/basket-quantities-over-time-response-dto";
import { type BasketQuantitiesSummaryResponseDto } from "../models/basket-quantities-summary-response-dto";
import { getDefaultSelections } from "../utils/getDefaultSelections";
import { getHierarchyValueId } from "../utils/getHierarchyValueId";

const getPersistenceKey = (reportId: string) => `basket-quantities-${reportId}`;

export type BasketQuantitiesPersistedSelections = {
  focalItem: HierarchyValueDto;
  focalItemTableSelectionState: RowSelectionState;
  localParametersSelections: BasketQuantitiesLocalSelections;
};

export type BasketQuantitiesLocalSelections = {
  breakdown: PanelOption;
  channel: PanelOption;
  locationHierarchy: LocalHierarchyNodeSelection;
  promotion: PanelOption;
  segment: PanelOption;
  segmentation: PanelOption;
  time: string;
  timePeriodLength: string;
  viewAs: PanelOption;
};

export type BasketQuantitiesState = {
  focalItemTableMetadata: BasketQuantitiesMetadata;
  focalItemTableRows: TableRow[];

  localParameters: SidePanelParameter[];
  localParametersInitialised: boolean;

  overTimeBasketSizeSelection: string;
  overTimeChart: OverTimeChartResponse;
  overTimePrimaryMetric: string;
  overTimeSecondaryMetric: string;

  persistedSelections: BasketQuantitiesPersistedSelections;
  persistedSelectionsLoaded: boolean;

  reportId: string;
  reportName: string;

  showBasketQuantitiesOverTimeChartDataLabels: boolean;
  showBasketQuantitiesSummaryChartDataLabels: boolean;

  summaryChart: BasketQuantitiesSummaryResponseDto;
  summaryChartAdditionalMetric: string;
};

export const initialState: BasketQuantitiesState = {
  focalItemTableMetadata: {
    metricMetadata: [],
  },
  focalItemTableRows: [],
  localParameters: [],
  localParametersInitialised: false,
  overTimeBasketSizeSelection: "Total",
  overTimeChart: {
    basketSeries: [],
    dates: [],
  },
  overTimePrimaryMetric: "Baskets",
  overTimeSecondaryMetric: "",
  reportName: "",
  showBasketQuantitiesOverTimeChartDataLabels: false,
  showBasketQuantitiesSummaryChartDataLabels: false,
  summaryChart: {
    categories: [],
    series: [],
  },
  persistedSelections: {
    focalItem: {
      itemCode: "",
      name: "",
      shortName: "",
    },
    focalItemTableSelectionState: {},
    localParametersSelections: {
      breakdown: {
        value: "",
        label: "",
      },
      channel: {
        value: "",
        label: "",
      },
      locationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
        isDefault: false,
      },
      promotion: {
        value: "",
        label: "",
      },
      segmentation: {
        value: "",
        label: "",
      },
      segment: {
        value: "",
        label: "",
      },
      time: "",
      timePeriodLength: "",
      viewAs: {
        value: "",
        label: "",
      },
    },
  },
  summaryChartAdditionalMetric: "",
  persistedSelectionsLoaded: false,
  reportId: "",
};

export const basketQuantitiesSlice = createSlice({
  initialState,
  name: "basketQuantities",
  reducers: {
    onFocalItemChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<HierarchyValueDto>
    ) => {
      const oldFocalItemId = getHierarchyValueId(
        state.persistedSelections.focalItem
      );
      if (
        oldFocalItemId in state.persistedSelections.focalItemTableSelectionState
      ) {
        state.persistedSelections.focalItemTableSelectionState[oldFocalItemId] =
          false;
      }

      state.persistedSelections.focalItem = payload;
      state.persistedSelections.focalItemTableSelectionState[
        getHierarchyValueId(payload)
      ] = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onBasketQuantitiesSummaryResponseReceived: (
      state: BasketQuantitiesState,
      action: PayloadAction<BasketQuantitiesSummaryResponseDto>
    ) => {
      state.summaryChart = action.payload;
    },
    onTableDataReceived: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<InitialTableResponse>
    ) => {
      state.focalItemTableMetadata = payload.tableMetadata;
      state.focalItemTableRows = payload.tableRows;
    },
    onOverTimeResponseReceived: (
      state: BasketQuantitiesState,
      action: PayloadAction<OverTimeChartResponse>
    ) => {
      state.overTimeChart = action.payload;
    },
    onLocalParametersReceived: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<BasketQuantitiesLocalParametersResponseDto>
    ) => {
      state.reportName = payload.reportName;
      state.localParameters = payload.localParameters;
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParametersSelections =
          getDefaultSelections(payload.localParameters);
        const oldFocalItemId = getHierarchyValueId(
          state.persistedSelections.focalItem
        );
        if (
          oldFocalItemId in
          state.persistedSelections.focalItemTableSelectionState
        ) {
          state.persistedSelections.focalItemTableSelectionState[
            oldFocalItemId
          ] = false;
        }

        state.persistedSelections.focalItem = payload.focalItem;
        state.persistedSelections.focalItemTableSelectionState[
          getHierarchyValueId(payload.focalItem)
        ] = true;
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onViewAsChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.viewAs = payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onBreakdownChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      // when breakdown changes to either Segmentation or Promotion, set the Segment or Promotion to All
      if (payload.value === BreakdownByParameterId.Segmentation) {
        state.persistedSelections.localParametersSelections.segment = {
          value: "All",
          label: "All",
        };
      }

      if (payload.value === BreakdownByParameterId.Promotion) {
        state.persistedSelections.localParametersSelections.promotion = {
          value: "All",
          label: "All",
        };
      }

      state.persistedSelections.localParametersSelections.breakdown = payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onChannelChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.channel = {
        label: payload.label,
        value: payload.value,
      };
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onPromotionChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.promotion = {
        label: payload.label,
        value: payload.value,
      };
      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localParametersSelections.segmentation = {
        value: payload.segmentationValue,
        label: payload.segmentationLabel,
      };
      state.persistedSelections.localParametersSelections.segment = {
        value: payload.segmentValue,
        label: payload.segmentLabel,
      };

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localParametersSelections.locationHierarchy =
        payload;

      persistSelections(
        getPersistenceKey(state.reportId),
        state.persistedSelections
      );
    },
    onSummaryChartAdditionalMetricChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<string>
    ) => {
      state.summaryChartAdditionalMetric = payload;
    },
    onOverTimeBasketSizeChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<string>
    ) => {
      state.overTimeBasketSizeSelection = payload;
    },
    onOverTimePrimaryMetricChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<string>
    ) => {
      state.overTimePrimaryMetric = payload;
    },
    onOverTimeSecondaryMetricChange: (
      state: BasketQuantitiesState,
      { payload }: PayloadAction<string>
    ) => {
      state.overTimeSecondaryMetric = payload;
    },
    toggleBasketQuantitiesOverTimeChartDataLabels: (
      state: BasketQuantitiesState
    ) => {
      state.showBasketQuantitiesOverTimeChartDataLabels =
        !state.showBasketQuantitiesOverTimeChartDataLabels;
    },
    toggleBasketQuantitiesSummaryChartDataLabels: (
      state: BasketQuantitiesState
    ) => {
      state.showBasketQuantitiesSummaryChartDataLabels =
        !state.showBasketQuantitiesSummaryChartDataLabels;
    },
    onReportOpen: (
      state: BasketQuantitiesState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: BasketQuantitiesPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
  },
});

export const selectLocalParameter = createSelector(
  (state: RootState) => state.basketQuantities.localParameters,
  (localParameters) => localParameters
);
export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.basketQuantities.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);
export const selectBreakdown = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .breakdown,
  (breakdown) => breakdown
);
export const isBreakdownByTotal = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .breakdown.value,
  (value) => value === BreakdownByParameterId.Total
);
export const selectChannel = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .channel,
  (channel) => channel
);
export const selectLocationHierarchy = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .locationHierarchy,
  (locationHierarchy) => locationHierarchy
);
export const selectPromotion = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .promotion,
  (promotion) => promotion
);
export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .segmentation,
  (segmentation) => segmentation
);
export const selectSegment = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .segment,
  (segment) => segment
);
export const selectTime = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections.time,
  (time) => time
);
export const selectTimePeriodLength = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections
      .timePeriodLength,
  (timePeriodLength) => timePeriodLength
);
export const selectViewAs = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.localParametersSelections.viewAs,
  (viewAs) => viewAs
);
export const selectFocalItem = createSelector(
  (state: RootState) => state.basketQuantities.persistedSelections.focalItem,
  (focalItem) => focalItem
);
export const selectFocalItemTableRows = createSelector(
  (state: RootState) => state.basketQuantities.focalItemTableRows,
  (focalItemTableRows) => focalItemTableRows
);
export const selectFocalItemTableMetadata = createSelector(
  (state: RootState) => state.basketQuantities.focalItemTableRows,
  (focalItemTableMetadata) => focalItemTableMetadata
);
export const selectFocalItemTableSelectionState = createSelector(
  (state: RootState) =>
    state.basketQuantities.persistedSelections.focalItemTableSelectionState,
  (focalItemTableSelectionState) => focalItemTableSelectionState
);
export const selectReportName = createSelector(
  (state: RootState) => state.basketQuantities.reportName,
  (reportName) => reportName
);
export const selectSummaryChartAdditionalMetric = createSelector(
  (state: RootState) => state.basketQuantities.summaryChartAdditionalMetric,
  (summaryChartAdditionalMetric) => summaryChartAdditionalMetric
);
export const selectOverTimeBasketSize = createSelector(
  (state: RootState) => state.basketQuantities.overTimeBasketSizeSelection,
  (overTimeBasketSizeSelection) => overTimeBasketSizeSelection
);
export const selectOverTimePrimaryMetric = createSelector(
  (state: RootState) => state.basketQuantities.overTimePrimaryMetric,
  (overTimePrimaryMetric) => overTimePrimaryMetric
);
export const selectOverTimeSecondaryMetric = createSelector(
  (state: RootState) => state.basketQuantities.overTimeSecondaryMetric,
  (overTimeSecondaryMetric) => overTimeSecondaryMetric
);
export const selectShowBasketQuantitiesOverTimeChartDataLabels = createSelector(
  (state: RootState) =>
    state.basketQuantities.showBasketQuantitiesOverTimeChartDataLabels,
  (showBasketQuantitiesOverTimeChartDataLabels) =>
    showBasketQuantitiesOverTimeChartDataLabels
);
export const selectShowBasketQuantitiesSummaryChartDataLabels = createSelector(
  (state: RootState) =>
    state.basketQuantities.showBasketQuantitiesSummaryChartDataLabels,
  (showBasketQuantitiesSummaryChartDataLabels) =>
    showBasketQuantitiesSummaryChartDataLabels
);
export const selectReportId = createSelector(
  (state: RootState) => state.basketQuantities.reportId,
  (reportId) => reportId
);

export const {
  onReportOpen,
  onFocalItemChange,
  onBasketQuantitiesSummaryResponseReceived,
  onOverTimeResponseReceived,
  onTableDataReceived,
  onLocalParametersReceived,
  onViewAsChange,
  onBreakdownChange,
  onChannelChange,
  onPromotionChange,
  onSegmentationChange,
  onLocationChange,
  onSummaryChartAdditionalMetricChange,
  onOverTimeBasketSizeChange,
  onOverTimePrimaryMetricChange,
  onOverTimeSecondaryMetricChange,
  toggleBasketQuantitiesOverTimeChartDataLabels,
  toggleBasketQuantitiesSummaryChartDataLabels,
} = basketQuantitiesSlice.actions;

export default basketQuantitiesSlice.reducer;
