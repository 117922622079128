import { type Row } from "@tanstack/react-table";
import { type LocationHierarchyParameterItem } from "../hierarchy/LocationHierarchyParameterTable";
import { type ProductHierarchyParameterItem } from "../hierarchy/ProductHierarchyParameterTable";

export type ItemLookup = Record<
  string,
  | Record<
      string,
      LocationHierarchyParameterItem | ProductHierarchyParameterItem | undefined
    >
  | undefined
>;

export const createItemLookup = (
  items: LocationHierarchyParameterItem[] | ProductHierarchyParameterItem[]
): ItemLookup => {
  const itemLookup: ItemLookup = {};
  const createItemLookupRecursive = (
    itemsOrSubRows:
      | LocationHierarchyParameterItem[]
      | ProductHierarchyParameterItem[]
  ) => {
    for (const item of itemsOrSubRows) {
      let itemsByCode = itemLookup[item.shortName];
      if (!itemsByCode) {
        itemsByCode = {};
        itemLookup[item.shortName] = itemsByCode;
      }

      itemsByCode[item.code] = item;
      if (item.subRows) {
        createItemLookupRecursive(item.subRows);
      }
    }
  };

  createItemLookupRecursive(items);
  return itemLookup;
};

export const insertChildrenIntoPosition = (
  itemLookup: ItemLookup,
  subRows: LocationHierarchyParameterItem[] | ProductHierarchyParameterItem[],
  parentCode: string,
  parentShortName: string
) => {
  const itemsByCode = itemLookup[parentShortName];
  if (!itemsByCode) return;
  const row = itemsByCode[parentCode];
  if (!row) return;
  for (const element of subRows) {
    element.parentRow = { ...row };
  }

  if (row.subRows === undefined || row.subRows.length === 0) {
    row.subRows = subRows;
  } else {
    row.subRows[row.subRows.length - 1].isMoreRow = false;
    row.subRows = [...row.subRows, ...subRows];
  }
};

export const overwriteChildrenInPosition = (
  itemLookup: ItemLookup,
  subRows: LocationHierarchyParameterItem[] | ProductHierarchyParameterItem[],
  parentCode: string,
  parentShortName: string
) => {
  const itemsByCode = itemLookup[parentShortName];
  if (!itemsByCode) return;
  const row = itemsByCode[parentCode];
  if (!row) return;
  for (const element of subRows) {
    element.parentRow = { ...row };
  }

  row.subRows = subRows;
};

export const toggleNodeExpanding = (
  itemLookup: ItemLookup,
  code: string,
  shortName: string
) => {
  const itemsByCode = itemLookup[shortName];
  if (!itemsByCode) return;
  const row = itemsByCode[code];
  if (!row) return;
  row.isExpanding = row.subRows === undefined || row.subRows.length === 0;
};

export const findAncestorWithShortName = (
  current: LocationHierarchyParameterItem | ProductHierarchyParameterItem,
  shortName: string
): LocationHierarchyParameterItem | ProductHierarchyParameterItem | null => {
  if (current.shortName === shortName) {
    return current;
  } else {
    if (!current.parentRow) {
      return null;
    }

    return findAncestorWithShortName(current.parentRow, shortName);
  }
};

export const isDisabledByHierarchyLevelLimit = (
  selectedRows: ProductHierarchyParameterItem[],
  row: Row<ProductHierarchyParameterItem>,
  shortName: string
) => {
  // enabled if selected so it can be deselected
  if (
    selectedRows.filter(
      (x) =>
        x.code === row.original.code && x.shortName === row.original.shortName
    ).length === 1
  )
    return false;

  const selectedAncestorsWithShortName = [
    ...new Set(
      selectedRows.map((x) => findAncestorWithShortName(x, shortName)?.code)
    ),
  ].filter(Boolean);

  const ancestorWithShortName = findAncestorWithShortName(
    row.original,
    shortName
  );

  // otherwise, check if it matches with the selected rows
  // if nothing is selected, then everything else is enabled
  if (selectedAncestorsWithShortName.length === 0) return false;

  // if something is selected, check there is only one ancestor which matches row
  if (
    ancestorWithShortName &&
    selectedAncestorsWithShortName.length === 1 &&
    selectedAncestorsWithShortName[0]
  ) {
    // check ancestor, disable unless same ancestor
    if (ancestorWithShortName.code === selectedAncestorsWithShortName[0]) {
      return false;
    }

    return true;
  }

  // should only reach here if item in selectedRows doesn't have an ancestor of shortname
  // either it is above the restricted row or a parent wasn't set correctly
  return false;
};
