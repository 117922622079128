import {
  type LocalHierarchyNodeSelection,
  type HierarchyValue,
} from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../../common/models/LocalParameterId";
import {
  type TableChildRowsRequestDto,
  type TableRowsRequestDto,
} from "../../models/CustomerLoyaltyTableRowsRequestDto";

const TABLE_PAGE_SIZE = 50;

export const getTableRequestDto = (
  activeTab: string,
  segmentation: string[],
  locationHierarchy: LocalHierarchyNodeSelection,
  pageIndex: number,
  reportId: string,
  searchQuery?: string
): TableRowsRequestDto => ({
  levelOfAnalysis: activeTab,
  localSelectedValues: [
    {
      id: LocalParameters.Segmentation,
      values: segmentation,
    },
    {
      id: LocalParameters.LocationHierarchy,
      values: [locationHierarchy.nodeNumber.toString()],
    },
  ],
  pageIndex,
  pageSize: TABLE_PAGE_SIZE,
  reportId,
  searchQuery,
});

export const getTableChildrenRequestDto = (
  activeTab: string,
  pageIndex: number,
  parentHierarchyItem: HierarchyValue,
  reportId: string,
  segmentation: string[],
  locationHierarchy: LocalHierarchyNodeSelection
): TableChildRowsRequestDto => ({
  levelOfAnalysis: activeTab,
  localSelectedValues: [
    {
      id: LocalParameters.Segmentation,
      values: segmentation,
    },
    {
      id: LocalParameters.LocationHierarchy,
      values: [locationHierarchy.nodeNumber.toString()],
    },
  ],
  pageIndex,
  pageSize: TABLE_PAGE_SIZE,
  parentHierarchyItem,
  reportId,
});
