import { type CustomerGroupDto } from "@quantium-enterprise/common-ui";
import {
  TIME_ELAPSED_UPDATE_INTERVAL_MS,
  getTimeAgoString,
} from "@quantium-enterprise/common-ui";
import { useDebounce, useDivision } from "@quantium-enterprise/hooks-ui";
import {
  Checkbox,
  FormBlock,
  FormBlockType,
  Input,
} from "@quantium-enterprise/qds-react";
import {
  type RowSelectionState,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import { EmptyGroup } from "components-ui/src/search/EmptyGroup";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { type TableVirtuosoHandle } from "react-virtuoso";
import {
  selectRenameState,
  selectSearchText,
} from "../../states/group-list-slice";
import {
  getDefaultGroupsPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { ActionMenu } from "../action-menu/ActionMenu";
import styles from "./GroupListTable.module.css";

const CheckboxClassName = "group-list-row-checkbox";

type CheckboxCellProps = {
  checked: boolean;
  groupId: string;
  groupName: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxCell = ({
  checked,
  groupId,
  groupName,
  onChange,
}: CheckboxCellProps) => (
  <FormBlock blockType={FormBlockType.Checkbox} className={styles.checkboxCell}>
    <Input>
      <Checkbox
        checked={checked}
        className={CheckboxClassName}
        data-testid={groupId}
        id={groupId}
        label=""
        name={groupName}
        onChange={onChange}
      />
    </Input>
  </FormBlock>
);

type NameCellProps = {
  icon: JSX.Element;
  text: string;
};

export const NameCell = ({ icon, text }: NameCellProps) => (
  <div className={styles.nameCell}>
    {icon}
    <span className={styles.nameText}>{text}</span>
  </div>
);

const QuickActionsClassName = "group-list-row-quick-actions";

type QuickActionsCellProps = {
  getCustomerGroup?: (groupId: string) => CustomerGroupDto | undefined;
  groupId: string;
  handleDelete?: (groupId: string) => void;
  handleDownload?: (groupId: string) => void;
  handleMove?: (groupId: string) => void;
  handleRefresh?: (groupId: string) => void;
  handleRename?: (groupId: string) => void;
  handleShare?: (groupId: string) => void;
};

export const QuickActionsCell = ({
  getCustomerGroup,
  groupId,
  handleDelete,
  handleDownload,
  handleMove,
  handleRefresh,
  handleRename,
  handleShare,
}: QuickActionsCellProps) => (
  <ActionMenu
    className={QuickActionsClassName}
    getCustomerGroup={
      getCustomerGroup ? () => getCustomerGroup(groupId) : undefined
    }
    handleDelete={handleDelete ? () => handleDelete(groupId) : undefined}
    handleDownload={handleDownload ? () => handleDownload(groupId) : undefined}
    handleMove={handleMove ? () => handleMove(groupId) : undefined}
    handleRefresh={handleRefresh ? () => handleRefresh(groupId) : undefined}
    handleRename={handleRename ? () => handleRename(groupId) : undefined}
    handleShare={handleShare ? () => handleShare(groupId) : undefined}
  />
);

type TimeElapsedCellProps = {
  timestamp: string;
};

export const TimeElapsedCell = ({ timestamp }: TimeElapsedCellProps) => {
  const [timeAgoString, setTimeAgoString] = useState(
    getTimeAgoString(timestamp)
  );

  useEffect(() => {
    setTimeAgoString(getTimeAgoString(timestamp));
    const interval = setInterval(() => {
      setTimeAgoString(getTimeAgoString(timestamp));
    }, TIME_ELAPSED_UPDATE_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [timestamp]);

  return (
    <div className={styles.dateCell}>
      <span className={styles.dateText}>{timeAgoString}</span>
    </div>
  );
};

type TypeCellProps = {
  icon: JSX.Element;
  text: string;
};

export const TypeCell = ({ icon, text }: TypeCellProps) => (
  <div className={styles.typeCell}>
    <span className={styles.typeIcon}>{icon}</span>
    <span className={styles.typeText}>{text}</span>
  </div>
);

export type GroupRow = {
  id?: string;
  name?: string;
  updateDateUtc?: string;
};

export type GroupListTableProps<T extends GroupRow> = {
  columns: Array<ColumnDef<T>>;
  groupType: string;
  isFetchUnitialised: boolean;
  isFetching: boolean;
  onCheckboxSelect?: (groupId: string) => void;
  rows: T[];
  setFocalGroup: (groupId: string | undefined) => void;
  sortByColumnId: string;
};

export const GroupListTable = <T extends GroupRow>({
  columns,
  groupType,
  onCheckboxSelect,
  isFetching,
  isFetchUnitialised,
  rows,
  setFocalGroup,
  sortByColumnId,
}: GroupListTableProps<T>) => {
  const navigate = useNavigate();
  const { name: divisionName } = useDivision();
  const { groupId } = useParams();
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
    {}
  );
  const [initialised, setInitialised] = useState<boolean>(false);
  const searchTerm = useSelector(selectSearchText);
  const renameState = useSelector(selectRenameState);
  const debouncedIsFetching = useDebounce<boolean>(isFetching, 500, false);
  const virtuosoTableComponentRef = useRef<TableVirtuosoHandle>();

  const getRowId = useCallback((row: T) => row.id ?? "", []);

  useEffect(() => {
    if (groupId) {
      for (const row of rows) {
        if (groupId && row.id === groupId) {
          setRowSelectionState({ [getRowId(row)]: true });
          setFocalGroup(groupId);
          return;
        }
      }
    }

    setRowSelectionState({});
    setFocalGroup(undefined);
  }, [groupId, rows, getRowId, setFocalGroup]);

  useEffect(() => {
    setSelectedGroupIds([]);
  }, [groupType]);

  const sort = useMemo(
    () => (sortByColumnId ? [{ desc: true, id: sortByColumnId }] : undefined),
    [sortByColumnId]
  );

  const handleRowClick = useCallback(
    (
      event: React.MouseEvent<HTMLTableRowElement>,
      rowId: number,
      rowData: T
    ) => {
      if (
        (event.target as Element).closest(`.${CheckboxClassName}`) ||
        (event.target as Element).closest(`.${QuickActionsClassName}`)
      ) {
        return;
      }

      if (!renameState.id) {
        if (rowData.id === groupId) {
          navigate(getDefaultGroupsPath(divisionName, groupType));
        } else {
          navigate(getGroupListPath(divisionName, groupType, rowData.id));
        }
      }
    },
    [groupId, renameState, navigate, divisionName, groupType]
  );

  const checkboxCell = useCallback(
    (info: CellContext<T, unknown>) => {
      const { id, name } = info.getValue<{ id: string; name: string }>();
      const checked = selectedGroupIds.includes(id);
      const onChange = () => {
        if (checked) {
          setSelectedGroupIds((ids) => ids.filter((id_) => id_ !== id));
        } else {
          setSelectedGroupIds((ids) => [...ids, id]);
        }

        onCheckboxSelect?.(id);
      };

      return (
        <CheckboxCell
          checked={checked}
          groupId={id}
          groupName={name}
          onChange={onChange}
        />
      );
    },
    [selectedGroupIds, onCheckboxSelect]
  );

  const columnsWithCheckbox: Array<ColumnDef<T>> = [
    {
      accessorFn: (row) => ({
        id: row.id,
        name: row.name,
      }),
      cell: checkboxCell,
      header: "",
      id: "selectColumn",
      minSize: 20,
      maxSize: 20,
      size: 20,
    },
    ...columns,
  ];

  useEffect(() => {
    if (!initialised && virtuosoTableComponentRef.current && rows.length) {
      if (groupId) {
        const index = rows.findIndex((row) => row.id === groupId);
        if (index >= 0) {
          // Defer scroll so that the table has time to render.
          // Needed some time more than 1ms. 500ms should be adequate most of the time.
          setTimeout(
            () => virtuosoTableComponentRef.current?.scrollToIndex(index),
            500
          );
          setRowSelectionState({ [getRowId(rows[index])]: true });
        }
      }

      setInitialised(true);
    }
  }, [
    groupId,
    virtuosoTableComponentRef,
    initialised,
    rows,
    getRowId,
    setInitialised,
    setRowSelectionState,
  ]);

  return (
    <>
      <VirtuosoTableComponent
        className={styles.groupListTable}
        columns={columnsWithCheckbox}
        data={rows}
        getRowId={getRowId}
        onRowClick={handleRowClick}
        ref={virtuosoTableComponentRef}
        refreshingData={debouncedIsFetching && rows.length === 0}
        rowSelectionState={rowSelectionState}
        sorting={sort}
      />
      {!isFetching &&
      !isFetchUnitialised &&
      rows.length === 0 &&
      !searchTerm ? (
        <div className={styles.emptyContent}>
          <EmptyGroup type={groupType} />
        </div>
      ) : !isFetching && rows.length === 0 && searchTerm ? (
        <div className={styles.emptySearch}>
          <EmptySearch />
        </div>
      ) : null}
    </>
  );
};
