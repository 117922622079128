import {
  type ParameterOptionDto,
  type MeasureThresholdParameterOption,
  type ParameterDto,
  NumberFormat,
} from "@quantium-enterprise/common-ui";
import {
  Button,
  ButtonVariant,
  FormBlock,
  FormBlockType,
  Icon,
  IconGlyph,
  Input,
  NumberInput,
  Select,
  SelectOption,
  Text,
} from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  measureThresholdAdded,
  measureThresholdRemoved,
  measureThresholdUpdated,
} from "../../states/report-wizard-slice";
import { ParameterLabel } from "../parameter-label/ParameterLabel";
import styles from "./MeasureThresholdParameter.module.css";
import {
  getValidComparators,
  type MeasureThresholdSelection,
} from "./MeasureThresholdParameterState";
import { getMeasureThresholdParameterState } from "./MeasureThresholdParameterState";

export type MeasureThresholdParameterProps = {
  headingText?: string;
  parameterDto: ParameterDto;
};

export const MeasureThresholdParameter = ({
  headingText,
  parameterDto,
}: MeasureThresholdParameterProps) => {
  const dispatch = useDispatch();
  const { selections } = useAppSelector(
    getMeasureThresholdParameterState(parameterDto.id)
  );

  const maxSelections = parameterDto.attributes.maxSelections;
  const measures = parameterDto.options as MeasureThresholdParameterOption[];
  const getComparators = useCallback(
    (
      measure: MeasureThresholdParameterOption,
      comparator: ParameterOptionDto
    ) => getValidComparators(selections, measure, comparator),
    [selections]
  );

  const addFilter = useCallback(() => {
    let newSelection: MeasureThresholdSelection | undefined;
    for (const measure of measures) {
      const comparator = getValidComparators(selections, measure).at(0);
      if (comparator) {
        newSelection = {
          measure,
          comparator,
          id: uniqueId(),
          threshold: undefined,
        };
        break;
      }
    }

    if (!newSelection) {
      return;
    }

    dispatch(
      measureThresholdAdded({
        parameterId: parameterDto.id,
        newSelection,
      })
    );
  }, [dispatch, parameterDto.id, measures, selections]);

  const removeFilter = useCallback(
    (index: number) => {
      dispatch(
        measureThresholdRemoved({
          parameterId: parameterDto.id,
          index,
        })
      );
    },
    [dispatch, parameterDto.id]
  );

  const updateMeasure = useCallback(
    (index: number, event: React.FormEvent<HTMLInputElement>) => {
      const measure = measures.find(
        (option) => option.value === event.currentTarget.value
      );

      if (!measure) {
        return;
      }

      const newSelection = { ...selections[index] };
      newSelection.measure = measure;
      newSelection.threshold = undefined;

      dispatch(
        measureThresholdUpdated({
          parameterId: parameterDto.id,
          newSelection,
          index,
        })
      );
    },
    [selections, measures, dispatch, parameterDto.id]
  );

  const updateComparator = useCallback(
    (index: number, event: React.FormEvent<HTMLInputElement>) => {
      const comparator = selections[index].measure.options.find(
        (option) => option.value === event.currentTarget.value
      );
      if (!comparator) {
        return;
      }

      const newSelection = { ...selections[index] };
      newSelection.comparator = comparator;

      dispatch(
        measureThresholdUpdated({
          parameterId: parameterDto.id,
          newSelection,
          index,
        })
      );
    },
    [selections, dispatch, parameterDto.id]
  );

  const updateThreshold = useCallback(
    (index: number, event: React.FormEvent<HTMLInputElement>) => {
      const newSelection = { ...selections[index] };

      const input = Number(event.currentTarget.value);
      const isInvalid =
        input < 0 ||
        (newSelection.measure.thresholdFormat === NumberFormat.Percent &&
          input > 100) ||
        (newSelection.measure.thresholdFormat === NumberFormat.Integer &&
          !Number.isInteger(input));

      if (isInvalid) {
        return;
      }

      newSelection.threshold = input;

      dispatch(
        measureThresholdUpdated({
          parameterId: parameterDto.id,
          newSelection,
          index,
        })
      );
    },
    [dispatch, parameterDto.id, selections]
  );

  return (
    <div className={styles.measureThresholdParameter}>
      <ParameterLabel
        description={parameterDto.description}
        heading={headingText ?? parameterDto.name}
        htmlFor={parameterDto.name}
      />
      {selections.map((selection, index) => (
        <div className={styles.measureThresholdFilter} key={selection.id}>
          <FormBlock blockType={FormBlockType.Select}>
            <Select
              id={`filter-${selection.id}-measure`}
              onChange={(event) => updateMeasure(index, event)}
              value={selection.measure.value}
            >
              {measures
                .filter(
                  (measure) =>
                    getComparators(measure, selection.comparator).length > 0
                )
                .map((measure) => (
                  <SelectOption
                    key={measure.value}
                    text={measure.label}
                    value={measure.value}
                  />
                ))}
            </Select>
          </FormBlock>
          <FormBlock blockType={FormBlockType.Select}>
            <Select
              id={`filter-${selection.id}-comparator`}
              onChange={(event) => updateComparator(index, event)}
              value={selection.comparator.value}
            >
              {getComparators(selection.measure, selection.comparator).map(
                (comparator) => (
                  <SelectOption
                    key={comparator.value}
                    text={comparator.label}
                    value={comparator.value}
                  />
                )
              )}
            </Select>
          </FormBlock>
          <FormBlock
            blockType={FormBlockType.Number}
            className={styles.thresholdFormBlock}
          >
            <Input className={styles.thresholdInput}>
              <NumberInput
                id={`filter-${selection.id}-threshold`}
                min={0}
                onChange={(event) => updateThreshold(index, event)}
                step={1}
                value={String(selection.threshold)}
              />
            </Input>
            {selection.measure.thresholdFormat === NumberFormat.Percent ? (
              <Text>%</Text>
            ) : (
              <> </>
            )}
          </FormBlock>
          <Button
            onClick={() => removeFilter(index)}
            variant={ButtonVariant.Link}
          >
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Remove filter" />
          </Button>
        </div>
      ))}
      <Button
        className={styles.addButton}
        disabled={selections.length >= maxSelections}
        onClick={addFilter}
        variant={ButtonVariant.Stealth}
      >
        <Icon glyph={IconGlyph.AddAndPlusAddPlus} text="Add filter" />
        <span>Add filter</span>
      </Button>
    </div>
  );
};
