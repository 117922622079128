import { type SegmentDto } from "@quantium-enterprise/common-ui";
import { format } from "date-fns";

export const toCSVData = (segments: SegmentDto[]): string[][] => {
  const data = [
    [
      "Customer group id",
      "Customer group name",
      "Segment id",
      "Segment name",
      "Count",
      "Creation date",
      "Description",
    ],
  ];
  for (const segment of segments) {
    data.push([
      segment.customerGroupId,
      segment.customerGroupName ?? "",
      segment.segmentId.toString(),
      segment.name,
      segment.count.toString(),
      segment.customerGroupCreatedDate
        ? format(new Date(segment.customerGroupCreatedDate), "yyyy-MM-dd")
        : "",
      segment.activationNotes ?? "",
    ]);
  }

  return data;
};
