import { type ReactNode } from "react";
import HeavyIcon from "../../assets/common/segmentation-icons/Heavy.svg";
import LightIcon from "../../assets/common/segmentation-icons/Light.svg";
import MediumIcon from "../../assets/common/segmentation-icons/Medium.svg";
import BudgetIcon from "../../assets/common/segmentation-icons/affluence-budget.svg";
import MainstreamIcon from "../../assets/common/segmentation-icons/affluence-mainstream.svg";
import PremiumIcon from "../../assets/common/segmentation-icons/affluence-premium.svg";
import LifestageMidAgeIcon from "../../assets/common/segmentation-icons/lifestage-mid-age.svg";
import LifestageNewFamilyIcon from "../../assets/common/segmentation-icons/lifestage-new-family.svg";
import LifestageOlderFamiliesIcon from "../../assets/common/segmentation-icons/lifestage-older-families.svg";
import LifestageOlderSinglesIcon from "../../assets/common/segmentation-icons/lifestage-older-singles.svg";
import LifestageRetireesIcon from "../../assets/common/segmentation-icons/lifestage-retirees.svg";
import LifestageYoungFamiliesIcon from "../../assets/common/segmentation-icons/lifestage-young-families.svg";
import LifestageYoungSinglesIcon from "../../assets/common/segmentation-icons/lifestage-young-singles.svg";
import { SegmentationContent } from "./SegmentationContent";
import styles from "./SegmentationModal.module.css";

export type expanderContent = {
  className?: string;
  content: ReactNode;
  name: string;
  title: string;
};

const affluenceAccordion: expanderContent = {
  title: "Affluence",
  name: "Affluence",
  content: (
    <SegmentationContent
      segmentIcons={[
        {
          className: styles.affluenceIcon,
          icon: BudgetIcon,
          legend: "Budget customers",
        },
        {
          className: styles.affluenceIcon,
          icon: MainstreamIcon,
          legend: "Mainstream customers",
        },
        {
          className: styles.affluenceIcon,
          icon: PremiumIcon,
          legend: "Premium customers",
        },
      ]}
      segmentList={[
        "Budget (lower-priced product preference)",
        "Mainstream (mid-priced product preference)",
        "Premium (higher-priced product preference)",
      ]}
      segmentationDefinition={`This segmentation groups customers into one of three segments, based on
    their relative price preference by looking at the price points of the
    products they purchase and the types of products they buy:`}
    />
  ),
};

const lifeStageAccordion: expanderContent = {
  title: "Life-stage",
  name: "Life-stage",
  content: (
    <SegmentationContent
      segmentIcons={[
        {
          className: styles.lifestageIcon,
          icon: LifestageNewFamilyIcon,
          legend: "New families",
        },
        {
          className: styles.lifestageIcon,
          icon: LifestageYoungFamiliesIcon,
          legend: "Young families",
        },
        {
          className: styles.lifestageIcon,
          icon: LifestageYoungSinglesIcon,
          legend: "Young singles and couples",
        },
        {
          className: styles.lifestageIcon,
          icon: LifestageMidAgeIcon,
          legend: "Mid-age singles and couples",
        },
        {
          className: styles.lifestageIcon,
          icon: LifestageOlderFamiliesIcon,
          legend: "Older families",
        },
        {
          className: styles.lifestageIcon,
          icon: LifestageOlderSinglesIcon,
          legend: "Older singles and couples",
        },
        {
          className: styles.lifestageIcon,
          icon: LifestageRetireesIcon,
          legend: "Retirees",
        },
      ]}
      segmentList={[
        "Young singles and couples (30 or under with no children)",
        "Mid-aged singles (31 - 45 no children)",
        "Older singles and couples (46 - 64 no children)",
        "New families (children aged under 3)",
        "Young families (children aged 3 - 12)",
        "Older families (Children aged 13+)",
        "Retirees (65+ with no children)",
      ]}
      segmentationDefinition={`This segmentation groups customers into one of seven different
    life-stages, based on their shopping behaviour and product preferences:`}
    />
  ),
};

const generationAccordion: expanderContent = {
  title: "Generation",
  name: "Generation",
  content: (
    <SegmentationContent
      segmentList={[
        "Generation Z (1997 - Now)",
        "Younger Millenials (1989 - 1996)",
        "Older Millenials (1981 - 1988)",
        "Generation X (1965 - 1980)",
        "Baby Boomers (1946 - 1964)",
        "Traditionalists (1925 - 1945)",
      ]}
      segmentationDefinition={`This segmentation groups customers into one of six different
    generational groups, based on birthdate information:`}
    />
  ),
};

const hmlAccordion: expanderContent = {
  title: "Heavy, medium, light",
  name: "HML",
  content: (
    <SegmentationContent
      segmentIcons={[
        {
          className: styles.hmlIcon,
          icon: LightIcon,
          legend: "Light",
        },
        {
          className: styles.hmlIcon,
          icon: MediumIcon,
          legend: "Medium",
        },
        {
          className: styles.hmlIcon,
          icon: HeavyIcon,
          legend: "Heavy",
        },
      ]}
      segmentList={[
        "Heavy (top 20%)",
        "Medium (20% - 50%)",
        "Light (bottom 50%)",
      ]}
      segmentationDefinition={` This segmentation groups customers based on their total value spend in
    retailer over the previous year. Customers are classified as 'Heavy',
    'Medium' or 'Light' buyers:`}
    />
  ),
};

const segmentationDescription = (
  <div>
    <p>
      Our customer segmentations are identified and grouped through their
      shopping habits and patterns. In {import.meta.env.VITE_TITLE} you have the
      ability to select a segmentation to filter your report.
    </p>
    <p>
      The following segmentations are available for use in{" "}
      {import.meta.env.VITE_TITLE} reports:
    </p>
  </div>
);

export const SegmentationInformation = {
  description: segmentationDescription,
  expanderContent: [
    affluenceAccordion,
    lifeStageAccordion,
    generationAccordion,
    hmlAccordion,
  ],
};
