import { type GainLossData } from "components-ui/src/tables/gains-loss-table/GainsLossTable";
import {
  type GainsAndLossOverviewItem,
  type GainsAndLossOverviewMetric,
} from "../models/GainsAndLossOverviewReportletDto";
import {
  GainsAndLossMetrics,
  ValueTypes,
  MetricIdTypes,
  measureLabels,
} from "../models/GainsAndLossReportValues";
import { getMetrics, filterData } from "./common-utils";

export const getMetricValue = (
  metricGroup: string,
  metrics: GainsAndLossOverviewMetric[]
) => {
  const metricValues = metrics.find(
    (metric) => metric.metricGroup === metricGroup
  )?.metricValues;

  if (!metricValues) {
    return {
      gain: undefined,
      loss: undefined,
      net: undefined,
    };
  }

  const gainsMetric = metricValues.find(
    (metricValue) => metricValue.id === MetricIdTypes.Gains
  )?.data;
  const lossMetric = metricValues.find(
    (metricValue) => metricValue.id === MetricIdTypes.Loss
  )?.data;
  const netMetric = metricValues.find(
    (metricValue) => metricValue.id === MetricIdTypes.Net
  )?.data;

  return {
    gain: gainsMetric,
    loss: lossMetric,
    net: netMetric,
  };
};

export const createDataTableData = (
  salesMetrics: GainsAndLossOverviewMetric[],
  unitsMetrics: GainsAndLossOverviewMetric[],
  volumesMetrics: GainsAndLossOverviewMetric[],
  metricGroups: string[]
): GainLossData[] => {
  const dataTableFields: GainLossData[] = [];

  for (const metricGroup of metricGroups) {
    const salesMetricValue = getMetricValue(metricGroup, salesMetrics);
    const unitsMetricValue = getMetricValue(metricGroup, unitsMetrics);
    const volumeMetricValue = getMetricValue(metricGroup, volumesMetrics);
    dataTableFields.push({
      header: {
        depth:
          metricGroup === GainsAndLossMetrics.TotalNewLostRetailer ||
          metricGroup === GainsAndLossMetrics.TotalNewLostUniverse
            ? 1
            : 0,
        title: metricGroup,
      },
      sales: salesMetricValue,
      units: unitsMetricValue,
      volume: volumeMetricValue,
    });
  }

  return dataTableFields;
};

export const getDataTableData = (
  data: GainsAndLossOverviewItem[]
): GainLossData[] => {
  const salesData = filterData(data, measureLabels.Sales, ValueTypes.Absolute);
  const unitsData = filterData(data, measureLabels.Units, ValueTypes.Absolute);
  const volumeData = filterData(
    data,
    measureLabels.Volume,
    ValueTypes.Absolute
  );

  if (!salesData || !unitsData || !volumeData) {
    return [];
  }

  const metricGroups = [
    GainsAndLossMetrics.NetChange,
    GainsAndLossMetrics.TotalNewLostCustomers,
    GainsAndLossMetrics.TotalNewLostUniverse,
    GainsAndLossMetrics.TotalNewLostRetailer,
    GainsAndLossMetrics.Switching,
    GainsAndLossMetrics.CustomersSpendingMoreLess,
  ];

  const salesMetrics = getMetrics(salesData, metricGroups);

  const unitsMetrics = getMetrics(unitsData, metricGroups);

  const volumeMetrics = getMetrics(volumeData, metricGroups);

  const dataTableData = createDataTableData(
    salesMetrics,
    unitsMetrics,
    volumeMetrics,
    metricGroups
  );

  return dataTableData;
};
