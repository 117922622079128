import { format } from "date-fns";

export const getTimeAgoString = function (
  addedTimeStamp: string,
  compareDate?: Date
): string {
  const oneMinute = 1_000 * 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  const oneWeek = oneDay * 7;

  const dateAdded: Date = new Date(addedTimeStamp);
  const rightNow = compareDate ?? new Date();

  const difference = rightNow.getTime() - dateAdded.getTime();
  if (difference <= oneMinute) {
    return "A few seconds ago";
  }

  if (difference < oneHour) {
    const minsDifference = Math.floor(difference / oneMinute);
    return minsDifference + " min" + (minsDifference === 1 ? "" : "s") + " ago";
  }

  if (difference <= oneDay) {
    const hoursDifference = Math.floor(difference / oneHour);
    return (
      hoursDifference + " hour" + (hoursDifference === 1 ? "" : "s") + " ago"
    );
  }

  if (difference <= oneWeek) {
    const daysDifference = Math.floor(difference / 1_000 / 60 / 60 / 24);
    return daysDifference + " day" + (daysDifference === 1 ? "" : "s") + " ago";
  }

  return dateAdded.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const formatShortDate = (date?: string) => {
  if (!date) {
    return "-";
  }

  return format(new Date(date), "dd MMM yyyy");
};
