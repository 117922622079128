import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import {
  type LocalGroupSelection,
  type LocalHierarchySelection,
} from "@quantium-enterprise/common-ui";
import { useMemo } from "react";
import {
  GroupSelectionSummary,
  HierarchySelectionSummary,
} from "../../parameter-selection-summary";
import { CollapsiblePanel } from "../CollapsiblePanel";
import styles from "./FixedLocationHierarchyFilter.module.scss";

const DEFAULT_GROUP_SELECTIONS: LocalGroupSelection[] = [];

export type FixedLocationHierarchyFilterProps = {
  groupSelections?: LocalGroupSelection[];
  hasShortNamePrefix?: boolean;
  hierarchySelections: LocalHierarchySelection[];
  isCollapsed: boolean;
  title: string;
  toggleCollapsed: () => void;
};

export const FixedLocationHierarchyFilter = ({
  groupSelections = DEFAULT_GROUP_SELECTIONS,
  hasShortNamePrefix,
  hierarchySelections,
  isCollapsed,
  title,
  toggleCollapsed,
}: FixedLocationHierarchyFilterProps) => {
  const isDisabled = useMemo(() => {
    const itemListLength =
      groupSelections.length > 0
        ? hierarchySelections.length + groupSelections.length
        : hierarchySelections.length;

    return itemListLength <= 1;
  }, [hierarchySelections, groupSelections]);

  const itemGroup = useMemo(
    () => (
      <div className={styles.itemGroup}>
        <HierarchySelectionSummary
          hasShortNamePrefix={hasShortNamePrefix}
          items={hierarchySelections.map((selection) => ({
            ...selection.hierarchyItem,
            type: HierarchyItemType.Hierarchy,
          }))}
          maxDepth={hierarchySelections.length}
          moreItemsText=""
        />
        {groupSelections.length > 0 && (
          <GroupSelectionSummary
            items={groupSelections}
            maxDepth={groupSelections.length}
            moreItemsText=""
          />
        )}
      </div>
    ),
    [hierarchySelections, groupSelections, hasShortNamePrefix]
  );

  const summaryPanel = useMemo(
    () => (
      <div>
        Aggregate of
        {hierarchySelections.length > 0 && (
          <>
            <span className={styles.itemCount}>
              {hierarchySelections.length}
            </span>
            {hierarchySelections.length === 1 ? "location" : "locations"}
          </>
        )}
        {hierarchySelections.length > 0 && groupSelections.length > 0 && " and"}
        {groupSelections.length > 0 && (
          <>
            <span className={styles.itemCount}>{groupSelections.length}</span>
            {"location " + (groupSelections.length === 1 ? "group" : "groups")}
          </>
        )}
      </div>
    ),
    [hierarchySelections, groupSelections]
  );

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed && !isDisabled}
      isDisabled={isDisabled}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content: itemGroup,
        summary: summaryPanel,
      }}
    </CollapsiblePanel>
  );
};

export default FixedLocationHierarchyFilter;
