import { type ParameterDto } from "@quantium-enterprise/common-ui";
import CheckboxList from "components-ui/src/checkbox-list/CheckboxList";
import RadioButtonList from "components-ui/src/radio-button-list/RadioButtonList";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { listOptionSelected } from "../../states/report-wizard-slice";
import { ParameterLabel } from "../parameter-label/ParameterLabel";
import styles from "./ListParameter.module.css";
import { ListParameterSelectionType } from "./ListParameterSelectionType";
import {
  getListParameterState,
  type CheckboxSelection,
} from "./ListParameterState";

type ListParameterProps = {
  parameterDto: ParameterDto;
  selectionType: ListParameterSelectionType;
  title?: string;
};

export const ListParameter = ({
  parameterDto,
  selectionType,
  title,
}: ListParameterProps) => {
  const dispatch = useDispatch();

  const listParameterState = useAppSelector(
    getListParameterState(parameterDto.id)
  );
  if (!listParameterState) {
    return null;
  }

  const selectedValues = listParameterState.selections;

  const onChange = (value: string) => {
    dispatch(
      listOptionSelected({
        parameter: parameterDto.id,
        selectedValue: value,
        selectionType,
      })
    );
  };

  const defaultOption = parameterDto.options.find((option) => option.isDefault);
  const disabledMessageDictionary: Record<string, string> = {};
  if (
    defaultOption &&
    selectedValues.length === 1 &&
    selectedValues[0].value === defaultOption.value
  ) {
    disabledMessageDictionary[defaultOption.value] =
      "At least one option must be selected";
  }

  return (
    <>
      {title && (
        <ParameterLabel
          description={parameterDto.description}
          heading={title}
          htmlFor={parameterDto.name}
        />
      )}
      {selectionType === ListParameterSelectionType.Checkbox && (
        <CheckboxList
          className={styles.listParameter}
          disabledValuesWithReason={{}}
          onChange={onChange}
          parameter={parameterDto}
          selectedValues={selectedValues.map(
            (selection: CheckboxSelection) => selection.value
          )}
        />
      )}
      {selectionType === ListParameterSelectionType.CheckboxWithDefault && (
        <CheckboxList
          className={styles.listParameter}
          disabledValuesWithReason={disabledMessageDictionary}
          onChange={onChange}
          parameter={parameterDto}
          selectedValues={selectedValues.map(
            (selection: CheckboxSelection) => selection.value
          )}
        />
      )}
      {selectionType === ListParameterSelectionType.RadioButton && (
        <RadioButtonList
          onChange={onChange}
          parameter={parameterDto}
          selectedValues={selectedValues.map(
            (selection: CheckboxSelection) => selection.value
          )}
        />
      )}
    </>
  );
};
