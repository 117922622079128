import { type ParameterDto } from "@quantium-enterprise/common-ui";

export type ParameterValidation = {
  errors: Record<string, boolean>;
  isValid: boolean;
  maxSelections: number;
  minSelections: number;
};

export const DEFAULT_PARAMETER_VALIDATION: ParameterValidation = {
  errors: {},
  isValid: true,
  maxSelections: 0,
  minSelections: 0,
};

export const isBetweenMinMax = (
  selections: number,
  parameterDto: ParameterDto
) =>
  selections >= parameterDto.attributes.minSelections &&
  selections <= parameterDto.attributes.maxSelections;
