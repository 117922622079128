import {
  type TrackingComponent,
  type TrackingEvent,
  FeatureFlag,
  GenericTrackingProperties,
  ReportType,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import { RepertoireReportlet } from "./components/RepertoireReportlet";
import { RepertoireSidePanel } from "./components/RepertoireSidePanel";
import RepertoireTopDrawer from "./components/repertoire-top-drawer/RepertoireTopDrawer";
import { useLazyGetLocalParametersQuery } from "./services/repertoire-local-parameters-api-slice";
import {
  onMetadataSuccess,
  onReportOpen,
  reset,
  selectLocalParameters,
  selectMetaData,
} from "./services/repertoire-slice";

const RepertoireReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          isTabsEnabled,
          reportId: id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabsEnabled, id]);

  const { data: infoPanelSummary, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      { skip: !activeDivisionName || !id }
    );

  useEffect(() => {
    if (infoPanelSummary) {
      dispatch(
        onMetadataSuccess({
          reportId: infoPanelSummary.id,
          reportName: infoPanelSummary.reportName,
        })
      );
    }
  }, [dispatch, infoPanelSummary]);

  const { localParametersInitialised } = useSelector(selectLocalParameters);

  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, reportId: string) => {
      await getLocalParameters({ divisionName: division, reportId });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  const metaData = useSelector(selectMetaData);

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = useCallback(
    (
      componentHierarchy: TrackingComponent[],
      event: TrackingEvent,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      properties: Record<string, any>
    ) =>
      eventTrackingService.trackEvent(
        componentHierarchy,
        event,
        new GenericTrackingProperties({
          reportName: "Repertoire",
          reportId: metaData.reportId,
          ...properties,
        })
      ),
    [eventTrackingService, metaData]
  );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <RepertoireTopDrawer
            eventTrackingService={eventTrackingServiceWithMetaData}
            renameReport={handleRenameReport}
            reportName={metaData.reportName}
            reportParameters={infoPanelSummary}
            reportType={ReportType.Repertoire}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <RepertoireSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <RepertoireReportlet reportParameters={infoPanelSummary} />
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={infoPanelSummary}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <RepertoireReport />,
  path: "repertoire/:id",
};

export default RepertoireReport;
