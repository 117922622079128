export const HEAT_MAP_COLOURS = [
  "#EB5757",
  "#EF7641",
  "#F39C26",
  "#FEC941",
  "#DFBE13",
  "#B1B422",
  "#6FA639",
  "#219653",
];
