import { type CustomerCrossShopLocalParametersResponseDto } from "../models/CustomerCrossShopLocalParametersResponseDto";
import { customerCrossShopApiSlice } from "./customer-cross-shop-api-slice";

export const customerCrossShopLocalParametersApiSlice =
  customerCrossShopApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        CustomerCrossShopLocalParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useLazyGetLocalParametersQuery } =
  customerCrossShopLocalParametersApiSlice;
