import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { type SortingState, type ColumnDef } from "@tanstack/react-table";
import { TextCell } from "components-ui/src/tables/common/table-cell/TextCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { ColumnType } from "components-ui/src/tables/virtuoso-table/VirtuosoTableHeaderElement";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { type StoreBreakdownRow } from "../models/basket-limits-reportlet-models";
import { selectStoreBreakdownRows } from "../services/basket-limits-slice";
import styles from "./StoreBreakdownTable.module.scss";

const qtyPurchasedColumn = "Qty purchased";
const sortingState: SortingState = [{ id: qtyPurchasedColumn, desc: true }];

const generateColumns: (
  formatter: DeferredFormatFunction
) => Array<ColumnDef<StoreBreakdownRow>> = (
  formatter: DeferredFormatFunction
) => [
  {
    accessorFn: (row) => row.store.name,
    cell: ({ getValue }) => <TextCell value={getValue() as string} />,
    header: "Store name",
    id: "Store name",
    meta: {
      columnType: ColumnType.Text,
    },
  },
  {
    accessorFn: (row) => row.store.itemCode,
    cell: ({ getValue }) => <TextCell value={getValue() as string} />,
    header: "Store number",
    id: "Store number",
    meta: {
      columnType: ColumnType.Text,
    },
  },
  {
    accessorFn: (row) => row.state,
    cell: ({ getValue }) => <TextCell value={getValue() as string} />,
    header: "State",
    id: "State",
    meta: {
      columnType: ColumnType.Text,
    },
  },
  {
    accessorFn: (row) => row.qtyPurchased,
    cell: ({ getValue }) => (
      <ValueCell
        formatter={formatter(MetricTypes.Integer)}
        value={getValue() as number}
      />
    ),
    header: qtyPurchasedColumn,
    id: qtyPurchasedColumn,
    meta: {
      columnType: ColumnType.Numeric,
    },
  },
  {
    accessorFn: (row) => row.valueOfBaskets,
    cell: ({ getValue }) => (
      <ValueCell
        formatter={formatter(MetricTypes.Currency)}
        value={getValue() as number}
      />
    ),
    header: "Value of baskets",
    id: "Value of baskets",
    meta: {
      columnType: ColumnType.Numeric,
    },
  },
  {
    accessorFn: (row) => row.numberOfBaskets,
    cell: ({ getValue }) => (
      <ValueCell
        formatter={formatter(MetricTypes.Integer)}
        value={getValue() as number}
      />
    ),
    header: "Number of baskets",
    id: "Number of baskets",
    meta: {
      columnType: ColumnType.Numeric,
    },
  },
  {
    accessorFn: (row) => row.averagePrice,
    cell: ({ getValue }) => (
      <ValueCell
        formatter={formatter(MetricTypes.Currency)}
        value={getValue() as number}
      />
    ),
    header: "Average price",
    id: "Average price",
    meta: {
      columnType: ColumnType.Numeric,
    },
  },
  {
    accessorFn: (row) => row.percentOfBaskets,
    cell: ({ getValue }) => (
      <ValueCell
        formatter={formatter(MetricTypes.Percentage)}
        value={getValue() as number}
      />
    ),
    header: "% of baskets",
    id: "% of baskets",
    meta: {
      columnType: ColumnType.Numeric,
    },
  },
];

export const StoreBreakdownTable = () => {
  const formatter = useFormatter();
  const storeBreakdownRows = useSelector(selectStoreBreakdownRows);
  const columns: Array<ColumnDef<StoreBreakdownRow>> = useMemo(
    () => generateColumns(formatter),
    [formatter]
  );
  return (
    <div className={styles.storeBreakdownTable}>
      <VirtuosoTableComponent
        columns={columns}
        data={storeBreakdownRows}
        sorting={sortingState}
      />
    </div>
  );
};
