import {
  useEscapeKeyListener,
  useExternalClickListener,
} from "@quantium-enterprise/hooks-ui";
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  IconSize,
} from "@quantium-enterprise/qds-react";
import classnames from "classnames";
import { DecimalScaleLegend } from "components-ui/src/tables/common/legend/ScaleLegend";
import React, {
  type PropsWithChildren,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { EditableField } from "../editable-field/EditableField";
import { type AttributeItem, FocalItem } from "../focal-item/FocalItem";
import { ReportIcon } from "../icons";
import { RenameItem } from "../rename-item/RenameItem";
import { ReportViewContext } from "../report/ReportView";
import styles from "./ReportTopDrawer.module.scss";

export type ReportTopDrawerProps = {
  controls: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
  disableEscape?: boolean;
  itemCount?: number;
  items: AttributeItem[];
  legendTitle?: string;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;
  reportId: string | undefined;
  reportName: string;
  reportType: string;
  selectItemsClicked?: Function;
  toolbarItems?:
    | JSX.Element
    | JSX.Element[]
    | React.ReactNode
    | React.ReactNode[];
};

export const ReportTopDrawer = ({
  itemCount,
  items,
  children,
  controls,
  legendTitle,
  renameReport,
  reportType,
  reportId,
  reportName,
  selectItemsClicked,
  toolbarItems,
  disableEscape = false,
}: PropsWithChildren<ReportTopDrawerProps>) => {
  const [expanded, setExpanded] = useState(false);
  const { handleRename } = RenameItem({
    itemType: "Report",
    renameItem: renameReport,
  });

  const toggleExpanded = (panelExpandedState?: boolean) => {
    setExpanded(panelExpandedState ?? !expanded);
  };

  const focalItemClicked = () => {
    toggleExpanded();
    if (selectItemsClicked) selectItemsClicked();
  };

  const collapse = useCallback(() => setExpanded(false), [setExpanded]);
  const drawerRef = useRef<HTMLDivElement>(null);

  useExternalClickListener(drawerRef, document.body, collapse);
  useEscapeKeyListener(collapse, disableEscape);

  const setShowInfoPanel = useContext(ReportViewContext).setShowInfoPanel;

  return (
    <div className={styles.reportTopDrawerWrapper}>
      <div
        className={classnames(styles.reportTopDrawer, {
          [styles.expanded]: expanded,
        })}
        ref={drawerRef}
      >
        <div className={styles.gridFocalItem}>
          <div className={styles.focalItemContainer}>
            <FocalItem
              isActive={expanded}
              itemCount={itemCount}
              items={items}
              onClick={() => {
                focalItemClicked();
              }}
            />
          </div>
        </div>
        <div className={styles.gridReportNameSection}>
          <div className={styles.reportIconContainer}>
            <ReportIcon className={styles.reportIcon} type={reportType} />
          </div>
          {reportId ? (
            <EditableField
              className={styles.title}
              save={async (value: string) => {
                const data = await handleRename(value, reportId).then(
                  () => true,
                  () => false
                );
                return data;
              }}
              value={reportName}
            />
          ) : (
            <span className={styles.title}>{reportName}</span>
          )}

          <div>
            <Button
              height={ButtonHeight.Small}
              id={styles.summaryButton}
              onClick={() => setShowInfoPanel(true)}
              variant={ButtonVariant.Stealth}
            >
              <Icon
                glyph={IconGlyph.AlertsAndNotificationsInform}
                id={styles.summaryIcon}
                size={IconSize.Large}
                text="Report Summary"
              />
            </Button>
          </div>
        </div>

        {expanded && toolbarItems && (
          <div
            className={classnames(styles.toolbarItems, {
              [styles.expanded]: expanded,
            })}
          >
            <div
              className={classnames(styles.controlsContainer, {
                [styles.closeVisible]: expanded,
                [styles.closeHidden]: !expanded,
              })}
            >
              {toolbarItems}
            </div>
          </div>
        )}

        {expanded && (
          <div
            className={classnames(styles.gridControls, {
              [styles.expanded]: expanded,
            })}
          >
            <div
              className={classnames(styles.controlsContainer, {
                [styles.closeVisible]: expanded,
                [styles.closeHidden]: !expanded,
              })}
            >
              {controls}
              <div
                aria-label="Close"
                className={styles.closeButton}
                onClick={collapse}
                onKeyUp={collapse}
                role="button"
                tabIndex={0}
              >
                <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
              </div>
            </div>
          </div>
        )}

        <div
          className={classnames(styles.gridContent, {
            [styles.expanded]: expanded,
          })}
        >
          <div className={styles.contentContainer}>{children}</div>
        </div>
        <div className={styles.gridLegend}>
          {legendTitle && <DecimalScaleLegend title={legendTitle} />}
        </div>
      </div>
    </div>
  );
};
