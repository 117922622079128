import { ddLog } from "@quantium-enterprise/common-ui";
import {
  type TimeOfDayRequestDto,
  type TimeOfDayResponseDto,
} from "../models/time-of-day-chart";
import { timeOfDayDayOfWeekApiSlice } from "./time-of-day-day-of-week-api-slice";

export const timeOfDayChartDataSlice =
  timeOfDayDayOfWeekApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getTimeOfDayChartData: builder.query<
        TimeOfDayResponseDto,
        { divisionName: string; requestPayload: TimeOfDayRequestDto }
      >({
        async onQueryStarted(argument, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch {
            ddLog("Error fetching GetTimeOfDayData data", {}, "error");
          }
        },
        query: ({ divisionName, requestPayload }) => ({
          body: requestPayload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTimeOfDayData/${divisionName}`,
        }),
      }),
    }),
  });

export const { useGetTimeOfDayChartDataQuery } = timeOfDayChartDataSlice;
