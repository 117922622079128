import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import {
  type TableSearchRequestDto,
  type InitialTableRequestDto,
  type ReportDataTableRequestDto,
  type TableChildrenRequestDto,
  type ExportFocalItemRequest,
} from "../models/CustomerProfilingReportTableRequestDto";
import {
  type TableResponseDto,
  type TableChildrenResponseDto,
} from "../models/CustomerProfilingReportTableResponseDto";
import { customerProfilingApiSlice } from "./customer-profiling-api-slice";

export const customerProfilingDataTableApiSlice =
  customerProfilingApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getInitialTable: builder.query<
        TableResponseDto,
        { divisionName: string; payload: InitialTableRequestDto }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetInitialTable/${divisionName}`,
        }),
      }),
      getReportDataTable: builder.query<
        TableResponseDto,
        { divisionName: string; payload: ReportDataTableRequestDto }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetReportTableData/${divisionName}`,
        }),
      }),
      getTableChildren: builder.query<
        TableChildrenResponseDto,
        { divisionName: string; payload: TableChildrenRequestDto }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetTableChildren/${divisionName}`,
        }),
      }),
      getSearchResultsTable: builder.query<
        TableResponseDto,
        { divisionName: string; payload: TableSearchRequestDto }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `GetTableSearchData/${divisionName}`,
        }),
      }),
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportFocalItemRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetExportData/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `customer profiling focal item csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const {
  useGetInitialTableQuery,
  useLazyGetReportDataTableQuery,
  useLazyGetTableChildrenQuery,
  useGetSearchResultsTableQuery,
  useLazyDownloadTableQuery,
} = customerProfilingDataTableApiSlice;
