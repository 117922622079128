import { z } from "zod";
import { type CustomerGroupStatus, type CustomerGroupType } from "../enums";
import { type ActivationGuidelineStatus } from "../enums/ActivationGuidelineStatus";
import {
  ParameterGroupSelectionDtoSchema,
  type ParameterGroupSelectionDto,
} from "./parameter-group-selection-dto";

export type CustomerGroupDto = {
  activationGuidelineStatus?: ActivationGuidelineStatus;
  activationGuidelinesRefreshDateUtc?: string;
  createDateUtc: string;
  id: string;
  name: string;
  parameters: ParameterGroupSelectionDto[];
  refreshDateUtc?: string;
  segments: SegmentDto[];
  status: CustomerGroupStatus;
  type: CustomerGroupType;
  updateDateUtc: string;
  userId: string;
};

export type CustomerGroupTypeDto = {
  customerGroupType: string;
  description?: string;
  disabled: boolean;
  displayName: string;
};

export type SegmentDto = {
  activationDate?: string;
  activationNotes?: string;
  count: number;
  customerGroupCreatedDate?: string;
  customerGroupId: string;
  customerGroupName?: string;
  customerGroupTypeName?: string;
  customerIds?: string[];
  key: string;
  lastRefreshedDate?: string;
  maximumValue?: number;
  minimumValue?: number;
  name: string;
  ordinal: number;
  ownerId?: string;
  segmentGuidelines?: SegmentGuideline[];
  segmentId: number;
  stagedActivationDate?: string;
};

export type CustomerGroupDtoWithDisplayName = CustomerGroupDto & {
  typeDisplayName: string;
};

export type CustomerGroupWithSharingDto = CustomerGroupDtoWithDisplayName & {
  sharedByUserId?: string;
  sharedWithUserIds?: string[];
};

const SegmentDtoSchema = z.object({
  count: z.number(),
  customerGroupId: z.string(),
  key: z.string(),
  maximumValue: z.number().optional(),
  minimumValue: z.number().optional(),
  name: z.string(),
  ordinal: z.number(),
});

const CustomerGroupDtoSchema = z.object({
  createDateUtc: z.string().datetime(),
  id: z.string(),
  name: z.string(),
  parameters: z.array(ParameterGroupSelectionDtoSchema),
  refreshDateUtc: z.string().datetime().optional(),
  activationGuidelinesRefreshDateUtc: z.string().datetime().optional(),
  segments: z.array(SegmentDtoSchema),
  status: z.unknown(),
  activationGuidelineStatus: z.unknown().optional(),
  type: z.unknown(),
  updateDateUtc: z.string().datetime(),
  userId: z.string(),
});

export const parseCustomerGroupDto = (input: unknown) =>
  CustomerGroupDtoSchema.parse(input);

export type RenameCustomerGroupRequestDto = {
  groupId: string;
  proposedName: string;
};

export type SegmentStagingDto = {
  segmentKey: string;
  stage: boolean;
};

export type UpdateStagingRequestDto = {
  groupId: string;
  segmentKeys: SegmentStagingDto[];
};

export type UpdateActivationRequestDto = {
  activate: boolean;
  groupId: string;
  segmentKey: string;
};

export type SegmentGuideline = {
  guidelineKey: string;
  result: string;
  valid: boolean;
};

export type RunSegmentGuidelinesRequestDto = {
  customerGroupId: string;
};

export type CombinedSegmentDto = {
  groupId?: string;
  groupName: string;
  groupType?: string;
  refreshDate?: string;
  segmentKey?: string;
  segmentName: string;
  segmentationId?: string;
};

export type UpdateActivationNotesRequestDto = {
  activateNotes: string;
  groupId: string;
  segmentKey: string;
};
