import {
  type ParameterDependency,
  type ParameterGroupDto,
} from "@quantium-enterprise/common-ui";
import ErrorBanner from "components-ui/src/error-banner/ErrorBanner";
import InfoBanner from "components-ui/src/error-banner/InfoBanner";
import WarningBanner from "components-ui/src/error-banner/WarningBanner";
import { useSelector } from "react-redux";
import { type ParameterState } from "../../../states/ParameterState";
import { type RootState } from "../../../store";

const getInvalidDependencies = (
  parameterGroupDto: ParameterGroupDto,
  parameterStates: Record<string, ParameterState>
) => {
  let dependencies: ParameterDependency[] = [];
  for (const parameter of parameterGroupDto.parameters) {
    if (parameter.attributes.dependency) {
      dependencies = [...dependencies, parameter.attributes.dependency];
    }
  }

  return dependencies.length > 0
    ? dependencies.filter((dp) => !parameterStates[dp.parameterId].isValid)
    : [];
};

export type ParameterGroupAlertsProps = {
  className?: string;
  parameterGroupDto: ParameterGroupDto;
};

export const ParameterGroupAlerts = ({
  parameterGroupDto,
  className,
}: ParameterGroupAlertsProps) => {
  const tabName = parameterGroupDto.label;
  const { tabState, parameterStates, serverError } = useSelector(
    (state: RootState) => ({
      tabState: state.reportParameter.parameterGroups[tabName],
      parameterStates: state.reportParameter.parameters,
      serverError: state.reportParameter.serverError,
    })
  );

  const visitedTabs = useSelector(
    (state: RootState) => state.wizard.visitedTabs
  );

  const invalidDependencies = getInvalidDependencies(
    parameterGroupDto,
    parameterStates
  );

  if (!tabState) {
    return null;
  }

  const errorMessages: JSX.Element[] = [];
  if (visitedTabs[tabName] && tabState.errorMessage) {
    if (typeof tabState.isValid === "boolean" && !tabState.isValid) {
      errorMessages.push(
        <ErrorBanner
          key={tabState.parameters[0]}
          text={tabState.errorMessage[0].message}
        />
      );
    } else if (typeof tabState.isValid !== "boolean") {
      const invalidParameters = tabState.isValid
        .filter((subGroup) => !subGroup.isValid)
        .flatMap((subGroup) => subGroup.parameters);

      const messages = tabState.errorMessage.filter((error) =>
        error.parameters.some((parameter) =>
          invalidParameters.includes(parameter)
        )
      );

      for (const message of messages) {
        errorMessages.push(
          <ErrorBanner key={message.parameters[0]} text={message.message} />
        );
      }
    }
  }

  const isServerError = serverError.isError
    ? serverError.parameters.some((errorParameter) =>
        tabState.parameters.includes(errorParameter)
      )
    : false;

  if (isServerError) {
    errorMessages.push(
      <ErrorBanner key="ServerError" text={serverError.errorMessage} />
    );
  }

  return (
    <div className={className}>
      {errorMessages}

      {invalidDependencies.map(
        (dependency) =>
          dependency.message && (
            <WarningBanner
              key={dependency.parameterId}
              text={dependency.message}
            />
          )
      )}

      {tabState.isWarning && tabState.warningMessage && (
        <WarningBanner
          key={parameterGroupDto.label}
          text={tabState.warningMessage}
        />
      )}

      {tabState.isInfo && tabState.infoMessage && (
        <InfoBanner key={parameterGroupDto.label} text={tabState.infoMessage} />
      )}

      {tabState.isEntitlementsWarning &&
        tabState.entitlementsWarningMessage && (
          <WarningBanner
            key={parameterGroupDto.label}
            text={tabState.entitlementsWarningMessage}
          />
        )}

      {tabState.isParametermissing && tabState.parameterWarningMessage && (
        <WarningBanner
          key={parameterGroupDto.label}
          text={tabState.parameterWarningMessage}
        />
      )}
    </div>
  );
};

export default ParameterGroupAlerts;
