import {
  ddLog,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { type AggregateRankTableResponseDto } from "../models/aggregate-rank-table-models";
import { aggregateRankApiSlice } from "./aggregate-rank-api-slice";

export type AggregateRankTableRequest = {
  attributes: string[];
  hideDeleted: boolean;
  localSelectedValues: LocalSelectedValueDto[];
  paginationProperties?: { pageSize: number; skipRows: number };
  rankedMetrics: Array<{ metric: string; weight: number }>;
  reportId: string;
  searchText?: string;
  sortProperties?: {
    isDescending: boolean;
    isRankColumn: boolean;
    metric?: string;
  };
  unrankedMetrics: string[];
};

export const aggregateRankTableApiSlice = aggregateRankApiSlice.injectEndpoints(
  {
    endpoints: (builder) => ({
      getTableRows: builder.query<
        AggregateRankTableResponseDto,
        { divisionName: string; payload: AggregateRankTableRequest }
      >({
        async onQueryStarted(argument, { queryFulfilled }) {
          // `onStart` side-effect
          try {
            await queryFulfilled;
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTableRows/${divisionName}`,
        }),
      }),
    }),
  }
);

export const { useGetTableRowsQuery, useLazyGetTableRowsQuery } =
  aggregateRankTableApiSlice;
