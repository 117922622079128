import { type HierarchyItemType } from "@quantium-enterprise/common-ui";
import { useEscapeKeyListener } from "@quantium-enterprise/hooks-ui";
import {
  Checkbox,
  FormBlockEditability,
  Spinner,
  SpinnerSize,
} from "@quantium-enterprise/qds-react";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { useMemo } from "react";
import styles from "./ContextMenu.module.css";

export enum ContextMenuType {
  None = 0,
  Menu = 1,
  Tooltip = 2,
}

export type ContextMenuSelectOption = {
  handleOnChange: (addRows: boolean) => void;
  isChecked: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  name: string;
  shortName: string;
};

export type ContextMenuProps = {
  hierarchyItemType: HierarchyItemType;
  resetTimeout?: () => void;
  selectOptions: ContextMenuSelectOption[];
  stopTimeout?: () => void;
};

export type ContextMenuOverlayProps = {
  closeContextMenu: () => void;
};

export type ContextMenuElements = ContextMenuOverlayProps &
  ContextMenuProps & {
    isOpen: boolean;
    openContextMenu: () => void;
    toggleContextMenu: () => void;
  };

export type ContextMenuWrapper = {
  contextMenuElements?: ContextMenuElements;
  contextMenuType: ContextMenuType;
};

const defaultNoop = () => {};

export const ContextMenu = ({
  selectOptions,
  hierarchyItemType,
  stopTimeout = defaultNoop,
  resetTimeout = defaultNoop,
}: ContextMenuProps) => {
  const allOptionsCheckedOrDisabled = useMemo(
    () => selectOptions.every((op) => op.isChecked || op.isDisabled),
    [selectOptions]
  );

  const allOptionsDisabled = useMemo(
    () => selectOptions.every((option) => option.isDisabled),
    [selectOptions]
  );

  const selectAll = () => {
    for (const option of selectOptions) {
      // conditionally calling "handleOnChange", eslint is confused.
      /* eslint-disable @babel/no-unused-expressions */
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      option.isDisabled || option.handleOnChange(!allOptionsCheckedOrDisabled);
      /* eslint-enable @babel/no-unused-expressions */
    }
  };

  const checkboxes = selectOptions.map((option) => (
    <div
      className={styles.contextMenuCheckboxElement}
      key={`cm-checkbox-${option.shortName}`}
    >
      {option.isLoading ? (
        <>
          <Spinner className={styles.loadingSpinner} size={SpinnerSize.Small} />
          <span>
            <HierarchyLevelIcon
              shortName={option.shortName}
              type={hierarchyItemType}
            />{" "}
            {option.name}{" "}
          </span>
        </>
      ) : (
        <Checkbox
          checked={option.isChecked}
          editability={
            option.isDisabled
              ? FormBlockEditability.Disabled
              : FormBlockEditability.Editable
          }
          label={
            <span>
              <HierarchyLevelIcon
                shortName={option.shortName}
                type={hierarchyItemType}
              />{" "}
              {option.name}{" "}
            </span>
          }
          name={option.shortName}
          onChange={() => option.handleOnChange(!option.isChecked)}
        />
      )}
    </div>
  ));

  return (
    <div
      className={styles.contextMenu}
      onMouseEnter={stopTimeout}
      onMouseLeave={resetTimeout}
    >
      <div className={styles.contextMenuCaption}>
        Included Hierarchy Levels:
      </div>
      {selectOptions.length > 1 && (
        <Checkbox
          checked={Boolean(allOptionsCheckedOrDisabled && !allOptionsDisabled)}
          editability={
            allOptionsDisabled
              ? FormBlockEditability.Disabled
              : FormBlockEditability.Editable
          }
          label="Select All"
          name="cm-select-all"
          onChange={selectAll}
        />
      )}
      {checkboxes}
    </div>
  );
};

export const ContextMenuOverlay = ({
  closeContextMenu,
}: ContextMenuOverlayProps) => {
  useEscapeKeyListener(closeContextMenu);
  return (
    <div
      className={styles.overlay}
      onClick={closeContextMenu}
      role="presentation"
    />
  );
};
