import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  useDivision,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { Checkbox, FormBlockEditability } from "@quantium-enterprise/qds-react";
import {
  type HeaderContext,
  type FilterFnOption,
  type ColumnDef,
  type CellContext,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import {
  excludeDeletedLineFilter,
  DELETED_LINE_FILTER_COLUMN_ID,
  DEFAULT_DELETED_LINE_FILTER_VALUE,
} from "components-ui/src/tables/common/filters/excludeDeletedLineFilter";
import { selectedItemsOnlyFilter } from "components-ui/src/tables/common/filters/selectedItemsOnlyFilter";
import { ScaleLegendColors } from "components-ui/src/tables/common/legend/ScaleLegend";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import {
  GraphicsCell,
  GraphicsCellMetricType,
} from "components-ui/src/tables/common/table-cell/GraphicsCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { type RootState } from "../../../store";
import {
  type ProductSubstitutabilityHierarchyProductData,
  type ProductSubstitutabilityHierarchyProductLoadMoreRow,
  type ProductSubstitutabilityHierarchyProductRow,
} from "../../models/ProductSubstitutabilityHierarchyProductData";
import { type TableRowsRequestDto } from "../../models/ProductSubstitutabilityTableRowsRequestDto";
import { useLazyGetTableRowsQuery } from "../../services/product-substitutability-data-table-api-slice";
import {
  onLoadMoreResponseReceived,
  onSearchQueryChange,
  selectPagination,
  selectRowSelection,
  setFocalItems,
  unsetFocalItems,
} from "../../services/product-substitutability-slice";
import { calculatePercentile } from "../../utils/calculatePercentile";
import styles from "./ProductSubstitutabilityTopDrawerProductTable.module.css";

const GRAPHIC_CELL_FORMATTER_COLUMNS = ["Uniqueness"];

const isLoadMore = (
  panel: ProductSubstitutabilityHierarchyProductRow
): panel is ProductSubstitutabilityHierarchyProductLoadMoreRow =>
  Object.prototype.hasOwnProperty.call(panel, "loadMoreParentRow");

const getRowId = (row: ProductSubstitutabilityHierarchyProductRow) => {
  if (isLoadMore(row)) {
    return `${row.loadMoreParentRow.item.itemCode}-load-more`;
  }

  return row.item.itemCode;
};

export type ProductSubstitutabilityTopDrawerProductTableProps = {
  isQuerySuccess: boolean;
};

export const ProductSubstitutabilityTopDrawerProductTable = ({
  isQuerySuccess,
}: ProductSubstitutabilityTopDrawerProductTableProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();
  const formatter = useFormatter();

  const pagination = useSelector(selectPagination);
  const rowSelection = useSelector(selectRowSelection);

  const {
    attributes,
    columnVisibility,
    metrics,
    searchQuery,
    tableRows,
    columnFilters,
  } = useSelector((state: RootState) => ({
    attributes: state.productSubstitutability.attributes,
    columnVisibility: state.productSubstitutability.columnVisibility,
    metrics: state.productSubstitutability.focalItemTableMetrics,
    searchQuery: state.productSubstitutability.searchQuery,
    tableRows: state.productSubstitutability.topDrawerTableRows,
    columnFilters: state.productSubstitutability.columnFilters,
  }));

  const [getTableRows] = useLazyGetTableRowsQuery();

  const fetchData = useCallback(
    async (division: string, payload: TableRowsRequestDto) => {
      const response = await getTableRows({ division, payload });
      if (response.data) {
        dispatch(onLoadMoreResponseReceived(response.data));
      }
    },
    [getTableRows, dispatch]
  );

  const onMoreClickedHandler = useCallback(async () => {
    const payload: TableRowsRequestDto = {
      includeMeta: false,
      offset: pagination.offset,
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      reportId: reportId ?? "",
      searchQuery,
    };

    await fetchData(divisionName, payload);
  }, [pagination, reportId, fetchData, divisionName, searchQuery]);

  const onSearchChange = useCallback(
    (value: string) => dispatch(onSearchQueryChange(value)),
    [dispatch]
  );

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return pagination.totalCount;
  }, [pagination.totalCount, searchQuery]);

  const filteredRows = useMemo(() => {
    if (
      columnFilters.some(
        (item) =>
          item.id === DELETED_LINE_FILTER_COLUMN_ID &&
          item.value === DEFAULT_DELETED_LINE_FILTER_VALUE
      )
    ) {
      return tableRows.filter(
        (row) => row.attributeValues[3] === DEFAULT_DELETED_LINE_FILTER_VALUE
      );
    }

    return tableRows;
  }, [tableRows, columnFilters]);

  const memoizedSearchBox = useCallback(
    ({
      table,
    }: HeaderContext<ProductSubstitutabilityHierarchyProductData, unknown>) => {
      /*
       * Tanstack table has built-in row.getIsSomeRowsSelected method,
       * however, this method has a known bug
       * hence we're using our own extention.
       */

      const checkboxEditable = filteredRows.some(
        (row) => rowSelection[row.item.itemCode] === true
      );
      const checkboxChecked = table.getIsAllRowsSelected();
      const checkboxIndeterminate =
        filteredRows.some((row) => rowSelection[row.item.itemCode] === true) &&
        !checkboxChecked;

      return (
        <div className={styles.searchbox}>
          <Checkbox
            checked={checkboxChecked}
            editability={
              checkboxEditable
                ? FormBlockEditability.Editable
                : FormBlockEditability.Disabled
            }
            indeterminate={checkboxIndeterminate}
            label=""
            name="all"
            onChange={() => dispatch(unsetFocalItems(filteredRows))}
          />
          <SearchBox
            enableDebounce
            onChange={onSearchChange}
            placeholder="Type to search"
            resultCount={matchesFound}
            searchQuery={searchQuery}
          />
        </div>
      );
    },
    [
      onSearchChange,
      matchesFound,
      searchQuery,
      dispatch,
      rowSelection,
      filteredRows,
    ]
  );

  const displayEmptySearch: boolean =
    isQuerySuccess && searchQuery.length > 0 && tableRows.length === 0;

  const createNameCell = useCallback(
    ({
      row,
    }: CellContext<ProductSubstitutabilityHierarchyProductData, unknown>) => (
      <ExpandableNameCell
        canExpand={false}
        depth={0}
        handleToggleExpanded={row.getToggleExpandedHandler()}
        handleToggleSelected={(event) => {
          row.getToggleSelectedHandler()(event);
          dispatch(
            setFocalItems({
              isSelected: row.getIsSelected(),
              selectedItem: row.original.item,
            })
          );
        }}
        isExpanded={false}
        isSelected={row.getIsSelected()}
        name={row.original.item.name}
        shortName={row.original.item.shortName}
        type={HierarchyItemType.Hierarchy}
        value={row.original.item.name}
      />
    ),
    [dispatch]
  );

  const cellFormatterAttribute = useCallback(
    (
      {
        row,
      }: CellContext<ProductSubstitutabilityHierarchyProductData, unknown>,
      index: number
    ): React.ReactNode => (
      <ValueCell
        formatter={formatter(MetricTypes.String)}
        value={row.original.attributeValues[index]}
      />
    ),
    [formatter]
  );

  const cellFormatterMetric = useCallback(
    (
      {
        row,
      }: CellContext<ProductSubstitutabilityHierarchyProductData, unknown>,
      index: number,
      format: string
    ): React.ReactNode => (
      <ValueCell
        formatter={formatter(format)}
        value={row.original.metricValues[index]}
      />
    ),
    [formatter]
  );

  const cellFormatterMetricWithGraphic = useCallback(
    (
      {
        row,
      }: CellContext<ProductSubstitutabilityHierarchyProductData, unknown>,
      index: number,
      format: string
    ): React.ReactNode => (
      <GraphicsCell
        color={
          ScaleLegendColors[
            calculatePercentile(row.original.metricValues[index])
          ]
        }
        formatter={formatter(format)}
        type={GraphicsCellMetricType.Percentile}
        value={row.original.metricValues[index]}
      />
    ),
    [formatter]
  );

  const metricColumnsDefs = React.useMemo<
    Array<ColumnDef<ProductSubstitutabilityHierarchyProductData>>
  >(() => {
    // Attributes
    const attributesColumns: Array<
      ColumnDef<ProductSubstitutabilityHierarchyProductData>
    > = attributes.map((attribute, index) => {
      let columnConfig: ColumnDef<ProductSubstitutabilityHierarchyProductData> =
        {
          accessorKey: attribute.shortName,
          cell: (
            cellInfo: CellContext<
              ProductSubstitutabilityHierarchyProductData,
              unknown
            >
          ) => cellFormatterAttribute(cellInfo, index),
          enableHiding: false,
          enableResizing: false,
          enableSorting: false,
          header: attribute.displayName,
          id: attribute.shortName,
        };

      if (attribute.shortName === DELETED_LINE_FILTER_COLUMN_ID) {
        columnConfig = {
          ...columnConfig,
          filterFn:
            excludeDeletedLineFilter as FilterFnOption<ProductSubstitutabilityHierarchyProductData>,
        };
      }

      return columnConfig;
    });

    // Metrics
    const metricsColumns: Array<
      ColumnDef<ProductSubstitutabilityHierarchyProductData>
    > = metrics.map((metric, index) => ({
      accessorKey: metric.displayName,
      cell: (cellInfo) =>
        GRAPHIC_CELL_FORMATTER_COLUMNS.includes(metric.displayName)
          ? cellFormatterMetricWithGraphic(cellInfo, index, metric.format)
          : cellFormatterMetric(cellInfo, index, metric.format),
      enableHiding: false,
      enableResizing: false,
      enableSorting: false,
      header: metric.displayName,
      id: index + metric.displayName,
    }));

    return attributesColumns.concat(metricsColumns);
  }, [
    attributes,
    cellFormatterAttribute,
    cellFormatterMetric,
    cellFormatterMetricWithGraphic,
    metrics,
  ]);

  const columnDefs: Array<
    ColumnDef<ProductSubstitutabilityHierarchyProductData>
  > = useMemo(
    () => [
      {
        accessorKey: "name",
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        filterFn:
          selectedItemsOnlyFilter as FilterFnOption<ProductSubstitutabilityHierarchyProductData>,
        header: memoizedSearchBox,
      },
      ...metricColumnsDefs,
    ],
    [metricColumnsDefs, createNameCell, memoizedSearchBox]
  );

  return (
    <ReportHierarchyTableWrapper isSuccess={isQuerySuccess}>
      <VirtuosoTableComponent
        columnFilters={columnFilters}
        columnVisibility={columnVisibility}
        columns={columnDefs}
        data={tableRows}
        enableRowFiltering
        enableSorting
        getRowId={getRowId}
        handleMoreClicked={onMoreClickedHandler}
        pinFirstColumn
        rowExpandedState={{ "0": true }}
        rowSelectionState={rowSelection}
        sorting={searchQuery ? [] : [{ id: "0Uniqueness", desc: true }]}
      />

      {displayEmptySearch && (
        <div className={styles.emptySearch}>
          <EmptySearch />
        </div>
      )}
    </ReportHierarchyTableWrapper>
  );
};
