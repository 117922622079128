import {
  myReportsApi,
  savedParametersApi,
  reportParametersWebApi,
  reportRerunApi,
  availableReportsApi,
  userApi,
  sseApi,
  foldersApi,
} from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import generatedReportsReducer from "../../common/src/services/my-reports-slice";
import wizardReducer from "../../report-parameters/src/common/wizard/wizard-slice";
import reportParametersReducer from "../../report-parameters/src/states/report-wizard-slice";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [myReportsApi.reducerPath]: myReportsApi.reducer,
  [foldersApi.reducerPath]: foldersApi.reducer,
  [reportParametersWebApi.reducerPath]: reportParametersWebApi.reducer,
  [reportRerunApi.reducerPath]: reportRerunApi.reducer,
  [savedParametersApi.reducerPath]: savedParametersApi.reducer,
  [availableReportsApi.reducerPath]: availableReportsApi.reducer,
  generatedReports: generatedReportsReducer,
  [sseApi.reducerPath]: sseApi.reducer,
  reportParameter: reportParametersReducer,
  wizard: wizardReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "my-reports" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        userApi.middleware,
        foldersApi.middleware,
        myReportsApi.middleware,
        savedParametersApi.middleware,
        reportParametersWebApi.middleware,
        reportRerunApi.middleware,
        availableReportsApi.middleware,
        sseApi.middleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
