import { FormInputHeight } from "@quantium-enterprise/qds-react";
import { ButtonToggleGroup } from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportletFilterWrapper } from "../../../common/components/ReportletFilterWrapper";
import { type RootState } from "../../../store";
import {
  ChartSeriesLabels,
  FilterLabels,
} from "../../models/CustomerProfilingSegmentsOverTimeReportlet";
import {
  onChartSeriesToggleSelectionChange,
  onFocalItemOptionSelectionChange,
  onSegmentOptionSelectionChange,
  onSegmentationToggleSelectionChange,
  selectFocalItemFilter,
  selectFocalItems,
  selectSegmentation,
} from "../../services/customer-profiling-slice";
import styles from "./CustomerProfilingSegmentsOverTimeReportletFilters.module.css";

export const DEFAULT_ALL_OPTION: PanelOption = { label: "All", value: "All" };

export const CustomerProfilingSegmentsOverTimeReportletFilters = () => {
  const dispatch = useDispatch();

  const focalItems = useSelector(selectFocalItems);
  const segmentation = useSelector(selectSegmentation);

  const {
    reportSegmentationMeta,
    segmentsFilter,
    chartSeriesActiveButtonIndex,
    segmentationActiveButtonIndex,
  } = useSelector((state: RootState) => ({
    reportSegmentationMeta: state.customerProfiling.reportSegmentationMeta,
    focalItemFilter:
      state.customerProfiling.segmentsOverTimeFilters.focalItemFilter,
    segmentsFilter:
      state.customerProfiling.segmentsOverTimeFilters.segmentsFilter,
    chartSeriesActiveButtonIndex:
      state.customerProfiling.segmentsOverTimeFilters
        .chartSeriesActiveButtonIndex,
    segmentationActiveButtonIndex:
      state.customerProfiling.segmentsOverTimeFilters
        .segmentationActiveButtonIndex,
  }));

  const focalItemFilter = useSelector(selectFocalItemFilter);

  const focalItemDropdownOptions: PanelOption[] = useMemo(
    () =>
      focalItemFilter.options.map((item) => ({
        label: `(${hierarchyLevelDisplayLabel(item.shortName)}) ${item.name}`,
        value: item.nodeNumber.toString(),
      })),
    [focalItemFilter]
  );

  const segmentsDropdownOptions: PanelOption[] = useMemo(
    () =>
      segmentsFilter.options.map((item) => ({
        label: item.segment,
        value: item.segment,
      })),
    [segmentsFilter.options]
  );

  const chartSeriesToggleHandler = useCallback(
    (index: number) => {
      dispatch(onChartSeriesToggleSelectionChange(index));
    },
    [dispatch]
  );

  const segmentationToggleHandler = useCallback(
    (index: number) => {
      dispatch(onSegmentationToggleSelectionChange(index));
    },
    [dispatch]
  );

  const focalItemDropdownSelectionHandler = useCallback(
    (value: PanelOption) => {
      dispatch(onFocalItemOptionSelectionChange(Number(value.value)));
    },
    [dispatch]
  );

  const segmentDropdownSelectionHandler = useCallback(
    (segmentOption: PanelOption) => {
      dispatch(onSegmentOptionSelectionChange(segmentOption.value as string));
    },
    [dispatch]
  );

  const showMultiItemFilters = useMemo(
    () => focalItems.length > 1,
    [focalItems]
  );

  const showSegmentationFilter = useMemo(
    () => segmentation.length > 1,
    [segmentation]
  );

  const segmentationButtons = useMemo(
    () =>
      reportSegmentationMeta
        .filter((segmentationMeta) =>
          segmentation.some(
            (seg) => segmentationMeta.id === (seg.value as string)
          )
        )
        .map((segmentationMeta, index) => ({
          displayText: segmentationMeta.name,
          id: index,
        })),
    [segmentation, reportSegmentationMeta]
  );

  const disableFocalItemFilter = useMemo(() => {
    const selection =
      Object.values(ChartSeriesLabels)[chartSeriesActiveButtonIndex];
    return focalItems.length === 0 || selection === ChartSeriesLabels.FocalItem;
  }, [chartSeriesActiveButtonIndex, focalItems.length]);

  const disableCustomerSegmentFilter = useMemo(() => {
    const selection =
      Object.values(ChartSeriesLabels)[chartSeriesActiveButtonIndex];
    return (
      focalItems.length === 0 ||
      selection === ChartSeriesLabels.CustomerSegments
    );
  }, [chartSeriesActiveButtonIndex, focalItems.length]);

  return (
    <div className={styles.cpSegmentsOverTimeReportletFiltersContainer}>
      {showMultiItemFilters ? (
        <ReportletFilterWrapper label={FilterLabels.ChartSeries}>
          <ButtonToggleGroup
            buttonSelected={chartSeriesActiveButtonIndex}
            buttons={Object.values(ChartSeriesLabels).map((label, index) => ({
              displayText: label,
              id: index,
            }))}
            setButtonSelected={chartSeriesToggleHandler}
          />
        </ReportletFilterWrapper>
      ) : null}

      {showSegmentationFilter ? (
        <ReportletFilterWrapper label={FilterLabels.Segmentation}>
          <ButtonToggleGroup
            buttonSelected={segmentationActiveButtonIndex}
            buttons={segmentationButtons}
            setButtonSelected={segmentationToggleHandler}
          />
        </ReportletFilterWrapper>
      ) : null}

      {showMultiItemFilters ? (
        <ReportletFilterWrapper label={FilterLabels.FocalItem}>
          <SingleSelectDropdown
            height={FormInputHeight.XSmall}
            isDisabled={disableFocalItemFilter}
            onSelection={focalItemDropdownSelectionHandler}
            selectOptions={
              disableFocalItemFilter
                ? [DEFAULT_ALL_OPTION]
                : focalItemDropdownOptions
            }
            selectedValue={focalItemFilter.activeOption?.nodeNumber.toString()}
            title="focalItem"
          />
        </ReportletFilterWrapper>
      ) : null}

      {showMultiItemFilters ? (
        <ReportletFilterWrapper label={FilterLabels.CustomerSegment}>
          <SingleSelectDropdown
            height={FormInputHeight.XSmall}
            isDisabled={disableCustomerSegmentFilter}
            onSelection={segmentDropdownSelectionHandler}
            selectOptions={
              disableCustomerSegmentFilter
                ? [DEFAULT_ALL_OPTION]
                : segmentsDropdownOptions
            }
            selectedValue={segmentsFilter.activeOption?.segment}
            title="segments"
          />
        </ReportletFilterWrapper>
      ) : null}
    </div>
  );
};
