type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const HeavyMediumLightIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--cg-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--cg-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--cg-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Heavy / medium / light customer group"}</title>
      <path
        clipRule="evenodd"
        d="M15.4215 4.50159L14.2593 2.38856C14.1275 2.1489 13.8757 2 13.6022 2H10.6484C10.374 2 10.1214 2.14992 9.98999 2.39086L8.83868 4.50159H8.26244C7.76071 4.50159 7.39591 4.97807 7.52682 5.46242L9.01996 10.9871C9.10979 11.3194 9.41131 11.5503 9.75559 11.5503H14.4944C14.8387 11.5503 15.1402 11.3194 15.23 10.9871L16.7232 5.46242C16.8541 4.97807 16.4893 4.50159 15.9876 4.50159H15.4215ZM9.97777 4.50159L10.7968 3H13.4544L14.2802 4.50159H9.97777ZM13.0245 9.07372C13.0245 9.59978 12.598 10.0262 12.072 10.0262C11.5459 10.0262 11.1195 9.59978 11.1195 9.07372C11.1195 8.54765 11.5459 8.12119 12.072 8.12119C12.598 8.12119 13.0245 8.54765 13.0245 9.07372Z"
        fill={colour2}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.35233 14.9766L4.48999 12.8909C4.62141 12.6499 4.87395 12.5 5.14841 12.5H8.10218C8.3757 12.5 8.62753 12.6489 8.75934 12.8886L9.90774 14.9766H10.4876C10.9893 14.9766 11.3541 15.453 11.2232 15.9374L9.73004 21.462C9.64022 21.7944 9.3387 22.0252 8.99442 22.0252H4.25559C3.91131 22.0252 3.60979 21.7944 3.51996 21.462L2.02682 15.9374C1.89591 15.453 2.26071 14.9766 2.76244 14.9766H3.35233ZM4.49142 14.9766H8.76647L7.95436 13.5H5.29682L4.49142 14.9766ZM5.23862 20.5012C5.76469 20.5012 6.19114 20.0747 6.19114 19.5487C6.19114 19.0226 5.76469 18.5962 5.23862 18.5962C4.71256 18.5962 4.2861 19.0226 4.2861 19.5487C4.2861 20.0747 4.71256 20.5012 5.23862 20.5012ZM8.85807 19.5487C8.85807 20.0747 8.43161 20.5012 7.90555 20.5012C7.37949 20.5012 6.95303 20.0747 6.95303 19.5487C6.95303 19.0226 7.37949 18.5962 7.90555 18.5962C8.43161 18.5962 8.85807 19.0226 8.85807 19.5487Z"
        fill={colour1}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.8984 12.5C15.624 12.5 15.3714 12.6499 15.24 12.8909L14.1023 14.9766H13.5124C13.0107 14.9766 12.6459 15.453 12.7768 15.9374L14.27 21.462C14.3598 21.7944 14.6613 22.0252 15.0056 22.0252H19.7444C20.0887 22.0252 20.3902 21.7944 20.48 21.462L21.9732 15.9374C22.1041 15.453 21.7393 14.9766 21.2376 14.9766H20.6577L19.5093 12.8886C19.3775 12.6489 19.1257 12.5 18.8522 12.5H15.8984ZM15.2414 14.9766L16.0468 13.5H18.7044L19.5165 14.9766H15.2414ZM16.9411 19.5487C16.9411 20.0747 16.5147 20.5012 15.9886 20.5012C15.4626 20.5012 15.0361 20.0747 15.0361 19.5487C15.0361 19.0226 15.4626 18.5962 15.9886 18.5962C16.5147 18.5962 16.9411 19.0226 16.9411 19.5487ZM17.322 18.2151C17.8481 18.2151 18.2745 17.7886 18.2745 17.2625C18.2745 16.7365 17.8481 16.31 17.322 16.31C16.796 16.31 16.3695 16.7365 16.3695 17.2625C16.3695 17.7886 16.796 18.2151 17.322 18.2151ZM19.6081 19.5487C19.6081 20.0747 19.1816 20.5012 18.6555 20.5012C18.1295 20.5012 17.703 20.0747 17.703 19.5487C17.703 19.0226 18.1295 18.5962 18.6555 18.5962C19.1816 18.5962 19.6081 19.0226 19.6081 19.5487Z"
        fill={colour3}
        fillRule="evenodd"
      />
    </svg>
  );
};
