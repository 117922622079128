import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type ContributionItem } from "../models/ContributionChartDriverDto";

export const getExportColumnHeader =
  (currencySymbol: string, isChartData: boolean) =>
  ({ format, label }: ContributionItem, index: number) => {
    let columnHeader = label;
    if (format === MetricTypes.Currency) {
      columnHeader = `${
        index === 0 && isChartData ? label + " growth" : label
      } (${currencySymbol})`;
    }

    return columnHeader;
  };
