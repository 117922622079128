import { format } from "date-fns";
import { type FocusPeriodOption } from "../models/FocusPeriodOption";

const formatDate = (dateString: string): string => {
  const year = Number.parseInt(dateString.slice(0, 4), 10);
  const month = Number.parseInt(dateString.slice(4, 6), 10) - 1;
  const day = Number.parseInt(dateString.slice(6, 8), 10);

  const date = new Date(year, month, day);
  return format(date, "dd MMM yy");
};

export const selectFocusPeriodText = (
  focusPeriod: FocusPeriodOption | undefined
) => {
  if (!focusPeriod?.startDate || !focusPeriod.endDate) {
    return "";
  }

  const formattedStartDate = formatDate(focusPeriod.startDate);
  const formattedEndDate = formatDate(focusPeriod.endDate);

  return `${formattedStartDate} - ${formattedEndDate}`;
};
