import { type CustomerProfilingMultiSegmentationReportletData } from "../../models/CustomerProfilingMultiSegmentationResponseDto";
import { type CustomerProfilingSingleSegmentationMetricValue } from "../../models/CustomerProfilingSingleSegmentationResponseDto";
import { getExportColumnHeader } from "../../utils/csvTransformUtils";

export const csvSingleSegmentationTransformation = (
  response: CustomerProfilingSingleSegmentationMetricValue | undefined,
  currencySymbol: string,
  focalItemLabels: string[]
) => {
  const columnHeaders: string[] = [];
  const csvData = [columnHeaders];
  if (response) {
    const focalItems = focalItemLabels;
    columnHeaders.push(`Focal item${focalItems.length > 1 ? "s" : ""}`);
    const segments = response.items[0].data.map((x) => x.name);
    const segmentData = segments.map((segment) =>
      response.items.map(
        (item) =>
          item.data.find((data) => data.name === segment)?.value?.toString() ??
          "-"
      )
    );
    columnHeaders.push(
      ...segments.map(
        getExportColumnHeader(
          currencySymbol,
          response.format,
          response.displayName
        )
      )
    );
    for (const [index, focalItem] of focalItems.entries()) {
      const row = [focalItem, ...segmentData.map((data) => data[index])];
      csvData.push(row);
    }
  }

  return csvData;
};

export const csvMultiSegmentationTransformation = (
  response: CustomerProfilingMultiSegmentationReportletData | undefined,
  currencySymbol: string
) => {
  const columnHeaders = ["Segments"];
  const csvData = [columnHeaders];
  if (response) {
    const segmentsA = response.segmentationBItems[0].segmentationAData.map(
      (x) => x.segmentName
    );
    const segmentsB = response.segmentationBItems.map(
      (item) => item.segmentName
    );
    const segmentData = segmentsA.map((segment) =>
      response.segmentationBItems.map(
        (item) =>
          item.segmentationAData
            .find((data) => data.segmentName === segment)
            ?.value?.toString() ?? "-"
      )
    );
    columnHeaders.push(
      ...segmentsA.map(
        getExportColumnHeader(
          currencySymbol,
          response.format,
          response.displayName
        )
      )
    );

    for (const [index, element] of segmentsB.entries()) {
      const row = [element, ...segmentData.map((data) => data[index])];
      csvData.push(row);
    }
  }

  return csvData;
};
