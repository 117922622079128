import { ddLog } from "@quantium-enterprise/common-ui";
import { type RequestDto } from "../models/reportlet-request";
import { type PricingLaddersResponseDto } from "../models/reportlet-response";
import { pricingLaddersApiSlice } from "./pricing-ladders-api-slice";

export const pricingLaddersReportletDataSlice =
  pricingLaddersApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getPricingLaddersReportlet: builder.query<
        PricingLaddersResponseDto,
        {
          division: string;
          payload: RequestDto;
        }
      >({
        query: ({ division, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetReportletData/${division}`,
        }),
        async onQueryStarted(_argument, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error_) {
            let error: Error | undefined;
            if (error_ instanceof Error) {
              error = error_;
            }

            ddLog(
              "Error retrieving chart data for pricing ladder report",
              undefined,
              "error",
              error
            );
          }
        },
      }),
    }),
  });

export const { useLazyGetPricingLaddersReportletQuery } =
  pricingLaddersReportletDataSlice;
