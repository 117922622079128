import { type ReportParametersDto } from "@quantium-enterprise/common-ui";
import {
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
  createRowNameMatchesSearchPredicate,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  useDivision,
  useFlags,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import {
  type Table,
  type Row,
  type RowSelectionState,
  type ColumnDef,
  type SortingState,
  type CellContext,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { GraphicsCell } from "components-ui/src/tables/common/table-cell/GraphicsCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { RepertoireFeatureFlags } from "../../constants/repertoire-feature-flags";
import {
  type RepertoireSidePanelSelections,
  autoSelectValue,
  selectIsAutoSelectValueInvalid,
  onFocalItemSelect,
  onInputValueChange,
  onRepertoireSelect,
  selectFocalItems,
  selectLocalParameters,
  selectMetaData,
  selectRepertoireItems,
  selectTableResponseDto,
} from "../../services/repertoire-slice";
import {
  type RepertoireFocalDataRequest,
  type RepertoireFocalItemRow,
  useLazyGetRepertoireFocalItemDataQuery,
} from "../../services/repertoire-top-drawer-api-slice";
import { getLocalParameterSummary } from "../../utils/export-utils";
import { getUserSelections } from "../../utils/local-parameter-utils";
import LoyaltyThresholdForm from "./LoyaltyThresholdForm";
import RepertoireSelectCell from "./RepertoireSelectCell";
import styles from "./RepertoireTopDrawer.module.css";
import { csvTransformation } from "./csvTransformation";

const MAX_SELECTIONS = 50;

export type RepertoireTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportParameters?: ReportParametersDto;
  reportType: string;
};

const FocalItemAndRepertoireHeader = () => (
  <div className={styles.focalItemSelectHeader}>
    <span>Focal item</span>
    <span>Repertoire</span>
  </div>
);

export const RepertoireTopDrawer = ({
  eventTrackingService,
  renameReport,
  reportType,
  reportName,
  reportParameters,
}: RepertoireTopDrawerProps) => {
  const { name: activeDivisionName } = useDivision();
  const { id } = useParams();
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const [searchQuery, setSearchQuery] = useState("");
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[RepertoireFeatureFlags.ReportExport] ?? false;

  const tableResponse = useSelector(selectTableResponseDto);
  const focalItems = useSelector(selectFocalItems);
  const repertoireItems = useSelector(selectRepertoireItems);
  const { localParametersInitialised, localParameterSelections } = useSelector(
    selectLocalParameters
  );
  const storedSelectValue = useSelector(autoSelectValue);
  const isAutoSelectValueInvalid = useSelector(selectIsAutoSelectValueInvalid);
  const { reportId } = useSelector(selectMetaData);

  const tableRows = useMemo(() => {
    const rowNameMatchesSearch =
      createRowNameMatchesSearchPredicate(searchQuery);
    const updatedTableRows = tableResponse.focalItemData.filter(
      (x) => searchQuery === "" || rowNameMatchesSearch(x.name)
    );
    if (searchQuery.length > 0) {
      return updatedTableRows.sort((a, b) => a.name.localeCompare(b.name));
    }

    return updatedTableRows;
  }, [searchQuery, tableResponse]);

  const displayEmptySearch: boolean =
    searchQuery.length > 0 && tableRows.length === 0;

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return tableRows.length;
  }, [tableRows.length, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.itemHeaderContainer}>
        <div className={styles.itemHeader}>
          <div className={styles.headerHierarchyIcon}>
            <HierarchyLevelIcon
              shortName={tableResponse.levelOfAnalysis}
              type={HierarchyItemType.Hierarchy}
            />
          </div>
          <span className={styles.headerValue}> Item </span>
        </div>
        <SearchBox
          enableDebounce
          onChange={(searchString: string) => {
            setSearchQuery(searchString);
          }}
          placeholder="Type to search"
          resultCount={matchesFound}
          searchQuery={searchQuery}
        />
      </div>
    ),
    [tableResponse.levelOfAnalysis, matchesFound, searchQuery]
  );

  const [
    getFocalItemDataQuery,
    { isFetching: isTableFetching, isLoading: isTableLoading },
  ] = useLazyGetRepertoireFocalItemDataQuery();

  const fetchFocalItemDataQuery = useCallback(
    async (division: string, requestPayload: RepertoireFocalDataRequest) => {
      await getFocalItemDataQuery({ divisionName: division, requestPayload });
    },
    [getFocalItemDataQuery]
  );

  useEffect(() => {
    if (
      activeDivisionName &&
      id &&
      localParametersInitialised &&
      reportId === id
    ) {
      void fetchFocalItemDataQuery(activeDivisionName, {
        // pass in only relevant selections to prevent unnecessary calls to backend
        localSelectedValues: getUserSelections({
          Channel: localParameterSelections.Channel,
          Metric: localParameterSelections.Metric,
          Segmentation: localParameterSelections.Segmentation,
          LocationHierarchy: localParameterSelections.LocationHierarchy,
        } as RepertoireSidePanelSelections),
        reportId: id,
      });
    }
  }, [
    activeDivisionName,
    fetchFocalItemDataQuery,
    id,
    localParameterSelections.Channel,
    localParameterSelections.LocationHierarchy,
    localParameterSelections.Metric,
    localParameterSelections.Segmentation,
    localParametersInitialised,
    reportId,
  ]);

  const focalTableEvent = useCallback(
    (currentItems: string[], newItem: string, trackingComponent: string) => {
      eventTrackingService(
        [
          TrackingComponent.MyReports,
          TrackingComponent.Report,
          trackingComponent,
        ],
        currentItems.includes(newItem)
          ? TrackingEvent.Unselected
          : TrackingEvent.Selected,
        {
          selection: currentItems.includes(newItem)
            ? currentItems.filter((x) => x !== newItem)
            : currentItems.concat([newItem]),
        }
      );
    },
    [eventTrackingService]
  );

  const handleFocalItemSelection = useCallback(
    (value: string) => {
      dispatch(onFocalItemSelect(value));
      dispatch(onInputValueChange(""));
    },
    [dispatch]
  );

  const handleRepertoireSelection = useCallback(
    (value: string) => {
      dispatch(onRepertoireSelect(value));
    },
    [dispatch]
  );

  const rowSelectionState = useMemo(() => {
    const rowSelection: RowSelectionState = {};

    for (const selection of repertoireItems) {
      rowSelection[selection] = true;
    }

    return rowSelection;
  }, [repertoireItems]);

  // Set the sorting state manually and disable sorting on the columns
  const [sortingState, setSortingState] = useState<SortingState>([
    { id: "repertoire-top-drawer-metric-col", desc: true },
  ]);

  const getIsRowDisabled = useCallback(
    (row: Row<RepertoireFocalItemRow>, table: Table<RepertoireFocalItemRow>) =>
      !table.getState().rowSelection[row.id] &&
      (focalItems.length >= MAX_SELECTIONS ||
        repertoireItems.length >= MAX_SELECTIONS),
    [focalItems, repertoireItems]
  );

  const columnDefs: Array<ColumnDef<RepertoireFocalItemRow>> = useMemo(
    () => [
      {
        cell: ({ row, table }) =>
          RepertoireSelectCell({
            isRowDisabled: getIsRowDisabled(row, table),
            isFocalItem: focalItems.includes(row.original.id),
            isRepertoireItem: repertoireItems.includes(row.original.id),
            rowId: row.original.id,
            onFocalItemSelect: (value: string) => {
              focalTableEvent(focalItems, value, "FocalItemTableRow");
              handleFocalItemSelection(value);
            },
            onRepertoireSelect: (value: string) => {
              focalTableEvent(repertoireItems, value, "RepertoireItemTableRow");
              handleRepertoireSelection(value);
            },
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        header: FocalItemAndRepertoireHeader,
        id: `repertoire-top-drawer-select-col`,
      },
      {
        cell: ({ row }: CellContext<RepertoireFocalItemRow, string>) =>
          GraphicsCell({
            hoverOverflow: true,
            value: row.original.name,
            formatter: formatter(MetricTypes.String),
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        minSize: 600,
        header: memoizedSearchBox,
        id: `repertoire-top-drawer-item-col`,
      } as unknown as ColumnDef<RepertoireFocalItemRow>,
      {
        cell: ({ row }) =>
          ValueCell({
            formatter: formatter(tableResponse.metric.format),
            value: row.original.value,
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        header: tableResponse.metric.name.toLowerCase(),
        id: "repertoire-top-drawer-metric-col",
      },
    ],
    [
      focalTableEvent,
      focalItems,
      formatter,
      handleFocalItemSelection,
      handleRepertoireSelection,
      getIsRowDisabled,
      memoizedSearchBox,
      repertoireItems,
      tableResponse.metric.format,
      tableResponse.metric.name,
    ]
  );

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Repertoire_${localParameterSelections.TimePeriod}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const parameterSummary = useMemo(
    () =>
      getLocalParameterSummary(
        localParameterSelections,
        repertoireItems
      ).filter((parameter) => parameter.name !== "Level of analysis"),
    [localParameterSelections, repertoireItems]
  );

  const csvTransformationCallback = useCallback(
    () => csvTransformation(tableResponse),
    [tableResponse]
  );

  return (
    <ReportTopDrawer
      controls={[
        <div key="data-table-options">
          <DataTableOptions
            filename={exportFilename}
            invokeCSVDownload={csvTransformationCallback}
            isFeatureEnabled={isExportEnabled}
            key={id}
            localParameters={parameterSummary.slice(1)}
            reportParameters={reportParameters}
          />
        </div>,
      ]}
      items={focalItems.map((item) => ({
        code: tableResponse.levelOfAnalysis,
        displayName: item,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={id}
      reportName={reportName}
      reportType={reportType}
      toolbarItems={[
        <LoyaltyThresholdForm
          errorMessage={`Value must be greater to meet item selection limit of ${MAX_SELECTIONS}.`}
          handleOnChange={(value: string) => {
            dispatch(onInputValueChange(value));
          }}
          inputValue={storedSelectValue}
          isErrorState={isAutoSelectValueInvalid}
          key="repertoire-auto-select-input-control"
          metric={tableResponse.metric.name}
        />,
      ]}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper
          className={styles.repertoireReportHierarchyTableWrapperContainer}
          isSuccess={!isTableFetching && !isTableLoading}
        >
          <VirtuosoTableComponent
            className={styles.repertoireFocalItemTable}
            columns={columnDefs}
            data={tableRows}
            getIsRowDisabled={getIsRowDisabled}
            getRowId={(row) => row.id.toString()}
            onSortingChange={setSortingState}
            rowSelectionState={rowSelectionState}
            sorting={sortingState}
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default RepertoireTopDrawer;
