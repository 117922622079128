import { type ProductSubstitutabilityLocalParametersResponseDto } from "../models/ProductSubstitutabilityLocalParametersResponseDto";
import { productSubstitutabilityApiSlice } from "./product-substitutability-api-slice";

export const productSubstitutabilityLocalParametersApiSlice =
  productSubstitutabilityApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getLocalParameters: builder.query<
        ProductSubstitutabilityLocalParametersResponseDto,
        { divisionName: string; reportId: string }
      >({
        query: ({ divisionName, reportId }) => ({
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "GET",
          url: `/GetLocalParameters/${divisionName}/${reportId}`,
        }),
      }),
    }),
  });

export const { useGetLocalParametersQuery } =
  productSubstitutabilityLocalParametersApiSlice;
