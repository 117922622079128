export const Customer = ({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1134 9.25335C12.0267 9.87335 12.6667 10.7134 12.6667 11.8334V13.8334H15.3334V11.8334C15.3334 10.38 12.9534 9.52002 11.1134 9.25335Z"
      fill="#B5B5B5"
    />
    <path
      d="M10 8.50002C11.4734 8.50002 12.6667 7.30669 12.6667 5.83335C12.6667 4.36002 11.4734 3.16669 10 3.16669C9.68669 3.16669 9.39335 3.23335 9.11335 3.32669C9.66669 4.01335 10 4.88669 10 5.83335C10 6.78002 9.66669 7.65335 9.11335 8.34002C9.39335 8.43335 9.68669 8.50002 10 8.50002Z"
      fill="#B5B5B5"
    />
    <path
      d="M6.00002 8.50002C7.47335 8.50002 8.66669 7.30669 8.66669 5.83335C8.66669 4.36002 7.47335 3.16669 6.00002 3.16669C4.52669 3.16669 3.33335 4.36002 3.33335 5.83335C3.33335 7.30669 4.52669 8.50002 6.00002 8.50002ZM6.00002 4.50002C6.73335 4.50002 7.33335 5.10002 7.33335 5.83335C7.33335 6.56669 6.73335 7.16669 6.00002 7.16669C5.26669 7.16669 4.66669 6.56669 4.66669 5.83335C4.66669 5.10002 5.26669 4.50002 6.00002 4.50002Z"
      fill="#B5B5B5"
    />
    <path
      d="M6.00002 9.16669C4.22002 9.16669 0.666687 10.06 0.666687 11.8334V13.8334H11.3334V11.8334C11.3334 10.06 7.78002 9.16669 6.00002 9.16669ZM10 12.5H2.00002V11.84C2.13335 11.36 4.20002 10.5 6.00002 10.5C7.80002 10.5 9.86669 11.36 10 11.8334V12.5Z"
      fill="#B5B5B5"
    />
  </svg>
);
