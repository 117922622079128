import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  SidePanel,
  type Panel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isTimePeriodParameter,
  isSegmentationParameter,
  isPlainTextContentParameter,
  isSingleSelectionParameter,
  isLocationSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildTimePeriodPanel,
  buildSegmentationPanel,
  buildPlainTextContentPanel,
  buildSingleSelectPanel,
  buildLocationHierarchyPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import {
  onChannelChange,
  onLocationChange,
  onMetricChange,
  onSegmentationChange,
  onViewAsChange,
  selectLocalParameters,
  selectLocalParametersDto,
} from "../services/repertoire-slice";

export const RepertoireSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  const dispatch = useDispatch();
  const { localParameterSelections: localSelections } = useSelector(
    selectLocalParameters
  );
  const localParameters = useSelector(selectLocalParametersDto);

  const generatedPanels = useCallback((): Panel[] => {
    const panels: Panel[] = [];

    for (const localParameter of localParameters) {
      switch (localParameter.id) {
        case LocalParameters.Segmentation:
          if (isSegmentationParameter(localParameter)) {
            const onSegmentSelection = (value: SegmentOption) => {
              dispatch(onSegmentationChange(value));
            };

            panels.push(
              buildSegmentationPanel(
                localParameter,
                onSegmentSelection,
                localSelections.Segmentation.map(
                  (option) => option.value as string
                )
              )
            );
          } else if (isPlainTextContentParameter(localParameter)) {
            panels.push(buildPlainTextContentPanel(localParameter));
          }

          break;
        case LocalParameters.Time:
          if (isTimePeriodParameter(localParameter)) {
            panels.push(buildTimePeriodPanel(localParameter));
          }

          break;
        case LocalParameters.Channel:
          if (isSingleSelectionParameter(localParameter)) {
            panels.push(
              buildSingleSelectPanel(
                localParameter,
                (value: PanelOption) => {
                  dispatch(onChannelChange(value));
                },
                localSelections.Channel.value as string
              )
            );
          }

          break;

        case LocalParameters.ViewAs:
          if (isSingleSelectionParameter(localParameter)) {
            panels.push(
              buildSingleSelectPanel(
                localParameter,
                (value: PanelOption) => {
                  dispatch(onViewAsChange(value));
                },
                localSelections.ViewAs.value as string
              )
            );
          }

          break;
        case LocalParameters.Metric:
          if (isSingleSelectionParameter(localParameter)) {
            panels.push(
              buildSingleSelectPanel(
                localParameter,
                (value: PanelOption) => {
                  dispatch(onMetricChange(value));
                },
                localSelections.Metric.value as string
              )
            );
          }

          break;

        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(localParameter)) {
            panels.push(
              buildLocationHierarchyPanel(
                localParameter,
                (selection: LocalHierarchyNodeSelection) => {
                  dispatch(onLocationChange(selection));
                },
                localSelections.LocationHierarchy
              )
            );
          }

          break;

        default:
          break;
      }
    }

    return panels;
  }, [dispatch, localParameters, localSelections]);

  return (
    <SidePanel
      eventTrackingService={eventTrackingService}
      panels={generatedPanels()}
    />
  );
};
