import {
  type TimePeriodParameterOption,
  ParameterId,
  type ParameterDto,
  isTimePeriodSelectionDto,
  type SelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { addDays } from "date-fns";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { TimePeriodOptions } from "./components/TimePeriodConstants";
import { type TimePeriodOption } from "./components/time-period-option";
import {
  getDifferenceInWeeks,
  timestampToDate,
  getEndDate,
  getStartDate,
  convertParametersToTimePeriodOptions,
  validateComparisonPeriodOptions,
  datetimeToIsoNoOffset,
} from "./utilities";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface TimePeriodState extends ParameterState {
  endDate: string;
  errors: Record<string, boolean>;
  label: string;
  options: TimePeriodOption[];
  startDate: string;
  value: string;
  weeks: number | undefined;
}

export const isTimePeriodState = (
  state: ParameterState
): state is TimePeriodState =>
  Object.prototype.hasOwnProperty.call(state, "startDate") &&
  Object.prototype.hasOwnProperty.call(state, "endDate") &&
  Object.prototype.hasOwnProperty.call(state, "label") &&
  Object.prototype.hasOwnProperty.call(state, "value") &&
  Object.prototype.hasOwnProperty.call(state, "weeks") &&
  Object.prototype.hasOwnProperty.call(state, "options");

export const validateFields = (
  value: string,
  label: string,
  startDate: string,
  endDate: string,
  weeks: number | undefined,
  options: TimePeriodOption[]
) => {
  const errors: Record<string, boolean> = {};
  if (!endDate) {
    errors.endDate = true;
  }

  if (!startDate) {
    errors.startDate = true;
  }

  if (!value) {
    errors.value = true;
  }

  if (!label) {
    errors.label = true;
  }

  if (weeks === undefined) {
    errors.weeks = true;
  }

  const isWarning = options.every((option) => option.disabled);

  return { errors, isValid: Object.keys(errors).length === 0, isWarning };
};

export const validateTimePeriod = (timePeriod: TimePeriodState) =>
  validateFields(
    timePeriod.value,
    timePeriod.label,
    timePeriod.startDate,
    timePeriod.endDate,
    timePeriod.weeks,
    timePeriod.options
  );

export const resetTimePeriodState = (
  parameterConfig: ParameterDto,
  parameterGroup: string
): TimePeriodState => ({
  endDate: "",
  errors: {
    value: true,
  },
  isValid: false,
  isWarning: false,
  label: "",
  options: convertParametersToTimePeriodOptions(parameterConfig.options),
  parameterConfig,
  parameterGroup,
  startDate: "",
  value: "",
  weeks: undefined,
});

export const newTimePeriodState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): TimePeriodState => {
  const defaultSelection = parameterConfig.options.find(
    (option) => option.isDefault
  ) as TimePeriodParameterOption | undefined;

  const prefilledSelection =
    savedSelections ?? parameterConfig.selections ?? [];
  let newState = resetTimePeriodState(parameterConfig, parameterGroup);
  if (defaultSelection) {
    newState = {
      ...newState,
      endDate: defaultSelection.endDate,
      label: defaultSelection.label,
      startDate: defaultSelection.startDate,
      value: defaultSelection.value,
      weeks: getDifferenceInWeeks(
        timestampToDate(defaultSelection.startDate),
        timestampToDate(defaultSelection.endDate)
      ),
    };
  }

  if (prefilledSelection.length > 0) {
    const selection = prefilledSelection[0];

    if (
      isTimePeriodSelectionDto(selection) &&
      (selection.periodValue === TimePeriodOptions.CUSTOM_PERIOD ||
        parameterConfig.id === ParameterId.LeadPeriod)
    )
      newState = {
        ...newState,
        endDate: selection.endDate,
        label: selection.label,
        startDate: selection.startDate,
        value: selection.periodValue,
        weeks: getDifferenceInWeeks(
          timestampToDate(selection.startDate),
          timestampToDate(selection.endDate)
        ),
      };
    else if (isTimePeriodSelectionDto(selection)) {
      const result = parameterConfig.options.find(
        (option) => option.value === selection.periodValue
      ) as TimePeriodParameterOption;
      newState = {
        ...newState,
        endDate: result.endDate,
        label: result.label,
        startDate: result.startDate,
        value: result.value,
        weeks: getDifferenceInWeeks(
          timestampToDate(result.startDate),
          timestampToDate(result.endDate)
        ),
      };
    }
  }

  const validation = validateTimePeriod(newState);

  return {
    ...newState,
    errors: validation.errors,
    isValid: validation.isValid,
    isWarning: validation.isWarning,
  };
};

export const getTimePeriodState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as TimePeriodState | undefined
  );

export const handleFocusPeriodSelection = (
  focusPeriodState: ParameterState,
  selection: string
): ParameterState => {
  // Type guard check first
  // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
  if (isTimePeriodState(focusPeriodState)) {
    let updatedFocusPeriodState = resetTimePeriodState(
      focusPeriodState.parameterConfig,
      focusPeriodState.parameterGroup
    );

    // Get options which contains the start & end date
    const result = focusPeriodState.options.find(
      (option) => option.value === selection
    );

    if (result) {
      updatedFocusPeriodState = {
        ...focusPeriodState,
        endDate: result.endDate,
        label: result.label,
        startDate: result.startDate,
        value: selection,
        weeks: getDifferenceInWeeks(
          timestampToDate(result.startDate),
          timestampToDate(result.endDate)
        ),
      } as TimePeriodState;
    }

    const validation = validateTimePeriod(updatedFocusPeriodState);

    return {
      ...updatedFocusPeriodState,
      errors: validation.errors,
      isValid: validation.isValid,
      isWarning: validation.isWarning,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...focusPeriodState };
};

export const handleComparisonPeriodSelection = (
  parameters: Record<string, ParameterState>,
  selection?: string
): Record<string, ParameterState> => {
  const comparisonPeriodExists = Object.keys(parameters).includes(
    ParameterId.ComparisonPeriod
  );

  if (comparisonPeriodExists) {
    const comparisonPeriodState = parameters[ParameterId.ComparisonPeriod];
    const focusPeriodState = parameters[ParameterId.FocusPeriod];

    // Type guard check first
    // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
    if (
      isTimePeriodState(comparisonPeriodState) &&
      isTimePeriodState(focusPeriodState)
    ) {
      let updatedComparisonPeriodState = resetTimePeriodState(
        comparisonPeriodState.parameterConfig,
        comparisonPeriodState.parameterGroup
      );
      updatedComparisonPeriodState = validateComparisonPeriodOptions(
        updatedComparisonPeriodState,
        focusPeriodState
      );
      // Get options which contains the start & end date
      const result = updatedComparisonPeriodState.options.find(
        (option) => option.value === selection
      );

      if (result && selection) {
        updatedComparisonPeriodState = {
          ...comparisonPeriodState,
          endDate: result.endDate,
          label: result.label,
          startDate: result.startDate,
          value: selection,
          weeks: focusPeriodState.weeks,
        } as TimePeriodState;
      }

      const validation = validateTimePeriod(updatedComparisonPeriodState);

      parameters[ParameterId.ComparisonPeriod] = {
        ...updatedComparisonPeriodState,
        errors: validation.errors,
        isValid: validation.isValid,
        isWarning: validation.isWarning,
      } as ParameterState;
    }
  }

  return parameters;
};

export const handleLeadPeriodSelection = (
  leadPeriodState: ParameterState,
  selection: string,
  focusPeriodState: ParameterState
): ParameterState => {
  if (
    !isTimePeriodState(leadPeriodState) ||
    !isTimePeriodState(focusPeriodState)
  )
    return { ...leadPeriodState };

  let updatedLeadPeriodState = resetTimePeriodState(
    leadPeriodState.parameterConfig,
    leadPeriodState.parameterGroup
  );

  const result = leadPeriodState.options.find(
    (option) => option.value === selection
  );

  const focusPeriodStartDate = timestampToDate(focusPeriodState.startDate);
  const endDate = datetimeToIsoNoOffset(
    focusPeriodStartDate
      ? addDays(focusPeriodStartDate, -1)
      : focusPeriodStartDate
  );

  if (result && selection === TimePeriodOptions.CUSTOM_PERIOD) {
    updatedLeadPeriodState = {
      ...leadPeriodState,
      endDate,
      label: result.label,
      startDate: result.startDate,
      value: selection,
      weeks: getDifferenceInWeeks(
        timestampToDate(result.startDate),
        timestampToDate(endDate)
      ),
    } as TimePeriodState;
  }

  if (
    selection !== "PriorPeriod-0" &&
    selection !== TimePeriodOptions.CUSTOM_PERIOD
  ) {
    const weeks = Number(result?.value.split("-")[1]);

    const startDate = datetimeToIsoNoOffset(
      focusPeriodStartDate
        ? addDays(focusPeriodStartDate, -7 * weeks)
        : focusPeriodStartDate
    );

    updatedLeadPeriodState = {
      ...leadPeriodState,
      endDate,
      label: result?.label,
      startDate,
      value: selection,
      weeks: getDifferenceInWeeks(
        timestampToDate(startDate),
        timestampToDate(endDate)
      ),
    } as TimePeriodState;
  }

  if (selection === "PriorPeriod-0") {
    updatedLeadPeriodState = {
      ...leadPeriodState,
      endDate: focusPeriodState.startDate,
      label: result?.label,
      startDate: focusPeriodState.startDate,
      value: selection,
      weeks: 0,
    } as TimePeriodState;
  }

  const validation = validateTimePeriod(updatedLeadPeriodState);

  return {
    ...updatedLeadPeriodState,
    errors: validation.errors,
    isValid: validation.isValid,
    isWarning: validation.isWarning,
    isParameterMissing: false,
  } as ParameterState;
};

export const handleResetLeadPeriodSelection = (
  leadPeriodState: ParameterState,
  focusPeriodStartDate: string
): ParameterState => {
  if (!isTimePeriodState(leadPeriodState)) return { ...leadPeriodState };

  const date = timestampToDate(focusPeriodStartDate);
  const endDate = datetimeToIsoNoOffset(date ? addDays(date, -1) : date);

  let updatedLeadPeriodState = resetTimePeriodState(
    leadPeriodState.parameterConfig,
    leadPeriodState.parameterGroup
  );

  updatedLeadPeriodState = {
    ...leadPeriodState,
    endDate,
    label: "",
    startDate: "",
    value: "",
    weeks: undefined,
  } as TimePeriodState;

  const validation = validateTimePeriod(updatedLeadPeriodState);

  return {
    ...updatedLeadPeriodState,
    errors: validation.errors,
    isValid: validation.isValid,
    isWarning: validation.isWarning,
  } as ParameterState;
};

export const handleStartDateSelection = (
  timePeriodState: ParameterState,
  startDate: string,
  parameter: string
): ParameterState => {
  // Type guard check first
  // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
  if (isTimePeriodState(timePeriodState)) {
    timePeriodState.startDate = startDate;

    if (parameter === ParameterId.FocusPeriod) {
      timePeriodState.weeks = getDifferenceInWeeks(
        timestampToDate(startDate),
        timestampToDate(timePeriodState.endDate)
      );
    } else if (
      parameter === ParameterId.ComparisonPeriod &&
      timePeriodState.weeks
    ) {
      timePeriodState.endDate = getEndDate(startDate, timePeriodState.weeks);
    } else if (parameter === ParameterId.LeadPeriod) {
      timePeriodState.weeks = getDifferenceInWeeks(
        timestampToDate(startDate),
        timestampToDate(timePeriodState.endDate)
      );
    }

    const validation = validateTimePeriod(timePeriodState);

    return {
      ...timePeriodState,
      errors: validation.errors,
      isValid: validation.isValid,
      isWarning: validation.isWarning,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...timePeriodState };
};

export const handleEndDateSelection = (
  timePeriodState: ParameterState,
  endDate: string,
  parameter: string
): ParameterState => {
  // Type guard check first
  // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
  if (isTimePeriodState(timePeriodState)) {
    timePeriodState.endDate = endDate;
    if (parameter === ParameterId.FocusPeriod) {
      timePeriodState.weeks = getDifferenceInWeeks(
        timestampToDate(timePeriodState.startDate),
        timestampToDate(endDate)
      );
    } else if (
      parameter === ParameterId.ComparisonPeriod &&
      timePeriodState.weeks
    ) {
      timePeriodState.startDate = getStartDate(endDate, timePeriodState.weeks);
    }

    const validation = validateTimePeriod(timePeriodState);

    return {
      ...timePeriodState,
      errors: validation.errors,
      isValid: validation.isValid,
      isWarning: validation.isWarning,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...timePeriodState };
};
