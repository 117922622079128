import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { useNumberFormat } from "@quantium-enterprise/hooks-ui";
import {
  NumberGroup,
  NumberSet,
  NumberSize,
  NumberUnitPosition,
  NumberVariant,
  Number,
  NumberGroupVariant,
} from "@quantium-enterprise/qds-react";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { useSelector } from "react-redux";
import {
  selectFocalItem,
  selectFocalItemData,
} from "../services/basket-affinities-slice";
import styles from "./BasketAffinitiesPurchasedWithFocalItemsStats.module.css";

export const BasketAffinitiesPurchasedWithFocalItemsStats = () => {
  const focalItem = useSelector(selectFocalItem);
  const stats = useSelector(selectFocalItemData);
  const { currencyFormatter, decimalFormatter, integerFormatter } =
    useNumberFormat();

  const AVG_SPEND_PER_VISIT = "Avg spend per visit";
  const AVG_UNITS_PER_VISIT = "Avg units per visit";
  const SALES = "Sales";
  const VISITS = "Visits";
  const CUSTOMERS = "Customers";

  return (
    <>
      {focalItem && (
        <div className={styles.focalItem}>
          <span className={styles.detailsFor}>Details for</span>
          <HierarchyLevelIcon
            shortName={focalItem.shortName}
            type={HierarchyItemType.Leaf}
          />
          {focalItem.name}
        </div>
      )}
      <div className={styles.stats}>
        <NumberGroup
          className={styles.numberGroup}
          variant={NumberGroupVariant.Comparison}
        >
          <NumberSet className={styles.numberSet} title={<div>{SALES}</div>}>
            <Number
              className={styles.numberValue}
              size={NumberSize.Small}
              unitPosition={NumberUnitPosition.Before}
              variant={NumberVariant.Featured}
            >
              {currencyFormatter(
                stats.find((x) => x.metric === SALES)?.value ?? 0
              )}
            </Number>
          </NumberSet>
          <NumberSet
            className={styles.numberSet}
            title={<div>{CUSTOMERS}</div>}
          >
            <Number
              className={styles.numberValue}
              size={NumberSize.Small}
              unitPosition={NumberUnitPosition.Before}
              variant={NumberVariant.Featured}
            >
              {integerFormatter(
                stats.find((x) => x.metric === CUSTOMERS)?.value ?? 0
              )}
            </Number>
          </NumberSet>
          <NumberSet className={styles.numberSet} title={<div>{VISITS}</div>}>
            <Number
              className={styles.numberValue}
              size={NumberSize.Small}
              unitPosition={NumberUnitPosition.Before}
              variant={NumberVariant.Featured}
            >
              {integerFormatter(
                stats.find((x) => x.metric === VISITS)?.value ?? 0
              )}
            </Number>
          </NumberSet>
          <NumberSet
            className={styles.numberSet}
            title={<div>{AVG_SPEND_PER_VISIT}</div>}
          >
            <Number
              className={styles.numberValue}
              size={NumberSize.Small}
              unitPosition={NumberUnitPosition.Before}
              variant={NumberVariant.Featured}
            >
              {currencyFormatter(
                stats.find((x) => x.metric === AVG_SPEND_PER_VISIT)?.value ?? 0
              )}
            </Number>
          </NumberSet>
          <NumberSet
            className={styles.numberSet}
            title={<div>{AVG_UNITS_PER_VISIT}</div>}
          >
            <Number
              className={styles.numberValue}
              size={NumberSize.Small}
              unitPosition={NumberUnitPosition.Before}
              variant={NumberVariant.Featured}
            >
              {decimalFormatter(
                stats.find((x) => x.metric === AVG_UNITS_PER_VISIT)?.value ?? 0
              )}
            </Number>
          </NumberSet>
        </NumberGroup>
      </div>
    </>
  );
};
