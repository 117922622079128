import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "../../store";

export const selectFocalItems = createSelector(
  (state: RootState) => state.timeOfDayDayOfWeek.persistedSelections.focalItems,
  (focalItems) => focalItems
);

export const selectFocalItemParents = createSelector(
  (state: RootState) => state.timeOfDayDayOfWeek.focalItemParents,
  (focalItemParents) => focalItemParents
);

export const selectInitialDataRecieved = createSelector(
  (state: RootState) => state.timeOfDayDayOfWeek.focalItemTableInitialised,
  (focalItemTableInitialised) => focalItemTableInitialised
);

export const selectLocalParametersSelections = createSelector(
  (state: RootState) =>
    state.timeOfDayDayOfWeek.persistedSelections.localParametersSelections,
  (localParametersSelections) => localParametersSelections
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.timeOfDayDayOfWeek.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectTableMetaData = createSelector(
  (state: RootState) => state.timeOfDayDayOfWeek.focalItemTableMetaData,
  (focalItemTableMetaData) => focalItemTableMetaData
);

export const selectTableRows = createSelector(
  (state: RootState) => state.timeOfDayDayOfWeek.focalItemTableRows,
  (focalItemTableRows) => focalItemTableRows
);

export const selectSearchQuery = createSelector(
  (state: RootState) => state.timeOfDayDayOfWeek.focalItemSearchQuery,
  (focalItemSearchQuery) => focalItemSearchQuery
);
