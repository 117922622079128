import tokens from "@quantium-enterprise/qds-styles/dist/tokens.json";

// usage : const x = ChartColour.BrandBlue500;
export const ChartColour = {
  BrandBlue500: tokens.colour["brand-blue"],
  BrandCyan800: tokens.colour["brand-cyan-palette"]["800"],
  // these two colours are not yet in qbit.
  BrandTurquoiseNew: "#12A35A",
  BrandYellowNew: "#C48221",
  BrandOrange900: tokens.colour["brand-orange-palette"]["900"],
  BrandCoral900: tokens.colour["brand-coral-palette"]["900"],
  BrandBurgundy500: tokens.colour["brand-burgundy-palette"]["500"],
  BrandViolet400: tokens.colour["brand-violet-palette"]["400"],
  BrandBlueA400: tokens.colour["brand-blue-palette"].A400,
  BrandBurgundy900: tokens.colour["brand-burgundy-palette"]["900"],
  BrandViolet900: tokens.colour["brand-violet-palette"]["900"],
  BrandBlue900: tokens.colour["brand-blue-palette"]["900"],
  BrandSecondaryDarkWarmGrey:
    tokens.colour["brand-secondary"]["dark-warm-grey"],
  BrandCyan900: tokens.colour["brand-cyan-palette"]["900"],
};

export const ChartColoursList = Object.values(ChartColour);

// helper function so you can simply call `getColourByIndex(index++)`
// and never have to worry about the length of the colours
export const getColourByIndex = (index: number): string =>
  ChartColoursList[index % ChartColoursList.length];
