import { isSameDay } from "date-fns";
import { type CustomTimePeriodOption } from "./CustomTimePeriod";

export const disableSpecificDay = (
  date: Date,
  dateToDisable: Date | undefined
): Boolean => {
  if (!dateToDisable) {
    return false;
  }

  return isSameDay(date, dateToDisable);
};

export const disableAllOtherDays = (day: Date, options: Date[]): Boolean => {
  const result = !options.some((option) => isSameDay(day, option));
  return result;
};

export const disableDatesStart = (
  date: Date,
  endDate: Date | undefined,
  options: CustomTimePeriodOption[]
): Boolean => {
  const result = disableAllOtherDays(
    date,
    options.map((option) => option.weekStartDate)
  );
  return result;
};

export const disableDatesEnd = (
  date: Date,
  startDate: Date | undefined,
  options: CustomTimePeriodOption[]
): Boolean => {
  const result = disableAllOtherDays(
    date,
    options.map((option) => option.weekEndDate)
  );
  return result;
};

export const getMaxDate = (
  date1: Date | undefined,
  date2: Date | undefined
): Date | undefined => {
  if (date1 && date2) {
    return date1 > date2 ? date1 : date2;
  } else {
    return date1 ?? date2;
  }
};

export const getMinDate = (
  date1: Date | undefined,
  date2: Date | undefined
): Date | undefined => {
  if (date1 && date2) {
    return date1 < date2 ? date1 : date2;
  } else {
    return date1 ?? date2;
  }
};
