import { useGetReportParametersQuery } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import selectedMatrixImgIcon from "../../../assets/tab-icons/matrix-icon-selected.svg";
import defaultMatrixImgIcon from "../../../assets/tab-icons/matrix-icon.svg";
import selectedVennImgIcon from "../../../assets/tab-icons/venn-icon-selected.svg";
import defaultVennImgIcon from "../../../assets/tab-icons/venn-icon.svg";
import { ReportButtonTabs } from "../../../common/components/ReportTabComponents/ReportButtonTabs";
import { ReportTabItem } from "../../../common/components/ReportTabComponents/ReportTabItem";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { CrossShopInteractionsMatrix } from "./CrossShopInteractionsMatrix";
import { CrossShopInteractionsVenn } from "./CrossShopInteractionsVenn";

const enum CROSS_SHOP_INTERACTIONS_REPORTLET_TABS {
  Matrix = "Matrix",
  Venn = "Venn",
}

export const CrossShopInteractionsReportlet = () => {
  const [activeTab, setActiveTab] = useState<string>(
    CROSS_SHOP_INTERACTIONS_REPORTLET_TABS.Venn
  );

  const { id } = useParams();
  const { name: activeDivisionName } = useDivision();
  const { data: infoPanelSummary } = useGetReportParametersQuery(
    { divisionName: activeDivisionName, reportId: id ?? "" },
    { skip: !activeDivisionName || !id }
  );

  return (
    <ReportletAccordion
      subtitle="Understand the interaction between brands, products and categories."
      title="Cross shop interactions"
    >
      <ReportButtonTabs
        activeTab={activeTab}
        onClickButtonTabItem={setActiveTab}
      >
        <ReportTabItem
          defaultImgIcon={defaultVennImgIcon}
          label={CROSS_SHOP_INTERACTIONS_REPORTLET_TABS.Venn}
          selectedImgIcon={selectedVennImgIcon}
          value={CROSS_SHOP_INTERACTIONS_REPORTLET_TABS.Venn}
        >
          <ErrorBoundary>
            <CrossShopInteractionsVenn infoPanelSummary={infoPanelSummary} />
          </ErrorBoundary>
        </ReportTabItem>
        <ReportTabItem
          defaultImgIcon={defaultMatrixImgIcon}
          label={CROSS_SHOP_INTERACTIONS_REPORTLET_TABS.Matrix}
          selectedImgIcon={selectedMatrixImgIcon}
          value={CROSS_SHOP_INTERACTIONS_REPORTLET_TABS.Matrix}
        >
          <ErrorBoundary>
            <CrossShopInteractionsMatrix infoPanelSummary={infoPanelSummary} />
          </ErrorBoundary>
        </ReportTabItem>
      </ReportButtonTabs>
    </ReportletAccordion>
  );
};
