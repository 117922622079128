import {
  type ReportParametersDto,
  type HierarchyValue,
  ReportType,
} from "@quantium-enterprise/common-ui";
import {
  type DeferredFormatFunction,
  MetricTypes,
  useDivision,
  useFormatter,
  getNumberFormat,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { Spinner, SpinnerSize } from "@quantium-enterprise/qds-react";
import {
  ButtonToggleGroup,
  type ToggleButton,
} from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { type DataLabelsOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { BasicColumnAndLineChart } from "components-ui/src/charts/column-and-line-chart/BasicColumnAndLineChart";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { Panel } from "components-ui/src/panel/Panel";
import { format, parse } from "date-fns";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import ChartIcon from "../../assets/tab-icons/bar-chart-black.svg";
import ChartIconSelected from "../../assets/tab-icons/bar-chart-white.svg";
import TableIcon from "../../assets/tab-icons/table-black.svg";
import TableIconSelected from "../../assets/tab-icons/table-white.svg";
import { EMPTY_NODE_NUMBER } from "../../common/constants";
import { type RootState } from "../../store";
import { KeyDriversOverTimeFeatureFlags } from "../constants/key-drivers-over-time-feature-flags";
import { type ReportletItem } from "../models/reportlet-reponse";
import { useGetKeyDriversOverTimeReportletDataQuery } from "../services/key-drivers-over-time-reportlet-api-slice";
import {
  onReportletDataReceived,
  onReportletTabChange,
  onToggleShowChartDataLabels,
  selectChannel,
  selectFocalItem,
  selectKeyDriver,
  selectLocalParameterSelections,
  selectLocalParametersInitialised,
  selectLocationHierarchy,
  selectSegment,
  selectSegmentation,
} from "../services/key-drivers-over-time-slice";
import getUserSelections from "../utils/getUserSelections";
import { KeyDriversOverTimeContributionBreakdownTable } from "./KeyDriversOverTimeContributionBreakdownTable";
import styles from "./KeyDriversOverTimeReportlet.module.css";
import { csvTransformation } from "./csvTransformation";

export type KeyDriversOverTimeReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

const colors = ["#000006", "#3F69AE", "#44B6C5", "#EF9C48", "#C96478"];

export const reshapeChartData = (
  chartData: ReportletItem[],
  metricFormatter: DeferredFormatFunction
) =>
  chartData.map(({ label, format: numberFormat, values }, index) => ({
    color: colors[index],
    // eslint-disable-next-line unicorn/prefer-number-properties
    data: values.map((value) => (isNaN(value) ? 0 : value)),
    formatter: (
      value?: number | string | null,
      alwaysDisplaySign: boolean = false
    ) => metricFormatter(numberFormat)(value, alwaysDisplaySign),
    name: index === 0 ? label + " growth" : label,
    type: (index === 0 ? "line" : "column") as "column" | "line",
  }));

export const formatTableData = (chartData: ReportletItem[]) =>
  chartData.map(({ label, values }, index) => ({
    data: values.map((value) => value),
    header: {
      depth: index === 0 ? 0 : 1,
      name: index === 0 ? label + " growth" : label,
    },
  }));

export type ReportletTabContentProps = {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
};

export const ReportletTabContent = forwardRef(
  ({ onOptionsChanged }: ReportletTabContentProps, ref) => {
    const { name: activeDivisionName } = useDivision();
    const dispatch = useDispatch();
    const formatter = useFormatter();

    const focalItem = useSelector(selectFocalItem);
    const keyDriver = useSelector(selectKeyDriver);
    const channel = useSelector(selectChannel);
    const location = useSelector(selectLocationHierarchy);
    const segmentation = useSelector(selectSegmentation);
    const segment = useSelector(selectSegment);
    const localParameterSelections = useSelector(
      selectLocalParameterSelections
    );
    const localParametersInitialised = useSelector(
      selectLocalParametersInitialised
    );

    const {
      reportId,
      reportletData,
      reportletTabActive,
      time,
      showChartDataLabels,
    } = useSelector((state: RootState) => ({
      localParametersInitialised:
        state.keyDriversOverTime.localParametersInitialised,
      reportId: state.keyDriversOverTime.metaData.reportId,
      reportletData: state.keyDriversOverTime.reportletData,
      reportletTabActive: state.keyDriversOverTime.reportletTabActive,
      time: state.keyDriversOverTime.persistedSelections
        .localParameterSelections.Time,
      showChartDataLabels: state.keyDriversOverTime.showChartDataLabels,
    }));

    const parameterSummary = useMemo(
      () => [
        { name: "Focal item", value: focalItem.name },
        {
          name: "Time",
          value: time as string,
        },
        { name: "Key driver", value: keyDriver.label },
        { name: "Channel", value: channel.label },
        {
          name: "Location",
          value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
            location.name
          }`,
        },
        { name: "Segmentation", value: segmentation.label },
        { name: "Customer segment", value: segment.label },
      ],
      [time, focalItem, keyDriver, channel, location, segment, segmentation]
    );

    const requestPayload = {
      focalItem: {
        itemCode: focalItem.code,
        name: focalItem.name,
        nodeNumber: focalItem.nodeNumber,
        shortName: focalItem.shortName,
      } as HierarchyValue,
      localSelectedValues: getUserSelections({
        Channel: localParameterSelections.Channel,
        KeyDriver: localParameterSelections.KeyDriver,
        LocationHierarchy: localParameterSelections.LocationHierarchy,
        Segment: localParameterSelections.Segment,
        Segmentation: localParameterSelections.Segmentation,
      }),
      reportId,
    };

    const isDataLoaded =
      localParametersInitialised &&
      reportId &&
      focalItem.nodeNumber !== EMPTY_NODE_NUMBER;
    const { data: newReportletData, isFetching: isChartDataFetching } =
      useGetKeyDriversOverTimeReportletDataQuery(
        {
          divisionName: activeDivisionName,
          requestPayload,
        },
        {
          skip: !isDataLoaded,
        }
      );

    const reportletDataJson = JSON.stringify(reportletData);
    const newReportletDataJson = JSON.stringify(newReportletData);
    useEffect(() => {
      if (reportletDataJson !== newReportletDataJson) {
        const initialDataJson = {
          label: "",
          promoWeeks: [],
        };
        dispatch(onReportletDataReceived(newReportletData ?? initialDataJson));
      }
    }, [dispatch, reportletDataJson, newReportletDataJson, newReportletData]);

    const chartData = useMemo(
      () =>
        reportletData.chartData
          ? reshapeChartData(reportletData.chartData, formatter)
          : undefined,
      [formatter, reportletData.chartData]
    );

    const convertedPromoWeeks = useMemo(
      () =>
        reportletData.promoWeeks.map((pw) =>
          format(parse(pw, "yyyyMMdd", new Date()), "d MMM yy")
        ),
      [reportletData.promoWeeks]
    );

    if (isChartDataFetching || !isDataLoaded) {
      return (
        <div className={styles.chartContainer}>
          <Spinner
            data-testid="data-table-loading-icon"
            size={SpinnerSize.Large}
          />
        </div>
      );
    }

    if (
      reportletData.chartData &&
      reportletData.chartData.length > 0 &&
      chartData
    ) {
      const isDecimal = reportletData.chartData[0].label === "Volume";
      const metricType = isDecimal ? MetricTypes.Decimal : MetricTypes.Currency;
      const tableHeader =
        reportletData.chartData[0].label + " growth contribution table";

      if (reportletTabActive === "Chart") {
        return (
          <ErrorBoundary key="chart">
            <ChartFooterWrapper
              height="400px"
              parameters={parameterSummary}
              ref={ref}
            >
              <BasicColumnAndLineChart
                alwaysDisplaySign={false}
                categories={convertedPromoWeeks}
                chartData={chartData}
                chartDataFormatter={formatter(metricType)}
                onOptionsChanged={onOptionsChanged}
                showChartDataLabels={showChartDataLabels}
                tooltipLabel={reportletData.label}
                yAxisLabel={reportletData.label}
              />
            </ChartFooterWrapper>
          </ErrorBoundary>
        );
      } else {
        return (
          <ErrorBoundary key="table">
            <div className={styles.tableContainer}>
              <div className={styles.tableHeader}>{tableHeader}</div>
              <KeyDriversOverTimeContributionBreakdownTable
                categories={convertedPromoWeeks}
                metricType={metricType}
                tableData={formatTableData(reportletData.chartData)}
              />
            </div>
          </ErrorBoundary>
        );
      }
    }

    return (
      <div className={styles.chartContainer}>
        <span>No data to display</span>
      </div>
    );
  }
);

const tabButtons: Array<ToggleButton<string>> = [
  {
    displayText: "Chart",
    icon: {
      iconDefault: ChartIcon,
      iconSelected: ChartIconSelected,
    },
    id: "Chart",
  },
  {
    displayText: "Table",
    icon: {
      iconDefault: TableIcon,
      iconSelected: TableIconSelected,
    },
    id: "Table",
  },
];

export const ReportletTabs = () => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state: RootState) => ({
    activeTab: state.keyDriversOverTime.reportletTabActive,
  }));

  return (
    <ButtonToggleGroup
      buttonSelected={activeTab}
      buttons={tabButtons}
      setButtonSelected={(id: string) => dispatch(onReportletTabChange(id))}
    />
  );
};

export const KeyDriversOverTimeReportlet = ({
  infoPanelSummary,
}: KeyDriversOverTimeReportletProps) => {
  const focalItem = useSelector(selectFocalItem);
  const keyDriver = useSelector(selectKeyDriver);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocationHierarchy);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);

  const {
    activeTab,
    timePeriodLength,
    time,
    reportletData,
    showChartDataLabels,
    reportName,
  } = useSelector((state: RootState) => ({
    activeTab: state.keyDriversOverTime.reportletTabActive,
    timePeriodLength:
      state.keyDriversOverTime.persistedSelections.localParameterSelections
        .TimePeriodLength,
    time: state.keyDriversOverTime.persistedSelections.localParameterSelections
      .Time,
    reportletData: state.keyDriversOverTime.reportletData,
    showChartDataLabels: state.keyDriversOverTime.showChartDataLabels,
    reportName: state.keyDriversOverTime.metaData.reportName,
  }));

  const chartContainerRef = useRef<HTMLElement>();

  const dispatch = useDispatch();

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[KeyDriversOverTimeFeatureFlags.ReportExport] ?? false;
  const exportChartFilename = useMemo(
    () =>
      cleanFilename(
        `Key_Drivers_Over_Time_Chart_${timePeriodLength}_${location.name}`
      ),
    [location, timePeriodLength]
  );

  const exportTableFilename = useMemo(
    () =>
      cleanFilename(
        `Key_Drivers_Over_Time_Table_${timePeriodLength}_${location.name}`
      ),
    [location, timePeriodLength]
  );

  const parameterSummary = useMemo(
    () => [
      { name: "Focal item", value: focalItem.name },
      {
        name: "Time",
        value: time as string,
      },
      { name: "Key driver", value: keyDriver.label },
      { name: "Channel", value: channel.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
          location.name
        }`,
      },
      { name: "Segmentation", value: segmentation.label },
      { name: "Customer segment", value: segment.label },
    ],
    [time, focalItem, keyDriver, channel, location, segment, segmentation]
  );

  const { locale, currency } = useDivision();

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const chartCsvTransformationCallback = useCallback(
    () => csvTransformation(reportletData, currencySymbol),
    [reportletData, currencySymbol]
  );

  const dataLabelOptions: DataLabelsOptions = {
    isSelected: showChartDataLabels,
    value: "",
  };

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  return (
    <Panel
      subtitle="View sales performance drivers over time."
      title="Key drivers over time"
    >
      <div className={styles.chartOptionsContainer}>
        <ReportletTabs />
        {activeTab === "Chart" ? (
          <ChartOptions
            dataLabelsOptions={[dataLabelOptions]}
            downloadWizardOptions={
              currentOptions
                ? {
                    chartOptions: currentOptions,
                    reportIcon: (
                      <ReportIcon type={ReportType.KeyDriversOverTime} />
                    ),
                    chartTitle: `Key drivers over time - ${reportName}`,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={exportChartFilename}
            getCSVData={chartCsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={infoPanelSummary}
            toggleDataLabels={() => {
              dispatch(onToggleShowChartDataLabels());
            }}
          />
        ) : (
          <DataTableOptions
            filename={exportTableFilename}
            invokeCSVDownload={chartCsvTransformationCallback}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={infoPanelSummary}
          />
        )}
      </div>
      <ReportletTabContent
        onOptionsChanged={setCurrentOptions}
        ref={chartContainerRef}
      />
    </Panel>
  );
};
