import {
  ButtonToggleGroup,
  type ToggleButton,
} from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { InformationTooltip } from "components-ui/src/information-tooltip/InformationTooltip";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFocalItemDropdownOptions } from "../../../common/utils/focal-item-labels-utils";
import { type RootState } from "../../../store";
import {
  onChartViewByChange,
  onChartBenchmarkChange,
  onChartCustomerSegmentChange,
  onChartFocalItemChange,
  onChartDayChange,
} from "../../services/time-of-day-day-of-week-slice";
import {
  selectFocalItemParents,
  selectFocalItems,
} from "../../services/time-of-day-day-of-week-slice-selectors";
import { formatDayId } from "../../utils/chart-utils";
import { ChartFilterDropdown } from "./ChartFilterDropdown";
import styles from "./ChartSelectors.module.css";

export const ChartSelectors = () => {
  const dispatch = useDispatch();

  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);

  const { chartSelections } = useSelector((state: RootState) => ({
    chartSelections: state.timeOfDayDayOfWeek.chartSelections,
  }));
  const selectedTab = chartSelections.tabSelected;
  const viewByOptions =
    selectedTab === "Day of Week"
      ? chartSelections.viewBy.dayOfWeekOptions
      : chartSelections.viewBy.timeOfDayOptions;
  const benchmark = chartSelections.benchmark;
  const onBenchmarkChange = useCallback(
    (event: string) => {
      dispatch(onChartBenchmarkChange(Number(event)));
    },
    [dispatch]
  );
  const segment = chartSelections.segment;
  const onSegmentChange = useCallback(
    (event: string) => {
      dispatch(onChartCustomerSegmentChange(event));
    },
    [dispatch]
  );
  const focalItem = chartSelections.focalItem;
  const onFocalChange = useCallback(
    (event: string) => {
      dispatch(onChartFocalItemChange(Number(event)));
    },
    [dispatch]
  );
  const dayOfWeek = chartSelections.dayOfWeek;
  const onDayChange = useCallback(
    (event: string) => {
      dispatch(onChartDayChange(event));
    },
    [dispatch]
  );
  const viewByButtons: Array<ToggleButton<string>> = viewByOptions.map(
    (tab: string) => ({
      displayText: tab,
      id: tab,
    })
  );
  const viewByButtonGroup = (
    <div className={styles.selectorButtons}>
      <div className={styles.selectorHeaderRow}>
        <div className={styles.selectorHeading}>View by</div>
      </div>
      <ButtonToggleGroup
        buttonSelected={chartSelections.viewBy.selected}
        buttons={viewByButtons}
        setButtonSelected={(id: string) => dispatch(onChartViewByChange(id))}
      />
    </div>
  );
  const benchmarkDropdown = (
    <div className={styles.selectorDropdown}>
      <div className={styles.selectorHeaderRow}>
        <div className={styles.selectorHeading}>Benchmark</div>
        <InformationTooltip displayText="Use benchmarks for a comparison of focal item(s) sales patterns." />
      </div>
      <ChartFilterDropdown
        defaultOption={{ label: "None", value: "-1" }}
        isEnabled={benchmark.isEnabled}
        onChangeHandler={onBenchmarkChange}
        options={benchmark.options}
        selected={benchmark.selected}
      />
    </div>
  );
  let customerSegmentDropdown = null;
  if (segment.options.length > 1) {
    customerSegmentDropdown = (
      <div className={styles.selectorDropdown}>
        <div className={styles.selectorHeaderRow}>
          <div className={styles.selectorHeading}>Customer segment</div>
          <InformationTooltip displayText="Understand when a customer segment is shopping." />
        </div>
        <ChartFilterDropdown
          isEnabled={segment.isEnabled}
          onChangeHandler={onSegmentChange}
          options={segment.options}
          selected={segment.selected}
        />
      </div>
    );
  }

  const focalItemDropdownOptions = useMemo(() => {
    const options = getFocalItemDropdownOptions(focalItems, focalItemParents);
    return options.map((item) => ({
      label: item.label,
      value: item.value.toString(),
    }));
  }, [focalItems, focalItemParents]);

  const focalItemDropdown = (
    <div className={styles.selectorDropdown}>
      <div className={styles.selectorHeaderRow}>
        <div className={styles.selectorHeading}>Focal item</div>
      </div>
      <ChartFilterDropdown
        isEnabled={focalItem.isEnabled}
        onChangeHandler={onFocalChange}
        options={focalItemDropdownOptions}
        selected={focalItem.selected}
      />
    </div>
  );
  let dayOfWeekDropdown = null;
  if (selectedTab === "Time of Day") {
    const daysDropdownOptions = dayOfWeek.options.map((day) => ({
      label: formatDayId[day.toUpperCase()],
      value: day,
    }));
    dayOfWeekDropdown = (
      <div className={styles.selectorDropdown}>
        <div className={styles.selectorHeaderRow}>
          <div className={styles.selectorHeading}>Day of week</div>
          <InformationTooltip displayText="Focus on the shopping patterns for a specific day when viewing focal item(s) or customer segmentations." />
        </div>
        <ChartFilterDropdown
          isEnabled={dayOfWeek.isEnabled}
          onChangeHandler={onDayChange}
          options={daysDropdownOptions}
          selected={dayOfWeek.selected}
        />
      </div>
    );
  }

  return (
    <div className={styles.chartSelectors}>
      <div className={styles.chartSelectorsRow}>{viewByButtonGroup}</div>
      <div className={styles.chartSelectorsRow}>
        {benchmarkDropdown}
        {customerSegmentDropdown}
        {focalItemDropdown}
        {dayOfWeekDropdown}
      </div>
    </div>
  );
};
