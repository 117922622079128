// this file is one big switch statement and not that complex
/* eslint-disable complexity */
import {
  type TransactionSource,
  type ParameterDto,
  ParameterId,
  type SelectionDto,
} from "@quantium-enterprise/common-ui";
import {
  newBandsParameterState,
  newBufferParameterState,
  newDropdownParameterState,
  newGroupParameterState,
  newHierarchyState,
  newLevelOfAnalysisState,
  newStructureState,
  newListParameterState,
  newMeasureThresholdParameterState,
  newSegmentFilterParameterState,
  newSegmentationParameterState,
  newRollingPeriodState,
  newTimePeriodState,
} from "../parameters";

// We disable eslint specifically because we want to use 'interface' for inheritance not possible with 'type'
// Source: https://blog.logrocket.com/types-vs-interfaces-in-typescript/
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface ParameterState {
  isInfo?: boolean;
  isParameterMissing?: boolean;
  isValid: boolean;
  isWarning?: boolean;
  maxGroupSelectionsReached?: boolean;
  // Hold a copy of the parameter's config useful as it contains all the option values
  parameterConfig: ParameterDto;
  parameterGroup: string;
}

export const initialParametersState = (
  parameterDto: ParameterDto,
  parameterGroup: string,
  dataSource?: TransactionSource,
  isDataEntitlementsShown?: boolean,
  savedSelections?: SelectionDto[]
) => {
  switch (parameterDto.id) {
    case ParameterId.Channel:
      return newListParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.ComparisonPeriod:
      return newTimePeriodState(parameterDto, parameterGroup, savedSelections);
    case ParameterId.Dataset:
      return newListParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.FocusPeriod:
      return newTimePeriodState(parameterDto, parameterGroup, savedSelections);
    case ParameterId.LeadPeriod:
      return newTimePeriodState(parameterDto, parameterGroup, savedSelections);
    case ParameterId.LevelOfAnalysis:
      return newLevelOfAnalysisState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.LocationHierarchy:
      return newHierarchyState(parameterDto, parameterGroup, savedSelections);
    case ParameterId.LocationStructure:
      return newStructureState(
        parameterDto,
        parameterGroup,
        dataSource,
        isDataEntitlementsShown,
        savedSelections
      );
    case ParameterId.ProductHierarchy:
      return newHierarchyState(parameterDto, parameterGroup, savedSelections);
    case ParameterId.ProductStructure:
      return newStructureState(
        parameterDto,
        parameterGroup,
        undefined,
        undefined,
        savedSelections
      );
    case ParameterId.Promotion:
      return newListParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.RollingPeriod:
      return newRollingPeriodState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.Segmentation:
      return newSegmentationParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.SegmentFilter:
      return newSegmentFilterParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.ProductGroups:
      return newGroupParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.LocationGroups:
      return newGroupParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.PurchasingBehaviour:
      return newListParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.PurchasingTrends:
      return newListParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.NewLapsedContinuous:
      return newListParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.RetailerCustomers:
      return newListParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.Measure:
      return newDropdownParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    case ParameterId.Bands:
      return newBandsParameterState(parameterDto, parameterGroup);
    case ParameterId.Buffer:
      return newBufferParameterState(parameterDto, parameterGroup);
    case ParameterId.MeasureThreshold:
      return newMeasureThresholdParameterState(
        parameterDto,
        parameterGroup,
        savedSelections
      );
    default:
      return {
        isValid: false,
        parameterConfig: parameterDto,
      } as ParameterState;
  }
};
