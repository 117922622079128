type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};
export const NewProductBenchmarkingIcon = ({
  className,
  coloured,
  text,
}: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "New product benchmark report"}</title>
      <g clipPath="url(#clip0_1991_122198)">
        <path
          d="M8.43224 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H8.70254C8.58545 12.3846 8.4868 11.7084 8.43224 11Z"
          fill={colour1}
        />
        <path
          d="M21 13H15.6046C15.719 12.3902 15.8124 11.7159 15.8589 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13Z"
          fill={colour1}
        />
        <path
          clipRule="evenodd"
          d="M12.0244 2.09083C12.0991 1.96732 12.2713 1.96712 12.3467 2.0902C12.9252 3.03399 14.8588 6.40652 14.9606 9.47693C15.004 10.7836 14.8607 12.0123 14.6602 13.0486C14.6441 13.1317 14.6814 13.2161 14.7541 13.2597L16.1575 14.1001C16.2083 14.1306 16.243 14.182 16.2523 14.2405L16.7797 17.5741C16.8063 17.7426 16.6233 17.865 16.4778 17.776L13.7979 16.1366C13.7665 16.1174 13.7304 16.1072 13.6935 16.1072H10.6516C10.6148 16.1072 10.5786 16.1174 10.5472 16.1366L7.86896 17.7755C7.72342 17.8646 7.54036 17.7422 7.56702 17.5737L8.09437 14.2401C8.10363 14.1816 8.13834 14.1302 8.18916 14.0998L9.64445 13.2282C9.71708 13.1847 9.75439 13.1004 9.73828 13.0173C9.53528 11.9701 9.38321 10.743 9.40848 9.47693C9.46734 6.52757 11.4419 3.05343 12.0244 2.09083ZM13.5055 7.20767C13.5055 7.93583 12.9141 8.52612 12.1846 8.52612C11.455 8.52612 10.8636 7.93583 10.8636 7.20767C10.8636 6.47952 11.455 5.88923 12.1846 5.88923C12.9141 5.88923 13.5055 6.47952 13.5055 7.20767Z"
          fill={colour2}
          fillRule="evenodd"
        />
        <path
          d="M10.5862 20.1799C10.8636 20.8566 11.6356 21.6201 11.9713 21.9315C12.0619 22.0156 12.1992 22.0156 12.2899 21.9315C12.6256 21.6201 13.3975 20.8566 13.6749 20.1799C14.0879 19.1727 13.745 17.756 13.6055 17.2708C13.576 17.1679 13.4812 17.1 13.3741 17.1H10.8871C10.78 17.1 10.6852 17.1679 10.6556 17.2708C10.5162 17.756 10.1733 19.1727 10.5862 20.1799Z"
          fill={colour3}
        />
      </g>
      <defs>
        <clipPath id="clip0_1991_122198">
          <rect
            fill="white"
            height="20"
            transform="translate(2 2)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
