type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const BasketQuantitiesIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Basket quantities report"}</title>
      <g clipPath="url(#clip0_1991_122547)">
        <path
          d="M12.668 16.4426C12.7462 16.5862 12.7853 16.7583 12.7853 16.9588C12.7853 17.1562 12.7478 17.3268 12.6726 17.4704C12.6004 17.614 12.4951 17.7248 12.3568 17.8026C12.2214 17.8774 12.0605 17.9148 11.874 17.9148C11.6875 17.9148 11.5236 17.8774 11.3823 17.8026C11.2439 17.7248 11.1356 17.614 11.0574 17.4704C10.9822 17.3268 10.9446 17.1562 10.9446 16.9588C10.9446 16.7583 10.9822 16.5862 11.0574 16.4426C11.1356 16.296 11.2439 16.1838 11.3823 16.106C11.5206 16.0282 11.6815 15.9893 11.865 15.9893C12.0485 15.9893 12.2094 16.0282 12.3477 16.106C12.4861 16.1838 12.5929 16.296 12.668 16.4426Z"
          fill={colour2}
        />
        <path
          d="M12.5598 13.633C12.6229 13.7616 12.6545 13.9142 12.6545 14.0908C12.6545 14.2673 12.6229 14.4229 12.5598 14.5576C12.4996 14.6892 12.4109 14.7924 12.2936 14.8672C12.1793 14.9421 12.0394 14.9795 11.874 14.9795C11.7086 14.9795 11.5672 14.9421 11.4499 14.8672C11.3326 14.7924 11.2424 14.6892 11.1792 14.5576C11.1191 14.4229 11.089 14.2673 11.089 14.0908C11.089 13.9083 11.1191 13.7527 11.1792 13.624C11.2424 13.4924 11.3311 13.3921 11.4454 13.3233C11.5627 13.2545 11.7026 13.2201 11.865 13.2201C12.0334 13.2201 12.1748 13.256 12.2891 13.3278C12.4064 13.3996 12.4966 13.5013 12.5598 13.633Z"
          fill={colour2}
        />
        <path
          clipRule="evenodd"
          d="M6.09889 21.9984C5.3898 21.9984 4.77129 21.5296 4.47082 20.8526L2.09123 10.9996L2 10.5413C1.99981 10.265 2.0954 10.0001 2.26575 9.80478C2.43611 9.60945 2.66726 9.49972 2.90837 9.49972L11.248 9.50101L11.9895 9.49835L12.748 9.50122L21.0902 9.49972C21.3313 9.49972 21.5626 9.60945 21.7332 9.80478C21.9038 10.0001 21.9998 10.265 22 10.5413L21.9638 10.8433L19.5436 20.8526C19.244 21.5296 18.6262 21.9984 17.9171 21.9984H6.09889ZM13.805 18.0808C13.9974 17.7996 14.0937 17.454 14.0937 17.044C14.0937 16.676 13.9974 16.3558 13.805 16.0836C13.6191 15.8206 13.3658 15.6149 13.0453 15.4663C13.3148 15.3274 13.5289 15.1442 13.6877 14.9166C13.8651 14.6593 13.9538 14.3646 13.9538 14.0324C13.9538 13.6375 13.8636 13.3053 13.6831 13.036C13.5057 12.7638 13.2591 12.5573 12.9433 12.4167C12.6305 12.276 12.271 12.2057 11.865 12.2057C11.465 12.2057 11.1071 12.276 10.7913 12.4167C10.4785 12.5573 10.2318 12.7638 10.0514 13.036C9.87391 13.3053 9.78518 13.6375 9.78518 14.0324C9.78518 14.3646 9.87391 14.6593 10.0514 14.9166C10.21 15.144 10.424 15.3271 10.6932 15.466C10.5393 15.5368 10.399 15.623 10.2724 15.7245C10.0739 15.8831 9.91903 16.0746 9.80774 16.299C9.69646 16.5204 9.64082 16.7688 9.64082 17.044C9.64082 17.454 9.73857 17.7996 9.93406 18.0808C10.1296 18.3591 10.3957 18.57 10.7326 18.7137C11.0695 18.8543 11.4499 18.9246 11.874 18.9246C12.2981 18.9246 12.6771 18.8543 13.0109 18.7137C13.3478 18.57 13.6125 18.3591 13.805 18.0808Z"
          fill={colour2}
          fillRule="evenodd"
        />
        <path
          d="M7.74275 5.22554C5.88607 5.22554 4.36705 6.67124 4.25 8.49836H11.2355C11.1184 6.67124 9.59942 5.22554 7.74275 5.22554Z"
          fill={colour3}
        />
        <path
          d="M19.751 3.11575L14.2863 2.01003C14.0156 1.95526 13.7518 2.13028 13.6971 2.40094L12.4633 8.49839H19.1721L20.142 3.70498C20.1967 3.43433 20.0217 3.17052 19.751 3.11575Z"
          fill={colour1}
        />
      </g>
      <defs>
        <clipPath id="clip0_1991_122547">
          <rect
            fill="white"
            height="20"
            transform="translate(2 2)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
