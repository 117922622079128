import {
  type SelectionDto,
  type ParameterDto,
  isListSelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

export type DropdownOption = {
  label: string;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface DropdownParameterState extends ParameterState {
  selection: DropdownOption | undefined;
}

export const isDropdownParameterState = (
  state: ParameterState | undefined
): state is DropdownParameterState =>
  state !== undefined && Object.hasOwn(state, "selection");

export const newDropdownParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): DropdownParameterState => {
  let selection =
    parameterConfig.options.find((option) => {
      if (savedSelections) {
        return savedSelections.find((savedSelection) => {
          const value = isListSelectionDto(savedSelection)
            ? savedSelection.value
            : undefined;
          return value === option.value;
        });
      }

      return option.isDefault;
    }) ?? parameterConfig.options.at(0);

  const prefilledSelection = parameterConfig.selections ?? [];

  if (prefilledSelection.length > 0) {
    const existingSelection = prefilledSelection[0];
    if (isListSelectionDto(existingSelection)) {
      selection = parameterConfig.options.find(
        (option) => option.label === existingSelection.label
      );
    }
  }

  return {
    isValid: isBetweenMinMax(1, parameterConfig),
    parameterConfig,
    parameterGroup,
    selection,
  };
};

export const getDropdownParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as DropdownParameterState
  );

export const handleDropdownParameterSelection = (
  parameterState: ParameterState,
  selection: DropdownOption
): ParameterState => {
  if (isDropdownParameterState(parameterState)) {
    return {
      ...parameterState,
      isValid: isBetweenMinMax(1, parameterState.parameterConfig),
      selection,
    } as DropdownParameterState;
  }

  return { ...parameterState };
};
