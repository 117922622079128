import {
  ddLog,
  getBaseQuery,
  isParameterConfigurationDto,
  type ParameterConfigurationsDto,
} from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const reportRerunApi = createApi({
  baseQuery: getBaseQuery(`/`),
  endpoints: (builder) => ({
    getRerunParameterConfigurations: builder.query<
      ParameterConfigurationsDto,
      { divisionName: string; reportType: string; rerunReportId: string }
    >({
      async onQueryStarted(argument, { queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;

          // `onSuccess` side-effect
          if (!isParameterConfigurationDto(data)) {
            throw new Error(
              `getRerunParameterConfigurations error: received response does not match what is expected: ${JSON.stringify(
                data
              )} `
            );
          }

          if (!data.parameterGroups) {
            throw new Error(
              `getParameterConfigurations error: received response does not contain parameterConfig: ${JSON.stringify(
                data
              )} `
            );
          }
        } catch (error) {
          // `onError` side-effect
          ddLog("ERROR", {}, "error", error as Error);
        }
      },
      providesTags: ["ValidateAndRerunReport"],
      query: ({ divisionName, reportType, rerunReportId }) =>
        `/api/${reportType}/ValidateAndReRunReport/${divisionName}/${rerunReportId}`,
    }),
  }),
  reducerPath: "reportRerunApi",
  tagTypes: ["ValidateAndRerunReport"],
});

export const { useLazyGetRerunParameterConfigurationsQuery } = reportRerunApi;

export default reportRerunApi.reducer;
