import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { Text } from "@quantium-enterprise/qds-react";
import {
  type SortingState,
  type ColumnDef,
  type OnChangeFn,
} from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import {
  type AggregateRankTableRow,
  type AggregateRankTableMetaData,
} from "../../../../reports/src/aggregate-rank/models/aggregate-rank-common-models";
import { NoDataChartWrapper } from "../../../../reports/src/common/components/NoDataChartWrapper";
import { HierarchyLevelIcon } from "../../hierarchy-level-icon/HierarchyLevelIcon";
import { EmptySearch } from "../../search/EmptySearch";
import { SearchBox } from "../../search-box/SearchBox";
import {
  calculatePercentile,
  PercentileScaleLegend,
  ScaleLegendColors,
} from "../common/legend/ScaleLegend";
import {
  GraphicsCell,
  GraphicsCellMetricType,
} from "../common/table-cell/GraphicsCell";
import { VirtuosoTableComponent } from "../virtuoso-table/VirtuosoTableComponent";
import styles from "./AggregateRankTable.module.scss";

const IconHeader = ({
  iconCode,
  headerName,
}: {
  headerName: string;
  iconCode: string;
}) => (
  <div className={styles.iconHeader}>
    <div className={styles.icon}>
      <HierarchyLevelIcon
        shortName={iconCode}
        type={HierarchyItemType.Hierarchy}
      />
    </div>
    <Text>{headerName}</Text>
  </div>
);

export type AggregateRankTableProps = {
  dataRows: AggregateRankTableRow[];
  height?: number;
  metaData: AggregateRankTableMetaData;
  onLoadMore: () => Promise<void>;
  onSearchChange: (searchText: string) => void;
  onSort: OnChangeFn<SortingState>;
  rowsLoading?: boolean;
  searchResultCount?: number;
  searchText: string;
  showRanks: boolean;
  sortingState: SortingState;
  totalRows: number;
};

export const AggregateRankTable = ({
  metaData,
  dataRows,
  totalRows,
  showRanks,
  onSort,
  sortingState,
  onLoadMore,
  height = 500,
  rowsLoading = false,
  searchText,
  searchResultCount,
  onSearchChange,
}: AggregateRankTableProps) => {
  const formatter = useFormatter();

  const headerCallbackWrapper = useCallback(
    (element: JSX.Element) => () => element,
    []
  );

  const memoizedDefaultColumnDefs: Array<ColumnDef<AggregateRankTableRow>> =
    useMemo(
      () => [
        {
          id: "LOA",
          columns: [
            {
              id: "LoaSearchBox",
              header: headerCallbackWrapper(
                <div className={styles.searchBox}>
                  <SearchBox
                    enableDebounce
                    onChange={onSearchChange}
                    placeholder="Type to search"
                    resultCount={
                      searchResultCount && !rowsLoading
                        ? searchResultCount
                        : undefined
                    }
                    searchQuery={searchText}
                  />
                </div>
              ),
              accessorFn: (row: AggregateRankTableRow) => row,
              cell: ({ getValue }: { getValue: Function }) =>
                GraphicsCell({
                  color:
                    ScaleLegendColors[
                      calculatePercentile(getValue().aggregateRank, totalRows)
                    ],
                  hoverOverflow: true,
                  justifyContent: "space-between",
                  type: GraphicsCellMetricType.Percentile,
                  value: `${getValue().productName}`,
                  formatter: formatter(MetricTypes.String),
                }),
              enableSorting: false,
            },
          ],
          header: headerCallbackWrapper(
            <IconHeader
              headerName={metaData.levelOfAnalysis.name}
              iconCode={metaData.levelOfAnalysis.code}
            />
          ),
        },
        ...(metaData.attributes?.length
          ? [
              {
                header: "Attributes",
                columns: metaData.attributes.map(({ name, code }) => ({
                  id: code,
                  header: headerCallbackWrapper(
                    <IconHeader headerName={name} iconCode={code} />
                  ),
                  accessorFn: (row: AggregateRankTableRow) =>
                    row.attributes[code],
                  cell: ({ getValue }: { getValue: Function }) =>
                    GraphicsCell({
                      hoverOverflow: true,
                      justifyContent: "left",
                      value: getValue(),
                      formatter: formatter(MetricTypes.String),
                    }),
                  // Disable sorting on the Attribute columns for now.
                  enableSorting: false,
                })),
              },
            ]
          : []),
        {
          header: "Aggregate",
          columns: [
            {
              accessorFn: (row: AggregateRankTableRow) => row.aggregateRank,
              id: "Rank",
              header: "Rank",
              cell: ({ getValue }: { getValue: Function }) =>
                GraphicsCell({
                  value: getValue(),
                  formatter: formatter(MetricTypes.Integer),
                }),
              sortDescFirst: false,
            },
            {
              accessorFn: (row: AggregateRankTableRow) => row.aggregateScore,
              id: "Score",
              header: "Score",
              cell: ({ getValue }: { getValue: Function }) =>
                GraphicsCell({
                  value: getValue(),
                  formatter: formatter(MetricTypes.Integer),
                }),
              sortDescFirst: false,
            },
          ],
        },
        ...(metaData.rankedMetrics.length > 0
          ? [
              {
                header: "Ranked metrics",
                columns: metaData.rankedMetrics.flatMap(
                  ({ name, type, format }) => [
                    {
                      id: name,
                      header: headerCallbackWrapper(
                        <div className={styles.elipsisOverflow}>{name}</div>
                      ),
                      accessorFn: (row: AggregateRankTableRow) =>
                        row.rankedMetrics[name],
                      cell: ({ getValue }: { getValue: Function }) =>
                        GraphicsCell({
                          value: getValue()?.value,
                          formatter: formatter(format),
                          color:
                            ScaleLegendColors[
                              getValue()
                                ? calculatePercentile(
                                    getValue().rank,
                                    totalRows
                                  )
                                : 0
                            ],
                          type,
                        }),
                    },

                    ...(showRanks
                      ? [
                          {
                            id: `${name}-rank`,
                            header: headerCallbackWrapper(
                              <div
                                className={styles.elipsisOverflow}
                              >{`Rank - ${name}`}</div>
                            ),
                            accessorFn: (row: AggregateRankTableRow) =>
                              row.rankedMetrics[name],
                            cell: ({ getValue }: { getValue: Function }) =>
                              GraphicsCell({
                                formatter: formatter(MetricTypes.Integer),
                                value: getValue()?.rank,
                              }),
                            enableSorting: false,
                          },
                        ]
                      : []),
                  ]
                ),
              },
            ]
          : []),
        ...(metaData.unrankedMetrics?.length
          ? [
              {
                header: "Unranked metrics",
                columns: metaData.unrankedMetrics.map(
                  ({ name, type, format }) => ({
                    id: name,
                    header: headerCallbackWrapper(
                      <div className={styles.elipsisOverflow}>{name}</div>
                    ),
                    accessorFn: (row: AggregateRankTableRow) =>
                      row.unrankedMetrics[name],
                    cell: ({ getValue }: { getValue: Function }) =>
                      GraphicsCell({
                        value: getValue(),
                        formatter: formatter(format),
                        type,
                      }),
                  })
                ),
              },
            ]
          : []),
      ],

      [
        headerCallbackWrapper,
        metaData.levelOfAnalysis.name,
        metaData.levelOfAnalysis.code,
        metaData.attributes,
        metaData.rankedMetrics,
        metaData.unrankedMetrics,
        onSearchChange,
        searchResultCount,
        rowsLoading,
        searchText,
        totalRows,
        formatter,
        showRanks,
      ]
    );

  // There is more rows to load IF current number of rows less than total rows when search is off
  // Otherwise there are more rows to load if current number of rows less than searchResult count when search is one.
  const hasMoreRows =
    (!rowsLoading && dataRows.length < totalRows && !searchText) ||
    (searchResultCount !== undefined && dataRows.length < searchResultCount);

  return (
    <div className={styles.aggregateRankReportletGroup}>
      <div className={styles.aggregateRankTableContainer} style={{ height }}>
        <VirtuosoTableComponent
          columns={memoizedDefaultColumnDefs}
          data={
            rowsLoading
              ? []
              : dataRows.concat(
                  // @ts-expect-error: this adds load more row, but does match AggregateRankTableRow type
                  hasMoreRows ? [{ moreLabel: "true" }] : []
                )
          }
          enableSorting
          enableSortingRemoval={false}
          getRowId={(row: AggregateRankTableRow) => row.productName}
          handleMoreClicked={onLoadMore}
          manualSorting
          onSortingChange={onSort}
          pinFirstColumn
          sortByDescendingFirst
          sorting={sortingState}
        />

        {(rowsLoading || (dataRows.length === 0 && !searchText)) && (
          <div className={styles.spinner}>
            <NoDataChartWrapper
              isLoading={rowsLoading}
              noData={dataRows.length === 0}
            />
          </div>
        )}

        {!rowsLoading && dataRows.length === 0 && searchText && (
          <div className={styles.emptySearch}>
            <EmptySearch />
          </div>
        )}
      </div>
      <PercentileScaleLegend title="Performance rank %:" />
    </div>
  );
};
