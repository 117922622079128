import classNames from "classnames";
import styles from "./KeyDriverTreeNodeContent.module.css";

type KeyDriverTreeNodeContentProps = {
  benchmark?: string;
  change?: string;
  contribution?: string;
  measure: string;
  value: string;
};

// We coalesce '\xa0' (non-breaking whitespace) with change and contribution
// so that the lines are always present. Position of the lines in the correct
// order is important as the position is a visual identification of what the
// value represents.
export const KeyDriverTreeNodeContent = ({
  benchmark = "",
  change = "",
  contribution = "",
  measure,
  value,
}: KeyDriverTreeNodeContentProps) => (
  <div className={styles.keyDriverTreeNodeContent} key={measure}>
    <div className={styles.measure}>{measure}</div>
    <div className={styles.value}>{value}</div>

    <div className={styles.changeAndBenchmark}>
      <div
        className={classNames(styles.change, {
          [styles.negVal]: change.startsWith("-"),
          [styles.posVal]: change.startsWith("+"),
          [styles.neutralVal]:
            !change.startsWith("-") && !change.startsWith("+"),
        })}
      >
        {change || "\u00A0"}
      </div>
      {benchmark && (
        <div
          className={classNames(styles.benchmark, {
            [styles.negVal]: benchmark.startsWith("-"),
            [styles.posVal]: benchmark.startsWith("+"),
            [styles.neutralVal]:
              !benchmark.startsWith("-") && !benchmark.startsWith("+"),
          })}
        >
          ({benchmark})
        </div>
      )}
    </div>
    <div
      className={classNames(styles.contribution, {
        [styles.negVal]: contribution.startsWith("-"),
        [styles.posVal]: contribution.startsWith("+"),
        [styles.neutralVal]:
          !contribution.startsWith("-") && !contribution.startsWith("+"),
      })}
    >
      {contribution || "\u00A0"}
    </div>
  </div>
);
