import classNames from "classnames";
import { type TableProps } from "react-virtuoso";
import commonStyles from "../common/GridTable.module.css";
import styles from "./VirtuosoTable.module.css";

export const PERCENT_WIDTH = "100%";

export type VirtuosoTableElementProps = TableProps & {
  "aria-rowcount": number;
  className?: string;
  tableWidth?: number | string;
};

export const VirtuosoTableElement = ({
  style,
  className,
  // can be a value in px, or a string value to set the table width
  tableWidth = PERCENT_WIDTH,
  ...properties
}: VirtuosoTableElementProps) => (
  <table
    {...properties}
    className={classNames(styles.virtuosoTable, commonStyles.table, className)}
    data-testid="virtuoso-table"
    role="table"
    style={{
      ...style,
      width: typeof tableWidth === "string" ? tableWidth : `${tableWidth}px`,
    }}
  />
);
