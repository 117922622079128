import {
  type FastReportingTabMetadata,
  type AdvancedReportingTabMetadata,
} from "../models/ReportTab";

export const isAdvancedReportingTabMetadata = (
  metadata: AdvancedReportingTabMetadata | FastReportingTabMetadata
): metadata is AdvancedReportingTabMetadata =>
  Object.hasOwn(metadata, "path") &&
  Object.hasOwn(metadata, "name") &&
  Object.hasOwn(metadata, "type") &&
  Object.hasOwn(metadata, "dataSource") &&
  Object.hasOwn(metadata, "reportDisplayName");
