import {
  Checkbox,
  FormBlock,
  FormBlockEditability,
  FormBlockType,
  Input,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import classNames from "classnames";
import styles from "./RepertoireSelectCell.module.css";

export type RepertoireSelectCellProps = {
  isFocalItem: boolean;
  isRepertoireItem: boolean;
  isRowDisabled: boolean;
  onFocalItemSelect: (value: string) => void;
  onRepertoireSelect: (value: string) => void;
  rowId: string;
};

export const RepertoireSelectCell = ({
  isFocalItem,
  isRepertoireItem,
  onFocalItemSelect,
  onRepertoireSelect,
  rowId,
  isRowDisabled,
}: RepertoireSelectCellProps) => {
  const repertoireCell = (
    <div>
      <FormBlock
        blockType={FormBlockType.Checkbox}
        className={classNames(styles.checkbox, {
          [styles.isDisabled]: isRowDisabled,
        })}
      >
        <Input>
          <Checkbox
            checked={isRepertoireItem}
            editability={
              isFocalItem
                ? FormBlockEditability.Disabled
                : FormBlockEditability.Editable
            }
            label=""
            name={`${rowId}-repertoire-select`}
            onChange={() => {
              onRepertoireSelect(rowId);
            }}
          />
        </Input>
      </FormBlock>
    </div>
  );

  const repertoireCellWithTooltip = (
    <div className={styles.selectCell}>
      <FormBlock
        blockType={FormBlockType.Checkbox}
        className={classNames(styles.checkbox, {
          [styles.isDisabled]: isRowDisabled,
        })}
      >
        <Input>
          <Checkbox
            checked={isFocalItem}
            label=""
            name={`${rowId}-focal-item-select`}
            onChange={() => {
              onFocalItemSelect(rowId);
            }}
          />
        </Input>
      </FormBlock>
      {isFocalItem ? (
        <Tooltip
          placement={TooltipPlacement.RightCentre}
          spaceInside={TooltipSpaceInside.Medium}
          trigger={repertoireCell}
          variant={TooltipVariant.ArrowDark}
        >
          <div className={styles.repertoireCheckboxTooltipContent}>
            Repertoire is automatically selected with the focal item.
          </div>
        </Tooltip>
      ) : (
        repertoireCell
      )}
    </div>
  );

  return isRowDisabled ? (
    <Tooltip
      className={styles.disabledRowTooltip}
      placement={TooltipPlacement.RightCentre}
      spaceInside={TooltipSpaceInside.Medium}
      trigger={repertoireCellWithTooltip}
      variant={TooltipVariant.ArrowDark}
    >
      <div className={styles.repertoireCheckboxTooltipContent}>
        Selection limit of 50 items reached. Please deselect items to enable
        selection.
      </div>
    </Tooltip>
  ) : (
    repertoireCellWithTooltip
  );
};

export default RepertoireSelectCell;
