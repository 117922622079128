import { ddLog, TransactionSource } from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Toggle,
  ToggleSize,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@quantium-enterprise/qds-react";
import { type SortingState } from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { AggregateRankTable } from "components-ui/src/tables/aggregate-rank-table/AggregateRankTable";
import { GraphicsCellMetricType } from "components-ui/src/tables/common/table-cell/GraphicsCell";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { type SidePanelTimePeriodParameter } from "../../../common/models/local-parameters/SidePanelParameters";
import { type RootState } from "../../../store";
import { AggregateRankFeatureFlags } from "../../constants/aggregate-rank-feature-flags";
import {
  type AggregateRankMetric,
  type AggregateRankTableMetaData,
} from "../../models/aggregate-rank-common-models";
import { useLazyDownloadTableQuery } from "../../services/aggregate-rank-export-api-slice";
import {
  onSearchChange,
  onTableLoadMoreResponse,
  onTableResponse,
  selectLevelOfAnalysis,
  selectLocalParametersSelections,
  selectReportId,
  selectDataset,
} from "../../services/aggregate-rank-slice";
import {
  type AggregateRankTableRequest,
  useGetTableRowsQuery,
  useLazyGetTableRowsQuery,
} from "../../services/aggregate-rank-table-api-slice";
import { timeData } from "../../utils/getExportFunctions";
import { getUserSelections } from "../../utils/local-parameter-utils";
import AggregateRankModal from "../aggregate-rank-modal/AggregateRankModal";
import styles from "./AggregateRankTableQueryWrapper.module.css";

const AGGREGRATE_RANK_TABLE_PAGE_SIZE = 300;

export const AggregateRankTableQueryWrapper = () => {
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[AggregateRankFeatureFlags.ReportExport] ?? false;

  const [sortingState, setSortingState] = useState<SortingState>([
    { id: "Rank", desc: false },
  ]);
  const [showRanks, setShowRanks] = useState<boolean>(false);
  const [hideDeletedRows, setHideDeletedRows] = useState<boolean>(false);

  const reportId = useSelector(selectReportId);
  const selectedLevelOfAnalysis = useSelector(selectLevelOfAnalysis);
  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );
  const dataset = useSelector(selectDataset);

  const {
    selectedAttributes,
    rankedMetrics,
    weights,
    unrankedMetrics,
    metricsMetadata,
    attributeMetadata,
    levelOfAnalysisOptions,
    tableRows,
    totalRows,
    localParametersInitialised,
    modalInitialised,
    localParametersConfig,
    searchText,
    searchResultCount,
  } = useSelector((state: RootState) => ({
    selectedAttributes: state.aggregateRank.selectedAttributes,
    rankedMetrics: state.aggregateRank.rankedMetrics,
    weights: state.aggregateRank.weights,
    unrankedMetrics: state.aggregateRank.unrankedMetrics,
    metricsMetadata: state.aggregateRank.metricsMetaData,
    attributeMetadata: state.aggregateRank.attributeMetaData,
    levelOfAnalysisOptions: state.aggregateRank.levelOfAnalysisOptions,
    tableRows: state.aggregateRank.tableRows,
    totalRows: state.aggregateRank.totalTableRows,
    localParametersInitialised: state.aggregateRank.localParametersInitialised,
    modalInitialised: state.aggregateRank.modalInitialised,
    localParametersConfig: state.aggregateRank.localParametersConfig,
    searchText: state.aggregateRank.tableSearchText,
    searchResultCount: state.aggregateRank.tableSearchResultCount,
  }));

  const tableMetrics: AggregateRankMetric[] = useMemo(
    () =>
      metricsMetadata.map((metric) => ({
        format: metric.format,
        name: metric.metricLabel,
        type: metric.isGrowthMetric
          ? GraphicsCellMetricType.Growth
          : GraphicsCellMetricType.Percentile,
      })),
    [metricsMetadata]
  );

  const rankedMetricsMetadata = useMemo(
    () =>
      tableMetrics
        .filter((metric) => rankedMetrics.includes(metric.name))
        .sort(
          (a, b) =>
            rankedMetrics.indexOf(a.name) - rankedMetrics.indexOf(b.name)
        ),
    [rankedMetrics, tableMetrics]
  );

  const unrankedMetricsMetadata = useMemo(
    () =>
      tableMetrics
        .filter((metric) => unrankedMetrics.includes(metric.name))
        .sort(
          (a, b) =>
            unrankedMetrics.indexOf(a.name) - unrankedMetrics.indexOf(b.name)
        ),
    [unrankedMetrics, tableMetrics]
  );

  const tableAttributes = useMemo(
    () =>
      attributeMetadata
        .filter((attribute) => selectedAttributes.includes(attribute.shortName))
        .map((attribute) => ({
          code: attribute.shortName,
          name: attribute.displayName,
        })),
    [attributeMetadata, selectedAttributes]
  );

  const levelOfAnalysisMetadata = useMemo(() => {
    const levelOfAnalysis = levelOfAnalysisOptions.find(
      (option) => option.value === selectedLevelOfAnalysis.value.toString()
    );

    return {
      code: levelOfAnalysis?.value ?? "",
      name: levelOfAnalysis?.label ?? "",
    };
  }, [levelOfAnalysisOptions, selectedLevelOfAnalysis]);

  const metadata: AggregateRankTableMetaData = {
    rankedMetrics: rankedMetricsMetadata,
    unrankedMetrics: unrankedMetricsMetadata,
    levelOfAnalysis: levelOfAnalysisMetadata,
    attributes: tableAttributes,
  };

  const requestRankedMetrics = useMemo(
    () =>
      rankedMetrics.map((metric, index) => ({
        metric,
        weight: weights[index].metricWeight,
      })),
    [rankedMetrics, weights]
  );

  const sortingProperty = useMemo(() => {
    // Defaulter - this shouldn't ever trigger but worth having to prevent undefined state
    if (sortingState.length < 1) {
      return undefined;
    }

    const { id: columnId, desc: isDescending } = sortingState[0];

    return {
      isDescending,
      metric:
        columnId === "Rank" || columnId === "Score" ? undefined : columnId,
      // Currently we cannot sort by an of the individual metric rank isRankColumn always false
      isRankColumn: false,
    };
  }, [sortingState]);

  const request: AggregateRankTableRequest = useMemo(
    () => ({
      attributes: selectedAttributes,
      localSelectedValues: getUserSelections(localParametersSelections),
      rankedMetrics: requestRankedMetrics,
      reportId,
      unrankedMetrics,
      sortProperties: sortingProperty,
      searchText,
      hideDeleted:
        localParametersSelections.LevelOfAnalysis.value === "PD"
          ? hideDeletedRows
          : false,
    }),
    [
      hideDeletedRows,
      localParametersSelections,
      reportId,
      requestRankedMetrics,
      searchText,
      selectedAttributes,
      sortingProperty,
      unrankedMetrics,
    ]
  );

  // Fetch table query - initial table and also when local parameters change
  const {
    data: tableResponse,
    isFetching: isTableDataFetching,
    isError,
  } = useGetTableRowsQuery(
    {
      divisionName: activeDivisionName,
      payload: {
        ...request,
        paginationProperties: {
          pageSize: AGGREGRATE_RANK_TABLE_PAGE_SIZE,
          skipRows: 0,
        },
      },
    },
    {
      skip: !localParametersInitialised || !modalInitialised,
    }
  );

  useEffect(() => {
    if (tableResponse) {
      dispatch(onTableResponse(tableResponse));
    }
  }, [dispatch, tableResponse]);

  const [getMoreRowsTrigger] = useLazyGetTableRowsQuery({
    refetchOnFocus: false,
  });

  const fetchMoreRows = useCallback(async () => {
    const payload: AggregateRankTableRequest = {
      ...request,
      paginationProperties: {
        pageSize: AGGREGRATE_RANK_TABLE_PAGE_SIZE,
        skipRows: tableRows.length,
      },
    };

    const { data } = await getMoreRowsTrigger({
      divisionName: activeDivisionName,
      payload,
    });

    if (data) {
      dispatch(onTableLoadMoreResponse(data));
    }
  }, [
    activeDivisionName,
    dispatch,
    getMoreRowsTrigger,
    request,
    tableRows.length,
  ]);

  const handleOnLoadMore = async () => {
    await fetchMoreRows().catch((error) => {
      ddLog("ERROR", {}, "error", error);
    });
  };

  // Filter deleted products depending on toggle
  const filteredRows = useMemo(
    () =>
      hideDeletedRows ? tableRows.filter((row) => !row.isDeleted) : tableRows,
    [hideDeletedRows, tableRows]
  );

  const timeValue = timeData(
    localParametersConfig[0] as SidePanelTimePeriodParameter
  );

  const [downloadTableTrigger] = useLazyDownloadTableQuery();
  const fileName = useMemo(
    () =>
      cleanFilename(
        `Aggregate_Rank_${timeValue.timePeriodLength}_${localParametersSelections.LocationHierarchy.name}`
      ),
    [localParametersSelections, timeValue]
  );

  const isSegmentationDisabled = useMemo(
    () =>
      dataset.value === TransactionSource.Total &&
      localParametersSelections.Segmentation.length,
    [dataset, localParametersSelections]
  );

  const parameterSummary = useMemo(
    () => [
      { name: "Channel", value: localParametersSelections.Channel.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localParametersSelections.LocationHierarchy.shortName
        )}) ${localParametersSelections.LocationHierarchy.name}`,
      },
      { name: "Time", value: timeValue.time },
      ...(dataset.label
        ? [{ name: "Transaction set", value: dataset.label }]
        : []),
      {
        name: "Level of Analysis",
        value: localParametersSelections.LevelOfAnalysis.label,
      },
      {
        name: "Segmentation",
        value: isSegmentationDisabled
          ? "N/A"
          : localParametersSelections.Segmentation[0]?.label ?? "",
      },
      {
        name: "Customer segment",
        value: isSegmentationDisabled
          ? "N/A"
          : localParametersSelections.Segmentation[1]?.label ?? "",
      },
    ],
    [localParametersSelections, timeValue, dataset, isSegmentationDisabled]
  );

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName: activeDivisionName,
      payload: {
        localParameters: parameterSummary,
        localSelectedValues: getUserSelections(localParametersSelections),
        attributes: selectedAttributes,
        rankedMetrics: requestRankedMetrics,
        unRankedMetrics: unrankedMetrics,
        sortProperties: sortingProperty,
        fileName,
        reportId,
        showRanks,
      },
    });
  }, [
    downloadTableTrigger,
    activeDivisionName,
    parameterSummary,
    localParametersSelections,
    selectedAttributes,
    requestRankedMetrics,
    unrankedMetrics,
    sortingProperty,
    fileName,
    reportId,
    showRanks,
  ]);

  return (
    <div className={styles.tableContent}>
      <div className={styles.reportletControls}>
        <AggregateRankModal />
        <div className={styles.toggleGroup}>
          <Toggle
            checked={showRanks}
            className={styles.toggle}
            label="Show metric ranks"
            onClick={() => setShowRanks(!showRanks)}
            size={ToggleSize.XSmall}
          />

          {localParametersSelections.LevelOfAnalysis.value === "PD" ? (
            <Toggle
              checked={hideDeletedRows}
              className={styles.toggle}
              disabled={false}
              label="Hide deleted lines"
              onClick={() => setHideDeletedRows(!hideDeletedRows)}
              size={ToggleSize.XSmall}
            />
          ) : (
            <Tooltip
              key="disabled-deleted-lines-toggle"
              placement={TooltipPlacement.TopCentre}
              spaceInside={TooltipSpaceInside.Medium}
              trigger={
                <div>
                  <Toggle
                    checked={false}
                    className={styles.toggle}
                    disabled
                    label="Hide deleted lines"
                    onClick={() => setHideDeletedRows(!hideDeletedRows)}
                    size={ToggleSize.XSmall}
                  />
                </div>
              }
              variant={TooltipVariant.ArrowDark}
            >
              <div className={styles.toggleTooltip}>
                Available only when the level of analysis is Product
              </div>
            </Tooltip>
          )}
          <div>
            <DataTableOptions
              filename={fileName}
              invokeCSVDownload={handleDownloadButtonClick}
              isFeatureEnabled={isExportEnabled}
              key={reportId}
            />
          </div>
        </div>
      </div>

      <NoDataChartWrapper
        isLoading={!localParametersInitialised || !modalInitialised}
        noData={isError}
      >
        <AggregateRankTable
          dataRows={filteredRows}
          height={650}
          metaData={metadata}
          onLoadMore={handleOnLoadMore}
          onSearchChange={(value: string) => dispatch(onSearchChange(value))}
          onSort={setSortingState}
          rowsLoading={isTableDataFetching}
          searchResultCount={searchResultCount}
          searchText={searchText}
          showRanks={showRanks}
          sortingState={sortingState}
          totalRows={totalRows}
        />
      </NoDataChartWrapper>
    </div>
  );
};

export default AggregateRankTableQueryWrapper;
