import {
  Toggle,
  ToggleLayout,
  ToggleSize,
} from "@quantium-enterprise/qds-react";
import styles from "./ToggleSelectTable.module.css";

export type ToggleSelectTableCellProps = {
  isSelected: boolean;
  label: string;
  onSelect: (value: string) => void;
  value: string;
};

export const ToggleSelectTableCell = ({
  isSelected,
  label,
  onSelect,
  value,
}: ToggleSelectTableCellProps) => (
  <Toggle
    checked={isSelected}
    className={styles.toggleCell}
    label={label}
    layout={ToggleLayout.Together}
    onClick={() => onSelect(value)}
    size={ToggleSize.XSmall}
  />
);

export default ToggleSelectTableCell;
