import { Button, ButtonVariant } from "@quantium-enterprise/qds-react";
import { range } from "ramda";
import styles from "./YearPicker.module.css";

export type YearPickerProps = {
  displayMonth: Date;
  fromDate: Date;
  onSelect: (date: Date) => void;
  toDate?: Date;
};

const defaultToDate = new Date();

export const YearPicker = ({
  displayMonth,
  fromDate,
  onSelect,
  toDate = defaultToDate,
}: YearPickerProps) => {
  const years = range(fromDate.getFullYear(), toDate.getFullYear() + 1).map(
    (year: number) => new Date(year, fromDate.getMonth())
  );

  return (
    <div className={styles.yearButtonGroup}>
      {years.map((year: Date) => (
        <Button
          className={styles.yearPickerBtn}
          key={year.getFullYear()}
          onClick={() =>
            onSelect(new Date(year.getFullYear(), displayMonth.getMonth()))
          }
          text={year.getFullYear().toString()}
          variant={ButtonVariant.Stealth}
        />
      ))}
    </div>
  );
};

export default YearPicker;
