import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { type ChartDataSeries } from "components-ui/src/charts/trends-chart/TrendsChart";
import { type ChartSelections } from "../../../models/chart-selections";
import { type TimeOfDayResponseDto } from "../../../models/time-of-day-chart";
import { formatHourId } from "../../../utils/chart-utils";
import { getCsvColumnHeader } from "../../../utils/csv-util";
import {
  getBenchmarkData,
  getDaysData,
  getFocalData,
  getSegmentsData,
} from "../filter-data";

const FocalItemsView = "Focal item(s)";
const SegmentsView = "Customer segments";
const DaysView = "Days";

export const csvTransformation = (
  reportletData: TimeOfDayResponseDto,
  chartSelections: ChartSelections,
  currencySymbol: string,
  focalItemParents: HierarchySliceNodeDto[]
) => {
  let filteredData: ChartDataSeries[] = [];
  const columnHeaders = ["Time"];
  const csvData = [columnHeaders];

  const convertedHours = reportletData.hoursMetadata.map((item) =>
    formatHourId(item)
  );

  if (chartSelections.viewBy.selected === FocalItemsView) {
    filteredData = getFocalData(
      reportletData.focalItems,
      reportletData,
      chartSelections,
      focalItemParents
    );
    const benchmarkData = getBenchmarkData(reportletData, chartSelections);
    if (benchmarkData) {
      filteredData = [...filteredData, benchmarkData];
    }
  } else if (chartSelections.viewBy.selected === SegmentsView) {
    filteredData = getSegmentsData(reportletData, chartSelections);
  } else if (chartSelections.viewBy.selected === DaysView) {
    filteredData = getDaysData(reportletData, chartSelections);
  }

  columnHeaders.push(
    ...filteredData.map((data) =>
      getCsvColumnHeader(
        reportletData.format,
        currencySymbol,
        data.name,
        reportletData.metric
      )
    )
  );

  for (const [index, convertedHour] of convertedHours.entries()) {
    const row = [
      convertedHour,
      ...filteredData.map((data) => (data.data[index] ?? 0).toString()),
    ];
    csvData.push(row);
  }

  return csvData;
};
