import { MetricTypes } from "@quantium-enterprise/hooks-ui";
import { type Series, type Metric } from "components-ui/src/charts/models";
import type Highcharts from "highcharts";

export const mapToSegmentationBreakdownSeries = (
  series: Series[],
  selectedMetric?: Metric,
  selectedItem?: string
): Highcharts.SeriesColumnOptions[] => {
  const highchartsSeries = series
    .filter(
      (item) =>
        item.name === selectedItem && item.metric.name === selectedMetric?.name
    )
    .map(
      (item) =>
        ({
          color: item.color,
          data: Array.from(item.data),
          dataLabels: {
            enabled: item.showDataLabels,
            formatter() {
              return item.metric.formatter(this.y);
            },
          },
          name: item.segmentation,
          tooltip: {
            headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
            pointFormatter(this) {
              return `<tr><td>${
                this.series.name
              }</td> <td style="text-align: right"><b>${selectedMetric?.formatter(
                this.y
              )}</b></td></tr>`;
            },
            footerFormat: "</table>",
          },
          type: "column",
        } as Highcharts.SeriesColumnOptions)
    );
  return highchartsSeries;
};

export const getExportColumnHeader =
  (currencySymbol: string, metricFormat: string, selectedMetric?: string) =>
  (header: string) => {
    let columnHeader = `${selectedMetric} - ${header}`;
    if (metricFormat === MetricTypes.Currency) {
      columnHeader = `${selectedMetric}(${currencySymbol}) - ${header}`;
    }

    return columnHeader;
  };
