import {
  HierarchyItemType,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import { Text } from "@quantium-enterprise/qds-react";
import { uniqueId } from "@quantium-enterprise/qds-react/dist/Common";
import classNames from "classnames";
import { EllipsisLabel } from "components-ui/src/ellipsis-label/EllipsisLabel";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { type CrossShopTableItem } from "reports-ui/src/customer-cross-shop/models/CrossShopDataTableResponseDto";
import { HierarchyLevelIcon } from "../../hierarchy-level-icon/HierarchyLevelIcon";
import { ColorRankType } from "../common/models/ColorRankType";
import { getHeatMapColor } from "../common/utils/getHeatMapColor";
import styles from "./Matrix.module.css";

export type ProductInteractionData = {
  displayName: string;
  format: string;
  values: number[];
};

export type MatrixLeftColumnHeader = CrossShopTableItem;

export type MatrixProps = {
  colorRange: string[];
  colorRankMethod?: ColorRankType;
  detailsFormatter: (value: number | string | null) => string;
  formatter: (value: number | string | null) => string;
  headers: MatrixLeftColumnHeader[];
  productInteractionData: ProductInteractionData;
  selectedItemCodes?: string[];
  showDetails?: boolean;
  values: Array<Array<number | null>>;
  xAxisLabel: string;
  yAxisLabel: string;
};

export const Matrix = ({
  colorRange,
  colorRankMethod = ColorRankType.LINEAR,
  detailsFormatter,
  formatter,
  headers,
  values,
  selectedItemCodes,
  showDetails,
  productInteractionData,
  yAxisLabel,
  xAxisLabel,
}: MatrixProps) => {
  const sortedValues = values
    .flat()
    .filter((a): a is number => Number.isFinite(a))
    .sort((a: number, b: number) => a - b);

  const formattedValue = (value: number | string | null): string => {
    if (value === null) {
      return "-";
    }

    return formatter(value);
  };

  return (
    <table className={styles.matrix}>
      <tbody>
        <tr role="row">
          <th
            className={styles.matrixYAxisHeading}
            rowSpan={headers.length + 1}
          >
            <span className={styles.matrixYAxisHeadingContent}>
              <Text>{yAxisLabel}</Text>
            </span>
          </th>
        </tr>
        {headers.map((header, headerIndex) => (
          <tr key={uniqueId()} role="row">
            <th
              className={styles.leftColumnHeader}
              role="rowheader"
              scope="row"
            >
              <div
                className={classNames(styles.leftColumnHeaderItem, {
                  [styles.selected]: selectedItemCodes?.includes(
                    header.itemCode
                  ),
                })}
              >
                <span className={styles.leftColumnHeaderItemIcon}>
                  {header.shortName && header.shortName !== "GRP" ? (
                    <HierarchyLevelIcon
                      shortName={header.shortName}
                      type={HierarchyItemType.Hierarchy}
                    />
                  ) : null}
                  {header.shortName === "GRP" && header.evaluationType ? (
                    <HierarchyGroupIcon
                      evaluationType={header.evaluationType}
                      hierarchyType={HierarchyType.Product}
                    />
                  ) : null}
                </span>

                <div className={styles.leftColumnHeaderItemLabel}>
                  <EllipsisLabel
                    className={styles.leftColumnHeaderItemEllipsisLabelContent}
                  >
                    <Text title={header.name}>{header.name}</Text>
                  </EllipsisLabel>
                  {showDetails ? (
                    <div className={styles.leftColumnHeaderItemLabelDetails}>
                      <Text
                        title={`${
                          productInteractionData.displayName
                        } ${detailsFormatter(
                          productInteractionData.values[headerIndex]
                        )}`}
                      >
                        <span
                          className={
                            styles.leftColumnHeaderItemLabelInteractionType
                          }
                        >
                          {productInteractionData.displayName}
                        </span>
                        <span
                          className={
                            styles.leftColumnHeaderItemLabelFormattedValue
                          }
                        >
                          {detailsFormatter(
                            productInteractionData.values[headerIndex]
                          )}
                        </span>
                      </Text>
                    </div>
                  ) : null}
                </div>
              </div>
            </th>

            {values[headerIndex].map((value) => (
              <td key={uniqueId()}>
                <div
                  className={classNames(styles.cellContent, {
                    [styles.dashedContent]: !Number.isFinite(value),
                  })}
                  style={{
                    backgroundColor: getHeatMapColor(
                      colorRankMethod,
                      value,
                      colorRange,
                      sortedValues
                    ),
                  }}
                >
                  <Text title={formattedValue(value)}>
                    {formattedValue(value)}
                  </Text>
                </div>
              </td>
            ))}
          </tr>
        ))}

        <tr className={styles.bottomColumnHeaderWrapper} role="row">
          <th colSpan={2} role="columnheader" scope="col">
            <div />
          </th>
          {headers.map((bottomHeader) => (
            <th
              className={styles.bottomColumnHeader}
              key={uniqueId()}
              role="columnheader"
              scope="col"
            >
              <div className={styles.bottomColumnHeaderContentWrapper}>
                <div className={styles.bottomColumnHeaderContent}>
                  <Text title={bottomHeader.name}>{bottomHeader.name}</Text>
                </div>
              </div>
            </th>
          ))}
        </tr>
        <tr role="row">
          <th
            className={styles.matrixXAxisHeading}
            colSpan={headers.length + 2}
            role="columnheader"
            scope="col"
          >
            <div className={styles.matrixXAxisHeadingContent}>
              <Text>{xAxisLabel}</Text>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  );
};
