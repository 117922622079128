import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  getNumberFormat,
  useDivision,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { GroupColumnChart } from "components-ui/src/charts/group-column-chart/GroupColumnChart";
import { GroupColumnIndividualTooltipChart } from "components-ui/src/charts/group-column-individual-tooltip-chart/GroupColumnIndividualTooltipChart";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SALES_INDEXED,
  SALES_INDEXED_THRESHOLD,
} from "../../../common/constants";
import { getFocalItemSummaryWithParents } from "../../../common/utils/export-parameter-summary-utils";
import { getFocalItemLabels } from "../../../common/utils/focal-item-labels-utils";
import { type RootState } from "../../../store";
import {
  selectChannel,
  selectFocalItemParents,
  selectFocalItems,
  selectIndexedAgainst,
  selectLocation,
  selectMetric,
  selectPromotion,
  selectSegmentation,
  selectShowCustomerProfilingChartDataLabels,
  selectTime,
  selectTimePeriodLength,
  toggleCustomerProfilingChartDataLabels,
} from "../../services/customer-profiling-slice";
import styles from "./CustomerProfilingSingleSegmentationChart.module.css";
import { csvSingleSegmentationTransformation } from "./csvTransformation";
import {
  getCustomerProfilingSingleSegmentationGroupChartData,
  renderSingleItemSingleSegmentationGroupChartTooltip,
} from "./utils";

export type CustomerProfilingSingleSegmentationChartProps = {
  isExportEnabled: boolean;
  reportParameters?: ReportParametersDto;
};
export const CustomerProfilingSingleSegmentationChart = ({
  reportParameters,
  isExportEnabled,
}: CustomerProfilingSingleSegmentationChartProps) => {
  const dispatch = useDispatch();

  const channel = useSelector(selectChannel);
  const indexedAgainst = useSelector(selectIndexedAgainst);
  const location = useSelector(selectLocation);
  const promotion = useSelector(selectPromotion);
  const metric = useSelector(selectMetric);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const showCustomerProfilingChartDataLabels = useSelector(
    selectShowCustomerProfilingChartDataLabels
  );

  const { metricValue } = useSelector((state: RootState) => ({
    metricValue:
      state.customerProfiling.customerProfilingSingleSegmentationData,
  }));
  const formatter = useFormatter();
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const focalItemLabels = useMemo(
    () => getFocalItemLabels(focalItems, focalItemParents),
    [focalItemParents, focalItems]
  );

  const categories: string[] = useMemo(() => {
    const categoriesLabels = [];
    for (const item of metricValue.items) {
      if (item.nodeNumber) {
        categoriesLabels.push(focalItemLabels[item.nodeNumber] ?? item.name);
      } else {
        categoriesLabels.push(item.name);
      }
    }

    return categoriesLabels;
  }, [focalItemLabels, metricValue.items]);

  const data = useMemo(
    () =>
      getCustomerProfilingSingleSegmentationGroupChartData(metricValue.items),
    [metricValue.items]
  );

  const threshold = metricValue.metricName.startsWith(SALES_INDEXED)
    ? SALES_INDEXED_THRESHOLD
    : 0;

  const labelFormatter = useMemo(() => {
    let format = metricValue.format;

    if (format === "") {
      format = MetricTypes.None;
    }

    return formatter(format);
  }, [formatter, metricValue.format]);
  const chartCsvTransformationCallback = useCallback(
    () =>
      csvSingleSegmentationTransformation(
        metricValue,
        currencySymbol,
        categories
      ),
    [metricValue, currencySymbol, categories]
  );
  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Customer_Profiling_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );
  const parameterSummary = useMemo(
    () =>
      [
        {
          name: "Focal item(s)",
          value: getFocalItemSummaryWithParents(focalItems, focalItemParents),
        },
        { name: "Time", value: time },
        { name: "Metric", value: metric.label },
        { name: "Index against", value: indexedAgainst.label },
        { name: "Channel", value: channel.label },
        { name: "Promotion", value: promotion.label },
        {
          name: "Segmentation",
          value: segmentation[0]?.label ?? "",
        },
        {
          name: "Location",
          value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
            location.name
          }`,
        },
      ].filter(
        (parameter) => !(parameter.name === "Index against" && !parameter.value)
      ),
    [
      focalItems,
      focalItemParents,
      time,
      metric.label,
      indexedAgainst.label,
      channel.label,
      promotion.label,
      segmentation,
      location.shortName,
      location.name,
    ]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showCustomerProfilingChartDataLabels,
      value: "",
    },
  ];

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();
  const reportName = useSelector(
    (state: RootState) => state.customerProfiling.reportName
  );

  return (
    <>
      <div className={styles.chartOptionsContainer}>
        <ChartOptions
          dataLabelsOptions={dataLabelOptions}
          downloadWizardOptions={
            currentOptions
              ? {
                  chartOptions: currentOptions,
                  reportIcon: (
                    <ReportIcon type={ReportType.CustomerProfiling} />
                  ),
                  chartTitle: `Customer profiling - ${reportName}`,
                  reportTitle: reportName,
                }
              : undefined
          }
          filename={exportFilename}
          getCSVData={chartCsvTransformationCallback}
          getElementToExport={() => chartContainerRef.current}
          isFeatureEnabled={isExportEnabled}
          localParameters={parameterSummary}
          reportParameters={reportParameters}
          toggleDataLabels={() => {
            dispatch(toggleCustomerProfilingChartDataLabels());
          }}
        />
      </div>
      <ChartFooterWrapper
        height="390px"
        parameters={parameterSummary}
        ref={chartContainerRef}
      >
        {metricValue.items.length > 1 ? (
          <GroupColumnChart
            categories={categories}
            data={data}
            dataLabelFormatter={labelFormatter}
            onOptionsChanged={setCurrentOptions}
            showChartDataLabels={showCustomerProfilingChartDataLabels}
            threshold={threshold}
            tooltipFormatter={labelFormatter}
            tooltipSecondaryHeader={metricValue.displayName}
            yAxisLabel={metricValue.displayName}
            yAxisTickFormatter={labelFormatter}
          />
        ) : (
          <GroupColumnIndividualTooltipChart
            categories={categories}
            data={data}
            dataLabelFormatter={labelFormatter}
            onOptionsChanged={setCurrentOptions}
            showChartDataLabels={showCustomerProfilingChartDataLabels}
            threshold={threshold}
            tooltipFormatter={labelFormatter}
            tooltipOverride={
              renderSingleItemSingleSegmentationGroupChartTooltip
            }
            tooltipSecondaryHeader={metricValue.displayName}
            yAxisLabel={metricValue.displayName}
            yAxisTickFormatter={labelFormatter}
          />
        )}
      </ChartFooterWrapper>
    </>
  );
};
