import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Nav,
  NavButton,
  NavSize,
  NavVariant,
} from "@quantium-enterprise/qds-react";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { ChartDataWrapper } from "../../../common/components/ChartDataWrapper";
import { ReportletAccordion } from "../../../common/components/ReportletAccordion";
import { EMPTY_NODE_NUMBER } from "../../../common/constants";
import { type RootState } from "../../../store";
import styles from "../../GainsAndLossReport.module.css";
import { GainAndLossFeatureFlags } from "../../constants/gain-and-loss-feature-flags";
import { type GainsAndLossSwitchingBreakdownRequestDto } from "../../models/GainsAndLossSwitchingBreakdownRequestDto";
import {
  onSwitchingBreakdownResponseReceived,
  onSwitchingBreakdownRequest,
  selectFocalItem,
  selectChannel,
  selectLocalParametersInitialised,
  selectLocation,
  selectMetricSet,
  selectSegmentation,
  selectSegment,
  selectShowSwitchingBreakdownChartDataLabels,
  selectTime,
  selectTimePeriodLength,
  selectReportId,
  toggleSwitchingBreakdownChartDataLabels,
} from "../../services/gains-and-loss-slice";
import {
  useLazyGetGainsAndLossSwitchingBreakdownExportDataQuery,
  useLazyGetGainsAndLossSwitchingBreakdownQuery,
} from "../../services/gains-and-loss-switching-breakdown-api-slice";
import { getParameterSummaryValueStrings } from "../../utils/getParameterSummaryValueStrings";
import { LargestGainsLosses } from "./LargestGainsLosses";
import {
  createSwitchingBreakdownExportPayload,
  createSwitchingBreakdownReportletPayload,
} from "./utils";

const enum SWITCHING_BREAKDOWN_TABS {
  LargestGainsAndLoses = "Largest gains / losses",
}

const tabs = [SWITCHING_BREAKDOWN_TABS.LargestGainsAndLoses];

export const GainsAndLossSwitchingBreakdownReportlet = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();
  const { id } = useParams();
  const chartContainerRef = useRef<HTMLElement>();
  const [currentNavIndex, setCurrentNavIndex] = useState<number>(0);
  const flags = useFlags();
  const isExportEnabled = flags[GainAndLossFeatureFlags.ReportExport] ?? false;

  const reportId = useSelector(selectReportId);
  const focalItem = useSelector(selectFocalItem);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const metricSet = useSelector(selectMetricSet);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const showSwitchingBreakdownChartDataLabels = useSelector(
    selectShowSwitchingBreakdownChartDataLabels
  );

  const { isDataLoading } = useSelector((state: RootState) => ({
    isDataLoading: state.gainsAndLoss.isSwitchingBreakdownDataLoading,
  }));

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Switching_breakdown_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const switchingBreakdownExportFilename = useMemo(
    () =>
      cleanFilename(`Switching_breakdown_${timePeriodLength}_${location.name}`),
    [timePeriodLength, location]
  );

  const parameterSummary = useMemo(
    () =>
      getParameterSummaryValueStrings(
        channel,
        location,
        metricSet,
        segment,
        segmentation,
        time,
        focalItem
      ),
    [channel, location, segment, segmentation, time, focalItem, metricSet]
  );
  const [getReportletData, { isError, isFetching, isSuccess, data }] =
    useLazyGetGainsAndLossSwitchingBreakdownQuery();

  const fetchData = useCallback(
    async (
      division: string,
      payload: GainsAndLossSwitchingBreakdownRequestDto
    ) => {
      await getReportletData({ division, payload });
    },
    [getReportletData]
  );

  const attemptFetch = useCallback(() => {
    if (
      id &&
      reportId === id &&
      focalItem?.itemCode &&
      location.nodeNumber !== EMPTY_NODE_NUMBER &&
      localParametersInitialised
    ) {
      dispatch(onSwitchingBreakdownRequest(true));

      const payload = createSwitchingBreakdownReportletPayload(
        id,
        focalItem,
        channel.value as string,
        location.nodeNumber.toString()
      );

      fetchData(divisionName, payload).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [
    channel.value,
    dispatch,
    divisionName,
    fetchData,
    focalItem,
    id,
    location.nodeNumber,
    localParametersInitialised,
    reportId,
  ]);

  useEffect(() => {
    attemptFetch();
  }, [attemptFetch]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(onSwitchingBreakdownResponseReceived({ data, metricSet }));
    } else if (isError) {
      dispatch(onSwitchingBreakdownResponseReceived({ data: [], metricSet }));
    }
  }, [data, dispatch, isError, isFetching, isSuccess, metricSet]);

  const [switchingBreakdownExport] =
    useLazyGetGainsAndLossSwitchingBreakdownExportDataQuery();

  const switchingBreakdownAllCsvExportCallback = useCallback(async () => {
    try {
      if (
        id &&
        focalItem?.itemCode &&
        location.nodeNumber !== EMPTY_NODE_NUMBER
      ) {
        const payload = createSwitchingBreakdownExportPayload(
          id,
          focalItem,
          channel.value as string,
          location.nodeNumber.toString(),
          segmentation.value.toString(),
          metricSet.value.toString(),
          switchingBreakdownExportFilename,
          false,
          parameterSummary
        );
        await switchingBreakdownExport({ division: divisionName, payload });
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  }, [
    id,
    divisionName,
    focalItem,
    segmentation,
    switchingBreakdownExportFilename,
    metricSet,
    channel,
    parameterSummary,
    location,
    switchingBreakdownExport,
  ]);

  const switchingBreakdownCsvExportCallback = useCallback(async () => {
    try {
      if (
        id &&
        focalItem?.itemCode &&
        location.nodeNumber !== EMPTY_NODE_NUMBER
      ) {
        const payload = createSwitchingBreakdownExportPayload(
          id,
          focalItem,
          channel.value as string,
          location.nodeNumber.toString(),
          segmentation.value.toString(),
          metricSet.value.toString(),
          switchingBreakdownExportFilename,
          true,
          parameterSummary
        );
        await switchingBreakdownExport({ division: divisionName, payload });
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  }, [
    id,
    divisionName,
    focalItem,
    segmentation,
    switchingBreakdownExportFilename,
    metricSet,
    channel,
    parameterSummary,
    location,
    switchingBreakdownExport,
  ]);

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showSwitchingBreakdownChartDataLabels,
      value: "",
    },
  ];

  return (
    <ReportletAccordion
      className={styles.reportlet}
      subtitle="Understand which items have driven switching and had the greatest contribution to gains and losses for your focal item."
      title="Switching breakdown"
    >
      <Nav
        activeIndex={currentNavIndex}
        size={NavSize.Medium}
        variant={NavVariant.Tab}
      >
        {tabs.map((tab, index) => (
          <NavButton key={tab} onClick={() => setCurrentNavIndex(index)}>
            {tab}
          </NavButton>
        ))}
      </Nav>

      {currentNavIndex ===
      tabs.indexOf(SWITCHING_BREAKDOWN_TABS.LargestGainsAndLoses) ? (
        <ErrorBoundary>
          <ChartDataWrapper
            isError={isError}
            isSuccess={isSuccess && !isDataLoading}
            retry={attemptFetch}
            showNoDataAvailable={
              !data || location.nodeNumber === EMPTY_NODE_NUMBER
            }
          >
            <div className={styles.chartOptionsContainer}>
              <ChartOptions
                dataLabelsOptions={dataLabelOptions}
                filename={exportFilename}
                getAllCSVData={switchingBreakdownAllCsvExportCallback}
                getCSVData={switchingBreakdownCsvExportCallback}
                getElementToExport={() => chartContainerRef.current}
                isFeatureEnabled={isExportEnabled}
                localParameters={parameterSummary}
                reportParameters={reportParameters}
                toggleDataLabels={() => {
                  dispatch(toggleSwitchingBreakdownChartDataLabels());
                }}
              />
            </div>

            <ChartFooterWrapper
              height="800px"
              overflow="auto"
              parameters={parameterSummary}
              ref={chartContainerRef}
            >
              {isSuccess && (
                <LargestGainsLosses
                  className={styles.reportletModule}
                  isExportEnabled={isExportEnabled}
                />
              )}
            </ChartFooterWrapper>
          </ChartDataWrapper>
        </ErrorBoundary>
      ) : null}
    </ReportletAccordion>
  );
};
