import { formatMetricValue } from "../../../common/utils/export-parameter-summary-utils";
import { type RepertoireFocalDataResponse } from "../../services/repertoire-top-drawer-api-slice";

export const csvTransformation = (response: RepertoireFocalDataResponse) => {
  const columnHeaders = ["Focal items"];
  columnHeaders.push(response.metric.name);
  const csvData = [columnHeaders];

  for (const row of response.focalItemData) {
    const rowData = [row.name, formatMetricValue(row.value)];
    csvData.push(rowData);
  }

  return csvData;
};
