import {
  ddLog,
  type HierarchySliceNodeDto,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { type RootState } from "../../store";
import {
  type SegmentationBreakdownResponseDto,
  type TrendsChartResponseDto,
} from "../models/ChartResponseDto";
import { keyMeasureTrendsApiSlice } from "./key-measure-trend-api-slice";
import {
  setLatestSegmentationBreakdownRequestId,
  setLatestTrendsChartRequestId,
  setSegmentationBreakdownResponse,
  setTrendsChartResponse,
} from "./key-measure-trends-slice";

// Generic request object used by both endpoints
export type KeyMeasureTrendsChartsRequest = {
  focalItems: HierarchySliceNodeDto[];
  localSelectedValues: LocalSelectedValueDto[];
  reportId: string;
};

export const keyMeasureTrendsTrendsDataApiSlice =
  keyMeasureTrendsApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getKeyMeasureTrendsTrendsChartData: builder.query<
        TrendsChartResponseDto,
        { divisionName: string; payload: KeyMeasureTrendsChartsRequest }
      >({
        async onQueryStarted(
          _argument,
          { dispatch, queryFulfilled, requestId, getState }
        ) {
          try {
            dispatch(setLatestTrendsChartRequestId(requestId));

            const { data } = await queryFulfilled;
            const state = getState() as RootState;

            if (state.keyMeasureTrends.latestTrendsChartRequest === requestId) {
              dispatch(setTrendsChartResponse(data));
            }
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetTrendsChartData/${divisionName}`,
        }),
      }),
      getKeyMeasureTrendsSegmentationBreakdownData: builder.query<
        SegmentationBreakdownResponseDto,
        { divisionName: string; payload: KeyMeasureTrendsChartsRequest }
      >({
        async onQueryStarted(
          _argument,
          { dispatch, queryFulfilled, requestId, getState }
        ) {
          try {
            dispatch(setLatestSegmentationBreakdownRequestId(requestId));
            const { data } = await queryFulfilled;
            const state = getState() as RootState;

            if (
              state.keyMeasureTrends.latestSegmentationBreakdownRequest ===
              requestId
            ) {
              dispatch(setSegmentationBreakdownResponse(data));
            }
          } catch {
            ddLog("ERROR", {}, "error");
          }
        },
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetSegmentationBreakdownData/${divisionName}`,
        }),
      }),
    }),
  });

export const {
  useLazyGetKeyMeasureTrendsTrendsChartDataQuery,
  useLazyGetKeyMeasureTrendsSegmentationBreakdownDataQuery,
} = keyMeasureTrendsTrendsDataApiSlice;
